import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { MyPagination } from "../../../components/Custom/Table/MyPagination";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { asyncLoadingFunction } from "../../../redux/actions/helperActions";
import {
  setActiveLot,
  setCards,
  starGetCardByLotId,
  startInactivateCards,
  startUpdateCard,
} from "../../../redux/actions/managementCardsActions";
import Barcode from "react-barcode";
import "./formCreateCards.scss";
import { State, StateCards } from "../../../enums/enums";
import { getBarcodeCard } from "../../../utils/utils";
import { getCardByLotId } from "../../../services/cards";
import { ExportDetailsDeliveredCardsData } from "../../../hooks/useDataTable/DetailsDeliveredCards/ExportDetailsDeliveredCards";

export const DetailsDeliveredCards = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { user, token } = useSelector((state: AppState) => state.auth);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [totalData, setTotalData] = useState([]);
  const { cards, activeLot } = useSelector(
    (state: AppState) => state.managementCards
  );
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);
  const [loading, setLoading] = useState(false);
  const [stateFilter, setStateFilter] = useState<StateCards | null>(null);
  const [cardIds, setCardIds] = useState("");
  const [observation, setObservation] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [isInactivating, setIsInactivating] = useState(false);
  const [paramFilter, setParamFilter] = useState<any>({});

  const fetchCards = async () => {
    if (user) {
      const params: any = {
        page: currentPage,
        perPage: rowsPerPage,
      };
      setParamFilter(params);
      await dispatch(
        asyncLoadingFunction(() =>
          dispatch(
            starGetCardByLotId({
              ...params,
            })
          )
        )
      );
    }
  };
  useEffect(() => {
    fetchCards();
    return () => {
      dispatch(setCards(null));
      dispatch(setActiveLot(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = async (page: number, rows?: number) => {
    setCurrentPage(page);
    setLoading(true);
    await dispatch(
      starGetCardByLotId({
        ...paramFilter,
        page,
      })
    );
    setLoading(false);
  };

  const getStateName = (state: StateCards) => {
    switch (state) {
      case StateCards.ACTIVE:
        return t("state.active");
      case StateCards.INACTIVE:
        return t("state.inactive");
      case StateCards.REDEEMED:
        return t("cards.redeemed");

      default:
        return t("state.inactive");
    }
  };

  const handleCleanFilter = async () => {
    setCurrentPage(1);
    setCardIds("");
    setStateFilter(null);
    setIsFiltering(false);
    setLoading(true);
    const params: any = {
      page: currentPage,
      perPage: rowsPerPage,
    };
    await dispatch(
      starGetCardByLotId({
        ...params,
      })
    );

    setParamFilter(params);
    setLoading(false);
  };

  const handleFilter = async () => {
    setCurrentPage(1);
    let ids = cardIds.split(",");

    ids = ids.filter((id) => id !== "");

    setLoading(true);
    let params: any = {
      page: currentPage,
      perPage: rowsPerPage,
    };

    if (ids.length > 0) {
      const from = ids[0];
      const to = ids[1];
      params = {
        ...params,
        from,
      };
      if (to) {
        params = {
          ...params,

          to,
        };
      }
    }
    if (stateFilter) {
      params = {
        ...params,
        state: stateFilter,
      };
    }

    await dispatch(
      starGetCardByLotId({
        ...params,
        page: 1,
      })
    );
    setParamFilter(params);
    setLoading(false);
    setIsFiltering(true);
  };

  const updatedCard = async (card: any, state: State) => {
    await dispatch(
      startUpdateCard(
        card,
        {
          state,
          observation,
        },
        true
      )
    );
    setCardIds("");
    await dispatch(
      starGetCardByLotId({
        ...paramFilter,
        page: cards?.page || 1,
      })
    );
  };
  const calculateNewCardState = () => {
    if (cards && cards?.data?.length <= 0) {
      return State.INACTIVE;
    }
    const currentCardState = cards?.data[0].state;
    const newCardState =
      currentCardState === State.ACTIVE ? State.INACTIVE : State.ACTIVE;
    return newCardState;
  };

  const handleChangeStateCards = async () => {
    const newCardState = calculateNewCardState();

    if (cards && cards.total === 1) {
      const cardId = cards.data[0].id;
      return updatedCard(cardId, newCardState);
    }
    const ids = cardIds.split(",");

    const from = ids[0];
    const to = ids[1];

    setIsInactivating(true);
    await dispatch(
      startInactivateCards(
        parseInt(from),
        parseInt(to),
        newCardState,
        true,
        observation
      )
    );

    await dispatch(
      starGetCardByLotId({
        ...paramFilter,
        page: cards?.page || 1,
      })
    );
    setIsInactivating(false);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedCharacters = /^[0-9,]+$/;
    if (!allowedCharacters.test(e.key)) {
      e.preventDefault();
    }
  };

  const onHandleChangeRange = (e: any) => {
    if (stateFilter && cardIds === "") {
      setIsFiltering(false);
    }
    setCardIds(e.target.value);
  };

  const onExport = async () => {
    if (!token) {
      return false;
    }
    const resCards = await getCardByLotId(activeLot as number, token);
    setTotalData(resCards.data);
    setTooltipIsOpen(true);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField
            name="state"
            label={t("table.state")}
            size="small"
            fullWidth
            variant="outlined"
            value={stateFilter || ""}
            onChange={(e) => setStateFilter(e.target.value as any)}
            select
          >
            {[StateCards.ACTIVE, StateCards.INACTIVE, StateCards.REDEEMED].map(
              (state) => (
                <MenuItem key={state} value={state}>
                  {getStateName(state)}
                </MenuItem>
              )
            )}
          </TextField>
        </Grid>
        <Grid item xs={8} mb={2}>
          <TextField
            name="cardIds"
            label={t("managementCards.rangeSeparatedComma")}
            size="small"
            fullWidth
            variant="outlined"
            value={cardIds}
            onChange={(e) => onHandleChangeRange(e)}
            onKeyPress={handleKeyPress}
          />
        </Grid>
        <Grid item xs={2}>
          <button
            className="d-flex btn-light-dark"
            type="button"
            style={{ width: "100%" }}
            onClick={() => (isFiltering ? handleCleanFilter() : handleFilter())}
            disabled={!!loading}
          >
            <div className="p-0 text-right">
              <i className="fa fa-filter btn-icon pt-1 fa-md"></i>
            </div>
            <div className="p-0 text-left">
              <label className="btn-label pl-1">
                {isFiltering ? t("filters.clean") : t("filters.filter")}
              </label>
            </div>
          </button>
        </Grid>
        {cards &&
          isFiltering &&
          cardIds &&
          stateFilter !== StateCards.INACTIVE &&
          stateFilter !== StateCards.REDEEMED &&
          !(
            cards.data.length === 1 &&
            cards.data[0].state === StateCards.REDEEMED
          ) && (
            <>
              <Grid item xs={8} mb={2}>
                <TextField
                  name="observation"
                  label="Observacion"
                  size="small"
                  fullWidth
                  variant="outlined"
                  value={observation}
                  onChange={(e) => {
                    setObservation(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4} mb={2}>
                <Button
                  style={{
                    border: "none",
                    outline: "none",
                    fontSize: 14,
                  }}
                  variant="contained"
                  className="btn-dialog primary-btn"
                  onClick={handleChangeStateCards}
                  disabled={isInactivating || !observation}
                >
                  {t(
                    calculateNewCardState() === State.INACTIVE
                      ? "cards.inactiveCards"
                      : "cards.activeCards"
                  )}
                  {calculateNewCardState() === State.INACTIVE ? (
                    <i className="fa-solid fa-ban ml-2"></i>
                  ) : (
                    <i className="fa-solid fa-check ml-2"></i>
                  )}
                </Button>
              </Grid>
            </>
          )}
        {loading ? (
          <Box
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            width="100%"
            mt={3}
          >
            <CircularProgress className={"colorLoading"} />
          </Box>
        ) : (
          <>
            <Grid item xs={12} bgcolor={"#F4F4F4"}>
              <Grid container spacing={2}>
                {[
                  "ID",
                  t("reports.deliveredTo").toUpperCase(),
                  t("form.value").toUpperCase(),
                  t("form.state").toUpperCase(),
                  t("cards.barcode").toUpperCase(),
                  t("user.observation").toUpperCase(),
                ].map((col) => (
                  <Grid item xs={1.9} key={col}>
                    <Typography
                      variant="body1"
                      fontWeight={"bold"}
                      color="#333"
                    >
                      {col.toUpperCase()}
                    </Typography>
                  </Grid>
                ))}
                <Grid item xs={0.5}>
                  <Tooltip
                    open={tooltipIsOpen}
                    onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#fff",
                          "& .MuiTooltip-arrow": {
                            color: "#02D0E7",
                          },
                          fontWeight: "bold",
                          fontSize: "12px",
                          color: "#000",
                          maxWidth: "none",
                          cursor: "pointer",
                        },
                      },
                    }}
                    title={
                      totalData.length > 0 && (
                        <ExportDetailsDeliveredCardsData
                          totalData={totalData}
                          setTooltipIsOpen={setTooltipIsOpen}
                        />
                      )
                    }
                    disableHoverListener
                    placement="bottom"
                  >
                    <div>
                      <i
                        className="fa-solid fa-file-export mr-1"
                        style={{
                          fontSize: 16,
                          color: "#2C97DF",
                          cursor: "pointer",
                        }}
                        onClick={onExport}
                      />
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {cards?.data.map((card) => (
                <Grid
                  container
                  spacing={2}
                  bgcolor={card.used ? "#02D0E71A" : "#fff"}
                  mt={1}
                  key={card.id}
                  alignItems="center"
                >
                  <Grid item xs={1.9}>
                    <Typography variant="body1" color="#333">
                      {card.id}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.9}>
                    <Typography variant="body1" color="#333">
                      {card.deliveredTo
                        ? card.deliveredTo
                        : t("cards.nonIrrigated")}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.9}>
                    <Typography variant="body1" color="#333">
                      {"$" + card.value}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.9}>
                    <Typography variant="body1" color="#333">
                      {card.used
                        ? t("cards.redeemed").toUpperCase()
                        : card.state === State.ACTIVE
                        ? t("state.active").toUpperCase()
                        : t("state.inactive").toUpperCase()}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.9} className="barcode-container">
                    <Typography variant="body1" color="#333">
                      <Barcode
                        format="EAN13"
                        value={getBarcodeCard(card.value)}
                        height={15}
                        width={1}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={1.9}>
                    <Typography variant="body1" color="#333">
                      {card.observation}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              {!loading && cards ? (
                <MyPagination
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                  lastPage={cards.last_page || 1}
                  siblingCount={1}
                  total={cards.total || 0}
                />
              ) : (
                <Box
                  display={"flex"}
                  justifyContent="center"
                  alignItems={"center"}
                  width="100%"
                  mt={3}
                >
                  <CircularProgress className={"colorLoading"} />
                </Box>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
