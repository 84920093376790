import moment from "moment";
import { useSelector } from "react-redux";

import { IPlayerActivityData } from "../../../interfaces/playerActivity";
import { getCopyArrayOrObject } from "../../../utils/utils";
import { AppState } from "../../../redux/reducers/rootReducer";
import { headersTransactionsReport } from "../../../helpers/headersTables";

export const TransactionsDataTable = (totalData: IPlayerActivityData[]) => {
    const { data } = useSelector((state: AppState) => state.search);

    const headersCvsStyle = headersTransactionsReport.map((header) => {
        return {
            title: header.label,
            width: { wpx: 120 },
            style: {
                font: { bold: true, color: { rgb: "FFFFFF" } },
                fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
                alignment: { vertical: "center", horizontal: "center" },
            },
        };
    });

    // Obtener datos por vista
    const dataTransactions =
        data && data.data ? getCopyArrayOrObject(data.data) : [];
    // Obtener total datos
    const dataTotalTransactions = totalData
        ? getCopyArrayOrObject(totalData)
        : [];
    //   DATA TOTAL
    const multiTotalDataTransactions = [
        {
            columns: headersCvsStyle,
            data: dataTotalTransactions.map((data: any) => {
                let array;
                array = [
                    {
                        value: data.id,
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: data.player?.id,
                        style: { alignment: { horizontal: "center" } },
                    },

                    {
                        value: data.player?.firstName + " " + data.player?.lastName,
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: moment(data.createAt).format("DD/MM/YYYY HH:mm:ss"),
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: "$ " + data.value,
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: data.observations,
                        style: { alignment: { horizontal: "center" } },
                    },
                ];
                return array;
            }),
        },
    ];
    const multiDataExclusions = [
        {
            columns: headersCvsStyle,
            data: dataTransactions.map((data: any) => {
                let array;
                array = [
                    {
                        value: data.id,
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: data.player?.id,
                        style: { alignment: { horizontal: "center" } },
                    },

                    {
                        value: data.player?.firstName + " " + data.player?.lastName,
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: moment(data.createAt).format("DD/MM/YYYY HH:mm:ss"),
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: "$ " + data.value,
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: data.observations,
                        style: { alignment: { horizontal: "center" } },
                    },
                ];
                return array;
            }),
        },
    ];
    const finalData = {
        dataPerView: dataTransactions,
        dataTotal: dataTotalTransactions,
        headersCSV: headersTransactionsReport,
        multiDataSet: multiDataExclusions,
        multiTotalDataSet: multiTotalDataTransactions,
    };
    return finalData;
};
