import React, { FC } from "react";
import { Breadcrumbs, Link, LinkProps, Typography } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";
import { setFlagForm } from "../../redux/actions/helperActions";

interface props {
  titles: string[];
  onChange?(): void;
}
interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter: FC<LinkRouterProps> = (props) => {
  return <Link {...props} component={RouterLink} />;
};

const MyBreadCrumbs: FC<props> = ({ titles, onChange, children }) => {
  const location = useLocation();
  const pathnames = location.pathname
    .split("/")
    .filter((x) => x)
    .filter((path) => !["admin"].includes(path));

  const { flagFormCreate } = useSelector((state: AppState) => state.helper);
  const dispatch = useDispatch();
  const handleChangeBreadCumb = (e: any) => {
    e.preventDefault();
    dispatch(setFlagForm({ flagFormCreate: false }));
    onChange && onChange();
  };

  return (
    <div role="presentation" onClick={handleChangeBreadCumb}>
      <Breadcrumbs aria-label="breadcrumb">
        {pathnames
          .filter((path) =>
            location.pathname === "/admin/transactions/settings"
              ? path !== "settings"
              : true
          )
          .map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/admin/${pathnames.slice(0, index + 1).join("/")}${
              location.pathname === "/admin/transactions/settings"
                ? "/settings"
                : ""
            }`;

            return last && !flagFormCreate && !children ? (
              <Typography color="text.primary" key={to}>
                {titles[index]}
              </Typography>
            ) : (
              <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                {titles[index]}
              </LinkRouter>
            );
          })}
        {children}
      </Breadcrumbs>
    </div>
  );
};

export default MyBreadCrumbs;
