import React, { useState, KeyboardEvent, useEffect } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import "./formCreateRol.scss";
import { IFormRoles, IRolesModules } from "../../../interfaces/roles";
import { getCopyArrayOrObject, regLettersNumbers } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { MyDialog } from "../custom/MyDialog";
import { MyActions } from "../custom/MyActions";
import {
  setActiveRol,
  startEditRol,
  startSaveRoles,
} from "../../../redux/actions/managementRolesActions";
import { DefaultOperators, Roles_Enum, State } from "../../../enums/enums";
import {
  Enum_rol,
  IMenuDB,
  IMenuList,
  IMenuParams,
} from "../../../interfaces/menu";
import {
  filterRoles,
  getRolByOperator,
} from "../../../services/managementRoles";
import { getMenusRedux } from "../../../redux/actions/managementMenuActions";
import {
  getMenusDb,
  getMenusLogged,
  getMenusPage,
} from "../../../services/managementMenuService";
import { MyAlert } from "../custom/MyAlert";
import { setCloseAlert } from "../../../redux/actions/helperActions";
import { arraySearch } from "../../../redux/actions/searchActions";
import { DEFAULT_ROLES } from "../../../shared/const";

const styles = {
  "& input.Mui-disabled ": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
  "& .MuiSelect-select.Mui-disabled": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const estado = [
  { label: "Activo", value: 1 },
  { label: "Inactivo", value: 2 },
];
const defaultRolModule: IRolesModules = {
  idMenu: 0,
  idModule: 0,
  permissions: {
    create: false,
    delete: false,
    edit: false,
    read: false,
  },
};
interface IDeleteIndex {
  indexRol: null | number;
  indexModule: null | number;
}

export const FormCreateRol = () => {
  const { t } = useTranslation();
  const notificationsMenuItem = t("notifications.notificationsType", {
    returnObjects: true,
    defaultValue: [],
  }) as [];
  //const { menus } = useSelector((state: AppState) => state.managementMenus);
  const [menus, setMenus] = useState<IMenuDB | null>(null);
  const { activeRol } = useSelector((state: AppState) => state.managementRoles);
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { openAlert, messageAlert, typeAlert } = useSelector(
    (state: AppState) => state.helper
  );
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );

  const [operatorId, setOperatorId] = useState<number | null>(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [btnAction, setBtnAction] = useState<any>(null);
  const [indexDelete, setIndexDelete] = useState<IDeleteIndex>({
    indexRol: null,
    indexModule: null,
  });
  const isSuper = user?.roles.name === Enum_rol.SUPER_ADMIN;

  const defaultRolValues: IFormRoles = {
    name: activeRol
      ? activeRol.rol
        ? activeRol.rol.name
        : (activeRol.name as string)
      : "",
    description: activeRol
      ? activeRol.rol
        ? activeRol.rol.description
        : (activeRol.description as string)
      : "",
    modules: [{ ...defaultRolModule }],
    state: activeRol
      ? activeRol.rol
        ? activeRol.rol.state
        : (activeRol.state as number)
      : 1,
    notification: activeRol
      ? activeRol.rol
        ? activeRol.rol.notification
        : (activeRol.notification as number)
      : "",
  };

  const [formValues, setFormValues] = useState([{ ...defaultRolValues }]);

  // FLAG IS SUPER AND NOT EDIT FORM
  const flagSuperEdit =
    user?.roles.name === Roles_Enum.SUPER_ADMIN && !activeRol;

  const dispatch = useDispatch();
  useEffect(() => {
    const permissions: IRolesModules[] = [];
    if (activeRol) {
      if (activeRol.rol) {
        activeRol?.rol.actionsPermissions.forEach((permission) => {
          const {
            menu: { id: idMenu },
            menuItem,
            created,
            deleted,
            updated,
            readed,
          } = permission;
          const idModule = menuItem ? menuItem.id : "";
          const rolModule: IRolesModules = {
            idMenu,
            idModule,
            permissions: {
              create: created === 1 ? true : false,
              delete: deleted === 1 ? true : false,
              edit: updated === 1 ? true : false,
              read: readed === 1 ? true : false,
              id: permission.id,
            },
          };
          permissions.push(rolModule);
        });
      } else {
        activeRol?.actionsPermissions?.forEach((permission) => {
          const {
            menu: { id: idMenu },
            menuItem,
            created,
            deleted,
            updated,
            readed,
          } = permission;
          const idModule = menuItem ? menuItem.id : "";
          const filterMenus =
            menus &&
            menus.data.filter(
              (menu: IMenuList) =>
                menu.id === permission.menu.id && menu.state === State.ACTIVE
            );
          if (filterMenus && filterMenus.length > 0) {
            const rolModule: IRolesModules = {
              idMenu,
              idModule,
              permissions: {
                create: created === 1 ? true : false,
                delete: deleted === 1 ? true : false,
                edit: updated === 1 ? true : false,
                read: readed === 1 ? true : false,
                id: permission.id,
              },
            };
            permissions.push(rolModule);
          }
        });
      }

      const copyFormValues = [...formValues];
      copyFormValues[0].modules = permissions;
      setFormValues(copyFormValues);
    }

    /* return () => {
      second
    } */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menus]);

  const fetchMenus = async (params: Partial<IMenuParams>) => {
    if (!token) {
      throw new Error("not valid token");
    }

    if (!params.operatorId) {
      delete params.operatorId;
    }

    const rs = await getMenusDb(token, params);

    setMenus(rs);
  };
  useEffect(() => {
    if (operatorId || (user as any).operator) {
      const params: Partial<IMenuParams> = {
        state: State.ACTIVE,
        operatorId: isSuper ? operatorId : (user as any).operator.id,
      };
      fetchMenus(params);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatorId]);

  // TRAER MENUS POR OPERADOR CUANDO ES SUPER
  const filterMenus = async () => {
    if (token) {
      // CUANDO ES EDITAR
      if (activeRol && isSuper) {
        const isDefaultRol = DEFAULT_ROLES.includes(activeRol.name as any);
        const responseRol = await getRolByOperator(token, activeRol.id);
        if (responseRol.data.length > 0 || isDefaultRol) {
          const params: Partial<IMenuParams> = {
            state: State.ACTIVE,
            operatorId: isDefaultRol
              ? DefaultOperators.NEAT
              : responseRol.data[0].operator.id,
          };

          await fetchMenus(params);
        } else {
          const rs = await getMenusLogged(token, activeRol.id, true);

          setMenus({
            data: rs,
            last_page: 1,
            page: 1,
            total: rs.length,
          });
        }
      }
    }
  };

  useEffect(() => {
    filterMenus();

    return () => {
      dispatch(arraySearch(null));
      setMenus(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    indexModule?: number
  ) => {
    if (indexModule !== undefined) {
      const newModule = getCopyArrayOrObject(formValues);
      if (event.target.name === "idMenu") {
        newModule[index].modules[indexModule] = {
          ...newModule[index].modules[indexModule],
          [event.target.name]: event.target.value,
          idModule: "",
        };
      } else {
        newModule[index].modules[indexModule] = {
          ...newModule[index].modules[indexModule],
          [event.target.name]: event.target.value,
        };
      }
      setFormValues(newModule);
    } else {
      setFormValues(
        formValues.map((formRoles: any, i: number) =>
          i === index
            ? { ...formRoles, [event.target.name]: event.target.value }
            : formRoles
        )
      );
    }
  };
  const handleAddModule = (index: number) => {
    const newFormValues = getCopyArrayOrObject(formValues);
    newFormValues[index].modules = [
      ...newFormValues[index].modules,
      defaultRolModule,
    ];
    setFormValues(newFormValues);
  };
  const handleAddRol = () => {
    setFormValues((prevState: any) => [...prevState, defaultRolValues]);
  };
  const handleCheckPermission = (
    indexRol: number,
    indexModule: number,
    name: string
  ) => {
    const CopyFormValues = getCopyArrayOrObject(formValues);
    const permissions =
      CopyFormValues[indexRol].modules[indexModule].permissions;

    CopyFormValues[indexRol].modules[indexModule].permissions = {
      ...permissions,
      [name]: !permissions[name],
    };
    setFormValues(CopyFormValues);
  };

  const handleSelectOperator = async (e: any) => {
    if (flagSuperEdit && menus && token) {
      const menusFilterData = await getMenusPage(token, e.target.value);
      await dispatch(getMenusRedux(menusFilterData));
    }
    setOperatorId(e.target.value);
  };

  const handleCleanForm = () => {
    setBtnAction("Cancel");
    setOpenDialog(true);
  };

  const handleCancel = () => {
    setBtnAction("GoBack");
    setOpenDialog(true);
  };

  const handleValidateFields = async () => {
    setIsSubmit(true);
    let noValid = false;
    let noModules = false;
    const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;

    if (isSuper && !activeRol && operatorId === null) {
      noValid = true;
    }

    formValues.forEach(async (values, indexRol) => {
      if (
        values.name === "" ||
        values.description === "" ||
        values.modules.length <= 0 ||
        values.notification === ""
      ) {
        noValid = true;
      }

      if (values.modules.length > 0) {
        values.modules.forEach((module) => {
          const enabledPermissions = Object.entries(module.permissions).filter(
            ([key, value]) => {
              return value && key !== "id";
            }
          );

          if (enabledPermissions.length < 1) {
            noValid = true;
          }
          //@ts-ignore
          if (getModulesFromMenu(module.idMenu as any)?.menuItem.length > 0) {
            if (module.idModule === 0 || (module.idModule as any) === "") {
              noValid = true;
            }
          } else {
            if (module.idMenu === 0 || (module.idMenu as any) === "") {
              noValid = true;
            }
          }
        });
      } else {
        noModules = true;
      }
    });

    if (noValid && !noModules) {
      dispatch(setMessageAlert("alerts.completeRequiredFields"));
      dispatch(setTypeAlert("error"));
      setOpenDialog(false);
      return dispatch(setOpenAlert());
    }

    if (noModules && noValid) {
      dispatch(setMessageAlert("alerts.roleNoModules"));
      dispatch(setTypeAlert("error"));
      setOpenDialog(false);
      return dispatch(setOpenAlert());
    }

    setBtnAction(activeRol ? "Edit" : "Save");
    setOpenDialog(true);
  };

  const handleSave = async () => {
    setOpenDialog(false);
    setIsLoading(true);

    if (activeRol) {
      const activeName = activeRol.rol
        ? activeRol.rol.name
        : activeRol.name || "";

      if (activeName.toLowerCase() !== formValues[0].name.toLowerCase()) {
        const getRole = await existsRole(formValues[0].name);

        if (getRole && getRole.data && getRole.data.length > 0) {
          dispatch(setMessageAlert("alerts.nameDuplicate"));
          dispatch(setTypeAlert("error"));
          return dispatch(setOpenAlert());
        }
      }
      await dispatch(
        startEditRol(
          formValues,
          activeRol.rol ? activeRol.rol.id : activeRol.id
        )
      );
    } else {
      let valid = true;
      for (const value of formValues) {
        const getRole = await existsRole(value.name);
        if (getRole && getRole.data && getRole.data.length > 0) {
          dispatch(setMessageAlert("alerts.nameDuplicate"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
          valid = false;
        }
      }

      valid &&
        (await dispatch(
          startSaveRoles(
            formValues,
            user && !isSuper ? user.operator.id : operatorId
          )
        ));
    }
    setIsSubmit(false);
    setIsLoading(false);
  };

  const getModulesFromMenu = (idMenu: number) => {
    if (!menus) {
      return null;
    }
    return menus?.data.find((menu) => menu.id === idMenu);
  };

  const existsRole = async (roleName: string) => {
    if (token && user) {
      let operator;

      if (activeRol && isSuper) {
        const getOperatorRole = await filterRoles(
          token,
          null,
          {
            name: activeRol.name,
            exact: true,
          },
          false
        );

        operator =
          getOperatorRole && getOperatorRole.data
            ? getOperatorRole.data[0].operator.id
            : user.operator.id;
      } else {
        operator = !isSuper ? user.operator.id : operatorId;
      }
      const finalValues = {
        name: roleName,
        exact: true,
      };

      const resp = await filterRoles(token, operator, finalValues, false);
      return resp;
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const isAvailableModule = (indexRol: number, idModule: number): boolean => {
    const currentRol: IFormRoles = getCopyArrayOrObject(formValues[indexRol]);
    const isAvailable = currentRol.modules.filter(
      (module) => module.idModule === idModule
    ).length;
    return isAvailable > 1;
  };

  const handleDeleteModule = (indexRol: number, indexModule: number) => {
    const allRoles = [...formValues];
    allRoles[indexRol].modules = allRoles[indexRol].modules.filter(
      (module, index) => index !== indexModule
    );
    setFormValues(allRoles);
    setOpenDialog(false);
  };

  const handleDeleteRole = (indexRol: number) => {
    let allRoles = [...formValues];
    allRoles = allRoles.filter((rol, index) => index !== indexRol);
    setFormValues(allRoles);
    setOpenDialog(false);
  };

  const handleResetForm = () => {
    setFormValues([{ ...defaultRolValues }]);
    setOperatorId(null);
    setIsSubmit(false);
    setOpenDialog(false);
  };
  const getMenuActiveDetails = (idMenu: number) => {
    const menu = !isSuper
      ? activeRol?.rol.actionsPermissions?.find((act) => act.menu.id === idMenu)
      : activeRol?.actionsPermissions?.find((act) => act.menu.id === idMenu);
    return menu;
  };
  const getMenuItemActiveDetails = (idMenuItem: number) => {
    const module = !isSuper
      ? activeRol?.rol.actionsPermissions?.find(
          (act) => act.menuItem?.id === idMenuItem
        )
      : activeRol?.actionsPermissions?.find(
          (act) => act.menuItem?.id === idMenuItem
        );

    return module;
  };

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
    if (typeAlert === "success") {
      dispatch(setFlagForm({ flagFormCreate: false }));
      dispatch(setActiveRol(null));
    }
  };

  return (
    <Box>
      <div className="form-container-lg">
        {formValues.map((values, index) => (
          <form key={index} className="mt-4">
            <Grid container>
              <Grid item xs={11} marginBottom={3}>
                <i className="fa-solid fa-plus fa-lg pr-3 iconUser" />
                <span className="titleUser">
                  {activeRol
                    ? t("rolManagement.editRol")
                    : t("rolManagement.newRol")}
                </span>
              </Grid>
              <Grid
                item
                xs={1}
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                {index > 0 && (
                  <Tooltip title={t("rolManagement.deleteteRole") as string}>
                    <IconButton
                      onClick={() => {
                        setBtnAction("DeleteRole");
                        setOpenDialog(true);
                        setIndexDelete({
                          ...indexDelete,
                          indexRol: index,
                        });
                      }}
                      style={{ border: "none", outline: "none" }}
                    >
                      <i
                        className="fa-regular fa-trash-can"
                        style={{ color: "#E62E1B", fontSize: 20 }}
                      ></i>
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
            <div className="filter">
              <Grid container spacing={2}>
                {
                  //MOSTRAR SELECT CON OPERADORES CUANDO SEA SUPER_ADMIN
                  flagSuperEdit && !activeRol && (
                    <Grid item xs={3}>
                      <FormControl
                        sx={{ width: "100%" }}
                        size="small"
                        error={
                          isSubmit &&
                          user?.roles.name === Roles_Enum.SUPER_ADMIN &&
                          !operatorId
                        }
                        disabled={!!activeRol}
                      >
                        <InputLabel id="operator-label">
                          {t("companyManagement.operator") + "*"}
                        </InputLabel>
                        <Select
                          labelId="operator-label"
                          name="operator"
                          size="small"
                          fullWidth
                          onChange={handleSelectOperator}
                          value={!operatorId ? "" : operatorId}
                          sx={styles}
                          input={
                            <OutlinedInput
                              label={t("companyManagement.operator") + "*"}
                            />
                          }
                          MenuProps={MenuProps}
                        >
                          {companies?.data?.map((company) => (
                            <MenuItem key={company.id} value={company.id}>
                              {company.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormHelperText>
                        {isSubmit && !operatorId && (
                          <label className="ml-3" style={{ color: "#d32f2f" }}>
                            {t("form.requiredFile")}
                          </label>
                        )}
                      </FormHelperText>
                    </Grid>
                  )
                }
                <Grid
                  item
                  md={flagSuperEdit ? 3 : 4}
                  lg={flagSuperEdit ? 3 : 4}
                  sm={6}
                  xs={12}
                >
                  <TextField
                    name="name"
                    variant="outlined"
                    size="small"
                    type="text"
                    InputLabelProps={{ shrink: false }}
                    label={values.name === "" ? t("form.name") + "*" : " "}
                    placeholder={t("form.name")}
                    fullWidth
                    error={isSubmit && values.name === ""}
                    sx={styles}
                    autoComplete="off"
                    value={values.name}
                    onChange={(e) => handleFormChange(index, e)}
                    inputProps={{ maxLength: 100 }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regLettersNumbers(e.key) && e.preventDefault()
                    }
                  />
                  <FormHelperText>
                    {isSubmit && values.name === "" && (
                      <label className="ml-3" style={{ color: "#d32f2f" }}>
                        {t("form.requiredFile")}
                      </label>
                    )}
                  </FormHelperText>
                </Grid>
                <Grid
                  item
                  md={flagSuperEdit ? 3 : 4}
                  lg={flagSuperEdit ? 3 : 4}
                  sm={6}
                  xs={12}
                >
                  <TextField
                    name="description"
                    variant="outlined"
                    size="small"
                    type="text"
                    InputLabelProps={{ shrink: false }}
                    label={
                      values.description === ""
                        ? t("form.description") + "*"
                        : " "
                    }
                    error={isSubmit && values.description === ""}
                    placeholder={t("form.description")}
                    fullWidth
                    sx={styles}
                    autoComplete="off"
                    value={values.description}
                    onChange={(e) => handleFormChange(index, e)}
                    inputProps={{ maxLength: 100 }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regLettersNumbers(e.key) && e.preventDefault()
                    }
                  />
                  <FormHelperText>
                    {isSubmit && values.description === "" && (
                      <label className="ml-3" style={{ color: "#d32f2f" }}>
                        {t("form.requiredFile")}
                      </label>
                    )}
                  </FormHelperText>
                </Grid>
                <Grid
                  item
                  md={flagSuperEdit ? 3 : 4}
                  lg={flagSuperEdit ? 3 : 4}
                  sm={6}
                  xs={12}
                >
                  <TextField
                    name="state"
                    size="small"
                    select
                    id="demo-multiple-name"
                    InputLabelProps={{ shrink: true }}
                    label={`${t("form.state")}*`}
                    placeholder={t("form.state")}
                    value={values.state}
                    onChange={(e) => handleFormChange(index, e)}
                    fullWidth
                    sx={styles}
                  >
                    {estado.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* SELECT NOTIFICATIONS */}
                <Grid
                  item
                  md={flagSuperEdit ? 3 : 4}
                  lg={flagSuperEdit ? 3 : 4}
                  sm={6}
                  xs={12}
                >
                  <TextField
                    name="notification"
                    size="small"
                    select
                    id="notification-name"
                    label={t("notifications.notificationsAs") + "*"}
                    value={values.notification}
                    onChange={(e) => handleFormChange(index, e)}
                    fullWidth
                    sx={styles}
                    error={isSubmit && values.notification === ""}
                    helperText={
                      isSubmit &&
                      values.notification === "" &&
                      t("form.requiredFile")
                    }
                  >
                    <MenuItem disabled value={""}></MenuItem>
                    {notificationsMenuItem.map((notification, index) => (
                      <MenuItem key={index} value={index + 1}>
                        {notification}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {!menus && !!activeRol && (
                  <Box mx="auto" mt={3}>
                    <CircularProgress className={"colorLoading"} />
                  </Box>
                )}
                {values.modules.length > 0 && (
                  <Grid item xs={12} mb={3} mt={3}>
                    <Divider
                      sx={{
                        "&.MuiDivider-root": {
                          "&::before": {
                            width: "20px",
                          },
                        },
                      }}
                      textAlign="left"
                    >
                      {t("rolManagement.module")}
                    </Divider>
                  </Grid>
                )}

                {values.modules.map((module, indexModule) => (
                  <React.Fragment key={indexModule + "-" + module.idMenu}>
                    <Grid item md={4} lg={4} sm={6} xs={12}>
                      <FormControl
                        sx={{ width: "100%" }}
                        size="small"
                        error={
                          isSubmit &&
                          (module.idMenu === 0 || (module.idMenu as any) === "")
                        }
                      >
                        <InputLabel id="menu-label">Menu*</InputLabel>
                        <Select
                          disabled={
                            (getModulesFromMenu(
                              module.idMenu as any
                            )?.menuItem.find(
                              (item) => item.id === module.idModule
                            )?.state === State.INACTIVE ||
                              !!!menus?.data.find(
                                (menu) => menu.id === module.idMenu
                              )) &&
                            module.idMenu !== 0
                          }
                          labelId="menu-label"
                          name="idMenu"
                          size="small"
                          value={module.idMenu !== 0 ? module.idMenu : ""}
                          onChange={(e: any) =>
                            handleFormChange(index, e, indexModule)
                          }
                          fullWidth
                          sx={styles}
                          input={<OutlinedInput label="Menu" />}
                          MenuProps={MenuProps}
                        >
                          {menus &&
                            menus.data
                              .filter((menu) => {
                                const currentRol = formValues[index];
                                const indexesSelectedMenu: number[] = [];
                                currentRol.modules.forEach((item, index) => {
                                  if (menu.id === item.idMenu) {
                                    indexesSelectedMenu.push(index);
                                  }
                                });
                                const modulesMenu = getModulesFromMenu(
                                  menu.id as any
                                );
                                const unSelectModules =
                                  modulesMenu?.menuItem.filter((item) => {
                                    const { modules } = formValues[index];
                                    const findUsedModule = modules.find(
                                      (modul) => modul.idModule === item.id
                                    );
                                    return !!!findUsedModule;
                                  });

                                if (modulesMenu?.menuItem.length === 0) {
                                  return indexesSelectedMenu.length > 0
                                    ? indexesSelectedMenu.includes(
                                        indexModule
                                      ) && menu.state === State.ACTIVE
                                    : menu.state === State.ACTIVE;
                                }

                                if (indexesSelectedMenu.length >= 0) {
                                  return (
                                    indexesSelectedMenu.includes(indexModule) ||
                                    (unSelectModules &&
                                      unSelectModules.length > 0)
                                  );
                                }

                                return menu.state === State.ACTIVE;
                              })
                              .map((menu) => (
                                <MenuItem key={menu.id} value={menu.id}>
                                  {menu.name}
                                </MenuItem>
                              ))}
                          {!!!menus?.data.find(
                            (menu) => menu.id === module.idMenu
                          ) &&
                            module.idMenu !== 0 && (
                              <MenuItem
                                key={module.idMenu}
                                value={module.idMenu}
                              >
                                {getMenuActiveDetails(module.idMenu)?.menu.name}
                              </MenuItem>
                            )}
                        </Select>
                      </FormControl>
                      <FormHelperText>
                        {isSubmit &&
                          isSubmit &&
                          (module.idMenu === 0 ||
                            (module.idMenu as any) === "") && (
                            <label
                              className="ml-3"
                              style={{ color: "#d32f2f" }}
                            >
                              {t("form.requiredFile")}
                            </label>
                          )}
                      </FormHelperText>
                    </Grid>
                    <Grid item md={4} lg={4} sm={6} xs={12}>
                      <FormControl
                        sx={{ width: "100%" }}
                        size="small"
                        error={
                          //@ts-ignore
                          !getModulesFromMenu(module.idMenu as any)?.menuItem
                            .length > 0
                            ? false
                            : isSubmit
                            ? module.idModule === 0 ||
                              (module.idModule as any) === "" ||
                              isAvailableModule(
                                index,
                                module.idModule as number
                              )
                            : (module.idModule as any) !== "" &&
                              isAvailableModule(
                                index,
                                module.idModule as number
                              )
                        }
                      >
                        <InputLabel id="module-label">
                          {t("rolManagement.module") + "*"}
                        </InputLabel>
                        <Select
                          labelId="module-label"
                          name="idModule"
                          size="small"
                          value={module.idModule !== 0 ? module.idModule : ""}
                          onChange={(e: any) =>
                            handleFormChange(index, e, indexModule)
                          }
                          disabled={
                            //@ts-ignore
                            (!getModulesFromMenu(module.idMenu as any)?.menuItem
                              .length > 0 ||
                              //@ts-ignore
                              getModulesFromMenu(
                                module.idMenu as any
                              )?.menuItem.find(
                                (item) => item.id === module.idModule
                              )?.state === State.INACTIVE ||
                              !!!menus?.data.find(
                                (menu) => menu.id === module.idMenu
                              )) &&
                            module.idModule !== 0
                          }
                          fullWidth
                          sx={styles}
                          input={
                            <OutlinedInput label={t("rolManagement.module")} />
                          }
                          MenuProps={MenuProps}
                        >
                          {getModulesFromMenu(module.idMenu as any)
                            ?.menuItem.filter((item) => {
                              const currentRol = formValues[index];
                              const indexSelectedModule =
                                currentRol.modules.findIndex(
                                  (modul) => modul.idModule === item.id
                                );
                              if (indexSelectedModule >= 0) {
                                const showInactiveModule =
                                  activeRol && item.state === State.INACTIVE
                                    ? true
                                    : item.state === State.ACTIVE;
                                return (
                                  indexSelectedModule === indexModule &&
                                  showInactiveModule
                                );
                              }
                              return item.state === State.ACTIVE;
                            })
                            .map((module) => (
                              <MenuItem key={module.id} value={module.id}>
                                {module.name}
                              </MenuItem>
                            ))}

                          {!!!menus?.data.find(
                            (menu) => menu.id === module.idMenu
                          ) &&
                            module.idModule !== 0 && (
                              <MenuItem
                                key={module.idModule}
                                value={module.idModule}
                              >
                                {
                                  getMenuItemActiveDetails(
                                    module.idModule as any
                                  )?.menuItem.name
                                }
                              </MenuItem>
                            )}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={4} lg={4} sm={6} xs={12} display="flex">
                      <FormControl
                        sx={{ width: "100%" }}
                        size="small"
                        error={
                          Object.entries(module.permissions)
                            .filter(([key, value]) => (value as any) === true)
                            .map(([key, value]) => {
                              return t(`rolManagement.${key}`);
                            }).length < 1 && isSubmit
                        }
                      >
                        <InputLabel id="permissions-label">
                          {t("rolManagement.permissions") + "*"}
                        </InputLabel>
                        <Select
                          disabled={
                            !!!menus?.data.find(
                              (menu) => menu.id === module.idMenu
                            )
                          }
                          id="checklist"
                          multiple
                          value={Object.entries(module.permissions)
                            .filter(([key, value]) => (value as any) === true)
                            .map(([key, value]) => {
                              return t(`rolManagement.${key}`);
                            })}
                          //onChange={handleChangePermissions}
                          input={
                            <OutlinedInput
                              label={t("rolManagement.permissions")}
                            />
                          }
                          renderValue={(selected) => selected.join(", ")}
                        >
                          {Object.entries(module.permissions).map(
                            // eslint-disable-next-line array-callback-return
                            ([name, value], indexPermission) => {
                              const moduleDetails = getModulesFromMenu(
                                module.idMenu as any
                              )?.menuItem.find(
                                (item) => item.id === module.idModule
                              );

                              if (name !== "id") {
                                if (
                                  moduleDetails?.url === "/management-users"
                                ) {
                                  if (
                                    t(`rolManagement.${name}`) !==
                                    t(`rolManagement.delete`)
                                  ) {
                                    return (
                                      <MenuItem
                                        key={indexPermission}
                                        className="check-list-item"
                                        value={name}
                                        onClick={() =>
                                          handleCheckPermission(
                                            index,
                                            indexModule,
                                            name
                                          )
                                        }
                                      >
                                        <Checkbox
                                          checked={value}
                                          sx={{
                                            color: "#DADADA",
                                            "&.Mui-checked": {
                                              color: "#02D0E7",
                                            },
                                          }}
                                        />
                                        <ListItemText
                                          primary={t(`rolManagement.${name}`)}
                                        />
                                      </MenuItem>
                                    );
                                  }
                                } else {
                                  return (
                                    <MenuItem
                                      key={indexPermission}
                                      className="check-list-item"
                                      value={name}
                                      onClick={() =>
                                        handleCheckPermission(
                                          index,
                                          indexModule,
                                          name
                                        )
                                      }
                                    >
                                      <Checkbox
                                        checked={value}
                                        sx={{
                                          color: "#DADADA",
                                          "&.Mui-checked": {
                                            color: "#02D0E7",
                                          },
                                        }}
                                      />
                                      <ListItemText
                                        primary={t(`rolManagement.${name}`)}
                                      />
                                    </MenuItem>
                                  );
                                }
                              }
                            }
                          )}
                        </Select>
                      </FormControl>

                      <Tooltip
                        title={
                          !!!menus?.data.find(
                            (menu) => menu.id === module.idMenu
                          ) &&
                          module.idMenu !== 0 &&
                          module.idModule !== 0
                            ? (t("alerts.onlyActionSuperAdmin") as string)
                            : (t("rolManagement.deleteteModule") as string)
                        }
                      >
                        <IconButton
                          onClick={(e) => {
                            const moduleFromSuper =
                              !!!menus?.data.find(
                                (menu) => menu.id === module.idMenu
                              ) &&
                              module.idMenu !== 0 &&
                              module.idModule !== 0;
                            if (moduleFromSuper) {
                              return e.preventDefault();
                            }
                            setBtnAction("Delete");
                            setOpenDialog(true);
                            setIndexDelete({
                              indexRol: index,
                              indexModule,
                            });
                          }}
                          style={{ border: "none", outline: "none" }}
                        >
                          <i
                            className="fa-regular fa-trash-can"
                            style={
                              !!!menus?.data.find(
                                (menu) => menu.id === module.idMenu
                              ) &&
                              module.idMenu !== 0 &&
                              module.idModule !== 0
                                ? { color: "#d3d3d3", fontSize: 20 }
                                : { color: "#E62E1B", fontSize: 20 }
                            }
                          ></i>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
              {menus && (
                <Box
                  mt={2}
                  className="boxAddModule"
                  onClick={(e) => handleAddModule(index)}
                >
                  <Typography fontSize={12} color="black">
                    {t("menuManagment.addModule")}
                  </Typography>
                </Box>
              )}
            </div>
          </form>
        ))}
        {!activeRol && (
          <Box mt={4} className="boxAddRol" onClick={handleAddRol}>
            <Typography fontSize={12} color="black">
              {t("rolManagement.addNewRol")}
            </Typography>
          </Box>
        )}
      </div>

      <MyActions
        handleCancel={handleCancel}
        handleAccept={handleValidateFields}
        handleClean={handleCleanForm}
        isLoading={isLoading || (!menus && !!activeRol)}
        disabled={!!activeRol}
        titleAccept={!!activeRol ? "button.edit" : "button.save"}
      />

      <MyDialog
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message={
          btnAction === "Cancel"
            ? t("dialog.sureClean")
            : btnAction === "Delete"
            ? `${t("menuManagment.deleteModuleAsk")} ${
                indexDelete.indexRol !== null
                  ? formValues[indexDelete.indexRol].name
                  : ""
              }?`
            : btnAction === "DeleteRole"
            ? t("menuManagment.deleteRoleAsk")
            : btnAction === "Edit"
            ? t("dialog.sureApplyChanges")
            : btnAction === "Save"
            ? t("managementRoles.sureCreateRole")
            : t("dialog.sureGoBack")
        }
        handleAccept={() => {
          const { indexModule, indexRol } = indexDelete;
          if (
            btnAction === "Delete" &&
            indexModule !== null &&
            indexRol !== null
          ) {
            handleDeleteModule(indexRol, indexModule);
          }
          if (btnAction === "DeleteRole" && indexRol !== null) {
            handleDeleteRole(indexRol);
          }
          if (btnAction === "Edit" || btnAction === "Save") {
            handleSave();
          }
          if (btnAction === "GoBack") {
            dispatch(setActiveRol(null));
            dispatch(
              setFlagForm({
                flagFormCreate: false,
              })
            );
          }
          if (btnAction === "Cancel") {
            handleResetForm();
          }
        }}
        handleClose={handleCloseDialog}
        isLoading={isLoading}
      />
      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />
    </Box>
  );
};
