import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { headersAwardsPayReport } from "../../../helpers/headersTables";
import { getCopyArrayOrObject } from "../../../utils/utils";
import moment from "moment";

export const AwardsPaysReportDataTable = (totalData: any[]) => {
    const { data } = useSelector((state: AppState) => state.search);

    const headersCvsStyle = headersAwardsPayReport.map((header) => {
      return {
        title: header.label,
        width: { wpx: 120 },
        style: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
          alignment: { vertical: "center", horizontal: "center" },
        },
      };
    });
    // Obtener datos por vista
    const dataBets =
      data && data.data ? getCopyArrayOrObject(data.data) : [];
    // Obtener total datos
    const dataTotalBets = totalData ? getCopyArrayOrObject(totalData) : [];

    const buildTotalData = (arr: any) => {
        const multiData = [
          {
            columns: headersCvsStyle,
            data: arr.map((data: any) => {
              const array = [
                {
                  value: data.transactionId,
                  style: { alignment: { horizontal: "center" } },
                },
                {
                  value: moment(data.createAt).format("DD/MM/YYYY"),
                  style: { alignment: { horizontal: "center" } },
                },
                {
                  value: moment(data.createAt).format("HH:mm:ss A"),
                  style: { alignment: { horizontal: "center" } },
                },
                {
                  value: data.amount,
                  style: { alignment: { horizontal: "center" } },
                },
                {
                  value: data.player.email,
                  style: { alignment: { horizontal: "center" } },
                },
              ];
              return array;
            }),
          },
        ];
        return multiData;
      };
    
      const multiTotalData = buildTotalData(dataTotalBets);
      const multiData = buildTotalData(dataBets);
      const finalData = {
        dataPerView: dataBets,
        dataTotal: dataTotalBets,
        headersCSV: headersAwardsPayReport,
        multiDataSet: multiData,
        multiTotalDataSet: multiTotalData,
      };
      return finalData;
}