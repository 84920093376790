import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IrrigationAdminInfoCards } from "../../../components/transactions/irrigation/admin/IrrigationAdminInfoCards";
import { AssociateAdminInfo } from "../../../components/transactions/irrigation/admin/AssociateAdminInfo";
import { useDispatch } from "react-redux";
import { setActiveAssociate } from "../../../../redux/actions/associatesActions";
import { arraySearch } from "../../../../redux/actions/searchActions";
import { useFormikContext } from "formik";
import { GenerateTransferAdmin } from "../../../components/transactions/irrigation/admin/GenerateTransferAdmin";
import { IrrigationAdminHistory } from "../../../components/transactions/irrigation/admin/IrirgationAdminHistory";

export const IrrigationAdmin = () => {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState<boolean>(false);
  const [resetInfo, setResetInfo] = useState(false);
  const [resetGenerate, setResetGenerate] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(setActiveAssociate(null));
      dispatch(arraySearch(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AutoReset = () => {
    const { resetForm } = useFormikContext();
    useEffect(() => {
      if (resetInfo) {
        resetForm();
      }
      setResetInfo(false);
      setResetGenerate(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetInfo]);
    return null;
  };
  return (
    <div className="content w-100 mb-4">
      <Box sx={{ ml: 3, mt: 2 }} width={1}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <IrrigationAdminInfoCards />
          </Grid>
          <Grid item xs={12} md={6}>
            <AssociateAdminInfo
              expand={expand}
              setExpand={setExpand}
              setResetInfo={setResetInfo}
              AutoReset={AutoReset}
              setResetGenerate={setResetGenerate}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GenerateTransferAdmin
              expand={expand}
              setExpand={setExpand}
              setResetInfo={setResetInfo}
              resetInfo={resetInfo}
              setResetGenerate={setResetGenerate}
              resetGenerate={resetGenerate}
            />
          </Grid>
          <Grid item xs={12}>
            <IrrigationAdminHistory />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
