import { FC, Fragment } from "react";
import ToggleGroup from "../Casino/ToggleGroup";

const AffiliationCommissionFTDManagement: FC<{
  setShowForm: any;
  setValue: any;
}> = ({ setShowForm, setValue }) => {
  return (
    <Fragment>
      <div className="commission-management-container">
        <ToggleGroup
          title="Comisión punto de venta"
          editAction={() => {
            setShowForm(true);
            setValue("affiliationCommissionFTD");
          }}
          isFirst={true}
          toggle={false}
        />
      </div>
    </Fragment>
  );
};

export default AffiliationCommissionFTDManagement;
