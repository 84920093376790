import { AppState } from "../../../redux/reducers/rootReducer";
import { useSelector } from "react-redux";
import { headersGameRevenue } from "../../../helpers/headersTables";
import { getCopyArrayOrObject } from "../../../utils/utils";
import { IGameRevenueData } from "../../../interfaces/reports";

export const GameRevenueDataTable = (totalData: IGameRevenueData[]) => {
  const { data } = useSelector((state: AppState) => state.search);

  const headersCvsStyle = headersGameRevenue.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  // Obtener datos por vista
  const dataActivityPlayer =
    data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalActivityPlayer = totalData
    ? getCopyArrayOrObject(totalData)
    : [];
  //   DATA TOTAL
  const multiTotalDataPlayerActivity = [
    {
      columns: headersCvsStyle,
      data: dataTotalActivityPlayer.map((data: any) => {
        let array;
        array = [
          {
            value: currencyFormatter.format(data.totalAmountDeposits),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: currencyFormatter.format(data.totalWithdrawals),
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: currencyFormatter.format(data.totalFundsAvailable),
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];
  const multiDataPlayerActivity = [
    {
      columns: headersCvsStyle,
      data: dataActivityPlayer.map((data: any) => {
        let array;
        array = [
          {
            value: currencyFormatter.format(data.totalAmountDeposits),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: currencyFormatter.format(data.totalWithdrawals),
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: currencyFormatter.format(data.totalFundsAvailable),
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];
  const finalData = {
    dataPerView: dataActivityPlayer,
    dataTotal: dataTotalActivityPlayer,
    headersCSV: headersGameRevenue,
    multiDataSet: multiDataPlayerActivity,
    multiTotalDataSet: multiTotalDataPlayerActivity,
  };
  return finalData;
};
