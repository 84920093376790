import { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  startGetSegmentations,
  startSendMessages,
} from "../../../redux/actions/segmentations";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Form, Formik, FormikHelpers } from "formik";
import { MyTextField } from "../../components/custom/MyTextField";
import { MyDialog } from "../../components/custom/MyDialog";
import { MyAlert } from "../../components/custom/MyAlert";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";

export const BulkMessages = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [segmentationSelected, setSegmentationSelected] = useState(-1);
  const { data } = useSelector((state: AppState) => state.segmentations);
  const [formData, setFormData] = useState({
    message: "",
    segmentationId: -1,
    isWithTemplate: false,
  });
  const [openDialog, setOpenDialog] = useState(false);

  const { openAlert, messageAlert, typeAlert } = useSelector(
    (state: AppState) => state.helper
  );

  const initialValues = {
    message: "",
  };

  const validationSchema = yup.object({
    message: yup
      .string()
      .min(
        10,
        t("bulkMessages.validationMinLength", {
          min: 10,
        })
      )
      .max(
        160,
        t("bulkMessages.validationMaxLength", {
          max: 160,
        })
      )
      .required(t("bulkMessages.validationRequired")),
  });

  useEffect(() => {
    dispatch(
      startGetSegmentations({
        page: 1,
        perPage: 100,
        createAt: "true",
        sortBy: "desc",
      })
    );
  }, [dispatch]);

  const handleSendMessage = () => {
    // Send message
    dispatch(startSendMessages(formData));
    setOpenDialog(false);
  };

  const handleOnSubmit = (
    data: {
      message: string;
    },
    formikHelpers: FormikHelpers<{
      message: string;
    }>
  ) => {
    formikHelpers.setSubmitting(true);
    if (segmentationSelected === -1) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert(t("bulkMessages.validationMessageType")));
      dispatch(setTypeAlert("error"));
      formikHelpers.setSubmitting(false);
      return;
    }
    setFormData({
      message: data.message,
      isWithTemplate: false,
      segmentationId: segmentationSelected,
    });
    setOpenDialog(true);
    formikHelpers.setSubmitting(false);
  };

  return (
    <div className="content w-100 mb-5">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyBreadCrumbs
            titles={[t("sidebar.settings"), t("bulkMessages.title")]}
          />
        </Grid>
        <div className="white-container mt-2 w-100">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={true}
            onSubmit={handleOnSubmit}
          >
            {({ isSubmitting, handleChange }) => (
              <Form>
                <Grid item xs={12} className="mb-4">
                  <Box
                    display="flex"
                    className="pb-1"
                    borderBottom={1}
                    borderColor="#02D0E7"
                  >
                    <i
                      className="fa-solid fa-circle-info"
                      style={{
                        color: "#02D0E7",
                        marginRight: 9,
                        fontSize: 18,
                      }}
                    ></i>
                    <p
                      className="txt-primary-md"
                      style={{ fontWeight: 600, fontSize: 15 }}
                    >
                      {t("bulkMessages.subTitleMessageType")}
                    </p>
                  </Box>
                  <div>
                    {data.map((segmentation: any, index: number) => {
                      return (
                        <Button
                          key={index}
                          sx={{
                            backgroundColor:
                              segmentation.id === segmentationSelected
                                ? "#19b543"
                                : "#fcfce6",
                            padding: "20px",
                            color:
                              segmentation.id === segmentationSelected
                                ? "white"
                                : "black",
                            margin: 1,
                            textTransform: "none",
                            "& span": {
                              textTransform: "lowercase",
                            },
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            fontWeight: "bold",
                            border: 0,
                          }}
                          onClick={() =>
                            setSegmentationSelected(segmentation.id)
                          }
                        >
                          {segmentation.name}
                        </Button>
                      );
                    })}
                  </div>
                </Grid>
                <Grid item xs={12} className="mb-4">
                  <Box
                    display="flex"
                    className="pb-1"
                    borderBottom={1}
                    borderColor="#02D0E7"
                  >
                    <i
                      className="fa-solid fa-circle-info"
                      style={{
                        color: "#02D0E7",
                        marginRight: 9,
                        fontSize: 18,
                      }}
                    ></i>
                    <p
                      className="txt-primary-md"
                      style={{ fontWeight: 600, fontSize: 15 }}
                    >
                      {t("bulkMessages.subTitleBody")}
                    </p>
                  </Box>
                  <MyTextField
                    name="message"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    sx={{
                      mt: 1,
                    }}
                    onChange={handleChange}
                    disabled={isSubmitting}
                  ></MyTextField>
                </Grid>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="right"
                  mt={5}
                  mb={2}
                >
                  <Button
                    type="submit"
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                    variant="contained"
                    id="btnSave"
                    className="primary-btn ripple"
                  >
                    {t("bulkMessages.buttonSendLabel")}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Grid>
      <MyDialog
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message={t("bulkMessages.confirmationMessage")}
        handleAccept={handleSendMessage}
        handleClose={() => setOpenDialog(false)}
      />
      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
      />
    </div>
  );
};
