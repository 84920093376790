import { FC } from "react";
import TableCell from "@mui/material/TableCell";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Roles_Enum } from "../../../enums/enums";

interface Props {
  row: any;
  param:
    | "paramPaymentMethods"
    | "paramGames"
    | "paramSolutions"
    | "bonusTable"
    | "percentageWithdrawalTable";
}

export const RowDataTableParamSettings: FC<Props> = ({ row, param }) => {
  const { user } = useSelector((state: AppState) => state.auth);

  const methods = row.paymentMethods
    ? row.paymentMethods.filter(
        (pay: any) => pay.type !== "withdrawal" && pay.state && !pay.country
      )
    : [];
  return (
    <>
      {param === "bonusTable" ? (
        <>
          <TableCell className="word-break">
            {row.id}

            {user?.roles.name === Roles_Enum.SUPER_ADMIN &&
              row.operator &&
              ` - ${row.operator?.name.toUpperCase()}`}
          </TableCell>
          <TableCell className="word-break">{row.name}</TableCell>
        </>
      ) : (
        <>
          {param === "percentageWithdrawalTable" ? (
            <>
              <TableCell className="word-break">
                {row.id}

                {user?.roles.name === Roles_Enum.SUPER_ADMIN &&
                  row.operator &&
                  ` - ${row.operator?.name.toUpperCase()}`}
              </TableCell>
              <TableCell className="word-break">{row.percentage}</TableCell>
            </>
          ) : (
            <>
              <TableCell className="word-break">
                {row.country?.name.toUpperCase()}{" "}
                {user?.roles.name === Roles_Enum.SUPER_ADMIN &&
                  ` - ${row.operator?.name.toUpperCase()}`}
              </TableCell>
              <TableCell className="word-break">
                <div>
                  {param === "paramPaymentMethods" &&
                    methods &&
                    methods.length > 0 &&
                    methods.map((method: any, index: number) => (
                      <label key={`${method.id}-${index}`}>
                        {method.name} &nbsp;
                        {methods.length - 1 !== index && "-"} &nbsp;
                      </label>
                    ))}
                  {param === "paramGames" &&
                    row.games &&
                    row.games.map((game: any, index: number) => (
                      <label key={`${game.id}-${index}`}>
                        {game.name} &nbsp;
                        {row.games.length - 1 !== index && "-"} &nbsp;
                      </label>
                    ))}
                  {param === "paramSolutions" &&
                    row.solutions &&
                    row.solutions.map((solution: any, index: number) => (
                      <label key={`${solution.id}-${index}`}>
                        {solution.name} &nbsp;
                        {row.solutions.length - 1 !== index && "-"} &nbsp;
                      </label>
                    ))}
                </div>
              </TableCell>
            </>
          )}
        </>
      )}
    </>
  );
};
