import { IUserData, IUserDB } from "../interfaces/users";
import { Types } from "../types/types";

interface IManagementUsersActions {
  type: Types;
  payload?: IUserDB | IUserData | any;
}

interface IUserDataState {
  users: IUserDB | null;
  totalUsers: IUserDB | null;
  activeUser: IUserData | null;
}

const initialState: IUserDataState = {
  users: null,
  totalUsers: null,
  activeUser: null,
};
export const managementUserReducer = (
  state = initialState,
  action: IManagementUsersActions
): IUserDataState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setUsers:
      return {
        ...state,
        users: payload as IUserDB,
      };
    case Types.setTotalUsers:
      return {
        ...state,
        totalUsers: payload as IUserDB,
      };
    case Types.addUser:
      const newUsers = state.users
        ? ({
            ...state.users,
            data: [payload.user, ...state.users.data],
            total: state.users.total + 1,
            last_page: Math.ceil((state.users.total + 1) / payload.rowsPerPage),
          } as IUserDB)
        : state.users;
      return {
        ...state,
        users: newUsers,
      };

    case Types.setActiveUser:
      return {
        ...state,
        activeUser: payload as IUserData,
      };

    case Types.updateUser:
      const usersUpdated = state.users
        ? ({
            ...state.users,
            data: state.users.data.map((row) =>
              row.id === (payload as IUserData).id
                ? (payload as IUserData)
                : row
            ),
          } as IUserDB)
        : state.users;
      return {
        ...state,
        users: usersUpdated,
      };

    default:
      return state;
  }
};
