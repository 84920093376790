import { IPaginationProps } from "../interfaces/shared";

export interface IAuditQuery extends IPaginationProps {
  createdBy?: number;
  startDate: string;
  endDate: string;
  playerAffected?: number;
  operatorId?: number;
}
export const getLimitAudit = (token: string, params: Partial<IAuditQuery>) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";
    
  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}limitsAudit?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
