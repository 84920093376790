import React from "react";
import { Box, Grid } from "@mui/material";
import { PointSaleInfo } from "../../components/transactions/irrigation/PointSaleInfo";
import { GenerateTransferPv } from "../../components/transactions/irrigation/GenerateTransferPv";
import { IrrigationPvHistory } from "../../components/transactions/irrigation/IrrigationPvHistory";

export const IrrigationPv = () => {
  return (
    <div className="content w-100 mb-4">
      <Box sx={{ ml: 3, mt: 2 }} width={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <PointSaleInfo />
          </Grid>
         
          <Grid item xs={12} md={6}>
            <GenerateTransferPv />
          </Grid>
          <Grid item xs={12}>
            <IrrigationPvHistory/>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
