import React, { useEffect } from "react";
import { IrrigationAdminInfoCards } from "../../../components/transactions/irrigation/admin/IrrigationAdminInfoCards";
import { Grid } from "@mui/material";
import { DealerInfoCards } from "./DealerInfoCards";
import { useDispatch } from "react-redux";
import {
  setActiveLot,
  setIrrigatedDetail,
} from "../../../../redux/actions/managementCardsActions";
import { GenerateTransferAdmin } from "../../../components/transactions/irrigation/admin/GenerateTransferAdmin";
import { IrrigationAdminHistory } from "../../../components/transactions/irrigation/admin/IrirgationAdminHistory";
import { CurrentPlaceCards } from "../../../../enums/enums";

export const IrrigationCardsToDealer = () => {
  const dispatch = useDispatch();
  const resetIrrigationInfo = () => {
    dispatch(setIrrigatedDetail(null));
    dispatch(setActiveLot(null));
  };
  useEffect(() => {
    return () => {
      resetIrrigationInfo();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content w-100 mb-4">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <IrrigationAdminInfoCards />
        </Grid>
        <Grid item xs={6}>
          <DealerInfoCards />
        </Grid>
        <Grid item xs={6}>
          <GenerateTransferAdmin
            expand={true}
            setExpand={() => {}}
            setResetInfo={() => {}}
            resetInfo={false}
            setResetGenerate={() => {}}
            resetGenerate={false}
          />
        </Grid>
        <Grid item xs={12}>
          <IrrigationAdminHistory to={CurrentPlaceCards.DEALER} />
        </Grid>
      </Grid>
    </div>
  );
};
