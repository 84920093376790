import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";

export const DealerWalletDetail = () => {
  const { dealer: dealerWallet } = useSelector(
    (state: AppState) => state.wallet
  );
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <label className="font-weight-bold">
          {t("associates.refillBalance").toUpperCase()}
        </label>
        <Box className="no-overflow">{dealerWallet?.refillsBalance}</Box>
      </Grid>
      <Grid item xs={6}>
        <label className="font-weight-bold">
          {t("irrigation.bettingBalance").toUpperCase()}
        </label>
        <Box className="no-overflow">{dealerWallet?.betsBalance}</Box>
      </Grid>
    </Grid>
  );
};
