import { FC } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";

import { IExclusion } from "../../../interfaces/exclusions";
import moment from "moment";
interface Props {
  row: IExclusion;
}

export const RowDataTableExclusions: FC<Props> = ({ row }) => {
  const { t } = useTranslation();

  return (
    <>
      <TableCell className="word-break">
        {moment(row.startDate).utc().format("DD/MM/YYYY - hh:mm:ss A")}
      </TableCell>
      <TableCell className="word-break">
        {row.totalExclusion
          ? "N/A"
          : moment(row.endDate).utc().format("DD/MM/YYYY - hh:mm:ss A") || ""}
      </TableCell>
      <TableCell className="word-break">
        {/* @ts-ignore */}
        {row.totalExclusion ? "N/A" : row.category.name || ""}
      </TableCell>
      <TableCell className="word-break">
        {row.totalExclusion ? t("user.yes").toUpperCase() : "N/A"}
      </TableCell>
    </>
  );
};
