import React, { FC } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Text, View } from "@react-pdf/renderer";

import { headersWithdrawalsBank } from "../../../helpers/headersTables";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import { IWithdrawalData } from "../../../interfaces/withdrawals";
import { WhiteListOperators } from "../../../enums/enums";
import { buildFullName } from "../../../utils/utils";

interface props {
  page: IWithdrawalData[];
}
export const WithdrawalBankPdfDocument: FC<props> = ({ page }) => {
  const { t } = useTranslation();
  const columns = headersWithdrawalsBank;
  //change label if comes from approbal or pending tab

  const isApproval = page.find((item) => item.approvalDate);

  if (isApproval) {
    columns[columns.length - 1] = {
      label: t("withdrawals.approvedDate").toUpperCase(),
    };
  }

  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, index) => (
          <View key={index} style={[stylesPdf.pCol, stylesPdf.col]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>

      <View style={{ flex: 1 }}>
        {page.map((data) => {
          const percentage =
            data.player.operator?.name?.toUpperCase() ===
            WhiteListOperators.BETPPRO
              ? 0
              : 5.5;
          return (
            <View key={data.id} style={stylesPdf.row}>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>{data.id}</Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>{data.player.id}</Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {data.player.typeDocument.name}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {data.player.numberDocument}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {buildFullName(
                    data.player.firstName,
                    data.player.secondName,
                    data.player.lastName,
                    data.player.secondLastName
                  )}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {data.player.email}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {data.playerBankAccounts?.bank?.name
                    ? data.playerBankAccounts.bank.name
                    : ""}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {data.playerBankAccounts?.accountNumber
                    ? `${data.playerBankAccounts.accountNumber} - ${t(
                        data.playerBankAccounts?.accountType === 1
                          ? "associates.savings"
                          : "associates.current"
                      )}`
                    : ""}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {parseFloat(data.taxValue) === 0
                    ? parseFloat(data.taxValue).toFixed(1)
                    : percentage.toFixed(1)}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {parseFloat(data.taxValue).toFixed(2)}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {data.withdrawalValue}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>{data.paidValue}</Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {t(
                    data.playerBankAccounts?.verified
                      ? "table.yes"
                      : "table.not"
                  )}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {moment(data.approvalDate || data.createAt).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </>
  );
};
