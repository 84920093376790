import React, { useState } from "react";
import { Box } from "@mui/material";
import Filter from "../../../../components/Filter/Filter";
import { CustomAlert } from "../../../../components/Alerts/CustomAlert";
import { useTranslation } from "react-i18next";
import MyCustomTable, {
  IColumns,
} from "../../../../components/Custom/Table/MyCustomTable";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { asyncLoadingFunction } from "../../../../redux/actions/helperActions";
import { startGetIrrigationsDealer } from "../../../../redux/actions/transactionsActions";
import { RowIrrigationDealer } from "./RowIrrigationDealer";

import moment from "moment";
import { getIrrigationsDealer } from "../../../../services/irrigations";
import { IrrigationWalletDataTable } from "../../../../hooks/useDataTable/irrigation/IrrigationWalletDataTable";
import { IrrigationWalletPdf } from "../../../../components/Pdf/Irrigation/IrrigationPvPdf";

export const IrrigationDealerHistory = () => {
  const [dataFilter, setDataFilter] = useState({});

  const { token } = useSelector((state: AppState) => state.auth);
  const { data } = useSelector((state: AppState) => state.search);
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);
  const { dealer } = useSelector((state: AppState) => state.wallet);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterFields: IFilterField[] = [
    {
      label: t("form.value"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
  ];
  const columns: IColumns[] = [
    {
      align: "center",
      label: "ID",
    },
    {
      align: "center",
      label: t("transactions.transactionType"),
    },
    {
      align: "center",
      label: t("transactions.wallet"),
    },
    {
      align: "center",
      label: t("transactions.valueTransaction"),
    },
    {
      align: "center",
      label: t("playerActivity.balance"),
    },
    {
      align: "center",
      label: t("roles.ASSOCIATE"),
    },
    {
      align: "center",
      label: t("user.createAt"),
    },
  ];

  const handleFilter = async (formValues: any) => {
    if (!dealer) {
      return false;
    }
    const { id } = dealer;
    const params = {
      dealer: id,
      amount: formValues[t("form.value")],
      startDate: formValues[t("filters.startDate")],
      endDate: formValues[t("filters.endDate")],
      page: 1,
    };
    await dispatch(
      asyncLoadingFunction(
        async () => await dispatch(startGetIrrigationsDealer({ ...params }))
      )
    );
    setDataFilter(params);
  };
  const onCleanFilter = async () => {
    if (!dealer) {
      return false;
    }
    const { id } = dealer;
    const params = {
      dealer: id,
      page: 1,
    };
    await dispatch(
      asyncLoadingFunction(
        async () => await dispatch(startGetIrrigationsDealer({ ...params }))
      )
    );
    setDataFilter({});
  };
  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(
        async () =>
          await dispatch(
            startGetIrrigationsDealer({
              ...dataFilter,
              page,
              perPage: rows || rowsPerPage,
            })
          )
      )
    );
  };
  const getAllIrrigationPv = async (props: any) => {
    if (!dealer || !token) {
      return false;
    }
    const { id } = dealer;
    const params = {
      dealer: id,
      page: 1,
    };
    return await getIrrigationsDealer(token, params);
  };
  return (
    <Box className="filter" p={2} sx={{ minHeight: "300px" }}>
      <Box sx={{ border: "1px solid #DADADA", borderRadius: "5px" }} p={1}>
        <Box my={1}>
          <Filter
            fields={filterFields}
            handleFilter={handleFilter}
            onClean={onCleanFilter}
            cleanFilter={!data}
          />
        </Box>

        {!data && (
          <CustomAlert htmlText={t("irrigation.filterText")} type="info" />
        )}
      </Box>
      {data && data.total > 0 && (
        <Box mt={2}>
          <MyCustomTable
            columns={columns}
            data={data.data}
            lastPage={data.last_page}
            totalData={data.total}
            onPageChange={handlePageChange}
            renderBody={(item) => <RowIrrigationDealer row={item} />}
            exportData
            hookBuildTable={IrrigationWalletDataTable}
            getAllDataToExport={getAllIrrigationPv}
            pdfComponent={IrrigationWalletPdf}
            titleCSV={`${t("transactions.irrigations")
              .split(" ")
              .join("-")}-${moment().toISOString()}`}
          />
        </Box>
      )}
    </Box>
  );
};
