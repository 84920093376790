import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import TableCell from "@mui/material/TableCell";
import { StateTransaction, TransferConcept } from "../../../../enums/enums";
import { IPaymentOrdersData } from "../../../../interfaces/players";
interface Props {
  row: IPaymentOrdersData;
}

export const RowDataTablePaymentOrders: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  return (
    <>
      <TableCell className="word-break">{row.value}</TableCell>
      <TableCell className="word-break">
        {row.paymentType === TransferConcept.CASH
          ? t("companyManagement.cash")
          : t("irrigation.bankTransfer")}
      </TableCell>

      {row.state === StateTransaction.Approved && (
        <>
          <TableCell className="word-break">{row.pointSale.name}</TableCell>
          <TableCell className="word-break">
            {moment(row.paymentDate).utc().format("YYYY-MM-DD HH:mm:ss")}
          </TableCell>
        </>
      )}
      <TableCell className="word-break">
        {moment(row.createAt).utc().format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
    </>
  );
};
