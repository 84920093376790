import React, { FC, useState } from "react";
import { Paper } from "@mui/material";
import { ChromePicker, ColorResult } from "react-color";

interface props {
  open: boolean;
  onChange(color: ColorResult): void;
  onColorButtonClick(): void;
  defaultColor?: string;
}
export const MyColorPicker: FC<props> = ({
  open,
  onChange,
  onColorButtonClick,
  defaultColor,
}) => {
  const [color, setColor] = useState(defaultColor || "#FFF");
  const handleColorButtonClick = () => {
    onColorButtonClick();
  };
  const handleColorChange = (color: ColorResult) => {
    onChange(color);
    setColor(color.hex);
  };
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Paper
        sx={{
          width: "40px",
          height: "40px",
          backgroundColor: color,
          cursor: "pointer",
          borderRadius:"10px"
        }}
        onClick={handleColorButtonClick}
      />
      {open && (
        <div
          style={{
            position: "absolute",
            top: "60px", // Agregar 20px de margen superior
            zIndex: 1,
          }}
        >
          <ChromePicker
            color={color}
            onChange={handleColorChange}
            disableAlpha
          />
        </div>
      )}
    </div>
  );
};
