import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

export const Request = () => {
  const { t } = useTranslation();
  return (
    <Row className="reports-container" style={{ margin: "0 1px 0 2px" }}>
      <i className="fa-solid fa-circle-question fa-xl question-icon" />
      <h3 className="title" style={{ marginBottom: 0, color: "#333333" }}>
        {t("request.title")}
      </h3>
      <Col md={12} className="d-flex justify-content-center">
        <h5 className="primary-text">{t("request.noRequest")}</h5>
      </Col>
    </Row>
  );
};
