import * as React from "react";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import circleUser from "../../../assets/svg/circle-user.svg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    borderRadius: "50px 0px",
    boxShadow: "0px 4px 4px rgba(2, 208, 231, 0.25)",
  },
}));

interface Props {
  open: boolean;
  handleStay: () => void;
}

export const TimeoutDialog: React.FC<Props> = ({ open, handleStay }) => {
  const { t } = useTranslation();
  return (
    <div>
      <BootstrapDialog
        fullWidth
        maxWidth="sm"
        aria-label="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {handleStay ? (
            <IconButton
              disableFocusRipple
              disableRipple
              className="hover-close"
              aria-label="close"
              onClick={handleStay}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                "&:focus": {
                  outline: "none !important",
                },
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center">
            <div className="text-center" style={{ fontSize: "20px" }}>
              {t("login.expireSession")}
              <br />
              <span className="font-weight-bold">
                {" "}
                {t("login.youStillThere")}
              </span>
            </div>
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "30px",
          }}
        >
          <Button
            type="button"
            style={{
              border: "none",
              outline: "none",
              backgroundColor: "#02D0E7",
              height: "50px",
              borderRadius: "25px",
              width: "300px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            variant="contained"
            onClick={handleStay}
          >
            <img src={circleUser} alt="Circle user" />
            <span style={{ fontSize: "20px" }}>
              {t("login.stay").toUpperCase()}
            </span>

            <div />
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
