import React, { FC, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IAssociateReportRow } from "../../../../interfaces/reports";
import { useTranslation } from "react-i18next";
import { MovementTypeComissionReport } from "../../../../enums/enums";
interface props {
  row: IAssociateReportRow;
}
export const RowAssociateReport: FC<props> = ({ row }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleCollapse = () => {
    setOpen(!open);
  };
  const movementType =
    row.movementType === "Premios"
      ? t("reports.associateAwards")
      : row.movementType;

  function validateValue(value: number): number {
    return value < 0 ? 0 : value;
  }
  return (
    <>
      <TableRow key={row.id} hover role="checkbox" tabIndex={-1}>
        {row.id === MovementTypeComissionReport.GGR ||
        row.id === MovementTypeComissionReport.Affiliations ? (
          <TableCell
            className="word-break"
            style={{
              display: "flex",
              alignItems: "left",
            }}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleCollapse}
              style={{ marginRight: "auto", outline: "none" }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>

            <span
              style={{
                flex: 1,
                textAlign: "center",
                paddingRight: "15%",
                paddingTop: "2%",
              }}
            >
              {row.movementType}
            </span>
          </TableCell>
        ) : (
          <TableCell className="word-break" align="center">
            {movementType}
          </TableCell>
        )}
        <TableCell className="word-break" align="center">
          {row.quantity}
        </TableCell>
        <TableCell className="word-break" align="center">
          {row.total}
        </TableCell>
        <TableCell className="word-break" align="center">
          {validateValue(row.commission)}
        </TableCell>
      </TableRow>
      {(row.id === MovementTypeComissionReport.GGR ||
        row.id === MovementTypeComissionReport.Affiliations) && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {t("reports.totalBets")}
                      </TableCell>
                      <TableCell align="center">
                        {t("reports.totalPrizes")}
                      </TableCell>
                      {row.id === MovementTypeComissionReport.Affiliations && (
                        <TableCell align="center">
                          {t("reports.totalAffiliates")}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">
                        {row.dataQuery?.total_bets || 0}
                      </TableCell>
                      <TableCell align="center">
                        {row.dataQuery?.total_prizes || 0}
                      </TableCell>
                      {row.id === MovementTypeComissionReport.Affiliations && (
                        <TableCell align="center">
                          {row.dataQuery?.totalAffiliates || 0}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
