import { socket } from "../../helpers/socket";
import { INotifications } from "../../interfaces/notifications";
import {
  checkHealtNotificationsBack,
  getNotificationsService,
  markNotificationAsReadService,
  softDeleteNotificationService,
  updateNotificationsService,
} from "../../services/notificationsService";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import { setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";

export const addNotificationAction = (notification: INotifications) => ({
  type: Types.addNotifications,
  payload: notification,
});

export const updateNotificationAction = (notification: INotifications) => ({
  type: Types.updateNotification,
  payload: notification,
});

export const deleteNotificationAction = (notification: INotifications) => ({
  type: Types.deleteNotification,
  payload: notification,
});
export const markAsReadNotificationRedux = (id: number) => ({
  type: Types.markAsReadNotification,
  payload: { id },
});

export const softDeleteNotificationRedux = (notification: INotifications) => ({
  type: Types.softDeleteNotification,
  payload: notification,
});

// Utilidad para manejar errores
const handleNotificationError = (dispatch: Function) => {
  dispatch(setMessageAlert("alerts.errorGetNotifications"));
  dispatch(setTypeAlert("error"));
  dispatch(setOpenAlert());
};

// Acciones asíncronas
export const fetchNotifications = () => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;

    if (token && user) {
      try {
        const healthCheckPromise = checkHealtNotificationsBack(token);
        const timeoutPromise = new Promise((resolve) =>
          setTimeout(resolve, 3000, { status: false })
        );

        const healthCheckResult = await Promise.race([
          healthCheckPromise,
          timeoutPromise,
        ]);

        if (healthCheckResult.status) {
          const response = await getNotificationsService(
            token,
            user.operator?.id
          );

          if (response && !response.error) {
            response.forEach((notification: INotifications) => {
              dispatch(addNotificationAction(notification));
            });
          } else {
            handleNotificationError(dispatch);
          }
        } else {
          console.warn("Health check failed or timed out.");
        }
      } catch (error) {
        handleNotificationError(dispatch);
      }
    }
  };
};

export const _updateNotification = (data: any) => {
  return async (dispatch: Function, getState: Function) => {
    const state = getState() as AppState;
    const { token } = state.auth;
    const { notifications } = state.notifications;

    if (token && notifications) {
      try {
        const response = await updateNotificationsService(data.id, data, token);

        if (response && !response.error) {
          notifications.forEach((notification: INotifications) => {
            dispatch(
              updateNotificationAction(
                notification.id === data.id
                  ? { ...notification, ...data }
                  : notification
              )
            );
          });
        } else {
          handleNotificationError(dispatch);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
};

// actions/notificationsActions.ts

export const markNotificationAsRead = (id: number | string) => {
  return async (dispatch: Function, getState: Function) => {
    const state = getState() as AppState;
    const { token } = state.auth;

    if (token) {
      try {
        const response = await markNotificationAsReadService(id, token);
        if (response && !response.error) {
          socket.emit("Admin/MarkNotificationAsRead", { notification: id });
          dispatch(markAsReadNotificationRedux(Number(id)));
        } else {
          handleNotificationError(dispatch);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
};

export const softDeleteNotification = (id: number | string) => {
  return async (dispatch: Function, getState: Function) => {
    const state = getState() as AppState;
    const { token } = state.auth;

    if (token) {
      try {
        const response = await softDeleteNotificationService(id, token);
        if (response && !response.error) {
          dispatch(softDeleteNotificationRedux({ ...response, state: 0 }));
        } else {
          handleNotificationError(dispatch);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
};
