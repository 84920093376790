import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  setActiveBonusType,
  starCreateBonusType,
  startUpdateBonusType,
} from "../../../redux/actions/bonusActions";
import { IBonusTypeBody } from "../../../interfaces/bonus";
import { Roles_Enum, State } from "../../../enums/enums";
import { uiSetOpenDialog } from "../../../redux/actions/uiAction";
import { getOperatorsService } from "../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../redux/actions/managementCompanyActions";
const ITEM_HEIGHT = 42;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const FormBonus = () => {
  const { activeBonusType } = useSelector((state: AppState) => state.bonus);
  const [isLoading, setIsLoading] = useState(false);
  const [bonusName, setBonusName] = useState(
    activeBonusType ? activeBonusType.name : ""
  );
  const { user, token } = useSelector((state: AppState) => state.auth);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const [operator, setOperator] = useState(isSuper ? null : user?.operator.id);
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    token && fetchOperators(token);
    return () => {
      dispatch(setActiveBonusType(null));
      dispatch(setCompaniesRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateBonus = async () => {
    const castedName = bonusName.trim();
    if (castedName.length > 0) {
      setIsLoading(true);
      const operatorId = operator;
      if (operatorId) {
        const bonusType: IBonusTypeBody = {
          name: castedName,
          operator: operatorId as number,
          state: State.ACTIVE,
        };
        if (activeBonusType) {
          //@ts-ignore
          delete bonusType.state;
          //update
          await dispatch(startUpdateBonusType(activeBonusType.id, bonusType));
        } else {
          //add
          await dispatch(starCreateBonusType(bonusType));
        }
        dispatch(uiSetOpenDialog());
      }

      setIsLoading(false);
    }
  };

  const handleChangeSelectOperator = (e: SelectChangeEvent<number>) => {
    setOperator(Number(e.target.value));
  };

  return (
    <Grid container spacing={2}>
      {isSuper && !activeBonusType && (
        <>
          {companies && (
            <Grid item xs={4}>
              <label className="ml-3">
                {t("companyManagement.operator") + "*"}
              </label>
              <FormControl
                sx={{ width: "100%" }}
                size="small"
                disabled={!!activeBonusType}
              >
                <Select
                  name="operator"
                  size="small"
                  fullWidth
                  onChange={handleChangeSelectOperator}
                  value={operator || 0}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  MenuProps={MenuProps}
                >
                  {companies?.data?.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </>
      )}
      <Grid item xs={4}>
        <label className="ml-3">
          {t("parameterization.enterBonusName") + "*"}
        </label>
        <TextField
          variant="outlined"
          size="small"
          label=" "
          InputLabelProps={{
            shrink: false,
          }}
          fullWidth
          value={bonusName}
          onChange={(e) => setBonusName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="flex-end" mt={20}>
        {isLoading ? (
          <CircularProgress className={"colorLoading"} />
        ) : (
          <Button
            style={{
              border: "none",
              outline: "none",
              fontSize: 14,
            }}
            variant="contained"
            className="btn-dialog primary-btn"
            onClick={handleCreateBonus}
          >
            {t("button.createParameters")}
            <i className="fa-solid fa-check-circle ml-2"></i>
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
