import { useTranslation } from "react-i18next";
import {
  Grid,
  Box,
  ThemeProvider,
  Button,
  CircularProgress,
} from "@mui/material";
import { theme } from "../custom/customStyles";
import { ICardsListCreate } from "../../../interfaces/cards";
import clearLogo from "../../../assets/svg/icon-park_clear-format.svg";
import moment from "moment";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
interface Props {
  handleCancel: () => void;
  handleSave: () => void;
  handleCleanForm: () => void;
  handlePrevious: () => void;
  lotData: Omit<ICardsListCreate, "id" | "operator" | "currency" | "dealers">;
  admin: number | null;
  loading: boolean;
}

interface ConfirmDataInfoProps {
  lotData: Omit<ICardsListCreate, "id" | "operator" | "currency" | "dealers">;
  admin: any;
}

const ConfirmDataInfo = ({ lotData, admin }: ConfirmDataInfoProps) => {
  const { data } = useSelector((state: AppState) => state.search);
  const { t } = useTranslation();
  const getAdminDetail = (id: number) => {
    const adminFind = data.find((item: any) => item.value === id);

    return adminFind;
  };

  return (
    <>
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          className="pb-1"
          borderBottom={1}
          borderColor="#02D0E7"
        >
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 10, fontSize: 20 }}
          ></i>
          <p
            className="txt-primary-md"
            style={{ fontWeight: 900, fontSize: "18px" }}
          >
            {t("cards.confirmAssociate", {
              associate: getAdminDetail(admin).label,
            }).toUpperCase()}
          </p>
        </Box>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ marginTop: "20px" }}
        className="lot-info"
      >
        <Grid item xs={12} sm={12} md={4}>
          <label>{t("cards.generateDate").toUpperCase()}</label>
          <span>{moment().format("DD-MMMM-YYYY")}</span>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <label>{t("cards.qtyCardDelivery").toUpperCase()}</label>
          <span>{lotData.amount}</span>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <label>{t("cards.valueCards").toUpperCase()}</label>
          <span>{`$ ${lotData?.value} USD`}</span>
        </Grid>
      </Grid>
    </>
  );
};

export const ConfirmLotCards = ({
  handleCancel,
  handleSave,
  handleCleanForm,
  handlePrevious,
  lotData,
  admin,
  loading,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="container mt-3">
      <ThemeProvider theme={theme}>
        <Grid container spacing={2}>
          <ConfirmDataInfo lotData={lotData} admin={admin} />
          <Grid
            item
            xs={12}
            mt={2}
            display="flex"
            justifyContent="center"
            sx={{ height: "60px" }}
          >
            {loading ? (
              <CircularProgress size={"1.6rem"} color="inherit" />
            ) : (
              <>
                <Button
                  style={{
                    border: "none",
                    outline: "none",
                  }}
                  variant="contained"
                  className="footer-btn primary-btn ripple"
                  onClick={handlePrevious}
                >
                  <i className="fa-solid fa-angle-left pl-2"></i>
                  {t("button.previous").toUpperCase()}
                </Button>
                <Button
                  style={{ border: "none", outline: "none" }}
                  variant="contained"
                  className="footer-btn danger-btn ripple"
                  onClick={handleCancel}
                >
                  <i className="fa-solid fa-x"></i>
                  {t("button.cancel").toUpperCase()}
                </Button>
                <Button
                  style={{
                    border: "none",
                    outline: "none",
                  }}
                  variant="contained"
                  className="footer-btn primary-btn ripple"
                  onClick={handleSave}
                >
                  {t("button.save").toUpperCase()}
                </Button>
                <Button
                  style={{
                    border: "none",
                    outline: "none",
                    marginLeft: "4px",
                    opacity: 0.5,
                  }}
                  onClick={handleCleanForm}
                  disabled={true}
                >
                  <img src={clearLogo} alt="Icon clear" />
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};
