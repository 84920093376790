import moment from "moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "@react-pdf/renderer";

import { headersPlayerSessionReport } from "../../../helpers/headersTables";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import { SessionLogoutReason } from "../../../enums/enums";

interface props {
  page: any[];
}
export const PlayerSessionPdf: FC<props> = ({ page }) => {
  const { t } = useTranslation();
  const columns = headersPlayerSessionReport;
  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column) => (
          <View key={column.key} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>

      <View style={{ flex: 1 }}>
        {page.map((data) => (
          <View key={data.id} style={stylesPdf.row}>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.id}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.player?.id}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {" "}
                {data.player?.firstName + " " + data.player?.lastName}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {moment(data.createAt).format("YYYY-MM-DD HH:mm:ss")}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {moment(data.lastAt).format("YYYY-MM-DD HH:mm:ss")}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.ip}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {data.balanceLogin ? "$ " + data.balanceLogin : ""}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {data.balanceLogout ? "$ " + data.balanceLogout : ""}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {"$ " + data.total_bets}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{"$ " + data.total_won}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {"$ " + data.total_deposits}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {"$ " + data.total_withdrawals}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {data.log_description && (
                  <>
                    {data.log_description === SessionLogoutReason.USER_LOGOUT &&
                      t("playerSessionReport.playerLogout")}
                    {data.log_description ===
                      SessionLogoutReason.PLATFORM_LOGOUT &&
                      t("playerSessionReport.platformLogout")}
                    {data.log_description ===
                      SessionLogoutReason.EXCLUSION_LOGOUT &&
                      t("playerSessionReport.exclusionLogout")}
                    {data.log_description ===
                      SessionLogoutReason.CHANGE_LOGIN_CREDENTIALS &&
                      t("playerSessionReport.changeCredentials")}
                    {data.log_description ===
                      SessionLogoutReason.TIMEOUT_EXCEEDED &&
                      t("playerSessionReport.timeoutExceeded")}
                  </>
                )}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};
