import { IIpBlacklist, IIpBlacklistData } from "../../interfaces/ipBlacklist";
import { Types } from "../types/types";

interface IBlaklistActions {
  type: Types;
  payload?: IIpBlacklistData | IIpBlacklist | null;
}

interface IBlacklistState {
  IpBlacklist: IIpBlacklistData | null;
  activeIp: IIpBlacklist | null;
}

const initialState: IBlacklistState = {
  IpBlacklist: null,
  activeIp: null,
};

export const managementIpBlacklistReducer = (
  state = initialState,
  action: IBlaklistActions
): IBlacklistState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setBlacklist:
      return {
        ...state,
        IpBlacklist: payload as IIpBlacklistData,
      };
    case Types.setActiveIp:
      return {
        ...state,
        activeIp: payload as IIpBlacklist,
      };
    default:
      return state;
  }
};
