import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import React from "react";
import { IConfigCommissional } from "../../../../interfaces/pointsSale";

interface Props {
  data: IConfigCommissional[];
}

export const TabConfigCommisioners = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      {/* CONFIRMACIÓN CONFIGURACIÓN COMISIONALES*/}
      <Grid container mb={2} ml={4}>
        <Grid item xs={3} mb={3}>
          <label>{t("pointsOfSale.refillsCredits").toUpperCase()}</label>
          <Box>
            <label>
              <b>
                {data.find(
                  (commissional: IConfigCommissional) =>
                    commissional.commissional?.name.toLowerCase() ===
                    t("pointsOfSale.refillsCredits").toLowerCase()
                )
                  ? t("state.active").toUpperCase()
                  : t("state.inactive").toUpperCase()}
              </b>
            </label>
          </Box>
        </Grid>
        <Grid item xs={3} mb={3}>
          <label>{t("tabs.paymentOrders").toUpperCase()}</label>
          <Box>
            <label>
              <b>
                {" "}
                {data.find(
                  (commissional: IConfigCommissional) =>
                    commissional.commissional?.name.toLowerCase() ===
                    t("tabs.paymentOrders").toLowerCase()
                )
                  ? t("state.active").toUpperCase()
                  : t("state.inactive").toUpperCase()}
              </b>
            </label>
          </Box>
        </Grid>
        <Grid item xs={3} mb={3}>
          <label>{t("pointsOfSale.affiliateSales").toUpperCase()}</label>
          <Box>
            <label>
              <b>
                {data.find(
                  (commissional: IConfigCommissional) =>
                    commissional.commissional?.name.toLowerCase() ===
                    t("pointsOfSale.affiliateSales").toLowerCase()
                )
                  ? t("state.active").toUpperCase()
                  : t("state.inactive").toUpperCase()}
              </b>
            </label>
          </Box>
        </Grid>
        <Grid item xs={3} mb={3}>
          <label>{t("pointsOfSale.gameSales").toUpperCase()}</label>
          <Box>
            <label>
              <b>
                {data.find(
                  (commissional: IConfigCommissional) =>
                    commissional.commissional.name.toLowerCase() ===
                    t("pointsOfSale.gameSales").toLowerCase()
                )
                  ? t("state.active").toUpperCase()
                  : t("state.inactive").toUpperCase()}
              </b>
            </label>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
