import { useEffect, useState } from "react";
import { Alert, AlertTitle, Box, Divider, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./FinantialInformation.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import InfoIcon from "@mui/icons-material/Info";
import { IActivityQuery } from "../../../../interfaces/activityPlayer";
import { startGetFinantialInformation } from "../../../../redux/actions/activityPlayerActions";
import { Row } from "reactstrap";
import Filter from "../../../../components/Filter/Filter";

export const FinantialInformation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { player } = useSelector((state: AppState) => state.managementPlayers);
  const { flagCleanFilter } = useSelector((state: AppState) => state.helper);
  const [cleanFilter, setCleanFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState<any>({});
  const [filter, setFilter] = useState(false);
  const [data, setData] = useState<any>({
    totalDeposits: 0,
    totalBets: 0,
    totalPrizePayments: 0,
    totalWithdrawals: 0,
    ggr: 0,
  });

  useEffect(() => {
    if (flagCleanFilter) {
      return setCleanFilter(true);
    }
    return setCleanFilter(false);
  }, [flagCleanFilter]);

  const filterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
  ];
  const cleanData = () => {
    setData({
      totalDeposits: 0,
      totalBets: 0,
      totalPrizePayments: 0,
      totalWithdrawals: 0,
      ggr: 0,
    });
  };
  useEffect(() => {
    cleanData();
  }, [player]);

  const handleFilter = async (values: any) => {
    if (player) {
      const params: Partial<IActivityQuery> = {
        startDate: values[t("filters.startDate")],
        endDate: values[t("filters.endDate")],
        playerId: `${player.data[0].id}`,
      };
      setDataFilter(params);
      const result: any = await dispatch(
        startGetFinantialInformation({ ...dataFilter, ...params })
      );
      if (result) {
        setData({
          totalDeposits: +result.totalDeposits || 0,
          totalBets: +result.totalBets || 0,
          totalPrizePayments: +result.totalPrizePayments || 0,
          totalWithdrawals: +result.totalWithdrawals || 0,
          ggr: +result.ggr || 0,
        });
      }
    }
  };

  const onCleanFilter = async () => {
    setDataFilter({});
    setFilter(false);
    cleanData();
  };

  const getColorStyle = (value: number) => ({
    color: value >= 0 ? "green" : "red",
  });

  return (
    <div className="container-fluid finantial-styles">
      <Row
        style={{
          marginTop: "12px",
          pointerEvents: player && player.data.length > 0 ? "all" : "none",
          opacity: player && player.data.length > 0 ? 1 : 0.4,
        }}
      >
        <Filter
          fields={filterFields}
          cleanFilter={cleanFilter}
          handleFilter={handleFilter}
          setDataFilter={setDataFilter}
          onClean={onCleanFilter}
        />
      </Row>
      <Box className="finantial-box-styles">
        <Grid item xs={12}>
          <Divider
            sx={{
              "&.MuiDivider-root": {
                "&::before": {
                  width: "20px",
                },
              },
            }}
            textAlign="left"
            style={{ color: "#000" }}
          >
            {t("tabs.finantianInformation")}
          </Divider>
          <Grid container spacing={2} mt={1} mb={3}>
            <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
              <label className="form-text labelField">
                {t("form.totalDeposits")}
              </label>
              <span className="labelP">$ {data.totalDeposits}</span>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
              <label className="form-text labelField">
                {t("form.totalBets")}
              </label>
              <span className="labelP">$ {data.totalBets}</span>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
              <label className="form-text labelField">
                {t("form.totalAwards")}
              </label>
              <span className="labelP">$ {data.totalPrizePayments}</span>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
              <label className="form-text labelField">
                {t("form.totalWithdrawals")}
              </label>
              <span className="labelP">$ {data.totalWithdrawals}</span>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1} mb={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div>
                <label className="form-text labelField">
                  {t("form.totalggr")}
                </label>
                <span className="labelP" style={getColorStyle(data.ggr)}>
                  $ {data.ggr}
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {!filter && !data && (
        <Grid item xs={12} pt={3} mt={-2}>
          <Stack
            sx={{ width: "100%" }}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
            spacing={2}
          >
            <Alert
              icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
              sx={{
                borderLeft: `7px solid #2C97DF`,
                backgroundColor: "#EAF5FC",
                color: "#4b4b4b",
              }}
            >
              <div className="d-flex">
                <AlertTitle
                  style={{ marginTop: "5px" }}
                  sx={{ color: "#333333" }}
                >
                  {t("alerts.clickButton")}
                </AlertTitle>
                <AlertTitle
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  sx={{ color: "#333333", fontWeight: "bold" }}
                >
                  {t("alerts.finantialInformation")}
                </AlertTitle>
              </div>
            </Alert>
          </Stack>
        </Grid>
      )}
    </div>
  );
};
