import { Types } from "../types/types";

interface IPromotionsActions {
  type: Types;
  payload?: any;
}

interface IPromotionsState {
  promotions: any;
  activePromotion: any;
}

const initialState: IPromotionsState = {
  promotions: null,
  activePromotion: null,
};

export const managementPromotionsPopupsReducer = (
  state = initialState,
  action: IPromotionsActions
): IPromotionsState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setPromotionsPopups:
      return {
        ...state,
        promotions: payload as any,
      };
    case Types.setActivePromotionPopup:
      return {
        ...state,
        activePromotion: payload as any,
      };
    default:
      return state;
  }
};
