import {useEffect} from "react";
import {Col, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {Box, Grid} from "@mui/material";
import {AppState} from "../../../redux/reducers/rootReducer";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import {asyncLoadingFunction} from "../../../redux/actions/helperActions";
import {startGetExternalUsers} from "../../../redux/actions/commercialNetworkActions";
import { NetworksList } from "../../components/managementNetworks/NetworksList";

export const ManagementNetworks = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {user} = useSelector((state: AppState) => state.auth);
  const {networks} = useSelector((state: AppState) => state.commercialNetworks);

  useEffect(() => {
    dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startGetExternalUsers({operatorId: user?.operator?.id})
        )
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content w-100 mb-4">
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Row>
            <Col xs={12}>
              <MyBreadCrumbs
                titles={[
                  t("sidebar.settings"),
                  t("shorcuts.commercialNetworks"),
                ]}
              ></MyBreadCrumbs>
            </Col>{" "}
          </Row>
        </Col>
      </Row>

      <div className="filter mt-2" style={{minHeight: '70vh'}}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} className="mb-3">
            <Box
              display="flex"
              className="pb-1"
              borderBottom={1}
              borderColor="#02D0E7"
            >
              <i
                className="fa-solid fa-circle-info"
                style={{color: "#02D0E7", marginRight: 9, fontSize: 17}}
              ></i>
              <p className="txt-primary-md">
                {t("shorcuts.commercialNetworks")}
              </p>
            </Box>
          </Grid>

          <Grid item xs={12}>
            {networks?.data?.map((network, index) => (
              <NetworksList network={network} key={network.id} isFirst={index === 0}/>
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
