import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { MyAlert } from "../../components/custom/MyAlert";
import { CustomAlert } from "../../../components/Alerts/CustomAlert";
import { DialogParamSettings } from "../../components/ParamSettings.tsx/DialogParamSettings";
import {
  setCloseAlert,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { FormPaymentMethodsTable } from "../../components/ParamSettings.tsx/FormPaymentMethodsTable";
import { FormGamesTable } from "../../components/ParamSettings.tsx/FormGamesTable";
import { FormSolutionsTable } from "../../components/ParamSettings.tsx/FormSolutionsTable";
import { TableComponent } from "../../../components/Table/TableComponent";
import { IColumnsTable, Icons } from "../../../interfaces/components";
import { getParamSettings, startGetPercentageWithdrawal } from "../../../redux/actions/managementParametersActions";
import { uiSetOpenDialog } from "../../../redux/actions/uiAction";
import { arraySearch } from "../../../redux/actions/searchActions";
import { MyDialog } from "../../components/custom/MyDialog";
import { useLocation } from "react-router-dom";
import { removeAccents } from "../../../utils/utils";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { FormBonus } from "../../components/ParamSettings.tsx/FormBonus";
import {
  setBonusType,
  startGetBonusType,
} from "../../../redux/actions/bonusActions";
import { FormDepostiPercentage } from "../../components/ParamSettings.tsx/FormDepositPercentage";

const tableOptions = [
  "paymentmethodstablelist",
  "verticaltablegameslist",
  "solutionstablelist",
  "bonusTable",
  "percentageWithdrawalTable",
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const ParamSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { openAlert, messageAlert, typeAlert, rowsPerPage } = useSelector(
    (state: AppState) => state.helper
  );
  const { data } = useSelector((state: AppState) => state.search);
  const { bonusType } = useSelector((state: AppState) => state.bonus);
  const { user } = useSelector((state: AppState) => state.auth);
  const { openDialog } = useSelector((state: AppState) => state.ui);
  const [open, setOpen] = useState(false);
  const [table, setTable] = useState("");
  const [searchText, setSearchText] = useState("");

  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { pathname } = useLocation();

  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;

  const param =
    table === "paymentmethodstablelist"
      ? "paramPaymentMethods"
      : table === "verticaltablegameslist"
      ? "paramGames"
      : table === "bonustable"
      ? "bonusTable"
      : table === "percentagewithdrawaltable"
      ? "percentageWithdrawalTable"
      : "paramSolutions";

  const displayedOptions = useMemo(
    () =>
      tableOptions.filter((option) => {
        return removeAccents(t(`parameterization.${option}`))
          .toLowerCase()
          .includes(removeAccents(searchText).toLowerCase());
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchText]
  );

  const handleManage = () => {
    if (table.trim()) {
      dispatch(uiSetOpenDialog());
    } else {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.selectTableManage"));
      dispatch(setTypeAlert("error"));
    }
  };

  const handleFilter = async () => {
    if (table.trim()) {
      if (table === "bonustable" && user) {
        let params: any = {
          page: 1,
          perPage: rowsPerPage,
        };
        if (user.operator) {
          params = {
            ...params,
            operator: user.operator.id,
          };
        }
        await dispatch(startGetBonusType(params));
      } else {
        if (table === "percentagewithdrawaltable") {
          await dispatch(startGetPercentageWithdrawal());
        } else {
          await dispatch(getParamSettings(param, 1));
        }
      }
    } else {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.selectTableFilter"));
      dispatch(setTypeAlert("error"));
    }
  };

  const onCloseAlert = () => {
    dispatch(setCloseAlert());
  };

  const handleCloseModal = () => {
    setOpen(true);
  };

  const handleAcceptClose = () => {
    setOpen(false);
    dispatch(uiSetOpenDialog());
  };

  const handleCancelClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(arraySearch(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(setBonusType(null));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table]);

  const columns: IColumnsTable[] =
    table === "paymentmethodstablelist"
      ? [
          { id: 1, header: "user.country" },
          { id: 2, header: "parameterization.paymentMethod" },
          { id: 3, header: "table.actions" },
        ]
      : table === "verticaltablegameslist"
      ? [
          { id: 1, header: "user.country" },
          { id: 2, header: "companyManagement.selectedGames" },
          { id: 3, header: "table.actions" },
        ]
      : table === "bonustable"
      ? [
          { id: 1, header: "parameterization.bonusId" },
          { id: 2, header: "parameterization.bonus" },
          { id: 3, header: "table.actions" },
        ]
      : table === "percentagewithdrawaltable"
      ? [
          { id: 1, header: "parameterization.operatorId" },
          { id: 2, header: "parameterization.percentage" },
          { id: 3, header: "table.actions" },
        ]
      : [
          { id: 1, header: "user.country" },
          { id: 2, header: "parameterization.solutions" },
          { id: 3, header: "table.actions" },
        ];
  const icons: Icons = {
    seeMore: false,
    add: false,
    delete: table !== "percentagewithdrawaltable",
    see: table !== "percentagewithdrawaltable",
  };
  const renderContentTable = (table: any) => {
    switch (table) {
      case "paymentmethodstablelist":
        return <FormPaymentMethodsTable />;
      case "verticaltablegameslist":
        return <FormGamesTable />;
      case "solutionstablelist":
        return <FormSolutionsTable />;
      case "bonustable":
        return <FormBonus />;
      case "percentagewithdrawaltable":
        return <FormDepostiPercentage />;

      default:
        return <>NOT FOUND</>;
    }
  };

  return (
    <div className="content w-100 mb-5">
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Row style={{ marginBottom: 2 }}>
            <Col xs={12}>
              <MyBreadCrumbs
                titles={[t("sidebar.settings"), t("sidebar.paramSettings")]}
              />
            </Col>
          </Row>
          <div
            style={{ height: "auto !important" }}
            className="white-container-auto-height"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} className="mb-3">
                <Box
                  display="flex"
                  className="pb-1"
                  borderBottom={1}
                  borderColor="#02D0E7"
                >
                  <i
                    className="fa-solid fa-circle-info"
                    style={{ color: "#2C97DF", marginRight: 9, fontSize: 17 }}
                  ></i>
                  <p className="txt-primary-md">
                    {t("parameterization.title")}
                  </p>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="search-select-label">
                    {t("parameterization.selectTable")}
                  </InputLabel>

                  <Select
                    labelId="search-select-label"
                    name="table"
                    value={table || ""}
                    onChange={(e) => {
                      setTable(e.target.value.toLowerCase().toString());
                      dispatch(arraySearch(null));
                    }}
                    size="small"
                    input={
                      <OutlinedInput
                        label={t("parameterization.selectTable")}
                      />
                    }
                    MenuProps={{ autoFocus: false, ...MenuProps }}
                    onClose={() => setSearchText("")}
                  >
                    <ListSubheader>
                      <TextField
                        size="small"
                        name="searchText"
                        value={searchText || ""}
                        autoFocus
                        fullWidth
                        InputLabelProps={{ shrink: false }}
                        label={" "}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                          setTable("");
                        }}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    {displayedOptions.map((option, i) => (
                      <MenuItem
                        key={`${option}-${i}`}
                        value={option.toLowerCase()}
                      >
                        {t(`parameterization.${option}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} display="flex">
                {enableCreated && (
                  <button
                    className="d-flex btn-light-dark primary-btn mr-2"
                    type="button"
                    style={{
                      width: "30%",
                    }}
                    onClick={handleManage}
                  >
                    <div className="p-0 text-left mr-1">
                      <label className="btn-label pl-1">
                        {t("button.manage").toUpperCase()}
                      </label>
                    </div>
                    <div className="p-0 text-right">
                      <i className="fa fa-plus-circle btn-icon pt-1 fa-md"></i>
                    </div>
                  </button>
                )}

                <button
                  className="d-flex btn-light-dark"
                  type="button"
                  style={{ width: "30%" }}
                  onClick={handleFilter}
                >
                  <div className="p-0 text-right">
                    <i className="fa fa-filter btn-icon pt-1 fa-md"></i>
                  </div>
                  <div className="p-0 text-left">
                    <label className="btn-label pl-1">
                      {t("filters.filter").toUpperCase()}
                    </label>
                  </div>
                </button>
              </Grid>

              <Grid item xs={12}>
                <Box borderBottom={1} borderColor="#02D0E7" width={1} mt={1} />
              </Grid>

              <Grid item xs={12}>
                {(data && data.data.length > 0) || bonusType ? (
                  <TableComponent
                    columns={columns}
                    data={
                      table === "bonustable"
                        ? bonusType?.data
                        : data
                        ? param === "paramPaymentMethods"
                          ? data.data.filter(
                              (el: any) =>
                                el.paymentMethods.filter(
                                  (method: any) =>
                                    method.state && method.type !== "withdrawal"
                                ).length > 0
                            )
                          : data.data
                        : []
                    }
                    pages={
                      table === "bonustable"
                        ? bonusType?.last_page
                        : data
                        ? data.last_page
                        : 1
                    }
                    component={"parameterizations"}
                    totalData={
                      table === "bonustable" ? bonusType : data ? data : []
                    }
                    param={param}
                    icons={icons}
                    isSortingTable={false}
                    dataFilter={{
                      isFilter: true,
                      param:
                        param === "percentageWithdrawalTable"
                          ? "percentage"
                          : param,
                    }}
                  />
                ) : (
                  <CustomAlert
                    htmlText={t("parameterization.filterText")}
                    type="info"
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </Col>
        <MyAlert
          open={openAlert}
          message={messageAlert ? messageAlert : ""}
          typeAlert={typeAlert}
          handleClose={onCloseAlert}
        />
        <DialogParamSettings
          openModal={openDialog}
          handleClose={handleCloseModal}
          title={
            table === "paymentmethodstablelist"
              ? `${t("parameterization.addParameters")} - ${t(
                  "parameterization.paymentMethodsTable"
                )}`
              : table === "verticaltablegameslist"
              ? `${t("parameterization.addParameters")} - ${t(
                  "parameterization.gamesTable"
                )}`
              : table === "bonustable"
              ? `${t("parameterization.addParameters")} - ${t(
                  "parameterization.bonusTable"
                )}`
              : table === "percentagewithdrawaltable"
              ? `${t("parameterization.addParameters")} - ${t(
                  "parameterization.percentageWithdrawalTable"
                )}`
              : `${t("parameterization.addParameters")} - ${t(
                  "parameterization.solutionsTable"
                )}`
          }
          content={renderContentTable(table)}
        />
        <MyDialog
          open={open}
          title={t("dialog.confirmationWindow")}
          message={t("dialog.sureCloseModal")}
          handleAccept={handleAcceptClose}
          handleClose={handleCancelClose}
        />
      </Row>
    </div>
  );
};
