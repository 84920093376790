import { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { MyTextField } from "../../custom/MyTextField";
import "../styles.scss";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { Roles_Enum, State } from "../../../../enums/enums";
import clearLogo from "../../../../assets/svg/icon-park_clear-format.svg";
import { MyDialog } from "../../custom/MyDialog";
import {
  asyncLoadingFunction,
  setCloseAlert,
  setFlagForm,
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import { MyAlert } from "../../custom/MyAlert";
import {
  setActiveBonusGroup,
  setOpenBonusDetails,
  startFilterBonus,
} from "../../../../redux/actions/bonusActions";
import { addBonusToGroup } from "../../../../services/bonus";
const styles = {
  "& input.Mui-disabled ": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
  "& .MuiSelect-select.Mui-disabled": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const FormAddBonusToGroup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const { bonus, activeBonusGroup } = useSelector(
    (state: AppState) => state.bonus
  );
  const { token, user } = useSelector((state: AppState) => state.auth);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const [operatorId, setOperatorId] = useState<number | null>(
    !isSuper && user ? user.operator.id : null
  );
  const [submit, setSubmit] = useState(false);
  const [message, setMessage] = useState("");
  const [cancel, setCancel] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalData, setTotalData] = useState<any>();
  const { openAlert, messageAlert, typeAlert, loading } = useSelector(
    (state: AppState) => state.helper
  );
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const initialValues = {
    bonus: "",
  };

  const validationSchema = yup.object({
    bonus: yup.number().required(t("form.requiredFile")),
  });

  const handleCancel = () => {
    setMessage("dialog.sureExit");
    setCancel(true);
    setOpenDialog(true);
  };

  const handleClean = () => {
    (formikRef.current as any).resetForm();
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleAccept = async () => {
    handleCloseDialog();
    if (cancel) {
      dispatch(setFlagForm({ flagFormCreate: false }));
    } else {
      (formikRef.current as any).setSubmitting(true);
      const data = {
        groupId: activeBonusGroup.id,
        bonusId: totalData?.bonus,
        startDate: totalData?.startDate,
        endDate: totalData?.endDate,
      };
      const response = await addBonusToGroup(data, token);
      if (response?.message === "ok") {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("managementBonds.successAddBonus"));
        dispatch(setTypeAlert("success"));
        dispatch(setActiveBonusGroup(null));
        dispatch(setOpenBonusDetails(false));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("managementBonds.errorAddbonus"));
        dispatch(setTypeAlert("error"));
        dispatch(setActiveBonusGroup(null));
        dispatch(setOpenBonusDetails(false));
      }
      (formikRef.current as any).setSubmitting(false);
    }
    setCancel(false);
    return dispatch(
      setFlagForm({
        flagFormCreate: false,
      })
    );
  };

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
    if (typeAlert === "success") {
      dispatch(setFlagForm({ flagFormCreate: false }));
      dispatch(setLoadingState({ loading: false }));
    }
  };

  useEffect(() => {
    const fetchBonus = async () => {
      if (user) {
        await dispatch(
          asyncLoadingFunction(() => dispatch(startFilterBonus({})))
        );
      }
    };
    fetchBonus();
    return () => {
      dispatch(setFlagForm({ flagFormCreate: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openConfirmation = () => {
    setMessage("managementBonds.sureAddBonusGroup");
    setOpenDialog(true);
    setCancel(false);
  };

  return (
    <Grid container spacing={2} mt={1} pl={1} pr={1}>
      <Grid item xs={12}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          onSubmit={async (data) => {
            setTotalData(data);
            openConfirmation();
          }}
        >
          {({
            values,
            isValid,
            setFieldValue,
            handleChange,
            errors,
            isSubmitting,
          }) => (
            <Form data-testid={"bonusForm"}>
              <Grid container spacing={4}>
                {
                  //MOSTRAR SELECT CON OPERADORES CUANDO SEA SUPER_ADMIN
                  isSuper && companies && (
                    <Grid item xs={4}>
                      <FormControl
                        sx={{ width: "100%" }}
                        size="small"
                        error={submit && isSuper && !operatorId}
                      >
                        <InputLabel id="operator-label">
                          {t("companyManagement.operator") + "*"}
                        </InputLabel>
                        <Select
                          labelId="operator-label"
                          name="operator"
                          size="small"
                          fullWidth
                          onChange={
                            (e: any) => setOperatorId(e.target.value)
                            // filterOperatorCurrency(e.target.value);
                          }
                          value={!operatorId ? "" : operatorId}
                          sx={styles}
                          input={
                            <OutlinedInput
                              label={t("companyManagement.operator") + "*"}
                            />
                          }
                          MenuProps={MenuProps}
                        >
                          {companies?.data?.map((company) => (
                            <MenuItem key={company.id} value={company.id}>
                              {company.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormHelperText>
                        {submit && !operatorId && (
                          <label className="ml-3" style={{ color: "#d32f2f" }}>
                            {t("form.requiredFile")}
                          </label>
                        )}
                      </FormHelperText>
                    </Grid>
                  )
                }
                <Grid item xs={12}>
                  <MyTextField
                    id="bonusTextField"
                    name="bonus"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={values.bonus}
                    onChange={(e: any) =>
                      setFieldValue("bonus", e.target.value)
                    }
                    role={"combobox"}
                    select
                    label={`${t("managementBonds.bondName")}*`}
                    inputProps={{
                      id: "bonusInput",
                    }}
                  >
                    {bonus?.data.filter((b: any) => b.state === State.ACTIVE).map((item: any) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        sx={{
                          "&.Mui-focused": {
                            backgroundColor: "red",
                          },
                        }}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </MyTextField>
                </Grid>
                <Box
                  display={"flex"}
                  mt={9}
                  flex={1}
                  justifyContent="flex-end"
                  alignItems={"flex-end"}
                >
                  <Button
                    variant="contained"
                    className="footer-btn danger-btn ripple"
                    style={{ border: "none", outline: "none" }}
                    onClick={handleCancel}
                    disabled={!!isSubmitting || !!loading}
                    data-testid={"btnCancel"}
                  >
                    {t("button.cancel").toUpperCase()}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className="footer-btn primary-btn ripple"
                    style={{ border: "none", outline: "none" }}
                    /*                     disabled={
                                          !!isSubmitting ||
                                          !isValid ||
                                          !!loading ||
                                          error
                                        } */
                    data-testid={"btnSave"}
                    onClick={() => setSubmit(true)}
                  >
                    {isSubmitting || !!loading ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      <>{t("button.save").toUpperCase()}</>
                    )}
                  </Button>
                  <Button
                    style={{
                      border: "none",
                      outline: "none",
                      marginLeft: "4px",
                    }}
                    onClick={() => {
                      setMessage("dialog.sureClean");
                      setOpenDialog(true);
                    }}
                    disabled={!!isSubmitting || !!loading}
                  >
                    <img src={clearLogo} alt="Icon clear" />
                  </Button>
                </Box>
              </Grid>

              <MyDialog
                id="confirmationDialog"
                data-testid="confirmationDialog"
                open={openDialog}
                title={t("dialog.confirmationWindow")}
                message={t(message)}
                handleAccept={
                  message === "dialog.sureClean" ? handleClean : handleAccept
                }
                handleClose={handleCloseDialog}
                isLoading={isSubmitting || !!loading}
              />
            </Form>
          )}
        </Formik>
        <MyAlert
          open={openAlert}
          message={messageAlert ? messageAlert : ""}
          typeAlert={typeAlert}
          handleClose={onCloseAlert}
        />
      </Grid>
    </Grid>
  );
};
