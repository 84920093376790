import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";

import { AuthLayout } from "../layouts/Auth/Auth";
import { AdminLayout } from "../layouts/Admin/Admin";

import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";

import { AppState } from "../redux/reducers/rootReducer";
import { startLoadAvailableRoutes } from "../redux/actions/managementMenuActions";
import { Box, CircularProgress, Grid } from "@mui/material";
import FormFilesUploads from "../views/pages/FilesUploads/FormFilesUploads";
import { useNetworkStatus } from "../hooks/useNetworkStatus";
import {
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setOpenTimeout,
  setTypeAlert,
} from "../redux/actions/helperActions";
import { uiSetNetworkState } from "../redux/actions/uiAction";
import { useTranslation } from "react-i18next";
import { useIdleTimeout } from "../hooks/useIdleTimeout";
// import { io, Socket } from "socket.io-client";
import {
  // addNotificationRedux,
  fetchNotifications,
} from "../redux/actions/notificationsActions";
import { Login } from "../views/pages/Login";
import {
  startGetAssociateWallet,
  startGetDealerWallet,
  startGetPointSaleWallet,
} from "../redux/actions/walletActions";
import { Roles_Enum } from "../enums/enums";
// import { INotifications } from "../interfaces/notifications";
// import { Roles_Enum } from "../enums/enums";

export const AppRouter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: AppState) => state.auth);
  const [isLogged, setIsLogged] = useState(true);
  const [checking, setChecking] = useState(true);
  // const [socket, setSocket] = useState<Socket | null>(null);
  const networkStatus = useNetworkStatus();

  // Función para conectar y establecer el socket
  // const connectSocket = () => {
  //   const newSocket = io(
  //     process.env.REACT_APP_API_HTTP_NOTIFICATIONS as string
  //   );
  //   setSocket(newSocket);
  // };

  // Función para desconectar y limpiar el socket
  // const disconnectSocket = () => {
  //   if (socket && socket.connected) {
  //     socket.disconnect();
  //   }
  //   setSocket(null);
  // };

  useEffect(() => {
    if (!!user) {
      // connectSocket();
      dispatch(fetchNotifications());
    } else {
      // disconnectSocket();
    }
    setIsLogged(!!user);

    const fetchData = async () => {
      //get available routes
      setChecking(true);
      await dispatch(startLoadAvailableRoutes());
      if (user) {
        if (user.roles.name === Roles_Enum.ASSOCIATE) {
          await dispatch(startGetAssociateWallet());
        }  
        if([Roles_Enum.POINT_SALE,Roles_Enum.SELLER].includes(user.roles.name as Roles_Enum) )
        {
          await dispatch(startGetPointSaleWallet());
        }
        if(user.roles.name === Roles_Enum.DEALER){
          await dispatch( startGetDealerWallet() )

        }
      }

      setChecking(false);
    };

    fetchData();
    return () => {
      setChecking(false);
      setIsLogged(false);
      dispatch(setOpenTimeout(false));
      // disconnectSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // useEffect(() => {
  //   const onNotifications = async () => {
  //     if (socket && user) {
  //       socket.on("withdrawal", (notificationData: INotifications) => {
  //         (user.roles.name === Roles_Enum.SUPER_ADMIN ||
  //           notificationData.operator === user.operator.id) &&
  //           dispatch(addNotificationRedux(notificationData));
  //       });

  //       socket.on(
  //         "transactionalSettings",
  //         (notificationData: INotifications) => {
  //           (user.roles.name === Roles_Enum.SUPER_ADMIN ||
  //             notificationData.operator === user.operator.id) &&
  //             dispatch(addNotificationRedux(notificationData));
  //         }
  //       );
  //     }
  //   };
  //   onNotifications();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [socket]);

  useEffect(() => {
    dispatch(uiSetNetworkState(networkStatus));
    if (networkStatus === "Offline") {
      dispatch(setLoadingState({ loading: false }));
      dispatch(setTypeAlert("warning"));
      dispatch(setMessageAlert(t("alerts.notNetworkConect")));
      dispatch(setOpenAlert());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkStatus]);

  const handleIdle = () => {
    isLogged && dispatch(setOpenTimeout(true));
  };
  const { idleTimer } = useIdleTimeout({
    onIdle: handleIdle,
    isLogged,
    idleTime: 15,
  });

  const handleStay = () => {
    dispatch(setOpenTimeout(false));
    idleTimer.reset();
  };

  if (checking) {
    return (
      <div className={"rootLoading"}>
        <Grid container className={"paperLoading"}>
          <Box mx="auto" my="45vh">
            <CircularProgress className={"colorLoading"} />
          </Box>
        </Grid>
      </div>
    );
  }

  return (
    <Router>
      <div>
        <Switch>
          <PublicRoute
            path="/auth"
            component={AuthLayout}
            isAuthenticated={isLogged}
          />
          <PublicRoute
            path="/upload-files"
            component={FormFilesUploads}
            isAuthenticated={isLogged}
          />
          <PublicRoute
            path="/reset-password/:token"
            component={Login}
            isAuthenticated={isLogged}
          />
          <PrivateRoute
            path="/admin"
            component={AdminLayout}
            handleStay={handleStay}
            isAuthenticated={isLogged}
          />

          <Redirect to="/auth/login" />
        </Switch>
      </div>
    </Router>
  );
};
