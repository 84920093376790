import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  KeyboardEvent,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  Box,
  IconButton,
  ThemeProvider,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { theme } from "../custom/customStyles";
import { AppState } from "../../../redux/reducers/rootReducer";

import { regNumbers } from "../../../utils/utils";
import { setLoadingState } from "../../../redux/actions/helperActions";
import clearLogo from "../../../assets/svg/icon-park_clear-format.svg";
import { State } from "../../../enums/enums";
import { IDealer } from "../../../interfaces/cards";
import { getDealers } from "../../../services/dealers";

interface Props {
  dealers: Array<any>;
  setDealers: Dispatch<SetStateAction<Array<any>>>;
  handleCancel?: () => void;
  handleContinue?: () => void;
  handleCleanForm?: () => void;
  handlePrevious?: () => void;
  amountCards: string;
  operator: number | null;
  edit?: boolean;
  handleEdit?: (data: any) => void;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const AssignAssociate = ({
  dealers,
  setDealers,
  handleCancel,
  handleContinue,
  handleCleanForm,
  handlePrevious,
  amountCards,
  operator,
  edit,
  handleEdit,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token } = useSelector((state: AppState) => state.auth);
  const { loading } = useSelector((state: AppState) => state.helper);
  const { activeLot } = useSelector((state: AppState) => state.managementCards);
  const [arrayAssociates, setArrayAssociates] = useState<IDealer[] | null>(
    null
  );

  const [disabled, setDisabled] = useState(true);
  const [qtyCards, setQtyCards] = useState(amountCards);

  useEffect(() => {
    const fetchAssociatesByOperator = async () => {
      if (token) {
        dispatch(setLoadingState({ loading: true }));
        const params: any = operator
          ? {
              operator,
            }
          : {};
        if (!activeLot) {
          params.state = State.ACTIVE;
        }
        const response = await getDealers(params, token);

        if (response && !response.error) {
          setArrayAssociates(response?.data);
        } else {
          setArrayAssociates(null);
        }
        dispatch(setLoadingState({ loading: false }));
      }
    };
    fetchAssociatesByOperator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (data: any) => {
    handleContinue && handleContinue();
  };
  const handleChangeDealer = (e: any, index: any) => {
    const {
      target: { value },
    } = e;
    const copyDealers = [...dealers];

    const dealerDetails = arrayAssociates?.find(
      (dealer) => dealer.id === value
    );
    const currentDealer = copyDealers[index];
    currentDealer.dealer = value;
    currentDealer.name = dealerDetails?.name;
    setDealers([...copyDealers]);
  };
  const handleChangeAmountCards = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    const {
      target: { value },
    } = e;

    const copyDealers = [...dealers];
    const currentDealer = copyDealers[index];

    currentDealer.amountCards = value;
    setDealers([...copyDealers]);
  };
  useEffect(() => {
    let sum = 0;
    let validDealers = true;
    dealers.forEach((obj) => {
      const amountCards = obj.amountCards ? parseInt(obj.amountCards) : 0;
      sum += amountCards;
      if (!obj.hasOwnProperty("dealer")) {
        validDealers = false;
      }
    });
    const diffCards = parseInt(amountCards) - sum;
    setQtyCards(diffCards.toString());
    const disable = diffCards === 0 && validDealers;
    setDisabled(!disable);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealers]);

  return (
    <div className="container mt-3">
      <ThemeProvider theme={theme}>
        <Grid container spacing={2}>
          {dealers?.map((item: any, index: number) => {
            return (
              <>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    className="pb-1"
                    borderBottom={1}
                    borderColor="#02D0E7"
                  >
                    <i
                      className="fa-solid fa-circle-info"
                      style={{
                        color: "#02D0E7",
                        marginRight: 10,
                        fontSize: 20,
                      }}
                    ></i>
                    <p
                      className="txt-primary-md"
                      style={{ fontWeight: 900, fontSize: "18px" }}
                    >
                      {index === 0
                        ? t("cards.assignAssociateCard")
                        : t("cards.addCardsOtherAssociate")}
                    </p>
                  </Box>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4}>
                    <label className="ml-3">
                      {t("cards.selectDealer") + "*"}
                    </label>

                    <TextField
                      name="associate"
                      select
                      label=""
                      onChange={(e) => handleChangeDealer(e, index)}
                      size="small"
                      variant="outlined"
                      value={item.dealer || ""}
                      fullWidth
                      SelectProps={{
                        MenuProps,
                      }}
                    >
                      {arrayAssociates?.map((dealer) => (
                        <MenuItem key={dealer.id} value={dealer.id}>
                          {dealer.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <label className="ml-3">{t("cards.qtyCards")}</label>
                    <TextField
                      name="amountCards"
                      variant="outlined"
                      InputLabelProps={{ shrink: false }}
                      label={" "}
                      type="text"
                      onChange={(e: any) => {
                        handleChangeAmountCards(e, index);
                      }}
                      value={item.amountCards}
                      size="small"
                      fullWidth
                      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                        if (item.amountCards === "") {
                          e.key === "0"
                            ? e.preventDefault()
                            : !regNumbers(e.key) && e.preventDefault();
                        } else {
                          e.key === "."
                            ? e.preventDefault()
                            : !regNumbers(e.key) && e.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box>
                      {index === 0 ? (
                        <IconButton
                          id="openFormBtn"
                          style={{ border: "none", outline: "none" }}
                          disabled={parseInt(qtyCards) === 0 ? true : false}
                          onClick={() => {
                            setDealers([
                              ...dealers,
                              {
                                amountCards: "",
                                user: "",
                              },
                            ]);
                          }}
                        >
                          <span
                            style={{
                              color: "#000",
                              fontSize: "16px",
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "normal",
                            }}
                          >
                            {t("cards.addMoreAssociate")}
                          </span>
                          <span className="btn-add" style={{ padding: "10px" }}>
                            <i
                              className="fa-solid fa-plus"
                              style={{ fontSize: 30 }}
                            ></i>
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => {
                            const newDealers = dealers?.filter(
                              (list) => list.dealer !== item.dealer
                            );

                            setDealers(newDealers);
                          }}
                          style={{
                            border: "none",
                            outline: "none",
                            boxShadow: "none",
                          }}
                        >
                          <span style={{ padding: "10px" }}>
                            <i
                              className="fa fa-trash iconDelete"
                              style={{ fontSize: 30 }}
                            ></i>
                          </span>
                        </IconButton>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </>
            );
          })}

          <Grid item xs={12} display="flex" justifyContent="center">
            <span
              style={{
                color: "#007E8C",
                fontSize: "14px",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              }}
            >
              <i className="fa-solid fa-circle-info"></i>
              {t("cards.availableCards", { amount: qtyCards })}
            </span>
          </Grid>
          <Grid
            item
            xs={12}
            mt={2}
            display="flex"
            justifyContent="center"
            sx={{ height: "60px" }}
          >
            {!edit && (
              <Button
                style={{
                  border: "none",
                  outline: "none",
                }}
                variant="contained"
                className="footer-btn primary-btn ripple"
                onClick={handlePrevious}
              >
                <i className="fa-solid fa-angle-left pl-2"></i>
                {t("button.previous").toUpperCase()}
              </Button>
            )}
            <Button
              style={{ border: "none", outline: "none" }}
              variant="contained"
              className="footer-btn danger-btn ripple"
              onClick={handleCancel}
            >
              <i className="fa-solid fa-x"></i>
              {t("button.cancel").toUpperCase()}
            </Button>
            <Button
              style={{
                border: "none",
                outline: "none",
              }}
              type="submit"
              data-testid="btnNext"
              variant="contained"
              className="footer-btn primary-btn ripple"
              onClick={(e) =>
                edit && handleEdit ? handleEdit(dealers) : handleSubmit(e)
              }
              disabled={disabled}
            >
              {loading ? (
                <CircularProgress size={"1.6rem"} color="inherit" />
              ) : (
                t(edit ? "button.save" : "button.next").toUpperCase()
              )}
            </Button>
            {!edit && (
              <Button
                style={{
                  border: "none",
                  outline: "none",
                  marginLeft: "4px",
                }}
                onClick={handleCleanForm}
              >
                <img src={clearLogo} alt="Icon clear" />
              </Button>
            )}
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};
