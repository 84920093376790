import { ToggleButton, ToggleButtonGroup } from "@mui/material";

interface Props {
  checked: boolean;
  handleChange: (e: any, state: string) => void;
}

export const MyToggleButton = ({ checked, handleChange }: Props) => {
  return (
    <ToggleButtonGroup
      style={{ height: "40px" }}
      fullWidth
      value={checked?"on":"off"}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton fullWidth value={"off"} style={{ border: "none", outline: "none" }}>
        Off
      </ToggleButton>
      <ToggleButton fullWidth value={"on"} style={{ border: "none", outline: "none" }}>
        On
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
