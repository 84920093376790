import React, { FC } from "react";
import moment from "moment";
import TableCell from "@mui/material/TableCell";
import { IPlayerActivityData } from "../../../../interfaces/playerActivity";

interface Props {
  row: IPlayerActivityData;
}

export const RowDataTableCommercialReport: FC<Props> = ({ row }) => {
  return (
    <>
      <TableCell className="word-break">
        {row.externalUser?.name}
      </TableCell>
      <TableCell className="word-break">
        {row.id}
      </TableCell>
      <TableCell className="word-break">
        {row.refTransactionId}
      </TableCell>
      <TableCell className="word-break">
        {row.transactionType.name}
      </TableCell>
      <TableCell className="word-break">
        {row.transactionStatus.name}
      </TableCell>
      <TableCell className="word-break">
        {row.player.id}
      </TableCell>
      <TableCell className="word-break">
        {row.player.numberDocument}
      </TableCell>
      <TableCell>
        {`$${row.amount}`}
      </TableCell>
      <TableCell className="word-break">
        {row.meta?.codePoint}
      </TableCell>
      <TableCell className="word-break">
        {row.meta?.province}
      </TableCell>
      <TableCell className="word-break">
        {row.meta?.canton}
      </TableCell>
      <TableCell className="word-break">
        {moment(row.updateAt).format("DD/MM/YYYY HH:mm:ss")}
      </TableCell>
      <TableCell/>
    </>
  );
};
