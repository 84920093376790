import { useState, useEffect } from "react";
// @ts-ignore
import classNames from "classnames";

// reactstrap components
import { Navbar } from "reactstrap";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import logo from "../../assets/svg/logo-neat.svg";
import logoHover from "../../assets/svg/logo-neat-hover.svg";
import logoSm from "../../assets/svg/logo-neat-mini.svg";
import logoSmHover from "../../assets/svg/logo-neat-mini-hover.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";
import { Badge, Divider, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useDate } from "../../hooks/useDate";
import { uiSetOpenNotifications } from "../../redux/actions/uiAction";
import { startLogout } from "../../redux/actions/authActions";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { INotifications } from "../../interfaces/notifications";
import {
  DealerTypeEnum,
  NotificationsTypes,
  Roles_Enum,
} from "../../enums/enums";
import { ReloadButton, UserBalanceContainer } from "../Header/UseHeaderStyles";
import { ReloadIcon } from "../../assets/svg/ReloadIcon";
import {
  startGetAssociateWallet,
  startGetDealerWallet,
  startGetPointSaleWallet,
} from "../../redux/actions/walletActions";

const sizeText = { lg: 13.5, md: 11, sm: 10, xs: 8.5 };

export const AdminNavbar = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const [collapseOpen] = useState(false);
  const [color, setColor] = useState("navbar-transparent");
  const { user } = useSelector((state: AppState) => state.auth);
  const { date, utc, hour } = useDate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [imageLogo, setImageLogo] = useState(logo);
  const [imageLogoSm, setImageLogoSm] = useState(logoSm);
  const [contador, setContador] = useState(0);
  const { openNotifications } = useSelector((state: AppState) => state.ui);
  const { pointSale, associate, dealer } = useSelector(
    (state: AppState) => state.wallet
  );
  const open = Boolean(anchorEl);
  const [currentPath, setCurrentPath] = useState("");
  const isLessThanMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isReloading, setIsReloading] = useState(false);

  const { notifications } = useSelector(
    (state: AppState) => state.notifications
  );

  const getFilteredNotifications = (): INotifications[] | null => {
    if (!notifications || !notifications.length) return [];
    if (user?.roles.notification === NotificationsTypes.SUPER_ADMIN) {
      return notifications.filter(
        (notification: INotifications) => !notification.read
      );
    }
    if (user?.roles.notification === NotificationsTypes.PORTFOLIO) {
      return notifications.filter(
        (notification: INotifications) =>
          !notification.read &&
          ["withdrawal", "transactionalSettings"].includes(notification.module)
      );
    }
    return null;
  };

  useEffect(() => {
    const notificationsFilter = getFilteredNotifications();
    if (notificationsFilter) {
      setContador(notificationsFilter.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();
  useEffect(() => {
    window.addEventListener("resize", updateColor);
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };

  const handleLogout = () => {
    setAnchorEl(null);
    dispatch(startLogout());
  };
  useEffect(() => {
    const mainPanel = document.getElementById("main-panel");
    if (mainPanel) {
      if (openNotifications) {
        mainPanel.classList.add("opacity-main-panel");
      } else {
        mainPanel.classList.remove("opacity-main-panel");
      }
    }
  }, [openNotifications]);

  const handleGoHome = () => {
    history.replace("/admin/home");
  };

  const handleGoAccount = () => {
    history.replace("/admin/account");
  };
  const associateDealerWalletValues = associate ?? dealer;
  const refillBalance =
    [Roles_Enum.ASSOCIATE, Roles_Enum.DEALER].includes(
      user?.roles.name as Roles_Enum
    ) && associateDealerWalletValues
      ? associateDealerWalletValues.refillsBalance
      : pointSale?.amount;
  const betsBalance =
    [Roles_Enum.ASSOCIATE, Roles_Enum.DEALER].includes(
      user?.roles.name as Roles_Enum
    ) && associateDealerWalletValues
      ? associateDealerWalletValues.betsBalance
      : pointSale?.amountBets;

  const handleReloadBalanceWallet = async () => {
    if (user) {
      setIsReloading(true);
      const {
        roles: { name: rolName },
      } = user;
      if (rolName === Roles_Enum.ASSOCIATE) {
        await dispatch(startGetAssociateWallet());
      }
      if (
        [Roles_Enum.POINT_SALE, Roles_Enum.SELLER].includes(
          rolName as Roles_Enum
        )
      ) {
        await dispatch(startGetPointSaleWallet());
      }
      if (rolName === Roles_Enum.DEALER) {
        await dispatch(startGetDealerWallet());
      }
      setIsReloading(false);
    }
  };

  const dealerType = user?.dealer?.type;
  const sellerType = pointSale?.pointSale.type;

  return (
    <>
      <Navbar
        className={classNames("navbar-absolute", {
          [color]: props.location.pathname.indexOf("full-screen-map") === -1,
        })}
        expand="lg"
      >
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            xl={8}
            lg={7}
            md={7}
            sm={7}
            xs={6}
            display="flex"
            justifyContent={{
              xs: "flex-star",
              sm: "center",
              md: "center",
              lg: "center",
            }}
            alignItems="center"
          >
            {user &&
              [
                Roles_Enum.SELLER,
                Roles_Enum.ASSOCIATE,
                Roles_Enum.DEALER,
              ].includes(user.roles.name as Roles_Enum) &&
              dealerType !== DealerTypeEnum.CARDS &&
              sellerType !== DealerTypeEnum.CARDS && (
                <Box display="flex" gap={5}>
                  <Box display="flex" gap={5}>
                    <p
                      className="txt-primary-md"
                      style={{ fontWeight: 600, fontSize: 13, margin: 0 }}
                    >
                      {`${t("associates.refillBalance")}: ${
                        refillBalance ?? "..."
                      }`}
                    </p>
                    <p
                      className="txt-primary-md"
                      style={{ fontWeight: 600, fontSize: 13, margin: 0 }}
                    >
                      {`${t("irrigation.bettingBalance")}: ${
                        betsBalance ?? "..."
                      }`}
                    </p>
                  </Box>
                  <Box mt={2}>
                    <UserBalanceContainer>
                      <ReloadButton
                        //@ts-ignore
                        onClick={handleReloadBalanceWallet}
                        updatedBalance={isReloading}
                      >
                        <ReloadIcon color="#333333" />
                      </ReloadButton>
                    </UserBalanceContainer>
                  </Box>
                </Box>
              )}
            <Box
              sx={{
                display: { xs: "block", sm: "none" },
                textAlign: "start",
                cursor: "pointer",
              }}
            >
              <img
                onClick={props.toggleSidebar}
                className="navbar-logo-mini"
                src={imageLogoSm}
                alt="NEAT LOGO"
                onMouseOver={() => setImageLogoSm(logoSmHover)}
                onMouseLeave={() => setImageLogoSm(logoSm)}
              />
            </Box>
            <Box
              sx={{
                display: { xs: "none", sm: "block" },
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <img
                onClick={handleGoHome}
                className="navbar-logo"
                src={imageLogo}
                alt="logo"
                onMouseOver={() => setImageLogo(logoHover)}
                onMouseLeave={() => setImageLogo(logo)}
              />
            </Box>
          </Grid>
          <Grid
            item
            xl={4}
            lg={5}
            md={5}
            sm={5}
            xs={6}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              rowSpacing={0}
              spacing={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid
                item
                lg={4}
                md={4}
                sm={3}
                xs={2}
                display="flex"
                justifyContent="space-around"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  //sx={{ display: { xs: "none", sm: "none", md: "block" } }}
                >
                  {user && (
                    <section>
                      <Typography
                        className={"color-text"}
                        sx={{ fontWeight: 700 }}
                        fontSize={sizeText}
                        style={{ paddingRight: 10 }}
                        align="right"
                      >
                        {t("navbar.hello")} {user.username}
                      </Typography>

                      <Typography
                        className={"color-text capitalize-text"}
                        fontSize={sizeText}
                        style={{ paddingRight: 10 }}
                        align="right"
                      >
                        {user.roles.name}
                      </Typography>
                    </section>
                  )}
                </Box>
                <Box display="flex" alignItems="center">
                  <IconButton
                    onClick={() =>
                      (currentPath !== "/admin/home" ||
                        (currentPath === "/admin/home" &&
                          isLessThanMediumScreen)) &&
                      dispatch(uiSetOpenNotifications())
                    }
                    size="small"
                    disableRipple
                    style={{
                      border: "none",
                      outline: "none",
                      boxShadow: "none",
                    }}
                  >
                    <Badge color="error" badgeContent={contador}>
                      <NotificationsNoneIcon
                        sx={{
                          fontSize: "35px",
                          color: "#333",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                    </Badge>
                    {/* {hasNotification && <NotificationIndicator />} */}
                    {/* <NotificationIndicator /> */}
                  </IconButton>
                </Box>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={7}
                display="flex"
                justifyContent="space-around"
                alignItems="center"
              >
                <Divider orientation="vertical" variant="middle" flexItem />
                <Box display="flex" flexDirection="column">
                  <Typography
                    className={"color-text"}
                    align="right"
                    fontSize={sizeText}
                  >
                    {date}
                  </Typography>
                  <Typography
                    className="color-text"
                    fontSize={{ lg: 12, md: 10, xs: 9 }}
                  >
                    {utc} <strong>{hour}</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                lg={4}
                md={3}
                sm={3}
                xs={3}
                display="flex"
                justifyContent="space-around"
                alignItems="center"
              >
                <Divider orientation="vertical" variant="middle" flexItem />
                <div className="settings">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    ml={1}
                  >
                    <Typography fontSize={sizeText} className="color-text">
                      {t("sidebar.settings")}
                    </Typography>

                    <IconButton
                      onClick={handleClick}
                      size="small"
                      aria-controls={open ? "notifications" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      style={{
                        border: "none",
                        outline: "none",
                      }}
                    >
                      <i
                        style={{ fontSize: 10 }}
                        className="fa-solid fa-chevron-down"
                      ></i>
                    </IconButton>
                  </Box>

                  <Menu
                    id="account-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>{t("menu.help")}</MenuItem>
                    <MenuItem onClick={handleGoAccount}>
                      {t("menu.account")}
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                      {t("menu.goOut")}
                    </MenuItem>
                  </Menu>
                </div>
                <div className="settings-icon">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                  >
                    <i
                      style={{ fontSize: 20 }}
                      className="tim-icons icon-settings-gear-63"
                    ></i>
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Navbar>
    </>
  );
};
