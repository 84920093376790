import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { headersTransactionsSettings } from "../../../helpers/headersTables";
import { AppState } from "../../../redux/reducers/rootReducer";
import moment from "moment";
import { ITransaction } from "../../../interfaces/transactionSettings";
import {
  getCopyArrayOrObject,
  getStateSettingName,
} from "../../../utils/utils";
import {
  DetailTransactionalSettings,
  ResponsibleSetting,
} from "../../../enums/enums";

export const TransactionsDataTable = (totalData: ITransaction[]) => {
  const { t } = useTranslation();
  const { settings } = useSelector((state: AppState) => state.transactions);
  //   COLUMNS HEADERS STYLES
  const headersCvsStyle = headersTransactionsSettings.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  // Obtener datos por vista
  const dataTransactionSettings =
    settings && settings.data
      ? getCopyArrayOrObject(settings.data).map((setting: any) => {
          // @ts-ignore

          setting.state = getStateSettingName(setting.state);
          setting.createAt = moment(setting.createAt).format(
            "DD-MM-YYYY hh:mm:ss a"
          );
          return setting;
        })
      : [];

  // Obtener total datos
  const dataTotalTransactionSettings = totalData
    ? getCopyArrayOrObject(totalData).map((setting: any) => {
        // @ts-ignore
        setting.state = getStateSettingName(setting.state);
        setting.createAt = moment(setting.createAt).format(
          "DD-MM-YYYY hh:mm:ss a"
        );
        return setting;
      })
    : [];

  //   DATA TOTAL
  const multiTotalDataSetTransaction = [
    {
      columns: headersCvsStyle,
      data: dataTotalTransactionSettings.map((data: any) => {
        let array;
        const detail = (DetailTransactionalSettings as any)[data.detail] || "";
        const responsible = (ResponsibleSetting as any)[data.responsible] || "";
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: data.state,
            style: {
              fill: {
                patternType: "solid",
                fgColor: {
                  rgb: data.state === t("state.realized") ? "c6efce" : "ffc7ce",
                },
              },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: data.player.email,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.movementType.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.wallet.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.type.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: t(`transactionalSettings.${detail}`, detail),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: responsible ? t(`transactionalSettings.${responsible}`) : "",
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: data.value,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.createAt,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.observations,
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];
  const multiDataSetTransaction = [
    {
      columns: headersCvsStyle,
      data: dataTransactionSettings.map((data: any) => {
        let array;
        const detail = (DetailTransactionalSettings as any)[data.detail] || "";
        const responsible = (ResponsibleSetting as any)[data.responsible];
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: data.state,
            style: {
              fill: {
                patternType: "solid",
                fgColor: {
                  rgb: data.state === t("state.realized") ? "c6efce" : "ffc7ce",
                },
              },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: data.player.email,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.movementType.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.wallet.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.type.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: t(`transactionalSettings.${detail}`, detail),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: responsible ? t(`transactionalSettings.${responsible}`) : "",
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.value,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.createAt,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.observations,
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];
  const finalData = {
    dataPerView: dataTransactionSettings,
    dataTotal: dataTotalTransactionSettings,
    headersCSV: headersTransactionsSettings,
    multiDataSet: multiDataSetTransaction,
    multiTotalDataSet: multiTotalDataSetTransaction,
  };

  return finalData;
};
