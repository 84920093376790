// React & hooks
import { useState, useEffect, KeyboardEvent, useRef } from "react";
import { Typography, makeStyles } from "@material-ui/core";
// Redux & actions
import { useDispatch, useSelector } from "react-redux";
import {
  setCloseAlert,
  setFlagForm,
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import {
  setActiveBonus,
  starGetBonus,
  startCreateBonus,
  startCreateBonusAltenar,
  startGetBonusType,
  startUpdateBonus,
} from "../../../redux/actions/bonusActions";
import { setCompaniesRedux } from "../../../redux/actions/managementCompanyActions";

// UI Components
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Checkbox,
} from "@mui/material";

// Custom components
import { MyTextField } from "../custom/MyTextField";
import { MyDialog } from "../custom/MyDialog";
import { MyAlert } from "../custom/MyAlert";
import { AltenarBonusForm } from "./AltenarBonusForm";

// Form & validations
import { Form, Formik } from "formik";
import * as yup from "yup";

// Utilities & constants
import { useTranslation } from "react-i18next";
import moment from "moment";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  State,
  Roles_Enum,
  ProvidersBonus,
  AltenarBonusCampain,
} from "../../../enums/enums";
import { regLettersNumbers } from "../../../utils/utils";

// Services
import {
  getOperatorByIdService,
  getOperatorsService,
} from "../../../services/managementCompanyService";
import { getProviders } from "../../../services/providers";

// Assets
import clearLogo from "../../../assets/svg/icon-park_clear-format.svg";

// Styles
import "./styles.scss";
import { PragmaticBonusForm } from "./PragmaticBonusForm";
import { PariplayBonusForm } from "./PariplayBonusForm";

// Styles for disabled input and select elements
// const disabledElementStyles = {
//   background: "#F5F5F5",
//   border: "1px solid #CED4DA",
//   borderRadius: "6px",
// };

// const styles = {
//   "& input.Mui-disabled": { ...disabledElementStyles },
//   "& .MuiSelect-select.Mui-disabled": { ...disabledElementStyles },
// };

// Constants for menu props
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const FormCreateBonus = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { activeBonus } = useSelector((state: AppState) => state.bonus);
  const { error } = useSelector((state: AppState) => state.ui);
  const { token, user } = useSelector((state: AppState) => state.auth);
  const { openAlert, messageAlert, typeAlert, loading } = useSelector(
    (state: AppState) => state.helper
  );
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );

  const [submit, setSubmit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [cancel, setCancel] = useState(false);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const [operatorId, setOperatorId] = useState<number | null>(
    activeBonus
      ? activeBonus.operator.id
      : !isSuper && user
      ? user.operator.id
      : null
  );
  const [totalData, setTotalData] = useState<any>();
  const [currencyOp, setCurrencyOp] = useState<string>("");
  const [countryOp, setCountryOp] = useState<string>("");

  const [providers, setProviders] = useState<any[]>([]);

  const formikRef = useRef(null);

  const whiteListProviders = ["Altenar", "Pragmatic Play", "Pariplay"];

  const getOperatorById = async () => {
    if (operatorId && token) {
      const operator = await getOperatorByIdService(token, operatorId);
      operator.currencyOp[0]
        ? setCurrencyOp(operator.currencyOp[0].currency.code)
        : setCurrencyOp("USD");
      operator.country.code
        ? setCountryOp(operator.country.code)
        : setCountryOp("US");
    }
  };
  useEffect(() => {
    getOperatorById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatorId]);

  const initialValues = {
    id: activeBonus ? activeBonus.id : null,
    name: activeBonus ? activeBonus.name : "",
    state: activeBonus ? activeBonus.state : "",
    type: activeBonus?.bonusType ? activeBonus.bonusType.id : "",
    startTime: activeBonus ? activeBonus.startTime : "",
    endTime: activeBonus ? activeBonus.endTime : "",
    startDate: activeBonus
      ? moment(activeBonus.startDate).format("yyyy-MM-DD")
      : "",
    endDate: activeBonus
      ? moment(activeBonus.endDate).format("yyyy-MM-DD")
      : "",
    expirationDays: activeBonus?.expirationDays
      ? activeBonus.expirationDays
      : "",
    value: activeBonus?.value ? activeBonus.value : "",
    amountBonus: activeBonus?.amountBonus ? activeBonus.amountBonus : "",
    totalBondsValue: activeBonus?.totalValue ? activeBonus.totalValue : "",
    /* ROLLOVER */
    minimumDeposit: activeBonus?.minimumDeposit
      ? activeBonus.minimumDeposit
      : "",
    minimumSportsLines: activeBonus?.minimumSportsLines
      ? activeBonus.minimumSportsLines
      : "",
    maximumSportsLines: activeBonus?.maximumSportsLines
      ? activeBonus.maximumSportsLines
      : "",
    minimumSportsFee: activeBonus?.minimumSportsFee
      ? parseFloat(activeBonus.minimumSportsFee as any)
      : "",
    paymentMethod: activeBonus?.paymentMethod ? activeBonus.paymentMethod : "",

    registrationDate: activeBonus?.registrationDate
      ? moment(activeBonus.registrationDate).format("yyyy-MM-DD")
      : "",
    /* ROLLOVER */
    operator: activeBonus && activeBonus.operator.id,
    createdUser: activeBonus && activeBonus.createdUser.id,
    provider: activeBonus?.provider ? activeBonus.provider.id : "",
    code: activeBonus?.code ? activeBonus.code : "",
    redemtionCode: activeBonus?.redemtionCode ? activeBonus.redemtionCode : "",
    isMassive: activeBonus?.isMassive || false,
    altenarBonusType: activeBonus?.altenarBonusType || "",
    providerBonusId: activeBonus?.providerBonusId || "",
    pragmaticNumberRounds: activeBonus?.pragmaticNumberRounds || "",
    pragmaticBonus: activeBonus?.pragmaticBonus || [], // json con los juegos vinculados al bono
    casinoBonusType: activeBonus?.casinoBonusType || "",
    percentageDeposit: activeBonus?.percentageDeposit || "", //porcentaje del deposito aplicado al bono
    minDeposit: activeBonus?.minDeposit || "",
    maxDeposit: activeBonus?.maxDeposit || "",
    maxBonus: activeBonus?.maxBonus || "",
    payMethods: activeBonus?.payMethods || [],
  };

  const validationSchema = yup.object({
    name: yup.string().required(t("form.requiredFile")),
    state: yup.number().required(t("form.requiredFile")),

    /* ALTENAR BONUS */
    startDate: yup
      .date()
      .required(t("form.requiredFile"))
      .test(
        "isDateRangeValid",
        t("managementBonds.isStartDateRangeValid"),
        function (value) {
          const endDate: any = this.resolve(yup.ref("endDate"));
          return moment(value).isSameOrBefore(endDate, "day");
        }
      ),

    endDate: yup
      .date()
      .required(t("form.requiredFile"))
      .test(
        "isDateRangeValid",
        t("managementBonds.isEndDateRangeValid"),
        function (value) {
          const startDate: any = this.resolve(yup.ref("startDate"));
          return moment(value).isSameOrAfter(startDate, "day");
        }
      ),

    provider: yup.number().required(t("form.requiredFile")),
    code: yup.string(),

    altenarBonusType: yup.number().when("provider", {
      is: (provider: any) => {
        const providerValue = providers.find((prov) => prov.value === provider);
        return providerValue && providerValue.label === "Altenar";
      },
      then: yup.number().required(t("form.requiredFile")),
      otherwise: yup.number(),
    }),
    redemtionCode: yup.string().when("altenarBonusType", {
      is: (value: any) =>
        value === AltenarBonusCampain.FreeBetBonus ||
        value === AltenarBonusCampain.CashbackBonus ||
        value === AltenarBonusCampain.RiskFreeBet,
      then: yup.string().required("El código de redención es obligatorio"),
      otherwise: yup.string().notRequired(),
    }),
    providerBonusId: yup.string().when("provider", {
      is: (provider: any) => {
        const providerValue = providers.find((prov) => prov.value === provider);
        return providerValue && providerValue.label === "Altenar";
      },
      then: yup.string().required(t("form.requiredFile")),
      otherwise: yup.string(),
    }),

    value: yup.number().when(["provider", "percentageDeposit"], {
      is: (provider: any, percentageDeposit: number) => {
        const providerValue = providers.find((prov) => prov.value === provider);

        return (
          providerValue &&
          providerValue.label === "Altenar" &&
          !percentageDeposit
        );
      },
      then: yup.number().required(t("form.requiredFile")),
      otherwise: yup.number(),
    }),

    percentageDeposit: yup.lazy((_v) => {
      return yup.number().when(["provider", "value"], {
        is: (provider: any, value: number) => {
          const providerValue = providers.find(
            (prov) => prov.value === provider
          );
          return (
            providerValue &&
            providerValue.label === "Altenar" &&
            (!value || String(value).trim() === "")
          );
        },
        then: yup
          .number()
          .moreThan(0, t("managementBondsgreaterThanZero"))
          .max(100, t("managementBonds.errorMaxValuePercentage"))
          .required(t("form.requiredFile")),
        otherwise: yup.number(),
      });
    }),

    minDeposit: yup.lazy((_v, { parent }) => {
      return yup.number().when(["provider", "value"], {
        is: (provider: any, percentageDeposit: number) => {
          const providerValue = providers.find(
            (prov) => prov.value === provider
          );
          return (
            providerValue &&
            providerValue.label === "Altenar" &&
            (!percentageDeposit || String(percentageDeposit).trim() === "")
          );
        },
        then: yup
          .number()
          .moreThan(0, t("managementBondsgreaterThanZero"))
          .required(t("form.requiredFile"))
          .test(
            "is-less-than-max-deposit",
            t("managementBonds.minDepositMustBeLessThanMaxDeposit"), // Mensaje de error personalizado
            function (value) {
              const { maxDeposit } = parent;
              return !maxDeposit || !value || value < maxDeposit;
            }
          ),
        otherwise: yup.number(),
      });
    }),
    maxDeposit: yup.lazy((_v) => {
      return yup.number().when(["provider", "value"], {
        is: (provider: any, percentageDeposit: number) => {
          const providerValue = providers.find(
            (prov) => prov.value === provider
          );
          return (
            providerValue &&
            providerValue.label === "Altenar" &&
            (!percentageDeposit || String(percentageDeposit).trim() === "")
          );
        },
        then: yup
          .number()
          .moreThan(0, t("managementBondsgreaterThanZero"))
          .required(t("form.requiredFile")),
        otherwise: yup.number(),
      });
    }),

    maxBonus: yup.lazy((_v) => {
      return yup.number().when(["provider", "value"], {
        is: (provider: any, percentageDeposit: number) => {
          const providerValue = providers.find(
            (prov) => prov.value === provider
          );
          return (
            providerValue &&
            providerValue.label === "Altenar" &&
            (!percentageDeposit || String(percentageDeposit).trim() === "")
          );
        },
        then: yup
          .number()
          .moreThan(0, t("managementBondsgreaterThanZero"))
          .required(t("form.requiredFile")),
        otherwise: yup.number(),
      });
    }),

    amountBonus: yup
      .number()
      .min(0)
      .when(["provider", "percentageDeposit"], {
        is: (provider: any, percentageDeposit: number) => {
          const providerValue = providers.find(
            (prov) => prov.value === provider
          );
          return (
            providerValue &&
            providerValue.label === "Altenar" &&
            !percentageDeposit
          );
        },
        then: yup.number().required(t("form.requiredFile")),
        otherwise: yup.number(),
      }),

    totalBondsValue: yup.number().when(["provider", "percentageDeposit"], {
      is: (provider: any, percentageDeposit: number) => {
        const providerValue = providers.find((prov) => prov.value === provider);
        return (
          providerValue &&
          providerValue.label === "Altenar" &&
          percentageDeposit
        );
      },
      then: yup.number().required(t("form.requiredFile")),
      otherwise: yup.number(),
    }),

    isMassive: yup.boolean().required(t("form.requiredFile")),
    pragmaticBonus: yup.array().when("provider", {
      is: (provider: any) => {
        const providerValue = providers.find((prov) => prov.value === provider);

        return providerValue && providerValue.label === whiteListProviders[1];
      },
      then: yup
        .array()
        .min(1, t("pragmatic.almostOneGame"))

        .required(t("form.requiredFile")),
      otherwise: yup.array(),
    }),
    pragmaticNumberRounds: yup
      .number()
      .moreThan(0, t("managementBonds.greaterThanZero"))
      .when("provider", {
        is: (provider: any) => {
          const providerValue = providers.find(
            (prov) => prov.value === provider
          );
          return providerValue && providerValue.label === whiteListProviders[1];
        },
        then: yup
          .number()
          .moreThan(0, t("managementBonds.greaterThanZero"))

          .required(t("form.requiredFile")),
        otherwise: yup
          .number()
          .moreThan(0, t("managementBonds.greaterThanZero")),
      }),
    casinoBonusType: yup.number().when("provider", {
      is: (provider: any) => {
        const providerValue = providers.find((prov) => prov.value === provider);
        return providerValue && providerValue.label === whiteListProviders[1];
      },
      then: yup.number().required(t("form.requiredFile")),
      otherwise: yup.number(),
    }),
  });

  const state = [
    { label: t("state.active"), value: State.ACTIVE },
    { label: t("state.inactive"), value: State.INACTIVE },
  ];

  const fetchProviders = async () => {
    if (token) {
      const response = await getProviders(token, {});

      if (!response.error) {
        const finalData = response
          .filter((prov: any) => prov.name !== "Punto pago")
          .map((prov: any) => ({
            label: prov.name,
            value: prov.id,
          }));

        setProviders(finalData);
      }
    }
  };
  useEffect(() => {
    fetchProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchBonusType = async () => {
    if (!user) {
      throw new Error("INVALID ACTION");
    }
    let params: any = {
      state: State.ACTIVE,
    };
    if (operatorId) {
      params = {
        ...params,
        operator: operatorId,
      };

      await dispatch(startGetBonusType(params));
    }
  };
  useEffect(() => {
    fetchBonusType();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatorId]);

  const handleCancel = () => {
    setMessage("dialog.sureExit");
    setCancel(true);
    setOpenDialog(true);
  };

  const normalizeData = (formData: any) => {
    const data = { ...formData };

    if (data.registrationDate) {
      data.registrationDate = moment(data.registrationDate)
        .startOf("day")
        .toISOString();
    }

    for (let key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }

    return data;
  };

  const showErrorDateAlert = () => {
    dispatch(setOpenAlert());
    dispatch(setMessageAlert("alerts.errorDate"));
    dispatch(setTypeAlert("error"));
    dispatch(setLoadingState({ loading: false }));
  };

  const validateDates = (startDate: string, endDate: string) => {
    return moment(startDate).isAfter(endDate);
  };

  const handleBonusCreation = async (data: any) => {
    if (data.provider === ProvidersBonus.ALTENAR) {
      await dispatch(startCreateBonusAltenar(data));
    } else {
      await dispatch(startCreateBonus(data));
    }
  };

  const validateExistsBonus = async (formData: any) => {
    dispatch(setLoadingState({ loading: true }));
    const data = normalizeData(formData);

    if (validateDates(data.startDate, data.endDate)) {
      return showErrorDateAlert();
    }
    const isPragmaticProvider = formData.provider === ProvidersBonus.PRAGMATIC;
    const bonusId = isPragmaticProvider ? data.id : data.name.id;

    activeBonus && activeBonus.id === bonusId
      ? await dispatch(startUpdateBonus(data))
      : await handleBonusCreation(data);
    await dispatch(starGetBonus());
  };

  useEffect(() => {
    return () => {
      dispatch(setActiveBonus(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const _handleCancel = () => {
    dispatch(setActiveBonus(null));
    dispatch(setFlagForm({ flagFormCreate: false }));
    setCancel(false);
  };

  const handleValidationAndSubmission = async () => {
    (formikRef.current as any).setSubmitting(true);
    await validateExistsBonus(totalData);
    (formikRef.current as any).setSubmitting(false);
    setCancel(false);
  };

  const handleAccept = async () => {
    handleCloseDialog();

    if (cancel) {
      _handleCancel();
    } else {
      await handleValidationAndSubmission();
    }
  };

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
    if (typeAlert === "success") {
      dispatch(setFlagForm({ flagFormCreate: false }));
      dispatch(setLoadingState({ loading: false }));
      dispatch(setActiveBonus(null));
    }
  };

  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    if (token) {
      fetchOperators(token);
    }
    return () => {
      dispatch(setCompaniesRedux(null));
      dispatch(setFlagForm({ flagFormCreate: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMessage = () => {
    return !!activeBonus ? "dialog.sureEdit" : "managementBonds.sureCreateBond";
  };

  const openConfirmation = () => {
    setMessage(getMessage());
    setOpenDialog(true);
    setCancel(false);
  };

  const handleClean = () => {
    // @ts-ignore
    if (formikRef.current) formikRef.current.resetForm();
    setOpenDialog(false);
  };

  const componentMap = {
    Altenar: (formikRef: any) => <AltenarBonusForm formikRef={formikRef} />,
    "Pragmatic Play": (formikRef: any, currencyOp: string) => (
      <PragmaticBonusForm formikRef={formikRef} currency={currencyOp} />
    ),
    Pariplay: (formikRef: any) => (
      <PariplayBonusForm
        currency={currencyOp}
        formikRef={formikRef}
        country={countryOp}
      />
    ),
  };

  const getProviderBonusForm = (id: number | string) => {
    const providerDetail = providers.find((prov: any) => prov.value === id);
    // @ts-ignore
    if (providerDetail && componentMap[providerDetail.label]) {
      // @ts-ignore
      return componentMap[providerDetail.label](formikRef.current, currencyOp);
    }
    return <></>;
  };

  const useStyles = makeStyles((theme: any) => ({
    infoIcon: {
      color: "#02D0E7",
      marginRight: theme.spacing(1.125), // roughly equivalent to 9px
      fontSize: 17,
    },
    title: {
      fontWeight: "bold",
      color: "black",
    },
    titleBox: {
      display: "flex",
      borderBottom: `1px solid #02D0E7`,
      paddingBottom: theme.spacing(0.25), // roughly equivalent to 4px
    },
    formControl: {
      width: "100%",
    },
    errorLabel: {
      marginLeft: theme.spacing(0.75),
      color: "#d32f2f",
    },
  }));
  const classes = useStyles();

  return (
    <Grid container spacing={2} mt={1} pl={1} pr={1}>
      <Grid item xs={12} className="mb-4">
        <Box className={classes.titleBox}>
          <i className={`fa-solid fa-circle-info ${classes.infoIcon}`}></i>
          <Typography variant="body1" className={classes.title}>
            {t("managementBonds.createBonds")}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          onSubmit={async (data) => {
            if (operatorId) {
              data.operator = operatorId;
              setTotalData(data);
              openConfirmation();
            }
          }}
        >
          {({
            values,
            isValid,
            setFieldValue,
            handleChange,
            errors,
            isSubmitting,
          }) => (
            <Form data-testid={"bonusForm"}>
              <Grid container spacing={4}>
                {
                  //MOSTRAR SELECT CON OPERADORES CUANDO SEA SUPER_ADMIN
                  isSuper && companies && (
                    <Grid item xs={4}>
                      <FormControl
                        className={classes.formControl}
                        size="small"
                        error={submit && isSuper && !operatorId}
                        disabled={!!activeBonus}
                      >
                        <InputLabel id="operator-label">
                          {t("companyManagement.operator") + "*"}
                        </InputLabel>
                        <Select
                          labelId="operator-label"
                          name="operator"
                          size="small"
                          fullWidth
                          onChange={(e: any) => setOperatorId(e.target.value)}
                          value={!operatorId ? "" : operatorId}
                          input={
                            <OutlinedInput
                              label={t("companyManagement.operator") + "*"}
                            />
                          }
                          MenuProps={MenuProps}
                          disabled={!!activeBonus}
                        >
                          {companies.data.map((company) => (
                            <MenuItem key={company.id} value={company.id}>
                              {company.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormHelperText>
                        {submit && !operatorId && (
                          <label className={classes.errorLabel}>
                            {t("form.requiredFile")}
                          </label>
                        )}
                      </FormHelperText>
                    </Grid>
                  )
                }
                <Grid item xs={4}>
                  <MyTextField
                    name="name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="text"
                    label={`${t("managementBonds.bondName")}*`}
                    inputProps={{
                      "data-testid": "bonusName",
                      maxLength: 50,
                    }}
                    onChange={handleChange}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regLettersNumbers(e.key) && e.preventDefault()
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <MyTextField
                    id="stateTextField"
                    name="state"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={values.state}
                    onChange={(e: any) =>
                      setFieldValue("state", e.target.value)
                    }
                    role={"combobox"}
                    select
                    label={`${t("form.state")}*`}
                    inputProps={{
                      id: "stateInput",
                    }}
                  >
                    {state.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        sx={{
                          "&.Mui-focused": {
                            backgroundColor: "red",
                          },
                        }}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </MyTextField>
                </Grid>

                <Grid item xs={4}>
                  <MyTextField
                    id="provider"
                    name="provider"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={!!activeBonus}
                    value={values.provider}
                    onChange={(e: any) =>
                      setFieldValue("provider", e.target.value)
                    }
                    role={"combobox"}
                    select
                    label={`${t("managementBonds.provider")}*`}
                  >
                    {providers
                      .filter((prov) => whiteListProviders.includes(prov.label))
                      .map((item) => (
                        <MenuItem
                          key={item.value}
                          value={item.value}
                          sx={{
                            "&.Mui-focused": {
                              backgroundColor: "red",
                            },
                          }}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                  </MyTextField>
                </Grid>

                {getProviderBonusForm(Number(values.provider))}

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isMassive"
                        checked={values.isMassive}
                        onChange={handleChange}
                      />
                    }
                    label={`${t("managementBonds.isMassiveBonus")}*`}
                  />
                </Grid>

                <Box
                  display={"flex"}
                  mt={9}
                  flex={1}
                  justifyContent="flex-end"
                  alignItems={"flex-end"}
                >
                  <Button
                    variant="contained"
                    className="footer-btn danger-btn ripple"
                    style={{ border: "none", outline: "none" }}
                    onClick={handleCancel}
                    disabled={!!isSubmitting || !!loading}
                    data-testid={"btnCancel"}
                  >
                    {t("button.cancel").toUpperCase()}
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    className="footer-btn primary-btn ripple"
                    style={{ border: "none", outline: "none" }}
                    disabled={
                      isSubmitting ||
                      !isValid ||
                      loading ||
                      error ||
                      (activeBonus?.totalBonusDelivered as any) > 0
                    }
                    data-testid={"btnSave"}
                    onClick={() => setSubmit(true)}
                  >
                    {isSubmitting || !!loading ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      <>{t("button.save").toUpperCase()}</>
                    )}
                  </Button>

                  <Button
                    style={{
                      border: "none",
                      outline: "none",
                      marginLeft: "4px",
                    }}
                    disabled={activeBonus !== null}
                    onClick={() => {
                      setMessage("dialog.sureClean");
                      setOpenDialog(true);
                    }}
                  >
                    <img src={clearLogo} alt="Icon clear" />
                  </Button>
                </Box>
              </Grid>

              <MyDialog
                id="confirmationDialog"
                data-testid="confirmationDialog"
                open={openDialog}
                title={t("dialog.confirmationWindow")}
                message={t(message)}
                handleAccept={
                  message === "dialog.sureClean" ? handleClean : handleAccept
                }
                handleClose={handleCloseDialog}
                isLoading={isSubmitting || !!loading}
              />
            </Form>
          )}
        </Formik>

        <MyAlert
          open={openAlert}
          message={messageAlert ? messageAlert : ""}
          typeAlert={typeAlert}
          handleClose={onCloseAlert}
        />
      </Grid>
    </Grid>
  );
};
