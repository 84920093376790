import { Form, Formik, useFormikContext } from "formik";
import React, { FC, KeyboardEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";

import { MyTextField } from "../../custom/MyTextField";
import { regNumbers } from "../../../../utils/utils";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { BalanceType, State, TransferConcept } from "../../../../enums/enums";
import { startCreateIrrigation } from "../../../../redux/actions/transactionsActions";
import { IrrigationForm } from "../../../../interfaces/irrigations";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import { MyDialog } from "../../custom/MyDialog";

interface Props {
  expand: boolean;
  setExpand: React.Dispatch<React.SetStateAction<boolean>>;
  balance: BalanceType;
  setResetInfo: React.Dispatch<React.SetStateAction<boolean>>;
  resetInfo: boolean;
  setResetGenerate: React.Dispatch<React.SetStateAction<boolean>>;
  resetGenerate: boolean;
}

export const GenerateTransfer: FC<Props> = ({
  expand,
  setExpand,
  balance: balanceType,
  setResetInfo,
  resetInfo,
  setResetGenerate,
  resetGenerate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: AppState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [newTransfer, setNewTransfer] = useState<IrrigationForm | null>(null);
  const { activeAssociate } = useSelector(
    (state: AppState) => state.associates
  );
  const initialValues = {
    transferConcept: "",
    value: "",
  };

  const validationSchema = yup.object({
    transferConcept: yup.number(),
    value: yup.number().moreThan(0, t("managementBonds.greaterThanZero")),
  });

  const handleGenerateTransfer = async (
    transferConcept: TransferConcept,
    value: string
  ) => {
    if (!activeAssociate) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("irrigation.searchAssociate"));
      dispatch(setTypeAlert("warning"));
    } else {
      if (activeAssociate.stateUser !== State.ACTIVE) {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("irrigation.errorAssociateInactive"));
        dispatch(setTypeAlert("error"));
      } else {
        const transfer: IrrigationForm = {
          balanceType,
          transferConcept,
          value,
          associate: activeAssociate.id!,
          operator: activeAssociate.operator.id,
          createdUser: user?.id!,
        };
        setNewTransfer(transfer);

        setOpenDialog(true);
      }
    }

    setLoading(false);
  };

  const handleAccept = async (resetForm: any) => {
    setLoading(true);
    setOpenDialog(false);

    newTransfer &&
      (await dispatch(
        startCreateIrrigation(newTransfer, resetForm, setResetInfo)
      ));

    setNewTransfer(null);
    setLoading(false);
  };

  const AutoReset = () => {
    const { resetForm } = useFormikContext();
    useEffect(() => {
      if (resetInfo || resetGenerate) {
        resetForm();
      }
      setResetInfo(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetInfo, resetGenerate]);
    return null;
  };

  return (
    <Box className="filter" py={1} sx={{ minHeight: expand ? "200px" : "" }}>
      <Box display="flex" justifyContent={"space-between"}>
        <Box display="flex" alignItems="center">
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <Typography
            color="text.primary"
            style={{ fontSize: "16px", fontWeight: 500 }}
          >
            {t("irrigation.generateTransfer")}
          </Typography>
        </Box>
        <IconButton
          size="small"
          style={{ border: "none", outline: "none" }}
          onClick={() => setExpand((prev) => !prev)}
        >
          {expand ? (
            <i className="fa fa-angle-up" />
          ) : (
            <i className="fa fa-angle-down" />
          )}
        </IconButton>
      </Box>
      {expand && (
        <Box width={1} borderTop={1} borderColor={"#02D0E7"} mt={1}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={true}
            enableReinitialize={true}
            onSubmit={async (data, { setSubmitting }) => {
              setSubmitting(true);
              await handleGenerateTransfer(
                // @ts-ignore
                data.transferConcept,
                data.value
              );
            }}
          >
            {({ handleChange, values, errors, resetForm }) => (
              <Form>
                <Grid container spacing={1} my={2}>
                  <Grid item md={6} xs={12}>
                    <MyTextField
                      name="transferConcept"
                      select
                      label={t("irrigation.transferConcept") + "*"}
                      onChange={handleChange}
                    >
                      <MenuItem value={TransferConcept.CASH}>
                        {t("companyManagement.cash")}
                      </MenuItem>
                    </MyTextField>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <MyTextField
                      name="value"
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={`${t("irrigation.transferValue")}*`}
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                        !regNumbers(e.key) && e.preventDefault()
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      className="primary-btn ripple"
                      style={{
                        border: "none",
                        outline: "none",
                        width: "20%",
                        marginTop: !errors.value ? "20px" : "",
                        color: "white",
                        opacity:
                          values.transferConcept === "" || values.value === ""
                            ? "0.5"
                            : "",
                      }}
                      disabled={
                        values.transferConcept === "" || values.value === ""
                      }
                    >
                      {loading ? (
                        <CircularProgress size={"1.6rem"} color="inherit" />
                      ) : (
                        t("button.save").toUpperCase()
                      )}
                    </Button>
                  </Grid>
                </Grid>
                <AutoReset />
                <MyDialog
                  open={openDialog}
                  title={t("dialog.confirmationWindow")}
                  message={t("irrigation.sureMakeTransfer")}
                  handleAccept={() => handleAccept(resetForm)}
                  handleClose={() => setOpenDialog(false)}
                  isLoading={loading}
                />
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </Box>
  );
};
