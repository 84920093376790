import React, { useEffect, useState } from "react";
import Filter from "../../../../components/Filter/Filter";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import { useTranslation } from "react-i18next";
import { setMovementTypes } from "../../../../redux/actions/transactionsActions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
  startGetCashFlow,
} from "../../../../redux/actions/helperActions";
import { Grid, Typography } from "@mui/material";
import MyCustomTable, {
  IColumns,
} from "../../../../components/Custom/Table/MyCustomTable";
import { RowCashFlow } from "./RowCashFlow";
import { arraySearch } from "../../../../redux/actions/searchActions";
import { CashFlowDataTable } from "../../../../hooks/useDataTable/reports/CashFlowDataTable";
import { getCashFlow } from "../../../../services/cashFlow";
import { CashFlowPdf } from "../../../../components/Pdf/Reports/CashFlowPdf";
import moment from "moment";
import { CustomAlert } from "../../../../components/Alerts/CustomAlert";
import { usePointSales } from "../../../../hooks/usePointSales";
import { buildFinalQueryParams } from "../../../../utils/utils";
import { DealerTypeEnum, TransactionTypesDB } from "../../../../enums/enums";

export const CashFlow = () => {
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { data } = useSelector((state: AppState) => state.search);
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);
  const [totalAmount, setTotalAmount] = useState(0);
  const [dataFilter, setDataFilter] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pointSales = usePointSales(
    {
      id: user && user.id,
      operator: user?.operator
        ? {
            id: user.operator.id,
            name: user.operator.name,
          }
        : null,
      roles: {
        id: user?.roles.id,
        name: user?.roles.name,
      },
    },
    token,
    DealerTypeEnum.ANONYMOUS
  );

  const transactionTypesList = [
    {
      label: t("withdrawals.cashWithdrawal"),
      value: TransactionTypesDB.WITHDRAWAL_CASH,
    },
    {
      label: t("withdrawals.balanceRecharge"),
      value: TransactionTypesDB.RECHARGE,
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(setMovementTypes([]));
      dispatch(arraySearch(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      const sumTotal = data.data.reduce((acc: number, item: any) => {
        const parseNumber = parseFloat(item.amount);
        if (isNaN(parseNumber)) {
          return acc;
        }
        return acc + parseNumber;
      }, 0);
      setTotalAmount(sumTotal.toFixed(2));
    }
  }, [data]);

  const filterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("transactions.transactionType"),
      type: Enum_FieldType.SELECT,
      options: [...transactionTypesList],
    },
    {
      label: t("shorcuts.PointSale"),
      type: Enum_FieldType.SELECT,
      options: [
        ...pointSales.map((pointSale) => ({
          label: pointSale.name,
          value: pointSale.id,
        })),
      ],
    },
  ];
  const columns: IColumns[] = [
    {
      align: "center",
      label: "ID",
    },
    {
      align: "center",
      label: t("shorcuts.PointSale"),
    },
    {
      align: "center",
      label: t("transactions.movementType"),
    },
    {
      align: "center",
      label: t("sidebar.player"),
    },
    {
      align: "center",
      label: t("transactions.valueTransaction"),
    },
    {
      align: "center",
      label: t("withdrawals.netValueWithdrawal"),
    },
    {
      align: "center",
      label: t("transactions.transactionType"),
    },
    {
      align: "center",
      label: t("transactions.createAt"),
    },
  ];
  const handleFilter = async (formValues: any) => {
    const startDate = formValues[t("filters.startDate")];
    const endDate = formValues[t("filters.endDate")];
    const pointSale = formValues[t("shorcuts.PointSale")];
    const transactionType = formValues[t("transactions.transactionType")];
    if (!pointSale) {
      dispatch(setMessageAlert("cashflow.selectPointSale"));
      dispatch(setTypeAlert("warning"));
      return dispatch(setOpenAlert());
    }
    const params = {
      startDate,
      endDate,
      transactionType,
      pointSale,
      page: 1,
    };

    await dispatch(startGetCashFlow(params));

    setDataFilter(params);
  };
  const onCleanFilter = async () => {
    dispatch(arraySearch(null));
    setDataFilter({});
  };
  const handlePageChange = async (page: number, rows?: number) => {
    await dispatch(
      startGetCashFlow({
        ...dataFilter,
        page,
        perPage: rows || rowsPerPage,
      })
    );
  };
  const getAllCashFlow = async (props: any) => {
    if (user && token) {
      const params: any = {
        ...dataFilter,
      };
      if (user.operator) {
        params.operator = user.operator.id;
      }
      delete params.perPage;
      const finalParams = buildFinalQueryParams(params);
      return await getCashFlow(token, finalParams);
    }
  };
  return (
    <>
      <div
        className="filter"
        style={{ padding: "18px 0px 0px", marginBottom: "18px" }}
      >
        <Filter
          fields={filterFields}
          handleFilter={handleFilter}
          onClean={onCleanFilter}
        />
      </div>
      {data ? (
        <div className="filter">
          <MyCustomTable
            columns={columns}
            data={data.data}
            lastPage={data.last_page}
            totalData={data.total}
            renderBody={(item) => <RowCashFlow row={item} />}
            onPageChange={handlePageChange}
            exportData
            hookBuildTable={CashFlowDataTable}
            getAllDataToExport={getAllCashFlow}
            pdfComponent={CashFlowPdf}
            titleCSV={`${t("reports.cashFlow")
              .split(" ")
              .join("-")}-${moment().toISOString()}`}
          >
            <Grid container bgcolor={"#F4F4F4"} p={1}>
              <Grid item xs={5} />

              <Grid
                item
                xs={1}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography variant="body2" fontWeight={"bold"} color={"#000"}>
                  TOTAL
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography
                  variant="body2"
                  color={"#208E9A"}
                  fontWeight={"bold"}
                >
                  {`$ ${totalAmount}`}
                </Typography>
              </Grid>
            </Grid>
          </MyCustomTable>
        </div>
      ) : (
        <CustomAlert htmlText={t("cashflow.filterText")} type="info" />
      )}
    </>
  );
};
