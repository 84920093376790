import React, { ChangeEvent, useRef } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../redux/reducers/rootReducer";
import "./formOperatorContent.scss";
import { setAlternativeOperatorLogo } from "../../../../../../redux/actions/contentManagementActions";
import { useTranslation } from "react-i18next";
import { UploadIcon } from "../../../../../../assets/svgComponents/UploadIcon";

export const FormAlternativeLogo = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const { alternativeLogo } = useSelector(
    (state: AppState) => state.contentManagement
  );
  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      dispatch(setAlternativeOperatorLogo(file));
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      (fileInputRef.current as any).click();
    }
  };

  return (
    <Grid container spacing={2} pl={1} pr={1}>
      <Grid item xs={12} className="operator-content-form">
        <Grid container></Grid>
        <Grid
          item
          xs={12}
          bgcolor={"#F4F4F4"}
          m={1}
          paddingBottom={1.8}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <input
            type="file"
            id="upload-logo"
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleImageUpload}
            ref={fileInputRef}
          />

          {alternativeLogo && (
            <Box
              bgcolor={"#FFF"}
              paddingLeft={2}
              paddingRight={2}
              borderRadius={6}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography color="#333">{alternativeLogo.name}</Typography>
              <IconButton
                style={{
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                }}
                onClick={() => dispatch(setAlternativeOperatorLogo(null))}
              >
                <i
                  className="fa-solid fa-circle-xmark"
                  style={{ color: "#333" }}
                ></i>
              </IconButton>
            </Box>
          )}

          <label
            htmlFor="upload-logo"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!alternativeLogo && (
              <Typography color="#333">
                {t("managContent.uploadLogo").toUpperCase()}
              </Typography>
            )}

            <IconButton
              style={{ border: "none", outline: "none", boxShadow: "none" }}
              onClick={handleButtonClick}
            >
              <UploadIcon />
            </IconButton>
          </label>
        </Grid>
      </Grid>
    </Grid>
  );
};
