import { IActivityQuery } from "../interfaces/activityPlayer";
import { IPaginationData } from "../interfaces/shared";

export const getActivityPlayer = (
    token: string,
    params: Partial<IPaginationData<IActivityQuery>>
  ) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        api: `${process.env.REACT_APP_API_KEY}`,
        Authorization: `bearer ${token}`,
      },
    };
    const queryParams = params
      ? new URLSearchParams(params as any).toString()
      : "";
  
    return fetch(
      `${process.env.REACT_APP_API_HTTP_PLAYERS}activity?${queryParams}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => responseData);
  };