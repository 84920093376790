import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DynamicTabs, IOptions } from "../../../components/Custom/DynamicTabs";
import { TransactionSettings } from "./TransactionSettings";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppState } from "../../../redux/reducers/rootReducer";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { Irrigation } from "./Irrigation";
import {
  setIrrigationsRedux,
  setWithdrawalsRedux,
} from "../../../redux/actions/transactionsActions";
import { WithdrawalRequest } from "./WithdrawalRequest";
import { Roles_Enum } from "../../../enums/enums";
import { MassiveTransactionSettings } from "./MassiveTransactionSettings";

export const Transactions = () => {
  const { user } = useSelector((state: AppState) => state.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const superRole = [
    Roles_Enum.SUPER_ADMIN,
    Roles_Enum.ADMIN_OPERATOR,
  ].includes(user?.roles?.name as Roles_Enum);

  const isPointSale = user && user.roles.name === Roles_Enum.POINT_SALE;
  const withdrawalPermissions =
    user &&
    (superRole ||
      (!superRole &&
        routes.some((menu) =>
          menu.menuItem.some(
            (item) => item.url === "/admin/transactions/withdrawals"
          )
        )));

  let optionsTabs: IOptions[] = [
    {
      id: 1,
      label: t("transactions.settings"),
    },
    {
      id: 2,
      label: t("transactions.massive"),
    },
    {
      id: 3,
      label: t("transactions.withdrawalRequest"),
    },
    {
      id: 4,
      label: t("transactions.irrigation"),
    },
  ];
  if (!withdrawalPermissions && !isPointSale) {
    optionsTabs = optionsTabs.filter((tab) => tab.id !== 2);
  }
  const [currentOption, setCurrentOption] = useState(
    isPointSale ? 2 : optionsTabs[0].id
  );
  const route = optionsTabs.find((opt) => opt.id === currentOption)?.label;

  const modulePermissions = routes
    .find(
      (route) =>
        !!route.menuItem.find((module) => module.url === location.pathname)
    )
    ?.menuItem.find((module) => module.url === location.pathname);
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;
  useEffect(() => {
    const option = new URLSearchParams(location.search).get("option");
    option && setCurrentOption(+option);
  }, [location]);
  const getComponent = (option: number) => {
    switch (option) {
      case 1:
        return <TransactionSettings enableCreated={!!enableCreated} />;
      case 2:
        return <MassiveTransactionSettings />;
      case 3:
        return <WithdrawalRequest standard={true} />; // bank
      case 4:
        return <Irrigation enableCreated={!!enableCreated} />;

      default:
        return <>BUILDING COMPONENT</>;
    }
  };
  const handleChangeBreadCumb = () => {
    setCurrentOption(1);
  };

  useEffect(() => {
    return () => {
      dispatch(setIrrigationsRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content w-100 mb-4">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyBreadCrumbs
            titles={[t("transactions.title")]}
            onChange={handleChangeBreadCumb}
          >
            <Typography color="text.primary">{route}</Typography>
          </MyBreadCrumbs>
        </Grid>
        {!isPointSale && (
          <Grid item xs={12}>
            <DynamicTabs
              onChangeComponent={(option) => {
                dispatch(setWithdrawalsRedux(null));
                setCurrentOption(option);
              }}
              options={optionsTabs}
              setOption={(callback) => callback(currentOption)}
            />
          </Grid>
        )}

        {getComponent(currentOption)}
      </Grid>
    </div>
  );
};
