import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { getUserById } from "../../../../services/managementUsers";
import { IGenericData } from "../../../../interfaces/params";

import * as yup from "yup";

import { setDealerWallet } from "../../../../redux/actions/walletActions";
import { arraySearch } from "../../../../redux/actions/searchActions";
import { MyTextField } from "../../../components/custom/MyTextField";
import {
  IQueryIrrigatedCards,
  IrrigationEntry,
} from "../../../../interfaces/cards";
import { startGetCardsIrrigated } from "../../../../redux/actions/managementCardsActions";
import { DealerIrrigatedCardsDetail } from "../../../components/transactions/irrigation/dealer/DealerIrrigatedCardsDetail";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import { DealerTypeEnum } from "../../../../enums/enums";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const DealerInfoCards = () => {
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { irrigatedDetail, activeLot } = useSelector(
    (state: AppState) => state.managementCards
  );

  const [listDealers, setListDealers] = useState<IGenericData[]>([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues = {
    dealer: "",
  };
  const validationSchema = yup.object({
    dealer: yup.number(),
  });
  const getDealers = async () => {
    if (!user || !token) {
      throw new Error("Invalid action");
    }
    const userDetail = await getUserById(token, user.id);

    const filterDealers = userDetail.associatesUsers
      .flatMap((asc: any) => asc.associate.dealers)
      .filter((del: any) =>
        [DealerTypeEnum.CARDS, DealerTypeEnum.BOTH].includes(del.type)
      );

    setListDealers(filterDealers);
  };
  useEffect(() => {
    getDealers();
    return () => {
      dispatch(setDealerWallet(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleFindDealerWallet = async (formData: any) => {
    if (!activeLot) {
      dispatch(setTypeAlert("error"));
      dispatch(setMessageAlert("irrigation.selectedLot"));
      return dispatch(setOpenAlert());
    }
    const { dealer } = formData;
    const { lotId } = activeLot as IrrigationEntry;
    const searchParams: Omit<IQueryIrrigatedCards, "pref"> = {
      lot: lotId as number,
      irrigator: dealer,
    };
    await dispatch(startGetCardsIrrigated(searchParams));
  };
  const resetIrrigationHistory = () => {
    dispatch(setDealerWallet(null));
    dispatch(arraySearch(null));
  };

  return (
    <Box className="filter" py={1}>
      <Box display="flex" justifyContent={"space-between"} mb={2}>
        <Box display="flex" alignItems="center">
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <Typography
            color="text.primary"
            style={{ fontSize: "16px", fontWeight: 500 }}
          >
            {t("pointsOfSale.pointSaleInformation")}
          </Typography>
        </Box>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          await handleFindDealerWallet(data);
          setSubmitting(false);
        }}
      >
        {({ handleChange, isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <MyTextField
                  name="dealer"
                  variant="outlined"
                  size="small"
                  fullWidth
                  select
                  label={t("managementDealers.dealers") + "*"}
                  onChange={(e: any) => {
                    handleChange(e);
                    resetIrrigationHistory();
                  }}
                  SelectProps={{
                    MenuProps,
                  }}
                >
                  {listDealers.map((item) => (
                    <MenuItem
                      key={item.name}
                      value={item.id}
                      sx={{
                        "&.Mui-focused": {
                          backgroundColor: "red",
                        },
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={4} sx={{ height: "60px" }} mb={2}>
                <Button
                  type="submit"
                  style={{
                    border: "none",
                    outline: "none",
                  }}
                  variant="contained"
                  id="btnSave"
                  disabled={isSubmitting}
                  className="primary-btn ripple"
                >
                  {isSubmitting ? (
                    <CircularProgress size={"1.6rem"} color="inherit" />
                  ) : (
                    <>{t("search.title")}</>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {irrigatedDetail && <DealerIrrigatedCardsDetail />}
    </Box>
  );
};
