import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import { FC } from "react";
import { BalanceType } from "../../../../enums/enums";
import { IAssociatesData } from "../../../../interfaces/associate";

interface Props {
  associate: IAssociatesData;
  balance: BalanceType;
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

export const AssociateDetail: FC<Props> = ({ associate, balance }) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={2.5}>
        <label className="font-weight-bold">
          {t("irrigation.associatedID").toUpperCase()}
        </label>
        <Box className="no-overflow">{associate?.id}</Box>
      </Grid>
      <Grid item xs={3.75}>
        <label className="font-weight-bold">
          {t("associates.associatedName").toUpperCase()}
        </label>
        <Box className="no-overflow">{associate?.name}</Box>
      </Grid>
      <Grid item xs={4}>
        <label className="font-weight-bold">
          {t(
            balance === BalanceType.REFILL
              ? "associates.refillBalance"
              : "irrigation.bettingBalance"
          ).toUpperCase()}
        </label>
        <Box className="no-overflow">
          {formatter.format(
            // @ts-ignore
            balance === BalanceType.REFILL
              ? associate.refillsBalance
              : associate.betsBalance
          )}
        </Box>
      </Grid>
      <Grid item xs={1.75}>
        <label className="font-weight-bold">
          {t("user.rol").toUpperCase()}
        </label>
        <Box className="no-overflow">{t("roles.ASSOCIATE")}</Box>
      </Grid>
    </Grid>
  );
};
