import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { MyTextField } from "../../components/custom/MyTextField";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { setActiveCategory } from "../../../redux/actions/casinoActivity";
import { MyDialog } from "../../components/custom/MyDialog";
import {
  asyncLoadingFunction,
  setFlagForm,
} from "../../../redux/actions/helperActions";
import {
  startAddIpBlacklist,
  startUpdateIp,
} from "../../../redux/actions/IpAddressesActions";

export const IpAddressesForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeIp } = useSelector((state: AppState) => state.ipAddresses);
  const { user } = useSelector((state: AppState) => state.auth);
  const [openDialog, setOpenDialog] = useState(false);
  const { loading } = useSelector((state: AppState) => state.helper);

  const [saveValues, setSaveValues] = useState<any>(null);
  const [cancel, setCancel] = useState(false);

  const handleCloseDialog = () => {
    setCancel(false);
    setOpenDialog(false);
  };

  const handleAccept = async () => {
    handleCloseDialog();
    if (cancel) {
      dispatch(setFlagForm({ flagFormCreate: false }));
    } else {
      const formData = {
        ipAddress: saveValues.ip,
        reason: saveValues.reason,
        createById: user?.id,
      };
      dispatch(
        asyncLoadingFunction(async () =>
          activeIp
            ? dispatch(startUpdateIp(formData, Number(activeIp?.id)))
            : dispatch(startAddIpBlacklist(formData))
        )
      );
    }
  };

  const validationSchema = Yup.object({
    ip: Yup.string().required("Campo requerido"),
    reason: Yup.string().required("Campo requerido"),
  });

  return (
    <div>
      <Formik
        initialValues={{
          ip: activeIp ? activeIp.ipAddress : "",
          reason: activeIp ? activeIp.reason : "",
        }}
        validateOnChange={true}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          const transformedData = {
            ...data,
          };
          const formValues = { ...transformedData };
          setSaveValues({
            ...formValues,
          });
          setOpenDialog(true);
        }}
      >
        {({ handleChange, values, errors, touched }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <label className="ml-3">Ip *</label>
                <MyTextField
                  name="ip"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label=""
                  disabled={activeIp}
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={handleChange}
                  error={touched.ip && Boolean(errors.ip)}
                  helperText={touched.ip && errors.ip}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="ml-3">{t("withdrawals.reason")}*</label>
                <MyTextField
                  name="reason"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label=""
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={handleChange}
                  error={touched.reason && Boolean(errors.reason)}
                  helperText={touched.reason && errors.reason}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    variant="contained"
                    className="footer-btn danger-btn ripple"
                    style={{ border: "none", outline: "none" }}
                    onClick={() => {
                      dispatch(setActiveCategory(null));
                      dispatch(setFlagForm({ flagFormCreate: false }));
                    }}
                  >
                    {t("button.cancel").toUpperCase()}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className="footer-btn primary-btn ripple"
                    style={{ border: "none", outline: "none" }}
                  >
                    {loading ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      t("button.save").toUpperCase()
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <MyDialog
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message={"¿Esta seguro de restringir la Ip?"}
        handleAccept={handleAccept}
        handleClose={() => setOpenDialog(false)}
        isLoading={!!loading}
      />
    </div>
  );
};
