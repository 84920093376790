import {
  ISegmentation,
  SegmentationAction,
} from "../../interfaces/segmentations";
import { Types } from "../types/types";

const initialState = {
  data: [],
};

export const segmentationReducer = (
  state: Partial<ISegmentation> = initialState,
  action: SegmentationAction
) => {
  switch (action.type) {
    case Types.setSegmentations:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
