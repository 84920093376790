import i18next from "../i18n";

export const betStatuses = [
  { value: "C", description: i18next.t("bets.open") },
  { value: "S", description: i18next.t("bets.won") },
  { value: "N", description: i18next.t("bets.lost") },
  { value: "A", description: i18next.t("bets.void") },
  { value: "R", description: i18next.t("bets.pending") },
  { value: "W", description: i18next.t("bets.waitingForClient") },
  { value: "J", description: i18next.t("bets.rejected") },
  { value: "T", description: i18next.t("bets.cashout") },
  { value: "Q", description: i18next.t("bets.partialCashout") },
  { value: "M", description: i18next.t("bets.rejectedByRegulator") },
  { value: "D", description: i18next.t("bets.rejectedByPortal") },
];
