import React, { useEffect, useState } from "react";

// reactstrap components
import { Row, Col } from "reactstrap";
import { ShorCuts } from "./components/Dashboard/ShorCuts";
import { Reports } from "./components/Dashboard/Reports";

import { IndicatorsList } from "./components/Dashboard/IndicatorsList";

import { Request } from "./components/Dashboard/Requests";
import logoHeart from "../assets/svg/heart-icon.svg";
import logo$ from "../assets/svg/logo$.svg";
import logoTrophy from "../assets/svg/logo-trophy.svg";
import logoPerson from "../assets/svg/person-icon.svg";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../redux/reducers/rootReducer";
import { buildShortCuts } from "../helpers/buildShortCuts";
import { IShortCuts } from "../interfaces/shortcuts";
import { IRoutes } from "../interfaces/menu";
import { filterUsers } from "../services/managementUsers";
import moment from "moment";
import { CustomSearch } from "../components/Dashboard/CustomSearch";
import { NotificationsList } from "../components/Dashboard/NotificationsList";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { MovementType } from "../enums/enums";
import { getIndicators } from "../services/indicatos";

const ShortCutsUrl = [
  "/admin/masters/associates",
  "/admin/masters/point-of-sale",
  "/admin/player/detail",
  "/admin/operating-company/config",
  "/admin/operating-company/management",
  "/admin/statistics/dashboard",
  "/admin/settings/management-users",
  /* "/admin/reports/extract-bag",
  "/admin/transactions/settings",
  "/admin/transactions/irrigation",
  "/admin/settings/admin-users",
  "/admin/settings/legal", */
];

const ShortCutsUrlSeller = ["/admin/bets"];
const Dashboard = () => {
  const location = useLocation();
  const theme = useTheme();
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { user, token } = useSelector((state: AppState) => state.auth);
  const [shortcuts, setShortcuts] = useState<IShortCuts[]>([]);
  const [usersCreated, setUsersCreated] = useState<number | null>(null);
  const isLessThanMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [searchText, setSearchText] = useState("");
  const [indicators, setIndicators] = useState({
    recharge: 0,
    payments: 0,
    loyalCustomers: 0,
  });
  useEffect(() => {
    //GET TOTAL USER BY OPERATOR IF IS SUPERADMIN OTHERWISE GET TOTAL USERS BY OPERATOR
    if (user && token) {
      const {
        roles: { name },
        operator,
      } = user;
      const fetchIndicators = async () => {
        //FETCH RECHARGE INDICATOR
        const recharges = await fetchMovementIndicators(MovementType.Input);
        const finalIndicators = {
          ...indicators,
        };
        if (recharges) {
          finalIndicators.recharge = recharges.total;
        }
        //FETCH RECHARGE INDICATOR
        const payments = await fetchMovementIndicators(MovementType.Output);
        if (payments) {
          finalIndicators.payments = payments.total;
        }
        //FETCH RECHARGE INDICATOR
        const loyalCustomers = await fetchMovementIndicators(
          MovementType.Input,
          true
        );
        if (loyalCustomers) {
          finalIndicators.loyalCustomers = loyalCustomers.total;
        }

        setIndicators(finalIndicators);

        //CALCULAR FECHA INICIAL Y FECHA FINAL PARA TRAER LOS REGISTROS DE LOS ULTIMOS 30 DIAS
        const startDate = moment()
          .subtract(30, "days")
          .startOf("day")
          .toISOString();
        const endDate = moment().endOf("day").toISOString();

        const params = {
          startDate,
          endDate,
        };
        const response = await filterUsers(
          token,
          null,
          name,
          params,
          operator?.id
        );
        if (response) {
          setUsersCreated(response.total);
        }
      };
      fetchIndicators();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchMovementIndicators = async (
    type: MovementType,
    playerRecharge?: boolean
  ) => {
    if (!token) {
      throw new Error("INVALID ACTION");
    }
    try {
      //CALCULAR FECHA INICIAL Y FECHA FINAL PARA TRAER LOS REGISTROS DE LOS ULTIMOS 30 DIAS
      const startDate = moment()
        .subtract(30, "days")
        .startOf("day")
        .toISOString();
      const endDate = moment().toISOString();
      let params: any = {
        startDate,
        endDate,
        type,
      };
      if (user?.operator) {
        params = {
          ...params,
          operator: user.operator.id,
        };
      }
      if (playerRecharge) {
        params = {
          ...params,
          playerRecharge,
        };
      }
      const rs = await getIndicators(token, params);
      return rs;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let filteredRoutes: any[] = [];
    if (user?.roles.name === "SELLER") {
      routes.forEach((route) => {
        if (ShortCutsUrlSeller.includes(route.url ? route.url : "")) {
          filteredRoutes.push(route);
        }
        if (route.collapse) {
          const modulesFilter = route.menuItem.filter((module) =>
            ShortCutsUrlSeller.includes(module.url)
          );
          if (modulesFilter) {
            filteredRoutes = [...filteredRoutes, ...modulesFilter];
          }
        }
      });
    } else {
      routes.forEach((route) => {
        if (ShortCutsUrl.includes(route.url)) {
          filteredRoutes.push(route);
        }
        if (route.collapse) {
          const modulesFilter = route.menuItem.filter((module) =>
            ShortCutsUrl.includes(module.url)
          );
          if (modulesFilter) {
            filteredRoutes = [...filteredRoutes, ...modulesFilter];
          }
        }
      });
    }

    const shortCutsArr = buildShortCuts(filteredRoutes, location.pathname);

    const routeChangePassword = {
      active: false,
      name: "Cambiar Contraseña",
      url: "/admin/account",
    };
    const routeChangeUser = {
      active: false,
      name: "Cambiar Usuario",
      url: "/admin/account",
    };
    shortCutsArr.push(routeChangePassword);
    shortCutsArr.push(routeChangeUser);

    // ROL SELLER
    if (user?.roles.name === "SELLER") {
      shortCutsArr[0].name = "Consultar Tiquete";
    }
    setShortcuts(shortCutsArr as IShortCuts[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const getActiveRoute = (routes: IRoutes[]): any => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        //@ts-ignore
        let collapseActiveRoute = getActiveRoute(routes[i].menuItem);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            //@ts-ignore
            routes[i].url
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  return (
    <>
      <div className="content">
        <Row>
          <i className="fa-solid fa-abacus"></i>
          <Col
            lg={isLessThanMediumScreen ? 12 : 8}
            md={isLessThanMediumScreen ? 12 : 8}
            sm={12}
          >
            <div style={{ marginTop: 22, marginBottom: 22 }}>
              <span className="active-route-text">
                {" "}
                {getActiveRoute(routes).toUpperCase()}
              </span>
            </div>

            <ShorCuts shorcuts={shortcuts} />
            <hr className="separator" />

            <IndicatorsList
              indicators={[
                {
                  title: "LoyalCustomers",
                  logo: logoHeart,
                  background: "#02D0E7",
                  value: indicators.loyalCustomers,
                },
                {
                  title: "CreatedUsers",
                  logo: logoPerson,
                  background: "#02D0E7CC",
                  value: usersCreated ? usersCreated.toString() : "...",
                },
                {
                  title: "PaidPrizes",
                  logo: logoTrophy,
                  background: "rgba(2, 208, 231, 0.6)",
                  value: indicators.payments,
                },
                {
                  title: "RefillsMade",
                  logo: logo$,
                  background: "rgba(2, 208, 231, 0.4)",
                  value: indicators.recharge,
                },
              ]}
            />
            <hr className="separator" />

            <Row>
              <Col md={5} sm={12}>
                <Reports />
              </Col>
              <Col md={7} sm={12}>
                <Request />
              </Col>
            </Row>
          </Col>
          <Col
            lg={4}
            md={4}
            sm={0}
            className={isLessThanMediumScreen ? "d-none mt-4" : "d-block mt-4"}
          >
            <Col xs="12">
              <CustomSearch onChange={(e) => setSearchText(e.target.value)} />
            </Col>
            <Col xs="12">
              <NotificationsList searchText={searchText} />
            </Col>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
