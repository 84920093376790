import { IActivityQuery } from "../interfaces/activityPlayer";
import { IQueryPlayerHistory } from "../interfaces/players";
import { IPlayerUpdateData } from "../interfaces/users";
import axios from "axios";

export const closeSessionPlayer = (id: number, token: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      playerId: id,
    }),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}players/logout`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const getFinantialInformation = (
  token: string,
  params: Partial<IActivityQuery>
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...params,
    }),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}players/getFinantialOperations`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const createPlayerUpdate = async (
  token: string,
  data: IPlayerUpdateData
) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    const value = data[key as keyof IPlayerUpdateData];
    if (key === "attachFile" && value instanceof File) {
      formData.append(key, value);
    } else if (key !== "attachFile" && value !== null && value !== undefined) {
      formData.append(key, value.toString());
    }
  });

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_HTTP_PLAYERS}updatePlayerData/${data.id}`,
      formData,
      {
        headers: {
          api: `${process.env.REACT_APP_API_KEY}`,
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.warn(error);
    throw error;
  }
};

export const getPlayerHistory = (
  token: string,
  params: Partial<IQueryPlayerHistory>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}updatePlayerData?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
