import { Types } from "../types/types";

interface IDepositState {
  deposits: any;
  activeDeposit: any;
}

interface IDepositActions {
  type: Types;
  payload?: any;
}
const initialState: IDepositState = {
  deposits: null,
  activeDeposit: null,
};

export const depositReducer = (
  state = initialState,
  action: IDepositActions
): IDepositState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setDepositQR:
      return {
        ...state,
        deposits: payload as any,
      };
    case Types.setActiveDepositQR:
      return {
        ...state,
        activeDeposit: payload as any,
      };
    default:
      return state;
  }
};
