import { useEffect, useState } from "react";
import { DealerTypeEnum, Roles_Enum, State } from "../enums/enums";
import { getPointsSalesMainService } from "../services/pointsSaleService";
import { getUserById } from "../services/managementUsers";
import { IGenericData } from "../interfaces/params";
interface IUsePointSale extends IGenericData {
  isDirect?: boolean;
  type: DealerTypeEnum;
}
export const usePointSales = (
  user: {
    id: number | null;
    operator: Partial<IGenericData> | null;
    roles: Partial<IGenericData>;
  },
  token: string | null,
  type?: DealerTypeEnum,
  showNestedPoints?: boolean
) => {
  const [pointSales, setPointSales] = useState<IUsePointSale[]>([]);
  let whiteListPVDealerType = [DealerTypeEnum.BOTH];
  if (type) {
    whiteListPVDealerType = [type, ...whiteListPVDealerType];
  }

  useEffect(() => {
    const fetchPointSales = async () => {
      if (!user || !token || !user.id) {
        throw new Error("invalid action");
      }

      if (
        [
          Roles_Enum.ADMIN_OPERATOR,
          Roles_Enum.SUPER_ADMIN,
          Roles_Enum.BETPPRO_TREASURY,
          Roles_Enum.CONTACT_CENTER,
        ].includes(`${user?.roles.name?.toUpperCase()}` as any)
      ) {
        const params: any = {};

        if (user.operator) {
          params.operatorId = user.operator.id;
        }

        const pointSalesOp = await getPointsSalesMainService(token, params);
        const listPointSalesByOp = pointSalesOp.data.map(
          ({ id, name, type }: any) => ({
            id,
            name,
            state: State.ACTIVE,
            type,
          })
        );

        const finalPS = listPointSalesByOp.filter((point: any) =>
          whiteListPVDealerType.includes(point.type)
        );

        setPointSales(finalPS);
      } else {
        const userDetail = await getUserById(token, user.id);

        let listPointSales = [];

        if (user && user.roles.name === Roles_Enum.SELLER) {
          listPointSales = userDetail.sellerPointSales.map(
            ({ pointSale }: any) => ({
              id: pointSale.id,
              name: pointSale.name,
              type: pointSale.type,
            })
          );
        } else if (user && user.roles.name === Roles_Enum.DEALER) {
          const dealerPoints = userDetail.dealer.dealersPointSale.map(
            (point: any) => point.pointSale
          );

          const promotorPoints: any[] = [];
          userDetail.pointsPromotor.forEach((prom: any) => {
            prom.pointSales.forEach((point: any) => {
              const findDealerPoint = dealerPoints.find(
                (dealPS: any) => dealPS.id === point.id
              );
              if (!findDealerPoint) {
                promotorPoints.push(point);
              }
            });
          });

          listPointSales = showNestedPoints
            ? [...dealerPoints, ...promotorPoints]
            : dealerPoints;
        } else if (user && user.roles.name === Roles_Enum.PROMOTOR) {
          userDetail.pointsPromotor.forEach((prom: any) => {
            prom.pointSales.forEach((point: any) => {
              listPointSales.push(point);
            });
          });
        } else {
          listPointSales = userDetail?.associatesUsers.flatMap((point: any) => {
            const pointSales = point.associate.associates.map((ass: any) => ({
              id: ass.pointSale.id,
              name: ass.pointSale.name,
              isDirect: ass.pointSale.isDirect,
              type: ass.pointSale.type,
            }));
            return pointSales;
          });
        }

        const finalPS = listPointSales.filter((point: any) =>
          whiteListPVDealerType.includes(point.type)
        );

        setPointSales(finalPS);
      }
    };

    fetchPointSales();

    return () => {
      setPointSales([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return pointSales;
};
