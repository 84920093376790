import { Alert, AlertTitle, Box, Grid, IconButton, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TableComponent } from "../../../../components/Table/TableComponent";
import {
  Enum_FieldType,
  IColumnsTable,
  IFilterField,
  Icons,
} from "../../../../interfaces/components";
import { AppState } from "../../../../redux/reducers/rootReducer";
import InfoIcon from "@mui/icons-material/Info";
import "./observations.scss";
import { Col, Row } from "reactstrap";
import { useEffect, useState } from "react";
import {
  asyncLoadingFunction,
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import { DialogParamSettings } from "../../ParamSettings.tsx/DialogParamSettings";
import { FormObservations } from "./FormObservations";
import { MyDialog } from "../../custom/MyDialog";
import Filter from "../../../../components/Filter/Filter";
import { State } from "../../../../enums/enums";
import {
  setObservationsRedux,
  startGetObservations,
} from "../../../../redux/actions/managementPlayersActions";
import moment from "moment";

export const Observations = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { player, observations } = useSelector(
    (state: AppState) => state.managementPlayers
  );
  const { flagFormCreate } = useSelector((state: AppState) => state.helper);
  const [open, setOpen] = useState(false);
  const [dataFilter, setDataFilter] = useState<any>({});

  useEffect(() => {
    dispatch(setFlagForm({ flagFormCreate: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("form.state"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("state.active"),
          value: State.ACTIVE,
        },
        {
          label: t("state.inactive"),
          value: State.INACTIVE,
        },
      ],
    },
  ];

  /**
   * Soporta las Columnas para renderizar en el header de la tabla
   */
  const columns: IColumnsTable[] = [
    { id: 1, header: "login.user" },
    { id: 2, header: "user.observation" },
    { id: 3, header: "user.createAt" },
    { id: 4, header: "form.state" },
    { id: 5, header: "table.actions" },
  ];

  /**
   * Soporta operador terniario para los iconos de acciones en tabla
   */

  const iconos: Icons = {
    see: true,
    edit: true,
    delete: false,
    seeMore: false,
    add: false,
  };

  /**
   *  Soporta el evento para abrir modal creación de observación
   */
  const handleOpenForm = () => {
    dispatch(setFlagForm({ flagFormCreate: true }));
  };

  /**
   * Soporta el evento para cerrar el modal de creación
   */

  const handleCloseModal = () => {
    setOpen(true);
  };

  /**
   * Soporta los eventos del dialogo de confirmación
   */

  const handleAcceptClose = () => {
    setOpen(false);
    dispatch(setFlagForm({ flagFormCreate: false }));
  };

  const handleCancelClose = () => {
    setOpen(false);
  };

  const handleFilter = (formValues: any) => {
    if (player && player.data[0]) {
      const startDate = formValues[t("filters.startDate")];
      const endDate = formValues[t("filters.endDate")];
      const params = {
        startDate,
        endDate,
        state: formValues[t("form.state")].toString(),
        playerId: player.data[0].id?.toString(),
        page: 1,
      };

      if (endDate.trim() && !startDate.trim()) {
        dispatch(setMessageAlert("alerts.selectStartDate"));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      const errorDate = moment(startDate).isAfter(endDate);
      if (errorDate) {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorDate"));
        dispatch(setTypeAlert("error"));
      } else {
        dispatch(
          asyncLoadingFunction(
            async () => await dispatch(startGetObservations(params))
          )
        );
      }
      setDataFilter(params);
    }
  };

  const onCleanFilter = async () => {
    if (player && player.data[0]) {
      await dispatch(
        asyncLoadingFunction(
          async () =>
            await dispatch(
              startGetObservations(
                {
                  page: 1,
                  playerId: player.data[0].id?.toString(),
                },
                true
              )
            )
        )
      );
      setDataFilter({
        state: "",
      });
    }
  };

  useEffect(() => {
    if (!player) {
      dispatch(setObservationsRedux(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  return (
    <div className="container-fluid">
      <Row>
        <Col xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            mb={2}
          >
            <Box display="flex" alignItems="center">
              <p
                className="txt-primary-md"
                style={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  alignSelf: "center",
                }}
              >
                {t("observations.createObservation")}
              </p>
            </Box>
            <Box>
              <IconButton
                data-testid="btnOpenFormObservations"
                onClick={handleOpenForm}
                style={{
                  border: "none",
                  outline: "none",
                  pointerEvents:
                    player && player.data.length > 0 ? "all" : "none",
                  opacity: player && player.data.length > 0 ? 1 : 0.4,
                }}
              >
                <span className="btn-add">
                  <i className="fa-solid fa-plus" style={{ fontSize: 30 }}></i>
                </span>
              </IconButton>
            </Box>
          </Box>
        </Col>
      </Row>

      <Row
        style={{
          marginTop: "12px",
          pointerEvents: player && player.data.length > 0 ? "all" : "none",
          opacity: player && player.data.length > 0 ? 1 : 0.4,
        }}
      >
        <Filter
          fields={filterFields}
          setDataFilter={setDataFilter}
          handleFilter={handleFilter}
          onClean={onCleanFilter}
        />
      </Row>

      {observations && observations.data.length > 0 && (
        <TableComponent
          columns={columns}
          data={observations.data}
          pages={observations.last_page || 1}
          component={"observations"}
          totalData={observations}
          icons={iconos}
          isSortingTable={false}
          dataFilter={dataFilter}
        />
      )}

      {!player && (
        <Grid item xs={12} pt={3} mt={-2}>
          <Stack
            sx={{ width: "100%" }}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
            spacing={2}
          >
            <Alert
              icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
              sx={{
                borderLeft: `7px solid #2C97DF`,
                backgroundColor: "#EAF5FC",
                color: "#4b4b4b",
              }}
            >
              <div className="d-flex">
                <AlertTitle
                  style={{ marginTop: "5px" }}
                  sx={{ color: "#333333" }}
                >
                  {t("alerts.informationPlayer")}
                </AlertTitle>
                <AlertTitle
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  sx={{ color: "#333333", fontWeight: "bold" }}
                >
                  {t("alerts.observationHistory")}
                </AlertTitle>
              </div>
            </Alert>
          </Stack>
        </Grid>
      )}

      <DialogParamSettings
        openModal={!!flagFormCreate}
        handleClose={handleCloseModal}
        title={`${t("observations.blockagesAnnotations")}`}
        content={<FormObservations />}
      />
      <MyDialog
        id="confirmationDialog"
        open={open}
        title={t("dialog.confirmationWindow")}
        message={t("dialog.sureCloseModal")}
        handleAccept={handleAcceptClose}
        handleClose={handleCancelClose}
      />
    </div>
  );
};
