import {
  Box,
  ClickAwayListener,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Tooltip,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Roles_Enum, State } from "../../enums/enums";
import { Icons } from "../../interfaces/components";
import { IConfigParamOperatorUpdate } from "../../interfaces/configParams";
import { IBetsData } from "../../interfaces/gamingActivity";
import { IMenuItem, IMenuList } from "../../interfaces/menu";
import { IConfigPointSale } from "../../interfaces/pointsSale";
import { IFormRoles, IRolesModules } from "../../interfaces/roles";
import {
  setActiveAssociate,
  startEditAssociate,
} from "../../redux/actions/associatesActions";
import { setActiveTicket } from "../../redux/actions/betsActions";
import {
  setActiveBonus,
  setActiveBonusType,
  startDeleteBonusType,
  startUpdateBonusType,
} from "../../redux/actions/bonusActions";
import {
  setActiveConfigParam,
  startUpdateParam,
} from "../../redux/actions/configParamsActions";
import {
  setFlagForm,
  setFlagFormConfig,
  setFlagFormEdit,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../redux/actions/helperActions";
import {
  setActiveLegal,
  startDeleteLegal,
} from "../../redux/actions/legalActions";
import {
  setActiveAd,
  startDeleteAd,
  startUpdateAdd,
} from "../../redux/actions/managementAdsActions";
import {
  editStateConfigBrand,
  editStateOperator,
  setActiveBrand,
  setActiveCompany,
} from "../../redux/actions/managementCompanyActions";
import {
  editStateMenu,
  setActiveMenu,
  startDeleteMenu,
  startFilteringMenus,
  startLoadAvailableRoutes,
} from "../../redux/actions/managementMenuActions";
import {
  editActiveGames,
  editActivePaymentMethods,
  editActiveSolutions,
  setActiveParamGames,
  setActiveParamPayments,
  setActiveParamSolutions,
  startDeleteParameterization,
} from "../../redux/actions/managementParametersActions";
import {
  editPlayer,
  setActiveObservation,
  startUpdateObservation,
  startUpdateStateExclusion,
} from "../../redux/actions/managementPlayersActions";
import {
  setActiveRol,
  startDeleteRol,
  startEditRol,
} from "../../redux/actions/managementRolesActions";
import { setActiveUser } from "../../redux/actions/managementUsersActions";
import {
  setActiveMessage,
  startDeleteMessage,
  startResendMessage,
} from "../../redux/actions/messagesActions";
import {
  editStatePointSale,
  setActivePointSale,
  startDeletePointSale,
} from "../../redux/actions/pointsSaleActions";
import { uiSetOpenDialog } from "../../redux/actions/uiAction";
import {
  IHelper,
  IHelperConfig,
  IHelperEdit,
} from "../../redux/interfaces/helper";
import { IRol, IRolesDb, IUserData } from "../../redux/interfaces/users";
import { AppState } from "../../redux/reducers/rootReducer";
import { getOperatorByIdService } from "../../services/managementCompanyService";
import { getUsers } from "../../services/managementUsers";
import { DEFAULT_ROLES } from "../../shared/const";
import { RowTableMenuItems } from "../../views/components/ManagementMenu/RowTableMenuItems";
import { RowDataTableActivity } from "../../views/components/ManagementPlayers/Activity/RowDataTableActivity";
import { RowDataTableBets } from "../../views/components/ManagementPlayers/GamingActivity/tabBets/RowDataTableBets";
import { RowDataTableCasino } from "../../views/components/ManagementPlayers/GamingActivity/tabCasino/RowDataTableCasino";
import { RowDataTableObservations } from "../../views/components/ManagementPlayers/Observations/RowDataTableObservations";
import { RowDataTablePaymentOrders } from "../../views/components/ManagementPlayers/PaymentOrders/RowDataTablepaymentOrders";
import { RowDataTableRefills } from "../../views/components/ManagementPlayers/Refills/RowDataTableRefills";
import { RowDataTableBonds } from "../../views/components/ManagementPlayers/bonds/RowDataTableBonds";
import { DialogTabsConfirmation } from "../../views/components/OperationCompany/ConfigCompany/DialogTabsConfirmation";
import { RowDataTableParamSettings } from "../../views/components/ParamSettings.tsx/RowDataTableParamSettings";
import { MyDialog } from "../../views/components/custom/MyDialog";
import { RowDataTableExclusions } from "../../views/components/exclusions/RowDataTableExclusions";
import { RowDataTableExclusionsAgileCheck } from "../../views/components/exclusions/RowDataTableExclusionsAgileCheck";
import { RowDataTableLegal } from "../../views/components/legal/RowDataTableLegal";
import { RowManagementAds } from "../../views/components/managementAds/RowManagementAds";
import { RowManagementBonus } from "../../views/components/managementBonus/RowManagementBonus";
import RowConfigParams from "../../views/components/managementConfigParams/RowConfigParams";
import { RowDataTableCredentials } from "../../views/components/masters/associates/RowDataTableCredentials";
import { RowDataTableCommissionerPointSale } from "../../views/components/masters/pointsOfSale/RowDataTableCommissionerPointSale";
import { RowDataTableCredentialsPointSale } from "../../views/components/masters/pointsOfSale/RowDataTableCredentialsPointSale";
import { RowDataTableMessage } from "../../views/components/messages/RowDataTableLegal";
import { RowDataTableTransactions } from "../../views/components/transactions/RowDataTableTransactions";
import { RowDataTableIrrigation } from "../../views/components/transactions/irrigation/RowDataTableIrrigation";
import { RowDataTableUsers } from "../../views/pages/ManagementUsers/RowDataTableUsers";
import { RowDataTableCompanyBrands } from "../../views/pages/OperationCompany/Config/RowDataTableCompanyBrands";
import { RowDataTableCompany } from "../../views/pages/OperationCompany/Management/RowDataTableCompany";
import { RowDataTableBettingHistory } from "../../views/pages/bets/RowDataTableBettingHistory";
import { RowDataTableTickets } from "../../views/pages/bets/RowDataTableTickets";
import { RowDataTable } from "../../views/pages/managementMenu/RowDataTable";
import { RowDataTableRoles } from "../../views/pages/managementRoles/RowDataTableRoles";
import { RowDataTableAssociates } from "../../views/pages/masters/associates/RowDataTableAssociates";
import { RowDataTablePointsOfSale } from "../../views/pages/masters/pointsOfSale/RowDataTablePointsSale";
import "./table.scss";
import {
  setActivePromotion,
  startDeletePromotion,
} from "../../redux/actions/promotionsActions";
import { RowDataTablePromotion } from "../../views/components/promotions/RowDataTablePromotion";
import { RowDataPayHistorySeller } from "../../views/pages/bets/RowDataPayHistorySeller";
interface Props {
  row: IUserData | any | IMenuList | IBetsData;
  subMenu?: boolean;
  icons?: Icons;
  component?:
    | "menu"
    | "users"
    | "roles"
    | "refills"
    | "managementCompany"
    | "configCompany"
    | "files"
    | "paymentOrders"
    | "parameterizations"
    | "managementAds"
    | "pointsOfSale"
    | "associates"
    | "credentials"
    | "credentialsPointSale"
    | "commissionalPointSale"
    | "bets"
    | "casino"
    | "playerActivity"
    | "observations"
    | "tickets"
    | "bettingHistory"
    | "managementBonds"
    | "exclusions"
    | "bonds"
    | "transactions"
    | "legal"
    | "messages"
    | "promotions"
    | "configParams"
    | "irrigation"
    | "payHistorySellers";
  param?:
    | "paramPaymentMethods"
    | "paramGames"
    | "paramSolutions"
    | "bonusTable"
    | "percentageWithdrawalTable";
  dataFilter: any;
  page: number;
  agileCheck?: boolean;
  exportData?: boolean;
}

export const BodyTable: FC<Props> = ({
  row,
  subMenu,
  icons,
  component,
  param,
  dataFilter,
  page,
  agileCheck,
  exportData,
}) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F4F4F4",
      color: component === "bets" ? "#007e8c" : "#000",
      fontSize: 14,
      fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      fontWeight: 700,
    },
  }));

  const StyledTableCellPrimary = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#02D0E7",
      color: "#fff",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
      fontWeight: 700,
    },
  }));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [showDialogFiles, setShowDialogFiles] = useState(false);
  const [showTabsConfirmation, setShowTabsConfirmation] = useState(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [isResendMessage, setResendMessage] = useState(false);
  const { activeMenu } = useSelector(
    (state: AppState) => state.managementMenus
  );
  const { activeRol } = useSelector((state: AppState) => state.managementRoles);
  const { activeAd } = useSelector((state: AppState) => state.managementAds);
  const { data } = useSelector((state: AppState) => state.search);
  const { activePointSale } = useSelector(
    (state: AppState) => state.pointsSale
  );
  const { activeBonusType } = useSelector((state: AppState) => state.bonus);
  const { token, user } = useSelector((state: AppState) => state.auth);
  const isSuper = !!(user?.roles.name === Roles_Enum.SUPER_ADMIN);
  const showEditConfigParam = row.configParam
    ? row.configParam.options.length > 0
    : true;
  const today = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  const handleCloseTooltip = () => {
    setTooltipIsOpen(false);
  };

  const handleOpenTooltip = () => {
    setTooltipIsOpen(true);
  };
  /**
   * Soporta el estado boolean para mostrar campos en filtro
   */
  const [isShowSubMenu, setIsShowSubMenu] = useState(false);

  /**
   * Soporta el evento click del boton ocultar o mostrar filtro busqueda
   */
  const toogleShowSubMenu = async () => {
    if (component !== "bets") {
      if (component === "roles") {
        const copyRol =
          isSuper && component === "roles" ? { ...row } : { ...row.rol };

        setIsShowSubMenu(copyRol.state === 1 ? !isShowSubMenu : false);

        copyRol.actionsPermissions.length === 0 && setIsShowSubMenu(false);
      } else {
        setIsShowSubMenu(row.state === 1 ? !isShowSubMenu : false);
      }
    } else {
      setIsShowSubMenu(!isShowSubMenu);
    }

    component === "menu" &&
      row.menuItem.length === 0 &&
      setIsShowSubMenu(false);

    component === "bets" && row.events.length === 0 && setIsShowSubMenu(false);
  };

  useEffect(() => {
    row.state === 2 && setIsShowSubMenu(false);
  }, [row.state]);

  const setFlagFormHelper: IHelper = {
    flagFormCreate: true,
  };
  const setFlagFormHelperEdit: IHelperEdit = {
    flagFormEdit: true,
  };
  const setFlagFormHelperConfig: IHelperConfig = {
    flagFormConfig: true,
  };
  const formularioCreacion = async () => {
    setResendMessage(false);
    if (component === "configParams") {
      dispatch(setActiveConfigParam(row));
      return dispatch(uiSetOpenDialog());
    }
    dispatch(setFlagForm(setFlagFormHelper));
    dispatch(setFlagFormEdit(setFlagFormHelperEdit));
    if (component === "menu") {
      dispatch(setActiveMenu(row));
    }
    if (component === "users") {
      dispatch(setActiveUser(row));
    }
    if (component === "roles") {
      dispatch(setActiveRol(row));
    }
    if (component === "managementCompany") {
      dispatch(setActiveCompany(row));
    }
    if (component === "configCompany") {
      dispatch(setFlagFormConfig(setFlagFormHelperConfig));
      dispatch(setActiveBrand(row));
      if (token) {
        const operator: any =
          token && (await getOperatorByIdService(token, row.operator.id));
        dispatch(setActiveCompany(operator));
      }
    }
    if (component === "parameterizations") {
      dispatch(uiSetOpenDialog());
      param === "paramPaymentMethods" && dispatch(setActiveParamPayments(row));
      param === "paramSolutions" && dispatch(setActiveParamSolutions(row));
      param === "paramGames" && dispatch(setActiveParamGames(row));
      param === "bonusTable" && dispatch(setActiveBonusType(row));
      param === "percentageWithdrawalTable" && dispatch(setActiveCompany(row));
    }
    if (component === "associates") {
      dispatch(setActiveAssociate(row));
    }
    if (component === "managementAds") {
      dispatch(setActiveAd(row));
    }
    if (component === "pointsOfSale") {
      dispatch(setActivePointSale(row));
    }
    if (component === "observations") {
      dispatch(setActiveObservation(row));
    }
    if (component === "managementBonds") {
      dispatch(setActiveBonus(row));
    }
    if (component === "legal") {
      dispatch(setActiveLegal(row));
    }
    if (component === "messages") {
      dispatch(setActiveMessage(row));
    }
    if (component === "promotions") {
      dispatch(setActivePromotion(row));
    }
  };

  const reSendMessage = async () => {
    setResendMessage(true);
    setOpenDialog(true);
    dispatch(setActiveMessage(row));
  };

  const confirmationDeleteMenu = async () => {
    setResendMessage(false);
    if (component === "menu") {
      dispatch(setActiveMenu(row));
    }
    if (component === "roles") {
      dispatch(setActiveRol(row));
    }
    if (component === "parameterizations") {
      param === "paramPaymentMethods" && dispatch(setActiveParamPayments(row));
      param === "paramSolutions" && dispatch(setActiveParamSolutions(row));
      param === "paramGames" && dispatch(setActiveParamGames(row));
      param === "bonusTable" && dispatch(setActiveBonusType(row));
    }
    if (component === "managementAds") {
      dispatch(setActiveAd(row));
    }
    if (component === "pointsOfSale") {
      dispatch(setActivePointSale(row));
    }
    if (component === "bonds") {
      //dispatch( setActiveBonus(row) )
    }
    if (component === "managementBonds") {
      dispatch(setActiveBonus(row));
    }
    if (component === "legal") {
      dispatch(setActiveLegal(row));
    }
    if (component === "messages") {
      dispatch(setActiveMessage(row));
    }
    if (component === "promotions") {
      dispatch(setActivePromotion(row));
    }
    setOpenDialog(true);
  };

  const seeInformation = async (row: any) => {
    if (component === "menu") {
      const copyMenu = { ...row };
      const state =
        copyMenu.state === State.ACTIVE ? State.INACTIVE : State.ACTIVE;
      copyMenu.state = state;
      const isFilteredData = !!data;
      await dispatch(editStateMenu(copyMenu));
      if (isFilteredData) {
        return await dispatch(startFilteringMenus(dataFilter, 1, false));
      }
      await dispatch(startLoadAvailableRoutes());
    }
    if (component === "roles") {
      let copyRol: IRolesDb = { ...row };

      copyRol = isSuper
        ? (copyRol = { ...copyRol, rol: row as IRol })
        : copyRol;

      const state =
        copyRol.rol.state === State.ACTIVE ? State.INACTIVE : State.ACTIVE;
      copyRol.rol.state = state;
      const modules: IRolesModules[] = copyRol.rol.actionsPermissions.map(
        (permission) => ({
          idMenu: permission.menu.id,
          idModule: permission.menuItem ? permission.menuItem.id : "",
          permissions: {
            create: permission.created === 1 ? true : false,
            delete: permission.deleted === 1 ? true : false,
            edit: permission.updated === 1 ? true : false,
            read: permission.readed === 1 ? true : false,
            id: permission.id,
          },
        })
      );

      const parseRol: IFormRoles = {
        description: copyRol.rol.description,
        modules,
        name: copyRol.rol.name,
        state: copyRol.rol.state,
        notification: copyRol.rol.notification,
      };

      await dispatch(
        startEditRol([parseRol], copyRol.rol.id, true, dataFilter.state !== "")
      );
    }

    if (component === "managementCompany") {
      const copyCompany = { ...row };
      const state =
        copyCompany.stateOperator === State.ACTIVE
          ? State.INACTIVE
          : State.ACTIVE;
      copyCompany.stateOperator = state;
      delete copyCompany.currencyOp;

      await dispatch(editStateOperator(copyCompany));
    }

    if (component === "configCompany") {
      const copyConfig = { ...row };
      const state =
        copyConfig.state === State.ACTIVE ? State.INACTIVE : State.ACTIVE;
      copyConfig.state = state;

      await dispatch(editStateConfigBrand(copyConfig));
    }

    if (component === "files") {
      setShowDialogFiles(true);
    }

    if (component === "parameterizations") {
      const copyConfig = { ...row };
      if (param === "bonusTable") {
        copyConfig.state =
          copyConfig.state === State.ACTIVE ? State.INACTIVE : State.ACTIVE;

        await dispatch(startUpdateBonusType(copyConfig.id, copyConfig));
      } else {
        param === "paramPaymentMethods"
          ? await dispatch(editActivePaymentMethods(copyConfig))
          : param === "paramGames"
          ? await dispatch(editActiveGames(copyConfig))
          : await dispatch(editActiveSolutions(copyConfig));
      }
    }
    if (component === "managementAds") {
      const copyAd = { ...row };
      copyAd.state =
        copyAd.state === State.ACTIVE ? State.INACTIVE : State.ACTIVE;
      dispatch(setActiveAd(copyAd));

      await dispatch(
        startUpdateAdd(
          {
            endDate: copyAd.endDate,
            image: copyAd.image,
            message: copyAd.message,
            name: copyAd.name,
            startDate: copyAd.startDate,
            state: copyAd.state,
            imageName: copyAd.imageName ? copyAd.imageName : "",
          },
          true,
          dataFilter.state !== ""
        )
      );
    }
    if (component === "pointsOfSale") {
      const copyPoint: IConfigPointSale = { ...row };
      const stateSalesP =
        copyPoint.stateSalesPoint === State.ACTIVE
          ? State.INACTIVE
          : State.ACTIVE;
      copyPoint.stateSalesPoint = stateSalesP;

      const {
        id,

        stateSalesPoint,
      } = copyPoint;
      const updatePointData = {
        id,

        stateSalesPoint,
      };
      await dispatch(
        editStatePointSale(
          updatePointData,
          dataFilter.state && dataFilter.state !== ""
        )
      );
    }

    if (component === "associates") {
      const copyAssociate = { ...row };
      const state =
        copyAssociate.stateUser === State.ACTIVE
          ? State.INACTIVE
          : State.ACTIVE;
      copyAssociate.stateUser = state;

      await dispatch(
        startEditAssociate(
          copyAssociate,
          copyAssociate.id,
          true,
          dataFilter.stateUser && dataFilter.stateUser !== ""
        )
      );
    }
    if (component === "configParams") {
      const requestBody: Partial<IConfigParamOperatorUpdate> = {
        state: row.state === State.ACTIVE ? State.INACTIVE : State.ACTIVE,
      };
      const succesMsg =
        requestBody.state === State.ACTIVE
          ? "succesActivation"
          : "succesInactivation";
      await dispatch(startUpdateParam(row.id, requestBody, succesMsg, page));
    }

    if (component === "observations") {
      const copyObservation = { ...row };
      copyObservation.state =
        +copyObservation.state === State.ACTIVE ? State.INACTIVE : State.ACTIVE;

      dispatch(setActiveObservation(copyObservation));
      dispatch(
        startUpdateObservation(copyObservation, true, dataFilter.state !== "")
      );
    }
  };

  const seeMoreInformation = async (row: any) => {
    component === "users" && setTooltipIsOpen(!tooltipIsOpen);
    if (component === "configCompany") {
      const newCountries = row.countries.map((country: any) => {
        country.paymentMethodsRecharges = country.countriesPayments.filter(
          (pay: any) =>
            pay.paramMethod.paymentMethod.type === "refills" ||
            pay.paramMethod.paymentMethod.type === "global"
        );

        country.paymentMethodsWithdrawal = country.countriesPayments.filter(
          (pay: any) => pay.paramMethod.paymentMethod.type === "withdrawal"
        );

        return country;
      });

      row.countries = newCountries;
      dispatch(setActiveBrand(row));
      setShowTabsConfirmation(true);
    }

    if (component === "associates") {
      dispatch(setFlagFormConfig({ flagFormConfig: true }));
      dispatch(setActiveAssociate(row));
    }

    if (component === "pointsOfSale") {
      dispatch(setFlagFormConfig({ flagFormConfig: true }));
      dispatch(setActivePointSale(row));
    }

    if (component === "roles") {
      dispatch(setFlagFormConfig({ flagFormConfig: true }));
      dispatch(setActiveRol(row));
    }
    if (
      component === "bets" ||
      component === "tickets" ||
      component === "bettingHistory"
    ) {
      dispatch(setFlagFormConfig({ flagFormConfig: true }));
      dispatch(setActiveTicket(row));
    }
    if (component === "menu") {
      dispatch(setFlagFormConfig({ flagFormConfig: true }));
      dispatch(setActiveMenu(row));
    }
  };

  const handleDeleteMenu = async () => {
    if (activeMenu && activeMenu.id) {
      setOpenDialog(false);

      await dispatch(startDeleteMenu(activeMenu.id));
    }
  };
  const handleDeleteRol = async () => {
    setOpenDialog(false);
    const response = token && (await getUsers(token));
    const id = row.rol ? row.rol.id : row.id;
    const findRol = response.data.find(
      (user: IUserData) => user.roles?.id === id
    );
    if (activeRol && !findRol) {
      data
        ? await dispatch(startDeleteRol(id, true))
        : await dispatch(startDeleteRol(id));
    }

    if (findRol) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.roleInUse"));
      dispatch(setTypeAlert("error"));
      dispatch(setActiveRol(null));
    }
  };
  const handleDeleteAd = async () => {
    if (activeAd) {
      setOpenDialog(false);
      await dispatch(startDeleteAd(activeAd.id));
    }
  };
  const handleDeletePointOfSale = async () => {
    if (activePointSale && activePointSale.id) {
      await dispatch(startDeletePointSale(activePointSale.id));
    }
  };

  const handleDeleteExclusions = async () => {
    setOpenDialog(false);
    await dispatch(startUpdateStateExclusion(row.id, State.INACTIVE));
  };
  const handleDeleteLegal = async () => {
    setOpenDialog(false);
    await dispatch(startDeleteLegal(row.id));
  };

  const handleDeletePromotion = async () => {
    setOpenDialog(false);
    await dispatch(startDeletePromotion(row.id));
  };

  const handleDeleteMessage = async () => {
    setOpenDialog(false);
    await dispatch(startDeleteMessage(row.id));
  };

  const handleResendMessage = async () => {
    setOpenDialog(false);
    await dispatch(startResendMessage(row));
  };

  const handleDeleteParameterization = async () => {
    if (param === "bonusTable" && activeBonusType) {
      await dispatch(startDeleteBonusType(activeBonusType.id));
    } else {
      param && (await dispatch(startDeleteParameterization(param)));
      param === "paramPaymentMethods" && dispatch(setActiveParamPayments(null));
      param === "paramSolutions" && dispatch(setActiveParamSolutions(null));
      param === "paramGames" && dispatch(setActiveParamGames(null));
    }
    setOpenDialog(false);
  };

  const handleDeleteObservationPlayer = async () => {
    let dataPlayer = row;
    if (row) {
      dataPlayer.observation = null;
      dataPlayer.status = null;
      const { answersPlayers, password, ...restData } = dataPlayer;
      await dispatch(editPlayer(restData));
    }
    setOpenDialog(false);
  };

  const handleAdd = async () => {
    if (component === "configCompany" && token) {
      const operator: any = await getOperatorByIdService(
        token,
        row.operator.id
      );
      dispatch(setActiveCompany(operator));
      dispatch(setFlagFormConfig(setFlagFormHelperConfig));
    }
  };

  const handleActive = async (row: any) => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    dispatch(setActiveMenu(null));
    dispatch(setActiveRol(null));
    dispatch(setActivePointSale(null));
    dispatch(setActiveAd(null));
  };

  const handleCloseModalConfirmation = () => {
    dispatch(setActiveBrand(null));
    setShowTabsConfirmation(false);
  };

  const handleClickAway = () => {
    setTooltipIsOpen(false);
  };

  const handlePay = (row: any) => {
    if (component === "tickets") {
      dispatch(uiSetOpenDialog());
      dispatch(setActiveTicket(row));
    }
  };
  return (
    <React.Fragment>
      <TableRow hover role="checkbox" tabIndex={-1}>
        {component !== "users" &&
          component !== "bettingHistory" &&
          component !== "parameterizations" &&
          component !== "playerActivity" &&
          component !== "bonds" &&
          component !== "observations" &&
          component !== "configParams" && (
            <TableCell align="center">
              <div className="row">
                {subMenu && (
                  <div
                    className="col-sm-1 iconTableSubMenu"
                    onClick={() => subMenu && toogleShowSubMenu()}
                  >
                    <i
                      className={
                        isShowSubMenu ? "fa fa-angle-up" : "fa fa-angle-right"
                      }
                      style={{
                        color: component !== "bets" ? "#000" : "#007e8c",
                        opacity:
                          row.state === 2 ||
                          (component === "menu" &&
                            row.menuItem &&
                            row.menuItem.length === 0) ||
                          (component === "bets" &&
                            row.events &&
                            row.events.length === 0) ||
                          (component === "roles" &&
                            row.rol &&
                            (row.rol.actionsPermissions.length === 0 ||
                              row.rol.state === 2))
                            ? 0.3
                            : 1,
                      }}
                    />
                  </div>
                )}
                <div className={component !== "bets" ? "col-sm-9" : "col-sm-5"}>
                  {(component === "menu" ||
                    component === "managementCompany" ||
                    component === "roles") && (
                    <div className="word-break">
                      {row.name
                        ? DEFAULT_ROLES.includes(row.name) &&
                          row.name.toUpperCase() !== Roles_Enum.PROMOTOR
                          ? t(`roles.${row.name}`)
                          : row.name
                        : DEFAULT_ROLES.includes(row.rol.name)
                        ? t(`roles.${row.rol.name}`)
                        : row.rol.name}
                    </div>
                  )}
                  {(component === "files" ||
                    component === "pointsOfSale" ||
                    component === "associates" ||
                    component === "managementAds" ||
                    component === "tickets" ||
                    component === "credentials" ||
                    component === "credentialsPointSale" ||
                    component === "paymentOrders" ||
                    component === "commissionalPointSale" ||
                    component === "bets") && (
                    <div className="d-flex">
                      {component === "credentials" ? row.user.id : row.id}
                    </div>
                  )}
                  {component === "casino" && (
                    <div className="d-flex justify-content-center">
                      {moment(row.date).utc().format("YYYY-MM-DD")}
                    </div>
                  )}
                  {component === "managementBonds" && (
                    <div className="d-flex justify-content-center">
                      {row.id}
                    </div>
                  )}
                  {component === "refills" && (
                    <div className="d-flex justify-content-center">
                      {row.id}
                    </div>
                  )}
                  {component === "transactions" && (
                    <div className="d-flex justify-content-center">
                      {row.id}
                    </div>
                  )}
                  {component === "configCompany" && (
                    <div className="word-break">{row.operator.name}</div>
                  )}
                  {component === "legal" && (
                    <div className="d-flex justify-content-center">
                      {"V." + row.version}{" "}
                    </div>
                  )}
                  {component === "messages" && (
                    <div className="d-flex justify-content-center">
                      {row.id}
                    </div>
                  )}
                  {component === "promotions" && (
                    <div className="d-flex justify-content-center">
                      {row.id}
                    </div>
                  )}
                  {component === "irrigation" && (
                    <div className="d-flex justify-content-center">
                      {row.associate.id}
                    </div>
                  )}
                  {component === "exclusions" && (
                    <div className="d-flex">
                      {agileCheck ? row.player.numberDocument : row.player.id}
                    </div>
                  )}
                </div>
              </div>
            </TableCell>
          )}
        {component === "users" && <RowDataTableUsers row={row} />}
        {component === "menu" && <RowDataTable row={row} />}
        {component === "refills" && <RowDataTableRefills row={row} />}
        {component === "roles" && <RowDataTableRoles row={row} />}
        {component === "managementCompany" && <RowDataTableCompany row={row} />}
        {component === "pointsOfSale" && <RowDataTablePointsOfSale row={row} />}
        {component === "associates" && <RowDataTableAssociates row={row} />}
        {component === "credentials" && <RowDataTableCredentials row={row} />}
        {component === "credentialsPointSale" && (
          <RowDataTableCredentialsPointSale row={row} />
        )}
        {component === "commissionalPointSale" && (
          <RowDataTableCommissionerPointSale row={row} />
        )}
        {component === "configCompany" && (
          <RowDataTableCompanyBrands row={row} />
        )}
        {component === "parameterizations" && param && (
          <RowDataTableParamSettings row={row} param={param} />
        )}
        {component === "managementAds" && <RowManagementAds row={row} />}
        {component === "managementBonds" && <RowManagementBonus row={row} />}
        {component === "bets" && <RowDataTableBets row={row} />}
        {component === "casino" && <RowDataTableCasino row={row} />}
        {component === "playerActivity" && <RowDataTableActivity row={row} />}
        {component === "observations" && <RowDataTableObservations row={row} />}
        {component === "tickets" && <RowDataTableTickets row={row} />}
        {component === "bettingHistory" && (
          <RowDataTableBettingHistory row={row} />
        )}
        {component === "configParams" && <RowConfigParams row={row} />}
        {component === "exclusions" &&
          (agileCheck ? (
            <RowDataTableExclusionsAgileCheck row={row} />
          ) : (
            <RowDataTableExclusions row={row} />
          ))}
        {component === "bonds" && <RowDataTableBonds row={row} />}
        {component === "transactions" && <RowDataTableTransactions row={row} />}
        {component === "legal" && <RowDataTableLegal row={row} />}
        {component === "messages" && <RowDataTableMessage row={row} />}
        {component === "promotions" && <RowDataTablePromotion row={row} />}
        {component === "irrigation" && <RowDataTableIrrigation row={row} />}
        {component === "paymentOrders" && (
          <RowDataTablePaymentOrders row={row} />
        )}
        {component === "payHistorySellers" && (
          <RowDataPayHistorySeller row={row} />
        )}
        {component !== "credentials" &&
          component !== "credentialsPointSale" &&
          component !== "paymentOrders" &&
          component !== "commissionalPointSale" &&
          component !== "transactions" &&
          component !== "irrigation" &&
          component !== "refills" &&
          component !== "bonds" && (
            <TableCell align="center">
              <div className="row">
                <div className="col-sm-12">
                  <div className="d-flex justify-content-center align-items-center">
                    {icons && icons.see && (
                      <>
                        {component === "roles" ||
                        component === "managementCompany" ||
                        component === "menu" ||
                        component === "parameterizations" ||
                        component === "configCompany" ? (
                          <Tooltip
                            title={t("button.changeState") as string}
                            arrow
                          >
                            <div>
                              <i
                                className={
                                  row.state === 1 ||
                                  (row.rol && row.rol.state === 1) ||
                                  row.stateOperator === 1 ||
                                  (param === "paramSolutions" &&
                                    row.solutions &&
                                    row.solutions.find(
                                      (sol: any) => sol.active === State.ACTIVE
                                    )) ||
                                  (param === "paramPaymentMethods" &&
                                    row.paymentMethods &&
                                    row.paymentMethods.find(
                                      (pay: any) =>
                                        pay.active === State.ACTIVE &&
                                        pay.type !== "withdrawal"
                                    )) ||
                                  (param === "paramGames" &&
                                    row.games &&
                                    row.games.find(
                                      (game: any) =>
                                        game.active === State.ACTIVE
                                    ))
                                    ? `fa fa-eye fa-lg mr-2 iconSee`
                                    : `fa-regular fa-eye-slash fa-lg mr-2 iconSee`
                                }
                                onClick={() => {
                                  setResendMessage(false);
                                  seeInformation(row);
                                }}
                              />
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={t("button.changeState") as string}
                            arrow
                          >
                            <div>
                              <i
                                className={
                                  row.state === State.ACTIVE ||
                                  row.stateUser === State.ACTIVE ||
                                  row.stateSalesPoint === State.ACTIVE ||
                                  parseInt(row.status) === State.ACTIVE
                                    ? "fa fa-eye fa-lg mr-2 iconSee"
                                    : "fa-regular fa-eye-slash fa-lg mr-2 iconSee"
                                }
                                onClick={() => {
                                  seeInformation(row);
                                  setResendMessage(false);
                                }}
                              />
                            </div>
                          </Tooltip>
                        )}
                      </>
                    )}
                    {icons && icons.edit && showEditConfigParam && (
                      <Tooltip title={t("button.edit") as string} arrow>
                        <div>
                          <i
                            onClick={() => formularioCreacion()}
                            className="fa fa-pencil-square-o fa-lg mr-2 iconEdit"
                          />
                        </div>
                      </Tooltip>
                    )}

                    {icons && icons.send && component === "messages" && (
                      <Tooltip title={t("button.reSend") as string} arrow>
                        <div>
                          <i
                            onClick={() => reSendMessage()}
                            className="fa fa-paper-plane fa-lg mr-2"
                            style={{
                              color: "#078C4F",
                            }}
                          />
                        </div>
                      </Tooltip>
                    )}

                    {icons &&
                      icons.pay &&
                      row.status &&
                      row.status === "S" &&
                      !row.paid && (
                        <Tooltip title={t("bets.pay") as string}>
                          <div>
                            <button
                              onClick={() => {
                                setResendMessage(false);
                                handlePay(row);
                              }}
                              className="buttonActive mr-2"
                            >
                              {t("bets.pay").toUpperCase()}
                            </button>
                          </div>
                        </Tooltip>
                      )}

                    {icons && icons.seeMore && (
                      <ClickAwayListener onClickAway={handleClickAway}>
                        {component === "users" ? (
                          <Tooltip
                            open={tooltipIsOpen}
                            onOpen={handleOpenTooltip}
                            onClose={handleCloseTooltip}
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: "transparent",
                                  "& .MuiTooltip-arrow": {
                                    color: "#02D0E7",
                                  },
                                  fontWeight: "bold",
                                  color: "#000",
                                  maxWidth: "none",
                                },
                              },
                            }}
                            title={
                              <TableContainer component={Paper}>
                                <Table
                                  stickyHeader
                                  aria-label="collapsible table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCellPrimary align="right">
                                        {t("user.specialStatus")}
                                      </StyledTableCellPrimary>
                                      <StyledTableCellPrimary align="right">
                                        {t("user.autenticationTwoStep")}
                                      </StyledTableCellPrimary>
                                      <StyledTableCellPrimary align="right">
                                        {t("user.failedLoginAttempts")}
                                      </StyledTableCellPrimary>
                                      <StyledTableCellPrimary align="right">
                                        {t("user.observation")}
                                      </StyledTableCellPrimary>
                                      <StyledTableCellPrimary align="right">
                                        {t("user.login")}
                                      </StyledTableCellPrimary>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <StyledTableCell align="center">
                                        {row.stateUser === State.ACTIVE
                                          ? t("state.active")
                                          : row.stateUser === State.INACTIVE
                                          ? t("state.inactive")
                                          : row.stateUser === State.RESTRICTED
                                          ? t("state.restricted")
                                          : t("state.retired")}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {parseInt(row.verificationTwoStep) ===
                                        State.INACTIVE
                                          ? t("table.not")
                                          : t("table.yes")}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {row.userLoginHistory}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {row.observation}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {row.username}
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            }
                            disableHoverListener
                          >
                            <div>
                              <i
                                className="fa fa-search-plus fa-lg mr-2 iconSeeMore"
                                onClick={() => {
                                  seeMoreInformation(row);
                                  setResendMessage(false);
                                }}
                              />
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={t("button.showMoreInfo") as string}
                            arrow
                          >
                            <div>
                              <i
                                className="fa fa-search-plus fa-lg mr-3 iconSeeMore"
                                onClick={() => {
                                  seeMoreInformation(row);
                                  setResendMessage(false);
                                }}
                              />
                            </div>
                          </Tooltip>
                        )}
                      </ClickAwayListener>
                    )}
                    {icons && icons.delete && (
                      <>
                        {((component !== "roles" &&
                          component !== "exclusions") ||
                          (component === "roles" &&
                            row.name !== Roles_Enum.ADMIN_OPERATOR)) && (
                          <Tooltip title={t("button.delete") as string} arrow>
                            <div>
                              <i
                                className="fa fa-trash fa-lg iconDelete"
                                onClick={() => confirmationDeleteMenu()}
                              />
                            </div>
                          </Tooltip>
                        )}
                      </>
                    )}
                    {icons && icons.add && (
                      <Tooltip title={t("button.add") as string} arrow>
                        <div>
                          <i
                            className="fa fa-plus-circle fa-lg iconAdd"
                            onClick={() => {
                              setResendMessage(false);
                              handleAdd();
                            }}
                          />
                        </div>
                      </Tooltip>
                    )}

                    {icons && icons.activate && row.state === State.ACTIVE && (
                      <Tooltip title={t("button.activate") as string}>
                        <div>
                          <button
                            onClick={() => {
                              setResendMessage(false);
                              handleActive(row);
                            }}
                            className="buttonActive"
                            disabled={
                              component === "exclusions" &&
                              !row.totalExclusion &&
                              moment(today).isAfter(row.endDate)
                            }
                          >
                            {t("button.activate").toUpperCase()}
                          </button>
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
              <MyDialog
                open={openDialog}
                title={t("dialog.confirmationWindow")}
                message={
                  component === "menu"
                    ? t("menuManagment.deleteMenuAsk")
                    : component === "roles"
                    ? `${t("rolManagement.deleteRolAsk")} ${
                        row.rol ? row.rol.name : row.name
                      }?`
                    : component === "parameterizations"
                    ? t("parameterization.deleteParameterizationAsk")
                    : component === "managementAds"
                    ? t("managementAds.deleteAdAsk")
                    : component === "pointsOfSale"
                    ? t("pointsOfSale.deletePointOfSaleAsk")
                    : component === "observations"
                    ? t("observations.deleteObservationPlayer")
                    : component === "managementBonds"
                    ? t("managementBonds.deleteBond")
                    : component === "exclusions"
                    ? t("exclusions.sureActivateUser")
                    : component === "legal"
                    ? t("legal.sureDeleteLegal")
                    : component === "messages" && !isResendMessage
                    ? t("messages.sureDeleteMessage")
                    : component === "messages" && isResendMessage
                    ? t("messages.sureResendMessage")
                    : component === "promotions"
                    ? t("promotions.sureDeletePromotion")
                    : ""
                }
                handleAccept={
                  component === "menu"
                    ? handleDeleteMenu
                    : component === "roles"
                    ? handleDeleteRol
                    : component === "managementAds"
                    ? handleDeleteAd
                    : component === "pointsOfSale"
                    ? handleDeletePointOfSale
                    : component === "observations"
                    ? handleDeleteObservationPlayer
                    : component === "exclusions"
                    ? handleDeleteExclusions
                    : component === "messages" && !isResendMessage
                    ? handleDeleteMessage
                    : component === "messages" && isResendMessage
                    ? handleResendMessage
                    : component === "legal"
                    ? handleDeleteLegal
                    : component === "promotions"
                    ? handleDeletePromotion
                    : handleDeleteParameterization
                }
                handleClose={handleCloseDialog}
              />
              <Dialog
                open={showDialogFiles}
                onClose={() => setShowDialogFiles(false)}
              >
                <DialogTitle
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton
                    disableFocusRipple
                    disableRipple
                    style={{ border: "none", outline: "none" }}
                    onClick={() => setShowDialogFiles(false)}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </IconButton>
                </DialogTitle>
                <Box className="p-3">
                  <DialogContent>
                    {row.frontCard && row.backCard ? (
                      <>
                        <Grid item xs={12} mt={3} textAlign="center">
                          <img
                            src={`${row.frontCard}`}
                            width="100%"
                            height="350"
                            alt="Front card"
                          />
                        </Grid>
                        <Grid item xs={12} mt={3} textAlign="center">
                          <img
                            src={`${row.backCard}`}
                            width="100%"
                            height="350"
                            alt="Back card"
                          />
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12} mt={3} textAlign="center">
                        <img
                          src={`${row.selfie}`}
                          width="100%"
                          height="350"
                          alt="Selfie card"
                        />
                      </Grid>
                    )}
                  </DialogContent>
                </Box>
              </Dialog>

              <DialogTabsConfirmation
                openModal={showTabsConfirmation}
                handleClose={handleCloseModalConfirmation}
              />
            </TableCell>
          )}
        {exportData &&
          (component === "users" ||
            component === "configCompany" ||
            component === "managementCompany" ||
            component === "menu" ||
            component === "legal" ||
            component === "irrigation" ||
            component === "bets" ||
            component === "bonds") && <TableCell align="center" />}

        {component === "bettingHistory" && <TableCell align="center" />}
      </TableRow>
      {isShowSubMenu && component === "menu" && row.state === 1 && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={isShowSubMenu} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table>
                  <TableHead>
                    <TableRow
                      style={{ backgroundColor: "#F4F4F4" }}
                      key={row.id}
                    >
                      <StyledTableCell align="center">
                        {t("table.module").toUpperCase()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t("table.url").toUpperCase()}
                      </StyledTableCell>
                      <StyledTableCell align="center" width={"10%"}>
                        {t("table.description").toUpperCase()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t("table.createdBy").toUpperCase()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t("table.state").toUpperCase()}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.menuItem.length > 0 &&
                      row.menuItem.map((item: IMenuItem, index: number) => (
                        <RowTableMenuItems
                          key={item.id + "-" + index}
                          row={item}
                        />
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};
