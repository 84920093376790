import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Filter from "../../../../components/Filter/Filter";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import {
  asyncLoadingFunction,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { Alert, AlertTitle, Grid, Stack } from "@mui/material";
import MyCustomTable, {
  IColumns,
} from "../../../../components/Custom/Table/MyCustomTable";
import InfoIcon from "@mui/icons-material/Info";
import { RowDataTableProgressiveJackpotWon } from "./RowDataTableProgressiveJackpotWon";
import { startGetGameRevenueReport } from "../../../../redux/actions/managementReportsActions";
import { gameRevenueReport } from "../../../../services/reports";
import { ProgressiveJackpotWonDataTable } from "../../../../hooks/useDataTable/reports/ProgressiveJackpotWonDataTable";
import { ProgressiveJackpotWonDocument } from "../../../../components/Pdf/Reports/ProgressiveJackpotWonDocument";

export const ProgressiveJackpotWon = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dataFilter, setDataFilter] = useState<any>({});
  const { token } = useSelector((state: AppState) => state.auth);
  const [filter, setFilter] = useState(false);
  const [resetPage, setResetPage] = useState(false);
  const { data } = useSelector((state: AppState) => state.search);

  const filterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    }
  ];

  const columns: IColumns[] = [
    {
      align: "center",
      label: t("reports.progressiveJackpotName"),
    },
    {
      align: "center",
      label: t("reports.gamePayoutsId"),
    },
    {
      align: "center",
      label: t("reports.playerSessionId"),
    },
    {
      align: "center",
      label: t("reports.playerId"),
    },
    {
      align: "center",
      label: t("reports.gameLoopId"),
    },
    {
      align: "center",
      label: t("reports.level"),
    },
    {
      align: "center",
      label: t("reports.progressiveJackpotAmount"),
    },
  ];

  const handleFilter = (formValues: any) => {
    setResetPage(false);
    const startDate = formValues[t("filters.startDate")];
    const endDate = formValues[t("filters.endDate")];
    const params = {
      startDate,
      endDate,
    };

    if (endDate.trim() && !startDate.trim()) {
      dispatch(setMessageAlert("alerts.selectStartDate"));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }

    const errorDate = moment(startDate).isAfter(endDate);
    if (errorDate) {
      dispatch(setMessageAlert("alerts.errorDate"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    } else {
      dispatch(
        asyncLoadingFunction(
          async () => await dispatch(startGetGameRevenueReport(params))
        )
      );
    }
    setDataFilter(params);
    setFilter(true);
    setResetPage(true);
  };

  const onCleanFilter = async () => {
    setDataFilter({});
    setResetPage(true);
    setFilter(false);
  };

  const handlePageChange = async (page: number, rows?: number) => {
    setResetPage(false);
  };

  const getGameRevenueReport = async (props: any) => {
    return await gameRevenueReport(token, dataFilter)
  };

  return (
    <>
      <div
        className="filter"
        style={{ padding: "18px 0px 0px", marginBottom: "18px" }}
      >
        <Filter
          fields={filterFields}
          setDataFilter={setDataFilter}
          handleFilter={handleFilter}
          onClean={onCleanFilter}
        />
      </div>
      {data && data.data.length > 0 && (
        <div className="filter">
          <Grid item xs={12}>
            <MyCustomTable
              columns={columns}
              data={data.data}
              lastPage={data.last_page}
              totalData={data.total}
              renderBody={(item: any) => <RowDataTableProgressiveJackpotWon row={item} />}
              onPageChange={handlePageChange}
              resetCurrentPage={resetPage}
              exportData
              hookBuildTable={ProgressiveJackpotWonDataTable}
              getAllDataToExport={getGameRevenueReport}
              pdfComponent={ProgressiveJackpotWonDocument}
            />
          </Grid>
        </div>
      )}

      {!filter && !data && (
        <Grid item xs={12} pt={3} mt={-2}>
          <Stack
            sx={{ width: "100%" }}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
            spacing={2}
          >
            <Alert
              icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
              sx={{
                borderLeft: `7px solid #2C97DF`,
                backgroundColor: "#EAF5FC",
                color: "#4b4b4b",
              }}
            >
              <div className="d-flex">
                <AlertTitle
                  style={{ marginTop: "5px" }}
                  sx={{ color: "#333333" }}
                >
                  {t("alerts.clickButton")}
                </AlertTitle>
                <AlertTitle
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  sx={{ color: "#333333", fontWeight: "bold" }}
                >
                  {t("reports.gameRevenueReport")}
                </AlertTitle>
              </div>
            </Alert>
          </Stack>
        </Grid>
      )}
    </>
  );
};
