import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { headersGamingActivityCasino } from "../../../helpers/headersTables";
import { getCopyArrayOrObject } from "../../../utils/utils";
import moment from "moment";
import { ICasino } from "../../../interfaces/casino";
import { useTranslation } from "react-i18next";

export const GamingActivityCasinoDataTable = (totalData: ICasino[]) => {
  const { t } = useTranslation();
  const { data } = useSelector((state: AppState) => state.search);

  const headersCvsStyle = headersGamingActivityCasino.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });
  // Obtener datos por vista
  const dataGamingActivityCasino =
    data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalaGamingActivityCasino = totalData
    ? getCopyArrayOrObject(totalData)
    : [];

  const buildTotalData = (arr: ICasino[]) => {
    const multiData = [
      {
        columns: headersCvsStyle,
        data: arr.map((data: ICasino) => {
          const lowerCaseState = data.state.toLowerCase();
          const statesWin = ["w", "cb", "jw"];
          const betWon =
            statesWin.includes(lowerCaseState) ||
            parseFloat(data.amountWin) > 0;

          const cancelTransaction = lowerCaseState.includes("ct");
          const statusBonus = ["db", "bw", "cb"];
          const bonus = statusBonus.includes(lowerCaseState);
          const statusText = cancelTransaction
            ? t("bets.cancelTransaction")
            : betWon
            ? t("bets.won")
            : t("bets.lost");
          const array = [
            {
              value: moment(data.createAt).format("YYYY-MM-DD HH:mm:ss"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.id,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.eventDescription,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.event || "",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.betAmount
                ? "$ " + parseFloat(data.betAmount).toFixed(2)
                : "0.00",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.amountWin
                ? "$ " + parseFloat(data.amountWin).toFixed(2)
                : "0.00",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: statusText,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: bonus ? t("table.yes") : t("table.not"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.provider,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.platform,
              style: { alignment: { horizontal: "center" } },
            },
          ];
          return array;
        }),
      },
    ];
    return multiData;
  };

  const multiTotalData = buildTotalData(dataTotalaGamingActivityCasino);
  const multiData = buildTotalData(dataGamingActivityCasino);
  const finalData = {
    dataPerView: dataGamingActivityCasino,
    dataTotal: dataTotalaGamingActivityCasino,
    headersCSV: headersGamingActivityCasino,
    multiDataSet: multiData,
    multiTotalDataSet: multiTotalData,
  };
  return finalData;
};
