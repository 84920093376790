import { IFormLegal } from "../interfaces/legal";

export const getLegalParameters = (
  token: string,
  operatorId: number | null,
  params?: any
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const operatorParam = operatorId ? `operatorId=${operatorId}` : "";
  const queryString = params ? new URLSearchParams(params).toString() : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}legal?${operatorParam}&${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const createLegalParameter = (data: IFormLegal, token: string) => {
  const myHeaders = new Headers();
  myHeaders.append("api", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Authorization", `bearer ${token}`);
  const formData = new FormData();

  formData.append("title", data.title);
  formData.append("state", data.state.toString());
  formData.append("version", data.version.toString());
  formData.append("operator", data.operator.toString());
  formData.append("createdUser", data.createdUser.toString());
  formData.append("fileName", data.fileName);
  formData.append("edit", data.edit.toString());
  formData.append("stringText", data.stringText);
  data.file && formData.append("file", data.file);

  const requestOptions: any = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}legal`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const updateLegalParameter = (data: IFormLegal, token: string) => {
  const myHeaders = new Headers();
  myHeaders.append("api", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Authorization", `bearer ${token}`);
  const formData = new FormData();

  formData.append("title", data.title);
  formData.append("state", data.state.toString());
  formData.append("version", data.version.toString());
  formData.append("operator", data.operator.toString());
  formData.append("createdUser", data.createdUser.toString());
  formData.append("fileName", data.fileName);
  formData.append("edit", data.edit.toString());
  formData.append("stringText", data.stringText);
  data.file && formData.append("file", data.file);

  const requestOptions: any = {
    method: "PUT",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}legal/${data.id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const deleteLegal = (id: number, token: string) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}legal/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
