import React, {
  ChangeEvent,
  useState,
  useEffect,
  KeyboardEvent,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Form, Formik } from "formik";
import { Button, CircularProgress, Grid, TextField } from "@mui/material";

import { regNumbers } from "../../../utils/utils";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Roles_Enum } from "../../../enums/enums";
import { getOperatorByIdService } from "../../../services/managementCompanyService";
import { MyTextField } from "../../components/custom/MyTextField";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import {
  IDepositLimit,
  IOperationManagForm,
} from "../../../interfaces/operationCompany";
import { startEditOperator } from "../../../redux/actions/managementCompanyActions";
import * as yup from "yup";

interface FormValues {
  maximumDailyRefill: string;
  maximumWeeklyRefill: string;
  maximumMonthlyRefill: string;
}

export const DepositLimit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const { user, token } = useSelector((state: AppState) => state.auth);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const [operatorId, setOperatorId] = useState(
    isSuper ? "" : user?.operator?.id
  );
  const [checking, setChecking] = useState(false);
  const [operator, setOperator] = useState<any>(null);

  const initialValues: IDepositLimit = {
    maximumDailyRefill: operator?.maximumDailyRefill
      ? operator.maximumDailyRefill
      : "",
    maximumWeeklyRefill: operator?.maximumWeeklyRefill
      ? operator.maximumWeeklyRefill
      : "",
    maximumMonthlyRefill: operator?.maximumMonthlyRefill
      ? operator.maximumMonthlyRefill
      : "",
  };

  useEffect(() => {
    handleGetOperator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetOperator = async () => {
    setChecking(true);
    if (token && operatorId) {
      const operator = await getOperatorByIdService(token, +operatorId);
      if (!operator) {
        setChecking(false);
        dispatch(setMessageAlert("alerts.noData"));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      setOperator(operator);
    }
    setChecking(false);
  };

  const handleUpdateOperator = async (data: IDepositLimit) => {
    const { maximumDailyRefill, maximumWeeklyRefill, maximumMonthlyRefill } =
      data;
    if (operator?.id) {
      const objEditOperator: Partial<IOperationManagForm> = {
        maximumDailyRefill: maximumDailyRefill.trim() || null,
        maximumWeeklyRefill: maximumWeeklyRefill.trim() || null,
        maximumMonthlyRefill: maximumMonthlyRefill.trim() || null,
      };
      await dispatch(startEditOperator(objEditOperator, operator.id));
    }
  };

  const validationSchema = yup.object({
    maximumDailyRefill: yup.string(),
    maximumWeeklyRefill: yup.string(),
    maximumMonthlyRefill: yup.string(),
  });

  const validateRefillValues = (values: FormValues) => {
    const { maximumDailyRefill, maximumWeeklyRefill, maximumMonthlyRefill } =
      values;
    const errors: Partial<FormValues> = {};

    if (
      maximumDailyRefill &&
      maximumWeeklyRefill &&
      parseFloat(maximumDailyRefill) >= parseFloat(maximumWeeklyRefill)
    ) {
      errors.maximumDailyRefill = t("deposits.limitValidationError", {
        limit1: t("deposits.daily"),
        condition: t("deposits.less"),
        limit2: t("deposits.weekly"),
      });
    }

    if (
      maximumDailyRefill &&
      maximumMonthlyRefill &&
      parseFloat(maximumDailyRefill) >= parseFloat(maximumMonthlyRefill)
    ) {
      errors.maximumDailyRefill = t("deposits.limitValidationError", {
        limit1: t("deposits.daily"),
        condition: t("deposits.less"),
        limit2: t("deposits.monthly"),
      });
    }

    if (
      maximumWeeklyRefill &&
      parseFloat(maximumWeeklyRefill) <= parseFloat(maximumDailyRefill)
    ) {
      errors.maximumWeeklyRefill = t("deposits.limitValidationError", {
        limit1: t("deposits.weekly"),
        condition: t("deposits.greater"),
        limit2: t("deposits.daily"),
      });
    }

    if (
      maximumWeeklyRefill &&
      maximumMonthlyRefill &&
      parseFloat(maximumWeeklyRefill) >= parseFloat(maximumMonthlyRefill)
    ) {
      errors.maximumWeeklyRefill = t("deposits.limitValidationError", {
        limit1: t("deposits.weekly"),
        condition: t("deposits.less"),
        limit2: t("deposits.monthly"),
      });
    }

    if (
      maximumMonthlyRefill &&
      parseFloat(maximumMonthlyRefill) <= parseFloat(maximumDailyRefill)
    ) {
      errors.maximumMonthlyRefill = t("deposits.limitValidationError", {
        limit1: t("deposits.monthly"),
        condition: t("deposits.greater"),
        limit2: t("deposits.daily"),
      });
    }

    if (
      maximumMonthlyRefill &&
      parseFloat(maximumMonthlyRefill) <= parseFloat(maximumWeeklyRefill)
    ) {
      errors.maximumMonthlyRefill = t("deposits.limitValidationError", {
        limit1: t("deposits.monthly"),
        condition: t("deposits.greater"),
        limit2: t("deposits.weekly"),
      });
    }

    return errors;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} className="white-container" mt={2} ml={2}>
        <Formik
          innerRef={formRef}
          validate={validateRefillValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            await handleUpdateOperator(data);
            setSubmitting(false);
          }}
        >
          {({ handleChange, values, isSubmitting, handleReset, errors }) => (
            <Form>
              {isSuper && (
                <Grid container spacing={2}>
                  <Grid item sm={12} md={7}>
                    <TextField
                      name="operatorId"
                      value={operatorId || ""}
                      label={t("parameterization.operatorId")}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleReset();
                        setOperator(null);
                        setOperatorId(e.target.value as string);
                      }}
                      onKeyPress={(e: any) => {
                        !regNumbers(e.key) && e.preventDefault();
                      }}
                      inputProps={{
                        maxLength: 6,
                      }}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={12} md={5}>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      className="primary-btn ripple"
                      style={{ border: "none", outline: "none" }}
                      onClick={() => {
                        handleReset();
                        setOperator(null);
                        handleGetOperator();
                      }}
                      disabled={!operatorId?.toString().trim() || !!operator}
                    >
                      {checking ? (
                        <CircularProgress size={"1.6rem"} color="inherit" />
                      ) : (
                        t("filters.filter")
                      )}
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                spacing={2}
                columns={{ xs: 1, sm: 1, md: 1 }}
                mb={5}
                mt={1}
              >
                <Grid item xs={1}>
                  <label className="form-label">
                    {t("playerLimit.maximumDailyRefill")}
                  </label>
                  <MyTextField
                    disabled={isSubmitting || !operatorId}
                    name="maximumDailyRefill"
                    value={values.maximumDailyRefill}
                    label={""}
                    InputLabelProps={{ shrink: false }}
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    onKeyPress={(e: any) => {
                      !regNumbers(e.key) && e.preventDefault();
                    }}
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <label className="form-label">
                    {t("playerLimit.maximumWeeklyRefill")}
                  </label>
                  <MyTextField
                    disabled={isSubmitting || !operatorId}
                    name="maximumWeeklyRefill"
                    value={values.maximumWeeklyRefill}
                    label={" "}
                    InputLabelProps={{ shrink: false }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regNumbers(e.key) && e.preventDefault()
                    }
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <label className="form-label">
                    {t("playerLimit.maximumMonthlyRefill")}
                  </label>
                  <MyTextField
                    disabled={isSubmitting || !operatorId}
                    name="maximumMonthlyRefill"
                    label={" "}
                    value={values.maximumMonthlyRefill}
                    InputLabelProps={{ shrink: false }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regNumbers(e.key) && e.preventDefault()
                    }
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={
                      isSubmitting ||
                      !!!operator ||
                      !operatorId ||
                      !!errors.maximumDailyRefill ||
                      !!errors.maximumMonthlyRefill ||
                      !!errors.maximumWeeklyRefill
                    }
                    fullWidth
                    className="primary-btn ripple"
                    style={{ border: "none", outline: "none" }}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      t("button.save")
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
