import { TableCell } from "@material-ui/core";
import moment from "moment";
import React, { FC } from "react";
interface props {
  row: any;
}
export const RowPromotialAcount: FC<props> = ({ row }) => {
  const getTotalExpiredBonus = () => {
    const { endDate, amountBonus, totalBonusDelivered } = row;
    const isExpired = moment(endDate).isAfter(moment());
    if (isExpired) {
      return parseInt(amountBonus) - totalBonusDelivered;
    }
    return 0;
  };
  return (
    <>
      <TableCell className="word-break">{row.name}</TableCell>
      <TableCell className="word-break">{row.value}</TableCell>
      <TableCell className="word-break">{row.totalBonusDelivered}</TableCell>
      <TableCell className="word-break">{row.amountBonus}</TableCell>
      <TableCell className="word-break">{row.totalValue}</TableCell>
      <TableCell className="word-break">
        {parseInt(row.value) * row.totalBonusDelivered}
      </TableCell>
      <TableCell className="word-break">{row.provider.name}</TableCell>

      <TableCell className="word-break">
        {row.createdUser.nameComplete}
      </TableCell>

      <TableCell className="word-break">
        {moment(row.createAt).format("DD/MM/YYYY HH:mm:ss")}
      </TableCell>
      <TableCell className="word-break">{row.operator.name}</TableCell>
      <TableCell className="word-break">{getTotalExpiredBonus()}</TableCell>
    </>
  );
};
