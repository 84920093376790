import { IRefills, IRefillsData } from "../../interfaces/refills";
import {
  IFullDetailPlayer,
  IFullDetailPlayerData,
  IPlayerData,
} from "../../interfaces/users";
import { Types } from "../types/types";
import {
  IExclusionCategory,
  IExclusionsData,
} from "../../interfaces/exclusions";
import { IPaginationData } from "../../interfaces/shared";
import {
  IObservationsData,
  IPlayerUpdateHistoryData,
} from "../../interfaces/players";
import { IBonusRedeemed } from "../../interfaces/bonus";

interface IManagementPlayersActions {
  type: Types;
  payload?:
    | IFullDetailPlayer
    | IRefills
    | IRefillsData
    | IRefillsData[]
    | IFullDetailPlayerData[]
    | IFullDetailPlayerData
    | IPlayerData
    | IExclusionsData
    | IExclusionsData[]
    | IPaginationData<IExclusionCategory>
    | IPaginationData<IObservationsData>
    | IObservationsData
    | IPaginationData<IBonusRedeemed>
    | null
    | any;
}

interface IPlayerDataState {
  player: IFullDetailPlayerData | null;
  refills: IRefillsData | null;
  totalRefills: IRefillsData | null;
  exclusions: IExclusionsData | null;
  exclusionCategories: IPaginationData<IExclusionCategory> | null;
  observations: IPaginationData<IObservationsData> | null;
  activeObservation: IObservationsData | null;
  bonusRedeemed: IPaginationData<IBonusRedeemed> | null;
  totalBonusRedeemed: IPaginationData<IBonusRedeemed> | null;
  playerUpdateHistory: IPaginationData<IPlayerUpdateHistoryData> | null;
}

const initialState: IPlayerDataState = {
  player: null,
  refills: null,
  totalRefills: null,
  exclusions: null,
  exclusionCategories: null,
  observations: null,
  activeObservation: null,
  bonusRedeemed: null,
  totalBonusRedeemed: null,
  playerUpdateHistory: null,
};

export const managementPlayerSReducer = (
  state = initialState,
  action: IManagementPlayersActions
): IPlayerDataState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setPlayer:
      return {
        ...state,
        player: payload as IFullDetailPlayerData | null,
      };
    case Types.setRefills:
      return {
        ...state,
        refills: payload as IRefillsData | null,
      };
    case Types.setTotalRefills:
      return {
        ...state,
        totalRefills: payload as IRefillsData | null,
      };
    case Types.updatePlayer:
      const playerUpdated = state.player
        ? ({
            ...state.player,
            data: state.player.data.map((row) =>
              row.id === (payload as IPlayerData).id
                ? (payload as IPlayerData)
                : row
            ),
          } as IFullDetailPlayerData)
        : state.player;
      return {
        ...state,
        player: playerUpdated,
      };

    case Types.setExclusions:
      return {
        ...state,
        exclusions: payload as IExclusionsData | null,
      };

    case Types.addExclusion:
      const newExclusions = state.exclusions
        ? ({
            ...state.exclusions,
            data: [payload.exclusion, ...state.exclusions.data],
            total: state.exclusions.total + 1,
            last_page: Math.ceil(
              (state.exclusions.total + 1) / payload.rowsPerPage
            ),
          } as IExclusionsData)
        : state.exclusions;
      return {
        ...state,
        exclusions: newExclusions,
      };

    case Types.updateExclusion:
      const exclusionUpdated = state.exclusions
        ? ({
            ...state.exclusions,
            data: state.exclusions?.data.map((exclusion) =>
              exclusion.id === (payload.id as any)
                ? { ...exclusion, ...payload.data }
                : exclusion
            ),
          } as IExclusionsData)
        : state.exclusions;

      return {
        ...state,
        exclusions: exclusionUpdated,
      };

    case Types.setExclusionCategories:
      return {
        ...state,
        exclusionCategories:
          payload as IPaginationData<IExclusionCategory> | null,
      };

    case Types.setObservations:
      return {
        ...state,
        observations: payload as IPaginationData<IObservationsData> | null,
      };

    case Types.setActiveObservation:
      return {
        ...state,
        activeObservation: payload as IObservationsData,
      };

    case Types.setBonusRedeemedByPlayer:
      return {
        ...state,
        bonusRedeemed: payload as IPaginationData<IBonusRedeemed> | null,
      };

    case Types.setTotalBonusRedeemedByPlayer:
      return {
        ...state,
        totalBonusRedeemed: payload as IPaginationData<IBonusRedeemed> | null,
      };
    case Types.setPlayerUpdateHistory:
      return {
        ...state,
        playerUpdateHistory:
          payload as IPaginationData<IPlayerUpdateHistoryData> | null,
      };

    default:
      return state;
  }
};
