import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../../../components/Filter/Filter";
import { FormCreateRol } from "../../components/ManagementRoles/FormCreateRol";
import { TableComponent } from "../../../components/Table/TableComponent";
import {
  Enum_FieldType,
  IColumnsTable,
  Icons,
  IFilterField,
} from "../../../interfaces/components";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  setCloseAlert,
  setFlagForm,
  setFlagSelect,
  setRowsPerPage,
} from "../../../redux/actions/helperActions";
import { setActiveRol, startGetRolesByPage } from "../../../redux/actions/managementRolesActions";
import { IPaginationRoles } from "../../../redux/interfaces/users";
import { getAllRoles, getRoles } from "../../../services/helperService";
import { MyAlert } from "../../components/custom/MyAlert";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { getMenus } from "../../../redux/actions/managementMenuActions";
import { setActiveUser } from "../../../redux/actions/managementUsersActions";
import { Roles_Enum, State } from "../../../enums/enums";
import { getOperatorsService } from "../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../redux/actions/managementCompanyActions";
import { useLocation } from "react-router-dom";
import { arraySearch } from "../../../redux/actions/searchActions";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { MyDialogInformation } from "../../../components/DialogInformation/MyDialogInformation";

export const ManagementRoles = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { flagFormCreate, flagSelect, openAlert, messageAlert, typeAlert } =
    useSelector((state: AppState) => state.helper);
  const { data } = useSelector((state: AppState) => state.search);
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { roles } = useSelector((state: AppState) => state.managementRoles);
  const { menus, routes } = useSelector(
    (state: AppState) => state.managementMenus
  );
  const { activeRol } = useSelector((state: AppState) => state.managementRoles);
  const [allRoles, setAllRoles] = useState<IPaginationRoles | null>(null);
  const [filterFields, setfilterFields] = useState<IFilterField[]>([]);
  const [dataFilter, setDataFilter] = useState<any>({});
  const { pathname } = useLocation();

  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;

  useEffect(() => {
    const fetchRoles = async (
      token: string,
      idOperador?: number,
      isSuper?: boolean
    ) => {
      if (!user) {
        throw new Error("Not available sesion");
      }
      await dispatch(startGetRolesByPage(token, 1, idOperador));

      //SI ES SUPER TRAE TODOS LOS ROLES, DE LO CONTRARIO SOLO TRAE LOS QUE CORRESPONDAN AL OPERADOR
      const response = isSuper
        ? await getAllRoles(token, null)
        : await getRoles(token, idOperador?.toString(), false, isSuper);

      if (isSuper) {
        const { data } = response;
        const filterData = data.filter(
          (rol: any) => rol.name !== "SUPER_ADMIN"
        );
        response.data = filterData;
      }
    
      const params = {
        state: State.ACTIVE,
      };
      await dispatch(getMenus(token, undefined, false, params));

      setAllRoles(response);
    };
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    token && fetchOperators(token);

    dispatch(setCloseAlert());
    dispatch(setRowsPerPage(9));
    if (user && token && !flagFormCreate) {
      const isSuper = user.roles.name === Roles_Enum.SUPER_ADMIN;

      const id = isSuper ? undefined : user.operator.id;

      fetchRoles(token, id, isSuper);
    }
    return () => {
      dispatch(setCompaniesRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagFormCreate]);

  useEffect(() => {
    let FilterFileds: IFilterField[] = [
      {
        label: t("user.rol"),
        type: Enum_FieldType.SELECT,
        options: [],
      },
      {
        label: t("menuManagment.menu"),
        type: Enum_FieldType.SELECT,
        options: [],
      },
      {
        label: t("rolManagement.permissions"),
        type: Enum_FieldType.SELECT,
        options: [
          {
            label: t("rolManagement.create"),
            value: 1,
          },
          {
            label: t("rolManagement.read"),
            value: 2,
          },
          {
            label: t("rolManagement.edit"),
            value: 3,
          },
          {
            label: t("rolManagement.delete"),
            value: 4,
          },
        ],
      },
      {
        label: t("form.state"),
        type: Enum_FieldType.SELECT,
        options: [
          {
            label: t("state.active"),
            value: State.ACTIVE,
          },
          {
            label: t("state.inactive"),
            value: State.INACTIVE,
          },
        ],
      },
    ];
    if (allRoles && menus) {
      if (flagSelect) {
        const fetchRoles = async (
          token: string,
          idOperador?: number,
          isSuper?: boolean
        ) => {
          //SI ES SUPER TRAE TODOS LOS ROLES, DE LO CONTRARIO SOLO TRAE LOS QUE CORRESPONDAN AL OPERADOR
          const response = isSuper
            ? await getAllRoles(token, null)
            : await getRoles(token, idOperador?.toString(), false, isSuper);

          if (isSuper) {
            const { data } = response;
            const filterData = data.filter(
              (rol: any) => rol.name !== "SUPER_ADMIN"
            );
            response.data = filterData;
          }
          await dispatch(getMenus(token, undefined, false));

          setAllRoles(response);
        };
        if (user && token) {
          const isSuper = user.roles.name === Roles_Enum.SUPER_ADMIN;

          const id = isSuper ? undefined : user.operator.id;
          fetchRoles(token, id, isSuper);
        }
        dispatch(setFlagSelect({ flagSelect: false }));
      }

      if (allRoles.data.length > 0) {
        FilterFileds[0] = {
          ...FilterFileds[0],
          options: [
            ...allRoles.data.map((itemRol) => ({
              id: itemRol.rol ? itemRol.rol.id : itemRol.id,
              label: itemRol.rol ? itemRol.rol.name : itemRol.name,
              //value: rol.id.toString(),
              value: itemRol.rol ? itemRol.rol.name : itemRol.name,
            })),
          ],
        };
        FilterFileds[1] = {
          ...FilterFileds[1],
          options: [
            ...menus.data
              .filter((menu) => menu.state === State.ACTIVE)
              .map((menu) => ({
                id: menu.id,
                label: menu.name,
                value: (menu.id as any).toString(),
              })),
          ],
        };
      }
      setfilterFields(FilterFileds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRoles, flagSelect, menus]);

  useEffect(() => {
    dispatch(arraySearch(null));
    dispatch(setActiveRol(null))
    dispatch(setFlagForm({ flagFormCreate: false }));
    return () => {
      dispatch(setFlagForm({ flagFormCreate: false }));
      dispatch(setFlagSelect({ flagSelect: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Soporta la información para los selects en el componente filtro
   */

  /**
   * Soporta las Columnas para renderizar en el header de la tabla
   */

  const columns: IColumnsTable[] = [
    { id: 1, header: "ROL" },
    { id: 2, header: "ID ROL" },
    { id: 3, header: "table.state" },
    { id: 4, header: "table.actions" },
  ];
  const iconos: Icons = {
    seeMore: true,
    add: false,
  };

  const onCloseAlertMenu = async () => {
    await dispatch(setCloseAlert());
  };

  const handleOpenForm = () => {
    dispatch(setFlagForm({ flagFormCreate: true }));
    dispatch(setActiveUser(null));
  };

  return (
    <div className="content w-100 mb-5">
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Row style={{ marginBottom: 2 }}>
            <Col xs={12}>
              <MyBreadCrumbs
                titles={[t("sidebar.settings"), t("sidebar.ManagementRoles")]}
              >
                {flagFormCreate && (
                  <Typography color="text.primary" style={{ fontSize: "16px" }}>
                    {t(!activeRol ? "button.createRole" : "button.editRole")}
                  </Typography>
                )}
              </MyBreadCrumbs>
            </Col>
          </Row>

          {flagFormCreate ? (
            <FormCreateRol />
          ) : (
            <>
              <Row style={{ marginTop: 10, marginBottom: -10 }}>
                <Col xs={12}>
                  <h3 style={{ fontSize: 16, color: "#333333" }}>
                    {t("filters.filters")}
                  </h3>
                </Col>
              </Row>
              <div className="white-container">
                {roles && allRoles && filterFields.length > 0 ? (
                  <>
                    {enableCreated && (
                      <Row>
                        <Col xs={12}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            mb={2}
                          >
                            <Box display="flex" alignItems="center">
                              <p
                                className="txt-primary-md"
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "uppercase",
                                  alignSelf: "center",
                                }}
                              >
                                {t("button.createRole")}
                              </p>
                            </Box>
                            <Box>
                              <IconButton
                                onClick={handleOpenForm}
                                id="openFormBtn"
                                style={{ border: "none", outline: "none" }}
                              >
                                <span className="btn-add">
                                  <i
                                    className="fa-solid fa-plus"
                                    style={{ fontSize: 30 }}
                                  ></i>
                                </span>
                              </IconButton>
                            </Box>
                          </Box>
                        </Col>
                      </Row>
                    )}

                    <Filter
                      fields={filterFields}
                      component={"roles"}
                      setDataFilter={setDataFilter}
                    />

                    {data ? (
                      <>
                        {data.data.length > 0 && (
                          <TableComponent
                            columns={columns}
                            data={data.data}
                            pages={data.last_page}
                            icons={iconos}
                            component={"roles"}
                            totalData={data}
                            isSortingTable={true}
                            dataFilter={dataFilter}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {roles && roles.data.length > 0 && (
                          <TableComponent
                            columns={columns}
                            data={roles.data}
                            pages={roles.last_page}
                            icons={iconos}
                            component={"roles"}
                            totalData={roles}
                            isSortingTable={true}
                            dataFilter={dataFilter}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <Grid container className={"paperLoading"}>
                    <Box mx="auto" my="40vh">
                      <CircularProgress className={"colorLoading"} />
                    </Box>
                  </Grid>
                )}
              </div>
            </>
          )}
        </Col>
        <MyDialogInformation
          component={"roles"}
          title={"rolManagement.roleDetail"}
        />
        <MyAlert
          open={openAlert}
          message={messageAlert ? messageAlert : ""}
          typeAlert={typeAlert}
          handleClose={onCloseAlertMenu}
        />
      </Row>
    </div>
  );
};
