import { useField } from "formik";
import { TextField } from "@mui/material";

export const MyTextField = (props: any) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const {enablepaste,...rest} = props
  return (
    <TextField
      size="small"
      {...rest}
      {...field}
      helperText={errorText || (props.error && props.helperText)}
      error={!!errorText || props.error}
      onChange={props.onChange && props.onChange}
      autoComplete="off"
      fullWidth
      inputProps={{
        ...props.inputProps,
        autoComplete: "new-password",
        form: {
          autocomplete: "off",
        },
      }}
      variant="outlined"      
    />
  );
};
