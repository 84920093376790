import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { IPlayerActivityData } from "../../../interfaces/playerActivity";
import { ExclusionType } from "../../../enums/enums";
import { getCopyArrayOrObject } from "../../../utils/utils";
import { AppState } from "../../../redux/reducers/rootReducer";
import { headersExclusionReport } from "../../../helpers/headersTables";

export const ExclusionDataTable = (totalData: IPlayerActivityData[]) => {
    const { t } = useTranslation();
    const { data } = useSelector((state: AppState) => state.search);

    const headersCvsStyle = headersExclusionReport.map((header) => {
        return {
            title: header.label,
            width: { wpx: 120 },
            style: {
                font: { bold: true, color: { rgb: "FFFFFF" } },
                fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
                alignment: { vertical: "center", horizontal: "center" },
            },
        };
    });

    // Obtener datos por vista
    const dataExclusions =
        data && data.data ? getCopyArrayOrObject(data.data) : [];
    // Obtener total datos
    const dataTotalExclusions = totalData
        ? getCopyArrayOrObject(totalData)
        : [];
    //   DATA TOTAL
    const multiTotalDataExclusions = [
        {
            columns: headersCvsStyle,
            data: dataTotalExclusions.map((data: any) => {
                let array;
                array = [
                    {
                        value: data.id,
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: data.player?.id,
                        style: { alignment: { horizontal: "center" } },
                    },

                    {
                        value: data.player?.firstName + " " + data.player?.lastName,
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: moment(data.createAt).format("DD/MM/YYYY HH:mm:ss"),
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: data.category?.type?.id === ExclusionType.CATEGORY &&
                            !data.totalExclusion
                            ? moment(data.endDate).format("DD/MM/YYYY - hh:mm:ss A") || ""
                            : "N/A",
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: data.totalExclusion
                            ? t("exclusionsReport.totalExclusion")
                            : data.category?.name,
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: t("exclusionsReport.by") + " " + data.category?.type?.name,
                        style: { alignment: { horizontal: "center" } },
                    },
                ];
                return array;
            }),
        },
    ];
    const multiDataExclusions = [
        {
            columns: headersCvsStyle,
            data: dataExclusions.map((data: any) => {
                let array;
                array = [
                    {
                        value: data.id,
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: data.player?.id,
                        style: { alignment: { horizontal: "center" } },
                    },

                    {
                        value: data.player?.firstName + " " + data.player?.lastName,
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: moment(data.createAt).format("DD/MM/YYYY HH:mm:ss"),
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: data.category?.type?.id === ExclusionType.CATEGORY &&
                            !data.totalExclusion
                            ? moment(data.endDate).format("DD/MM/YYYY - hh:mm:ss A") || ""
                            : "N/A",
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: data.totalExclusion
                            ? t("exclusionsReport.totalExclusion")
                            : data.category?.name,
                        style: { alignment: { horizontal: "center" } },
                    },
                    {
                        value: t("exclusionsReport.by") + " " + data.category?.type?.name,
                        style: { alignment: { horizontal: "center" } },
                    },
                ];
                return array;
            }),
        },
    ];
    const finalData = {
        dataPerView: dataExclusions,
        dataTotal: dataTotalExclusions,
        headersCSV: headersExclusionReport,
        multiDataSet: multiDataExclusions,
        multiTotalDataSet: multiTotalDataExclusions,
    };
    return finalData;
};
