import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { DynamicTabs } from "../../../components/Custom/DynamicTabs";
import { arraySearch } from "../../../redux/actions/searchActions";
import { PlayerSessionReport } from "../../components/Reports/playerSession/PlayerSessionReport";
import { ExclusionReport } from "../../components/Reports/exclusion/ExclusionReport";
import { MyAlert } from "../../components/custom/MyAlert";
import { AppState } from "../../../redux/reducers/rootReducer";
import { setCloseAlert } from "../../../redux/actions/helperActions";
import { NotificationChanges } from "../../components/Reports/notificationChanges/NotificationChanges";
import { GameRevenue } from "../../components/Reports/gameRevenue/GameRevenueReport";
import { ProgressiveJackpotWon } from "../../components/Reports/ProgressiveJackpotWon/ProgressiveJackpotWon";
import { TransactionsReport } from "../../components/Reports/transactionsReport/TransactionsReport";
import { PromotionalAcountStatement } from "../../components/Reports/promotionalAcount/PromotionalAcountStatement";
import {
  DealerTypeEnum,
  Roles_Enum,
  TabsReportsEnum,
} from "../../../enums/enums";
import { CashFlow } from "../../components/Reports/cashFlow/CashFlow";
import { CashFlowDeposits } from "../../components/Reports/cashFlow/deposits/CashFlowDeposits";
import { AssociateReport } from "../../components/Reports/associate/AssociateReport";
import { AssociateBetsReport } from "../../components/Reports/bets/AssociateBetsReport";
import { AssociateAwardsReport } from "../../components/Reports/awards/AssociateAwardReport";
import { CardIrrigationPointSale } from "../../components/Reports/cardIrrigation/pointSale/cardIrrigationPointSale";
import { IrrigationResume } from "../../components/Reports/cardIrrigation/resumeIrrigation";
import { CommercialNetworksReport } from "../../components/Reports/commercialNetworks/CommercialNetworksReport";
import { AffiliationsReport } from "../../components/Reports/affiliations/AffiliationsReport";

export const Reports = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: AppState) => state.auth);
  const { pointSale } = useSelector((state: AppState) => state.wallet);
  const { openAlert, typeAlert, messageAlert } = useSelector(
    (state: AppState) => state.helper
  );

  const isRoleAssociate = user?.roles.name === Roles_Enum.ASSOCIATE;
  const isRoleDealer = user?.roles.name === Roles_Enum.DEALER;
  const isRoleSeller = user?.roles.name === Roles_Enum.SELLER;
  const isRolePromoter = user?.roles.name === Roles_Enum.PROMOTOR;

  const optionsTabs = [
    {
      id: 1,
      label: t("reports.playerSessionReport"),
    },
    {
      id: 2,
      label: t("reports.exclusionReport"),
    },
    {
      id: 3,
      label: t("reports.accountBalanceReport"),
    },
    {
      id: 4,
      label: t("reports.promotionalAccountSummaryReport"),
    },
    {
      id: 5,
      label: t("reports.notificationChange"),
    },
    {
      id: 6,
      label: t("reports.gameRevenueReport"),
    },
    {
      id: 7,
      label: t("reports.progressiveJackpotWonReport"),
    },
    {
      id: 8,
      label: t("reports.cashFlow"),
    },
    {
      id: 9,
      label: t("reports.cashFlowDeposits"),
    },
    {
      id: 10,
      label: t("reports.commissions"),
    },
    {
      id: 11,
      label: t("reports.associateBets"),
    },
    {
      id: 12,
      label: t("reports.associateAwards"),
    },
    {
      id: 13,
      label: t("reports.cardIrrigation"),
    },
    {
      id: 14,
      label: t("reports.irrigationResume"),
    },
    {
      id: 15,
      label: t("reports.commercialNetworksReport"),
    },
    {
      id: 16,
      label: t("reports.affiliations"),
    },
  ];
  const onlyCashFlowReportPermissions = [
    Roles_Enum.POINT_SALE,
    Roles_Enum.SELLER,
  ];
  const isTreasuryRole =
    Roles_Enum.BETPPRO_TREASURY === user?.roles.name.toUpperCase();
  const isCashFlowPermission =
    user &&
    onlyCashFlowReportPermissions.includes(user.roles.name as Roles_Enum);

  const [currentOption, setCurrentOption] = useState(() => {
    if (isRoleAssociate || isTreasuryRole) {
      return TabsReportsEnum.CASH_FLOW;
    } else if (isRoleDealer) {
      const dealerType = user?.dealer.type;
      if (dealerType === DealerTypeEnum.CARDS) {
        return TabsReportsEnum.IRRIGATION_RESUME;
      } else {
        return TabsReportsEnum.CASH_FLOW;
      }
    } else if (isRoleSeller) {
      const sellerType = pointSale?.pointSale.type;
      if (sellerType === DealerTypeEnum.CARDS) {
        return TabsReportsEnum.CARD_IRRIGATION_POINT_SALE;
      } else {
        return TabsReportsEnum.CASH_FLOW;
      }
    } else if (isRolePromoter) {
      return TabsReportsEnum.IRRIGATION_RESUME;
    }
    return optionsTabs[0].id;
  });

  let optionsTabsFilter;

  if (isCashFlowPermission) {
    const sellerType = pointSale?.pointSale.type;
    const commonTabIds = [
      TabsReportsEnum.CASH_FLOW,
      TabsReportsEnum.CARD_IRRIGATION_POINT_SALE,
      TabsReportsEnum.IRRIGATION_RESUME,
      TabsReportsEnum.AFFILIATIONS,
    ];
    if (sellerType === DealerTypeEnum.ANONYMOUS) {
      optionsTabsFilter = optionsTabs.filter(
        (tab) =>
          tab.id === TabsReportsEnum.CASH_FLOW ||
          tab.id === TabsReportsEnum.AFFILIATIONS
      );
    } else if (sellerType === DealerTypeEnum.CARDS) {
      optionsTabsFilter = optionsTabs.filter(
        (tab) =>
          tab.id === TabsReportsEnum.CARD_IRRIGATION_POINT_SALE ||
          tab.id === TabsReportsEnum.IRRIGATION_RESUME
      );
    } else {
      optionsTabsFilter = optionsTabs.filter((tab) =>
        commonTabIds.includes(tab.id)
      );
    }
  } else if (isRoleAssociate) {
    optionsTabsFilter = optionsTabs.filter(
      (tab) =>
        tab.id === TabsReportsEnum.COMMISSIONS ||
        tab.id === TabsReportsEnum.CASH_FLOW ||
        tab.id === TabsReportsEnum.ASSOCIATE_BETS ||
        tab.id === TabsReportsEnum.ASSOCIATE_AWARDS ||
        tab.id === TabsReportsEnum.IRRIGATION_RESUME ||
        tab.id === TabsReportsEnum.AFFILIATIONS
    );
  } else if (isRoleDealer) {
    const dealerType = user?.dealer.type;
    const commonTabIds = [
      TabsReportsEnum.COMMISSIONS,
      TabsReportsEnum.CASH_FLOW,
      TabsReportsEnum.ASSOCIATE_BETS,
      TabsReportsEnum.ASSOCIATE_AWARDS,
      TabsReportsEnum.AFFILIATIONS,
    ];
    if (dealerType === DealerTypeEnum.ANONYMOUS) {
      optionsTabsFilter = optionsTabs.filter((tab) =>
        commonTabIds.includes(tab.id)
      );
    } else if (dealerType === DealerTypeEnum.CARDS) {
      optionsTabsFilter = optionsTabs.filter(
        (tab) => tab.id === TabsReportsEnum.IRRIGATION_RESUME
      );
    } else {
      optionsTabsFilter = optionsTabs.filter(
        (tab) =>
          commonTabIds.includes(tab.id) ||
          tab.id === TabsReportsEnum.IRRIGATION_RESUME
      );
    }
  } else if (isTreasuryRole) {
    optionsTabsFilter = optionsTabs.filter(
      (tab) =>
        tab.id === TabsReportsEnum.CASH_FLOW ||
        tab.id === TabsReportsEnum.CASH_FLOW_DEPOSITS
    );
  } else if (isRolePromoter) {
    optionsTabsFilter = optionsTabs.filter(
      (tab) => tab.id === TabsReportsEnum.IRRIGATION_RESUME
    );
  } else {
    optionsTabsFilter = optionsTabs;
  }

  const route = optionsTabs.find((opt) => opt.id === currentOption)?.label;

  const handleChangeBreadCumb = () => {
    setCurrentOption(1);
  };

  const getComponentByOption = (optionId: number) => {
    switch (optionId) {
      case 1:
        return <PlayerSessionReport />;
      case 2:
        return <ExclusionReport />;
      case 3:
        return <TransactionsReport />;
      case 4:
        return <PromotionalAcountStatement />;
      case 5:
        return <NotificationChanges />;
      case 6:
        return <GameRevenue />;
      case 7:
        return <ProgressiveJackpotWon />;
      case 8:
        return <CashFlow />;
      case 9:
        return <CashFlowDeposits />;
      case 10:
        return <AssociateReport />;
      case 11:
        return <AssociateBetsReport />;
      case 12:
        return <AssociateAwardsReport />;
      case 13:
        return <CardIrrigationPointSale />;
      case 14:
        return <IrrigationResume />;
      case 15:
        return <CommercialNetworksReport />;
      case 16:
        return <AffiliationsReport />;
      default:
        return (
          <>
            <Typography color="text.primary">Not found</Typography>
          </>
        );
    }
  };

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
  };

  return (
    <div className="content w-100">
      <Grid container>
        <Grid item xs={12}>
          <MyBreadCrumbs
            titles={[t("sidebar.reports"), t("playerSessionReports.title")]}
            onChange={handleChangeBreadCumb}
          >
            <Typography color="text.primary">{route}</Typography>
          </MyBreadCrumbs>
        </Grid>
        <Grid item xs={12}>
          <DynamicTabs
            onChangeComponent={(option) => {
              dispatch(arraySearch(null));
              setCurrentOption(option);
            }}
            options={optionsTabsFilter}
            setOption={(callback) => callback(currentOption)}
          />
        </Grid>

        <Grid item xs={12} p={1}>
          {getComponentByOption(currentOption)}
        </Grid>
      </Grid>

      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />
    </div>
  );
};
