import React, { KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Grid,
  Typography,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";

import { IExpand } from "./MyAccountSettings";
import { AppState } from "../../../redux/reducers/rootReducer";
import { editUsernameOrPassword } from "../../../redux/actions/managementUsersActions";
import { filterUsers } from "../../../services/managementUsers";
import { Roles_Enum } from "../../../enums/enums";
import {
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { regUsername } from "../../../utils/utils";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { MyTextField } from "../custom/MyTextField";

interface Props {
  expand: IExpand;
  setExpand: React.Dispatch<React.SetStateAction<IExpand>>;
  setUserChanged: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ChangeUser = ({ expand, setExpand, setUserChanged }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { loading } = useSelector((state: AppState) => state.helper);
  const initialValues = {
    user: "",
    confirmUser: "",
  };

  const handleChangeUser = async (username: string) => {
    setUserChanged(false);

    if (username.toUpperCase() === user?.username.toUpperCase()) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("account.sameUsername"));
      dispatch(setTypeAlert("error"));
    } else {
      const existsUser = await validateUser(username);

      if (existsUser) {
        setUserChanged(false);
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("account.usernameRegisterError"));
        dispatch(setTypeAlert("error"));
      } else {
        dispatch(setLoadingState({ loading: true }));
        token &&
          user &&
          (await dispatch(
            editUsernameOrPassword(
              { username },
              token,
              user?.id,
              true,
              setUserChanged
            )
          ));
      }
    }
  };

  const validationSchema = yup.object({
    user: yup.string().required(t("form.requiredFile")),
    confirmUser: yup
      .string()
      .required(t("form.requiredFile"))
      .oneOf([yup.ref("user"), null], () => (
        <>
          <i className="fa-solid fa-circle-exclamation" />{" "}
          {t("user.matchingUsernames")}
        </>
      )),
  });

  const validateUser = async (username: string) => {
    const params = { username };

    let findUsername =
      token &&
      user &&
      (await filterUsers(
        token,
        null,
        Roles_Enum.SUPER_ADMIN,
        params,
        null,
        true
      ));

    const exists =
      findUsername.data && findUsername.data.length > 0 ? true : false;
    return exists;
  };

  return (
    <>
      <Grid
        item
        xs={12}
        ml={2}
        mt={1}
        className={
          expand.changeUser ? "box-account-active" : "box-account cursor"
        }
        onClick={() =>
          setExpand({
            userInfo: false,
            changeUser: !expand.changeUser,
            changePassword: false,
          })
        }
      >
        <Box display={"flex"} alignItems="center" ml={2}>
          <Typography color="text.primary" style={{ fontSize: "16px" }}>
            {t("account.changeUser")}
          </Typography>
        </Box>
        <IconButton
          size="small"
          style={{ border: "none", outline: "none" }}
          onClick={() =>
            setExpand({
              userInfo: false,
              changeUser: !expand.changeUser,
              changePassword: false,
            })
          }
        >
          {expand.changeUser ? (
            <i className="fa fa-angle-up" />
          ) : (
            <i className="fa fa-angle-down" />
          )}
        </IconButton>
      </Grid>

      {expand.changeUser && (
        <Grid container>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={true}
              enableReinitialize={true}
              onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);
                setUserChanged(false);
                await handleChangeUser(data.user);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, handleChange }) => (
                <Form>
                  <Grid
                    container
                    m={1}
                    spacing={1}
                    style={{ cursor: "default !important" }}
                  >
                    <Grid item xs={5}>
                      <label className="label-text ml-2">
                        {t("account.newUser")}*
                      </label>
                      <MyTextField
                        name="user"
                        InputLabelProps={{ shrink: false }}
                        label={" "}
                        onChange={handleChange}
                        autoComplete="off"
                        inputProps={{ maxLength: 50 }}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                          !regUsername(e.key) && e.preventDefault()
                        }
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <label className="label-text ml-2">
                        {t("account.confirmUser")}*
                      </label>
                      <MyTextField
                        name="confirmUser"
                        InputLabelProps={{ shrink: false }}
                        label={" "}
                        autoComplete="off"
                        onChange={handleChange}
                        inputProps={{ maxLength: 50 }}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                          !regUsername(e.key) && e.preventDefault()
                        }
                      />
                    </Grid>
                    <Grid item xs={2} display="flex" mt={3} pr={2}>
                      <Button
                        fullWidth
                        type="submit"
                        style={{
                          border: "none",
                          outline: "none",
                          backgroundColor: "#02D0E7",
                          height: "40px",
                          marginBottom: "2px",
                        }}
                        variant="contained"
                        disabled={isSubmitting || !!loading}
                      >
                        {isSubmitting ? (
                          <CircularProgress size={"1.6rem"} color="inherit" />
                        ) : (
                          <>{t("button.save")}</>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      )}
    </>
  );
};
