import React, { useState, KeyboardEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  InputAdornment,
  MenuItem,
} from "@mui/material";

import { useForm } from "../../../hooks/useForm";
import {
  setExclusionsRedux,
  setPlayerRedux,
  startFilteringPlayers,
  startGetExclusions,
} from "../../../redux/actions/managementPlayersActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { regEmail, regNumbers, regPassport } from "../../../utils/utils";
import { DocumentTypes, ExclusionType } from "../../../enums/enums";
import { asyncLoadingFunction } from "../../../redux/actions/helperActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

interface Props {
  setErrorDate: React.Dispatch<React.SetStateAction<boolean>>;
  setDataFilter?: any;
  createdExclusion?: boolean;
  agileCheck?: boolean;
  getExclusions?: boolean;
}

export const FilterPlayer = ({
  setErrorDate,
  setDataFilter,
  createdExclusion = false,
  agileCheck = false,
  getExclusions = true,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isFiltering, setIsFiltering] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state: AppState) => state.auth);
  const { documentTypes } = useSelector((state: AppState) => state.helper);
  const { player, exclusionCategories } = useSelector(
    (state: AppState) => state.managementPlayers
  );
  const [formValues, handleInputChange, reset] = useForm({
    player: "",
    exclusionDate: "",
    category: "",
    documentType: "",
  });

  const params: any = {
    page: 1,
  };
  if (user?.operator) {
    params.operatorId = user.operator.id;
  }

  useEffect(() => {
    if (!player) {
      reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  /**
   * Soporta el evento para limpiar los inputs cuando se filtra
   */
  const handleCleanFilters = () => {
    setErrorDate(false);
    reset();
    setIsFiltering(false);
    dispatch(setPlayerRedux(null));
    dispatch(setExclusionsRedux(null));
  };

  const handleInputChangeMain = (e: any) => {
    handleInputChange(e);
    setIsFiltering(false);
  };

  /**
   * Soporta el evento para filtrar los datos por email o id de jugador
   */
  const handleFilterData = async () => {
    setLoading(true);
    setIsFiltering(true);
    dispatch(setPlayerRedux(null));
    dispatch(setExclusionsRedux(null));

    agileCheck ? await getDataByAgileCheck() : await getDataByCategory();

    setLoading(false);
  };

  const getDataByAgileCheck = async () => {
    if (formValues.player.trim()) {
      const documentNumber = formValues.player;
      params.playerDocument = documentNumber;
      params.documentType = formValues.documentType.toString();
      const playerParams = {
        documentType: formValues.documentType.toString(),
        numberDocument: documentNumber,
        exact: "true",
      };
      await dispatch(startFilteringPlayers(playerParams));
    }
    if (formValues.exclusionDate.trim()) {
      params.exclusionDate = formValues.exclusionDate;
    }
    if (formValues.category !== "") {
      params.category = formValues.category;
    }
    params.type = ExclusionType.AGILE_CHECK;

    getExclusions &&
      (await dispatch(
        asyncLoadingFunction(
          async () =>
            await dispatch(
              startGetExclusions(params, !formValues.player.trim())
            )
        )
      ));
    setDataFilter(params);
  };

  const getDataByCategory = async () => {
    if (formValues.player.trim()) {
      if (parseInt(formValues.player)) {
        params.playerId = formValues.player;
        const playerParams = { id: formValues.player };
        await dispatch(startFilteringPlayers(playerParams));
      } else {
        params.playerEmail = formValues.player;
        const playerParams = { email: formValues.player };
        await dispatch(startFilteringPlayers(playerParams));
      }
    }

    params.type = ExclusionType.CATEGORY;
    getExclusions &&
      (await dispatch(
        asyncLoadingFunction(
          async () =>
            await dispatch(
              startGetExclusions(params, !formValues.player.trim())
            )
        )
      ));
    setDataFilter(params);
  };

  useEffect(() => {
    if (createdExclusion) {
      setErrorDate(false);
      reset();
      setIsFiltering(false);
      dispatch(setPlayerRedux(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdExclusion]);

  return (
    <Grid container spacing={2}>
      {agileCheck && (
        <Grid item xs={3}>
          <TextField
            name="documentType"
            variant="outlined"
            size="small"
            label={t("exclusions.userDocumentType")}
            fullWidth
            autoComplete="off"
            onChange={(e) => {
              handleInputChange([
                {
                  target: {
                    name: "documentType",
                    value: e.target.value,
                  },
                },
                {
                  target: {
                    name: "player",
                    value: "",
                  },
                },
              ]);
            }}
            value={formValues.documentType}
            select
          >
            {documentTypes.map((item) => (
              <MenuItem key={item.name} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
      <Grid item xs={agileCheck ? 4.5 : 6}>
        <TextField
          name="player"
          variant="outlined"
          size="small"
          label={
            agileCheck
              ? t("exclusions.userDocumentNumber")
              : t("exclusions.userCode")
          }
          fullWidth
          autoComplete="off"
          onChange={handleInputChangeMain}
          value={formValues.player}
          InputProps={{
            style: {
              padding: 0,
              margin: 0,
            },
            endAdornment: (
              <InputAdornment position="end">
                <div className="box-personal-icon">
                  <PersonSearchIcon
                    fontSize="medium"
                    style={{
                      color: "white",
                    }}
                  />
                </div>
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: agileCheck ? 15 : 100,
          }}
          onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
            if (agileCheck) {
              if (
                [DocumentTypes.PASSPORT, DocumentTypes.CE].includes(
                  formValues.documentType
                )
                  ? !regPassport(e.key)
                  : !regNumbers(e.key)
              ) {
                e.preventDefault();
              }
            } else {
              !regEmail(e.key) && e.preventDefault();
            }
          }}
        />
      </Grid>

      <Grid item xs={agileCheck ? 3 : 4.5}>
        {player && player.data.length > 0 && (
          <TextField
            name="name"
            variant="outlined"
            size="small"
            label={t("sidebar.player")}
            fullWidth
            autoComplete="off"
            disabled={true}
            // onChange={handleInputChange}
            value={
              player && player.data.length > 0
                ? player.data[0].firstName + " " + player.data[0].lastName
                : ""
            }
          />
        )}
      </Grid>

      <Grid item xs={1.5}>
        <Button
          fullWidth
          type="button"
          style={{
            border: "none",
            outline: "none",
            backgroundColor: "#007E8C",
            height: "40px",
            marginBottom: "2px",
          }}
          variant="contained"
          className="ripple"
          onClick={() =>
            isFiltering ? handleCleanFilters() : handleFilterData()
          }
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={"1.6rem"} color="inherit" />
          ) : (
            <>
              <i className="fa fa-filter btn-icon fa-md mr-2"></i>

              {isFiltering
                ? t("filters.clean").toUpperCase()
                : t("filters.filter").toUpperCase()}
            </>
          )}
        </Button>
      </Grid>

      {agileCheck && (
        <>
          <Grid item xs={3}>
            <TextField
              name="exclusionDate"
              variant="outlined"
              size="small"
              type="date"
              label={t("exclusions.exclusionDate")}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: new Date().toLocaleDateString("en-CA"),
              }}
              onChange={handleInputChangeMain}
              value={formValues.exclusionDate}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl sx={{ width: "100%" }} size="small">
              <InputLabel id="category-label">
                {t("exclusions.exclusionReason")}
              </InputLabel>
              <Select
                labelId="category-label"
                name="category"
                size="small"
                fullWidth
                onChange={handleInputChangeMain}
                value={formValues.category}
                input={
                  <OutlinedInput label={t("exclusions.exclusionReason")} />
                }
              >
                {exclusionCategories?.data
                  ?.filter((cat) => cat.type.id === ExclusionType.AGILE_CHECK)
                  .map((cat) => (
                    <MenuItem key={cat.id} value={cat.id}>
                      {cat.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      )}
    </Grid>
  );
};
