import React, { Fragment, useState, KeyboardEvent, useEffect } from "react";
import "../Casino/TableComponent.scss";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Grid, Tooltip } from "@mui/material";
import { MyTextField } from "../../components/custom/MyTextField";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  setActivePromotionPopup,
  startDeletePromotionPopup,
  startGetPromotions,
} from "../../../redux/actions/promotionsPopupsActions";
import { regLettersNumbers } from "../../../utils/utils";
import {
  asyncLoadingFunction,
  setFlagForm,
} from "../../../redux/actions/helperActions";
import { MyDialog } from "../../components/custom/MyDialog";

const PromotionsTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { promotions, activePromotion } = useSelector(
    (state: AppState) => state.promotionsPopups
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState(10);
  const [isFiltering, setIsFiltering] = useState(false);
  const [filterData, setFilterData] = useState({});
  const currentItems = promotions?.data;
  const totalPages = promotions?.last_page;
  const [openDialog, setOpenDialog] = useState(false);
  const { loading } = useSelector((state: AppState) => state.helper);
  const initialValues = {
    startDate: "",
    endDate: "",
  };

  const handleSubmit = (data: any) => {
    if (data.startDate || data.endDate) {
      setIsFiltering(true);
      setFilterData(data);
    }
    const params = {
      ...data,
      page: currentPage,
      perPage: itemsPerPage,
    };
    dispatch(startGetPromotions(params));
  };

  const handleClean = (resetForm: any) => {
    setIsFiltering(false);
    resetForm();
    dispatch(startGetPromotions());
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    const params = {
      ...filterData,
      page: 1,
      perPage: itemsPerPage,
    };
    dispatch(startGetPromotions(params));
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      const params = {
        ...filterData,
        page: currentPage - 1,
        perPage: itemsPerPage,
      };
      dispatch(startGetPromotions(params));
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    const params = {
      ...filterData,
      page: page,
      perPage: itemsPerPage,
    };
    dispatch(startGetPromotions(params));
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      const params = {
        ...filterData,
        page: currentPage + 1,
        perPage: itemsPerPage,
      };
      dispatch(startGetPromotions(params));
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    const params = {
      ...filterData,
      page: totalPages,
      perPage: itemsPerPage,
    };
    dispatch(startGetPromotions(params));
  };

  const generatePageNumbers = () => {
    let pages = [];
    if (totalPages <= 7) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else if (currentPage <= 4) {
      pages = [1, 2, 3, 4, 5, "...", totalPages];
    } else if (currentPage > totalPages - 4) {
      pages = [
        1,
        "...",
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    } else {
      pages = [
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        totalPages,
      ];
    }
    return pages;
  };

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        perPage: itemsPerPage,
      };
      await dispatch(startGetPromotions(params));
    };
    dispatch(asyncLoadingFunction(fetchData));
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = (item: any) => {
    dispatch(setActivePromotionPopup(item));
    dispatch(setFlagForm({ flagFormCreate: true }));
  };

  const showModalDialog = (item: any) => {
    setOpenDialog(true);
    dispatch(setActivePromotionPopup(item));
  };

  const handleDelete = () => {
    dispatch(startDeletePromotionPopup(activePromotion.id));
    setOpenDialog(false);
  };

  return (
    <div className="table-container">
      <div className="table-controls">
        <div className="filters">
          <Formik
            initialValues={initialValues}
            onSubmit={async (data, { resetForm }) => {
              if (!isFiltering) {
                handleSubmit(data);
              } else {
                handleClean(resetForm);
              }
            }}
          >
            {({ handleChange }) => (
              <Form>
                <Grid container spacing={2} alignItems="end">
                  <Grid item xs={12} md={5}>
                    <label className="ml-3">{t("promotions.startDate")}</label>
                    <MyTextField
                      name="startDate"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="date"
                      InputLabelProps={{ shrink: false }}
                      label={" "}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={handleChange}
                      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                        !regLettersNumbers(e.key) && e.preventDefault()
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <label className="ml-3">{t("promotions.deadline")}</label>
                    <MyTextField
                      name="endDate"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="date"
                      InputLabelProps={{ shrink: false }}
                      label={" "}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={handleChange}
                      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                        !regLettersNumbers(e.key) && e.preventDefault()
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <button
                      className="d-flex btn-light-dark"
                      type="submit"
                      style={{ width: "100%" }}
                    >
                      <div className="p-0 text-right">
                        <i className="fa fa-filter btn-icon pt-1 fa-md"></i>
                      </div>
                      <div className="p-0 text-left">
                        <label className="btn-label pl-1">
                          {isFiltering
                            ? t("filters.clean")
                            : t("filters.filter")}
                        </label>
                      </div>
                    </button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {promotions?.total === 0 ? (
        <div>No se encontraron resultados</div>
      ) : (
        <Fragment>
          <table className="table">
            <thead>
              <tr>
                <th>Título</th>
                <th>Fecha de inicio</th>
                <th>Fecha de fin</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody className="tbody-table">
              {currentItems?.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>
                      {item.title ? (
                        item.title
                      ) : (
                        <a
                          href={item?.image.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Ver imagen
                        </a>
                      )}
                    </td>
                    <td>{item.startDate}</td>
                    <td>{item.endDate}</td>
                    <td>
                      <div className="actions">
                        <Tooltip title="Editar" arrow>
                          <div onClick={() => handleEdit(item)}>
                            <i className="fa fa-pencil-square-o fa-lg mr-2 iconEdit" />
                          </div>
                        </Tooltip>
                        <Tooltip title="Eliminar" arrow>
                          <div onClick={() => showModalDialog(item)}>
                            <i className="fa fa-trash fa-lg iconDelete" />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination">
            <button
              className="navigation"
              onClick={handleFirstPage}
              disabled={currentPage === 1}
            >
              {"<<"}
            </button>
            <button
              className="navigation"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              {"<"}
            </button>
            {generatePageNumbers().map((page, index) =>
              page === "..." ? (
                <span key={index} className="ellipsis">
                  ...
                </span>
              ) : (
                <button
                  key={index}
                  className={`page-number ${
                    page === currentPage ? "active" : ""
                  }`}
                  onClick={() => handlePageChange(page as number)}
                >
                  {page}
                </button>
              )
            )}
            <button
              className="navigation"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              {">"}
            </button>
            <button
              className="navigation"
              onClick={handleLastPage}
              disabled={currentPage === totalPages}
            >
              {">>"}
            </button>
          </div>
        </Fragment>
      )}
      <MyDialog
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message="¿Está seguro de eliminar la promoción?"
        handleAccept={handleDelete}
        handleClose={() => setOpenDialog(false)}
        isLoading={!!loading}
      />
    </div>
  );
};

export default PromotionsTable;
