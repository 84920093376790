import moment from "moment";
import { Dispatch } from "redux";
import { createFreeRounds } from "../services/pragmatic";
import {
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../redux/actions/helperActions";
import { IBonusForm, ParamsCreatePragmaticBonus } from "../interfaces/bonus";

export const handlePragmaticBonusCreation = async (
  finalBonus: IBonusForm,
  token: string,
  bonusPragmaticCode: string,
  dispatch: Dispatch
) => {
  const parseStartDate = moment(finalBonus.startDate).startOf("day").unix();
  const parseEndDate = moment(finalBonus.endDate)
    .endOf("day")
    .add(finalBonus.expirationDays + 1, "days")
    .unix();

  const gameList = finalBonus.pragmaticBonus;
  const parseGameList = gameList.map((item: any) => {
    let betValues = [...item.betValues];
    betValues[0].totalBet = parseFloat(betValues[0].totalBet);

    return {
      ...item,
      betValues,
    };
  });

  const bonusData: ParamsCreatePragmaticBonus = {
    bonusCode: bonusPragmaticCode,
    expirationDate: parseEndDate,
    startDate: parseStartDate,
  };

  if (finalBonus.pragmaticNumberRounds) {
    bonusData.rounds = finalBonus.pragmaticNumberRounds;
  }

  try {
    const rsPragmatic = await createFreeRounds(token, bonusData, {
      gameList: parseGameList,
    });

    return rsPragmatic;
  } catch (error) {
    dispatch(setTypeAlert("error"));
    dispatch(
      setMessageAlert("An error occurred while creating Pragmatic bonus")
    );
    dispatch(setOpenAlert());
    dispatch(setLoadingState({ loading: false }));
  }
};
