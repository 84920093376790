import { ICurrenciesOperatorQuery } from "../interfaces/curreniesOperator";

export const getCurrenciesOperator = (
  token: string,
  params: Partial<ICurrenciesOperatorQuery>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}currenciesOperator?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
