import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Box, Grid } from "@mui/material";
import {
  ICountryStep,
} from "../../../../interfaces/operationCompany";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { ICountryPayment } from "../../../../interfaces/params";
import { State } from "../../../../enums/enums";
import { getParamPaymentById } from "../../../../services/managementParametersService";

interface Props {
  countriesSelected: ICountryStep[];
  operator: any;
  readOnly?: boolean;
}

export const TabPaymentMethodsConfirmation: FC<Props> = ({
  countriesSelected,
  operator,
}) => {
  const { token } = useSelector((state: AppState) => state.auth);
  const [countryPaymentMethods, setCountryPaymentMethods] = useState<any[]>([]);
  const { activeBrand } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const { t } = useTranslation();

  const getParam = async (country: number): Promise<ICountryPayment[]> => {
    if (!token) {
      return [];
    }
    const params = {
      active: State.ACTIVE,
    };
    const response = await getParamPaymentById(
      token,
      country,
      operator.id,
      !activeBrand && params
    );
    return response.data;
  };

  useEffect(() => {
    const getPaymentMethods = async () => {
      const finalData: any[] = [];
      for (const country of countriesSelected) {
        const response = await getParam(parseInt(country.id));

        const objCountryPayment = {
          id: country.id,
          methods: response,
        };
        finalData.push(objCountryPayment);
      }
      finalData.forEach((data, index) => {
        data.methods = data.methods.filter((method: any) =>
          activeBrand
            ? method.state || method.methods.type === "withdrawal"
            : (method.active === State.ACTIVE && method.state) ||
              method.methods.type === "withdrawal"
        );
      });

      setCountryPaymentMethods(finalData);
      // dispatch(setPaymentMethodsRedux(finalData));
    };
    getPaymentMethods();
    return () => {
      setCountryPaymentMethods([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operator.id]);

  return (
    <Grid container>
      {/* CONFIRMACIÓN MÉTODOS DE PAGO DEPÓSITOS */}
      <>
        <Box
          display="flex"
          width={1}
          className="pb-1"
          borderBottom={1}
          borderColor="#02D0E7"
          mb={3}
        >
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <p className="txt-primary-md" style={{ fontWeight: 500 }}>
            {t("companyManagement.paymentMethodsDeposits")}
          </p>
        </Box>

        {countriesSelected.map((country) => (
          <Grid container mb={2} ml={4} key={country.id}>
            <Grid item xs={3}>
              {(country.name as string).toUpperCase()}
            </Grid>
            <Grid item xs={9}>
              {countryPaymentMethods
                .find((method) => method.id === country.id)
                ?.methods.filter(
                  (method: any) => method.methods.type !== "withdrawal"
                ).length <= 0 ? (
                <label className="label-text mt-2">
                  {t("parameterization.noParamPaymentMethods")}
                </label>
              ) : (
                <>
                  {country.paymentMethodsRecharges
                    ?.filter((el: any) => el.state)
                    ?.map((payment, index: number) => (
                      <label key={payment.id + "-" + index}>
                        {payment.name.toUpperCase()} &nbsp;
                        {country.paymentMethodsRecharges &&
                          country.paymentMethodsRecharges?.filter(
                            (el: any) => el.state
                          ).length -
                            1 !==
                            index &&
                          "-"}
                        &nbsp;
                      </label>
                    ))}
                </>
              )}
            </Grid>
          </Grid>
        ))}
      </>

      {/* CONFIRMACIÓN MÉTODOS DE PAGO RETIROS */}
      <>
        <Box
          display="flex"
          width={1}
          className="pb-1"
          borderBottom={1}
          borderColor="#02D0E7"
          mb={3}
        >
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <p className="txt-primary-md" style={{ fontWeight: 500 }}>
            {t("companyManagement.paymentMethodsWithdrawals")}
          </p>
        </Box>

        {countriesSelected.map((country, indexCountry) => (
          <Grid container mb={2} ml={4} key={country.id + "-" + indexCountry}>
            <Grid item xs={3}>
              {(country.name as string).toUpperCase()}
            </Grid>
            <Grid item xs={9}>
              {country.paymentMethodsWithdrawal
                ?.filter((el: any) => el.state)
                .map((payment, index: number) => (
                  <label key={payment.id}>
                    {payment.name.toUpperCase()} &nbsp;
                    {country.paymentMethodsWithdrawal &&
                      country.paymentMethodsWithdrawal?.filter(
                        (el: any) => el.state
                      ).length -
                        1 !==
                        index &&
                      "-"}
                    &nbsp;
                  </label>
                ))}
            </Grid>
          </Grid>
        ))}
      </>
    </Grid>
  );
};
