import {
  ILotsDB,
  ILotsList,
  ICardsDB,
  ICardDealer,
  ITotalWallet,
  IrrigationData,
  IrrigationEntry,
  IIrrigatedCardsResult,
} from "../../interfaces/cards";
import { IPaginationData } from "../../interfaces/shared";
import { Types } from "../types/types";

export interface ILotsCardsState {
  lots: ILotsDB | null;
  activeLot: number | null | ILotsList | IrrigationEntry;
  openDetails: boolean;
  cards: ICardsDB | null;
  cardDealers: IPaginationData<ICardDealer> | null;
  activeCardDealer: ICardDealer | null;
  irrigationCardsInventory: IrrigationData | null;
  irrigationCardsHistory: IrrigationData | null;
  totalWallet: ITotalWallet | null;
  irrigatedDetail: IIrrigatedCardsResult | null;
}

export interface ILotsCardsActions {
  type: Types;
  payload?: ILotsList | ILotsDB | number | ICardsDB | null;
}

const initialState: ILotsCardsState = {
  lots: null,
  activeLot: null,
  openDetails: false,
  cards: null,
  cardDealers: null,
  activeCardDealer: null,
  irrigationCardsInventory: null,
  irrigationCardsHistory: null,
  totalWallet: null,
  irrigatedDetail: null,
};

export const managementCardsReducer = (
  state = initialState,
  action: ILotsCardsActions
): ILotsCardsState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setLots:
      return {
        ...state,
        lots: payload as ILotsDB,
      };
    case Types.setActiveLot:
      return {
        ...state,
        activeLot: payload as number,
      };
    case Types.setCards:
      return {
        ...state,
        cards: payload as ICardsDB,
      };
    case Types.setCardDealers:
      return {
        ...state,
        cardDealers: payload as any,
      };
    case Types.setActiveCardDealer:
      return {
        ...state,
        activeCardDealer: payload as any,
      };
    case Types.setIrrigationCardsInventory:
      return {
        ...state,
        irrigationCardsInventory: payload as any,
      };
    case Types.setIrrigationCardsHistory:
      return {
        ...state,
        irrigationCardsHistory: payload as any,
      };
    case Types.setTotalWallet:
      return {
        ...state,
        totalWallet: payload as any,
      };
    case Types.setIrrigatedCards:
      return {
        ...state,
        irrigatedDetail: payload as any,
      };
    default:
      return state;
  }
};
