import { FC } from "react";
import TableCell from "@mui/material/TableCell";
interface Props {
  row: any;
}

export const RowDataTableGameRevenue: FC<Props> = ({ row }) => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return (
    <>
      <TableCell align="center" className="word-break">{currencyFormatter.format(row.totalAmountDeposits)}</TableCell>
      <TableCell align="center" className="word-break">{currencyFormatter.format(row.totalWithdrawals)}</TableCell>
      <TableCell align="center" className="word-break">{currencyFormatter.format(row.totalFundsAvailable)}</TableCell>
    </>
  );
};
