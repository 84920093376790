import { getOperatorByIdService } from "../services/managementCompanyService";

interface OperatorData {
  currency: string;
  country: string;
}

export const fetchOperatorDetails = async (
  token: string,
  operatorId: number
): Promise<OperatorData> => {
  try {
    const operator = await getOperatorByIdService(token, operatorId);

    const currency = operator.currencyOp[0]?.currency.code || "USD";
    const country = operator.country?.code || "US";

    return { currency, country };
  } catch (error) {
    console.error("Error fetching operator details:", error);
    return { currency: "USD", country: "US" }; // Valores predeterminados en caso de error
  }
};
