import moment from "moment";
import { FC } from "react";
import TableCell from "@mui/material/TableCell";
import { IRefills } from "../../../../interfaces/refills";
import { useTranslation } from "react-i18next";
import { buildRedName } from "../../../../utils/utils";
interface Props {
  row: IRefills;
}

export const RowDataTableRefills: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  const method = row.meta?.debitParty?.typeClass ? `- ${t(`paymentMethods.${row.meta?.debitParty?.typeClass}`)}` :'';
  
  return (
    <>
      <TableCell className="word-break">{row.id}</TableCell>
      <TableCell className="word-break">{row.meta?.transactionReference || ""}</TableCell>
      <TableCell className="word-break">{row.player.email}</TableCell>
      <TableCell className="word-break">${row.amount}</TableCell>
      <TableCell className="word-break">{row.transactionType.name + ' ' + method}</TableCell>
      <TableCell className="word-break">{buildRedName(row)}</TableCell>
      <TableCell className="word-break">{row.transactionStatus.name}</TableCell>
      <TableCell className="word-break">
        {moment(row.createAt).format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
      <TableCell />
    </>
  );
};
