import { IWalletPointSale } from "../../interfaces/pointsSale";
import { IAction } from "../interfaces/shared";
import { Types } from "../types/types";

interface State {
  pointSale: IWalletPointSale | null;
  associate: { betsBalance: string; refillsBalance: string } | null;
  dealer: {
    id: number;
    betsBalance: string;
    refillsBalance: string;
    comissionDeposit: string;
    comissionWithdrawals: string;
    comissionAwardsPayments: string;
    comissionAnonymousBets: string;
    commissionPercentageAffiliations: string;
  } | null;
}
const initialState: State = {
  pointSale: null,
  associate: null,
  dealer: null,
};
export const walletReducer = (state = initialState, action: IAction): State => {
  const { type, payload } = action;
  switch (type) {
    case Types.setWalletPointSale:
      return {
        ...state,
        pointSale: payload,
      };
    case Types.setWalletAssociate:
      return {
        ...state,
        associate: payload,
      };
    case Types.setWalletDealer:
      return {
        ...state,
        dealer: payload,
      };

    default:
      return state;
  }
};
