import {
  IIpBlacklist,
  IObjectInactivePlayers,
} from "../interfaces/ipBlacklist";

export const addIpBlacklist = (data: IIpBlacklist, token: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}blackListIp/create`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const getIpBlacklist = (token: string, params: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}blackListIp/getAll?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const deleteIpBlacklist = (
  id: number,
  token: string,
  createById: number
) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify({ createById }),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}blackListIp/delete/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const updateIpBlacklist = (
  data: IIpBlacklist,
  token: string,
  id: number
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}blackListIp/update/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const inactivePlayersIp = (
  data: IObjectInactivePlayers,
  token: string
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}blackListIp/updatePlayers`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
