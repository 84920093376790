import React, { FC,useEffect } from 'react'
import PerfectScrollbar from 'perfect-scrollbar';
let ps:any

export const DialogLayout:FC = ({children}) => {
    
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      //@ts-ignore
      ps = new PerfectScrollbar("#dialogLayout", {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  return (
    <div id={"dialogLayout"} className={"pb-2"}>{children}</div>
  )
}
