import moment from "moment";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { IColumnsTable } from "../../../../../interfaces/components";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import { betStatuses } from "../../../../../helpers/betStatus";
import { State_Bets } from "../../../../../enums/enums";
import { useStyles } from "../../../custom/customStyles";
import { RowDataTableEvents } from "./RowDataTableEvents";
// @ts-ignore
import classnames from "classnames";

export const BetDetail = () => {
  const { t } = useTranslation();
  const { activeBet: data } = useSelector((state: AppState) => state.bets);
  const [expand, setExpand] = useState(false);
  const [valuesBet, setValuesBet] = useState<any[]>([]);
  const classes = useStyles();

  /**
   * Soporta las Columnas para renderizar en el header de la tabla
   */
  const columns: IColumnsTable[] = [
    { id: 1, header: "table.betId" },
    { id: 2, header: "table.playerId" },
    { id: 3, header: "user.createAt" },
    { id: 4, header: "bets.betType" },
    { id: 5, header: "bets.event" },
    { id: 6, header: "bets.betAmount" },
    { id: 7, header: "bets.feeds" },
    { id: 8, header: "bets.bonus" },
    // { id: 8, header: "bets.cashout" },
    { id: 9, header: "table.state" },
  ];

  useEffect(() => {
    if (data) {
      let newFees: any;
      data.events.forEach((item: any) => {
        newFees =
          data.eventCount > 1
            ? data.betStakes[0].odds
            : item.markets[0]
            ? item.markets[0].odds * 1
            : 1;
      });
      const paid = data.paid ? `- ${t("betState.paid").toUpperCase()}` : "";
      setValuesBet([
        {
          title: columns[0].header,
          value: data.id,
        },
        {
          title: columns[1].header,
          value: data.player.id,
        },
        {
          title: columns[2].header,
          value: moment(data.createAt).format("D MMM HH:mm"),
        },
        {
          title: columns[3].header,
          value: data.isSystem
            ? `${t("betType.system")} (${data.eventCount})`
            : data.eventCount > 1
            ? `${t("betType.multiple")} (${data.eventCount})`
            : t("betType.simple"),
        },
        {
          title: columns[4].header,
          value: `${t("bets.eventsNumber")} (${data.eventCount})`,
        },
        {
          title: columns[5].header,
          value:
            "$ " +
            ` ${
              data.status === "S" &&
              data.transactions &&
              data.transactions.length > 0
                ? (parseFloat(data.transactions[0].amount) / newFees).toFixed(2)
                : data.bonusAccountId
                ? (data.betStakes[0].betstake / 100).toFixed(2)
                : data.amount
            }`,
        },
        {
          title: columns[6].header,
          value: newFees,
        },
        {
          title: columns[7].header,
          value: data.bonusAccountId ? t("table.yes") : t("table.not"),
        },
        // {
        //   title: columns[7].header,
        //   value: "No",
        // },
        {
          title: columns[8].header,
          value: `${betStatuses
            .find((status) => status.value === data.status)
            ?.description.toUpperCase()} ${paid}`,
          statusName: data.statusName,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <TableContainer component={Paper} sx={{ padding: 2 }}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton
            size="small"
            style={{ border: "none", outline: "none", marginTop: "15px" }}
            onClick={() => setExpand((prev) => !prev)}
          >
            {expand ? (
              <i className="fa fa-angle-up" />
            ) : (
              <i className="fa fa-angle-down" />
            )}
          </IconButton>
        </Grid>
        <Grid item xs={11.5}>
          <Table>
            <TableHead>
              <TableRow>
                {valuesBet &&
                  valuesBet.map((el, index) => (
                    <TableCell key={index} className={classes.tableCellPadding}>
                      <div
                        className={classes.cellTextPrimary}
                        style={{
                          color:
                            el.title === "table.state"
                              ? el.statusName === State_Bets.LOST
                                ? "red"
                                : el.statusName === State_Bets.WON ||
                                  el.statusName === State_Bets.OPEN
                                ? "#007E8C"
                                : "#BDBDBD"
                              : "#BDBDBD",
                        }}
                      >
                        {t(el.title).toUpperCase()}
                      </div>
                      <div
                        className={classnames(
                          classes.cellTextSecondary,
                          el.title === "user.createAt" && classes.capitalize
                        )}
                        style={{
                          color:
                            el.title === "table.state"
                              ? el.statusName === State_Bets.LOST
                                ? "red"
                                : el.statusName === State_Bets.WON ||
                                  el.statusName === State_Bets.OPEN
                                ? "#007E8C"
                                : "#BDBDBD"
                              : "#BDBDBD",
                        }}
                      >
                        {el.value}
                      </div>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>

            {expand && (
              <TableBody>
                {data &&
                  data.events.map((row, index) => (
                    <RowDataTableEvents row={row} bet={data} key={index} />
                  ))}
              </TableBody>
            )}
          </Table>
        </Grid>
      </Grid>
    </TableContainer>
  );
};
