import { FC } from "react";
import { Route, Redirect } from "react-router-dom";

interface PublicRouteProps {
  component: FC;
  isAuthenticated: boolean;
  path: string;
  exact?: boolean;
}

export const PublicRoute = (props: PublicRouteProps) => {
  const { component: Component, isAuthenticated, ...rest } = props;

  return (
    <Route
      {...rest}
      component={(routeProps: any) =>
        isAuthenticated ? <Redirect from="/" exact to="/admin" /> : <Component {...routeProps} />
      }
    />
  );
};
