import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { headersGamingActivityGoldenRace } from "../../../helpers/headersTables";
import { getCopyArrayOrObject } from "../../../utils/utils";
import moment from "moment";
import { IGoldenRaceCasino } from "../../../interfaces/casino";
import { useTranslation } from "react-i18next";

export const GamingActivityGoldenRaceDataTable = (totalData: IGoldenRaceCasino[]) => {
  const { t } = useTranslation();
  const { data } = useSelector((state: AppState) => state.search);

  const headersCvsStyle = headersGamingActivityGoldenRace.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });
  // Obtener datos por vista
  const dataGamingActivityGoldenRace =
    data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalaGamingActivityGoldenRace = totalData
    ? getCopyArrayOrObject(totalData)
    : [];

  const buildTotalData = (arr: IGoldenRaceCasino[]) => {
    const multiData = [
      {
        columns: headersCvsStyle,
        data: arr.map((data: IGoldenRaceCasino) => {
          const status: { [key: string]: string } = {
            won: t("betResults.won"),
            lost: t("betResults.loss"),
            canceled: t("betResults.canceled"),
            pending: t("betResults.pending"),
        };
          const array = [
            {
              value: moment(data.createAt).format("YYYY-MM-DD HH:mm:ss"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.id,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.amount
                ? "$ " + parseFloat(data.amount.toString()).toFixed(2)
                : "0.00",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.amountWon
                ? "$ " + parseFloat(data.amountWon.toString()).toFixed(2)
                : "0.00",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.gameName,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: status[`${data.betStatus}`].toUpperCase(),
              style: { alignment: { horizontal: "center" } },
            },
          ];
          return array;
        }),
      },
    ];
    return multiData;
  };

  const multiTotalData = buildTotalData(dataTotalaGamingActivityGoldenRace);
  const multiData = buildTotalData(dataGamingActivityGoldenRace);
  const finalData = {
    dataPerView: dataGamingActivityGoldenRace,
    dataTotal: dataTotalaGamingActivityGoldenRace,
    headersCSV: headersGamingActivityGoldenRace,
    multiDataSet: multiData,
    multiTotalDataSet: multiTotalData,
  };
  return finalData;
};
