import { gameRevenueReport } from "../../services/reports";
import { AppState } from "../reducers/rootReducer";
import { setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";
import { arraySearch } from "./searchActions";

export const startGetGameRevenueReport = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    try {
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      if (user?.operator?.id) {
        params.operatorId = user.operator.id;
      }

      let finalValues: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (value !== undefined && value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });
      const response = await gameRevenueReport(token, params);

      if (response && !response.error) {
        if (response.data?.length === 0) {
          dispatch(arraySearch(null));
          dispatch(setMessageAlert("alerts.noData"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        } else {
          dispatch(arraySearch(response));
        }
      } else {
        dispatch(setMessageAlert("alerts.errorGameRevenueReport"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.errorGameRevenueReport"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};