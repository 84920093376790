import AttachFileIcon from "@mui/icons-material/AttachFile";

// Quill Toolbar component
export const formats = [
  "header",
  "font",
  "align",
  "bold",
  "italic",
  "underline",
  "list",
  "image",
  "color",
  "link",
];

export const CustomToolbar = () => {
  return (
    <div id="toolbar">
      <span className="ql-formats">
        <select className="ql-font" defaultValue="arial">
          <option value="arial">Arial</option>
          <option value="comic-sans">Comic Sans</option>
          <option value="Roboto">Roboto</option>
          <option value="Raleway">Raleway</option>
          <option value="Montserrat">Montserrat</option>
          <option value="Lato">Lato</option>
          <option value="Rubik">Rubik</option>
        </select>
        <select
          className="ql-header"
          defaultValue={""}
          onChange={(e) => e.persist()}
        >
          <option value="1" />
          <option value="2" />
          <option value="3" />
          <option value="4" />
          <option value="5" />
          <option value="" />
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
      </span>
      <span className="ql-formats">
        <select className="ql-align" />
        <select className="ql-color" />
      </span>
      <span className="ql-formats">
        <button className="ql-undo">
          <AttachFileIcon />
        </button>
      </span>
    </div>
  );
};
