import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import TableCell from "@mui/material/TableCell";

import { State } from "../../../../enums/enums";
import { IAssociatesData } from "../../../../interfaces/associate";
import userIcon from "../../../../assets/svg/new-associate.svg";

import {
  setFlagFormAssociate,
  setComponent,
} from "../../../../redux/actions/helperActions";
import { setActiveAssociate } from "../../../../redux/actions/associatesActions";
import Tooltip from "@mui/material/Tooltip/Tooltip";

interface Props {
  row: IAssociatesData;
}

export const RowDataTableAssociates: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCreateAssociate = async () => {
    dispatch(setComponent("associates"));
    dispatch(setActiveAssociate(row));
    dispatch(setFlagFormAssociate({ flagFormCreateAssociate: true }));
    history.replace("/admin/settings/management-users");
  };

  return (
    <>
      <TableCell align="left" className="word-break">
        {row.refillsBalance}
      </TableCell>
      <TableCell align="left" className="word-break">
        {row.name}
      </TableCell>
      <TableCell align="left" className="word-break">
        {row.address}
      </TableCell>
      <TableCell align="left" className="word-break">
        {row.phone}
      </TableCell>
      <TableCell align="left">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                State.ACTIVE === row.stateUser
                  ? "d-flex cellRowActive"
                  : "d-flex cellRowInactive"
              }
            >
              <div className="d-flex col-sm-4 justify-content-center">
                {State.ACTIVE === row.stateUser && <>{t("state.active")}</>}
                {State.INACTIVE === row.stateUser && <>{t("state.inactive")}</>}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell align="left" className="word-break">
        {row.createdUser.nameComplete}
      </TableCell>
      <TableCell align="left" className="word-break">
        <Tooltip title={t("button.createUser") as string} arrow>
          <img
            width={"19px"}
            style={{
              cursor: row.stateUser === State.ACTIVE ? "pointer" : "",
              opacity: row.stateUser === State.ACTIVE ? "" : "0.3",
            }}
            src={userIcon}
            alt="File text"
            onClick={() =>
              row.stateUser === State.ACTIVE && handleCreateAssociate()
            }
          />
        </Tooltip>
      </TableCell>
    </>
  );
};
