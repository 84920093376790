export const getSegmentations = (token: string, params: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const queryString = params ? new URLSearchParams(params).toString() : "";
  return fetch(
    `${process.env.REACT_APP_API_HTTP_SMS}segmentation?${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const sendMessageWithSegmentation = (token: string, body: object) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(
    `${process.env.REACT_APP_API_HTTP_SMS}segmentation/send`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
