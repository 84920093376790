import { IAdsDb, IPaginationAds } from "../../interfaces/ads";
import { Types } from "../types/types";

interface IAdsState {
  ads: IPaginationAds | null;
  activeAd: IAdsDb | null;
}

interface IAdsActions {
  type: Types;
  payload?: IPaginationAds | IAdsDb[] | IAdsDb | string | null | number;
}
const initialState: IAdsState = {
  ads: null,
  activeAd: null,
};

export const managementAdsReducer = (
  state = initialState,
  action: IAdsActions
): IAdsState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setAds:
      return {
        ...state,
        ads: payload as any,
      };
    case Types.setActiveAd:
      return {
        ...state,
        activeAd: payload as any,
      };
    case Types.deleteAd:
      const copyAds = { ...state.ads };
      copyAds.data = copyAds.data
        ? copyAds.data.filter((ad) => ad.id !== payload)
        : [];

      return {
        ...state,
        ads: (copyAds as any),
      };

    default:
      return state;
  }
};
