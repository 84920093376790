import { IColumns } from "../components/Custom/Table/MyCustomTable";
import i18next from "../i18n";

export const bankColumns: IColumns[] = [
  {
    align: "left",
    label: i18next.t("withdrawals.withdrawalNumber"),
  },
  {
    align: "left",
    label: `${i18next.t("user.documentNumber")}`,
  },
  {
    align: "left",
    label: i18next.t("filters.email"),
  },
  {
    align: "left",
    label: `% ${i18next.t("withdrawals.withholding")}`,
  },
  {
    align: "left",
    label: i18next.t("withdrawals.withholdingValue"),
  },
  {
    align: "left",
    label: i18next.t("withdrawals.requestedValue"),
  },
  {
    align: "left",
    label: i18next.t("deposits.valuePaid"),
  },
  {
    align: "left",
    label: i18next.t("withdrawals.requestDate"),
  },
];
export const bankApprovedColumns: IColumns[] = [
  {
    align: "left",
    label: i18next.t("withdrawals.withdrawalNumber"),
  },
  {
    align: "left",
    label: `${i18next.t("user.documentNumber")}`,
  },
  {
    align: "left",
    label: i18next.t("filters.email"),
  },
  {
    align: "left",
    label: i18next.t("withdrawals.approvedDate"),
  },
  {
    align: "left",
    label: i18next.t("withdrawals.requestedValue"),
  },
  {
    align: "left",
    label: i18next.t("withdrawals.valuePaid"),
  },
];

export const bankPendingColumns: IColumns[] = bankColumns.concat({
  align: "center",
  label: i18next.t("table.actions"),
});

export const bankRejectColumns: IColumns[] = [
  {
    align: "left",
    label: i18next.t("withdrawals.withdrawalNumber"),
  },
  {
    align: "left",
    label: `${i18next.t("user.documentNumber")}`,
  },
  {
    align: "left",
    label: i18next.t("withdrawals.requestedValue"),
  },
  {
    align: "left",
    label: i18next.t("deposits.valuePaid"),
  },
  {
    align: "left",
    label: `${i18next.t("withdrawals.rejectDate")}`,
  },
  {
    align: "left",
    label: `${i18next.t("withdrawals.reason")}`,
  },
];

export const cashColumns: IColumns[] = [
  {
    align: "left",
    label: i18next.t("withdrawals.withdrawalNumber"),
  },
  {
    align: "left",
    label: `${i18next.t("user.documentNumber")}`,
  },
  {
    align: "left",
    label: i18next.t("filters.email"),
  },
  {
    align: "left",
    label: `% ${i18next.t("withdrawals.withholding")}`,
  },
  {
    align: "left",
    label: i18next.t("withdrawals.withholdingValue"),
  },
  {
    align: "left",
    label: i18next.t("withdrawals.requestedValue"),
  },
  {
    align: "left",
    label: i18next.t("deposits.valuePaid"),
  },
  {
    align: "left",
    label: i18next.t("withdrawals.requestDate"),
  },
];

export const cashRejectColumns: IColumns[] = cashColumns.concat({
  align: "left",
  label: i18next.t("withdrawals.reason"),
});
export const cashApproveColumns: IColumns[] = [
  {
    align: "left",
    label: i18next.t("withdrawals.withdrawalNumber"),
  },
  {
    align: "left",
    label: `${i18next.t("user.documentNumber")}`,
  },
  {
    align: "left",
    label: i18next.t("filters.email"),
  },
  {
    align: "left",
    label: i18next.t("withdrawals.approvedDate"),
  },
  {
    align: "left",
    label: i18next.t("withdrawals.requestedValue"),
  },
  {
    align: "left",
    label: i18next.t("withdrawals.valuePaid"),
  },
];
export const cashExpiredColumns: IColumns[] = [
  {
    align: "left",
    label: i18next.t("withdrawals.withdrawalNumber"),
  },
  
  {
    align: "left",
    label: i18next.t("filters.email"),
  },
  {
    align: "left",
    label: i18next.t("table.expirationDate"),
  },
  {
    align: "left",
    label: i18next.t("withdrawals.requestedValue"),
  },
  {
    align: "left",
    label: i18next.t("deposits.valuePaid"),
  },
];
