import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { headersIpAddresses } from "../../../helpers/headersTables";
import { getCopyArrayOrObject } from "../../../utils/utils";
import moment from "moment";

export const ManagementIpAddressesTable = (totalData: any[]) => {
  const { IpBlacklist: data } = useSelector(
    (state: AppState) => state.ipAddresses
  );

  const headersCvsStyle = headersIpAddresses.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });
  const dataIpAddresses =
    data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalIpAddresses = totalData ? getCopyArrayOrObject(totalData) : [];

  const buildTotalData = (arr: any[]) => {
    const multiData = [
      {
        columns: headersCvsStyle,
        data: arr.map((data) => {
          const array = [
            {
              value: data.id,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.ipAddress,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: moment(data.createdAt).format("YYYY-MM-DD hh:mm:ss A"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.reason ?? "",
              style: { alignment: { horizontal: "center" } },
            },
          ];
          return array;
        }),
      },
    ];
    return multiData;
  };

  const multiTotalData = buildTotalData(dataTotalIpAddresses);
  const multiData = buildTotalData(dataIpAddresses);
  const finalData = {
    dataPerView: dataIpAddresses,
    dataTotal: dataTotalIpAddresses,
    headersCSV: headersCvsStyle,
    multiDataSet: multiData,
    multiTotalDataSet: multiTotalData,
  };
  return finalData;
};
