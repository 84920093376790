import { Types } from "../types/types";
import { ILegalParameters, ILegalDb } from "../../interfaces/legal";

interface ILegalActions {
  type: Types;
  payload?: ILegalParameters | ILegalDb;
}

interface ILegalState {
  legalParameters: ILegalParameters | null;
  totalLegal: ILegalParameters | null;
  activeLegal: ILegalDb | null;
}

const initialState: ILegalState = {
  legalParameters: null,
  totalLegal: null,
  activeLegal: null,
};

export const legalReducer = (
  state = initialState,
  action: ILegalActions
): ILegalState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setLegal:
      return {
        ...state,
        legalParameters: payload as ILegalParameters,
      };

    case Types.setActiveLegal:
      return {
        ...state,
        activeLegal: payload as ILegalDb,
      };

    case Types.setTotalLegal:
      return {
        ...state,
        totalLegal: payload as ILegalParameters,
      };

    default:
      return state;
  }
};
