import React, { useState, KeyboardEvent, useEffect } from "react";
import { Box, Button, CircularProgress, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { regNumbers } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
  startUpdateLoginAttempts,
} from "../../../redux/actions/helperActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { uiSetOpenDialog } from "../../../redux/actions/uiAction";
import { getOperatorByIdService } from "../../../services/managementCompanyService";

export const FormConfigLogin = () => {
  const { user, token } = useSelector((state: AppState) => state.auth);
  const [attempts, setAttempts] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOperatorDetails = async () => {
      if (token && user) {
        const rs = await getOperatorByIdService(token, user.operator.id);
        if (rs) {
          setAttempts(rs.loginAttempts.toString());
        }
      }
    };
    fetchOperatorDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    if (user && attempts.trim()) {
      const parseAttempts = parseInt(attempts);
      if (parseAttempts <= 0) {
        dispatch(setTypeAlert("error"));
        dispatch(setMessageAlert("managementBonds.greaterThanZero"));
        return dispatch(setOpenAlert());
      }
      setLoading(true);
      await dispatch(startUpdateLoginAttempts(user.operator.id, parseAttempts));
      setLoading(false);
      dispatch(setTypeAlert("success"));
      dispatch(setMessageAlert("alerts.successful"));
      dispatch(setOpenAlert());
      dispatch(uiSetOpenDialog());
    }
  };
  const handleCancel = () => {
    dispatch(uiSetOpenDialog());
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={4} />
      <Grid item xs={4}>
        <TextField
          name="attempts"
          variant="outlined"
          size="small"
          label={t("user.maximumLoginAttempts") + "*"}
          onChange={(e) => setAttempts(e.target.value)}
          value={attempts}
          fullWidth
          autoComplete="off"
          onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
            if (attempts === "") {
              e.key === "0"
                ? e.preventDefault()
                : !regNumbers(e.key) && e.preventDefault();
            } else {
              !regNumbers(e.key) && e.preventDefault();
            }
          }}
        />
      </Grid>
      <Box
        display={"flex"}
        mt={9}
        flex={1}
        justifyContent="flex-end"
        alignItems={"flex-end"}
      >
        <Button
          variant="contained"
          className="footer-btn danger-btn ripple"
          style={{ border: "none", outline: "none" }}
          onClick={handleCancel}
          disabled={loading}
          data-testid={"btnCancel"}
        >
          {t("button.cancel").toUpperCase()}
        </Button>
        <Button
          type="submit"
          variant="contained"
          className="footer-btn primary-btn ripple"
          style={{ border: "none", outline: "none" }}
          data-testid={"btnSave"}
          disabled={!attempts.trim()}
          onClick={handleSave}
        >
          {loading ? (
            <CircularProgress size={"1.6rem"} color="inherit" />
          ) : (
            <>{t("button.save").toUpperCase()}</>
          )}
        </Button>
      </Box>
    </Grid>
  );
};
