import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { headersIrrigationCardsInventory } from "../../../../helpers/headersTables";
import { getCopyArrayOrObject } from "../../../../utils/utils";

export const IrrigationsInventoryDataTable = (totalData: any[]) => {
  const { data } = useSelector((state: AppState) => state.search);

  const headersCvsStyle = headersIrrigationCardsInventory.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  // Obtener datos por vista
  const dataIrrigationHistory =
    data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalIrrigationHistory = totalData
    ? getCopyArrayOrObject(totalData)
    : [];

    const buildAvailableRanges = (availableRanges: any) => {
        const res = availableRanges.map(
            (range: any, index: any) =>
                `${range.minCardId} - ${range.maxCardId}` +
                (index < availableRanges.length - 1 ? "\n" : "")
        );
        return res.join(' ');
    };
    
  const buildCardsNotAvailable = (data: any) => {
    const res =
      parseInt(data.redeemedCards as string) +
      parseInt(data.inactiveCards as string);
    return res;
  };

  const buildTotalData = (arr: any) => {
    const multiData = [
      {
        columns: headersCvsStyle,
        data: arr.map((data: any) => {
          const availableRanges = buildAvailableRanges(data.availableRanges);
          const cardsNotAvailable = buildCardsNotAvailable(data);
          const array = [
            {
              value: data.lotId,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.value,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: availableRanges,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.redeemedCards,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.inactiveCards,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: cardsNotAvailable,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.activeCards,
              style: { alignment: { horizontal: "center" } },
            },
          ];
          return array;
        }),
      },
    ];
    return multiData;
  };

  const multiTotalData = buildTotalData(dataTotalIrrigationHistory);
  const multiData = buildTotalData(dataIrrigationHistory);
  const finalData = {
    dataPerView: dataIrrigationHistory,
    dataTotal: dataTotalIrrigationHistory,
    headersCSV: headersIrrigationCardsInventory,
    multiDataSet: multiData,
    multiTotalDataSet: multiTotalData,
  };
  return finalData;
};
