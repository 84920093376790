import { FC, Fragment, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Grid, Tooltip } from "@mui/material";
import { MyTextField } from "../../components/custom/MyTextField";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  exportAffiliatesToExcel,
  startGetAffiliates,
} from "../../../redux/actions/affiliationsActions";
import moment from "moment";
import * as XLSX from "xlsx";
import "./TableComponent.scss";

const TableComponentPlayer: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { affiliates } = useSelector((state: AppState) => state.affiliations);
  const { token } = useSelector((state: AppState) => state.auth);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState(10);
  const [isFiltering, setIsFiltering] = useState(false);
  const [filterData, setFilterData] = useState({});
  const currentItems = affiliates?.data;
  const totalPages = affiliates?.last_page;
  const [expandExportList, setExpandExportList] = useState(false);
  const initialValues = {
    affiliate: "",
    referrer: "",
  };

  const handleSubmit = (data: any) => {
    if (data.affiliate || data.referrer) {
      setIsFiltering(true);
      setFilterData(data);
    }
    const params = { ...data };
    dispatch(startGetAffiliates(params));
  };

  const handleClean = (resetForm: any) => {
    setIsFiltering(false);
    resetForm();
    dispatch(startGetAffiliates({}));
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    const params = {
      ...filterData,
      page: page,
      perPage: itemsPerPage,
    };
    dispatch(startGetAffiliates(params));
  };

  const handleFirstPage = () => {
    handlePageChange(1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  const handleLastPage = () => {
    handlePageChange(totalPages);
  };

  const generatePageNumbers = () => {
    let pages = [];
    if (totalPages <= 7) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else if (currentPage <= 4) {
      pages = [1, 2, 3, 4, 5, "...", totalPages];
    } else if (currentPage > totalPages - 4) {
      pages = [
        1,
        "...",
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    } else {
      pages = [
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        totalPages,
      ];
    }
    return pages;
  };

  useEffect(() => {
    if (token) {
      const params = {
        page: 1,
        perPage: 10,
      };
      dispatch(startGetAffiliates(params));
    }
  }, [dispatch, token]);

  const dispatchWithPromise = (action: any) => {
    return new Promise((resolve, reject) => {
      dispatch(action).then(resolve).catch(reject);
    });
  };

  const exportToExcel = () => {
    dispatchWithPromise(exportAffiliatesToExcel({}))
      .then((result: any) => {
        const data = result.data.map((item: any) => ({
          ID_Afiliador: item.referrer.id,
          ID_Afiliado: item.affiliate.id,
          Fecha_Hora_de_registro: moment(item.createAt).format(
            "DD/MM/YYYY - hh:mm:ss A"
          ),
          Valor_Deposito: item.depositValue,
          Valor_Comision: item.operatorPercentage,
        }));
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Afiliados_Jugador");
        XLSX.writeFile(workbook, "Afiliados_Jugador.xlsx");
      })
      .catch((error: any) => {
        console.error("Error exporting data to Excel:", error);
      });
  };

  return (
    <div className="table-container">
      <div className="table-controls">
        <div className="filters">
          <Formik
            initialValues={initialValues}
            onSubmit={async (data, { resetForm }) => {
              if (!isFiltering) {
                handleSubmit(data);
              } else {
                handleClean(resetForm);
              }
            }}
          >
            {({ handleChange }) => (
              <Form>
                <Grid
                  container
                  spacing={2}
                  alignItems="end"
                  justifyContent="space-between"
                >
                  <Grid item xs={12} md={4}>
                    <label className="ml-3">ID Afiliado</label>
                    <MyTextField
                      name="affiliate"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      label=""
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(e: any) => {
                        handleChange(e);
                        setIsFiltering(false);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <label className="ml-3">ID Afiliador</label>
                    <MyTextField
                      name="referrer"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      label=""
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(e: any) => {
                        handleChange(e);
                        setIsFiltering(false);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <button
                      className="d-flex btn-light-dark"
                      type="submit"
                      style={{ width: "100%" }}
                    >
                      <div className="p-0 text-right">
                        <i className="fa fa-filter btn-icon pt-1 fa-md"></i>
                      </div>
                      <div className="p-0 text-left">
                        <label className="btn-label pl-1">
                          {isFiltering
                            ? t("filters.clean")
                            : t("filters.filter")}
                        </label>
                      </div>
                    </button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {affiliates?.total === 0 ? (
        <div>No se encontraron resultados</div>
      ) : (
        <Fragment>
          <table className="table">
            <thead>
              <tr>
                <th>Id Afiliador</th>
                <th>ID Afiliado</th>
                <th>Fecha/Hora de registro</th>
                <th>Valor depósito</th>
                <th>Valor de comisión</th>
                <th>
                  <Tooltip
                    open={expandExportList}
                    onClick={() => setExpandExportList((prev) => !prev)}
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "transparent",
                          "& .MuiTooltip-arrow": {
                            color: "#02D0E7",
                          },
                          fontWeight: "bold",
                          color: "#000",
                          maxWidth: "none",
                          cursor: "pointer",
                        },
                      },
                    }}
                    title="Exportar"
                    disableHoverListener
                    placement="bottom"
                  >
                    <i
                      style={{ color: "#007E8C", cursor: "pointer" }}
                      className="fa fa-file-export fa-lg"
                      onClick={exportToExcel}
                    ></i>
                  </Tooltip>
                </th>
              </tr>
            </thead>
            <tbody className="tbody-table">
              {currentItems?.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>{item.referrer.id}</td>
                    <td>{item.affiliate.id}</td>
                    <td>
                      {moment(item.createAt).format("DD/MM/YYYY - hh:mm:ss A")}
                    </td>
                    <td>{item.depositValue}</td>
                    <td>{item.comision}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination">
            <button
              className="navigation"
              onClick={handleFirstPage}
              disabled={currentPage === 1}
            >
              {"<<"}
            </button>
            <button
              className="navigation"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              {"<"}
            </button>
            {generatePageNumbers().map((page, index) =>
              page === "..." ? (
                <span key={index} className="ellipsis">
                  ...
                </span>
              ) : (
                <button
                  key={index}
                  className={`page-number ${
                    page === currentPage ? "active" : ""
                  }`}
                  onClick={() => handlePageChange(page as number)}
                >
                  {page}
                </button>
              )
            )}
            <button
              className="navigation"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              {">"}
            </button>
            <button
              className="navigation"
              onClick={handleLastPage}
              disabled={currentPage === totalPages}
            >
              {">>"}
            </button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default TableComponentPlayer;
