import { Form, Formik } from "formik";
import { useEffect, useMemo, useState, KeyboardEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { MyTextField } from "../../custom/MyTextField";
import { useForm } from "../../../../hooks/useForm";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  setBanks,
  setBanksRedux,
  setCities,
  setCitiesRedux,
  setCloseAlert,
  setCountries,
  setCountriesRedux,
  setDocumentTypes,
  setDocumentTypesRedux,
  setFlagForm,
  setFlagFormEdit,
  setMessageAlert,
  setOpenAlert,
  setStates,
  setStatesRedux,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import clearLogo from "../../../../assets/svg/icon-park_clear-format.svg";
import { MyDialog } from "../../custom/MyDialog";
import { IAssociateForm } from "../../../../interfaces/associate";
import {
  setActiveAssociate,
  startAddAssociate,
  startEditAssociate,
} from "../../../../redux/actions/associatesActions";
import { MyAlert } from "../../custom/MyAlert";
import { getAssociatesFilterService } from "../../../../services/associates";
import { DocumentTypes, Roles_Enum, State } from "../../../../enums/enums";
import { getOperatorsService } from "../../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../../redux/actions/managementCompanyActions";
import { setUsersRedux } from "../../../../redux/actions/managementUsersActions";
import { getUsers } from "../../../../services/managementUsers";
import { IUserDB } from "../../../../redux/interfaces/users";
import {
  ICities,
  ICountries,
  IDocumentTypes,
  IStates,
} from "../../../../redux/interfaces/shared";
import { IGenericData } from "../../../../interfaces/params";
import { setLoadingState } from "../../../../redux/actions/helperActions";

import {
  regAddress,
  regEmail,
  regLettersNumbers,
  regNumbers,
  regPassport,
  validatePhoneByCountry,
} from "../../../../utils/utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const FormCreateAssociate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    countries,
    cities,
    states,
    flagFormEdit,
    documentTypes,
    banks,
    openAlert,
    typeAlert,
    messageAlert,
  } = useSelector((state: AppState) => state.helper);
  const { token, user } = useSelector((state: AppState) => state.auth);
  const { loading } = useSelector((state: AppState) => state.helper);
  const { activeAssociate } = useSelector(
    (state: AppState) => state.associates
  );
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const [selectedAccountType, setSelectedAccountType] = useState<
    string | number
  >(
    activeAssociate?.accountType !== undefined
      ? activeAssociate.accountType.toString()
      : ""
  );
  const [errorAccountType, setErrorAccountType] = useState(false);

  const [errorOperator, setErrorOperator] = useState(false);

  let [formValues, handleInputChange] = useForm({
    country: activeAssociate ? activeAssociate.country.id : 0,
    state: activeAssociate ? activeAssociate.state.id : 0,
    city: activeAssociate ? activeAssociate.city.id : 0,
    bank: activeAssociate ? activeAssociate.bank.id : 0,
    operator: activeAssociate ? activeAssociate.operator.id : 0,
    createdUser: activeAssociate ? activeAssociate.createdUser.id : 0,
  });
  const [searchText] = useState("");
  const [resetFormFlag, setResetFormFlag] = useState(false);
  const [resetFunction, setResetFunction] = useState<Function>();
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [cancel, setCancel] = useState(false);
  const [totalData, setTotalData] = useState<IAssociateForm>();
  const [fieldsValid, setFieldsValid] = useState(false);
  const [flagStates, setFlagStates] = useState(true);
  const [flagCity, setFlagCity] = useState(true);
  const banksOptions = useMemo(() => (banks ? banks : []), [banks]);
  const [selectedCountry, setSelectedCountry] = useState<ICountries | null>(
    activeAssociate?.country ? activeAssociate.country : null
  );
  const [errorCountry, setErrorCountry] = useState(false);

  const [selectedStates, setSelectedStates] = useState<IStates | null>(
    activeAssociate?.state ? activeAssociate.state : null
  );
  const [errorStates, setErrorStates] = useState(false);

  const [cityData, setCity] = useState<ICities | null>(
    activeAssociate?.city ? activeAssociate.city : null
  );
  const [errorCity, setErrorCity] = useState(false);

  const [bankSelected, setBankSelected] = useState<any | null>(
    activeAssociate ? activeAssociate.bank : null
  );
  const [errorBank, setErrorBank] = useState(false);
  const [loadingValidate, setLoadingValidate] = useState(false);
  const [numberDocumentError, setNumberDocumentError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const formikRef = useRef(null);

  const state = [
    { label: t("state.active"), value: State.ACTIVE },
    { label: t("state.inactive"), value: State.INACTIVE },
  ];
  const initialValues: IAssociateForm = {
    id: activeAssociate?.id,
    name: activeAssociate ? activeAssociate.name : "",
    address: activeAssociate ? activeAssociate.address : "",
    country: activeAssociate?.country || {
      id: 0,
      name: "",
      imgUrl: "",
      code: "",
    },
    city: { id: 0, names: "" },
    state: activeAssociate?.state
      ? activeAssociate.state
      : { id: 0, names: "" },
    documentType: activeAssociate ? activeAssociate.documentType.id : "",
    documentNumber: activeAssociate ? activeAssociate.documentNumber : "",
    phone: activeAssociate ? activeAssociate.phone : "",
    email: activeAssociate ? activeAssociate.email : "",
    bank: { id: 0, name: "" },
    accountType: activeAssociate ? activeAssociate.accountType : "",
    accountNumber: activeAssociate ? activeAssociate.accountNumber : "",
    transferMoney: activeAssociate ? activeAssociate.transferMoney : "",
    maximumValue: activeAssociate ? activeAssociate.maximumValue : "",
    minimumValue: activeAssociate ? activeAssociate.minimumValue : "",
    stateUser: activeAssociate ? activeAssociate.stateUser : "",
    createdUser: { id: 0, nameComplete: "" },
    operator: 0,
    createAt: activeAssociate?.createAt,
    refillsBalance: activeAssociate?.refillsBalance ?? "0",
    betsBalance: activeAssociate?.betsBalance ?? "0",
    commissionPercentageWithdrawals: activeAssociate
      ? parseFloat(activeAssociate.commissionPercentageWithdrawals as string)
      : "",
    commissionPercentageRefills: activeAssociate
      ? parseFloat(activeAssociate.commissionPercentageRefills as string)
      : "",
    commissionPercentageGGR: activeAssociate
      ? parseFloat(activeAssociate.commissionPercentageGGR as string)
      : "",
    commissionPercentagePrizes: activeAssociate
      ? parseFloat(activeAssociate.commissionPercentagePrizes as string)
      : "",
    commissionPercentageCards:
      parseFloat(activeAssociate?.commissionPercentageCards as string) || "",
    commissionPercentageAffiliations:
      parseFloat(activeAssociate?.commissionPercentageAffiliations as string) ||
      "",
  };

  const validationSchema = yup.object({
    name: yup.string().required(t("form.requiredFile")),
    address: yup.string().required(t("form.requiredFile")),
    city: yup.object(),
    country: yup.object(),
    state: yup.object(),
    documentType: yup.number().required(t("form.requiredFile")),
    documentNumber: yup
      .string()
      .required(t("form.requiredFile"))
      .when("documentType", {
        is: (value: number) =>
          [DocumentTypes.CITIZENSHIP_CARD, DocumentTypes.PASSPORT].includes(
            value
          ),
        then: yup
          .string()
          .min(7, t("form.invalidLengthDocumentNumber", { min: 7, max: 10 }))
          .max(10, t("form.invalidLengthDocumentNumber", { min: 7, max: 10 })),
      })
      .when("documentType", {
        is: DocumentTypes.CIP,
        then: yup
          .string()
          .min(7, t("form.invalidLengthDocumentNumber", { min: 7, max: 13 }))
          .max(13, t("form.invalidLengthDocumentNumber", { min: 7, max: 13 })),
      })
      .when("documentType", {
        is: DocumentTypes.DNI,
        then: yup
          .string()
          .min(8, t("form.invalidLengthDocumentNumber", { min: 8, max: 13 }))
          .max(13, t("form.invalidLengthDocumentNumber", { min: 8, max: 13 })),
      })
      .when("documentType", {
        is: (value: number) =>
          [DocumentTypes.DUI, DocumentTypes.CE].includes(value),
        then: yup
          .string()
          .min(5, t("form.invalidLengthDocumentNumber", { min: 5, max: 15 }))
          .max(15, t("form.invalidLengthDocumentNumber", { min: 5, max: 15 })),
      })
      .when("documentType", {
        is: DocumentTypes.DPI,
        then: yup
          .string()
          .min(13, t("form.invalidLengthDocument", { value: 13 }))
          .max(13, t("form.invalidLengthDocument", { value: 13 })),
      }),
    phone: yup
      .number()
      .positive(t("user.errorNegativeNumber"))
      .required(t("form.requiredFile"))
      .test(
        "len",
        t("user.invalidPhone", {
          length: 10,
        }),
        function (value) {
          if (value) {
            const country: any = this.resolve(yup.ref("country"));

            const { valid, length, startOf } = validatePhoneByCountry(
              country.code,
              value?.toString()
            ); // Validar el número de teléfono

            if (!valid) {
              const params: any = {
                length,
                rest: startOf
                  ? t("user.startOfNumber", {
                      numbers: startOf.toString(),
                    })
                  : "",
              };

              return this.createError({
                message: t("user.invalidPhone", params),
              });
            }
          }
          return true;
        }
      ),
    email: yup
      .string()
      .email(t("user.invalidEmail"))
      .required(t("form.requiredFile")),
    bank: yup.object(),
    accountType: yup.number(),
    accountNumber: yup
      .string()
      .min(10, t("alerts.lengthMinAccountNumber"))
      .max(32, t("alerts.lengthMaxAccountNumber"))
      .required(t("form.requiredFile")),
    transferMoney: yup.number().required(t("form.requiredFile")),
    maximumValue: yup.number().when("transferMoney", {
      is: 1,
      then: yup
        .number()
        .required(t("form.requiredFile"))
        .moreThan(
          yup.ref("minimumValue"),
          "Debe ser mayor que el valor mínimo"
        ),
      otherwise: yup.number(),
    }),
    minimumValue: yup.number().when("transferMoney", {
      is: 1,
      then: yup
        .number()
        .required(t("form.requiredFile"))
        .lessThan(
          yup.ref("maximumValue"),
          "Debe ser menor que el valor maximo"
        ),
      otherwise: yup.number(),
    }),
    refillsBalance: yup.number().required(t("form.requiredFile")),
    betsBalance: yup.number().required(t("form.requiredFile")),
    operator: yup.number(),
    createdUser: yup.object(),
    stateUser: yup.number().required(t("form.requiredFile")),
    commissionPercentageWithdrawals: yup
      .number()
      .min(0, t("user.errorNegativeNumber"))
      .required(t("form.requiredFile")),
    commissionPercentageRefills: yup
      .number()
      .min(0, t("user.errorNegativeNumber"))
      .required(t("form.requiredFile")),
    commissionPercentageGGR: yup
      .number()
      .min(0, t("user.errorNegativeNumber"))
      .required(t("form.requiredFile")),
    commissionPercentagePrizes: yup
      .number()
      .min(0, t("user.errorNegativeNumber"))
      .required(t("form.requiredFile")),
    commissionPercentageCards: yup
      .number()
      .min(0, t("user.errorNegativeNumber"))
      .required(t("form.requiredFile")),
    commissionPercentageAffiliations: yup
      .number()
      .min(0, t("user.errorNegativeNumber"))
      .required(t("form.requiredFile")),
  });

  useEffect(() => {
    if (token) {
      dispatch(setCountries(token));
    }
    return () => {
      dispatch(setCitiesRedux([]));
      dispatch(setCountriesRedux([]));
      dispatch(setStatesRedux([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    token && fetchOperators(token);
    return () => {
      dispatch(setCompaniesRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * Soporta el evento click del boton para traer los estados del país
   */
  const getStates = (id: number) => {
    if (token) {
      dispatch(setStates(token, id));
    }
  };
  /**
   * Soporta el evento click del boton para traer las ciudades del estado
   */
  const getCities = async (id: number) => {
    if (token) {
      await dispatch(setCities(token, id));
    }
  };
  // States effect
  useEffect(() => {
    //FILTRAR LOS SELECTS
    if (selectedCountry) {
      getStates(selectedCountry.id);
      //@ts-ignore
      if (
        !activeAssociate ||
        (activeAssociate && activeAssociate.country.id !== selectedCountry.id)
      ) {
        setSelectedStates(null);
        setBankSelected(null);
        setSelectedAccountType("");
        (formikRef.current as any).setFieldValue("accountNumber", "");
        (formikRef.current as any).setFieldError("accountNumber", "");
        (formikRef.current as any).setFieldValue("phone", "");
        (formikRef.current as any).setFieldError("phone", "");
      }
      setFlagStates(false);
    }
    if (selectedCountry && token) {
      dispatch(setBanks(token, selectedCountry.id.toString()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  // Users effect
  useEffect(() => {
    const fetchUsers = async (token: string) => {
      if (formValues.operator && user) {
        const usersOperators = await getUsers(
          token,
          user.id,
          user.roles.name,
          1,
          null,
          formValues.operator
        );
        let filterUsers = usersOperators.data.filter((item: any) => {
          return item.roles.name === "ADMIN_OPERATOR";
        });

        let objUsers: IUserDB = {
          data: filterUsers,
          last_page: 1,
          page: 1,
          total: filterUsers.length,
        };
        formValues.operator && dispatch(setUsersRedux(objUsers));
      }
    };
    token && fetchUsers(token);
    return () => {
      dispatch(setUsersRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.operator]);

  // Cities effect
  useEffect(() => {
    //FILTRAR LOS SELECTS
    if (selectedStates) {
      getCities(selectedStates.id);
      if (
        !activeAssociate ||
        (activeAssociate && activeAssociate.state.id !== selectedStates.id)
      ) {
        setCity(null);
      }
      setFlagCity(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStates]);

  const displayedOptions = useMemo(
    () =>
      banksOptions.filter((bank) =>
        bank.name.toLowerCase().includes(searchText.toLowerCase())
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchText, banksOptions]
  );

  // Cargar tipos documento
  useEffect(() => {
    token && dispatch(setDocumentTypes(token));

    return () => {
      dispatch(setDocumentTypesRedux([]));
      dispatch(setBanksRedux([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    setMessage("dialog.sureExit");
    setCancel(true);
    setOpenDialog(true);
  };

  const handleCleanForm = () => {
    setMessage("dialog.sureClean");
    setResetFormFlag(true);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCancel(false);
    setResetFormFlag(false);
  };

  const openConfirmation = () => {
    setMessage(
      !!activeAssociate ? "dialog.sureEdit" : "dialog.sureSaveAssociate"
    );
    setOpenDialog(true);
  };

  const handleAccept = async () => {
    handleCloseDialog();
    if (resetFormFlag && !cancel) {
      formValues.country = 0;
      formValues.state = 0;
      formValues.city = 0;
      formValues.bank = 0;
      if (isSuper) {
        formValues.operator = 0;
      }
      resetFunction && resetFunction();
      setSelectedCountry(null);
      setSelectedStates(null);
      setCity(null);
      setBankSelected(null);
      setErrorBank(false);
      setErrorAccountType(false);
      setErrorOperator(false);
      setErrorCountry(false);
      setErrorStates(false);
      setErrorCity(false);
      setErrorAccountType(false);
      setSelectedAccountType("");
      setEmailError(false);
      setNumberDocumentError(false);
      setUsernameError(false);
      return null;
    }
    if (cancel) {
      dispatch(setFlagForm({ flagFormCreate: false }));
      dispatch(setFlagFormEdit({ flagFormEdit: false }));
      dispatch(setActiveAssociate(null));
    } else {
      if (totalData) {
        const { isValid, msg } = await validatedDuplicateField(totalData);
        setLoadingValidate(false);

        if (!isValid) {
          dispatch(setMessageAlert(msg));
          dispatch(setTypeAlert("error"));
          return dispatch(setOpenAlert());
        } else {
          setNumberDocumentError(false);
          setEmailError(false);
          setUsernameError(false);
          dispatch(setLoadingState({ loading: true }));

          !!activeAssociate
            ? totalData.id &&
              (await dispatch(startEditAssociate(totalData, totalData.id)))
            : await dispatch(startAddAssociate(totalData));
        }
      }
    }

    setCancel(false);
    setResetFormFlag(false);
    setOpenDialog(false);
  };

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
    if (typeAlert === "success") {
      if (fieldsValid) {
        dispatch(setFlagForm({ flagFormCreate: false }));
        dispatch(setFlagFormEdit({ flagFormEdit: false }));
        dispatch(setActiveAssociate(null));
      }
    }
    dispatch(setLoadingState({ loading: false }));
    setLoadingValidate(false);
  };

  const validatedDuplicateField = async (data: IAssociateForm) => {
    let isValid = true;
    let messages: string[] = [];
    setLoadingValidate(true);

    const operatorId = data.operator
      ? parseInt(data.operator.toString())
      : null;

    if (!activeAssociate || data.name !== activeAssociate.name) {
      const params = {
        username: data.name,
        exact: true,
      };

      const findUsername =
        token && (await getAssociatesFilterService(token, operatorId, params));

      if (findUsername.data.length > 0) {
        messages.push("alerts.usernameDuplicate");
        setUsernameError(true);
        isValid = false;
      } else {
        setUsernameError(false);
      }
    }

    if (
      !activeAssociate ||
      data.documentNumber !== activeAssociate.documentNumber
    ) {
      const params = {
        documentNumber: data.documentNumber,
      };

      const findDocumentNumber =
        token && (await getAssociatesFilterService(token, operatorId, params));

      if (findDocumentNumber.data.length > 0) {
        messages.push("alerts.numberDocumentDuplicate");
        setNumberDocumentError(true);
        isValid = false;
      } else {
        setNumberDocumentError(false);
      }
    }

    if (!activeAssociate || data.email !== activeAssociate.email) {
      const params = {
        email: data.email,
      };
      const findEmail =
        token && (await getAssociatesFilterService(token, operatorId, params));

      if (findEmail.data.length > 0) {
        messages.push("alerts.emailDuplicate");
        setEmailError(true);
        isValid = false;
      } else {
        setEmailError(false);
      }
    }
    setFieldsValid(isValid);

    const finalMesg =
      messages.length > 1 ? "alerts.duplicateValuesForm" : messages[0];

    return { isValid, msg: finalMesg };
  };

  /**
   * Soporta el evento para validar los campos
   */
  const validateFields = async (data: IAssociateForm) => {
    !selectedCountry ? setErrorCountry(true) : setErrorCountry(false);

    !selectedStates ? setErrorStates(true) : setErrorStates(false);

    !cityData ? setErrorCity(true) : setErrorCity(false);

    !bankSelected ? setErrorBank(true) : setErrorBank(false);

    setErrorOperator(
      user?.roles.name === Roles_Enum.SUPER_ADMIN && formValues.operator === 0
    );

    setErrorAccountType(selectedAccountType === "");
  };

  /**
   * Soporta el evento para capturar el país
   */
  const handleSelectCountry = async (value: ICountries | null) => {
    setSelectedStates(null);
    setCity(null);

    !value ? setErrorCountry(true) : setErrorCountry(false);
    value && setSelectedCountry(value);
  };

  /**
   * Soprta el evento para capturar el estado
   */
  const handleSelectStates = async (value: IStates | null) => {
    setCity(null);
    !value ? setErrorStates(true) : setErrorStates(false);
    value && setSelectedStates(value);
  };

  /**
   * Soporta el evebnto para capturar la ciudad
   */
  const handleSelectCities = async (value: ICities | null) => {
    !value ? setErrorCity(true) : setErrorCity(false);
    value && setCity(value);
  };

  /**
   * Soporta el evento para capturar el tipo de documento
   */
  const handleSelectBank = (value: IGenericData | null) => {
    value ? setErrorBank(false) : setErrorBank(true);
    value && setBankSelected(value);
  };

  /**
   * Soporta el evento para capturar el tipo de cuenta
   */
  const handleSelectAccountType = async (value: number | string) => {
    setErrorAccountType(value === "");
    setSelectedAccountType(value);
  };

  return (
    <div className="container mt-2">
      <div className="filter">
        <Formik
          innerRef={formikRef}
          validateOnChange={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            if (
              !errorBank &&
              selectedCountry &&
              !errorCountry &&
              selectedStates &&
              !errorStates &&
              !errorAccountType &&
              cityData &&
              !errorCity &&
              !errorOperator
            ) {
              const newData = { ...data };
              newData.country = selectedCountry;
              newData.state = selectedStates;
              newData.city = cityData;
              newData.bank = bankSelected;
              newData.accountType = selectedAccountType;
              newData.transferMoney = data.transferMoney;
              newData.maximumValue =
                data.transferMoney === 0 ? 0 : data.maximumValue;
              newData.minimumValue =
                data.transferMoney === 0 ? 0 : data.minimumValue;
              newData.operator = activeAssociate
                ? activeAssociate.operator
                : user?.roles.name === Roles_Enum.SUPER_ADMIN
                ? formValues.operator
                : user?.operator.id;
              if (user) {
                // @ts-ignore
                newData.createdUser = activeAssociate
                  ? activeAssociate.createdUser.id
                  : user.id;
              }
              setTotalData(newData);
              openConfirmation();
              setSubmitting(false);
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            handleReset,
            setFieldValue,
            isSubmitting,
            handleChange,
          }) => (
            <Form>
              <Grid container spacing={1.5}>
                <Grid item xs={12} className="mb-3">
                  <Box
                    display="flex"
                    className="pb-1"
                    borderBottom={1}
                    borderColor="#02D0E7"
                  >
                    <i
                      className="fa-solid fa-circle-info"
                      style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
                    ></i>
                    <p className="txt-primary-md">
                      {t("associates.personalInformation")}
                    </p>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <label className="ml-3">
                    {t("associates.associatedName")}*
                  </label>
                  <MyTextField
                    inputProps={{
                      "data-testid": "name",
                      maxLength: 150,
                    }}
                    name="name"
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    label={" "}
                    autoComplete="off"
                    error={usernameError && !loadingValidate}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regLettersNumbers(e.key) && e.preventDefault()
                    }
                    onChange={(e: any) => {
                      handleChange(e);
                      setUsernameError(false);
                    }}
                  />
                </Grid>
                {countries && (
                  <Grid item xs={3}>
                    <label className="ml-3">{t("user.country")}*</label>
                    <Autocomplete
                      id="countries"
                      data-testid="countriesSelect"
                      options={countries}
                      disableClearable
                      // @ts-ignore
                      value={selectedCountry}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event: any, value: ICountries | null) => {
                        setFieldValue("country", value);

                        handleSelectCountry(value);
                      }}
                      renderOption={(props, option) => {
                        const { name } = option;
                        return (
                          <span {...props} style={{ color: "#000" }}>
                            {name}
                          </span>
                        );
                      }}
                      renderInput={({ inputProps, ...rest }) => (
                        <TextField
                          {...rest}
                          inputProps={{ ...inputProps, readOnly: true }}
                          name="countries"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ shrink: false }}
                          onChange={handleInputChange}
                          label={" "}
                          error={errorCountry}
                        />
                      )}
                    />
                    <FormHelperText>
                      {errorCountry && (
                        <label className="ml-3" style={{ color: "#d32f2f" }}>
                          {t("form.requiredFile")}
                        </label>
                      )}
                    </FormHelperText>
                  </Grid>
                )}
                <Grid item xs={3}>
                  <label className="ml-3">
                    {t("companyManagement.department")}*
                  </label>
                  <Autocomplete
                    id="state"
                    data-testid="stateSelect"
                    options={states ? states : []}
                    renderOption={(props, option) => {
                      return (
                        <span {...props} style={{ color: "#000" }}>
                          {option.names}
                        </span>
                      );
                    }}
                    disabled={flagStates}
                    disableClearable
                    // @ts-ignore
                    value={selectedStates}
                    getOptionLabel={(option) => option.names}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event: any, value: IStates | null) =>
                      handleSelectStates(value)
                    }
                    renderInput={({ inputProps, ...rest }) => (
                      <TextField
                        {...rest}
                        inputProps={{ ...inputProps, readOnly: true }}
                        name="state"
                        variant="outlined"
                        InputLabelProps={{ shrink: false }}
                        size="small"
                        label={" "}
                        error={errorStates}
                        sx={{
                          "& .MuiOutlinedInput-root.Mui-error": {
                            "& fieldset": {
                              borderColor: "#d32f2f",
                            },
                          },
                        }}
                      />
                    )}
                  />
                  <FormHelperText>
                    {errorStates && (
                      <label className="ml-3" style={{ color: "#d32f2f" }}>
                        {t("form.requiredFile")}
                      </label>
                    )}
                  </FormHelperText>
                </Grid>
                <Grid item xs={3}>
                  <label className="ml-3">{t("companyManagement.town")}*</label>
                  <Autocomplete
                    id="city"
                    data-testid="citySelect"
                    options={cities ? cities : []}
                    disabled={flagCity}
                    disableClearable
                    // @ts-ignore
                    value={cityData}
                    getOptionLabel={(option) => option.names}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event: any, value: ICities | null) =>
                      handleSelectCities(value)
                    }
                    renderOption={(props, option) => {
                      return (
                        <span {...props} style={{ color: "#000" }}>
                          {option.names}
                        </span>
                      );
                    }}
                    renderInput={({ inputProps, ...rest }) => (
                      <TextField
                        {...rest}
                        inputProps={{ ...inputProps, readOnly: true }}
                        name="city"
                        variant="outlined"
                        InputLabelProps={{ shrink: false }}
                        size="small"
                        label={" "}
                        error={errorCity}
                        sx={{
                          "& .MuiOutlinedInput-root.Mui-error": {
                            "& fieldset": {
                              borderColor: "#d32f2f",
                            },
                          },
                        }}
                      />
                    )}
                  />
                  <FormHelperText>
                    {errorCity && (
                      <label className="ml-3" style={{ color: "#d32f2f" }}>
                        {t("form.requiredFile")}
                      </label>
                    )}
                  </FormHelperText>
                </Grid>

                <Grid item xs={3}>
                  <label className="ml-3">{t("user.address")}*</label>
                  <MyTextField
                    name="address"
                    inputProps={{
                      "data-testid": "address",
                      maxLength: 150,
                    }}
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    label={" "}
                    autoComplete="off"
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regAddress(e.key) && e.preventDefault()
                    }
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3} mb={errors.documentType && -1}>
                  <label className="ml-3">{t("user.documentType")}*</label>
                  <MyTextField
                    name="documentType"
                    variant="outlined"
                    size="small"
                    fullWidth
                    select
                    InputLabelProps={{ shrink: false }}
                    label={" "}
                    onChange={(event: any, value: IDocumentTypes | null) => {
                      handleChange(event);
                      setFieldValue("documentNumber", "");
                    }}
                    disabled={!!activeAssociate}
                  >
                    {documentTypes?.map((item) => (
                      <MenuItem key={item.name} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </MyTextField>
                </Grid>
                <Grid item xs={3}>
                  <label className="ml-3">{t("user.documentNumber")}*</label>
                  <MyTextField
                    name="documentNumber"
                    inputProps={{
                      "data-testid": "documentNumber",
                      maxLength: 15,
                    }}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: false }}
                    label={" "}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setNumberDocumentError(false);
                      handleChange(e);
                    }}
                    value={values.documentNumber}
                    error={numberDocumentError && !loadingValidate}
                    fullWidth
                    disabled={!!activeAssociate}
                    autoComplete="off"
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                      if (
                        [DocumentTypes.PASSPORT, DocumentTypes.CE].includes(
                          +values.documentType
                        )
                          ? !regPassport(e.key)
                          : !regNumbers(e.key)
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <label className="ml-3">{t("user.email")}*</label>
                  <MyTextField
                    inputProps={{
                      "data-testid": "email",
                      maxLength: 100,
                    }}
                    name="email"
                    InputLabelProps={{ shrink: false }}
                    label={" "}
                    error={emailError && !loadingValidate}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regEmail(e.key) && e.preventDefault()
                    }
                    onChange={(e: any) => {
                      handleChange(e);
                      setEmailError(false);
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <label className="ml-3">{t("user.phone")}*</label>
                  <MyTextField
                    name="phone"
                    inputProps={{
                      "data-testid": "phone",
                    }}
                    InputLabelProps={{ shrink: false }}
                    label={" "}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                      if (values.phone === "") {
                        e.key === "0"
                          ? e.preventDefault()
                          : !regNumbers(e.key) && e.preventDefault();
                      } else {
                        !regNumbers(e.key) && e.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                </Grid>

                {
                  //MOSTRAR SELECT CON OPERADORES CUANDO SEA SUPER_ADMIN
                  user?.roles.name === Roles_Enum.SUPER_ADMIN &&
                    !activeAssociate && (
                      <>
                        {companies && (
                          <Grid item xs={3}>
                            <label className="ml-3">
                              {t("companyManagement.operator") + "*"}
                            </label>
                            <FormControl
                              sx={{ width: "100%" }}
                              size="small"
                              error={errorOperator}
                              disabled={!!activeAssociate}
                            >
                              <Select
                                id="operatorTextField"
                                inputProps={{
                                  id: "operatorInput",
                                }}
                                name="operator"
                                size="small"
                                fullWidth
                                onChange={(event) => {
                                  handleInputChange(event);
                                  setErrorOperator(false);
                                }}
                                value={
                                  formValues.operator === 0
                                    ? ""
                                    : formValues.operator || ""
                                }
                                sx={{
                                  "& legend": { display: "none" },
                                  "& fieldset": { top: 0 },
                                }}
                                MenuProps={MenuProps}
                              >
                                {companies?.data?.map((company) => (
                                  <MenuItem key={company.id} value={company.id}>
                                    {company.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormHelperText>
                              {errorOperator && (
                                <label
                                  className="ml-3"
                                  style={{ color: "#d32f2f" }}
                                >
                                  {t("form.requiredFile")}
                                </label>
                              )}
                            </FormHelperText>
                          </Grid>
                        )}
                      </>
                    )
                }

                <Grid item xs={3}>
                  <label className="ml-3">{t("user.state")}*</label>
                  <MyTextField
                    name="stateUser"
                    select
                    InputLabelProps={{ shrink: false }}
                    label={" "}
                    onChange={handleChange}
                  >
                    {state.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        sx={{
                          "&.Mui-focused": {
                            backgroundColor: "red",
                          },
                        }}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </MyTextField>
                </Grid>

                <Grid item xs={3}>
                  <label className="ml-3">
                    {"% " + t("associates.commissionRefillClients")}*
                  </label>
                  <MyTextField
                    name="commissionPercentageWithdrawals"
                    inputProps={{
                      "data-testid": "commissionPercentageWithdrawals",
                      maxLength: 5,
                    }}
                    InputLabelProps={{ shrink: false }}
                    label={" "}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regNumbers(e.key) && e.preventDefault()
                    }
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={3}>
                  <label className="ml-3">
                    {t("associates.commissionPercentageRefills")}*
                  </label>
                  <MyTextField
                    name="commissionPercentageRefills"
                    inputProps={{
                      "data-testid": "commissionPercentageRefills",
                      maxLength: 5,
                    }}
                    InputLabelProps={{ shrink: false }}
                    label={" "}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regNumbers(e.key) && e.preventDefault()
                    }
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={3}>
                  <label className="ml-3">
                    {t("associates.commissionPercentageGGR")}*
                  </label>
                  <MyTextField
                    name="commissionPercentageGGR"
                    inputProps={{
                      "data-testid": "commissionPercentageGGR",
                      maxLength: 5,
                    }}
                    InputLabelProps={{ shrink: false }}
                    label={" "}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regNumbers(e.key) && e.preventDefault()
                    }
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={3}>
                  <label className="ml-3">
                    {"% " + t("managementDealers.comissionWithdrawals")}*
                  </label>
                  <MyTextField
                    name="commissionPercentagePrizes"
                    inputProps={{
                      "data-testid": "commissionPercentagePrizes",
                      maxLength: 5,
                    }}
                    InputLabelProps={{ shrink: false }}
                    label={" "}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regNumbers(e.key) && e.preventDefault()
                    }
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <label className="ml-3">
                    {t("associates.commissionPercentageCards")}*
                  </label>
                  <MyTextField
                    name="commissionPercentageCards"
                    inputProps={{
                      "data-testid": "commissionPercentageCards",
                      maxLength: 3,
                    }}
                    InputLabelProps={{ shrink: false }}
                    label={" "}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regNumbers(e.key) && e.preventDefault()
                    }
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <label className="ml-3">
                    {t("associates.commissionPercentageAffiliations")}*
                  </label>
                  <MyTextField
                    name="commissionPercentageAffiliations"
                    inputProps={{
                      "data-testid": "commissionPercentageAffiliations",
                      maxLength: 3,
                    }}
                    InputLabelProps={{ shrink: false }}
                    label={" "}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regNumbers(e.key) && e.preventDefault()
                    }
                    onChange={handleChange}
                  />
                </Grid>

                {/* --------- INFORMACIÓN CUENTA BANCARIA ---------- */}
                <Grid item xs={12} className="mb-3 mt-2">
                  <Box
                    display="flex"
                    className="pb-1"
                    borderBottom={1}
                    borderColor="#02D0E7"
                  >
                    <i
                      className="fa-solid fa-circle-info"
                      style={{
                        color: "#02D0E7",
                        marginRight: 9,
                        fontSize: 17,
                      }}
                    ></i>
                    <p className="txt-primary-md">
                      {t("associates.bankAccountInformation")}
                    </p>
                  </Box>
                </Grid>

                {displayedOptions && (
                  <Grid item xs={3}>
                    <label className="ml-3">{t("associates.bank")}*</label>
                    <Autocomplete
                      id="bank"
                      data-testid="bankSelect"
                      options={displayedOptions}
                      disableClearable
                      // @ts-ignore
                      value={bankSelected}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event: any, value: any | null) =>
                        handleSelectBank(value)
                      }
                      renderOption={(props, option) => {
                        const { name } = option;
                        return (
                          <span {...props} style={{ color: "#000" }}>
                            {name}
                          </span>
                        );
                      }}
                      renderInput={({ inputProps, ...rest }) => (
                        <TextField
                          {...rest}
                          inputProps={{ ...inputProps, readOnly: true }}
                          name="bank"
                          variant="outlined"
                          InputLabelProps={{ shrink: false }}
                          size="small"
                          label={" "}
                          error={errorBank}
                        />
                      )}
                    />
                    <FormHelperText>
                      {errorBank && (
                        <label className="ml-3" style={{ color: "#d32f2f" }}>
                          {t("form.requiredFile")}
                        </label>
                      )}
                    </FormHelperText>
                  </Grid>
                )}
                <Grid item xs={3}>
                  <label className="ml-3">{t("associates.accountType")}*</label>
                  <TextField
                    id="accountTypeTextField"
                    name="selectedAccountType"
                    inputProps={{
                      id: "accountTypeInput",
                    }}
                    onChange={(event: any) =>
                      handleSelectAccountType(event.target.value)
                    }
                    value={selectedAccountType}
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    label={" "}
                    autoComplete="off"
                    select
                    size="small"
                    fullWidth
                    error={errorAccountType}
                  >
                    <MenuItem value={0}>{t("associates.savings")}</MenuItem>
                    <MenuItem value={1}>{t("associates.current")}</MenuItem>
                  </TextField>
                  <FormHelperText>
                    {errorAccountType && (
                      <label className="ml-3" style={{ color: "#d32f2f" }}>
                        {t("form.requiredFile")}
                      </label>
                    )}
                  </FormHelperText>
                </Grid>

                <Grid item xs={3}>
                  <label className="ml-3">
                    {t("associates.accountNumber")}*
                  </label>
                  <MyTextField
                    name="accountNumber"
                    inputProps={{
                      "data-testid": "accountNumber",
                      maxLength: 32,
                    }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                      if (!regNumbers(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    InputLabelProps={{ shrink: false }}
                    label={" "}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={3}>
                  <label className="ml-3">
                    {t("associates.transferMoney")}*
                  </label>
                  <MyTextField
                    id="transferMoneyTextField"
                    inputProps={{
                      id: "transferMoneyInput",
                    }}
                    name="transferMoney"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    label={" "}
                    autoComplete="off"
                    select
                  >
                    <MenuItem disabled value={""}></MenuItem>
                    <MenuItem value={1}>{t("table.yes")}</MenuItem>
                    <MenuItem value={0}>{t("table.not")}</MenuItem>
                  </MyTextField>
                </Grid>

                {values.transferMoney === 1 && (
                  <>
                    <Grid item xs={3}>
                      <label className="ml-3">
                        {t("associates.minimumTransferValue")}*
                      </label>
                      <MyTextField
                        name="minimumValue"
                        inputProps={{
                          "data-testid": "minimumValue",
                          maxLength: 20,
                        }}
                        InputLabelProps={{ shrink: false }}
                        label={" "}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                          !regNumbers(e.key) && e.preventDefault()
                        }
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <label className="ml-3">
                        {t("associates.maximumTransferValue")}*
                      </label>
                      <MyTextField
                        name="maximumValue"
                        inputProps={{
                          "data-testid": "maximumValue",
                          maxLength: 20,
                        }}
                        InputLabelProps={{ shrink: false }}
                        label={" "}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                          !regNumbers(e.key) && e.preventDefault()
                        }
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                )}
                <Grid
                  item
                  xs={12}
                  mt={3}
                  className="gridButton"
                  sx={{ height: "60px" }}
                >
                  <Button
                    type="button"
                    style={{ border: "none", outline: "none" }}
                    variant="contained"
                    className="footer-btn danger-btn ripple"
                    onClick={handleCancel}
                    disabled={!!loading || loadingValidate}
                  >
                    <i className="fa-solid fa-x"></i>
                    {t("button.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    style={{
                      border: "none",
                      outline: "none",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    variant="contained"
                    className="footer-btn primary-btn ripple"
                    onClick={() => validateFields(values)}
                    id="btnSave"
                    data-testid="btnSubmit"
                    disabled={isSubmitting || !!loading || loadingValidate}
                  >
                    {loadingValidate || !!loading ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      <>
                        {flagFormEdit ? (
                          <>{t("button.edit")}</>
                        ) : (
                          <>{t("button.save")}</>
                        )}
                        <i className="fa-solid fa-angle-right ml-2"></i>
                      </>
                    )}
                  </Button>

                  <Button
                    data-testid="btnClean"
                    type="button"
                    style={{
                      border: "none",
                      outline: "none",
                      marginLeft: "4px",
                      opacity:
                        !!activeAssociate ||
                        !!loading ||
                        isSubmitting ||
                        loadingValidate
                          ? "0.3"
                          : "",
                    }}
                    disabled={
                      !!activeAssociate ||
                      isSubmitting ||
                      !!loading ||
                      loadingValidate
                    }
                    onClick={() => {
                      setResetFunction(() => handleReset);
                      handleCleanForm();
                    }}
                  >
                    <img src={clearLogo} alt="Icon clear" />
                  </Button>
                </Grid>
              </Grid>

              <MyDialog
                id="confirmationDialog"
                data-testid="confirmationDialog"
                open={openDialog}
                title={t("dialog.confirmationWindow")}
                message={t(message)}
                handleAccept={handleAccept}
                handleClose={handleCloseDialog}
                isLoading={loadingValidate || !!loading}
              />
            </Form>
          )}
        </Formik>

        <MyAlert
          open={openAlert}
          message={messageAlert ? messageAlert : ""}
          typeAlert={typeAlert}
          handleClose={onCloseAlert}
        />
      </div>
    </div>
  );
};
