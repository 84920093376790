/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikHelpers } from "formik";
import { useRef, useState, ChangeEvent, KeyboardEvent } from "react";
import * as yup from "yup";
import { MyTextField } from "../custom/MyTextField";
import clearLogo from "../../../assets/svg/icon-park_clear-format.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { excelToJson } from "../../../helpers/convertFileToJson";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { MyDialog } from "../custom/MyDialog";
import { regLettersNumbers } from "../../../utils/utils";
import { startCreatePlayersSettings } from "../../../redux/actions/managementUsersActions";
import {
  IDataUserStatus,
  ISettingsUserStatus,
} from "../../../interfaces/players";

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  inputAdorned: {
    marginRight: "30px",
  },
  infoIcon: {
    color: "#02D0E7",
    marginRight: theme.spacing(1.125),
    fontSize: 17,
  },
  title: {
    fontWeight: "bold",
    color: "black",
  },
  titleBox: {
    display: "flex",
    borderBottom: `1px solid #02D0E7`,
    paddingBottom: theme.spacing(0.25),
  },
  formControl: {
    width: "100%",
  },
  errorLabel: {
    marginLeft: theme.spacing(0.75),
    color: "#d32f2f",
  },
}));

const Container = styled("div")({
  display: "flex",
});

const CustomButton = styled(Button)({
  backgroundColor: "#007E8C",
  color: "white",
  height: "100%",
  width: "150px",
  "&:hover": {
    backgroundColor: "#007E8C",
    opacity: 1,
  },
});
export const FormManagementStatusUsers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState<any>(null);
  const { token } = useSelector((state: AppState) => state.auth);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const formikRef = useRef<any>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const initialValues = {
    stateUser: "",
    observations: "",
  };

  const validationSchema = yup.object({
    stateUser: yup.number().required(t("form.requiredFile")),
  });

  const handleShowConfirmationWindow = (message: string) => {
    setOpenConfirmation(true);
    setMessage(message);
  };

  const stateOptions = [
    { label: t("exclusions.stateOptions.active"), value: 1 },
    { label: t("exclusions.stateOptions.inactive"), value: 2 },
    { label: t("exclusions.stateOptions.retained"), value: 3 },
    { label: t("exclusions.stateOptions.pendingValidation"), value: 4 },
    { label: t("exclusions.stateOptions.closed"), value: 5 },
    { label: t("exclusions.stateOptions.selfExcluded"), value: 6 },
  ];

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files && event.target.files[0];
    setSelectedFile(file ?? null);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      (fileInputRef.current as any).click();
    }
  };

  const handleDownload = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();

    const fileName = "PlantillaUsersStatus.csv";

    const publicURL = process.env.PUBLIC_URL;
    const url = `${publicURL}/${fileName}`;

    window.location.href = url;
  };

  const isEmptyValues = (formValues: any) => {
    let emptyValues = false;

    Object.entries(formValues).forEach(([key, value]) => {
      if (value === "") {
        emptyValues = true;
      }
    });

    return emptyValues;
  };

  const handleCleanForm = (resetForm: any) => {
    resetForm();
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const callback = (totalParse: number, totalLines: number) => {
    // Callback function to track progress
    console.log(`Progreso: ${totalParse}/${totalLines}`);
  };

  const handleSave = async (
    formValues: ISettingsUserStatus,
    resetForm: FormikHelpers<any>["resetForm"]
  ) => {
    if (!selectedFile || !token) return;
    setSubmitting(true);
    try {
      const json = await excelToJson(selectedFile, callback);
      const dataPlayers = JSON.parse(json);
      const combinedData: IDataUserStatus = {
        dataPlayers,
        settings: formValues,
      };
      await dispatch(startCreatePlayersSettings(combinedData));
    } catch (error) {
      dispatch(setMessageAlert("managementUsers.errorAddUsersStatusSetting"));
      dispatch(setTypeAlert("warning"));
      dispatch(setOpenAlert());
    } finally {
      setSubmitting(false);
      setOpenConfirmation(false);
      setSelectedFile(null);
      resetForm();
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
  };

  const classes = useStyles();

  return (
    <Grid container spacing={2} mt={1} pl={1} pr={1}>
      <Grid item xs={12} className="mb-4">
        <Box className={classes.titleBox}>
          <i className={`fa-solid fa-circle-info ${classes.infoIcon}`}></i>
          <Typography variant="body1" className={classes.title}>
            {t("managementUsers.userStatusSettings")}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          onSubmit={async (data) => {
            setFormValues(data);
            handleShowConfirmationWindow(
              t("managementUsers.sureCreatedSetting")
            );
          }}
        >
          {({ handleChange, values, isValid, resetForm }) => (
            <Form>
              <Grid container spacing={2}>
                {/* stateUser */}
                <Grid item xs={3}>
                  <MyTextField
                    name="stateUser"
                    variant="outlined"
                    size="small"
                    fullWidth
                    select
                    label={`${t("account.userStatus")}*`}
                    onChange={handleChange}
                    value={values.stateUser || ""}
                  >
                    {stateOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </MyTextField>
                </Grid>
                {/* UPLOAD FILE */}
                <Grid item xs={6}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Container>
                      <TextField
                        label={`${t("managementBonds.uploadFilePlayers")}*`}
                        disabled
                        size="small"
                        fullWidth
                        value={selectedFile ? selectedFile.name : ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <input
                        type="file"
                        accept=".csv"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                      <label style={{ margin: 0 }}>
                        <CustomButton
                          style={{
                            border: "none",
                            outline: "none",
                            boxShadow: "none",
                          }}
                          onClick={handleButtonClick}
                        >
                          {t("managementBonds.examine")}
                        </CustomButton>
                      </label>
                    </Container>
                    <Typography>
                      <a href="#" onClick={handleDownload}>
                        {t("managementBonds.dowloadTemplate")}
                      </a>
                    </Typography>
                  </div>
                </Grid>
                {/* observations */}
                <Grid item xs={12}>
                  <MyTextField
                    name="observations"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="text"
                    label={`${t("tabs.observations")}`}
                    multiline
                    rows={6}
                    onChange={handleChange}
                    inputProps={{
                      maxLength: 300,
                    }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regLettersNumbers(e.key) && e.preventDefault()
                    }
                  />
                </Grid>
                {/* BUTTONS */}
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  sx={{ height: "60px" }}
                  mt={3}
                  mb={3}
                >
                  <Button
                    type="submit"
                    style={{ border: "none", outline: "none" }}
                    variant="contained"
                    id="btnSave"
                    disabled={
                      isSubmitting ||
                      isEmptyValues(values) ||
                      !isValid ||
                      !selectedFile
                    }
                    className="footer-btn primary-btn ripple"
                  >
                    {isSubmitting ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      <>
                        <i className="fa-solid fa-check"></i>
                        {t("button.save")}
                      </>
                    )}
                  </Button>
                  {!isSubmitting && (
                    <Button
                      type="button"
                      style={{
                        border: "none",
                        outline: "none",
                        marginLeft: "4px",
                      }}
                      onClick={() => handleCleanForm(resetForm)}
                    >
                      <img src={clearLogo} alt="Icon clear" />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <MyDialog
          open={openConfirmation}
          title={t("dialog.confirmationWindow")}
          message={message}
          handleAccept={() =>
            message === t("dialog.sureCancel")
              ? handleCancel()
              : handleSave(formValues, formikRef.current?.resetForm)
          }
          handleClose={() => setOpenConfirmation(false)}
          isLoading={isSubmitting}
        />
      </Grid>
    </Grid>
  );
};
