import {
  IConfigPointSale
} from "./../../interfaces/pointsSale";
import {
  IPointSaleForm,
  IPointsOfSale,
  IQueryFilterPointsOfSale,
} from "../../interfaces/pointsSale";
import {
  addPointSaleService,
  deletePointSaleService,
  editPointSaleService,
  getPointsSaleFilterService,
  getPointsSaleService,
} from "../../services/pointsSaleService";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import {
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";
import { arraySearch, setChangeData } from "./searchActions";
import { Roles_Enum, State } from "../../enums/enums";
import { socket } from "../../helpers/socket";

/* ACTION TRAER PUNTOS DE VENTA */
export const setGetPointsSale = (
  token: string,
  page: number,
  showLoading?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { user } = (getState() as AppState).auth;
    const isAssociate = user?.roles.name === Roles_Enum.ASSOCIATE;

    try {
      showLoading && dispatch(setLoadingState({ loading: true }));

      const operatorId = user && user.operator ? user.operator.id : undefined;
      const associateId =
        isAssociate && user && user.associatesUsers.length > 0
          ? user.associatesUsers[0].associate.id
          : undefined;

      const response =
        user &&
        (await getPointsSaleService(
          token,
          page,
          rowsPerPage,
          operatorId,
          associateId
        ));

      if (response && !response.error) {
        const data: IPointsOfSale = response;
        dispatch(setPointsSaleRedux(data));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorSetPointsSale"));
        dispatch(setTypeAlert("error"));
      }
    } catch (error) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.errorSetPointsSale"));
      dispatch(setTypeAlert("error"));
    } finally {
      showLoading && dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const setPointsSaleRedux = (pointSale: IPointsOfSale) => ({
  type: Types.setPointsSale,
  payload: pointSale,
});

/* ACTIONS PUNTOS DE VENTA FILTROS */
export const getPoinstSaleFilter = (
  formValues: IQueryFilterPointsOfSale | any,
  page?: number | null,
  cleanFilter?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    const isAssociate = user?.roles.name === Roles_Enum.ASSOCIATE;

    const operatorId =
      user?.roles.name === Roles_Enum.SUPER_ADMIN ? null : user?.operator.id;
    const associateId =
      isAssociate && user && user.associatesUsers.length > 0
        ? user.associatesUsers[0].associate.id
        : undefined;

    if (formValues.page || page) {
      formValues.perPage = rowsPerPage.toString();
    }
    try {
      if (token) {
        let finalValues: any = {};
        Object.entries(formValues).forEach(([key, value], index) => {
          //@ts-ignore
          if (value.length > 0) {
            //@ts-ignore
            finalValues[key] = value;
          }
        });

        dispatch(setLoadingState({ loading: true }));
        const response = await getPointsSaleFilterService(
          token,
          finalValues,
          false,
          page,
          rowsPerPage,
          operatorId,
          associateId
        );

        if (response && !response.error) {
          if (cleanFilter) {
            dispatch(arraySearch(null));
            dispatch(setPointsSaleRedux(response));
          } else {
            dispatch(arraySearch(response));
          }
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetPointsSale"));
          dispatch(setTypeAlert("error"));
        }

        dispatch(setLoadingState({ loading: false }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

/* ACTION AGREGAR UN NUEVO PUNTO DE VENTA */
export const startAddPointSale = (data: IConfigPointSale) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const copyData = { ...data };
    copyData.createdUser = user
      ? { id: user.id, nameComplete: user.nameComplete }
      : { id: 0, nameComplete: "" };
    copyData.maximumTransfers = Number(data.maximumTransfers);
    copyData.minimumTransfers = Number(data.minimumTransfers);
    //@ts-ignore
    copyData.allowAnonymousPayouts =
      (copyData.allowAnonymousPayouts as any) === ""
        ? State.INACTIVE
        : copyData.allowAnonymousPayouts;
    //@ts-ignore
    copyData.allowAnonymousSale =
      (copyData.allowAnonymousSale as any) === ""
        ? State.INACTIVE
        : copyData.allowAnonymousSale;

    try {
      dispatch(setLoadingState({ loading: true }));
      const response = token && (await addPointSaleService(copyData, token));
      
      if (response && response.success === true) {
        dispatch(arraySearch(null));
        dispatch(setMessageAlert("pointsOfSale.successCreatePointSale"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
      } else {
        if (response.error) {
          dispatch(setMessageAlert(response.error));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
          dispatch(setLoadingState({ loading: false }));
        } else {
          dispatch(setMessageAlert("alerts.createPointSaleError"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
          dispatch(setLoadingState({ loading: false }));
        }
      }
    } catch (error) {
      dispatch(setLoadingState({ loading: false }));
      throw new Error(error as any);
    }
  };
};
export const addPointSaleRedux = (pointSale: IConfigPointSale) => ({
  type: Types.addPointSale,
  payload: pointSale,
});

/* ACTION EDITAR UN NUEVO PUNTO DE VENTA */
export const startEditPointSale = (data: IPointSaleForm, id: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { activePointSale } = (getState() as AppState).pointsSale;
    const copyData = { ...data };

    copyData.createdUser = data.createdUser || user || { id: 0, nameComplete: "" };
    copyData.maximumTransfers = Number(data.maximumTransfers);
    copyData.minimumTransfers = Number(data.minimumTransfers);

    const { associates, configCommissioners, configGames, ...rest } = copyData;

    const parseAssociates = associates.map((associate) => {
      const existingAssociate = activePointSale?.associates.find((el) => el.associate?.id === associate.id);
      return existingAssociate
        ? { id: existingAssociate.id, associate: { id: associate.id, name: associate.name } }
        : { associate: { id: associate.id, name: associate.name } };
    });

    const parseCommissioners = configCommissioners.map((commissioner) => {
      const existingCommissioner = activePointSale?.configCommissioners.find((el) => el.commissional.id === commissioner.id);
      return existingCommissioner
        ? { id: existingCommissioner.id, percentage: commissioner.percentage, commissional: { ...commissioner } }
        : { percentage: commissioner.percentage, commissional: { ...commissioner } };
    });

    const parseGames = configGames.map((game) => {
      const existingGame = activePointSale?.configGames.find((el) => el.game.id === game.id);
      return existingGame
        ? { id: existingGame.id, game: { ...game } }
        : { game: { ...game } };
    });

    const newObj = {
      ...rest,
      associates: parseAssociates,
      configCommissioners: parseCommissioners,
      configGames: parseGames,
    };

    try {
      if (token) {
        const response = await editPointSaleService(newObj, token, id);

        if (response) {
          dispatch(arraySearch(null));
          if(response[6] && response[6].length) {
            response[6].forEach((playerId: any) => {
            socket.emit("Admin/ClosePlayerSession", { id: playerId, inactivate: false });
            });
          }
          await dispatch(setGetPointsSale(token, 1, false));
          dispatch(setMessageAlert("pointsOfSale.updatedPointSale"));
          dispatch(setTypeAlert("success"));
          dispatch(setOpenAlert());
        } else {
          dispatch(setMessageAlert("alerts.editPointSaleError"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
          dispatch(setLoadingState({ loading: false }));
        }
      }
    } catch (error) {
      dispatch(setLoadingState({ loading: false }));
      throw new Error(error as any);
    }
  };
};

export const editPointSaleRedux = (pointSale: IPointSaleForm) => ({
  type: Types.updatePointSale,
  payload: pointSale,
});

export const setActivePointSale = (pointSale: IPointSaleForm | null) => ({
  type: Types.setActivePointSale,
  payload: pointSale,
});

export const startDeletePointSale = (id: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { data } = (getState() as AppState).search;
    const { pointsSale } = (getState() as AppState).pointsSale;

    if (!token) {
      throw new Error("invalid token");
    }
    const response = await deletePointSaleService(id, token);
    if (!response.error) {
      data && dispatch(setChangeData(true));
      const newData = data ? { ...data } : pointsSale && { ...pointsSale };
      newData.data = newData.data.filter((el: any) => el.id !== id);
      const dataSearchFilter = {
        data: newData.data,
        total: newData.total - 1,
        page: newData.page,
        last_page: newData.last_page,
      };

      dispatch(
        data
          ? arraySearch(dataSearchFilter)
          : setPointsSaleRedux(dataSearchFilter)
      );

      dispatch(setMessageAlert("pointsOfSale.pointSaleDeleted"));
      dispatch(setTypeAlert("success"));
    } else {
      dispatch(setMessageAlert("pointsOfSale.errorDeletingPointSale"));
      dispatch(setTypeAlert("error"));
    }
    dispatch(setOpenAlert());
    dispatch(setActivePointSale(null));
  };
};

export const editStatePointSale = (
  dataPointSale: any,
  isFilterByState?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    let { data } = (getState() as AppState).search;
    let { pointsSale } = (getState() as AppState).pointsSale;

    try {
      const response =
        token &&
        (await editPointSaleService(dataPointSale, token, dataPointSale.id));

      if (response && !response.error) {
        data && dispatch(setChangeData(true));
        const newData = data ? { ...data } : pointsSale && { ...pointsSale };

        newData.data =
          data && isFilterByState
            ? newData.data.filter((el: any) => el.id !== dataPointSale.id)
            : newData.data.map((el: any) =>
                el.id === dataPointSale.id
                  ? { ...el, stateSalesPoint: dataPointSale.stateSalesPoint }
                  : el
              );

        newData.total =
          data && isFilterByState ? data.total - 1 : newData.total;
        newData.last_page =
          data && isFilterByState
            ? Math.ceil((data.total - 1) / rowsPerPage)
            : newData.last_page;

        dispatch(data ? arraySearch(newData) : setPointsSaleRedux(newData));
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("pointsOfSale.updatedPointSale"));
        dispatch(setTypeAlert("success"));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.editPointSaleError"));
        dispatch(setTypeAlert("error"));
      }
    } catch (error) {
      alert(error);
    }
  };
};
