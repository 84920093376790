import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";
import Filter from "../../../../components/Filter/Filter";
import {
  Enum_FieldType,
  IColumnsTable,
  IFilterField,
} from "../../../../interfaces/components";
import { CustomAlert } from "../../../../components/Alerts/CustomAlert";
import { TableComponent } from "../../../../components/Table/TableComponent";
import { TransferConcept } from "../../../../enums/enums";
import { asyncLoadingFunction } from "../../../../redux/actions/helperActions";
import { startFilterIrrigations } from "../../../../redux/actions/transactionsActions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";

const columns: IColumnsTable[] = [
  { id: 1, header: "irrigation.associatedID" },
  { id: 2, header: "user.createAt" },
  { id: 3, header: "irrigation.associatesName" },
  { id: 4, header: "transactions.wallet" },
  { id: 5, header: "irrigation.transactionConcept" },
  { id: 6, header: "irrigation.transferValue" },
];

export const IrrigationHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { irrigations } = useSelector((state: AppState) => state.transactions);
  const { data } = useSelector((state: AppState) => state.search);
  const { activeAssociate } = useSelector((state: AppState) => state.associates);
  const [dataFilter, setDataFilter] = useState<any>({});
  const [filter, setFilter] = useState(false);

  const filterFields: IFilterField[] = [
    {
      label: t("user.createAt"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("irrigation.transferConcept"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("companyManagement.cash"),
          value: TransferConcept.CASH,
        },
        {
          label: t("irrigation.bankTransfer"),
          value: TransferConcept.BANK_TRANSFER,
        },
      ],
    },
    {
      label: t("form.value"),
      type: Enum_FieldType.TEXT,
    },
  ];

  const handleFilter = (formValues: any) => {
    if(activeAssociate){
      setFilter(true);
      const params = {
        createAt: formValues[t("user.createAt")],
        transferConcept: formValues[t("irrigation.transferConcept")].toString(),
        value: formValues[t("form.value")],
        page: 1,
      };
      dispatch(
        asyncLoadingFunction(
          async () => await dispatch(startFilterIrrigations(params))
        )
      );
      setDataFilter(params);

    }
  };

  const onCleanFilter = async () => {
    if(activeAssociate){

      await dispatch(
        asyncLoadingFunction(
          async () => await dispatch(startFilterIrrigations({ page: 1 }, true))
        )
      );
      setFilter(false);
    }
  };

  return (
    <Box className="filter" p={2} sx={{ minHeight: "300px" }}>
      <Box sx={{ border: "1px solid #DADADA", borderRadius: "5px" }} p={1}>
        <Box my={1}>
          <Filter
            fields={filterFields}
            setDataFilter={setDataFilter}
            component="irrigation"
            handleFilter={handleFilter}
            onClean={onCleanFilter}
          />
        </Box>

        {!irrigations && !filter && !data && (
          <CustomAlert htmlText={t("irrigation.filterText")} type="info" />
        )}
      </Box>
      <Box mt={2}>
        {data ? (
          <>
            {data.data.length > 0 && (
              <TableComponent
                columns={columns}
                data={data.data}
                pages={data.last_page}
                component={"irrigation"}
                totalData={data}
                subMenu={false}
                isSortingTable={true}
                dataFilter={dataFilter}
              />
            )}
          </>
        ) : (
          <>
            {irrigations && irrigations.data.length > 0 && (
              <TableComponent
                columns={columns}
                data={irrigations.data}
                pages={irrigations.last_page}
                component={"irrigation"}
                totalData={irrigations}
                subMenu={false}
                isSortingTable={true}
                dataFilter={dataFilter}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
