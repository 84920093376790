import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { CustomSearch } from "../../../components/Dashboard/CustomSearch";
import { NotificationsList } from "../../../components/Dashboard/NotificationsList";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Loading } from "../../../components/Loading/Loading";
import { TableComponent } from "../../../components/Table/TableComponent";
import {
  IColumnsTable,
  Icons,
  Enum_FieldType,
  IFilterField,
} from "../../../interfaces/components";
import Filter from "../../../components/Filter/Filter";
import { startGetDocumentsFiles } from "../../../redux/actions/managementFilesActions";

const ManagementFiles = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading } = useSelector((state: AppState) => state.helper);
  const { data } = useSelector((state: AppState) => state.search);
  const [dataFilter, setDataFilter] = useState<any>({});

  useEffect(() => {
    dispatch(startGetDocumentsFiles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * Datos inputs para filtro
   */
  const FilterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
  ];
  /**
   * Soporta las Columnas para renderizar en el header de la tabla
   */

  const columns: IColumnsTable[] = [
    { id: 1, header: "filters.id" },
    { id: 2, header: "table.actions" },
  ];
  const iconos: Icons = {
    see: true,
    edit: false,
    delete: false,
    seeMore: false,
    add: false,
  };
  return (
    <div className="content w-100">
      <Row>
        <div className="contentBox">
          <Grid item xs={12} marginBottom={3}>
            <i className="fa fa-upload fa-lg pr-3 iconUser" />
            <span className="titleUser">{t("fileUpload.managementFiles")}</span>
          </Grid>

          <div className="white-container">
            <Grid item xs={12} mt={3}>
              <div className="input-container mb-3">
                <Filter fields={FilterFields} component={"files"} setDataFilter={setDataFilter} />
              </div>
            </Grid>
            <TableComponent
              columns={columns}
              data={data ? data.data : []}
              pages={data ? data.last_page : 1}
              titleButton={t("button.createMenu")}
              icons={iconos}
              component={"files"}
              totalData={data ? data : 0}
              isSortingTable={false}
              dataFilter={dataFilter}
            />
          </div>
        </div>
        <div className="notifications">
          <Col xs="12">
            <CustomSearch onChange={() => {}} />
          </Col>
          <Col xs="12">
            <NotificationsList />
          </Col>
        </div>
      </Row>
      {loading && <Loading />}
    </div>
  );
};

export default ManagementFiles;
