import React, { FC } from "react";
import { TableCell } from "@mui/material";
import moment from "moment";
import { ITransaction } from "../../../interfaces/transactionSettings";
import { getStateSettingName } from "../../../utils/utils";
interface props {
  row: ITransaction;
}

export const RowDataTableTransactions: FC<props> = ({ row }) => {
  return (
    <>
      <TableCell align="center" className="word-break">
        {row.id}
      </TableCell>
      <TableCell align="center" className="word-break">
        {getStateSettingName(row.state)}
      </TableCell>
      <TableCell align="center" className="word-break">
        {row.player.id}
      </TableCell>
      <TableCell align="center" className="word-break">
        {row.movementType.name}
      </TableCell>
      <TableCell align="center" className="word-break">
        {row.type.name}
      </TableCell>
      <TableCell align="center" className="word-break">
        {row.wallet.name}
      </TableCell>
      <TableCell align="center" className="word-break">
        {row.value}
      </TableCell>
      <TableCell align="center" className="word-break">
        {moment(row.createAt).format("DD-MM-YYYY hh:mm:ss a")}
      </TableCell>
    </>
  );
};
