import { FC } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";

import clearLogo from "../../../assets/svg/icon-park_clear-format.svg";

interface Props {
  handlePrevious?: () => void;
  handleAccept: () => void;
  handleCancel: () => void;
  handleClean: () => void;
  titleAccept?: string;
  isLoading?: boolean;
  disabled?: boolean;
}
export const MyActions: FC<Props> = ({
  handlePrevious,
  handleAccept,
  handleCancel,
  handleClean,
  titleAccept,
  isLoading,
  disabled,
}) => {
  const { t } = useTranslation();
  const { activeStep } = useSelector((state: AppState) => state.helper);
  return (
    <Box className="filter mt-3 center-content">
      {activeStep > 0 && (
        <Button
          style={{ border: "none", outline: "none" }}
          variant="contained"
          className="footer-btn primary-btn ripple"
          onClick={handlePrevious}
          disabled={isLoading}
        >
          <i className="fa-solid fa-angle-left "></i>
          {t("button.previous").toUpperCase()}
        </Button>
      )}
      <Button
        style={{ border: "none", outline: "none" }}
        variant="contained"
        className="footer-btn danger-btn ripple"
        onClick={handleCancel}
        disabled={isLoading}
      >
        <i className="fa-solid fa-x"></i>
        {t("button.cancel").toUpperCase()}
      </Button>
      <Button
        style={{
          border: "none",
          outline: "none",
          display: "flex",
          justifyContent: "space-between",
        }}
        variant="contained"
        className="footer-btn primary-btn ripple"
        onClick={handleAccept}
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress size={"1.6rem"} color="inherit" />
        ) : (
          <>
            {t(titleAccept ? `${titleAccept}` : "button.save").toUpperCase()}
            <i className="fa-solid fa-angle-right ml-2"></i>
          </>
        )}
      </Button>
      <Button
        style={{
          border: "none",
          outline: "none",
          marginLeft: "4px",
          opacity: disabled ? "0.3" : "",
        }}
        onClick={handleClean}
        disabled={disabled}
      >
        <img src={clearLogo} alt="Icon clear" />
      </Button>
    </Box>
  );
};
