export const getProviders = (token: string, params: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}providers?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
