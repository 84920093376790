import { TableCell } from "@mui/material";
import moment from "moment";
import React, { FC } from "react";
interface IRow {
  row: any;
}

export const RowNotificationChanges: FC<IRow> = ({ row }) => {
  const { playerAffected, operatorAffected } = row;
  return (
    <>
      <TableCell className="word-break">{row.event}</TableCell>
      <TableCell className="word-break">{row.createdBy.nameComplete}</TableCell>
      <TableCell className="word-break">
        {playerAffected ? playerAffected.username : operatorAffected.name}
      </TableCell>
      <TableCell className="word-break">
        {moment(row.createAt).format("DD/MM/YYYY HH:mm:ss")}
      </TableCell>
    </>
  );
};
