import { useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";
import { getCopyArrayOrObject } from "../../utils/utils";
import { headersNotificationChanges } from "../../helpers/headersTables";
import moment from "moment";

export const NotificationChangesDataTable = (totalData: any) => {
  const { data } = useSelector((state: AppState) => state.search);
  //   COLUMNS HEADERS STYLES
  const headersCvsStyle = headersNotificationChanges.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  // Obtener datos por vista
  const dataActivityPlayer =
    data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalActivityPlayer = totalData
    ? getCopyArrayOrObject(totalData)
    : [];
  //   DATA TOTAL
  const multiTotalDataPlayerActivity = [
    {
      columns: headersCvsStyle,
      data: dataTotalActivityPlayer.map((data: any) => {
        let array;
        array = [
          {
            value: data.event,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.createdBy.nameComplete,
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: data.playerAffected.username ? data.playerAffected.username : '',
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.createAt).format("DD/MM/YYYY HH:mm:ss"),
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];
  const multiDataPlayerActivity = [
    {
      columns: headersCvsStyle,
      data: dataActivityPlayer.map((data: any) => {
        let array;
        array = [
          {
            value: data.event,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.createdBy.nameComplete,
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: data.playerAffected.username ? data.playerAffected.username : '',
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.createAt).format("DD/MM/YYYY HH:mm:ss"),
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];
  const finalData = {
    dataPerView: dataActivityPlayer,
    dataTotal: dataTotalActivityPlayer,
    headersCSV: headersNotificationChanges,
    multiDataSet: multiDataPlayerActivity,
    multiTotalDataSet: multiTotalDataPlayerActivity,
  };
  return finalData;
};
