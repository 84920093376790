import React, { useEffect, useState } from "react";
import Filter from "../../../../components/Filter/Filter";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { arraySearch } from "../../../../redux/actions/searchActions";
import MyCustomTable, {
  IColumns,
} from "../../../../components/Custom/Table/MyCustomTable";
import { CustomAlert } from "../../../../components/Alerts/CustomAlert";
import { startGetExternalDeposit } from "../../../../redux/actions/depositsPlayersActions";
import { RowDepositQR } from "./RowDepositQR";

export const TableComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data } = useSelector((state: AppState) => state.search);
  const { user } = useSelector((state: AppState) => state.auth);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterFields, setFilterFields] = useState<any>([]);
  const [filterParams, setFilterParams] = useState<any>({});

  useEffect(() => {
    const filterFieldsFunction = () => {
      const filterFields: IFilterField[] = [
        {
          label: t("filters.startDate"),
          type: Enum_FieldType.DATE,
        },
        {
          label: t("filters.endDate"),
          type: Enum_FieldType.DATE,
        },
        {
          label: t("filters.documentNumber"),
          type: Enum_FieldType.TEXT,
        },
      ];
      setFilterFields(filterFields);
    };

    filterFieldsFunction();
  }, [t]);

  const handleFilter = async (formValues: any, page: number = 1) => {
    const startDate = formValues[t("filters.startDate")];
    const endDate = formValues[t("filters.endDate")];
    const documentNumber = formValues[t("filters.documentNumber")];
    const params = {
      startDate,
      endDate,
      documentNumber,
      page,
      userId: user?.id,
    };
    setFilterParams(params);
    await dispatch(startGetExternalDeposit(params));
  };

  const onCleanFilter = async () => {
    dispatch(arraySearch(null));
    setFilterParams({});
  };

  const onPageChange = (newPage: any) => {
    setCurrentPage(newPage);
    handleFilter(filterParams, newPage);
  };

  // TABLE
  const columns: IColumns[] = [
    {
      align: "center",
      label: "ID",
    },
    {
      align: "center",
      label: "Nombre del jugador",
    },
    {
      align: "center",
      label: "Número del documento",
    },
    {
      align: "center",
      label: "Fecha de solicitud",
    },
    {
      align: "center",
      label: "Monto",
    },
    {
      align: "center",
      label: "Acciones",
    },
  ];

  return (
    <>
      <div
        className="filter"
        style={{ padding: "18px 0px 0px", marginBottom: "18px" }}
      >
        {filterFields.length > 0 && (
          <Filter
            fields={filterFields}
            handleFilter={handleFilter}
            onClean={onCleanFilter}
          />
        )}
      </div>
      {data ? (
        <div className="filter">
          <MyCustomTable
            columns={columns}
            data={data.data}
            lastPage={data.last_page}
            totalData={data.total}
            renderBody={(item) => <RowDepositQR row={item} />}
            onPageChange={onPageChange}
            // @ts-ignore
            currentPage={currentPage}
          />
        </div>
      ) : (
        <CustomAlert htmlText={t("reports.externalQRDisclaimer")} type="info" />
      )}
    </>
  );
};
