import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { TabPanel } from "../../../../components/Tabs";
import { CreateBonusTab } from "./CreateBonusTab";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setBonus } from "../../../../redux/actions/bonusActions";
import { AutomaticBonus } from "./AutomaticBonus";
import { MassiveBonus } from "../../../components/managementBonus/masiveBonus/MassiveBonus";
import { FreeTicketsBonus } from "./FreeTicketsBonus";
import { CasinoBonusTab } from "./CasinoBonusTab";
import casinoIcon from "../../../../assets/svg/icon_casino.svg";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const styles = {
  "&.Mui-selected": {
    color: "#02D0E7",
    border: "none",
    outline: "none",
    fontWeight: "bold",
  },
};

export const BonusTabs = () => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setBonus(null));
    setValue(newValue);
  };
  return (
    <>
      <Tabs
        sx={{
          marginLeft: "20px",
        }}
        value={value}
        onChange={handleChange}
        style={{ border: "none", outline: "none" }}
        variant="standard"
        TabIndicatorProps={{
          style: {
            backgroundColor: " #02D0E7",
            height: 3,
          },
        }}
      >
        <Tab
          sx={styles}
          label={
            <span className="spanTab">
              <i className="fa-sharp fa-solid fa-pen mr-1"></i>
              {t("managementBonds.createBonds")}
            </span>
          }
          {...a11yProps(0)}
        />
        <Tab
          sx={styles}
          label={
            <span className="spanTab">
              <i className="fa-sharp fa-solid fa-users mr-1"></i>
              {t("managementBonds.createMasiveBonus")}
            </span>
          }
          {...a11yProps(1)}
        />
        <Tab
          sx={styles}
          label={
            <span className="spanTab">
              <i className="fa-sharp fa-solid fa-rotate mr-1"></i>
              {t("managementBonds.automaticBonuses")}
            </span>
          }
          {...a11yProps(2)}
        />
        <Tab
          sx={styles}
          label={
            <span className="spanTab">
              <i className="fa-sharp fa-solid fa-pen mr-1"></i>
              {t("managementBonds.freeTicketsBonus")}
            </span>
          }
          {...a11yProps(3)}
        />
        <Tab
          sx={styles}
          label={
            <span className="spanTab">
              <img
                loading="lazy"
                style={{
                  width: "20px",
                  height: "20px",
                }}
                src={casinoIcon}
                alt=""
              />
              {t("sidebar.casino")}
            </span>
          }
          {...a11yProps(4)}
        />
      </Tabs>
      <div className="white-container">
        <TabPanel value={value} index={0}>
          {/* NORMAL BONMUS */}
          <CreateBonusTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          {/* MASIVE BONUS */}
          <MassiveBonus />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {/* AUTOMATIC BONUS */}
          <AutomaticBonus />
        </TabPanel>
        <TabPanel value={value} index={3}>
          {/* FREE TICKETS BONUS */}
          <FreeTicketsBonus />
        </TabPanel>
        <TabPanel value={value} index={4}>
          {/* BONOS NUEVOS */}
          <CasinoBonusTab />
        </TabPanel>
      </div>
    </>
  );
};
