import React, { FC } from "react";
import { TableCell, Link } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  setActiveBonusGroup,
  setOpenBonusDetails,
} from "../../../../redux/actions/bonusActions";

interface props {
  row: any;
}

export const RowAutomaticBonus: FC<props> = ({ row }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleOpenDeliveredDetails = () => {
    dispatch(setActiveBonusGroup(row));
    dispatch(setOpenBonusDetails(true));
  };
  return (
    <>
      <TableCell align="center">{row.name}</TableCell>
      <TableCell align="center">{row.description}</TableCell>
      <TableCell align="center">
        <Link
          component={"button"}
          variant="body1"
          onClick={handleOpenDeliveredDetails}
          style={{ border: "none", outline: "none", boxShadow: "none" }}
        >
          {`${t("managementBonds.seeDetails")}`}
        </Link>
      </TableCell>
    </>
  );
};
