import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { AppState } from "../../../../redux/reducers/rootReducer";
import { TabsConfirmation } from "./TabsConfirmation";
import { useTranslation } from "react-i18next";
import { getCopyArrayOrObject } from "../../../../utils/utils";

interface Props {
  openModal: boolean;
  handleClose: () => void;
}

export const DialogTabsConfirmation = ({ openModal, handleClose }: Props) => {
  const { t } = useTranslation();
  const { activeBrand } = useSelector(
    (state: AppState) => state.managementCompanies
  );

  const activeBrandCopy = activeBrand && getCopyArrayOrObject(activeBrand);

  const brandCountries: any[] = activeBrand
    ? getCopyArrayOrObject(activeBrand.countries)
    : [];

  brandCountries.forEach((country, index) => {
    const {
      countriesCurrencies,
      countriesLanguages,
      countriesPayments,
      countriesGames,
      countriesSolutions,
    } = country;
    const parseCurrencies: any[] = [];
    const parseLanguages: any[] = [];
    const parseGames: any[] = [];
    const parseSolutions: any[] = [];
    const parsePaymentMethodRecharges: any[] = [];
    const parsePaymentMethodWithDrawal: any[] = [];

    countriesCurrencies?.forEach((currency: { currency: any }) => {
      parseCurrencies.push(currency.currency);
    });
    countriesLanguages?.forEach((language: { language: any }) => {
      parseLanguages.push(language.language);
    });
    countriesGames?.forEach((game: any) => {
      parseGames.push(game.paramGame.game);
    });
    countriesSolutions?.forEach((solution: any) => {
      parseSolutions.push(solution.paramSolution.solution);
    });
    countriesPayments?.forEach((country: any) => {
      const {
        paymentMethod: { type },
      } = country.paramMethod;
      if (type === "withdrawal") {
        parsePaymentMethodWithDrawal.push({
          ...country.paramMethod.paymentMethod,
          state: country.state,
        });
      } else {
        parsePaymentMethodRecharges.push({
          ...country.paramMethod.paymentMethod,
          state: country.state,
        });
      }
    });

    brandCountries[index].countriesCurrencies = parseCurrencies;
    brandCountries[index].countriesLanguages = parseLanguages;
    brandCountries[index].countriesGames = parseGames;
    brandCountries[index].countriesSolutions = parseSolutions;
    brandCountries[index].paymentMethodsWithdrawal =
      parsePaymentMethodWithDrawal;
    brandCountries[index].paymentMethodsRecharges = parsePaymentMethodRecharges;
  });
  activeBrandCopy && (activeBrandCopy.countries = brandCountries);

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      maxWidth="md"
      disableEscapeKeyDown
      scroll="body"
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 0,
        }}
      >
        <Box display="flex">
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <p className="txt-primary-md" style={{ fontWeight: 500 }}>
            {t("companyManagement.detailConfiguration")}
          </p>
        </Box>
        <IconButton
          disableFocusRipple
          disableRipple
          className="hover-close"
          style={{ border: "none", outline: "none" }}
          onClick={handleClose}
        >
          <i className="fa-solid fa-xmark"></i>
        </IconButton>
      </DialogTitle>
      <Box borderTop={1} borderColor="#02D0E7" m={2}>
        <DialogContent>
          {activeBrand && (
            <TabsConfirmation formValues={activeBrandCopy} readOnly={true} />
          )}
        </DialogContent>
      </Box>
      <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          style={{
            border: "none",
            outline: "none",
            marginRight: 15,
            marginBottom: 15,
          }}
          variant="contained"
          className="footer-btn primary-btn ripple"
          onClick={handleClose}
        >
          <i className="fa-solid fa-check-circle"></i>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
