import { IAds } from "../interfaces/ads";

export const getAdsByOperator = (
  operator: string | undefined,
  token: string,
  params?: any
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const operatorParam = operator ? `operatorId=${operator}` : "";
  const queryString = params ? new URLSearchParams(params).toString() : "";
  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}ads?${operatorParam}&${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const createNewAdd = (data: IAds, token: string) => {
  const myHeaders = new Headers();
  myHeaders.append("api", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Authorization", `bearer ${token}`);
  const formData = new FormData();

  formData.append("name", data.name);
  formData.append("message", data.message);
  formData.append("startDate", data.startDate);
  formData.append("endDate", data.endDate);
  formData.append("state", data.state.toString());
  formData.append("operator", data.operator.toString());
  formData.append("createdUser", data.createuser.toString());
  data.image && formData.append("image", data.image, data.image.name);

  const requestOptions: any = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  return fetch(`${process.env.REACT_APP_API_HTTP_MARKETING}ads`, requestOptions)
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const updateAdd = (
  data: IAds,
  token: string,
  isTableAction?: boolean
) => {
  const myHeaders = new Headers();
  myHeaders.append("api", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Authorization", `bearer ${token}`);
  const formData = new FormData();

  formData.append("name", data.name);
  formData.append("message", data.message);
  formData.append("startDate", data.startDate);
  formData.append("endDate", data.endDate);
  formData.append("state", data.state.toString());
  formData.append("operator", data.operator.toString());
  formData.append("imageName", data.imageName);
  data.image &&
    !isTableAction &&
    formData.append("image", data.image, data.image.name);

  const requestOptions: any = {
    method: "PUT",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}ads/${data.id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const deleteAd = (id: number, token: string) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}ads/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const getAdsFilterService = (
  token: string,
  operatorId?: number,
  params?: any
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const queryString = params ? new URLSearchParams(params).toString() : "";
  const operatorParam = operatorId ? `operatorId=${operatorId}` : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}ads?${operatorParam}&${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
