import React, { useState, KeyboardEvent, FC } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "./managementDeposits.scss";
import * as yup from "yup";
import { Form, Formik } from "formik";
// import { regNumbers } from "../../../utils/utils";
import { ITransactionForm } from "../../../../interfaces/transactionSettings";
import {
  ExclusionCategory,
  MovementType,
  StateTransaction,
  TransactionType,
  Wallet,
} from "../../../../enums/enums";
import { useDispatch, useSelector } from "react-redux";
import { startCreateTransactionSetting } from "../../../../redux/actions/transactionsActions";
import { getExclusions } from "../../../../services/managementPlayersService";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import { MyTextField } from "../../custom/MyTextField";
import { regDecimal } from "../../../../utils/utils";

interface Props {
  player: {
    id: number;
    email: string;
    numberDocument: string;
    username: string;
    firstName: string;
    lastName: string;
    playerBalance: {
      id: string;
      amountWallet: string;
      awardsWallet: string;
      balanceWallet: string;
      bonusWallet: string;
    };
  };
  //valuePaid: number | string;
  setFlagConfirmDeposit: any;
  setCleanData: any;
  //documentType: string;
  formValues: {
    documentNumberPlayer: string;
    documentType: number;
    pointSale: number;
    value: string;
    observations: string;
  };
}

export const FormConfirmDeposit: FC<Props> = ({
  player,
  setFlagConfirmDeposit,
  setCleanData,
  formValues: { value: valuePaid, documentType, pointSale, observations },
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const [negativeChange, setNegativeChange] = useState(false);
  const [changeValue, setChangeValue] = useState(0);
  const { user, token } = useSelector((state: AppState) => state.auth);
  const [confirmPay, setConfirmPay] = useState(false);
  const initialValues = {
    valuePay: parseFloat(valuePaid as string),
    valueReceived: "",
    change: changeValue,
  };

  const validationSchema = yup.object({
    valueReceived: yup.number().required(t("form.requiredFile")),
  });

  const isEmptyValues = (formValues: any) => {
    let emptyValues = false;

    Object.entries(formValues).forEach(([key, value]) => {
      if (value === "") {
        emptyValues = true;
      }
    });

    return emptyValues;
  };

  const CreateTransfer = async (data: any) => {
    if (!user || !token) {
      throw new Error("Invalid action");
    }
    setSubmitting(true);

    // Validate self-exclusion
    const params: any = {
      playerEmail: player.email,
      category: ExclusionCategory.SELF_EXCLUSION,
      state: 1,
      currentDate: true,
    };
    const selfExclusion = token && (await getExclusions(token, params));

    if (selfExclusion && selfExclusion.data && selfExclusion.data.length > 0) {
      dispatch(setMessageAlert(t("deposits.currentSelfExclusion")));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
      return setSubmitting(false);
    }

    // Validate active-exclusion
    const paramsExclusions: any = {
      playerEmail: player.email,
      activeExclusions: true,
    };
    const activeExclusions =
      token && (await getExclusions(token, paramsExclusions));
    if (
      activeExclusions &&
      activeExclusions.data &&
      activeExclusions.data.length > 0
    ) {
      dispatch(setMessageAlert(t("deposits.currentExclusion")));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
      return setSubmitting(false);
    }

    const parseProps: ITransactionForm = {
      // idPlayer: player.id,
      emailPlayer: player.email,
      movementType: MovementType.Input,
      documentNumberPlayer: player.numberDocument,
      observations,
      state: StateTransaction.Approved,
      type: TransactionType.Recharge,
      value: data.valuePay,
      wallet: Wallet.Credits,
      operatorId: (player as any).operator.id,
      documentType,
      pointSale,
      isRefill: true,
    };

    await dispatch(startCreateTransactionSetting(parseProps, true));
    setFlagConfirmDeposit(false);
    setCleanData(true);

    setSubmitting(false);
  };

  const calculateChange = (value: any) => {
    setNegativeChange(false);
    if (value) {
      const changeCalculate =
        parseFloat(value) - parseFloat(valuePaid as string);
      setChangeValue(changeCalculate);
      setNegativeChange(changeCalculate < 0);
    } else {
      setChangeValue(0);
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mt={2}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          onSubmit={async (data) => {
            CreateTransfer(data);
          }}
        >
          {({ handleChange, values, isValid, resetForm, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={4} mt={1}>
                  <div className="divText mt-2">
                    <p className="pText"> {t("deposits.valuePaid")}</p>
                  </div>
                </Grid>
                <Grid item xs={7} mt={1}>
                  <TextField
                    name="valuePay"
                    variant="outlined"
                    disabled
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setFieldValue("valuePay", e.target.value);
                    }}
                    value={values.valuePay}
                  />
                </Grid>
                <Grid item xs={4} mt={1}>
                  <div className="divText mt-2">
                    <p className="pText"> {t("deposits.valueReceived")}</p>
                  </div>
                </Grid>
                <Grid item xs={7} mt={1}>
                  <MyTextField
                    name="valueReceived"
                    variant="outlined"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regDecimal(e.key) && e.preventDefault()
                    }
                    onChange={(e: any) => {
                      if (e.target.value.length <= 8) {
                        setFieldValue("valueReceived", e.target.value);
                        calculateChange(e.target.value);
                      }
                    }}
                    value={values.valueReceived}
                  />
                </Grid>
                <Grid item xs={4} mt={1}>
                  <div className="divText mt-2">
                    <p className="pText"> {t("deposits.change")}</p>
                  </div>
                </Grid>
                <Grid item xs={7} mt={1}>
                  <TextField
                    name="change"
                    variant="outlined"
                    disabled
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                    value={parseFloat("" + changeValue).toFixed(2)}
                  />

                  <FormHelperText>
                    {negativeChange && (
                      <label className="ml-3" style={{ color: "#d32f2f" }}>
                        {t("deposits.alertPayment")}
                      </label>
                    )}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} ml={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="confirmPay"
                        checked={confirmPay}
                        onChange={() => setConfirmPay(!confirmPay)}
                      />
                    }
                    label={`${t("deposits.confirmDeposit", {
                      value: values.valuePay,
                      username: `${player.firstName} ${player.lastName}`,
                      document: player.numberDocument,
                    })}`}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  sx={{ height: "60px" }}
                  mt={5}
                  mb={2}
                >
                  <Button
                    type="submit"
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                    variant="contained"
                    id="btnSave"
                    disabled={
                      !confirmPay ||
                      isSubmitting ||
                      isEmptyValues(values) ||
                      !isValid ||
                      negativeChange
                    }
                    className="primary-btn ripple"
                  >
                    {isSubmitting ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      <>
                        <i
                          className="fa-solid fa-check-circle"
                          style={{
                            marginRight: 9,
                            fontSize: 18,
                          }}
                        />
                        {t("bets.pay")}
                      </>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
