export const DEFAULT_ROLES = [
  "admin_manager",
  "ADMINISTRATIVE",
  "POINT_SALE",
  "CASHIER",
  "ADMIN_OPERATOR",
  "ASSOCIATE",
  "STATISTICS",
  "MARKETING",
  "MASTERS",
  "CONFIGURATION",
  "SELLER",
  "JER",
  "DEALER",
  "PROMOTOR",
];
