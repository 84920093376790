import moment from "moment";
import { FC, useEffect, useState, useRef } from "react";
import { Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "perfect-scrollbar";
import { IconButton, Grid, Box, Fab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { uiSetOpenNotifications } from "../../redux/actions/uiAction";

import { AppState } from "../../redux/reducers/rootReducer";
import { INotifications } from "../../interfaces/notifications";
import { useHistory } from "react-router-dom";
import { NotificationsTypes, WithdrawalType } from "../../enums/enums";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../redux/actions/helperActions";
import { Popover } from "@mui/material";
import x from "../../assets/svg/x-.svg";
import {
  // _updateNotification,
  markNotificationAsRead,
  softDeleteNotification,
} from "../../redux/actions/notificationsActions";
import { useLocation } from "react-router-dom";
let ps: any;
interface Props {
  isSm?: boolean;
  closeIcon?: boolean;
  searchText?: string | undefined;
}
export const NotificationsList: FC<Props> = ({
  isSm,
  closeIcon,
  searchText,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const sectionRef = useRef<HTMLDivElement>(null);

  const { user } = useSelector((state: AppState) => state.auth);
  const { notifications } = useSelector(
    (state: AppState) => state.notifications
  );
  const { openNotifications } = useSelector((state: AppState) => state.ui);
  const { routes } = useSelector((state: AppState) => state.managementMenus);

  const [notificationsData, setNotificationsData] = useState<any>();
  const [curNotification, setCurNotification] = useState<INotifications>();

  const transactionsPermissions = routes.some((menu) =>
    menu.menuItem.some((item) => item.url === "/admin/transactions/settings")
  );
  const withdrawalsPermissions = routes.some((menu) =>
    menu.menuItem.some((item) => item.url === "/admin/transactions/withdrawals")
  );

  useEffect(() => {
    if (user?.roles.notification === NotificationsTypes.SUPER_ADMIN) {
      setNotificationsData(notifications);
      return;
    }

    if (user?.roles.notification === NotificationsTypes.PORTFOLIO) {
      const filteredNotifications = notifications?.filter((notification: any) =>
        ["withdrawal", "transactionalSettings"].includes(notification.module)
      );
      setNotificationsData(filteredNotifications);
    }
  }, [notifications, user?.roles.notification]);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      //@ts-ignore
      ps = new PerfectScrollbar(".notification-wrapper", {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/admin/home") {
      if (searchText === "") {
        setNotificationsData(notifications);
      } else {
        if (notifications && searchText) {
          setNotificationsData(
            notifications.filter((noti) =>
              noti.description
                ? noti.description
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
                : false
            )
          );
        }
      }
    }
  }, [searchText, notifications, location.pathname]);

  const getDiffTime = (date: Date): string => {
    const diff = moment(date).fromNow();
    return diff.charAt(0).toUpperCase() + diff.slice(1);
  };

  const updateNotification = (noti: INotifications) => {
    if (!noti.read) {
      noti.read = true;
      // @ts-ignore
      dispatch(markNotificationAsRead(noti.id));
    }

    openNotifications && dispatch(uiSetOpenNotifications());

    if (noti.module === "withdrawal") {
      handleWithdrawalNotification(noti.description);
    } else {
      handleTransactionalNotification();
    }
  };

  const handleWithdrawalNotification = (title: string) => {
    const words = title.split(" ");
    const lastWord = words[words.length - 1].toLowerCase();

    if (withdrawalsPermissions) {
      if(lastWord === WithdrawalType.BANK_TRANSFER && !transactionsPermissions){
        return handleAlert("withdrawalsNotParameterized");
      }
      navigateToPath(
        lastWord === WithdrawalType.CASH
          ? "/admin/transactions/withdrawals"
          : "/admin/transactions/settings?option=2"
      );
    } else {
      handleAlert("withdrawalsNotParameterized");
    }
  };

  const handleTransactionalNotification = () => {
    if (transactionsPermissions) {
      navigateToPath("/admin/transactions/settings");
    } else {
      handleAlert("transactionalNotParameterized");
    }
  };
  const navigateToPath = (path: string) => {
    history.push(path);
  };

  const handleAlert = (messageKey: string) => {
    dispatch(setMessageAlert(`alerts.${messageKey}`));
    dispatch(setTypeAlert("warning"));
    dispatch(setOpenAlert());
  };
  const deleteNotification = (event: React.MouseEvent) => {
    event.stopPropagation();
    handlePopoverClose();
    setTimeout(() => {
      // @ts-ignore
      dispatch(softDeleteNotification(curNotification.id));
    }, 100);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLParagraphElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLParagraphElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <section style={{ marginTop: 31 }} ref={sectionRef}>
      <div
        className="notification-wrapper pl-3 pr-3 pt-4"
        style={{ height: closeIcon ? "85vh" : "750px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            className="mb-3"
          >
            <i
              style={{ fontSize: 18, color: "#333333", marginRight: 12 }}
              className="fa-regular fa-bell "
            ></i>
            <p className="txt-primary">{t("notifications.title")}</p>
          </span>
          {closeIcon && (
            <IconButton
              disableFocusRipple
              disableRipple
              size="small"
              onClick={() => dispatch(uiSetOpenNotifications())}
              style={{
                border: "none",
                outline: "none",
                display: "flex",
                justifyContent: "center",
              }}
              sx={{
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
              className="hover-close"
            >
              <i
                style={{ fontSize: 18, color: "#333333" }}
                className="fa-solid fa-xmark"
              ></i>
            </IconButton>
          )}
        </div>
        {notificationsData && notificationsData.length > 0 ? (
          <div
            className="container scroll-notifications"
            style={{
              maxHeight: closeIcon ? "70vh" : "650px",
            }}
          >
            <Row>
              {notificationsData.map((noti: INotifications, index: number) => (
                <div
                  key={index}
                  className={`${
                    noti.read
                      ? "card-container-read "
                      : "card-container-notRead "
                  }
                    `}
                  style={{ cursor: "pointer" }}
                >
                  <Grid container>
                    <Grid onClick={() => updateNotification(noti)} item xs={1}>
                      <div
                        className={!noti.read ? `circle-notification` : ""}
                      />
                    </Grid>
                    <Grid onClick={() => updateNotification(noti)} item xs={7}>
                      <Box>
                        <p className="card-title-read">{noti.title}</p>
                        <p className="txt-primary-sm">{noti.description}</p>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        textAlign: "end",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }}
                    >
                      <p
                        onClick={() => updateNotification(noti)}
                        className={
                          noti.read
                            ? "card-caption-read"
                            : "card-caption-notRead"
                        }
                        style={{
                          margin: "0px",
                        }}
                      >
                        {getDiffTime(noti.createAt)}
                      </p>
                      <p
                        onClick={(event) => {
                          handlePopoverOpen(event);
                          setCurNotification(noti);
                        }}
                        style={{
                          fontWeight: "bold",
                          color: "#969696",
                          fontSize: "30px",
                          marginBottom: "0px",
                          marginTop: "-20px",
                        }}
                      >
                        ...
                      </p>
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <div
                          onClick={(event) => {
                            event.stopPropagation();
                            deleteNotification(event);
                          }}
                          style={{
                            padding: "5px",
                            borderRadius: "5px",
                            boxShadow: "0px 4px 4px 0px #00000040",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            justifyContent: "space-between",
                            gap: "10px",
                          }}
                        >
                          <img src={x} alt="" />
                          <p style={{ color: "black" }}>
                            {t("notifications.deleteNoti")}
                          </p>
                        </div>
                      </Popover>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Row>
          </div>
        ) : (
          <Grid
            container
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Fab
              disabled
              style={{
                backgroundColor: "#F5F5F5",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09",
                marginBottom: 10,
              }}
            >
              <i
                style={{ fontSize: 22, color: "#333" }}
                className="fa-regular fa-bell-slash"
              ></i>
            </Fab>
            <label>{t("notifications.noNotifications")}</label>
          </Grid>
        )}
      </div>
    </section>
  );
};
