export const createPromotion = (data: any, token: string) => {
  const myHeaders = new Headers();
  myHeaders.append("api", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Authorization", `bearer ${token}`);
  const formData = new FormData();
  formData.append("title", data.title);
  formData.append("buttons", JSON.stringify(data.buttons));
  formData.append("startDate", data.startDate);
  formData.append("endDate", data.endDate);
  data.operator && formData.append("operator", data.operator.toString());
  data.promotionalPopup &&
    formData.append("promotionalPopup", data.promotionalPopup);

  const requestOptions: any = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}promotionalPopup`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const getPromotions = (token: string, params: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}promotionalPopup?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const deletePromotion = (id: number, token: string) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}promotionalPopup/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const updatePromotion = (data: any, token: string, id: number) => {
  const myHeaders = new Headers();
  myHeaders.append("api", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Authorization", `bearer ${token}`);
  const formData = new FormData();
  formData.append("title", data.title);
  formData.append("buttons", JSON.stringify(data.buttons));
  formData.append("startDate", data.startDate);
  formData.append("endDate", data.endDate);
  data.operator && formData.append("operator", data.operator.toString());
  data.promotionalPopup &&
    formData.append("promotionalPopup", data.promotionalPopup);

  const requestOptions: any = {
    method: "PUT",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}promotionalPopup/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
