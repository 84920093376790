import { Types } from "../types/types";
import { IUserLoggedIn } from "../reducers/authReducer";
import { uiSetError, uiSetErrorMessage } from "./uiAction";
import { loginAccess, sessionValidation } from "../../services/auth";

export const startLoginUsernamePassword = (
  username: string,
  password: string
) => {
  return async (dispatch: Function) => {
    const response = await loginAccess(username, password);
    if (response) {
      localStorage.setItem("token", response.token);
      localStorage.setItem("token-init-date", new Date().getTime().toString());
      const { user, token } = response;
      const castedResponse: any = {
        user: {
          id: user.id,
          username: user.username,
          nameComplete: user.nameComplete,
          email: user.email,
          stateUser: user.stateUser,
          operator: user.operator,
          roles: user.roles,
          associatesUsers: user.associatesUsers,
          userDealer: user.userDealer,
          box: user.box,
          dealer: user.dealer || null,
        },
        token,
      };
      dispatch(login(castedResponse));
    } else {
      dispatch(uiSetError(true));
      dispatch(uiSetErrorMessage("login.errorLogging"));
    }
  };
};

export const startSessionValidation = (
  userId: number | string,
  token: string
) => {
  return async (dispatch: Function) => {
    const response = await sessionValidation(userId, token);

    if (response) {
      return response;
    } else {
      dispatch(uiSetError(true));
      dispatch(uiSetErrorMessage("network.error"));
    }
  };
};

export const login = (user: IUserLoggedIn) => ({
  type: Types.login,
  payload: user,
});

export const startLogout = () => {
  return (dispatch: Function) => {
    localStorage.clear();
    dispatch(resetStorage());
    dispatch(logout());
  };
};

const logout = () => ({ type: Types.logout });
const resetStorage = () => ({ type: Types.reset });
