import React, { useState, useEffect } from "react";
import Filter from "../../../../components/Filter/Filter";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { usePointSales } from "../../../../hooks/usePointSales";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { arraySearch } from "../../../../redux/actions/searchActions";
import MyCustomTable, {
  IColumns,
} from "../../../../components/Custom/Table/MyCustomTable";
import { CustomAlert } from "../../../../components/Alerts/CustomAlert";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
  startGetAssociatePayAwardsReport,
} from "../../../../redux/actions/helperActions";
import { RowAssociateAwardReport } from "./RowAssociateAwardReport";
import { AwardsPaysReportDataTable } from "../../../../hooks/useDataTable/AwardsPaysReport";
import { buildFinalQueryParams } from "../../../../utils/utils";
import { getPaymentsAwardsByAssociate } from "../../../../services/reports";
import { AwardsPaysPdf } from "../../../../components/Pdf/awardsPayReport/AwardsPaysPdf";
import moment from "moment";
import { DealerTypeEnum } from "../../../../enums/enums";

export const AssociateAwardsReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { data } = useSelector((state: AppState) => state.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterFormValues, setFilterFormValues] = useState({});
  const [pointSalesToFilter, setPointSalesToFilter] = useState<any[]>([]);
  const [filterParams, setFilterParams] = useState({});

  useEffect(() => {
    return () => {
      dispatch(arraySearch(null));
    };
  }, [dispatch]);

  const pointSales = usePointSales(
    {
      id: user && user.id,
      operator: user?.operator
        ? {
            id: user.operator.id,
            name: user.operator.name,
          }
        : null,
      roles: {
        id: user?.roles.id,
        name: user?.roles.name,
      },
    },
    token,
    DealerTypeEnum.ANONYMOUS
  );

  const filterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("shorcuts.PointSale"),
      type: Enum_FieldType.SELECT,
      options: [
        { label: t("managementBonds.all"), value: "all" },
        ...pointSales.map((pointSale) => ({
          label: pointSale.name,
          value: pointSale.id,
        })),
      ],
    },
  ];

  const handleFilter = async (formValues: any, page: number = 1) => {
    setFilterFormValues(formValues);
    const startDate = formValues[t("filters.startDate")];
    const endDate = formValues[t("filters.endDate")];
    const pointSale = formValues[t("shorcuts.PointSale")];
    let pointsSalesFilter;
    if (!pointSale) {
      dispatch(setMessageAlert("cashflow.selectPointSale"));
      dispatch(setTypeAlert("warning"));
      return dispatch(setOpenAlert());
    } else if (pointSale === "all") {
      pointsSalesFilter = pointSales
        .map((point) => ({ id: point.id }))
        .sort((a, b) => b.id - a.id);
    } else {
      pointsSalesFilter = [{ id: pointSale }];
    }
    const params = {
      startDate,
      endDate,
      page,
    };
    setFilterParams(params);
    setPointSalesToFilter(pointsSalesFilter);

    await dispatch(startGetAssociatePayAwardsReport(params, pointsSalesFilter));
  };

  const onCleanFilter = async () => {
    setFilterFormValues({});
    setFilterParams({});
    setPointSalesToFilter([]);
    dispatch(arraySearch(null));
  };

  const onPageChange = (newPage: any) => {
    setCurrentPage(newPage);
    handleFilter(filterFormValues, newPage);
  };

  // TABLE
  const columns: IColumns[] = [
    {
      align: "center",
      label: t("bets.ticketNumber"),
    },
    {
      align: "center",
      label: t("reports.date"),
    },
    {
      align: "center",
      label: t("reports.time"),
    },
    {
      align: "center",
      label: t("reports.amount"),
    },
    {
      align: "center",
      label: t("reports.payer"),
    },
  ];
  const getAllPays = async () => {
    if (!token) {
      return null;
    }

    const finalParams = buildFinalQueryParams({ ...filterParams, page: 1 });

    return await await getPaymentsAwardsByAssociate(
      token,
      finalParams,
      pointSalesToFilter
    );
  };
  return (
    <>
      <div
        className="filter"
        style={{ padding: "18px 0px 0px", marginBottom: "18px" }}
      >
        <Filter
          fields={filterFields}
          handleFilter={(values) => handleFilter(values)}
          onClean={onCleanFilter}
        />
      </div>
      {data ? (
        <div className="filter">
          <MyCustomTable
            columns={columns}
            data={data.data}
            lastPage={data.last_page}
            totalData={data.total}
            renderBody={(item) => <RowAssociateAwardReport row={item} />}
            onPageChange={onPageChange}
            // @ts-ignore
            currentPage={currentPage}
            exportData
            hookBuildTable={AwardsPaysReportDataTable}
            getAllDataToExport={getAllPays}
            pdfComponent={AwardsPaysPdf}
            titleCSV={`${t("reports.associateAwards")
              .split(" ")
              .join("-")}-${moment().toISOString()}`}
          />
        </div>
      ) : (
        <CustomAlert
          htmlText={t("reports.filterTextAssociateReport")}
          type="info"
        />
      )}
    </>
  );
};
