import React, { KeyboardEvent } from "react";
import { Grid, Box, Button, CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { MyTextField } from "../../custom/MyTextField";
import { regLettersNumbers } from "../../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { setFlagFormModal } from "../../../../redux/actions/helperActions";
import { startApproveWithdrawalCash } from "../../../../redux/actions/transactionsActions";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { PointSaleSelect } from "../../custom/PointSaleSelect";

export const ApproveWithDrawalForm = () => {
  const { t } = useTranslation();
  const { activeWithdrawal } = useSelector(
    (state: AppState) => state.transactions
  );
  const dispatch = useDispatch();
  const initialValues = {
    numberDocument: "",
    orderKey: "",
    pointSale: "",
  };
  const validationErrorMsg = t("form.invalidLengthDocumentNumber", {
    min: 5,
    max: 15,
  });
  const validationSchema = yup.object({
    numberDocument: yup
      .string()
      .min(5, validationErrorMsg)
      .max(15, validationErrorMsg)
      .required(t("form.requiredFile")),
    orderKey: yup
      .string()
      .length(10, t("withdrawals.minLengthKey", { length: 10 }))
      .required(t("form.requiredFile")),
    pointSale: yup.number().required(t("form.requiredFile")),
  });
  const handleCancel = () => {
    dispatch(
      setFlagFormModal({
        flagFormModal: false,
      })
    );
  };
  const handleApproveWithdrawal = async (data: any) => {
    if (!activeWithdrawal) {
      throw new Error("INVALID ACTION");
    }
    //verify key and number document player
    const params = {
      playerDocument: data.numberDocument,
      orderKey: data.orderKey,
      requestId: activeWithdrawal.id,
      pointSale: data.pointSale,
    };

    await dispatch(startApproveWithdrawalCash(params));
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      onSubmit={async (data, { setSubmitting }) => {
        setSubmitting(true);
        await handleApproveWithdrawal(data);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid, handleChange }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MyTextField
                name="numberDocument"
                variant="outlined"
                size="small"
                fullWidth
                type="text"
                label={`${t("withdrawals.numberDocumentClient")}*`}
                inputProps={{ maxLength: 15 }}
                onChange={handleChange}
                onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                  !regLettersNumbers(e.key) && e.preventDefault()
                }
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextField
                name="orderKey"
                variant="outlined"
                size="small"
                fullWidth
                type="text"
                label={`${t("withdrawals.orderKey")}*`}
                onChange={handleChange}
                onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                  !regLettersNumbers(e.key) && e.preventDefault()
                }
              />
            </Grid>

            <Grid item xs={6}>
              <PointSaleSelect
                //pointSales={pointSales || []}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Box
            display={"flex"}
            mt={9}
            flex={1}
            justifyContent="flex-end"
            alignItems={"flex-end"}
          >
            <Button
              variant="contained"
              className="footer-btn danger-btn ripple"
              style={{ border: "none", outline: "none" }}
              onClick={handleCancel}
              disabled={!!isSubmitting}
              data-testid={"btnCancel"}
            >
              {t("button.cancel").toUpperCase()}
            </Button>

            <Button
              type="submit"
              variant="contained"
              className="footer-btn primary-btn ripple"
              style={{ border: "none", outline: "none" }}
              disabled={isSubmitting || !isValid}
              data-testid={"btnSave"}
            >
              {isSubmitting ? (
                <CircularProgress size={"1.6rem"} color="inherit" />
              ) : (
                <>{t("button.save").toUpperCase()}</>
              )}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
