import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  CasinoBonusType,
  PaymentMethods,
  PaymentMethodsBonus,
  ProvidersBonus,
  State,
} from "../../../../enums/enums";
import moment from "moment";
import {
  PariplayBonusGameList,
  PragmaticBonusGameList,
  getPragmaticGames,
} from "../../../../services/pragmatic";
import { IGameList } from "../../../../interfaces/bonus";

export const BonusDetails = () => {
  const { activeBonus } = useSelector((state: AppState) => state.bonus);
  const { token } = useSelector((state: AppState) => state.auth);
  const [games, setGames] = useState<string[]>([]);
  const { t } = useTranslation();
  const getPaymentMethodName = (value: any) => {
    switch (parseInt(value)) {
      case PaymentMethodsBonus.ALL:
        return t("managementBonds.all");
      case PaymentMethodsBonus.PHYSICAL:
        return t("managementBonds.physical");
      case PaymentMethodsBonus.ONLINE:
        return t("managementBonds.online");

      default:
        return t("managementBonds.all");
    }
  };

  useEffect(() => {
    const fetchGames = async () => {
      try {
        if (!token) {
          throw new Error("invalid action");
        }
        const gamesPragmatic = activeBonus?.pragmaticBonus
          ? (activeBonus?.pragmaticBonus as PragmaticBonusGameList[])
          : [];
        const rs: any = await getPragmaticGames(token);

        if (rs && rs.gameList) {
          const finalGameList: IGameList[] = rs.gameList;
          const mappedGames: string[] = gamesPragmatic.map((game) => {
            const correspondingGame = finalGameList.find(
              (fgl) => fgl.gameID === game.gameId
            );
            return correspondingGame
              ? correspondingGame.gameName
              : "Juego no encontrado";
          });
          setGames(mappedGames);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataPariplay =
    activeBonus?.provider.id === ProvidersBonus.PARIPLAY
      ? ((activeBonus.pragmaticBonus?.[0] || "") as PariplayBonusGameList)
      : "";
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Typography variant="body1" color={"#747474"}>
          {t("managementBonds.bondName").toUpperCase()}
        </Typography>
        <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
          {activeBonus?.name}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        {activeBonus?.provider.id === ProvidersBonus.PARIPLAY ? (
          <>
            <Typography variant="body1" color={"#747474"}>
              {t("managementBonds.gameName").toUpperCase()}
            </Typography>
            <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
              {dataPariplay && dataPariplay.gameName}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body1" color={"#747474"}>
              {t("managementBonds.code").toUpperCase()}
            </Typography>
            <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
              {activeBonus?.provider.id === ProvidersBonus.PRAGMATIC
                ? activeBonus?.pragmaticBonusCode
                : activeBonus?.code}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1" color={"#747474"}>
          {t("form.state").toUpperCase()}
        </Typography>
        <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
          {activeBonus?.state === State.ACTIVE
            ? t("state.active").toUpperCase()
            : t("state.inactive").toUpperCase()}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography variant="body1" color={"#747474"}>
          {t("filters.startDate").toUpperCase()}
        </Typography>
        <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
          {moment(activeBonus?.startDate).format("YYYY-MM-DD hh:mm:ss A")}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1" color={"#747474"}>
          {t("table.expirationDate").toUpperCase()}
        </Typography>
        <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
          {moment(activeBonus?.endDate).format("YYYY-MM-DD hh:mm:ss A")}
        </Typography>
      </Grid>
      {activeBonus?.expirationDays && (
        <Grid item xs={3}>
          <Typography variant="body1" color={"#747474"}>
            {t("managementBonds.expirationDays").toUpperCase()}
          </Typography>
          <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
            {activeBonus?.expirationDays}
          </Typography>
        </Grid>
      )}
      <Grid item xs={3}>
        <Typography variant="body1" color={"#747474"}>
          {t("managementBonds.bondValue").toUpperCase()}
        </Typography>
        <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
          {"$ "}
          {activeBonus?.provider.id === ProvidersBonus.ALTENAR
            ? activeBonus?.value
            : activeBonus?.totalValue}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1" color={"#747474"}>
          {t("managementBonds.amountBonds").toUpperCase()}
        </Typography>
        <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
          {activeBonus?.amountBonus}
        </Typography>
      </Grid>
      {activeBonus?.provider.id !== ProvidersBonus.ALTENAR && (
        <>
          <Grid item xs={3}>
            <Typography variant="body1" color={"#747474"}>
              {t("pragmatic.RoundValue").toUpperCase()}
            </Typography>
            <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
              {"$" + activeBonus?.value}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" color={"#747474"}>
              {t("pragmatic.NumberRounds").toUpperCase()}
            </Typography>
            <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
              {activeBonus?.pragmaticNumberRounds}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={"#747474"}>
              {t("pragmatic.Games").toUpperCase()}
            </Typography>
            <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
              {games.length > 0 && games.join(", ")}
            </Typography>
          </Grid>
        </>
      )}
      <>
        <Grid item xs={3}>
          <Typography variant="body1" color={"#747474"}>
            {t("managementBonds.depositRange").toUpperCase()}
          </Typography>
          <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
            {(activeBonus?.minDeposit ? `$${activeBonus?.minDeposit}` : "N/A") +
              " - " +
              (activeBonus?.maxDeposit ? `$${activeBonus?.maxDeposit}` : "N/A")}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1" color={"#747474"}>
            {t("managementBonds.percentageDeposit").toUpperCase()}
          </Typography>
          <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
            {activeBonus?.percentageDeposit
              ? activeBonus.percentageDeposit + "%"
              : "N/A"}
          </Typography>
        </Grid>
        {activeBonus?.payMethods && activeBonus?.payMethods.length > 0 && (
          <Grid item xs={3}>
            <Typography variant="body1" color={"#747474"}>
              {t("managementBonds.paymentMethods").toUpperCase()}
            </Typography>
            <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
              {activeBonus?.payMethods && activeBonus?.payMethods.length > 0
                ? activeBonus.payMethods
                    // @ts-ignore
                    .map((method: any) => PaymentMethods[method] || "N/A")
                    .join(", ")
                : "N/A"}
            </Typography>
          </Grid>
        )}
      </>
      {activeBonus?.totalValue &&
        activeBonus?.provider.id === ProvidersBonus.ALTENAR && (
          <Grid item xs={3}>
            <Typography variant="body1" color={"#747474"}>
              {t("managementBonds.totalBondsValue").toUpperCase()}
            </Typography>
            <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
              {"$" + activeBonus?.totalValue}
            </Typography>
          </Grid>
        )}

      {activeBonus?.minimumDeposit && (
        <>
          <Grid item xs={3}>
            <Typography variant="body1" color={"#747474"}>
              {t("managementBonds.minimumDeposit").toUpperCase()}
            </Typography>
            <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
              {"$" + activeBonus?.minimumDeposit}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" color={"#747474"}>
              {t("managementBonds.registrationDate").toUpperCase()}
            </Typography>
            <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
              {moment(activeBonus?.registrationDate).format("YYYY-MM-DD")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" color={"#747474"}>
              {t("managementBonds.minimumSportsLines").toUpperCase()}
            </Typography>
            <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
              {activeBonus?.minimumSportsLines}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" color={"#747474"}>
              {t("managementBonds.maximumSportsLines").toUpperCase()}
            </Typography>
            <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
              {activeBonus?.maximumSportsLines}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" color={"#747474"}>
              {t("managementBonds.minimumSportsFee").toUpperCase()}
            </Typography>
            <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
              {activeBonus?.minimumSportsFee}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" color={"#747474"}>
              {t("managementBonds.paymentMethod").toUpperCase()}
            </Typography>
            <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
              {getPaymentMethodName(
                activeBonus?.paymentMethod || 0
              ).toUpperCase()}
            </Typography>
          </Grid>
        </>
      )}
      {activeBonus?.casinoBonusType && (
        <>
          <Grid item xs={3}>
            <Typography variant="body1" color={"#747474"}>
              {t("managementBonds.bondType").toUpperCase()}
            </Typography>
            <Typography variant="body1" color={"#747474"} fontWeight={"bold"}>
              {t(
                `managementBonds.bonusTypes.${
                  CasinoBonusType[activeBonus?.casinoBonusType]
                }`
              ).toUpperCase()}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
