import { FC } from "react";
import TableCell from "@mui/material/TableCell";
import "../tabsGamingActivity.scss";
import { useTranslation } from "react-i18next";
import { IGoldenRaceCasino } from "../../../../../interfaces/casino";
import moment from "moment";
// @ts-ignore

interface Props {
  row: IGoldenRaceCasino;
}

export const RowDataTableGoldenRace: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  const statusClass = row.betStatus === "lost" ? "loss" : "open";
  const status: { [key: string]: string } = {
    won: t("betResults.won"),
    lost: t("betResults.loss"),
    canceled: t("betResults.canceled"),
    pending: t("betResults.pending"),
  };

  return (
    <>
      <TableCell>
        {moment(row.createAt).format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
      <TableCell>{row.id}</TableCell>
      <TableCell>{row.amount}</TableCell>
      <TableCell>{row.amountWon}</TableCell>
      <TableCell>{row.gameName}</TableCell>
      <TableCell>
        <div className={statusClass}>
          {status[`${row.betStatus}`].toUpperCase()}
        </div>
      </TableCell>
      <TableCell></TableCell>
    </>
  );
};
