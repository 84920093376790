import moment from "moment";
import React, { FC, useState, useEffect } from "react";

import TableCell from "@mui/material/TableCell";
import { IBetData } from "../../../interfaces/bets";
import { betStatuses } from "../../../helpers/betStatus";
import { Circle } from "../../../components/Custom/Circle";

interface Props {
  row: IBetData;
}

export const RowDataTableTickets: FC<Props> = ({ row }) => {
  const [fees, setFees] = useState(1);

  useEffect(() => {
    row.events.map((item: any) =>
      setFees(
        row.eventCount > 1
          ? row.betStakes[0].odds
          : item.markets[0]
          ? item.markets[0].odds * fees
          : 1
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <TableCell align="left">{row.reference}</TableCell>
      <TableCell align="left">
        {moment(row.createAt).format("DD/MM/YYYY")}
      </TableCell>
      <TableCell align="left">
        {moment(row.createAt).format("HH:mm:ss A")}
      </TableCell>
      <TableCell align="left">
        {"$ "}
        {row.status === "S"
          ? row.transactions && row.transactions.length > 0
            ? parseFloat(row.transactions[0].amount).toFixed(2)
            : (parseFloat(row.amount) * fees).toFixed(2)
          : "0"}
      </TableCell>
      <TableCell align="left">
        <div className="row">
          <div className="col-sm-12">
            {row.status === "S" ? (
              <div style={{ display: "flex" }}>
                <Circle color={row.paid ? "#007E8C" : "#A4A0A0"} />
                {row.paid ? "Pagada" : "Pendiente por pagar"}
              </div>
            ) : (
              betStatuses.find((status) => status.value === row.status)
                ?.description
            )}
          </div>
        </div>
      </TableCell>
    </>
  );
};
