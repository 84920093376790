import { AppState } from "../reducers/rootReducer";
import {
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";
import {
  addBrandService,
  addOperatorService,
  editOperatorService,
  getBrandsService,
  getOperatorsService,
  editBrandService,
} from "../../services/managementCompanyService";
import { arraySearch, setChangeData } from "./searchActions";
import {
  ICompanyDB,
  ICompanyData,
  IOperationManagForm,
  ICountriesOperator,
  ICountriesCurrencyAdd,
  ICountriesGamesAdd,
  ICountriesLanguagesAdd,
  ICountriesPaymentsAdd,
  ICountriesUpdate,
  IFormConfigOperation,
  ICountriesSolutionsAdd,
} from "../../interfaces/operationCompany";
import { Types } from "../types/types";
import { getCopyArrayOrObject } from "../../utils/utils";
import { uiSetOpenDialog } from "./uiAction";
import { startGetPercentageWithdrawal } from "./managementParametersActions";
import { socket } from "../../helpers/socket";

export const getOperatorsFilter = (
  formValues: any,
  filter?: boolean,
  cleanFilter?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token } = (getState() as AppState).auth;

    if (formValues.page || filter) {
      formValues.perPage = rowsPerPage.toString();
    }

    try {
      if (token) {
        let finalValues: any = {};
        Object.entries(formValues).forEach(([key, value]) => {
          if (value !== undefined && value !== "") {
            finalValues[key] = value;
          }
        });

        dispatch(setLoadingState({ loading: true }));
        const response = await getOperatorsService(token, finalValues);

        if (response && !response.error) {
          if (cleanFilter) {
            dispatch(arraySearch(null));
            dispatch(setCompaniesRedux(response));
          } else {
            dispatch(arraySearch(response));
          }
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetBonds"));
          dispatch(setTypeAlert("error"));
        }

        dispatch(setLoadingState({ loading: false }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const startAddOperator = (data: IOperationManagForm) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    try {
      const response = token && (await addOperatorService(data, token));
      if (response && !response.error) {
        await dispatch(startGetCompanies());

        dispatch(setMessageAlert("companyManagement.successCreateCompany"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
      } else {
        dispatch(setMessageAlert("companyManagement.errorCreatingOperator"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      throw new Error(error as any);
    }
  };
};

export const startEditOperator = (
  data: Partial<IOperationManagForm>,
  id: number,
  changePercentage: boolean = false
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { activeCompany } = (getState() as AppState).managementCompanies;

    if (!token) {
      throw new Error("Invalid token");
    }
    try {
      if ((activeCompany && activeCompany.id) || id) {
        const response =
          token &&
          (await editOperatorService(
            data,
            token,
            activeCompany && activeCompany.id ? activeCompany.id : id
          ));
        if (response && !response.error) {
          socket.emit("Admin/SessionLimitChange", {
            operator: activeCompany ? activeCompany.id : id,
          });
          changePercentage && (await dispatch(startGetPercentageWithdrawal()));
          await dispatch(startGetCompanies());
          dispatch(setActiveCompany(null));
          dispatch(uiSetOpenDialog());
          dispatch(setMessageAlert("companyManagement.updatedCompany"));
          dispatch(setTypeAlert("success"));
          dispatch(setOpenAlert());
        } else {
          dispatch(setMessageAlert("alerts.editOperatorError"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        }
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.editOperatorError"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const editStateOperator = (
  dataCompany: ICompanyData,
  isFilterByState?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { data } = (getState() as AppState).search;
    const { companies } = (getState() as AppState).managementCompanies;
    const { rowsPerPage } = (getState() as AppState).helper;

    try {
      const response =
        token &&
        dataCompany.id &&
        (await editOperatorService(dataCompany as any, token, dataCompany.id));

      if (response && !response.error) {
        const newData = data ? { ...data } : companies && { ...companies };
        newData.data =
          data && isFilterByState
            ? newData.data.filter((el: any) => el.id !== dataCompany.id)
            : newData.data.map((el: any) =>
                el.id === dataCompany.id
                  ? { ...el, stateOperator: dataCompany.stateOperator }
                  : el
              );
        newData.total =
          data && isFilterByState ? data.total - 1 : newData.total;
        newData.last_page =
          data && isFilterByState
            ? Math.ceil((data.total - 1) / rowsPerPage)
            : newData.last_page;

        dispatch(data ? arraySearch(newData) : setCompaniesRedux(newData));

        dispatch(setActiveCompany(null));
        dispatch(setMessageAlert("alerts.editOperatorSuccess"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
      } else {
        dispatch(setMessageAlert("alerts.editOperatorError"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.editOperatorError"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startGetCompanies = () => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    dispatch(setLoadingState({ loading: true }));
    try {
      if (!user || !token) {
        throw new Error("NOT AVAILABLE ACTION");
      }
      const response = await getOperatorsService(token, {
        perPage: rowsPerPage,
        page: 1,
      });

      if (response && !response.error) {
        dispatch(arraySearch(null));
        dispatch(setCompaniesRedux(response));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorSetBonds"));
        dispatch(setTypeAlert("error"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const setCompaniesRedux = (companies: ICompanyDB | null) => ({
  type: Types.setCompanies,
  payload: companies,
});
export const setTotalCompaniesRedux = (companies: ICompanyDB | null) => ({
  type: Types.setTotalCompanies,
  payload: companies,
});

export const setBrandsRedux = (brands: IFormConfigOperation | null) => ({
  type: Types.setBrands,
  payload: brands,
});

export const setTotalBrandsRedux = (brands: IFormConfigOperation | null) => ({
  type: Types.setTotalBrands,
  payload: brands,
});

export const addCompanyRedux = (company: IOperationManagForm) => ({
  type: Types.addCompany,
  payload: company,
});

export const editCompanyRedux = (company: IOperationManagForm) => ({
  type: Types.updateCompany,
  payload: company,
});

export const setActiveCompany = (company: IOperationManagForm | null) => ({
  type: Types.setActiveCompany,
  payload: company,
});

export const startAddNewConfigCompany = (data: IFormConfigOperation) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;

    try {
      if (user && token) {
        const { name, countries, state } = data;
        const finalCountriesBrand: ICountriesOperator[] = [];
        countries.forEach((country) => {
          const {
            paymentMethodsRecharges,
            paymentMethodsWithdrawal,
            countriesCurrencies,
            countriesGames,
            countriesLanguages,
            countriesSolutions,
          } = country;

          const parseCurrencies: ICountriesCurrencyAdd[] = [];
          countriesCurrencies?.forEach((currency) => {
            const { id } = currency;
            parseCurrencies.push({
              state: 1,
              currency: id,
            });
          });
          const parseGames: ICountriesGamesAdd[] = [];
          countriesGames?.forEach((game) => {
            const { idParamGame } = game;
            if (idParamGame) {
              parseGames.push({
                state: 1,
                id: idParamGame,
              });
            }
          });
          const parseLanguages: ICountriesLanguagesAdd[] = [];
          countriesLanguages?.forEach((language) => {
            const { id } = language;
            if (id) {
              parseLanguages.push({
                state: 1,
                language: id,
              });
            }
          });
          const parseSolutions: ICountriesSolutionsAdd[] = [];
          countriesSolutions?.forEach((solution) => {
            const { idCountrySolution } = solution;
            if (idCountrySolution) {
              parseSolutions.push({
                state: 1,
                id: idCountrySolution,
              });
            }
          });
          const paymentMethods: ICountriesPaymentsAdd[] = [];
          paymentMethodsRecharges
            ?.filter((el) => el.state)
            .forEach((method) => {
              const { idParamMethod } = method;
              if (idParamMethod) {
                paymentMethods.push({
                  state: method.state,
                  id: idParamMethod,
                });
              }
            });
          paymentMethodsWithdrawal
            ?.filter((el) => el.state)
            .forEach((method) => {
              const { idParamMethod } = method;
              if (idParamMethod) {
                paymentMethods.push({
                  state: method.state,
                  id: idParamMethod,
                });
              }
            });

          finalCountriesBrand.push({
            //@ts-ignore
            country: country.id,
            countriesCurrencies: parseCurrencies,
            countriesGames: parseGames,
            countriesLanguages: parseLanguages,
            countriesPayments: paymentMethods,
            countriesSolutions: parseSolutions,
          });
        });
        const finalData = {
          name,
          state,
          operator: data.operator,
          createdUser: data.createdUser,
          countries: finalCountriesBrand,
        };

        const response = await addBrandService(finalData, token);

        if (response && !response.error) {
          await dispatch(setConfigBrands());
          dispatch(arraySearch(null));
          dispatch(setMessageAlert("companyManagement.successConfig"));
          dispatch(setTypeAlert("success"));
          dispatch(setOpenAlert());
        } else {
          dispatch(setMessageAlert("companyManagement.errorCreatingBrand"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
          dispatch(setLoadingState({ loading: false }));
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const getBrandsFilter = (formValues: any, cleanFilter?: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (formValues.page) {
      formValues.page = formValues.page.toString();
      formValues.perPage = rowsPerPage.toString();
    }

    try {
      if (token && user) {
        let finalValues: { [key: string]: string } = {};
        for (const [key, value] of Object.entries(formValues)) {
          if (typeof value === "string" && value.trim() !== "") {
            finalValues[key] = value;
          } else if (typeof value === "number") {
            finalValues[key] = value.toString();
          }
        }

        dispatch(setLoadingState({ loading: true }));
        const response = await getBrandsService(
          token,
          null,
          user.operator ? user.operator.id : undefined,
          finalValues
        );

        if (response && response.data && !response.error) {
          if (response.data.length === 0) {
            dispatch(setChangeData(false));
            dispatch(arraySearch(response));
          } else {
            dispatch(setChangeData(true));
            let config = response;
            config.data = config.data.map((row: any) => {
              row.countries = row.countriesBrands;
              delete row.countriesBrands;
              row.countries = row.countries.map((country: any) => {
                const paymentsCopy = getCopyArrayOrObject(
                  country.countriesPayments
                );
                country.idCountryBrand = country.id;
                country.id = country.country.id;
                country.name = country.country.name;
                country.imgUrl = country.country.imgUrl;
                country.countriesSolutions = country.countriesSolutions
                  ? country.countriesSolutions.filter(
                      (solution: any) => solution.paramSolution
                    )
                  : [];
                country.countriesCurrencies = country.countriesCurrencies
                  ? country.countriesCurrencies
                  : [];
                country.countriesGames = country.countriesGames
                  ? country.countriesGames.filter((game: any) => game.paramGame)
                  : [];
                country.countriesLanguages = country.countriesLanguages
                  ? country.countriesLanguages
                  : [];
                country.paymentMethodsRecharges = paymentsCopy.filter(
                  (payment: any) =>
                    payment.paramMethod.paymentMethod.type === "refills" ||
                    payment.paramMethod.paymentMethod.type === "global"
                );
                country.paymentMethodsWithdrawal = paymentsCopy.filter(
                  (payment: any) =>
                    payment.paramMethod.paymentMethod.type === "withdrawal"
                );
                delete country.country;
                return country;
              });
              return row;
            });

            if (cleanFilter) {
              dispatch(arraySearch(null));
              dispatch(setBrandsRedux(config));
            } else {
              dispatch(arraySearch(config));
            }
          }
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetBrands"));
          dispatch(setTypeAlert("error"));
        }
        dispatch(setLoadingState({ loading: false }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const setActiveBrand = (brand: IFormConfigOperation | null) => ({
  type: Types.setActiveBrand,
  payload: brand,
});

export const startEditConfigCompany = (data: IFormConfigOperation) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { activeCompany, activeBrand } = (getState() as AppState)
      .managementCompanies;

    try {
      if (user && activeCompany && activeCompany.id && token) {
        const { name, countries, state } = data;
        const finalCountriesBrand: ICountriesOperator[] = [];

        countries.forEach((country) => {
          const {
            paymentMethodsRecharges,
            paymentMethodsWithdrawal,
            countriesCurrencies,
            countriesGames,
            countriesLanguages,
            countriesSolutions,
          } = country;
          const parseCurrencies: ICountriesUpdate[] = [];
          const parseGames: ICountriesUpdate[] = [];
          const parseLanguages: ICountriesUpdate[] = [];
          const paymentMethodsRefills: ICountriesUpdate[] = [];
          const paymentMethodsPrizes: ICountriesUpdate[] = [];
          const parseSolutions: ICountriesUpdate[] = [];

          const existsCountry = !!activeBrand?.countries.find(
            (countryBrand) => countryBrand.id === country.id
          );

          if (!existsCountry) {
            // AGREGAR NUEVO PAIS
            const parseCurrencies: ICountriesCurrencyAdd[] = [];
            countriesCurrencies?.forEach((currency) => {
              const { id } = currency;
              parseCurrencies.push({
                state: 1,
                currency: id,
              });
            });
            const parseGames: ICountriesGamesAdd[] = [];
            countriesGames?.forEach((game) => {
              const { idParamGame } = game;
              if (idParamGame) {
                parseGames.push({
                  state: 1,
                  id: idParamGame,
                });
              }
            });
            const parseLanguages: ICountriesLanguagesAdd[] = [];
            countriesLanguages?.forEach((language) => {
              const { id } = language;
              if (id) {
                parseLanguages.push({
                  state: 1,
                  language: id,
                });
              }
            });
            const parseSolutions: ICountriesSolutionsAdd[] = [];
            countriesSolutions?.forEach((solution) => {
              const { idCountrySolution } = solution;
              if (idCountrySolution) {
                parseSolutions.push({
                  state: 1,
                  id: idCountrySolution,
                });
              }
            });
            const paymentMethods: ICountriesPaymentsAdd[] = [];
            paymentMethodsRecharges?.forEach((method) => {
              const { idParamMethod } = method;
              if (idParamMethod) {
                paymentMethods.push({
                  state: 1,
                  id: idParamMethod,
                });
              }
            });
            paymentMethodsWithdrawal?.forEach((method) => {
              const { idParamMethod } = method;
              if (idParamMethod) {
                paymentMethods.push({
                  state: 1,
                  id: idParamMethod,
                });
              }
            });
            finalCountriesBrand.push({
              //@ts-ignore
              country: country.id,
              countriesCurrencies: parseCurrencies,
              countriesGames: parseGames,
              countriesLanguages: parseLanguages,
              countriesPayments: paymentMethods,
              countriesSolutions: parseSolutions,
            });
          } else {
            activeBrand?.countries.forEach((objCountry) => {
              if (objCountry.id === country.id) {
                // *********** MAPEAR MONEDAS  ************ //
                countriesCurrencies?.forEach((currency) => {
                  const index = objCountry.countriesCurrencies
                    // @ts-ignore
                    ?.map((objCurrency) =>
                      // @ts-ignore
                      objCurrency.currency.currency.toLowerCase()
                    )
                    .indexOf(currency.currency.toLowerCase());

                  if (index !== -1 && objCountry.countriesCurrencies) {
                    parseCurrencies.push({
                      state:
                        // @ts-ignore
                        objCountry.countriesCurrencies[index!].currency.id ===
                        currency.id
                          ? 1
                          : 0,
                      id: objCountry.countriesCurrencies[index!].id,
                    });
                  } else {
                    parseCurrencies.push({
                      state: 1,
                      currency: currency.id,
                    });
                  }
                });
                objCountry.countriesCurrencies?.forEach((objCurrency) => {
                  const indexObj = parseCurrencies
                    .map((array) => array.id)
                    .indexOf(objCurrency.id);
                  if (indexObj === -1) {
                    parseCurrencies.push({
                      state: 0,
                      id: objCurrency.id,
                    });
                  }
                });

                // *********** MAPEAR JUEGOS  ************ //
                countriesGames?.forEach((game) => {
                  const index = objCountry.countriesGames
                    // @ts-ignore
                    ?.map((objGame) =>
                      // @ts-ignore
                      objGame.paramGame.game.name.toLowerCase()
                    )
                    .indexOf(game.name.toLowerCase());

                  if (index !== -1) {
                    parseGames.push({
                      state:
                        // @ts-ignore
                        objCountry.countriesGames[index!].paramGame.game.id ===
                        game.id
                          ? 1
                          : 0,
                      id: objCountry.countriesGames![index!].id,
                    });
                  } else {
                    parseGames.push({
                      state: 1,
                      paramGame: game.idParamGame,
                    });
                  }
                });

                objCountry.countriesGames?.forEach((objGame) => {
                  const indexObj = parseGames
                    .map((array) => array.id)
                    .indexOf(objGame.id);
                  if (indexObj === -1) {
                    parseGames.push({
                      state: 0,
                      id: objGame.id,
                    });
                  }
                });

                // *********** MAPEAR IDIOMAS  ************ //
                countriesLanguages?.forEach((language) => {
                  const index = objCountry.countriesLanguages
                    // @ts-ignore
                    ?.map((objLanguage) =>
                      // @ts-ignore
                      objLanguage.language.name.toLowerCase()
                    )
                    .indexOf(language.name.toLowerCase());

                  if (index !== -1) {
                    parseLanguages.push({
                      state:
                        // @ts-ignore
                        objCountry.countriesLanguages[index!].language.id ===
                        language.id
                          ? 1
                          : 0,
                      id: objCountry.countriesLanguages![index!].id,
                    });
                  } else {
                    parseLanguages.push({
                      state: 1,
                      language: language.id,
                    });
                  }
                });
                objCountry.countriesLanguages?.forEach((objLanguage) => {
                  const indexObj = parseLanguages
                    .map((array) => array.id)
                    .indexOf(objLanguage.id);
                  if (indexObj === -1) {
                    parseLanguages.push({
                      state: 0,
                      id: objLanguage.id,
                    });
                  }
                });

                // *********** MAPEAR SOLUCIONES  ************ //
                countriesSolutions?.forEach((solution) => {
                  const index = objCountry.countriesSolutions
                    // @ts-ignore
                    ?.map((objSol) =>
                      // @ts-ignore
                      objSol.paramSolution.solution.name.toLowerCase()
                    )
                    .indexOf(solution.name.toLowerCase());

                  if (index !== -1) {
                    parseSolutions.push({
                      state:
                        // @ts-ignore
                        objCountry.countriesSolutions[index!].paramSolution
                          .solution.id === solution.id
                          ? 1
                          : 0,
                      id: objCountry.countriesSolutions![index!].id,
                    });
                  } else {
                    parseSolutions.push({
                      state: 1,
                      paramSolution: solution.idCountrySolution,
                    });
                  }
                });

                objCountry.countriesSolutions?.forEach((objSol) => {
                  const indexObj = parseSolutions
                    .map((array) => array.id)
                    .indexOf(objSol.id);
                  if (indexObj === -1) {
                    parseSolutions.push({
                      state: 0,
                      id: objSol.id,
                    });
                  }
                });

                // *********** MAPEAR MÉTODOS DE PAGO RECARGAS ************ //
                paymentMethodsRecharges?.forEach((method) => {
                  const existsMethod = objCountry.paymentMethodsRecharges?.find(
                    (objMethod) =>
                      objMethod.paramMethod.paymentMethod.id === method.id
                  );

                  if (existsMethod) {
                    paymentMethodsRefills.push({
                      state: method.state,
                      id: existsMethod.id,
                    });
                  } else {
                    paymentMethodsRefills.push({
                      state: method.state,
                      paramMethod: method.idParamMethod,
                    });
                  }
                });

                objCountry.paymentMethodsRecharges?.forEach((objMethod) => {
                  const existsActiveMethod = paymentMethodsRecharges?.find(
                    (method) =>
                      method.id === objMethod.paramMethod.paymentMethod.id
                  );
                  if (!existsActiveMethod) {
                    paymentMethodsRefills.push({
                      state: 0,
                      id: objMethod.id,
                    });
                  }
                });

                // *********** MAPEAR MÉTODOS DE PAGO RETIROS ************ //
                paymentMethodsWithdrawal?.forEach((method) => {
                  const existsMethod =
                    objCountry.paymentMethodsWithdrawal?.find(
                      (objMethod) =>
                        objMethod.paramMethod.paymentMethod.id === method.id
                    );

                  if (existsMethod) {
                    paymentMethodsPrizes.push({
                      state: method.state,
                      id: existsMethod.id,
                    });
                  } else {
                    paymentMethodsPrizes.push({
                      state: method.state,
                      paramMethod: method.idParamMethod,
                    });
                  }
                });

                objCountry.paymentMethodsWithdrawal?.forEach((objMethod) => {
                  const existsActiveMethod = paymentMethodsWithdrawal?.find(
                    (method) =>
                      method.id === objMethod.paramMethod.paymentMethod.id
                  );
                  if (!existsActiveMethod) {
                    paymentMethodsPrizes.push({
                      state: 0,
                      id: objMethod.id,
                    });
                  }
                });

                finalCountriesBrand.push({
                  //@ts-ignore
                  id: objCountry.idCountryBrand,
                  country: country.id,
                  countriesCurrencies: parseCurrencies,
                  countriesGames: parseGames,
                  countriesLanguages: parseLanguages,
                  countriesPayments:
                    paymentMethodsRefills.concat(paymentMethodsPrizes),
                  countriesSolutions: parseSolutions,
                });
              }
            });
          }
        });
        const finalData: any = {
          id: data.id,
          name,
          state,
          operator: data.operator,
          createdUser: data.createdUser,
          countries: finalCountriesBrand,
        };

        const response =
          data.id && (await editBrandService(finalData, token, data.id));

        if (response && !response.error) {
          await dispatch(setConfigBrands());
          dispatch(arraySearch(null));
          dispatch(setMessageAlert("alerts.updateBrandSuccess"));
          dispatch(setTypeAlert("success"));
          dispatch(setOpenAlert());
        } else {
          dispatch(setLoadingState({ loading: false }));
          dispatch(setMessageAlert("alerts.updateBrandError"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const editStateConfigBrand = (
  data: IFormConfigOperation,
  isFilterByState?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    let { data: dataSearch } = (getState() as AppState).search;
    let { brands } = (getState() as AppState).managementCompanies;
    let { rowsPerPage } = (getState() as AppState).helper;

    try {
      const response =
        token &&
        data.id &&
        (await editBrandService(
          { id: data.id, state: data.state } as any,
          token,
          data.id
        ));

      if (response && !response.error) {
        data && dispatch(setChangeData(true));
        const newData = dataSearch
          ? { ...dataSearch }
          : brands && { ...brands };

        newData.data =
          dataSearch && isFilterByState
            ? newData.data.filter((el: any) => el.id !== data.id)
            : newData.data.map((el: any) =>
                el.id === data.id ? { ...el, state: data.state } : el
              );

        newData.total =
          dataSearch && isFilterByState ? dataSearch.total - 1 : newData.total;
        newData.last_page =
          data && isFilterByState
            ? Math.ceil((dataSearch.total - 1) / rowsPerPage)
            : newData.last_page;

        dispatch(dataSearch ? arraySearch(newData) : setBrandsRedux(newData));

        dispatch(setMessageAlert("alerts.updateBrandSuccess"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
      } else {
        dispatch(setMessageAlert("alerts.updateBrandError"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      alert(error);
    }
  };
};

export const setConfigBrands = () => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    const formValues = { page: 1, perPage: rowsPerPage.toString() };

    try {
      if (token && user) {
        let finalValues: any = {};
        Object.entries(formValues).forEach(([key, value], index) => {
          //@ts-ignore
          if (value && value.length > 0) {
            //@ts-ignore
            finalValues[key] = value;
          }
        });

        dispatch(setLoadingState({ loading: true }));
        const response = await getBrandsService(
          token,
          null,
          user.operator ? user.operator.id : undefined,
          finalValues
        );
        if (response && response.data && !response.error) {
          if (response.data.length === 0) {
            dispatch(setBrandsRedux(response));
          } else {
            let config = response;
            config.data = config.data.map((row: any) => {
              row.countries = row.countriesBrands;
              delete row.countriesBrands;
              row.countries = row.countries.map((country: any) => {
                const paymentsCopy = getCopyArrayOrObject(
                  country.countriesPayments
                );
                country.idCountryBrand = country.id;
                country.id = country.country.id;
                country.name = country.country.name;
                country.imgUrl = country.country.imgUrl;
                country.countriesSolutions = country.countriesSolutions
                  ? country.countriesSolutions.filter(
                      (solution: any) => solution.paramSolution
                    )
                  : [];
                country.countriesCurrencies = country.countriesCurrencies
                  ? country.countriesCurrencies
                  : [];
                country.countriesGames = country.countriesGames
                  ? country.countriesGames.filter((game: any) => game.paramGame)
                  : [];
                country.countriesLanguages = country.countriesLanguages
                  ? country.countriesLanguages
                  : [];
                country.paymentMethodsRecharges = paymentsCopy.filter(
                  (payment: any) =>
                    payment.paramMethod.paymentMethod.type === "refills" ||
                    payment.paramMethod.paymentMethod.type === "global"
                );
                country.paymentMethodsWithdrawal = paymentsCopy.filter(
                  (payment: any) =>
                    payment.paramMethod.paymentMethod.type === "withdrawal"
                );
                delete country.country;
                return country;
              });
              return row;
            });

            await dispatch(setBrandsRedux(config));
          }
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetBrands"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};
