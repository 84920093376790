import React, { FC } from "react";
import { IPlayerActivityData } from "../../../interfaces/playerActivity";

import { headersActivityPlayer } from "../../../helpers/headersTables";
import { Text, View } from "@react-pdf/renderer";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import moment from "moment";
import { ProvidersString, TransactionTypesDB } from "../../../enums/enums";
import { useTranslation } from "react-i18next";
import { buildRedName } from "../../../utils/utils";

interface props {
  page: IPlayerActivityData[];
}
export const ActivityPlayerPdf: FC<props> = ({ page }) => {
  const { t } = useTranslation();
  const columns = headersActivityPlayer;
  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column,index) => (
          <View key={index} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>

      <View style={{ flex: 1 }}>
        {page.map((data) => {
          const method =
            data.meta?.debitParty?.typeClass &&
            data.transactionType?.id === TransactionTypesDB.RECHARGE
              ? `- ${t(`paymentMethods.${data.meta?.debitParty?.typeClass}`)}`
              : "";
          return (
            <View key={data.id} style={stylesPdf.row}>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {moment(data.createAt).format("YYYY-MM-DD HH:mm:ss")}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.id}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {data.transactionType.name + " " + method}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {data.transactionStatus.name}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>${data.amount}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>${data.newBalance}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{buildRedName(data)}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {data.transactionId &&
                    data.provider.name === ProvidersString.ALTENAR
                    ? data.transactionId
                    : data.meta?.transactionReference || ""}
                </Text>
              </View>
            </View>
          )}
        )}
      </View>
    </>
  );
};
