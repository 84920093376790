import React, { FC } from "react";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
//import { Fonts } from "../../../shared/constants/fonts/Fonts";

interface props {
  variant: Highcharts.SeriesOptionsType;
  title: string;
  //series: Highcharts.PointOptionsObject[];
  series: any[];
  getContext?(context: any): void;
  options?: Highcharts.Options;
  height?: any;
  customChartStyles?:Highcharts.ChartOptions
}
export const MyCharts: FC<props> = ({
  variant,
  title,
  options,
  series,
  getContext,
  height: customHeigth,
  customChartStyles
}) => {
  let defaultOptions: Highcharts.Options = {
    chart: {
      //height: 400,
      type: variant.type,
      backgroundColor: "transparent",
      //margin: [50, 50, 50, 50],
      //spacingTop: 0,

      //defaultSeriesType: 'areaspline',

      events: {
        render: function () {
          if (getContext) {
            getContext(this);
          }
        },
      },
      style:{
        fontFamily:"Roboto"
      }
    },
    title: {
      text: title,
    },
    tooltip: {
      valueSuffix: "%",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        /* dataLabels: {
          enabled: true,
          format: "{point.name}: {y} %",
        }, */
        //borderWidth: 5,

        showInLegend: true,
        size: "100%",

        dataLabels: {
          enabled: false,
        },
      },
    },

    series: [...series],
    /* series: [
      {
        type: variant.type as any,
        //name: "aaaa",
        colorByPoint: true,
        innerSize: "75%",
        data: series as any,
      },
    ], */
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 300,
          },
          chartOptions: {
            title: {
              style: {
                fontSize: "11px",
              },
            },
          },
        },
      ], //.reverse()
    },

    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
  };
  if (options) {
    defaultOptions = {
      ...defaultOptions,
      ...options,
    };
  }
  if (customHeigth) {
    defaultOptions = {
      ...defaultOptions,
      chart: {
        ...defaultOptions.chart,
        height: customHeigth,
      },
    };
  }
  
  if(customChartStyles){
    defaultOptions = {
      ...defaultOptions,
      chart: {
        ...defaultOptions.chart,
        ...customChartStyles
      },
    };


  }
  return <HighchartsReact highcharts={Highcharts} options={defaultOptions} />;
};