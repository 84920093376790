import { IFormMessages, IMessagesParameters } from "../../interfaces/messages";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import {
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";
import { arraySearch, setChangeData } from "./searchActions";
import { createMessage, deleteMessage, getMessages, resendMessage, updateMessage } from "../../services/messages";
import { t } from "i18next";

export const startGetMessages = (page: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    try {
      if (!user || !token) {
        throw new Error("NOT AVAILABLE ACTION");
      }
      const operatorId = user.operator ? user.operator.id : null;
      const params = {
        perPage: rowsPerPage,
        page,
      };
      const response = await getMessages(token, operatorId, params);

      if (response && !response.error) {
        dispatch(arraySearch(null));
        dispatch(setMessages(response));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorSetLegal"));
        dispatch(setTypeAlert("error"));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const setMessages = (messages: IMessagesParameters) => ({
  type: Types.setMessages,
  payload: messages,
});

export const setActiveMessage = (message: any | null) => ({
  type: Types.setActiveMessage,
  payload: message,
});

export const startFilterMessages = (params: any, cleanFilter?: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (params.page || rowsPerPage) {
      params.page = params.page.toString();
      params.perPage = rowsPerPage.toString();
    }

    try {
      if (token) {
        let finalValues: any = {};
        Object.entries(params).forEach(([key, value], index) => {
          //@ts-ignore
          if (value && value.length > 0) {
            finalValues[key] = value;
          }
        });

        const response =
          user &&
          (await getMessages(token, user.operator?.id, finalValues));

        if (response && !response.error) {
          if (cleanFilter) {
            dispatch(arraySearch(null));
            dispatch(setMessages(response));
          } else {
            dispatch(arraySearch(response));
          }
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetLegal"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const startDeleteMessage = (id: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { data } = (getState() as AppState).search;
    const { messages: message } = (getState() as AppState).messages;

    if (!token) {
      throw new Error("invalid token");
    }

    const response = await deleteMessage(id, token);
    if (response && !response.error) {
      data && dispatch(setChangeData(true));
      const newData = data ? { ...data } : message && { ...message };
      newData.data = newData.data.filter((el: any) => el.id !== id);
      const dataSearchFilter = {
        data: newData.data,
        total: newData.total - 1,
        page: newData.page,
        lastPage: newData.lastPage,
      };

      dispatch(
        data ? arraySearch(dataSearchFilter) : setMessages(dataSearchFilter)
      );

      dispatch(setActiveMessage(null));
      dispatch(setOpenAlert());
      dispatch(setMessageAlert(t("messages.deleteSuccess")));
      dispatch(setTypeAlert("success"));
      dispatch(setActiveMessage(null));
    } else {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert(t("messages.deleteError")));
      dispatch(setTypeAlert("error"));
    }
  };
};

export const startUpdateMessage = (dataMessage: IFormMessages) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { messages: message } = (getState() as AppState).messages;
    const { data } = (getState() as AppState).search;

    if (!user || !token) {
      throw new Error("NOT AVAILABLE ACTION");
    }

    try {
      const response = await updateMessage(dataMessage, token);
      if (response && !response.error) {
        const newData = data ? { ...data } : message && { ...message };
        dispatch(data ? arraySearch(newData) : setMessages(newData));
        dispatch(setOpenAlert());
        dispatch(setMessageAlert(response.message));
        await dispatch(setTypeAlert("success"));
        dispatch(setFlagForm({ flagFormCreate: false }));
        dispatch(setActiveMessage(null));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert(response.error.errorMessage));
        dispatch(setTypeAlert("error"));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const startCreateMessage = (data: IFormMessages) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    try {
      if (user && token) {
        const operatorId = user.operator ? user.operator.id : null;
        data.operator = operatorId;
        const response = await createMessage(data, token);
        if (response && !response.error) {
          await dispatch(startGetMessages(1));
          await dispatch(arraySearch(null));

          dispatch(setOpenAlert());
          dispatch(setMessageAlert(response.message));
          await dispatch(setTypeAlert("success"));

          dispatch(setFlagForm({ flagFormCreate: false }));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert(response.error.errorMessage));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const startResendMessage = (data: IFormMessages) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    try {
      if (user && token) {
        const operatorId = user.operator ? user.operator.id : null;
        data.operator = operatorId;
        const response = await resendMessage(data, token);
        if (response && !response.error) {
          await dispatch(startGetMessages(1));
          await dispatch(arraySearch(null));

          dispatch(setOpenAlert());
          dispatch(setMessageAlert(response.message));
          await dispatch(setTypeAlert("success"));

          dispatch(setFlagForm({ flagFormCreate: false }));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert(response.error.errorMessage));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};
