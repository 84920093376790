import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Alert, AlertTitle, Grid, Stack, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import Filter from "../../../components/Filter/Filter";
import { DynamicTabs } from "../../../components/Custom/DynamicTabs";
import { General } from "../../components/ManagementPlayers/General/General";
import { Refills } from "../../components/ManagementPlayers/Refills/Refills";
import { Enum_FieldType, IFilterField } from "../../../interfaces/components";
import { AppState } from "../../../redux/reducers/rootReducer";

import "./managementPlayers.scss";
import { setPlayerRedux } from "../../../redux/actions/managementPlayersActions";
import { TabsGamingActivity } from "../../components/ManagementPlayers/GamingActivity/TabsGamingActivity";
import { Activity } from "../../components/ManagementPlayers/Activity/Activity";
import { Observations } from "../../components/ManagementPlayers/Observations/Observations";
import { SecurityQuestions } from "../../components/ManagementPlayers/securityQuestions/SecurityQuestions";
import { Bonds } from "../../components/ManagementPlayers/bonds/Bonds";
import { MyAlert } from "../../components/custom/MyAlert";
import { setCloseAlert } from "../../../redux/actions/helperActions";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { arraySearch } from "../../../redux/actions/searchActions";
import { setBetsRedux, setTotalBets } from "../../../redux/actions/betsActions";
import { StatePlayer } from "../../components/ManagementPlayers/StatePlayer/StatePlayer";
import { WithdrawalHistory } from "../../components/ManagementPlayers/WithdrawalHistory/WithdrawalHistory";
import { FinantialInformation } from "../../components/ManagementPlayers/FinantialInformation/FinantialInformation";

export const ManagementPlayers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { player } = useSelector((state: AppState) => state.managementPlayers);
  const { openAlert, typeAlert, messageAlert } = useSelector(
    (state: AppState) => state.helper
  );
  const [, setDataFilter] = useState<any>({});

  const optionsTabs = [
    {
      id: 1,
      label: t("tabs.general"),
    },
    {
      id: 2,
      label: t("tabs.finantialInformation"),
    },
    {
      id: 3,
      label: t("tabs.refills"),
    },
    {
      id: 4,
      label: t("account.userStatus"),
    },
    {
      id: 5,
      label: t("tabs.game"),
    },
    {
      id: 6,
      label: t("tabs.resetQuesions"),
    },
    {
      id: 7,
      label: t("tabs.bonds"),
    },
    {
      id: 8,
      label: t("tabs.activity"),
    },
    {
      id: 9,
      label: t("bets.withdrawals"),
    },
    {
      id: 10,
      label: t("tabs.observations"),
    },
  ];
  const [currentOption, setCurrentOption] = useState(optionsTabs[0].id);
  const route = optionsTabs.find((opt) => opt.id === currentOption)?.label;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getComponentByOption = (optionId: number) => {
    switch (optionId) {
      case 1:
        //GENERAL
        return <General data={player ? player.data : []} />;
      case 2:
        // INFORMACION FINANCIERA
        return <FinantialInformation />;
      case 3:
        //RECARGAS
        return <Refills />;
      case 4:
        //RECARGAS
        return <StatePlayer />;
      //PONER LAS DEMAS PANTALLAS
      case 5:
        //ACTIVIDAD DE JUEGO
        return <TabsGamingActivity />;
      case 6:
        //PREGUNTAS DE SEGURIDAD
        return <SecurityQuestions />;
      case 7:
        //BONOS
        return <Bonds />;
      case 8:
        //ACTIVIDAD
        return <Activity />;
      case 9:
        //   HISTORIAL RETIROS
        return <WithdrawalHistory />;
      case 10:
        //OBSERVACIONES
        return <Observations />;

      //PONER LAS DEMAS PANTALLAS

      default:
        return (
          <>
            <Typography color="text.primary">Not found</Typography>
          </>
        );
    }
  };

  const filterFields: IFilterField[] = [
    {
      label: t("user.username"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("filters.email"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("filters.id"),
      type: Enum_FieldType.TEXT,
    },
  ];

  useEffect(() => {
    dispatch(setPlayerRedux(null));
    dispatch(setCloseAlert());
    return () => {
      dispatch(setPlayerRedux(null));
      dispatch(arraySearch(null));
      dispatch(setBetsRedux(null));
      dispatch(setTotalBets(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
  };
  const handleChangeBreadCumb = () => {
    setCurrentOption(1);
  };

  return (
    <div className="content w-100">
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Row style={{ marginBottom: 2, marginLeft: 0 }}>
            <Col xs={12}>
              <MyBreadCrumbs
                titles={[t("sidebar.player"), t("sidebar.FullDetail")]}
                onChange={handleChangeBreadCumb}
              >
                <Typography color="text.primary">{route}</Typography>
              </MyBreadCrumbs>
            </Col>

            <Col xs={12} className="tabs-managementPlayers">
              <DynamicTabs
                onChangeComponent={(option) => {
                  dispatch(arraySearch(null));
                  setCurrentOption(option);
                }}
                options={optionsTabs}
                setOption={(callback) => callback(currentOption)}
              />
            </Col>
          </Row>
          <div className="filter m-2">
            <Filter
              fields={filterFields}
              component={"player"}
              setDataFilter={setDataFilter}
            />
          </div>

          {!player && (
            <Grid item xs={12} pt={3} marginX={1} mt={-2}>
              <Stack
                sx={{ width: "100%" }}
                style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
                spacing={2}
              >
                <Alert
                  icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
                  sx={{
                    borderLeft: `7px solid #2C97DF`,
                    backgroundColor: "#EAF5FC",
                    color: "#4b4b4b",
                  }}
                >
                  <div className="d-flex">
                    <AlertTitle
                      style={{ marginTop: "5px" }}
                      sx={{ color: "#333333" }}
                    >
                      {t("alerts.consultPlayerTo")}
                    </AlertTitle>
                    <AlertTitle
                      style={{ marginTop: "5px", marginLeft: "5px" }}
                      sx={{ color: "#333333", fontWeight: "bold" }}
                    >
                      {t("alerts.seeHisInformation")}
                    </AlertTitle>
                  </div>
                </Alert>
              </Stack>
            </Grid>
          )}
          <div
            className={
              currentOption === 1 ||
              currentOption === 2 ||
              currentOption === 5 ||
              currentOption === 6 ||
              currentOption === 7 ||
              currentOption === 8 ||
              currentOption === 9
                ? "filter mt-4 m-2"
                : "mt-4 m-2"
            }
          >
            {getComponentByOption(currentOption)}
          </div>
          {/* {selectedComponet !== null && (

          )} */}
          <MyAlert
            open={openAlert}
            message={messageAlert ? messageAlert : ""}
            typeAlert={typeAlert}
            handleClose={onCloseAlert}
          />
        </Col>
      </Row>
    </div>
  );
};
