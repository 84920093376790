import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { headersPlayerUpdateHistory } from "../../../helpers/headersTables";
import { Text, View } from "@react-pdf/renderer";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import { IPlayerUpdateHistoryData } from "../../../interfaces/players";
import moment from "moment";

interface props {
  page: IPlayerUpdateHistoryData[];
}
export const PlayerUpdateHistoryPdf: FC<props> = ({ page }) => {
  const { t } = useTranslation();
  const columns = headersPlayerUpdateHistory;
  const buildDetailUpdate = (row: IPlayerUpdateHistoryData) => {
    const { newPlayerData } = row;

    const { id, ...restData } = newPlayerData;
    return Object.entries(restData)
      .map(([key, value]) => `  ${t(`player.${key}`)} = ${value}`)
      .join(", ");
  };
  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, i) => (
          <View key={i} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>

      <View style={{ flex: 1 }}>
        {page.map((data) => (
          <View key={data.id} style={stylesPdf.row}>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.player.id}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {buildDetailUpdate(data)}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.observation}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {data.responsible.username}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {moment(data.createAt).format("YYYY-MM-DD hh:mm:ss A")}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};
