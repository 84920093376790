import { Typography } from "@mui/material";

import { setCloseAlert } from "../../../redux/actions/helperActions";

import { MyAlert } from "../../components/custom/MyAlert";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useTranslation } from "react-i18next";
import { ManagementAdsTabs } from "./tabs/ManagementAdsTabs";

export const ManagementAds = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { flagFormCreate, openAlert, messageAlert, typeAlert } = useSelector(
    (state: AppState) => state.helper
  );
  const { activeAd } = useSelector((state: AppState) => state.managementAds);

  const onCloseAlert = () => {
    dispatch(setCloseAlert());
  };

  return (
    <div className="content w-100 mb-5">
      <div className="W-100">
        <Typography color="text.primary" style={{ fontSize: "16px" }}>
          {`${t("managementAds.title")} `}{" "}
          {flagFormCreate &&
            `/ ${t(
              activeAd ? "managementAds.editAds" : "managementAds.createSliders"
            )}`}
        </Typography>
      </div>
      <ManagementAdsTabs />

      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />
    </div>
  );
};
