import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { TabPanel } from "../../../../components/Tabs";
import { FormCreateDeposit } from "../../../components/managementDeposits/deposits/FormCreateDeposit";
import { CardsSales } from "../../../components/managementDeposits/cards/CardsSales";
import { useTranslation } from "react-i18next";
import { DealerTypeEnum } from "../../../../enums/enums";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import DepositQR from "../../../components/managementDeposits/depositsQR/DepositQR";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const styles = {
  "&.Mui-selected": {
    color: "#55BCD9",
    border: "none",
    outline: "none",
  },
};

export const ManagementDepositTabs = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const showCardsSell = false;
  const { pointSale } = useSelector((state: AppState) => state.wallet);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        style={{ border: "none", outline: "none" }}
        variant="standard"
        TabIndicatorProps={{
          style: {
            backgroundColor: " #02D0E7",
          },
        }}
      >
        <Tab
          sx={styles}
          label={<span className="spanTab">{t("deposits.deposit")}</span>}
          {...a11yProps(0)}
        />
        {pointSale && pointSale?.pointSale?.type !== DealerTypeEnum.CARDS ? (
          <Tab
            sx={styles}
            label={<span className="spanTab">Depósitos vía código QR</span>}
            {...a11yProps(1)}
          />
        ) : (
          showCardsSell && (
            <Tab
              sx={styles}
              label={
                <span className="spanTab">{t("deposits.cardsSales")}</span>
              }
              {...a11yProps(1)}
            />
          )
        )}
      </Tabs>
      <TabPanel value={value} index={0}>
        {/* DEPOSIT */}
        <FormCreateDeposit />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {pointSale && pointSale?.pointSale?.type !== DealerTypeEnum.CARDS ? (
          <DepositQR />
        ) : (
          <CardsSales />
        )}
      </TabPanel>
    </>
  );
};
