import { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// @ts-ignore
import classnames from "classnames";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  NavLink,
} from "reactstrap";

import passwordIcon from "../../assets/img/reset-password-icon.jpg";

export const Recover = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    emailFocus: false,
  });
  const [email, setEmail] = useState("");
  useEffect(() => {
    document.body.classList.toggle("lock-page");
    return function cleanup() {
      document.body.classList.toggle("lock-page");
    };
  });

  const handleRecover = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  return (
    <>
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Card className="card-lock card-white text-center">
              <CardHeader>
                <img className="img img-raised" alt="..." src={passwordIcon} />
              </CardHeader>
              <CardBody>
                <CardTitle tag="h4" className="text-dark">
                  {t("login.recover.question")}
                </CardTitle>
                <CardTitle tag="h6" className="text-light">
                  {t("login.recover.message")}
                </CardTitle>
                <InputGroup
                  className={classnames({
                    "input-group-focus": state.emailFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="tim-icons icon-email-85" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="email"
                    placeholder={t("login.email")}
                    type="text"
                    value={email}
                    onChange={handleInputChange}
                    onFocus={(e) => setState({ ...state, emailFocus: true })}
                    onBlur={(e) => setState({ ...state, emailFocus: false })}
                  />
                </InputGroup>
              </CardBody>
              <CardFooter>
                <Button
                  block
                  className="mb-2"
                  color="primary"
                  href="#pablo"
                  size="lg"
                  onClick={handleRecover}
                >
                  {t("login.recover.title")}
                </Button>

                <div className="pull-left">
                  <h6>
                    <NavLink
                      tag={Link}
                      to="/auth/login"
                      className="nav-link text-primary"
                    >
                      {t("login.title")}
                    </NavLink>
                  </h6>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  );
};
