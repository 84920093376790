import { Alert, AlertTitle, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../../../../../components/Filter/Filter";
import InfoIcon from "@mui/icons-material/Info";
import { Row } from "reactstrap";
import MyCustomTable from "../../../../../components/Custom/Table/MyCustomTable";
import { GamingActivityGoldenRacePdf } from "../../../../../components/Pdf/activityPlayer/GamingActivityGoldenRace";
import { GameResultGoldenRace } from "../../../../../enums/enums";
import { GamingActivityGoldenRaceDataTable } from "../../../../../hooks/useDataTable/activityPlayer/GamingActivityGoldenRaceDataTable";
import { IGoldenRaceQuery } from "../../../../../interfaces/casino";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../../interfaces/components";
import { startGetGoldenRaceCasinoActivity } from "../../../../../redux/actions/goldenRaceActivity";
import {
  asyncLoadingFunction,
  setCloseAlert,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../../redux/actions/helperActions";
import { arraySearch } from "../../../../../redux/actions/searchActions";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import { getGoldenRaceCasinoReportForActivity } from "../../../../../services/goldenRace";
import "../tabsGamingActivity.scss";
import { RowDataTableGoldenRace } from "./RowDataTableGoldenRace";

export const TabGoldenRace = () => {
  const { t } = useTranslation();
  const { token } = useSelector((state: AppState) => state.auth);
  const { player } = useSelector((state: AppState) => state.managementPlayers);
  const { flagCleanFilter, rowsPerPage } = useSelector(
    (state: AppState) => state.helper
  );
  const { data } = useSelector((state: AppState) => state.search);
  const [cleanFilter, setCleanFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState<any>({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (flagCleanFilter) {
      return setCleanFilter(true);
    }
    return setCleanFilter(false);
  }, [flagCleanFilter]);

  /**
   * Datos inputs para filtro
   */
  const FilterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("bets.bettingStatus"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("betResults.pending"),
          value: GameResultGoldenRace.PENDING,
        },
        {
          label: t("betResults.won"),
          value: GameResultGoldenRace.WON,
        },
        {
          label: t("betResults.loss"),
          value: GameResultGoldenRace.LOST,
        },
        {
          label: t("betResults.canceled"),
          value: GameResultGoldenRace.CANCELED,
        },
      ],
    },
    {
      label: t("bets.betAmount"),
      type: Enum_FieldType.NUMBER,
    },
    {
      label: t("bets.winningAmount"),
      type: Enum_FieldType.NUMBER,
    },
    {
      label: t("bets.internalId"),
      type: Enum_FieldType.TEXT,
    },
  ];

  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(() => dispatch(
        startGetGoldenRaceCasinoActivity({
          ...dataFilter,
          page,
          perPage: rows || rowsPerPage,
        })
      ))
    );
  };

  const handleFilter = async (values: any) => {
    if (player) {
      const params: Partial<IGoldenRaceQuery> = {
        id: values[t("bets.internalId")],
        playerId: (player as any).data[0].id,
        startDate: values[t("filters.startDate")],
        endDate: values[t("filters.endDate")],
        betStatus: values[t("bets.bettingStatus")],
        amount: values[t("bets.betAmount")],
        amountWon: values[t("bets.winningAmount")],
        gameName: values[t("bets.gameName")],
        gameCycle: values[t("bets.gameCycle")],
        page: 1,
      };
      setDataFilter(params);
      if (params.endDate?.trim() && !params.startDate?.trim()) {
        dispatch(setMessageAlert("alerts.selectStartDate"));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }

      dispatch(
        asyncLoadingFunction(() => dispatch(startGetGoldenRaceCasinoActivity(params)))
      );
    }
  };

  useEffect(() => {
    dispatch(setCloseAlert());
    return () => {
      dispatch(setCloseAlert());
      dispatch(arraySearch(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActivityGoldenRaceAction = async () => {
    if (player && token) {
      const { page, perPage, ...rest } = dataFilter;
      const playerId = (player as any).data[0].id;
      const params: Record<string, any> = {};
      for (const key in rest) {
        if (Object.prototype.hasOwnProperty.call(rest, key)) {
          if (rest[key] !== undefined && rest[key] !== null && rest[key] !== '') {
            params[key] = rest[key];
          }
        }
      }
      if (playerId) {
        params.playerId = playerId;
      }
      return await getGoldenRaceCasinoReportForActivity(token, params);
    }
  };

  return (
    <div className="container-fluid">
      <Row
        style={{
          marginTop: "12px",
          pointerEvents: player && player.data.length > 0 ? "all" : "none",
          opacity: player && player.data.length > 0 ? 1 : 0.4,
        }}
      >
        <Filter
          fields={FilterFields}
          cleanFilter={cleanFilter}
          handleFilter={handleFilter}
        />
      </Row>
      {data && data.data.length > 0 && data.data[0].betStatus ? (
        <>
          <MyCustomTable
            columns={[
              {
                align: "left",
                label: t("table.date"),
              },
              {
                align: "left",
                label: t("bets.internalId"),
              },
              {
                align: "left",
                label: t("bets.betAmount"),
              },
              {
                align: "left",
                label: t("bets.winningAmount"),
              },
              {
                align: "left",
                label: t("bets.gameName"),
              },
              {
                align: "left",
                label: t("tabs.status"),
              },
            ]}
            data={data.data}
            lastPage={data.last_page}
            totalData={data.total}
            renderBody={(item) => <RowDataTableGoldenRace row={item} />}
            onPageChange={handlePageChange}
            exportData
            pdfComponent={GamingActivityGoldenRacePdf}
            hookBuildTable={GamingActivityGoldenRaceDataTable}
            getAllDataToExport={getActivityGoldenRaceAction}
            titleCSV={t("table.neatGoldenRace")}
          />
        </>
      ) : (
        <Grid item xs={12} pt={3} mt={-2}>
          <Stack
            sx={{ width: "100%" }}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
            spacing={2}
          >
            <Alert
              icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
              sx={{
                borderLeft: `7px solid #2C97DF`,
                backgroundColor: "#EAF5FC",
                color: "#4b4b4b",
              }}
            >
              <div className="d-flex">
                <AlertTitle
                  style={{ marginTop: "5px" }}
                  sx={{ color: "#333333" }}
                >
                  {t("alerts.clickButton")}
                </AlertTitle>
                <AlertTitle
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  sx={{ color: "#333333", fontWeight: "bold" }}
                >
                  {t("alerts.refillBets")}
                </AlertTitle>
              </div>
            </Alert>
          </Stack>
        </Grid>
      )}
    </div>
  );
};
