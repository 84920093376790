import React, { useState, useEffect } from "react";
import { Grid, Button, Box, CircularProgress } from "@mui/material";
import { FormCustomFooter } from "./FormCustomFooter";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../redux/reducers/rootReducer";
import { useTranslation } from "react-i18next";
import { MyDialog } from "../../../../../components/custom/MyDialog";
import { startSetColors, startSetContent } from "../../../../../../redux/actions/contentManagementActions";
import { setLoadingState } from "../../../../../../redux/actions/helperActions";
import { IContentManagementState } from "../../../../../../redux/reducers/contentManagementReducer";

export const ManagementFooter = () => {
  const { user } = useSelector((state: AppState) => state.auth);
  const { colors, footer } = useSelector(
    (state: AppState) => state.contentManagement
  );
  const { loading } = useSelector((state: AppState) => state.helper);
  const [openDialog, setOpenDialog] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startSetContent());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSaveConfigFooter = async () => {
    const operator = user?.operator ? user.operator.id : null;
    if (operator) {
      const data: Partial<IContentManagementState> = {
        colors: colors,
        footer: footer,
      };
      dispatch(setLoadingState({ loading: true }));
      await dispatch(startSetColors(operator, data));
      dispatch(setLoadingState({ loading: false }));
      setOpenDialog(false);
    }
  };

  return (
    <Grid container>
      {loading ? (
        <Grid container className={"paperLoading"}>
          <Box mx="auto" my={"30vh"}>
            <CircularProgress className={"colorLoading"} />
          </Box>
        </Grid>
      ) : (
        <>
          <Grid
            container
            bgcolor={"#FFF"}
            p={2}
            sx={{
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
          >
            <FormCustomFooter />
            <Grid item xs={10} />

            <Grid item xs={2} mt={1}>
              <Button
                type="submit"
                variant="contained"
                className="footer-btn primary-btn ripple"
                style={{ border: "none", outline: "none" }}
                onClick={() => setOpenDialog(true)}
                disabled={!!loading}
              >
                {t("button.save")}
              </Button>
            </Grid>
          </Grid>
          <MyDialog
            id="confirmationDialog"
            data-testid="confirmationDialog"
            open={openDialog}
            title={t("dialog.confirmationWindow")}
            message={t("dialog.sureSaveChanges")}
            handleAccept={handleSaveConfigFooter}
            handleClose={() => setOpenDialog(false)}
            isLoading={!!loading}
          />
        </>
      )}
    </Grid>
  );
};
