import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  headersWithdrawalsApproveCash,

} from "../../../helpers/headersTables";
import { AppState } from "../../../redux/reducers/rootReducer";
import { getCopyArrayOrObject } from "../../../utils/utils";
import { IWithdrawalData } from "../../../interfaces/withdrawals";

export const WithdrawalsApproveDataTable = (totalData: IWithdrawalData[]) => {
  const { t } = useTranslation();
  const { withdrawals } = useSelector((state: AppState) => state.transactions);

  //   COLUMNS HEADERS STYLES
  const headersCvsStyle = headersWithdrawalsApproveCash.map((header) => {
    return {
      title: header.label,
      width: {
        wpx: header.label === t("withdrawals.reason").toUpperCase() ? 400 : 150,
      },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  // Obtener datos por vista
  const dataWithdrawals =
    withdrawals && withdrawals.data
      ? getCopyArrayOrObject(withdrawals.data)
      : [];
  // Obtener total datos
  const dataTotalWithdrawals = totalData ? getCopyArrayOrObject(totalData) : [];

  // DATA POR VISTA
  const multiDataSetWithdrawals = [
    {
      columns: headersCvsStyle,
      data: dataWithdrawals.map((data: IWithdrawalData) => {
        let array;
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.numberDocument,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.email,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.approvalDate? moment(data.approvalDate).format("DD/MM/YYYY - HH:mm:ss"):"",
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.withdrawalValue,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.paidValue,
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  //   DATA TOTAL
  const multiTotalDataSetWithdrawals = [
    {
      columns: headersCvsStyle,
      data: dataTotalWithdrawals.map((data: IWithdrawalData) => {
        let array;
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.numberDocument,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.email,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.approvalDate? moment(data.approvalDate).format("DD/MM/YYYY - HH:mm:ss"):"",
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.withdrawalValue,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.paidValue,
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  const finalData = {
    dataPerView: dataWithdrawals,
    dataTotal: dataTotalWithdrawals,
    headersCSV: headersWithdrawalsApproveCash,
    multiDataSet: multiDataSetWithdrawals,
    multiTotalDataSet: multiTotalDataSetWithdrawals,
  };
  return finalData;
};
