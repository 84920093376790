import { View, Text } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { State } from "../../../enums/enums";
import { IUserData } from "../../../redux/interfaces/users";
import { stylesPdf } from "../../../views/components/custom/customStyles";

interface Props {
  page: any;
}

export const UsersPdfDocument: FC<Props> = ({ page }) => {
  const { t } = useTranslation();
  const columnsUser = [
    t("table.userId").toUpperCase(),
    t("table.name").toUpperCase(),
    t("table.email").toUpperCase(),
    t("table.creationDate").toUpperCase(),
    t("table.role").toUpperCase(),
    t("table.createdBy").toUpperCase(),
    t("form.state").toUpperCase(),
    "ESTADO ESP",
    t("table.aut2Steps").toUpperCase(),
    t("table.failedAttempts").toUpperCase(),
    t("user.observation").toUpperCase(),
    t("user.login").toUpperCase(),
  ];
  const columns = columnsUser;

  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, index) => (
          <View
            key={column}
            style={
              index === 0 || index === 6
                ? [{ flex: 0.7 }, stylesPdf.pCol]
                : index === 1 ||
                  index === 2 ||
                  index === 3 ||
                  index === 5 ||
                  index === 9
                ? [{ flex: 1.5 }, stylesPdf.pCol]
                : [stylesPdf.col, stylesPdf.pCol]
            }
          >
            <Text style={stylesPdf.text}>{column}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        {page.map((page: any) => (
          <View key={page.id} style={stylesPdf.row}>
            <View style={[stylesPdf.cell, { flex: 0.7 }]}>
              <Text style={stylesPdf.textContent}>{page.id}</Text>
            </View>
            <View style={[stylesPdf.pCol, { flex: 1.5 }]}>
              <Text style={stylesPdf.textContent}>
                {(page as IUserData).nameComplete}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, { flex: 1.5 }]}>
              <Text style={stylesPdf.textContent}>
                {(page as IUserData).email}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, { flex: 1.5 }]}>
              <Text style={stylesPdf.textContent}>
                {moment((page as IUserData).createAt).format("DD/MM/YYYY")}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {(page as IUserData).roles?.name}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, { flex: 1.5 }]}>
              <Text style={stylesPdf.textContent}>
                {(page as IUserData).createdUser?.nameComplete}
              </Text>
            </View>
            {((page as IUserData).stateUser === State.ACTIVE ||
              page.stateUser === "Activo") && (
              <View
                style={[
                  { flex: 0.7 },
                  stylesPdf.pCol,
                  {
                    backgroundColor: "#c6efce",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                ]}
              >
                <Text style={[stylesPdf.textContent, { color: "#0d6a44" }]}>
                  {t("state.active")}
                </Text>
              </View>
            )}
            {((page as IUserData).stateUser === State.INACTIVE ||
              page.stateUser === "Inactivo") && (
              <View
                style={[
                  { flex: 0.7 },
                  stylesPdf.pCol,
                  {
                    backgroundColor: "#ffc7ce",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                ]}
              >
                <Text style={[stylesPdf.textContent, { color: "#ad120b" }]}>
                  {t("state.inactive")}
                </Text>
              </View>
            )}
            {((page as IUserData).stateUser === State.RETIRED ||
              page.stateUser === "Retirado") && (
              <View
                style={[
                  { flex: 0.7 },
                  stylesPdf.pCol,
                  {
                    backgroundColor: "#EBEBEB",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                ]}
              >
                <Text style={[stylesPdf.textContent, { color: "#000000" }]}>
                  {t("state.retired")}
                </Text>
              </View>
            )}
            {((page as IUserData).stateUser === State.RESTRICTED) && (
              <View
                style={[
                  { flex: 0.7 },
                  stylesPdf.pCol,
                  {
                    backgroundColor: "#EBEBEB",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                ]}
              >
                <Text style={[stylesPdf.textContent, { color: "#000000" }]}>
                  {t("state.restricted")}
                </Text>
              </View>
            )}
            <View style={[{ flex: 1.5 }, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{"NA"}</Text>
            </View>
            {(page as IUserData).verificationTwoStep === State.INACTIVE ||
            (page as IUserData).verificationTwoStep === "NO" ? (
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{t("table.not")}</Text>
              </View>
            ) : (
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{t("table.yes")}</Text>
              </View>
            )}
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {(page as IUserData).userLoginHistory}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>
                {(page as IUserData).observation}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {(page as IUserData).username}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};
