import React, { KeyboardEvent, useRef, useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { MyTextField } from "../../custom/MyTextField";
import { regLettersNumbers, regNumbers } from "../../../../utils/utils";
import { MyDialog } from "../../custom/MyDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import { IBodyAwardPayment } from "../../../../interfaces/withdrawals";
import { getUserById } from "../../../../services/managementUsers";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { startVerifyAwardPay } from "../../../../redux/actions/transactionsActions";
import { startGetPointSaleWallet } from "../../../../redux/actions/walletActions";
import { Operators } from "../../../../enums/enums";

export const AwardsPaymentsForm = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const { user, token } = useSelector((state: AppState) => state.auth);
  const formikRef = useRef(null);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const initialValues = {
    orderNumber: "",
    numberDocument: "",
    orderKey: "",
    pointSale: "",
  };

  // Leer el valor de user.operator desde localStorage
  const storedUser = JSON.parse(localStorage.getItem("persist:root") || "{}");
  const storeAuth = JSON.parse(storedUser.auth);
  const operator = storeAuth.user?.operator || null;

  const validationErrorMsg = t("form.invalidLengthDocumentNumber", {
    min: 5,
    max: 15,
  });

  const validationSchema = yup.object({
    orderNumber: yup.number().required(t("form.requiredFile")),
    numberDocument: yup
      .string()
      .min(5, validationErrorMsg)
      .max(15, validationErrorMsg)
      .required(t("form.requiredFile")),
    orderKey: yup
      .string()
      .min(5, t("withdrawals.minLengthKey", { length: 5 }))
      .max(15, validationErrorMsg)
      .required(t("form.requiredFile")),
  });

  const dispatchRequiredPointSale = () => {
    dispatch(setMessageAlert(`withdrawals.requiredPointSale`));
    dispatch(setTypeAlert("error"));
    return dispatch(setOpenAlert());
  };

  const handlePay = async () => {
    if (!formikRef.current || !user || !token) {
      return false;
    }
    dispatch(setLoadingState({ loading: true }));
    const { setSubmitting, values, resetForm } = formikRef.current as any;
    const userDetail = await getUserById(token, user.id);

    const pointSaleLinked: string =
      userDetail.sellerPointSales.length > 0
        ? userDetail.sellerPointSales[0]?.pointSale?.id || ""
        : "";

    if (typeof pointSaleLinked === "string") {
      return dispatchRequiredPointSale();
    }
    setSubmitting(true);
    const { orderNumber, numberDocument, orderKey } = values;
    const verifyAwardObj: IBodyAwardPayment = {
      orderNumber,
      numberDocument,
      orderKey,
      pointSale: pointSaleLinked,
      userId: user.id,
    };
    await dispatch(startVerifyAwardPay(verifyAwardObj));
    setOpenDialog(false);
    setSubmitting(false);
    dispatch(setLoadingState({ loading: false }));
    resetForm();
    await dispatch(startGetPointSaleWallet());
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      onSubmit={async (_) => {
        setOpenDialog(true);
      }}
    >
      {({ isSubmitting, isValid, handleChange, resetForm }) => (
        <Form>
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={4} />

            <Grid item xs={4}>
              <label className="subtitleModule">
                {t("withdrawals.orderNumber")}*
              </label>
              <MyTextField
                name="orderNumber"
                variant="outlined"
                size="small"
                fullWidth
                type="text"
                label={" "}
                inputProps={{ maxLength: 15 }}
                onChange={handleChange}
                onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                  !regNumbers(e.key) && e.preventDefault()
                }
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4} />

            <Grid item xs={4}>
              <label className="subtitleModule">
                {t("withdrawals.numberDocumentClient")}*
              </label>
              <MyTextField
                name="numberDocument"
                variant="outlined"
                size="small"
                fullWidth
                type="text"
                label={" "}
                inputProps={{ maxLength: 15 }}
                onChange={handleChange}
                onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                  !regLettersNumbers(e.key) && e.preventDefault()
                }
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4} />

            <Grid item xs={4}>
              <label className="subtitleModule">
                {t("withdrawals.orderKey")}*
              </label>
              <MyTextField
                name="orderKey"
                variant="outlined"
                size="small"
                fullWidth
                type="text"
                label={" "}
                onChange={handleChange}
                onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                  !regLettersNumbers(e.key) && e.preventDefault()
                }
              />
            </Grid>
            <Grid item xs={4} />
          </Grid>
          <Box
            display={"flex"}
            mt={9}
            flex={1}
            justifyContent="flex-end"
            alignItems={"flex-end"}
          >
            <Button
              variant="contained"
              className="footer-btn danger-btn ripple"
              style={{ border: "none", outline: "none" }}
              onClick={() => resetForm()}
              disabled={!!isSubmitting}
              data-testid={"btnCancel"}
            >
              {t("button.cancel").toUpperCase()}
            </Button>

            <Button
              type="submit"
              variant="contained"
              className="footer-btn primary-btn ripple"
              style={{ border: "none", outline: "none" }}
              disabled={isSubmitting || !isValid}
              data-testid={"btnSave"}
            >
              {isSubmitting ? (
                <CircularProgress size={"1.6rem"} color="inherit" />
              ) : (
                <>{t("button.verify").toUpperCase()}</>
              )}
            </Button>
          </Box>
          <MyDialog
            open={openDialog}
            title={t("dialog.confirmationWindow")}
            message={t("withdrawals.confirmationWithdrawal")}
            handleAccept={handlePay}
            handleClose={() => setOpenDialog(false)}
          />
        </Form>
      )}
    </Formik>
  );
};
