import { Module } from "../../enums/enums";
import { ISlides } from "../../interfaces/slides";
import { getOperatorByIdService } from "../../services/managementCompanyService";
import {
  createNewSlide,
  deleteSlide,
  updateOrder,
} from "../../services/slides";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import {
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";

export const startCreateSlide = (data: ISlides, module: string) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    try {
      if (user && token) {
        const response = await createNewSlide(data, token);
        if (response && !response.error) {
          const operatorDetails = await getOperatorByIdService(
            token,
            user.operator.id
          );
          const sliderMappings: { [key: string]: string } = {
            dashboard: Module.DASHBOARD,
            casino: Module.CASINO,
            promotions: Module.PROMOTIONS,
            casino_live: Module.CASINO_LIVE,
            bettingShortcutsBackground: Module.BETTINGSHORTCUTSBACKGROUND,
            bettingShortcuts: Module.BETTINGSHORTCUTS,
            pageBackground: Module.PAGEBACKGROUND,
            paymentMethods: Module.PAYMENTMETHODS,
            footerItems: Module.FOOTERITEMS,
            signUp: Module.SIGNUP,
            bingo: Module.BINGO,
            virtualsGoldenRace: Module.VIRTUALSGOLDENRACE,
            mobile: Module.MOBILE,
          };

          const sliderProperty = sliderMappings[module];

          if (sliderProperty) {
            dispatch(setSlides(operatorDetails?.sliders?.[sliderProperty]));
          }
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("managementAds.createdSlider"));
          dispatch(setTypeAlert("success"));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("managementAds.errorSlider"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const startDeleteSlide = (id: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;

    if (!token) {
      throw new Error("invalid token");
    }

    const response = await deleteSlide(user?.operator?.id, id, token);
    if (response && !response.error) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("managementAds.deletedSlider"));
      dispatch(setTypeAlert("success"));
    } else {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("managementAds.deleteErrorSlider"));
      dispatch(setTypeAlert("error"));
    }
  };
};

export const updateOrderSlide = (data: ISlides[], module: string) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    try {
      if (user && token) {
        const castedData = {
          operator: user.operator.id,
          sliders: data,
          module,
        };
        const response = await updateOrder(castedData, token);
        if (response && !response.error) {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("managementAds.updatedOrder"));
          dispatch(setTypeAlert("success"));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("managementAds.errorSlider"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const setSlides = (data: ISlides[] | null) => ({
  type: Types.setSlides,
  payload: data,
});
