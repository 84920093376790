export const getdocumentsFiles = (token: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}documentsFiles`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const createFileService = async (data: any) => {
  const myHeaders = new Headers();
  myHeaders.append("api", `${process.env.REACT_APP_API_KEY}`);

  const formdata = new FormData();
  data.frontCard !== ""
    ? formdata.append("frontCard", data.frontCard, data.frontCard.name)
    : formdata.append("frontCard", data.frontCard);
  data.backCard !== ""
    ? formdata.append("backCard", data.backCard, data.backCard.name)
    : formdata.append("backCard", data.backCard);
  data.selfie !== ""
    ? formdata.append("selfie", data.selfie, data.selfie.name)
    : formdata.append("selfie", data.selfie);

  const requestOptions: any = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  return fetch(
    `http://neat-balancer-qa-0fdca435474f1f4f.elb.us-east-1.amazonaws.com:81/documentsFiles`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => result)
    .catch((error) => console.warn(error));
};

export const filterFiles = (token: string, params: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryString = new URLSearchParams(params).toString();

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}documentsFiles?${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
