import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { headersPromoter } from "../../../helpers/headersTables";
import { getCopyArrayOrObject } from "../../../utils/utils";
import { State } from "../../../enums/enums";
import { useTranslation } from "react-i18next";

export const PromotersDataTable = (totalData: any[]) => {
  const { promoters: data } = useSelector(
    (state: AppState) => state.managementPromoters
  );
  const { t } = useTranslation();

  //   COLUMNS HEADERS STYLES
  const headersCvsStyle = headersPromoter.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  // Obtener datos por vista
  const dataPerView = data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotal = totalData ? getCopyArrayOrObject(totalData) : [];
  const getStateName = (state: State) => {
    switch (state) {
      case State.ACTIVE:
        return t("state.active");

      case State.INACTIVE:
        return t("state.inactive");

      default:
        return t("state.active");
    }
  };
  //   DATA TOTAL
  const multiTotalDataSet = [
    {
      columns: headersCvsStyle,
      data: dataTotal.map((data: any) => {
        let array;
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.name,
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: data.phone,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.email,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: getStateName(data.state),
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];
  const multiDataSet = [
    {
      columns: headersCvsStyle,
      data: dataPerView.map((data: any) => {
        let array;
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.name,
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: data.phone,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.email,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: getStateName(data.state),
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];
  const finalData = {
    dataPerView,
    dataTotal,
    headersCSV: headersPromoter,
    multiDataSet,
    multiTotalDataSet,
  };
  return finalData;
};
