import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";

import { useDispatch, useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  asyncLoadingFunction,
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import { startFilterFreeTicketsBonus } from "../../../../redux/actions/bonusActions";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import { State } from "../../../../enums/enums";
import Filter from "../../../../components/Filter/Filter";
import MyCustomTable from "../../../../components/Custom/Table/MyCustomTable";
import { IParamsFreeTicketsBonus } from "../../../../interfaces/bonus";
import { FormCreateFreeTicketsBonus } from "../../../components/managementBonus/freeTicketsBonus/FormCreateFreeTicketsBonus";
import { RowFreeTicketsBonus } from "../../../components/managementBonus/freeTicketsBonus/RowFreeTicketsBonus";

export const FreeTicketsBonus = () => {
  const { t } = useTranslation();
  const { flagFormCreate } = useSelector((state: AppState) => state.helper);

  const { rowsPerPage } = useSelector((state: AppState) => state.helper);
  const { user } = useSelector((state: AppState) => state.auth);

  const { bonus } = useSelector((state: AppState) => state.bonus);
  const bonusData = bonus ? bonus.data : [];
  const dispatch = useDispatch();
  const [dataFilter, setDataFilter] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(1);
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const [isFiltering, setIsFiltering] = useState(false);
  const { pathname } = useLocation();

  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);

  useEffect(() => {
    dispatch(setFlagForm({ flagFormCreate: false }));
    const fetchBonus = async () => {
      if (user) {
        let params: any = {
          page: currentPage,
          perPage: rowsPerPage,
        };
        await dispatch(
          asyncLoadingFunction(() =>
            dispatch(
              startFilterFreeTicketsBonus({
                ...params,
              })
            )
          )
        );
      }
    };
    fetchBonus();
    return () => {
      dispatch(setFlagForm({ flagFormCreate: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FilterFileds: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("reports.playerId"),
      type: Enum_FieldType.NUMBER,
    },
    {
      label: t("tabs.status"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("state.active"),
          value: State.ACTIVE,
        },
        {
          label: t("state.inactive"),
          value: State.INACTIVE,
        },
      ],
    },
  ];

  useEffect(() => {
    if (bonus && bonus.data.length === 0) {
      dispatch(
        setMessageAlert(isFiltering ? "alerts.noData" : "alerts.noRecords")
      );
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bonus]);

  const handleShowForm = () => {
    dispatch(setFlagForm({ flagFormCreate: true }));
  };
  const handlePageChange = async (page: number, rows?: number) => {
    setCurrentPage(page);
    await dispatch(
      startFilterFreeTicketsBonus({
        page: page,
        perPage: rows || rowsPerPage,
        ...dataFilter,
      })
    );
  };
  const handleFilter = async (values: any) => {
    setIsFiltering(true);
    const params: IParamsFreeTicketsBonus = {
      status: values[t("tabs.status")].toString(),
      player: values[t("reports.playerId")].toString(),
      startDate: values[t("filters.startDate")],
      endDate: values[t("filters.endDate")],
    };
    await dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startFilterFreeTicketsBonus({
            ...params,
            page: 1,
          })
        )
      )
    );
    setDataFilter({ ...params });
  };
  const onCleanFilter = async () => {
    dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startFilterFreeTicketsBonus({
            page: 1,
          })
        )
      )
    );
    setDataFilter({});
    setIsFiltering(false);
  };

  return (
    <>
      {flagFormCreate ? (
        <FormCreateFreeTicketsBonus />
      ) : (
        <Grid container spacing={2}>
          {modulePermissions &&
            modulePermissions.permissions &&
            modulePermissions.permissions.created === 1 && (
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  mb={2}
                >
                  <Box display="flex" alignItems="center">
                    <p
                      className="txt-primary-md"
                      style={{
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        alignSelf: "center",
                      }}
                    >
                      {t("managementBonds.createBonds").toLocaleUpperCase()}
                    </p>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={handleShowForm}
                      id="openFormBtn"
                      data-testid="openFormBtn"
                      style={{ border: "none", outline: "none" }}
                    >
                      <span className="btn-add">
                        <i
                          className="fa-solid fa-plus"
                          style={{ fontSize: 30 }}
                        ></i>
                      </span>
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            )}

          <Grid item xs={12}>
            <Filter
              fields={FilterFileds}
              handleFilter={handleFilter}
              onClean={onCleanFilter}
            />
          </Grid>
          <Grid item xs={12}>
            <MyCustomTable
              columns={[
                {
                  align: "center",
                  label: t("managementBonds.bonusCode"),
                },
                {
                  align: "center",
                  label: t("managementBonds.bondType"),
                },
                {
                  align: "center",
                  label: t("filters.startDate"),
                },
                {
                  align: "center",
                  label: t("table.expirationDate"),
                },
                {
                  align: "center",
                  label: t("freeTicketsBonus.givenAmount"),
                },
                {
                  align: "center",
                  label: t("reports.playerId"),
                },
                {
                  align: "center",
                  label: t("sidebar.player"),
                },
                {
                  align: "center",
                  label: t("table.state"),
                },
              ]}
              data={bonusData}
              lastPage={bonus?.last_page || 1}
              totalData={bonus?.total || 0}
              renderBody={(item) => <RowFreeTicketsBonus row={item} />}
              onPageChange={handlePageChange}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
