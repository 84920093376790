import { Types } from "../types/types";

export const uiSetError = (error: boolean) => ({
  type: Types.setError,
  payload: error,
});

export const uiSetErrorMessage = (message: string) => ({
  type: Types.setErrorMessage,
  payload: message,
});

export const uiSetOpenNotifications = ()=> ({
  type:Types.setOpenNotifications
})
export const uiCloseNotifications = ()=> ({
  type:Types.closeNotifications
})
export const uiSetOpenDialog = ()=> ({
  type:Types.setOpenDialog
})
export const uiSetDialogMessage = (msg:string|null)=> ({
  type:Types.setDialogMessage,
  payload:msg
})
export const uiSetNetworkState = (state:"Online" | "Offline")=> ({
  type:Types.setNetworkState,
  payload:state
})