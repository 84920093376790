import { View, Text } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { State } from "../../../enums/enums";
import { ICompanyData } from "../../../interfaces/operationCompany";
import { stylesPdf } from "../../../views/components/custom/customStyles";

interface Props {
  page: any;
}

export const ManagementCompaniesPdfDocument: FC<Props> = ({ page }) => {
  const { t } = useTranslation();
  const columnsCompanies = [
    t("companyManagement.companyName").toUpperCase(),
    t("companyManagement.nit").toUpperCase(),
    t("user.country").toUpperCase(),
    t("table.creationDate").toUpperCase(),
    t("table.createdBy").toUpperCase(),
    t("table.state").toUpperCase(),
  ];
  const columns = columnsCompanies;
  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column) => (
          <View key={column} style={[stylesPdf.pCol, stylesPdf.col]}>
            <Text style={stylesPdf.text}>{column}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        {page.map((page: any) => (
          <View key={page.id} style={stylesPdf.row}>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>
                {(page as ICompanyData).name}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>
                {(page as ICompanyData).nit}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>
                {(page as ICompanyData).country.name}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>
                {moment((page as ICompanyData).createAt).format("DD/MM/YYYY")}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>
                {(page as ICompanyData).createdUser?.nameComplete}
              </Text>
            </View>
            {((page as ICompanyData).stateOperator === State.ACTIVE ||
              page.state === "Activo") && (
              <View
                style={[
                  stylesPdf.pCol,
                  stylesPdf.col,
                  {
                    backgroundColor: "#c6efce",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                ]}
              >
                <Text style={[stylesPdf.textContent, { color: "#0d6a44" }]}>
                  {t("state.active")}
                </Text>
              </View>
            )}
            {((page as ICompanyData).stateOperator === State.INACTIVE ||
              page.state === "Inactivo") && (
              <View
                style={[
                  stylesPdf.pCol,
                  stylesPdf.col,
                  {
                    backgroundColor: "#ffc7ce",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                ]}
              >
                <Text style={[stylesPdf.textContent, { color: "#ad120b" }]}>
                  {t("state.inactive")}
                </Text>
              </View>
            )}
          </View>
        ))}
      </View>
    </>
  );
};
