import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  startGetCards,
  startInactivateCards,
  startUpdateCard,
} from "../../../redux/actions/managementCardsActions";
import { MyPagination } from "../../../components/Custom/Table/MyPagination";
import { arraySearch } from "../../../redux/actions/searchActions";
import { State, StateCards } from "../../../enums/enums";
import Barcode from "react-barcode";
import { getTotalWallet } from "../../../services/cards";
import { MyCard } from "../../../components/Custom/MyCard";
import { getBarcodeCard, roundCommission } from "../../../utils/utils";
import moment from "moment";

export const DetailsDealerWallet = () => {
  const { activeCardDealer } = useSelector(
    (state: AppState) => state.managementCards
  );

  const { token } = useSelector((state: AppState) => state.auth);
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);

  const { data: cards } = useSelector((state: AppState) => state.search);
  const [cardIds, setCardIds] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isInactivating, setIsInactivating] = useState(false);
  const [dealerCardDetails, setDealerCardDetails] = useState<any>(null);
  const [paramFilter, setParamFilter] = useState<any>({});
  const [stateFilter, setStateFilter] = useState<StateCards | null>(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fetchCards = async () => {
    if (!activeCardDealer) {
      throw new Error("Invalid action");
    }

    const params = {
      dealer: activeCardDealer.dealer.id,
      lotId: activeCardDealer.lot.id,
      page: 1,
    };
    setParamFilter(params);

    await dispatch(
      startGetCards({
        ...params,
      })
    );
  };
  const fetchDetails = async () => {
    if (activeCardDealer && token) {
      const dealerId = activeCardDealer.dealer.id;
      const params = {
        dealer: dealerId,
        lotId: activeCardDealer.lot.id,
      };
      const rs = await getTotalWallet(params, token);
      if (!rs.error) {
        const roundedCommission = roundCommission(rs.totalComission);
        const roundTotalRecibe = roundCommission(
          rs.totalSell - roundedCommission
        );

        const deatailsCards: any[] = [
          {
            background: "#02D0E780",
            title: t("cards.deliveredCards").toUpperCase(),
            content: rs.amountCards,
          },
          {
            background: "#02D0E780",
            title: t("cards.selledCards").toUpperCase(),
            content: rs.amountSell,
          },
          {
            background: "#02D0E7",
            title: t("cards.valueCollected").toUpperCase(),
            content: `$${rs.totalSell} ${rs.currency.code}`,
          },
          {
            background: "#02D0E7",
            title: t("cards.commissionToDeliver").toUpperCase(),
            content: `$${roundedCommission} ${rs.currency.code}`,
          },
          {
            background: "#02D0E7",
            title: t("cards.totalToReceive").toUpperCase(),
            content: `$${roundTotalRecibe} ${rs.currency.code}`,
          },
        ];
        setDealerCardDetails(deatailsCards);
      }
    }
  };
  useEffect(() => {
    fetchCards();
    fetchDetails();

    return () => {
      dispatch(arraySearch(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setIsFiltering(false);
  }, [cardIds, stateFilter]);

  const handlePageChange = async (page: number, rows?: number) => {
    if (activeCardDealer) {
      setLoading(true);

      await dispatch(
        startGetCards({
          ...paramFilter,
          page: page,
          perPage: rows || rowsPerPage,
        })
      );
      setLoading(false);
    }
  };
  const onChangeState = async (card: any) => {
    await dispatch(
      startUpdateCard(card.id, {
        state: card.state === State.ACTIVE ? State.INACTIVE : State.ACTIVE,
      })
    );
    setCardIds("");
  };

  const handleFilter = async () => {
    let ids = cardIds.split(",");
    if (!activeCardDealer) {
      throw new Error("Invalid action");
    }

    ids = ids.filter((id) => id !== "");

    setLoading(true);
    let params: any = {
      //from,
      lotId: activeCardDealer.lot.id,
      dealer: activeCardDealer.dealer.id,
    };

    if (ids.length > 0) {
      const from = ids[0];
      const to = ids[1];
      params = {
        ...params,
        from,
      };
      if (to) {
        params = {
          ...params,

          to,
        };
      }
    }
    if (stateFilter) {
      params = {
        ...params,
        state: stateFilter,
      };
    }

    await dispatch(
      startGetCards({
        ...params,
        page: 1,
      })
    );
    setParamFilter(params);
    setLoading(false);
    setIsFiltering(true);
  };
  const handleCleanFilter = async () => {
    setCardIds("");
    setStateFilter(null);
    setIsFiltering(false);
    setLoading(true);
    if (!activeCardDealer) {
      throw new Error("Invalid action");
    }
    await dispatch(
      startGetCards({
        dealer: activeCardDealer.dealer.id,
        lotId: activeCardDealer.lot.id,
        page: cards.page || 1,
      })
    );
    setParamFilter({
      dealer: activeCardDealer.dealer.id,
      lotId: activeCardDealer.lot.id,
    });

    setLoading(false);
  };
  const handleInactiveCards = async () => {
    const ids = cardIds.split(",");

    const from = ids[0];
    const to = ids[1];

    setIsInactivating(true);
    await dispatch(startInactivateCards(parseInt(from), parseInt(to)));
    if (!activeCardDealer) {
      throw new Error("Invalid action");
    }
    await dispatch(
      startGetCards({
        ...paramFilter,
        page: cards.page || 1,
      })
    );
    setIsInactivating(false);
  };
  const getStateName = (state: StateCards) => {
    switch (state) {
      case StateCards.ACTIVE:
        return t("state.active");
      case StateCards.INACTIVE:
        return t("state.inactive");
      case StateCards.REDEEMED:
        return t("cards.redeemed");

      default:
        return t("state.inactive");
    }
  };
  const cols = [
    {
      label: t("cards.consecutiveCard").toUpperCase(),
      col: 1.7,
    },
    {
      label: t("cards.barcode").toUpperCase(),
      col: 2,
    },
    {
      label: t("form.value").toUpperCase(),
      col: 1,
    },
    {
      label: t("sidebar.player") + " ID".toUpperCase(),
      col: 1.3,
    },
    {
      label: t("table.activationDate").toUpperCase(),
      col: 2.5,
    },
    {
      label: t("form.state").toUpperCase(),
      col: 1.5,
    },
    {
      label: t("table.actions").toUpperCase(),
      col: 1,
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <TextField
          name="state"
          label={t("table.state")}
          size="small"
          fullWidth
          variant="outlined"
          value={stateFilter || ""}
          onChange={(e) => setStateFilter(e.target.value as any)}
          select
        >
          {[StateCards.ACTIVE, StateCards.INACTIVE, StateCards.REDEEMED].map(
            (state) => (
              <MenuItem key={state} value={state}>
                {getStateName(state)}
              </MenuItem>
            )
          )}
        </TextField>
      </Grid>
      <Grid item xs={8} mb={2}>
        <TextField
          name="cardIds"
          label="Ingresa el rango de ids separados por (,)"
          size="small"
          fullWidth
          variant="outlined"
          value={cardIds}
          onChange={(e) => setCardIds(e.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <button
          className="d-flex btn-light-dark"
          type="button"
          style={{ width: "100%" }}
          onClick={() => (isFiltering ? handleCleanFilter() : handleFilter())}
          disabled={!!loading}
        >
          <div className="p-0 text-right">
            <i className="fa fa-filter btn-icon pt-1 fa-md"></i>
          </div>
          <div className="p-0 text-left">
            <label className="btn-label pl-1">
              {isFiltering ? t("filters.clean") : t("filters.filter")}
            </label>
          </div>
        </button>
      </Grid>
      {cards &&
        isFiltering &&
        cards.total > 1 &&
        cardIds.split(",").length === 2 && (
          <Grid item xs={3} mb={2}>
            <Button
              style={{
                border: "none",
                outline: "none",
                fontSize: 14,
              }}
              variant="contained"
              className="btn-dialog primary-btn"
              onClick={handleInactiveCards}
              disabled={isInactivating}
            >
              {t("cards.inactiveCards")}
              <i className="fa-solid fa-ban ml-2"></i>
            </Button>
          </Grid>
        )}
      {loading ? (
        <Box
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
          width="100%"
          mt={3}
        >
          <CircularProgress className={"colorLoading"} />
        </Box>
      ) : (
        <>
          <Grid item xs={12} bgcolor={"#F4F4F4"}>
            <Grid container spacing={2}>
              {cols.map(({ col, label }) => (
                <Grid item xs={col} key={col}>
                  <Typography variant="body1" fontWeight={"bold"} color="#333">
                    {label.toUpperCase()}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {cards &&
              cards.data.map((card: any) => (
                <Grid
                  container
                  spacing={2}
                  bgcolor={card.activationDate ? "#02D0E71A" : "#fff"}
                  mt={1}
                  key={card.id}
                  alignItems="center"
                >
                  <Grid item xs={1.7}>
                    <Typography variant="body1" color="#333">
                      {card.id}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Barcode
                      format="EAN13"
                      value={getBarcodeCard(card.value)}
                      height={15}
                      width={1}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body1" color="#333">
                      {"$" + card.value}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.3}>
                    <Typography variant="body1" color="#333">
                      {card.externalUsersCards.length > 0
                        ? card.externalUsersCards[0].externalPlayerId
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Typography variant="body1" color="#333">
                      {card.activationDate &&
                        moment(card.activationDate).format(
                          "YYYY-MM-DD hh:mm:ss A"
                        )}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography variant="body1" color="#333">
                      {card.activationDate
                        ? t("cards.redeemed").toUpperCase()
                        : card.state === State.ACTIVE
                        ? t("state.active").toUpperCase()
                        : t("state.inactive").toUpperCase()}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title={t("button.changeState") as string} arrow>
                      <i
                        style={{
                          pointerEvents:
                            card.used || card.state === State.INACTIVE
                              ? "none"
                              : "all",
                          opacity: card.used ? 0.5 : 1,
                        }}
                        className={
                          card.state === State.ACTIVE || card.used
                            ? `fa fa-eye fa-lg mr-2 iconSee`
                            : `fa-regular fa-eye-slash fa-lg mr-2 iconSee`
                        }
                        onClick={() => !card.used && onChangeState(card)}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              ))}
          </Grid>

          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            {!loading && cards && (
              <MyPagination
                onPageChange={handlePageChange}
                currentPage={cards.page}
                lastPage={cards.last_page || 1}
                siblingCount={1}
                total={cards.total || 0}
              />
            )}
          </Grid>
          {dealerCardDetails && (
            <Grid item xs={10}>
              <Grid container spacing={2}>
                {dealerCardDetails.map((item: any, index: any) => (
                  <Grid item xs={2.4} key={index}>
                    <MyCard
                      onClick={() => {}}
                      {...item}
                      wrapperStyleProps={{
                        height: "90px",
                      }}
                      titleStyleProps={
                        index <= 3
                          ? {
                              color: "#333333",
                              fontSize: "14px",
                            }
                          : {
                              fontSize: "14px",
                            }
                      }
                      contentStyleProps={
                        index <= 3
                          ? {
                              color: "#333333",
                              fontSize: "20px",
                            }
                          : {
                              fontSize: "20px",
                            }
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
