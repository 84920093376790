import { useEffect, useState } from "react";
import { Alert, AlertTitle, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../../../../../components/Filter/Filter";

import {
  Enum_FieldType,
  IFilterField,
} from "../../../../../interfaces/components";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import InfoIcon from "@mui/icons-material/Info";
import "../tabsGamingActivity.scss";
import { Row } from "reactstrap";
import MyCustomTable from "../../../../../components/Custom/Table/MyCustomTable";
import { RowDataTableCasino } from "./RowDataTableCasino";
import { ICasinoGames, ICasinoQuery } from "../../../../../interfaces/casino";
import {
  CategoriesCasinoGames,
  GameResult,
  ProvidersCasino,
} from "../../../../../enums/enums";
import { startGetCasinoActivity } from "../../../../../redux/actions/casinoActivity";
import {
  asyncLoadingFunction,
  setCloseAlert,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../../redux/actions/helperActions";
import { arraySearch } from "../../../../../redux/actions/searchActions";
import {
  getCasinoActivity,
  getGamesDataBase,
} from "../../../../../services/casino";
import { GamingActivityCasinoPdf } from "../../../../../components/Pdf/activityPlayer/GamingActivityCasinoPdf";
import { GamingActivityCasinoDataTable } from "../../../../../hooks/useDataTable/activityPlayer/GamingActivityCasinoDataTable";
import { IPaginationData } from "../../../../../interfaces/shared";

export const TabCasino = () => {
  const { t } = useTranslation();
  const { token } = useSelector((state: AppState) => state.auth);
  const { player } = useSelector((state: AppState) => state.managementPlayers);
  const { flagCleanFilter, rowsPerPage } = useSelector(
    (state: AppState) => state.helper
  );
  const { data } = useSelector((state: AppState) => state.search);
  const [cleanFilter, setCleanFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState<any>({});
  const [games, setGames] = useState<IPaginationData<ICasinoGames> | null>(
    null
  );
  const dispatch = useDispatch();
  const gameCategoriesOptions = Object.values(CategoriesCasinoGames).map(
    (category) => ({
      label: category,
      value: category,
    })
  );

  useEffect(() => {
    const handleGetGames = async () => {
      const games = token && (await getGamesDataBase(token));
      games && setGames(games);
    };
    handleGetGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (flagCleanFilter) {
      return setCleanFilter(true);
    }
    return setCleanFilter(false);
  }, [flagCleanFilter]);

  /**
   * Datos inputs para filtro
   */
  const FilterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("bets.gameType"),
      type: Enum_FieldType.SELECT,
      options: gameCategoriesOptions,
    },
    {
      label: t("bets.gameTitle"),
      type: Enum_FieldType.SELECT,
      options: games
        ? [
            ...games?.data.map((game: any) => ({
              id: game.id,
              label: game.name,
              value: game.gameCode,
            })),
          ]
        : [],
    },
    {
      label: t("bets.results"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("betResults.won"),
          value: GameResult.WIN,
        },
        {
          label: t("betResults.loss"),
          value: GameResult.LOST,
        },
      ],
    },

    {
      label: t("bets.betAmount"),
      type: Enum_FieldType.NUMBER,
    },
    {
      label: t("bets.winningAmount"),
      type: Enum_FieldType.NUMBER,
    },
    {
      label: t("bets.platform"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: ProvidersCasino.PRAGMATICPLAY,
          value: ProvidersCasino.PRAGMATICPLAY,
        },
        {
          label: ProvidersCasino.PARIPLAY,
          value: ProvidersCasino.PARIPLAY,
        },
      ],
    },
    {
      label: t("bets.internalId"),
      type: Enum_FieldType.TEXT,
    },
  ];

  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startGetCasinoActivity({
            ...dataFilter,
            page,
            perPage: rows || rowsPerPage,
          })
        )
      )
    );
  };
  const handleFilter = async (values: any) => {
    if (player) {
      const params: Partial<ICasinoQuery> = {
        playerId: (player as any).data[0].id,
        startDate: values[t("filters.startDate")],
        endDate: values[t("filters.endDate")],
        gameType: values[t("bets.gameType")],
        result: values[t("bets.results")],
        amount: values[t("bets.betAmount")],
        amountWin: values[t("bets.winningAmount")],
        gameTitle: values[t("bets.gameTitle")],
        internalId: values[t("bets.internalId")],
        platform: values[t("bets.platform")],
        page: 1,
      };
      setDataFilter(params);

      if (params.endDate?.trim() && !params.startDate?.trim()) {
        dispatch(setMessageAlert("alerts.selectStartDate"));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }

      dispatch(
        asyncLoadingFunction(() => dispatch(startGetCasinoActivity(params)))
      );
    }
  };

  useEffect(() => {
    dispatch(setCloseAlert());
    return () => {
      dispatch(setCloseAlert());
      dispatch(arraySearch(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActivityCasinoAction = async () => {
    if (player && token) {
      const { page, perPage, ...rest } = dataFilter;
      const params: Partial<ICasinoQuery> = {
        ...rest,
        playerId: (player as any).data[0].id,
      };
      return await getCasinoActivity(token, params);
    }
  };

  return (
    <div className="container-fluid">
      <Row
        style={{
          marginTop: "12px",
          pointerEvents: player && player.data.length > 0 ? "all" : "none",
          opacity: player && player.data.length > 0 ? 1 : 0.4,
        }}
      >
        <Filter
          fields={FilterFields}
          cleanFilter={cleanFilter}
          handleFilter={handleFilter}
        />
      </Row>
      {data && data.data.length > 0 && data.data[0].eventDescription ? (
        <>
          <MyCustomTable
            columns={[
              {
                align: "center",
                label: t("table.date"),
              },
              {
                align: "center",
                label: t("bets.internalId"),
              },
              {
                align: "center",
                label: t("bets.gameType"),
              },
              {
                align: "center",
                label: t("bets.gameTitle"),
              },
              {
                align: "center",
                label: t("bets.betAmount"),
              },
              {
                align: "center",
                label: t("bets.winningAmount"),
              },
              {
                align: "center",
                label: t("bets.result"),
              },
              {
                align: "center",
                label: t("bets.bonus"),
              },
              {
                align: "center",
                label: t("bets.supplier"),
              },
              {
                align: "center",
                label: t("bets.platform"),
              },
            ]}
            data={data.data}
            lastPage={data.last_page}
            totalData={data.total}
            exportData
            renderBody={(item) => <RowDataTableCasino row={item} />}
            onPageChange={handlePageChange}
            pdfComponent={GamingActivityCasinoPdf}
            hookBuildTable={GamingActivityCasinoDataTable}
            getAllDataToExport={getActivityCasinoAction}
            titleCSV={t("table.neatCasino")}
          />
        </>
      ) : (
        <Grid item xs={12} pt={3} mt={-2}>
          <Stack
            sx={{ width: "100%" }}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
            spacing={2}
          >
            <Alert
              icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
              sx={{
                borderLeft: `7px solid #2C97DF`,
                backgroundColor: "#EAF5FC",
                color: "#4b4b4b",
              }}
            >
              <div className="d-flex">
                <AlertTitle
                  style={{ marginTop: "5px" }}
                  sx={{ color: "#333333" }}
                >
                  {t("alerts.clickButton")}
                </AlertTitle>
                <AlertTitle
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  sx={{ color: "#333333", fontWeight: "bold" }}
                >
                  {t("alerts.refillBets")}
                </AlertTitle>
              </div>
            </Alert>
          </Stack>
        </Grid>
      )}
    </div>
  );
};
