import {
  IConciliation,
  ICreateExtenalUser,
  IExternalUsersData,
} from "../../interfaces/commercialNetworks";
import { IPaginationData } from "../../interfaces/shared";
import {
  getExternalUsers,
  createCommercialNetwork,
  updateCommercialNetwork,
  getConciliations,
} from "../../services/commercialNetworks";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import { arraySearch } from "./searchActions";
import {
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";
import i18next from "i18next";

export const startGetExternalUsers = (params: any, filter?: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    if (!token || !user) {
      throw new Error("INVALID ACTION");
    }

    if (params.page) {
      params.page = params.page.toString();
      params.perPage = rowsPerPage.toString();
    }
    try {
      let finalValues: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (value && (value.length > 0 || typeof value === "number")) {
          finalValues[key] = value;
        }
      });
      const response = await getExternalUsers(finalValues);

      if (response && response.data) {
        if (response.data?.length === 0) {
          dispatch(setMessageAlert("alerts.noData"));
          dispatch(setTypeAlert("warning"));
          dispatch(setOpenAlert());
          filter
            ? dispatch(arraySearch(null))
            : dispatch(setExternalUsers(null));
        } else {
          filter
            ? dispatch(arraySearch(response))
            : dispatch(setExternalUsers(response));
        }
      } else {
        dispatch(setMessageAlert("alerts.errorSetCommercialNetworks"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const setExternalUsers = (
  networks: IPaginationData<IExternalUsersData> | null
) => ({
  type: Types.setCommercialNetworks,
  payload: networks,
});

export const createNewCommercialNetwork = (data: ICreateExtenalUser) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;

    if (!token || !user) {
      throw new Error("INVALID ACTION");
    }
    const response = await createCommercialNetwork(data);

    if (response && !response.statusCode) {
      await dispatch(
        startGetExternalUsers({ operatorId: user?.operator?.id, page: 1 })
      );

      dispatch(setMessageAlert(`commercialNetworks.createNetworkSuccess`));
      dispatch(setTypeAlert("success"));
      dispatch(setFlagForm({ flagFormCreate: false }));
    } else {
      let errorMessage = "commercialNetworks.createNetworkError";
      if (response && response.message) {
        const match = response.message.match(/\(([^)]+)\)=/);
        if (match && match[1]) {
          const fieldName = match[1];
          errorMessage = i18next.t(`commercialNetworks.fieldAlreadyExists`, {
            field: i18next.t(`commercialNetworks.${fieldName}`)
          });
        }
      }
      dispatch(setMessageAlert(errorMessage));
      dispatch(setTypeAlert("error"));
    }
    dispatch(setOpenAlert());
  };
};

export const startUpdateCommercialNetwork = (
  id: number,
  dataNet: Partial<IExternalUsersData>,
  isTableAction?: boolean,
  showTotal?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { data } = (getState() as AppState).search;
    const { networks } = (getState() as AppState).commercialNetworks;

    try {
      if (user && token) {
        const response = await updateCommercialNetwork(id, dataNet);

        if (response && !response.statusCode) {
          if (isTableAction) {
            const newData = data ? { ...data } : networks && { ...networks };

            newData.data = newData.data.map((el: IExternalUsersData) =>
              el.id === id ? { ...el, ...dataNet } : el
            );

            dispatch(data ? arraySearch(newData) : setExternalUsers(newData));
          } else {
            dispatch(arraySearch(null));
            await dispatch(
              startGetExternalUsers({
                operatorId: user?.operator?.id,
                page: showTotal ? null : 1,
              })
            );
          }

          dispatch(setMessageAlert("commercialNetworks.updatedNetwork"));
          dispatch(setTypeAlert("success"));
        } else {
          dispatch(setMessageAlert("commercialNetworks.updatedNetworkError"));
          dispatch(setTypeAlert("error"));
        }
        dispatch(setOpenAlert());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const startGetConciliations = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    if (!token || !user) {
      throw new Error("INVALID ACTION");
    }

    if (params.page) {
      params.page = params.page.toString();
      params.perPage = rowsPerPage.toString();
    }
    try {
      let finalValues: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (value && (value.length > 0 || typeof value === "number")) {
          finalValues[key] = value;
        }
      });
      const response = await getConciliations(finalValues);

      if (response && response.data) {
        if (response.data?.length === 0) {
          dispatch(setMessageAlert("alerts.noData"));
          dispatch(setTypeAlert("warning"));
          dispatch(setOpenAlert());
          dispatch(setConciliations(null));
        } else {
          dispatch(setConciliations(response));
        }
      } else {
        dispatch(setMessageAlert("alerts.errorSetConciliations"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const setConciliations = (
  conciliations: IPaginationData<IConciliation> | null
) => ({
  type: Types.setConciliations,
  payload: conciliations,
});
