import i18next from "../i18n";

export const headersUsers = [
  { label: i18next.t("table.userId").toUpperCase(), key: "id" },
  { label: i18next.t("table.name").toUpperCase(), key: "nameComplete" },
  { label: i18next.t("table.email").toUpperCase(), key: "email" },
  { label: i18next.t("table.creationDate").toUpperCase(), key: "createAt" },
  { label: i18next.t("table.role").toUpperCase(), key: "roles.name" },
  {
    label: i18next.t("table.createdBy").toUpperCase(),
    key: "createdUser.nameComplete",
  },
  {
    label: i18next.t("table.state").toUpperCase(),
    key: "stateUser",
  },
  {
    label: `${i18next.t("table.state").toUpperCase()} ESP`,
    key: "",
  },
  {
    label: i18next.t("table.aut2Steps").toUpperCase(),
    key: "verificationTwoStep",
  },
  {
    label: i18next.t("table.failedAttempts").toUpperCase(),
    key: "userLoginHistory",
  },
  {
    label: i18next.t("user.observation").toUpperCase(),
    key: "observation",
  },
  {
    label: i18next.t("user.login").toUpperCase(),
    key: "username",
  },
];

export const headersMenus = [
  { label: i18next.t("table.menu").toUpperCase(), key: "url" },
  { label: i18next.t("table.nameRol").toUpperCase(), key: "name" },
  { label: i18next.t("table.icon").toUpperCase(), key: "icon" },
  {
    label: i18next.t("table.createdBy").toUpperCase(),
    key: "createdUser.nameComplete",
  },
  { label: i18next.t("table.state").toUpperCase(), key: "state" },
];

export const headersRefills = [
  { label: i18next.t("player.refillNumber").toUpperCase(), key: "id" },
  {
    label: i18next.t("withdrawals.externalId").toUpperCase(),
    key: "externalId",
  },
  { label: i18next.t("login.user").toUpperCase(), key: "player.nameComplete" },
  { label: i18next.t("form.value").toUpperCase(), key: "value" },
  { label: i18next.t("form.description").toUpperCase(), key: "description" },
  { label: i18next.t("form.network").toUpperCase(), key: "" },
  { label: i18next.t("table.creationDate").toUpperCase(), key: "createAt" },
];

export const headersWithdrawalHistory = [
  {
    label: i18next.t("withdrawals.withdrawalNumber").toUpperCase(),
    key: "id",
  },
  {
    label: i18next.t("withdrawals.externalId").toUpperCase(),
    key: "externalID",
  },
  { label: i18next.t("login.user").toUpperCase(), key: "player.nameComplete" },
  {
    label: i18next.t("withdrawals.requestedValue").toUpperCase(),
    key: "withdrawalsValue",
  },
  {
    label: i18next.t("withdrawals.valueToWithdraw").toUpperCase(),
    key: "paidValue",
  },
  { label: i18next.t("table.state").toUpperCase(), key: "state" },
  { label: i18next.t("form.network").toUpperCase(), key: "provider" },
  { label: i18next.t("table.creationDate").toUpperCase(), key: "createAt" },
];

export const headersOperatorConfig = [
  {
    label: i18next.t("companyManagement.company").toUpperCase(),
    key: "operator.name",
  },
  { label: i18next.t("companyManagement.brand").toUpperCase(), key: "name" },
  { label: i18next.t("user.country").toUpperCase(), key: "countriesCSV.name" },
  {
    label: i18next.t("pointsOfSale.games").toUpperCase(),
    key: "countriesCSV.games",
  },
  {
    label: i18next.t("table.currency").toUpperCase(),
    key: "countriesCSV.currencies",
  },
  {
    label: i18next.t("companyManagement.methodDeposit").toUpperCase(),
    key: "countriesCSV.deposits",
  },
  {
    label: i18next.t("companyManagement.methodWithdrawal").toUpperCase(),
    key: "countriesCSV.payments",
  },
  {
    label: i18next.t("parameterization.solutions").toUpperCase(),
    key: "countriesCSV.solutions",
  },
];

export const headersManagementCompanies = [
  {
    label: i18next.t("companyManagement.companyName").toUpperCase(),
    key: "name",
  },
  { label: i18next.t("companyManagement.nit").toUpperCase(), key: "nit" },
  { label: i18next.t("user.country").toUpperCase(), key: "country.name" },
  { label: i18next.t("table.creationDate").toUpperCase(), key: "createAt" },
  {
    label: i18next.t("table.state").toUpperCase(),
    key: "state",
  },
  {
    label: i18next.t("table.createdBy").toUpperCase(),
    key: "createdUser.nameComplete",
  },
];
export const headersTransactionsSettings = [
  {
    label: i18next.t("transactions.setId").toUpperCase(),
  },
  {
    label: i18next.t("table.state").toUpperCase(),
  },
  {
    label: i18next.t("sidebar.player").toUpperCase(),
  },
  {
    label: i18next.t("transactions.movementType").toUpperCase(),
  },
  { label: i18next.t("transactions.wallet").toUpperCase() },
  {
    label: i18next.t("transactions.setType").toUpperCase(),
  },
  //transactionalSettings.detail
  { label: i18next.t("transactionalSettings.detail").toUpperCase() },
  { label: i18next.t("table.role").toUpperCase() },
  { label: i18next.t("transactions.value").toUpperCase() },
  { label: i18next.t("user.createAt").toUpperCase() },
  {
    label: i18next.t("transactionsReport.observations").toUpperCase(),
  },
];
export const headersLegal = [
  { label: i18next.t("legal.version").toUpperCase(), key: "version" },
  { label: i18next.t("legal.title").toUpperCase(), key: "title" },
  {
    label: i18next.t("table.state").toUpperCase(),
    key: "state",
  },
  { label: i18next.t("user.createAt").toUpperCase(), key: "createAt" },
];

export const headersIrrigation = [
  {
    label: i18next.t("irrigation.associatedID").toUpperCase(),
    key: "associate.id",
  },
  { label: i18next.t("user.createAt").toUpperCase(), key: "createAt" },
  {
    label: i18next.t("irrigation.associatesName").toUpperCase(),
    key: "associate.name",
  },
  {
    label: i18next.t("transactions.wallet").toUpperCase(),
    key: "wallet",
  },
  {
    label: i18next.t("irrigation.transactionConcept").toUpperCase(),
    key: "transferConcept",
  },
  {
    label: i18next.t("irrigation.transferValue").toUpperCase(),
    key: "value",
  },
];

export const headersBetHistory = [
  {
    label: i18next.t("table.betId").toUpperCase(),
    key: "id",
  },
  { label: i18next.t("bets.ticketNumber").toUpperCase(), key: "reference" },
  {
    label: i18next.t("bets.dateBet").toUpperCase(),
    key: "createAt",
  },
  {
    label: i18next.t("bets.hourBet").toUpperCase(),
    key: "createAt",
  },
  {
    label: i18next.t("bets.winningAmount").toUpperCase(),
    key: "amount",
  },
  {
    label: i18next.t("bets.bettingStatus").toUpperCase(),
    key: "status",
  },
];
export const headersActivityPlayer = [
  {
    label: i18next.t("table.date").toUpperCase(),
  },
  {
    label: i18next.t("playerActivity.activityNumber").toUpperCase(),
  },
  {
    label: i18next.t("playerActivity.activityType").toUpperCase(),
  },
  {
    label: i18next.t("commercialNetworks.transactionStatus").toUpperCase(),
  },
  {
    label: i18next.t("form.value").toUpperCase(),
  },
  { label: i18next.t("playerActivity.balance").toUpperCase() },
  { label: i18next.t("managementBonds.provider").toUpperCase() },
  { label: i18next.t("bets.ticketNumber").toUpperCase() },
];

export const headersBets = [
  {
    label: i18next.t("table.betId").toUpperCase(),
    key: "id",
  },
  { label: i18next.t("user.createAt").toUpperCase(), key: "createAt" },
  { label: i18next.t("bets.betType").toUpperCase(), key: "type" },
  {
    label: i18next.t("bets.event").toUpperCase(),
    key: "event",
  },
  {
    label: i18next.t("bets.betAmount").toUpperCase(),
    key: "amount",
  },
  {
    label: i18next.t("bets.winningAmount").toUpperCase(),
    key: "winning",
  },
  {
    label: i18next.t("bets.feeds").toUpperCase(),
    key: "feeds",
  },
  {
    label: i18next.t("bets.bonus").toUpperCase(),
    key: "bonus",
  },
  {
    label: i18next.t("table.state").toUpperCase(),
    key: "statusName",
  },
];

export const headersWithdrawalsCash = [
  {
    label: i18next.t("withdrawals.withdrawalNumber").toUpperCase(),
    key: "id",
  },
  {
    label: `${i18next.t("table.playerId")}`.toUpperCase(),
    key: "player.id",
  },
  {
    label: `${i18next.t("user.documentNumber")}`.toUpperCase(),
    key: "player.documentNumber",
  },
  {
    label: i18next.t("filters.email").toUpperCase(),
    key: "filters.email",
  },
  {
    label: i18next.t("withdrawals.withholding").toUpperCase(),
    key: "withdrawalValue",
  },
  {
    label: `% ${i18next.t("withdrawals.withholdingValue")}`.toUpperCase(),
    key: "withholding",
  },
  {
    label: i18next.t("withdrawals.requestedValue").toUpperCase(),
    key: "withholdingValue",
  },
  {
    label: i18next.t("deposits.valuePaid").toUpperCase(),
    key: "paidValue",
  },
  {
    label: i18next.t("withdrawals.requestDate").toUpperCase(),
    key: "requestDate",
  },
];
export const headersWithdrawalsApproveCash = [
  {
    label: i18next.t("withdrawals.withdrawalNumber").toUpperCase(),
    key: "id",
  },
  { label: `${i18next.t("table.playerId")}`.toUpperCase(), key: "player.id" },
  {
    label: `${i18next.t("user.documentNumber")}`.toUpperCase(),
    key: "player.documentNumber",
  },
  {
    label: i18next.t("filters.email").toUpperCase(),
    key: "filters.email",
  },
  {
    label: i18next.t("withdrawals.approvedDate").toUpperCase(),
    key: "withdrawals.approvedDate",
  },
  {
    label: i18next.t("withdrawals.requestedValue").toUpperCase(),
    key: "withdrawals.requestedValue",
  },
  {
    label: i18next.t("withdrawals.valuePaid").toUpperCase(),
    key: "withdrawals.valuePaid",
  },
];
export const headersWithdrawalsCashExpired = [
  {
    key: "withdrawals.withdrawalNumber",
    label: i18next.t("withdrawals.withdrawalNumber"),
  },
  {
    label: `${i18next.t("table.playerId")}`.toUpperCase(),
    key: "player.id",
  },
  {
    key: "filters.email",
    label: i18next.t("filters.email"),
  },
  {
    key: "table.expirationDate",
    label: i18next.t("table.expirationDate"),
  },
  {
    key: "withdrawals.requestedValue",
    label: i18next.t("withdrawals.requestedValue"),
  },
  {
    key: "deposits.valuePaid",
    label: i18next.t("deposits.valuePaid"),
  },
];

export const headersWithdrawalsBank = [
  {
    label: i18next.t("withdrawals.withdrawalNumber").toUpperCase(),
  },
  { label: `${i18next.t("table.playerId")}`.toUpperCase() },
  { label: `${i18next.t("user.documentType")}`.toUpperCase() },
  { label: `${i18next.t("user.documentNumber")}`.toUpperCase() },
  {
    label: i18next.t("filters.username").toUpperCase(),
  },
  { label: `${i18next.t("user.email")}`.toUpperCase() },
  {
    label: i18next.t("associates.bank").toUpperCase(),
  },
  {
    label: i18next.t("associates.accountNumber").toUpperCase(),
  },
  {
    label: `% ${i18next.t("withdrawals.withholding")}`.toUpperCase(),
  },
  {
    label: i18next.t("withdrawals.withholdingValue").toUpperCase(),
  },
  {
    label: i18next.t("withdrawals.requestedValue").toUpperCase(),
  },
  {
    label: i18next.t("deposits.valuePaid").toUpperCase(),
  },
  {
    label: i18next.t("withdrawals.verifiedAccount").toUpperCase(),
  },
  {
    label: i18next.t("withdrawals.requestDate").toUpperCase(),
  },
];

export const headersWithdrawalsRejectBank = [
  {
    label: i18next.t("withdrawals.withdrawalNumber").toUpperCase(),
  },
  { label: `${i18next.t("table.playerId")}`.toUpperCase() },
  { label: `${i18next.t("user.documentType")}`.toUpperCase() },

  {
    label: `${i18next.t("user.documentNumber").toUpperCase()}`,
  },
  {
    label: `${i18next.t("user.email").toUpperCase()}`,
  },
  {
    label: i18next.t("associates.bank").toUpperCase(),
  },
  {
    label: i18next.t("associates.accountNumber").toUpperCase(),
  },
  {
    label: i18next.t("withdrawals.requestedValue").toUpperCase(),
  },
  {
    label: i18next.t("deposits.valuePaid").toUpperCase(),
  },
  {
    label: `${i18next.t("withdrawals.rejectDate").toUpperCase()}`,
  },
  {
    label: `${i18next.t("withdrawals.reason").toUpperCase()}`,
  },
  {
    label: i18next.t("withdrawals.verifiedAccount").toUpperCase(),
  },
];

export const headersBonusRedeemed = [
  {
    label: i18next.t("managementBonds.bondType").toUpperCase(),
    key: "bonus.altenarBonusType",
  },
  {
    label: i18next.t("managementBonds.bondName").toUpperCase(),
    key: "bonus.name",
  },
  {
    label: i18next.t("managementBonds.bondValue").toUpperCase(),
    key: "bonus.value",
  },
  {
    label: i18next.t("table.creationDate").toUpperCase(),
    key: "bonus.createAt",
  },
  {
    label: i18next.t("table.expirationDate").toUpperCase(),
    key: "bonus.endDate",
  },
  {
    label: i18next.t("form.state").toUpperCase(),
    key: "state",
  },
];

export const headersPlayerSessionReport = [
  {
    label: i18next.t("playerSessionReport.idSession").toUpperCase(),
    key: "id",
  },
  {
    label: i18next.t("table.playerId").toUpperCase(),
    key: "player.id",
  },
  {
    label: i18next.t("filters.name").toUpperCase(),
    key: "player.name",
  },
  {
    label: i18next.t("filters.startDate").toUpperCase(),
    key: "createAt",
  },
  {
    label: i18next.t("filters.endDate").toUpperCase(),
    key: "lastAt",
  },
  {
    label: i18next.t("playerSessionReport.ipAddress").toUpperCase(),
    key: "ip",
  },
  {
    label: i18next.t("playerSessionReport.balanceAtLogin").toUpperCase(),
    key: "balanceAtLogin",
  },
  {
    label: i18next.t("playerSessionReport.balanceAtLogout").toUpperCase(),
    key: "balanceAtLogout",
  },
  {
    label: i18next.t("playerSessionReport.totalBets").toUpperCase(),
    key: "total_bets",
  },
  {
    label: i18next.t("playerSessionReport.totalWon").toUpperCase(),
    key: "total_won",
  },
  {
    label: i18next.t("playerSessionReport.totalDeposits").toUpperCase(),
    key: "total_deposits",
  },
  {
    label: i18next.t("playerSessionReport.totalWithdrawals").toUpperCase(),
    key: "total_withdrawals",
  },
  {
    label: i18next.t("playerSessionReport.terminationReason").toUpperCase(),
    key: "log_description",
  },
];
export const headersNotificationChanges = [
  {
    label: i18next.t("bets.event").toUpperCase(),
    key: "bets.event",
  },
  {
    label: i18next.t("table.createdBy").toUpperCase(),
    key: "table.createdBy",
  },
  {
    label: i18next.t("playerLimit.playerAffected").toUpperCase(),
    key: "playerLimit.playerAffected",
  },
  {
    label: i18next.t("user.createAt").toUpperCase(),
    key: "bonus.createAt",
  },
];

export const headersExclusionReport = [
  {
    label: i18next.t("exclusionsReport.id").toUpperCase(),
    key: "id",
  },
  {
    label: i18next.t("table.playerId").toUpperCase(),
    key: "player.id",
  },
  {
    label: i18next.t("filters.name").toUpperCase(),
    key: "player.name",
  },
  {
    label: i18next.t("filters.startDate").toUpperCase(),
    key: "createAt",
  },
  {
    label: i18next.t("filters.endDate").toUpperCase(),
    key: "endDate",
  },
  {
    label: i18next.t("exclusionsReport.category").toUpperCase(),
    key: "category.id",
  },
  {
    label: i18next.t("exclusionsReport.exclusionType").toUpperCase(),
    key: "type.id",
  },
];

export const headersTransactionsReport = [
  {
    label: i18next.t("transactionsReport.id").toUpperCase(),
    key: "id",
  },
  {
    label: i18next.t("table.playerId").toUpperCase(),
    key: "player.id",
  },
  {
    label: i18next.t("filters.name").toUpperCase(),
    key: "player.name",
  },
  {
    label: i18next.t("filters.startDate").toUpperCase(),
    key: "createAt",
  },
  {
    label: i18next.t("transactionsReport.value").toUpperCase(),
    key: "value",
  },
  {
    label: i18next.t("transactionsReport.observations").toUpperCase(),
    key: "observations",
  },
];
export const headersPromotionalAcount = [
  {
    label: i18next.t("managementBonds.bondName").toUpperCase(),
    key: "managementBonds.bondName",
  },
  {
    label: i18next.t("form.value").toUpperCase(),
    key: "form.value",
  },
  {
    label: i18next.t("playerLimit.playerAffected").toUpperCase(),
    key: "playerLimit.playerAffected",
  },
  {
    label: i18next.t("managementBonds.totalBondsValue").toUpperCase(),
    key: "managementBonds.totalBondsValue",
  },
  {
    label: i18next.t("reports.totalValue").toUpperCase(),
    key: "reports.totalValue",
  },
  {
    label: i18next.t("managementBonds.bonusValueDelivered").toUpperCase(),
    key: "managementBonds.bonusValueDelivered",
  },
  {
    label: i18next.t("managementBonds.provider").toUpperCase(),
    key: "managementBonds.provider",
  },
  {
    label: i18next.t("table.createdBy").toUpperCase(),
    key: "table.createdBy",
  },
  {
    label: i18next.t("user.createAt").toUpperCase(),
    key: "user.createAt",
  },
  {
    label: i18next.t("companyManagement.operator").toUpperCase(),
    key: "companyManagement.operator",
  },
  {
    label: i18next.t("managementBonds.expiredBonus").toUpperCase(),
    key: "managementBonds.expiredBonus",
  },
];

export const headersDealer = [
  {
    label: i18next.t("managementDealers.idDealer").toUpperCase(),
    key: "idDealer",
  },
  {
    label: i18next.t("managementDealers.dealerName").toUpperCase(),
    key: "dealerName",
  },
  {
    label: i18next.t("managementDealers.dealerPhone").toUpperCase(),
    key: "dealerPhone",
  },
  {
    label: i18next.t("managementDealers.dealerEmail").toUpperCase(),
    key: "dealerEmail",
  },
  {
    label: i18next.t("managementDealers.comissionPercentage").toUpperCase(),
    key: "comissionPercentage",
  },
  {
    label: i18next.t("table.state").toUpperCase(),
    key: "state",
  },
];

export const headersGameRevenue = [
  {
    label: i18next.t("reports.totalAmountDeposits").toUpperCase(),
    key: "totalAmountDeposits",
  },
  {
    label: i18next.t("reports.totalWithdrawals").toUpperCase(),
    key: "totalWithdrawals",
  },
  {
    label: i18next.t("reports.totalFundsAvailable").toUpperCase(),
    key: "totalFundsAvailable",
  },
];
export const headersCashFlow = [
  {
    label: "ID",
  },
  {
    label: i18next.t("shorcuts.PointSale"),
  },
  {
    label: i18next.t("transactions.movementType"),
  },
  {
    label: i18next.t("sidebar.player"),
  },
  {
    label: i18next.t("transactions.valueTransaction"),
  },
  {
    label: i18next.t("withdrawals.netValueWithdrawal"),
  },
  {
    label: i18next.t("transactions.transactionType"),
  },
  {
    label: i18next.t("transactions.createAt"),
  },
];
export const headersCashFlowDeposit = [
  {
    label: i18next.t("reports.playerId"),
  },
  {
    label: i18next.t("reports.transactionValue"),
  },
  {
    label: i18next.t("reports.gateway"),
  },
  {
    label: i18next.t("reports.playerName"),
  },
  {
    label: i18next.t("table.email"),
  },
  {
    label: i18next.t("transactions.createAt"),
  },
  {
    label: i18next.t("observations.title"),
  },
];

export const headersIrrigationPv = [
  {
    label: "ID",
  },
  {
    label: i18next.t("transactions.transactionType"),
  },
  {
    label: i18next.t("transactions.wallet"),
  },
  {
    label: i18next.t("transactions.valueTransaction"),
  },
  {
    label: i18next.t("playerActivity.balance"),
  },
  {
    label: i18next.t("roles.ASSOCIATE"),
  },
  {
    label: i18next.t("user.createAt"),
  },
];
export const headersComission = [
  {
    label: i18next.t("transactions.movementType"),
  },
  {
    label: i18next.t("reports.quantity"),
  },
  {
    label: i18next.t("reports.totalValue"),
  },
  {
    label: i18next.t("dealerWallet.commission"),
  },
];
export const headersBetsReport = [
  {
    label: i18next.t("bets.ticketNumber"),
  },
  {
    label: i18next.t("reports.date"),
  },
  {
    label: i18next.t("reports.time"),
  },
  {
    label: i18next.t("reports.amount"),
  },
  {
    label: i18next.t("reports.seller"),
  },
];
export const headersAwardsPayReport = [
  {
    label: i18next.t("bets.ticketNumber"),
  },
  {
    label: i18next.t("reports.date"),
  },
  {
    label: i18next.t("reports.time"),
  },
  {
    label: i18next.t("reports.amount"),
  },
  {
    label: i18next.t("reports.payer"),
  },
];

export const headersGamingActivityCasino = [
  {
    label: i18next.t("table.date"),
  },
  {
    label: i18next.t("bets.internalId"),
  },
  {
    label: i18next.t("bets.gameType"),
  },
  {
    label: i18next.t("bets.gameTitle"),
  },
  {
    label: i18next.t("bets.betAmount"),
  },
  {
    label: i18next.t("bets.winningAmount"),
  },
  {
    label: i18next.t("bets.result"),
  },
  {
    label: i18next.t("bets.bonus"),
  },
  {
    label: i18next.t("bets.supplier"),
  },
  {
    label: i18next.t("bets.platform"),
  },
];
export const headersGamingActivityAviatrix = [
  {
    label: i18next.t("table.date"),
  },
  {
    label: i18next.t("bets.internalId"),
  },
  {
    label: i18next.t("bets.betAmount"),
  },
  {
    label: i18next.t("bets.winningAmount"),
  },
  {
    label: i18next.t("bets.round"),
  },
  {
    label: i18next.t("tabs.status"),
  },
];
export const headersGamingActivityGoldenRace = [
  {
    label: i18next.t("table.date"),
  },
  {
    label: i18next.t("bets.internalId"),
  },
  {
    label: i18next.t("bets.betAmount"),
  },
  {
    label: i18next.t("bets.winningAmount"),
  },
  {
    label: i18next.t("bets.gameName"),
  },
  {
    label: i18next.t("tabs.status"),
  },
];
export const headersIrrigationCardsHistory = [
  {
    label: i18next.t("reports.deliveredTo"),
  },
  {
    label: i18next.t("irrigation.lot"),
  },
  {
    label: i18next.t("form.value"),
  },
  {
    label: i18next.t("cards.consecutiveCard"),
  },
  {
    label: i18next.t("irrigation.redeemed"),
  },
  {
    label: i18next.t("irrigation.available"),
  },
  {
    label: i18next.t("irrigation.inactive"),
  },
  {
    label: i18next.t("reports.deliveredDate"),
  },
];

export const headersIrrigationCardsInventory = [
  {
    label: i18next.t("irrigation.lot"),
  },
  {
    label: i18next.t("form.value"),
  },
  {
    label: i18next.t("cards.consecutiveCard"),
  },
  {
    label: i18next.t("irrigation.redeemed"),
  },
  {
    label: i18next.t("irrigation.inactive"),
  },
  {
    label: i18next.t("irrigation.notAvailable"),
  },
  {
    label: i18next.t("irrigation.available"),
  },
];

export const headersCards = [
  {
    label: "ID",
  },
  {
    label: i18next.t("reports.deliveredTo"),
  },
  {
    label: i18next.t("form.value"),
  },
  {
    label: i18next.t("form.state"),
  },
  {
    label: i18next.t("cards.barcode"),
  },
  {
    label: i18next.t("user.observation"),
  },
];

export const headersBingoGameActivity = [
  {
    label: i18next.t("table.date"),
  },
  {
    label: i18next.t("bets.internalId"),
  },
  {
    label: i18next.t("bets.betAmount"),
  },
  {
    label: i18next.t("bets.winningAmount"),
  },
  {
    label: i18next.t("bets.round"),
  },
  {
    label: i18next.t("tabs.status"),
  },
];

export const headersAnonymousBetsReport = [
  { label: i18next.t("table.betId") },
  { label: i18next.t("bets.ticketNumber") },
  { label: i18next.t("bets.dateBet") },
  { label: i18next.t("bets.hourBet") },
  { label: i18next.t("bets.winningAmount") },
  { label: i18next.t("bets.betAmount") },
  { label: i18next.t("bets.bettingStatus") },
  { label: i18next.t("table.actions") },
];
export const headersAnonymouspayments = [
  {
    label: i18next.t("bets.ticketNumber"),
  },
  {
    label: i18next.t("bets.payDate"),
  },
  {
    label: i18next.t("bets.payTime"),
  },
  {
    label: i18next.t("bets.payAmount"),
  },
];

export const headersPromoter = [
  {
    label: i18next.t("managementPromoters.idPromoter").toUpperCase(),
  },
  {
    label: i18next.t("managementPromoters.promoterName").toUpperCase(),
  },
  {
    label: i18next.t("managementPromoters.promoterPhone").toUpperCase(),
  },
  {
    label: i18next.t("managementPromoters.promoterEmail").toUpperCase(),
  },
  {
    label: i18next.t("table.state").toUpperCase(),
  },
];

export const headersCommercialNetwork = [
  { label: i18next.t("commercialNetworks.commercialNetwork") },
  { label: i18next.t("companyManagement.nit") },
  { label: i18next.t("companyManagement.legalRepresentative") },
  { label: i18next.t("user.phone") },
  { label: i18next.t("user.email") },
  { label: i18next.t("user.createAt") },
];

export const headersCommercialNetworksReport = [
  { label: i18next.t("form.network") },
  { label: i18next.t("transactions.id") },
  { label: i18next.t("commercialNetworks.reference") },
  { label: i18next.t("playerActivity.activityType") },
  { label: i18next.t("commercialNetworks.transactionStatus") },
  { label: i18next.t("reports.playerId") },
  { label: i18next.t("user.documentNumber") },
  { label: i18next.t("form.value") },
  { label: i18next.t("commercialNetworks.codePoint") },
  { label: i18next.t("commercialNetworks.province") },
  { label: i18next.t("commercialNetworks.canton") },
  { label: i18next.t("commercialNetworks.dateUpdated") },
];

export const headersGamingActivityEvolution = [
  {
    label: i18next.t("table.date"),
  },
  {
    label: i18next.t("bets.internalId"),
  },
  {
    label: i18next.t("bets.betAmount"),
  },
  {
    label: i18next.t("bets.winningAmount"),
  },
  {
    label: i18next.t("bets.gameName"),
  },
  {
    label: i18next.t("tabs.status"),
  },
];

export const headersReporCardsPuntoPago = [
  {
    label: `${i18next.t("sidebar.player")} id`,
  },
  {
    label: `${i18next.t("user.phone")}`,
  },
  {
    label: `${i18next.t("withdrawals.requestDate")}`,
  },
];

export const headersAffiliations = [
  {
    label: "ID usuario",
  },
  {
    label: "Nombre",
  },
  {
    label: "Apellido",
  },
  {
    label: "Fecha de registro",
  },
  {
    label: "PV",
  },
  {
    label: "Modelo de Afiliación",
  },
  {
    label: "Apuestas",
  },
  {
    label: "Premios",
  },
  {
    label: "GGR",
  },
  {
    label: "Depósito",
  },
  {
    label: "Comisión",
  },
];

export const headersManagementBonus = [
  {
    label: `${i18next.t("managementBonds.bondName")}`,
  },
  {
    label: `${i18next.t("managementBonds.provider")}`,
  },
  {
    label: `${i18next.t("managementBonds.code")}`,
  },
  {
    label: `${i18next.t("form.state")}`,
  },
  {
    label: `${i18next.t("filters.startDate")}`,
  },
  {
    label: `${i18next.t("table.expirationDate")}`,
  },
  {
    label: `${i18next.t("managementBonds.bondValue")}`,
  },
  {
    label: `${i18next.t("managementBonds.amountBonds")}`,
  },
];

export const headersPlayerUpdateHistory = [
  {
    label: `${i18next.t("sidebar.player")} id`,
  },
  {
    label: i18next.t("transactionalSettings.detail"),
  },
  {
    label: i18next.t("user.observation"),
  },
  {
    label: i18next.t("form.responsible"),
  },
  {
    label: i18next.t("user.createAt"),
  },
];

export const headersIpAddresses = [
  {
    label: `${i18next.t("Id")}`,
  },
  {
    label: `${i18next.t("Ip")}`,
  },
  {
    label: `${i18next.t("user.createAt")}`,
  },
  {
    label: `${i18next.t("withdrawals.reason")}`,
  },
];
