import * as React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Divider, Box, CircularProgress, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useStyles } from "./customStyles";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: "10px 5px", ml: 2 }} {...other}>
      <Typography className={classes.title}>
        {" "}
        <i
          className="fa-solid fa-circle-exclamation mr-1"
          style={{ color: "#02D0E7" }}
        />{" "}
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          disableFocusRipple
          disableRipple
          className="hover-close"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 3,
            "&:focus": {
              outline: "none !important",
            },
            color: (theme) => theme.palette.grey[500],
            fontSize: "10px !important",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface Props {
  id?: string;
  open: boolean;
  handleClose: () => void;
  handleAccept: () => void;
  title: string | React.ReactNode;
  message: string | any;
  isLoading?: boolean;
  component?: string;
}

export const MyDialog: React.FC<Props> = ({
  id,
  open,
  handleClose,
  handleAccept,
  title,
  message,
  isLoading,
  component,
}) => {
  const { loading } = useSelector((state: AppState) => state.helper);
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <BootstrapDialog
        fullWidth
        data-testid={id}
        maxWidth="sm"
        id={id || ""}
        onClose={handleClose}
        aria-label="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {typeof title === "string" ? <>{t(`${title}`)}</> : title}
        </BootstrapDialogTitle>
        <Divider variant="middle" color="#02D0E7" />
        <DialogContent>
          <Box
            p={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={"80px"}
          >
            <Typography
              gutterBottom
              className={component === "tickets" ? "" : classes.message}
              component={"div"}
            >
              {component === "tickets" ? (
                <div style={{ textAlign: "center", marginTop: 20 }}>
                  {message}
                </div>
              ) : (
                t(`${message}`)
              )}
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box display="flex" justifyContent="flex-end" mr={1} mb={1}>
            <Button
              data-testid="btnRejectDialog"
              id="btnReject"
              type="button"
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "#FF5757",
              }}
              variant="contained"
              className="ripple"
              onClick={handleClose}
              disabled={!!isLoading}
            >
              {t("table.not")}
              <i className="fa fa-circle-xmark ml-2"></i>
            </Button>
            &nbsp;&nbsp;
            <Button
              type="button"
              data-testid="btnConfirmDialog"
              id="btnConfirm"
              style={{
                border: "none",
                outline: "none",
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#02D0E7",
              }}
              variant="contained"
              className="ripple"
              onClick={handleAccept}
              disabled={isLoading || !!loading}
            >
              {isLoading || loading ? (
                <CircularProgress size={"1.6rem"} color="inherit" />
              ) : (
                <>
                  {t("user.yes")}
                  <i className="fa fa-circle-check ml-2"></i>
                </>
              )}
            </Button>
          </Box>
          {/* )} */}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
