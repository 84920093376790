import {
  getParamSettingsDB,
  deleteParameterizationService,
  createParamGame,
  createParamSolution,
  updateParamConfigPaymentMethod,
  updateParamGame,
  updateParamSolutionService,
} from "../../services/managementParametersService";
import { AppState } from "../reducers/rootReducer";
import {
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";
import { arraySearch } from "./searchActions";
import { uiSetError, uiSetErrorMessage, uiSetOpenDialog } from "./uiAction";
import {
  ICountriesSolution,
  ICountriesPayment,
  ICountriesGame,
} from "../interfaces/shared";
import { Types } from "../types/types";
import { Roles_Enum, State } from "../../enums/enums";
import {
  ICountryGamesDb,
  ICountryPayment,
  ICountrySolution,
  ICountrySolutionsDb,
  IFormParamPaymentMethod,
  IParamCountryGames,
  IParamCountrySolutions,
  IParamGames,
  IParamPaymentMethod,
  IPaymentMethodParam,
  IPercentageWithdrawal,
} from "../../interfaces/params";
import { getOperatorsService } from "../../services/managementCompanyService";

export const getParamSettings = (
  param: string,
  page: number | string,
  isFilter?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (!user) {
      return null;
    }

    const operatorId =
      user.roles.name === Roles_Enum.SUPER_ADMIN ? null : user.operator.id;

    try {
      if (token && param !== "operators") {
        dispatch(setLoadingState({ loading: true }));
        const response = await getParamSettingsDB(
          token,
          param,
          page,
          rowsPerPage,
          operatorId
        );

        if (response) {
          const data: any = response;

          if (data && data.data.length > 0) {
            if (param === "paramPaymentMethods") {
              const filterParams = data.data.filter((paymethod: any) => {
                return !!paymethod.paymentMethods.find(
                  (pay: any) => pay.type === "refills" && pay.state === 1
                );
              });

              if (filterParams.length || isFilter) {
                dispatch(arraySearch(data));
              } else {
                dispatch(arraySearch(null));
                dispatch(setOpenAlert());
                dispatch(setMessageAlert("alerts.noData"));
                dispatch(setTypeAlert("error"));
              }
            } else {
              dispatch(arraySearch(data));
            }
          } else {
            dispatch(arraySearch(null));
            dispatch(setOpenAlert());
            dispatch(setMessageAlert("alerts.noData"));
            dispatch(setTypeAlert("error"));
          }
        } else {
          dispatch(arraySearch(null));
          dispatch(uiSetError(true));
          dispatch(uiSetErrorMessage("alerts.errorSetMenus"));
        }
      } else {
        const params = {
          page: page,
          perPage: rowsPerPage,
          stateOperator: State.ACTIVE,
        };
        const operators = token && (await getOperatorsService(token, params));
        const objPercentageWithdrawal = operators.data.map((item: any) => ({
          id: item.id,
          operator: { name: item.name },
          percentage: 10,
        }));

        const dataPercentageWithdrawal = {
          data: objPercentageWithdrawal,
          total: operators.total,
          page: operators.page,
          last_page: operators.last_page,
        };
        dispatch(arraySearch(dataPercentageWithdrawal));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const setActiveParamSolutions = (
  countrySolution: ICountriesSolution | null
) => ({
  type: Types.setActiveParamSolutions,
  payload: countrySolution,
});

export const setActiveParamPayments = (
  countryPayment: ICountriesPayment | null
) => ({
  type: Types.setActiveParamPayments,
  payload: countryPayment,
});

export const setActiveParamGames = (countryGame: ICountriesGame | null) => ({
  type: Types.setActiveParamGames,
  payload: countryGame,
});

export const startDeleteParameterization = (
  route:
    | "paramPaymentMethods"
    | "paramGames"
    | "paramSolutions"
    | "bonusTable"
    | "percentageWithdrawalTable"
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    let { data: dataSearch } = (getState() as AppState).search;
    const { activeParamPayments, activeParamGames, activeParamSolutions } = (
      getState() as AppState
    ).managementParameters;

    if (token) {
      try {
        const idCountry =
          route === "paramPaymentMethods"
            ? activeParamPayments?.country.id
            : route === "paramGames"
            ? activeParamGames?.country.id
            : activeParamSolutions?.country.id;

        if (route === "paramPaymentMethods" && activeParamPayments) {
          //eliminar los datos por el idParam
          const methodsDelete = activeParamPayments?.paymentMethods
            .filter((pay) => !pay.isNativeGlobal)
            .map((pay) => {
              return { id: pay.idParamMethod, state: 0 };
            });

          const rs = await Promise.all(
            //llamar servicio de eliminacion paramMethods
            methodsDelete.map(async (method) => {
              return await deleteParameterizationService(
                token,
                route,
                method.id as unknown as number
              );
            })
          );
          if (!rs) {
            dispatch(setOpenAlert());
            dispatch(setMessageAlert("parameterization.deteleParamError"));
            return dispatch(setTypeAlert("error"));
          }

          dataSearch.data = dataSearch.data.filter(
            (data: any) => data.country.id !== idCountry
          );
          dispatch(arraySearch(dataSearch));

          dispatch(setOpenAlert());
          dispatch(setMessageAlert("parameterization.deletedParam"));
          dispatch(setTypeAlert("success"));
        } else {
          // Se deben ELIMINAR los paramGames o paramSolutions
          const idsDelete =
            route === "paramGames"
              ? activeParamGames?.games.map((game) => game.idParamGame)
              : activeParamSolutions?.solutions.map(
                  (sol: any) => sol.idParamSolution
                );

          const resFinal =
            idsDelete &&
            (await Promise.all(
              idsDelete.map(async (id) => {
                return await deleteParameterizationService(token, route, id);
              })
            ));
          if (!resFinal) {
            dispatch(setOpenAlert());
            dispatch(setMessageAlert("parameterization.deteleParamError"));
            return dispatch(setTypeAlert("error"));
          }

          dataSearch.data = dataSearch.data.filter(
            (data: any) => data.country.id !== idCountry
          );
          dispatch(arraySearch(dataSearch));

          dispatch(setOpenAlert());
          dispatch(setMessageAlert("parameterization.deletedParam"));
          dispatch(setTypeAlert("success"));
        }
      } catch (error) {
        alert(error);
      }
    }
  };
};

export const editActivePaymentMethods = (copyConfig: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    let { data: dataSearch } = (getState() as AppState).search;

    if (copyConfig && token) {
      const objConfig = {
        country: copyConfig.country.id,
        operator: copyConfig.operator.id,
        methods: copyConfig.paymentMethods
          .filter((method: any) => !method.isNativeGlobal)
          .map((method: any) => {
            return {
              id: method.idParamMethod,
              active:
                method.type !== "withdrawal"
                  ? method.active === State.ACTIVE
                    ? State.INACTIVE
                    : State.ACTIVE
                  : method.active,
              paymentMethod: method.id,
            };
          }),
      };

      try {
        const response = await updateParamConfigPaymentMethod(
          token,
          objConfig as IParamPaymentMethod
        );

        if (response) {
          dataSearch.data = dataSearch.data.map((row: any) => {
            if (
              row.country.id === objConfig.country &&
              row.operator.id === objConfig.operator
            ) {
              row.paymentMethods = row.paymentMethods.map((pay: any) => {
                return {
                  ...pay,
                  active:
                    pay.type !== "withdrawal"
                      ? pay.active === State.ACTIVE
                        ? State.INACTIVE
                        : State.ACTIVE
                      : pay.active,
                };
              });
              return row;
            } else {
              return row;
            }
          });
          dispatch(arraySearch(dataSearch));
        }
      } catch (error) {
        alert(error);
      }
    }
  };
};

export const editActiveGames = (copyConfig: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    let { data: dataSearch } = (getState() as AppState).search;

    if (copyConfig && token) {
      const objConfig = {
        country: copyConfig.country.id,
        operator: copyConfig.operator.id,
        games: copyConfig.games.map((game: any) => {
          return {
            id: game.idParamGame,
            active:
              game.active === State.ACTIVE ? State.INACTIVE : State.ACTIVE,
            game: game.id,
          };
        }),
      };

      try {
        const response = await updateParamGame(
          token,
          objConfig as IParamCountryGames
        );

        if (response) {
          dataSearch.data = dataSearch.data.map((row: any) => {
            if (
              row.country.id === objConfig.country &&
              row.operator.id === objConfig.operator
            ) {
              row.games = row.games.map((game: any) => {
                return {
                  ...game,
                  active:
                    game.active === State.ACTIVE
                      ? State.INACTIVE
                      : State.ACTIVE,
                };
              });
              return row;
            } else {
              return row;
            }
          });
          dispatch(arraySearch(dataSearch));
        }
      } catch (error) {
        alert(error);
      }
    }
  };
};

export const editActiveSolutions = (copyConfig: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    let { data: dataSearch } = (getState() as AppState).search;

    if (copyConfig && token) {
      const objConfig = {
        country: copyConfig.country.id,
        operator: copyConfig.operator.id,
        solutions: copyConfig.solutions.map((solution: any) => {
          return {
            id: solution.idParamSolution,
            active:
              solution.active === State.ACTIVE ? State.INACTIVE : State.ACTIVE,
            solution: solution.id,
          };
        }),
      };

      try {
        const response = await updateParamSolutionService(
          token,
          objConfig as IParamCountrySolutions
        );

        if (response) {
          dataSearch.data = dataSearch.data.map((row: any) => {
            if (
              row.country.id === objConfig.country &&
              row.operator.id === objConfig.operator
            ) {
              row.solutions = row.solutions.map((sol: any) => {
                return {
                  ...sol,
                  active:
                    sol.active === State.ACTIVE ? State.INACTIVE : State.ACTIVE,
                };
              });
              return row;
            } else {
              return row;
            }
          });
          dispatch(arraySearch(dataSearch));
        }
      } catch (error) {
        alert(error);
      }
    }
  };
};

export const updateParamPaymentMethod = (
  paramMethod: IFormParamPaymentMethod,
  initialParam: ICountryPayment[]
) => {
  return async (dispatch: Function, getState: Function) => {
    const { country, methods } = paramMethod;
    const { token, user } = (getState() as AppState).auth;
    const { activeParamPayments: activePayment } = (getState() as AppState)
      .managementParameters;

    if (token && user) {
      try {
        const finalMethods: IPaymentMethodParam[] = [];
        for (const method of methods) {
          if (method.isNativeGlobal) {
            finalMethods.push({
              paymentMethod: method.id as any,
              state: method.state,
              active: 1,
            });
          } else {
            finalMethods.push({
              id: method.idParamMethod as any,
              paymentMethod: method.id as any,
              state: method.state,
              active: 1,
            });
          }
        }

        const objParamPayService: IParamPaymentMethod = {
          country,
          methods: finalMethods,
          operator: paramMethod.operator,
        };

        await updateParamConfigPaymentMethod(token, objParamPayService);
        await dispatch(getParamSettings("paramPaymentMethods", 1));

        dispatch(setActiveParamPayments(null));

        dispatch(uiSetOpenDialog());
        dispatch(setOpenAlert());
        dispatch(
          setMessageAlert(
            !!activePayment || initialParam.length > 0
              ? "alerts.succesSendInformation"
              : "alerts.successParam"
          )
        );
        dispatch(setTypeAlert("success"));
      } catch (error) {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorParam"));
        dispatch(setTypeAlert("error"));
      }
    }
  };
};
export const addParamGames = (paramGames: IParamCountryGames) => {
  return async (dispatch: Function, getState: Function) => {
    const { country, games } = paramGames;
    const { token } = (getState() as AppState).auth;
    if (token) {
      try {
        const finalGames: IParamGames[] = [];
        for (const game of games) {
          finalGames.push({
            game: game as number,
            state: 1,
          });
        }
        const objParamGameService: IParamCountryGames = {
          country,
          games: finalGames,
          operator: paramGames.operator,
        };

        await createParamGame(token, objParamGameService);
        await dispatch(getParamSettings("paramGames", 1));
        dispatch(uiSetOpenDialog());
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.successParam"));
        dispatch(setTypeAlert("success"));
      } catch (error) {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorParam"));
        dispatch(setTypeAlert("error"));
      }
    }
  };
};
export const updateParamGames = (
  gamesParam: IParamCountryGames,
  initialParam: ICountryGamesDb[]
) => {
  return async (dispatch: Function, getState: Function) => {
    const { country, games } = gamesParam;
    const { token } = (getState() as AppState).auth;
    const { activeParamGames: active } = (getState() as AppState)
      .managementParameters;
    if (token) {
      try {
        const finalMethods: IParamGames[] = [];
        for (const game of games) {
          const gameFind = initialParam.find(
            (param) => param.games.id === game
          );
          if (!!gameFind) {
            finalMethods.push({
              id: gameFind.id,
              game: game as number,
              state: 1,
            });
          } else {
            finalMethods.push({
              game: game as number,
              state: 1,
            });
          }
        }
        const objParamGameService: IParamCountryGames = {
          country,
          games: finalMethods,
          operator: gamesParam.operator,
        };

        await updateParamGame(token, objParamGameService);
        await dispatch(getParamSettings("paramGames", 1));

        dispatch(setActiveParamGames(null));
        dispatch(uiSetOpenDialog());
        dispatch(setOpenAlert());
        dispatch(
          setMessageAlert(
            !!active || initialParam.length > 0
              ? "alerts.succesSendInformation"
              : "alerts.successParam"
          )
        );
        dispatch(setTypeAlert("success"));
      } catch (error) {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorParam"));
        dispatch(setTypeAlert("error"));
      }
    }
  };
};
export const addParamSolutions = (paramSolutions: IParamCountrySolutions) => {
  return async (dispatch: Function, getState: Function) => {
    const { country, solutions } = paramSolutions;
    const { token } = (getState() as AppState).auth;
    if (token) {
      try {
        const finalSolution: ICountrySolution[] = [];
        for (const solution of solutions) {
          finalSolution.push({
            solution: solution as number,
            state: 1,
          });
        }
        const objParamSolutionService: IParamCountrySolutions = {
          country,
          solutions: finalSolution,
          operator: paramSolutions.operator,
        };
        await createParamSolution(token, objParamSolutionService);
        await dispatch(getParamSettings("paramSolutions", 1));

        dispatch(uiSetOpenDialog());
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.successParam"));
        dispatch(setTypeAlert("success"));
      } catch (error) {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorParam"));
        dispatch(setTypeAlert("error"));
      }
    }
  };
};
export const updateParamSolution = (
  paramSolutions: IParamCountrySolutions,
  initialParam: ICountrySolutionsDb[]
) => {
  return async (dispatch: Function, getState: Function) => {
    const { country, solutions } = paramSolutions;
    const { token } = (getState() as AppState).auth;
    const { activeParamSolutions: active } = (getState() as AppState)
      .managementParameters;
    if (token) {
      try {
        const finalSolutions: ICountrySolution[] = [];
        for (const solution of solutions) {
          const gameFind = initialParam.find(
            (param) => param.solutions.id === solution
          );
          if (!!gameFind) {
            finalSolutions.push({
              id: gameFind.id,
              solution: solution as number,
              state: 1,
            });
          } else {
            finalSolutions.push({
              solution: solution as number,
              state: 1,
            });
          }
        }
        const objParamSolutionService: IParamCountrySolutions = {
          country,
          solutions: finalSolutions,
          operator: paramSolutions.operator,
        };

        await updateParamSolutionService(token, objParamSolutionService);
        await dispatch(getParamSettings("paramSolutions", 1));
        dispatch(setActiveParamSolutions(null));
        dispatch(uiSetOpenDialog());
        dispatch(setOpenAlert());
        dispatch(
          setMessageAlert(
            !!active || initialParam.length > 0
              ? "alerts.succesSendInformation"
              : "alerts.successParam"
          )
        );
        dispatch(setTypeAlert("success"));
      } catch (error) {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorParam"));
        dispatch(setTypeAlert("error"));
      }
    }
  };
};

export const startGetPercentageWithdrawal = (page: number = 1) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    const params: IPercentageWithdrawal = {
      page,
      perPage: rowsPerPage,
      stateOperator: State.ACTIVE,
    };
    if(user?.operator){
      params.id = user.operator.id;
    }

    const operators = token && (await getOperatorsService(token, params));
    const objPercentageWithdrawal = operators.data.map((item: any) => ({
      id: item.id,
      operator: { name: item.name },
      percentage: item.withdrawalPercentage,
    }));

    const dataPercentageWithdrawal = {
      data: objPercentageWithdrawal,
      total: operators.total,
      page: operators.page,
      last_page: operators.last_page,
    };
    dispatch(arraySearch(dataPercentageWithdrawal));
  };
};
