/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MyCustomTable, {
  IColumns,
} from "../../../../components/Custom/Table/MyCustomTable";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import Filter from "../../../../components/Filter/Filter";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { arraySearch } from "../../../../redux/actions/searchActions";
import { startGetIrrigationsCardsReport } from "../../../../redux/actions/helperActions";
import { Roles_Enum } from "../../../../enums/enums";
import { CustomAlert } from "../../../../components/Alerts/CustomAlert";
import moment from "moment";
import { RowResumeIrrigation } from "./RowResumeIrrigation";
import { ComissionsDataTable } from "../../../../hooks/useDataTable/comissions";
import { ComissionsPdf } from "../../../../components/Pdf/comissions/ComissionsPdf";
import { MyDialog } from "../../../../components/Custom/MyDialog";
import { CardsResumeModal } from "./CardsResumeModal";
import { uiSetOpenDialog } from "../../../../redux/actions/uiAction";
export const IrrigationResume = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: AppState) => state.auth);
  const { data } = useSelector((state: AppState) => state.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterFormValues, setFilterFormValues] = useState({});

  const [columns, setColumns] = useState<IColumns[]>([]);

  const [selectedRow, setSelectedRow] = useState({});

  const { openDialog } = useSelector((state: AppState) => state.ui);
  const cardStates = [
    { id: 1, name: "filters.cardActive" },
    { id: 2, name: "filters.cardInactive" },
    { id: 3, name: "filters.cardRedeemed" },
  ];

  const filterFields: IFilterField[] = [
    {
      label: t("filters.lotId"),
      type: Enum_FieldType.NUMBER,
    },
    {
      label: t("filters.amount"),
      type: Enum_FieldType.NUMBER,
    },
    {
      label: t("filters.cardNumber"),
      type: Enum_FieldType.NUMBER,
    },
    {
      label: t("filters.cardState"),
      type: Enum_FieldType.SELECT,
      options: [
        { label: t("filters.cardAllStates"), value: "all" },
        ...cardStates.map((state) => ({
          label: t(state.name),
          value: state.id,
        })),
      ],
    },
  ];

  const generateRoleBasedColumns = () => {
    let roleBasedColumns: IColumns[] = [
      {
        align: "center",
        label: t("filters.lotId"),
      },
      {
        align: "center",
        label: t("reports.cardRanges"),
      },
      {
        align: "center",
        label: t("reports.totalCards"),
      },
      {
        align: "center",
        label: t("reports.amount"),
      },
      {
        align: "center",
        label: t("reports.actions"),
      },
    ];

    return [...roleBasedColumns];
  };
  const getUserRoleAbbreviation = () => {
    switch (user?.roles.name) {
      case Roles_Enum.SUPER_ADMIN:
      case Roles_Enum.ADMIN_OPERATOR:
        return "AD";
      case Roles_Enum.ADMIN_ASSOCIATE:
      case Roles_Enum.ASSOCIATE:
        return "AS";
      case Roles_Enum.DEALER:
        return "DEL";
      case Roles_Enum.SELLER:
        return "PS";
      case Roles_Enum.PROMOTOR:
        return "PR";
    }
  };

  const handleFilter = async (formValues: any, page: number = 1) => {
    const cardAmount = formValues[t("reports.amount")];
    const lotId = formValues[t("filters.lotId")];
    const cardNumber = formValues[t("filters.cardNumber")];
    let params: any = {
      cardNumber,
      cardAmount,
      lotId,
      page,
      userId: user?.id,
      currentPlace: getUserRoleAbbreviation(),
    };
    console.log("🚀 ~ handleFilter ~ params:", params);

    if (
      formValues[t("filters.cardState")] &&
      formValues[t("filters.cardState")] !== "all"
    ) {
      params.cardState = formValues[t("filters.cardState")];
    }

    const roleBasedColumns = generateRoleBasedColumns();
    // @ts-ignore
    setColumns(roleBasedColumns);

    await dispatch(startGetIrrigationsCardsReport(params));
  };
  const handleOpenDialog = (row: any) => {
    setSelectedRow(row);
    dispatch(uiSetOpenDialog());
  };

  const handleCloseDialog = () => {
    dispatch(uiSetOpenDialog());
  };

  const onCleanFilter = async () => {
    setFilterFormValues({});
    dispatch(arraySearch(null));
  };
  const onPageChange = (newPage: any) => {
    setCurrentPage(newPage);
    handleFilter(filterFormValues, newPage);
  };
  function getAllComissions(token: string, params: any): Promise<any> {
    throw new Error("Function not implemented.");
  }

  return (
    <>
      <div className="content w-100 mb-4">
        <Grid item xs={12}>
          <div
            className="filter"
            style={{ padding: "18px 0px 0px", marginBottom: "18px" }}
          >
            <Filter
              fields={filterFields}
              handleFilter={(values) => handleFilter(values)}
              onClean={onCleanFilter}
            />
          </div>
        </Grid>
      </div>
      {data ? (
        <div className="filter">
          <MyCustomTable
            columns={columns}
            data={data.data}
            lastPage={data.last_page}
            totalData={data.total}
            renderBody={(item) => (
              <RowResumeIrrigation row={item} onOpenDialog={handleOpenDialog} />
            )}
            onPageChange={onPageChange}
            // @ts-ignore
            currentPage={currentPage}
            exportData
            hookBuildTable={ComissionsDataTable}
            getAllDataToExport={getAllComissions}
            pdfComponent={ComissionsPdf}
            titleCSV={`${t("reports.commissions")
              .split(" ")
              .join("-")}-${moment().toISOString()}`}
          />
        </div>
      ) : (
        <CustomAlert
          htmlText={t("reports.filterIrrigationResumeReport")}
          type="info"
        />
      )}
      <MyDialog
        openModal={openDialog}
        onClose={handleCloseDialog}
        content={
          <CardsResumeModal row={selectedRow} onClose={handleCloseDialog} />
        }
      />
    </>
  );
};
