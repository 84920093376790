import { Document, Image, Page, View } from "@react-pdf/renderer";
import React, { FC } from "react";
import { sliceArray } from "../../../utils/utils";
import LogoNeat from "../../../assets/img/NEAT-LOGO.png";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import { Roles_Enum } from "../../../enums/enums";

interface Props {
  data: any[];
  RenderComponent: any;
  rol?: Roles_Enum;
}
export const MyPdf: FC<Props> = ({ data, RenderComponent, rol }) => {
  const pagesData: any[][] = sliceArray(20, data);

  return (
    <Document>
      {pagesData.map((page, index) => (
        <Page
          size="A4"
          key={index}
          style={stylesPdf.page}
          orientation="landscape"
        >
          <View style={stylesPdf.container}>
            <View
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <Image src={LogoNeat} style={stylesPdf.logo} />
            </View>
            {<RenderComponent page={page} rol={rol} />}
          </View>
        </Page>
      ))}
    </Document>
  );
};
