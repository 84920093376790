import { IConciliation, IExternalUsersData } from "../../interfaces/commercialNetworks";
import { IPaginationData } from "../../interfaces/shared";
import { Types } from "../types/types";

interface ICommercialNetworksActions {
  type: Types;
  payload?: IPaginationData<IExternalUsersData> | IPaginationData<IConciliation> | null;
}

interface ICommercialNetworksState {
  networks: IPaginationData<IExternalUsersData> | null;
  conciliations: IPaginationData<IConciliation> | null;
}

const initialState: ICommercialNetworksState = {
  networks: null,
  conciliations: null,
};
export const commercialNetworksReducer = (
  state = initialState,
  action: ICommercialNetworksActions
): ICommercialNetworksState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setCommercialNetworks:
      return {
        ...state,
        networks: payload as IPaginationData<IExternalUsersData>,
      };
    
    case Types.setConciliations:
      return {
        ...state,
        conciliations: payload as IPaginationData<IConciliation>,
      };

    default:
      return state;
  }
};
