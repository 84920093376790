import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, KeyboardEvent, useEffect } from "react";
import { MyTextField } from "../../components/custom/MyTextField";
import { regLettersNumbers } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  asyncLoadingFunction,
  setFlagForm,
} from "../../../redux/actions/helperActions";
import { MyDialog } from "../../components/custom/MyDialog";
import { AppState } from "../../../redux/reducers/rootReducer";
import "./ipAddresses.scss";
import {
  setActiveIp,
  startDeleteIp,
  startGetBlacklist,
  startUpdatePlayersIp,
} from "../../../redux/actions/IpAddressesActions";
import {
  IIpBlacklist,
  IObjectInactivePlayers,
} from "../../../interfaces/ipBlacklist";
import MyCustomTable, {
  IColumns,
} from "../../../components/Custom/Table/MyCustomTable";
import { RowIpAddreses } from "./RowIpAddreses";
import { getIpBlacklist } from "../../../services/ipAddresses";
import { ManagementIpAddressesTable } from "../../../hooks/useDataTable/ipAddresses";
import { IpAddressesPdf } from "../../../components/Pdf/ipAddresses/IpAddressesPdf";

export const IpAddressesTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeIp, IpBlacklist } = useSelector(
    (state: AppState) => state.ipAddresses
  );
  const { token } = useSelector((state: AppState) => state.auth);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState(9);
  const [isFiltering, setIsFiltering] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [message, setMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const { loading } = useSelector((state: AppState) => state.helper);
  const initialValues = {
    startDate: "",
    endDate: "",
    ipAddress: "",
  };

  const currentItems = IpBlacklist ? IpBlacklist.data : [];

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        perPage: itemsPerPage,
      };
      await dispatch(startGetBlacklist(params));
    };
    dispatch(asyncLoadingFunction(fetchData));
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (data: any) => {
    if (data.startDate || data.endDate || data.ipAddress) {
      setIsFiltering(true);
      setFilterData(data);
    }
    const params = {
      ...data,
      page: currentPage,
      perPage: itemsPerPage,
    };

    dispatch(startGetBlacklist(params));
  };

  const handleClean = (resetForm: any) => {
    setIsFiltering(false);
    resetForm();
    const params = {
      page: 1,
      perPage: itemsPerPage,
    };
    dispatch(startGetBlacklist(params));
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    const params = {
      ...filterData,
      page: page,
      perPage: itemsPerPage,
    };
    dispatch(startGetBlacklist(params));
  };

  const handleEdit = (item: IIpBlacklist) => {
    dispatch(setActiveIp(item));
    dispatch(setFlagForm({ flagFormCreate: true }));
  };

  const handleInactivePlayers = () => {
    const objInactivePlayers: IObjectInactivePlayers = {
      ip: activeIp ? activeIp.ipAddress : "",
      reason: activeIp ? activeIp.reason : "",
      isActive: false,
    };
    activeIp && dispatch(startUpdatePlayersIp(objInactivePlayers));
    setOpenDialog(false);
  };

  const showModalDialog = (item: IIpBlacklist) => {
    dispatch(setActiveIp(item));
    setOpenDialog(true);
    setIsDelete(true);
    setMessage(t("player.confirmDeleteIp"));
  };

  const handleDelete = () => {
    activeIp &&
      dispatch(
        startDeleteIp(
          parseInt(activeIp.id as string),
          itemsPerPage,
          currentPage
        )
      );
    const params = {
      page: 1,
      perPage: itemsPerPage,
    };
    dispatch(startGetBlacklist(params));
    setOpenDialog(false);
  };

  const handleAccept = () => {
    if (isDelete) {
      handleDelete();
    } else {
      handleInactivePlayers();
    }
  };

  const columns: IColumns[] = [
    {
      align: "left",
      label: t("id"),
    },
    {
      align: "left",
      label: t("ip"),
    },
    {
      align: "left",
      label: t("user.createAt"),
    },
    {
      align: "left",
      label: t("withdrawals.reason"),
    },
    {
      align: "center",
      label: t("reports.actions"),
    },
  ];

  const getTotalIpAddressesData = async () => {
    if (token) {
      return await getIpBlacklist(token, {});
    }
  };
  return (
    <div className="table-container">
      <div className="table-controls">
        <div className="filters">
          <Formik
            initialValues={initialValues}
            onSubmit={async (data, { resetForm }) => {
              if (!isFiltering) {
                handleSubmit(data);
              } else {
                data.startDate = "";
                data.endDate = "";
                data.ipAddress = "";
                handleClean(resetForm);
              }
            }}
          >
            {({ handleChange }) => (
              <Form>
                <Grid
                  container
                  spacing={2}
                  alignItems="end"
                  justifyContent="space-between"
                >
                  <Grid item xs={12} md={3}>
                    <label className="ml-3">{t("filters.startDate")}</label>
                    <MyTextField
                      name="startDate"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="date"
                      InputLabelProps={{ shrink: false }}
                      label={" "}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={handleChange}
                      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                        !regLettersNumbers(e.key) && e.preventDefault()
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <label className="ml-3">{t("filters.endDate")}</label>
                    <MyTextField
                      name="endDate"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="date"
                      InputLabelProps={{ shrink: false }}
                      label={" "}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={handleChange}
                      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                        !regLettersNumbers(e.key) && e.preventDefault()
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <label className="ml-3">{t("ip")}</label>
                    <MyTextField
                      name="ipAddress"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      InputLabelProps={{ shrink: false }}
                      label={" "}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <button
                      className="d-flex btn-light-dark"
                      type="submit"
                      style={{ width: "100%" }}
                    >
                      <div className="p-0 text-right">
                        <i className="fa fa-filter btn-icon pt-1 fa-md"></i>
                      </div>
                      <div className="p-0 text-left">
                        <label className="btn-label pl-1">
                          {isFiltering
                            ? t("filters.clean")
                            : t("filters.filter")}
                        </label>
                      </div>
                    </button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {IpBlacklist?.total === 0 ? (
        <div>{t("alerts.noData")}</div>
      ) : (
        <Grid item xs={12}>
          <MyCustomTable
            columns={columns}
            data={currentItems}
            lastPage={IpBlacklist?.last_page || 1}
            totalData={IpBlacklist?.total || 0}
            renderBody={(item) => (
              <RowIpAddreses
                row={item}
                actions={{
                  edit: true,
                  delete: true,
                }}
                onDelete={showModalDialog}
                onEdit={handleEdit}
              />
            )}
            onPageChange={handlePageChange}
            exportData
            getAllDataToExport={getTotalIpAddressesData}
            hookBuildTable={ManagementIpAddressesTable}
            pdfComponent={IpAddressesPdf}
            titleCSV={`${t("ipAddresses.title")}-${new Date().toISOString()}`}
          />
        </Grid>
      )}
      <MyDialog
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message={message}
        handleAccept={handleAccept}
        handleClose={() => setOpenDialog(false)}
        isLoading={!!loading}
      />
    </div>
  );
};
