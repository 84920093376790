import { FC } from "react";
import { TableCell } from "@mui/material";
import moment from "moment";
import { StateTransaction, WhiteListOperators } from "../../../../enums/enums";

import { IWithdrawalData } from "../../../../interfaces/withdrawals";

interface Props {
  row: IWithdrawalData;
  component: StateTransaction;
}

export const RowDataTableBankWithdrawals: FC<Props> = ({ row, component }) => {
  const percentage =
    row.player.operator?.name?.toUpperCase() === WhiteListOperators.BETPPRO
      ? 0
      : 5.5;

  return (
    <>
      <TableCell className="word-break">{row.id}</TableCell>
      <TableCell className="word-break">{row.player.numberDocument}</TableCell>
      {component !== StateTransaction.Refused && (
        <TableCell className="word-break">{`${row.player.email}`}</TableCell>
      )}
      {component === StateTransaction.Approved && (
        <>
          <TableCell className="word-break">
            {row.approvalDate
              ? moment(row.approvalDate).format("DD/MM/YYYY - HH:mm:ss")
              : ""}
          </TableCell>
          <TableCell align="left">{row.withdrawalValue}</TableCell>
          <TableCell align="left">{row.paidValue}</TableCell>
        </>
      )}
      {component === StateTransaction.Pending && (
        <>
          <TableCell className="word-break">
            {parseFloat(row.taxValue) === 0
              ? parseFloat(row.taxValue).toFixed(1)
              : percentage.toFixed(1)}
          </TableCell>
          <TableCell className="word-break">
            {parseFloat(row.taxValue).toFixed(2)}
          </TableCell>
          <TableCell align="left">{row.withdrawalValue}</TableCell>
          <TableCell align="left">{row.paidValue}</TableCell>
          <TableCell className="word-break">
            {moment(row.createAt).format("DD/MM/YYYY - HH:mm:ss")}
          </TableCell>
        </>
      )}
      {component === StateTransaction.Refused && (
        <>
          <TableCell align="left">{row.withdrawalValue}</TableCell>
          <TableCell align="left">{row.paidValue}</TableCell>
          <TableCell align="left" className="word-break">
            {row.rejectDate
              ? moment(row.rejectDate).format("DD/MM/YYYY - HH:mm:ss")
              : ""}
          </TableCell>
          <TableCell align="left" className="word-break">
            {row.reason}
          </TableCell>
        </>
      )}
    </>
  );
};
