import {
  FC,
  FormEvent,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NotificationAlert from "react-notification-alert";

import { Card, CardBody, Form, Container, Col } from "reactstrap";
import {
  CircularProgress,
  TextField,
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { startLoginUsernamePassword } from "../../redux/actions/authActions";
import { uiSetError } from "../../redux/actions/uiAction";
import { AppState } from "../../redux/reducers/rootReducer";
import Logo from "../../assets/svg/logo-neat1.svg";
// import ReCAPTCHA from "react-google-recaptcha";
import { regUsername } from "../../utils/utils";
import { MyAlert } from "../components/custom/MyAlert";
import {
  setCloseAlert,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../redux/actions/helperActions";
import { RequestPassword } from "../components/RequestPassword/RequestPassword";
import { ChangePasswordForm } from "../components/RequestPassword/ChangePasswordForm";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface Token {
  token: string;
}

export const Login: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token } = useParams<Token | any>();
  const [state, setState] = useState({
    submit: false,
    loading: false,
  });
  const [form, setForm] = useState({ username: "", password: "" });
  const { error, errorMessage } = useSelector((state: AppState) => state.ui);
  const { user } = useSelector((state: AppState) => state.auth);
  const notificationAlertRef = useRef(null);
  // const captchaRef = useRef(null);
  const [submit, setSubmit] = useState(false);
  const { openAlert, typeAlert, messageAlert } = useSelector(
    (state: AppState) => state.helper
  );
  const [showModalPassword, setShowModalPassword] = useState(false);
  const [showModalChangePassword, setShowModalChangePassword] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
      resetForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const resetForm = () => {
    setState({ ...state, loading: false, submit: true });
  };

  useEffect(() => {
    if (error && errorMessage) {
      const options = {
        place: "tr",
        message: `${t(errorMessage)}`,
        type: "primary",
        //icon: "tim-icons icon-bell-55",
        icon: "fa-solid fa-bell",
        autoDismiss: 5,
        closeButton: dispatch(uiSetError(false)),
      };
      (notificationAlertRef.current as any).notificationAlert(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, errorMessage]);

  const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    setState({ ...state, loading: false });
    event.preventDefault();
    if (form.username === "" || form.password === "") {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.completeRequiredFields"));
      dispatch(setTypeAlert("error"));
    } else {
      setState({ ...state, loading: true });
      if (form.username.trim() && form.password.trim()) {
        await dispatch(
          startLoginUsernamePassword(form.username, form.password)
        );
        if (!user) {
          resetForm();
        }
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleRecoverPassword = () => {
    setShowModalPassword(true);
  };

  const onCloseAlert = () => {
    dispatch(setCloseAlert());
  };
  return (
    <>
      <div className="content">
        <img src={Logo} className="login-logo" alt="play house logo" />
        <Container style={{ marginTop: 0 }}>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form" onSubmit={(e) => handleLogin(e)}>
              <Card className="card-login card-white">
                <CardBody>
                  <Grid>
                    <TextField
                      name="username"
                      label={t("login.user")}
                      fullWidth
                      value={form.username}
                      onChange={handleInputChange}
                      error={
                        !!(form.username.length === 0 && state.submit) ||
                        (submit && form.username === "")
                      }
                      size="small"
                      margin="normal"
                      inputProps={{ maxLength: 50 }}
                      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                        !regUsername(e.key) && e.preventDefault()
                      }
                    />
                    <FormHelperText>
                      {submit && form.username === "" && (
                        <label className="ml-3" style={{ color: "#d32f2f" }}>
                          {t("form.requiredFile")}
                        </label>
                      )}
                    </FormHelperText>
                  </Grid>
                  <Grid>
                    <TextField
                      name="password"
                      label={t("login.password")}
                      type={showPassword ? "text" : "password"}
                      fullWidth
                      value={form.password}
                      onChange={handleInputChange}
                      autoComplete="off"
                      error={
                        !!(form.password.length === 0 && state.submit) ||
                        (submit && form.password === "")
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              style={{ border: "none", outline: "none" }}
                              edge="end"
                              onClick={() => setShowPassword((prev) => !prev)}
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      size="small"
                      margin="normal"
                      inputProps={{ maxLength: 30 }}
                    />
                    <FormHelperText>
                      {submit && form.password === "" && (
                        <label className="ml-3" style={{ color: "#d32f2f" }}>
                          {t("form.requiredFile")}
                        </label>
                      )}
                    </FormHelperText>
                  </Grid>

                  <Button
                    id="btnLogin"
                    type="submit"
                    fullWidth
                    className="btn-login mb-3 mt-2"
                    disabled={state.loading}
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: "10px",
                      fontSize: 15,
                    }}
                    onClick={() => setSubmit(true)}
                  >
                    {state.loading ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      <p>{t("login.title")}</p>
                    )}
                  </Button>
                  <div className="text-center">
                    <h6>
                      <span
                        onClick={handleRecoverPassword}
                        className="nav-link text-link"
                      >
                        {t("login.recover.question")}
                      </span>
                    </h6>
                  </div>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Container>

        <MyAlert
          open={openAlert}
          message={messageAlert ? messageAlert : ""}
          typeAlert={typeAlert}
          handleClose={onCloseAlert}
        />
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <RequestPassword
        openModal={showModalPassword}
        setShowModalPassword={setShowModalPassword}
      />
      {token && (
        <ChangePasswordForm
          openModal={showModalChangePassword}
          handleClose={() => {
            setShowModalChangePassword(false);
          }}
        />
      )}
    </>
  );
};
