import { FC } from "react";
import TableCell from "@mui/material/TableCell";
import { TableRow } from "@mui/material";

import moment from "moment";
import { IBetData } from "../../../../../interfaces/bets";
import { useStyles } from "../../../custom/customStyles";
import { useTranslation } from "react-i18next";
// @ts-ignore
import classnames from "classnames";

interface Props {
  row: any;
  bet: IBetData | null;
}

export const RowDataTableEvents: FC<Props> = ({ row, bet }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableRow
      style={{
        width: "100%",
      }}
    >
      <TableCell
        style={{
          minWidth: 100,
        }}
      >
      
      </TableCell>
      <TableCell className={classes.cellMinWidth}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={classes.subtitleCellTextPrimay}>
            {t("bets.eventDate").toUpperCase()}
          </div>
          <div
            className={classnames(
              classes.textCellSecondary,
              classes.capitalize
            )}
          >
            {moment(row.eventDate).format("D MMM HH:mm")}
          </div>
        </div>
      </TableCell>

      <TableCell className={classes.cellMinWidth}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={classes.subtitleCellTextPrimay}>
            {t("bets.sport").toUpperCase()}{" "}
          </div>
          <div className={classes.textCellSecondary}>{row?.sportName}</div>
        </div>
      </TableCell>

      <TableCell className={classes.cellMaxWidth}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={classes.subtitleCellTextPrimay}>
            {t("bets.event").toUpperCase()}{" "}
          </div>
          <div className={classes.textCellSecondary}>{row.event}</div>
        </div>
      </TableCell>
      <TableCell className={classes.cellMaxWidth}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={classes.subtitleCellTextPrimay}>
            {t("bets.marketType").toUpperCase()}
          </div>
          <div className={classes.textCellSecondary}>
            {row.markets[0]?.market}
          </div>
        </div>
      </TableCell>
      <TableCell className={classes.cellMinWidth}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={classes.subtitleCellTextPrimay}>
            {t("bets.selection").toUpperCase()}
          </div>
        </div>
        <div className={classes.textCellSecondary}>
          {row.markets[0]?.outcome}
        </div>
      </TableCell>
      <TableCell className={classes.cellMinWidth}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={classes.subtitleCellTextPrimay}>
            {t("bets.fee").toUpperCase()}
          </div>
        </div>
        <div className={classes.textCellSecondary}>{row.markets[0]?.odds}</div>
      </TableCell>
      
    </TableRow>
  );
};
