import { Types } from "../types/types";

interface IPayMethodsActions {
  type: Types;
  payload?: any;
}

const initialState = {
  payMethods: [],
};

export const managementPayMethodsReducer = (
  state = initialState,
  action: IPayMethodsActions
) => {
  const { type, payload } = action;
  switch (type) {
    case Types.setPayMethods:
      return {
        ...state,
        payMethods: payload,
      };
    default:
      return state;
  }
};
