import React, { FC } from "react";

import { Dialog } from "@mui/material";

interface Props {
  content: any;
  openModal: boolean;
  onClose(): void;
}

export const MyDialog: FC<Props> = ({ content, openModal, onClose }) => {
  const handleCloseDialog = () => {
    onClose();
  };

  const childrenWithProps = React.Children.map(content, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      //@ts-ignore
      return React.cloneElement(child, { handleCloseDialog });
    }
    return child;
  });
  return (
    <Dialog
      open={openModal}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          overflow: "hidden",
        },
      }}
    >
      {childrenWithProps}
    </Dialog>
  );
};
