import React, { FC, useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import { Box, Grid } from "@mui/material";
import { AssociateInfo } from "../../components/transactions/irrigation/AssociateInfo";
import { GenerateTransfer } from "../../components/transactions/irrigation/GenerateTransfer";
import { IrrigationHistory } from "../../components/transactions/irrigation/IrrigationHistory";
import { BalanceType } from "../../../enums/enums";
import { setActiveAssociate } from "../../../redux/actions/associatesActions";
import { arraySearch } from "../../../redux/actions/searchActions";

interface props {
  enableCreated: boolean;
}

export const Irrigation: FC<props> = ({ enableCreated }) => {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState<boolean>(false);
  const [balance, setBalance] = useState(BalanceType.REFILL);
  const [resetInfo, setResetInfo] = useState(false);
  const [resetGenerate, setResetGenerate] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(setActiveAssociate(null));
      dispatch(arraySearch(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AutoReset = () => {
    const { resetForm } = useFormikContext();
    useEffect(() => {
      if (resetInfo) {
        resetForm();
      }
      setResetInfo(false);
      setResetGenerate(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetInfo]);
    return null;
  };

  return (
    <Box sx={{ ml: 3, mt: 2 }} width={1}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={!enableCreated ? 12 : 6}>
          <AssociateInfo
            expand={expand}
            setExpand={setExpand}
            balance={balance}
            setBalance={setBalance}
            setResetInfo={setResetInfo}
            AutoReset={AutoReset}
            setResetGenerate={setResetGenerate}
          />
        </Grid>

        {enableCreated && (
          <Grid item xs={12} md={6}>
            <GenerateTransfer
              expand={expand}
              setExpand={setExpand}
              balance={balance}
              setResetInfo={setResetInfo}
              resetInfo={resetInfo}
              setResetGenerate={setResetGenerate}
              resetGenerate={resetGenerate}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <IrrigationHistory />
        </Grid>
      </Grid>
    </Box>
  );
};
