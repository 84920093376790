import React, { FC } from "react";
import { TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  row: any;
  onOpenDialog: (cardGroups: any[][]) => void;
}

export const RowResumeIrrigation: FC<Props> = ({ row, onOpenDialog }) => {
  const { t } = useTranslation();

  return (
    <>
      <TableCell className="word-break" align="center">
        {row.lotId}
      </TableCell>

      <TableCell className="word-break" align="center">
        {row.ranges &&
          row.ranges
            .map((group: any) => `${group.min}-${group.max}`)
            .join(", ")}
      </TableCell>
      <TableCell className="word-break" align="center">
        {row.totalCards}
      </TableCell>
      <TableCell className="word-break" align="center">
        $ {row.cardAmount}
      </TableCell>
      <TableCell className="word-break" align="center">
        <button
          className="d-flex btn-light-dark"
          type="button"
          style={{ width: "100%" }}
          onClick={() => onOpenDialog(row)}
        >
          <div className="p-0 text-center">
            <label className="btn-label pl-1">{t("filters.viewCards")}</label>
          </div>
        </button>
      </TableCell>
    </>
  );
};
