import { ISlides } from "../../interfaces/slides";
import { Types } from "../types/types";

interface ISlidesState {
  slides: Array<ISlides> | null
}

interface ISlidesActions {
  type: Types;
  payload?: Array<ISlides> | null
}
const initialState: ISlidesState = {
  slides: [],
};

export const managementSlidesReducer = (
  state = initialState,
  action: ISlidesActions
): ISlidesState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setSlides:
      return {
        ...state,
        slides: payload as any,
      };
    default:
      return state;
  }
};
