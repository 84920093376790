import { useEffect, useState, KeyboardEvent } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setCities,
  setCountries,
  setLoadingState,
  setStates,
} from "../../../../redux/actions/helperActions";
import { IAssociateStep } from "../../../../interfaces/pointsSale";
import { theme } from "../../custom/customStyles";
import ClearIcon from "@mui/icons-material/Clear";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { IAssociates, IAssociatesData } from "../../../../interfaces/associate";
import {
  ICities,
  ICountries,
  IStates,
} from "../../../../redux/interfaces/shared";
import {
  regAddress,
  regEmail,
  regLettersNumbers,
  regNumbers,
  regNumbersReals,
} from "../../../../utils/utils";
import { getOperatorsService } from "../../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../../redux/actions/managementCompanyActions";
import { DealerTypeEnum, Roles_Enum, State } from "../../../../enums/enums";
import { getAssociatesFilterService } from "../../../../services/associates";
import { NumericFormatCustom } from "../../custom/NumericFormatCustom";
import { startGetConfigParams } from "../../../../redux/actions/configParamsActions";

/**
 * Styles MUI
 */
const styles = {
  "& input.Mui-disabled ": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
  "& .MuiSelect-select.Mui-disabled": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
interface Props {
  formValues: any;
  submit: boolean;
  handleInputChange: () => void;
  handleSelectAssociate: (e: any, value: IAssociateStep[]) => void;
  handleSelectCountry: (value: ICountries | null) => void;
  handleSelectState: (value: IStates | null) => void;
  handleSelectCity: (value: ICities | null) => void;
  selectedAssociates: IAssociateStep[];
  setSelectedAssociates: any;
  selectedCountries: ICountries | null;
  selectedStates: IStates | null;
  selectedCities: ICities | null;
  errorCountry: boolean;
  errorStates: boolean;
  errorCity: boolean;
  errorAssociates: boolean;
  errorEmail: boolean;
  emailNoValid: boolean;
  errorName: boolean;
  errorTransfer: boolean;
  validPhone: boolean;
  setErrorEmail: any;
  setErrorName: any;
  paramsPhoneError: any;
}

export const FormConfigPointSale = ({
  formValues,
  submit,
  handleInputChange,
  handleSelectAssociate,
  handleSelectCountry,
  handleSelectState,
  handleSelectCity,
  selectedAssociates,
  setSelectedAssociates,
  selectedCountries,
  selectedStates,
  selectedCities,
  errorCountry,
  errorStates,
  errorCity,
  errorAssociates,
  errorEmail,
  emailNoValid,
  errorName,
  errorTransfer,
  validPhone,
  setErrorEmail,
  setErrorName,
  paramsPhoneError,
}: Props) => {
  /**
   *  Soporta la constante para las traducciones
   */
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { configParams } = useSelector((state: AppState) => state.configParams);

  /**
   * Soporta las banderas para mostrar las ciduades y estados en los select
   */
  const [flagStates, setFlagStates] = useState(true);
  const [flagCity, setFlagCity] = useState(true);

  /**
   * Soporta el token para enviar con la funcion de crear o eliminar
   */
  const { token, user } = useSelector((state: AppState) => state.auth);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;

  /**
   * Soporta los roles, paises, estados y ciudades para mostrar en los respectivos selects
   */
  const { countries, states, cities } = useSelector(
    (state: AppState) => state.helper
  );
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const { activePointSale } = useSelector(
    (state: AppState) => state.pointsSale
  );
  const [arrayAssociates, setArrayAssociates] = useState<IAssociates | null>(
    null
  );
  const pointType = [
    { label: t("managementCards.cards"), value: DealerTypeEnum.CARDS },
    {
      label: t("managementDealers.anonymous"),
      value: DealerTypeEnum.ANONYMOUS,
    },
    { label: t("managementDealers.both"), value: DealerTypeEnum.BOTH },
  ];

  const enableAnonymousSaleSelect =
    configParams &&
    configParams.data.length > 0 &&
    !!configParams.data[0].configParam.options.find(
      (op) =>
        op.name === "Juego anónimo deportivas" &&
        (activePointSale ? true : op.state === State.ACTIVE) &&
        !op.isNative
    );
  const disabledAnonymousSelect =
    !!activePointSale &&
    !!configParams &&
    configParams.data.length > 0 &&
    !!configParams.data[0].configParam.options.find(
      (op) =>
        op.name === "Juego anónimo deportivas" && op.state === State.INACTIVE
    );

  const enableAnonymousPayoutSelect =
    configParams &&
    configParams.data.length > 0 &&
    !!configParams.data[0].configParam.options.find(
      (op) =>
        op.name === "Pagar premios de juego anónimo" &&
        (activePointSale ? true : op.state === State.ACTIVE) &&
        !op.isNative
    );
  const disabledAnonymousPayout =
    !!activePointSale &&
    !!configParams &&
    configParams.data.length > 0 &&
    !!configParams.data[0].configParam.options.find(
      (op) =>
        op.name === "Pagar premios de juego anónimo" &&
        op.state === State.INACTIVE
    );

  const fetchConfigParams = async (operator: number) => {
    if (!token) {
      throw new Error("Invalid action");
    }
    await dispatch(
      startGetConfigParams({
        operator,
        state: State.ACTIVE,
        name: "JuegoAnonimoDeportivas",
      })
    );
  };

  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    if (token && isSuper) {
      fetchOperators(token);
    }
    return () => {
      dispatch(setCompaniesRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    token && dispatch(setCountries(token));
    dispatch(setLoadingState({ loading: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //FILTRAR LOS SELECTS
    if (selectedCountries) {
      handleSelectState(null);
      getStates(selectedCountries.id);

      setFlagStates(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountries]);

  useEffect(() => {
    //FILTRAR LAS CIUDADES
    handleSelectCity(null);
    if (selectedStates) {
      getCities(selectedStates.id);
      setFlagCity(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStates]);

  /**
   * Soporta el evento click del boton para traer los estados del país
   */
  const getStates = (id: number) => {
    if (token) {
      dispatch(setStates(token, id));
    }
  };
  /**
   * Soporta el evento click del boton para traer las ciudades del estado
   */
  const getCities = (id: number) => {
    if (token) {
      dispatch(setCities(token, id));
    }
  };

  // DATOS ASOCIADOS
  const dataAssociates: IAssociateStep[] =
    formValues.operator === ""
      ? []
      : arrayAssociates && arrayAssociates.data
      ? arrayAssociates.data
          .filter((el) => el.stateUser === 1)
          .map((el: IAssociatesData) => {
            return { id: el.id!, name: el.name };
          })
      : [];

  useEffect(() => {
    if (token && formValues.operator !== "") {
      //CONSULTAR LOS USUARIOS DEPENDIENDO DEL OPERADOR
      const fetchAssociatesByOperator = async () => {
        if (user && token && formValues.operator !== "") {
          const { associatesUsers } = user;
          const associateId =
            associatesUsers?.length > 0
              ? associatesUsers[0].associate.id
              : null;
          let params = {};
          if (user.roles.name === Roles_Enum.ASSOCIATE) {
            params = {
              id: associateId,
            };
          }
          const response = await getAssociatesFilterService(
            token,
            formValues.operator,
            params
          );
          if (response && !response.error) {
            const data: IAssociates = response;
            setArrayAssociates(data);
          } else {
            setArrayAssociates(null);
          }
        }
      };
      fetchAssociatesByOperator();
    }
    if (user) {
      const operator = isSuper ? formValues.operator : user.operator.id;
      fetchConfigParams(operator);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.operator]);
  const setAnonymousInputValues = async () => {
    const enableAnonymousSaleSelect =
      configParams &&
      !!configParams.data[0].configParam.options.find(
        (op) =>
          op.name === "Juego anónimo deportivas" &&
          op.state === State.ACTIVE &&
          !op.isNative
      );
    const enableAnonymousPayoutSelect =
      configParams &&
      !!configParams.data[0].configParam.options.find(
        (op) =>
          op.name === "Pagar premios de juego anónimo" &&
          op.state === State.ACTIVE &&
          !op.isNative
      );
    if (!enableAnonymousSaleSelect && !activePointSale) {
      //@ts-ignore
      await handleInputChange([
        {
          target: {
            name: "allowAnonymousSale",
            value: State.INACTIVE,
          },
        },
      ]);
    }
    if (!enableAnonymousPayoutSelect && !activePointSale) {
      //@ts-ignore
      await handleInputChange([
        {
          target: {
            name: "allowAnonymousPayouts",
            value: State.INACTIVE,
          },
        },
      ]);
    }
  };

  useEffect(() => {
    if (configParams && configParams.data.length <= 0) {
      formValues.allowAnonymousPayouts = 2;
      formValues.allowAnonymousSale = 2;
    } else {
      setAnonymousInputValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configParams]);

  return (
    <div className="container mt-3">
      <ThemeProvider theme={theme}>
        <Grid container spacing={2}>
          <Grid item xs={12} className="mb-4">
            <Box
              display="flex"
              className="pb-1"
              borderBottom={1}
              borderColor="#02D0E7"
            >
              <i
                className="fa-solid fa-circle-info"
                style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
              ></i>
              <p className="txt-primary-md">
                {t(
                  activePointSale
                    ? "pointsOfSale.formEditPointOfSale"
                    : "pointsOfSale.formCreatePointOfSale"
                )}
              </p>
            </Box>
          </Grid>
          {
            //MOSTRAR SELECT CON OPERADORES CUANDO SEA SUPER_ADMIN
            user?.roles.name === Roles_Enum.SUPER_ADMIN && !activePointSale && (
              <Grid item xs={3}>
                <FormControl
                  sx={{ width: "100%" }}
                  size="small"
                  disabled={!!activePointSale}
                >
                  <InputLabel id="operator-label">
                    {`${t("companyManagement.operator")}*`}
                  </InputLabel>
                  <Select
                    name="operator"
                    labelId="operator-label"
                    size="small"
                    fullWidth
                    error={submit && formValues.operator === ""}
                    onChange={(e: any) => {
                      // @ts-ignore
                      handleInputChange(e);
                      setSelectedAssociates([]);
                    }}
                    value={formValues.operator}
                    disabled={!!activePointSale}
                    input={
                      <OutlinedInput
                        label={`${t("companyManagement.operator")}*`}
                      />
                    }
                    MenuProps={MenuProps}
                  >
                    {companies?.data?.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )
          }

          <Grid item xs={6} className="mb-3">
            <FormControl
              sx={{ width: "100%" }}
              size="small"
              error={submit && formValues.associates === 0}
            >
              <Autocomplete
                id="associateSelect"
                data-testid="associateSelect"
                multiple
                size="small"
                autoComplete={false}
                options={dataAssociates}
                value={selectedAssociates}
                onChange={(e, v) => {
                  handleSelectAssociate(e, v);
                }}
                renderOption={(props, option) => {
                  return (
                    <span {...props} style={{ color: "#000" }}>
                      {option.associate ? option.associate.name : option.name}
                    </span>
                  );
                }}
                getOptionLabel={(option) =>
                  option.associate ? option.associate.name : option.name
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => (
                    <Chip
                      style={{
                        backgroundColor: "rgba(0, 126, 140, 0.1)",
                        height: "28px",
                      }}
                      deleteIcon={<ClearIcon style={{ fontSize: 14 }} />}
                      {...getTagProps({ index })}
                      label={
                        option.associate ? option.associate.name : option.name
                      }
                    />
                  ));
                }}
                renderInput={({ inputProps, ...rest }) => (
                  <TextField
                    {...rest}
                    inputProps={{ ...inputProps, readOnly: true }}
                    label={t("Asociados") + "*"}
                    error={submit && errorAssociates}
                  />
                )}
              />
            </FormControl>
          </Grid>
          {countries && (
            <Grid item xs={3}>
              <FormControl
                sx={{ width: "100%" }}
                size="small"
                error={submit && formValues.countries === 0}
              >
                <Autocomplete
                  id="countries"
                  data-testid="countriesSelect"
                  options={countries}
                  disableClearable
                  // @ts-ignore
                  value={selectedCountries}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event: any, value: ICountries | null) =>
                    handleSelectCountry(value)
                  }
                  renderOption={(props, option) => {
                    const { name } = option;
                    return (
                      <span {...props} style={{ color: "#000" }}>
                        {name}
                      </span>
                    );
                  }}
                  renderInput={({ inputProps, ...rest }) => (
                    <TextField
                      {...rest}
                      inputProps={{ ...inputProps, readOnly: true }}
                      name="countries"
                      variant="outlined"
                      size="small"
                      label={t("user.country") + "*"}
                      error={submit && errorCountry}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={3}>
            <Autocomplete
              id="state"
              data-testid="stateSelect"
              options={states ? states : []}
              renderOption={(props, option) => {
                return (
                  <span {...props} style={{ color: "#000" }}>
                    {option.names}
                  </span>
                );
              }}
              disabled={flagStates}
              disableClearable
              // @ts-ignore
              value={selectedStates}
              getOptionLabel={(option) => option.names}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event: any, value: IStates | null) =>
                handleSelectState(value)
              }
              renderInput={({ inputProps, ...rest }) => (
                <TextField
                  {...rest}
                  inputProps={{ ...inputProps, readOnly: true }}
                  name="state"
                  variant="outlined"
                  size="small"
                  label={t("user.states") + "*"}
                  error={submit && errorStates}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              id="city"
              data-testid="citySelect"
              options={cities ? cities : []}
              disabled={flagCity}
              disableClearable
              // @ts-ignore
              value={selectedCities}
              getOptionLabel={(option) => option.names}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event: any, value: ICities | null) =>
                handleSelectCity(value)
              }
              renderOption={(props, option) => {
                return (
                  <span {...props} style={{ color: "#000" }}>
                    {option.names}
                  </span>
                );
              }}
              renderInput={({ inputProps, ...rest }) => (
                <TextField
                  {...rest}
                  inputProps={{ ...inputProps, readOnly: true }}
                  name="city"
                  variant="outlined"
                  size="small"
                  label={t("user.city") + "*"}
                  error={submit && errorCity}
                />
              )}
            />
          </Grid>
          <Grid item xs={3} className="mb-3">
            <TextField
              name="name"
              variant="outlined"
              size="small"
              type="text"
              label={t("pointsOfSale.name") + "*"}
              fullWidth
              sx={styles}
              autoComplete="off"
              onChange={(e: any) => {
                setErrorName(false);
                setErrorEmail(false);
                // @ts-ignore
                handleInputChange(e);
              }}
              value={formValues.name}
              inputProps={{
                "data-testid": "pointSaleName",
                maxLength: 150,
              }}
              error={(submit && formValues.name === "") || errorName}
              helperText={errorName && t("pointsOfSale.alreadyUseName")}
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                !regLettersNumbers(e.key) && e.preventDefault()
              }
            />
          </Grid>
          <Grid item xs={3} className="mb-3">
            <TextField
              name="address"
              variant="outlined"
              size="small"
              type="text"
              label={t("pointsOfSale.address") + "*"}
              fullWidth
              sx={styles}
              autoComplete="off"
              inputProps={{
                "data-testid": "address",
                maxLength: 150,
              }}
              onChange={handleInputChange}
              value={formValues.address}
              error={submit && formValues.address === ""}
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                !regAddress(e.key) && e.preventDefault()
              }
            />
          </Grid>
          <Grid item xs={3} className="mb-3">
            <TextField
              name="email"
              variant="outlined"
              size="small"
              type="text"
              label={t("pointsOfSale.email") + "*"}
              fullWidth
              sx={styles}
              autoComplete="off"
              inputProps={{
                "data-testid": "email",
                maxLength: 100,
              }}
              onChange={(e: any) => {
                setErrorName(false);
                setErrorEmail(false);
                // @ts-ignore
                handleInputChange(e);
              }}
              value={formValues.email}
              error={
                (submit && formValues.email === "") ||
                errorEmail ||
                emailNoValid
              }
              // error={(submit && formValues.email === "") || existsEmail}
              helperText={
                errorEmail
                  ? t("pointsOfSale.alreadyUseEmail")
                  : emailNoValid && t("user.invalidEmail")
              }
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                !regEmail(e.key) && e.preventDefault()
              }
            />
          </Grid>
          <Grid item xs={3} className="mb-3">
            <TextField
              name="phone"
              variant="outlined"
              size="small"
              label={t("pointsOfSale.phone") + "*"}
              fullWidth
              sx={styles}
              autoComplete="off"
              inputProps={{
                "data-testid": "phone",
                maxLength: 10,
              }}
              onChange={handleInputChange}
              value={formValues.phone}
              error={
                (submit && formValues.phone === "") ||
                (formValues.phone !== "" && !validPhone)
              }
              helperText={
                formValues.phone !== "" &&
                !validPhone &&
                t("user.invalidPhone", paramsPhoneError)
              }
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                if (formValues.phone === "") {
                  e.key === "0"
                    ? e.preventDefault()
                    : !regNumbers(e.key) && e.preventDefault();
                } else {
                  !regNumbers(e.key) && e.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={3} className="mb-3">
            <FormControl
              sx={{ width: "100%" }}
              size="small"
              error={submit && formValues.ntc === 0}
            >
              <InputLabel id="NTC-label">
                {t("pointsOfSale.appliesNTC") + "*"}
              </InputLabel>
              <Select
                labelId="ntc-label"
                name="ntc"
                size="small"
                fullWidth
                sx={styles}
                onChange={handleInputChange}
                value={formValues.ntc === 0 ? "" : formValues.ntc}
                inputProps={{
                  "data-testid": "ntcSelect",
                }}
                input={
                  <OutlinedInput label={t("pointsOfSale.appliesNTC") + "*"} />
                }
              >
                <MenuItem key={1} value={1}>
                  {t("user.yes")}
                </MenuItem>
                <MenuItem key={2} value={2}>
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} className="mb-3">
            <FormControl
              sx={{ width: "100%" }}
              size="small"
              error={submit && formValues.allowsTransfer === 0}
            >
              <InputLabel id="transfers-label">
                {t("pointsOfSale.allowsTransfers") + "*"}
              </InputLabel>
              <Select
                labelId="menu-label"
                name="allowsTransfer"
                size="small"
                fullWidth
                sx={styles}
                onChange={handleInputChange}
                value={
                  formValues.allowsTransfer === 0
                    ? ""
                    : formValues.allowsTransfer
                }
                inputProps={{
                  "data-testid": "allowsTransferSelect",
                }}
                input={
                  <OutlinedInput
                    label={t("pointsOfSale.allowsTransfers") + "*"}
                  />
                }
              >
                <MenuItem key={1} value={1}>
                  {t("user.yes")}
                </MenuItem>
                <MenuItem key={2} value={2}>
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {formValues.allowsTransfer === 1 && (
            <>
              <Grid item xs={3} className="mb-3">
                <TextField
                  name="minimumTransfers"
                  variant="outlined"
                  size="small"
                  label={t("pointsOfSale.minimumTransferValue") + "*"}
                  fullWidth
                  sx={styles}
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={formValues.minimumTransfers}
                  error={errorTransfer}
                  helperText={
                    errorTransfer && t("pointsOfSale.errorValueTransfer")
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputComponent: NumericFormatCustom as any,
                  }}
                  inputProps={{ maxLength: 30 }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regNumbers(e.key) && e.preventDefault()
                  }
                />
              </Grid>
              <Grid item xs={3} className="mb-3">
                <TextField
                  name="maximumTransfers"
                  variant="outlined"
                  size="small"
                  label={t("pointsOfSale.maximumTransferValue") + "*"}
                  fullWidth
                  sx={styles}
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={formValues.maximumTransfers}
                  error={errorTransfer}
                  helperText={
                    errorTransfer && t("pointsOfSale.errorValueTransfer")
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputComponent: NumericFormatCustom as any,
                  }}
                  inputProps={{ maxLength: 30 }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regNumbers(e.key) && e.preventDefault()
                  }
                />
              </Grid>
            </>
          )}

          {configParams && configParams.data.length > 0 && (
            <>
              {enableAnonymousSaleSelect && (
                <Grid item xs={6}>
                  <FormControl
                    sx={{ width: "100%" }}
                    size="small"
                    disabled={disabledAnonymousSelect}
                  >
                    <InputLabel>
                      {`${t("pointsOfSale.allowAnonymousSale")}*`}
                    </InputLabel>
                    <Select
                      name="allowAnonymousSale"
                      onChange={handleInputChange}
                      size="small"
                      disabled={disabledAnonymousSelect}
                      fullWidth
                      error={submit && formValues.allowAnonymousSale === ""}
                      value={formValues.allowAnonymousSale}
                      input={
                        <OutlinedInput
                          label={`${t("pointsOfSale.allowAnonymousSale")}*`}
                        />
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem key={1} value={1}>
                        {t("user.yes")}
                      </MenuItem>
                      <MenuItem key={2} value={2}>
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {enableAnonymousPayoutSelect && (
                <Grid item xs={6}>
                  <FormControl
                    sx={{ width: "100%" }}
                    size="small"
                    disabled={disabledAnonymousPayout}
                  >
                    <InputLabel id="operator-label">
                      {`${t("pointsOfSale.allowAnonymousPayouts")}*`}
                    </InputLabel>
                    <Select
                      name="allowAnonymousPayouts"
                      onChange={handleInputChange}
                      size="small"
                      disabled={disabledAnonymousPayout}
                      fullWidth
                      error={submit && formValues.allowAnonymousPayouts === ""}
                      value={formValues.allowAnonymousPayouts}
                      input={
                        <OutlinedInput
                          label={`${t("pointsOfSale.allowAnonymousPayouts")}*`}
                        />
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem key={1} value={1}>
                        {t("user.yes")}
                      </MenuItem>
                      <MenuItem key={2} value={2}>
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </>
          )}

          <Grid item xs={3} className="mb-3">
            <TextField
              name="accountDebitNumber"
              variant="outlined"
              size="small"
              type="text"
              label={t("pointsOfSale.accountDebitNumber") + "*"}
              fullWidth
              sx={styles}
              autoComplete="off"
              onChange={(e: any) => {
                // @ts-ignore
                handleInputChange(e);
              }}
              value={formValues.accountDebitNumber}
              inputProps={{
                maxLength: 8,
              }}
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                !regNumbers(e.key) && e.preventDefault()
              }
            />
          </Grid>
          <Grid item xs={3} className="mb-3">
            <TextField
              name="accountCreditNumber"
              variant="outlined"
              size="small"
              type="text"
              label={t("pointsOfSale.accountCreditNumber") + "*"}
              fullWidth
              sx={styles}
              autoComplete="off"
              onChange={(e: any) => {
                // @ts-ignore
                handleInputChange(e);
              }}
              value={formValues.accountCreditNumber}
              inputProps={{
                maxLength: 8,
              }}
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                !regNumbers(e.key) && e.preventDefault()
              }
            />
          </Grid>
          <Grid item xs={3} className="mb-3">
            <TextField
              name="accountDebitNumberWithdrawals"
              variant="outlined"
              size="small"
              type="text"
              label={t("pointsOfSale.accountDebitNumberWithdrawals") + "*"}
              fullWidth
              sx={styles}
              autoComplete="off"
              onChange={(e: any) => {
                // @ts-ignore
                handleInputChange(e);
              }}
              value={formValues.accountDebitNumberWithdrawals}
              inputProps={{
                maxLength: 8,
              }}
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                !regNumbers(e.key) && e.preventDefault()
              }
            />
          </Grid>
          <Grid item xs={3} className="mb-3">
            <TextField
              name="accountCreditNumberWithdrawals"
              variant="outlined"
              size="small"
              type="text"
              label={t("pointsOfSale.accountCreditNumberWithdrawals") + "*"}
              fullWidth
              sx={styles}
              autoComplete="off"
              onChange={(e: any) => {
                // @ts-ignore
                handleInputChange(e);
              }}
              value={formValues.accountCreditNumberWithdrawals}
              inputProps={{
                maxLength: 8,
              }}
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                !regNumbers(e.key) && e.preventDefault()
              }
            />
          </Grid>
          <Grid item xs={3} className="mb-3">
            <TextField
              name="accountDebitNumberWithdrawalTaxes"
              variant="outlined"
              size="small"
              type="text"
              label={t("pointsOfSale.accountDebitNumberWithdrawalTaxes") + "*"}
              fullWidth
              sx={styles}
              autoComplete="off"
              onChange={(e: any) => {
                // @ts-ignore
                handleInputChange(e);
              }}
              value={formValues.accountDebitNumberWithdrawalTaxes}
              inputProps={{
                maxLength: 8,
              }}
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                !regNumbers(e.key) && e.preventDefault()
              }
            />
          </Grid>
          <Grid item xs={3} className="mb-3">
            <TextField
              name="accountCreditNumberWithdrawalTaxes"
              variant="outlined"
              size="small"
              type="text"
              label={t("pointsOfSale.accountCreditNumberWithdrawalTaxes") + "*"}
              fullWidth
              sx={styles}
              autoComplete="off"
              onChange={(e: any) => {
                // @ts-ignore
                handleInputChange(e);
              }}
              value={formValues.accountCreditNumberWithdrawalTaxes}
              inputProps={{
                maxLength: 8,
              }}
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                !regNumbers(e.key) && e.preventDefault()
              }
            />
          </Grid>
          <Grid item xs={3} className="mb-3">
            <TextField
              name="latitude"
              variant="outlined"
              size="small"
              type="text"
              label={t("pointsOfSale.latitude") + "*"}
              fullWidth
              sx={styles}
              autoComplete="off"
              onChange={(e: any) => {
                // @ts-ignore
                handleInputChange(e);
              }}
              value={formValues.latitude}
              inputProps={{
                maxLength: 100,
              }}
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                !regNumbers(e.key) && e.preventDefault()
              }
            />
          </Grid>
          <Grid item xs={3} className="mb-3">
            <TextField
              name="longitude"
              variant="outlined"
              size="small"
              type="text"
              label={t("pointsOfSale.longitude") + "*"}
              fullWidth
              sx={styles}
              autoComplete="off"
              onChange={(e: any) => {
                // @ts-ignore
                handleInputChange(e);
              }}
              value={formValues.longitude}
              inputProps={{
                maxLength: 100,
              }}
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                !regNumbersReals(e.key) && e.preventDefault()
              }
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="type"
              variant="outlined"
              size="small"
              fullWidth
              select
              label={`${t("pointsOfSale.type")}*`}
              inputProps={{
                maxLength: 3,
              }}
              autoComplete="off"
              onChange={(e: any) => {
                // @ts-ignore
                handleInputChange(e);
              }}
              value={formValues.type}
            >
              {pointType.map((item) => (
                <MenuItem
                  key={item.value}
                  value={item.value}
                  sx={{
                    "&.Mui-focused": {
                      backgroundColor: "red",
                    },
                  }}
                >
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={3} className="mb-3">
            <FormControl
              sx={{ width: "100%" }}
              size="small"
              error={submit && formValues.commissionType === 0}
            >
              <InputLabel id="commissionType-label">
                Modelo de Afiliación*
              </InputLabel>
              <Select
                labelId="commissionType-label"
                name="commissionType"
                size="small"
                fullWidth
                sx={styles}
                onChange={handleInputChange}
                value={
                  formValues.commissionType === 0
                    ? ""
                    : formValues.commissionType
                }
                inputProps={{
                  "data-testid": "commissionTypeSelect",
                }}
                input={<OutlinedInput label="Modelo de Afiliación* " />}
                disabled={
                  activePointSale && activePointSale.commissionType
                    ? true
                    : false
                }
              >
                <MenuItem key={1} value="GGR">
                  GGR
                </MenuItem>
                <MenuItem key={2} value="FTD">
                  FTD
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};
