import React, { FC } from "react";
import { Button } from "@mui/material";
// @ts-ignore
import ReactExport from "react-data-export";
import { useTranslation } from "react-i18next";
import { ICardsList } from "../../../interfaces/cards";
import { StateCards } from "../../../enums/enums";
import moment from "moment";
import { getBarcodeCard } from "../../../utils/utils";
interface props {
  totalData: ICardsList[];
}
export const ExportJerCardsReport: FC<props> = ({ totalData }) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const { t } = useTranslation();

  
  const getStateName = (state: StateCards) => {
    switch (state) {
      case StateCards.ACTIVE:
        return t("state.active");
      case StateCards.INACTIVE:
        return t("state.inactive");
      case StateCards.REDEEMED:
        return t("cards.redeemed");

      default:
        return t("state.inactive");
    }
  };

  return (
    <ExcelFile
      element={
        <Button
          style={{
            border: "none",
            outline: "none",
            fontSize: 14,
          }}
          variant="contained"
          className="btn-dialog primary-btn"
        >
          {t("dialog.exportExcel")}
          <i className="fa-solid fa-file-excel ml-2"></i>
        </Button>
      }
      filename={`report-${moment().format("YYYY-MM-DD")}`}
    >
      <ExcelSheet data={totalData} name="cardsList">
        <ExcelColumn
          label={t("cards.consecutiveCard").toUpperCase()}
          value="id"
        />
        <ExcelColumn
          label={t("dealerWallet.dealer").toUpperCase()}
          value={(card: ICardsList) => card.dealer.name}
        />
        <ExcelColumn
          label={t("cards.barcode").toUpperCase()}
          value={(card: any) => getBarcodeCard(card.value)}
        />

        <ExcelColumn
          label={t("cards.rechargeValue").toUpperCase()}
          value="value"
        />
        <ExcelColumn
          label={t("form.state").toUpperCase()}
          value={(card: ICardsList) => getStateName(card.state)}
        />
        <ExcelColumn
          label={t("sidebar.player").toUpperCase()}
          value={(card: ICardsList) =>
            card.externalUsersCards.length > 0
              ? card.externalUsersCards[0].externalPlayerId
              : ""
          }
        />
        <ExcelColumn
          label={t("table.date").toUpperCase()}
          value={(card: ICardsList) =>
            card.activationDate
              ? moment(card.activationDate).format("YYYY-MM-DD HH:mm:ss")
              : ""
          }
        />
        <ExcelColumn
          label={t("cards.batch").toUpperCase()}
          value={(card: ICardsList) => card.lot.id}
        />
      </ExcelSheet>
    </ExcelFile>
  );
};
