import { Types } from "../types/types";

export interface IColors {
  primary100: string;
  primary90: string;
  primary80: string;
  primary70: string;
  primary60: string;
  primary50: string;
  primary40: string;
  primary20: string;
  primary10: string;
  primary05: string;
  secondary100: string;
  secondary90: string;
  secondary70: string;
  secondary20: string;
  gray100: string;
  gray60: string;
  gray50: string;
  gray20: string;
  white: string;
  error: string;
  emailHeaderBackgroundColor: string;
  emailTitleColor: string;
  emailTextColor: string;
  emailButtonBackgroundColor: string;
  emailFooterBackgroundColor: string;
  emailFooterButtonBackgroundColor: string;
  emailFooterButtonColor: string;
}

export interface ILogo {
  url: string;
  name: string;
}

export interface ISocialMedia {
  show: boolean;
  url: string | null;
}

export interface IFooter {
  facebook: ISocialMedia;
  instagram: ISocialMedia;
  telegram: ISocialMedia;
  tiktok: ISocialMedia;
  twitter: ISocialMedia;
  youtube: ISocialMedia;
}

export interface IContentManagementState {
  colors: IColors;
  footer: IFooter;
  logo: File | ILogo | null;
  alternativeLogo: File | ILogo | null;
}
const initialState: IContentManagementState = {
  colors: {
    primary100: "#ffffff",
    primary90: "#ffffff",
    primary80: "#ffffff",
    primary70: "#ffffff",
    primary60: "#ffffff",
    primary50: "#ffffff",
    primary40: "#ffffff",
    primary20: "#ffffff",
    primary10: "#ffffff",
    primary05: "#ffffff",
    secondary100: "#ffffff",
    secondary90: "#ffffff",
    secondary70: "#ffffff",
    secondary20: "#ffffff",
    gray100: "#ffffff",
    gray60: "#ffffff",
    gray50: "#ffffff",
    gray20: "#ffffff",
    white: "#ffffff",
    error: "#ffffff",
    emailHeaderBackgroundColor: "#ffffff",
    emailTitleColor: "#ffffff",
    emailTextColor: "#ffffff",
    emailButtonBackgroundColor: "#ffffff",
    emailFooterBackgroundColor: "#ffffff",
    emailFooterButtonBackgroundColor: "#ffffff",
    emailFooterButtonColor: "#ffffff",
  },
  footer: {
    facebook: {
      show: true,
      url: null,
    },
    instagram: {
      show: true,
      url: null,
    },
    telegram: {
      show: true,
      url: null,
    },
    tiktok: {
      show: true,
      url: null,
    },
    twitter: {
      show: true,
      url: null,
    },
    youtube: {
      show: true,
      url: null,
    },
  },
  logo: null,
  alternativeLogo: null,
};

interface IContentManagementActions {
  type: Types;
  payload?: IColors | IFooter | ILogo | null;
}
export const contentManagementReducer = (
  state = initialState,
  action: IContentManagementActions
): IContentManagementState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setUpContainerColors:
      return {
        ...state,
        colors: payload as IColors,
      };
    case Types.setLogoOperator:
      return {
        ...state,
        logo: payload as any,
      };
    case Types.setAlternativeLogoOperator:
      return {
        ...state,
        alternativeLogo: payload as any,
      };
    case Types.setFooter:
      return {
        ...state,
        footer: payload as IFooter,
      };
    default:
      return state;
  }
};
