import React, { FC } from 'react'
import { useTranslation } from 'react-i18next';
import { BalanceType } from '../../../../enums/enums';
import { TableCell } from '@mui/material';
import moment from 'moment';
import { ITransactionIrrigation } from '../../../../interfaces/irrigations';
interface props {
    row: ITransactionIrrigation;
  }
export const RowIrrigationDealer:FC<props> = ({row}) => {
    const { t } = useTranslation();
  const getWalletName = (wallet: number) => {
    return t(
      wallet === BalanceType.REFILL
        ? "associates.refillBalance"
        : "irrigation.bettingBalance"
    ).toUpperCase();
  };
  return (
    <>
     <TableCell align="center">{row.id}</TableCell>
      <TableCell align="center">{row.transactionType.name}</TableCell>
      <TableCell align="center">{getWalletName(row.meta.wallet)}</TableCell>
      <TableCell align="center">{row.amount}</TableCell>
      <TableCell align="center">{row.newBalance}</TableCell>
      <TableCell align="center">{row.createBy.nameComplete}</TableCell>
      <TableCell align="center">
        {moment(row.createAt).format("DD/MM/YYYY HH:mm:ss")}
      </TableCell>
    
    </>
  )
}
