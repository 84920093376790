import { FC, useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { MyTextField } from "../../components/custom/MyTextField";
import { MyDialog } from "../../components/custom/MyDialog";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { startUpdateCommission } from "../../../redux/actions/affiliationsActions";
import { regPercentage } from "../../../utils/utils";

const AffiliationCommissionFTDForm: FC<{ setShowForm: any; setValue: any }> = ({
  setShowForm,
  setValue,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading } = useSelector((state: AppState) => state.helper);
  const { activeCompany } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const initialValues = {
    affiliationCommissionFTD: activeCompany
      ? parseFloat(activeCompany.affiliationCommissionFTD)
      : "",
  };
  const validationSchema = yup.object({
    affiliationCommissionFTD: yup.string().required(t("form.requiredFile")),
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [saveValues, setSaveValues] = useState<any>(null);

  const handleAccept = () => {
    dispatch(startUpdateCommission(saveValues));
    setShowForm(false);
    setValue("");
  };

  return (
    <div className="category-form">
      <Formik
        initialValues={initialValues}
        validateOnChange={true}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          setSaveValues(data);
          setOpenDialog(true);
        }}
      >
        {({ handleChange }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <label className="ml-3">Porcentaje de la comisión</label>
                <MyTextField
                  name="affiliationCommissionFTD"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onKeyPress={(e: any) =>
                    !regPercentage(e.key) && e.preventDefault()
                  }
                  label=""
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    variant="contained"
                    className="footer-btn danger-btn ripple"
                    style={{ border: "none", outline: "none" }}
                    onClick={() => {
                      setShowForm(false);
                      setValue("");
                    }}
                  >
                    {t("button.cancel").toUpperCase()}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className="footer-btn primary-btn ripple"
                    style={{ border: "none", outline: "none" }}
                  >
                    {loading ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      t("button.save").toUpperCase()
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <MyDialog
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message="¿Estás seguro de actualizar el valor de la comisión?"
        handleAccept={handleAccept}
        handleClose={() => setOpenDialog(false)}
        isLoading={!!loading}
      />
    </div>
  );
};

export default AffiliationCommissionFTDForm;
