import { IBodyDealer, IDealer, IDealersQuery } from "../../interfaces/cards";
import { createDealer, getDealers, updateDealer } from "../../services/dealers";
import { buildFinalQueryParams } from "../../utils/utils";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import { setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";

export const startGetDelaers = (params: Partial<IDealersQuery>) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;

    if (!token || !user) {
      throw new Error("invalid action");
    }

    try {
      if (params.page) {
        params.perPage = rowsPerPage;
      }
      if (user.operator) {
        params.operator = user.operator.id;
      }
      let finalValues = buildFinalQueryParams(params);
      const { associatesUsers } = user;
      const associateId =
        associatesUsers.length > 0 ? associatesUsers[0].associate.id : null;
      if (associateId) {
        finalValues.associate = associateId;
      }

      const response = await getDealers(finalValues, token);
      if (response.error) {
        dispatch(
          setMessageAlert(`managementDealers.${response.error.errorServer}`)
        );
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      dispatch(setDealers(response));
    } catch (error) {}
  };
};

export const setDealers = (dealer: any) => ({
  type: Types.setDealers,
  payload: dealer,
});

export const startAddDealer = (dealer: IBodyDealer) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    if (!token || !user) {
      throw new Error("Invalid action");
    }
    //link associate
    const { associatesUsers } = user;
    const associateId =
      associatesUsers.length > 0 ? associatesUsers[0].associate.id : null;
    //createBy user
    const createBy = user.id;

    if (associateId) {
      dealer.associate = associateId;
    }
    dealer.createBy = createBy;
    const response = await createDealer(dealer, token);
    const messageAlert = response.error
      ? response.error.errorServer
      : "createDealerSuccess";
    const alertType = response.error ? "error" : "success";

    dispatch(setMessageAlert(`managementDealers.${messageAlert}`));
    dispatch(setTypeAlert(alertType));
    if (response.error) {
      dispatch(setOpenAlert());
      throw new Error(response.error);
    }
    await dispatch(startGetDelaers({ page: 1 }));

    return dispatch(setOpenAlert());
  };
};
export const startUpdateDealer = (id: number, dealer: Partial<IBodyDealer>) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { dealers } = (getState() as AppState).managementDealers;
    if (!token) {
      throw new Error("Invalid action");
    }
    const response = await updateDealer(id, dealer, token);
    const messageAlert = response.error
      ? response.error.errorServer
      : "updateDealerSuccess";
    const alertType = response.error ? "error" : "success";

    dispatch(setMessageAlert(`managementDealers.${messageAlert}`));
    dispatch(setTypeAlert(alertType));
    if (response.error) {
      dispatch(setOpenAlert());
      throw new Error(messageAlert);
    }
    await dispatch(startGetDelaers({ page: dealers?.page }));

    return dispatch(setOpenAlert());
  };
};
export const setActiveDealer = (dealer: any) => ({
  type: Types.setActiveDealer,
  payload: dealer,
});
export const setDealersRedux = (dealers: IDealer[]) => ({
  type: Types.setDealersPointSale,
  payload: dealers,
});
