import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: "blue",
  },
  top: {
    //color: "red",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));
export const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number }
) => {
  const classes = useStyles();
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          //className={classes.bottom}
          size={"10rem"}
          sx={{ color: "#D5D5D5" }}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          disableShrink
          className={classes.top}
          size={"10rem"}
          sx={{ color: "#FF5757" }}
          classes={{
            circle: classes.circle,
          }}
          thickness={4}
          {...props}
        />
      </div>

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          fontSize={18}
          fontWeight={"bold"}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};
