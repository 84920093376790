import { IPlayerByTypeAndNumberDocumentParams } from "../interfaces/players";

export const playerByTypeAndNumberDocumentService = (
  token: string,
  params: Partial<IPlayerByTypeAndNumberDocumentParams>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}players/player/document?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const getExternalDeposit = (token: string, params: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}depositsQr/deposit?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const approveOrDeny = (token: string, params: any, id: number) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(params),
  };
  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}depositsQr/deposit/update/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
