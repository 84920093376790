import React, { useState } from "react";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import fileText from "../../../assets/svg/file-text.svg";

import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Loading } from "../../../components/Loading/Loading";
import { setLoadingState } from "../../../redux/actions/helperActions";
import { generalReports } from "../../../services/reports";
import { BlobProvider } from "@react-pdf/renderer";
import { PdfDocument } from "../../../components/Pdf/PdfDocument";
import { Box, CircularProgress, ListItem, ListItemIcon } from "@mui/material";
import { useTranslation } from "react-i18next";
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <i className="fa-solid fa-caret-right" style={{ color: "#00458D" }}></i>
    }
    {...props}
  />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: `1px solid #9FB8ED`,
  },
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
export const ReportsAccordion = () => {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [totalData, setTotalData] = useState<any>();
  const { loading } = useSelector((state: AppState) => state.helper);
  const { token, user } = useSelector((state: AppState) => state.auth);
  const [reportType, setReportType] = useState<string>('grossIncomeReport');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChange = (panel: string) => async (event: React.SyntheticEvent, newExpanded: boolean) => {
    dispatch(setLoadingState({ loading: true }));
    if (newExpanded) {
      const response = await generalReports(user && user.operator ? user.operator.id : null, token, panel) as any;
      setReportType(panel);
      setTotalData(panel === 'jackpotAwardsReport' ? response : [response]);
    }
    setExpanded(newExpanded ? panel : false);
    dispatch(setLoadingState({ loading: false }));
  };
  return (
    <>
      {loading && <Loading />}
      {
        ['grossIncomeReport', 'jackpotAwardsReport', 'relevantEventsReport', 'significantEventsReport'].map((type: string, index: number) => {
          return (
            <Accordion
              expanded={expanded === type}
              onChange={handleChange(type)}
              key={index}
            >
              <AccordionSummary aria-controls={`${type}-content`} id={`${type}-header`}>
                <span>{t(`awardsReports.${type}`)}</span>
              </AccordionSummary>
              <AccordionDetails>
                {totalData && (
                  <BlobProvider
                    document={
                      <PdfDocument
                        component={reportType}
                        data={totalData}
                        id={index}
                      />
                    }
                  >
                    {({ url, loading }) =>
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      loading ? (
                        <Box
                          display={"flex"}
                          justifyContent="center"
                          alignItems={"center"}
                          width="100%"
                          mt={3}
                        >
                          <CircularProgress className={"colorLoading"} />
                        </Box>
                      ) : (
                        url && (
                          <ListItem
                            role="listitem"
                          >
                            <ListItemIcon sx={{ mr: -3, ml: -0.2 }}>
                              <img width={"19px"} src={fileText} alt="File text" />
                            </ListItemIcon>

                            <a
                              href={url}
                              style={{ color: "#333333" }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {t("dialog.exportPdf")}</a>
                          </ListItem>
                        )
                      )
                    }
                  </BlobProvider>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })
      }
    </>
  );
};
