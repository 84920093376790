import { Types } from "../types/types";

interface IUiState {
  error: boolean;
  errorMessage: string | null;
  openNotifications:boolean,
  loading:boolean,
  openDialog:boolean,
  dialogMessage:string | null;
  networkState:"Online" | "Offline"
}
interface IUiAction {
  type: Types;
  payload: IUiState;
}

const initialState: IUiState = {
  error: false,
  errorMessage: null,
  openNotifications:false,
  loading:false,
  openDialog:false,
  dialogMessage:null,
  networkState:"Online"
};

export const uiReducer = (state = initialState, action: IUiAction):IUiState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setError:
      return {
        ...state,
        error: payload as any,
      };

    case Types.setErrorMessage:
      return {
        ...state,
        errorMessage: payload as any,
      };
    case Types.setOpenNotifications:
      return {
        ...state,
        openNotifications: !state.openNotifications,
      };
    case Types.closeNotifications:
      return {
        ...state,
        openNotifications: false,
      };
    case Types.setOpenDialog:
      return {
        ...state,
        openDialog: !state.openDialog,
      };
    case Types.setDialogMessage:
      return {
        ...state,
        dialogMessage: payload as any,
      };
    case Types.setNetworkState:
      return {
        ...state,
        networkState: payload as any,
      };
    

    default:
      return state;
  }
};
