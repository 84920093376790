import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Box, IconButton, MenuItem, TextField } from "@mui/material";

import { AppState } from "../../redux/reducers/rootReducer";
import { setRowsPerPage } from "../../redux/actions/helperActions";

interface Props {
  onPageChange(page:number,rows:number): any;
  total?: number | null;
}

export const StaticPagination: FC<Props> = ({ onPageChange, total }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let rows = [9, 18, 27];

  const { rowsPerPage } = useSelector((state: AppState) => state.helper);

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const rows = parseInt(event.target.value)
    dispatch(setRowsPerPage(rows));
    onPageChange(1,rows);
  };

  return (
    <div className="d-flex justify-content-center m-0 p-0">
      {total !== 0 && (
        <Box
          mr={2}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <span>{`1 - ${total} ${t("table.of")} ${total}`}</span>
        </Box>
      )}
      {/* Flecha de navegación primera página */}
      <IconButton
        disabled
        size="small"
        sx={{ color: "#000" }}
        style={{ border: "none", outline: "none" }}
      >
        &lt;&lt;
      </IconButton>
      <ul className="ulStyle pagination-container pagination-bar mt-3">
        {/* Flecha de navegación izquierda */}
        <IconButton
          sx={{ color: "#000" }}
          style={{ border: "none", outline: "none" }}
          size="small"
          disabled
        >
          &lt;
        </IconButton>
        {/* Render numbers para la página */}
        <li className="pagination-item selected">1</li>
        {/* Flecha de navegación derecha  */}
        <IconButton
          sx={{ color: "#000" }}
          style={{ border: "none", outline: "none" }}
          size="small"
          disabled
        >
          &gt;
        </IconButton>
      </ul>
      {/* Flecha de navegación ultima página */}
      <IconButton
        size="small"
        sx={{ color: "#000" }}
        style={{ border: "none", outline: "none" }}
        disabled
      >
        &gt;&gt;
      </IconButton>

      <Box
        ml={2}
        width={"30%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <TextField
          name="rowsPerPage"
          size="small"
          select
          InputLabelProps={{ shrink: false }}
          label={" "}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {rows.map((row) => (
            <MenuItem key={row} value={row}>
              {row}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </div>
  );
};
