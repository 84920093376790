import { FC } from "react";
import {
  ActionsTable,
  IActionsProps,
} from "../../../components/Custom/Table/ActionsTable";
import { IIpBlacklist } from "../../../interfaces/ipBlacklist";
import { Grid, TableCell } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { Roles_Enum } from "../../../enums/enums";
import { useLocation } from "react-router-dom";
import { AppState } from "../../../redux/reducers/rootReducer";

interface Props {
  row: IIpBlacklist;
  actions?: IActionsProps;
  onDelete?(row: any): void;
  onEdit?(row: any): void;
}
export const RowIpAddreses: FC<Props> = ({
  row,
  actions,
  onDelete,
  onEdit,
}) => {
  const { user } = useSelector((state: AppState) => state.auth);

  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const { pathname } = useLocation();
  const { routes } = useSelector((state: AppState) => state.managementMenus);

  const menuPermissions = routes.find(
    (route) =>
      !!route.menuItem.find((module) => module.url === pathname) ||
      route.url === pathname
  );

  let finalActions: IActionsProps = { ...actions };
  if (menuPermissions) {
    const editAction =
      finalActions.edit !== undefined ? finalActions.edit : false;
    const deleteAction =
      finalActions.delete !== undefined ? finalActions.delete : false;
    const seeMoreAction =
      finalActions.seeMore !== undefined ? finalActions.seeMore : false;
    if (menuPermissions.menuItem.length > 0) {
      const modulePermissions = menuPermissions.menuItem.find(
        (module) => module.url === pathname
      );

      finalActions.edit = isSuper
        ? editAction
        : modulePermissions && modulePermissions.permissions?.updated === 1
        ? editAction
        : false;
      finalActions.delete = isSuper
        ? deleteAction
        : finalActions.delete !== undefined
        ? finalActions.delete
        : modulePermissions && modulePermissions.permissions?.deleted === 1
        ? deleteAction
        : false;
      finalActions.seeMore = isSuper
        ? seeMoreAction
        : modulePermissions?.permissions?.readed === 1
        ? seeMoreAction
        : false;
    } else {
      const permissions = menuPermissions.permissionsMenu;

      finalActions.edit = isSuper
        ? editAction
        : permissions?.updated === 1
        ? finalActions.edit !== undefined
          ? finalActions.edit
          : editAction
        : false;
      finalActions.delete = isSuper
        ? deleteAction
        : permissions?.deleted === 1
        ? finalActions.delete !== undefined
          ? finalActions.delete
          : deleteAction
        : false;
      finalActions.seeMore = isSuper
        ? seeMoreAction
        : permissions?.readed === 1
        ? finalActions.seeMore !== undefined
          ? finalActions.seeMore
          : seeMoreAction
        : false;
    }
  }
  return (
    <>
      <TableCell>{row.id}</TableCell>
      <TableCell>{row.ipAddress}</TableCell>
      <TableCell>
        {moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss A")}
      </TableCell>
      <TableCell>{row.reason}</TableCell>
      <TableCell>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {actions && (
              <ActionsTable
                actions={{
                  ...finalActions,
                }}
                onDelete={() => onDelete && onDelete(row)}
                onEdit={() => onEdit && onEdit(row)}
              />
            )}
          </Grid>
        </Grid>
      </TableCell>
    </>
  );
};
