import { arraySearch } from "./searchActions";
import {
  addFreeTicketsBonus,
  bonusGroupList,
  createBonusType,
  createNewBonus,
  deleteBonus,
  deleteBonusType,
  filterBonus,
  filterBonusPariplay,
  filterFreeTicketsBonus,
  getNewBonusType,
  toogleBonusAltenar,
  updateBonus,
  updateBonusAltenar,
  updateBonusType,
} from "./../../services/bonus";
import {
  setOpenAlert,
  setMessageAlert,
  setTypeAlert,
  setLoadingState,
  setFlagForm,
} from "./helperActions";

import {
  IBonusForm,
  IBonusPariplayDb,
  IBonusType,
  IBonusTypeBody,
  IBonusTypeQuery,
  IDataFreeTicketsBonus,
  IFreeTicketsBonus,
} from "./../../interfaces/bonus";
import { Types } from "./../types/types";
import { IBonusDb, IBonus } from "../../interfaces/bonus";
import {
  createBonus,
  createBonusAltenar,
  getBonus,
  getBonusType,
} from "../../services/bonus";
import { AppState } from "./../reducers/rootReducer";
import { ProvidersBonus } from "../../enums/enums";
import moment from "moment";
import { createFreeRounds } from "../../services/pragmatic";
import { createUniquePragmaticCode } from "../../helpers/uniqueBonusPragmaticCode";
import { handlePragmaticBonusCreation } from "../../helpers/pragmaticFreeRoundsHelper";
import { Dispatch } from "redux";

export const starGetBonus = () => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    dispatch(setLoadingState({ loading: true }));
    try {
      if (!user || !token) {
        throw new Error("NOT AVAILABLE ACTION");
      }
      const idOperator = user.operator ? user.operator.id : undefined;
      const response = await getBonus(idOperator, token, rowsPerPage);
      if (response && !response.error) {
        dispatch(arraySearch(null));
        dispatch(setBonus(response));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorSetBonds"));
        dispatch(setTypeAlert("error"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};
export const setBonus = (bonus: IBonusDb | null) => ({
  type: Types.setBonus,
  payload: bonus,
});
export const startUpdateBonusType = (id: number, data: IBonusTypeBody) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    try {
      if (!user || !token) {
        throw new Error("NOT AVAILABLE ACTION");
      }
      const response = await updateBonusType(token, id, data);
      if (response && !response.error) {
        await dispatch(
          startGetBonusType({
            page: 1,
            perPage: rowsPerPage,
            operator: user.operator.id,
          })
        );
        dispatch(setMessageAlert("managementBonds.updatedBonusType"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
    }
  };
};
export const starCreateBonusType = (data: IBonusTypeBody) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    try {
      if (!user || !token) {
        throw new Error("NOT AVAILABLE ACTION");
      }
      const response = await createBonusType(token, data);
      if (response && !response.error) {
        let params: any = {
          page: 1,
          perPage: rowsPerPage,
        };
        if (user.operator) {
          //@ts-ignore
          params = {
            ...params,
            operator: user.operator.id,
          };
        }
        await dispatch(startGetBonusType(params));
        dispatch(setMessageAlert("managementBonds.createBonusType"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};
export const startDeleteBonusType = (id: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    try {
      if (!user || !token) {
        throw new Error("NOT AVAILABLE ACTION");
      }
      const response = await deleteBonusType(token, id);
      if (response && !response.error) {
        await dispatch(
          startGetBonusType({
            page: 1,
            perPage: rowsPerPage,
            operator: user.operator.id,
          })
        );
        dispatch(setMessageAlert("managementBonds.deletedBonusType"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};
export const startGetBonusType = (params?: Partial<IBonusTypeQuery>) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    if (!user || !token) {
      throw new Error("NOT AVAILABLE ACTION");
    }
    const response = await getBonusType(
      token,
      params as Partial<IBonusTypeQuery>
    );

    dispatch(setBonusType(response));
  };
};
export const setBonusType = (bonusType: IBonusType[] | null) => ({
  type: Types.setBonustypes,
  payload: bonusType,
});
export const setActiveBonusType = (active: IBonusType | null) => ({
  type: Types.setActiveBonusType,
  payload: active,
});

export const startCreateBonus = (data: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    try {
      if (!user || !token) {
        throw new Error("NOT AVAILABLE ACTION");
      }
      const {
        state,
        amountBonus,
        totalBondsValue,
        type,
        value,
        operator,
        ...rest
      } = data;
      const bonusPragmaticCode = await createUniquePragmaticCode(token);

      const finalBonus: IBonusForm = {
        ...rest,
        state,
        amountBonus: parseInt(amountBonus),
        totalValue: totalBondsValue,
        bonusType: type,
        operator: operator,
        createdUser: user.id,
        value: parseFloat(value),
        pragmaticBonusCode:
          rest.provider === ProvidersBonus.PRAGMATIC
            ? bonusPragmaticCode
            : null,
        pragmaticNumberRounds: parseInt(rest.pragmaticNumberRounds),
      };
      if (finalBonus.provider === ProvidersBonus.PRAGMATIC) {
        // hacer seteo de datos para enviar la peticion al back pragmatic
        const parseStartDate = moment(finalBonus.startDate)
          .startOf("day")
          .unix();
        const parseEndDate = moment(finalBonus.endDate).endOf("day").unix();
        const gameList: any = finalBonus.pragmaticBonus;
        const parseGameList = gameList.map((item: any) => {
          let betValues = [...item.betValues];
          betValues[0].totalBet = parseFloat(betValues[0].totalBet);

          return {
            ...item,
            betValues,
          };
        });

        const rsPragmatic = await createFreeRounds(
          token,
          {
            bonusCode: bonusPragmaticCode,
            expirationDate: parseEndDate,
            startDate: parseStartDate,
            rounds: finalBonus.pragmaticNumberRounds,
          },
          {
            gameList: parseGameList,
          }
        );

        if (rsPragmatic.error !== "0") {
          dispatch(setTypeAlert("error"));
          dispatch(
            setMessageAlert("managementBonds.errorCreateBonusPragmatic")
          );
          dispatch(setOpenAlert());
          return dispatch(setLoadingState({ loading: false }));
        }
      }

      const newStartDate = moment(
        `${data.startDate} ${data.startTime || "00:00:00"}`
      ).add(5, "hours");
      const newEndDate = moment(
        `${data.endDate} ${data.endTime || "23:59:59"}`
      ).add(5, "hours");
      let newData = {
        ...finalBonus,
        startDate: newStartDate.format("YYYY-MM-DD 00:00:00"),
        endDate: newEndDate.format("YYYY-MM-DD 23:59:59"),
        startTime: newStartDate.format("HH:mm"),
        endTime: newEndDate.format("HH:mm"),
      };

      const response = await createBonus(newData, token);
      if (response && !response.error) {
        dispatch(arraySearch(null));
        dispatch(setMessageAlert("managementBonds.createdBonus"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
        dispatch(setFlagForm({ flagFormCreate: false }));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("managementBonds.errorCreteBonus"));
        dispatch(setTypeAlert("error"));
        dispatch(setLoadingState({ loading: false }));
      }
    } catch (error) {
      console.log(error);
      dispatch(setLoadingState({ loading: false }));
    }
  };
};
export const startCreateBonusAltenar = (data: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    try {
      if (!user || !token) {
        throw new Error("NOT AVAILABLE ACTION");
      }
      const {
        state,
        amountBonus,
        totalBondsValue,
        type,
        value,
        operator,
        ...rest
      } = data;

      const finalBonus: IBonusForm = {
        ...rest,
        state,
        amountBonus: parseInt(amountBonus),
        totalValue: totalBondsValue,
        bonusType: type,
        operator: operator,
        createdUser: user.id,
        value: parseFloat(value),
        pragmaticBonusCode: null,
        pragmaticNumberRounds: null,
      };

      const newStartDate = moment(`${data.startDate} ${data.startTime}`).add(
        5,
        "hours"
      );

      const newEndDate = moment(`${data.endDate} ${data.endTime}`).add(
        5,
        "hours"
      );

      let newData = {
        ...finalBonus,
        startDate: newStartDate.format("YYYY-MM-DD HH:mm"),
        endDate: newEndDate.format("YYYY-MM-DD HH:mm"),
      };

      const response = await createBonusAltenar(newData, token);
      if (response && !response.error) {
        dispatch(arraySearch(null));
        dispatch(setMessageAlert("managementBonds.createdBonus"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("managementBonds.errorCreteBonus"));
        dispatch(setTypeAlert("error"));
        dispatch(setLoadingState({ loading: false }));
      }
    } catch (error) {
      console.log(error);
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

const transformDataBonus = async (dataBonus: any, token: string) => {
  const {
    state,
    amountBonus,
    totalBondsValue,
    type,
    value,
    operator,
    ...rest
  } = dataBonus;

  const bonusPragmaticCode = await createUniquePragmaticCode(token);
  const provider = rest.provider.id ? rest.provider.id : rest.provider;

  return {
    ...rest,
    state,
    amountBonus: parseInt(amountBonus),
    totalValue: totalBondsValue,
    bonusType: type,
    operator: operator,
    value: parseInt(value),
    pragmaticBonusCode:
      provider === ProvidersBonus.PRAGMATIC ? bonusPragmaticCode : null,
    pragmaticNumberRounds:
      provider === ProvidersBonus.PRAGMATIC
        ? parseInt(rest.pragmaticNumberRounds)
        : null,
  };
};
const prepareBonusDataForUpdatePragmatic = (
  finalBonus: any,
  activeBonus: any
) => {
  const parseStartDate = moment(finalBonus.startDate).startOf("day").unix();
  const parseEndDate = moment(finalBonus.endDate).endOf("day").unix();
  const parseGameList = finalBonus.pragmaticBonus.map((item: any) => {
    let betValues = [...item.betValues];
    betValues[0].totalBet = parseInt(betValues[0].totalBet);

    return {
      ...item,
      betValues,
    };
  });

  return {
    dataBonus: finalBonus,
    dataPragmatic: {
      bonusCode: finalBonus.pragmaticBonusCode,
      expirationDate: parseEndDate,
      startDate: parseStartDate,
      rounds: finalBonus.pragmaticNumberRounds,
      gameList: parseGameList,
    },
    bonusCode: activeBonus.pragmaticBonusCode as string,
  };
};
const handleResponse = (dispatch: Function, response: any) => {
  const messageType = response && !response.error ? "success" : "error";
  const messageKey =
    response && !response.error
      ? "managementBonds.updatedBonus"
      : "managementBonds.errorEditBonus";

  dispatch(setMessageAlert(messageKey));
  dispatch(setTypeAlert(messageType));
  dispatch(setOpenAlert());

  if (messageType === "error") {
    dispatch(setLoadingState({ loading: false }));
  }
};
function filterEmptyValues(json: any) {
  let result: any = {};
  for (let key in json) {
    if (json[key] !== null && json[key] !== undefined && json[key] !== "") {
      result[key] = json[key];
    }
  }
  return result;
}
export const startUpdateBonus = (dataBonus: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { auth, bonus } = getState() as AppState;
    const { user, token } = auth;
    const { activeBonus } = bonus;
    if (!user || !token || !activeBonus) {
      throw new Error("NOT AVAILABLE ACTION");
    }
    const finalBonus: any = await transformDataBonus(dataBonus, token);
    try {
      const isPragmaticProvider = (provider: any) =>
        provider?.id
          ? provider.id === ProvidersBonus.PRAGMATIC
          : provider === ProvidersBonus.PRAGMATIC;

      if (isPragmaticProvider(finalBonus.provider)) {
        const objUpdatedBonus = prepareBonusDataForUpdatePragmatic(
          finalBonus,
          activeBonus
        );
        const response = await updateBonus(
          activeBonus.id,
          objUpdatedBonus,
          token
        );
        handleResponse(dispatch, response);
      }
      if (finalBonus.provider === ProvidersBonus.ALTENAR) {
        let data: any = filterEmptyValues(dataBonus);
        const newStartDate = moment(`${data.startDate} ${data.startTime}`).add(
          5,
          "hours"
        );
        const newEndDate = moment(`${data.endDate} ${data.endTime}`).add(
          5,
          "hours"
        );
        data = {
          ...data,
          startDate: newStartDate.format("YYYY-MM-DD 00:00:00"),
          endDate: newEndDate.format("YYYY-MM-DD 23:59:59"),
          startTime: newStartDate.format("HH:mm"),
          endTime: newEndDate.format("HH:mm"),
        };
        const response = await updateBonusAltenar(data, token);
        handleResponse(dispatch, response);
      }
    } catch (error) {
      console.log(error);
      dispatch(setLoadingState({ loading: false }));
    }
  };
};
export const startDeleteBonus = () => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;

    const { activeBonus } = (getState() as AppState).bonus;
    if (!user || !token || !activeBonus) {
      throw new Error("NOT AVAILABLE ACTION");
    }

    const response = await deleteBonus(activeBonus.id, token);
    if (response && !response.error) {
      dispatch(setActiveBonus(null));
      dispatch(setMessageAlert("managementBonds.deletedBonus"));
      dispatch(setTypeAlert("success"));
      dispatch(setOpenAlert());
    } else {
      dispatch(setMessageAlert("managementBonds.errorDeleteBonus"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};
export const startToggleBonusAltenar = (dataBonus: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { auth, bonus } = getState() as AppState;
    const { user, token } = auth;
    const { activeBonus } = bonus;
    if (!user || !token || !activeBonus) {
      throw new Error("NOT AVAILABLE ACTION");
    }
    try {
      const response = await toogleBonusAltenar(dataBonus, token);
      handleResponse(dispatch, response);
    } catch (error) {
      console.log(error);
      dispatch(setLoadingState({ loading: false }));
    }
  };
};
export const setActiveBonus = (bonus: IBonus | null) => ({
  type: Types.setActiveBonus,
  payload: bonus,
});

export const startFilterBonus = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (params.page) {
      params.perPage = rowsPerPage;
    }

    try {
      if (token) {
        let finalValues: any = {};

        Object.entries(params).forEach(([key, value], index) => {
          if (
            //@ts-ignore
            value.length > 0 ||
            typeof value === "number" ||
            typeof value === "boolean"
          ) {
            //@ts-ignore
            finalValues[key] = `${value}`;
          }
        });

        const response =
          user && (await filterBonus(user.operator?.id, finalValues, token));

        if (response && !response.error) {
          dispatch(setBonus(response));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetBonds"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};
export const setOpenBonusDetails = (open: boolean) => ({
  type: Types.setOpenBonusDetails,
  payload: open,
});

export const startGetBonusGroup = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (params.page) {
      params.perPage = rowsPerPage;
    }

    try {
      if (token) {
        let finalValues: any = {};

        Object.entries(params).forEach(([key, value], index) => {
          if (
            //@ts-ignore
            value.length > 0 ||
            typeof value === "number" ||
            typeof value === "boolean"
          ) {
            //@ts-ignore
            finalValues[key] = `${value}`;
          }
        });

        const response =
          user && (await bonusGroupList(user.operator?.id, finalValues, token));

        if (response && !response.error) {
          dispatch(setBonusGroup(response));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetBonds"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const setBonusGroup = (data: any) => ({
  type: Types.setBonusGroup,
  payload: data,
});

export const setActiveBonusGroup = (bonus: any | null) => ({
  type: Types.setActiveBonusGroup,
  payload: bonus,
});

export const startGetReportBonus = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (params.page) {
      params.perPage = rowsPerPage;
    }

    try {
      if (token) {
        let finalValues: any = {};

        Object.entries(params).forEach(([key, value], index) => {
          if (
            //@ts-ignore
            value.length > 0 ||
            typeof value === "number" ||
            typeof value === "boolean"
          ) {
            //@ts-ignore
            finalValues[key] = `${value}`;
          }
        });

        const response =
          user && (await filterBonus(user.operator?.id, finalValues, token));

        if (response && !response.error) {
          dispatch(arraySearch(response));
        } else {
          dispatch(arraySearch(null));
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetBonds"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const startFilterBonusPariplay = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (params.page) {
      params.perPage = rowsPerPage;
    }

    try {
      if (token) {
        let finalValues: any = {};

        Object.entries(params).forEach(([key, value], index) => {
          if (
            //@ts-ignore
            value.length > 0 ||
            typeof value === "number" ||
            typeof value === "boolean"
          ) {
            //@ts-ignore
            finalValues[key] = `${value}`;
          }
        });

        const response =
          user &&
          (await filterBonusPariplay(user.operator?.id, finalValues, token));

        if (response && !response.error) {
          dispatch(setBonusPariplay(response));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetBonds"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};
export const setBonusPariplay = (bonusPariplay: IBonusPariplayDb | null) => ({
  type: Types.setBonusPariplay,
  payload: bonusPariplay,
});

export const startCreateFreeTicketsBonus = (
  settings: IDataFreeTicketsBonus
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { user, token } = (getState() as AppState).auth;
      if (!token || !user) {
        throw new Error("INVALID ACTION");
      }

      const rs = await addFreeTicketsBonus(token, settings);
      if (rs && !rs.error) {
        dispatch(setMessageAlert("freeTicketsBonus.createdSuccessfully"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
        dispatch(startFilterFreeTicketsBonus({ page: 1 }));
        dispatch(setFlagForm({ flagFormCreate: false }));
      } else {
        let errorMsg = "createdError";
        errorMsg = rs.error.errorMessage;
        dispatch(setMessageAlert(`freeTicketsBonus.${errorMsg}`));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startFilterFreeTicketsBonus = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (params.page) {
      params.perPage = rowsPerPage;
    }

    try {
      if (token) {
        let finalValues: any = {};

        Object.entries(params).forEach(([key, value], index) => {
          if (
            //@ts-ignore
            value.length > 0 ||
            typeof value === "number" ||
            typeof value === "boolean"
          ) {
            //@ts-ignore
            finalValues[key] = `${value}`;
          }
        });

        const response =
          user && (await filterFreeTicketsBonus(finalValues, token));

        if (response && !response.error) {
          dispatch(setFreeTicketsBonus(response));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetBonds"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};
export const setFreeTicketsBonus = (bonus: IFreeTicketsBonus | null) => ({
  type: Types.setFreeTicketsBonus,
  payload: bonus,
});

export const startCreateNewBonus = (data: any) => {
  return async (dispatch: Dispatch, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    try {
      if (!user || !token) {
        throw new Error("NOT AVAILABLE ACTION");
      }
      const {
        state,
        amountBonus,
        totalBondsValue,
        expirationDays,
        type,
        value,
        operator,
        pragmaticBonusCode,
        percentageDeposit,
        minDeposit,
        maxDeposit,
        ...rest
      } = data;

      const finalBonus: IBonusForm = {
        ...rest,
        state,
        percentageDeposit: parseFloat(percentageDeposit),
        minDeposit: parseFloat(minDeposit),
        maxDeposit: parseFloat(maxDeposit),
        amountBonus: parseInt(amountBonus),
        expirationDays: parseInt(expirationDays),
        totalValue: percentageDeposit
          ? parseFloat(totalBondsValue)
          : totalBondsValue,
        bonusType: type,
        operator: operator,
        createdUser: user.id,
        value: parseFloat(value),
        pragmaticBonusCode,
        pragmaticNumberRounds: rest.pragmaticNumberRounds
          ? parseInt(rest.pragmaticNumberRounds)
          : 1,
      };
      // CREATE BONUS IN PRAGMATIC
      if (finalBonus.provider === ProvidersBonus.PRAGMATIC) {
        const rsPragmatic = await handlePragmaticBonusCreation(
          finalBonus,
          token,
          pragmaticBonusCode,
          dispatch
        );
        if (rsPragmatic.error !== "0") {
          console.log("first");
          dispatch(setTypeAlert("error"));
          dispatch(
            setMessageAlert("managementBonds.errorCreateBonusPragmatic")
          );
          dispatch(setOpenAlert());
          dispatch(setLoadingState({ loading: false }));
          return;
        }
      }

      const newStartDate = moment(
        `${data.startDate} ${data.startTime || "00:00:00"}`
      ).add(5, "hours");
      const newEndDate = moment(
        `${data.endDate} ${data.endTime || "23:59:59"}`
      ).add(5, "hours");
      let newData = {
        ...finalBonus,
        startDate: newStartDate.format("YYYY-MM-DD 00:00:00"),
        endDate: newEndDate.format("YYYY-MM-DD 23:59:59"),
        startTime: newStartDate.format("HH:mm"),
        endTime: newEndDate.format("HH:mm"),
      };

      const response = await createNewBonus(newData, token);
      if (response && !response.error) {
        dispatch(arraySearch(null));
        dispatch(setMessageAlert("managementBonds.createdBonus"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
        dispatch(setFlagForm({ flagFormCreate: false }));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("managementBonds.errorCreteBonus"));
        dispatch(setTypeAlert("error"));
        dispatch(setLoadingState({ loading: false }));
      }
      return response;
    } catch (error) {
      console.log(error);
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const startGetNewBonusType = (params?: Partial<IBonusTypeQuery>) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    if (!user || !token) {
      throw new Error("NOT AVAILABLE ACTION");
    }
    const response = await getNewBonusType(
      token,
      params as Partial<IBonusTypeQuery>
    );
    await dispatch(setBonusType(response));
  };
};
