import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { IPlayerActivityData } from "../../../interfaces/playerActivity";
import { SessionLogoutReason } from "../../../enums/enums";
import { getCopyArrayOrObject } from "../../../utils/utils";
import { AppState } from "../../../redux/reducers/rootReducer";
import { headersPlayerSessionReport } from "../../../helpers/headersTables";

export const PlayerSessionDataTable = (totalData: IPlayerActivityData[]) => {
  const { t } = useTranslation();
  const { data } = useSelector((state: AppState) => state.search);

  const headersCvsStyle = headersPlayerSessionReport.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  // Obtener datos por vista
  const dataPlayerSession =
    data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalPlayerSession = totalData
    ? getCopyArrayOrObject(totalData)
    : [];
  //   DATA TOTAL
  const multiTotalDataPlayerSession = [
    {
      columns: headersCvsStyle,
      data: dataTotalPlayerSession.map((data: any) => {
        const getDescription = () => {
          let reason = "";
          data.log_description === SessionLogoutReason.USER_LOGOUT &&
            (reason = t("playerSessionReport.playerLogout"));
          data.log_description === SessionLogoutReason.PLATFORM_LOGOUT &&
            (reason = t("playerSessionReport.platformLogout"));
          data.log_description === SessionLogoutReason.EXCLUSION_LOGOUT &&
            (reason = t("playerSessionReport.exclusionLogout"));
          data.log_description ===
            SessionLogoutReason.CHANGE_LOGIN_CREDENTIALS &&
            (reason = t("playerSessionReport.changeCredentials"));
          data.log_description === SessionLogoutReason.TIMEOUT_EXCEEDED &&
            (reason = t("playerSessionReport.timeoutExceeded"));
          return reason;
        };

        let array;
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player?.id,
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: data.player?.firstName + " " + data.player?.lastName,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.createAt).format("DD/MM/YYYY HH:mm:ss"),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.lastAt).format("DD/MM/YYYY HH:mm:ss"),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.ip,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.balanceLogin ? "$ " + data.balanceLogin : "",
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.balanceLogout ? "$ " + data.balanceLogout : "",
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: "$ " + data.total_bets,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: "$ " + data.total_won,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: "$ " + data.total_deposits,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: "$ " + data.total_withdrawals,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: getDescription(),
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];
  const multiDataPlayerSession = [
    {
      columns: headersCvsStyle,
      data: dataPlayerSession.map((data: any) => {
        const getDescription = () => {
          let reason = "";
          data.log_description === SessionLogoutReason.USER_LOGOUT &&
            (reason = t("playerSessionReport.playerLogout"));
          data.log_description === SessionLogoutReason.PLATFORM_LOGOUT &&
            (reason = t("playerSessionReport.platformLogout"));
          data.log_description === SessionLogoutReason.EXCLUSION_LOGOUT &&
            (reason = t("playerSessionReport.exclusionLogout"));
          data.log_description ===
            SessionLogoutReason.CHANGE_LOGIN_CREDENTIALS &&
            (reason = t("playerSessionReport.changeCredentials"));
          data.log_description === SessionLogoutReason.TIMEOUT_EXCEEDED &&
            (reason = t("playerSessionReport.timeoutExceeded"));
          return reason;
        };

        let array;
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player?.id,
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: data.player?.firstName + " " + data.player?.lastName,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.createAt).format("DD/MM/YYYY HH:mm:ss"),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.lastAt).format("DD/MM/YYYY HH:mm:ss"),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.ip,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.balanceLogin ? "$ " + data.balanceLogin : "",
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.balanceLogout ? "$ " + data.balanceLogout : "",
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: "$ " + data.total_bets,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: "$ " + data.total_won,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: "$ " + data.total_deposits,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: "$ " + data.total_withdrawals,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: getDescription(),
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];
  const finalData = {
    dataPerView: dataPlayerSession,
    dataTotal: dataTotalPlayerSession,
    headersCSV: headersPlayerSessionReport,
    multiDataSet: multiDataPlayerSession,
    multiTotalDataSet: multiTotalDataPlayerSession,
  };
  return finalData;
};
