import React, { useState, useEffect, FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Switch,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
} from "@mui/material";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useForm } from "../../../hooks/useForm";
import {
  createNewExclusion,
  setPlayerRedux,
  startGetExclusionCategories,
} from "../../../redux/actions/managementPlayersActions";
import { IExclusion } from "../../../interfaces/exclusions";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import moment from "moment";
import { ExclusionCategory, ExclusionType, State } from "../../../enums/enums";
import { getExclusions } from "../../../services/managementPlayersService";
import { MyDialog } from "../custom/MyDialog";
import { createExclusionAudit } from "../../../services/exclusionsAudit";
import { socket } from "../../../helpers/socket";

const initialValues = {
  startDate: "",
  endDate: "",
  category: "",
  state: 1,
};
interface props {
  errorDate: boolean;
  setErrorDate: any;
  enableCreated?: boolean;
}

export const ExclusionForm: FC<props> = (props) => {
  const { errorDate, setErrorDate, enableCreated } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { token, user } = useSelector((state: AppState) => state.auth);
  const { player, exclusionCategories } = useSelector(
    (state: AppState) => state.managementPlayers
  );
  const [formValues, handleInputChange, reset] = useForm(initialValues);
  const [totalExclusion, setTotalExclusion] = useState(false);
  const [playerSelected, setPlayerSelected] = useState<number | string>("");
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");

  // const socket = io(process.env.REACT_APP_API_HTTP_NOTIFICATIONS as string);

  const handleSaveExclusion = async () => {
    setErrorDate(false);
    if (formValues.startDate > formValues.endDate && !totalExclusion) {
      setErrorDate(true);
    } else {
      if (formValues.category === ExclusionCategory.LOGOUT) {
        setMessage("exclusions.sureLogoutPlayer");
      } else {
        setMessage(
          totalExclusion
            ? "exclusions.sureCompletelyExcludePlayer"
            : "exclusions.sureToExclude"
        );
      }
      setOpenDialog(true);
    }
  };

  useEffect(() => {
    if (totalExclusion) {
      dispatch(setMessageAlert("exclusions.warningMessageExclusion"));
      dispatch(setTypeAlert("warning"));
      dispatch(setOpenAlert());

      handleInputChange([
        {
          target: {
            name: "category",
            value: "",
          },
        },
        {
          target: {
            name: "endDate",
            value: "",
          },
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalExclusion]);

  useEffect(() => {
    dispatch(startGetExclusionCategories());

    return () => {
      dispatch(setPlayerRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (player && player.data.length > 0) {
      player.data[0].id && setPlayerSelected(player.data[0].id);
    } else {
      reset();
      setTotalExclusion(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  const verifyExclusionExists = async (exclusion: IExclusion) => {
    const params: any = {
      playerId: exclusion.player,
      type: 1,
      category: exclusion.category,
      totalExclusion,
      state: State.ACTIVE,
    };
    if (exclusion.totalExclusion) {
      params.totalDate = exclusion.startDate + "T00:00:00.000Z";
    } else {
      params.startDate = exclusion.startDate + "T00:00:00.000Z";
      params.endDate = exclusion.endDate + "T23:59:59.000Z";
    }

    const exclusions = token && (await getExclusions(token, params));

    return exclusions.data && exclusions.data.length > 0;
  };

  const handleCreate = async () => {
    setLoading(true);

    if (formValues.category === ExclusionCategory.LOGOUT) {
      if (token && user) {
        const event = t("exclusions.auditMessageSesion");

        await createExclusionAudit(token, {
          event: event,
          createdBy: user.id,
          playerAffected: parseInt(playerSelected as string),
        });

        dispatch(setMessageAlert("exclusions.successLogoutPlayer"));
        dispatch(setTypeAlert("success"));
        socket.emit("Admin/ClosePlayerSession", {
          id: playerSelected,
          inactivate: false,
        });
        dispatch(setOpenAlert());
        reset();
      } else {
        throw new Error("Invalid token");
      }
    } else {
      let newExclusion: IExclusion = {
        ...formValues,
        endDate: totalExclusion ? null : formValues.endDate,
        category: totalExclusion ? 1 : formValues.category,
        totalExclusion,
        player: playerSelected,
      };
      // Validar si existe exclusión
      const exclusionExists = await verifyExclusionExists(newExclusion);
      if (exclusionExists) {
        dispatch(
          setMessageAlert(
            totalExclusion
              ? "exclusions.totalExclusionAlreadyExists"
              : "exclusions.exclusionExistsByCategory"
          )
        );
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
        reset();
      } else {
        if (token && user) {
          const event = t("exclusions.auditMessageCategoryExclusion", {
            category: t(
              `exclusionsCategory.${ExclusionCategory[formValues.category]}`
            ),
          });
          const eventTotal = t("exclusions.auditMessageTotalExclusion");

          await createExclusionAudit(token, {
            event: totalExclusion ? eventTotal : event,
            createdBy: user.id,
            playerAffected: parseInt(playerSelected as string),
          });
          await dispatch(createNewExclusion(token, newExclusion, false, reset));
        } else {
          throw new Error("Invalid token");
        }
      }
    }
    setLoading(false);
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={2.2}>
        <FormControl sx={{ width: "100%" }} size="small">
          <InputLabel id="game-label">
            {t("exclusions.exclusionCategory")}
          </InputLabel>
          <Select
            labelId="category-label"
            name="category"
            size="small"
            fullWidth
            onChange={(e: any) => {
              handleInputChange(e);
              setTotalExclusion(false);
            }}
            value={formValues.category}
            input={<OutlinedInput label={t("exclusions.exclusionCategory")} />}
            disabled={!player || totalExclusion}
          >
            {exclusionCategories?.data
              ?.filter(
                (cat) =>
                  cat.type.id === ExclusionType.CATEGORY &&
                  cat.id !== ExclusionCategory.SELF_EXCLUSION
              )
              .map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name === "Todas"
                    ? "Seleccionar todas"
                    : category.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <TextField
          name="startDate"
          variant="outlined"
          type="date"
          size="small"
          fullWidth
          autoComplete="off"
          label={t("exclusions.startDateExclusion")}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleInputChange}
          value={formValues.startDate}
          disabled={!player || formValues.category === ExclusionCategory.LOGOUT}
          inputProps={{
            min: moment().format("YYYY-MM-DD"),
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          name="endDate"
          variant="outlined"
          type="date"
          size="small"
          fullWidth
          autoComplete="off"
          label={t("exclusions.endDateExclusion")}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {
            handleInputChange(event);
            setErrorDate(false);
          }}
          value={formValues.endDate}
          disabled={
            !player ||
            totalExclusion ||
            formValues.category === ExclusionCategory.LOGOUT
          }
          error={errorDate}
          helperText={errorDate && t("alerts.errorDate")}
        />
      </Grid>

      <Grid
        item
        xs={2.3}
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
      >
        <label
          className="label-text"
          style={{
            color: "#333",
            fontWeight: "bold",
            fontSize: 12,
            marginTop: 5,
          }}
        >
          {t("exclusions.totalExclusion")}
        </label>
        <Box display="flex" alignItems="center">
          <Switch
            name="totalExclusion"
            checked={totalExclusion}
            onChange={(e) => setTotalExclusion((prev) => !prev)}
            disabled={
              !player || formValues.category === ExclusionCategory.LOGOUT
            }
          />
        </Box>
      </Grid>
      {enableCreated && (
        <Grid item xs={1.5} display="flex">
          <Button
            fullWidth
            type="button"
            style={{
              border: "none",
              outline: "none",
              backgroundColor: "#02D0E7",
              height: "40px",
              marginBottom: "2px",
            }}
            variant="contained"
            className="ripple"
            onClick={handleSaveExclusion}
            disabled={
              loading ||
              !player ||
              (totalExclusion &&
                formValues.startDate === "" &&
                formValues.category !== ExclusionCategory.LOGOUT) ||
              (!totalExclusion &&
                (formValues.startDate === "" ||
                  formValues.endDate === "" ||
                  formValues.category === "") &&
                formValues.category !== ExclusionCategory.LOGOUT)
            }
          >
            {loading ? (
              <CircularProgress size={"1.6rem"} color="inherit" />
            ) : (
              <>{t("button.save")}</>
            )}
          </Button>
        </Grid>
      )}
      <MyDialog
        id="confirmationDialog"
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message={t(message)}
        handleAccept={handleCreate}
        handleClose={handleCloseDialog}
        isLoading={loading}
      />
    </Grid>
  );
};
