import { NumericFormat, NumericFormatProps } from "react-number-format";
import * as React from "react";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  decimal?: boolean;
}

export const NumericFormatCustom = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, decimal, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={","}
      decimalSeparator={"."}
      valueIsNumericString
      decimalScale={decimal ? 2 : 0}
      fixedDecimalScale={decimal}
    />
  );
});
