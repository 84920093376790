import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { Grid, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IPlayer } from "../../../../interfaces/exclusions";

import MyCustomTable, {
  IColumns,
} from "../../../../components/Custom/Table/MyCustomTable";
import { RowStatePlayer } from "./RowStatePlayer";
import {
  IQueryParams,
  getObservationsPlayerState,
} from "../../../../services/observationsPlayer";
import { MyCustomDialog } from "../../../../components/Custom/MyCustomDialog";
import { setFlagFormModal } from "../../../../redux/actions/helperActions";
import { ObservationForm } from "./ObservationForm";
import { setObservationsRedux } from "../../../../redux/actions/managementPlayersActions";
export interface IObservation {
  id: number;
  action: number;
  observation: string;
  createAt: string;
  updateAt: string;
  player: IPlayer;
  createBy: {
    id: number;
    nameComplete: string;
  };
}
export const StatePlayer = () => {
  const { token } = useSelector((state: AppState) => state.auth);
  const { player, observations } = useSelector(
    (state: AppState) => state.managementPlayers
  );
  const { rowsPerPage, flagFormModal } = useSelector(
    (state: AppState) => state.helper
  );

  const [stateUser, setStateUser] = useState(player?.data[0].stateUser || 1);

  useEffect(() => {
    if (player?.data[0]?.stateUser !== undefined) {
      setStateUser(player.data[0].stateUser);
    }
  }, [player]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const columns: IColumns[] = [
    {
      align: "left",
      label: t("exclusions.action"),
    },
    {
      align: "left",
      label: t("withdrawals.reason"),
    },
    {
      align: "left",
      label: t("table.createdBy"),
    },
    {
      align: "left",
      label: t("table.creationDate"),
    },
  ];

  const handleShowObservationForm = () => {
    dispatch(
      setFlagFormModal({
        flagFormModal: true,
      })
    );
  };

  const handleStateChange = (event: any) => {
    setStateUser(event.target.value);
    handleShowObservationForm();
  };

  const handleCloseObservationForm = () => {
    dispatch(
      setFlagFormModal({
        flagFormModal: false,
      })
    );
  };
  const getObservations = async (params: Partial<IQueryParams>) => {
    if (!token) {
      throw new Error("INVALID ACTION");
    }
    const rs = await getObservationsPlayerState(token, {
      player: player?.data[0].id as any,
      ...params,
    });
    dispatch(setObservationsRedux(rs));
  };
  useEffect(() => {
    if (player) {
      getObservations({
        page: 1,
        perPage: rowsPerPage,
      });
    }

    return () => {
      dispatch(setObservationsRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);
  const handleChangePage = async (page: number, rows?: number) => {
    const params = {
      page: page,
      perPage: rows || rowsPerPage,
    };
    await getObservations(params);
  };

  const stateOptions = [
    { label: t("exclusions.stateOptions.active"), value: 1 },
    { label: t("exclusions.stateOptions.inactive"), value: 2 },
    { label: t("exclusions.stateOptions.retained"), value: 3 },
    { label: t("exclusions.stateOptions.pendingValidation"), value: 4 },
    { label: t("exclusions.stateOptions.closed"), value: 5 },
    { label: t("exclusions.stateOptions.selfExcluded"), value: 6 },
  ];

  return (
    <>
      {player && (
        <div className="container-fluid white-container">
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Select
                value={stateUser}
                onChange={handleStateChange}
                displayEmpty
                variant="outlined"
                fullWidth
              >
                {stateOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              {observations && (
                <MyCustomTable
                  columns={columns}
                  data={observations.data}
                  lastPage={observations.last_page}
                  totalData={observations.total}
                  renderBody={(item) => <RowStatePlayer row={item} />}
                  onPageChange={handleChangePage}
                />
              )}
            </Grid>

            <MyCustomDialog
              open={!!flagFormModal}
              content={<ObservationForm stateUser={stateUser} />}
              onClose={handleCloseObservationForm}
              title={t("user.observation")}
              width={"sm"}
              icon={
                <i
                  className="fa-solid fa-circle-info"
                  style={{ color: "#02D0E7", marginRight: 9, fontSize: 20 }}
                ></i>
              }
            />
          </Grid>
        </div>
      )}
    </>
  );
};
