import { Form, Formik } from "formik";
import React, { FC, KeyboardEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";

import { MyTextField } from "../../custom/MyTextField";
import { regLettersNumbers } from "../../../../utils/utils";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { AssociateDetail } from "./AssociateDetail";
import { getAssociatesFilterService } from "../../../../services/associates";
import { BalanceType, Roles_Enum } from "../../../../enums/enums";
import { setActiveAssociate } from "../../../../redux/actions/associatesActions";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import { arraySearch } from "../../../../redux/actions/searchActions";

interface Props {
  expand: boolean;
  setExpand: React.Dispatch<React.SetStateAction<boolean>>;
  balance: BalanceType;
  setBalance: React.Dispatch<any>;
  setResetInfo: React.Dispatch<React.SetStateAction<boolean>>;
  AutoReset: () => null;
  setResetGenerate: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AssociateInfo: FC<Props> = ({
  expand,
  setExpand,
  balance,
  setBalance,
  setResetInfo,
  AutoReset,
  setResetGenerate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token, user } = useSelector((state: AppState) => state.auth);
  const { activeAssociate } = useSelector(
    (state: AppState) => state.associates
  );
  const [loading, setLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;

  const initialValues = {
    balance: "",
    documentNumber: "",
  };

  const validationSchema = yup.object({
    balance: yup.number(),
    documentNumber: yup.string(),
  });

  const handleSearchAssociate = async (
    balance: number,
    documentNumber: string
  ) => {
    const operatorId = !isSuper && user ? user.operator.id : null;

    const resp =
      token &&
      (await getAssociatesFilterService(token, operatorId, {
        documentNumber,
        exact: true,
      }));

    setLoading(false);
    if (resp && resp.data) {
      if (resp.data.length > 0) {
        dispatch(setActiveAssociate(resp.data[0]));
        setBalance(balance);
      } else {
        dispatch(setOpenAlert());

        dispatch(setMessageAlert("irrigation.associateNotRegistered"));
        dispatch(setTypeAlert("error"));
      }
    } else {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("irrigation.errorSetAssociate"));
      dispatch(setTypeAlert("error"));
    }
  };

  return (
    <Box className="filter" py={1} sx={{ minHeight: expand ? "200px" : "" }}>
      <Box display="flex" justifyContent={"space-between"}>
        <Box display="flex" alignItems="center">
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <Typography
            color="text.primary"
            style={{ fontSize: "16px", fontWeight: 500 }}
          >
            {t("irrigation.associateInformation")}
          </Typography>
        </Box>
        <IconButton
          size="small"
          style={{ border: "none", outline: "none" }}
          onClick={() => setExpand((prev) => !prev)}
        >
          {expand ? (
            <i className="fa fa-angle-up" />
          ) : (
            <i className="fa fa-angle-down" />
          )}
        </IconButton>
      </Box>
      {expand && (
        <Box width={1} borderTop={1} borderColor={"#02D0E7"} mt={1}>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={true}
            onSubmit={async (data, { setSubmitting, resetForm }) => {
              dispatch(setActiveAssociate(null));
              setResetGenerate(true);
              setSubmitting(true);
              if (isSearch) {
                setLoading(true);
                await handleSearchAssociate(
                  parseInt(data.balance),
                  data.documentNumber
                );
              } else {
                resetForm();
                setResetInfo(true);
              }
            }}
          >
            {({ handleChange, values }) => (
              <Form>
                <Grid container spacing={1} my={2}>
                  <Grid item md={5} xs={12}>
                    <MyTextField
                      name="balance"
                      select
                      label={t("transactions.balanceType") + "*"}
                      onChange={(e: any) => {
                        setIsSearch(false);
                        handleChange(e);
                        dispatch( setActiveAssociate(null) )
                        dispatch( arraySearch(null) )
                      }}
                    >
                      <MenuItem value={BalanceType.REFILL}>
                        {t("associates.refillBalance")}
                      </MenuItem>
                      <MenuItem value={BalanceType.BETTING}>
                        {t("irrigation.bettingBalance")}
                      </MenuItem>
                    </MyTextField>
                  </Grid>

                  <Grid item md={5} xs={12}>
                    <MyTextField
                      name="documentNumber"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      label={`${t("associates.documentNo")}*`}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(e: any) => {
                        setIsSearch(false);
                        handleChange(e);
                      }}
                      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                        !regLettersNumbers(e.key) && e.preventDefault()
                      }
                    />
                  </Grid>

                  <Grid item md={2} xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      className="primary-btn ripple"
                      style={{
                        border: "none",
                        outline: "none",
                        width: "100%",
                        color: "white",
                        opacity:
                          values.balance === "" ||
                          values.documentNumber === "" ||
                          loading
                            ? "0.5"
                            : "",
                      }}
                      disabled={
                        values.balance === "" ||
                        values.documentNumber === "" ||
                        loading
                      }
                      onClick={() => setIsSearch((prev) => !prev)}
                    >
                      {loading ? (
                        <CircularProgress size={"1.6rem"} color="inherit" />
                      ) : (
                        <>
                          {isSearch
                            ? t("filters.clean").toUpperCase()
                            : t("search.title").toUpperCase()}
                        </>
                      )}
                    </Button>
                  </Grid>
                </Grid>
                <AutoReset />
              </Form>
            )}
          </Formik>

          {!!activeAssociate && (
            <AssociateDetail associate={activeAssociate} balance={balance} />
          )}
        </Box>
      )}
    </Box>
  );
};
