import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { FC, useEffect, useState } from "react";
interface props {
  isCash?: boolean;
}
export const WithdrawalDetail: FC<props> = ({ isCash }) => {
  const { t } = useTranslation();
  const { activeWithdrawal } = useSelector(
    (state: AppState) => state.transactions
  );
  const [cols, setCols] = useState([
    t("table.playerId"),
    t("table.email"),
    t("account.userName"),
    t("managementBonds.documentPlayer"),
    t("associates.bank"),
    t("associates.accountNumber"),
    t("withdrawals.valueToWithdraw"),
  ]);
  useEffect(() => {
    let newCols = [];

    if (isCash) {
      newCols = cols.filter(
        (col) =>
          col !== t("associates.bank") && col !== t("associates.accountNumber")
      );
    } else {
      newCols = cols.filter(
        (col) => col !== t("managementBonds.documentPlayer")
      );
    }
    setCols(newCols);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      {activeWithdrawal && (
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <i
            className="fa-solid fa-circle-user"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 35 }}
          ></i>
          <strong>{activeWithdrawal.player.username || ""}</strong>
        </Grid>
      )}

      <Grid item xs={12} bgcolor={"#F4F4F4"} mt={2} p={1}>
        <Grid container spacing={1}>
          {cols.map((col, index) => (
            <Grid
              item
              xs={isCash ? 2.4 : index === 0 ? 1.5 : index === 3 ? 2.5 : 2}
              key={index}
            >
              <Typography variant="caption" fontWeight={"bold"} color="#333">
                {col.toUpperCase()}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} bgcolor={"#02D0E71A"} mt={1} p={1}>
        <Grid container spacing={1}>
          <Grid item xs={isCash ? 2.4 : 1.5} className="no-overflow">
            <Typography variant="body2" color="#333">
              {activeWithdrawal?.player.id}
            </Typography>
          </Grid>
          <Grid item xs={isCash ? 2.4 : 2} className="no-overflow">
            <Typography variant="body2" color="#333">
              {activeWithdrawal?.player.email}
            </Typography>
          </Grid>
          <Grid item xs={isCash ? 2.4 : 2} className="no-overflow">
            <Typography variant="body2" color="#333">
              {activeWithdrawal?.player.username}
            </Typography>
          </Grid>
          {isCash && (
            <Grid item xs={isCash ? 2.4 : 2} className="no-overflow">
              <Typography variant="body2" color="#333">
                {activeWithdrawal?.player.numberDocument}
              </Typography>
            </Grid>
          )}
          {!isCash && (
            <>
              <Grid item xs={2.5} className="no-overflow">
                <Typography variant="body2" color="#333">
                  {activeWithdrawal?.playerBankAccounts?.bank?.name}
                </Typography>
              </Grid>
              <Grid item xs={2} className="no-overflow">
                <Typography variant="body2" color="#333">
                  {activeWithdrawal?.playerBankAccounts?.accountNumber}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={isCash ? 2.4 : 1} className="no-overflow">
            <Typography variant="body2" color="#333">
              $ {activeWithdrawal?.withdrawalValue}
            </Typography>
          </Grid>
          {!isCash && (
            <Grid item xs={isCash ? 2.4 : 1} className="no-overflow">
              <Typography variant="body2" color="#333">
                {activeWithdrawal?.playerBankAccounts?.verified ? (
                  <i className="fa fa-circle-check fa-lg mr-2 iconApprove" />
                ) : (
                  <i className="fa fa-circle-xmark fa-lg iconDelete" />
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
