import { useState, useEffect, FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Box, Grid } from "@mui/material";
import { Enum_FieldType, IFilterField } from "../../../interfaces/components";
import Filter from "../../../components/Filter/Filter";
import { IWithdrawalParams } from "../../../interfaces/withdrawals";
import { TabsWithdrawals } from "../../components/transactions/withdrawals/TabsWithdrawals";
import { setChangeData } from "../../../redux/actions/searchActions";
import { ProvidersBonus, StateTransaction, TransferConcept } from "../../../enums/enums";
import {
  asyncLoadingFunction,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import {
  setWithdrawalsRedux,
  startGetWithdrawals,
} from "../../../redux/actions/transactionsActions";
import moment from "moment";

interface Props {
  standard: boolean;
}

export const WithdrawalRequest: FC<Props> = ({ standard }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dataFilter, setDataFilter] = useState<any>({});

  const [component, setComponent] = useState(StateTransaction.Pending);

  const [resetPage, setResetPage] = useState(false);

  const filterFields: IFilterField[] = [
    {
      type: Enum_FieldType.NUMBER,
      label: t("withdrawals.withdrawalNumber"),
    },
    {
      type: Enum_FieldType.TEXT,
      label: `${t("user.documentNumber")}`,
    },
    {
      type: Enum_FieldType.TEXT,
      label: t("user.email"),
    },
    {
      type: Enum_FieldType.NUMBER,
      label: t("transactions.value"),
    },
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
  ];

  const handleFilter = async (values: any) => {
    const params: Partial<IWithdrawalParams> = {
      id: values[t("withdrawals.withdrawalNumber")],
      numberDocument: values[t("user.documentNumber")],
      email: values[t("user.email")],
      value: values[t("transactions.value")],
      startDate: values[t("filters.startDate")],
      endDate: values[t("filters.endDate")],
      state: component,
      providerId: ProvidersBonus.NEAT,
      paymentMethod: TransferConcept.BANK_TRANSFER,
      page: 1,
    };

    const { endDate, startDate } = params;

    if (endDate?.trim() && !startDate?.trim()) {
      dispatch(setMessageAlert("alerts.selectStartDate"));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }

    const errorDate = moment(startDate).isAfter(endDate);
    if (errorDate) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.errorDate"));
      dispatch(setTypeAlert("error"));
    } else {
      dispatch(
        asyncLoadingFunction(
          async () => await dispatch(startGetWithdrawals(params, true))
        )
      );
      setDataFilter(params);
    }
  };

  const onCleanFilter = async () => {
    dispatch(setWithdrawalsRedux(null));
    setDataFilter({});
  };

  useEffect(() => {
    return () => {
      dispatch(setChangeData(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={standard ? "w-100 mr-4" : "content"}>
      <Box sx={{ ml: 3, mt: 2 }} width={1}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box className="filter" p={2} pb={0} sx={{ minHeight: "110px" }}>
              <Filter
                fields={filterFields}
                setDataFilter={setDataFilter}
                handleFilter={handleFilter}
                onClean={onCleanFilter}
              />
            </Box>

            <Box mt={1}>
              <TabsWithdrawals
                setComponent={setComponent}
                dataFilter={dataFilter}
                setResetPage={setResetPage}
                resetPage={resetPage}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
