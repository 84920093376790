import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncLoadingFunction,
  setCloseAlert,
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { Enum_FieldType, IFilterField } from "../../../interfaces/components";
import Filter from "../../../components/Filter/Filter";
import { CustomAlert } from "../../../components/Alerts/CustomAlert";
import { AppState } from "../../../redux/reducers/rootReducer";
import MyCustomTable, {
  IColumns,
} from "../../../components/Custom/Table/MyCustomTable";
import { MyAlert } from "../../components/custom/MyAlert";
import { useLocation } from "react-router-dom";
import { FormPromoters } from "../../components/managementPromoters/FormPromoters";
import { RowPromoters } from "../../components/managementPromoters/RowPromoters";
import {
  setPromoters,
  startGetPromoters,
} from "../../../redux/actions/managementPromotersActions";
import { PromotersPdf } from "../../../components/Pdf/promoters/PromotersPdf";
import { getPromoters } from "../../../services/promoters";
import { PromotersDataTable } from "../../../hooks/useDataTable/promoters";

export const ManagementPromoters = () => {
  const [dataFilter, setDataFilter] = useState<any>({});
  const { promoters } = useSelector(
    (state: AppState) => state.managementPromoters
  );

  const { flagFormCreate, openAlert, messageAlert, typeAlert, rowsPerPage } =
    useSelector((state: AppState) => state.helper);
  const { token, user } = useSelector((state: AppState) => state.auth);
  const [isFiltering, setIsFiltering] = useState(false);
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { pathname } = useLocation();

  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const FilterFileds: IFilterField[] = [
    {
      label: t("managementPromoters.promoterName"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("managementPromoters.promoterEmail"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("managementPromoters.promoterPhone"),
      type: Enum_FieldType.NUMBER,
    },
  ];
  const columns: IColumns[] = [
    {
      align: "center",
      label: t("managementPromoters.idPromoter"),
    },
    {
      align: "center",
      label: t("managementPromoters.promoterName"),
    },
    {
      align: "center",
      label: t("managementPromoters.promoterPhone"),
    },
    {
      align: "center",
      label: t("managementPromoters.promoterEmail"),
    },
    {
      align: "center",
      label: t("table.state"),
    },
    {
      align: "center",
      label: t("table.actions"),
    },
  ];
  useEffect(() => {
    if (promoters && promoters.data.length === 0) {
      dispatch(
        setMessageAlert(isFiltering ? "alerts.noData" : "alerts.noRecords")
      );
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoters]);

  const handleOpenForm = () => {
    dispatch(setFlagForm({ flagFormCreate: true }));
  };
  const handleFilter = async (values: any) => {
    const params = {
      name: values[t("managementPromoters.promoterName")],
      email: values[t("managementPromoters.promoterEmail")],
      phone: values[t("managementPromoters.promoterPhone")],
    };

    await dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startGetPromoters({
            ...params,
            page: 1,
          })
        )
      )
    );
    setDataFilter({ ...params });
    setIsFiltering(true);
  };
  const onCleanFilter = async () => {
    dispatch(setPromoters(null));
    setDataFilter({});
    setIsFiltering(false);
  };
  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(
        async () =>
          await dispatch(
            startGetPromoters({
              ...dataFilter,
              page,
              perPage: rows || rowsPerPage,
            })
          )
      )
    );
  };
  const onCloseAlert = () => {
    dispatch(setCloseAlert());
  };
  const getTotalPromoters = async () => {
    if (user && token) {
      const params = {
        operator: user.operator.id,
      };
      return await getPromoters(params, token);
    }
  };

  return (
    <div className="content w-100 mb-5">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyBreadCrumbs
            titles={[t("sidebar.settings"), t("managementPromoters.title")]}
          />
        </Grid>

        <div className="white-container w-100">
          {flagFormCreate ? (
            <FormPromoters />
          ) : (
            <>
              {enableCreated && (
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    mb={2}
                  >
                    <Box display="flex" alignItems="center">
                      <p
                        className="txt-primary-md"
                        style={{
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          alignSelf: "center",
                        }}
                      >
                        {t("managementPromoters.createPromoter")}
                      </p>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={handleOpenForm}
                        id="openFormBtn"
                        style={{ border: "none", outline: "none" }}
                      >
                        <span className="btn-add">
                          <i
                            className="fa-solid fa-plus"
                            style={{ fontSize: 30 }}
                          ></i>
                        </span>
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Filter
                  fields={FilterFileds}
                  handleFilter={handleFilter}
                  onClean={onCleanFilter}
                />
              </Grid>

              <Grid item xs={12}>
                {(!promoters || promoters.total === 0) && (
                  <CustomAlert
                    htmlText={t("managementPromoters.filterText")}
                    type="info"
                  />
                )}
                {promoters && promoters.total > 0 && (
                  <MyCustomTable
                    columns={columns}
                    data={promoters.data}
                    lastPage={promoters.last_page}
                    totalData={promoters.total}
                    onPageChange={handlePageChange}
                    renderBody={(item) => <RowPromoters row={item} />}
                    exportData
                    getAllDataToExport={getTotalPromoters}
                    hookBuildTable={PromotersDataTable}
                    pdfComponent={PromotersPdf}
                    titleCSV={
                      "promoters" + user && user?.operator
                        ? user?.operator.name
                        : "" + new Date().toISOString()
                    }
                  />
                )}
              </Grid>
            </>
          )}
        </div>
      </Grid>
      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />
    </div>
  );
};
