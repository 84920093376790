import React, { FC } from "react";
import { Text, View } from "@react-pdf/renderer";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import { headersDealer } from "../../../helpers/headersTables";
import { useTranslation } from "react-i18next";
import { State } from "../../../enums/enums";
interface props {
  page: any;
}

export const DealersPdf: FC<props> = ({ page }) => {
  const { t } = useTranslation();
  const columns = headersDealer;
  const getStateName = (state: State) => {
    switch (state) {
      case State.ACTIVE:
        return t("state.active");

      case State.INACTIVE:
        return t("state.inactive");

      default:
        return t("state.active");
    }
  };
  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column) => (
          <View key={column.key} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>

      <View style={{ flex: 1 }}>
        {page.map((data: any) => {
          return (
            <View key={data.id} style={stylesPdf.row}>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.id}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.name}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.phone}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.email}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {data.comissionPercentage.toString() + "%"}
                </Text>
              </View>

              <View
                style={[
                  stylesPdf.col,
                  stylesPdf.pCol,
                  {
                    backgroundColor:
                      (data.state as any) ===State.ACTIVE
                        ? "#c6efce"
                        : "#ffc7ce",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                ]}
              >
                <Text
                  style={[
                    stylesPdf.textContent,
                    {
                      color:
                        (data.state as any) ===State.ACTIVE
                          ? "#0d6a44"
                          : "#ad120b",
                    },
                  ]}
                >
                  {getStateName(data.state)}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </>
  );
};
