import React, { FC, useState, useEffect } from "react";

import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";

import { MyTextField } from "../custom/MyTextField";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { regNumbers } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";

import { getPariplayGames } from "../../../services/casino";
import moment from "moment";
import { CasinoBonusType } from "../../../enums/enums";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  errorLabel: {
    marginLeft: theme.spacing(0.75),
    color: "#d32f2f",
  },
  root: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  inputAdorned: {
    marginRight: "30px",
  },
}));
const styles = {
  "& input.Mui-disabled ": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
  "& .MuiSelect-select.Mui-disabled": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
};

// Constants for menu props
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IGameList {
  name: string;
  gameCode: string;
  freeRoundBonus: boolean;
  multiplier: number;
  stakes: number[];
}
interface props {
  formikRef: any;
  currency: string;
  country: string;
}
export const PariplayBonusForm: FC<props> = ({
  formikRef,
  currency,
  country,
}) => {
  const { token } = useSelector((state: AppState) => state.auth);
  // const { activeBonus } = useSelector((state: AppState) => state.bonus);
  const [games, setGames] = useState<IGameList[]>([]);
  const [gameCode, setGameCode] = useState<string | null>();
  const [gameSelected, setGameSelected] = useState<IGameList | null>(null);
  const [selectedStake, setSelectedStake] = useState<number | null>(null);

  const classes = useStyles();
  const { values, handleChange, setFieldValue } = formikRef;

  const today = new Date();
  const minDate = today.toISOString().split("T")[0]; // Formato "yyyy-mm-dd"

  const maxDate = new Date(today);
  maxDate.setFullYear(maxDate.getFullYear() + 1);
  const maxDateFormatted = maxDate.toISOString().split("T")[0]; // Formato "yyyy-mm-dd"

  const actualDate = moment(today).format("yyyy-MM-DD");
  values.startDate = actualDate;

  const { t } = useTranslation();

  // SELECT BONUS TYPE
  const casinoBonusType = [
    {
      label: t("casinoBonusType.registration"),
      value: CasinoBonusType.REGISTRATION,
    },
    { label: t("casinoBonusType.deposit"), value: CasinoBonusType.DEPOSIT },
    { label: t("casinoBonusType.custom"), value: CasinoBonusType.CUSTOM },
  ];

  useEffect(() => {
    const fetchGames = async () => {
      try {
        if (!token) {
          throw new Error("invalid action");
        }
        const rs: any = await getPariplayGames(token);
        if (rs) {
          const gameList: any[] = rs.map(
            ({ gameCode, name, freeRoundBonus, multiplier, stakes }: any) => ({
              gameCode,
              name,
              freeRoundBonus,
              multiplier,
              stakes,
            })
          );

          const finalGameList = gameList.filter((game) => game.freeRoundBonus);

          setGames(finalGameList);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gameSelected) {
      const roundValue = (selectedStake as number) * gameSelected.multiplier;
      const totalBonusValue =
        roundValue * parseInt(values.pragmaticNumberRounds);
      setFieldValue(
        "value",
        roundValue ? Math.round(roundValue * 100) / 100 : ""
      );
      setFieldValue(
        "totalBondsValue",
        totalBonusValue ? Math.round(totalBonusValue * 100) / 100 : ""
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStake, values.pragmaticNumberRounds]);

  const selectedGame = (e: any) => {
    setSelectedStake(null);
    setFieldValue("value", "");
    setFieldValue("totalBondsValue", "");
    setFieldValue("pragmaticNumberRounds", "");
    const gameCode = e.target.value;
    const coinValue = selectedStake;
    const matchingGame = games.find((game) => game.gameCode === gameCode);
    const gameName = matchingGame ? matchingGame.name : "";
    setGameSelected(matchingGame ? matchingGame : null);
    const endDate = moment(values.endDate)
      .endOf("day")
      .format("YYYY-MM-DDTHH:mm:ss[Z]");

    setGameCode(e.target.value);
    setFieldValue("pragmaticBonus", [
      {
        gameName: gameName,
        gameCode: e.target.value,
        currencyCode: currency,
        countryCode: country,
        endDate: endDate,
        coinValue: coinValue,
      },
    ]);
  };

  const handleStakeChange = (event: any) => {
    setSelectedStake(event.target.value);
  };

  useEffect(() => {
    const gameName = games.find((game) => game.gameCode === gameCode)?.name;
    const coinValue = selectedStake;
    const endDate = moment(values.endDate).utc()
      .endOf("day")
      .format("YYYY-MM-DDTHH:mm:ss[Z]");

    setFieldValue("pragmaticBonus", [
      {
        gameName: gameName,
        gameCode: gameCode,
        currencyCode: currency,
        countryCode: country,
        endDate: endDate,
        coinValue: coinValue,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.endDate, selectedStake]);

  return (
    <>
      <Grid item xs={4}>
        <MyTextField
          id="stateTextField"
          name="casinoBonusType"
          variant="outlined"
          size="small"
          fullWidth
          value={values.state}
          onChange={(e: any) =>
            setFieldValue("casinoBonusType", e.target.value)
          }
          role={"combobox"}
          select
          label={`${t("managementBonds.bondType")}*`}
          inputProps={{
            id: "stateInput",
          }}
        >
          {casinoBonusType.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              sx={{
                "&.Mui-focused": {
                  backgroundColor: "red",
                },
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </MyTextField>
      </Grid>
      <Grid item xs={4}>
        <MyTextField
          name="amountBonus"
          variant="outlined"
          size="small"
          fullWidth
          onKeyPress={(e: any) => !regNumbers(e.key) && e.preventDefault()}
          value={values.amountBonus}
          onChange={handleChange}
          label={`${t("managementBonds.amountBonds")}*`}
          inputProps={{
            "data-testid": "amountBonus",
            maxLength: 10,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <MyTextField
          variant="outlined"
          size="small"
          type={"date"}
          name={"endDate"}
          label={`${t("table.expirationDate")}*`}
          InputProps={{
            classes: {
              input: classes.root,
              adornedEnd: classes.inputAdorned,
            },
            inputProps: { min: minDate, max: maxDateFormatted },
          }}
          fullWidth
          value={values.endDate}
          inputProps={{
            "data-testid": "endDate",
          }}
          onChange={handleChange}
          sx={styles}
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      {games.length > 0 ? (
        <>
          <Grid item xs={4}>
            <FormControl className={classes.formControl} size="small">
              <InputLabel id="game-label">
                {`${t("pragmatic.Game")}*`}
              </InputLabel>
              <Select
                labelId="game-label"
                name="pragmaticBonus"
                size="small"
                fullWidth
                onChange={(e: any) => selectedGame(e)}
                value={!gameCode ? "" : gameCode}
                input={<OutlinedInput label={t("pragmatic.Game") + "*"} />}
                MenuProps={MenuProps}
              >
                {games.map((game, index) => (
                  <MenuItem key={index} value={game.gameCode}>
                    {game.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      ) : (
        <></>
      )}
      {gameSelected ? (
        <>
          <Grid item xs={4}>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={gameSelected.multiplier}
              label={`${t("pariplay.multiplier")}`}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl className={classes.formControl} size="small">
              <InputLabel id="stakes-label">{`${t(
                "pariplay.stakes"
              )}*`}</InputLabel>
              <Select
                labelId="stakes-label"
                size="small"
                fullWidth
                onChange={(e: any) => handleStakeChange(e)}
                value={!selectedStake ? "" : selectedStake}
                input={<OutlinedInput label={t("pariplay.stakes") + "*"} />}
                MenuProps={MenuProps}
              >
                {gameSelected.stakes.map((stake: any, index: any) => (
                  <MenuItem key={index} value={stake}>
                    {stake}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <MyTextField
              name="pragmaticNumberRounds"
              variant="outlined"
              size="small"
              fullWidth
              onKeyPress={(e: any) => !regNumbers(e.key) && e.preventDefault()}
              value={values.pragmaticNumberRounds || ""}
              onChange={handleChange}
              label={`${t("pragmatic.NumberRounds")}*`}
              inputProps={{
                maxLength: 10,
              }}
            />
          </Grid>
        </>
      ) : (
        <></>
      )}

      {values.pragmaticNumberRounds && selectedStake ? (
        <>
          <Grid item xs={4}>
            <MyTextField
              name="value"
              variant="outlined"
              size="small"
              fullWidth
              onKeyPress={(e: any) => {
                if (
                  !regNumbers(e.key) ||
                  (e.target.value.length === 0 && e.key === "0")
                ) {
                  return e.preventDefault();
                }
              }}
              inputProps={{
                "data-testid": "value",
                maxLength: 10,
              }}
              disabled
              value={values.value || ""}
              onChange={handleChange}
              label={`${t("pragmatic.RoundValue")}*`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <MyTextField
              name="totalBondsValue"
              variant="outlined"
              size="small"
              fullWidth
              onKeyPress={(e: any) => {
                if (
                  !regNumbers(e.key) ||
                  (e.target.value.length === 0 && e.key === "0")
                ) {
                  return e.preventDefault();
                }
              }}
              inputProps={{
                "data-testid": "totalBonusValue",
                maxLength: 10,
              }}
              value={values.totalBondsValue}
              onChange={handleChange}
              disabled
              label={`${t("managementBonds.bondValue")}*`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
