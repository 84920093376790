import moment from "moment";
import React, { FC } from "react";
import { Text, View } from "@react-pdf/renderer";

import { headersWithdrawalsCash } from "../../../helpers/headersTables";
import { IWithdrawalData } from "../../../interfaces/withdrawals";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import { WhiteListOperators } from "../../../enums/enums";

interface props {
  page: IWithdrawalData[];
}
export const WithdrawalCashPdfDocument: FC<props> = ({ page }) => {
  const columns = headersWithdrawalsCash;

  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column) => (
          <View key={column.key} style={[stylesPdf.pCol, stylesPdf.col]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>

      <View style={{ flex: 1 }}>
        {page.map((data) => {
          const percentage =
            data.player.operator?.name?.toUpperCase() ===
            WhiteListOperators.BETPPRO
              ? 0
              : 5.5;
          return (
            <View key={data.id} style={stylesPdf.row}>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>{data.id}</Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>{data.player.id}</Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {data.player.numberDocument}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>{data.player.email}</Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {parseFloat(data.taxValue) === 0
                    ? parseFloat(data.taxValue).toFixed(1)
                    : percentage.toFixed(1)}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {parseFloat(data.taxValue).toFixed(2)}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>{data.withdrawalValue}</Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>{data.paidValue}</Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {moment(data.createAt).format("DD/MM/YYYY HH:mm:ss")}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </>
  );
};
