import { Types } from "../types/types";

interface IAffiliationsActions {
  type: Types;
  payload?: any;
}

interface IAffiliationsState {
  affiliates: any;
  pointSales: any;
}

const initialState: IAffiliationsState = {
  affiliates: null,
  pointSales: null,
};
export const affiliationsReducer = (
  state = initialState,
  action: IAffiliationsActions
): IAffiliationsState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setAffiliates:
      return {
        ...state,
        affiliates: payload as any,
      };
    case Types.setPointsSale:
      return {
        ...state,
        pointSales: payload as any,
      };
    default:
      return state;
  }
};
