import React, { FC, useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import moment from "moment";
import { IBetData } from "../../../interfaces/bets";
import { betStatuses } from "../../../helpers/betStatus";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setFlagFormConfig } from "../../../redux/actions/helperActions";
import { setActiveTicket } from "../../../redux/actions/betsActions";

interface Props {
  row: IBetData;
}

export const RowDataTableBettingHistory: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  const [fees, setFees] = useState(1);
  const dispatch = useDispatch();
  let newFees: any;
  row.events.forEach((item: any) => {
    newFees =
      row.eventCount > 1
        ? row.betStakes[0].odds
        : item.markets[0]
        ? item.markets[0].odds * 1
        : 1;
  });

  useEffect(() => {
    row.events.map((item: any) =>
      setFees(
        row.eventCount > 1
          ? row.betStakes[0].odds
          : item.markets[0]
          ? item.markets[0].odds * fees
          : 1
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSeeDetail = (bet: IBetData) => {
    dispatch(setFlagFormConfig({ flagFormConfig: true }));
    dispatch(setActiveTicket(bet));
  };
  return (
    <>
      <TableCell align="center" className="word-break">
        {row.id}
      </TableCell>
      <TableCell align="center" className="word-break">
        {row.reference}
      </TableCell>
      <TableCell align="center" className="word-break">
        {moment(row.createAt).format("DD/MM/YYYY")}
      </TableCell>
      <TableCell align="center" className="word-break">
        {moment(row.createAt).format("HH:mm:ss A")}
      </TableCell>
      <TableCell align="center">
        {"$ "}
        {row.status === "S"
          ? row.transactions && row.transactions.length > 0
            ? parseFloat(row.transactions[0].amount).toFixed(2)
            : (parseFloat(row.amount) * fees).toFixed(2)
          : "0"}
      </TableCell>
      <TableCell align="center">
        {"$ " +
          ` ${
            row.status === "S" &&
            row.transactions &&
            row.transactions.length > 0
              ? (parseFloat(row.transactions[0].amount) / newFees).toFixed(2)
              : row.bonusAccountId
              ? (row.betStakes[0].betstake / 100).toFixed(2)
              : row.amount
          }`}
      </TableCell>
      <TableCell align="center" className="word-break">
        {betStatuses
          .find((status) => status.value === row.status)
          ?.description.toUpperCase()}
        {row.paid && ` - ${t("betState.paid").toUpperCase()}`}
      </TableCell>
      <TableCell align="center" className="word-break">
        <i
          className="fa fa-search-plus fa-lg mr-2 iconSeeMore"
          onClick={() => {
            handleSeeDetail(row);
            //setResendMessage(false);
          }}
        />
      </TableCell>
    </>
  );
};
