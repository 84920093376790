import React, { FC } from "react";
import TableCell from "@mui/material/TableCell";
import { IUserData } from "../../../redux/interfaces/users";
import { State } from "../../../enums/enums";
import { useTranslation } from "react-i18next";
import moment from "moment";
interface Props {
  row: IUserData;
}

export const RowDataTableUsers: FC<Props> = ({ row }) => {
  const { t } = useTranslation();

  return (
    <>
      <TableCell className="word-break">{row.id}</TableCell>
      <TableCell className="word-break">{row.nameComplete}</TableCell>
      <TableCell className="word-break">{row.email}</TableCell>
      <TableCell className="word-break">
        {moment(row.createAt).format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
      <TableCell className="word-break">{row.roles?.name}</TableCell>
      <TableCell className="word-break">
        {row.createdUser?.nameComplete}
      </TableCell>
      <TableCell align="center">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                State.ACTIVE === row.stateUser
                  ? "d-flex justify-content-center cellRowActive"
                  : State.INACTIVE === row.stateUser
                  ? "d-flex justify-content-center cellRowInactive"
                  : "d-flex justify-content-center cellRowRetired"
              }
            >
              <div className="d-flex col-sm-4 justify-content-center">
                {State.ACTIVE === row.stateUser && <>{t("state.active")}</>}
                {State.INACTIVE === row.stateUser && <>{t("state.inactive")}</>}
                {State.RETIRED === row.stateUser && <>{t("state.retired")}</>}
                {State.RESTRICTED === row.stateUser && (
                  <>{t("state.restricted")}</>
                )}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
    </>
  );
};
