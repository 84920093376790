import { FC } from "react";
import TableCell from "@mui/material/TableCell";
import "../tabsGamingActivity.scss";
import { useTranslation } from "react-i18next";

import { IAviatrixCasino } from "../../../../../interfaces/casino";
import moment from "moment";

interface Props {
  row: IAviatrixCasino;
}

export const RowDataTableAviatrix: FC<Props> = ({ row }) => {
  const { t } = useTranslation();

  const statusClass = row.status === "lost" ? "loss" : "open";
  const status:{ [key: string]: string } = {
    won: t("betResults.won"),
    lost: t("betResults.loss"),
    canceled: t("betResults.canceled"),
    done: t("betResults.done"),
  }

  return (
    <>
      <TableCell className="word-break">
        {moment(row.createAt).format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
      <TableCell className="word-break">{row.id}</TableCell>
      <TableCell className="word-break">{row.amountBet}</TableCell>
      <TableCell className="word-break">{row.awardsBet}</TableCell>
      <TableCell className="word-break">{row.roundId}</TableCell>
      <TableCell className="word-break">
        <div className={statusClass}>{status[`${row.status}`].toUpperCase()}</div>
      </TableCell>
    </>
  );
};
