import moment from "moment";
import { useSelector } from "react-redux";
import { headersIrrigation } from "../../../helpers/headersTables";
import { AppState } from "../../../redux/reducers/rootReducer";
import { getCopyArrayOrObject } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import { BalanceType, TransferConcept } from "../../../enums/enums";

export const IrrigationDataTable = () => {
  const { t } = useTranslation();
  let { irrigations, totalIrrigations } = useSelector(
    (state: AppState) => state.transactions
  );

  let { data } = useSelector((state: AppState) => state.search);

  const copyIrrigation = data
    ? getCopyArrayOrObject(data)
    : getCopyArrayOrObject(irrigations);

  const copyTotalIrrigation = getCopyArrayOrObject(totalIrrigations);
  const headersCSVIrrigation = headersIrrigation;

  //   COLUMNS HEADERS STYLES
  const headersCvsStyle = headersCSVIrrigation.map((header) => {
    return {
      title: header.label,
      width: { wpx: 150 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });
  // Obtener datos por vista
  const dataIrrigation = copyIrrigation
    ? copyIrrigation.data.map((irrigation: any) => {
        irrigation.createAt = moment(irrigation.createAt).format(
          "DD/MM/YYYY HH:mm:ss"
        );
        return irrigation;
      })
    : [];

  // Obtener el total de datos
  const dataTotalIrrigation = copyTotalIrrigation
    ? copyTotalIrrigation.data.map((irrigation: any) => {
        irrigation.createAt = moment(irrigation.createAt).format(
          "DD/MM/YYYY HH:mm:ss"
        );
        return irrigation;
      })
    : [];
    const getWalletName = (wallet: number) => {
      return t(
        wallet === BalanceType.REFILL
          ? "associates.refillBalance"
          : "irrigation.bettingBalance"
      ).toUpperCase();
    };

  // DATA POR VISTA
  const multiDataSetIrrigation = [
    {
      columns: headersCvsStyle,
      data: dataIrrigation.map((data: any) => {
        let array;
        array = [
          {
            value: data.associate.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.createAt,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.associate.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: getWalletName(data.balanceType),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: t(
              data.transferConcept === TransferConcept.CASH
                ? "companyManagement.cash"
                : "irrigation.bankTransfer"
            ),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: "$" + data.value,
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  //   DATA TOTAL
  const multiTotalDataSetIrrigation = [
    {
      columns: headersCvsStyle,
      data: dataTotalIrrigation.map((data: any) => {
        let array;
        array = [
          {
            value: data.associate.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.createAt,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.associate.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: getWalletName(data.balanceType),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: t(
              data.transferConcept === TransferConcept.CASH
                ? "companyManagement.cash"
                : "irrigation.bankTransfer"
            ),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: "$" + data.value,
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  return {
    dataIrrigation,
    dataTotalIrrigation,
    headersCSVIrrigation,
    multiDataSetIrrigation,
    multiTotalDataSetIrrigation,
  };
};
