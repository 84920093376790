import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import TableCell from "@mui/material/TableCell";

import { State } from "../../../../enums/enums";
import { IObservationsData } from "../../../../interfaces/players";
interface Props {
  row: IObservationsData;
}

export const RowDataTableObservations: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  return (
    <>
      <TableCell className="word-break">{row.player.id}</TableCell>

      <TableCell className="word-break">{row.observation}</TableCell>
      <TableCell className="word-break">
        {moment(row.createAt).format("DD/MM/YYYY HH:mm:ss a")}
      </TableCell>
      <TableCell align="left">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                State.ACTIVE === row.state
                  ? "d-flex justify-content-center cellRowActive"
                  : State.INACTIVE === row.state
                  ? "d-flex justify-content-center cellRowInactive"
                  : "d-flex justify-content-center cellRowRetired"
              }
            >
              <div className="d-flex col-sm-12 justify-content-center">
                {State.ACTIVE === row.state && <>{t("state.active")}</>}
                {State.INACTIVE === row.state && <>{t("state.inactive")}</>}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
    </>
  );
};
