import { MovementType } from "../enums/enums";
import { IPaginationProps } from "../interfaces/shared";

export interface ICashFlowQuery extends IPaginationProps {
  type: MovementType;
  operator: number;
}
export interface ICashFlowQueryDeposit extends IPaginationProps {
  startDate: string;
  endDate: string;
  operator: number;
}
export const getCashFlow = (token: string, params: Partial<ICashFlowQuery>) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}reports/cashflow?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
export const getCashFlowDeposit = (
  token: string,
  params: Partial<ICashFlowQueryDeposit>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}reports/cashflowDeposit?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
