import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { headersAnonymousBetsReport } from "../../../../helpers/headersTables";
import { getCopyArrayOrObject } from "../../../../utils/utils";
import moment from "moment";
import { betStatuses } from "../../../../helpers/betStatus";
import { useTranslation } from "react-i18next";

export const AnonymousBetsDataTable = (totalData: any[]) => {
  const { data } = useSelector((state: AppState) => state.search);
  const { t } = useTranslation();

  const headersCvsStyle = headersAnonymousBetsReport.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });
  // Obtener datos por vista
  const dataBets = data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalBets = totalData ? getCopyArrayOrObject(totalData) : [];

  const buildTotalData = (arr: any) => {
    const multiData = [
      {
        columns: headersCvsStyle,
        data: arr.map((data: any) => {
          let fees: number = 1;
          data.events.forEach((item: any) => {
            const calculateFee =
              data.eventCount > 1
                ? data.betStakes[0].odds
                : item.markets[0]
                ? item.markets[0].odds * fees
                : 1;
            fees = calculateFee;
          });
          let newFees: any;
          data.events.forEach((item: any) => {
            newFees =
              data.eventCount > 1
                ? data.betStakes[0].odds
                : item.markets[0]
                ? item.markets[0].odds * 1
                : 1;
          });

          const amountWin = `$${
            data.status === "S"
              ? data.transactions && data.transactions.length > 0
                ? parseFloat(data.transactions[0].amount).toFixed(2)
                : (parseFloat(data.amount) * fees).toFixed(2)
              : "0"
          }`;
          const amountBet = ` ${
            data.status === "S" &&
            data.transactions &&
            data.transactions.length > 0
              ? (parseFloat(data.transactions[0].amount) / newFees).toFixed(2)
              : data.bonusAccountId
              ? (data.betStakes[0].betstake / 100).toFixed(2)
              : data.amount
          }`;
          
          const matchedStatus = betStatuses.find(
            (status) => status.value === data.status
          );
          const statusDescription = matchedStatus
            ? matchedStatus.description.toUpperCase()
            : "";

         
          const paidState = data.paid
            ? `- ${t("betState.paid").toUpperCase()}`
            : "";
          const stateBet = `${statusDescription} ${paidState}`;

          const array = [
            {
              value: data.id,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.reference,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: moment(data.createAt).format("DD/MM/YYYY"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: moment(data.createAt).format("HH:mm:ss A"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: amountWin,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: amountBet,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: stateBet,
              style: { alignment: { horizontal: "center" } },
            },
          ];
          return array;
        }),
      },
    ];
    return multiData;
  };

  const multiTotalData = buildTotalData(dataTotalBets);
  const multiData = buildTotalData(dataBets);
  const finalData = {
    dataPerView: dataBets,
    dataTotal: dataTotalBets,
    headersCSV: headersAnonymousBetsReport,
    multiDataSet: multiData,
    multiTotalDataSet: multiTotalData,
  };
  return finalData;
};
