import moment from "moment";
import { useSelector } from "react-redux";
import { headersBetHistory } from "../../../../helpers/headersTables";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { getCopyArrayOrObject } from "../../../../utils/utils";
import { betStatuses } from "../../../../helpers/betStatus";
import { useTranslation } from "react-i18next";

export const BetHistoryDataTable = () => {
  const { t } = useTranslation();
  const { bets, totalBets } = useSelector((state: AppState) => state.bets);
  const { data } = useSelector((state: AppState) => state.search);

  const copyBets = data
    ? getCopyArrayOrObject(data)
    : getCopyArrayOrObject(bets);

  const copyTotalBets = getCopyArrayOrObject(totalBets);
  const headersCSVBets = headersBetHistory;

  //   COLUMNS HEADERS STYLES
  const headersCvsStyle = headersCSVBets.map((header) => {
    return {
      title: header.label,
      width: { wpx: 150 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });
  // Obtener datos por vista
  const dataBetHistory = copyBets ? copyBets.data : [];

  // Obtener el total de datos
  const dataTotalBetHistory = copyTotalBets ? copyTotalBets.data : [];

  // DATA POR VISTA
  const multiDataSetBetHistory = [
    {
      columns: headersCvsStyle,
      data: dataBetHistory.map((data: any) => {
        let feeds = 1;
        if (data.betStakes) {
          feeds = data.betStakes[0].odds;
        }
        const paid = data.paid ? `- ${t("betState.paid").toUpperCase()}` : "";
        let array;
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.reference,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.createAt).format("DD/MM/YYYY"),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.createAt).format("HH:mm:ss A"),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value:
              "$ " +
              `${
                data.status === "S"
                  ? data.transactions && data.transactions.length > 0
                    ? parseFloat(data.transactions[0].amount).toFixed(2)
                    : (parseFloat(data.amount) * feeds).toFixed(2)
                  : "0"
              } `,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: `${betStatuses
              .find((status) => status.value === data.status)
              ?.description.toUpperCase()} ${paid}`,

            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  //   DATA TOTAL
  const multiTotalDataSetBetHistory = [
    {
      columns: headersCvsStyle,
      data: dataTotalBetHistory.map((data: any) => {
        let feeds = 1;
        if (data.betStakes[0]) {
          feeds = data.betStakes[0].odds;
        }
        const paid = data.paid ? `- ${t("betState.paid").toUpperCase()}` : "";
        let array;
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.reference,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.createAt).format("DD/MM/YYYY"),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.createAt).format("HH:mm:ss A"),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value:
              "$ " +
              `${
                data.status === "S"
                  ? data.transactions && data.transactions.length > 0
                    ? parseFloat(data.transactions[0].amount).toFixed(2)
                    : (parseFloat(data.amount) * feeds).toFixed(2)
                  : "0"
              } `,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: `${betStatuses
              .find((status) => status.value === data.status)
              ?.description.toUpperCase()} ${paid}`,
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  return {
    dataBetHistory,
    dataTotalBetHistory,
    headersCSVBetHistory: headersCSVBets,
    multiDataSetBetHistory,
    multiTotalDataSetBetHistory,
  };
};
