import React, { useState, Dispatch, SetStateAction, FC } from "react";
import { useTranslation } from "react-i18next";

import { Box, Tab, Tabs } from "@mui/material";
import { TabPanel, a11yProps, styles } from "../../../../components/Tabs";
import { TabsByBalanceWithdrawals } from "./TabsByBalanceWithdrawals";
import { StateTransaction } from "../../../../enums/enums";
import { useDispatch, useSelector } from "react-redux";
import { setChangeTab } from "../../../../redux/actions/helperActions";
import { setWithdrawalsRedux } from "../../../../redux/actions/transactionsActions";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { CustomAlert } from "../../../../components/Alerts/CustomAlert";

interface Props {
  setComponent(value:StateTransaction):void;

  dataFilter: any;
  valueTab?: any;
  resetPage: boolean;
  setResetPage: Dispatch<SetStateAction<boolean>>;
}

export const TabsWithdrawals: FC<Props> = ({
  setComponent,
  dataFilter,
  valueTab,
  setResetPage,
  resetPage,
}) => {
  const { withdrawals } = useSelector((state: AppState) => state.transactions);
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    dispatch(setWithdrawalsRedux(null));

    dispatch(setChangeTab(true));
    const currentTab =
      newValue === 0
        ? StateTransaction.Pending
        : newValue === 1
        ? StateTransaction.Approved
        : StateTransaction.Refused;
    setResetPage(false);
    setComponent(currentTab);
  };
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={valueTab ? valueTab : value}
          onChange={(e: React.SyntheticEvent, newValue: number) => {
            handleChange(e, newValue);
          }}
          style={{ border: "none", outline: "none" }}
          TabIndicatorProps={{
            style: {
              backgroundColor: " #02D0E7",
              fontWeight: "bold",
            },
          }}
        >
          <Tab
            sx={styles}
            label={
              <span className="spanTab">
                <i className="fa-regular fa-clock mr-1"></i>
                {t("tabs.slopes")}
              </span>
            }
            {...a11yProps(0)}
          />
          <Tab
            sx={styles}
            label={
              <span className="spanTab">
                <i className="fa-solid fa-dollar-sign mr-1"></i>
                {t("tabs.approved")}
              </span>
            }
            {...a11yProps(1)}
          />
          <Tab
            sx={styles}
            label={
              <span className="spanTab">
                <i className="fa-solid fa-circle-xmark mr-1"></i>
                {t("tabs.rejected")}
              </span>
            }
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <div className="filter mt-3">
        <TabPanel value={value} index={0}>
          {withdrawals ? (
            <TabsByBalanceWithdrawals
              component={StateTransaction.Pending}
              dataFilter={dataFilter}
              setResetPage={setResetPage}
              resetPage={resetPage}
            />
          ) : (
            <CustomAlert htmlText={t("withdrawals.filterText")} type="info" />
          )}
        </TabPanel>

        <TabPanel value={value} index={1}>
          {withdrawals ? (
            <TabsByBalanceWithdrawals
              component={StateTransaction.Approved}
              dataFilter={dataFilter}
              setResetPage={setResetPage}
              resetPage={resetPage}
            />
          ) : (
            <CustomAlert htmlText={t("withdrawals.filterText")} type="info" />
          )}
        </TabPanel>

        <TabPanel value={value} index={2}>
          {withdrawals ? (
            <TabsByBalanceWithdrawals
              component={StateTransaction.Refused}
              dataFilter={dataFilter}
              setResetPage={setResetPage}
              resetPage={resetPage}
            />
          ) : (
            <CustomAlert htmlText={t("withdrawals.filterText")} type="info" />
          )}
        </TabPanel>
      </div>
    </Box>
  );
};
