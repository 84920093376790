import {
  IBonusForm,
  IBonusReciveMassive,
  IBonusRedeemedQuery,
  IBonusTypeBody,
  IBonusTypeQuery,
  IBonusUpdatedData,
  IDataFreeTicketsBonus,
} from "./../interfaces/bonus";
export const getBonus = (
  operator: number | undefined,
  token: string,
  perPage: number | null
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const operatorParam = operator ? `operator=${operator}` : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonus?${operatorParam}&${
      perPage ? `perPage=${perPage}` : `perPage=9`
    }`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const getBonusRedeemed = (
  token: string,
  params: Partial<IBonusRedeemedQuery>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonusRedeemed?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const filterBonus = (
  operator: number | null,
  params: any,
  token: string
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const operatorParam = operator ? `operator=${operator}` : "";
  const queryString = params ? new URLSearchParams(params).toString() : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonus?${operatorParam}&${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const getBonusType = (
  token: string,
  params: Partial<IBonusTypeQuery>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";
  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonusType?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const createBonusType = (token: string, data: IBonusTypeBody) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonusType`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const updateBonusType = (
  token: string,
  id: number,
  data: IBonusTypeBody
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonusType/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const deleteBonusType = (token: string, id: number) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonusType/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const createBonus = (data: IBonusForm, token: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${process.env.REACT_APP_API_HTTP_BETS}bonus`, requestOptions)
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const createBonusAltenar = (data: IBonusForm, token: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonus/create-altenar`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const updateBonusAltenar = (data: any, token: any) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonus/update`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const toogleBonusAltenar = (data: any, token: any) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonus/toggle`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const updateBonus = (
  id: number,
  data: IBonusUpdatedData,
  token: string
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonus/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const deleteBonus = (id: number, token: string) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonus/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const bonusReciveMassive = (
  data: IBonusReciveMassive,
  token: string | null
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonus/reciveMassive`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const bonusGroupList = (
  operator: number | null,
  params: any,
  token: string
) => {
  const data = {
    operator: operator,
    state: 1,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const queryString = params ? new URLSearchParams(params).toString() : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonus/list/group?${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const bonusGroupDetail = (
  groupId: number,
  params: any,
  token: string
) => {
  const data = {
    groupId,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const queryString = params ? new URLSearchParams(params).toString() : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonus/list/groupBonus?${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const activeOrInactiveBonus = (bonusGroupId: number, token: string) => {
  const data = {
    bonusGroupId,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonus/toggle/groupBonus`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const addBonusToGroup = (data: any, token: string | null) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonus/add/groupBonus`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const filterBonusPariplay = (
  operator: number | null,
  params: any,
  token: string
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const operatorParam = operator ? `operator=${operator}` : "";
  const queryString = params ? new URLSearchParams(params).toString() : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bonusPariplay?${operatorParam}&${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const addFreeTicketsBonus = (
  token: string,
  settings: IDataFreeTicketsBonus
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(settings),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}freeTickets`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const filterFreeTicketsBonus = (params: any, token: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryString = params ? new URLSearchParams(params).toString() : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}freeTickets?${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const createNewBonus = (data: IBonusForm, token: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${process.env.REACT_APP_API_HTTP_BONDS}create`, requestOptions)
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const getNewBonusType = (
  token: string,
  params: Partial<IBonusTypeQuery>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";
  return fetch(
    `${process.env.REACT_APP_API_HTTP_BONDS}types?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
