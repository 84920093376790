import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";

import { TableCell, Tooltip } from "@mui/material";
import { IExternalUsersData } from "../../../../interfaces/commercialNetworks";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { startUpdateCommercialNetwork } from "../../../../redux/actions/commercialNetworkActions";
import { capitalizeFirstLetter } from "../../../../utils/utils";
interface Props {
  row: IExternalUsersData;
}

export const RowDataTableCommercialNetworks: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { routes: RoutesRedux } = useSelector(
    (state: AppState) => state.managementMenus
  );
  const { pathname } = useLocation();
  const modulePermissions = RoutesRedux.find(
    (route) => !!route.menuItem.find((module) => module.url === pathname)
  )?.menuItem.find((module) => module.url === pathname);
  const enableEdit =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.updated === 1;

  const onSee = async (id: number) => {
    await dispatch(
      startUpdateCommercialNetwork(id, { active: !row.active }, true)
    );
  };
  return (
    <>
      <TableCell className="word-break">{capitalizeFirstLetter(row.name)}</TableCell>
      <TableCell className="word-break">{row.nit.toUpperCase()}</TableCell>
      <TableCell className="word-break">{row.legalRepresentative}</TableCell>
      <TableCell className="word-break">{row.phone}</TableCell>
      <TableCell className="word-break">{row.email}</TableCell>
      <TableCell className="word-break">
        {moment(row.createAt).format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
      {enableEdit && (
        <TableCell>
          <Tooltip title={t("button.changeState") as string} arrow>
            <div className="d-flex justify-content-center">
              <i
                className={
                  row.active
                    ? `fa fa-eye fa-lg mr-2 iconSee`
                    : `fa-regular fa-eye-slash fa-lg mr-2 iconSee`
                }
                onClick={() => onSee(row.id)}
              />
            </div>
          </Tooltip>
        </TableCell>
      )}
      <TableCell />
    </>
  );
};
