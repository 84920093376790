import moment from "moment";
import { useSelector } from "react-redux";
import { headersWithdrawalHistory } from "../../../helpers/headersTables";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  buildRedName,
  getCopyArrayOrObject,
  getStateSettingName,
} from "../../../utils/utils";
import { IWithdrawalData } from "../../../interfaces/withdrawals";

export const WithdrawalHistoryDataTable = (totalData: IWithdrawalData[]) => {
  const { data } = useSelector((state: AppState) => state.search);

  const headersCvsStyle = headersWithdrawalHistory.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  const dataWithdrawals =
    data && data.data ? getCopyArrayOrObject(data.data) : [];
  const dataTotalWithdrawals = totalData ? getCopyArrayOrObject(totalData) : [];

  const multiDataSetWithdrawals = [
    {
      columns: headersCvsStyle,
      data: dataWithdrawals.map((data: IWithdrawalData) => {
        let array;
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.meta?.transactionReference || "",
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.email,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: `$${data.withdrawalValue}`,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: `$${data.paidValue}`,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: getStateSettingName(data.state),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: buildRedName(data),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.createAt).format("YYYY-MM-DD HH:mm:ss"),
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  const multiTotalDataSetWithdrawals = [
    {
      columns: headersCvsStyle,
      data: dataTotalWithdrawals.map((data: IWithdrawalData) => {
        let array;
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.meta?.transactionReference || "",
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.email,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: `$${data.withdrawalValue}`,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: `$${data.paidValue}`,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: getStateSettingName(data.state),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: buildRedName(data),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.createAt).format("YYYY-MM-DD HH:mm:ss"),
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  return {
    dataPerView: dataWithdrawals,
    dataTotal: dataTotalWithdrawals,
    headersCSV: headersWithdrawalHistory,
    multiDataSet: multiDataSetWithdrawals,
    multiTotalDataSet: multiTotalDataSetWithdrawals,
  };
};