import { ISearch, SearchAction } from "../../interfaces/search";
import { Types } from "../types/types";

const initialState = {
  data: null,
  value: "",
  changeData: false,
};

export const searchReducer = (
  state: Partial<ISearch> = initialState,
  action: SearchAction
) => {
  switch (action.type) {
    case Types.arraySearch:
      return {
        ...state,
        data: action.payload,
      };

    case Types.valueSearched:
      return {
        ...state,
        value: action.payload,
      };

    case Types.changeData:
      return {
        ...state,
        changeData: action.payload,
      };

    default:
      return state;
  }
};
