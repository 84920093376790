import moment from "moment";
import { Alert, AlertTitle, Grid, Stack } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import { Row } from "reactstrap";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Enum_FieldType, IFilterField } from "../../../interfaces/components";
import Filter from "../../../components/Filter/Filter";

import {
  asyncLoadingFunction,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { startGetPaymentsPointSale } from "../../../redux/actions/paymentActions";
import MyCustomTable, {
  IColumns,
} from "../../../components/Custom/Table/MyCustomTable";
import { RowDataPayHistorySeller } from "../../pages/bets/RowDataPayHistorySeller";
import { PaymentsHistoryDataTable } from "../../../hooks/useDataTable/bets/payments/PaymentsHistoryDataTable";
import { AnonymousPaymentsPdf } from "../../../components/Pdf/bets/AnonymousPaymentsPdf";

export const TabSellersPaymentsHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data } = useSelector((state: AppState) => state.search);
  const { pays } = useSelector((state: AppState) => state.pays);

  const [filter, setFilter] = useState(false);

  /**
   * Datos inputs para filtro
   */
  const FilterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("bets.ticketNumber"),
      type: Enum_FieldType.TEXT,
    },
  ];

  const columns: IColumns[] = [
    {
      align: "center",
      label: t("bets.ticketNumber"),
    },
    {
      align: "center",
      label: t("bets.payDate"),
    },
    {
      align: "center",
      label: t("bets.payTime"),
    },
    {
      align: "center",
      label: t("bets.payAmount"),
    },
  ];

  const handleFilter = (formValues: any) => {
    setFilter(true);
    const startDate =
      formValues[t("filters.startDate")].length > 0
        ? formValues[t("filters.startDate")] + "T00:00:00"
        : "";
    const endDate =
      formValues[t("filters.endDate")].length > 0
        ? formValues[t("filters.endDate")] + "T23:59:00"
        : "";
    const params = {
      startDate,
      endDate,
      ticketId: formValues[t("bets.ticketNumber")],
      page: 1,
    };

    const errorDate = moment(startDate).isAfter(endDate);
    if (errorDate) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.errorDate"));
      dispatch(setTypeAlert("error"));
    } else {
      dispatch(
        asyncLoadingFunction(
          async () => await dispatch(startGetPaymentsPointSale(params))
        )
      );
    }
  };
  const onCleanFilter = async () => {
    await dispatch(
      asyncLoadingFunction(
        async () => await dispatch(startGetPaymentsPointSale({ page: 1 }, true))
      )
    );
    setFilter(false);
  };

  return (
    <div className="container-fluid">
      <Row
        style={{
          marginTop: "12px",
        }}
      >
        <Filter
          fields={FilterFields}
          component={"bettingHistory"}
          handleFilter={handleFilter}
          onClean={onCleanFilter}
        />
      </Row>

      {data && (
        <>
          {data.data.length > 0 && (
            <MyCustomTable
              columns={columns}
              data={data.data}
              lastPage={data.last_page}
              totalData={data.total}
              onPageChange={() => {}}
              renderBody={(row) => <RowDataPayHistorySeller row={row} />}
              exportData
              hookBuildTable={PaymentsHistoryDataTable}
              getAllDataToExport={async () => {return {data:[]}}}
              pdfComponent={AnonymousPaymentsPdf}
              titleCSV={`${t("reports.associateAwards")
                .split(" ")
                .join("-")}-${moment().toISOString()}`}
            />
          )}
        </>
      )}

      {!pays && !filter && !data && (
        <Grid item xs={12} pt={3} mt={-2}>
          <Stack
            sx={{ width: "100%" }}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
            spacing={2}
          >
            <Alert
              icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
              sx={{
                borderLeft: `7px solid #2C97DF`,
                backgroundColor: "#EAF5FC",
                color: "#4b4b4b",
              }}
            >
              <div className="d-flex">
                <AlertTitle
                  style={{ marginTop: "5px" }}
                  sx={{ color: "#333333" }}
                >
                  {t("alerts.clickButton")}
                </AlertTitle>
                <AlertTitle
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  sx={{ color: "#333333", fontWeight: "bold" }}
                >
                  {t("alerts.refillBets")}
                </AlertTitle>
              </div>
            </Alert>
          </Stack>
        </Grid>
      )}
    </div>
  );
};
