import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { Form, Formik, useFormikContext } from "formik";
import * as yup from "yup";
import { MyTextField } from "../../custom/MyTextField";
import {
  regDecimal,
  regLettersNumbers,
  regNumbers,
  regPassport,
} from "../../../../utils/utils";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { getPlayerByTypeAndNumberDocument } from "../../../../redux/actions/depositsPlayersActions";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import { MyCustomDialog } from "../../../../components/Custom/MyCustomDialog";
import { FormConfirmDeposit } from "./FormConfirmDeposit";
import { IDocumentTypes } from "../../../../redux/interfaces/shared";
import { DocumentTypes, Roles_Enum } from "../../../../enums/enums";
import { IPlayerByTypeAndNumberDocumentParams } from "../../../../interfaces/players";
import { getUserById } from "../../../../services/managementUsers";
import { PointSaleSelect } from "../../custom/PointSaleSelect";
import { getPointSaleWallet } from "../../../../services/helperService";
import { setPointSaleWallet } from "../../../../redux/actions/walletActions";
import { arraySearch } from "../../../../redux/actions/searchActions";

export const FormCreateDeposit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { documentTypes } = useSelector((state: AppState) => state.helper);
  const { pointSale: pointSaleWallet } = useSelector(
    (state: AppState) => state.wallet
  );

  const [isSubmitting, setSubmitting] = useState(false);
  const [flagConfirmDeposit, setFlagConfirmDeposit] = useState(false);
  const [cleanData, setCleanData] = useState(false);
  const [pointSaleSelected, setPointSaleSelected] = useState("");
  const [typeDocumentSelected, setTypeDocumentSelected] = useState<
    number | null
  >(null);
  const [player, setPlayer] = useState({
    id: 0,
    email: "",
    numberDocument: "",
    username: "",
    firstName: "",
    lastName: "",
    playerBalance: {
      id: "",
      amountWallet: "",
      awardsWallet: "",
      balanceWallet: "",
      bonusWallet: "",
    },
  });

  const initialValues = {
    documentType: "",
    documentNumberPlayer: "",
    value: "",
    pointSale: "",
    observations: "",
  };
  const [formValues, setFormValues] = useState<any>(initialValues);
  const formikRef = useRef(null);

  const validationSchema = yup.object({
    documentType: yup.number().required(t("form.requiredFile")),
    documentNumberPlayer: yup
      .string()
      .required(t("form.requiredFile"))
      .when("documentType", {
        is: (value: number) =>
          [DocumentTypes.CITIZENSHIP_CARD, DocumentTypes.PASSPORT].includes(
            value
          ),
        then: yup
          .string()
          .min(6, t("form.invalidLengthDocumentNumber", { min: 6, max: 15 }))
          .max(15, t("form.invalidLengthDocumentNumber", { min: 6, max: 15 })),
      })
      .when("documentType", {
        is: DocumentTypes.CIP,
        then: yup
          .string()
          .min(6, t("form.invalidLengthDocumentNumber", { min: 6, max: 15 }))
          .max(15, t("form.invalidLengthDocumentNumber", { min: 6, max: 15 })),
      })
      .when("documentType", {
        is: DocumentTypes.DNI,
        then: yup
          .string()
          .min(8, t("form.invalidLengthDocumentNumber", { min: 8, max: 13 }))
          .max(13, t("form.invalidLengthDocumentNumber", { min: 8, max: 13 })),
      })
      .when("documentType", {
        is: (value: number) =>
          [DocumentTypes.DUI, DocumentTypes.CE].includes(value),
        then: yup
          .string()
          .min(5, t("form.invalidLengthDocumentNumber", { min: 5, max: 15 }))
          .max(15, t("form.invalidLengthDocumentNumber", { min: 5, max: 15 })),
      })
      .when("documentType", {
        is: DocumentTypes.DPI,
        then: yup
          .string()
          .min(13, t("form.invalidLengthDocument", { value: 13 }))
          .max(13, t("form.invalidLengthDocument", { value: 13 })),
      }),
    value: yup
      .number()
      .positive(t("alerts.onlyPositiveNumber"))
      .test(
        "len",
        t("form.lessThan20"),
        (val: any) => val && val.toString().length <= 8
      )
      .required(t("form.requiredFile")),
    observations: yup.string(),
  });

  const getWallet = async (pointSale: any) => {
    if (!user || !token || !user.id) {
      throw new Error("invalid action");
    }
    try {
      dispatch(setPointSaleWallet(null));

      const wallet = await getPointSaleWallet(token, pointSale);
      dispatch(setPointSaleWallet(wallet));
    } catch (error) {
      dispatch(setPointSaleWallet(null));
    }
  };
  const getUserDetail = async () => {
    if (!user || !token || !user.id) {
      throw new Error("invalid action");
    }
    const userDetail = await getUserById(token, user.id);

    const pointSaleLinked: string =
      userDetail.sellerPointSales.length > 0
        ? userDetail.sellerPointSales[0]?.pointSale?.id || ""
        : "";
    (formikRef.current as any).setFieldValue("pointSale", pointSaleLinked);
  };
  useEffect(() => {
    if (user && user.roles.name === Roles_Enum.SELLER) {
      getUserDetail();
      dispatch(arraySearch(null));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEmptyValues = (formValues: any) => {
    let emptyValues = false;

    Object.entries(formValues).forEach(([key, value]) => {
      if (value === "" && key !== "observations") {
        emptyValues = true;
      }
    });

    return emptyValues;
  };

  /**
   *
   * @param data datos formulario crear nueva recaga
   * Evento que soporta la consulta del jugador mediante tipo y número de documento
   * para su posterior recarga de cuenta
   */

  const GetPlayer = async (data: any) => {
    setSubmitting(true);
    const playerData: IPlayerByTypeAndNumberDocumentParams = {
      typeDocument: data.documentType,
      numberDocument: data.documentNumberPlayer,
      value: data.value,
    };

    if (user?.operator) {
      playerData.operatorId = user?.operator?.id;
    }

    const responsePlayer: any = await dispatch(
      getPlayerByTypeAndNumberDocument(playerData)
    );
    if (responsePlayer && responsePlayer.rs) {
      setPlayer(responsePlayer.rs);
      handleShowForm();
      setSubmitting(false);
    } else {
      dispatch(setMessageAlert(`alerts.${responsePlayer.error.errorMessage}`));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
    setSubmitting(false);
  };
  useEffect(() => {
    if (pointSaleSelected !== "") {
      getWallet(pointSaleSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointSaleSelected]);

  /**
   * EVENTOS ABRIR Y CERRAR MODAL DE CONFIRMACION DE DEPOSITO
   */
  const handleShowForm = () => {
    setFlagConfirmDeposit(true);
  };
  const handleCloseForm = () => {
    setFlagConfirmDeposit(false);
  };

  const AutoReset = () => {
    const { resetForm } = useFormikContext();
    useEffect(() => {
      if (cleanData) {
        resetForm();
        if (user && user.roles.name === Roles_Enum.SELLER) {
          getUserDetail();
        } else {
          (formikRef.current as any).setFieldValue(
            "pointSale",
            pointSaleSelected
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cleanData]);
    return null;
  };

  // EVENT SELECT DOCUMENT TYPE
  const handleSelectTypeDocument = (value: number | null) => {
    value && setTypeDocumentSelected(value);
  };

  return (
    <div className="white-container">
      <Grid container spacing={2}>
        <Grid item xs={12} className="mb-4">
          <Box
            display="flex"
            className="pb-1"
            borderBottom={1}
            borderColor="#02D0E7"
          >
            <i
              className="fa-solid fa-circle-info"
              style={{
                color: "#02D0E7",
                marginRight: 9,
                fontSize: 18,
              }}
            ></i>
            <p
              className="txt-primary-md"
              style={{ fontWeight: 600, fontSize: 15 }}
            >
              {t("deposits.makeDeposit")}
            </p>
          </Box>
        </Grid>
        {user &&
          [Roles_Enum.SUPER_ADMIN, Roles_Enum.ADMIN_OPERATOR].includes(
            user.roles.name as any
          ) && (
            <Grid item spacing={12}>
              <p
                className="txt-primary-md"
                style={{ fontWeight: 600, fontSize: 15 }}
              >
                {`${t("pointsOfSale.availableBalance")}: ${
                  pointSaleWallet?.amount ?? "..."
                }`}
              </p>
            </Grid>
          )}
      </Grid>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={3}>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange
              onSubmit={async (data) => {
                setFormValues(data);
                GetPlayer(data);
              }}
            >
              {({ handleChange, values, isValid, setFieldValue }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <MyTextField
                        name="documentType"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        label={t("user.documentType") + "*"}
                        onChange={(
                          event: any,
                          value: IDocumentTypes | null
                        ) => {
                          handleSelectTypeDocument(event.target.value);
                          handleChange(event);
                          setFieldValue("documentNumberPlayer", "");
                        }}
                      >
                        {documentTypes.map((item) => (
                          <MenuItem
                            key={item.name}
                            value={item.id}
                            sx={{
                              "&.Mui-focused": {
                                backgroundColor: "red",
                              },
                            }}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </MyTextField>
                    </Grid>
                    <Grid item xs={4}>
                      <MyTextField
                        name="documentNumberPlayer"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        label={`${t("transactions.documentNumberPlayer")}*`}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: 15,
                        }}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                          if (
                            [
                              DocumentTypes.PASSPORT,
                              DocumentTypes.CE,
                              DocumentTypes.CITIZENSHIP_CARD,
                              DocumentTypes.CIP,
                            ].includes(typeDocumentSelected as number)
                              ? !regPassport(e.key)
                              : !regNumbers(e.key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MyTextField
                        name="value"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        label={`${t("deposits.valueDeposited")}*`}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                          !regDecimal(e.key) && e.preventDefault()
                        }
                        value={values.value}
                        onChange={(e: any) => {
                          if (e.target.value.length <= 8) {
                            setFieldValue("value", e.target.value);
                          }
                        }}
                      />
                    </Grid>
                    {user &&
                      [
                        Roles_Enum.SUPER_ADMIN,
                        Roles_Enum.ADMIN_OPERATOR,
                        Roles_Enum.CONTACT_CENTER,
                      ].includes(
                        user.roles.name.toUpperCase() as Roles_Enum
                      ) && (
                        <Grid item xs={4}>
                          <PointSaleSelect
                            onChange={(e) => {
                              handleChange(e);
                              setPointSaleSelected(e.target.value);
                            }}
                          />
                        </Grid>
                      )}
                    {[
                      Roles_Enum.SUPER_ADMIN,
                      Roles_Enum.ADMIN_OPERATOR,
                      Roles_Enum.BETPPRO_TREASURY,
                      Roles_Enum.CONTACT_CENTER,
                    ].includes(
                      user?.roles.name.toUpperCase() as Roles_Enum
                    ) && (
                      <Grid item xs={8}>
                        <MyTextField
                          name="observations"
                          label={t("observations.title")}
                          multiline
                          rows={1}
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 200,
                          }}
                          onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                            !regLettersNumbers(e.key) && e.preventDefault()
                          }
                        />
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="right"
                      sx={{ height: "60px" }}
                      mt={5}
                      mb={2}
                    >
                      <Button
                        type="submit"
                        style={{
                          border: "none",
                          outline: "none",
                        }}
                        variant="contained"
                        id="btnSave"
                        disabled={
                          isSubmitting ||
                          isEmptyValues(values) ||
                          !isValid ||
                          (pointSaleWallet
                            ? parseFloat(pointSaleWallet.amount) <
                              parseFloat(values.value)
                            : false)
                        }
                        className="primary-btn ripple"
                      >
                        {isSubmitting ? (
                          <CircularProgress size={"1.6rem"} color="inherit" />
                        ) : (
                          <>{t("deposits.deposite")}</>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                  <AutoReset />
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
      <MyCustomDialog
        open={!!flagConfirmDeposit && !!formValues}
        content={
          <FormConfirmDeposit
            player={player}
            setFlagConfirmDeposit={setFlagConfirmDeposit}
            setCleanData={setCleanData}
            formValues={formValues}
          />
        }
        onClose={handleCloseForm}
        width={"sm"}
        title={`${t("deposits.purchaseSummary")} - ${player.firstName} ${
          player.lastName
        }`}
        icon={
          <i
            className="fa-solid fa-circle-info"
            style={{
              color: "#02D0E7",
              marginRight: 9,
              fontSize: 20,
            }}
          ></i>
        }
      />
    </div>
  );
};
