import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import { Box, IconButton, Typography } from "@mui/material";
import { TableComponent } from "../../../../components/Table/TableComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  setCloseAlert,
  setFlagForm,
  setRowsPerPage,
  setFlagFormEdit,
} from "../../../../redux/actions/helperActions";
import {
  Enum_FieldType,
  IColumnsTable,
  Icons,
  IFilterField,
} from "../../../../interfaces/components";
import Filter from "../../../../components/Filter/Filter";
import { FormCreateAssociate } from "../../../components/masters/associates/FormCreateAssociate";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { AssociateDetails } from "../../../components/masters/associates/AssociateDetails";
import { useEffect, useState } from "react";
import {
  setGetAssociates,
  setActiveAssociate,
} from "../../../../redux/actions/associatesActions";
import { setRolesRedux } from "../../../../redux/actions/managementRolesActions";
import { getOperatorsService } from "../../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../../redux/actions/managementCompanyActions";
import { getRoles } from "../../../../services/helperService";
import { Roles_Enum, State } from "../../../../enums/enums";
import { useLocation } from "react-router-dom";
import { arraySearch } from "../../../../redux/actions/searchActions";
import { MyAlert } from "../../../components/custom/MyAlert";
import MyBreadCrumbs from "../../../../components/Custom/MyBreadCrumbs";

const columns: IColumnsTable[] = [
  { id: 1, header: "associates.idCode" },
  { id: 2, header: "associates.refillBalance" },
  { id: 3, header: "table.name" },
  { id: 4, header: "user.address" },
  { id: 5, header: "user.phone" },
  { id: 6, header: "form.state" },
  { id: 7, header: "table.createdBy" },
  { id: 8, header: "associates.createNewUser" },
  { id: 9, header: "table.actions" },
];

const icons: Icons = {
  see: true,
  delete: false,
  seeMore: true,
  add: false,
};

export const Associates = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { flagFormCreate, openAlert, messageAlert, typeAlert } =
    useSelector((state: AppState) => state.helper);
  const { data } = useSelector((state: AppState) => state.search);
  const { token, user } = useSelector((state: AppState) => state.auth);
  const { associates, activeAssociate } = useSelector(
    (state: AppState) => state.associates
  );
  const [dataFilter, setDataFilter] = useState<any>({});
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { pathname } = useLocation();

  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;

  useEffect(() => {
    token && dispatch(setGetAssociates(token, 1));
    dispatch(arraySearch(null));
    dispatch(setFlagForm({ flagFormCreate: false }));
    return () => {
      dispatch(setFlagForm({ flagFormCreate: false }));
      dispatch(setFlagFormEdit({ flagFormEdit: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenForm = () => {
    dispatch(setActiveAssociate(null));
    dispatch(setFlagForm({ flagFormCreate: true }));
  };

  const filterFields: IFilterField[] = [
    {
      label: t("associates.idCode"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("table.createdBy"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("filters.name"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("form.state"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("state.active"),
          value: State.ACTIVE,
        },
        {
          label: t("state.inactive"),
          value: State.INACTIVE,
        },
      ],
    },
    {
      label: t("user.address"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("user.phone"),
      type: Enum_FieldType.TEXT,
    },
  ];

  useEffect(() => {
    dispatch(arraySearch(null));
    dispatch(setCloseAlert());
    dispatch(setRowsPerPage(9));
    const fetchRoles = async (token: string) => {
      if (user) {
        const id = user.operator ? user.operator.id : undefined;
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
        const isSuper = user.roles.name === Roles_Enum.SUPER_ADMIN;
        const response = await getRoles(
          token,
          id?.toString(),
          true,
          isSuper
        );
        response && dispatch(setRolesRedux(response));
      }
    };
    token && fetchRoles(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseAlert = () => {
    dispatch(setCloseAlert());
  };

  return (
    <div className="content w-100 mb-4">
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Row>
            <Col xs={12}>
              <MyBreadCrumbs
                titles={[t("sidebar.masters"), t("shorcuts.Associates")]}
              >
                {flagFormCreate && (
                  <Typography color="text.primary">
                    {t(
                      !!activeAssociate
                        ? "button.editAssociate"
                        : "button.createAssociate"
                    )}
                  </Typography>
                )}
              </MyBreadCrumbs>
            </Col>{" "}
          </Row>
          {flagFormCreate ? (
            <FormCreateAssociate />
          ) : (
            <div className="white-container mt-2">
              <>
                {enableCreated && (
                  <Row>
                    <Col xs={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        mb={2}
                      >
                        <Box display="flex" alignItems="center">
                          <p
                            className="txt-primary-md"
                            style={{
                              fontWeight: "bold",
                              textTransform: "uppercase",
                              alignSelf: "center",
                            }}
                          >
                            {t("button.createAssociate")}
                          </p>
                        </Box>
                        <Box>
                          <IconButton
                            onClick={handleOpenForm}
                            style={{ border: "none", outline: "none" }}
                            id="openFormBtn"
                          >
                            <span className="btn-add">
                              <i
                                className="fa-solid fa-plus"
                                style={{ fontSize: 30 }}
                              ></i>
                            </span>
                          </IconButton>
                        </Box>
                      </Box>
                    </Col>
                  </Row>
                )}

                <Filter
                  fields={filterFields}
                  component={"associates"}
                  setDataFilter={setDataFilter}
                />

                {data ? (
                  <>
                    {data.data.length > 0 && (
                      <TableComponent
                        columns={columns}
                        data={data.data}
                        pages={data.last_page}
                        icons={icons}
                        component={"associates"}
                        totalData={data}
                        isSortingTable={true}
                        dataFilter={dataFilter}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {associates && associates.data.length > 0 && (
                      <TableComponent
                        columns={columns}
                        data={associates.data}
                        pages={associates.last_page}
                        icons={icons}
                        component={"associates"}
                        totalData={associates}
                        isSortingTable={true}
                        dataFilter={dataFilter}
                      />
                    )}
                  </>
                )}
              </>
            </div>
          )}
        </Col>
      </Row>

      <AssociateDetails />

      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />
    </div>
  );
};
