import React, { FC } from "react";
import TableCell from "@mui/material/TableCell";
import moment from "moment";
import { IBetData } from "../../../interfaces/bets";

interface Props {
  row: IBetData;
}

export const RowDataPayHistorySeller: FC<Props> = ({ row }) => {
  return (
    <>
      <TableCell align="center" className="word-break">
        {row.transactionId}
      </TableCell>
      <TableCell align="center" className="word-break">
        {moment(row.createAt).format("DD/MM/YYYY")}
      </TableCell>
      <TableCell align="center" className="word-break">
        {moment(row.createAt).format("HH:mm:ss A")}
      </TableCell>
      <TableCell align="center">
        {"$ "}
        {parseFloat(row.amount).toFixed(2)}
      </TableCell>
    </>
  );
};
