import React, { useEffect, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { setTotalUsersRedux } from "../../redux/actions/managementUsersActions";
import { AppState } from "../../redux/reducers/rootReducer";
import { IUserDB } from "../../redux/interfaces/users";
import { getUsers } from "../../services/managementUsers";
import { getRefillsService } from "../../services/managementPlayersService";
import {
  setTotalBonusRedeemedRedux,
  setTotalRefillsRedux,
} from "../../redux/actions/managementPlayersActions";
import { IRefillsData } from "../../interfaces/refills";
import { useDataTable } from "../../hooks/useDataTable/useDataTable";
import filePlus from "../../assets/svg/file-plus.svg";
import fileText from "../../assets/svg/file-text.svg";
import { BlobProvider } from "@react-pdf/renderer";
import { PdfDocument } from "../Pdf/PdfDocument";
// @ts-ignore
import ReactExport from "react-data-export";
import { setTotalMenusRedux } from "../../redux/actions/managementMenuActions";
import { IMenuDB } from "../../interfaces/menu";
import { getMenusService } from "../../services/managementMenuService";
import { getLegalParameters } from "../../services/legal";
import { setTotalLegalRedux } from "../../redux/actions/legalActions";
import { ILegalParameters } from "../../interfaces/legal";
import {
  getBrandsService,
  getOperatorsService,
} from "../../services/managementCompanyService";
import {
  setTotalBrandsRedux,
  setTotalCompaniesRedux,
} from "../../redux/actions/managementCompanyActions";
import {
  ICompanyDB,
  IFormConfigOperation,
} from "../../interfaces/operationCompany";
import { getTransactionSettings } from "../../services/transactionSettings";
import {
  setTotalIrrigations,
  setTotalSettings,
} from "../../redux/actions/transactionsActions";
import { getIrrigations } from "../../services/irrigations";
import { getBets, getPlayerBets } from "../../services/bets";
import { setTotalBets } from "../../redux/actions/betsActions";
import { getBonusRedeemed } from "../../services/bonus";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const useStylesCard = makeStyles(() => ({
  title: {
    color: "white",
    fontSize: 12,
  },
  textItem: {
    fontSize: "12px",
  },
}));

interface Props {
  component: string;
  exportData: any[];
  setTooltipIsOpen: any;
}

export const ExportFilesList: FC<Props> = ({
  component,
  exportData,
  setTooltipIsOpen,
}) => {
  const [value, setValue] = useState(0);
  const [titleCSV, setTitleCvs] = useState("");

  const classes = useStylesCard();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token, user } = useSelector((state: AppState) => state.auth);
  const { player } = useSelector((state: AppState) => state.managementPlayers);
  const [loadingData, setLoading] = useState(false);

  const { dataTotal, multiDataSet, multiTotalDataSet } =
    useDataTable(component);

  useEffect(() => {
    const onGetTotalData = async () => {
      let response: any = null;
      setLoading(true);
      if (token && user) {
        switch (component) {
          // USERS
          case "users":
            response = await getUsers(
              token,
              user.id,
              user.roles.name,
              1,
              null,
              user.operator && user.operator.id ? user.operator.id : undefined
            );
            await dispatch(setTotalUsersRedux(response as IUserDB));
            setTitleCvs(t("table.neatUsers"));
            setValue(1);
            break;

          // REFILLS
          case "refills":
            response = await getRefillsService(
              token,
              user.id,
              1,
              null,
              user.operator?.id
            );
            await dispatch(setTotalRefillsRedux(response as IRefillsData));
            setTitleCvs(t("table.neatRefills"));
            setValue(2);
            break;

          // MENU
          case "menu":
            response = await getMenusService(token, user.operator?.id);
            await dispatch(setTotalMenusRedux(response as IMenuDB));
            setTitleCvs(t("table.neatMenus"));
            setValue(3);
            break;
          // CONFIGURATION COMPANY
          case "configCompany":
            response = await getBrandsService(token, null, user.operator?.id);
            await dispatch(
              setTotalBrandsRedux(response as IFormConfigOperation)
            );
            setTitleCvs(t("table.neatConfigCompany"));
            setValue(4);
            break;
          // MANAGEMENT COMPANY
          case "managementCompany":
            response = await getOperatorsService(token);
            await dispatch(setTotalCompaniesRedux(response as ICompanyDB));
            setTitleCvs(t("table.neatManagementCompany"));
            setValue(5);
            break;
          // TRANSACTION SETTINGS
          case "transactions":
            const params = user.operator
              ? {
                  operatorId: user.operator.id,
                }
              : {};
            response = await getTransactionSettings(token, params);
            dispatch(setTotalSettings(response));
            setTitleCvs(t("configParams.title"));
            setValue(6);
            break;

          case "legal":
            response = await getLegalParameters(token, user.operator?.id);
            await dispatch(setTotalLegalRedux(response as ILegalParameters));
            setTitleCvs(t("table.neatLegal"));
            setValue(7);
            break;
          case "irrigation":
            response = await getIrrigations(token, user.operator?.id);
            dispatch(setTotalIrrigations(response));
            setTitleCvs(t("table.neatIrrigation"));
            setValue(8);
            break;
          case "bettingHistory":
            response = await getBets(token, user.operator?.id);
            dispatch(setTotalBets(response));
            setTitleCvs(t("table.neatBetHistory"));
            setValue(9);
            break;

          case "bets":
            const betsParams = {
              playerId: player?.data[0].id?.toString(),
            };
            response = await getPlayerBets(token, betsParams);
            dispatch(setTotalBets(response));
            setTitleCvs(t("table.neatBets"));
            setValue(10);
            break;
          case "bonds":
            const bondsParams = {
              playerId: player?.data[0].id?.toString(),
            };
            // @ts-ignore
            response = await getBonusRedeemed(token, bondsParams);
            dispatch(setTotalBonusRedeemedRedux(response));
            setTitleCvs(t("table.neatBets"));
            setValue(11);
            break;
          // TODO: DEMAS COMPONENTES
          default:
            return null;
        }
      }
      response && setLoading(false);
    };
    onGetTotalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseTooltip = () => {
    setTooltipIsOpen((prev: boolean) => !prev);
  };

  return (
    <Card>
      <CardHeader
        classes={{
          title: classes.title,
        }}
        sx={{
          px: 2,
          py: 1,
          backgroundColor: "#02D0E7",
          color: "white",
          fontSize: "13px !important",
          minWidth: "180px",
        }}
        title={
          <Box display="flex" justifyContent="space-between">
            {t("dialog.exportAs")}
            {":"}
            <i className="fa fa-times" onClick={handleCloseTooltip}></i>
          </Box>
        }
      />
      <Divider />
      <List
        sx={{
          bgcolor: "#F4F4F4",
        }}
        dense
        component="div"
        role="list"
      >
        {loadingData ? (
          <Box
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            width="100%"
          >
            <CircularProgress className={"colorLoading"} />
          </Box>
        ) : (
          <>
            {/* EXPORT ALL DATA PDF */}
            {dataTotal && (
              <BlobProvider
                document={
                  <PdfDocument
                    component={component}
                    data={dataTotal}
                    id={value}
                  />
                }
              >
                {({ url, loading }) =>
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  loading || loadingData ? (
                    <Box
                      display={"flex"}
                      justifyContent="center"
                      alignItems={"center"}
                      width="100%"
                      mt={3}
                    >
                      <CircularProgress className={"colorLoading"} />
                    </Box>
                  ) : (
                    url && (
                      <ListItem
                        role="listitem"
                        button
                        onClick={handleCloseTooltip}
                      >
                        <ListItemIcon sx={{ mr: -3, ml: -0.2 }}>
                          <img width={"19px"} src={fileText} alt="File text" />
                        </ListItemIcon>

                        <a
                          href={url}
                          style={{ color: "#333333" }}
                          className={classes.textItem}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("dialog.exportPdf")}
                        </a>
                      </ListItem>
                    )
                  )
                }
              </BlobProvider>
            )}

            {/* JUST EXPORT VIEW PDF */}
            <BlobProvider
              document={
                <PdfDocument
                  component={component}
                  data={exportData}
                  id={value}
                  perView
                />
              }
            >
              {({ url }) =>
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                url && (
                  <ListItem role="listitem" button onClick={handleCloseTooltip}>
                    <ListItemIcon sx={{ mr: -3, ml: -0.2 }}>
                      <img width={"19px"} src={fileText} alt="File text" />
                    </ListItemIcon>
                    <a
                      href={url}
                      style={{ color: "#333333" }}
                      className={classes.textItem}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("dialog.exportViewPdf")}
                    </a>
                  </ListItem>
                )
              }
            </BlobProvider>

            {multiTotalDataSet && (
              <ExcelFile
                element={
                  <ListItem role="listitem" button onClick={handleCloseTooltip}>
                    <ListItemIcon sx={{ mr: -3, ml: -0.2 }}>
                      <img width={"19px"} src={filePlus} alt="File plus" />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ my: 0 }}
                      classes={{
                        primary: classes.textItem,
                      }}
                      primary={t("dialog.exportExcel")}
                    />
                  </ListItem>
                }
                filename={titleCSV}
              >
                <ExcelSheet
                  dataSet={multiTotalDataSet}
                  name={`${t("table.sheet")} 1`}
                />
              </ExcelFile>
            )}
            {multiDataSet && (
              <ExcelFile
                element={
                  <ListItem role="listitem" button onClick={handleCloseTooltip}>
                    <ListItemIcon sx={{ mr: -3, ml: -0.2 }}>
                      <img width={"19px"} src={filePlus} alt="File plus" />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ my: 0 }}
                      classes={{
                        primary: classes.textItem,
                      }}
                      primary={t("dialog.exportViewExcel")}
                    />
                  </ListItem>
                }
                filename={titleCSV}
              >
                <ExcelSheet
                  dataSet={multiDataSet}
                  name={`${t("table.sheet")} 1`}
                />
              </ExcelFile>
            )}
          </>
        )}
      </List>
    </Card>
  );
};
