import React, { FC } from "react";
import { headersAnonymouspayments } from "../../../helpers/headersTables";
import { Text, View } from "@react-pdf/renderer";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import moment from "moment";
interface props {
  page: any[];
}
export const AnonymousPaymentsPdf: FC<props> = ({ page }) => {
  const columns = headersAnonymouspayments;

  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, index) => (
          <View key={index} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>

      <View style={{ flex: 1 }}>
        {page.map((data) => (
          <View key={data.transactionId} style={stylesPdf.row}>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.transactionId}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {moment(data.createAt).format("DD/MM/YYYY")}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {moment(data.createAt).format("HH:mm:ss A")}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {"$ "}
                {parseFloat(data.amount).toFixed(2)}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};
