import React, { FC, useState } from "react";
import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setFlagFormModal } from "../../../../redux/actions/helperActions";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { startChangeStateObservation } from "../../../../redux/actions/managementPlayersActions";
import { IObservationData } from "../../../../services/observationsPlayer";

interface props {
  stateUser: number;
}

export const ObservationForm: FC<props> = ({ stateUser }) => {
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { player } = useSelector((state: AppState) => state.managementPlayers);
  const [observation, setObservation] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleClose = () => {
    setObservation("");
    dispatch(
      setFlagFormModal({
        flagFormModal: false,
      })
    );
  };
  const handleSave = async () => {
    if (!user || !token || !player) {
      throw new Error("INVALID ACTION");
    }

    //change state player
    const newStatePlayer = stateUser;

    setLoading(true);

    const observationData: IObservationData = {
      observation,
      action: newStatePlayer,
      createBy: user?.id,
      player: player.data[0].id as number,
    };
    await dispatch(startChangeStateObservation(observationData));
    setLoading(false);
    handleClose();
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          name="reason"
          fullWidth
          style={{ border: "none", outline: "none" }}
          InputLabelProps={{ shrink: false }}
          multiline
          placeholder={`${t("observations.addObservationTitle")}...`}
          rows={4}
          onChange={(e: any) => setObservation(e.target.value)}
          inputProps={{
            maxLength: 300,
          }}
          disabled={loading}
        />
      </Grid>

      <Grid item xs={12} display={"flex"} justifyContent={"flex-end"} mb={-2}>
        <Button
          data-testid="btnRejectDialog"
          id="btnReject"
          type="button"
          style={{
            border: "none",
            outline: "none",
            backgroundColor: "#FF5757",
          }}
          variant="contained"
          className="ripple"
          onClick={handleClose}
          //disabled={loading}
        >
          {t("button.cancel")}
          <i className="fa fa-circle-xmark ml-2"></i>
        </Button>
        &nbsp;&nbsp;
        <Button
          type="button"
          data-testid="btnConfirmDialog"
          id="btnConfirm"
          style={{
            border: "none",
            outline: "none",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#02D0E7",
          }}
          variant="contained"
          className="ripple"
          onClick={handleSave}
          disabled={loading || !observation.trim()}
        >
          {loading ? (
            <CircularProgress size={"1.6rem"} color="inherit" />
          ) : (
            <>
              {t("button.save")}
              <i className="fa fa-circle-check ml-2"></i>
            </>
          )}
        </Button>
      </Grid>
    </Grid>
  );
};
