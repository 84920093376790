import moment from "moment";
import { useSelector } from "react-redux";
import { buildFullName, getCopyArrayOrObject } from "../../../utils/utils";
import { AppState } from "../../../redux/reducers/rootReducer";
import { headersCashFlowDeposit } from "../../../helpers/headersTables";

export const CashFlowDepositDataTable = (totalData: any[]) => {
  const { data } = useSelector((state: AppState) => state.search);

  const headersCvsStyle = headersCashFlowDeposit.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  // Obtener datos por vista
  const dataCashFlow = data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalCashFlow = totalData ? getCopyArrayOrObject(totalData) : [];
  const buildDataSet = (dataSet: any[]) => {
    return {
      columns: headersCvsStyle,
      data: dataSet.map((data: any) => {
        const fullNamePlayer = buildFullName(
          data.player.firstName,
          data.player.secondName,
          data.player.lastName,
          data.player.secondLastName
        );

        const array = [
          {
            value: data.player.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: "$" + data.amount,
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: data.provider.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: fullNamePlayer,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.email,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.createAt).format("DD/MM/YYYY HH:mm:ss"),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.meta?.transactionReference ?? "",
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    };
  };
  //   DATA TOTAL
  const multiTotalDataCashFlow = [
    {
      ...buildDataSet(dataTotalCashFlow),
    },
  ];
  const multiDataCashFlow = [
    {
      ...buildDataSet(dataCashFlow),
    },
  ];
  const finalData = {
    dataPerView: dataCashFlow,
    dataTotal: dataTotalCashFlow,
    headersCSV: headersCashFlowDeposit,
    multiDataSet: multiDataCashFlow,
    multiTotalDataSet: multiTotalDataCashFlow,
  };
  return finalData;
};
