import React, { FC } from "react";
import { headersCashFlowDeposit } from "../../../helpers/headersTables";
import { Text, View } from "@react-pdf/renderer";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import { buildFullName } from "../../../utils/utils";
import moment from "moment";
interface props {
  page: any[];
}
export const CashFlowDepositPdf: FC<props> = ({ page }) => {
  const columns = headersCashFlowDeposit;

  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, index) => (
          <View key={index} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        {page.map((data) => (
          <View key={data.id} style={stylesPdf.row}>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.player.id}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{"$ " + data.amount}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.provider.name}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {buildFullName(
                  data.player.firstName,
                  data.player.secondName,
                  data.player.lastName,
                  data.player.secondLastName
                )}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.player.email}</Text>
            </View>

            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {moment(data.createAt).format("DD/MM/YYYY HH:mm:ss")}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {data.meta?.transactionReference ?? ""}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};
