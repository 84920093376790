import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { regNumbers } from "../../../utils/utils";
import { MyTextField } from "../../components/custom/MyTextField";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useTranslation } from "react-i18next";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { Roles_Enum } from "../../../enums/enums";
import { getOperatorByIdService } from "../../../services/managementCompanyService";
import * as yup from "yup";
import { startEditOperator } from "../../../redux/actions/managementCompanyActions";

interface IFormValues {
  sesionTimeLimit: string | null;
}

export const SessionLimit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token, user } = useSelector((state: AppState) => state.auth);
  const [operator, setOperator] = useState<any>(null);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const [operatorId, setOperatorId] = useState(
    isSuper ? "" : user?.operator?.id
  );

  const [checking, setChecking] = useState(false);
  const formRef = useRef(null);
  const initialValues: IFormValues = {
    sesionTimeLimit: operator?.sesionTimeLimit || "",
  };

  const generateSelectOptions = () => [
    { value: 1, label: t("playerLimit.minute", { count: 1 }) },
    { value: 60 / 2, label: t("playerLimit.minutes", { count: 30 }) },
    { value: 60 * 1, label: t("playerLimit.hour", { count: 1 }) },
    { value: 60 * 2, label: t("playerLimit.hours", { count: 2 }) },
    { value: 60 * 3, label: t("playerLimit.hours", { count: 3 }) },
    { value: 60 * 5, label: t("playerLimit.hours", { count: 5 }) },
    { value: 0, label: t("playerLimit.unlimited") },
  ];

  useEffect(() => {
    handleGetOperator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetOperator = async () => {
    setChecking(true);
    if (token && operatorId) {
      const operator = await getOperatorByIdService(token, +operatorId);
      if (!operator) {
        setChecking(false);
        dispatch(setMessageAlert("alerts.noData"));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      setOperator(operator);
    }
    setChecking(false);
  };

  const validationSchema = yup.object({
    sesionTimeLimit: yup.string(),
  });

  const handleSetBetLimit = async (data: any) => {
    data.sesionTimeLimit = parseInt(data.sesionTimeLimit);
    operator?.id && (await dispatch(startEditOperator(data, operator.id)));
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} className="white-container" mt={2} ml={2}>
        <Formik
          innerRef={formRef}
          validationSchema={validationSchema}
          validateOnChange={true}
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            const newData = { ...data };
            await handleSetBetLimit(newData);
            setSubmitting(false);
          }}
        >
          {({ handleChange, values, isSubmitting, handleReset, errors }) => (
            <Form>
              {isSuper && (
                <Grid container spacing={1}>
                  <Grid item sm={12} md={7}>
                    <TextField
                      name="operatorId"
                      value={operatorId || ""}
                      label={t("parameterization.operatorId")}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleReset();
                        setOperator(null);
                        setOperatorId(e.target.value as string);
                      }}
                      onKeyPress={(e: any) => {
                        !regNumbers(e.key) && e.preventDefault();
                      }}
                      inputProps={{
                        maxLength: 6,
                      }}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={12} md={5}>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      className="primary-btn ripple"
                      style={{ border: "none", outline: "none" }}
                      onClick={() => {
                        handleReset();
                        setOperator(null);
                        handleGetOperator();
                      }}
                      disabled={!operatorId?.toString().trim() || !!operator}
                    >
                      {checking ? (
                        <CircularProgress size={"1.6rem"} color="inherit" />
                      ) : (
                        t("filters.filter")
                      )}
                    </Button>
                  </Grid>
                </Grid>
              )}

              <Grid
                container
                spacing={2}
                columns={{ xs: 1, sm: 1, md: 1 }}
                mb={5}
                mt={1}
              >
                <Grid item xs={1}>
                  <label className="form-label">
                    {" "}
                    {t("playerLimit.maximumSesionTime")}
                  </label>
                  <MyTextField
                    disabled={isSubmitting || !operatorId}
                    name="sesionTimeLimit"
                    value={values.sesionTimeLimit}
                    label={""}
                    InputLabelProps={{ shrink: false }}
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    select
                  >
                    <MenuItem disabled value=""></MenuItem>
                    {generateSelectOptions().map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </MyTextField>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={
                      isSubmitting ||
                      !!!operator ||
                      !operatorId ||
                      values.sesionTimeLimit === ""
                    }
                    fullWidth
                    className="primary-btn ripple"
                    style={{ border: "none", outline: "none" }}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      t("button.save")
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
