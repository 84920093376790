import { FC } from "react";
import { TableCell } from "@mui/material";
import moment from "moment";
import { StateTransaction } from "../../../../enums/enums";
import { IWithdrawalData } from "../../../../interfaces/withdrawals";
import { calculateTaxValue } from "../../../../utils/utils";

interface Props {
  row: IWithdrawalData;
  component: StateTransaction;
}

export const RowDataTableCashWithdrawals: FC<Props> = ({ row, component }) => {
  return (
    <>
      <TableCell className="word-break">{row.id}</TableCell>
      {component !== StateTransaction.Expired && (
        <TableCell className="word-break">
          {row.player.numberDocument}
        </TableCell>
      )}

      <TableCell className="word-break">{`${row.player.email}`}</TableCell>
      {component === StateTransaction.Expired && (
        <>
          <TableCell className="word-break">
            {moment(row.createAt).add(1, "day").format("DD/MM/YYYY - HH:mm:ss")}
          </TableCell>
          <TableCell align="left">{row.withdrawalValue}</TableCell>
          <TableCell align="left">{row.paidValue}</TableCell>
        </>
      )}

      {component === StateTransaction.Approved && (
        <>
          <TableCell className="word-break">
            {row.approvalDate
              ? moment(row.approvalDate).format("DD/MM/YYYY - HH:mm:ss")
              : ""}
          </TableCell>
          <TableCell align="left">{row.withdrawalValue}</TableCell>
          <TableCell align="left">{row.paidValue}</TableCell>
        </>
      )}
      {component === StateTransaction.Pending && (
        <>
          <TableCell className="word-break">
            {parseFloat(row.taxValue).toFixed(2)}
          </TableCell>
          <TableCell className="word-break">
            {calculateTaxValue(row.withdrawalValue, row.taxValue)}
          </TableCell>
          <TableCell align="left">{row.withdrawalValue}</TableCell>
          <TableCell align="left">{row.paidValue}</TableCell>
          <TableCell className="word-break">
            {moment(row.createAt).format("DD/MM/YYYY - HH:mm:ss")}
          </TableCell>
        </>
      )}
    </>
  );
};
