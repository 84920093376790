import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { headersReporCardsPuntoPago } from "../../../helpers/headersTables";
import { getCopyArrayOrObject } from "../../../utils/utils";
import moment from "moment";

export const ReportCardsDataTable = (totalData: any[]) => {
  const { data } = useSelector((state: AppState) => state.search);

  const headersCvsStyle = headersReporCardsPuntoPago.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });
  const dataReportCard =
    data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalReportCard = totalData ? getCopyArrayOrObject(totalData) : [];
  const buildTotalData = (arr: any[]) => {
    const multiData = [
      {
        columns: headersCvsStyle,
        data: arr.map((data) => {
          const array = [
            {
              value: data.playerId,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.phone,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: moment(data.requestCardDate).format("YYYY-MM-DD HH:mm:ss"),
              style: { alignment: { horizontal: "center" } },
            },
          ];
          return array;
        }),
      },
    ];
    return multiData;
  };
  const multiTotalData = buildTotalData(dataTotalReportCard);
  const multiData = buildTotalData(dataReportCard);
  const finalData = {
    dataPerView: dataReportCard,
    dataTotal: dataTotalReportCard,
    headersCSV: headersCvsStyle,
    multiDataSet: multiData,
    multiTotalDataSet: multiTotalData,
  };
  return finalData;
};
