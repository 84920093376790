import { FC } from "react";
import TableCell from "@mui/material/TableCell";
import moment from "moment";
import { IPlayerActivityData } from "../../../../interfaces/playerActivity";
import { ProvidersString, TransactionTypesDB } from "../../../../enums/enums";
import { useTranslation } from "react-i18next";
import { buildRedName } from "../../../../utils/utils";
interface Props {
  row: IPlayerActivityData;
}

export const RowDataTableActivity: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  const method =
    row.meta?.debitParty?.typeClass &&
      row.transactionType?.id === TransactionTypesDB.RECHARGE
      ? `- ${t(`paymentMethods.${row.meta?.debitParty?.typeClass}`)}`
      : "";
  return (
    <>
      <TableCell align="center" className="word-break">
        {moment(row.createAt).format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
      <TableCell align="center" className="word-break">
        {row.id}
      </TableCell>

      <TableCell align="center" className="word-break">
        {row.transactionType.name + " " + method}
      </TableCell>
      <TableCell align="center" className="word-break">
        {row.transactionStatus.name}
      </TableCell>
      <TableCell align="center" className="word-break">
        ${row.amount}
      </TableCell>
      <TableCell align="center" className="word-break">
        ${row.newBalance}
      </TableCell>
      <TableCell align="center" className="word-break">
        {buildRedName(row)}
      </TableCell>
      <TableCell align="center" className="word-break">
        {row.transactionId && row.provider.name === ProvidersString.ALTENAR
          ? row.transactionId
          : row.meta?.transactionReference || ""}
      </TableCell>
      <TableCell />
    </>
  );
};
