import React, { useState, KeyboardEvent, FC } from "react";
import { useTranslation } from "react-i18next";

import { Grid, TextField, CircularProgress, Button } from "@mui/material";
import { regAddress } from "../../../../utils/utils";

interface Props {
  loading: boolean;
  handleClose: () => void;
  handleAccept: (reason: string) => void;
}

export const FormRejectWithdrawal: FC<Props> = ({
  loading,
  handleAccept,
  handleClose,
}) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState("");
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          name="reason"
          fullWidth
          style={{ border: "none", outline: "none" }}
          InputLabelProps={{ shrink: false }}
          multiline
          placeholder="Ingrese el motivo de rechazo del retiro transaccional ..."
          rows={4}
          onChange={(e: any) => setReason(e.target.value)}
          inputProps={{
            maxLength: 300,
          }}
          onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
            !regAddress(e.key) && e.preventDefault()
          }
        />
      </Grid>

      <Grid item xs={12} display={"flex"} justifyContent={"flex-end"} mb={-2}>
        <Button
          data-testid="btnRejectDialog"
          id="btnReject"
          type="button"
          style={{
            border: "none",
            outline: "none",
            backgroundColor: "#FF5757",
          }}
          variant="contained"
          className="ripple"
          onClick={handleClose}
          disabled={loading}
        >
          {t("button.cancel")}
          <i className="fa fa-circle-xmark ml-2"></i>
        </Button>
        &nbsp;&nbsp;
        <Button
          type="button"
          data-testid="btnConfirmDialog"
          id="btnConfirm"
          style={{
            border: "none",
            outline: "none",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#02D0E7",
          }}
          variant="contained"
          className="ripple"
          onClick={() => handleAccept(reason)}
          disabled={loading || !reason.trim()}
        >
          {loading ? (
            <CircularProgress size={"1.6rem"} color="inherit" />
          ) : (
            <>
              {t("button.reject")}
              <i className="fa fa-circle-check ml-2"></i>
            </>
          )}
        </Button>
      </Grid>
    </Grid>
  );
};
