import { useEffect, useState, FC, Dispatch, SetStateAction } from "react";
import { Grid, ThemeProvider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../custom/customStyles";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  setActiveStep,
  setCloseAlert,
  setFlagForm,
} from "../../../redux/actions/helperActions";
import { MyDialog } from "../custom/MyDialog";
import { MyAlert } from "../custom/MyAlert";
import {
  setActiveLot,
  startCreateLot,
} from "../../../redux/actions/managementCardsActions";
import { ICardsListCreate } from "../../../interfaces/cards";
import { FormCreateLots } from "./FormCreateLots";
import "./formCreateCards.scss";
import { ConfirmLotCards } from "./ConfirmLotCards";
import { Roles_Enum, State } from "../../../enums/enums";
import { getOperatorsService } from "../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../redux/actions/managementCompanyActions";
import { AssingAdminCards } from "./AssingAdminCards";
import { arraySearch } from "../../../redux/actions/searchActions";

interface Props {
  setNextStep: Dispatch<SetStateAction<boolean>>;
}

export const Modules: FC<Props> = ({ setNextStep }) => {
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeStep, openAlert, messageAlert, typeAlert } = useSelector(
    (state: AppState) => state.helper
  );
  const [cancel, setCancel] = useState(false);
  const [message, setMessage] = useState("");
  const [resetForm, setResetForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [lotData, setLotData] = useState<
    Omit<ICardsListCreate, "id" | "operator" | "currency" | "dealers">
  >({
    amount: "",
    value: "",
    bonusCodes: [],
    extLot: false,
  });

  const [admin, setAdmin] = useState<number | null>(null);
  const [resetFunction, setResetFunction] = useState<any>(null);
  const [operator, setOperator] = useState(
    user?.operator ? user.operator.id : null
  );

  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;

  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    if (isSuper && token) {
      fetchOperators(token);
    }
    return () => {
      dispatch(setCompaniesRedux(null));
      dispatch(arraySearch(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCleanForm = (callback?: any) => {
    setMessage("dialog.sureClean");
    setResetForm(true);
    setOpenDialog(true);
    setResetFunction(() => callback);
  };

  const handleCancel = () => {
    setMessage("dialog.sureGoBackMenu");
    setCancel(true);
    setOpenDialog(true);
  };

  const handleContinue = () => {
    dispatch(setActiveStep(activeStep + 1));
  };

  const handlePrevious = () => {
    dispatch(setActiveStep(activeStep - 1));
  };

  const saveCard = async () => {
    setLoading(true);
    /// ARREGLAR CODIGO PARA QUE NO MANDE LOS DEALERS
    const data = {
      value: parseInt(lotData?.value),
      operator,
      bonusCodes: lotData.bonusCodes,
      amount: parseInt(lotData?.amount),
      admin,
      currency: 2,
      extLot: lotData.extLot,
    };

    await dispatch(startCreateLot(data));

    setLoading(false);
    dispatch(setFlagForm({ flagFormCreate: false }));
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCancel(false);
  };

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
    if (typeAlert === "success") {
      dispatch(setFlagForm({ flagFormCreate: false }));
      dispatch(setActiveLot(null));
    }
  };

  const handleAccept = async () => {
    handleCloseDialog();

    if (resetForm && !cancel) {
      setNextStep(false);
      return handleCleanFields();
    }

    if (cancel) {
      dispatch(setFlagForm({ flagFormCreate: false }));
    }
    setCancel(false);
    setResetForm(false);
  };

  const handleCleanFields = () => {
    if (activeStep === 0) {
      setLotData({
        amount: "",
        value: "",
        bonusCodes: [],
        extLot: false,
      });
      if (resetFunction) {
        resetFunction();
      }
    }
  };

  // Efecto para manejar el next button
  useEffect(() => {
    const validateNextStep = async () => {
      setNextStep(true);
    };
    validateNextStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  return (
    <div className="ml-4 mr-4">
      <div className="filter pl-5">
        <ThemeProvider theme={theme}>
          <Grid container spacing={2}>
            {/* ********************************** */}
            {/*            Create Lot              */}
            {/* ********************************** */}

            {activeStep === 0 && (
              <FormCreateLots
                lotData={lotData}
                setLotData={setLotData}
                handleCancel={handleCancel}
                handleContinue={handleContinue}
                handleCleanForm={(reset) => handleCleanForm(reset)}
                operator={operator}
                setOperator={setOperator}
              />
            )}

            {/* ********************************** */}
            {/*           Assign Admin owner         */}
            {/* ********************************** */}
            {activeStep === 1 && (
              <AssingAdminCards
                admin={admin ?? null}
                setAdmin={setAdmin}
                operator={operator}
                handleCancel={handleCancel}
                handleContinue={handleContinue}
                handleCleanForm={handleCleanForm}
                handlePrevious={handlePrevious}
              />
            )}

            {/* ********************************** */}
            {/*                Confirm Data        */}
            {/* ********************************** */}
            {activeStep === 2 && (
              <ConfirmLotCards
                handleCancel={handleCancel}
                handleSave={saveCard}
                handleCleanForm={handleCleanForm}
                handlePrevious={handlePrevious}
                lotData={lotData}
                admin={admin}
                loading={loading}
              />
            )}
          </Grid>
          <MyDialog
            open={openDialog}
            title={t("dialog.confirmationWindow")}
            message={t(message)}
            handleAccept={handleAccept}
            handleClose={handleCloseDialog}
            isLoading={!!loading}
          />

          <MyAlert
            open={openAlert}
            message={messageAlert ? messageAlert : ""}
            typeAlert={typeAlert}
            handleClose={onCloseAlert}
          />
        </ThemeProvider>
      </div>
    </div>
  );
};
