import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import { MyAccountSettings } from "../../components/account/MyAccountSettings";

export const MyAccount = () => {
  const { t } = useTranslation();

  return (
    <div className="content">
      <Col lg={12} md={12} sm={12}>
        <Row style={{ marginBottom: 5 }}>
          <Typography color="text.primary" style={{ fontSize: "16px" }}>
            {`${t("sidebar.settings")} / ${t("menu.account")} `}
          </Typography>
        </Row>
        <Row>
          <MyAccountSettings />
        </Row>
      </Col>
    </div>
  );
};
