import React, { FC, useState, useEffect } from "react";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MyCustomDialog } from "../../../components/Custom/MyCustomDialog";
import Filter from "../../../components/Filter/Filter";
import { Enum_FieldType, IFilterField } from "../../../interfaces/components";
import {
  asyncLoadingFunction,
  setDocumentTypes,
  setDocumentTypesRedux,
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { FormCreateTransactionSet } from "../../components/transactions/FormCreateTransactionSet";
import { StateTransaction } from "../../../enums/enums";
import { ITransactionSettingParams } from "../../../interfaces/transactionSettings";
import { getMovementTypes } from "../../../services/movementTypes";
import { getSettingTypes } from "../../../services/settingTypes";
import {
  getTransactionSettings,
  getWallets,
} from "../../../services/transactionSettings";
import {
  setMovementTypes,
  setSettingTypes,
  setTotalSettings,
  setWallets,
  startGetTransactionSetting,
} from "../../../redux/actions/transactionsActions";
import MyCustomTable, {
  IColumns,
} from "../../../components/Custom/Table/MyCustomTable";

import { RowDataTableTransactions } from "../../components/transactions/RowDataTableTransactions";
import { TransactionsDataTable } from "../../../hooks/useDataTable/transactionSettings/TransactionsDataTable";
import TransactionSettingsPdf from "../../../components/Pdf/transactions/TransactionSettingsPdf";
import { Enum_rol } from "../../../interfaces/menu";
interface props {
  enableCreated: boolean;
}

export const TransactionSettings: FC<props> = ({ enableCreated }) => {
  const { t } = useTranslation();
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { flagFormCreate, rowsPerPage } = useSelector(
    (state: AppState) => state.helper
  );
  const [dataFilter, setDataFilter] = useState<any>({});
  const [isFilter, setIsFilter] = useState(false);
  const [resetPage, setResetPage] = useState(false);

  const { settings, movementTypes, settingTypes, wallets } = useSelector(
    (state: AppState) => state.transactions
  );
  const dispatch = useDispatch();

  const fetchMovementType = async () => {
    if (!token) {
      throw new Error("Invalid action");
    }
    try {
      const { data } = await getMovementTypes(token);
      dispatch(setMovementTypes(data));
    } catch (error) {
      throw new Error(error as any);
    }
  };
  const fetchSettingTypes = async () => {
    if (!token) {
      throw new Error("Invalid action");
    }
    try {
      const { data } = await getSettingTypes(token);
      dispatch(setSettingTypes(data));
    } catch (error) {
      throw new Error(error as any);
    }
  };
  const fetchWallets = async () => {
    if (!token) {
      throw new Error("Invalid action");
    }
    try {
      const { data } = await getWallets(token);
      dispatch(setWallets(data));
    } catch (error) {
      throw new Error(error as any);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      await fetchMovementType();
      await fetchSettingTypes();
      await fetchWallets();
      await dispatch(startGetTransactionSetting({ page: 1, isSetting: true }));
    };
    dispatch(asyncLoadingFunction(fetchData));
    fetchData();
    return () => {
      dispatch(setTotalSettings(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (settings && settings.data.length === 0) {
      dispatch(
        setMessageAlert(isFilter ? "alerts.noData" : "alerts.noRecords")
      );
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const filterFields: IFilterField[] = [
    {
      type: Enum_FieldType.NUMBER,
      label: t("transactions.setId"),
    },
    {
      label: t("form.state"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("state.approved"),
          value: StateTransaction.Approved,
        },
        {
          label: t("state.pending"),
          value: StateTransaction.Pending,
        },
        {
          label: t("state.refused"),
          value: StateTransaction.Refused,
        },
      ],
    },
    {
      type: Enum_FieldType.NUMBER,
      label: `${t("sidebar.player")} id`,
    },
    {
      label: t("transactions.movementType"),
      type: Enum_FieldType.SELECT,
      options: [
        ...movementTypes.map((movement) => ({
          label: movement.name,
          value: movement.id,
        })),
      ],
    },
    {
      label: t("transactions.type"),
      type: Enum_FieldType.SELECT,
      options: [
        ...settingTypes.map((type) => ({
          label: type.name,
          value: type.id,
        })),
      ],
    },
    {
      label: t("transactions.wallet"),
      type: Enum_FieldType.SELECT,
      options: [
        ...wallets.map((type) => ({
          label: type.name,
          value: type.id,
        })),
      ],
    },

    {
      type: Enum_FieldType.NUMBER,
      label: t("transactions.value"),
    },
    {
      label: t("user.createAt"),
      type: Enum_FieldType.DATE,
    },
  ];

  const columns: IColumns[] = [
    { align: "center", label: t("transactions.setId") },
    { align: "center", label: t("form.state") },
    { align: "center", label: t("sidebar.player") + " ID" },
    { align: "center", label: t("transactions.movementType") },
    { align: "center", label: t("transactions.setType") },
    { align: "center", label: t("transactions.wallet") },
    { align: "center", label: t("transactions.value") },
    { align: "center", label: t("user.createAt") },
  ];

  const handleShowForm = () => {
    dispatch(setFlagForm({ flagFormCreate: true }));
  };
  const handleCloseForm = () => {
    dispatch(setFlagForm({ flagFormCreate: false }));
  };

  const handleFilter = async (values: any) => {
    setResetPage(false);
    const params: ITransactionSettingParams = {
      id: values[t("transactions.setId")],
      state: values[t("form.state")],
      movementType: values[t("transactions.movementType")],
      settingtype: values[t("transactions.type")],
      value: values[t("transactions.value")],
      player: values[`${t("sidebar.player")} id`],
      wallet: values[t("transactions.wallet")],
      page: 1,
      creationDate: values[t("user.createAt")],
      isSetting: true,
    };
    dispatch(
      asyncLoadingFunction(() => dispatch(startGetTransactionSetting(params)))
    );
    setDataFilter(params);
    setIsFilter(true);
    setResetPage(true);
  };
  const onCleanFilter = async () => {
    setResetPage(false);
    dispatch(
      asyncLoadingFunction(() =>
        dispatch(startGetTransactionSetting({ page: 1, isSetting: true }))
      )
    );
    setDataFilter({});
    setResetPage(true);
    setIsFilter(false);
  };

  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startGetTransactionSetting({
            ...dataFilter,
            page: page,
            perPage: rows || rowsPerPage,
            isSetting: true,
          })
        )
      )
    );
    setResetPage(false);
  };

  useEffect(() => {
    token && dispatch(setDocumentTypes(token));

    return () => {
      dispatch(setDocumentTypesRedux([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getAllTransactionSettings = async (_: any) => {
    if (token && user) {
      const params: any = {
        isSetting: true,
      };
      if (user.roles.name !== Enum_rol.SUPER_ADMIN) {
        params.operatorId = user.operator.id;
      }
      return await getTransactionSettings(token, params);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className="filter m-2">
          <Filter
            fields={filterFields}
            setDataFilter={setDataFilter}
            handleFilter={handleFilter}
            onClean={onCleanFilter}
          />
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className="white-container">
          <Grid container>
            <Grid item xs={11} />
            {enableCreated && (
              <Grid item xs={1}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  mb={2}
                >
                  <Box display="flex" alignItems="center">
                    <p
                      className="txt-primary-md"
                      style={{
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        alignSelf: "center",
                      }}
                    >
                      {t("transactions.new")}
                    </p>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={handleShowForm}
                      id="openFormBtn"
                      style={{ border: "none", outline: "none" }}
                    >
                      <span className="btn-add">
                        <i
                          className="fa-solid fa-plus"
                          style={{ fontSize: 30 }}
                        ></i>
                      </span>
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>

          <MyCustomTable
            columns={columns}
            data={settings ? settings.data : []}
            lastPage={settings ? settings.last_page : 1}
            totalData={settings ? settings.total : 0}
            renderBody={(item) => <RowDataTableTransactions row={item} />}
            onPageChange={handlePageChange}
            isSortingTable
            exportData
            getAllDataToExport={getAllTransactionSettings}
            hookBuildTable={TransactionsDataTable}
            pdfComponent={TransactionSettingsPdf}
            titleCSV={t("transactions.settings")}
            actions={{
              see: false,
              add: false,
              delete: false,
              edit: false,
              seeMore: false,
            }}
            resetCurrentPage={resetPage}
          />
        </div>
      </Grid>

      <MyCustomDialog
        open={!!flagFormCreate}
        content={<FormCreateTransactionSet />}
        onClose={handleCloseForm}
        title={t("notifications.newAdjustment")}
      />
    </Grid>
  );
};
