import React, { ChangeEvent, useEffect } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  ClassNameMap,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getPayMethods } from "../redux/actions/managementPayMethodsActions";
import { AppState } from "../redux/reducers/rootReducer";
import { MenuProps } from "../views/components/custom/bonusStyles";
import { useTranslation } from "react-i18next";

interface PayMethodsSelectProps {
  formikRef: any;
  classes: ClassNameMap<
    "infoIcon" | "title" | "titleBox" | "formControl" | "errorLabel"
  >;
  resetSelection?: boolean;
}

const PayMethodsSelect: React.FC<PayMethodsSelectProps> = ({
  formikRef,
  classes,
  resetSelection,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { payMethods } = useSelector(
    (state: AppState) => state.managementPayMethods
  );
  useEffect(() => {
    dispatch(getPayMethods());
  }, [dispatch]);

  useEffect(() => {
    if (resetSelection && formikRef.current) {
      formikRef.current.setFieldValue("payMethods", []);
    }
  }, [resetSelection, formikRef]);

  const handlePayMethodChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (formikRef.current) {
      formikRef.current.setFieldValue("payMethods", value);
    }
  };

  return (
    <Grid item xs={6}>
      <FormControl className={classes.formControl} size="small">
        <InputLabel id="operator-label">
          {t("managementBonds.payLabel")}
        </InputLabel>
        <Select
          labelId="operator-label"
          name="operator"
          size="small"
          fullWidth
          multiple
          value={
            Array.isArray(formikRef.current.values.payMethods)
              ? formikRef.current.values.payMethods
              : []
          }
          onChange={(e: any) => handlePayMethodChange(e)}
          input={<OutlinedInput label={t("managementBonds.payLabel")} />}
          MenuProps={MenuProps}
        >
          {payMethods.map((item: any, key: any) => (
            <MenuItem key={key} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default PayMethodsSelect;
