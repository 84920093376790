import { IAnswer, IPlayerData } from "./../../interfaces/users";
import {
  createObservationService,
  editPlayerService,
  filterPlayers,
  filterRefills,
  getExclusionCategories,
  getPlayerObservations,
  getRefillsService,
  resetSecurityQuestionService,
  updateObservationService,
} from "./../../services/managementPlayersService";
import { IFullDetailPlayer } from "../../interfaces/users";
import {
  getFullDetailPlayer,
  getExclusions,
} from "../../services/managementPlayersService";
import { Types } from "../types/types";
import {
  setFlagForm,
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";
import { uiSetError, uiSetErrorMessage, uiSetOpenDialog } from "./uiAction";
import { IRefillsData } from "../../interfaces/refills";
import { AppState } from "../reducers/rootReducer";
import {
  IExclusionsData,
  IExclusion,
  IExclusionCategory,
} from "../../interfaces/exclusions";
import {
  createEXclusionService,
  updateExclusionService,
} from "../../services/managementPlayersService";
import { IPaginationData } from "../../interfaces/shared";
import { ExclusionType, State } from "../../enums/enums";
import {
  IObservationsData,
  IPlayerUpdateHistoryData,
  IQueryPlayerHistory,
} from "../../interfaces/players";
import { getBonusRedeemed } from "../../services/bonus";
import { IBonusRedeemed } from "../../interfaces/bonus";
import { arraySearch } from "./searchActions";
import {
  playerSessionReport,
  playerExlusionsReport,
  playerTransactionsReport,
} from "../../services/reports";
import {
  IObservationData,
  createObservationsPlayerState,
} from "../../services/observationsPlayer";
import { socket } from "../../helpers/socket";
import {
  createPlayerUpdate,
  getPlayerHistory,
} from "../../services/playersService";
import { buildFinalQueryParams } from "../../utils/utils";

/**
 * GET player info back
 */
export const getInfoPlayer = (token: string, operatorId: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    try {
      dispatch(setLoadingState({ loading: true }));
      const response =
        user && (await getFullDetailPlayer(token, operatorId, user.roles.name));

      if (response) {
        const data: IFullDetailPlayer = response;
        dispatch(setPlayerRedux(data));
      } else {
        dispatch(uiSetError(true));
        dispatch(uiSetErrorMessage("alerts.errorSetPlayer"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const startFilteringPlayers = (formValues: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    try {
      if (!!user && token) {
        let finalValues: any = {};
        Object.entries(formValues).forEach(([key, value], index) => {
          //@ts-ignore
          if (value.length > 0) {
            //@ts-ignore
            finalValues[key] = value;
          }
        });

        dispatch(setLoadingState({ loading: true }));
        const response = await filterPlayers(
          token,
          finalValues,
          user.roles.name,
          user.operator?.id
        );

        if (response) {
          if (response.error) {
            dispatch(setOpenAlert());
            dispatch(setMessageAlert("alerts.invalidSyntax"));
            dispatch(setTypeAlert("error"));
            dispatch(setPlayerRedux(null));
          } else {
            if (response.data.length === 0) {
              dispatch(setOpenAlert());
              dispatch(setMessageAlert("alerts.noData"));
              dispatch(setTypeAlert("error"));
            } else {
              dispatch(setPlayerRedux(response));
            }
          }
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetPlayers"));
          dispatch(setTypeAlert("error"));
          dispatch(setPlayerRedux(null));
        }

        dispatch(setLoadingState({ loading: false }));
      }
    } catch (error) {
      console.log(error);
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const setPlayerRedux = (player: IFullDetailPlayer | null) => ({
  type: Types.setPlayer,
  payload: player,
});

/**
 * GET recargas back
 */
export const getRefills = (token: string, page: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { user } = (getState() as AppState).auth;

    try {
      dispatch(setLoadingState({ loading: true }));
      const response =
        user &&
        (await getRefillsService(
          token,
          user.id,
          page,
          rowsPerPage,
          user.operator?.id
        ));

      if (response && response.data) {
        if (response.data.length > 0) {
          const data: IRefillsData = response;
          dispatch(setRefillsRedux(data));
        } else {
          dispatch(setMessageAlert("alerts.noData"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        }
      } else {
        dispatch(uiSetError(true));
        dispatch(uiSetErrorMessage("alerts.errorSetPlayer"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const setRefillsRedux = (refills: IRefillsData | null) => ({
  type: Types.setRefills,
  payload: refills,
});

export const setTotalRefillsRedux = (refills: IRefillsData | null) => ({
  type: Types.setTotalRefills,
  payload: refills,
});

export const startFilteringRefills = (formValues: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;

    if (formValues.page || rowsPerPage) {
      formValues.page = formValues.page.toString();
      formValues.perPage = rowsPerPage.toString();
    }
    if (!!user && token) {
      let finalValues: any = {};
      Object.entries(formValues).forEach(([key, value], index) => {
        //@ts-ignore
        if (value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });
      const response = await filterRefills(
        token,
        finalValues,
        user.id,
        user.operator?.id
      );
      if (response && response.data && response.data.length > 0) {
        dispatch(setRefillsRedux(response));
      } else {
        dispatch(setRefillsRedux(response.data ? response : null));
        dispatch(setMessageAlert("alerts.noData"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    }
  };
};

/**
 *
 * @param player
 * @returns EDIT PLAYER, REDUX AND DATABASE
 */

export const editPlayerRedux = (player: IPlayerData) => ({
  type: Types.updatePlayer,
  payload: player,
});

export const editPlayer = (data: Partial<IPlayerData>) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { openDialog } = (getState() as AppState).ui;

    const response =
      token && data.id && (await editPlayerService(data, token, data.id));

    if (response) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.informationSentSuccess"));
      dispatch(setTypeAlert("success"));

      openDialog && dispatch(uiSetOpenDialog());
      dispatch(editPlayerRedux(data as any));
    } else {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.editObservationPlayerError"));
      dispatch(setTypeAlert("error"));
    }
  };
};

export const resetSecurityQuestionsPlayer = (data: IPlayerData) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    dispatch(uiSetOpenDialog());
    const rs = await Promise.all(
      data.answersPlayers.map(async (answer: IAnswer) => {
        return (
          token &&
          answer.id &&
          (await resetSecurityQuestionService(token, answer.id))
        );
      })
    );

    if (rs) {
      data.answersPlayers = null;
      dispatch(editPlayerRedux(data));
    } else {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.resetSecurityQuestionsError"));
      dispatch(setTypeAlert("error"));
    }
  };
};

export const startGetExclusions = (params: any, noPlayer?: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    const { player } = (getState() as AppState).managementPlayers;
    try {
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      params.perPage = rowsPerPage;
      const response = await getExclusions(token, params);

      if (response && !response.error) {
        if (response.data.length === 0 && (noPlayer || player)) {
          dispatch(setOpenAlert());
          dispatch(
            setMessageAlert(
              player ? "exclusions.noPlayerExclusions" : "alerts.noData"
            )
          );
          dispatch(setTypeAlert("error"));
        }
        const data: IExclusionsData[] = response;
        dispatch(setExclusionsRedux(data));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorSetExclusions"));
        dispatch(setTypeAlert("error"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const setExclusionsRedux = (exclusions: IExclusionsData[] | null) => ({
  type: Types.setExclusions,
  payload: exclusions,
});

export const createNewExclusion = (
  token: string,
  data: IExclusion,
  agileCheck: boolean,
  reset: Function | null,
  setCreatedExclusion?: any
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    const { player } = (getState() as AppState).managementPlayers;
    const dataCopy = { ...data };
    dataCopy.startDate = data.startDate + "T00:00:00.000Z";
    dataCopy.endDate = data.endDate ? data.endDate + "T23:59:59.000Z" : null;

    const response = await createEXclusionService(token, dataCopy);

    if (response && !response.error) {
      const params: any = {
        page: 1,
        type: agileCheck ? ExclusionType.AGILE_CHECK : ExclusionType.CATEGORY,
      };
      if (agileCheck) {
        setCreatedExclusion(true);
      } else {
        params.playerId = player?.data[0].id;
      }
      if (user?.operator) {
        params.operatorId = user.operator.id;
      }

      token && (await dispatch(startGetExclusions(params)));

      dispatch(setOpenAlert());
      dispatch(setMessageAlert(`exclusions.${response.message}`));
      dispatch(setTypeAlert("success"));
      reset && reset();
      dispatch(setFlagForm({ flagFormCreate: false }));
      agileCheck && setCreatedExclusion(false);
    } else {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert(`exclusions.${response.errorMsg}`));
      dispatch(setTypeAlert("error"));
    }
  };
};

export const addExclusionRedux = (
  exclusion: IExclusion,
  rowsPerPage: number
) => ({
  type: Types.addExclusion,
  payload: { exclusion, rowsPerPage },
});

export const startUpdateStateExclusion = (
  idExclusion: number,
  state: number
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    try {
      const data = { state };
      if (!!user && token) {
        const response = await updateExclusionService(token, idExclusion, data);
        if (!response || response.error) {
          dispatch(setOpenAlert());
          dispatch(
            setMessageAlert(`exclusions.${response.error.errorMessage}`)
          );
          dispatch(setTypeAlert("error"));
          throw new Error(response.error);
        } else {
          if (response.rs.affected === 1) {
            dispatch(updateExclusion(idExclusion, data));
            dispatch(setOpenAlert());
            dispatch(setMessageAlert(`exclusions.userSuccessfullyActivated`));
            dispatch(setTypeAlert("success"));
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateExclusion = (
  idExclusion: number,
  data: Partial<IExclusion>
) => ({
  type: Types.updateExclusion,
  payload: { id: idExclusion, data },
});

export const startGetExclusionCategories = () => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    try {
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      const rs = await getExclusionCategories(token);

      if (rs && !rs.error) {
        dispatch(
          setExclusionCategories(rs as IPaginationData<IExclusionCategory>)
        );
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorSetExclusionCategories"));
        dispatch(setTypeAlert("error"));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const setExclusionCategories = (
  categories: IPaginationData<IExclusionCategory> | null
) => ({
  type: Types.setExclusionCategories,
  payload: categories,
});

export const setObservationsRedux = (
  observations: IPaginationData<IObservationsData> | null
) => ({
  type: Types.setObservations,
  payload: observations,
});

export const setActiveObservation = (
  observation: IObservationsData | null
) => ({
  type: Types.setActiveObservation,
  payload: observation,
});

export const startGetObservations = (params: any, cleanFilter?: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    if (!token || !user) {
      throw new Error("INVALID ACTION");
    }

    if (params.page || rowsPerPage) {
      params.page = params.page.toString();
      params.perPage = rowsPerPage.toString();
    }
    try {
      let finalValues: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (value && value.length > 0) {
          finalValues[key] = value;
        }
      });

      const response = await getPlayerObservations(token, finalValues);

      if (response && !response.error) {
        if (response.data.length === 0 && !cleanFilter) {
          dispatch(setMessageAlert("alerts.noData"));
          dispatch(setTypeAlert("warning"));
          dispatch(setOpenAlert());
          dispatch(setObservationsRedux(null));
        } else {
          dispatch(setObservationsRedux(response));
        }
      } else {
        dispatch(setMessageAlert("alerts.errorSetBets"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const createNewObservation = (data: IObservationsData) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { player } = (getState() as AppState).managementPlayers;

    if (!token || !user || !player) {
      throw new Error("INVALID ACTION");
    }

    const response = await createObservationService(token, data);

    if (response && !response.error) {
      const params: any = {
        page: 1,
        playerId: player.data[0].id?.toString(),
      };

      await dispatch(startGetObservations(params));

      dispatch(setMessageAlert(`observations.${response.message}`));
      dispatch(setTypeAlert("success"));
      dispatch(setOpenAlert());
      dispatch(setFlagForm({ flagFormCreate: false }));
    } else {
      dispatch(setMessageAlert(`observations.${response.error.errorMessage}`));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startUpdateObservation = (
  data: IObservationsData,
  isTableAction?: boolean,
  isFilterByState?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { activeObservation, observations, player } = (getState() as AppState)
      .managementPlayers;
    const { rowsPerPage } = (getState() as AppState).helper;

    if (
      !user ||
      !token ||
      !activeObservation ||
      !activeObservation.id ||
      !player
    ) {
      throw new Error("NOT AVAILABLE ACTION");
    }

    try {
      const response = await updateObservationService(
        token,
        activeObservation.id,
        data
      );

      if (response && !response.error) {
        if (isTableAction) {
          if (observations) {
            const newData = { ...observations };
            newData.data =
              newData.data &&
              (isFilterByState
                ? newData.data.filter((el: any) => el.id !== data.id)
                : newData.data.map((el: any) =>
                    el.id === data.id ? data : el
                  ));

            newData.total = isFilterByState ? newData.total - 1 : newData.total;
            newData.last_page = isFilterByState
              ? Math.ceil((newData.total - 1) / rowsPerPage)
              : newData.last_page;

            dispatch(setObservationsRedux(newData));
            dispatch(setActiveObservation(null));
          }
        } else {
          const params: any = {
            page: 1,
            playerId: player.data[0].id?.toString(),
          };
          await dispatch(startGetObservations(params));
        }

        dispatch(setMessageAlert(`observations.${response.message}`));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
        dispatch(setFlagForm({ flagFormCreate: false }));
      } else {
        dispatch(setLoadingState({ loading: false }));
        dispatch(
          setMessageAlert(`observations.${response.error.errorMessage}`)
        );
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      console.log(error);
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const startGetBonusRedeemedPlayer = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    if (!token || !user) {
      throw new Error("INVALID ACTION");
    }

    if (params.page) {
      params.page = params.page.toString();
      params.perPage = rowsPerPage.toString();
    }
    try {
      let finalValues: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (value && value.length > 0) {
          finalValues[key] = value;
        }
      });

      const response = await getBonusRedeemed(token, finalValues);

      if (response && !response.error) {
        if (response.data.length === 0) {
          dispatch(setMessageAlert("alerts.playerNotBonus"));
          dispatch(setTypeAlert("warning"));
          dispatch(setOpenAlert());
          dispatch(setBonusRedeemedRedux(null));
        } else {
          dispatch(setBonusRedeemedRedux(response));
        }
      } else {
        dispatch(setMessageAlert("alerts.errorSetBonus"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.errorSetBonus"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const setBonusRedeemedRedux = (
  bonus: IPaginationData<IBonusRedeemed> | null
) => ({
  type: Types.setBonusRedeemedByPlayer,
  payload: bonus,
});

export const setTotalBonusRedeemedRedux = (
  bonus: IPaginationData<IBonusRedeemed> | null
) => ({
  type: Types.setTotalBonusRedeemedByPlayer,
  payload: bonus,
});

export const startGetPlayerSessions = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    try {
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      if (params.page) {
        params.perPage = rowsPerPage;
      }
      if (user?.operator?.id) {
        params.operatorId = user.operator.id;
      }

      let finalValues: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (value !== undefined && value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });
      const response = await playerSessionReport(token, params);

      if (response && !response.error) {
        if (response.data?.length === 0) {
          dispatch(arraySearch(null));
          dispatch(setMessageAlert("alerts.noData"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        } else {
          dispatch(arraySearch(response));
        }
      } else {
        dispatch(setMessageAlert("alerts.errorSetSessionReport"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.errorSetSessionReport"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startGetPlayerExclusions = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    try {
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      if (params.page) {
        params.perPage = rowsPerPage;
      }
      if (user?.operator?.id) {
        params.operatorId = user.operator.id;
      }

      let finalValues: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (value !== undefined && value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });
      const response = await playerExlusionsReport(token, params);

      if (response && !response.error) {
        if (response.data?.length === 0) {
          dispatch(arraySearch(null));
          dispatch(setMessageAlert("alerts.noData"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        } else {
          dispatch(arraySearch(response));
        }
      } else {
        dispatch(setMessageAlert("alerts.errorSetTransactionsReport"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.errorSetTransactionsReport"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startGetPlayerTransactions = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    try {
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      if (params.page) {
        params.perPage = rowsPerPage;
      }
      if (user?.operator?.id) {
        params.operatorId = user.operator.id;
      }

      let finalValues: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (value !== undefined && value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });
      const response = await playerTransactionsReport(token, params);

      if (response && !response.error) {
        if (response.data?.length === 0) {
          dispatch(arraySearch(null));
          dispatch(setMessageAlert("alerts.noData"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        } else {
          dispatch(arraySearch(response));
        }
      } else {
        dispatch(setMessageAlert("alerts.errorSetExclusionsReport"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.errorSetExclusionsReport"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startChangeStateObservation = (data: IObservationData) => {
  return async (dispatch: Function, getState: Function) => {
    const { player } = (getState() as AppState).managementPlayers;
    const { token, user } = (getState() as AppState).auth;
    if (!user || !token || !player) {
      throw new Error("INVALID ACTION");
    }
    const newStatePlayer = data.action;
    //change state player
    const updateObj: any = {
      id: player.data[0].id,
      stateUser: data.action,
    };

    //save observation
    try {
      await editPlayerService(updateObj, token, updateObj.id);

      await createObservationsPlayerState(token, data);

      await dispatch(
        startFilteringPlayers({ id: player?.data[0].id?.toString() })
      );
      //CLOSE PLAYER SESSION
      if (newStatePlayer === State.INACTIVE) {
        socket.emit("Admin/ClosePlayerSession", {
          id: updateObj.id,
          inactivate: true,
        });
      }

      dispatch(setMessageAlert(`player.changeStateSucces`));
      dispatch(setTypeAlert("success"));
      dispatch(setOpenAlert());
      dispatch(setFlagForm({ flagFormCreate: false }));
    } catch (error) {
      dispatch(setMessageAlert("player.changeStateError"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startCreatePlayerUpdate = (data: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { player } = (getState() as AppState).managementPlayers;
    if (!token || !player) {
      throw new Error("INVALID ACTION");
    }
    try {
      data.responsible = user?.id;
      if (!player.data || !player.data[0]) {
        throw new Error("Player data is invalid");
      }
      // console.log("🚀 ~ return ~ data:", data);
      // const castedData = castedPlayerUpdateData(player.data[0], data);
      // console.log("🚀 ~ return ~ castedData:", castedData);

      const response = await createPlayerUpdate(token, data);
      if (response && !response.error) {
        dispatch(setMessageAlert("alerts.informationSentSuccess"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
        return { status: "ok" };
      } else {
        dispatch(
          setMessageAlert(
            `alerts.${response.error?.errorMessage ?? "updateError"}`
          )
        );
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
        return { status: "error" };
      }
    } catch (error: any) {
      const errorMessage =
        `alerts.${error.response?.data?.message}` ||
        "Ocurrió un error durante la actualización";

      dispatch(setMessageAlert(errorMessage));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
      return { status: "error" };
    }
  };
};

export const startGetPlayerHistory = (params: Partial<IQueryPlayerHistory>) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;

    if (!token) {
      throw new Error("INVALID ACTION");
    }
    try {
      if (params.page) {
        params.perPage = rowsPerPage;
      }
      const finalParams = buildFinalQueryParams(params);
      const rs = await getPlayerHistory(token, finalParams);

      dispatch(setPlayerHistory(rs));
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
    }
  };
};

export const setPlayerHistory = (
  data: IPaginationData<IPlayerUpdateHistoryData> | null
) => ({
  type: Types.setPlayerUpdateHistory,
  payload: data,
});
