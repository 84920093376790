import { View, Text } from "@react-pdf/renderer";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { stylesPdf } from "../../../views/components/custom/customStyles";

interface Props {
  page: any;
}

export const ProgressiveJackpotWonDocument: FC<Props> = ({ page }) => {
  const { t } = useTranslation();
  const columnsMenus = [
    t("reports.progressiveJackpotName").toUpperCase(),
    t("reports.gamePayoutsId").toUpperCase(),
    t("reports.playerSessionId").toUpperCase(),
    t("reports.playerId").toUpperCase(),
    t("reports.gameLoopId").toUpperCase(),
    t("reports.level").toUpperCase(),
    t("reports.progressiveJackpotAmount").toUpperCase(),
  ];
  const columns = columnsMenus;

  return (
    <>
      <View
        style={[
          stylesPdf.row,
          {
            backgroundColor: "#02D0E7",
          },
        ]}
      >
        {columns.map((column, index) => (
          <View key={column} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        {
          page.map((data: any, index: number) => {
            return (
              <View key={index} style={[stylesPdf.row]}>
                <View style={[stylesPdf.col, stylesPdf.pCol]}>
                  <Text style={stylesPdf.textContent}>
                    {t("reports.withoutActivity").toUpperCase()}
                  </Text>
                </View>
              </View>
            )
          })
        }
      </View>
    </>
  );
};
