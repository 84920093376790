import styled, { css, keyframes } from 'styled-components';
const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
interface ReloadButtonProps {
    updatedBalance: boolean;
    children?: React.ReactNode; // Agregamos la propiedad children
  }
export const ReloadButton: React.FC<ReloadButtonProps> = styled.div<{ updatedBalance: boolean,children:any }>`
  cursor: pointer;
  ${({ updatedBalance }) =>
    updatedBalance
      ? css`
          animation: ${rotateAnimation} 1s linear infinite;
        `
      : css`
          animation: none;
  `}
`;

export const UserBalanceContainer = styled.div`
  display: flex;
  align-items: center;
  svg{
    width: 20px;
    height: 20px;
    margin: 0 5px;
  }
  span{
    color: #FFF;
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 5px;
  }
`;