/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { styled } from "@mui/system";
import {
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import { excelToJson } from "./../../../../helpers/convertFileToJson";
import { bonusReciveMassive } from "../../../../services/bonus";
import {
  IBonusReciveMassive,
  ICreateBonusPariplay,
} from "../../../../interfaces/bonus";
import { startFilterBonus } from "../../../../redux/actions/bonusActions";
import { ProvidersBonus, State } from "../../../../enums/enums";
import {
  PariplayBonusGameList,
  asingBonusToPlayer,
} from "../../../../services/pragmatic";
import { getProviders } from "../../../../services/providers";
import { addFreeRoundsPlayersPariplay } from "../../../../services/casino";

const Container = styled("div")({
  display: "flex",
});
const CustomButton = styled(Button)({
  backgroundColor: "#007E8C",
  color: "white",
  height: "100%",
  width: "150px",
  "&:hover": {
    backgroundColor: "#007E8C",
    opacity: 1,
  },
});

const styles = {
  "& input.Mui-disabled ": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
  "& .MuiSelect-select.Mui-disabled": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const FormCreateMasiveBonus = () => {
  const { bonus } = useSelector((state: AppState) => state.bonus);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [bonusId, setBonusId] = useState<number | null | string>(null);
  const [providerId, setProviderId] = useState<number | null | string>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [providers, setProviders] = useState<any[]>([]);
  const [bonusFilter, setBonusFilter] = useState<any[]>([]);

  const whiteListProviders = ["Altenar", "Pragmatic Play", "Pariplay"];

  const { token } = useSelector((state: AppState) => state.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fetchActiveBonus = async () => {
    await dispatch(
      startFilterBonus({
        state: State.ACTIVE,
        massive: true,
      })
    );
  };
  useEffect(() => {
    fetchActiveBonus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      (fileInputRef.current as any).click();
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files && event.target.files[0];
    setSelectedFile(file ?? null);
  };
  const callback = (totalParse: number, totalLines: number) => {
    // Callback function to track progress
    // console.log(`Progreso: ${totalParse}/${totalLines}`);
  };
  const handleSave = async () => {
    if (!selectedFile || !bonus || !token) return;
    setIsLoading(true);
    try {
      const json = await excelToJson(selectedFile, callback);
      const bonusDetail = bonus.data.find((item) => item.id === bonusId);
      if (!bonusDetail) {
        throw new Error("bonus not found");
      }
      const providerBonus = bonusDetail?.provider;
      const playerList = JSON.parse(json).map((item: any) => item.playerId);
      if (providerBonus?.id === ProvidersBonus.PRAGMATIC) {
        const bonusCode = bonusDetail.pragmaticBonusCode;

        if (bonusCode) {
          await asingBonusToPlayer(token, bonusCode, { playerList });
        }
      }
      if (providerBonus?.id === ProvidersBonus.ALTENAR) {
        const data: IBonusReciveMassive = {
          bonusId: bonusId as number,
          jsonFile: json,
        };
        await bonusReciveMassive(data, token);
      }
      const dataPariplayBonus =
        bonusDetail.pragmaticBonus &&
        (bonusDetail.pragmaticBonus[0] as PariplayBonusGameList);
      if (providerId === ProvidersBonus.PARIPLAY) {
        const objCreateBonusPariplay: ICreateBonusPariplay = {
          playerList: playerList,
          gameCode: dataPariplayBonus?.gameCode as string,
          numberFreeRounds: parseInt(
            bonusDetail?.pragmaticNumberRounds as string
          ),
          endDate: dataPariplayBonus?.endDate as string,
          countryCode: dataPariplayBonus?.countryCode as string,
          currencyCode: dataPariplayBonus?.currencyCode as string,
          amountBonus: parseInt(bonusDetail.amountBonus as string),
          bonusId: bonusDetail.id as number,
          coinValue: parseFloat(dataPariplayBonus?.coinValue as string),
        };
        await addFreeRoundsPlayersPariplay(token, objCreateBonusPariplay);
      }
      dispatch(setMessageAlert("managementBonds.massiveBonus"));
      dispatch(setTypeAlert("success"));
      dispatch(setOpenAlert());
      dispatch(setFlagForm({ flagFormCreate: false }));
    } catch (error) {
      dispatch(setMessageAlert("managementBonds.errorMassiveBonus"));
      dispatch(setTypeAlert("warning"));
      dispatch(setOpenAlert());
    } finally {
      setIsLoading(false);
    }
  };
  const handleCancel = () => {
    setBonusId(null);
    setSelectedFile(null);
    dispatch(
      setFlagForm({
        flagFormCreate: false,
      })
    );
  };
  const handleDownload = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();

    const fileName =
      providerId === ProvidersBonus.PRAGMATIC
        ? "PlantillaBonosMasivos.csv"
        : "PlantillaBonosMasivosPariplayAltenar.csv";

    const publicURL = process.env.PUBLIC_URL;
    const url = `${publicURL}/${fileName}`;

    window.location.href = url;
  };

  const fetchProviders = async () => {
    if (token) {
      const response = await getProviders(token, {});

      if (!response.error) {
        const finalData = response
          .filter((prov: any) => prov.name !== "Punto pago")
          .map((prov: any) => ({
            label: prov.name,
            value: prov.id,
          }));

        setProviders(finalData);
      }
    }
  };
  useEffect(() => {
    fetchProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // UseEffect to update bonusFilter when providerId changes
  useEffect(() => {
    if (providerId) {
      const filteredBonuses =
        bonus?.data.filter((b) => b.provider.id === providerId) || [];
      setBonusFilter(filteredBonuses);
    } else {
      setBonusFilter(bonus?.data || []);
    }
  }, [providerId, bonus]);

  const handleProviderChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setBonusId(null);
    setProviderId(e.target.value as number | string);
  };

  return (
    <Grid container spacing={2} mt={1} pl={1} pr={1}>
      <Grid item xs={12} className="mb-4">
        <Box
          display="flex"
          className="pb-1"
          borderBottom={1}
          borderColor="#02D0E7"
        >
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <p
            className="txt-primary-md"
            style={{
              fontWeight: "bold",
            }}
          >
            {t("managementBonds.createMasiveBonus")}
          </p>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <FormControl sx={{ width: "100%" }} size="small">
          <InputLabel id="select-providers-label">
            {t("managementBonds.provider") + "*"}
          </InputLabel>
          <Select
            labelId="select-providers-label"
            name="provider"
            size="small"
            fullWidth
            onChange={(e: any) => handleProviderChange(e)}
            value={!providerId ? "" : providerId}
            sx={styles}
            input={
              <OutlinedInput label={`${t("managementBonds.provider")}*`} />
            }
            MenuProps={MenuProps}
          >
            {providers
              .filter((prov) => whiteListProviders.includes(prov.label))
              .map((item) => (
                <MenuItem
                  key={item.value}
                  value={item.value}
                  sx={{
                    "&.Mui-focused": {
                      backgroundColor: "red",
                    },
                  }}
                >
                  {item.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl sx={{ width: "100%" }} size="small">
          <InputLabel id="select-bonus-label">
            {t("managementBonds.selectBonus") + "*"}
          </InputLabel>
          <Select
            labelId="select-bonus-label"
            name="bonus"
            size="small"
            fullWidth
            onChange={(e: any) => setBonusId(e.target.value)}
            value={!bonusId ? "" : bonusId}
            sx={styles}
            input={
              <OutlinedInput label={t("managementBonds.selectBonus") + "*"} />
            }
            MenuProps={MenuProps}
          >
            {bonusFilter.length === 0 && (
              <MenuItem value="" disabled>
                {t("managementBonds.noBonusFound")}
              </MenuItem>
            )}
            {bonusFilter.map((bonus) => (
              <MenuItem key={bonus.id} value={bonus.id}>
                {bonus.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Container>
            <TextField
              label={`${t("managementBonds.uploadFilePlayers")}*`}
              disabled
              size="small"
              fullWidth
              value={selectedFile ? selectedFile.name : ""}
              InputProps={{
                readOnly: true,
              }}
            />
            <input
              type="file"
              accept=".csv"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            <label style={{ margin: 0 }}>
              <CustomButton
                disabled={!providerId || !bonusId}
                style={{
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                }}
                onClick={handleButtonClick}
              >
                {t("managementBonds.examine")}
              </CustomButton>
            </label>
          </Container>
          {providerId && bonusId && (
            <Typography>
              <a href="#" onClick={handleDownload}>
                {t("managementBonds.dowloadTemplate")}
              </a>
            </Typography>
          )}
        </div>
      </Grid>
      <Grid item xs={12} mt={24} display={"flex"} justifyContent={"flex-end"}>
        <Button
          variant="contained"
          className="footer-btn danger-btn ripple"
          style={{ border: "none", outline: "none" }}
          onClick={handleCancel}
          data-testid={"btnCancel"}
        >
          {t("button.cancel").toUpperCase()}
        </Button>
        <Button
          type="submit"
          variant="contained"
          className="footer-btn primary-btn ripple"
          style={{ border: "none", outline: "none" }}
          disabled={isLoading || !bonusId || !selectedFile || !providerId}
          data-testid={"btnSave"}
          onClick={handleSave}
        >
          {isLoading ? (
            <CircularProgress size={"1.6rem"} color="inherit" />
          ) : (
            <>{t("button.save").toUpperCase()}</>
          )}
        </Button>
      </Grid>
    </Grid>
  );
};
