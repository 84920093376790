import React, { useState } from "react";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Filter from "../../../components/Filter/Filter";
import { Enum_FieldType, IFilterField } from "../../../interfaces/components";
import MyCustomTable, {
  IColumns,
} from "../../../components/Custom/Table/MyCustomTable";
import { RowRequestCard } from "../../components/requestCard/RowRequestCard";
import { useDispatch, useSelector } from "react-redux";
import { asyncLoadingFunction } from "../../../redux/actions/helperActions";
import { startGetRequestedCards } from "../../../redux/actions/reportCardsActions";
import { arraySearch } from "../../../redux/actions/searchActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { getRequestedCards } from "../../../services/reports";
import { ReportCardsDataTable } from "../../../hooks/useDataTable/reportCards";
import { ReportCardsPuntoPagoPdf } from "../../../components/Pdf/reportCardsPuntoPago/ReportCardsPuntoPagoPdf";

export const ReportCards = () => {
  const [dataFilter, setDataFilter] = useState<any>({});
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { data } = useSelector((state: AppState) => state.search);
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const FilterFileds: IFilterField[] = [
    {
      type: Enum_FieldType.NUMBER,
      label: `${t("sidebar.player")} id`,
    },
    {
      type: Enum_FieldType.DATE,
      label: `${t("filters.startDate")}`,
    },
    {
      type: Enum_FieldType.DATE,
      label: `${t("filters.endDate")}`,
    },
  ];
  const columns: IColumns[] = [
    {
      align: "center",
      label: `${t("sidebar.player")} id`,
    },
    {
      align: "center",
      label: t("user.phone"),
    },
    {
      align: "center",
      label: t("withdrawals.requestDate"),
    },
  ];
  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(
        async () =>
          await dispatch(
            startGetRequestedCards({
              ...dataFilter,
              page,
              perPage: rows || rowsPerPage,
            })
          )
      )
    );
  };
  const handleFilter = async (values: any) => {
    const params = {
      player: values[`${t("sidebar.player")} id`],
      startDate: values[t("filters.startDate")],
      endDate: values[t("filters.endDate")],
    };

    await dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startGetRequestedCards({
            ...params,
            page: 1,
          })
        )
      )
    );
    setDataFilter({ ...params });
  };
  const onCleanFilter = async () => {
    dispatch(arraySearch(null));
    setDataFilter({});
  };
  const getTotalCards = async () => {
    if (user && token) {
      const params = {
        operator: user.operator.id,
        //playerId: (player as any).data[0].id,
      };
      return await getRequestedCards(token, params);
    }
  };
  return (
    <div className="content w-100 mb-5">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyBreadCrumbs titles={[t("reportCards.title")]} />
        </Grid>

        <div className="white-container w-100">
          <Grid item xs={12}>
            <Filter
              fields={FilterFileds}
              handleFilter={handleFilter}
              onClean={onCleanFilter}
            />
          </Grid>
          {data && (
            <Grid item xs={12}>
              <MyCustomTable
                columns={columns}
                data={data.data}
                lastPage={data.last_page}
                totalData={data.total}
                onPageChange={handlePageChange}
                renderBody={(item) => <RowRequestCard row={item} />}
                exportData
                getAllDataToExport={getTotalCards}
                hookBuildTable={ReportCardsDataTable}
                pdfComponent={ReportCardsPuntoPagoPdf}
                titleCSV={`${t(
                  "reportCards.title"
                )}-${new Date().toISOString()}`}
              />
            </Grid>
          )}
        </div>
      </Grid>
    </div>
  );
};
