import React, { FC, useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";

import { useLocation } from "react-router-dom";
import { Roles_Enum, State, whiteListExport } from "../../../enums/enums";
import { ActionsTable, IActionsProps } from "./ActionsTable";
import { StaticPagination } from "../../Pagination/StaticPagination";
import { MyPagination } from "./MyPagination";
import { ExportDataList } from "./ExportDataList";
import { useTranslation } from "react-i18next";

export interface IColumns {
  label: string;
  align: "left" | "center" | "inherit" | "justify" | "right";
}

interface props {
  columns: IColumns[];
  data: any[];
  renderBody(prop: any): JSX.Element;
  lastPage: number;
  onPageChange(page: number, rows?: number): void;
  totalData: number;
  isSortingTable?: boolean;
  exportData?: boolean;
  actions?: IActionsProps;
  //EXPORT DATA PROPS
  titleCSV?: string;
  getAllDataToExport?(token: string, params: any): Promise<any>;
  pdfComponent?: any;
  hookBuildTable?(totalData: any): any;
  onAdd?(row: any): void;
  onDelete?(row: any): void;
  onEdit?(row: any): void;
  onSee?(row: any): void;
  onSeeMore?(row: any): void;
  onApprove?(row: any): void;
  onReject?(row: any): void;
  onAttach?(row: any): void;
  resetCurrentPage?: boolean;
  expand?: boolean;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F4F4F4",
    color: "#000",
    fontSize: 12,
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const MyCustomTable: FC<props> = ({
  columns,
  exportData,
  renderBody,
  data,
  actions,
  lastPage,
  onPageChange,
  totalData,
  isSortingTable,
  titleCSV,
  pdfComponent,
  hookBuildTable,
  getAllDataToExport,
  onAdd,
  onDelete,
  onEdit,
  onSee,
  onSeeMore,
  onApprove,
  onReject,
  resetCurrentPage,
  children,
  expand,
  onAttach,
}) => {
  const { t } = useTranslation();
  const [columnSort, setColumnSort] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [orderDirection, setOrderDirection] = useState<any>("desc");
  const [expandExportList, setExpandExportList] = useState(false);

  const { loading } = useSelector((state: AppState) => state.helper);
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { user } = useSelector((state: AppState) => state.auth);
  const { pathname } = useLocation();

  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const treasuryPermissions =
    user?.roles.name.toUpperCase() === Roles_Enum.BETPPRO_TREASURY &&
    [
      "/admin/transactions/settings",
      "/admin/transactions/withdrawals",
      "/admin/player/detail",
      "/admin/reports",
    ].includes(pathname);
  const exportPermissions = whiteListExport.includes(
    user?.roles.name as Roles_Enum
  );
  const isContactCenter =
    titleCSV?.includes("activityplayer") &&
    user?.roles.name.toUpperCase() === Roles_Enum.CONTACT_CENTER;
  const menuPermissions = routes.find(
    (route) =>
      !!route.menuItem.find((module) => module.url === pathname) ||
      route.url === pathname
  );

  const isUpdateData =
    user?.roles.name.toUpperCase() === Roles_Enum.UPDATE_DATA;

  useEffect(() => {
    if (resetCurrentPage) {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetCurrentPage]);

  let finalActions: IActionsProps = { ...actions };
  if (menuPermissions) {
    const seeAction =
      finalActions.see !== undefined
        ? finalActions.see
        : {
            show: false,
            state: State.ACTIVE,
          };
    const editAction =
      finalActions.edit !== undefined ? finalActions.edit : false;
    const attachAction =
      finalActions.attach !== undefined ? finalActions.attach : false;
    const deleteAction =
      finalActions.delete !== undefined ? finalActions.delete : false;
    const seeMoreAction =
      finalActions.seeMore !== undefined ? finalActions.seeMore : false;
    if (menuPermissions.menuItem.length > 0) {
      const modulePermissions = menuPermissions.menuItem.find(
        (module) => module.url === pathname
      );

      finalActions.see = isSuper
        ? seeAction
        : modulePermissions && modulePermissions.permissions?.readed
        ? seeAction
        : undefined;
      finalActions.edit = isSuper
        ? editAction
        : modulePermissions && modulePermissions.permissions?.updated === 1
        ? editAction
        : false;
      finalActions.attach = isSuper
        ? attachAction
        : modulePermissions && modulePermissions.permissions?.updated === 1
        ? attachAction
        : false;
      finalActions.delete = isSuper
        ? deleteAction
        : finalActions.delete !== undefined
        ? finalActions.delete
        : modulePermissions && modulePermissions.permissions?.deleted === 1
        ? deleteAction
        : false;
      finalActions.seeMore = isSuper
        ? seeMoreAction
        : modulePermissions?.permissions?.readed === 1
        ? seeMoreAction
        : false;
    } else {
      const permissions = menuPermissions.permissionsMenu;

      finalActions.see = isSuper
        ? seeAction
        : permissions?.readed === 1
        ? finalActions.see !== undefined
          ? finalActions.see
          : seeAction
        : undefined;
      finalActions.edit = isSuper
        ? editAction
        : permissions?.updated === 1
        ? finalActions.edit !== undefined
          ? finalActions.edit
          : editAction
        : false;
      finalActions.attach = isSuper
        ? attachAction
        : permissions?.updated === 1
        ? finalActions.attach !== undefined
          ? finalActions.attach
          : attachAction
        : false;
      finalActions.delete = isSuper
        ? deleteAction
        : permissions?.deleted === 1
        ? finalActions.delete !== undefined
          ? finalActions.delete
          : deleteAction
        : false;
      finalActions.seeMore = isSuper
        ? seeMoreAction
        : permissions?.readed === 1
        ? finalActions.seeMore !== undefined
          ? finalActions.seeMore
          : seeMoreAction
        : false;
    }
  }
  const handlePageChange = (page: number, rows?: number) => {
    setCurrentPage(page);
    onPageChange(page, rows);
  };
  const sortArray = (arr: any, orderBy: any) => {
    switch (orderBy) {
      case "desc":
        return arr.sort((a: any, b: any) => {
          if (a.id < b.id) return 1;

          if (b.id < a.id) return -1;

          return 0;
        });
      case "asc":
      default:
        return arr.sort((a: any, b: any) => {
          if (a.id > b.id) return 1;

          if (b.id > a.id) return -1;

          return 0;
        });
    }
  };
  const handleSortRequest = (col: string) => {
    setColumnSort(col);

    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    sortArray(data, orderDirection);
  };

  const ExportList = (
    <ExportDataList
      setTooltipIsOpen={() => setExpandExportList(false)}
      titleCSV={titleCSV || ""}
      getTotalData={getAllDataToExport}
      pageData={data}
      pdfComponent={pdfComponent}
      hookBuildDataTable={hookBuildTable}
    />
  );

  return (
    <div>
      {loading ? (
        <Box
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
          width="100%"
          mt={3}
        >
          <CircularProgress className={"colorLoading"} />
        </Box>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map(({ label, align }) => (
                    <StyledTableCell
                      key={label}
                      align={align}
                      onClick={() => {
                        handleSortRequest(label);
                      }}
                    >
                      {label.toUpperCase()}
                      {isSortingTable && label !== t("table.actions") && (
                        <TableSortLabel
                          active={true}
                          direction={orderDirection}
                          IconComponent={
                            columnSort === label
                              ? ArrowDropDownIcon
                              : ArrowDropUpIcon
                          }
                          sx={{
                            ".MuiTableSortLabel-icon": {
                              fontSize: 35,
                              color: `${
                                columnSort === label ? "#2C97DF" : "#CED4DA"
                              } !important`,
                              cursor: "pointer",
                            },
                          }}
                        />
                      )}
                    </StyledTableCell>
                  ))}
                  {exportData &&
                  (exportPermissions ||
                    treasuryPermissions ||
                    isContactCenter ||
                    isUpdateData) ? (
                    <StyledTableCell align="center" width={50}>
                      <Tooltip
                        open={expandExportList}
                        onClick={() => setExpandExportList((prev) => !prev)}
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "transparent",
                              "& .MuiTooltip-arrow": {
                                color: "#02D0E7",
                              },
                              fontWeight: "bold",
                              color: "#000",
                              maxWidth: "none",
                              cursor: "pointer",
                            },
                          },
                        }}
                        title={ExportList}
                        disableHoverListener
                        placement="bottom"
                      >
                        <i
                          style={{ color: "#007E8C", cursor: "pointer" }}
                          className="fa fa-file-export fa-lg"
                        ></i>
                      </Tooltip>
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="center" width={50} />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <React.Fragment key={index}>
                    {expand ? (
                      renderBody(item)
                    ) : (
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        {renderBody(item)}
                        {actions && (
                          <ActionsTable
                            actions={{
                              ...finalActions,
                              see: {
                                show: !!finalActions.see,
                                state: item.state,
                              },
                            }}
                            onAdd={() => onAdd && onAdd(item)}
                            onDelete={() => onDelete && onDelete(item)}
                            onEdit={() => onEdit && onEdit(item)}
                            onSee={() => onSee && onSee(item)}
                            onSeeMore={() => onSeeMore && onSeeMore(item)}
                            onApprove={() => onApprove && onApprove(item)}
                            onReject={() => onReject && onReject(item)}
                            onAttach={() => onAttach && onAttach(item)}
                          />
                        )}
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {children}

          <Grid container display="flex" justifyContent="center">
            {lastPage === 1 && totalData > 0 ? (
              <StaticPagination
                total={totalData}
                onPageChange={handlePageChange}
              />
            ) : (
              <MyPagination
                onPageChange={handlePageChange}
                currentPage={currentPage}
                lastPage={lastPage}
                siblingCount={1}
                total={totalData}
              />
            )}
          </Grid>
        </>
      )}
    </div>
  );
};

export default MyCustomTable;
