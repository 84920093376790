import { FC, Fragment, useState } from "react";
import PeriodicityManagement from "./PeriodicityManagement";
import CommissionManagement from "./CommissionManagement";
import ValuesManagementForm from "./ValuesManagementForm";
import AffiliationCommissionFTDManagement from "./AffiliationCommissionFTDManagement";

const ValuesManagement: FC = () => {
  const [showForm, setShowForm] = useState(false);
  const [value, setValue] = useState<
    | "affiliationPercentage"
    | "affiliationPeriodicity"
    | "affiliationCommissionFTD"
    | ""
  >("");
  return (
    <Fragment>
      {showForm ? (
        <ValuesManagementForm
          value={value}
          setShowForm={setShowForm}
          setValue={setValue}
        />
      ) : (
        <div className="values-management">
          <div className="values-management-item">
            <PeriodicityManagement
              setShowForm={setShowForm}
              setValue={setValue}
            />
          </div>
          <div className="values-management-item">
            <CommissionManagement
              setShowForm={setShowForm}
              setValue={setValue}
            />
          </div>
          <div className="values-management-item">
            <AffiliationCommissionFTDManagement
              setShowForm={setShowForm}
              setValue={setValue}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ValuesManagement;
