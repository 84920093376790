import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress, Grid, ThemeProvider, Box } from "@mui/material";

import { theme } from "../../custom/customStyles";
import { MyToggleButton } from "../../../../components/Custom/MyToggleButton";
import { ICountryStep } from "../../../../interfaces/operationCompany";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { IPaymentMethods } from "../../../../redux/interfaces/shared";
import { getParamPaymentById } from "../../../../services/managementParametersService";
import { ICountryPayment } from "../../../../interfaces/params";
import { setPaymentMethodsRedux } from "../../../../redux/actions/helperActions";
import { State } from "../../../../enums/enums";

interface Props {
  formValues: any;
  countriesSelected: ICountryStep[];
  setPaymentMethod(
    indexCountry: number,
    value: any,
    fromRecharges?: boolean
  ): void;
}
export const FormPaymentMethodsRefills = ({
  countriesSelected,
  setPaymentMethod,
  formValues,
}: Props) => {
  const { token } = useSelector((state: AppState) => state.auth);
  const [countryPaymentMethods, setCountryPaymentMethods] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    state: IPaymentMethods,
    indexCountry: number
  ) => {
    setPaymentMethod(indexCountry, state, true);
  };

  const getParam = async (country: number): Promise<ICountryPayment[]> => {
    setIsLoading(true);
    if (!token) {
      return [];
    }
    const params = {
      active: State.ACTIVE,
    };
    const response = await getParamPaymentById(
      token,
      country,
      formValues.operator,
      params
    );
    setIsLoading(false);
    return response.data;
  };
  useEffect(() => {
    const getPaymentMethods = async () => {
      const finalData: any[] = [];
      for (const country of countriesSelected) {
        const response = await getParam(parseInt(country.id));

        const objCountryPayment = {
          id: country.id,
          methods: response,
        };
        finalData.push(objCountryPayment);
      }
      finalData.forEach((data) => {
        data.methods = data.methods.filter(
          (method: any) =>
            (method.active && method.state) ||
            method.methods.type === "withdrawal"
        );
      });

      setCountryPaymentMethods(finalData);
      dispatch(setPaymentMethodsRedux(finalData));
    };
    getPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.operator]);

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={5}>
        {countriesSelected.map((country, index) => {
          return (
            <Grid item xs={4} p={4} key={country.id}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={6}
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <img
                      loading="lazy"
                      style={{
                        height: "50px",
                        width: "50px",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                      alt="Country"
                      src={country.imgUrl}
                    />
                    <label className="label-text">{country.name}</label>
                  </Grid>
                </Grid>
                {isLoading ? (
                  <Box
                    display={"flex"}
                    justifyContent="center"
                    alignItems={"center"}
                    width="100%"
                    mt={3}
                    ml={9}
                  >
                    <CircularProgress className={"colorLoading"} />
                  </Box>
                ) : (
                  <>
                    {countryPaymentMethods
                      .find((method) => method.id === country.id)
                      ?.methods.filter(
                        (method: any) => method.methods.type !== "withdrawal"
                      ).length <= 0 && (
                      <label className="label-text mt-2">
                        {t("parameterization.noParamPaymentMethods")}
                      </label>
                    )}
                    {countryPaymentMethods
                      .find((method) => method.id === country.id)
                      ?.methods.map((method: any) => (
                        <Fragment key={country.id + "-" + method.id}>
                          {(method.methods.type === "refills" ||
                            method.methods.type === "global") && (
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              alignItems="center"
                            >
                              <Grid item xs={6}>
                                {method.methods.name.toUpperCase()}
                              </Grid>
                              <Grid item xs={6}>
                                <MyToggleButton
                                  // @ts-ignore
                                  checked={
                                    country.paymentMethodsRecharges?.find(
                                      (pay) => pay.name === method.methods.name
                                    )?.state
                                  }
                                  handleChange={(e) =>
                                    handleToggle(
                                      e,
                                      {
                                        id: method.methods.id,
                                        name: method.methods.name,
                                        idParamMethod: method.id,
                                        type: method.methods.type,
                                      },
                                      index
                                    )
                                  }
                                />
                              </Grid>
                            </Grid>
                          )}
                        </Fragment>
                      ))}
                  </>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </ThemeProvider>
  );
};
