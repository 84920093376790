import {
  IConfigPointSale,
  IUpdateWalletPointSale,
} from "./../interfaces/pointsSale";
import { IPointSaleForm } from "../interfaces/pointsSale";

export const getPointsSaleService = (
  token: string,
  page: number,
  rowsPerPage: number | null,
  operatorId?: number,
  associate?: number
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  let params = rowsPerPage
    ? operatorId
      ? `operatorId=${operatorId}&page=${page}&perPage=${rowsPerPage}`
      : `&page=${page}&perPage=${rowsPerPage}`
    : operatorId
    ? `operatorId=${operatorId}&page=${page}`
    : `&page=${page}`;
  params = associate ? params + `&associate=${associate}` : params;

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}pointsSale?${params}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const getPointsSaleFilterService = (
  token: string,
  params?: any,
  exact?: boolean,
  page?: number | null,
  perPage?: number | null,
  operator?: number | null,
  associate?: number
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  let queryString = params ? new URLSearchParams(params).toString() : "";

  queryString = exact ? queryString + "&exact=true" : queryString;

  const paginationQuery =
    page && perPage ? `&page=${page}&perPage=${perPage}` : "";

  const operatorId = operator ? `operatorId=${operator}` : "";
  queryString = associate
    ? queryString + `&associate=${associate}`
    : queryString;

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}pointsSale?&${paginationQuery}&${queryString}&${operatorId}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const getPointSaleByIdService = (token: string, id: number) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}pointsSale/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const editPointSaleService = (
  data: IPointSaleForm | any,
  token: string,
  id: number
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}pointsSale/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const addPointSaleService = (data: IConfigPointSale, token: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}pointsSale`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const deletePointSaleService = (id: number, token: string) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}pointsSale/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const getPointsSalesMainService = (token: string, params: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}pointsSale?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const updateWalletPointSale = (
  token: string,
  data: IUpdateWalletPointSale
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}sellers/point-sale-balance`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
