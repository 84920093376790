import { IPromotionForm } from "../interfaces/promotions";

export const getPromotions = (
  token: string,
  operatorId: number | null,
  params?: any
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  params.operatorId = operatorId;
  const queryString = params ? new URLSearchParams(params).toString() : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}promotions?${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const createPromotion = (data: IPromotionForm, token: string) => {
  const myHeaders = new Headers();
  myHeaders.append("api", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Authorization", `bearer ${token}`);
  const formData = new FormData();
  formData.append("title", data.title ? data.title : '');
  formData.append("startDate", data.startDate ? data.startDate : '');
  formData.append("endDate", data.endDate ? data.endDate : '');
  formData.append("category", data.category ? data.category : '');
  formData.append("redirect", data.redirect ? data.redirect : '');
  formData.append("termsOfService", data.termsOfService ? data.termsOfService : '');
  data.operator && formData.append("operator", data.operator.toString());
  data.file && formData.append("file", data.file);

  const requestOptions: any = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}promotions`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const updatePromotion = (data: IPromotionForm, token: string) => {
  const myHeaders = new Headers();
  myHeaders.append("api", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Authorization", `bearer ${token}`);
  const formData = new FormData();
  formData.append("title", data.title ? data.title : '');
  formData.append("startDate", data.startDate ? data.startDate : '');
  formData.append("endDate", data.endDate ? data.endDate : '');
  formData.append("category", data.category ? data.category : '');
  formData.append("redirect", data.redirect ? data.redirect : '');
  formData.append("termsOfService", data.termsOfService ? data.termsOfService : '');
  data.operator && formData.append("operator", data.operator.toString());
  data.file && formData.append("file", data.file);

  const requestOptions: any = {
    method: "PUT",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}promotions/${data.id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const deletePromotion = (id: number, token: string) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}promotions/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};