import { deleteAd, getAdsFilterService, updateAdd } from "./../../services/ads";
import { IAds, IAdsDb, IFormAds, IPaginationAds } from "../../interfaces/ads";
import { createNewAdd, getAdsByOperator } from "../../services/ads";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import { arraySearch, setChangeData } from "./searchActions";
import {
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";

export const startCreateAdd = (data: IFormAds, operatorId: number | null) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;

    try {
      if (user && token) {
        const idOperator = operatorId ? operatorId : user.operator.id;

        const finalData: IAds = {
          ...data,
          operator: idOperator,
          createuser: user.id,
        };
        const response = await createNewAdd(finalData, token);

        if (response && !response.error) {
          await dispatch(startGetAds({ page: 1 }));
          await dispatch(arraySearch(null));

          dispatch(setOpenAlert());
          dispatch(setMessageAlert("managementAds.createdAd"));
          dispatch(setTypeAlert("success"));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("managementAds.errorAd"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const startGetAds = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { user, token } = (getState() as AppState).auth;
    if (!user || !token) {
      return;
    }
    if (params.page || rowsPerPage) {
      params.perPage = rowsPerPage.toString();
    }
    const { operator } = user;

    const response = await getAdsByOperator(
      operator?.id.toString(),
      token,
      params
    );

    if (response && !response.error) {
      dispatch(arraySearch(null));
      dispatch(setAds(response));
    } else {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.errorSetAds"));
      dispatch(setTypeAlert("error"));
    }
  };
};
export const startFilterAds = (params: any, cleanFilter?: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (params.page || rowsPerPage) {
      params.perPage = rowsPerPage.toString();
    }

    try {
      if (token) {
        let finalValues: any = {};
        Object.entries(params).forEach(([key, value], index) => {
          //@ts-ignore
          if (value !== undefined && value.length > 0) {
            //@ts-ignore
            finalValues[key] = value;
          }
        });

        const response =
          user &&
          (await getAdsFilterService(token, user.operator?.id, finalValues));

        if (response && !response.error) {
          if (cleanFilter) {
            dispatch(arraySearch(null));
            dispatch(setAds(response));
          } else {
            dispatch(arraySearch(response));
          }
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetAds"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const startUpdateAdd = (
  dataAds: IFormAds,
  isTableAction?: boolean,
  isFilterByState?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { activeAd, ads } = (getState() as AppState).managementAds;
    const { data } = (getState() as AppState).search;
    const { rowsPerPage } = (getState() as AppState).helper;

    try {
      if (user && token && activeAd) {
        const finalData: IAds = {
          ...dataAds,
          operator: activeAd.operator.id,
          id: activeAd.id,
          createuser: activeAd.createdUser.id,
        };
        const response = await updateAdd(finalData, token, isTableAction);

        if (response && !response.error) {
          if (isTableAction) {
            const newData = data ? { ...data } : ads && { ...ads };

            newData.data =
              data && isFilterByState
                ? newData.data.filter((el: any) => el.id !== activeAd.id)
                : newData.data.map((el: any) =>
                  el.id === activeAd.id ? { ...el, state: dataAds.state } : el
                );
            newData.total =
              data && isFilterByState ? data.total - 1 : newData.total;
            newData.last_page =
              data && isFilterByState
                ? Math.ceil((data.total - 1) / rowsPerPage)
                : newData.last_page;

            dispatch(data ? arraySearch(newData) : setAds(newData));
            dispatch(setActiveAd(null));
          } else {
            dispatch(arraySearch(null));
            await dispatch(startGetAds({ page: 1 }));
          }

          dispatch(setOpenAlert());
          dispatch(setMessageAlert("managementAds.updatedAd"));
          dispatch(setTypeAlert("success"));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("managementAds.updateErrorAd"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};
export const startDeleteAd = (id: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { data } = (getState() as AppState).search;
    const { ads } = (getState() as AppState).managementAds;

    if (!token) {
      throw new Error("invalid token");
    }

    const response = await deleteAd(id, token);
    if (response && !response.error) {
      data && dispatch(setChangeData(true));

      const newData = data ? { ...data } : ads && { ...ads };
      newData.data = newData.data.filter((el: any) => el.id !== id);

      const dataSearchFilter = {
        data: newData.data,
        total: newData.total - 1,
        page: newData.page,
        last_page: newData.last_page,
      };

      dispatch(data ? arraySearch(dataSearchFilter) : setAds(dataSearchFilter));

      dispatch(setActiveAd(null));
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("managementAds.deletedAd"));
      dispatch(setTypeAlert("success"));
    } else {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("managementAds.deleteErrorAd"));
      dispatch(setTypeAlert("error"));
    }
  };
};

// const deleteAdRedux = (id: number) => ({
//   type: Types.deleteAd,
//   payload: id,
// });
export const setAds = (data: IPaginationAds | null) => ({
  type: Types.setAds,
  payload: data,
});
export const setActiveAd = (ads: IAdsDb | null) => ({
  type: Types.setActiveAd,
  payload: ads,
});
