import { KeyboardEvent, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../../hooks/useForm";
import {
  setCities,
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setStates,
  setFlagFormConfig,
  setTypeAlert,
  setFlagFormEdit,
  setCloseAlert,
} from "../../../redux/actions/helperActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import "../ManagementRoles/formCreateRol.scss";
import "./formCreateOperation.scss";
import { IOperationManagForm } from "../../../interfaces/operationCompany";
import {
  setActiveCompany,
  startAddOperator,
  startEditOperator,
} from "../../../redux/actions/managementCompanyActions";
import { MyDialog } from "../custom/MyDialog";
import { getOperatorsService } from "../../../services/managementCompanyService";

import clearLogo from "../../../assets/svg/icon-park_clear-format.svg";
import * as yup from "yup";
import { Col, Row } from "reactstrap";
import { State } from "../../../enums/enums";
import {
  regEmail,
  regNit,
  regLetters,
  regNumbers,
  validatePhoneByCountry,
} from "../../../utils/utils";
import {
  ICities,
  ICountries,
  ICurrencies,
  IStates,
} from "../../../redux/interfaces/shared";
import { MyAlert } from "../custom/MyAlert";
import { getCurrencies } from "../../../services/helperService";

const styles = {
  "& input.Mui-disabled ": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
  "& .MuiSelect-select.Mui-disabled": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
};
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const FormCreateOperationCompany = () => {
  const { t } = useTranslation();
  const { countries, cities, states, openAlert, typeAlert, messageAlert } =
    useSelector((state: AppState) => state.helper);
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { activeCompany } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const [currencies, setCurrencies] = useState<ICurrencies[]>([]);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [existsName, setExistsName] = useState(false);
  const [existsNit, setExistsNit] = useState(false);
  const [existsEmail, setExistsEmail] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [cancel, setCancel] = useState(false);
  const [noValidEmail, setNoValidEmail] = useState(false);
  const [noValidPhone, setNoValidPhone] = useState(false);
  const [validNit, setValidNit] = useState(true);
  const [validName, setValidName] = useState(true);
  const [validRepresentative, setValidRepresentative] = useState(true);
  const [errorCountry, setErrorCountry] = useState(false);
  const [errorStates, setErrorStates] = useState(false);
  const [errorCity, setErrorCity] = useState(false);
  const [flagStates, setFlagStates] = useState(true);
  const [flagCity, setFlagCity] = useState(true);

  const [selectedCountry, setSelectedCountry] = useState<ICountries | null>(
    activeCompany?.country ? activeCompany.country : null
  );

  const [selectedStates, setSelectedStates] = useState<IStates | null>(
    activeCompany?.state ? activeCompany.state : null
  );

  const [cityData, setCity] = useState<ICities | null>(
    activeCompany?.city ? activeCompany.city : null
  );

  const [currencySelected, setCurrencySelected] = useState<ICurrencies | null>(
    activeCompany?.currencyOp && activeCompany.currencyOp.length > 0
      ? activeCompany.currencyOp[0].currency
      : null
  );
  const [paramsPhoneError, setParamsPhoneError] = useState("");
  const initialFormValues: IOperationManagForm = {
    id: activeCompany?.id && activeCompany.id,
    name: activeCompany?.name ? activeCompany.name : "",
    nit: activeCompany?.nit ? activeCompany.nit : "",
    email: activeCompany?.email ? activeCompany.email : "",
    legalRepresentative: activeCompany?.legalRepresentative
      ? activeCompany.legalRepresentative
      : "",
    phone: activeCompany?.phone ? activeCompany.phone : "",
    stateOperator: activeCompany?.stateOperator
      ? activeCompany.stateOperator
      : 1,
    country: activeCompany?.country ? activeCompany.country.id : 0,
    state: activeCompany?.state ? activeCompany.state.id : 0,
    city: activeCompany?.city ? activeCompany.city.id : 0,
    createdUser: user?.id && user.id,
    currencies: [],
  };
  let [formValues, handleInputChange, reset] = useForm({
    ...initialFormValues,
  });
  const schema = yup.object().shape({
    email: yup.string().email(),
  });
  const dispatch = useDispatch();

  const getAllCurrencies = async (token: string) => {
    const response = await getCurrencies(token, selectedCountry?.id);
    setCurrencies(response);
  };
  useEffect(() => {
    if (token && selectedCountry) {
      getAllCurrencies(token);
    }

    return () => {
      setCurrencies([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  useEffect(() => {
    if (activeCompany) {
      formValues.stateOperator = activeCompany.stateOperator;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  useEffect(() => {
    const checkEmailValidation = async () => {
      const isValid = await validateEmail(formValues.email);
      setNoValidEmail(!isValid);
    };
    checkEmailValidation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.email]);

  /**
   * UseEffect Hook para validar número de telefono
   */
  useEffect(() => {
    const checkValidationPhone = async () => {
      const isValid = await validatePhoneNumber();
      setNoValidPhone(!isValid);
    };

    selectedCountry && checkValidationPhone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.phone, selectedCountry]);

  useEffect(() => {
    const nitLength = formValues.nit.length;
    if (nitLength > 0) {
      const validLengthNit = nitLength <= 20 && nitLength >= 7;
      setValidNit(validLengthNit);
    }
  }, [formValues.nit]);

  useEffect(() => {
    setValidName(formValues.name.length <= 150);
  }, [formValues.name]);

  useEffect(() => {
    setValidRepresentative(formValues.legalRepresentative.length <= 150);
  }, [formValues.legalRepresentative]);

  useEffect(() => {
    //FILTRAR LOS SELECTS
    if (selectedCountry) {
      getStates(selectedCountry.id);
      //@ts-ignore
      if (!activeCompany) {
        setSelectedStates(null);
      }
      setFlagStates(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  useEffect(() => {
    //FILTRAR LOS SELECTS
    if (selectedStates) {
      getCities(selectedStates.id);
      if (!activeCompany) {
        setCity(null);
      }
      setFlagCity(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStates]);

  const getStates = (id: number) => {
    if (token) {
      dispatch(setStates(token, id));
    }
  };
  const getCities = (id: number) => {
    if (token) {
      dispatch(setCities(token, id));
    }
  };

  /**
   *
   * @param data FORM VALUES
   * @returns la validación de los campos del formulario (true, false)
   */
  const validateFormFields = async (data: any) => {
    // const responseOperators = token && getOperatorsService(token);
    let isValid = true;

    !selectedCountry ? setErrorCountry(true) : setErrorCountry(false);
    !selectedStates ? setErrorStates(true) : setErrorStates(false);
    !cityData ? setErrorCity(true) : setErrorCity(false);

    data.country = selectedCountry;
    data.state = selectedStates;
    data.city = cityData;
    if (user) {
      data.createdUser = { id: user.id, nameComplete: user.nameComplete };
    }

    Object.entries(data).forEach(([key, value], index) => {
      //@ts-ignore
      if ((value === "" || value === 0) && key !== "stateOperator") {
        isValid = false;
      }
      if (key === "currencies") {
        //@ts-ignore
        isValid = true;
      }
    });
    const isValidEmail = await validateEmail(data.email);

    return (
      isValid &&
      isValidEmail &&
      !noValidPhone &&
      !errorCountry &&
      selectedCountry &&
      !errorStates &&
      cityData &&
      selectedStates &&
      !errorCity &&
      currencySelected
    );
  };

  const validateExistFields = async (formValues: any) => {
    let alreadyExist = false;
    let responseName = null;
    let responseNit = null;
    let responseEmail = null;
    if (token) {
      if (!activeCompany || activeCompany.name !== formValues.name) {
        responseName = await getOperatorsService(
          token,
          {
            name: formValues.name,
          },
          true
        );
      }

      if (!activeCompany || activeCompany.nit !== formValues.nit) {
        responseNit = await getOperatorsService(
          token,
          {
            nit: formValues.nit,
          },
          true
        );
      }

      if (!activeCompany || activeCompany.email !== formValues.email) {
        responseEmail = await getOperatorsService(
          token,
          {
            email: formValues.email,
          },
          true
        );
      }
      if (responseName && responseName.data.length > 0) {
        setExistsName(true);
        alreadyExist = true;
      } else {
        setExistsName(false);
      }
      if (responseNit && responseNit.data.length > 0) {
        setExistsNit(true);
        alreadyExist = true;
      } else {
        setExistsNit(false);
      }

      if (responseEmail && responseEmail.data.length > 0) {
        setExistsEmail(true);
        alreadyExist = true;
      } else {
        setExistsEmail(false);
      }
    }

    return alreadyExist;
  };

  /**
   * SOPORTA LOS EVENTOS PARA SELECCIONAR PAIS, ESTADO Y CIUDAD
   */

  const handleSelectCountry = async (value: ICountries | null) => {
    setSelectedStates(null);
    setCity(null);
    !value ? setErrorCountry(true) : setErrorCountry(false);
    value && setSelectedCountry(value);
  };

  const handleSelectStates = async (value: IStates | null) => {
    setCity(null);
    !value ? setErrorStates(true) : setErrorStates(false);
    value && setSelectedStates(value);
  };

  const handleSelectCities = async (value: ICities | null) => {
    !value ? setErrorCity(true) : setErrorCity(false);
    value && setCity(value);
  };

  const handleSave = async () => {
    setIsLoading(true);
    let finalFormValues: any = {};
    Object.entries(formValues).forEach(([key, value], index) => {
      //@ts-ignore
      finalFormValues[key] = typeof value === "string" ? value.trim() : value;
    });
    finalFormValues.currencies = finalFormValues.currencies.concat(
      currencySelected?.id
    );

    const alreadyExistValues = await validateExistFields(finalFormValues);

    if (alreadyExistValues) {
      dispatch(setMessageAlert("companyManagement.alreadyExistFields"));
      dispatch(setTypeAlert("error"));
      setShowConfirmDialog(false);
      setIsLoading(false);

      return dispatch(setOpenAlert());
    }

    try {
      activeCompany && activeCompany.id
        ? await dispatch(startEditOperator(finalFormValues, activeCompany.id))
        : await dispatch(startAddOperator(finalFormValues));

      setIsLoading(false);
      setShowConfirmDialog(false);
      setExistsName(false);
      setExistsNit(false);
      setExistsEmail(false);

      reset();
      dispatch(setFlagForm({ flagFormCreate: false }));
    } catch (error) {
      setIsLoading(false);
      dispatch(setMessageAlert("companyManagement.errorConfig"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
  const handleCancel = () => {
    setMessage("dialog.sureExit");
    setCancel(true);
    setOpenDialog(true);
  };

  const handleShowDialog = async () => {
    setIsSubmit(true);

    const isValid = await validateFormFields(formValues);

    if (!isValid) {
      dispatch(setMessageAlert("alerts.completeRequiredFields"));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }
    setShowConfirmDialog(true);
    setIsSubmit(false);
  };

  const handleCleanForm = () => {
    setMessage("dialog.sureClean");
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCancel(false);
  };

  const cleanSelectFields = () => {
    setCity(null);
    setSelectedCountry(null);
    setSelectedStates(null);
  };

  const handleAccept = () => {
    handleCloseDialog();
    if (cancel) {
      // Cerrar modal
      dispatch(setFlagForm({ flagFormCreate: false }));
      dispatch(setFlagFormEdit({ flagFormEdit: false }));
      dispatch(setFlagFormConfig({ flagFormConfig: false }));
      dispatch(setActiveCompany(null));
    } else {
      // Resetear formulario
      reset();
      cleanSelectFields();
    }
    setCancel(false);
    setIsSubmit(false);
    setExistsEmail(false);
    setExistsName(false);
    setExistsNit(false);
  };
  const validateEmail = async (email: string) => {
    return await schema.isValid({ email: formValues.email });
  };

  /**
   * Soporta el evento para validar el número de telefono correcto
   */
  const validatePhoneNumber = async () => {
    if (!selectedCountry) {
      return;
    }
    const currentCountry = countries?.find(
      (country) => country.id === selectedCountry.id
    );
    if (!currentCountry) {
      return;
    }

    const { valid, length, startOf } = validatePhoneByCountry(
      currentCountry.code,
      formValues.phone.toString()
    ); // Validar el número de teléfono

    if (!valid) {
      const params: any = {
        length,
        rest: startOf
          ? t("user.startOfNumber", {
              numbers: startOf.toString(),
            })
          : "",
      };

      setParamsPhoneError(params);
    }
    return valid;
  };

  /**
   * VALIDATIONS FORM STRING TEXT
   */
  const validNameError = !validName
    ? `${t("alerts.invalidFormat")}. ${t("alerts.enterMax")} 150 ${t(
        "alerts.letters"
      )}`
    : (existsName && t("companyManagement.alreadyUseName")) || "";

  const validNitError = !validNit
    ? t("companyManagement.lengthNitNumber")
    : (existsNit && t("companyManagement.alreadyUseNit")) || "";

  const validEmailError = existsEmail
    ? t("companyManagement.alreadyUseEmail")
    : noValidEmail && t("user.invalidEmail");

  const validRepresentativeError = !validRepresentative
    ? `${t("alerts.invalidFormat")}. ${t("alerts.enterMax")} 150 ${t(
        "alerts.letters"
      )}`
    : "";

  const validPhoneError =
    formValues.phone !== "" &&
    noValidPhone &&
    t("user.invalidPhone", paramsPhoneError);

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
    if (typeAlert === "success") {
      dispatch(setFlagForm({ flagFormCreate: false }));
      dispatch(setActiveCompany(null));
    }
  };

  return (
    <Col xs={12}>
      <Row style={{ marginBottom: 10, marginLeft: 5 }}>
        <Col xs={12}>
          <Typography color="text.primary" style={{ fontSize: "16px" }}>
            {t("companyManagement.title")} /{" "}
            {t(
              !!activeCompany
                ? "companyManagement.editCompany"
                : "companyManagement.createCompany"
            )}
          </Typography>
        </Col>
      </Row>
      <div className="container ">
        <div className="filter p-5">
          <Grid container spacing={2}>
            <Grid item xs={12} className="mb-4">
              <Box
                display="flex"
                className="pb-1"
                borderBottom={1}
                borderColor="#02D0E7"
              >
                <i
                  className="fa-solid fa-circle-info"
                  style={{ color: "#2C97DF", marginRight: 9, fontSize: 17 }}
                ></i>
                <p className="txt-primary-md">
                  {t(
                    activeCompany
                      ? "companyManagement.formEditCompany"
                      : "companyManagement.formCreateCompany"
                  )}
                </p>
              </Box>
            </Grid>
            <Grid item xs={4} className="mb-3">
              <TextField
                name="name"
                variant="outlined"
                size="small"
                type="text"
                label={t("companyManagement.companyName") + "*"}
                fullWidth
                sx={styles}
                autoComplete="off"
                onChange={handleInputChange}
                value={formValues.name}
                error={
                  (isSubmit && formValues.name === "") ||
                  existsName ||
                  !validName
                }
                helperText={
                  validNameError ||
                  (isSubmit && formValues.name === "" && t("form.requiredFile"))
                }
                onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                  !regLetters(e.key) && e.preventDefault()
                }
                inputProps={{ maxLength: 100 }}
                
              />
            </Grid>
            <Grid item xs={4} className="mb-3">
              <TextField
                name="nit"
                variant="outlined"
                size="small"
                type="text"
                disabled={!!activeCompany}
                label={t("companyManagement.nit") + "*"}
                fullWidth
                error={
                  (isSubmit && formValues.nit === "") || existsNit || !validNit
                }
                sx={styles}
                autoComplete="off"
                onChange={handleInputChange}
                value={formValues.nit}
                helperText={
                  validNitError ||
                  (isSubmit && formValues.nit === "" && t("form.requiredFile"))
                }
                onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                  !regNit(e.key) && e.preventDefault()
                }
                inputProps={{ maxLength: 20 }}
                
              />
            </Grid>
            <Grid item xs={4} className="mb-3">
              <TextField
                name="email"
                variant="outlined"
                size="small"
                type="text"
                label={t("companyManagement.companyEmail") + "*"}
                fullWidth
                error={
                  (isSubmit && formValues.email === "") ||
                  existsEmail ||
                  noValidEmail
                }
                sx={styles}
                autoComplete="off"
                onChange={handleInputChange}
                value={formValues.email}
                helperText={
                  validEmailError ||
                  (isSubmit &&
                    formValues.email === "" &&
                    t("form.requiredFile"))
                }
                onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                  !regEmail(e.key) && e.preventDefault()
                }
                inputProps={{ maxLength: 100 }}
                
              />
            </Grid>
            <Grid item xs={4} className="mb-3">
              <TextField
                name="legalRepresentative"
                variant="outlined"
                size="small"
                type="text"
                label={t("companyManagement.legalRepresentative") + "*"}
                fullWidth
                error={
                  (isSubmit && formValues.legalRepresentative === "") ||
                  !validRepresentative
                }
                sx={styles}
                autoComplete="off"
                onChange={handleInputChange}
                value={formValues.legalRepresentative}
                onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                  !regLetters(e.key) && e.preventDefault()
                }
                helperText={
                  validRepresentativeError ||
                  (isSubmit &&
                    formValues.legalRepresentative === "" &&
                    t("form.requiredFile"))
                }
                inputProps={{ maxLength: 150 }}
                
              />
            </Grid>
            {countries && (
              <Grid item xs={4} className="mb-3">
                <Autocomplete
                  disabled={!!activeCompany}
                  id="countries"
                  data-testid="countriesSelect"
                  options={countries}
                  disableClearable
                  // @ts-ignore
                  value={selectedCountry}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event: any, value: ICountries | null) => {
                    handleSelectCountry(value);
                    setCurrencySelected(null);
                  }}
                  renderOption={(props, option) => {
                    const { name } = option;
                    return (
                      <span {...props} style={{ color: "#000" }}>
                        {name}
                      </span>
                    );
                  }}
                  renderInput={({ inputProps, ...rest }) => (
                    <TextField
                      {...rest}
                      inputProps={{ ...inputProps, readOnly: true }}
                      name="countries"
                      variant="outlined"
                      size="small"
                      label={t("user.country") + "*"}
                      error={errorCountry}
                    />
                  )}
                />
                <FormHelperText>
                  {errorCountry && (
                    <label className="ml-3" style={{ color: "#d32f2f" }}>
                      {t("form.requiredFile")}
                    </label>
                  )}
                </FormHelperText>
              </Grid>
            )}
            <Grid item xs={4} className="mb-3">
              <Autocomplete
                name="state"
                data-testid="stateSelect"
                id="state"
                options={states ? states : []}
                renderOption={(props, option) => {
                  return (
                    <span {...props} style={{ color: "#000" }}>
                      {option.names}
                    </span>
                  );
                }}
                disabled={flagStates}
                disableClearable
                // @ts-ignore
                value={selectedStates}
                getOptionLabel={(option) => option.names}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event: any, value: IStates | null) =>
                  handleSelectStates(value)
                }
                renderInput={({ inputProps, ...rest }) => (
                  <TextField
                    {...rest}
                    inputProps={{ ...inputProps, readOnly: true }}
                    name="state"
                    variant="outlined"
                    size="small"
                    label={t("user.states") + "*"}
                    error={errorStates}
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-error": {
                        "& fieldset": {
                          borderColor: "#d32f2f",
                        },
                      },
                    }}
                  />
                )}
              />
              <FormHelperText>
                {errorStates && (
                  <label className="ml-3" style={{ color: "#d32f2f" }}>
                    {t("form.requiredFile")}
                  </label>
                )}
              </FormHelperText>
            </Grid>
            <Grid item xs={4} className="mb-3">
              <Autocomplete
                name="city"
                data-testid="citySelect"
                id="city"
                options={cities ? cities : []}
                disabled={flagCity}
                disableClearable
                // @ts-ignore
                value={cityData}
                getOptionLabel={(option) => option.names}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event: any, value: ICities | null) =>
                  handleSelectCities(value)
                }
                renderOption={(props, option) => {
                  return (
                    <span {...props} style={{ color: "#000" }}>
                      {option.names}
                    </span>
                  );
                }}
                renderInput={({ inputProps, ...rest }) => (
                  <TextField
                    {...rest}
                    inputProps={{ ...inputProps, readOnly: true }}
                    name="city"
                    variant="outlined"
                    size="small"
                    label={t("user.city") + "*"}
                    error={errorCity}
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-error": {
                        "& fieldset": {
                          borderColor: "#d32f2f",
                        },
                      },
                    }}
                  />
                )}
              />
              <FormHelperText>
                {errorCity && (
                  <label className="ml-3" style={{ color: "#d32f2f" }}>
                    {t("form.requiredFile")}
                  </label>
                )}
              </FormHelperText>
            </Grid>
            <Grid item xs={4} className="mb-3">
              <TextField
                name="phone"
                variant="outlined"
                size="small"
                label={t("user.phone") + "*"}
                fullWidth
                onChange={handleInputChange}
                value={formValues.phone}
                error={
                  (isSubmit && formValues.phone === "") ||
                  (formValues.phone !== "" && noValidPhone)
                }
                sx={styles}
                autoComplete="off"
                helperText={
                  validPhoneError ||
                  (isSubmit &&
                    formValues.phone === "" &&
                    t("form.requiredFile"))
                }
                onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (formValues.phone === "") {
                    e.key === "0"
                      ? e.preventDefault()
                      : !regNumbers(e.key) && e.preventDefault();
                  } else {
                    !regNumbers(e.key) && e.preventDefault();
                  }
                }}
                
              />
            </Grid>
            <Grid item xs={6} className="mb-3">
              <Autocomplete
                disabled={
                  !!activeCompany && activeCompany.currencyOp.length > 0
                }
                data-testid="currencySelect"
                id="currencySelect"
                options={currencies}
                disableClearable
                // @ts-ignore
                value={currencySelected}
                getOptionLabel={(option) => option.currency}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event: any, value: ICurrencies | null) =>
                  value && setCurrencySelected(value)
                }
                renderOption={(props, option, { selected }) => (
                  <Box
                    component="li"
                    sx={{
                      "& > img": { mr: 2, flexShrink: 0, borderRadius: "50%" },
                      color: "black",
                    }}
                    {...props}
                  >
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      sx={{
                        marginRight: 2,
                        "&.Mui-checked": {
                          color: "#04BF9D",
                        },
                      }}
                      checked={selected}
                    />
                    <img
                      loading="lazy"
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                      src={option.imgUrl}
                      alt=""
                    />
                    {option.label} - {option.currency}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={isSubmit && !currencySelected}
                    helperText={
                      isSubmit && !currencySelected && t("form.requiredFile")
                    }
                    // @ts-ignore
                    //InputLabelProps={{ shrink: false }}
                    label={t("companyManagement.currencies")}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "disabled",
                      readOnly: true,
                    }}
                  />
                )}
              />
            </Grid>
            {activeCompany && (
              <Grid item xs={4} className="mb-3">
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel id="town-label">{t("form.state")}</InputLabel>
                  <Select
                    labelId="state-label"
                    name="stateOperator"
                    size="small"
                    fullWidth
                    onChange={handleInputChange}
                    value={formValues.stateOperator}
                    sx={styles}
                    input={<OutlinedInput label={t("form.state")} />}
                  >
                    <MenuItem value={State.ACTIVE}>
                      {t("state.active")}
                    </MenuItem>
                    <MenuItem value={State.INACTIVE}>
                      {t("state.inactive")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              sx={{ height: "60px" }}
            >
              <Button
                style={{ border: "none", outline: "none" }}
                variant="contained"
                className="footer-btn danger-btn ripple"
                onClick={handleCancel}
              >
                <i className="fa-solid fa-x"></i>
                {t("button.cancel")}
              </Button>
              <Button
                style={{ border: "none", outline: "none" }}
                variant="contained"
                id="btnSave"
                className="footer-btn primary-btn ripple"
                onClick={handleShowDialog}
              >
                <i className="fa-solid fa-check"></i>
                {t(activeCompany ? "button.edit" : "button.save")}
              </Button>
              <Button
                type="button"
                style={{
                  border: "none",
                  outline: "none",
                  marginLeft: "4px",
                  opacity: !!activeCompany ? "0.3" : "",
                }}
                onClick={handleCleanForm}
                disabled={!!activeCompany}
              >
                <img src={clearLogo} alt="Icon clear" />
              </Button>
            </Grid>
            <MyDialog
              id="confirmationDialog"
              open={showConfirmDialog}
              title={t("dialog.confirmationWindow")}
              message={
                activeCompany
                  ? t("companyManagement.shureApplyChanges")
                  : t("companyManagement.shureCreateCompany")
              }
              handleAccept={handleSave}
              handleClose={() => setShowConfirmDialog(false)}
              isLoading={isLoading}
            />

            <MyDialog
              open={openDialog}
              title={t("dialog.confirmationWindow")}
              message={t(message)}
              handleAccept={handleAccept}
              handleClose={handleCloseDialog}
            />

            <MyAlert
              open={openAlert}
              message={messageAlert ? messageAlert : ""}
              typeAlert={typeAlert}
              handleClose={onCloseAlert}
            />
          </Grid>
        </div>
      </div>
    </Col>
  );
};
