import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Tab, Tabs } from "@mui/material";
import { ExclusionsByCategory } from "../../components/exclusions/ExclusionsByCategory";
import { Row } from "reactstrap";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { ExclusionsByAgileCheck } from "../../components/exclusions/ExclusionsByAgileCheck";
import { useDispatch } from "react-redux";
import { setPlayerRedux } from "../../../redux/actions/managementPlayersActions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const styles = {
  marginRight: "3px !important",
  width: "260px",
  boxShadow: "3px 0px 1px rgba(0, 0, 0, 0.25) !important",
  borderRadius: "0px 5px 0px 0px",
  backgroundColor: "#EEEEEE",
  color: "#747474",
  "&.Mui-selected": {
    border: "none",
    outline: "none",
    boxShadow: "3px 0px 1px rgba(2, 208, 231, 0.25) !important",
    borderRadius: "0px 5px 0px 0px",
    color: "#02D0E7",
    backgroundColor: "#C6F9FF",
    fontWeight: 700,
  },
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Exclusions = () => {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setPlayerRedux(null));
    setValue(newValue);
  };
  const { t } = useTranslation();

  return (
    <div className="content w-100">
      <Row style={{ marginBottom: 10, marginLeft: 0 }}>
        <MyBreadCrumbs
          titles={[
            t("sidebar.player"),
            t(value === 0 ? "exclusions.title" : "exclusions.restrictiveLists"),
          ]}
        />
      </Row>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          style={{ border: "none", outline: "none" }}
          TabIndicatorProps={{
            style: {
              display: "none",
              width: "250px",
            },
          }}
        >
          <Tab
            sx={styles}
            label={<span className="spanTab">{t("exclusions.title")}</span>}
            {...a11yProps(0)}
          />
          <Tab
            sx={styles}
            label={<span>{t("exclusions.restrictiveLists")}</span>}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <div>
        <TabPanel value={value} index={0}>
          <ExclusionsByCategory />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <ExclusionsByAgileCheck />
        </TabPanel>
      </div>
    </div>
  );
};
