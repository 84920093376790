import { IPromotionsAds } from "../../interfaces/promotionsAds";
import { Types } from "../types/types";

interface IPromotionsState {
  promotions: Array<IPromotionsAds> | null
}

interface IPromotionsActions {
  type: Types;
  payload?: Array<IPromotionsAds> | null
}
const initialState: IPromotionsState = {
  promotions: [],
};

export const managementPromotionsReducer = (
  state = initialState,
  action: IPromotionsActions
): IPromotionsState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setPromotionsAds:
      return {
        ...state,
        promotions: payload as any,
      };
    default:
      return state;
  }
};
