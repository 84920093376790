import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  setCloseAlert,
  setDocumentTypes,
  setDocumentTypesRedux,
} from "../../../redux/actions/helperActions";
import { MyAlert } from "../../components/custom/MyAlert";
import { ManagementDepositTabs } from "./tabs/ManagementDepositTabs";

export const ManagementDeposits = () => {
  const dispatch = useDispatch();

  /**
   * Soporta el token para enviar con la funcion de crear o eliminar
   */
  const { token } = useSelector((state: AppState) => state.auth);
  const { openAlert, messageAlert, typeAlert } = useSelector(
    (state: AppState) => state.helper
  );

  useEffect(() => {
    // dispatch(setCloseAlert());
    token && dispatch(setDocumentTypes(token));

    return () => {
      dispatch(setDocumentTypesRedux([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseAlert = () => {
    dispatch(setCloseAlert());
  };

  return (
    <div className="content w-100 mb-4">
      <Row>
        {/* TABS */}
        <Col xs={12} className="mt-4">
          <ManagementDepositTabs />
        </Col>
      </Row>
      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />
    </div>
  );
};
