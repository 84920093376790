import React, { useState } from "react";
import { Grid } from "@mui/material";
import { MyColorPicker } from "../../../../../../components/Custom/Table/MyColorPicker";
import { ColorResult } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../redux/reducers/rootReducer";
import "./formOperatorContent.scss";
import { setUpContainerColors } from "../../../../../../redux/actions/contentManagementActions";
import { useTranslation } from "react-i18next";

export const FormCustomColors = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { colors } = useSelector((state: AppState) => state.contentManagement);
  const [pickerOpen, setPickerOpen] = useState({
    index: "primary100",
    open: false,
  });

  const handleBgColorButtonClick = (index: string) => {
    setPickerOpen({
      index,
      open: !pickerOpen.open,
    });
  };
  const handleBgColorChange = (index: string, color: ColorResult) => {
    if (color) {
      const selectedColor = color.hex;
      dispatch(
        setUpContainerColors({
          ...colors,
          [index]: selectedColor,
        })
      );
    }
  };

  return (
    <Grid container className="operator-content-form">
      <Grid item container xs={3}>
        <div className="content-flex">
          <span>primary 100</span>
          <MyColorPicker
            defaultColor={colors?.primary100}
            open={pickerOpen.index === "primary100" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("primary100")}
            onChange={(e) => handleBgColorChange("primary100", e)}
          />
        </div>
        <div className="content-flex">
          <span>primary 90</span>
          <MyColorPicker
            defaultColor={colors?.primary90}
            open={pickerOpen.index === "primary90" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("primary90")}
            onChange={(e) => handleBgColorChange("primary90", e)}
          />
        </div>
        <div className="content-flex">
          <span>primary 80</span>
          <MyColorPicker
            defaultColor={colors?.primary80}
            open={pickerOpen.index === "primary80" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("primary80")}
            onChange={(e) => handleBgColorChange("primary80", e)}
          />
        </div>
        <div className="content-flex">
          <span>primary 70</span>
          <MyColorPicker
            defaultColor={colors?.primary70}
            open={pickerOpen.index === "primary70" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("primary70")}
            onChange={(e) => handleBgColorChange("primary70", e)}
          />
        </div>
        <div className="content-flex">
          <span>primary 60</span>
          <MyColorPicker
            defaultColor={colors?.primary60}
            open={pickerOpen.index === "primary60" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("primary60")}
            onChange={(e) => handleBgColorChange("primary60", e)}
          />
        </div>
      </Grid>
      <Grid item container xs={3}>
        <div className="content-flex">
          <span>primary 50</span>
          <MyColorPicker
            defaultColor={colors?.primary50}
            open={pickerOpen.index === "primary50" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("primary50")}
            onChange={(e) => handleBgColorChange("primary50", e)}
          />
        </div>
        <div className="content-flex">
          <span>primary 40</span>
          <MyColorPicker
            defaultColor={colors?.primary40}
            open={pickerOpen.index === "primary40" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("primary40")}
            onChange={(e) => handleBgColorChange("primary40", e)}
          />
        </div>
        <div className="content-flex">
          <span>primary 20</span>
          <MyColorPicker
            defaultColor={colors?.primary20}
            open={pickerOpen.index === "primary20" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("primary20")}
            onChange={(e) => handleBgColorChange("primary20", e)}
          />
        </div>
        <div className="content-flex">
          <span>primary 10</span>
          <MyColorPicker
            defaultColor={colors?.primary10}
            open={pickerOpen.index === "primary10" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("primary10")}
            onChange={(e) => handleBgColorChange("primary10", e)}
          />
        </div>
        <div className="content-flex">
          <span>primary 05</span>
          <MyColorPicker
            defaultColor={colors?.primary05}
            open={pickerOpen.index === "primary05" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("primary05")}
            onChange={(e) => handleBgColorChange("primary05", e)}
          />
        </div>
      </Grid>
      <Grid item container xs={3}>
        <div className="content-flex">
          <span>secondary 100</span>
          <MyColorPicker
            defaultColor={colors?.secondary100}
            open={pickerOpen.index === "secondary100" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("secondary100")}
            onChange={(e) => handleBgColorChange("secondary100", e)}
          />
        </div>
        <div className="content-flex">
          <span>secondary 90</span>
          <MyColorPicker
            defaultColor={colors?.secondary90}
            open={pickerOpen.index === "secondary90" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("secondary90")}
            onChange={(e) => handleBgColorChange("secondary90", e)}
          />
        </div>
        <div className="content-flex">
          <span>secondary 70</span>
          <MyColorPicker
            defaultColor={colors?.secondary70}
            open={pickerOpen.index === "secondary70" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("secondary70")}
            onChange={(e) => handleBgColorChange("secondary70", e)}
          />
        </div>
        <div className="content-flex">
          <span>secondary 20</span>
          <MyColorPicker
            defaultColor={colors?.secondary20}
            open={pickerOpen.index === "secondary20" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("secondary20")}
            onChange={(e) => handleBgColorChange("secondary20", e)}
          />
        </div>
        <div className="content-flex">
          <span>gray 100</span>
          <MyColorPicker
            defaultColor={colors?.gray100}
            open={pickerOpen.index === "gray100" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("gray100")}
            onChange={(e) => handleBgColorChange("gray100", e)}
          />
        </div>
      </Grid>
      <Grid item container xs={3}>
        <div className="content-flex">
          <span>gray 60</span>
          <MyColorPicker
            defaultColor={colors?.gray60}
            open={pickerOpen.index === "gray60" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("gray60")}
            onChange={(e) => handleBgColorChange("gray60", e)}
          />
        </div>
        <div className="content-flex">
          <span>gray 50</span>
          <MyColorPicker
            defaultColor={colors?.gray50}
            open={pickerOpen.index === "gray50" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("gray50")}
            onChange={(e) => handleBgColorChange("gray50", e)}
          />
        </div>
        <div className="content-flex">
          <span>gray 20</span>
          <MyColorPicker
            defaultColor={colors?.gray20}
            open={pickerOpen.index === "gray20" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("gray20")}
            onChange={(e) => handleBgColorChange("gray20", e)}
          />
        </div>
        <div className="content-flex">
          <span>white</span>
          <MyColorPicker
            defaultColor={colors?.white}
            open={pickerOpen.index === "white" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("white")}
            onChange={(e) => handleBgColorChange("white", e)}
          />
        </div>
        <div className="content-flex">
          <span>error</span>
          <MyColorPicker
            defaultColor={colors?.error}
            open={pickerOpen.index === "error" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("error")}
            onChange={(e) => handleBgColorChange("error", e)}
          />
        </div>
      </Grid>
      <Grid item container xs={12} marginTop={5}>
        <h3>{t("contentManagement.emailColors")}</h3>
        <Grid item container xs={12}>
          <Grid item container xs={4}>
            <div className="content-flex">
              <span>{t("contentManagement.emailHeaderBackgroundColor")}</span>
              <MyColorPicker
                defaultColor={colors?.emailHeaderBackgroundColor}
                open={
                  pickerOpen.index === "emailHeaderBackgroundColor" &&
                  pickerOpen.open
                }
                onColorButtonClick={() =>
                  handleBgColorButtonClick("emailHeaderBackgroundColor")
                }
                onChange={(e) =>
                  handleBgColorChange("emailHeaderBackgroundColor", e)
                }
              />
            </div>
            <div className="content-flex">
              <span>{t("contentManagement.emailTitleColor")}</span>
              <MyColorPicker
                defaultColor={colors?.emailTitleColor}
                open={pickerOpen.index === "emailTitleColor" && pickerOpen.open}
                onColorButtonClick={() =>
                  handleBgColorButtonClick("emailTitleColor")
                }
                onChange={(e) => handleBgColorChange("emailTitleColor", e)}
              />
            </div>
            <div className="content-flex">
              <span>{t("contentManagement.emailTextColor")}</span>
              <MyColorPicker
                defaultColor={colors?.emailTextColor}
                open={pickerOpen.index === "emailTextColor" && pickerOpen.open}
                onColorButtonClick={() =>
                  handleBgColorButtonClick("emailTextColor")
                }
                onChange={(e) => handleBgColorChange("emailTextColor", e)}
              />
            </div>
            <div className="content-flex">
              <span>{t("contentManagement.emailButtonBackgroundColor")}</span>
              <MyColorPicker
                defaultColor={colors?.emailButtonBackgroundColor}
                open={
                  pickerOpen.index === "emailButtonBackgroundColor" &&
                  pickerOpen.open
                }
                onColorButtonClick={() =>
                  handleBgColorButtonClick("emailButtonBackgroundColor")
                }
                onChange={(e) =>
                  handleBgColorChange("emailButtonBackgroundColor", e)
                }
              />
            </div>
          </Grid>
          <Grid item container xs={4}>
            <div className="content-flex">
              <span>{t("contentManagement.emailFooterBackgroundColor")}</span>
              <MyColorPicker
                defaultColor={colors?.emailFooterBackgroundColor}
                open={
                  pickerOpen.index === "emailFooterBackgroundColor" &&
                  pickerOpen.open
                }
                onColorButtonClick={() =>
                  handleBgColorButtonClick("emailFooterBackgroundColor")
                }
                onChange={(e) =>
                  handleBgColorChange("emailFooterBackgroundColor", e)
                }
              />
            </div>
            <div className="content-flex">
              <span>
                {t("contentManagement.emailFooterButtonBackgroundColor")}
              </span>
              <MyColorPicker
                defaultColor={colors?.emailFooterButtonBackgroundColor}
                open={
                  pickerOpen.index === "emailFooterButtonBackgroundColor" &&
                  pickerOpen.open
                }
                onColorButtonClick={() =>
                  handleBgColorButtonClick("emailFooterButtonBackgroundColor")
                }
                onChange={(e) =>
                  handleBgColorChange("emailFooterButtonBackgroundColor", e)
                }
              />
            </div>
            <div className="content-flex">
              <span>{t("contentManagement.emailFooterButtonColor")}</span>
              <MyColorPicker
                defaultColor={colors?.emailFooterButtonColor}
                open={
                  pickerOpen.index === "emailFooterButtonColor" &&
                  pickerOpen.open
                }
                onColorButtonClick={() =>
                  handleBgColorButtonClick("emailFooterButtonColor")
                }
                onChange={(e) =>
                  handleBgColorChange("emailFooterButtonColor", e)
                }
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
