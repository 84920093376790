import { FC } from "react";
import { TableCell } from "@mui/material";
import moment from "moment";
interface props {
  row: any;
}
export const RowRequestCard: FC<props> = ({ row }) => {
  return (
    <>
      <TableCell align="center">{row.playerId}</TableCell>
      <TableCell align="center">{row.phone}</TableCell>
      <TableCell align="center">
        {moment(row.requestCardDate).format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
    </>
  );
};
