import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, KeyboardEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { MyDialog } from "../../custom/MyDialog";
import { BalanceType } from "../../../../enums/enums";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { MyTextField } from "../../custom/MyTextField";
import { regNumbers } from "../../../../utils/utils";
import { setLoadingState } from "../../../../redux/actions/helperActions";
import { setDealerWallet } from "../../../../redux/actions/walletActions";
import { startCreateIrrigationToDealer } from "../../../../redux/actions/transactionsActions";

export const GenerateTransferDealer = () => {
  const { dealer: dealerWallet, associate } = useSelector(
    (state: AppState) => state.wallet
  );
  const [openDialog, setOpenDialog] = useState(false);

  const { t } = useTranslation();
  const formikRef = useRef(null);

  const dispatch = useDispatch();
  const initialValues = {
    amount: "",
    wallet: "",
  };
  const validationSchema = yup.object({
    amount: yup.number().required(t("form.requiredFile")),
    wallet: yup.string().required(t("form.requiredFile")),
  });
  const getWalletBalance = (wallet: BalanceType) => {
    if (!associate) {
      return 0;
    }

    switch (wallet) {
      case BalanceType.BETTING:
        return parseFloat(associate.betsBalance);
      case BalanceType.REFILL:
        return parseFloat(associate.refillsBalance);

      default:
        return 0;
    }
  };
  const handleGenerateTransfer = async () => {
    if (!formikRef.current || !dealerWallet) {
      return false;
    }

    dispatch(setLoadingState({ loading: true }));
    setOpenDialog(false);

    const { setSubmitting, values, resetForm } = formikRef.current as any;
    setSubmitting(true);
    await dispatch(
      startCreateIrrigationToDealer({
        amount: values.amount,
        wallet: values.wallet,
        dealer:dealerWallet.id
      })
    );
    resetForm();
    setSubmitting(false);
    dispatch( setDealerWallet(null) )

    dispatch(setLoadingState({ loading: false }));
  };
  return (
    <Box className="filter" py={1}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <i
              className="fa-solid fa-circle-info"
              style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
            ></i>
            <Typography
              color="text.primary"
              style={{ fontSize: "16px", fontWeight: 500 }}
            >
              {t("irrigation.generateTransfer")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange
            onSubmit={async (data, { setSubmitting }) => {
              setOpenDialog(true);
            }}
          >
            {({ handleChange, isSubmitting, isValid, values }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <MyTextField
                      name="wallet"
                      select
                      label={t("transactions.balanceType") + "*"}
                      onChange={handleChange}
                    >
                      <MenuItem value={BalanceType.REFILL}>
                        {t("associates.refillBalance")}
                      </MenuItem>
                      <MenuItem value={BalanceType.BETTING}>
                        {t("irrigation.bettingBalance")}
                      </MenuItem>
                    </MyTextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <MyTextField
                      name="amount"
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={`${t("irrigation.transferValue")}*`}
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                        !regNumbers(e.key) && e.preventDefault()
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      className="primary-btn ripple"
                      style={{
                        border: "none",
                        outline: "none",
                        width: "20%",

                        color: "white",
                        opacity:
                          !dealerWallet ||
                          !isValid ||
                          getWalletBalance(values.wallet as any) <
                            parseFloat(values.amount)
                            ? "0.5"
                            : "",
                      }}
                      disabled={
                        !dealerWallet ||
                        !isValid ||
                        getWalletBalance(values.wallet as any) <
                          parseFloat(values.amount)
                      }
                    >
                      {isSubmitting ? (
                        <CircularProgress size={"1.6rem"} color="inherit" />
                      ) : (
                        t("button.save").toUpperCase()
                      )}
                    </Button>
                  </Grid>
                </Grid>
                <MyDialog
                  open={openDialog}
                  title={t("dialog.confirmationWindow")}
                  message={t("irrigation.sureMakeTransfer")}
                  handleAccept={handleGenerateTransfer}
                  handleClose={() => setOpenDialog(false)}
                />
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );
};
