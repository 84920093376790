import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { startLogout } from "../redux/actions/authActions";

/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */

interface Props {
  onIdle: () => void;
  isLogged: boolean;
  idleTime: number;
}

export const useIdleTimeout = ({ onIdle, idleTime = 15, isLogged }: Props) => {
  const dispatch = useDispatch();
  const idleTimeout = 1000 * 60 * idleTime;
  const promptBeforeIdle = 1000 * 60 * (idleTime / 3);
  const [isIdle, setIdle] = useState(false);
  const handleIdle = async () => {
    if (isLogged) {
      setIdle(true);
      await dispatch(startLogout());
    }
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle,
    onPrompt: onIdle,
    onIdle: handleIdle,
    stopOnIdle: true,
  });

  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};
export default useIdleTimeout;
