import { FC } from "react";
import { useTranslation } from "react-i18next";

import TableCell from "@mui/material/TableCell";
import moment from "moment";

interface Props {
  row: any;
}

export const RowDataTableCommissionerPointSale: FC<Props> = ({ row }) => {
  const { t } = useTranslation();

  return (
    <>
      <TableCell className="word-break">{row.name}</TableCell>
      <TableCell className="word-break">
        {row.state &&
          moment(row.activationDate).format("DD-MM-YYYY hh:mm:ss a")}
      </TableCell>
      {row.totalPercentage >= 0 && (
        <TableCell className="word-break">{row.totalPercentage}</TableCell>
      )}
      <TableCell align="center">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                row.state
                  ? "d-flex justify-content-center cellRowActive"
                  : "d-flex justify-content-center cellRowInactive"
              }
            >
              <div className="d-flex col-sm-4 justify-content-center">
                {row.state ? (
                  <>{t("state.active")}</>
                ) : (
                  <>{t("state.inactive")}</>
                )}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
    </>
  );
};
