import { Roles_Enum } from "../enums/enums";
import { IDataUserStatus } from "../interfaces/players";
import { IUserData } from "../redux/interfaces/users";

export const getUsers = (
  token: string,
  userId?: number,
  nameRole?: string,
  page?: number,
  rowsPerPage?: number | null,
  operatorId?: number
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const params = rowsPerPage
    ? operatorId
      ? `operatorId=${operatorId}&page=${page}&perPage=${rowsPerPage}`
      : `page=${page}&perPage=${rowsPerPage}`
    : operatorId
    ? `operatorId=${operatorId}&page=${page}`
    : `page=${page}`;

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}users?${params}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const createUserService = (
  data: IUserData,
  token: string,
  superAdmin: boolean
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const isSuper = superAdmin ? `super=${superAdmin}` : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}users?${isSuper}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const editUserService = (data: IUserData, token: string, id: number) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}users/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((response) => response.json());
};

export const filterUsers = (
  token: string,
  userId: number | null,
  nameRole: string,
  params: any,
  operatorId?: number | null,
  validateExists?: boolean,
  page?: number | null,
  perPage?: number | null
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const isSuper = nameRole === Roles_Enum.SUPER_ADMIN;

  const totalParams =
    validateExists || isSuper
      ? {
          ...params,
          isSuper,
        }
      : {
          ...params,
          operatorId,
        };

  const queryString = new URLSearchParams(totalParams).toString();
  const paginationQuery =
    page && perPage ? `&page=${page}&perPage=${perPage}` : "";

  const validate = validateExists ? `validateExists=true` : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}users?&${queryString}${paginationQuery}&${validate}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const getUserById = (token: string, id: number) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}users/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const getUsersMainService = (token: string, params: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const queryString = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}users?&${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const addPlayersSettings = (
  token: string,
  settings: IDataUserStatus
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(settings),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}playersSettings/create/massive`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
