import { FC, useEffect } from "react";
import MyCustomTable from "../../../../components/Custom/Table/MyCustomTable";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  cashApproveColumns,
  cashColumns,
  cashExpiredColumns,
} from "../../../../helpers/headersWithdrawalTable";
import { RowDataTableCashWithdrawals } from "./RowDataTableCashWithdrawals";
import { StateTransaction, TransferConcept } from "../../../../enums/enums";
import { WithdrawalCashPdfDocument } from "../../../../components/Pdf/withdrawals/WithdrawalCashPdfDocument";
import { getWithdrawals } from "../../../../services/withdrawals";
import {
  asyncLoadingFunction,
  setChangeTab,
  setFlagFormConfig,
  setFlagFormModal,
} from "../../../../redux/actions/helperActions";
import {
  setActiveWithdrawal,
  startGetWithdrawals,
} from "../../../../redux/actions/transactionsActions";
import { MyCustomDialog } from "../../../../components/Custom/MyCustomDialog";
import { WithdrawalDetail } from "./WithdrawalDetail";
import { IWithdrawalData } from "../../../../interfaces/withdrawals";
import { ApproveWithDrawalForm } from "./ApproveWithDrawalForm";
import { WithdrawalApproveCashPdf } from "../../../../components/Pdf/withdrawals/WithdrawalApproveCashPdf";
import { WithdrawalExpiredCash } from "../../../../components/Pdf/withdrawals/WithdrawalExpiredCashPdf";
import { WithdrawalsApproveDataTable } from "../../../../hooks/useDataTable/withdrawals/WithdrawalsApproveDataTable";
import { WithdrawalsExpiredDataTable } from "../../../../hooks/useDataTable/withdrawals/WithdrawalsExpiredDataTable";
import { WithdrawalsCashDataTable } from "../../../../hooks/useDataTable/withdrawals/WithdrawalsCashDataTable";
interface props {
  stateWithDrawal: StateTransaction;
  filterParams: any;
}
export const TableWithDrawalsTab: FC<props> = ({
  stateWithDrawal,
  filterParams,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { rowsPerPage, flagFormConfig, flagFormModal } = useSelector(
    (state: AppState) => state.helper
  );
  const { token, user } = useSelector((state: AppState) => state.auth);
  const { withdrawals } = useSelector((state: AppState) => state.transactions);
  const columns =
    stateWithDrawal === StateTransaction.Approved
      ? cashApproveColumns
      : stateWithDrawal === StateTransaction.Expired
      ? cashExpiredColumns
      : cashColumns;
  const showActions = ![
    StateTransaction.Approved,
    StateTransaction.Expired,
  ].includes(stateWithDrawal);
  useEffect(() => {
    dispatch(setChangeTab(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = async (page: number, rows?: number) => {
    const params = {
      ...filterParams,
      page: page,
      perPage: rows || rowsPerPage,
      state: stateWithDrawal,
      paymentMethod: TransferConcept.CASH,
    };
    dispatch(
      asyncLoadingFunction(
        async () => await dispatch(startGetWithdrawals(params))
      )
    );
  };
  const getWithdrawalsAction = async () => {
    if (token && user) {
      const { page, perPage, ...rest } = filterParams;
      const params = {
        ...rest,
        state: stateWithDrawal,
        paymentMethod: TransferConcept.CASH,
      };
      return await getWithdrawals(token, user.operator?.id, params);
    }
  };
  const onCloseModalInfo = async () => {
    dispatch(setFlagFormConfig({ flagFormConfig: false }));
    dispatch(setActiveWithdrawal(null));
  };
  const onSeeMore = (row: IWithdrawalData) => {
    dispatch(setActiveWithdrawal(row));
    dispatch(setFlagFormConfig({ flagFormConfig: true }));
  };

  const handleOpenApproveForm = (row: IWithdrawalData) => {
    dispatch(setActiveWithdrawal(row));
    dispatch(
      setFlagFormModal({
        flagFormModal: true,
      })
    );
  };
  const handleCloseApproveForm = () => {
    dispatch(setActiveWithdrawal(null));
    dispatch(
      setFlagFormModal({
        flagFormModal: false,
      })
    );
  };
  return (
    <>
      <MyCustomTable
        columns={
          showActions
            ? [...columns, { align: "center", label: t("table.actions") }]
            : columns
        }
        data={withdrawals ? withdrawals.data : []}
        lastPage={withdrawals ? withdrawals.last_page : 1}
        totalData={withdrawals ? withdrawals.total : 0}
        renderBody={(item) => (
          <RowDataTableCashWithdrawals row={item} component={stateWithDrawal} />
        )}
        actions={
          !showActions
            ? undefined
            : {
                seeMore: true,
                approve: true,
                reject: false,
                see: false,
              }
        }
        onPageChange={handlePageChange}
        onSeeMore={onSeeMore}
        onApprove={handleOpenApproveForm}
        isSortingTable={true}
        exportData
        hookBuildTable={
          stateWithDrawal === StateTransaction.Approved
            ? WithdrawalsApproveDataTable
            : stateWithDrawal === StateTransaction.Expired
            ? WithdrawalsExpiredDataTable
            : WithdrawalsCashDataTable
        }
        getAllDataToExport={getWithdrawalsAction}
        pdfComponent={
          stateWithDrawal === StateTransaction.Approved
            ? WithdrawalApproveCashPdf
            : stateWithDrawal === StateTransaction.Expired
            ? WithdrawalExpiredCash
            : WithdrawalCashPdfDocument
        }
        titleCSV={t("table.neatWithdrawalCash")}
      />

      <MyCustomDialog
        open={!!flagFormConfig}
        content={<WithdrawalDetail isCash />}
        onClose={onCloseModalInfo}
        title={t("withdrawals.playerWithdrawalInformation")}
        width={"lg"}
        icon={
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 20 }}
          ></i>
        }
        okButton={true}
      />

      <MyCustomDialog
        open={!!flagFormModal}
        content={<ApproveWithDrawalForm />}
        onClose={handleCloseApproveForm}
        title={t("withdrawals.playerWithdrawalInformation")}
        width={"lg"}
        icon={
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 20 }}
          ></i>
        }
      />
    </>
  );
};
