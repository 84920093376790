import moment from "moment";
import { useSelector } from "react-redux";
import { headersRefills } from "../../../helpers/headersTables";
import { IRefills } from "../../../interfaces/refills";
import { AppState } from "../../../redux/reducers/rootReducer";
import { buildRedName, getCopyArrayOrObject } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

export const RefillsDataTable = (totalData: IRefills[]) => {
  const { data } = useSelector((state: AppState) => state.search);
  const { t } = useTranslation();

  const headersCvsStyle = headersRefills.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  // Obtener datos por vista
  const dataRefills =
    data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalRefills = totalData
    ? getCopyArrayOrObject(totalData)
    : [];

  // DATA POR VISTA
  const multiDataSetRefills = [
    {
      columns: headersCvsStyle,
      data: dataRefills.map((data: IRefills) => {
        const method =
          data.meta?.debitParty?.typeClass 
            ? `- ${t(`paymentMethods.${data.meta?.debitParty?.typeClass}`)}`
            : "";
        let array;
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.meta?.transactionReference || "",
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.email,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: `$${data.amount}`,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.transactionType.name + " " + method,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: buildRedName(data),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value:  moment(data.createAt).format("YYYY-MM-DD HH:mm:ss"),
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  //   DATA TOTAL
  const multiTotalDataSetRefills = [
    {
      columns: headersCvsStyle,
      data: dataTotalRefills.map((data: IRefills) => {
        const method =
          data.meta?.debitParty?.typeClass 
            ? `- ${t(`paymentMethods.${data.meta?.debitParty?.typeClass}`)}`
            : "";
        let array;
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.meta?.transactionReference || "",
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.email,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: `$${data.amount}`,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.transactionType.name + " " + method,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: buildRedName(data),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value:  moment(data.createAt).format("YYYY-MM-DD HH:mm:ss"),
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  return {
    dataPerView: dataRefills,
    dataTotal: dataTotalRefills,
    headersCSV: headersRefills,
    multiDataSet: multiDataSetRefills,
    multiTotalDataSet: multiTotalDataSetRefills,
  };
};
