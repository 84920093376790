import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import {
  DealerTypeEnum,
  DocumentTypes,
  Roles_Enum,
  State,
} from "../../../enums/enums";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { MyTextField } from "../custom/MyTextField";
import {
  regEmail,
  regLettersNumbers,
  regNumbers,
  regPassport,
} from "../../../utils/utils";
import clearLogo from "../../../assets/svg/icon-park_clear-format.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setCities,
  setCitiesRedux,
  setCountries,
  setCountriesRedux,
  setDocumentTypes,
  setDocumentTypesRedux,
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setStates,
  setStatesRedux,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import {
  setActiveDealer,
  startAddDealer,
  startUpdateDealer,
} from "../../../redux/actions/managementDealersActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { getOperatorsService } from "../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../redux/actions/managementCompanyActions";
import { ICountries, IStates } from "../../../redux/interfaces/shared";
import moment from "moment";
import { getAssociatesFilterService } from "../../../services/associates";
import { setAssociatesRedux } from "../../../redux/actions/associatesActions";
import { setPromoters } from "../../../redux/actions/managementPromotersActions";
import { getPromoters } from "../../../services/promoters";

const styles = {
  "& input.Mui-disabled ": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
  "& .MuiSelect-select.Mui-disabled": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const FormDealers = () => {
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { activeDealer } = useSelector(
    (state: AppState) => state.managementDealers
  );
  const { associates } = useSelector((state: AppState) => state.associates);
  const { promoters } = useSelector(
    (state: AppState) => state.managementPromoters
  );
  const { countries, cities, states, documentTypes } = useSelector(
    (state: AppState) => state.helper
  );
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const [operator, setOperator] = useState(
    activeDealer
      ? activeDealer.operator.id
      : user?.operator
      ? user.operator.id
      : null
  );

  const [associateSelected, setAssociateSelected] = useState<number | null>(
    activeDealer?.associate.id ?? null
  );
  const [selectedCountry, setSelectedCountry] = useState<number | null>(
    activeDealer?.country.id ?? null
  );

  const [selectedStates, setSelectedStates] = useState<number | null>(
    activeDealer?.stateCountry.id ?? null
  );

  const [promoterSelected, setPromoterSelected] = useState<number | null>(
    activeDealer?.promotor?.id ?? null
  );

  const formikRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const isAdmin = user?.roles.name === Roles_Enum.ADMIN_OPERATOR;

  const initialValues = {
    name: activeDealer ? activeDealer.name : "",
    address: activeDealer ? activeDealer.address : "",
    phone: activeDealer ? activeDealer.phone : "",
    email: activeDealer ? activeDealer.email : "",
    state: activeDealer ? activeDealer.state : State.ACTIVE,
    typeDocument: activeDealer?.user.typeDocument.id ?? "",
    documentNumber: activeDealer?.documentNumber ?? "",
    country: activeDealer?.country.id ?? null,
    stateCountry: activeDealer?.stateCountry.id ?? null,
    city: activeDealer?.city.id ?? null,
    comissionPercentage: activeDealer
      ? activeDealer.comissionPercentage.toString()
      : "",
    comissionDeposit: activeDealer?.comissionDeposit ?? `${0}`,
    comissionWithdrawals: activeDealer?.comissionWithdrawals ?? `${0}`,
    comissionAwardsPayments: activeDealer?.comissionAwardsPayments ?? `${0}`,
    comissionAnonymousBets: activeDealer?.comissionAnonymousBets ?? `${0}`,
    commissionPercentageAffiliations:
      activeDealer?.commissionPercentageAffiliations ?? `${0}`,
    betsBalance: activeDealer?.betsBalance ?? `${0}`,
    refillsBalance: activeDealer?.refillsBalance ?? `${0}`,
    associate: activeDealer?.associate?.id ?? "",
    createBy: activeDealer?.createBy?.id ?? "",
    operator: user?.operator?.id ?? "",
    expeditionDate:
      moment(activeDealer?.user.expeditionDate).utc().format("YYYY-MM-DD") ??
      "",
    type: activeDealer?.type ?? "",
  };
  const validationErrorMsg = t("form.invalidLengthDocumentNumber", {
    min: 8,
    max: 13,
  });
  const validationSchema = yup.object({
    name: yup.string().required(t("form.requiredFile")),
    address: yup.string().required(t("form.requiredFile")),
    phone: yup
      .string()
      .min(8, validationErrorMsg)
      .max(13, validationErrorMsg)
      .required(t("form.requiredFile")),
    email: yup
      .string()
      .email(t("user.invalidEmail"))
      .required(t("form.requiredFile")),
    comissionPercentage: yup
      .number()
      .lessThan(101, t("managementBonds.errorMaxValuePercentage"))
      .required(t("form.requiredFile")),
    state: yup.number().required(t("form.requiredFile")),
    type: yup.number().required(t("form.requiredFile")),
  });

  useEffect(() => {
    if (token && user) {
      dispatch(setCountries(token));
      const { associatesUsers } = user;
      const associateId =
        associatesUsers?.length > 0 ? associatesUsers[0].associate.id : null;
      //createBy user
      if (associateId) {
        setAssociateSelected(associateId);
      }
    }
    return () => {
      dispatch(setCitiesRedux([]));
      dispatch(setCountriesRedux([]));
      dispatch(setStatesRedux([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    token && dispatch(setDocumentTypes(token));

    return () => {
      dispatch(setDocumentTypesRedux([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getStates = (id: number) => {
    if (token) {
      dispatch(setStates(token, id));
    }
  };
  /**
   * Soporta el evento click del boton para traer las ciudades del estado
   */
  const getCities = async (id: number) => {
    if (token) {
      await dispatch(setCities(token, id));
    }
  };

  useEffect(() => {
    //FILTRAR LOS SELECTS
    if (selectedCountry) {
      getStates(selectedCountry);
      if (
        !activeDealer ||
        (activeDealer && activeDealer.country.id !== selectedCountry)
      ) {
        (formikRef.current as any).setFieldValue("stateCountry", "");

        setSelectedStates(null);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  useEffect(() => {
    //FILTRAR LOS SELECTS
    if (selectedStates) {
      getCities(selectedStates);
      if (
        !activeDealer ||
        (activeDealer && activeDealer.stateCountry.id !== selectedStates)
      ) {
        (formikRef.current as any).setFieldValue("city", "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStates]);
  const fetchAssociates = async () => {
    if (!token || !operator) {
      throw new Error("Invalid action");
    }
    const rs = await getAssociatesFilterService(token, operator);
    dispatch(setAssociatesRedux(rs));
  };

  const fetchPromoters = async () => {
    if (!token || !operator) {
      throw new Error("Invalid action");
    }
    const params = {
      operator,
    };

    const rs = await getPromoters(params, token);

    dispatch(setPromoters(rs));
  };

  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    if (isSuper && token) {
      fetchOperators(token);
    }
    if (isAdmin) {
      fetchAssociates();
      fetchPromoters();
    }
    return () => {
      dispatch(setActiveDealer(null));
      dispatch(setCompaniesRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const state = [
    { label: t("state.active"), value: State.ACTIVE },
    { label: t("state.inactive"), value: State.INACTIVE },
  ];

  const dealerType = [
    { label: t("managementCards.cards"), value: DealerTypeEnum.CARDS },
    {
      label: t("managementDealers.anonymous"),
      value: DealerTypeEnum.ANONYMOUS,
    },
    { label: t("managementDealers.both"), value: DealerTypeEnum.BOTH },
  ];
  const handleCancel = () => {
    dispatch(setFlagForm({ flagFormCreate: false }));
  };
  const handleSubmit = async (data: any) => {
    try {
      const validateExtraSelect = isAdmin || isSuper;
      if (!operator || (validateExtraSelect && !associateSelected)) {
        dispatch(setMessageAlert(t("alerts.completeRequiredFields")));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      data.operator = operator;

      data.associate = associateSelected;
      data.promotor = promoterSelected ?? null;
      if (activeDealer) {
        await dispatch(startUpdateDealer(activeDealer.id, data));
      } else {
        await dispatch(startAddDealer(data));
      }
      dispatch(setFlagForm({ flagFormCreate: false }));
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelectCountry = async (value: number | null) => {
    setSelectedStates(null);

    //!value ? setErrorCountry(true) : setErrorCountry(false);
    value && setSelectedCountry(value);
  };
  const handleSelectStates = async (value: number | null) => {
    value && setSelectedStates(value);
  };
  const getOptionLabelCountry = (id: number) => {
    const countryDetail = countries?.find((country) => country.id === id);
    return countryDetail;
  };
  const getOptionLabelState = (id: number) => {
    const stateDetail = states?.find((state) => state.id === id);
    return stateDetail;
  };
  const getOptionLabelCity = (id: number) => {
    const cityDetail = cities?.find((city) => city.id === id);
    return cityDetail;
  };
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        className="pb-1"
        borderBottom={1}
        borderColor="#02D0E7"
      >
        <i
          className="fa-solid fa-circle-info"
          style={{ color: "#02D0E7", marginRight: 10, fontSize: 20 }}
        ></i>
        <p
          className="txt-primary-md"
          style={{ fontWeight: 900, fontSize: "18px" }}
        >
          {activeDealer
            ? t("managementDealers.updateDealer")
            : t("managementDealers.createDealer")}
        </p>
      </Box>

      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          await handleSubmit(data);
          setSubmitting(false);
        }}
      >
        {({
          isValid,
          isSubmitting,
          values,
          handleChange,
          resetForm,
          setFieldValue,
        }) => (
          <Form>
            <Grid container spacing={4} mt={3}>
              {isSuper && companies && !activeDealer && (
                <Grid item xs={4}>
                  <TextField
                    name="operator"
                    size="small"
                    select
                    fullWidth
                    onChange={(e) => setOperator(parseInt(e.target.value))}
                    value={operator || ""}
                    label={`${t("companyManagement.operator")}*`}
                    sx={styles}
                    SelectProps={{
                      MenuProps,
                    }}
                    disabled={!!activeDealer}
                  >
                    {companies?.data?.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              {(isAdmin || isSuper) && associates && !activeDealer && (
                <Grid item xs={4}>
                  <TextField
                    name="associate"
                    size="small"
                    select
                    fullWidth
                    onChange={(e) =>
                      setAssociateSelected(parseInt(e.target.value))
                    }
                    value={associateSelected || ""}
                    label={`${t("reports.associate")}*`}
                    sx={styles}
                    SelectProps={{
                      MenuProps,
                    }}
                    disabled={!!activeDealer}
                  >
                    {associates?.data?.map((associate) => (
                      <MenuItem key={associate.id} value={associate.id}>
                        {associate.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}

              <Grid item xs={4}>
                <MyTextField
                  name="name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label={`${t("managementDealers.dealerName")}*`}
                  inputProps={{
                    maxLength: 20,
                  }}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regLettersNumbers(e.key) && e.preventDefault()
                  }
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={4}>
                <MyTextField
                  name="typeDocument"
                  variant="outlined"
                  size="small"
                  fullWidth
                  select
                  label={`${t("user.documentType")}*`}
                  disabled={!!activeDealer}
                  onChange={(event: any) => {
                    handleChange(event);
                    setFieldValue("documentNumber", "");
                  }}
                >
                  {documentTypes?.map((item) => (
                    <MenuItem key={item.name} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>
              <Grid item xs={4}>
                <MyTextField
                  name="documentNumber"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label={`${t("user.documentNumber")}*`}
                  disabled={!!activeDealer}
                  inputProps={{
                    maxLength: 20,
                  }}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (
                      [DocumentTypes.PASSPORT, DocumentTypes.CE].includes(
                        +values.typeDocument
                      )
                        ? !regPassport(e.key)
                        : !regNumbers(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={4}>
                <MyTextField
                  name="expeditionDate"
                  variant="outlined"
                  size="small"
                  type="date"
                  label={t("user.expeditionDate") + "*"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    "data-testid": "expeditionDate",
                    max: new Date().toLocaleDateString("en-CA"),
                  }}
                  onChange={handleChange}
                  value={values.expeditionDate}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <MyTextField
                  name="address"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label={`${t("managementDealers.dealerAddress")}*`}
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regLettersNumbers(e.key) && e.preventDefault()
                  }
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={4}>
                <MyTextField
                  name="phone"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label={`${t("managementDealers.dealerPhone")}*`}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regNumbers(e.key) && e.preventDefault()
                  }
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={4}>
                <MyTextField
                  name="email"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label={`${t("managementDealers.dealerEmail")}*`}
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regEmail(e.key) && e.preventDefault()
                  }
                  autoComplete="off"
                />
              </Grid>

              {countries && (
                <Grid item xs={4}>
                  <Autocomplete
                    id="countries"
                    options={countries}
                    disableClearable
                    // @ts-ignore
                    value={values.country}
                    getOptionLabel={(option) =>
                      getOptionLabelCountry(option as any)?.name ?? ""
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event: any, value: ICountries | null) => {
                      setFieldValue("country", value?.id);

                      handleSelectCountry(value?.id ?? null);
                    }}
                    renderOption={(props, option) => {
                      const { name } = option;

                      return (
                        <span
                          {...props}
                          key={option.id}
                          style={{ color: "#000" }}
                        >
                          {name}
                        </span>
                      );
                    }}
                    renderInput={({ inputProps, ...rest }) => (
                      <TextField
                        {...rest}
                        inputProps={{ ...inputProps, readOnly: true }}
                        name="country"
                        variant="outlined"
                        size="small"
                        label={`${t("user.country")}*`}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={4}>
                {states && (
                  <Autocomplete
                    id="state"
                    data-testid="stateSelect"
                    options={states ? states : []}
                    renderOption={(props, option) => {
                      return (
                        <span
                          {...props}
                          key={option.id}
                          style={{ color: "#000" }}
                        >
                          {option.names}
                        </span>
                      );
                    }}
                    disabled={!values.country}
                    disableClearable
                    // @ts-ignore
                    value={values.stateCountry}
                    getOptionLabel={(option) =>
                      getOptionLabelState(option as any)?.names ?? ""
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event: any, value: IStates | null) => {
                      setFieldValue("stateCountry", value?.id);
                      handleSelectStates(value?.id ?? null);
                    }}
                    renderInput={({ inputProps, ...rest }) => (
                      <TextField
                        {...rest}
                        inputProps={{ ...inputProps, readOnly: true }}
                        name="stateCountry"
                        variant="outlined"
                        size="small"
                        label={`${t("companyManagement.department")}*`}
                        sx={{
                          "& .MuiOutlinedInput-root.Mui-error": {
                            "& fieldset": {
                              borderColor: "#d32f2f",
                            },
                          },
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {cities && (
                  <Autocomplete
                    id="city"
                    options={cities ? cities : []}
                    renderOption={(props, option) => {
                      return (
                        <span
                          {...props}
                          key={option.id}
                          style={{ color: "#000" }}
                        >
                          {option.names}
                        </span>
                      );
                    }}
                    disabled={!values.stateCountry}
                    disableClearable
                    // @ts-ignore
                    value={values.city}
                    getOptionLabel={(option) =>
                      getOptionLabelCity(option as any)?.names ?? ""
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event: any, value: IStates | null) =>
                      setFieldValue("city", value?.id)
                    }
                    renderInput={({ inputProps, ...rest }) => (
                      <TextField
                        {...rest}
                        inputProps={{ ...inputProps, readOnly: true }}
                        name="city"
                        variant="outlined"
                        size="small"
                        label={`${t("companyManagement.town")}*`}
                        sx={{
                          "& .MuiOutlinedInput-root.Mui-error": {
                            "& fieldset": {
                              borderColor: "#d32f2f",
                            },
                          },
                        }}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={4}>
                <MyTextField
                  name="comissionPercentage"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label={`${t("managementDealers.comissionPercentage")}*`}
                  inputProps={{
                    maxLength: 3,
                  }}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regNumbers(e.key) && e.preventDefault()
                  }
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={4}>
                <MyTextField
                  name="comissionDeposit"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label={`${t("managementDealers.comissionDeposit")}*`}
                  inputProps={{
                    maxLength: 3,
                  }}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regNumbers(e.key) && e.preventDefault()
                  }
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={4}>
                <MyTextField
                  name="comissionWithdrawals"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label={`${t("managementDealers.comissionWithdrawals")}*`}
                  inputProps={{
                    maxLength: 3,
                  }}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regNumbers(e.key) && e.preventDefault()
                  }
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={4}>
                <MyTextField
                  name="comissionAwardsPayments"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label={`${t("managementDealers.comissionAwardsPayments")}*`}
                  inputProps={{
                    maxLength: 3,
                  }}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regNumbers(e.key) && e.preventDefault()
                  }
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={4}>
                <MyTextField
                  name="comissionAnonymousBets"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label={`${t("managementDealers.comissionAnonymousBets")}*`}
                  inputProps={{
                    maxLength: 3,
                  }}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regNumbers(e.key) && e.preventDefault()
                  }
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={4}>
                <MyTextField
                  name="commissionPercentageAffiliations"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  label={`${t(
                    "managementDealers.commissionPercentageAffiliations"
                  )}*`}
                  inputProps={{
                    maxLength: 3,
                  }}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regNumbers(e.key) && e.preventDefault()
                  }
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="state"
                  variant="outlined"
                  size="small"
                  fullWidth
                  select
                  label={`${t("managementDealers.dealerState")}*`}
                  inputProps={{
                    maxLength: 3,
                  }}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regLettersNumbers(e.key) && e.preventDefault()
                  }
                  autoComplete="off"
                  sx={styles}
                  SelectProps={{
                    MenuProps,
                  }}
                  value={values.state}
                >
                  {state.map((item) => (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                      sx={{
                        "&.Mui-focused": {
                          backgroundColor: "red",
                        },
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="type"
                  variant="outlined"
                  size="small"
                  fullWidth
                  select
                  label={`${t("managementDealers.dealerType")}*`}
                  inputProps={{
                    maxLength: 3,
                  }}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regLettersNumbers(e.key) && e.preventDefault()
                  }
                  autoComplete="off"
                  sx={styles}
                  SelectProps={{
                    MenuProps,
                  }}
                  value={values.type}
                >
                  {dealerType.map((item) => (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                      sx={{
                        "&.Mui-focused": {
                          backgroundColor: "red",
                        },
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {(isAdmin || isSuper) && promoters && (
                <Grid item xs={4}>
                  <TextField
                    name="promoter"
                    size="small"
                    select
                    fullWidth
                    onChange={(e) =>
                      setPromoterSelected(parseInt(e.target.value))
                    }
                    value={promoterSelected || ""}
                    label={`${t("managementPromoters.promoter")}`}
                    sx={styles}
                    SelectProps={{
                      MenuProps,
                    }}
                    disabled={!!activeDealer && !!activeDealer.promotor}
                  >
                    {promoters?.data?.map((promoter) => (
                      <MenuItem key={promoter.id} value={promoter.id}>
                        {promoter.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}

              <Box
                display={"flex"}
                mt={9}
                flex={1}
                justifyContent="center"
                alignItems={"center"}
              >
                <Button
                  variant="contained"
                  className="footer-btn danger-btn ripple"
                  style={{ border: "none", outline: "none" }}
                  onClick={handleCancel}
                  disabled={!!isSubmitting}
                  data-testid={"btnCancel"}
                >
                  {t("button.cancel").toUpperCase()}
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  className="footer-btn primary-btn ripple"
                  style={{ border: "none", outline: "none" }}
                  data-testid={"btnSave"}
                  disabled={!!isSubmitting || !isValid}
                >
                  {isSubmitting ? (
                    <CircularProgress size={"1.6rem"} color="inherit" />
                  ) : (
                    <>{t("button.save").toUpperCase()}</>
                  )}
                </Button>

                <Button
                  style={{
                    border: "none",
                    outline: "none",
                    marginLeft: "4px",
                    opacity: activeDealer ? "0.3" : "1",
                  }}
                  onClick={() => {
                    resetForm();
                  }}
                  disabled={isSubmitting || !!activeDealer}
                >
                  <img src={clearLogo} alt="Icon clear" />
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
