import { FC } from "react";
import { TableCell } from "@mui/material";
import moment from "moment";
import { State } from "../../../../enums/enums";

import { IObservation } from "./StatePlayer";
import { useTranslation } from "react-i18next";

interface Props {
  row: IObservation;
}

export const RowStatePlayer: FC<Props> = ({ row }) => {
  const { t } = useTranslation();

  const stateOptions = {
    [State.ACTIVE]: t("state.active"),
    [State.INACTIVE]: t("state.inactive"),
    [State.RETAINED]: t("state.retained"),
    [State.PENDING_VALIDATION]: t("state.pendingValidation"),
    [State.CLOSED]: t("state.closed"),
    [State.SELF_EXCLUDED]: t("state.selfExcluded"),
  };

  const getActionName = (action: State) => {
    return stateOptions[action] || t("state.unknown");
  };
  return (
    <>
      <TableCell className="word-break">{getActionName(row.action)}</TableCell>
      <TableCell className="word-break">{row.observation}</TableCell>
      <TableCell className="word-break">{row.createBy.nameComplete}</TableCell>
      <TableCell className="word-break">
        {moment(row.createAt).format("DD/MM/YYYY - HH:mm:ss")}
      </TableCell>
    </>
  );
};
