export const getPayMethodsDB = (token: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify({}),
  };
  let baseUrl = process.env.REACT_APP_API_HTTP_BETS;

  return fetch(`${baseUrl}bonus/payMethodsOperator`, requestOptions)
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
