import { Typography } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { IShortCuts } from "../../../interfaces/shortcuts";

interface Props {
  shorcuts: IShortCuts[];
}
const fontSize = {
  lg: 13,
  md: 12,
  sm: 12,
  xs: 12,
};
export const ShorCuts: FC<Props> = ({ shorcuts }) => {
  const { t } = useTranslation();
  return (
    <Row style={{ marginBottom: 18 }}>
      <Col xs={12}>
        <h3 className="subtitle">{t("shorcuts.title")}</h3>
      </Col>

      <Row lg={12} xs={12} className="container-list">
        {shorcuts.length > 0 ? (
          shorcuts.map((item, index) => (
            <Col className="list-item" key={index}>
              <Link to={item.url} className="shortcut-content">
                <div
                  className={`${item.active ? "circle-active" : "circle"}`}
                />

                <Typography fontSize={fontSize} className="item-text">
                  {item.name}
                </Typography>
              </Link>
            </Col>
          ))
        ) : (
          <h3 className="primary-text" style={{ fontWeight: "bold" }}>
            {t("shorcuts.noShortCuts")}
          </h3>
        )}
      </Row>
    </Row>
  );
};
