import {
  IApproveWithdrawalCash,
  IBodyAwardPayment,
  IWithdrawalData,
  IWithdrawalParams,
} from "../interfaces/withdrawals";

export const getWithdrawals = (
  token: string,
  operatorId: number | null,
  params?: Partial<IWithdrawalParams>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const operatorParam = operatorId ? `operatorId=${operatorId}` : "";
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}withdrawals?${operatorParam}&${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const updateWithdrawal = (
  id: number,
  data: IWithdrawalData,
  token: string
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}withdrawals/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const approveCashWithdrawal = (
  data: IApproveWithdrawalCash,
  token: string
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}withdrawals/approveCash`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const approveAwardPay = (data: IBodyAwardPayment, token: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}awards-payment`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const uploadVoucher = (data: any, token: string) => {
  const myHeaders = new Headers();
  myHeaders.append("api", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Authorization", `bearer ${token}`);
  const formData = new FormData();

  formData.append("id", data.id);
  data.file && formData.append("file", data.file);

  const requestOptions: any = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}voucher`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
