import { Grid } from "@mui/material";
import React from "react";
import { IrrigationAdminInfoCards } from "../../../transactions/irrigation/admin/IrrigationAdminInfoCards";

export const CardIrrigationPointSale = () => {
  return (
    <div className="content w-100 mb-4">
      <Grid item xs={12}>
        <IrrigationAdminInfoCards />
      </Grid>
    </div>
  );
};
