import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { useForm } from "../../../hooks/useForm";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  setCountries,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
  setPaymentMethodsByCountry,
} from "../../../redux/actions/helperActions";
import { theme } from "../custom/customStyles";
import { IPaymentMethods } from "../../../redux/interfaces/shared";
import { getParamPaymentById } from "../../../services/managementParametersService";
import { ICountryPayment } from "../../../interfaces/params";
import {
  setActiveParamPayments,
  updateParamPaymentMethod,
} from "../../../redux/actions/managementParametersActions";
import { MyDialog } from "../custom/MyDialog";
import { Roles_Enum, State } from "../../../enums/enums";
import { getOperatorsService } from "../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../redux/actions/managementCompanyActions";

const ITEM_HEIGHT = 42;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const FormPaymentMethodsTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token, user } = useSelector((state: AppState) => state.auth);
  const [initialParam, setInitialParam] = useState<ICountryPayment[]>([]);
  const { countries, paymentMethodsByCountry } = useSelector(
    (state: AppState) => state.helper
  );
  const { activeParamPayments: active } = useSelector(
    (state: AppState) => state.managementParameters
  );
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const [searchText, setSearchText] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [paymentMethodsOptions, setPaymentMethodsOptions] = useState<
    IPaymentMethods[]
  >([]);

  const initialFormValues = {
    country: active ? active.country.id : 0,
    methods: active
      ? active.paymentMethods
          .filter(
            (method) => method.type !== "withdrawal" && method.state === 1
          )
          .map((method) => method.id)
      : [],
    operator: active
      ? active.operator.id
      : user?.roles.name !== Roles_Enum.SUPER_ADMIN
      ? user?.operator.id
      : 0,
  };

  const [formValues, handleInputChange] = useForm({
    ...initialFormValues,
  });

  useEffect(() => {
    token && dispatch(setCountries(token));
    if (active) {
      handleInputChange({
        target: {
          name: "methods",
          value:
            active.paymentMethods.length > 0
              ? active.paymentMethods
                  .filter(
                    (method) =>
                      method.type !== "withdrawal" && method.state === 1
                  )
                  .map((item) => item.id)
              : [],
        },
      });
    }
    return () => {
      dispatch(setActiveParamPayments(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getParam = async (token: string, country: number) => {
    try {
      const operatorId =
        user && user.operator ? user.operator.id : formValues.operator;
      const response = await getParamPaymentById(token, country, operatorId);
      const { data } = response;
      const checkedMethods = data.filter(
        (method: any) => method.state === 1 && !method.isNativeGlobal
      );

      setInitialParam(checkedMethods);
    } catch (error) {}
  };
  useEffect(() => {
    if (!active) {
      handleInputChange({
        target: {
          name: "methods",
          value:
            formValues.operator === 0
              ? []
              : initialParam.length > 0
              ? initialParam.map((item) => item.methods.id)
              : [],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialParam, formValues.operator]);

  useEffect(() => {
    token &&
      dispatch(
        setPaymentMethodsByCountry(
          token,
          formValues.country,
          formValues.operator
        )
      );
    token && !active && getParam(token, formValues.country);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.country, formValues.operator]);

  useEffect(() => {
    setPaymentMethodsOptions(
      paymentMethodsByCountry ? paymentMethodsByCountry : []
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodsByCountry]);

  const displayedOptions = useMemo(
    () =>
      paymentMethodsOptions.filter((option) =>
        option.name.toLowerCase().includes(searchText.toLowerCase())
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchText, paymentMethodsOptions]
  );

  const handleCreatePaymentMethods = async () => {
    setIsSubmit(true);
    const isValid = validateEmptyMethods(formValues.methods);
    if (
      formValues.country === 0 ||
      (user?.roles.name === Roles_Enum.SUPER_ADMIN &&
        formValues.operator === 0) ||
      !isValid
    ) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.fillInRequiredFields"));
      return dispatch(setTypeAlert("error"));
    } else {
      if (!!active) {
        setOpenDialog(true);
      } else {
        handleAcceptCreate();
      }
    }
  };
  const validateEmptyMethods = (methods: any[]) => {
    return methods.length > 0;
  };

  const handleAcceptCreate = async () => {
    const isValid = validateEmptyMethods(formValues.methods);
    if (!isValid) {
      dispatch(setTypeAlert("error"));
      dispatch(setMessageAlert("alerts.fillInRequiredFields"));
      return dispatch(setOpenAlert());
    }
    setIsLoading(true);
    setOpenDialog(false);

    const finalMethods: IPaymentMethods[] = [];
    paymentMethodsOptions.forEach((method) => {
      const enableMethod = !!(formValues.methods as number[]).find(
        (idMethod) => idMethod === method.id
      );
      finalMethods.push({
        ...method,
        state: enableMethod ? 1 : 0,
      });
    });

    formValues.methods = finalMethods;

    if (
      formValues.country !== 0 &&
      formValues.methods.length > 0 &&
      formValues.operator !== 0
    ) {
      await dispatch(updateParamPaymentMethod(formValues, initialParam));
    }

    setIsLoading(false);
    setIsSubmit(false);
  };

  const handleCancelCreate = () => {
    setOpenDialog(false);
    setIsSubmit(false);
  };

  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    token && fetchOperators(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2}>
        {user?.roles.name === Roles_Enum.SUPER_ADMIN && !active && (
          <>
            {companies && (
              <Grid item xs={4}>
                <label className="ml-3">
                  {t("companyManagement.operator") + "*"}
                </label>
                <FormControl
                  sx={{ width: "100%" }}
                  size="small"
                  disabled={!!active}
                  error={
                    isSubmit &&
                    user.roles.name === Roles_Enum.SUPER_ADMIN &&
                    formValues.operator === 0
                  }
                >
                  <Select
                    name="operator"
                    size="small"
                    fullWidth
                    onChange={handleInputChange}
                    value={
                      formValues.operator === 0 ? "" : formValues.operator || ""
                    }
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    MenuProps={MenuProps}
                  >
                    {companies?.data?.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </>
        )}
        <Grid item xs={4}>
          <label className="ml-2">
            {t("companyManagement.selectCountry")}*
          </label>
          <FormControl
            sx={{ width: 1 }}
            size="small"
            error={isSubmit && formValues.country === 0}
          >
            <InputLabel id="country-label">{t("user.country")}</InputLabel>
            {/* @ts-ignore */}
            <Select
              labelId="country-label"
              label={t("user.country")}
              name="country"
              fullWidth
              onChange={handleInputChange}
              value={formValues.country === 0 ? "" : formValues.country || ""}
              input={<OutlinedInput label={t("user.country")} />}
              disabled={!!active || formValues.operator === 0}
            >
              {countries?.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  <img
                    loading="lazy"
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                    src={country.imgUrl}
                    alt={country.name}
                  />{" "}
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <label className="ml-2">
            {t("parameterization.enterPaymentMethod")}*
          </label>
          <FormControl
            sx={{ width: 1 }}
            size="small"
            error={isSubmit && !validateEmptyMethods(formValues.methods)}
          >
            <InputLabel id="demo-simple-select-autowidth-label">
              {t("parameterization.paymentMethod")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              label={t("parameterization.paymentMethod")}
              name="methods"
              multiple
              value={formValues.methods || ""}
              onChange={handleInputChange}
              input={
                <OutlinedInput label={t("parameterization.paymentMethod")} />
              }
              renderValue={(selected) =>
                paymentMethodsOptions
                  .filter((method) => selected.includes(method.id))
                  .map((method) => method.name)
                  .join(" - ")
              }
              MenuProps={MenuProps}
              onClose={() => setSearchText("")}
            >
              <ListSubheader>
                <TextField
                  size="small"
                  autoFocus
                  fullWidth
                  InputLabelProps={{ shrink: false }}
                  label={" "}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      e.stopPropagation();
                    }
                  }}
                  
                />
              </ListSubheader>
              {displayedOptions.map((method, index) => (
                <MenuItem key={`${method.id}-${index}`} value={method.id}>
                  <Checkbox
                    checked={
                      !!formValues.methods.find(
                        (item: any) => item === method.id
                      )
                    }
                    sx={{
                      "&.Mui-checked": {
                        color: "#04BF9D",
                      },
                    }}
                  />
                  <ListItemText primary={method.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="flex-end" mt={20}>
          {isLoading ? (
            <CircularProgress className={"colorLoading"} />
          ) : (
            <Button
              style={{
                border: "none",
                outline: "none",
                fontSize: 14,
              }}
              variant="contained"
              className="btn-dialog primary-btn"
              onClick={handleCreatePaymentMethods}
            >
              {t(
                !!active || initialParam.length > 0
                  ? "button.editParameters"
                  : "button.createParameters"
              )}
              <i className="fa-solid fa-check-circle ml-2"></i>
            </Button>
          )}
        </Grid>

        <MyDialog
          open={openDialog}
          title={t("dialog.confirmationWindow")}
          message={t("dialog.sureEdit")}
          handleAccept={handleAcceptCreate}
          handleClose={handleCancelCreate}
        />
      </Grid>
    </ThemeProvider>
  );
};
