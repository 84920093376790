import React, { FC } from "react";
import TableCell from "@mui/material/TableCell";
import { TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
interface Props {
  row: any;
}

export const RowDataTableRolesItem: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  return (
    <TableRow key={row.id + row.menu.id}>
      <TableCell className="word-break">{row.menu.name}</TableCell>
      <TableCell className="word-break">
        {row.menuItem ? row.menuItem.name : ""}
      </TableCell>
      <TableCell className="word-break">
        {row.created === 1 ? t("table.yes") : t("table.not")}
      </TableCell>
      <TableCell className="word-break">
        {row.readed === 1 ? t("table.yes") : t("table.not")}
      </TableCell>
      <TableCell className="word-break">
        {row.updated === 1 ? t("table.yes") : t("table.not")}
      </TableCell>
      <TableCell className="word-break">
        {row.deleted === 1 ? t("table.yes") : t("table.not")}
      </TableCell>
    </TableRow>
  );
};
