import moment from "moment";
import { StateCards } from "../../enums/enums";
import {
  IUpdateWalletPointSale,
  IWalletPointSale,
} from "../../interfaces/pointsSale";
import { updateCard } from "../../services/cards";
import { getPointSaleWallet } from "../../services/helperService";
import { getUserById } from "../../services/managementUsers";
import { updateWalletPointSale } from "../../services/pointsSaleService";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import { setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";
import { startGetCards } from "./managementCardsActions";
import { getDealerById } from "../../services/dealers";

export const startGetPointSaleWallet = (pointSale?: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    if (!token || !user) {
      throw new Error("Invalid action");
    }
    const userDetail = await getUserById(token, user.id);

    let pointSaleLinked: any =
      userDetail?.sellerPointSales?.length > 0
        ? userDetail.sellerPointSales[0]?.pointSale?.id || ""
        : "";
    if (pointSale) {
      pointSaleLinked = pointSale;
    }

    const wallet = await getPointSaleWallet(token, parseInt(pointSaleLinked));
    if (wallet && !wallet?.error) {
      dispatch(setPointSaleWallet(wallet));
    }
  };
};
export const startGetAssociateWallet = () => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    if (!token || !user) {
      throw new Error("Invalid action");
    }
    const userDetail = await getUserById(token, user.id);
    const { associateBalance } = userDetail;
    const walletAssociate = associateBalance[0].associate;
    dispatch(setAssociateWallet(walletAssociate));
  };
};
export const setPointSaleWallet = (data: IWalletPointSale | null) => ({
  type: Types.setWalletPointSale,
  payload: data,
});
export const setAssociateWallet = (data: any) => ({
  type: Types.setWalletAssociate,
  payload: data,
});

export const updatedBalalanceRefillPointSale = (
  pointSale: number,
  amount: number,
  cardId: number
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { user, token } = (getState() as AppState).auth;
      if (!token || !user) {
        throw new Error("INVALID ACTION");
      }
      //subtract point sale balance
      const walletUpdateData: IUpdateWalletPointSale = {
        pointSale,
        amount: amount,
        user: user.id,
      };
      const rsCardUpdated = await updateCard(
        cardId,
        { state: StateCards.SOLD, activationDate: moment().toISOString() },
        token
      );
      if (rsCardUpdated.error) {
        dispatch(setMessageAlert(`managementCards.errorCardAlert`));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      const rs = await updateWalletPointSale(token, walletUpdateData);
      if (rs.error) {
        dispatch(setMessageAlert(`managementCards.errorCardAlert`));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      } else {
        dispatch(setMessageAlert(`managementCards.cardSuccessfullySold`));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
        await dispatch(startGetCards({ state: StateCards.ACTIVE }));
        return await dispatch(startGetPointSaleWallet(pointSale));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const setDealerWallet = (data: any) => ({
  type: Types.setWalletDealer,
  payload: data,
});

export const startGetDealerWallet = (id?: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    if (!token || !user) {
      throw new Error("Invalid action");
    }
    let idDealer = id ?? 0;
    if (!id) {
      const userDetail = await getUserById(token, user.id);
      const { dealer } = userDetail;
      idDealer = dealer?.id;
    }
    if (idDealer) {
      const dealerDetail = await getDealerById(idDealer, token);
      const {
        refillsBalance,
        betsBalance,
        comissionDeposit,
        comissionWithdrawals,
        comissionAwardsPayments,
        comissionAnonymousBets,
        commissionPercentageAffiliations,
      } = dealerDetail;

      dispatch(
        setDealerWallet({
          id,
          refillsBalance,
          betsBalance,
          comissionDeposit,
          comissionWithdrawals,
          comissionAwardsPayments,
          comissionAnonymousBets,
          commissionPercentageAffiliations,
        })
      );
    }
  };
};
