import React, { useState } from "react";
import "./ToggleGroup.scss";
import { Tooltip } from "@mui/material";

interface ToggleGroupProps {
  title: string;
  deleteAction?: any;
  editAction?: any;
  isFirst: boolean;
  toggle?: boolean;
}

const ToggleGroup: React.FC<ToggleGroupProps> = ({
  title,
  children,
  deleteAction,
  editAction,
  isFirst,
  toggle = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`toggle-group ${isFirst ? "first" : ""}`}>
      <div className="toggle-header">
        <span className="toggle-text">{title}</span>
        <div className="toggle-actions">
          {editAction && (
            <Tooltip title="Editar" arrow>
              <div onClick={editAction}>
                <i className="fa fa-pencil-square-o fa-lg mr-2 iconEdit" />
              </div>
            </Tooltip>
          )}
          {deleteAction && (
            <Tooltip title="Eliminar" arrow>
              <div onClick={deleteAction}>
                <i className="fa fa-trash fa-lg iconDelete" />
              </div>
            </Tooltip>
          )}
          {toggle && (
            <Tooltip title="Toggle" arrow>
              <div onClick={toggleHandler}>
                {isOpen ? (
                  <i className="fa fa-angle-up" />
                ) : (
                  <i className="fa fa-angle-down" />
                )}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      {isOpen && <div className="toggle-content">{children}</div>}
    </div>
  );
};

export default ToggleGroup;
