import {
  Dispatch,
  SetStateAction,
  KeyboardEvent,
  useState,
  useRef,
  ChangeEvent,
} from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  Grid,
  Box,
  ThemeProvider,
  Button,
  MenuItem,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { theme } from "../custom/customStyles";
import { styled } from "@mui/system";
import { regNumbers } from "../../../utils/utils";
import { ICardsListCreate } from "../../../interfaces/cards";
import { MyTextField } from "../custom/MyTextField";
import clearLogo from "../../../assets/svg/icon-park_clear-format.svg";
import { excelToJson } from "../../../helpers/convertFileToJson";
import { useDispatch, useSelector } from "react-redux";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Roles_Enum } from "../../../enums/enums";

const Container = styled("div")({
  display: "flex",
});
const CustomButton = styled(Button)({
  backgroundColor: "#007E8C",
  color: "white",
  height: "100%",
  width: "150px",
  "&:hover": {
    backgroundColor: "#007E8C",
    opacity: 1,
  },
});
const styles = {
  "& input.Mui-disabled ": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
  "& .MuiSelect-select.Mui-disabled": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props {
  lotData: Omit<ICardsListCreate, "id" | "operator" | "currency" | "dealers">;
  setLotData: Dispatch<
    SetStateAction<
      Omit<ICardsListCreate, "id" | "operator" | "currency" | "dealers">
    >
  >;
  handleCancel: () => void;
  handleContinue: () => void;
  handleCleanForm: (callback?: any) => void;
  operator: number | null;
  setOperator: (value: number) => void;
}

export const FormCreateLots = ({
  lotData,
  setLotData,
  handleCancel,
  handleContinue,
  handleCleanForm,
  operator,
  setOperator,
}: Props) => {
  const { user } = useSelector((state: AppState) => state.auth);
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;

  const [selectedFile, setSelectedFile] = useState<File | null>(
    lotData.fileBonusCode || null
  );

  const cardValues = [1, 5, 10, 20];

  const initialValues = {
    amount: lotData?.amount,
    value: lotData?.value,
    extLot: lotData?.extLot || false,
  };

  const validationSchema = yup.object({
    amount: yup
      .number()
      .required(t("form.requiredFile"))
      .min(1, `${t("form.lessThanMaximumValue")}`)
      .max(10000, `${t("form.maximunValue")} ${10000}`),
    value: yup
      .number()
      .required(t("form.requiredFile"))
      .min(1, `${t("form.lessThanMaximumValue")}`),
    extLot: yup.boolean().required(t("form.requiredFile")),
  });
  const callback = (totalParse: number, totalLines: number) => {
    // Callback function to track progress
    // console.log(`Progreso: ${totalParse}/${totalLines}`);
  };

  const handleSubmit = async (
    data: Omit<ICardsListCreate, "id" | "operator" | "currency" | "dealers">
  ) => {
    try {
      const json = selectedFile
        ? await excelToJson(selectedFile, callback)
        : JSON.stringify([]);
      const parseData = JSON.parse(json);

      if (parseData.length > parseInt(data.amount)) {
        dispatch(setMessageAlert(t("cards.errorBonusCodeLength")));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      if (!operator) {
        dispatch(setMessageAlert(t("alerts.completeRequiredFields")));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      data.bonusCodes = parseData;
      data.fileBonusCode = selectedFile;
      setLotData(data);

      handleContinue();
    } catch (error) {
      console.log(error);
    }
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      (fileInputRef.current as any).click();
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files && event.target.files[0];
    setSelectedFile(file ?? null);
  };
  const handleDownload = (e: any) => {
    e.preventDefault();
    const url = process.env.PUBLIC_URL + "/PlantillaCardsBonus.csv";
    window.location.href = url;
  };

  return (
    <div className="container mt-3">
      <ThemeProvider theme={theme}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              className="pb-1"
              borderBottom={1}
              borderColor="#02D0E7"
            >
              <i
                className="fa-solid fa-circle-info"
                style={{ color: "#02D0E7", marginRight: 10, fontSize: 20 }}
              ></i>
              <p
                className="txt-primary-md"
                style={{ fontWeight: 900, fontSize: "18px" }}
              >
                {t("cards.createCardBatches")}
              </p>
            </Box>
          </Grid>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validateOnChange={true}
            validationSchema={validationSchema}
            onSubmit={async (data) => {
              handleSubmit(data as any);
            }}
          >
            {({ values, handleChange, resetForm }) => (
              <Form style={{ width: "100%", marginTop: "20px" }}>
                <Grid container spacing={2}>
                  {isSuper && companies && (
                    <Grid item xs={4}>
                      <label className="ml-3">{`${t(
                        "companyManagement.operator"
                      )}*`}</label>

                      <TextField
                        name="operator"
                        size="small"
                        select
                        fullWidth
                        onChange={(e) => setOperator(parseInt(e.target.value))}
                        value={operator || ""}
                        label={``}
                        sx={styles}
                        SelectProps={{
                          MenuProps,
                        }}
                      >
                        {companies?.data?.map((company) => (
                          <MenuItem key={company.id} value={company.id}>
                            {company.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={4}>
                    <label className="ml-3">{t("cards.qtyCards")}</label>
                    <MyTextField
                      name="amount"
                      variant="outlined"
                      InputLabelProps={{ shrink: false }}
                      label={" "}
                      type="number"
                      onChange={handleChange}
                      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                        if (values.amount === "") {
                          e.key === "0" || e.key === "."
                            ? e.preventDefault()
                            : !regNumbers(e.key) && e.preventDefault();
                        } else {
                          e.key === "."
                            ? e.preventDefault()
                            : !regNumbers(e.key) && e.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <label className="ml-3">{t("cards.valueCards")}</label>
                    <MyTextField
                      name="value"
                      variant="outlined"
                      InputLabelProps={{ shrink: false }}
                      label={" "}
                      select
                      onChange={handleChange}
                      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                        if (values.value === "") {
                          e.key === "0"
                            ? e.preventDefault()
                            : !regNumbers(e.key) && e.preventDefault();
                        } else {
                          !regNumbers(e.key) && e.preventDefault();
                        }
                      }}
                    >
                      {cardValues.map((item) => (
                        <MenuItem key={item} value={item}>
                          {"$" + item}
                        </MenuItem>
                      ))}
                    </MyTextField>
                  </Grid>
                  <Grid item xs={4}>
                    <label className="ml-3">{t("cards.bonusFile")}*</label>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Container>
                        <TextField
                          label={``}
                          disabled
                          size="small"
                          fullWidth
                          value={selectedFile ? selectedFile.name : ""}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        <input
                          type="file"
                          accept=".csv"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          onChange={handleFileChange}
                        />
                        <label style={{ margin: 0 }}>
                          <CustomButton
                            style={{
                              border: "none",
                              outline: "none",
                              boxShadow: "none",
                            }}
                            onClick={handleButtonClick}
                          >
                            {t("managementBonds.examine")}
                          </CustomButton>
                        </label>
                      </Container>
                      <Typography>
                        <a href="/#" onClick={handleDownload}>
                          {t("managementBonds.dowloadTemplate")}
                        </a>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={isSuper ? 12 : 4}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="extLot"
                            checked={values.extLot}
                            onChange={handleChange}
                          />
                        }
                        label={`${t("cards.isExtLot")}*`}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    sx={{ height: "60px" }}
                  >
                    <Button
                      style={{ border: "none", outline: "none" }}
                      variant="contained"
                      className="footer-btn danger-btn ripple"
                      onClick={handleCancel}
                    >
                      <i className="fa-solid fa-x"></i>
                      {t("button.cancel").toUpperCase()}
                    </Button>
                    <Button
                      type="submit"
                      style={{
                        border: "none",
                        outline: "none",
                      }}
                      data-testid="btnNext"
                      variant="contained"
                      className="footer-btn primary-btn ripple"
                    //onClick={handleContinue}
                    >
                      {t("button.next").toUpperCase()}
                    </Button>
                    <Button
                      style={{
                        border: "none",
                        outline: "none",
                        marginLeft: "4px",
                      }}
                      onClick={() =>
                        handleCleanForm(() => {
                          resetForm();
                          setSelectedFile(null);
                        })
                      }
                    >
                      <img src={clearLogo} alt="Icon clear" />
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </ThemeProvider>
    </div>
  );
};
