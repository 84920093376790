import { IRequestedCardsQuery } from "../../interfaces/reports";
import { getRequestedCards } from "../../services/reports";
import { buildFinalQueryParams } from "../../utils/utils";
import { AppState } from "../reducers/rootReducer";
import { setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";
import { arraySearch } from "./searchActions";

export const startGetRequestedCards = (
  params: Partial<IRequestedCardsQuery>
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;

    if (!token || !user) {
      throw new Error("invalid action");
    }

    try {
      if (params.page) {
        params.perPage = rowsPerPage;
      }
      if (user.operator) {
        params.operator = user.operator.id;
      }
      let finalValues = buildFinalQueryParams(params);
      const response = await getRequestedCards(token, finalValues);
      if (response.error) {
        dispatch(setMessageAlert(`alerts.errorSetUsers`));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      dispatch(arraySearch(response));
    } catch (error) {}
  };
};
