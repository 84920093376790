import { Types } from "../types/types";

interface IPaysActions {
  type: Types;
  payload?: any;
}
const initialState = {
  pays: null,
  totalBets: null,
  activeBet: null,
};

export const paysReducer = (state = initialState, action: IPaysActions) => {
  const { type, payload } = action;
  switch (type) {
    case Types.setPays:
      return {
        ...state,
        pays: payload as any | null,
      };
    default: 
      return state;
  }
};
