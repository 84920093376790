import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@mui/material";

import { AppState } from "../../../redux/reducers/rootReducer";

import { setActiveUser } from "../../../redux/actions/managementUsersActions";
import {
  setActiveStep,
  setCloseAlert,
  setFlagForm,
  setMessageAlert,
  setRowsPerPage,
} from "../../../redux/actions/helperActions";
import { arraySearch } from "../../../redux/actions/searchActions";

import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";

import { TabsManagementCards } from "./TabsManagementCards";
import { Box } from "@material-ui/core";
import { MyAlert } from "../../components/custom/MyAlert";

export const ManagementCards = () => {
  const { t } = useTranslation();
  const { flagFormCreate } = useSelector((state: AppState) => state.helper);
  const { openAlert, messageAlert, typeAlert } = useSelector(
    (state: AppState) => state.helper
  );
  const dispatch = useDispatch();

  const { activeLot } = useSelector((state: AppState) => state.managementCards);

  useEffect(() => {
    dispatch(setCloseAlert());
    dispatch(setRowsPerPage(9));
    dispatch(setActiveStep(0));
    dispatch(setActiveUser(null));
    dispatch(arraySearch(null));
    dispatch(setFlagForm({ flagFormCreate: false }));

    return () => {
      dispatch(setFlagForm({ flagFormCreate: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onCloseAlert = () => {
    dispatch(setCloseAlert());
    dispatch(setMessageAlert(""));
  };

  return (
    <div className="content w-100 mb-5">
      <MyBreadCrumbs
        titles={[t("sidebar.settings"), t("sidebar.managementCards")]}
      >
        {flagFormCreate && (
          <Typography color="text.primary" style={{ fontSize: "16px" }}>
            {t(!!activeLot ? "cards.editCard" : "cards.createCard")}
          </Typography>
        )}
      </MyBreadCrumbs>

      <Box mt={2}>
        <TabsManagementCards />
      </Box>
      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />
    </div>
  );
};

export default ManagementCards;
