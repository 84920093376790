import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { AppState } from "../../../../redux/reducers/rootReducer";

export const AssociateInformation = () => {
  const { t } = useTranslation();
  const { activeAssociate } = useSelector(
    (state: AppState) => state.associates
  );

  return (
    <>
      <Grid container spacing={2} sx={{ borderBottom: "1px solid #9E9E9E" }}>
        <Grid item xs={2}>
          <label>{t("table.name").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activeAssociate?.name.toUpperCase()}
          </Box>
        </Grid>
        <Grid item xs={2.5}>
          <label>{t("user.address").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activeAssociate?.address.toUpperCase()}
          </Box>
        </Grid>
        <Grid item xs={1.5}>
          <label>{t("user.country").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activeAssociate?.country.name.toUpperCase()}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <label>{t("companyManagement.department").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activeAssociate?.state.names.toUpperCase()}
          </Box>
        </Grid>
        <Grid item xs={1.8}>
          <label>{t("user.city").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activeAssociate?.city.names.toUpperCase()}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <label>{t("user.documentType").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activeAssociate?.documentType.name.toUpperCase()}
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        mt={1}
        sx={{ borderBottom: "1px solid #9E9E9E" }}
      >
        <Grid item xs={2}>
          <label>{t("associates.documentNo").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activeAssociate?.documentNumber}
          </Box>
        </Grid>
        <Grid item xs={2.5}>
          <label>{t("user.email").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activeAssociate?.email.toUpperCase()}
          </Box>
        </Grid>
        <Grid item xs={1.5}>
          <label>{t("user.phone").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activeAssociate?.phone}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <label>{t("associates.bank").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }}>{activeAssociate?.bank.name}</Box>
        </Grid>
        <Grid item xs={1.8}>
          <label>{t("associates.accountType").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {t(
              activeAssociate?.accountType === 0
                ? "associates.savings"
                : "associates.current"
            ).toUpperCase()}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <label>{t("associates.accountNo").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activeAssociate?.accountNumber}
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        mt={1}
        sx={{ borderBottom: "1px solid #9E9E9E" }}
      >
        <Grid item xs={2}>
          <label>{t("associates.transferMoney").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {t(
              activeAssociate?.transferMoney === 0 ? "table.not" : "table.yes"
            )}
          </Box>
        </Grid>
        <Grid item xs={2.5}>
          <label>{t("associates.maximumTransferValue").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activeAssociate?.maximumValue}
          </Box>
        </Grid>
        <Grid item xs={2.5}>
          <label>{t("associates.minimumTransferValue").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activeAssociate?.minimumValue}
          </Box>
        </Grid>
        <Grid item xs={2.5}>
          <label>
            {t("associates.commissionPercentageWithdrawals").toUpperCase()}
          </label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {parseFloat(
              activeAssociate?.commissionPercentageWithdrawals as string
            )}
          </Box>
        </Grid>
        <Grid item xs={2.5}>
          <label>
            {t("associates.commissionPercentageRefills").toUpperCase()}
          </label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {parseFloat(activeAssociate?.commissionPercentageRefills as string)}
          </Box>
        </Grid>
        <Grid item xs={2.5}>
          <label>{t("associates.commissionPercentageGGR").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {parseFloat(activeAssociate?.commissionPercentageGGR as string)}
          </Box>
        </Grid>
        <Grid item xs={2.5}>
          <label>
            {t("associates.commissionPercentagePrizes").toUpperCase()}
          </label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {parseFloat(activeAssociate?.commissionPercentagePrizes as string)}
          </Box>
        </Grid>
        <Grid item xs={2.5}>
          <label>
            {t("associates.commissionPercentageAffiliations").toUpperCase()}
          </label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {parseFloat(
              activeAssociate?.commissionPercentageAffiliations as string
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
