import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FC, useState, useEffect } from "react";

import { CircularProgress, Grid, TextField } from "@mui/material";

import { useForm } from "../../hooks/useForm";
import {
  Enum_FieldType,
  IFilterField,
  ISelectOptions,
  OptionSelectCheckBox,
} from "../../interfaces/components";
import { IQueryFilterMenu } from "../../interfaces/menu";
import {
  IQueryFilterFiles,
  IQueryFilterRefills,
} from "../../interfaces/refills";
import { IQueryFilterRoles } from "../../interfaces/roles";
import { IQueryFilterPlayer, IQueryFilterUsers } from "../../interfaces/users";
import {
  asyncLoadingFunction,
  setCloseAlert,
  setFlagCleanFilter,
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setRowsPerPage,
  setTypeAlert,
} from "../../redux/actions/helperActions";
import { startFilteringMenus } from "../../redux/actions/managementMenuActions";
import {
  getRefills,
  setRefillsRedux,
  startFilteringPlayers,
  startFilteringRefills,
  setPlayerRedux,
  startGetBonusRedeemedPlayer,
} from "../../redux/actions/managementPlayersActions";
import { startFilteringRoles } from "../../redux/actions/managementRolesActions";
import { startFilteringUsers } from "../../redux/actions/managementUsersActions";
import { arraySearch, setChangeData } from "../../redux/actions/searchActions";
import { AppState } from "../../redux/reducers/rootReducer";
import { IQueryFilterCompany } from "../../interfaces/operationCompany";
import {
  getBrandsFilter,
  getOperatorsFilter,
} from "../../redux/actions/managementCompanyActions";
import { startFilteringFiles } from "../../redux/actions/managementFilesActions";
import * as yup from "yup";
import "./filter.scss";
import { IQueryFilterPointsOfSale } from "../../interfaces/pointsSale";
import { IQueryFilterAssociate } from "../../interfaces/associate";
import { getAssociatesFilter } from "../../redux/actions/associatesActions";
import { IQueryFilterAds } from "../../interfaces/ads";
import { startFilterAds } from "../../redux/actions/managementAdsActions";
import { getPoinstSaleFilter } from "../../redux/actions/pointsSaleActions";

import { TextFieldFilter } from "./components/TextFieldFilter";
import { Roles_Enum } from "../../enums/enums";
import moment from "moment";
import { SelectWithSearch } from "../Custom/SelectWithSearch";
import { IQueryFilterLegal } from "../../interfaces/legal";
import { startFilterLegal } from "../../redux/actions/legalActions";
import { startFilterPaymentOrders } from "../../redux/actions/paymentOrdersActions";
import { startGetPlayerBets } from "../../redux/actions/betsActions";
import { SelectCheckbox } from "../Custom/SelectCheckbox";
import { startFilterMessages } from "../../redux/actions/messagesActions";
import { startFilterPromotions } from "../../redux/actions/promotionsActions";

interface Props {
  fields: IFilterField[];
  setDataFilter?: any;
  component?: string;
  handleFilter?(values: any): void;
  onClean?(): void;
  showTitle?: boolean;
  cleanFilter?: boolean;
  setIdDealer?: any;
  setIdAssociate?: any;
}
const styles = {
  "& input.Mui-disabled ": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
  "& .MuiSelect-select.Mui-disabled": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
};

const Filter: FC<Props> = ({
  component,
  fields,
  showTitle,
  setDataFilter,
  cleanFilter,
  handleFilter,
  onClean,
  setIdDealer,
  setIdAssociate,
}) => {
  const { t } = useTranslation();
  const { token } = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();
  const [isFiltering, setIsFiltering] = useState(false);
  const { data, changeData } = useSelector((state: AppState) => state.search);
  const { player } = useSelector((state: AppState) => state.managementPlayers);
  const { user } = useSelector((state: AppState) => state.auth);
  const { loading, changeTab } = useSelector((state: AppState) => state.helper);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const schema = yup.object().shape({
    email: yup.string().email(),
  });

  useEffect(() => {
    if (cleanFilter) {
      handleCleanFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleanFilter]);

  useEffect(() => {
    if (changeTab) {
      handleCleanFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeTab]);

  useEffect(() => {
    if (data && data.data && data.data.length === 0 && !changeData) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.noData"));
      dispatch(setTypeAlert("error"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getInitialFormValues = () => {
    const finalValues = {};

    fields?.forEach((item) => {
      //@ts-ignore
      finalValues[item.label] = "";
    });
    return finalValues;
  };
  const initialValues = getInitialFormValues();

  const [formValues, handleInputChange, reset] = useForm({ ...initialValues });

  /**
   * Soporta el estado boolean para mostrar campos en filtro
   */
  const [isShowFilter, setIsShowFilter] = useState(false);

  /**
   * Soporta el evento click del boton ocultar o mostrar filtro busqueda
   */
  const toogleShowFilter = () => {
    if (isShowFilter) {
      setIsShowFilter(false);
    } else {
      setIsShowFilter(true);
    }
  };
  const handleInputChangeMain = (e: any) => {
    if (component === "player") {
      dispatch(setPlayerRedux(null));
      Object.entries(formValues).forEach(([key, value]) => {
        if (key === e.target.name) {
          //@ts-ignore
          formValues[key] = value;
        } else {
          formValues[key] = "";
        }
      });
    }
    handleInputChange(e);

    setIsFiltering(false);
  };
  const validateEmail = async (email: string) => {
    return await schema.isValid({ email: email });
  };
  const ifComponentIsUser = async () => {
    const userParams: IQueryFilterUsers = {
      username: isFiltering ? "" : formValues[t("filters.username")],
      nameComplete: isFiltering ? "" : formValues[t("filters.name")],
      stateUser: isFiltering ? "" : formValues[t("form.state")].toString(),
      roles: isFiltering ? "" : formValues[t("user.rol")],
      startDate: isFiltering ? "" : formValues[t("filters.startDate")],
      endDate: isFiltering ? "" : formValues[t("filters.endDate")],
    };

    if (userParams.endDate.trim() && !userParams.startDate.trim()) {
      dispatch(setMessageAlert("alerts.selectStartDate"));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }
    const errorDate = moment(userParams.startDate).isAfter(userParams.endDate);

    if (errorDate) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.errorDate"));
      dispatch(setTypeAlert("error"));
    } else {
      await dispatch(startFilteringUsers(userParams, 1, isFiltering));
      setDataFilter(userParams);
    }
  };
  const ifComponentIsMenu = async () => {
    const menuParams: IQueryFilterMenu = {
      name: isFiltering ? "" : formValues[`${t("menuManagment.menu")}`],
      description: isFiltering ? "" : formValues[`${t("form.description")}`],
      state: isFiltering ? "" : formValues[`${t("form.state")}`].toString(),
      url: isFiltering ? "" : formValues[`${t("table.url")}`],
    };

    await dispatch(startFilteringMenus(menuParams, 1, true, isFiltering));
    setDataFilter(menuParams);
  };
  const ifComponentIsPlayer = async () => {
    dispatch(arraySearch(null));
    const playerParams: IQueryFilterPlayer = {
      name: formValues[`${t("user.username")}`],
      email: formValues[`${t("filters.email")}`].toString(),
      id: formValues[`${t("filters.id")}`],
    };

    if (isValidEmail) {
      if (
        playerParams.email === "" &&
        playerParams.name === "" &&
        playerParams.id === ""
      ) {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.noParams"));
        dispatch(setTypeAlert("error"));
        setIsFiltering(false);
      } else {
        dispatch(setLoadingState({ loading: true }));
        dispatch(startFilteringPlayers(playerParams));
        dispatch(setFlagCleanFilter({ flagCleanFilter: false }));
      }
    } else {
      setIsFiltering(false);
    }
  };
  const ifComponentIsRefillsTab = async () => {
    const startDate = isFiltering ? "" : formValues[t("filters.startDate")];
    const endDate = isFiltering ? "" : formValues[t("filters.endDate")];

    const refillParams: IQueryFilterRefills = {
      startDate,
      endDate,
      number: isFiltering ? "" : formValues[`${t("form.refillNumber")}`],
      value: isFiltering ? "" : formValues[t("form.value")],
      page: 1,
    };

    if (endDate.trim() && !startDate.trim()) {
      setIsFiltering(false);
      dispatch(setMessageAlert("alerts.selectStartDate"));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }

    const errorDate = moment(refillParams.startDate).isAfter(
      refillParams.endDate
    );

    if (errorDate) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.errorDate"));
      dispatch(setTypeAlert("error"));
    } else {
      dispatch(
        asyncLoadingFunction(
          async () => await dispatch(startFilteringRefills(refillParams))
        )
      );

      setDataFilter(refillParams);
    }
  };
  const ifComponentIsRole = async () => {
    const superAdmin = user && user.roles.name === Roles_Enum.SUPER_ADMIN;
    const rolesParams: IQueryFilterRoles = {
      name: isFiltering ? "" : formValues[t("user.rol")],
      state: isFiltering ? "" : formValues[t("form.state").toString()],
      menuId: isFiltering ? "" : formValues[t("menuManagment.menu")],
      permission: isFiltering ? "" : formValues[t("rolManagement.permissions")],
    };
    await dispatch(
      startFilteringRoles(rolesParams, superAdmin, 1, true, isFiltering)
    );
    setDataFilter(rolesParams);
  };
  const ifComponentIsCompany = async () => {
    const startDate = isFiltering ? "" : formValues[t("filters.startDate")];
    const endDate = isFiltering ? "" : formValues[t("filters.endDate")];
    const nit = isFiltering ? "" : formValues[t("companyManagement.nit")];
    const brand = isFiltering ? "" : formValues[t("companyManagement.brand")];
    const country = isFiltering ? "" : formValues[t("user.country")];

    let companyParams: IQueryFilterCompany = {
      name: isFiltering ? "" : formValues[t("companyManagement.companyName")],
      country,
      startDate,
      endDate,
      page: 1,
    };

    if (component === "managementCompany") {
      companyParams.nit = nit;
    } else {
      companyParams.brand = brand;
    }

    if (endDate.trim() && !startDate.trim()) {
      setIsFiltering(false);
      dispatch(setMessageAlert("alerts.selectStartDate"));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }

    const errorDate = moment(companyParams.startDate).isAfter(
      companyParams.endDate
    );
    if (errorDate) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.errorDate"));
      dispatch(setTypeAlert("error"));
    } else {
      component === "managementCompany"
        ? await dispatch(getOperatorsFilter(companyParams, true, isFiltering))
        : await dispatch(getBrandsFilter(companyParams, isFiltering));
      setDataFilter(companyParams);
    }
  };

  const ifComponentIsPointOfSale = async () => {
    const pointsOfSaleParams: IQueryFilterPointsOfSale = {
      id: isFiltering ? "" : formValues[t("filters.id")],
      associate: isFiltering
        ? ""
        : formValues[t("pointsOfSale.associated").toString()],
      name: isFiltering ? "" : formValues[t("pointsOfSale.name")],
      createdUser: isFiltering ? "" : formValues[t("table.createdBy")],
      email: isFiltering ? "" : formValues[t("pointsOfSale.email")],
      address: isFiltering ? "" : formValues[t("pointsOfSale.address")],
      phone: isFiltering ? "" : formValues[t("pointsOfSale.phone")],
      state: isFiltering ? "" : formValues[t("form.state")].toString(),
    };

    await dispatch(getPoinstSaleFilter(pointsOfSaleParams, 1, isFiltering));
    setDataFilter(pointsOfSaleParams);
  };
  const ifComponentIsAssociate = async () => {
    const associateParams: IQueryFilterAssociate = {
      id: isFiltering ? "" : formValues[t("associates.idCode")],
      createdUser: isFiltering ? "" : formValues[t("table.createdBy")],
      name: isFiltering ? "" : formValues[t("filters.name")],
      stateUser: isFiltering ? "" : formValues[t("form.state")].toString(),
      address: isFiltering ? "" : formValues[t("user.address")],
      phone: isFiltering ? "" : formValues[t("user.phone")],
      page: 1,
    };

    await dispatch(getAssociatesFilter(associateParams, isFiltering));
    setDataFilter(associateParams);
  };
  const ifComponentIsFiles = async () => {
    const filesParams: IQueryFilterFiles = {
      startDate: formValues[t("filters.startDate")],
      endDate: formValues[t("filters.endDate")],
    };
    if (filesParams.startDate !== "" || filesParams.endDate !== "") {
      filesParams.startDate =
        filesParams.startDate === ""
          ? "2022-01-01T00:00:00"
          : filesParams.startDate + "T00:00:00";
      filesParams.endDate =
        filesParams.endDate === ""
          ? new Date().toISOString()
          : filesParams.endDate + "T23:59:00";

      dispatch(setLoadingState({ loading: true }));
      dispatch(startFilteringFiles(filesParams));
      dispatch(setLoadingState({ loading: false }));
    } else {
      if (token) {
        dispatch(setLoadingState({ loading: true }));
        dispatch(setRowsPerPage(9));
        dispatch(getRefills(token, 1));
        dispatch(setLoadingState({ loading: false }));
      }
    }
  };
  const ifComponentIsManagementADS = async () => {
    const startDate = isFiltering ? "" : formValues[t("filters.startDate")];
    const endDate = isFiltering ? "" : formValues[t("filters.endDate")];
    const adsParams: IQueryFilterAds = {
      id: isFiltering ? "" : formValues[t("filters.id")],
      state: isFiltering ? "" : formValues[t("form.state")].toString(),
      startDate,
      endDate,
      page: 1,
    };

    if (endDate.trim() && !startDate.trim()) {
      dispatch(setMessageAlert("alerts.selectStartDate"));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }
    const errorDate = moment(startDate).isAfter(endDate);

    if (errorDate) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.errorDate"));
      dispatch(setTypeAlert("error"));
    } else {
      dispatch(setLoadingState({ loading: true }));
      await dispatch(startFilterAds(adsParams, isFiltering));
      setDataFilter(adsParams);
    }
  };

  /** DETAIL PLAYER */
  // PAYMENT ORDER
  const ifComponentIsPaymentOrders = async () => {
    const startDate = isFiltering ? "" : formValues[t("filters.startDate")];
    const endDate = isFiltering ? "" : formValues[t("filters.endDate")];

    const state =
      component === "paidPaymentOrders"
        ? 1
        : component === "pendingPaymentOrders"
        ? 2
        : 3;

    if (endDate.trim() && !startDate.trim()) {
      dispatch(setMessageAlert("alerts.selectStartDate"));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }

    const errorDate = moment(startDate).isAfter(endDate);
    if (errorDate) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.errorDate"));
      dispatch(setTypeAlert("error"));
    } else {
      const paymentOrdersParams: any = {
        id: isFiltering ? "" : formValues[t("player.paymentOrder")].toString(),
        state: state.toString(),
        value: isFiltering ? "" : formValues[t("form.value")].toString(),
        paymentType: isFiltering
          ? ""
          : formValues[t("player.paymentType")].toString(),
        startDate,
        endDate,
        page: 1,
      };

      dispatch(
        asyncLoadingFunction(
          async () =>
            await dispatch(startFilterPaymentOrders(paymentOrdersParams))
        )
      );

      setDataFilter(paymentOrdersParams);
    }
  };

  /** GAMING ACTIVITY */
  // BETS
  const ifComponentIsTabBets = async () => {
    const startDate = isFiltering ? "" : formValues[t("filters.startDate")];
    const endDate = isFiltering ? "" : formValues[t("filters.endDate")];

    if (endDate.trim() && !startDate.trim()) {
      dispatch(setMessageAlert("alerts.selectStartDate"));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }

    const errorDate = moment(startDate).isAfter(endDate);
    if (errorDate) {
      dispatch(setMessageAlert("alerts.errorDate"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    } else {
      const betsParams: any = {
        id: isFiltering ? "" : formValues[t("table.betId")].toString(),
        mode: isFiltering ? "" : formValues[t("bets.sport")],
        type: isFiltering ? "" : formValues[t("bets.betType")].toString(),
        event: isFiltering ? "" : formValues[t("bets.event")].toString(),
        betState: isFiltering ? "" : formValues[t("table.state")].toString(),
        market: isFiltering ? "" : formValues[t("bets.market")],
        amount: isFiltering ? "" : formValues[t("bets.betAmount")].toString(),
        winning: isFiltering
          ? ""
          : formValues[t("bets.winningAmount")].toString(),
        startDate,
        endDate,
        page: 1,
        playerId: player?.data[0].id?.toString(),
      };

      dispatch(
        asyncLoadingFunction(
          async () => await dispatch(startGetPlayerBets(betsParams))
        )
      );
      setDataFilter(betsParams);
    }
  };

  const ifComponentIsTabBonds = async () => {
    const startDate = isFiltering ? "" : formValues[t("filters.startDate")];
    const endDate = isFiltering ? "" : formValues[t("filters.endDate")];

    if (endDate.trim() && !startDate.trim()) {
      dispatch(setMessageAlert("alerts.selectStartDate"));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }

    const errorDate = moment(startDate).isAfter(endDate);
    if (errorDate) {
      dispatch(setMessageAlert("alerts.errorDate"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    } else {
      const bondsParams: any = {
        type: isFiltering
          ? ""
          : formValues[t("managementBonds.bondType")].toString(),
        value: isFiltering
          ? ""
          : formValues[t("managementBonds.bondValue")].toString(),
        startDate,
        endDate,
        page: 1,
        playerId: player?.data[0].id?.toString(),
      };

      dispatch(
        asyncLoadingFunction(
          async () => await dispatch(startGetBonusRedeemedPlayer(bondsParams))
        )
      );
      setDataFilter(bondsParams);
    }
  };

  const ifComponentIsLegal = async () => {
    const params: IQueryFilterLegal = {
      state: isFiltering ? "" : formValues[t("form.state")].toString(),
      title: isFiltering ? "" : formValues[t("legal.title")],
      createAt: isFiltering ? "" : formValues[t("user.createAt")],
      page: 1,
    };
    dispatch(
      asyncLoadingFunction(async () => await dispatch(startFilterLegal(params)))
    );
    setDataFilter(params);
  };

  const ifComponentIsMessages = async () => {
    const params = {
      startDate: isFiltering ? "" : formValues[t("filters.startDate")],
      endDate: isFiltering ? "" : formValues[t("filters.startDate")],
      page: 1,
    };
    dispatch(
      asyncLoadingFunction(
        async () => await dispatch(startFilterMessages(params))
      )
    );
    setDataFilter(params);
  };

  const ifComponentIsPromotions = async () => {
    const params = {
      startDate: isFiltering ? "" : formValues[t("filters.startDate")],
      endDate: isFiltering ? "" : formValues[t("filters.startDate")],
      page: 1,
      perPage: 7,
    };
    dispatch(
      asyncLoadingFunction(
        async () => await dispatch(startFilterPromotions(params))
      )
    );
    setDataFilter(params);
  };

  const handleFilterData = async () => {
    dispatch(setChangeData(false));
    //Filtrar datos
    setIsFiltering(true);
    if (handleFilter) {
      return handleFilter(formValues);
    }
    if (component === "users") {
      await ifComponentIsUser();
    }
    if (component === "menu") {
      await ifComponentIsMenu();
    }
    if (component === "player") {
      await ifComponentIsPlayer();
    }
    if (component === "refillsTab") {
      await ifComponentIsRefillsTab();
    }
    if (component === "roles") {
      await ifComponentIsRole();
    }
    if (component === "managementCompany" || component === "configCompany") {
      await ifComponentIsCompany();
    }
    /**
     * COMPONENTE PUNTOS DE VENTA
     */
    if (component === "pointsOfSale") {
      await ifComponentIsPointOfSale();
    }
    if (component === "associates") {
      await ifComponentIsAssociate();
    }
    /**
     * COMPONENTE FILES
     */
    if (component === "files") {
      await ifComponentIsFiles();
    }
    if (component === "managementAds") {
      await ifComponentIsManagementADS();
    }
    /** TABS DETAIL PLAYER */
    //PAYMENT ORDER
    if (
      component === "pendingPaymentOrders" ||
      component === "paidPaymentOrders" ||
      component === "canceledPaymentOrders"
    ) {
      await ifComponentIsPaymentOrders();
    }
    //TAB BETS GAMING ACTIVITY
    if (component === "bets") {
      await ifComponentIsTabBets();
    }
    if (component === "bonds") {
      await ifComponentIsTabBonds();
    }
    if (component === "legal") {
      await ifComponentIsLegal();
    }
    if (component === "messages") {
      await ifComponentIsMessages();
    }
    if (component === "promotions") {
      await ifComponentIsPromotions();
    }
  };

  const handleCleanFilters = async () => {
    reset();
    setIsFiltering(false);
    dispatch(arraySearch(null));
    dispatch(setCloseAlert());
    dispatch(setChangeData(true));
    if (onClean) {
      return onClean();
    }
    if (component === "player") {
      dispatch(setPlayerRedux(null));
      dispatch(setRefillsRedux(null));
    }

    component === "menu" && (await ifComponentIsMenu());
    component === "users" && (await ifComponentIsUser());
    component === "roles" && (await ifComponentIsRole());
    component === "pointsOfSale" && (await ifComponentIsPointOfSale());

    (component === "managementCompany" || component === "configCompany") &&
      (await ifComponentIsCompany());
    component === "associates" && (await ifComponentIsAssociate());
    component === "managementAds" && (await ifComponentIsManagementADS());
    component === "legal" && (await ifComponentIsLegal());
    component === "messages" && (await ifComponentIsMessages());
    component === "promotions" && (await ifComponentIsPromotions());
    component === "bets" && (await ifComponentIsTabBets());
    component === "refillsTab" && (await ifComponentIsRefillsTab());
    (component === "pendingPaymentOrders" ||
      component === "paidPaymentOrders" ||
      component === "canceledPaymentOrders") &&
      (await ifComponentIsPaymentOrders());
    component === "bonds" && (await ifComponentIsTabBonds());
  };

  useEffect(() => {
    let allInputClean =
      JSON.stringify(formValues) === JSON.stringify(initialValues);
    const checkEmailValidation = async () => {
      const isValid = await validateEmail(formValues[t("filters.email")]);
      setIsValidEmail(isValid);
    };
    if (allInputClean) {
      setIsFiltering(false);
    }
    if (formValues[t("filters.email")] !== undefined) {
      if (formValues[t("filters.email")] !== "") {
        checkEmailValidation();
      } else {
        setIsValidEmail(true);
      }
    }
    if (component === "reportAssociate" && formValues.Distribuidores) {
      setIdDealer(formValues.Distribuidores);
    }
    if (component === "reportAssociate" && formValues.Asociados) {
      setIdAssociate(formValues.Asociados);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const buildField = (item: IFilterField) => {
    const { type, label, options } = item;

    switch (type) {
      case Enum_FieldType.TEXT:
        return (
          <TextFieldFilter
            component={component}
            formValues={formValues}
            handleInputChangeMain={handleInputChangeMain}
            isValidEmail={isValidEmail}
            label={item.label}
            player={player}
          />
        );
      case Enum_FieldType.NUMBER:
        return (
          <TextFieldFilter
            component={component}
            formValues={formValues}
            handleInputChangeMain={handleInputChangeMain}
            isValidEmail={isValidEmail}
            label={item.label}
            player={player}
            type="number"
          />
        );
      case Enum_FieldType.SELECT:
        return (
          <SelectWithSearch
            label={item.label}
            options={(options as ISelectOptions[]) || []}
            value={formValues[`${item.label}`]}
            onChange={handleInputChangeMain}
          />
        );
      case Enum_FieldType.DATE:
        return (
          <TextField
            variant="outlined"
            size="small"
            type={"date"}
            name={`${label}`}
            id={`${label}`}
            label={`${label}`}
            fullWidth
            sx={styles}
            autoComplete="off"
            onChange={handleInputChangeMain}
            InputLabelProps={{ shrink: true }}
            inputProps={
              component !== "managementAds" && component !== "reports"
                ? {
                    max: new Date().toLocaleDateString("en-CA"),
                  }
                : undefined
            }
            value={formValues[`${label}`]}
          />
        );
      case Enum_FieldType.SELECT_CHECKBOX:
        return (
          <SelectCheckbox
            label={item.label}
            options={options as OptionSelectCheckBox}
            onChange={handleInputChangeMain}
          />
        );
      default:
        return (
          <select
            className="custom-select"
            name={`${item.label}`}
            value={formValues[`${item.label}`]}
            //@ts-ignore
            onChange={handleInputChangeMain}
          >
            {(item.options as ISelectOptions[])?.map((obj: any, i: number) => {
              return (
                <option key={obj.value + "-" + i} value={obj.value}>
                  {obj.label}
                </option>
              );
            })}
          </select>
        );
    }
  };
  const gridDisplay = isShowFilter ? "none" : "block";
  const displayFilterContainer = isShowFilter ? "block" : "none";
  const gridShowFilterLabels = isShowFilter
    ? t("filters.fewerFilters")
    : t("filters.moreFilters");
  const gridShowFilterClass = isShowFilter
    ? "fa fa-angle-up fa-xl ml-1 pl-1"
    : "fa fa-angle-down fa-xl  ml-1 pl-1";
  const gridShowFilterAction = isFiltering ? (
    loading ? (
      <CircularProgress size={"1rem"} color="inherit" />
    ) : (
      t("filters.clean").toUpperCase()
    )
  ) : (
    t("filters.filter").toUpperCase()
  );

  return (
    <div className="container-fluid" data-testid="filter-component">
      {showTitle && (
        <div className="col-lg-12 pb-0 pt-0 pr-0 pl-0">
          <h4 className="subtitleFilter">{t("filters.filters")}</h4>
        </div>
      )}
      <div
        className={
          component === "player" || component === "irrigation"
            ? "row"
            : "row filter-container"
        }
      >
        <Grid container spacing={2}>
          {fields?.slice(0, 3).map((field, i) => (
            <Grid
              key={field.value + "-" + i}
              item
              xs={12}
              sm={6}
              md={3}
              //lg={fields.length % 2 === 0 ? 12 / fields.length : 4}
              lg={field.label === t("legal.title") ? 4 : 3}
            >
              {buildField(field)}
            </Grid>
          ))}
          {isShowFilter && (
            <>
              {fields?.slice(3).map((field, i) => (
                <Grid
                  key={field.value + "-" + i}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "none",
                      md: "none",
                      lg: "none",
                    },
                  }}
                  //lg={fields.length % 2 === 0 ? 12 / fields.length : 4}
                  lg={3}
                >
                  {buildField(field)}
                </Grid>
              ))}
            </>
          )}
          <Grid
            item
            lg={component === "legal" ? 2 : 3}
            md={3}
            sm={6}
            xs={12}
            sx={{
              display: {
                lg: "block",
                md: gridDisplay,
                sm: gridDisplay,
                xs: gridDisplay,
              },
            }}
          >
            <Grid container>
              {fields && fields.length > 3 ? (
                <Grid
                  item
                  md={6}
                  lg={6}
                  sm={5}
                  xs={3}
                  onClick={() => toogleShowFilter()}
                >
                  <Grid container>
                    <Grid item md={6} lg={6} sm={5} xs={6}>
                      <span
                        className=" spanMoreFilter mr-3"
                        style={{ color: "#666666", cursor: "pointer" }}
                      >
                        {gridShowFilterLabels}
                      </span>
                    </Grid>
                    <Grid item md={6} lg={6} mt={1} sm={7} xs={6}>
                      <i
                        style={{ cursor: "pointer" }}
                        className={gridShowFilterClass}
                        onClick={() => toogleShowFilter()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item md={6} lg={6} sm={5} xs={3} />
              )}

              <Grid item md={6} lg={6} sm={7} xs={9}>
                <button
                  className="d-flex btn-light-dark"
                  type="button"
                  style={{ width: "100%" }}
                  onClick={() =>
                    isFiltering ? handleCleanFilters() : handleFilterData()
                  }
                  disabled={
                    (component === "refillsTab" &&
                      (!player || player.data.length === 0)) ||
                    !!loading
                  }
                >
                  {!loading && (
                    <div className="p-0 text-right">
                      <i className="fa fa-filter btn-icon pt-1 fa-md"></i>
                    </div>
                  )}
                  <div className="p-0 text-left">
                    <label className="btn-label pl-1">
                      {gridShowFilterAction}
                    </label>
                  </div>
                </button>
              </Grid>
            </Grid>
          </Grid>

          {isShowFilter && (
            <>
              {fields?.slice(3).map((field, i) => (
                <Grid
                  key={field.value + "-" + i}
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{ display: { xs: "none", sm: "block" } }}
                  //lg={fields.length % 2 === 0 ? 12 / fields.length : 4}
                  lg={3}
                >
                  {buildField(field)}
                </Grid>
              ))}
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                sx={{
                  display: {
                    lg: "none",
                    md: displayFilterContainer,
                    sm: displayFilterContainer,
                    xs: displayFilterContainer,
                  },
                }}
              >
                <Grid container>
                  {fields && fields.length > 3 && (
                    <Grid
                      item
                      md={6}
                      lg={6}
                      sm={5}
                      xs={3}
                      onClick={() => toogleShowFilter()}
                    >
                      <Grid container>
                        <Grid item md={6} lg={6} sm={5} xs={6}>
                          <span
                            className=" spanMoreFilter mr-3"
                            style={{ color: "#2C97DF", cursor: "pointer" }}
                          >
                            {isShowFilter
                              ? t("filters.fewerFilters")
                              : t("filters.moreFilters")}
                          </span>
                        </Grid>
                        <Grid item md={6} lg={6} mt={1} sm={7} xs={6}>
                          <i
                            style={{ cursor: "pointer" }}
                            className={
                              isShowFilter
                                ? "fa fa-angle-up fa-xl ml-1 pl-1"
                                : "fa fa-angle-down fa-xl  ml-1 pl-1"
                            }
                            onClick={() => toogleShowFilter()}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item md={6} lg={6} sm={6} xs={9}>
                    <button
                      className="d-flex btn-light-dark"
                      type="button"
                      style={{ width: "100%" }}
                      onClick={() =>
                        isFiltering ? handleCleanFilters() : handleFilterData()
                      }
                      disabled={!!loading}
                    >
                      <div className="p-0 text-right">
                        <i className="fa fa-filter btn-icon pt-1 fa-md"></i>
                      </div>
                      <div className="p-0 text-left">
                        <label className="btn-label pl-1">
                          {isFiltering
                            ? t("filters.clean")
                            : t("filters.filter")}
                        </label>
                      </div>
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default Filter;
