import { TableCell } from "@mui/material";
import moment from "moment";
import React, { FC } from "react";
import { buildFullName } from "../../../../../utils/utils";
interface props {
  row: any;
}
export const RowCashFlowDeposit: FC<props> = ({ row }) => {
  const fullNamePlayer = buildFullName(
    row.player.firstName,
    row.player.secondName,
    row.player.lastName,
    row.player.secondLastName
  );
  return (
    <>
      <TableCell className="word-break" align="center">
        {row.player.id}
      </TableCell>

      <TableCell className="word-break" align="center">
        {"$ " + row.amount}
      </TableCell>
      <TableCell className="word-break" align="center">
        {row.provider.name}
      </TableCell>

      <TableCell className="word-break" align="center">
        {fullNamePlayer}
      </TableCell>
      <TableCell className="word-break" align="center">
        {row.player.email}
      </TableCell>
      <TableCell className="word-break" align="center">
        {moment(row.createAt).format("DD/MM/YYYY HH:mm:ss")}
      </TableCell>
      <TableCell className="word-break" align="center">
        {row.meta?.transactionReference ?? ""}
      </TableCell>
      <TableCell />
    </>
  );
};
