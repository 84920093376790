import i18next from "i18next";
import * as yup from "yup";

const HOUR_PATTERN = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;

const createAmountValidation = (field: string) =>
  yup
    .number()
    .nullable()
    .typeError(i18next.t("managementBonds.errorNumberValue"))
    .moreThan(1, i18next.t("commercialNetworks.greaterThanOne"));

const createTimeValidation = (startField: string, endField: string) => ({
  [`${startField}`]: yup.string().when(`${endField}`, {
    is: (endTime: string) => endTime && endTime.trim() !== "",
    then: yup
      .string()
      .required(i18next.t("form.requiredFile"))
      .matches(HOUR_PATTERN),
    otherwise: yup.string(),
  }),
  [`${endField}`]: yup
    .string()
    .test(
      "is-greater",
      i18next.t("commercialNetworks.greaterStartTime"),
      function (value) {
        const startTime = this.parent[startField];
        return value ? value > startTime : true;
      }
    )
    .matches(HOUR_PATTERN)
    .when(`${startField}`, {
      is: (startTime: string) => startTime && startTime.trim() !== "",
      then: yup.string().required(i18next.t("form.requiredFile")),
      otherwise: yup.string(),
    }),
});

export const networkConfigurationSchema = yup
  .object()
  .shape(
    {
      timezone: yup.string().required(i18next.t("form.requiredFile")),
      ...createTimeValidation("rechargeStartTime", "rechargeEndTime"),
      ...createTimeValidation("withdrawalStartTime", "withdrawalEndTime"),
      maxRechargePerTransaction: createAmountValidation("maxRechargePerTransaction"),
      maxRechargeDaily: createAmountValidation("maxRechargeDaily"),
      maxRechargeWeekly: createAmountValidation("maxRechargeWeekly"),
      maxRechargeMonthly: createAmountValidation("maxRechargeMonthly"),
      maxWithdrawalPerTransaction: createAmountValidation("maxWithdrawalPerTransaction"),
      maxWithdrawalDaily: createAmountValidation("maxWithdrawalDaily"),
      maxWithdrawalWeekly: createAmountValidation("maxWithdrawalWeekly"),
      maxWithdrawalMonthly: createAmountValidation("maxWithdrawalMonthly"),
    },
    [
      ["rechargeEndTime", "rechargeStartTime"],
      ["withdrawalEndTime", "withdrawalStartTime"],
    ]
  )
  .test("rechargeAmounts", function (values) {
    const {
      maxRechargePerTransaction,
      maxRechargeDaily,
      maxRechargeWeekly,
      maxRechargeMonthly,
    } = values;

    const errors: Record<string, string> = {};

    if (
      maxRechargePerTransaction &&
      maxRechargeDaily &&
      maxRechargePerTransaction > maxRechargeDaily
    ) {
      errors.maxRechargePerTransaction = i18next.t("commercialNetworks.lessThanDailyAmount");
    }
    if (
      maxRechargePerTransaction &&
      maxRechargeWeekly &&
      maxRechargePerTransaction > maxRechargeWeekly
    ) {
      errors.maxRechargePerTransaction = i18next.t("commercialNetworks.lessThanWeeklyAmount");
    }
    if (
      maxRechargePerTransaction &&
      maxRechargeMonthly &&
      maxRechargePerTransaction > maxRechargeMonthly
    ) {
      errors.maxRechargePerTransaction = i18next.t("commercialNetworks.lessThanMonthlyAmount");
    }
    if (
      maxRechargeDaily &&
      maxRechargeWeekly &&
      maxRechargeDaily > maxRechargeWeekly
    ) {
      errors.maxRechargeDaily = i18next.t("commercialNetworks.lessThanWeeklyAmount");
    }
    if (
      maxRechargeDaily &&
      maxRechargeMonthly &&
      maxRechargeDaily > maxRechargeMonthly
    ) {
      errors.maxRechargeDaily = i18next.t("commercialNetworks.lessThanMonthlyAmount");;
    }
    if (
      maxRechargeWeekly &&
      maxRechargeMonthly &&
      maxRechargeWeekly > maxRechargeMonthly
    ) {
      errors.maxRechargeWeekly = i18next.t("commercialNetworks.lessThanMonthlyAmount");
    }

    if (Object.keys(errors).length > 0) {
      return this.createError({
        path: Object.keys(errors)[0],
        message: Object.values(errors)[0],
      });
    }

    return true;
  })
  .test("withdrawalAmounts", function (values) {
    const {
      maxWithdrawalPerTransaction,
      maxWithdrawalDaily,
      maxWithdrawalWeekly,
      maxWithdrawalMonthly,
    } = values;

    const errors: Record<string, string> = {};

    if (
      maxWithdrawalPerTransaction &&
      maxWithdrawalDaily &&
      maxWithdrawalPerTransaction > maxWithdrawalDaily
    ) {
      errors.maxWithdrawalPerTransaction = i18next.t("commercialNetworks.lessThanDailyAmount");
    }
    if (
      maxWithdrawalPerTransaction &&
      maxWithdrawalWeekly &&
      maxWithdrawalPerTransaction > maxWithdrawalWeekly
    ) {
      errors.maxWithdrawalPerTransaction = i18next.t("commercialNetworks.lessThanWeeklyAmount");
    }
    if (
      maxWithdrawalPerTransaction &&
      maxWithdrawalMonthly &&
      maxWithdrawalPerTransaction > maxWithdrawalMonthly
    ) {
      errors.maxWithdrawalPerTransaction = i18next.t("commercialNetworks.lessThanMonthlyAmount");
    }
    if (
      maxWithdrawalDaily &&
      maxWithdrawalWeekly &&
      maxWithdrawalDaily > maxWithdrawalWeekly
    ) {
      errors.maxWithdrawalDaily = i18next.t("commercialNetworks.lessThanWeeklyAmount");
    }
    if (
      maxWithdrawalDaily &&
      maxWithdrawalMonthly &&
      maxWithdrawalDaily > maxWithdrawalMonthly
    ) {
      errors.maxWithdrawalDaily = i18next.t("commercialNetworks.lessThanMonthlyAmount");
    }
    if (
      maxWithdrawalWeekly &&
      maxWithdrawalMonthly &&
      maxWithdrawalWeekly > maxWithdrawalMonthly
    ) {
      errors.maxWithdrawalWeekly = i18next.t("commercialNetworks.lessThanMonthlyAmount");
    }

    if (Object.keys(errors).length > 0) {
      return this.createError({
        path: Object.keys(errors)[0],
        message: Object.values(errors)[0],
      });
    }

    return true;
  });
