import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Autocomplete, Box, Checkbox, Chip, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ClearIcon from "@mui/icons-material/Clear";

import { ICountryStep } from "../../interfaces/operationCompany";
import { setCountries } from "../../redux/actions/helperActions";
import { AppState } from "../../redux/reducers/rootReducer";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  countriesSelected: ICountryStep[];
  handleSelectCountry: (e: any, value: CountryType[]) => void;
}
export const CountrySelect = ({
  countriesSelected,
  handleSelectCountry,
}: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: AppState) => state.auth);
  const { countries } = useSelector((state: AppState) => state.helper);

  // @ts-ignore
  const arrayCountries: readonly ICountryStep[] = countries;

  useEffect(() => {
    token && dispatch(setCountries(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Autocomplete
      multiple
      id="country-select-demo"
      // @ts-ignore
      options={arrayCountries}
      autoComplete={false}
      // @ts-ignore
      value={countriesSelected}
      onChange={(e, v) => {
        v.forEach((item, index) => {
          v[index].countriesCurrencies = [];
          v[index].countriesLanguages = [];
          v[index].countriesGames = [];
          v[index].paymentMethodsRecharges = [];
          v[index].paymentMethodsWithdrawal = [];
          v[index].countriesSolutions = [];
        });

        handleSelectCountry(e, v);
      }}
      getOptionLabel={(option) => option.name as string}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => (
        <Box
          component="li"
          key={option.name}
          id={option.name}
          sx={{
            "& > img": { mr: 2, flexShrink: 0, borderRadius: "50%" },
            color: "black",
          }}
          {...props}
        >
          <Checkbox
            id={option.name}
            icon={icon}
            checkedIcon={checkedIcon}
            sx={{
              marginRight: 2,
              "&.Mui-checked": {
                color: "#04BF9D",
              },
            }}
            checked={selected}
          />
          <img
            id={option.name}
            loading="lazy"
            style={{
              width: "20px",
              height: "20px",
            }}
            src={option.imgUrl}
            alt={option.name}
          />
          {option.name}
        </Box>
      )}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip
            style={{
              backgroundColor: "rgba(0, 126, 140, 0.1)",
              height: "28px",
            }}
            deleteIcon={<ClearIcon style={{ fontSize: 14 }} />}
            {...getTagProps({ index })}
            label={
              <>
                <img
                  loading="lazy"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                    borderRadius: "50%",
                    padding: 0,
                  }}
                  src={option.imgUrl}
                  alt=""
                />
                {option.name}
              </>
            }
          />
        ));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          // @ts-ignore
          InputLabelProps={{ shrink: false }}
          label={" "}
          inputProps={{
            ...params.inputProps,
            autoComplete: "disabled",
            readOnly: true,
          }}
        />
      )}
    />
  );
};

export interface CountryType {
  name?: string;
  id: string;
  imgUrl: string;
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
