import React, { FC, useEffect, useState, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { theme } from "../custom/customStyles";
import "./FormCreateMenu.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setFlagForm,
  setFlagFormEdit,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
  setActiveStep,
  setCloseAlert,
} from "../../../redux/actions/helperActions";
import {
  createMenu,
  editMenu,
  getMenus,
  setActiveMenu,
} from "../../../redux/actions/managementMenuActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { IMenuDB, IMenuItem, IMenuListCreate } from "../../../interfaces/menu";
import { IHelper } from "../../../redux/interfaces/helper";
import { MyDialog } from "../custom/MyDialog";
import { MyActions } from "../custom/MyActions";
import { IconSearch } from "../../../components/Custom/IconSearch";
import routes from "../../../routes";
import { Roles_Enum, State } from "../../../enums/enums";
import { getOperatorsService } from "../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../redux/actions/managementCompanyActions";
import { ICreatedUser } from "../../../interfaces/ads";
import { setUsersRedux } from "../../../redux/actions/managementUsersActions";
import { IUserDB } from "../../../redux/interfaces/users";
import { getUsers } from "../../../services/managementUsers";
import { regLettersNumbers } from "../../../utils/utils";
import { MyAlert } from "../custom/MyAlert";
import {
  getMenusDb,
  getMenusService,
} from "../../../services/managementMenuService";

const styles = {
  "& input.Mui-disabled ": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
  "& .MuiSelect-select.Mui-disabled": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const defaultModuleValues: IMenuItem = {
  name: "",
  url: "",
  description: "",
  state: 1,
};

const estado = [
  { label: "Activo", value: 1 },
  { label: "Inactivo", value: 2 },
];
interface Props {
  confirm?: boolean;
  menu?: boolean;
  module?: boolean;
  handleReset: () => void;
}

export const FormCreateMenu: FC<Props> = ({
  confirm = false,
  menu = false,
  module = false,
  handleReset,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const setFlagFormHelper: IHelper = {
    flagFormCreate: false,
  };

  const [isSubmit, setIsSubmit] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const { flagFormEdit, activeStep, openAlert, messageAlert, typeAlert } =
    useSelector((state: AppState) => state.helper);

  const [nameError, setNameError] = useState({ error: false, text: "" });
  const [urlError, setUrlError] = useState({ error: false, text: "" });

  const [nameModuleError, setNameModuleError] = useState({
    error: false,
    text: "",
  });
  const [urlModuleError, setUrlModuleError] = useState({
    error: false,
    text: "",
  });

  const [cancel, setCancel] = useState(false);
  const [message, setMessage] = useState("");
  const [resetForm, setResetForm] = useState(false);
  const [menuItemsIds, setMenuItemsIds] = useState<number[]>([]);
  const [showIconSearch, setShowIconSearch] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { activeMenu } = useSelector(
    (state: AppState) => state.managementMenus
  );
  const SUPPORTED_ICON_CLASS = [
    "fa-solid fa-",
    "fa-regular fa-",
    "fa-brands fa-",
  ];
  const defaultMenuValues: IMenuListCreate = {
    id: activeMenu?.id,
    name: activeMenu?.name ? activeMenu.name : "",
    url: activeMenu?.url ? activeMenu.url : "",
    icon: activeMenu?.icon ? activeMenu.icon : "",
    description: activeMenu?.description ? activeMenu.description : "",
    state: activeMenu?.state ? activeMenu.state : 1,
    menuItem: [],
    createdUser: activeMenu?.createdUser
      ? activeMenu.createdUser
      : { id: 0, nameComplete: "" },
    operator: activeMenu?.operator ? activeMenu.operator.id : 0,
  };

  let [formModuleValues, setFormModuleValues] = useState<any[]>(
    activeMenu ? activeMenu.menuItem : []
  );
  const { users } = useSelector((state: AppState) => state.managementUsers);

  let [formMenuValues, setFormMenuValues] = useState(defaultMenuValues);

  const { token, user } = useSelector((state: AppState) => state.auth);

  const [menus, setMenus] = useState<IMenuDB | null>(null);

  const [userSelected, setUserSelected] = useState<any | null>(
    activeMenu ? activeMenu.createdUser : null
  );

  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );

  const [errorUser, setErrorUser] = useState(false);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;

  useEffect(() => {
    if (formMenuValues.name.trim() !== activeMenu?.name.trim()) {
      const findName = menus?.data.find(
        (el) =>
          el.name.toLowerCase().trim() ===
          formMenuValues.name.toLowerCase().trim()
      );
      findName &&
        setNameError({
          error: true,
          text: t("alerts.nameDuplicate"),
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMenuValues.name, formMenuValues.operator]);

  useEffect(() => {
    const avaliableRoutes = getModulesFromMenu(formMenuValues.url);
    const findRoute = routes.find(
      (route) => route.layout + route.path === formMenuValues.url
    );
    const modulesFromMenu = formModuleValues.filter(
      (module) =>
        !!avaliableRoutes.find((route) =>
          route.views?.find((view) => view.path === module.url)
        )
    );
    const modulesValues = findRoute?.collapse ? [defaultModuleValues] : [];

    setFormModuleValues(
      modulesFromMenu.length > 0 ? modulesFromMenu : modulesValues
    );

    if (formMenuValues.url !== activeMenu?.url && formMenuValues.url !== "") {
      const findUrl = menus?.data.find((el) =>
        isSuper
          ? el.url === formMenuValues.url &&
            el.operator?.id === formMenuValues.operator
          : el.url === formMenuValues.url
      );

      setUrlError({
        error: !!findUrl,
        text: findUrl ? t("alerts.urlDuplicate") : "",
      });
    }
    if (formMenuValues.url !== "") {
      if (!findRoute?.collapse) {
        setFormModuleValues([]);
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorSelectedModules"));
        dispatch(setTypeAlert("warning"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMenuValues.url, menus]);

  const handleMenuInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.target.name === "name" &&
      setNameError({
        error: false,
        text: "",
      });

    event.target.name === "url" &&
      setUrlError({
        error: false,
        text: "",
      });

    event.target.name === "operator" && setUserSelected(null);

    setFormMenuValues({
      ...formMenuValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleModuleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormModuleValues(
      formModuleValues.map((module: any, i: number) =>
        i === index
          ? { ...module, [event.target.name]: event.target.value }
          : module
      )
    );
  };

  const handleAddModule = () => {
    if (module) {
      if (formMenuValues.url === "") {
        dispatch(setMessageAlert("alerts.firtsSelectMenuUrl"));
        dispatch(setTypeAlert("warning"));
        return dispatch(setOpenAlert());
      }
      const findRoute = routes.find(
        (route) => route.layout + route.path === formMenuValues.url
      );
      if (findRoute?.collapse) {
        setFormModuleValues((prevState: any) => [
          ...prevState,
          defaultModuleValues,
        ]);
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorSelectedModules"));
        dispatch(setTypeAlert("warning"));
      }
    }
  };

  const closeForm = () => {
    setMessage("dialog.sureCancel");
    setCancel(true);
    setOpenDialog(true);
  };

  const verifyIncludeIconClass = (value: string): boolean => {
    const stringSplit = value.split("-");
    const finalStr = stringSplit.slice(0, 2).join("-");
    return SUPPORTED_ICON_CLASS.includes(finalStr + "-");
  };
  const cleanValidations = () => {
    setUrlError({ error: false, text: "" });
    setNameError({ error: false, text: "" });
    setNameModuleError({ error: false, text: "" });
    setResetForm(false);
  };

  const saveMenu = async () => {
    setLoading(true);
    handleCloseDialog();
    if (resetForm && !cancel) {
      setFormMenuValues({
        id: activeMenu?.id,
        name: "",
        url: "",
        icon: "",
        description: "",
        state: 1,
        menuItem: [],
        createdUser: activeMenu?.createdUser,
      });
      setIsSubmit(false);

      setFormModuleValues([]);
      cleanValidations();
      setLoading(false);
      return null;
    }
    if (cancel) {
      handleReset();
      dispatch(setActiveMenu(null));
      dispatch(setFlagForm(setFlagFormHelper));
      dispatch(setFlagFormEdit({ flagFormEdit: false }));
    } else {
      setNameModuleError({ error: false, text: "" });

      setUrlModuleError({ error: false, text: "" });

      if (token) {
        formMenuValues.name = formMenuValues.name.trim();
        if (flagFormEdit && formMenuValues.id) {
          await dispatch(
            editMenu(formMenuValues, token, formMenuValues.id, menuItemsIds)
          );
        } else {
          if (isSuper) {
            formMenuValues.createdUser = {
              id: userSelected.id,
              nameComplete: userSelected.nameComplete,
            };
          }
          formMenuValues.createdUser = { id: 0, nameComplete: "" };
          //CONSULTAR SI EL MENU YA EXISTE POR SI SE ESTAN CREANDO REGISTROS EN PARALELO
          const rsMenuDb = await getMenusDb(token, {
            operatorId: formMenuValues.operator || user?.operator.id,
            url: formMenuValues.url,
          });
          if (rsMenuDb.data.length > 0) {
            dispatch(setMessageAlert("menuManagment.duplicatedMenu"));
            dispatch(setTypeAlert("error"));
            setLoading(false);
            return dispatch(setOpenAlert());
          }

          await dispatch(createMenu(formMenuValues, token));
        }
        setOpenDialog(false);
        dispatch(setFlagFormEdit({ flagFormEdit: false }));
        dispatch(setFlagForm({ flagFormCreate: false }));
        await dispatch(getMenus(token, 1));
      }
      setIsSubmit(false);
    }
    setLoading(false);
    handleReset();
    setCancel(false);
    setResetForm(false);
    setOpenDialog(false);
  };

  /**
   *
   * SOPORTA EVENTO CLICK PARA VALIDACIONES
   */
  const validationsEdit = () => {
    let noValid = false;
    //let iconFormatError = false;
    setIsSubmit(true);
    isSuper && !userSelected ? setErrorUser(true) : setErrorUser(false);
    //verificar que no hayan campos obligatorios vacios o con valores por default

    if (
      formMenuValues.name === "" ||
      formMenuValues.icon === "" ||
      formMenuValues.url === "" ||
      (user?.roles.name === Roles_Enum.SUPER_ADMIN && !userSelected)
    ) {
      noValid = true;
    }

    const detailMenu = getModulesFromMenu(formMenuValues.url);

    if (
      detailMenu.length > 0 &&
      detailMenu[0].views &&
      formModuleValues.length <= 0
    ) {
      noValid = true;
    }
    formModuleValues.forEach((value) => {
      if (value.name === "" || value.url === "") {
        noValid = true;
      }
    });

    if (noValid) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.completeRequiredFields"));
      dispatch(setTypeAlert("error"));
    } else {
      const nameModuleEdit = activeMenu?.menuItem.find((moduleActive) =>
        formModuleValues.find((module) => module.name === moduleActive.name)
      );

      // VALIDACIONES SI EXISTEN CAMPOS IGUALES NOMBRE, URL Y ORDEN EN MENU
      const findNameModule = menus?.data.find((menu) =>
        menu.menuItem.find((item) =>
          formModuleValues.find((module) => module.name === item.name)
        )
      );

      const urlModuleEdit = activeMenu?.menuItem.find((moduleActive) =>
        formModuleValues.find((module) => module.url === moduleActive.url)
      );

      const findUrlModule = menus?.data.find((menu) =>
        menu.menuItem.find((item) =>
          formModuleValues.find(
            (module) =>
              menu.url === formMenuValues.url && module.url === item.url
          )
        )
      );

      if (activeMenu) {
        //VALIDACIONES CUANDO ES EDIT
        if (findNameModule || nameError.error || urlError.error) {
          !nameModuleEdit &&
            findNameModule &&
            setNameModuleError({
              error: true,
              text: t("alerts.nameDuplicate"),
            });

          !urlModuleEdit &&
            findUrlModule &&
            setUrlModuleError({
              error: true,
              text: t("alerts.urlDuplicate"),
            });
        }
      }

      const areDuplicateValues =
        (!nameModuleEdit && findNameModule) ||
        (!urlModuleEdit && findUrlModule);

      if (areDuplicateValues) {
        dispatch(setMessageAlert("alerts.duplicateValues"));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      flagFormEdit
        ? setMessage("dialog.sureEdit")
        : setMessage("dialog.sureSaved");
      handleClickOpenDialog();
    }
  };
  /**
   * Soporta el evento click para abrir la ventana de confirmación
   */
  const handleClickOpenDialog = () => {
    formModuleValues = formModuleValues.map((module) => {
      return module;
    });
    setFormModuleValues(
      formModuleValues.map((module: IMenuItem) => {
        module.createdUser = user
          ? { id: user.id, nameComplete: user.nameComplete }
          : { id: 0, nameComplete: "" };
        return module;
      })
    );
    setFormMenuValues({
      ...formMenuValues,
      menuItem: formModuleValues ? formModuleValues : [],
    });
    setOpenDialog(true);
  };

  /**
   * Soporta el evento click para cerrar la ventana de confirmación
   */
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCancel(false);
  };

  const handleDeleteModule = (index: number, idItem: number) => {
    const menu = getModulesFromMenu(formMenuValues.url);
    const menuWithModules =
      //@ts-ignore
      menu && menu.length > 0 && menu[0].views?.length > 0;
    if (menuWithModules && formModuleValues.length - 1 > 0) {
      idItem && setMenuItemsIds((prevState: any) => [...prevState, idItem]);
      setFormModuleValues(
        formModuleValues.filter((module: any, i: number) => i !== index)
      );
    } else {
      dispatch(setMessageAlert("alerts.almostOneModule"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };

  const disableFieldModule = !!(
    confirm ||
    menu ||
    formMenuValues.name === "" ||
    formMenuValues.icon === ""
  );

  const handleCancel = () => {
    setMessage("dialog.sureGoBackMenu");
    setCancel(true);
    setOpenDialog(true);
  };

  const handleCleanForm = () => {
    setMessage("dialog.sureClean");
    setResetForm(true);
    setOpenDialog(true);
  };

  const handleContinue = () => {
    dispatch(setActiveStep(activeStep + 1));
  };

  const handlePrevious = () => {
    dispatch(setActiveStep(activeStep - 1));
  };

  const returnNewMenu = () => {
    module && dispatch(setActiveStep(0));
  };

  /**
   * FETCH OBTENER OPERADORES
   */

  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    token && fetchOperators(token);
    return () => {
      dispatch(setCompaniesRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Users effect
  useEffect(() => {
    cleanValidations();
    const fetchMenus = async () => {
      if (token) {
        const rs = await getMenusService(
          token,
          isSuper ? formMenuValues.operator : (user as any).operator.id
        );
        setMenus(rs);
      }
    };

    const fetchUsers = async (token: string) => {
      if (formMenuValues.operator && user) {
        const usersOperators = await getUsers(
          token,
          user.id,
          user.roles.name,
          1,
          null,
          formMenuValues.operator
        );
        let filterUsers = usersOperators.data.filter((item: any) => {
          return item.roles.name === "ADMIN_OPERATOR";
        });

        let objUsers: IUserDB = {
          data: filterUsers,
          last_page: 1,
          page: 1,
          total: filterUsers.length,
        };
        formMenuValues.operator && dispatch(setUsersRedux(objUsers));
      }
    };
    if (token) {
      fetchMenus();
      fetchUsers(token);
    }
    return () => {
      setMenus(null);
      dispatch(setUsersRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMenuValues.operator]);

  /**
   * Soporta el evento para capturar el usuario creador
   */
  const handleSelectUser = (value: ICreatedUser | null) => {
    value ? setErrorUser(false) : setErrorUser(true);
    value && setUserSelected(value);
  };
  const getModulesFromMenu = (url: string) => {
    return routes.filter((route) => {
      return route.layout !== "/auth" && route.layout + route.path === url;
    });
  };

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
    if (typeAlert === "success") {
      dispatch(setFlagForm({ flagFormCreate: false }));
      dispatch(setActiveMenu(null));
    }
  };
  return (
    <div className="container-fluid">
      <div className={confirm ? "filter" : ""}>
        <ThemeProvider theme={theme}>
          <Box>
            {/* MENU CREATION FORM*/}
            {(menu || module || confirm) && (
              <div
                className={menu || module ? "filter mb-4" : ""}
                style={{ cursor: module ? "pointer" : "default" }}
                onClick={returnNewMenu}
              >
                {confirm ? (
                  <>
                    <Grid item xs={12} marginBottom={3}>
                      <i className="fa-solid fa-plus fa-lg pr-3 iconUser" />
                      <span className="titleUser">
                        {t("menuManagment.confirmInformation")}
                      </span>
                    </Grid>
                    <Grid item xs={12} mb={2}>
                      <Divider
                        sx={{
                          "&.MuiDivider-root": {
                            "&::before": {
                              width: "20px",
                            },
                          },
                        }}
                        textAlign="left"
                      >
                        {t("menuManagment.menu")}
                      </Divider>
                    </Grid>
                  </>
                ) : (
                  <Grid
                    item
                    xs={12}
                    marginBottom={
                      module && formModuleValues.length > 2 ? -1 : 3
                    }
                  >
                    <i className="fa-solid fa-plus fa-lg pr-3 iconUser" />
                    {module && formMenuValues.name !== "" ? (
                      <span className="titleUser">
                        {t("menuManagment.menu")} {formMenuValues.name}
                      </span>
                    ) : (
                      <span className="titleUser">
                        {t("menuManagment.newMenu")}
                      </span>
                    )}
                  </Grid>
                )}
                {(menu || formModuleValues.length <= 2 || confirm) && (
                  <form>
                    <Grid container spacing={confirm ? 1 : 2}>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        {confirm && (
                          <label
                            className="form-text"
                            style={{ color: "black" }}
                          >
                            {t("form.name")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                        )}
                        <TextField
                          name="name"
                          variant="outlined"
                          size="small"
                          type="text"
                          InputLabelProps={{ shrink: false }}
                          label={
                            formMenuValues.name === "" && !confirm
                              ? `${t("form.name")}*`
                              : " "
                          }
                          placeholder={t("form.name")}
                          value={formMenuValues.name}
                          error={
                            (isSubmit && formMenuValues.name === "") ||
                            nameError.error
                          }
                          onChange={handleMenuInputChange}
                          fullWidth
                          disabled={confirm}
                          sx={styles}
                          autoComplete="off"
                          helperText={
                            isSubmit && formMenuValues.name === ""
                              ? t("form.requiredFile")
                              : nameError.text
                          }
                          inputProps={{
                            maxLength: 50,
                          }}
                          onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                            !regLettersNumbers(e.key) && e.preventDefault()
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        {confirm && (
                          <label
                            className="form-text"
                            style={{ color: "black" }}
                          >
                            Url
                          </label>
                        )}
                        <TextField
                          name="url"
                          size="small"
                          select
                          id="url-select"
                          label={!confirm ? `Url` : " "}
                          value={!formMenuValues.url ? "" : formMenuValues.url}
                          onChange={handleMenuInputChange}
                          fullWidth
                          disabled={confirm}
                          sx={styles}
                          error={
                            (isSubmit && formMenuValues.url === "") ||
                            urlError.error
                          }
                          helperText={
                            isSubmit && formMenuValues.url === ""
                              ? t("form.requiredFile")
                              : urlError.text
                          }
                        >
                          {routes
                            .filter((route) => route.layout !== "/auth")
                            .map((route) => (
                              <MenuItem
                                key={route.layout + route.path}
                                value={route.layout + route.path}
                              >
                                {route.layout + route.path}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        {confirm && (
                          <label
                            className="form-text"
                            style={{ color: "black" }}
                          >
                            {t("form.icon")}
                          </label>
                        )}
                        <TextField
                          name="icon"
                          variant="outlined"
                          size="small"
                          type="text"
                          InputLabelProps={{ shrink: false }}
                          label={
                            formMenuValues.icon === "" && !confirm
                              ? `${t("form.icon")}*`
                              : " "
                          }
                          placeholder={t("form.icon")}
                          error={
                            isSubmit &&
                            (formMenuValues.icon === "" ||
                              !verifyIncludeIconClass(formMenuValues.icon))
                          }
                          value={formMenuValues.icon}
                          onClick={() =>
                            menu && setShowIconSearch(!showIconSearch)
                          }
                          fullWidth
                          helperText={
                            isSubmit &&
                            !verifyIncludeIconClass(formMenuValues.icon)
                              ? "El formato no coincide. Por favor validar información"
                              : ""
                          }
                          inputProps={{
                            className: "icon-picker",
                            maxLength: 100,
                          }}
                          sx={styles}
                          autoComplete="off"
                          disabled={confirm}
                        />
                        {showIconSearch && menu && (
                          <IconSearch
                            onSelectIcon={(name) => {
                              handleMenuInputChange({
                                target: { name: "icon", value: name },
                              } as any);
                              setShowIconSearch(false);
                            }}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        {confirm && (
                          <label
                            className="form-text"
                            style={{ color: "black" }}
                          >
                            {t("form.description")}
                          </label>
                        )}
                        <TextField
                          name="description"
                          variant="outlined"
                          size="small"
                          type="text"
                          InputLabelProps={{ shrink: false }}
                          label={
                            formMenuValues.description === "" && !confirm
                              ? t("form.description")
                              : " "
                          }
                          placeholder={t("form.description")}
                          value={formMenuValues.description}
                          onChange={handleMenuInputChange}
                          fullWidth
                          disabled={confirm}
                          sx={styles}
                          autoComplete="off"
                          inputProps={{
                            maxLength: 250,
                          }}
                          onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                            !regLettersNumbers(e.key) && e.preventDefault()
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        {confirm && (
                          <label
                            className="form-text"
                            style={{ color: "black" }}
                          >
                            {t("form.state")}
                          </label>
                        )}
                        <TextField
                          name="state"
                          size="small"
                          select
                          id="demo-multiple-name"
                          InputLabelProps={{ shrink: true }}
                          label={
                            formMenuValues.state && !confirm
                              ? `${t("form.state")}*`
                              : " "
                          }
                          value={formMenuValues.state}
                          onChange={handleMenuInputChange}
                          fullWidth
                          disabled={confirm}
                          sx={styles}
                        >
                          {estado.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {
                        //MOSTRAR SELECT CON OPERADORES CUANDO SEA SUPER_ADMIN
                        isSuper && !activeMenu && (
                          <>
                            {companies && (
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                {confirm && (
                                  <label
                                    className="form-text"
                                    style={{ color: "black" }}
                                  >
                                    {t("companyManagement.operator")}
                                  </label>
                                )}
                                <FormControl
                                  sx={{ width: "100%" }}
                                  size="small"
                                  error={
                                    isSubmit &&
                                    user?.roles.name ===
                                      Roles_Enum.SUPER_ADMIN &&
                                    formMenuValues.operator === 0
                                  }
                                  disabled={!!activeMenu}
                                >
                                  <TextField
                                    name="operator"
                                    id="crear-menu-operador"
                                    size="small"
                                    select
                                    fullWidth
                                    onChange={handleMenuInputChange}
                                    InputLabelProps={{ shrink: false }}
                                    value={
                                      formMenuValues.operator === 0
                                        ? ""
                                        : formMenuValues.operator || ""
                                    }
                                    label={
                                      formMenuValues.operator === 0 && !confirm
                                        ? `${t("companyManagement.operator")}*`
                                        : " "
                                    }
                                    disabled={!!activeMenu || confirm}
                                    sx={styles}
                                    error={
                                      isSubmit &&
                                      user?.roles.name ===
                                        Roles_Enum.SUPER_ADMIN &&
                                      formMenuValues.operator === 0
                                    }
                                    SelectProps={{
                                      MenuProps,
                                    }}
                                  >
                                    {companies?.data?.map((company) => (
                                      <MenuItem
                                        key={company.id}
                                        value={company.id}
                                      >
                                        {company.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </FormControl>
                              </Grid>
                            )}
                            {users && (
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                {confirm && (
                                  <label
                                    className="form-text"
                                    style={{ color: "black" }}
                                  >
                                    {t("User Admin")}
                                  </label>
                                )}
                                <Autocomplete
                                  id="createdUser"
                                  options={users.data}
                                  disableClearable
                                  disabled={!!activeMenu || confirm}
                                  // @ts-ignore
                                  value={userSelected}
                                  getOptionLabel={(option) =>
                                    option.nameComplete
                                  }
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                  onChange={(event: any, value: any | null) =>
                                    handleSelectUser(value)
                                  }
                                  renderOption={(props, option) => {
                                    const { nameComplete } = option;
                                    return (
                                      <span
                                        {...props}
                                        style={{ color: "#000" }}
                                      >
                                        {nameComplete}
                                      </span>
                                    );
                                  }}
                                  renderInput={({ inputProps, ...rest }) => (
                                    <TextField
                                      {...rest}
                                      name="createdUser"
                                      InputLabelProps={{ shrink: false }}
                                      variant="outlined"
                                      inputProps={{
                                        ...inputProps,
                                        readOnly: true,
                                      }}
                                      size="small"
                                      label={
                                        !userSelected && !confirm
                                          ? t("User Admin") + "*"
                                          : " "
                                      }
                                      error={errorUser}
                                    />
                                  )}
                                />
                              </Grid>
                            )}
                          </>
                        )
                      }
                    </Grid>
                  </form>
                )}
              </div>
            )}

            {/* MODULE CREATION FORM*/}
            {(menu || module || confirm) && (
              <div className={menu ? "opacity" : ""}>
                <div className={menu || module ? "filter" : ""}>
                  {confirm ? (
                    <Grid item xs={12} mb={3} mt={3}>
                      <Divider
                        sx={{
                          "&.MuiDivider-root": {
                            "&::before": {
                              width: "20px",
                            },
                          },
                        }}
                        textAlign="left"
                      >
                        {t("menuManagment.modules")}
                      </Divider>
                    </Grid>
                  ) : (
                    <Grid item xs={12} marginBottom={3}>
                      <i className="fa-solid fa-plus fa-lg pr-3 iconUser" />
                      <span className="titleUser">
                        {t("menuManagment.newModule")}
                      </span>
                    </Grid>
                  )}
                  <div className="add-module-form pb-4">
                    {formModuleValues.map((module: any, index: number) => (
                      <form key={index}>
                        <Grid
                          container
                          spacing={confirm ? 1 : 2}
                          mt={index === 0 ? -2 : 2}
                        >
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            {confirm && (
                              <label
                                className="form-text"
                                style={{ color: "black" }}
                              >
                                {t("form.name")}{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                            )}
                            <TextField
                              name="name"
                              variant="outlined"
                              size="small"
                              type="text"
                              InputLabelProps={{ shrink: false }}
                              label={
                                module.name === "" && !confirm
                                  ? t("form.name")
                                  : " "
                              }
                              placeholder={t("form.name")}
                              value={module.name}
                              error={
                                (isSubmit && module.name === "") ||
                                nameModuleError.error
                              }
                              onChange={(e) =>
                                handleModuleInputChange(index, e)
                              }
                              fullWidth
                              helperText={
                                isSubmit && module.name === ""
                                  ? t("form.requiredFile")
                                  : nameError.text
                              }
                              disabled={disableFieldModule}
                              sx={styles}
                              autoComplete="off"
                              inputProps={{
                                maxLength: 50,
                              }}
                              onKeyPress={(
                                e: KeyboardEvent<HTMLInputElement>
                              ) =>
                                !regLettersNumbers(e.key) && e.preventDefault()
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            {confirm && (
                              <label
                                className="form-text"
                                style={{ color: "black" }}
                              >
                                Url
                              </label>
                            )}

                            <TextField
                              name="url"
                              size="small"
                              select
                              id="url-module-select"
                              label={
                                module.url === "" && !confirm ? "Url*" : " "
                              }
                              placeholder={"Url"}
                              value={
                                !!getModulesFromMenu(formMenuValues.url).find(
                                  (item) =>
                                    item.views?.find(
                                      (modul) => modul.path === module.url
                                    )
                                )
                                  ? module.url
                                  : ""
                              }
                              InputLabelProps={{ shrink: false }}
                              error={
                                (isSubmit && module.url === "") ||
                                urlModuleError.error
                              }
                              helperText={
                                isSubmit && module.url === ""
                                  ? t("form.requiredFile")
                                  : urlModuleError.text
                              }
                              onChange={(e) =>
                                handleModuleInputChange(index, e)
                              }
                              fullWidth
                              disabled={disableFieldModule}
                              sx={styles}
                              inputProps={{ maxLength: 50 }}
                            >
                              {getModulesFromMenu(formMenuValues.url).map(
                                (route) =>
                                  route.collapse &&
                                  route.views
                                    .filter((menuItem) => {
                                      const indexSelectedModule =
                                        formModuleValues.findIndex(
                                          (modul: any) =>
                                            modul.url === menuItem.path
                                        );

                                      if (indexSelectedModule >= 0) {
                                        return indexSelectedModule === index;
                                      }
                                      return true;
                                    })

                                    .map((subRoute) => (
                                      <MenuItem
                                        key={subRoute.path}
                                        value={subRoute.path}
                                      >
                                        {subRoute.path}
                                      </MenuItem>
                                    ))
                              )}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            {confirm && (
                              <label
                                className="form-text"
                                style={{ color: "black" }}
                              >
                                {t("form.description")}
                              </label>
                            )}
                            <TextField
                              name="description"
                              variant="outlined"
                              InputLabelProps={{ shrink: false }}
                              size="small"
                              type="text"
                              label={
                                module.description === "" && !confirm
                                  ? t("form.description")
                                  : " "
                              }
                              placeholder={t("form.description")}
                              value={module.description}
                              onChange={(e) =>
                                handleModuleInputChange(index, e)
                              }
                              fullWidth
                              disabled={disableFieldModule}
                              sx={styles}
                              autoComplete="off"
                              inputProps={{
                                maxLength: 250,
                              }}
                              onKeyPress={(
                                e: KeyboardEvent<HTMLInputElement>
                              ) =>
                                !regLettersNumbers(e.key) && e.preventDefault()
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            {confirm && (
                              <label
                                className="form-text"
                                style={{ color: "black" }}
                              >
                                {t("form.state")}
                              </label>
                            )}
                            <TextField
                              name="state"
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              select
                              id="demo-multiple-name"
                              label={
                                module.state && !confirm ? t("form.state") : " "
                              }
                              placeholder={t("form.state")}
                              value={module.state}
                              onChange={(e) =>
                                handleModuleInputChange(index, e)
                              }
                              fullWidth
                              disabled={disableFieldModule}
                              sx={styles}
                            >
                              {estado.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={8}
                            lg={8}
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <i
                              className={
                                menu || confirm
                                  ? "fa fa-trash iconDelete not-active"
                                  : "fa fa-trash iconDelete"
                              }
                              style={{
                                fontSize: 24,
                              }}
                              onClick={() =>
                                handleDeleteModule(index, module.id)
                              }
                            />
                          </Grid>
                        </Grid>
                      </form>
                    ))}
                  </div>

                  {!confirm && (
                    <Box
                      mt={2}
                      className="boxAddModule"
                      onClick={handleAddModule}
                    >
                      <Typography fontSize={12} color="black">
                        {t(
                          formModuleValues.length === 0
                            ? "menuManagment.addModule"
                            : "menuManagment.addAnotherModule"
                        )}
                      </Typography>
                    </Box>
                  )}
                </div>
              </div>
            )}

            {!confirm && (
              <MyActions
                handlePrevious={handlePrevious}
                handleCancel={handleCancel}
                handleAccept={handleContinue}
                handleClean={handleCleanForm}
                titleAccept={"button.next"}
                disabled={!!activeMenu}
              />
            )}

            {confirm && (
              <Grid item xs={12} mt={3} className="gridButton">
                <Button
                  style={{ border: "none", outline: "none" }}
                  variant="contained"
                  className="footer-btn primary-btn ripple"
                  onClick={() => dispatch(setActiveStep(1))}
                  disabled={loading}
                >
                  <i className="fa-solid fa-angle-left "></i>
                  {t("button.previous")}
                </Button>

                <Button
                  style={{ border: "none", outline: "none" }}
                  variant="contained"
                  className="footer-btn danger-btn ripple"
                  onClick={closeForm}
                  disabled={loading}
                >
                  <i className="fa-solid fa-x"></i>
                  {t("button.cancel")}
                </Button>
                <Button
                  style={{
                    border: "none",
                    outline: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  disabled={urlError.error || nameError.error || loading}
                  variant="contained"
                  className="footer-btn primary-btn ripple"
                  onClick={validationsEdit}
                >
                  {loading ? (
                    <CircularProgress size={"1.6rem"} color="inherit" />
                  ) : (
                    <>
                      {t("button.save")}
                      <i className="fa-solid fa-angle-right ml-2"></i>
                    </>
                  )}
                </Button>
              </Grid>
            )}
            <MyDialog
              open={openDialog}
              title={t("dialog.confirmationWindow")}
              message={t(message)}
              handleAccept={saveMenu}
              handleClose={handleCloseDialog}
              isLoading={loading}
            />
          </Box>
        </ThemeProvider>

        <MyAlert
          open={openAlert}
          message={messageAlert ? messageAlert : ""}
          typeAlert={typeAlert}
          handleClose={onCloseAlert}
        />
      </div>
    </div>
  );
};
