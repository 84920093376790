import { Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { TabsBets } from "../../components/bets/TabsBets";

export const Bets = () => {
  const { t } = useTranslation();
  const [currentOption, setCurrentOption] = useState<string>(
    t("bets.consultTicket")
  );

  return (
    <div className="content w-100">
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Row style={{ marginBottom: 2, marginLeft: 0 }}>
            <Col xs={12}>
              <Typography
                color="text.primary"
                style={{ fontSize: "16px" }}
              >{`${t("bets.title")} / ${currentOption}`}</Typography>
            </Col>
            <Col xs={12}>
              <TabsBets
                onChangeComponent={(option) => {
                  setCurrentOption(option);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
