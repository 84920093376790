import React, { FC } from "react";
import { Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

import { headersTransactionsSettings } from "../../../helpers/headersTables";
import { ITransaction } from "../../../interfaces/transactionSettings";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import {
  DetailTransactionalSettings,
  ResponsibleSetting,
} from "../../../enums/enums";

interface props {
  page: ITransaction[];
}

const TransactionSettingsPdf: FC<props> = ({ page }) => {
  const { t } = useTranslation();
  const columns = headersTransactionsSettings;

  const getDetail = (constan: Record<string, string>, index: string) => {
    const detail = constan[index];
    return detail ?t(`transactionalSettings.${detail}`, detail): ''
  };

  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, index) => (
          <View key={index} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>

      <View style={{ flex: 1 }}>
        {page.map((data) => {
          return (
            <View key={data.id} style={stylesPdf.row}>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.id}</Text>
              </View>

              <View
                style={[
                  stylesPdf.col,
                  stylesPdf.pCol,
                  {
                    backgroundColor:
                      (data.state as any) === t("state.realized")
                        ? "#c6efce"
                        : "#ffc7ce",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                ]}
              >
                <Text
                  style={[
                    stylesPdf.textContent,
                    {
                      color:
                        (data.state as any) === t("state.realized")
                          ? "#0d6a44"
                          : "#ad120b",
                    },
                  ]}
                >
                  {data.state}
                </Text>
              </View>

              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.player.email}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {data.movementType.name}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.wallet.name}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.type.name}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {getDetail(DetailTransactionalSettings, data.detail)}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {getDetail(ResponsibleSetting, data.responsible)}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.value}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.createAt}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.observations}</Text>
              </View>
            </View>
          );
        })}
      </View>
    </>
  );
};

export default TransactionSettingsPdf;
