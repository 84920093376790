import {
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";

import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import {
  createIrrigationCard,
  createLot,
  filterLots,
  getCardByLotId,
  getCardDealers,
  getCards,
  getIrrigatedCards,
  getIrrigationCardsHistory,
  getIrrigationCardsInventory,
  getLots,
  getTotalWallet,
  inactivateCards,
  updateCard,
  updateCardDeales,
  updateLot,
} from "../../services/cards";
import { arraySearch } from "./searchActions";
import {
  ICardDealerQuery,
  ICardsList,
  IDataIrrigationCard,
  ITotalWallet,
  IIrrigatedCardsResult,
  IQueryIrrigatedCards,
  IQueryIrrigationCards,
  IrrigationData,
} from "../../interfaces/cards";
import { CurrentPlaceCards, Roles_Enum, State } from "../../enums/enums";
import { buildFinalQueryParams } from "../../utils/utils";
import {
  CurrentPlace,
  CurrentPlaceInventoryCards,
} from "../../helpers/currentPlaceCard";
import { getUserById } from "../../services/managementUsers";

export const startCreateLot = (data: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    dispatch(setLoadingState({ loading: true }));
    try {
      if (user && token) {
        const response = await createLot(data, token);
        if (response && !response.error) {
          await dispatch(
            startFilterLots({
              page: 1,
            })
          );
          dispatch(setMessageAlert("cards.successCreateLot"));
          dispatch(setTypeAlert("success"));
          dispatch(setOpenAlert());
        } else {
          dispatch(setMessageAlert("cards.errorCreateLot"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const setActiveLot = (lot: number | null) => ({
  type: Types.setActiveLot,
  payload: lot,
});

export const startFilterLots = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (params.page) {
      params.perPage = rowsPerPage;
    }

    try {
      if (token) {
        const finalValues = buildFinalQueryParams(params);
        if (user && user.operator?.id) {
          finalValues.operator = user?.operator.id;
        }

        const response = user && (await filterLots(finalValues, token));

        if (response && !response.error) {
          dispatch(setLots(response));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetBonds"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const starGetLots = () => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    dispatch(setLoadingState({ loading: true }));
    try {
      if (!user || !token) {
        throw new Error("NOT AVAILABLE ACTION");
      }
      const idOperator = user.operator ? user.operator.id : undefined;
      const response = await getLots(idOperator, token, rowsPerPage);
      if (response) {
        dispatch(arraySearch(null));
        dispatch(setLots(response));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorSetCards"));
        dispatch(setTypeAlert("error"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const setLots = (lots: any | null) => ({
  type: Types.setLots,
  payload: lots,
});

export const starGetCardByLotId = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { user, token } = (getState() as AppState).auth;
    const { activeLot } = (getState() as AppState).managementCards;
    dispatch(setLoadingState({ loading: true }));
    if (params.page) {
      params.perPage = rowsPerPage;
    }
    try {
      if (!user || !token) {
        throw new Error("NOT AVAILABLE ACTION");
      }
      let finalValues: any = {};

      Object.entries(params).forEach(([key, value], index) => {
        if (
          //@ts-ignore
          value.length > 0 ||
          typeof value === "number" ||
          typeof value === "boolean"
        ) {
          //@ts-ignore
          finalValues[key] = `${value}`;
        }
      });
      const response = await getCardByLotId(
        activeLot as number,
        token,
        finalValues
      );
      if (response) {
        dispatch(setCards(response));
        dispatch(arraySearch(response));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorSetCards"));
        dispatch(setTypeAlert("error"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const setCards = (cards: ICardsList[] | null) => ({
  type: Types.setCards,
  payload: cards,
});

export const startGetCardDelaers = (params: Partial<ICardDealerQuery>) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;

    if (!token || !user) {
      throw new Error("invalid action");
    }

    try {
      const isJerRol = user && user.roles.name.toUpperCase() === Roles_Enum.JER;

      if (params.page) {
        params.perPage = rowsPerPage;
      }
      if (user.operator && !isJerRol) {
        params.operator = user.operator.id;
      }

      let finalValues: any = {};
      const exeptionsTypes = ["number", "boolean"];
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (exeptionsTypes.includes(typeof value) || value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });

      const response = await getCardDealers(finalValues, token);
      if (response.error) {
        dispatch(
          setMessageAlert(`managementCards.${response.error.errorServer}`)
        );
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      dispatch(setCardDealers(response));
    } catch (error) {}
  };
};

export const setCardDealers = (data: any) => ({
  type: Types.setCardDealers,
  payload: data,
});
export const setActiveCardDealer = (dealer: any) => ({
  type: Types.setActiveCardDealer,
  payload: dealer,
});

export const startUpdateLot = (id: number, lot: any, showMsg = true) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    if (!token) {
      throw new Error("Invalid action");
    }
    const response = await updateLot(id, lot, token);
    if (showMsg) {
      const messageAlert = response.error
        ? response.error.errorMessage
        : "updateSuccessful";
      const alertType = response.error ? "error" : "success";

      dispatch(setMessageAlert(`alerts.${messageAlert}`));
      dispatch(setTypeAlert(alertType));
      if (response.error) {
        return dispatch(setOpenAlert());
      }

      return dispatch(setOpenAlert());
    }
  };
};

export const startGetCards = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;

    if (!token || !user) {
      throw new Error("invalid action");
    }

    try {
      if (params.page) {
        params.perPage = rowsPerPage;
      }
      if (user.operator) {
        params.operator = user.operator.id;
      }
      let finalValues: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (typeof value === "number" || value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });
      const response = await getCards(finalValues, token);
      if (response.error) {
        dispatch(setMessageAlert(`cards.${response.error.errorServer}`));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }

      dispatch(arraySearch(response));
    } catch (error) {
      console.log(error);
    }
  };
};

export const startUpdateCard = (id: number, card: any, admin?: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { data } = (getState() as AppState).search;
    const { activeCardDealer } = (getState() as AppState).managementCards;

    if (!token || (!activeCardDealer && !admin)) {
      throw new Error("Invalid action");
    }
    const response = await updateCard(id, card, token);
    const messageAlert = response.error
      ? response.error.errorMessage
      : "successful";
    const alertType = response.error ? "error" : "success";

    dispatch(setMessageAlert(`alerts.${messageAlert}`));
    dispatch(setTypeAlert(alertType));
    if (response.error) {
      return dispatch(setOpenAlert());
    }
    if (admin) {
      const params: any = {
        page: data?.page,
      };
      await dispatch(
        starGetCardByLotId({
          ...params,
        })
      );
    } else {
      await dispatch(
        startGetCards({
          page: data?.page,
          dealer: activeCardDealer?.dealer.id,
          lotId: activeCardDealer?.lot.id,
        })
      );
    }

    return dispatch(setOpenAlert());
  };
};
export const startInactivateCards = (
  from: number,
  to: number,
  state: State,
  admin?: boolean,
  observation?: string
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { data } = (getState() as AppState).search;
    const { activeCardDealer } = (getState() as AppState).managementCards;
    if (!token || (!activeCardDealer && !admin)) {
      throw new Error("Invalid action");
    }
    const objUpdatedCards = {
      from,
      to,
      observation: observation || "",
      state,
    };
    const response = await inactivateCards(objUpdatedCards, token);
    const messageAlert = response.error
      ? response.error.errorMessage
      : "successful";
    const alertType = response.error ? "error" : "success";

    dispatch(setMessageAlert(`alerts.${messageAlert}`));
    dispatch(setTypeAlert(alertType));
    if (response.error) {
      return dispatch(setOpenAlert());
    }
    if (admin) {
      const params: any = {
        page: data?.page,
      };
      await dispatch(
        starGetCardByLotId({
          ...params,
        })
      );
    } else {
      await dispatch(
        startGetCards({
          page: data?.page,

          dealer: activeCardDealer?.dealer.id,
          lotId: activeCardDealer?.lot.id,
        })
      );
    }

    return dispatch(setOpenAlert());
  };
};

export const startGetTotalWallet = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;

    if (!token || !user) {
      throw new Error("invalid action");
    }

    try {
      if (params.page) {
        params.perPage = rowsPerPage;
      }

      const finalParams = buildFinalQueryParams(params);

      const response = await getTotalWallet(finalParams, token);
      if (response.error) {
        dispatch(setTotalWallet(null));
        dispatch(setMessageAlert(`alerts.${response.error.errorMessage}`));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }

      dispatch(setTotalWallet(response));
    } catch (error) {
      console.log(error);
    }
  };
};

export const setTotalWallet = (wallet: ITotalWallet | null) => ({
  type: Types.setTotalWallet,
  payload: wallet,
});

export const startUpdateCardDealers = (idLot: number, dealers: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    if (!token) {
      throw new Error("Invalid action");
    }
    const response = await updateCardDeales(idLot, dealers, token);

    const messageAlert = response.error
      ? response.error.errorMessage
      : "updateSuccessful";
    const alertType = response.error ? "error" : "success";

    dispatch(setMessageAlert(`alerts.${messageAlert}`));
    dispatch(setTypeAlert(alertType));
    if (response.error) {
      return dispatch(setOpenAlert());
    }

    return dispatch(setOpenAlert());
  };
};

export const startGetIrrigationCardsInventory = (
  params: Partial<IQueryIrrigationCards>
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;

    if (!token || !user) {
      throw new Error("invalid action");
    }

    try {
      if (params.page) {
        params.perPage = rowsPerPage;
      }
      if (user.operator) {
        params.operator = user.operator.id;
      }
      if (params.currentPlace === CurrentPlaceCards.ASSOCIATE) {
        const associateId =
          user.associatesUsers.length > 0
            ? user.associatesUsers[0].associate.id
            : user.id;
        params.userId = associateId;
      }
      if (params.currentPlace === CurrentPlaceCards.DEALER) {
        const userDetail = await getUserById(token, user.id);
        const {
          dealer: { id: dealerId },
        } = userDetail;

        params.userId = dealerId;
      }
      if (params.currentPlace === CurrentPlaceCards.PROTOMOR) {
        const userDetail = await getUserById(token, user.id);
        const { promotor } = userDetail;
        const promotorId = promotor?.id;
        params.userId = promotorId;
      }
      let finalValues: any = {};
      finalValues = buildFinalQueryParams(params);
      const response = await getIrrigationCardsInventory(finalValues, token);
      if (response.error) {
        dispatch(setMessageAlert(`cards.${response.error.errorServer}`));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      dispatch(setIrrigationCards(response));
      dispatch(arraySearch(response));
    } catch (error) {
      console.log(error);
    }
  };
};

export const setIrrigationCards = (
  irrigationCardsInventory: IrrigationData | null
) => ({
  type: Types.setIrrigationCardsInventory,
  payload: irrigationCardsInventory,
});

export const startGetIrrigationCardsHistory = (
  params: Partial<IQueryIrrigationCards>
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;

    if (!token || !user) {
      dispatch(setMessageAlert("Accion no valida"));
      dispatch(setTypeAlert("warning"));
      dispatch(setOpenAlert());
      return;
    }

    try {
      if (user.operator) {
        params.operator = user.operator.id;
      }
      if (params.currentPlace === CurrentPlaceCards.ASSOCIATE) {
        const associateId =
          user.associatesUsers.length > 0
            ? user.associatesUsers[0].associate.id
            : user.id;
        params.userId = associateId;
      }
      if (params.currentPlace === CurrentPlaceCards.DEALER) {
        const userDetail = await getUserById(token, user.id);
        const {
          dealer: { id: dealerId },
        } = userDetail;

        params.userId = dealerId;
      }
      if (params.currentPlace === CurrentPlaceCards.PROTOMOR) {
        const userDetail = await getUserById(token, user.id);
        const { promotor } = userDetail;
        const promotorId = promotor?.id;
        params.userId = promotorId;
      }
      let finalValues: any = {};
      finalValues = buildFinalQueryParams(params);
      const response = await getIrrigationCardsHistory(finalValues, token);
      if (response.error) {
        dispatch(setMessageAlert(`cards.${response.error.errorServer}`));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      dispatch(setIrrigationCardsHistory(response));
      dispatch(arraySearch(response));
    } catch (error) {
      console.log(error);
    }
  };
};

export const setIrrigationCardsHistory = (
  irrigationCardsHistory: IrrigationData | null
) => ({
  type: Types.setIrrigationCardsHistory,
  payload: irrigationCardsHistory,
});

export const startCreateIrrigationCards = (
  data: IDataIrrigationCard,
  to?: CurrentPlaceCards
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    dispatch(setLoadingState({ loading: true }));
    try {
      if (user && token) {
        const currentPlace = CurrentPlace(user.roles.name as Roles_Enum);
        data.payload.to = to ?? currentPlace;
        const response = await createIrrigationCard(data, token);
        if (response && !response.error) {
          const currentPlace = CurrentPlaceInventoryCards(
            user.roles.name as Roles_Enum
          );
          const params = {
            page: 1,
            currentPlace: currentPlace,
            userId: user.id,
          };
          dispatch(setIrrigatedDetail(null));
          dispatch(setActiveLot(null));
          await dispatch(startGetIrrigationCardsInventory(params));
          dispatch(setMessageAlert("irrigation.createdSuccess"));
          dispatch(setTypeAlert("success"));
          dispatch(setOpenAlert());
        } else {
          dispatch(setMessageAlert("irrigation.error"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const startGetCardsIrrigated = (
  params: Partial<IQueryIrrigatedCards>
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;

    if (!token || !user) {
      throw new Error("invalid action");
    }
    try {
      if (!params.pref) {
        const calculatePref = CurrentPlace(user.roles.name as Roles_Enum);

        params.pref = calculatePref;
      }
      let finalValues: any = buildFinalQueryParams(params);
      const response = await getIrrigatedCards(finalValues, token);
      if (response.error) {
        dispatch(setMessageAlert(`cards.${response.error.errorServer}`));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      dispatch(setIrrigatedDetail(response));
    } catch (error) {
      console.log(error);
    }
  };
};
export const setIrrigatedDetail = (detail: IIrrigatedCardsResult | null) => ({
  type: Types.setIrrigatedCards,
  payload: detail,
});
