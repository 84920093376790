import { IQueryIrrigationCards } from "../../interfaces/cards";
import { IGenericData } from "../../interfaces/params";
import {
  IPointsSalesFilter,
  IQueryAssociateReport,
} from "../../interfaces/reports";
import { IAuditQuery, getLimitAudit } from "../../services/audit";
import {
  ICashFlowQuery,
  getCashFlow,
  getCashFlowDeposit,
} from "../../services/cashFlow";
import {
  getBanks,
  getCurrencies,
  getPaymentMethods,
} from "../../services/helperService";
import { editOperatorService } from "../../services/managementCompanyService";
import {
  getAffiliationsReport,
  getAssociateReport,
  getBetsByAssociate,
  getPaymentsAwardsByAssociate,
  getResumeIrrigationCards,
} from "../../services/reports";
import { buildFinalQueryParams } from "../../utils/utils";
import {
  IHelper,
  IHelperAssociate,
  IHelperCleanFilter,
  IHelperConfig,
  IHelperEdit,
  IHelperFormModal,
  IHelperSelect,
  ILoadingState,
} from "../interfaces/helper";
import {
  ICities,
  ICountries,
  ICurrencies,
  IDocumentTypes,
  IGames,
  ILanguageType,
  IPaymentMethods,
  ISolutions,
  IStates,
} from "../interfaces/shared";
import { IUserData } from "../interfaces/users";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import {
  getCities,
  getCountries,
  getDocumentTypes,
  getGames,
  getLanguages,
  getPaymentMethodsByCountry,
  getSolutions,
  getStates,
} from "./../../services/helperService";
import { getAssociatesFilter } from "./associatesActions";
import {
  startFilterBets,
  startGetBets,
  startGetPlayerBets,
} from "./betsActions";
import { startFilterBonus } from "./bonusActions";
import { startGetConfigParams } from "./configParamsActions";
import { startFilterLegal, startGetLegal } from "./legalActions";
import { startFilterAds } from "./managementAdsActions";
import {
  getBrandsFilter,
  getOperatorsFilter,
} from "./managementCompanyActions";
import { getMenus, startFilteringMenus } from "./managementMenuActions";
import {
  getParamSettings,
  startGetPercentageWithdrawal,
} from "./managementParametersActions";
import {
  getRefills,
  startGetBonusRedeemedPlayer,
  startGetExclusions,
  startGetObservations,
} from "./managementPlayersActions";
import {
  startFilteringRoles,
  startGetRolesByPage,
} from "./managementRolesActions";
import { setGetUsers, startFilteringUsers } from "./managementUsersActions";
import { startFilterMessages, startGetMessages } from "./messagesActions";
import { startFilterPaymentOrders } from "./paymentOrdersActions";
import { getPoinstSaleFilter } from "./pointsSaleActions";
import { arraySearch } from "./searchActions";
import {
  startFilterIrrigations,
  startGetIrrigations,
  startGetTransactionSetting,
} from "./transactionsActions";
import { uiSetError, uiSetErrorMessage } from "./uiAction";

export const setFlagForm = (setFlagFormCreate: IHelper) => ({
  type: Types.setFlagFormCreate,
  payload: setFlagFormCreate,
});

export const setFlagFormAssociate = (
  setFlagFormCreateAssociate: IHelperAssociate
) => ({
  type: Types.setFlagFormCreateAssociate,
  payload: setFlagFormCreateAssociate,
});

export const setFlagFormEdit = (setFlagFormEdit: IHelperEdit) => ({
  type: Types.setFlagFormEdit,
  payload: setFlagFormEdit,
});

export const setFlagFormConfig = (setFlagFormConfig: IHelperConfig) => ({
  type: Types.setFlagFormConfig,
  payload: setFlagFormConfig,
});

export const setLoadingState = (loading: ILoadingState) => ({
  type: Types.setLoadingState,
  payload: loading,
});

export const setFlagFormModal = (setFlagFormModal: IHelperFormModal) => ({
  type: Types.setFlagFormModal,
  payload: setFlagFormModal,
});

export const setCountries = (token: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setLoadingState({ loading: true }));
      const response = await getCountries(token);
      if (response) {
        const data: ICountries[] = response;
        dispatch(setCountriesRedux(data));
      } else {
        dispatch(uiSetError(true));
        dispatch(uiSetErrorMessage("alerts.errorSetCountries"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const setCountriesRedux = (countries: ICountries[]) => ({
  type: Types.countries,
  payload: countries,
});

export const setStates = (token: string, id: number) => {
  return async (dispatch: Function) => {
    const response = await getStates(token, id);

    if (response) {
      const data: IStates[] = response;
      dispatch(setStatesRedux(data));
    } else {
      dispatch(uiSetError(true));
      dispatch(uiSetErrorMessage("alerts.errorSetStates"));
    }
  };
};

export const setStatesRedux = (states: IStates[]) => ({
  type: Types.states,
  payload: states,
});

export const setCities = (token: string, id: number) => {
  return async (dispatch: Function) => {
    const response = await getCities(token, id);
    if (response) {
      const data: ICities[] = response;
      dispatch(setCitiesRedux(data));
    } else {
      dispatch(uiSetError(true));
      dispatch(uiSetErrorMessage("alerts.errorSetStates"));
    }
  };
};

export const setCitiesRedux = (cities: ICities[]) => ({
  type: Types.cities,
  payload: cities,
});

export const setOpenAlert = () => ({ type: Types.openAlert });

export const setCloseAlert = () => ({ type: Types.closeAlert });

export const setMessageAlert = (message: string) => ({
  type: Types.setMessageAlert,
  payload: message,
});

export const setTypeAlert = (
  type: "success" | "warning" | "error" | "info"
) => ({
  type: Types.setTypeAlert,
  payload: type,
});

export const setActiveStep = (step: number) => ({
  type: Types.setActiveStep,
  payload: step,
});

export const setRowsPerPage = (rows: number) => ({
  type: Types.setRowsPerPage,
  payload: rows,
});

export const getDataPagination = (
  page: number,
  component:
    | "menu"
    | "users"
    | "roles"
    | "refills"
    | "managementCompany"
    | "configCompany"
    | "files"
    | "paymentOrders"
    | "parameterizations"
    | "managementAds"
    | "pointsOfSale"
    | "associates"
    | "credentials"
    | "credentialsPointSale"
    | "commissionalPointSale"
    | "bets"
    | "casino"
    | "playerActivity"
    | "observations"
    | "tickets"
    | "bettingHistory"
    | "managementBonds"
    | "exclusions"
    | "bonds"
    | "transactions"
    | "legal"
    | "messages"
    | "promotions"
    | "configParams"
    | "irrigation",
  param?:
    | "paramPaymentMethods"
    | "paramGames"
    | "paramSolutions"
    | "bonusTable"
    | "percentageWithdrawalTable"
    | "operators",
  dataFilter?: any
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    const { data } = (getState() as AppState).search;
    const { player } = (getState() as AppState).managementPlayers;
    const superAdmin = user && user.roles.name === "SUPER_ADMIN";
    let response = null;
    if (token && user) {
      const id = user.operator ? user.operator.id : undefined;
      switch (component) {
        case "users": {
          response = data
            ? await dispatch(startFilteringUsers(dataFilter, page))
            : await dispatch(setGetUsers(token, page));
          if (response) {
            dispatch(arraySearch(response));
          }
          break;
        }
        case "menu": {
          response = data
            ? await dispatch(startFilteringMenus(dataFilter, page))
            : await dispatch(getMenus(token, page));
          if (response) {
            dispatch(arraySearch(response));
          }
          break;
        }
        case "refills": {
          await dispatch(getRefills(token, page));
          break;
        }
        case "roles": {
          response = data
            ? await dispatch(startFilteringRoles(dataFilter, superAdmin, page))
            : await dispatch(startGetRolesByPage(token, page, id));
          if (response) {
            await dispatch(arraySearch(response));
          }
          break;
        }
        case "managementCompany": {
          response = data
            ? await dispatch(
                getOperatorsFilter({ ...dataFilter, page: page.toString() })
              )
            : await dispatch(getOperatorsFilter({ page: page.toString() }));
          if (response) {
            await dispatch(arraySearch(response));
          }
          break;
        }
        case "configCompany": {
          response = data
            ? await dispatch(
                getBrandsFilter({ ...dataFilter, page: page.toString() })
              )
            : await dispatch(getBrandsFilter({ page: page.toString() }));
          if (response) {
            await dispatch(arraySearch(response));
          }
          break;
        }
        case "parameterizations": {
          if (dataFilter.param && dataFilter.param === "percentage") {
            dispatch(
              asyncLoadingFunction(
                async () => await dispatch(startGetPercentageWithdrawal(page))
              )
            );
          } else {
            await dispatch(
              getParamSettings(
                dataFilter.param,
                page.toString(),
                dataFilter.isFilter
              )
            );
          }
          break;
        }
        case "associates": {
          response = data
            ? await dispatch(
                getAssociatesFilter({ ...dataFilter, page: page.toString() })
              )
            : await dispatch(getAssociatesFilter({ page: page.toString() }));
          if (response) {
            await dispatch(arraySearch(response));
          }
          break;
        }
        case "pointsOfSale": {
          response = data
            ? await dispatch(getPoinstSaleFilter(dataFilter, page))
            : await dispatch(getPoinstSaleFilter({ page: page.toString() }));
          if (response) {
            await dispatch(arraySearch(response));
          }
          break;
        }
        case "managementAds": {
          response = data
            ? await dispatch(
                startFilterAds({ ...dataFilter, page: page.toString() })
              )
            : await dispatch(startFilterAds({ page: page.toString() }));
          if (response) {
            await dispatch(arraySearch(response));
          }
          break;
        }
        case "managementBonds": {
          response = data
            ? await dispatch(
                startFilterBonus({ ...dataFilter, page: page.toString() })
              )
            : await dispatch(startFilterBonus({ page: page.toString() }));
          if (response) {
            await dispatch(arraySearch(response));
          }
          break;
        }
        case "legal": {
          response = data
            ? await dispatch(
                startFilterLegal({ ...dataFilter, page: page.toString() })
              )
            : await dispatch(startGetLegal(page));
          if (response) {
            await dispatch(arraySearch(response));
          }
          break;
        }
        case "messages": {
          response = data
            ? await dispatch(
                startFilterMessages({ ...dataFilter, page: page.toString() })
              )
            : await dispatch(startGetMessages(page));
          if (response) {
            await dispatch(arraySearch(response));
          }
          break;
        }
        case "transactions": {
          response = await dispatch(
            startGetTransactionSetting({ page: page, isSetting: true })
          );
          break;
        }
        case "configParams": {
          response = await dispatch(
            startGetConfigParams({
              ...dataFilter,
              page: page,
            })
          );
          break;
        }

        case "irrigation": {
          response = data
            ? await dispatch(
                startFilterIrrigations({ ...dataFilter, page: page.toString() })
              )
            : await dispatch(startGetIrrigations(page));
          if (response) {
            await dispatch(arraySearch(response));
          }
          break;
        }

        case "bettingHistory": {
          response = data
            ? await dispatch(
                asyncLoadingFunction(
                  async () =>
                    await dispatch(
                      startFilterBets({ ...dataFilter, page: page.toString() })
                    )
                )
              )
            : await dispatch(
                asyncLoadingFunction(
                  async () => await dispatch(startGetBets(page))
                )
              );
          if (response) {
            await dispatch(arraySearch(response));
          }
          break;
        }

        case "paymentOrders": {
          response =
            data &&
            (await dispatch(
              startFilterPaymentOrders({ ...dataFilter, page: page.toString() })
            ));

          if (response) {
            await dispatch(arraySearch(response));
          }
          break;
        }

        case "exclusions": {
          response = await dispatch(
            asyncLoadingFunction(
              async () =>
                await dispatch(
                  startGetExclusions({
                    ...dataFilter,
                    page: page,
                    perPage: rowsPerPage,
                  })
                )
            )
          );
          break;
        }

        case "bets": {
          response = await dispatch(
            asyncLoadingFunction(
              async () =>
                await dispatch(
                  startGetPlayerBets({
                    ...dataFilter,
                    page: page,
                    perPage: rowsPerPage,
                  })
                )
            )
          );
          break;
        }

        case "observations": {
          const paramsObservations = {
            ...dataFilter,
            page: page,
            perPage: rowsPerPage,
          };
          if (player && player.data) {
            paramsObservations.playerId = player.data[0].id?.toString();
          }
          response = await dispatch(
            asyncLoadingFunction(
              async () =>
                await dispatch(startGetObservations(paramsObservations))
            )
          );
          break;
        }

        case "bonds": {
          const paramsBonds = {
            ...dataFilter,
            page: page,
            perPage: rowsPerPage,
          };
          if (player && player.data) {
            paramsBonds.playerId = player.data[0].id?.toString();
          }
          response = await dispatch(
            asyncLoadingFunction(
              async () =>
                await dispatch(startGetBonusRedeemedPlayer(paramsBonds))
            )
          );
          break;
        }

        default:
          break;
      }
    }
  };
};

export const setCurrencies = (token: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setLoadingState({ loading: true }));
      const response = await getCurrencies(token);
      if (response) {
        const data: ICurrencies[] = response;
        dispatch(setCurrenciesRedux(data));
      } else {
        dispatch(uiSetError(true));
        dispatch(uiSetErrorMessage("alerts.errorSetCurrencies"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

const setCurrenciesRedux = (currencies: ICurrencies[]) => ({
  type: Types.setCurrencies,
  payload: currencies,
});

export const setLanguages = (token: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setLoadingState({ loading: true }));
      const response = await getLanguages(token);
      if (response) {
        const data: ILanguageType[] = response;
        dispatch(setLanguagesRedux(data));
      } else {
        dispatch(uiSetError(true));
        dispatch(uiSetErrorMessage("alerts.errorSetLanguages"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

const setLanguagesRedux = (languages: ILanguageType[]) => ({
  type: Types.setLanguages,
  payload: languages,
});

export const setPaymentMethods = (token: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setLoadingState({ loading: true }));
      const response = await getPaymentMethods(token);

      if (response) {
        const data: IPaymentMethods[] = response;

        dispatch(setPaymentMethodsRedux(data));
      } else {
        dispatch(uiSetError(true));
        dispatch(uiSetErrorMessage("alerts.errorSetPaymentMethods"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const setPaymentMethodsRedux = (payments: IPaymentMethods[]) => ({
  type: Types.setPaymentMethods,
  payload: payments,
});

export const setPaymentMethodsByCountry = (
  token: string,
  countryId: number,
  operator: number
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setLoadingState({ loading: true }));

      const response = await getPaymentMethodsByCountry(
        token,
        countryId,
        operator
      );

      const { data } = response;
      if (data && data.length > 0) {
        const dataRedux: IPaymentMethods[] = data
          .filter((method: any) => method.methods.type !== "withdrawal")
          .map((method: any) => {
            const { methods } = method;
            return {
              ...methods,
              idParamMethod: method.id,
              active: method.active,
              state: method.state,
              isNativeGlobal: method.isNativeGlobal ? true : false,
            };
          });

        dispatch(setPaymentMethodsByCountryRedux(dataRedux));
      } else {
        dispatch(setPaymentMethodsByCountryRedux([]));
        dispatch(uiSetError(true));
        dispatch(uiSetErrorMessage("alerts.errorSetPaymentMethods"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

const setPaymentMethodsByCountryRedux = (payments: IPaymentMethods[]) => ({
  type: Types.setPaymentMethodsByCountry,
  payload: payments,
});

export const setGames = (token: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setLoadingState({ loading: true }));
      const response = await getGames(token);
      if (response) {
        const data: IGames[] = response;
        dispatch(setGamesRedux(data));
      } else {
        dispatch(uiSetError(true));
        dispatch(uiSetErrorMessage("alerts.errorSetGames"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

const setGamesRedux = (games: IGames[]) => ({
  type: Types.setGames,
  payload: games,
});

export const setSolutions = (token: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setLoadingState({ loading: true }));
      const response = await getSolutions(token);
      if (response) {
        const data: ISolutions[] = response;
        dispatch(setSolutionsRedux(data));
      } else {
        dispatch(uiSetError(true));
        dispatch(uiSetErrorMessage("alerts.errorSetSolutions"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

const setSolutionsRedux = (solutions: ISolutions[]) => ({
  type: Types.setSolutions,
  payload: solutions,
});

export const setComponent = (component: "associates" | null) => ({
  type: Types.setComponent,
  payload: component,
});

export const setDocumentTypes = (token: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setLoadingState({ loading: true }));
      const response = await getDocumentTypes(token);
      if (response) {
        const data: IDocumentTypes[] = response;
        dispatch(setDocumentTypesRedux(data));
      } else {
        dispatch(uiSetError(true));
        dispatch(uiSetErrorMessage("alerts.errorSetDocumentTypes"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const setDocumentTypesRedux = (types: IDocumentTypes[]) => ({
  type: Types.setDocumentTypes,
  payload: types,
});

export const setBanks = (token: string, countryId: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setLoadingState({ loading: true }));
      const response = await getBanks(token, countryId);

      if (response) {
        const data: IGenericData[] = response;
        dispatch(setBanksRedux(data));
      } else {
        dispatch(uiSetError(true));
        dispatch(uiSetErrorMessage("alerts.errorSetBanks"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const setBanksRedux = (banks: IGenericData[]) => ({
  type: Types.setBanks,
  payload: banks,
});

export const setSellersRedux = (sellers: IUserData[]) => ({
  type: Types.setSellers,
  payload: sellers,
});

export const setFlagSelect = (setFlagSelect: IHelperSelect) => ({
  type: Types.setFlagSelect,
  payload: setFlagSelect,
});

export const setFlagCleanFilter = (setFlagCleanFilter: IHelperCleanFilter) => ({
  type: Types.setFlagCleanFilter,
  payload: setFlagCleanFilter,
});

export const setOpenTimeout = (open: boolean) => ({
  type: Types.setOpenTimeout,
  payload: open,
});

export const asyncLoadingFunction = (func: Function) => {
  return async (dispatch: Function) => {
    dispatch(setLoadingState({ loading: true }));
    await func();
    dispatch(setLoadingState({ loading: false }));
  };
};

export const setChangeTab = (change: boolean) => ({
  type: Types.setChangeTab,
  payload: change,
});

export const startUpdateLoginAttempts = (id: number, attempts: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;

    try {
      if (token) {
        const updateObj: any = {
          loginAttempts: attempts,
        };
        const response = await editOperatorService(updateObj, token, id);
        if (response && response.error) {
          dispatch(setMessageAlert(response.error.errorMessage));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const startGetLimitsAudit = (params: Partial<IAuditQuery>) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;

    try {
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      if (params.page) {
        params.perPage = rowsPerPage;
      }
      if (user?.operator?.id) {
        params.operatorId = user.operator.id;
      }

      const finalValues = buildFinalQueryParams(params);

      const response = await getLimitAudit(token, finalValues);
      if (!response.error) {
        const { rs } = response;
        if (rs.data?.length === 0) {
          dispatch(arraySearch(null));
          dispatch(setMessageAlert("alerts.noData"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        } else {
          dispatch(arraySearch(rs));
        }
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};
export const startGetCashFlow = (params: Partial<ICashFlowQuery>) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;

    try {
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      if (params.page) {
        params.perPage = rowsPerPage;
      }
      if (user?.operator?.id) {
        params.operator = user.operator.id;
      }

      const finalValues = buildFinalQueryParams(params);

      const rs = await getCashFlow(token, finalValues);

      if (!rs.error) {
        if (rs.data?.length === 0) {
          dispatch(arraySearch(null));
          dispatch(setMessageAlert("alerts.noData"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        } else {
          dispatch(arraySearch(rs));
        }
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};
export const startGetCashFlowDeposit = (params: Partial<ICashFlowQuery>) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;

    try {
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      if (params.page) {
        params.perPage = rowsPerPage;
      }
      if (user?.operator?.id) {
        params.operator = user.operator.id;
      }

      const finalValues = buildFinalQueryParams(params);

      const rs = await getCashFlowDeposit(token, finalValues);

      if (!rs.error) {
        if (rs.data?.length === 0) {
          showNoDataAlert(dispatch);
        } else {
          dispatch(arraySearch(rs));
        }
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};
const showNoDataAlert = (dispatch: any) => {
  dispatch(arraySearch(null));
  dispatch(setMessageAlert("alerts.noData"));
  dispatch(setTypeAlert("error"));
  dispatch(setOpenAlert());
};

export const startGetAssociateReport = (
  params: Partial<IQueryAssociateReport>,
  pointsSales: IPointsSalesFilter[]
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;

    try {
      if (!token) {
        throw new Error("INVALID ACTION");
      }

      const finalValues = buildFinalQueryParams(params);

      const rs = await getAssociateReport(token, finalValues, pointsSales);

      if (!rs.error) {
        if (rs.data?.length === 0) {
          dispatch(arraySearch(null));
          dispatch(setMessageAlert("alerts.noData"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        } else {
          dispatch(arraySearch(rs));
        }
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startGetAssociateBetsReport = (
  params: Partial<IQueryAssociateReport>,
  pointsSales: IPointsSalesFilter[]
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    try {
      if (!token) {
        throw new Error("INVALID ACTION");
      }

      const finalValues = buildFinalQueryParams(params);
      finalValues.perPage = rowsPerPage;
      const rs = await getBetsByAssociate(token, finalValues, pointsSales);
      if (!rs.error) {
        if (rs.data?.length === 0) {
          dispatch(arraySearch(null));
          dispatch(setMessageAlert("alerts.noData"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        } else {
          dispatch(arraySearch(rs));
        }
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startGetAssociatePayAwardsReport = (
  params: Partial<IQueryAssociateReport>,
  pointsSales: IPointsSalesFilter[]
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;

    try {
      if (!token) {
        throw new Error("INVALID ACTION");
      }

      const finalValues = buildFinalQueryParams(params);
      finalValues.perPage = rowsPerPage;
      const rs = await getPaymentsAwardsByAssociate(
        token,
        finalValues,
        pointsSales
      );

      if (!rs.error) {
        if (rs.data?.length === 0) {
          dispatch(arraySearch(null));
          dispatch(setMessageAlert("alerts.noData"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        } else {
          dispatch(arraySearch(rs));
        }
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startGetIrrigationsCardsReport = (
  params: Partial<IQueryIrrigationCards>
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const state = getState() as AppState;
      const { token } = state.auth;
      const { rowsPerPage } = state.helper;

      if (!token) {
        throw new Error("INVALID ACTION");
      }

      const finalValues = buildFinalQueryParams(params);
      finalValues.perPage = rowsPerPage;
      const rs = await getResumeIrrigationCards(token, {
        ...finalValues,
      });

      if (rs.data?.length === 0) {
        dispatch(arraySearch(null));
        dispatch(setMessageAlert("alerts.noData"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      } else {
        dispatch(arraySearch(rs));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startGetAffiliatesReport = (
  params: Partial<IQueryAssociateReport>,
  pointsSales: IPointsSalesFilter[]
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    try {
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      const finalValues = buildFinalQueryParams(params);
      finalValues.perPage = rowsPerPage;
      const rs = await getAffiliationsReport(token, finalValues, pointsSales);
      if (!rs.error) {
        if (rs.data?.length === 0) {
          dispatch(arraySearch(null));
          dispatch(setMessageAlert("alerts.noData"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        } else {
          dispatch(arraySearch(rs));
        }
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};
