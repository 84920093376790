import { AppState } from "../../../redux/reducers/rootReducer";
import { useSelector } from "react-redux";
import { headersActivityPlayer } from "../../../helpers/headersTables";
import { buildRedName, getCopyArrayOrObject } from "../../../utils/utils";
import { IPlayerActivityData } from "../../../interfaces/playerActivity";
import moment from "moment";
import { ProvidersString, TransactionTypesDB } from "../../../enums/enums";
import { useTranslation } from "react-i18next";

export const ActivityPlayerDataTable = (totalData: IPlayerActivityData[]) => {
  const { t } = useTranslation();
  const { data } = useSelector((state: AppState) => state.search);
  //   COLUMNS HEADERS STYLES
  const headersCvsStyle = headersActivityPlayer.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  // Obtener datos por vista
  const dataActivityPlayer =
    data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalActivityPlayer = totalData
    ? getCopyArrayOrObject(totalData)
    : [];
  //   DATA TOTAL
  const multiTotalDataPlayerActivity = [
    {
      columns: headersCvsStyle,
      data: dataTotalActivityPlayer.map((data: any) => {
        const method =
          data.meta?.debitParty?.typeClass &&
          data.transactionType?.id === TransactionTypesDB.RECHARGE
            ? `- ${t(`paymentMethods.${data.meta?.debitParty?.typeClass}`)}`
            : "";
        let array;
        array = [
          {
            value: moment(data.createAt).format("YYYY-MM-DD HH:mm:ss"),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: data.transactionType.name + " " + method,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.transactionStatus.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: "$" + data.amount,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: "$" + data.newBalance,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: buildRedName(data),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value:
              data.transactionId &&
              data.provider.name === ProvidersString.ALTENAR
                ? data.transactionId
                : data.meta?.transactionReference || "",
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];
  const multiDataPlayerActivity = [
    {
      columns: headersCvsStyle,
      data: dataActivityPlayer.map((data: any) => {
        const method =
          data.meta?.debitParty?.typeClass &&
          data.transactionType?.id === TransactionTypesDB.RECHARGE
            ? `- ${t(`paymentMethods.${data.meta?.debitParty?.typeClass}`)}`
            : "";
        let array;
        array = [
          {
            value: moment(data.createAt).format("YYYY-MM-DD HH:mm:ss"),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: data.transactionType.name + " " + method,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.transactionStatus.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: "$" + data.amount,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: "$" + data.newBalance,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: buildRedName(data),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value:
              data.transactionId &&
              data.provider.name === ProvidersString.ALTENAR
                ? data.transactionId
                : data.meta?.transactionReference || "",
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];
  const finalData = {
    dataPerView: dataActivityPlayer,
    dataTotal: dataTotalActivityPlayer,
    headersCSV: headersActivityPlayer,
    multiDataSet: multiDataPlayerActivity,
    multiTotalDataSet: multiTotalDataPlayerActivity,
  };
  return finalData;
};
