import { View, Text, Font } from "@react-pdf/renderer";
import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import { IBetData } from "../../../interfaces/bets";
import { betStatuses } from "../../../helpers/betStatus";

Font.registerHyphenationCallback((word) => {
  const middle = Math.floor(word.length / 2);
  const parts =
    word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];

  return parts;
});

interface Props {
  page: any;
}

export const BetHistoryPdfDocument: FC<Props> = ({ page }) => {
  const { t } = useTranslation();
  const columnsBetHistory = [
    t("table.betId").toUpperCase(),
    t("bets.ticketNumber").toUpperCase(),
    t("bets.dateBet").toUpperCase(),
    t("bets.hourBet").toUpperCase(),
    t("bets.winningAmount").toUpperCase(),
    t("bets.bettingStatus").toUpperCase(),
  ];
  const columns = columnsBetHistory;

  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, index) => (
          <View key={column} style={[stylesPdf.pCol, stylesPdf.col]}>
            <Text style={stylesPdf.text}>{column}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        {page.map((page: IBetData) => {
          let feeds = 1;
          if (page.events && page.events.length > 0) {
            feeds = page.events[0]?.markets[0] ? page.events[0].markets[0].odds : 1;
          }
          return (
            <View key={page.id} style={stylesPdf.row}>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>{page.id}</Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={[stylesPdf.textContent]}>{page.reference}</Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {moment(page.createAt).format("DD/MM/YYYY")}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {moment(page.createAt).format("HH:mm:ss A")}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {"$ "}
                  {page.status === "S"
                    ? page.transactions && page.transactions.length > 0
                      ? parseFloat(page.transactions[0].amount).toFixed(2)
                      : (parseFloat(page.amount) * feeds).toFixed(2)
                    : "0"}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={[stylesPdf.textContent]}>
                  {betStatuses
                    .find((status) => status.value === page.status)
                    ?.description.toUpperCase()}{" "}
                  {page.paid && ` - ${t("betState.paid").toUpperCase()}`}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </>
  );
};
