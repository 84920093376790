import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import { Roles_Enum } from "../../enums/enums";
import { IColumnsTable, Icons } from "../../interfaces/components";
import { IMenuList } from "../../interfaces/menu";
import { getDataPagination } from "../../redux/actions/helperActions";
import { valueSearched } from "../../redux/actions/searchActions";
import { IUserData } from "../../redux/interfaces/users";
import { AppState } from "../../redux/reducers/rootReducer";
import { MyDialogExportFile } from "../../views/components/custom/MyDialogExportFile";
import { ExportFilesList } from "../Custom/ExportFilesList";
import { Pagination } from "../Pagination/Pagination";
import { StaticPagination } from "../Pagination/StaticPagination";
import { BodyTable } from "./BodyTable";
import "./table.scss";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F4F4F4",
    color: "#000",
    fontSize: 12,
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 55,
  },
}));

interface Props {
  columns: IColumnsTable[];
  data: IUserData[] | IMenuList[] | any[];
  pages?: number;
  actualpage?: number;
  titleButton?: string;
  subMenu?: boolean;
  icons?: Icons;
  component?:
  | "menu"
  | "users"
  | "roles"
  | "refills"
  | "managementCompany"
  | "configCompany"
  | "files"
  | "paymentOrders"
  | "parameterizations"
  | "managementAds"
  | "pointsOfSale"
  | "associates"
  | "credentials"
  | "credentialsPointSale"
  | "commissionalPointSale"
  | "bets"
  | "casino"
  | "playerActivity"
  | "observations"
  | "tickets"
  | "bettingHistory"
  | "managementBonds"
  | "exclusions"
  | "bonds"
  | "transactions"
  | "legal"
  | "messages"
  | "promotions"
  | "configParams"
  | "irrigation"
  | "payHistorySellers";
  totalData: any;
  credentialsTitle?: string;
  param?:
  | "paramPaymentMethods"
  | "paramGames"
  | "paramSolutions"
  | "bonusTable"
  | "percentageWithdrawalTable";
  isSortingTable: boolean;
  dataFilter: any;
  agileCheck?: boolean;
}

export const TableComponent: FC<Props> = ({
  columns,
  data,
  pages,
  subMenu,
  icons,
  component = "menu",
  totalData,
  param,
  credentialsTitle,
  isSortingTable,
  dataFilter,
  agileCheck,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [page] = React.useState(0);
  const [orderDirection, setOrderDirection] = useState<any>("desc");
  const [filteredValues, setfilteredValues] = useState<any[]>([]);
  const [, setRowData] = useState(data);
  const { value } = useSelector((state: AppState) => state.search);
  const { loading, rowsPerPage } = useSelector(
    (state: AppState) => state.helper
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [columnSort, setColumnSort] = useState("");
  const [count, setCount] = useState(0);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { user } = useSelector((state: AppState) => state.auth);
  const { pathname } = useLocation();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const isAdmin = user?.roles.name === Roles_Enum.ADMIN_OPERATOR;
  const exportPermissions = isSuper || isAdmin;
  const menuPermissions = routes.find(
    (route) =>
      !!route.menuItem.find((module) => module.url === pathname) ||
      route.url === pathname
  );
  let actions: Icons = { ...icons };
  if (menuPermissions) {
    const seeAction = actions.see !== undefined ? actions.see : true;
    const editAction = actions.edit !== undefined ? actions.edit : true;
    const deleteAction =
      param === "bonusTable"
        ? false
        : actions.delete !== undefined
          ? actions.delete
          : true;
    const seeMoreAction =
      actions.seeMore !== undefined ? actions.seeMore : true;
    const activateAction =
      actions.activate !== undefined ? actions.activate : false;
    const payAction = actions.pay !== undefined ? actions.pay : false;
    const sendAction = actions.send !== undefined ? actions.send : true;
    if (menuPermissions.menuItem.length > 0) {
      const modulePermissions = menuPermissions.menuItem.find(
        (module) => module.url === pathname
      );

      actions.see = isSuper
        ? seeAction
        : modulePermissions && modulePermissions.permissions?.readed === 1
          ? seeAction
          : false;
      actions.edit = isSuper
        ? editAction
        : modulePermissions && modulePermissions.permissions?.updated === 1
          ? editAction
          : false;
      actions.delete = isSuper
        ? deleteAction
        : actions.delete !== undefined
          ? actions.delete
          : modulePermissions && modulePermissions.permissions?.deleted === 1
            ? deleteAction
            : false;
      actions.seeMore = isSuper
        ? seeMoreAction
        : modulePermissions && modulePermissions?.permissions?.readed === 1
          ? seeMoreAction
          : false;
      actions.activate = isSuper
        ? activateAction
        : modulePermissions && modulePermissions.permissions?.updated === 1
          ? activateAction
          : false;
      actions.pay = isSuper
        ? payAction
        : modulePermissions && modulePermissions.permissions?.updated === 1
          ? payAction
          : false;
      actions.send = isSuper
        ? sendAction
        : modulePermissions && modulePermissions.permissions?.updated === 1
          ? sendAction
          : false;
    } else {
      const permissions = menuPermissions.permissionsMenu;

      actions.see = isSuper
        ? seeAction
        : permissions?.readed === 1
          ? actions.see !== undefined
            ? actions.see
            : seeAction
          : false;
      actions.edit = isSuper
        ? editAction
        : permissions?.updated === 1
          ? actions.edit !== undefined
            ? actions.edit
            : editAction
          : false;
      actions.delete = isSuper
        ? deleteAction
        : permissions?.deleted === 1
          ? actions.delete !== undefined
            ? actions.delete
            : deleteAction
          : false;
      actions.seeMore = isSuper
        ? seeMoreAction
        : permissions?.readed === 1
          ? actions.seeMore !== undefined
            ? actions.seeMore
            : seeMoreAction
          : false;
      actions.activate = isSuper
        ? activateAction
        : permissions?.updated === 1
          ? actions.activate !== undefined
            ? actions.activate
            : activateAction
          : false;
      actions.pay = isSuper
        ? payAction
        : permissions?.updated === 1
          ? actions.pay !== undefined
            ? actions.pay
            : payAction
          : false;
      actions.send = isSuper
        ? sendAction
        : permissions?.updated === 1
          ? actions.send !== undefined
            ? actions.send
            : sendAction
          : false;
    }
  }

  if (isSortingTable === undefined) {
    isSortingTable =
      component !== "parameterizations" &&
      component !== "associates" &&
      component !== "credentials" &&
      component !== "tickets" &&
      component !== "bettingHistory" &&
      component !== "credentialsPointSale" &&
      component !== "paymentOrders" &&
      component !== "commissionalPointSale" &&
      component !== "pointsOfSale" &&
      component !== "observations" &&
      component !== "playerActivity";
  }

  const sortArray = (arr: any, orderBy: any) => {
    switch (orderBy) {
      case "desc":
        return arr.sort((a: any, b: any) => {
          if (a.id < b.id) return 1;

          if (b.id < a.id) return -1;

          return 0;
        });
      case "asc":
      default:
        return arr.sort((a: any, b: any) => {
          if (a.id > b.id) return 1;

          if (b.id > a.id) return -1;

          return 0;
        });
    }
  };

  // PAGE DATA
  const actualPage = totalData ? totalData.page : currentPage;

  const handleSortRequest = (column: string) => {
    setColumnSort(column);
    setRowData(sortArray(data, orderDirection));
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
  };

  /**
   * Soporta el evento click para cerrar la ventana de confirmación
   */
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  /**
   * Soporta el evento click para descargar datos de la tabla en el formato seleccionado
   */
  const handleExportFile = () => {
    alert("funciona");
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value.toUpperCase();
    const arrayData = [...data];
    dispatch(valueSearched(searchText));

    if (searchText.length === 0) return setfilteredValues([]);

    const filteredData = arrayData.filter((data) =>
      handleFindData(data, searchText)
    );

    setfilteredValues(filteredData);
  };

  const handleFindData = (data: any, searchText: string) => {
    let search = "";
    //User list
    component === "users" &&
      (search = data.nameComplete.toUpperCase() + " " + data.id);

    data.url && (search = data.name.toUpperCase());

    data.actionsPermissions && (search = data.name.toUpperCase());

    component === "credentials" &&
      (search = data.user.nameComplete.toUpperCase() + " " + data.user.id);

    component === "credentialsPointSale" &&
      (search = data.nameComplete.toUpperCase() + " " + data.id);

    component === "commissionalPointSale" &&
      (search = data.name.toUpperCase() + " " + data.id);

    component === "roles" &&
      (search = data.rol?.name.toUpperCase() + " " + data.rol?.id);

    component === "managementAds" &&
      (search = data.name.toUpperCase() + " " + data.id.toString());

    return search.indexOf(searchText) > -1;
  };

  /**
   * Soporta el evento click para consulta de datos en el paginador
   */
  const getDataPage = async (page: number) => {
    await dispatch(getDataPagination(page, component, undefined, dataFilter));
  };

  useEffect(() => {
    totalData.total && setCount(totalData.total);
  }, [totalData]);

  /**
   * COMPONENT PARA EXPORTAR FILES
   */

  const arrayComponent = [
    { component: "users" },
    // { component: "refills" },
    { component: "menu" },
    { component: "configCompany" },
    { component: "managementCompany" },
    { component: "transactions" },
    { component: "legal" },
    { component: "irrigation" },
    { component: "bettingHistory" },
    { component: "bets" },
    { component: "bonds" },
  ];

  const exportFileComponent = arrayComponent.some(
    (item) => item.component === component
  );

  return (
    <>
      <div
        className={
          component !== "parameterizations" &&
            component !== "credentials" &&
            component !== "credentialsPointSale" &&
            component !== "paymentOrders" &&
            component !== "commissionalPointSale" &&
            component !== "exclusions" &&
            component !== "irrigation"
            ? "m-3"
            : ""
        }
      >
        {loading ? (
          <Box
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            width="100%"
            mt={3}
          >
            <CircularProgress className={"colorLoading"} />
          </Box>
        ) : (
          <>
            <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
              <Table
                stickyHeader={component !== "credentials"}
                aria-label="collapsible table"
              >
                <TableHead>
                  {(component === "credentials" ||
                    component === "credentialsPointSale" ||
                    component === "commissionalPointSale") && (
                      <TableRow>
                        <TableCell
                          sx={{
                            backgroundColor: "#f4f4f4",
                            width: "100%",
                            "&.MuiTableCell-root": {
                              borderBottom: "1px solid #3C9CA7",
                              whiteSpace: "normal", // Permitir el ajuste automático de líneas
                              wordBreak: "break-word", // Permitir el salto de palabras largas
                            },
                          }}
                          colSpan={7}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ fontSize: 16, fontWeight: 700 }}
                          >
                            <Box
                              width={"50%"}
                              style={{ color: "#3C9CA7" }}
                              className="no-overflow"
                            >
                              {credentialsTitle}
                            </Box>
                            <div className="inner-addon left-addon-associate">
                              <i className="fa fa-search aico clr" />
                              <input
                                type="text"
                                className="input-app-sm w-100 ml-10"
                                placeholder={t("search.placeholder")}
                                onChange={handleFilter}
                                title="Filtrar de busqueda en la tabla"
                              />
                            </div>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  <TableRow>
                    {columns.map((item: IColumnsTable, index) => {
                      return (
                        <StyledTableCell
                          key={item.id}
                          align={
                            (component === "parameterizations" ||
                              component === "associates" ||
                              component === "bettingHistory" ||
                              component === "tickets" ||
                              component === "exclusions" ||
                              component === "bonds" ||
                              component === "observations" ||
                              component === "playerActivity" ||
                              component === "bets" ||
                              component === "refills" ||
                              component === "credentials" ||
                              component === "credentialsPointSale" ||
                              component === "pointsOfSale" ||
                              component === "commissionalPointSale" ||
                              component === "configParams" ||
                              component === "paymentOrders") &&
                              item.header !== "table.actions" &&
                              item.header !== "exclusions.action"
                              ? "left"
                              : "center"
                          }
                          width={150}
                          onClick={() => {
                            item.header !== "table.actions" &&
                              handleSortRequest(item.header);
                          }}
                        >
                          <Grid container m={0} p={0}>
                            <Grid
                              item
                              xs={
                                item.header === "table.actions" ||
                                  component === "tickets" ||
                                  component === "exclusions" ||
                                  component === "playerActivity" ||
                                  component === "observations"
                                  ? 12
                                  : 9
                              }
                              m={0}
                              p={0}
                            >
                              {t(`${item.header}`).toUpperCase()}
                            </Grid>
                            {item.header !== "table.actions" &&
                              isSortingTable && (
                                <Grid
                                  item
                                  xs={3}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                  m={0}
                                  p={0}
                                >
                                  {columnSort === item.header ? (
                                    <TableSortLabel
                                      active={true}
                                      direction={orderDirection}
                                      IconComponent={ArrowDropDownIcon}
                                      sx={{
                                        ".MuiTableSortLabel-icon": {
                                          fontSize: 35,
                                          color: "#2C97DF !important",
                                          cursor: "pointer",
                                        },
                                      }}
                                    />
                                  ) : (
                                    <TableSortLabel
                                      active={true}
                                      IconComponent={ArrowDropUpIcon}
                                      sx={{
                                        ".MuiTableSortLabel-icon": {
                                          fontSize: 35,
                                          color: "#CED4DA !important",
                                          cursor: "pointer",
                                        },
                                      }}
                                    />
                                  )}
                                </Grid>
                              )}
                          </Grid>
                        </StyledTableCell>
                      );
                    })}
                    {exportFileComponent && exportPermissions && (
                      <StyledTableCell align="center" width={50}>
                        <Tooltip
                          open={tooltipIsOpen}
                          onClick={() => setTooltipIsOpen((prev) => !prev)}
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: "transparent",
                                "& .MuiTooltip-arrow": {
                                  color: "#02D0E7",
                                },
                                fontWeight: "bold",
                                color: "#000",
                                maxWidth: "none",
                                cursor: "pointer",
                              },
                            },
                          }}
                          title={
                            <ExportFilesList
                              component={component}
                              exportData={data}
                              setTooltipIsOpen={setTooltipIsOpen}
                            />
                          }
                          disableHoverListener
                          placement="bottom"
                        >
                          <i
                            style={{ color: "#007E8C", cursor: "pointer" }}
                            className="fa fa-file-export fa-lg"
                          ></i>
                        </Tooltip>
                      </StyledTableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {value.trim().length > 0 && component !== "tickets"
                    ? filteredValues
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any, i) => (
                        <BodyTable
                          key={row.id + "-" + i}
                          row={row}
                          subMenu={subMenu}
                          icons={actions}
                          component={component}
                          param={param}
                          dataFilter={dataFilter}
                          page={actualPage}
                          agileCheck={agileCheck}
                          exportData={
                            exportFileComponent && exportPermissions
                          }
                        />
                      ))
                    : data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any, i) => (
                        <BodyTable
                          key={row.id + "-" + i}
                          row={row}
                          subMenu={subMenu}
                          icons={actions}
                          component={component}
                          param={param}
                          dataFilter={dataFilter}
                          page={actualPage}
                          agileCheck={agileCheck}
                          exportData={
                            exportFileComponent && exportPermissions
                          }
                        />
                      ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid container display="flex" justifyContent="center">
              {pages !== undefined &&
                (pages === 1 && count > 0 ? (
                  <>
                    <StaticPagination
                      total={count}
                      onPageChange={(page: any) => {
                        getDataPage(page);
                        setCurrentPage(page);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Pagination
                      currentPage={actualPage}
                      totalPages={pages}
                      siblingCount={1}
                      onPageChange={(page: any) => {
                        getDataPage(page);
                        setCurrentPage(page);
                      }}
                      component={component}
                      totalData={totalData}
                      param={param}
                      dataFilter={dataFilter}
                    />
                  </>
                ))}
            </Grid>

            <MyDialogExportFile
              open={openDialog}
              title={t("dialog.selectFormat")}
              handleAccept={handleExportFile}
              handleClose={handleCloseDialog}
            />
          </>
        )}
      </div>
    </>
  );
};
