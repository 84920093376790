import {
  BonusPragmatic,
  ParamsCreatePragmaticBonus,
} from "../interfaces/bonus";

export const getPragmaticGames = (token: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PRAGMATIC}getGames?options=GetFeatures,GetFrbDetails`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const createFreeRounds = (
  token: string,
  params: ParamsCreatePragmaticBonus,
  body: BonusPragmatic
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  const queryParams = new URLSearchParams(params as any).toString();

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PRAGMATIC}createFreeRounds?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
interface bonusPlayerList {
  playerList: string[];
}
export const asingBonusToPlayer = (
  token: string,
  bonusCode: string,
  body: bonusPlayerList
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(body),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PRAGMATIC}addPlayers?bonusCode=${bonusCode}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
