import { FC, KeyboardEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import { MyTextField } from "../custom/MyTextField";
import { networkConfigurationSchema } from "./validationSchemas";
import { MyDialog } from "../custom/MyDialog";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { IExternalUsersData } from "../../../interfaces/commercialNetworks";
import { startUpdateCommercialNetwork } from "../../../redux/actions/commercialNetworkActions";
import { asyncLoadingFunction } from "../../../redux/actions/helperActions";
import { useLocation } from "react-router-dom";
import { regDecimal } from "../../../utils/utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const utcOffsets = [
  "UTC",
  "America/Bogota",
  "America/Guayaquil",
  "America/Costa_Rica",
  "America/El_Salvador",
  "America/Panama",
  "America/Tegucigalpa"
];
interface Props {
  network: IExternalUsersData;
}
export const FormNetworkConfiguration: FC<Props> = ({ network }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { loading } = useSelector((state: AppState) => state.helper);
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const [submit, setSubmit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [saveValues, setSaveValues] =
    useState<Partial<IExternalUsersData> | null>(null);
  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);
  const enableEdit =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.updated === 1;

  const initialValues: Partial<IExternalUsersData> = {
    rechargeStartTime: network.rechargeStartTime || "",
    rechargeEndTime: network.rechargeEndTime || "",
    withdrawalStartTime: network.withdrawalStartTime || "",
    withdrawalEndTime: network.withdrawalEndTime || "",
    maxRechargePerTransaction: network.maxRechargePerTransaction || "",
    maxRechargeDaily: network.maxRechargeDaily || "",
    maxRechargeWeekly: network.maxRechargeWeekly || "",
    maxRechargeMonthly: network.maxRechargeMonthly || "",
    maxWithdrawalPerTransaction: network.maxWithdrawalPerTransaction || "",
    maxWithdrawalDaily: network.maxWithdrawalDaily || "",
    maxWithdrawalWeekly: network.maxWithdrawalWeekly || "",
    maxWithdrawalMonthly: network.maxWithdrawalMonthly || "",
    timezone: network.timezone || "",
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleAccept = async () => {
    handleCloseDialog();

    if (saveValues) {
      const updatedValues: Partial<IExternalUsersData> = {
        rechargeStartTime: saveValues?.rechargeStartTime?.trim() || null,
        rechargeEndTime: saveValues?.rechargeEndTime?.trim() || null,
        withdrawalStartTime: saveValues?.withdrawalStartTime?.trim() || null,
        withdrawalEndTime: saveValues?.withdrawalEndTime?.trim() || null,
        maxRechargePerTransaction: saveValues?.maxRechargePerTransaction?.trim()
          ? parseFloat(saveValues.maxRechargePerTransaction).toFixed(2)
          : null,
        maxRechargeDaily: saveValues?.maxRechargeDaily?.trim()
          ? parseFloat(saveValues.maxRechargeDaily).toFixed(2)
          : null,
        maxRechargeWeekly: saveValues?.maxRechargeWeekly?.trim()
          ? parseFloat(saveValues.maxRechargeWeekly).toFixed(2)
          : null,
        maxRechargeMonthly: saveValues?.maxRechargeMonthly?.trim()
          ? parseFloat(saveValues.maxRechargeMonthly).toFixed(2)
          : null,
        maxWithdrawalPerTransaction:
          saveValues?.maxWithdrawalPerTransaction?.trim()
            ? parseFloat(saveValues.maxWithdrawalPerTransaction).toFixed(2)
            : null,
        maxWithdrawalDaily: saveValues?.maxWithdrawalDaily?.trim()
          ? parseFloat(saveValues.maxWithdrawalDaily).toFixed(2)
          : null,
        maxWithdrawalWeekly: saveValues?.maxWithdrawalWeekly?.trim()
          ? parseFloat(saveValues.maxWithdrawalWeekly).toFixed(2)
          : null,
        maxWithdrawalMonthly: saveValues?.maxWithdrawalMonthly?.trim()
          ? parseFloat(saveValues.maxWithdrawalMonthly).toFixed(2)
          : null,
        timezone: saveValues?.timezone?.trim() || null,
      };

      dispatch(
        asyncLoadingFunction(
          async () =>
            await dispatch(
              startUpdateCommercialNetwork(network.id, { ...updatedValues }, true, true)
            )
        )
      );
    }
  };

  return (
    <Box width={1}>
      <Formik
        initialValues={initialValues}
        validationSchema={networkConfigurationSchema}
        validateOnChange={true}
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          setOpenDialog(true);
          setSaveValues(data);
          setMessage(t("commercialNetworks.sureConfigureNetwork"));
          setSubmitting(false);
        }}
      >
        {({ handleChange, values, isValid }) => (
          <Form>
            <Grid
              container
              spacing={1}
              display={"flex"}
              justifyContent={"space-between	"}
            >
              <Grid item xs={12} sm={6} md={3}>
                <label className="ml-3">
                  {t("commercialNetworks.initialRechargeTime") + "*"}
                </label>
                <MyTextField
                  name="rechargeStartTime"
                  type="time"
                  InputLabelProps={{ shrink: false }}
                  inputProps={{
                    step: 1,
                  }}
                  onChange={handleChange}
                  disabled={!enableEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <label className="ml-3">
                  {t("commercialNetworks.finalRechargeTime") + "*"}
                </label>
                <MyTextField
                  name="rechargeEndTime"
                  type="time"
                  InputLabelProps={{ shrink: false }}
                  inputProps={{
                    step: 1,
                  }}
                  onChange={handleChange}
                  disabled={!enableEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <label className="ml-3">
                  {t("commercialNetworks.initialWithdrawalTime") + "*"}
                </label>
                <MyTextField
                  name="withdrawalStartTime"
                  type="time"
                  InputLabelProps={{ shrink: false }}
                  inputProps={{
                    step: 1,
                  }}
                  onChange={handleChange}
                  disabled={!enableEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <label className="ml-3">
                  {t("commercialNetworks.finalWithdrawalTime") + "*"}
                </label>
                <MyTextField
                  name="withdrawalEndTime"
                  type="time"
                  InputLabelProps={{ shrink: false }}
                  inputProps={{
                    step: 1,
                  }}
                  onChange={handleChange}
                  disabled={!enableEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <label className="ml-3">
                  {t("commercialNetworks.maxRechargePerTransaction") + "*"}
                </label>
                <MyTextField
                  name="maxRechargePerTransaction"
                  InputLabelProps={{ shrink: false }}
                  onChange={handleChange}
                  disabled={!enableEdit}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regDecimal(e.key) && e.preventDefault()
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <label className="ml-3">
                  {t("commercialNetworks.maxRechargeDaily") + "*"}
                </label>
                <MyTextField
                  name="maxRechargeDaily"
                  InputLabelProps={{ shrink: false }}
                  onChange={handleChange}
                  disabled={!enableEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <label className="ml-3">
                  {t("commercialNetworks.maxRechargeWeekly") + "*"}
                </label>
                <MyTextField
                  name="maxRechargeWeekly"
                  InputLabelProps={{ shrink: false }}
                  onChange={handleChange}
                  disabled={!enableEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <label className="ml-3">
                  {t("commercialNetworks.maxRechargeMonthly") + "*"}
                </label>
                <MyTextField
                  name="maxRechargeMonthly"
                  InputLabelProps={{ shrink: false }}
                  onChange={handleChange}
                  disabled={!enableEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <label className="ml-3">
                  {t("commercialNetworks.maxWithdrawalPerTransaction") + "*"}
                </label>
                <MyTextField
                  name="maxWithdrawalPerTransaction"
                  InputLabelProps={{ shrink: false }}
                  onChange={handleChange}
                  disabled={!enableEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <label className="ml-3">
                  {t("commercialNetworks.maxWithdrawalDaily") + "*"}
                </label>
                <MyTextField
                  name="maxWithdrawalDaily"
                  InputLabelProps={{ shrink: false }}
                  onChange={handleChange}
                  disabled={!enableEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <label className="ml-3">
                  {t("commercialNetworks.maxWithdrawalWeekly") + "*"}
                </label>
                <MyTextField
                  name="maxWithdrawalWeekly"
                  InputLabelProps={{ shrink: false }}
                  onChange={handleChange}
                  disabled={!enableEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <label className="ml-3">
                  {t("commercialNetworks.maxWithdrawalMonthly") + "*"}
                </label>
                <MyTextField
                  name="maxWithdrawalMonthly"
                  InputLabelProps={{ shrink: false }}
                  onChange={handleChange}
                  disabled={!enableEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <label className="ml-3">
                  {t("commercialNetworks.timezone") + "*"}
                </label>
                <FormControl
                  sx={{ width: "100%" }}
                  size="small"
                  error={submit && !values.timezone?.trim()}
                >
                  <Select
                    name="timezone"
                    value={values.timezone ?? ""}
                    onChange={handleChange}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    MenuProps={MenuProps}
                  >
                    {utcOffsets.map((offset) => (
                      <MenuItem key={offset} value={offset}>
                        {offset}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText>
                  {submit && !values.timezone?.trim() && (
                    <label className="ml-3" style={{ color: "#d32f2f" }}>
                      {t("form.requiredFile")}
                    </label>
                  )}
                </FormHelperText>
              </Grid>
              {
                enableEdit &&
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={9}
                  display="flex"
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Button
                    type="submit"
                    style={{
                      border: "none",
                      outline: "none",
                      backgroundColor: "#02D0E7",
                      height: "40px",
                    }}
                    variant="contained"
                    onClick={() => setSubmit(true)}
                    disabled={!isValid || !!loading}
                  >
                    {!!loading ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      <>{t("button.save")}</>
                    )}
                  </Button>
                </Grid>
              }
            </Grid>
          </Form>
        )}
      </Formik>

      <MyDialog
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message={message}
        handleAccept={handleAccept}
        handleClose={handleCloseDialog}
        isLoading={!!loading}
      />
    </Box>
  );
};
