import React, { useEffect } from "react";
import TableComponent from "./TableComponent";
import { useDispatch, useSelector } from "react-redux";
import { startGetGames } from "../../../redux/actions/casinoActivity";
import { AppState } from "../../../redux/reducers/rootReducer";

const GamesTable: React.FC = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    if (token) {
      const params = {
        page: 1,
        perPage: 10,
      };
      dispatch(startGetGames(params));
    }
  }, [dispatch, token]);

  return <TableComponent />;
};

export default GamesTable;
