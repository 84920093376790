import { useSelector } from "react-redux";
import moment from "moment";
import { headersCommercialNetwork } from "../../../helpers/headersTables";
import { capitalizeFirstLetter, getCopyArrayOrObject } from "../../../utils/utils";
import { AppState } from "../../../redux/reducers/rootReducer";
import { IExternalUsersData } from "../../../interfaces/commercialNetworks";

export const CommercialNetworkDataTable = (_: any[]) => {
  const { data } = useSelector((state: AppState) => state.search);
  const { networks } = useSelector((state: AppState) => state.commercialNetworks);
  const totalData = data ?? networks;
  const headersCvsStyle = headersCommercialNetwork.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  const dataUsers =
    totalData && totalData.data ? getCopyArrayOrObject(totalData.data) : [];

  const buildTotalData = (arr: IExternalUsersData[]) => {
    const multiData = [
      {
        columns: headersCvsStyle,
        data: arr.map((data: IExternalUsersData) => {
          const array = [
            {
              value: capitalizeFirstLetter(data.name),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.nit,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.legalRepresentative,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.phone,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.email,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: moment(data.createAt).format("DD/MM/YYYY"),
              style: { alignment: { horizontal: "center" } },
            },
          ];
          return array;
        }),
      },
    ];
    return multiData;
  };

  const multiData = buildTotalData(dataUsers);
  const finalData = {
    dataPerView: dataUsers,
    dataTotal: dataUsers,
    headersCSV: headersCommercialNetwork,
    multiDataSet: multiData,
    multiTotalDataSet: multiData,
  };
  return finalData;
};
