import { getPaymetsByPointSale } from "../../services/bets";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import { setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";
import { arraySearch } from "./searchActions";

export const startGetPaymentsPointSale = (
  params: any,
  cleanFilter?: boolean,
  ticket?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (params.page || rowsPerPage) {
      params.page = params.page.toString();
      params.perPage = rowsPerPage.toString();
    }

    try {
      if (token) {
        let finalValues: any = {};
        Object.entries(params).forEach(([key, value], index) => {
          //@ts-ignore
          if (value && value.length > 0) {
            finalValues[key] = value;
          }
        });

        const response =
          user && (await getPaymetsByPointSale(token, finalValues));

        if (response && !response.error) {
          if (cleanFilter) {
            dispatch(arraySearch(null));
            dispatch(setPaysRedux(response));
          } else {
            if (response.data.length === 0 && ticket) {
              dispatch(setOpenAlert());
              dispatch(setMessageAlert("bets.ticketNumberNotExist"));
              dispatch(setTypeAlert("error"));
              dispatch(arraySearch(null));
            } else {
              dispatch(arraySearch(response));
            }
          }
        } else {
          dispatch(setOpenAlert());
          dispatch(
            setMessageAlert(
              ticket ? "alerts.errorSetTickets" : "alerts.errorSetBets"
            )
          );
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const setPaysRedux = (pays: any | null) => ({
  type: Types.setPays,
  payload: pays,
});
