import {
  IParamCountryGames,
  IParamCountrySolutions,
  IParamPaymentMethod,
} from "../interfaces/params";

export const getParamSettingsDB = (
  token: string,
  param: string,
  page: number | string,
  rowsPerPage: number,
  operatorId: number | null
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  let baseUrl = process.env.REACT_APP_API_HTTP_SECURITY;
  if (param === "bonusTable") {
    param = "bonusType";
    baseUrl = process.env.REACT_APP_API_HTTP_BETS;
  }

  const operator = operatorId ? `operator=${operatorId}` : "";

  return fetch(
    `${baseUrl}${param}?page=${page}&perPage=${rowsPerPage}&${operator}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const createParamConfigPaymentMethod = (
  token: string,
  data: IParamPaymentMethod
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}paramPaymentMethods`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => error);
};
export const updateParamConfigPaymentMethod = (
  token: string,
  data: IParamPaymentMethod
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}paramPaymentMethods`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => error);
};
export const getParamPaymentById = (
  token: string,
  id: number,
  operatorId: number | null,
  params?: any
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryString = params ? new URLSearchParams(params).toString() : "";

  const operator = operatorId ? `operator=${operatorId}` : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}paramPaymentMethods?${queryString}&page=1&perPage=100&countryId=${id}&${operator}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const createParamGame = (token: string, data: IParamCountryGames) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${process.env.REACT_APP_API_HTTP_SECURITY}paramGames`, requestOptions)
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => error);
};
export const updateParamGame = (token: string, data: IParamCountryGames) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${process.env.REACT_APP_API_HTTP_SECURITY}paramGames`, requestOptions)
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => error);
};
export const getParamGameByCountry = (
  token: string,
  idCountry: number,
  operatorId: number | null
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const operator = operatorId ? `operator=${operatorId}` : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}paramGames?countryId=${idCountry}&${operator}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => error);
};
export const createParamSolution = (
  token: string,
  data: IParamCountrySolutions
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}paramSolutions`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => error);
};
export const updateParamSolutionService = (
  token: string,
  data: IParamCountrySolutions
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}paramSolutions`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => error);
};
export const getParamSolutionByCountry = (
  token: string,
  id: number,
  operatorId: number | null
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const operator = operatorId ? `operator=${operatorId}` : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}paramSolutions?countryId=${id}&${operator}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => error);
};

export const deleteParameterizationService = (
  token: string,
  route: string,
  idParam: number
) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}${route}/${idParam}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
