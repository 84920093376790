import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import TableCell from "@mui/material/TableCell";
import { SessionLogoutReason } from "../../../../enums/enums";

interface Props {
  row: any;
}

export const RowDataTablePlayerSession: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  return (
    <>
      <TableCell className="word-break">{row.id}</TableCell>
      <TableCell className="word-break">{row.player?.id}</TableCell>
      <TableCell className="word-break">
        {row.player?.firstName + " " + row.player?.lastName}
      </TableCell>
      <TableCell className="word-break">
        {moment(row.createAt).format("DD/MM/YYYY HH:mm:ss")}
      </TableCell>
      <TableCell className="word-break">
        {moment(row.lastAt).format("DD/MM/YYYY HH:mm:ss")}
      </TableCell>
      <TableCell className="word-break">{row.ip}</TableCell>
      <TableCell className="word-break">
        {row.balanceLogin ? "$ " + row.balanceLogin : ""}
      </TableCell>
      <TableCell className="word-break">
        {row.balanceLogout ? "$ " + row.balanceLogout : ""}
      </TableCell>
      <TableCell className="word-break">{"$ " + row.total_bets}</TableCell>
      <TableCell className="word-break">{"$ " + row.total_won}</TableCell>
      <TableCell className="word-break">{"$ " + row.total_deposits}</TableCell>
      <TableCell className="word-break">
        {"$ " + row.total_withdrawals}
      </TableCell>
      <TableCell className="word-break">
        {row.log_description && (
          <>
            {row.log_description === SessionLogoutReason.USER_LOGOUT &&
              t("playerSessionReport.playerLogout")}
            {row.log_description === SessionLogoutReason.PLATFORM_LOGOUT &&
              t("playerSessionReport.platformLogout")}
            {row.log_description === SessionLogoutReason.EXCLUSION_LOGOUT &&
              t("playerSessionReport.exclusionLogout")}
            {row.log_description ===
              SessionLogoutReason.CHANGE_LOGIN_CREDENTIALS &&
              t("playerSessionReport.changeCredentials")}
            {row.log_description === SessionLogoutReason.TIMEOUT_EXCEEDED &&
              t("playerSessionReport.timeoutExceeded")}
          </>
        )}
      </TableCell>
      <TableCell />
    </>
  );
};
