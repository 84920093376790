import { FC } from "react";
import { useTranslation } from "react-i18next";

import TableCell from "@mui/material/TableCell";

import { State } from "../../../../enums/enums";
import moment from "moment";
import { DEFAULT_ROLES } from "../../../../shared/const";

interface Props {
  row: any;
}

export const RowDataTableCredentials: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  return (
    <>
      <TableCell className="word-break">{row.user.nameComplete}</TableCell>
      <TableCell className="word-break">
        {DEFAULT_ROLES.includes(row.user.roles.name)
          ? t(`roles.${row.user.roles.name}`)
          : row.user.roles.name}
      </TableCell>
      <TableCell className="word-break">{row.user.email}</TableCell>
      <TableCell className="word-break">
        {moment(row.user.createAt).format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
      <TableCell className="word-break">
        {row.user.verificationTwoStep === 1 ? t("table.yes") : t("table.not")}
      </TableCell>
      <TableCell align="center">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                State.ACTIVE === row.user.stateUser
                  ? "d-flex justify-content-center cellRowActive"
                  : State.INACTIVE === row.user.stateUser
                  ? "d-flex justify-content-center cellRowInactive"
                  : "d-flex justify-content-center cellRowRetired"
              }
            >
              <div className="d-flex col-sm-4 justify-content-center">
                {State.ACTIVE === row.user.stateUser && (
                  <>{t("state.active")}</>
                )}
                {State.INACTIVE === row.user.stateUser && (
                  <>{t("state.inactive")}</>
                )}
                {State.RETIRED === row.user.stateUser && (
                  <>{t("state.retired")}</>
                )}
                {State.RESTRICTED === row.user.stateUser && (
                  <>{t("state.restricted")}</>
                )}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
    </>
  );
};
