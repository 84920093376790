import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Button, CircularProgress, Grid, ThemeProvider } from "@mui/material";
import { theme } from "../custom/customStyles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import clearLogo from "../../../assets/svg/icon-park_clear-format.svg";
import { getUsersMainService } from "../../../services/managementUsers";
import { Roles_Ids_Enum } from "../../../enums/enums";
import { SelectWithSearch } from "../../../components/Custom/SelectWithSearch";
import { ISelectOptions } from "../../../interfaces/components";
import { arraySearch } from "../../../redux/actions/searchActions";

interface Props {
  admin: number | null;
  setAdmin: Dispatch<SetStateAction<number | null>>;
  handleCancel?: () => void;
  handleContinue?: () => void;
  handleCleanForm?: () => void;
  handlePrevious?: () => void;
  operator: number | null;
}
export const AssingAdminCards: FC<Props> = ({
  admin,
  setAdmin,
  handlePrevious,
  handleCancel,
  handleContinue,
  handleCleanForm,
}) => {
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { loading } = useSelector((state: AppState) => state.helper);
  const [adminList, setAdminList] = useState<any[]>([]);
  const [disabled, setDisabled] = useState(true);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    setDisabled(admin === null);
  }, [admin]);
  const getAdminOperators = async () => {
    if (!token || !user) {
      throw new Error("Invalid action");
    }

    const listAdmins = await getUsersMainService(token, {
      roles: Roles_Ids_Enum.ADMIN_OPERATOR,
      operatorId: user?.operator.id ?? null,
    });

    const castedAdminList: ISelectOptions[] = listAdmins.data.map(
      (item: any) => ({
        label: item.username,
        value: item.id,
      })
    );
    dispatch(arraySearch(castedAdminList));

    setAdminList(castedAdminList);
  };
  useEffect(() => {
    getAdminOperators();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeAdmin = (e: any) => {
    const {
      target: { value },
    } = e;
    setAdmin(value);
  };
  const handleSubmit = (data: any) => {
    handleContinue && handleContinue();
  };
  return (
    <div className="container mt-3">
      <ThemeProvider theme={theme}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <label className="ml-3">{t("cards.selectAdmin") + "*"}</label>
            <SelectWithSearch
              label={""}
              options={adminList}
              value={admin ? `${admin}` : ""}
              onChange={handleChangeAdmin}
            />
          </Grid>

          <Grid
            item
            xs={12}
            mt={2}
            display="flex"
            justifyContent="center"
            sx={{ height: "60px" }}
          >
            <Button
              style={{
                border: "none",
                outline: "none",
              }}
              variant="contained"
              className="footer-btn primary-btn ripple"
              onClick={handlePrevious}
            >
              <i className="fa-solid fa-angle-left pl-2"></i>
              {t("button.previous").toUpperCase()}
            </Button>

            <Button
              style={{ border: "none", outline: "none" }}
              variant="contained"
              className="footer-btn danger-btn ripple"
              onClick={handleCancel}
            >
              <i className="fa-solid fa-x"></i>
              {t("button.cancel").toUpperCase()}
            </Button>
            <Button
              style={{
                border: "none",
                outline: "none",
              }}
              type="submit"
              data-testid="btnNext"
              variant="contained"
              className="footer-btn primary-btn ripple"
              onClick={(e) => handleSubmit(e)}
              disabled={disabled}
            >
              {loading ? (
                <CircularProgress size={"1.6rem"} color="inherit" />
              ) : (
                t("button.next").toUpperCase()
              )}
            </Button>

            <Button
              style={{
                border: "none",
                outline: "none",
                marginLeft: "4px",
              }}
              onClick={handleCleanForm}
            >
              <img src={clearLogo} alt="Icon clear" />
            </Button>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};
