export const getMovementTypes = (token: string) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        api: `${process.env.REACT_APP_API_KEY}`,
        Authorization: `bearer ${token}`,
      },
    };
  
    return fetch(
      `${process.env.REACT_APP_API_HTTP_PLAYERS}movementTypes`,
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => responseData);
  };