import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Filter from "../../../../components/Filter/Filter";
import { useTranslation } from "react-i18next";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import { CustomAlert } from "../../../../components/Alerts/CustomAlert";
import { MyAlert } from "../../custom/MyAlert";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  asyncLoadingFunction,
  setCloseAlert,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import MyCustomTable, {
  IColumns,
} from "../../../../components/Custom/Table/MyCustomTable";
import { RowCardsSales } from "./RowCardsSales";
import { startGetCards } from "../../../../redux/actions/managementCardsActions";
import { StateCards } from "../../../../enums/enums";

export const CardsSales = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [dataFilter, setDataFilter] = useState<any>({});
  const [isFiltering, setIsFiltering] = useState(false);
  const { data } = useSelector((state: AppState) => state.search);
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);

  const { openAlert, messageAlert, typeAlert } = useSelector(
    (state: AppState) => state.helper
  );

  const FilterFileds: IFilterField[] = [
    {
      label: t("managementCards.cards"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("managementCards.cardsSold"),
          value: StateCards.SOLD,
        },
        {
          label: t("managementCards.cardsActive"),
          value: StateCards.ACTIVE,
        },
      ],
    },
    {
      label: t("managementCards.consecutiveCard"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("managementCards.valueCard"),
      type: Enum_FieldType.TEXT,
    },
  ];

  const columns: IColumns[] = [
    {
      align: "center",
      label: t("managementCards.idLot"),
    },
    {
      align: "center",
      label: t("managementCards.consecutiveCard"),
    },
    {
      align: "center",
      label: t("managementCards.valueCard"),
    },
    {
      align: "center",
      label:
        dataFilter.state === StateCards.SOLD
          ? t("managementCards.activationDate")
          : t("table.actions"),
    },
  ];

  const handleFilter = async (values: any) => {
    const params = {
      state: values[t("managementCards.cards")] || StateCards.ACTIVE,
      id: values[t("managementCards.consecutiveCard")],
      value: values[t("managementCards.valueCard")],
    };
    setDataFilter(params);

    dispatch(asyncLoadingFunction(() => dispatch(startGetCards(params))));
  };

  const onCleanFilter = async () => {
    setDataFilter({});
    setIsFiltering(false);
  };

  const cardsArray = data;

  const onCloseAlert = () => {
    dispatch(setCloseAlert());
  };

  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startGetCards({
            ...dataFilter,
            page,
            perPage: rows || rowsPerPage,
          })
        )
      )
    );
    setIsFiltering(true);
  };

  useEffect(() => {
    if (data && data.data.length === 0) {
      dispatch(
        setMessageAlert(isFiltering ? "alerts.noData" : "alerts.noRecords")
      );
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="white-container">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Filter
            fields={FilterFileds}
            handleFilter={handleFilter}
            onClean={onCleanFilter}
          />
        </Grid>
        <Grid item xs={12}>
          {(!cardsArray || cardsArray.total === 0) && (
            <CustomAlert
              htmlText={t("managementCards.filterText")}
              type="info"
            />
          )}
          {cardsArray && cardsArray.total > 0 && (
            <MyCustomTable
              columns={columns}
              data={cardsArray.data}
              lastPage={cardsArray.last_page}
              totalData={cardsArray.total}
              onPageChange={handlePageChange}
              renderBody={(item) => <RowCardsSales row={item} />}
            />
          )}
        </Grid>
      </Grid>
      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />
    </div>
  );
};
