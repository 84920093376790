import React, { useEffect, useState } from "react";
import Filter from "../../../../components/Filter/Filter";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { usePointSales } from "../../../../hooks/usePointSales";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { arraySearch } from "../../../../redux/actions/searchActions";
import MyCustomTable, {
  IColumns,
} from "../../../../components/Custom/Table/MyCustomTable";
import { CustomAlert } from "../../../../components/Alerts/CustomAlert";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
  startGetAffiliatesReport,
} from "../../../../redux/actions/helperActions";
import { DealerTypeEnum, Roles_Enum } from "../../../../enums/enums";
import { IGenericData } from "../../../../interfaces/params";
import { getUserById } from "../../../../services/managementUsers";
import { getDealerById } from "../../../../services/dealers";
import { getAffiliationsReport } from "../../../../services/reports";
import moment from "moment";
import { setGetAssociates } from "../../../../redux/actions/associatesActions";
import { getAssociateById } from "../../../../services/associates";
import { AffiliationsDataTable } from "../../../../hooks/useDataTable/affiliations";
import { AffiliationsPdf } from "../../../../components/Pdf/affiliations/AffiliationsPdf";
import { RowAffiliationsReport } from "./RowAffiliationsReport";
import { IParamsFilterAffiliatesReport } from "../../../../interfaces/reports";

interface Field {
  label: string;
}

export const AffiliationsReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { dealer } = useSelector((state: AppState) => state.wallet);
  const { data } = useSelector((state: AppState) => state.search);
  const { associates } = useSelector((state: AppState) => state.associates);
  const [currentPage, setCurrentPage] = useState(1);
  const [listDealers, setListDealers] = useState<IGenericData[]>([]);
  const [idDealer, setIdDealer] = useState<number | string>("none");
  const [idAssociate, setIdAssociate] = useState<number | string>("");
  const [idPointSale, setIdPointSale] = useState<number | string>("");
  const [pointSalesFilter, setPointSalesFilter] = useState<IGenericData[]>([]);
  const [pointSales, setPointSales] = useState<IGenericData[]>([]);
  const [filterFields, setFilterFields] = useState<any>([]);
  const [percentageComissionAffiliations, setPercentageCommissionAffiliations] =
    useState("");
  const [filterParams, setFilterParams] =
    useState<IParamsFilterAffiliatesReport>({});
  const [pointSalesToFilter, setPointSalesToFilter] = useState<IGenericData[]>(
    []
  );
  const isRoleDealer = user?.roles.name === Roles_Enum.DEALER;
  const isRoleAssociate = user?.roles.name === Roles_Enum.ASSOCIATE;
  const isRoleAdmin = user?.roles.name === Roles_Enum.ADMIN_OPERATOR;
  const whiteListPVDealerType = [DealerTypeEnum.ANONYMOUS, DealerTypeEnum.BOTH];
  const allPointSales = usePointSales(
    {
      id: user && user.id,
      operator: user?.operator
        ? {
            id: user.operator.id,
            name: user.operator.name,
          }
        : null,
      roles: {
        id: user?.roles.id,
        name: user?.roles.name,
      },
    },
    token,
    DealerTypeEnum.ANONYMOUS
  );

  const getPercentagesCommission = () => {
    if (user) {
      if (isRoleDealer && dealer) {
        setPercentageCommissionAffiliations(
          dealer.commissionPercentageAffiliations
        );
      } else if (isRoleAssociate) {
        setPercentageCommissionAffiliations(
          user.associatesUsers[0].associate.commissionPercentageAffiliations
        );
      }
    }
  };

  const getDealer = async () => {
    if (token) {
      const dealerDetail = await getDealerById(idDealer as number, token);
      if (isRoleAdmin) {
        const { commissionPercentageAffiliations } = dealerDetail;
        setPercentageCommissionAffiliations(commissionPercentageAffiliations);
      }
      return dealerDetail && dealerDetail;
    }
    return [];
  };

  const getDealers = async () => {
    if (!user || !token) {
      throw new Error("Invalid action");
    }
    const userDetail = await getUserById(token, user.id);

    const filterDealers = userDetail.associatesUsers
      .flatMap((asc: any) => asc.associate.dealers)
      .filter((del: any) => whiteListPVDealerType.includes(del.type));
    setListDealers(filterDealers);
  };

  useEffect(() => {
    if (isRoleAssociate) {
      getDealers();
    } else if (isRoleAdmin) {
      const fetchAsociates = async () => {
        if (!user || !token) {
          throw new Error("Invalid action");
        }
        await dispatch(setGetAssociates(token, 1, true));
      };
      fetchAsociates();
    }
    getPercentagesCommission();
    return () => {
      dispatch(arraySearch(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (idDealer !== "none") {
      const fetchDealerDetail = async () => {
        const dealerDetail = await getDealer();
        const dealersPointSale = dealerDetail.dealersPointSale.map(
          (item: any) => ({
            id: item.pointSale.id,
            name: item.pointSale.name,
          })
        );
        setPointSalesFilter(dealersPointSale);
      };
      fetchDealerDetail();
    } else {
      if (!isRoleAdmin) {
        setPointSalesFilter(allPointSales);
      } else {
        if (
          listDealers.length !== 0 &&
          idAssociate &&
          idAssociate !== "none" &&
          token
        ) {
          fetchAssociateData(token);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idDealer, allPointSales]);

  const fetchAssociateData = async (token: string) => {
    const findUserAssociate: any = await getAssociateById(
      token,
      Number(idAssociate),
      false,
      true
    );

    const filterPoints = findUserAssociate.PointSalesAssociate.filter(
      (point: any) => whiteListPVDealerType.includes(point.type)
    );

    setPointSalesFilter(filterPoints);

    const { dealers, commissionPercentageAffiliations } = findUserAssociate;
    const filterDealers = dealers.filter((del: any) =>
      whiteListPVDealerType.includes(del.type)
    );
    setListDealers(filterDealers || []);
    setPercentageCommissionAffiliations(commissionPercentageAffiliations);
  };

  useEffect(() => {
    if (idAssociate && idAssociate !== "none" && token) {
      fetchAssociateData(token);
    } else {
      const dealers = associates
        ? associates.data.flatMap((associate: any) => associate.dealers)
        : [];
      setListDealers(dealers);
      setPointSalesFilter([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idAssociate]);

  const filterOutRole = (fields: Field[], role: string): Field[] => {
    if (role === "Distribuidores") {
      return fields.filter(
        (item) => item.label !== "Distribuidores" && item.label !== "Asociados"
      );
    } else if (role === "Asociados") {
      return fields.filter((item) => item.label !== "Asociados");
    } else {
      return fields;
    }
  };

  const getFilteredFields = (filterFields: Field[], role: string): Field[] => {
    switch (role) {
      case "Distribuidores":
        return filterOutRole(filterFields, "Distribuidores");
      case "Asociados":
        return filterOutRole(filterFields, "Asociados");
      default:
        return filterFields;
    }
  };

  const role: string | null = isRoleDealer
    ? "Distribuidores"
    : isRoleAssociate
    ? "Asociados"
    : null;

  const shouldFilterPointSales =
    (isRoleAssociate && idDealer !== 0) || isRoleAdmin;

  const pointSalesArray = shouldFilterPointSales
    ? pointSalesFilter
    : allPointSales;

  if (pointSalesArray !== pointSales) {
    setPointSales(pointSalesArray);
  }

  const generateAssociateOptions = (
    associates: any
  ): { label: string; value: string }[] => {
    if (!associates) {
      return [];
    }
    return associates.data.map((pointSale: any) => ({
      label: pointSale.name,
      value: pointSale.id,
    }));
  };

  const associatesOptions = generateAssociateOptions(associates);

  useEffect(() => {
    const filterFieldsFunction = () => {
      const filterFields: IFilterField[] = [
        {
          label: t("filters.startDate"),
          type: Enum_FieldType.DATE,
        },
        {
          label: t("filters.endDate"),
          type: Enum_FieldType.DATE,
        },
        {
          label: t("shorcuts.Associates"),
          type: Enum_FieldType.SELECT,
          options: [
            { label: t("managementDealers.none"), value: "none" },
            ...associatesOptions,
          ],
        },
        {
          label: t("managementDealers.dealers"),
          type: Enum_FieldType.SELECT,
          options: [
            { label: t("managementDealers.none"), value: "none" },
            ...listDealers.map((dealer) => ({
              label: dealer.name,
              value: dealer.id,
            })),
          ],
        },
        {
          label: t("shorcuts.PointSale"),
          type: Enum_FieldType.SELECT,
          options: [
            { label: t("managementBonds.all"), value: "all" },
            ...pointSales.map((pointSale: any) => ({
              label: pointSale.name,
              value: pointSale.id,
            })),
          ],
        },
      ];
      const newFilterFields: Field[] = role
        ? getFilteredFields(filterFields, role)
        : filterFields;

      setFilterFields(newFilterFields);
    };

    filterFieldsFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointSales, listDealers, associates]);

  const handleFilter = async (formValues: any, page: number = 1) => {
    const startDate = formValues[t("filters.startDate")];
    const endDate = formValues[t("filters.endDate")];
    const pointSale = formValues[t("shorcuts.PointSale")] || idPointSale;
    const dealer = formValues[t("managementDealers.dealers")] || idDealer;
    const associate = formValues[t("shorcuts.Associates")] || idAssociate;

    setIdPointSale(pointSale);
    const showAlert = (message: string): void => {
      dispatch(setMessageAlert(message));
      dispatch(setTypeAlert("warning"));
      dispatch(setOpenAlert());
    };
    if (isRoleAdmin && !associate) {
      return showAlert("associates.selectAssociate");
    } else if (associate === "none" && !dealer) {
      return showAlert("reports.selectDealeOrAssociate");
    } else if (!pointSale) {
      return showAlert("cashflow.selectPointSale");
    }

    let pointsSalesFilter;

    if (pointSale === "all") {
      pointsSalesFilter =
        pointSales.length > 0
          ? pointSales
              .map((point) => ({ id: point.id }))
              .sort((a, b) => b.id - a.id)
          : [];
    } else {
      pointsSalesFilter = pointSale ? [{ id: pointSale }] : [];
    }
    const commissionPercentageAffiliations =
      parseFloat(percentageComissionAffiliations) || 0;
    const params: IParamsFilterAffiliatesReport = {
      isSeller: true,
      commissionPercentageAffiliations,
      startDate,
      endDate,
      page,
    };
    setFilterParams(params);
    setPointSalesToFilter(pointSalesFilter);
    await dispatch(startGetAffiliatesReport(params, pointsSalesFilter));
  };

  const onCleanFilter = async () => {
    dispatch(arraySearch(null));
    setFilterParams({});
    setPointSalesToFilter([]);
  };

  const onPageChange = (newPage: any) => {
    setCurrentPage(newPage);
    handleFilter(filterParams, newPage);
  };

  // TABLE
  const columns: IColumns[] = [
    {
      align: "center",
      label: "ID usuario",
    },
    {
      align: "center",
      label: "Nombre",
    },
    {
      align: "center",
      label: "Apellido",
    },
    {
      align: "center",
      label: "Fecha de registro",
    },
    {
      align: "center",
      label: "PV",
    },
    {
      align: "center",
      label: "Modelo de Afiliación",
    },
    {
      align: "center",
      label: "Apuestas",
    },
    {
      align: "center",
      label: "Premios",
    },
    {
      align: "center",
      label: "GGR",
    },
    {
      align: "center",
      label: "Depósito",
    },
    {
      align: "center",
      label: "Comisión",
    },
  ];

  const getAllAffiliations = async () => {
    if (!token) {
      return false;
    }
    const params = {
      isSeller: filterParams.isSeller ?? true,
      commissionPercentageAffiliations:
        filterParams.commissionPercentageAffiliations ?? 0,
      startDate: filterParams.startDate ?? "",
      endDate: filterParams.endDate ?? "",
      page: 1,
    };

    return await getAffiliationsReport(token, params, pointSalesToFilter);
  };
  return (
    <>
      <div
        className="filter"
        style={{ padding: "18px 0px 0px", marginBottom: "18px" }}
      >
        {filterFields.length > 0 && (
          <Filter
            fields={filterFields}
            handleFilter={handleFilter}
            onClean={onCleanFilter}
            component="reportAssociate"
            setIdDealer={setIdDealer}
            setIdAssociate={setIdAssociate}
          />
        )}
      </div>
      {data ? (
        <div className="filter">
          <MyCustomTable
            columns={columns}
            data={data.data}
            lastPage={data.last_page}
            totalData={data.total}
            renderBody={(item) => <RowAffiliationsReport row={item} />}
            onPageChange={onPageChange}
            // @ts-ignore
            currentPage={currentPage}
            expand={true}
            exportData
            hookBuildTable={AffiliationsDataTable}
            getAllDataToExport={getAllAffiliations}
            pdfComponent={AffiliationsPdf}
            titleCSV={`${"reporte de afiliaciones"
              .split(" ")
              .join("-")}-${moment().toISOString()}`}
          />
        </div>
      ) : (
        <CustomAlert
          htmlText={t("reports.filterTextAssociateReport")}
          type="info"
        />
      )}
    </>
  );
};
