/*eslint-disable*/
import { Container } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Footer = (props) => {
  const { t } = useTranslation();
  return (
    <footer className={"footer" + (props.default ? " footer-default" : "")}>
      <Container fluid={props.fluid ? true : false}>
        <div className="copyright">
          {t("login.publicationDate")}
          <h3>V.1.3</h3>
        </div>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
