export const getPaymentOrders = (
  token: string,
  operatorId: number | null,
  playerId: number | undefined,
  params?: any
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const operatorParam = operatorId ? `operatorId=${operatorId}` : "";
  const playerParam = playerId ? `playerId=${playerId}` : "";
  const queryString = params ? new URLSearchParams(params).toString() : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}paymentOrders?${operatorParam}&${playerParam}&${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
