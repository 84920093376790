import {
  Grid,
  InputAdornment,
  TextField,
  Box,
  CircularProgress,
} from "@mui/material";
import React, { useState, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "reactstrap";
import SearchIcon from "@mui/icons-material/Search";
import LogoTicket from "../../../assets/svg/ticket.svg";
import { IColumnsTable, Icons } from "../../../interfaces/components";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { TableComponent } from "../../../components/Table/TableComponent";
import parse from "html-react-parser";
import { MyDialog } from "../custom/MyDialog";
import { uiSetOpenDialog } from "../../../redux/actions/uiAction";
import { regSpecialCharacters } from "../../../utils/utils";
import {
  setCloseAlert,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { MyAlert } from "../custom/MyAlert";
import { getBets } from "../../../services/bets";
import { IBet } from "../../../interfaces/bets";
import { MyDialogInformation } from "../../../components/DialogInformation/MyDialogInformation";
import {
  setActiveTicket,
  startPayBet,
} from "../../../redux/actions/betsActions";
import { WhiteListOperators } from "../../../enums/enums";

const icons: Icons = {
  see: false,
  delete: false,
  seeMore: true,
  edit: false,
  pay: true,
};

export const TabConsultTicket = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { value } = useSelector((state: AppState) => state.search);
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { openDialog } = useSelector((state: AppState) => state.ui);
  const { activeBet } = useSelector((state: AppState) => state.bets);
  const [ticket, setTicket] = useState(value !== "" ? value : "");
  const [dataTicket, setDataTicket] = useState<IBet | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingTicket, setLoadingTicket] = useState(false);
  const { openAlert, typeAlert, messageAlert } = useSelector(
    (state: AppState) => state.helper
  );
  const operator = activeBet?.player?.operator?.name.toUpperCase();

  /**
   * Soporta las Columnas para renderizar en el header de la tabla
   */
  const columns: IColumnsTable[] = [
    { id: 1, header: "table.betId" },
    { id: 3, header: "bets.ticketNumber" },
    { id: 4, header: "bets.dateBet" },
    { id: 5, header: "bets.hourBet" },
    { id: 6, header: "bets.winningAmount" },
    { id: 7, header: "bets.bettingStatus" },
    { id: 8, header: "table.actions" },
  ];

  /**
   * Soporta los eventos del dialogo de confirmación
   */
  const handleAccept = async () => {
    if (activeBet && activeBet.transactions.length > 0) {
      setLoading(true);
      const amount = parseFloat(activeBet.transactions[0].amount);
      //IMPUESTO %
      const percentage = operator === WhiteListOperators.STARPLAY ? 5.5 : 0;
      const taxValue = amount * (percentage / 100);
      const amountPay = amount;
      await dispatch(
        startPayBet({
          ...activeBet,
          amountPay: amountPay.toFixed(2),
          taxValue: taxValue.toFixed(2),
          playerId: activeBet.player.id,
        })
      );
      setLoading(false);
      dispatch(uiSetOpenDialog());
      dispatch(setActiveTicket(null));
      handleSearchTicket();
    }
  };

  const handleCancel = () => {
    dispatch(uiSetOpenDialog());
    dispatch(setActiveTicket(null));
  };

  /**
   * MENSAJE DE CONFIRMACIÓN A ENVIAR -> PARSEADO
   */
  const textConfirmation =
    `<span >${t("bets.dialogTicktetMsg1")} </span>` +
    ` <span style="font-weight:bold">#${activeBet?.reference},</span>` +
    `${t("bets.dialogTicktetMsg2")} ` +
    ` <span style="font-weight:bold">${
      activeBet && activeBet.transactions.length > 0
        ? parseFloat(activeBet.transactions[0].amount).toFixed(2)
        : ""
    }</span> ` +
    `${
      (t("bets.dialogTicktetMsg3", { tax: operator === WhiteListOperators.STARPLAY ? 5.5 : 0 }))
    } ` +
    `<br>` +
    `<span style="display:flex; justify-content: center; font-weight:bold; margin-top: 4px;">${t(
      "bets.surePaid"
    )}</span>`;

  const textSend = parse(textConfirmation);

  const handleSearchTicket = async () => {
    setLoadingTicket(true);
    if (ticket.trim()) {
      const params = {
        ticketId: ticket,
        exact: "true",
        page: 1,
      };

      let finalValues: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (value && value.length > 0) {
          finalValues[key] = value;
        }
      });

      const response =
        user && token && (await getBets(token, user.operator?.id, finalValues));

      if (response && !response.error) {
        if (response.data.length === 0 && ticket) {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("bets.ticketNumberNotExist"));
          dispatch(setTypeAlert("error"));
          setDataTicket(null);
        } else {
          setDataTicket(response);
        }
      } else {
        dispatch(setOpenAlert());
        dispatch(
          setMessageAlert(
            ticket ? "alerts.errorSetTickets" : "alerts.errorSetBets"
          )
        );
        dispatch(setTypeAlert("error"));
      }
    }
    setLoadingTicket(false);
  };

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
  };

  return (
    <div className="container-fluid">
      <Row
        style={{
          marginTop: "12px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} pt={3} mt={2}>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={1} mt={2}>
                <img
                  width={95}
                  height={95}
                  src={LogoTicket}
                  alt="Icon exclamation"
                  style={{ marginRight: "10px" }}
                />
              </Grid>
              <Grid item xs={3} display={"flex"} alignItems={"center"}>
              
                <label className="ml-3 mr-3" style={{marginBottom:0}}>{t("table.betId")}</label>
                <TextField
                  name="ticket"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: false }}
                  inputProps={{ maxLength: 100 }}
                  label={" "}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setTicket(e.target.value);
                  }}
                  value={ticket}
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={handleSearchTicket}
                        >
                          <SearchIcon fontSize="large" />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    regSpecialCharacters(e.key) && e.preventDefault()
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Row>
      {loadingTicket ? (
        <Box
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
          width="100%"
        >
          <CircularProgress className={"colorLoading"} />
        </Box>
      ) : (
        dataTicket &&
        dataTicket.data.length > 0 && (
          <>
            <TableComponent
              columns={columns}
              icons={icons}
              data={dataTicket.data}
              pages={0}
              component={"tickets"}
              totalData={dataTicket}
              isSortingTable={false}
              dataFilter={{}}
            />
          </>
        )
      )}
      <MyDialog
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message={activeBet ? textSend : ""}
        handleAccept={handleAccept}
        handleClose={handleCancel}
        component={"tickets"}
        isLoading={loading}
      />

      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />

      <MyDialogInformation component={"bets"} title={"bets.betDetail"} />
    </div>
  );
};
