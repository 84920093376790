interface Props {
  color: string;
}

export const Circle = ({ color }: Props) => {
  const circleStyle = {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    backgroundColor: color,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
    marginRight: "4px",
  };

  return <div style={circleStyle}></div>;
};
