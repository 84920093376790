import React, { useEffect, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import filePlus from "../../../assets/svg/file-plus.svg";
import fileText from "../../../assets/svg/file-text.svg";
import { BlobProvider } from "@react-pdf/renderer";

// @ts-ignore
import ReactExport from "react-data-export";
import { AppState } from "../../../redux/reducers/rootReducer";
import { MyPdf } from "../Pdf/MyPdf";
import { Roles_Enum } from "../../../enums/enums";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const useStylesCard = makeStyles(() => ({
  title: {
    color: "white",
    fontSize: 12,
  },
  textItem: {
    fontSize: "12px",
  },
}));

interface Props {
  pageData: any[];
  setTooltipIsOpen: any;
  getTotalData: any;
  hookBuildDataTable: any;
  titleCSV: string;
  pdfComponent: any;
}

export const ExportDataList: FC<Props> = ({
  pageData,
  setTooltipIsOpen,
  getTotalData,
  hookBuildDataTable,
  titleCSV,
  pdfComponent,
}) => {
  const classes = useStylesCard();
  const { t } = useTranslation();
  const [totalData, setTotalData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const { token, user } = useSelector((state: AppState) => state.auth);

  const { dataPerView, dataTotal, multiDataSet, multiTotalDataSet } =
    hookBuildDataTable(totalData);

  const handleGetTotalData = async () => {
    try {
      if (user && token) {
        setLoadingData(true);
        const params = user.operator
          ? {
              operatorId: user.operator.id,
            }
          : {};
        const response = await getTotalData(token, params);
        const data = response.rs ? response.rs.data : response.data;

        setTotalData(data);
        response && setLoadingData(false);
      }
    } catch (error) {
      setTotalData([]);
    }
  };

  useEffect(() => {
    handleGetTotalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseTooltip = () => {
    setTooltipIsOpen((prev: boolean) => !prev);
  };

  return (
    <Card>
      <CardHeader
        classes={{
          title: classes.title,
        }}
        sx={{
          px: 2,
          py: 1,
          backgroundColor: "#02D0E7",
          color: "white",
          fontSize: "13px !important",
        }}
        title={
          <Box display="flex" justifyContent="space-between">
            {t("dialog.exportAs")}
            {":"}
            <i className="fa fa-times" onClick={handleCloseTooltip}></i>
          </Box>
        }
      />
      <Divider />
      <List
        sx={{
          bgcolor: "#F4F4F4",
        }}
        dense
        component="div"
        role="list"
      >
        {loadingData ? (
          <Box
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            width="100%"
          >
            <CircularProgress className={"colorLoading"} />
          </Box>
        ) : (
          <>
            {/* EXPORT ALL DATA PDF */}
            {dataTotal && (
              <BlobProvider
                document={
                  <MyPdf
                    data={dataTotal}
                    RenderComponent={pdfComponent}
                    rol={user?.roles.name as Roles_Enum}
                  />
                }
              >
                {({ url }) =>
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  loadingData ? (
                    <Box
                      display={"flex"}
                      justifyContent="center"
                      alignItems={"center"}
                      width="100%"
                      mt={3}
                    >
                      <CircularProgress className={"colorLoading"} />
                    </Box>
                  ) : (
                    url && (
                      <ListItem
                        role="listitem"
                        button
                        onClick={handleCloseTooltip}
                      >
                        <ListItemIcon sx={{ mr: -3, ml: -0.2 }}>
                          <img width={"19px"} src={fileText} alt="File text" />
                        </ListItemIcon>

                        <a
                          href={url}
                          style={{ color: "#333333" }}
                          className={classes.textItem}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("dialog.exportPdf")}
                        </a>
                      </ListItem>
                    )
                  )
                }
              </BlobProvider>
            )}

            {/* JUST EXPORT VIEW PDF */}
            {dataPerView && (
              <BlobProvider
                document={
                  <MyPdf
                    data={dataPerView}
                    RenderComponent={pdfComponent}
                    rol={user?.roles.name as Roles_Enum}
                  />
                }
              >
                {({ url }) =>
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  loadingData ? (
                    <Box
                      display={"flex"}
                      justifyContent="center"
                      alignItems={"center"}
                      width="100%"
                      mt={3}
                    >
                      <CircularProgress className={"colorLoading"} />
                    </Box>
                  ) : (
                    url && (
                      <ListItem
                        role="listitem"
                        button
                        onClick={handleCloseTooltip}
                      >
                        <ListItemIcon sx={{ mr: -3, ml: -0.2 }}>
                          <img width={"19px"} src={fileText} alt="File text" />
                        </ListItemIcon>
                        <a
                          href={url}
                          style={{ color: "#333333" }}
                          className={classes.textItem}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("dialog.exportViewPdf")}
                        </a>
                      </ListItem>
                    )
                  )
                }
              </BlobProvider>
            )}

            {!loadingData && (
              <>
                {multiTotalDataSet && (
                  <ExcelFile
                    element={
                      <ListItem
                        role="listitem"
                        button
                        onClick={handleCloseTooltip}
                      >
                        <ListItemIcon sx={{ mr: -3, ml: -0.2 }}>
                          <img width={"19px"} src={filePlus} alt="File plus" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ my: 0 }}
                          classes={{
                            primary: classes.textItem,
                          }}
                          primary={t("dialog.exportExcel")}
                        />
                      </ListItem>
                    }
                    filename={titleCSV}
                  >
                    <ExcelSheet
                      dataSet={multiTotalDataSet}
                      name={`${t("table.sheet")} 1`}
                    />
                  </ExcelFile>
                )}
                {multiDataSet && (
                  <ExcelFile
                    element={
                      <ListItem
                        role="listitem"
                        button
                        onClick={handleCloseTooltip}
                      >
                        <ListItemIcon sx={{ mr: -3, ml: -0.2 }}>
                          <img width={"19px"} src={filePlus} alt="File plus" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ my: 0 }}
                          classes={{
                            primary: classes.textItem,
                          }}
                          primary={t("dialog.exportViewExcel")}
                        />
                      </ListItem>
                    }
                    filename={titleCSV}
                  >
                    <ExcelSheet
                      dataSet={multiDataSet}
                      name={`${t("table.sheet")} 1`}
                    />
                  </ExcelFile>
                )}
              </>
            )}
          </>
        )}
      </List>
    </Card>
  );
};
