import React , {FC} from "react";
import {IExternalUsersData} from "../../../interfaces/commercialNetworks";
import ToggleGroup from "../../pages/Casino/ToggleGroup";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import { FormNetworkConfiguration } from "./FormNetworkConfiguration";

interface Props {
    network: IExternalUsersData;
    isFirst: boolean;
  }
export const NetworksList: FC<Props> = ({network, isFirst}) => {
  return (
    <ToggleGroup key={network.id} title={network.name} isFirst={isFirst}>
        <DragDropContext onDragEnd={(result) => console.log(result)}>
          <Droppable
            droppableId={`droppable-${network.id}`}
            direction="horizontal"
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="games-container"
                style={{display: "flex", flexWrap: "wrap"}}
              >
                <FormNetworkConfiguration network={network}/>
              </div>
            )}
          </Droppable>
        </DragDropContext>
    </ToggleGroup>
  );
};