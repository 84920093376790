import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { State } from "../../../../enums/enums";

export const PointSaleInformation = () => {
  const { t } = useTranslation();
  const { activePointSale } = useSelector(
    (state: AppState) => state.pointsSale
  );

  return (
    <>
      <Grid container spacing={2} sx={{ borderBottom: "1px solid #9E9E9E" }}>
        <Grid item xs={2.4}>
          <label>{t("shorcuts.Associates").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activePointSale?.associates.map((associate, index) => (
              <label key={index} className="no-overflow">
                {associate.associate
                  ? associate.associate.name.toUpperCase()
                  : ""}
                &nbsp;
                {activePointSale.associates &&
                  activePointSale.associates.length - 1 !== index && <br />}
              </label>
            ))}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <label>{t("table.name").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activePointSale?.name.toUpperCase()}
          </Box>
        </Grid>
        <Grid item xs={2.4}>
          <label>{t("user.address").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activePointSale?.address.toUpperCase()}
          </Box>
        </Grid>
        <Grid item xs={2.4}>
          <label>{t("user.country").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activePointSale?.country.name.toUpperCase()}
          </Box>
        </Grid>
        <Grid item xs={1.8}>
          <label>{t("companyManagement.department").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activePointSale?.state.names.toUpperCase()}
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        mt={1}
        sx={{ borderBottom: "1px solid #9E9E9E" }}
      >
        <Grid item xs={2.4}>
          <label>{t("user.city").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activePointSale?.city.names.toUpperCase()}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <label>{t("user.email").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activePointSale?.email.toUpperCase()}
          </Box>
        </Grid>
        <Grid item xs={2.4}>
          <label>{t("user.phone").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activePointSale?.phone}
          </Box>
        </Grid>

        <Grid item xs={2.4}>
          <label>{t("associates.transferMoney").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {t(
              activePointSale?.allowsTransfer === State.INACTIVE
                ? "table.not"
                : "table.yes"
            )}
          </Box>
        </Grid>
        <Grid item xs={2.4}>
          <label>{t("associates.refillBalance").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activePointSale?.walletPointSale?.amount ?? "0"}
          </Box>
        </Grid>
        <Grid item xs={2.4}>
          <label>{t("irrigation.bettingBalance").toUpperCase()}</label>
          <Box sx={{ fontWeight: 600 }} className="no-overflow">
            {activePointSale?.walletPointSale?.amountBets ?? "0"}
          </Box>
        </Grid>
      </Grid>
      {activePointSale?.allowsTransfer === 1 && (
        <Grid
          container
          spacing={2}
          mt={1}
          sx={{ borderBottom: "1px solid #9E9E9E" }}
        >
          <Grid item xs={2.4}>
            <label>
              {t("pointsOfSale.minimumTransferValue").toUpperCase()}
            </label>
            <Box sx={{ fontWeight: 600 }} className="no-overflow">
              {/* @ts-ignore */}
              {Intl.NumberFormat("es-MX").format(
                Number(activePointSale.minimumTransfers)
              )}
            </Box>
          </Grid>

          <Grid item xs={2.4}>
            <label>
              {t("pointsOfSale.maximumTransferValue").toUpperCase()}
            </label>
            <Box sx={{ fontWeight: 600 }} className="no-overflow">
              {/* @ts-ignore */}
              {Intl.NumberFormat("es-MX").format(
                Number(activePointSale.maximumTransfers)
              )}
            </Box>
          </Grid>
        </Grid>
      )}
      {(activePointSale?.allowAnonymousSale === State.ACTIVE ||
        activePointSale?.allowAnonymousPayouts === State.ACTIVE) && (
        <Grid
          container
          spacing={2}
          mt={1}
          sx={{ borderBottom: "1px solid #9E9E9E" }}
        >
          {activePointSale?.allowAnonymousSale === State.ACTIVE && (
            <Grid item xs={4} mb={3}>
              <label>
                {t("pointsOfSale.allowAnonymousSale").toUpperCase()}
              </label>
              <Box className="no-overflow">
                <label>
                  <b>
                    <b>
                      {t(
                        activePointSale?.allowAnonymousSale === State.ACTIVE
                          ? "table.yes"
                          : "table.not"
                      )}
                    </b>
                  </b>
                </label>
              </Box>
            </Grid>
          )}

          {activePointSale?.allowAnonymousPayouts === State.ACTIVE && (
            <Grid item xs={4} mb={3}>
              <label>
                {t("pointsOfSale.allowAnonymousPayouts").toUpperCase()}
              </label>
              <Box className="no-overflow">
                <label>
                  <b>
                    <b>
                      {t(
                        activePointSale?.allowAnonymousPayouts === State.ACTIVE
                          ? "table.yes"
                          : "table.not"
                      )}
                    </b>
                  </b>
                </label>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};
