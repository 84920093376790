import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { getBonusRedeemed } from "../../../../services/bonus";
import { IPaginationData } from "../../../../interfaces/shared";

import { useTranslation } from "react-i18next";

import { setRowsPerPage } from "../../../../redux/actions/helperActions";
import { MyPagination } from "../../../../components/Custom/Table/MyPagination";
import { IBonusRedeemed } from "../../../../interfaces/bonus";

export const BonusDeliverDetails = () => {
  const { token } = useSelector((state: AppState) => state.auth);
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);
  const { activeBonus } = useSelector((state: AppState) => state.bonus);
  const [bonusRedeemed, setBonusRedeemed] =
    useState<IPaginationData<IBonusRedeemed> | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    //fetch bonusRedeemed
    const fetchBonusRedeemed = async () => {
      if (token && activeBonus) {
        const params = {
          bonusId: activeBonus.id,
          page: 1,
          perPage: rowsPerPage,
        };
        const response = await getBonusRedeemed(token, params);
        setBonusRedeemed(response);
      }
    };
    fetchBonusRedeemed();

    return () => {
      dispatch(setRowsPerPage(9));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePageChange = async (page: number, rows?: number) => {
    setCurrentPage(page);

    if (token && activeBonus) {
      const params = {
        page: page,
        perPage: rows || rowsPerPage,
        bonusId: activeBonus.id,
      };
      const rs = await getBonusRedeemed(token, params);
      setBonusRedeemed(rs);
    } else {
      throw new Error("INVALID ACTION");
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} bgcolor={"#F4F4F4"}>
          <Grid container spacing={2}>
            {[
              "ID " + t("sidebar.player").toUpperCase(),
              t("managementBonds.documentPlayer").toUpperCase(),
              t("managementBonds.playerUser").toUpperCase(),
              t("managementBonds.bondValue").toUpperCase(),
            ].map((col) => (
              <Grid item xs={3} key={col}>
                <Typography variant="body1" fontWeight={"bold"} color="#333">
                  {col.toUpperCase()}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {bonusRedeemed?.data.map((player) => (
            <Grid
              container
              spacing={2}
              bgcolor={"#02D0E71A"}
              mt={1}
              key={player.id}
            >
              <Grid item xs={3}>
                <Typography variant="body1" color="#333">
                  {player.player.id}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1" color="#333">
                  {player.player.numberDocument}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1" color="#333">
                  {player.player.username || ""}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1" color="#333">
                  {player.value || ""}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} display={"flex"} justifyContent={"center"}>
          {bonusRedeemed ? (
            <MyPagination
              onPageChange={handlePageChange}
              currentPage={currentPage}
              lastPage={bonusRedeemed.last_page || 1}
              siblingCount={1}
              total={bonusRedeemed.total || 0}
            />
          ) : (
            <Box
              display={"flex"}
              justifyContent="center"
              alignItems={"center"}
              width="100%"
              mt={3}
            >
              <CircularProgress className={"colorLoading"} />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};
