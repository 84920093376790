import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { headersManagementBonus } from "../../../helpers/headersTables";
import { getCopyArrayOrObject } from "../../../utils/utils";
import { ProvidersBonus, State } from "../../../enums/enums";
import { useTranslation } from "react-i18next";
import moment from "moment";

export const ManagementBonusDataTable = (totalData: any[]) => {
  const { bonus: data } = useSelector((state: AppState) => state.bonus);

  const { t } = useTranslation();

  const headersCvsStyle = headersManagementBonus.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });
  const dataBonus = data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalBonus = totalData ? getCopyArrayOrObject(totalData) : [];

  const buildTotalData = (arr: any[]) => {
    const multiData = [
      {
        columns: headersCvsStyle,
        data: arr.map((data) => {
          const array = [
            {
              value: data.name,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data?.provider.name,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value:
                data?.provider.id === ProvidersBonus.PRAGMATIC
                  ? data?.pragmaticBonusCode ?? ""
                  : data?.code ?? "",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value:
                data?.state === State.ACTIVE
                  ? t("state.active").toUpperCase()
                  : t("state.inactive").toUpperCase(),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: moment(data?.startDate).format("YYYY-MM-DD hh:mm:ss A"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: moment(data?.endDate).format("YYYY-MM-DD hh:mm:ss A"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value:
                data?.provider.id === ProvidersBonus.ALTENAR
                  ? data.value
                    ? `$ ${data?.value}`
                    : ""
                  : data?.totalValue
                  ? `$ ${data.totalValue}`
                  : "",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data?.amountBonus ?? "",
              style: { alignment: { horizontal: "center" } },
            },
          ];
          return array;
        }),
      },
    ];
    return multiData;
  };

  const multiTotalData = buildTotalData(dataTotalBonus);
  const multiData = buildTotalData(dataBonus);
  const finalData = {
    dataPerView: dataBonus,
    dataTotal: dataTotalBonus,
    headersCSV: headersCvsStyle,
    multiDataSet: multiData,
    multiTotalDataSet: multiTotalData,
  };
  return finalData;
};
