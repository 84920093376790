import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TabPanel } from "../../../components/Tabs";
import { CardsTab } from "./CardsTab";
import { DealerWallet } from "./DealerWallet";
import { TotalWallet } from "./TotalWallet";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Roles_Enum, WhiteListUserCard } from "../../../enums/enums";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const styles = {
  "&.Mui-selected": {
    color: "#55BCD9",
    border: "none",
    outline: "none",
  },
};
export const TabsManagementCards = () => {
  const { user } = useSelector((state: AppState) => state.auth);
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const { routes: RoutesRedux } = useSelector(
    (state: AppState) => state.managementMenus
  );
  const { pathname } = useLocation();

  const modulePermissions = RoutesRedux.find(
    (route) => !!route.menuItem.find((module) => module.url === pathname)
  )?.menuItem.find((module) => module.url === pathname);
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;
  const enableRead =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.readed === 1;

  const enableWalletDealer =
    [Roles_Enum.ADMIN_OPERATOR].includes(user?.roles.name as Roles_Enum) &&
    enableRead;

  const isJerRol = user && user.roles.name.toUpperCase() === Roles_Enum.JER;

  const showAllTabs =
    enableCreated && WhiteListUserCard.includes(user?.username as any);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        sx={{
          marginLeft: "20px",
        }}
        value={value}
        onChange={handleChange}
        style={{ border: "none", outline: "none" }}
        variant="standard"
        TabIndicatorProps={{
          style: {
            backgroundColor: " #02D0E7",
            height: 3,
          },
        }}
      >
        {showAllTabs && (
          <Tab
            sx={styles}
            label={<span className="spanTab">{t("cards.title")}</span>}
            {...a11yProps(0)}
          />
        )}
        {enableWalletDealer && (
          <Tab
            sx={styles}
            label={<span className="spanTab">{t("cards.walletDealer")}</span>}
            {...a11yProps(1)}
          />
        )}
        {!isJerRol && enableRead && (
          <Tab
            sx={styles}
            label={<span className="spanTab">{t("cards.totalWaller")}</span>}
            {...a11yProps(2)}
          />
        )}
      </Tabs>
      <div className="white-container">
        {showAllTabs && (
          <TabPanel value={value} index={0}>
            <CardsTab />
          </TabPanel>
        )}
        {enableWalletDealer && (
          <TabPanel value={value} index={!showAllTabs ? 0 : 1}>
            <DealerWallet />
          </TabPanel>
        )}
        {!isJerRol && enableRead && (
          <TabPanel
            value={value}
            index={
              !showAllTabs && !enableWalletDealer
                ? 0
                : enableWalletDealer && !showAllTabs
                ? 1
                : 2
            }
          >
            <TotalWallet />
          </TabPanel>
        )}
      </div>
    </>
  );
};
