import { FC } from "react";

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

interface Props {
  content: any;
  openModal: boolean;
  title: string;
  handleClose: () => void;
}

export const DialogParamSettings: FC<Props> = ({
  content,
  openModal,
  title,
  handleClose,
}) => {
  return (
    <Dialog
      open={openModal}
      data-testid="dialogSettings"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 0,
        }}
      >
        <Box display="flex">
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <p className="txt-primary-md" style={{ fontWeight: 500 }}>
            {title}
          </p>
        </Box>
        <IconButton
          disableFocusRipple
          disableRipple
          data-testid="btnCloseDialogParamSettings"
          className="hover-close"
          style={{
            border: "none",
            outline: "none",
          }}
          onClick={handleClose}
        >
          <i className="fa-solid fa-xmark"></i>
        </IconButton>
      </DialogTitle>
      <Box borderTop={1} borderColor="#02D0E7" m={2}>
        <DialogContent>{content}</DialogContent>
      </Box>
    </Dialog>
  );
};
