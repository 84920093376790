import { FC } from "react";
import TableCell from "@mui/material/TableCell";
import "../tabsGamingActivity.scss";

import { ICasino } from "../../../../../interfaces/casino";
import moment from "moment";
import { useTranslation } from "react-i18next";
interface Props {
  row: ICasino;
}

export const RowDataTableCasino: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  const lowerCaseState = row.state.toLowerCase();
  const statesWin = ["w", "cb", "jw"];
  const betWon =
    statesWin.includes(lowerCaseState) || parseFloat(row.amountWin) > 0;

  const cancelTransaction = lowerCaseState.includes("ct");
  const statusBonus = ["db", "bw", "cb"];
  const bonus = statusBonus.includes(lowerCaseState);

  const statusClass = cancelTransaction ? "loss" : betWon ? "open" : "loss";
  const statusText = cancelTransaction
    ? t("bets.cancelTransaction")
    : betWon
    ? t("bets.won")
    : t("bets.lost");
  return (
    <>
      <TableCell className="word-break">
        {moment(row.createAt).format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
      <TableCell className="word-break">{row.id}</TableCell>
      <TableCell className="word-break">{row.eventDescription}</TableCell>
      <TableCell className="word-break">{row.event}</TableCell>
      <TableCell className="word-break">
        {row.betAmount ? parseFloat(row.betAmount).toFixed(2) : "0.00"}
      </TableCell>
      <TableCell className="word-break">
        {row.amountWin ? parseFloat(row.amountWin).toFixed(2) : "0.00"}
      </TableCell>
      <TableCell className="word-break">
        <div className={statusClass}>{statusText}</div>
      </TableCell>
      <TableCell className="word-break">
        {bonus ? t("table.yes") : t("table.not")}
      </TableCell>
      <TableCell className="word-break">{row.provider}</TableCell>
      <TableCell className="word-break">{row.platform}</TableCell>
    </>
  );
};
