import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { theme } from "../../custom/customStyles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { ISeller } from "../../../../interfaces/pointsSale";
import { getAvailableSellersService } from "../../../../services/helperService";
import { IUserData } from "../../../../redux/interfaces/users";
import { setSellersRedux } from "../../../../redux/actions/helperActions";
interface props {
  setSellers: any;
  sellers: ISeller[];
  handleAddSeller: () => void;
  handleDeleteSeller: (i: number) => void;
  operator: number;
}
export const FormConfigSellers: FC<props> = ({
  handleAddSeller,
  handleDeleteSeller,
  setSellers,
  sellers,
  operator,
}) => {
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { activePointSale } = useSelector(
    (state: AppState) => state.pointsSale
  );
  const [expandCurrentIcon, setExpandCurrentIcon] = useState(
    sellers.map(() => true)
  );
  const dispatch = useDispatch();

  const [sellerList, setSellerList] = useState<IUserData[]>([]);
  const { t } = useTranslation();

  const getSellers = async () => {
    if (!user || !token) {
      return null;
    }

    const response = await getAvailableSellersService(token, operator);
    return response;
  };
  useEffect(() => {
    //consultar los vendedores
    getSellers().then((res) => {
      const currentSellers = [...res];
      if (activePointSale) {
        const sellersPointCurrent = (
          activePointSale as any
        ).sellerPointSales.map((item: any) => ({ ...item.user }));
        currentSellers.push(...sellersPointCurrent);
      }
      setSellerList(currentSellers);
      dispatch(setSellersRedux(currentSellers));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mt-3">
      <ThemeProvider theme={theme}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              display="flex"
              className="pb-1"
              borderBottom={1}
              borderColor="#02D0E7"
            >
              <i
                className="fa-solid fa-circle-info"
                style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
              ></i>
              <p className="txt-primary-md">
                {t(
                  activePointSale
                    ? "pointsOfSale.editSellers"
                    : "pointsOfSale.addSellers"
                )}
              </p>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Box display="flex" alignItems="center">
              <p
                className="txt-primary-md"
                style={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  alignSelf: "center",
                }}
              >
                {t("pointsOfSale.addSeller")}
              </p>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  handleAddSeller();
                  setExpandCurrentIcon((prevState: any) => [
                    ...prevState,
                    true,
                  ]);
                }}
                style={{ border: "none", outline: "none" }}
              >
                <span className="btn-add">
                  <i className="fa-solid fa-plus" style={{ fontSize: 30 }}></i>
                </span>
              </IconButton>
            </Box>
          </Box>
        </Grid>
        {sellers.map((seller, index) => (
          <Grid key={index} container spacing={1} mt={1} ml={3}>
            <Grid item xs={6}>
              <label className="label-text">
                {t("pointsOfSale.selectSeller")}*
              </label>
              <TextField
                name="user"
                variant="outlined"
                size="small"
                type="text"
                InputLabelProps={{ shrink: false }}
                label={" "}
                fullWidth
                autoComplete="off"
                onChange={(e) => setSellers(e, index)}
                value={seller.user || ""}
                select
                defaultValue={""}
              >
                <MenuItem disabled value=""></MenuItem>
                {sellerList.length > 0 &&
                  sellerList
                    .filter((item) => {
                      if (seller.user === item.id) {
                        return true;
                      } else {
                        return !sellers.some(
                          (s, i) => i !== index && s.user === item.id
                        );
                      }
                    })
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.nameComplete}
                      </MenuItem>
                    ))}
              </TextField>
            </Grid>
            <Grid item xs={0.5} display="flex" mt={2} justifyContent="center">
              <i
                className={"fa fa-trash fa-1x iconDelete"}
                style={{ fontSize: 20 }}
                onClick={() => {
                  handleDeleteSeller(index);
                  setExpandCurrentIcon(
                    expandCurrentIcon.filter(
                      (state: boolean, i: number) => i !== index
                    )
                  );
                }}
              />
            </Grid>
          </Grid>
        ))}
      </ThemeProvider>
    </div>
  );
};
