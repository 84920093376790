import React, { useState } from "react";
import Filter from "../../../../../components/Filter/Filter";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../../interfaces/components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { arraySearch } from "../../../../../redux/actions/searchActions";
import MyCustomTable, {
  IColumns,
} from "../../../../../components/Custom/Table/MyCustomTable";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import { CustomAlert } from "../../../../../components/Alerts/CustomAlert";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { RowCashFlowDeposit } from "./RowCashFlowDeposit";
import { startGetCashFlowDeposit } from "../../../../../redux/actions/helperActions";
import { CashFlowDepositDataTable } from "../../../../../hooks/useDataTable/reports/CashFlowDepositDataTable";
import { getCashFlowDeposit } from "../../../../../services/cashFlow";
import { CashFlowDepositPdf } from "../../../../../components/Pdf/Reports/CashFlowDepositPdf";

export const CashFlowDeposits = () => {
  const [dataFilter, setDataFilter] = useState({});
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { data } = useSelector((state: AppState) => state.search);
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
  ];
  const columns: IColumns[] = [
    {
      align: "center",
      label: t("reports.playerId"),
    },
    {
      align: "center",
      label: t("reports.transactionValue"),
    },
    {
      align: "center",
      label: t("reports.gateway"),
    },
    {
      align: "center",
      label: t("reports.playerName"),
    },
    {
      align: "center",
      label: t("table.email"),
    },
    {
      align: "center",
      label: t("transactions.createAt"),
    },
    {
      align: "center",
      label: t("withdrawals.externalId"),
    },
  ];
  const handleFilter = async (formValues: any) => {
    const startDate = formValues[t("filters.startDate")];
    const endDate = formValues[t("filters.endDate")];

    const params = {
      startDate,
      endDate,
    };

    await dispatch(
      startGetCashFlowDeposit({
        ...params,

        page: 1,
      })
    );

    setDataFilter(params);
  };
  const onCleanFilter = async () => {
    dispatch(arraySearch(null));
    setDataFilter({});
  };
  const handlePageChange = async (page: number, rows?: number) => {
    await dispatch(
      startGetCashFlowDeposit({
        ...dataFilter,
        page,
        perPage: rows || rowsPerPage,
      })
    );
  };
  const getAllCashFlow = async (_: any) => {
    if (user && token) {
      const params: any = {
        ...dataFilter,
      };
      if (user.operator) {
        params.operator = user.operator.id;
      }
      return await getCashFlowDeposit(token, params);
    }
  };
  return (
    <>
      <div
        className="filter"
        style={{ padding: "18px 0px 0px", marginBottom: "18px" }}
      >
        <Filter
          fields={filterFields}
          handleFilter={handleFilter}
          onClean={onCleanFilter}
        />
      </div>

      {data ? (
        <div className="filter">
          <Grid item xs={12}>
            <MyCustomTable
              columns={columns}
              data={data.data}
              lastPage={data.last_page}
              totalData={data.total}
              renderBody={(item) => <RowCashFlowDeposit row={item} />}
              onPageChange={handlePageChange}
              exportData
              hookBuildTable={CashFlowDepositDataTable}
              getAllDataToExport={getAllCashFlow}
              pdfComponent={CashFlowDepositPdf}
              titleCSV={`${t("reports.cashFlowDeposits")
                .split(" ")
                .join("-")}-${moment().toISOString()}`}
            >
              <Grid container bgcolor={"#F4F4F4"} p={1}>
                <Grid item xs={5} />

                <Grid
                  item
                  xs={1}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography
                    variant="body2"
                    fontWeight={"bold"}
                    color={"#000"}
                  >
                    TOTAL
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography
                    variant="body2"
                    color={"#208E9A"}
                    fontWeight={"bold"}
                  >
                    {`$ ${parseFloat(data.totalAmount).toLocaleString(
                      "es-ES"
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </MyCustomTable>
          </Grid>
        </div>
      ) : (
        <CustomAlert htmlText={t("cashflow.filterTextByDeposit")} type="info" />
      )}
    </>
  );
};
