import React, { FC, useEffect, Dispatch, SetStateAction } from "react";

import { Box } from "@mui/material";

import { StateTransaction } from "../../../../enums/enums";
import { useDispatch } from "react-redux";
import { setChangeTab } from "../../../../redux/actions/helperActions";
import { TableWithdrawals } from "./TableWithdrawals";

interface Props {
  component: StateTransaction;

  dataFilter: any;
  setResetPage: Dispatch<SetStateAction<boolean>>;
  resetPage: boolean;
}

export const TabsByBalanceWithdrawals: FC<Props> = ({
  component,
  dataFilter,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setChangeTab(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TableWithdrawals
          component={component}
          dataFilter={dataFilter}
          isCash={false}
        />
      </Box>
    </Box>
  );
};
