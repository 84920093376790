import React, { FC } from "react";
import { headersIrrigationCardsInventory } from "../../../../helpers/headersTables";
import { Text, View } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../views/components/custom/customStyles";
interface props {
  page: any[];
}
export const IrrigationCardsInventoryPdf: FC<props> = ({ page }) => {
  const columns = headersIrrigationCardsInventory;

  const cardsNotAvailable = (data: any) => {
    const res =
      parseInt(data.redeemedCards as string) +
      parseInt(data.inactiveCards as string);
    return res;
  };

  const buildAvailableRanges = (availableRanges: any) => {
    const res = availableRanges.map(
      (range: any, index: any) =>
        `${range.minCardId} - ${range.maxCardId}` +
        (index < availableRanges.length - 1 ? "\n" : "")
    );
    return res.join(" ");
  };
  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, index) => (
          <View key={index} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>

      <View style={{ flex: 1 }}>
        {page.map((data, index) => (
          <View key={index} style={stylesPdf.row}>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.lotId}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.value}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {buildAvailableRanges(data.availableRanges)}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.redeemedCards}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.inactiveCards}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {cardsNotAvailable(data)}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.activeCards}</Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};
