import { AppState } from "../reducers/rootReducer";
import {
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";
import { arraySearch } from "./searchActions";
import { Types } from "../types/types";
import {
  IIpBlacklist,
  IIpBlacklistData,
  IObjectInactivePlayers,
} from "../../interfaces/ipBlacklist";
import {
  addIpBlacklist,
  deleteIpBlacklist,
  getIpBlacklist,
  inactivePlayersIp,
  updateIpBlacklist,
} from "../../services/ipAddresses";

export const startAddIpBlacklist = (data: IIpBlacklist) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    try {
      if (user && token) {
        const response = await addIpBlacklist(data, token);
        if (response && !response.error) {
          await dispatch(startGetBlacklist());
          await dispatch(arraySearch(null));
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("ipAddresses.ipBlacklisted"));
          await dispatch(setTypeAlert("success"));
          dispatch(setFlagForm({ flagFormCreate: false }));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert(`alerts.${response.error.errorMessage}`));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const startGetBlacklist = (params?: any, exportExcel?: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      const response = await getIpBlacklist(token, params);
      if (response) {
        if (exportExcel) {
          return response;
        } else {
          dispatch(setBlacklistRedux(response));
        }
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startDeleteIp = (id: number, perPage?: number, page?: number) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token, user } = (getState() as AppState).auth;
      if (!token || !user) {
        throw new Error("INVALID ACTION");
      }
      const response = await deleteIpBlacklist(id, token, user.id);
      if (response) {
        await dispatch(startGetBlacklist({ perPage, page }));
        await dispatch(setActiveIp(null));
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("ipAddresses.ipRemoved"));
        dispatch(setTypeAlert("success"));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startUpdateIp = (data: IIpBlacklist, id: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    try {
      if (user && token) {
        const response = await updateIpBlacklist(data, token, id);
        if (response && !response.error) {
          await dispatch(startGetBlacklist());
          await dispatch(arraySearch(null));
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("ipAddresses.ipUpdated"));
          await dispatch(setTypeAlert("success"));
          dispatch(setFlagForm({ flagFormCreate: false }));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert(response.error.errorMessage));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const startUpdatePlayersIp = (data: IObjectInactivePlayers) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    try {
      if (user && token) {
        const response = await inactivePlayersIp(data, token);
        if (response && !response.error) {
          await dispatch(startGetBlacklist());
          await dispatch(arraySearch(null));
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("ipAddresses.ipUpdated"));
          await dispatch(setTypeAlert("success"));
          dispatch(setFlagForm({ flagFormCreate: false }));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert(response.error.errorMessage));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const setBlacklistRedux = (blacklist: IIpBlacklistData) => ({
  type: Types.setBlacklist,
  payload: blacklist,
});

export const setActiveIp = (ip: IIpBlacklist | null) => ({
  type: Types.setActiveIp,
  payload: ip,
});
