import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Filter from "../../../../components/Filter/Filter";
import { Enum_FieldType, IFilterField } from "../../../../interfaces/components";
import { setMovementTypes } from "../../../../redux/actions/transactionsActions";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  asyncLoadingFunction,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import { arraySearch } from "../../../../redux/actions/searchActions";
import { CustomAlert } from "../../../../components/Alerts/CustomAlert";
import { TransactionTypesDB } from "../../../../enums/enums";
import { startGetExternalUsers } from "../../../../redux/actions/commercialNetworkActions";
import { IExternalUsersData } from "../../../../interfaces/commercialNetworks";
import { startGetActivityPlayer } from "../../../../redux/actions/activityPlayerActions";
import { IActivityQuery } from "../../../../interfaces/activityPlayer";
import MyCustomTable, {
  IColumns,
} from "../../../../components/Custom/Table/MyCustomTable";
import { RowDataTableCommercialReport } from "./RowDataTableCommercialReport";
import { CommercialNetworksReportDataTable } from "../../../../hooks/useDataTable/commercialNetworks/CommercialNetworksReportDataTable";
import { getActivityPlayer } from "../../../../services/activityPlayer";
import { CommercialNetworksReportPdf } from "../../../../components/Pdf/commercialNetworks/CommercialNetworksReportPdf";

export const CommercialNetworksReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { networks } = useSelector((state: AppState) => state.commercialNetworks);
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);
  const { data } = useSelector((state: AppState) => state.search);
  const [dataFilter, setDataFilter] = useState<any>({});
  const [resetPage, setResetPage] = useState(false);
  const [externalUsers, setExternalUsers] = useState<Array<IExternalUsersData>>(
    []
  );

  const transactionTypesList = [
    {
      label: t("withdrawals.cashWithdrawal"),
      value: TransactionTypesDB.WITHDRAWAL_CASH,
    },
    {
      label: t("withdrawals.balanceRecharge"),
      value: TransactionTypesDB.RECHARGE,
    },
  ];

  useEffect(() => {
    dispatch(
      asyncLoadingFunction(() =>
        dispatch(startGetExternalUsers({ operatorId: user?.operator?.id }))
      )
    );
    return () => {
      dispatch(setMovementTypes([]));
      dispatch(arraySearch(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (networks?.data) {
      setExternalUsers(networks?.data);
    }
  }, [networks]);

  const filterFields: IFilterField[] = [
    {
      label: t("commercialNetworks.commercialNetwork"),
      type: Enum_FieldType.SELECT,
      options: [
        ...externalUsers.map((network) => ({
          label: network.name,
          value: network.id,
        })),
      ],
    },
    {
      label: t("transactions.transactionType"),
      type: Enum_FieldType.SELECT,
      options: [...transactionTypesList],
    },
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    }
  ];

  const columns: IColumns[] = [
    { align: "left", label: t("form.network") },
    { align: "left", label: t("transactions.id") },
    { align: "left", label: t("commercialNetworks.reference") },
    { align: "left", label: t("playerActivity.activityType") },
    { align: "left", label: t("commercialNetworks.transactionStatus") },
    { align: "left", label: t("reports.playerId") },
    { align: "left", label: t("user.documentNumber") },
    { align: "left", label: t("form.value") },
    { align: "left", label: t("commercialNetworks.codePoint") },
    { align: "left", label: t("commercialNetworks.province") },
    { align: "left", label: t("commercialNetworks.canton") },
    { align: "left", label: t("commercialNetworks.dateUpdated") },
  ];

  const handleFilter = async (formValues: any) => {
    setResetPage(false);
    const startDate = formValues[t("filters.startDate")];
    const endDate = formValues[t("filters.endDate")];
    const network = formValues[t("commercialNetworks.commercialNetwork")];
    const transactionType = formValues[t("transactions.transactionType")];
    if (!network) {
      dispatch(setMessageAlert("commercialNetworks.selectCommercialNetwork"));
      dispatch(setTypeAlert("warning"));
      return dispatch(setOpenAlert());
    }
    const params: Partial<IActivityQuery> = {
      startDate,
      endDate,
      transactionType,
      network,
      page: 1,
    };
    dispatch(
      asyncLoadingFunction(() => dispatch(startGetActivityPlayer(params)))
    );

    setDataFilter(params);
    setResetPage(true);
  };

  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(
        async () =>
          await dispatch(
            startGetActivityPlayer({
              ...dataFilter,
              page,
              perPage: rows || rowsPerPage,
            })
          )
      )
    );
    setResetPage(false);
  };
  const onCleanFilter = async () => {
    dispatch(arraySearch(null));
    setDataFilter({});
  };

  const getTransactionsAction = async () => {
    if (token) {
      delete dataFilter.page;
      delete dataFilter.perPage;
      return await getActivityPlayer(token, {...dataFilter});
    }
  };

  return (
    <>
      <div
        className="filter"
        style={{ padding: "18px 0px 0px", marginBottom: "18px" }}
      >
        <Filter
          fields={filterFields}
          handleFilter={handleFilter}
          onClean={onCleanFilter}
        />
      </div>
      {data ? (
        <div className="filter">
          <MyCustomTable
            columns={columns}
            data={data.data}
            lastPage={data.last_page}
            totalData={data.total}
            onPageChange={handlePageChange}
            renderBody={(row) => <RowDataTableCommercialReport row={row} />}
            resetCurrentPage={resetPage}
            exportData
            hookBuildTable={CommercialNetworksReportDataTable}
            getAllDataToExport={getTransactionsAction}
            pdfComponent={CommercialNetworksReportPdf}
            titleCSV={t("reports.commercialNetworksReport")}
          />
        </div>
      ) : (
        <CustomAlert
          htmlText={t("commercialNetworks.filterText")}
          type="info"
        />
      )}
    </>
  );
};
