import { FC } from "react";
import { ICasino } from "../../../interfaces/casino";
import { headersGamingActivityCasino } from "../../../helpers/headersTables";
import { Text, View } from "@react-pdf/renderer";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface props {
  page: ICasino[];
}
export const GamingActivityCasinoPdf: FC<props> = ({ page }) => {
  const { t } = useTranslation();
  const columns = headersGamingActivityCasino;
  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, index) => (
          <View key={index} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>

      <View style={{ flex: 1 }}>
        {page.map((data) => {
          const lowerCaseState = data.state.toLowerCase();
          const statesWin = ["w", "cb", "jw"];
          const betWon =
            statesWin.includes(lowerCaseState) ||
            parseFloat(data.amountWin) > 0;

          const cancelTransaction = lowerCaseState.includes("ct");
          const statusBonus = ["db", "bw", "cb"];
          const bonus = statusBonus.includes(lowerCaseState);

          const statusClass = cancelTransaction
            ? "loss"
            : betWon
            ? "open"
            : "loss";
          const statusText = cancelTransaction
            ? t("bets.cancelTransaction")
            : betWon
            ? t("bets.won")
            : t("bets.lost");
          return (
            <View key={data.id} style={stylesPdf.row}>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {moment(data.createAt).format("YYYY-MM-DD HH:mm:ss")}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.id}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {data.eventDescription}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.event}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  ${" "}
                  {data.betAmount
                    ? parseFloat(data.betAmount).toFixed(2)
                    : "0.00"}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  ${" "}
                  {data.amountWin
                    ? parseFloat(data.amountWin).toFixed(2)
                    : "0.00"}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  <div className={statusClass}>{statusText}</div>
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {bonus ? t("table.yes") : t("table.not")}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.provider}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.platform}</Text>
              </View>
            </View>
          );
        })}
      </View>
    </>
  );
};
