import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncLoadingFunction,
  setCloseAlert,
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { Enum_FieldType, IFilterField } from "../../../interfaces/components";
import Filter from "../../../components/Filter/Filter";
import { CustomAlert } from "../../../components/Alerts/CustomAlert";
import { AppState } from "../../../redux/reducers/rootReducer";
import { FormDealers } from "../../components/managementDealers/FormDealers";
import MyCustomTable, {
  IColumns,
} from "../../../components/Custom/Table/MyCustomTable";
import { RowDealers } from "../../components/managementDealers/RowDealers";
import {
  setDealers,
  startGetDelaers,
} from "../../../redux/actions/managementDealersActions";
import { MyAlert } from "../../components/custom/MyAlert";
import { DealersDataTable } from "../../../hooks/useDataTable/dealers";
import { DealersPdf } from "../../../components/Pdf/dealers/DealersPdf";
import { getDealers } from "../../../services/dealers";
import { useLocation } from "react-router-dom";

export const ManagementDealers = () => {
  const [dataFilter, setDataFilter] = useState<any>({});
  const { dealers } = useSelector((state: AppState) => state.managementDealers);

  const { flagFormCreate, openAlert, messageAlert, typeAlert, rowsPerPage } =
    useSelector((state: AppState) => state.helper);
  const { token, user } = useSelector((state: AppState) => state.auth);
  const [isFiltering, setIsFiltering] = useState(false);
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { pathname } = useLocation();

  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const FilterFileds: IFilterField[] = [
    {
      label: t("managementDealers.dealerName"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("managementDealers.dealerEmail"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("managementDealers.dealerPhone"),
      type: Enum_FieldType.NUMBER,
    },
  ];
  const columns: IColumns[] = [
    {
      align: "center",
      label: t("managementDealers.idDealer"),
    },
    {
      align: "center",
      label: t("managementDealers.dealerName"),
    },
    {
      align: "center",
      label: t("managementDealers.dealerPhone"),
    },
    {
      align: "center",
      label: t("managementDealers.dealerEmail"),
    },
    {
      align: "center",
      label: t("managementDealers.comissionPercentage"),
    },
    {
      align: "center",
      label: t("table.state"),
    },
    {
      align: "center",
      label: t("table.actions"),
    },
  ];
  useEffect(() => {
    if (dealers && dealers.data.length === 0) {
      dispatch(
        setMessageAlert(isFiltering ? "alerts.noData" : "alerts.noRecords")
      );
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealers]);

  const handleOpenForm = () => {
    dispatch(setFlagForm({ flagFormCreate: true }));
  };
  const handleFilter = async (values: any) => {
    const params = {
      name: values[t("managementDealers.dealerName")],
      email: values[t("managementDealers.dealerEmail")],
      phone: values[t("managementDealers.dealerPhone")],
    };

    await dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startGetDelaers({
            ...params,
            page: 1,
          })
        )
      )
    );
    setDataFilter({ ...params });
    setIsFiltering(true);
  };
  const onCleanFilter = async () => {
    dispatch(setDealers(null));
    setDataFilter({});
    setIsFiltering(false);
  };
  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(
        async () =>
          await dispatch(
            startGetDelaers({
              ...dataFilter,
              page,
              perPage: rows || rowsPerPage,
            })
          )
      )
    );
  };
  const onCloseAlert = () => {
    dispatch(setCloseAlert());
  };
  const getTotalDealers = async () => {
    if (user && token) {
      const params = {
        operator: user.operator.id,
        //playerId: (player as any).data[0].id,
      };
      return await getDealers(params, token);
    }
  };

  return (
    <div className="content w-100 mb-5">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyBreadCrumbs
            titles={[t("sidebar.settings"), t("managementDealers.title")]}
          />
        </Grid>

        <div className="white-container w-100">
          {flagFormCreate ? (
            <FormDealers />
          ) : (
            <>
              {enableCreated && (
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    mb={2}
                  >
                    <Box display="flex" alignItems="center">
                      <p
                        className="txt-primary-md"
                        style={{
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          alignSelf: "center",
                        }}
                      >
                        {t("managementDealers.createDealer")}
                      </p>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={handleOpenForm}
                        id="openFormBtn"
                        style={{ border: "none", outline: "none" }}
                      >
                        <span className="btn-add">
                          <i
                            className="fa-solid fa-plus"
                            style={{ fontSize: 30 }}
                          ></i>
                        </span>
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Filter
                  fields={FilterFileds}
                  handleFilter={handleFilter}
                  onClean={onCleanFilter}
                />
              </Grid>

              <Grid item xs={12}>
                {(!dealers || dealers.total === 0) && (
                  <CustomAlert
                    htmlText={t("managementDealers.filterText")}
                    type="info"
                  />
                )}
                {dealers && dealers.total > 0 && (
                  <MyCustomTable
                    columns={columns}
                    data={dealers.data}
                    lastPage={dealers.last_page}
                    totalData={dealers.total}
                    onPageChange={handlePageChange}
                    renderBody={(item) => <RowDealers row={item} />}
                    exportData
                    getAllDataToExport={getTotalDealers}
                    hookBuildTable={DealersDataTable}
                    pdfComponent={DealersPdf}
                    titleCSV={
                      "dealers" + user && user?.operator
                        ? user?.operator.name
                        : "" + new Date().toISOString()
                    }
                  />
                )}
              </Grid>
            </>
          )}
        </div>
      </Grid>
      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />
    </div>
  );
};
