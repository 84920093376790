import {
  getAffiliates,
  getPointSale,
  updateCommission,
  updatePointSale,
} from "../../services/affiliates";
import { getOperatorByIdService } from "../../services/managementCompanyService";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import {
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";
import { setActiveCompany } from "./managementCompanyActions";
import { arraySearch } from "./searchActions";

export const startGetAffiliates = (params?: any, pointSale?: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      const { rowsPerPage } = (getState() as AppState).helper;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      if (params.page) {
        params.perPage = rowsPerPage;
      }
      if (pointSale) {
        params.isSeller = true;
      }
      const response = await getAffiliates(token, params);
      if (response) {
        dispatch(setAffiliates(response));
      }
      if (response && !response.error) {
        return dispatch(arraySearch(response));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startUpdateCommission = (data: any) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { user, token } = (getState() as AppState).auth;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      const operatorId = user?.operator ? user.operator.id : null;
      const response: any = await updateCommission(
        token,
        Number(operatorId),
        data
      );
      if (response) {
        const operator =
          operatorId && (await getOperatorByIdService(token, operatorId));
        dispatch(setActiveCompany(operator));
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("Operador editado correctamente"));
        dispatch(setTypeAlert("success"));
        dispatch(setFlagForm({ flagFormCreate: false }));
      }

      if (response && !response.error) {
        return dispatch(arraySearch(response));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startActiveOrInactiveModule = (item: any) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { user, token } = (getState() as AppState).auth;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      const playerIds: number[] = item?.walletPointSale?.players?.map(
        (player: any) => player?.id
      );
      const data = {
        isActive:
          item?.walletPointSale &&
          item?.walletPointSale?.players[0]?.affiliationCode
            ? false
            : true,
        players: playerIds,
      };
      const response: any = await updatePointSale(token, data);
      if (response) {
        const params = {
          page: 1,
          operatorId: user?.operator?.id,
        };
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("Punto de venta actualizado correctamente"));
        dispatch(setTypeAlert("success"));
        await dispatch(startGetPointSales(params));
      }

      if (response && !response.error) {
        return dispatch(arraySearch(response));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startGetPointSales = (params?: any) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      const { rowsPerPage } = (getState() as AppState).helper;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      if (params.page) {
        params.perPage = rowsPerPage;
      }
      const response = await getPointSale(token, params);
      if (response) {
        dispatch(setPointsSale(response));
      }
      if (response && !response.error) {
        return dispatch(arraySearch(response));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const exportAffiliatesToExcel = (params?: any, pointSale?: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      if (pointSale) {
        params.isSeller = true;
      }
      const response = await getAffiliates(token, params);
      if (response && !response.error) {
        return response;
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
      throw error;
    }
  };
};

export const setAffiliates = (affiliates: any) => ({
  type: Types.setAffiliates,
  payload: affiliates,
});

export const setPointsSale = (pointSale: any) => ({
  type: Types.setPointsSale,
  payload: pointSale,
});
