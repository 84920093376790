import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import * as yup from "yup";

import { Container, Col } from "reactstrap";

import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@mui/material";

import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";

import { MyTextField } from "../custom/MyTextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { changePassword } from "../../../services/auth";

interface Token {
  token: string;
}

interface Props {
  openModal: boolean;
  handleClose: () => void;
}

export const ChangePasswordForm = ({ openModal, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token } = useParams<Token | any>();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const validationSchema = yup.object({
    password: yup
      .string()
      .required(t("form.requiredFile"))
      .min(6, t("alerts.minLengthPassword"))
      .max(20, t("form.maxLength"))
      .matches(
        //eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*()_+\-=;':"\\|,.?~])/,
        t("user.rexPassword")
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], t("user.matchingPasswords"))
      .required(t("form.requiredFile")),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);
    const result = (await changePassword(data?.password, token)) as any;

    if (result?.error) {
      dispatch(setOpenAlert());
      dispatch(setTypeAlert("error"));
      dispatch(setMessageAlert(result?.error));
    } else {
      handleClose();
      dispatch(setOpenAlert());
      dispatch(setTypeAlert("success"));
      dispatch(setMessageAlert(result?.message));
      history.replace({ pathname: "/" });
    }
    setLoading(false);
  };

  return (
    <Dialog open={openModal} maxWidth="md" disableEscapeKeyDown scroll="body">
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 0,
        }}
      >
        <Box display="flex">
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <p className="txt-primary-md" style={{ fontWeight: 500 }}>
            {t("forgotPassword.modalTitle")}
          </p>
        </Box>
        <IconButton
          disableFocusRipple
          disableRipple
          className="hover-close"
          style={{ border: "none", outline: "none" }}
          onClick={handleClose}
        >
          <i className="fa-solid fa-xmark"></i>
        </IconButton>
      </DialogTitle>
      <Box borderTop={1} borderColor="#02D0E7">
        <DialogContent>
          <Container>
            <Col>
              <Formik
                validateOnChange={true}
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={async (data, { setSubmitting }) => {
                  setSubmitting(true);
                  handleSubmit(data);
                }}
              >
                {({ errors, handleChange }) => (
                  <Form>
                    <Grid>
                      <Grid item mb={3}>
                        <MyTextField
                          name="password"
                          variant="outlined"
                          size="small"
                          label={t("user.password") + "*"}
                          onChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  onClick={handleClickShowPassword}
                                >
                                  {showPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          type={showPassword ? "text" : "password"}
                          custom="true"
                          inputProps={{ maxLength: 20 }}
                        />
                      </Grid>
                      <Grid item mb={3}>
                        <MyTextField
                          name="confirmPassword"
                          variant="outlined"
                          onChange={handleChange}
                          label={t("user.confirmPassword") + "*"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  onClick={handleClickShowConfirmPassword}
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          type={showConfirmPassword ? "text" : "password"}
                          custom="true"
                          inputProps={{ maxLength: 20 }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      display="flex"
                      justifyContent="space-between"
                      mb={1}
                    >
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        disabled={
                          loading || errors.password || errors.confirmPassword
                            ? true
                            : false
                        }
                      >
                        {loading ? (
                          <CircularProgress size={"1.6rem"} color="inherit" />
                        ) : (
                          t("button.resetPassword")
                        )}
                      </Button>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Col>
          </Container>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
