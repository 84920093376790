import { FC } from "react";
import { TableCell, TableRow } from "@mui/material";
import moment from "moment";

export const RowAffiliationsReport: FC<{ row: any }> = ({ row }) => {
  return (
    <>
      <TableRow key={row.id} hover role="checkbox" tabIndex={-1}>
        <TableCell align="center">{row.affiliate.id}</TableCell>
        <TableCell align="center">{row.affiliate.firstName}</TableCell>
        <TableCell align="center">{row.affiliate.lastName}</TableCell>
        <TableCell align="center">
          {moment(row?.affiliate?.createAt).format("YYYY-MM-DD HH:mm:ss A")}
        </TableCell>
        <TableCell align="center">{row.pointSale.name}</TableCell>
        <TableCell align="center">{row.pointSale.commissionType}</TableCell>
        <TableCell align="center">{Number(row.sum_bets).toFixed(2)}</TableCell>
        <TableCell align="center">
          {Number(row.sum_bets_win).toFixed(2)}
        </TableCell>
        <TableCell align="center">
          {row.pointSale.commissionType === "GGR"
            ? Number(row.commissionGGR).toFixed(2)
            : "-"}
        </TableCell>
        <TableCell align="center">{row.deposit}</TableCell>
        <TableCell align="center">
          {row.pointSale.commissionType === "FTD"
            ? Number(row.commissionFTD).toFixed(2)
            : "-"}
        </TableCell>
      </TableRow>
    </>
  );
};
