import React, { FC } from "react";
import { MyTextField } from "./MyTextField";
import { useTranslation } from "react-i18next";
import { IGenericData } from "../../../interfaces/params";
import { MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";

import { usePointSales } from "../../../hooks/usePointSales";
import { DealerTypeEnum } from "../../../enums/enums";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
interface props {
  onChange(e: any): void;
  onlyDirect?: boolean;
  type?: DealerTypeEnum;
  showNestedPoints?: boolean;
}
export const PointSaleSelect: FC<props> = ({
  onChange,
  onlyDirect,
  type,
  showNestedPoints,
}) => {
  const { user, token } = useSelector((state: AppState) => state.auth);

  const pointSaleList = usePointSales(
    {
      id: user && user.id,
      operator: user?.operator
        ? {
            id: user?.operator.id,
            name: user?.operator.name,
          }
        : null,
      roles: {
        id: user?.roles.id,
        name: user?.roles.name,
      },
    },
    token,
    type,
    showNestedPoints
  );
  let pointSales = [...pointSaleList];

  if (onlyDirect) {
    const filterPoint = pointSaleList.filter((item) => item.isDirect);

    pointSales = [...filterPoint];
  }

  const { t } = useTranslation();

  return (
    <MyTextField
      name="pointSale"
      variant="outlined"
      size="small"
      fullWidth
      select
      label={t("shorcuts.PointSale") + "*"}
      onChange={(event: any, value: IGenericData | null) => {
        onChange(event);
      }}
      SelectProps={{
        MenuProps,
      }}
    >
      {pointSales.map((item) => (
        <MenuItem
          key={item.name}
          value={item.id}
          sx={{
            "&.Mui-focused": {
              backgroundColor: "red",
            },
          }}
        >
          {item.name}
        </MenuItem>
      ))}
    </MyTextField>
  );
};
