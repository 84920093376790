import React, { useEffect, useState } from "react";
import { Module, State } from "../../../../../enums/enums";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import { useLocation } from "react-router-dom";
import { setFlagForm } from "../../../../../redux/actions/helperActions";
import { arraySearch } from "../../../../../redux/actions/searchActions";
import { startGetAds } from "../../../../../redux/actions/managementAdsActions";
import {
  getOperatorByIdService,
  getOperatorsService,
} from "../../../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../../../redux/actions/managementCompanyActions";
import { FormCreateNewSlide } from "../../../../components/managementSlides/FormCreateNewSlide";
import { Box, CircularProgress, Grid, IconButton } from "@mui/material";
import DragAndDropCards from "../../../../components/custom/DragAndDropCards";
import {
  setSlides,
  startDeleteSlide,
  updateOrderSlide,
} from "../../../../../redux/actions/managementSlidesActions";

interface Props {
  module: string;
}

export const Slides = ({ module }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(true);
  const { flagFormCreate } = useSelector((state: AppState) => state.helper);
  const { slides } = useSelector((state: AppState) => state.managementSlides);
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { pathname } = useLocation();
  const modulePermissions = routes.find((route) => route.url === pathname);
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissionsMenu &&
    modulePermissions.permissionsMenu.created === 1;

  useEffect(() => {
    dispatch(setFlagForm({ flagFormCreate: false }));
    dispatch(arraySearch(null));
    const fetchOperators = async (token: string) => {
      await dispatch(startGetAds({ page: 1 }));
      if (user) {
        setIsFetching(true);
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        const operatorDetails = await getOperatorByIdService(
          token,
          user.operator.id
        );
        const sliderMappings: { [key: string]: string } = {
          dashboard: Module.DASHBOARD,
          casino: Module.CASINO,
          promotions: Module.PROMOTIONS,
          casino_live: Module.CASINO_LIVE,
          bettingShortcutsBackground: Module.BETTINGSHORTCUTSBACKGROUND,
          bettingShortcuts: Module.BETTINGSHORTCUTS,
          pageBackground: Module.PAGEBACKGROUND,
          paymentMethods: Module.PAYMENTMETHODS,
          footerItems: Module.FOOTERITEMS,
          signUp: Module.SIGNUP,
          bingo: Module.BINGO,
          virtualsGoldenRace: Module.VIRTUALSGOLDENRACE,
          mobile: Module.MOBILE,
        };

        const sliderProperty = sliderMappings[module];

        if (sliderProperty) {
          dispatch(setSlides(operatorDetails?.sliders?.[sliderProperty]));
        }
        dispatch(setCompaniesRedux(operators));
        setIsFetching(false);
      }
    };
    token && fetchOperators(token);
    return () => {
      dispatch(setCompaniesRedux(null));
      dispatch(setFlagForm({ flagFormCreate: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowForm = () => {
    if (
      (module === Module.BETTINGSHORTCUTSBACKGROUND &&
        Number(slides?.length) > 0) ||
      (module === Module.PAGEBACKGROUND && Number(slides?.length) > 0)
    ) {
      dispatch(setFlagForm({ flagFormCreate: false }));
    } else {
      dispatch(setFlagForm({ flagFormCreate: true }));
    }
  };
  return (
    <div className="white-container">
      {isFetching ? (
        <Grid container className={"paperLoading"}>
          <Box mx="auto" my={"30vh"}>
            <CircularProgress className={"colorLoading"} />
          </Box>
        </Grid>
      ) : (
        <>
          {flagFormCreate ? (
            <FormCreateNewSlide module={module} />
          ) : (
            <Grid container spacing={2}>
              {enableCreated && (
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Box display="flex" alignItems="center">
                      <p
                        className="txt-primary-md"
                        style={{
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          alignSelf: "center",
                        }}
                      >
                        {module === Module.BETTINGSHORTCUTSBACKGROUND ||
                        module === Module.PAGEBACKGROUND
                          ? "Crear fondo"
                          : module === Module.BETTINGSHORTCUTS
                          ? "Crear atajos"
                          : t("managementAds.createSliders")}
                      </p>
                    </Box>
                    <Box mr={1}>
                      <IconButton
                        onClick={handleShowForm}
                        id="openFormBtn"
                        style={{ border: "none", outline: "none" }}
                      >
                        <span className="btn-add">
                          <i
                            className="fa-solid fa-plus"
                            style={{ fontSize: 30 }}
                          ></i>
                        </span>
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sx={{ textAlign: "end" }}>
                {slides && slides.length > 0 && (
                  <DragAndDropCards
                    cards={slides}
                    deleteMethod={startDeleteSlide}
                    updateOrderMethod={updateOrderSlide}
                    module={module}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </div>
  );
};
