import moment from "moment-timezone";
import { useEffect, useState } from "react";

export const useDate = () => {
  const [date, setDate] = useState("");
  const [hour, setHour] = useState("");
  const [utc, setUtc] = useState("");
  const timezone = moment.tz.guess();

  useEffect(() => {
    const timer = setInterval(() => {
      let utcString = moment().tz(timezone).format("[UTC]Z");
      const index = utcString.indexOf(":");
      utcString = utcString.substring(0, index);

      setDate(moment().tz(timezone).format("DD/MM/YYYY"));
      setUtc(utcString);
      setHour(moment().tz(timezone).format("HH:mm a"));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    date,
    utc,
    hour,
  };
};
