import { IEvolutionQuery } from "../../interfaces/casino";
import { getEvolutionCasinoReportForActivity } from "../../services/evolution";
import { AppState } from "../reducers/rootReducer";
import { setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";
import { arraySearch } from "./searchActions";

export const startGetEvolutionCasinoActivity = (
  params: Partial<IEvolutionQuery>
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      const { rowsPerPage } = (getState() as AppState).helper;

      if (!token) {
        throw new Error("INVALID ACTION");
      }
      if (params.page) {
        params.perPage = rowsPerPage;
      }

      let finalParams: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        if (value) {
          //@ts-ignore
          if (typeof value === "number" || value.length > 0) {
            //@ts-ignore
            finalParams[key] = value;
          }
        }
      });
      const response = await getEvolutionCasinoReportForActivity(
        token,
        finalParams
      );

      if (response && !response.error) {
        return dispatch(arraySearch(response));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};
