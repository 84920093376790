import i18next from "i18next";
import { IMenuList, IRoutes } from "../interfaces/menu";
import routes from "../routes";
import Dashboard from "../views/Dashboard";
import FormFilesUploads from "../views/pages/FilesUploads/FormFilesUploads";
import ManagementFiles from "../views/pages/FilesUploads/ManagementFiles";
import { IRoles } from "../redux/interfaces/users";
import { Roles_Enum } from "../enums/enums";

export const buildRoutes = (
  menuList: IMenuList[],
  layout: string,
  rol: IRoles
) => {
  const { id: userRolId, name: rolName } = rol;
  let finalRoutes: IRoutes[] = [];

  const filteredRoutes = routes.filter(
    /* filtrar las rutas por rol */
    (route) =>
      route.layout === layout && route.layout + route.path !== "/admin/profile"
  );

  filteredRoutes.forEach((route) => {
    //hacer un find con la ruta dirigido a menu
    if (!route.collapse) {
      const existsRoute = menuList.find(
        (menu) =>
          menu.url === `${route.layout}${route.path}` && menu.state === 1
      );
      if (!!existsRoute) {
        finalRoutes.push({
          /* agregar a finalRoutes */ ...existsRoute,
          layout,
          icon: "sidebar-icons " + existsRoute.icon,
          component: route.component,
        });
      }
    } else {
      let subRoutes: any[] = []; /* recorrer subrutas disponibles */
      const currentMenu = menuList.find(
        (menu) =>
          menu.state === 1 &&
          !!route.views.find(
            (subRoute) =>
              menu.menuItem &&
              menu.menuItem.find(
                (module) =>
                  module.state === 1 &&
                  (module.url ===
                    `${route.layout}${route.path}${subRoute.path}` ||
                    `${route.layout}${route.path}${subRoute.path}` ===
                      menu.url + module.url)
              )
          )
      );
      if (currentMenu) {
        route.views.forEach((subRoute) => {
          const menuFinded = menuList.find(
            (menu) =>
              menu.state === 1 &&
              menu.menuItem &&
              menu.menuItem.find(
                (module) =>
                  module.state === 1 &&
                  (module.url ===
                    `${route.layout}${route.path}${subRoute.path}` ||
                    `${route.layout}${route.path}${subRoute.path}` ===
                      menu.url + module.url)
              )
          );
          if (!!menuFinded) {
            const moduleFinded = menuFinded.menuItem.find(
              (module) =>
                module.state === 1 &&
                (module.url ===
                  `${route.layout}${route.path}${subRoute.path}` ||
                  module.url === `${route.path}${subRoute.path}` ||
                  module.url === `${subRoute.path}`)
            );
            if (moduleFinded) {
              subRoutes.push({
                id: moduleFinded.id,
                url: moduleFinded.url
                  ? menuFinded.url + moduleFinded.url
                  : moduleFinded.url,
                name: moduleFinded.name,
                permissions: moduleFinded.permissions,
                /*  icon:
                  "sidebar-icons sidebar-icon-sub-route " + moduleFinded.icon, */

                component: subRoute.component,
              });
            }
          }
        });

        const completeRoute: IRoutes = {
          ...currentMenu,
          menuItem: subRoutes,
          collapse: true,
          layout,
          state: currentMenu.name.split(" ").join("") + "Collapse",
          icon: "sidebar-icons " + currentMenu.icon,
        };
        finalRoutes.push(completeRoute);
      }
    }
  });

  //verify if exist admin/home route
  const existsHomeRoute = finalRoutes.find(
    (route) => route.url === "/admin/home"
  );
  const BlackListHome = [
    Roles_Enum.DEALER.toLowerCase(),
    Roles_Enum.JER.toLowerCase(),
    Roles_Enum.ASSOCIATE.toLowerCase(),
    Roles_Enum.SELLER.toLowerCase(),
    Roles_Enum.PROMOTOR.toLowerCase(),
    Roles_Enum.CONCILIATION.toLowerCase(),
  ];
  if (!existsHomeRoute) {
    if (userRolId === 63) {
      finalRoutes = [
        {
          createAt: new Date().toISOString(),
          description: "Home route",
          icon: "sidebar-icons fa-solid fa-house-user",
          id: finalRoutes.length + 1,
          layout,
          menuItem: [],
          name: i18next.t("menu.home.title"),
          order: finalRoutes.length + 1,
          state: 1,
          updateAt: new Date().toISOString(),
          url: "/admin/home",
          permissionsMenu: {
            created: 1,
            deleted: 1,
            readed: 1,
            updated: 1,
          },
          component: Dashboard,
        },
        {
          createAt: new Date().toISOString(),
          description: "menu para gestión de archivos",
          icon: "sidebar-icons fa-solid fa fa-upload",
          id: finalRoutes.length + 2,
          layout: "/admin",
          collapse: true,
          menuItem: [
            {
              id: finalRoutes.length + 4,
              name: "Archivos",
              url: "/admin/fileUpload/managementFiles",
              description: "",
              // @ts-ignore
              component: ManagementFiles,
            },
            {
              id: finalRoutes.length + 3,
              name: "Gestión Archivos",
              url: "/admin/fileUpload/management-form",
              description: "",
              // @ts-ignore
              component: FormFilesUploads,
            },
          ],
          name: "Archivos",
          order: 2,
          state: "ArchivosCollapse",
          updateAt: new Date().toISOString(),
          url: "/admin/fileUpload/managementFiles",
          component: ManagementFiles,
        },
        ...finalRoutes,
      ];
    } else {
      if (!BlackListHome.includes(rolName.toLowerCase())) {
        finalRoutes = [
          {
            createAt: new Date().toISOString(),
            description: "Home route",
            icon: "sidebar-icons fa-solid fa-house-user",
            id: finalRoutes.length + 1,
            layout,
            menuItem: [],
            name: i18next.t("menu.home.title"),
            order: finalRoutes.length + 1,
            state: 1,
            updateAt: new Date().toISOString(),
            url: "/admin/home",
            permissionsMenu: {
              created: 1,
              deleted: 1,
              readed: 1,
              updated: 1,
            },
            component: Dashboard,
          },
          ...finalRoutes,
        ];
      }
    }
  }
  return finalRoutes;
};
