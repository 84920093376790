import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { AppState } from "../redux/reducers/rootReducer";
import { addNotificationAction, markAsReadNotificationRedux } from "../redux/actions/notificationsActions";
import { socket } from "../helpers/socket";
interface PrivateRouteProps {
  component: FC;
  isAuthenticated: boolean;
  path: string;
  exact?: boolean;
  handleStay: () => void;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const dispatch = useDispatch();
  const { component: Component, isAuthenticated, handleStay, ...rest } = props;
  const { user } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    if (user) {
      socket.connect();
      socket.emit("Admin/Auth", { user });

      socket.on("Alerts/Message", (notification: any) => {
        dispatch(addNotificationAction(notification.data));
      });
      socket.on("Admin/UpdateNotificationAsRead", ({ data }) => {
        dispatch(markAsReadNotificationRedux(data));
      });
    } else {
      socket.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, socket]);

  return (
    <Route
      {...rest}
      component={(routeProps: any) =>
        isAuthenticated ? (
          <Component {...routeProps} handleStay={handleStay} />
        ) : (
          <Redirect from="/" exact to="/auth/login" />
        )
      }
    />
  );
};
