export interface IAudit{
    event:string,
    createdBy:number,
    playerAffected?:number
    operatorAffected?:number
}
export const createLimitAudit =(token:string,audit:IAudit)=>{
    const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          api: `${process.env.REACT_APP_API_KEY}`,
          Authorization: `bearer ${token}`,
        },
        body: JSON.stringify(audit),
      };
    
      return fetch(
        `${process.env.REACT_APP_API_HTTP_SECURITY}limitsAudit`,
        requestOptions
      )
        .then((response) => response.json())
        .then((responseData) => responseData);


}