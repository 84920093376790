import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  activeOrInactiveBonus,
  bonusGroupDetail,
} from "../../../../services/bonus";

import { useTranslation } from "react-i18next";

import {
  setFlagForm,
  setRowsPerPage,
} from "../../../../redux/actions/helperActions";
import { MyPagination } from "../../../../components/Custom/Table/MyPagination";
import { State } from "../../../../enums/enums";
import { useLocation } from "react-router-dom";
import { FormAddBonusToGroup } from "../../../components/managementBonus/automaticBonus/FormAddBonusToGroup";

export const BonusGroupDetails = () => {
  const { token } = useSelector((state: AppState) => state.auth);
  const { rowsPerPage, flagFormCreate } = useSelector(
    (state: AppState) => state.helper
  );
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { activeBonusGroup } = useSelector((state: AppState) => state.bonus);
  const [bonusList, setBonusList] = useState<Array<any>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);

  useEffect(() => {
    const fetchBonusList = async () => {
      if (token && activeBonusGroup) {
        const params = {
          page: 1,
          perPage: rowsPerPage,
        };

        const response = await bonusGroupDetail(
          activeBonusGroup.id,
          params,
          token
        );
        setBonusList(response);
      }
    };
    fetchBonusList();

    return () => {
      dispatch(setRowsPerPage(9));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePageChange = async (page: number, rows?: number) => {
    setCurrentPage(page);

    if (!token) {
      throw new Error("INVALID ACTION");
    }
    const params = {
      page: page,
      perPage: rows || rowsPerPage,
    };
    const response = await bonusGroupDetail(activeBonusGroup.id, params, token);
    setBonusList(response);
  };

  const activeOrInactive = async (id: number) => {
    if (!token) {
      throw new Error("INVALID ACTION");
    }

    const response = await activeOrInactiveBonus(id, token);
    if (response.message === "ok") {
      const params = {
        page: 1,
        perPage: rowsPerPage,
      };

      const result = await bonusGroupDetail(activeBonusGroup.id, params, token);
      setBonusList(result);
    }
  };

  const handleShowForm = () => {
    dispatch(setFlagForm({ flagFormCreate: true }));
  };

  return (
    <>
      {flagFormCreate ? (
        <FormAddBonusToGroup />
      ) : (
        <Grid container spacing={2}>
          {modulePermissions &&
            modulePermissions.permissions &&
            modulePermissions.permissions.created === 1 && (
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  mb={2}
                >
                  <Box display="flex" alignItems="center">
                    <p
                      className="txt-primary-md"
                      style={{
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        alignSelf: "center",
                      }}
                    >
                      {t("managementBonds.addBonus").toLocaleUpperCase()}
                    </p>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={handleShowForm}
                      id="openFormBtn"
                      data-testid="openFormBtn"
                      style={{ border: "none", outline: "none" }}
                    >
                      <span className="btn-add">
                        <i
                          className="fa-solid fa-plus"
                          style={{ fontSize: 30 }}
                        ></i>
                      </span>
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            )}
          <Grid item xs={12} bgcolor={"#F4F4F4"}>
            <Grid container spacing={2}>
              {[
                t("managementBonds.bonusCode").toUpperCase(),
                t("managementBonds.bondName").toUpperCase(),
                t("table.state").toUpperCase(),
                t("table.actions").toUpperCase(),
              ].map((col) => (
                <Grid item xs={3} key={col}>
                  <Typography
                    variant="body1"
                    fontWeight={"bold"}
                    color="#333"
                    textAlign="center"
                  >
                    {col.toUpperCase()}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {bonusList?.map((bonus) => (
              <Grid
                container
                spacing={2}
                bgcolor={"#02D0E71A"}
                mt={1}
                key={bonus.id}
              >
                <Grid item xs={3}>
                  <Typography variant="body1" color="#333" textAlign="center">
                    {bonus.id}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" color="#333" textAlign="center">
                    {bonus.name}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" color="#333" textAlign="center">
                    {bonus?.groupBonusStatus === State.ACTIVE
                      ? t("state.active").toUpperCase()
                      : t("state.inactive").toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Tooltip title={t("button.changeState") as string} arrow>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <i
                        className={
                          bonus?.groupBonusStatus === State.ACTIVE
                            ? `fa fa-eye fa-lg mr-2 iconSee`
                            : `fa-regular fa-eye-slash fa-lg mr-2 iconSee`
                        }
                        onClick={() => {
                          activeOrInactive(bonus.id);
                        }}
                      />
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            {bonusList ? (
              <MyPagination
                onPageChange={handlePageChange}
                currentPage={currentPage}
                lastPage={bonusList.length || 1}
                siblingCount={1}
                total={bonusList.length || 0}
              />
            ) : (
              <Box
                display={"flex"}
                justifyContent="center"
                alignItems={"center"}
                width="100%"
                mt={3}
              >
                <CircularProgress className={"colorLoading"} />
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
