import React, { useEffect, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CustomToolbar, formats } from "./CustomToolbar";
import "./styles.css";

type Props = {
  value: string;
  placeholder: string;
  onChange: (e: any) => void;
  onChangeText: any;
  onChangeFile: (file: File) => void;
};

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "Roboto",
  "Raleway",
  "Montserrat",
  "Lato",
  "Rubik",
];
Quill.register(Font, true);

export const TextEditor: React.FC<Props> = ({
  value,
  onChange,
  onChangeText,
  placeholder,
  onChangeFile,
}) => {
  const reactQuillRef = useRef<any>();

  const imageHandler = async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute(
      "accept",
      "text/plain, application/pdf, application/msword"
    );
    input.click();
    input.onchange = async () => {
      const file: any = input && input.files ? input.files[0] : null;
      onChangeFile(file);
    };
  };

  const modules = React.useMemo(
    () => ({
      toolbar: {
        container: "#toolbar",
        handlers: {
          undo: imageHandler,
          font: null,
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const editor = reactQuillRef?.current.editor;
    const range = editor?.getSelection(true).index;
    const text = editor.getText(0, range + 1);
    onChangeText(text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="text-editor">
      <CustomToolbar />
      <ReactQuill
        ref={reactQuillRef}
        theme="snow"
        value={value || ""}
        modules={modules}
        onChange={onChange}
        placeholder={placeholder}
        formats={formats}
      />
    </div>
  );
};
