import { useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";
import { getCopyArrayOrObject } from "../../utils/utils";
import { headersPromotionalAcount } from "../../helpers/headersTables";
import moment from "moment";

export const PromotionalAcountDataTable = (totalData: any) => {
  const { data } = useSelector((state: AppState) => state.search);
  //   COLUMNS HEADERS STYLES
  const headersCvsStyle = headersPromotionalAcount.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  // Obtener datos por vista
  const dataPerView = data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotal = totalData ? getCopyArrayOrObject(totalData) : [];
  const getTotalExpiredBonus = (row: any) => {
    const { endDate, amountBonus, totalBonusDelivered } = row;
    const isExpired = moment(endDate).isAfter(moment());
    if (isExpired) {
      return parseInt(amountBonus) - totalBonusDelivered;
    }
    return 0;
  };
  //   DATA TOTAL
  const multiTotalDataSet = [
    {
      columns: headersCvsStyle,
      data: dataTotal.map((data: any) => {
        let array;
        array = [
          {
            value: data.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.value,
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: data.totalBonusDelivered,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.amountBonus,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.totalValue ? data.totalValue : 0,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: parseInt(data.value) * data.totalBonusDelivered,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.provider.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.createdUser.nameComplete,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.createAt).format("DD/MM/YYYY HH:mm:ss"),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.operator.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: getTotalExpiredBonus(data),
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];
  const multiDataSet = [
    {
      columns: headersCvsStyle,
      data: dataPerView.map((data: any) => {
        let array;
        array = [
          {
            value: data.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.value,
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: data.totalBonusDelivered,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.amountBonus,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.totalValue ? data.totalValue : 0,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: parseInt(data.value) * data.totalBonusDelivered,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.provider.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.createdUser.nameComplete,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.createAt).format("DD/MM/YYYY HH:mm:ss"),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.operator.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: getTotalExpiredBonus(data),
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];
  const finalData = {
    dataPerView,
    dataTotal,
    headersCSV: headersPromotionalAcount,
    multiDataSet,
    multiTotalDataSet,
  };
  return finalData;
};
