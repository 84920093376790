import { KeyboardEvent, useState, useEffect } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import clearLogo from "../../../assets/svg/icon-park_clear-format.svg";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { MyTextField } from "../custom/MyTextField";
import MenuItem from "@mui/material/MenuItem";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { ITransactionForm } from "../../../interfaces/transactionSettings";
import {
  StateTransaction,
  ExclusionCategory,
  Roles_Enum,
  State,
  DetailTransactionalSettings,
  ResponsibleSetting,
} from "../../../enums/enums";
import { startCreateTransactionSetting } from "../../../redux/actions/transactionsActions";
import {
  regDecimal,
  regLettersNumbers,
  regNumbers,
} from "../../../utils/utils";
import { MyDialog } from "../custom/MyDialog";
import {
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import TextField from "@mui/material/TextField";
import {
  getExclusions,
  getPlayerById,
} from "../../../services/managementPlayersService";
import { getOperatorsService } from "../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../redux/actions/managementCompanyActions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const FormCreateTransactionSet = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { movementTypes, settingTypes, wallets } = useSelector(
    (state: AppState) => state.transactions
  );
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const [message, setMessage] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [formValues, setFormValues] = useState<any>(null);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;

  const initialValues = {
    id: "",
    value: "",
    movementType: "",
    setType: "",
    wallet: "",
    observations: "",
    operator: isSuper ? "" : user?.operator.id,
    detail: "",
    responsible: "",
  };
  const validationSchema = yup.object({
    id: yup.string().required(t("form.requiredFile")),
    value: yup
      .number()
      .positive(t("alerts.onlyPositiveNumber"))
      .test(
        "len",
        t("form.lessThan20"),
        (val: any) => val && val.toString().length <= 8
      )
      .required(t("form.requiredFile")),
    movementType: yup.number().required(t("form.requiredFile")),
    setType: yup.number().required(t("form.requiredFile")),
    wallet: yup.number().required(t("form.requiredFile")),
    observations: yup.string().required(t("form.requiredFile")),
    detail: yup.string().required(t("form.requiredFile")),
    responsible: yup.string().required(t("form.requiredFile")),
    operator: isSuper
      ? yup.string().required(t("form.requiredFile"))
      : yup.string(),
  });

  const handleCancel = () => {
    handleShowConfirmationWindow(t("dialog.sureCancel"));
  };
  const handleCleanForm = (resetForm: any) => {
    resetForm();
  };
  const handleGoBack = () => {
    dispatch(setFlagForm({ flagFormCreate: false }));
  };
  const handleCreateSet = async (formValues: any) => {
    setSubmitting(true);
    try {
      const resPlayer =
        token &&
        (await getPlayerById(token, formValues.id, formValues.operator));

      if (!resPlayer || resPlayer.error) {
        dispatch(setMessageAlert(`transactions.userNotFound`));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
        setSubmitting(false);
        return setOpenConfirmation(false);
      }
      const playerEmail = resPlayer.email;
      const documentNumber = resPlayer.numberDocument;

      // Validate self-exclusion
      const params: any = {
        playerEmail,
        category: ExclusionCategory.SELF_EXCLUSION,
        state: 1,
        currentDate: true,
      };
      const selfExclusion = token && (await getExclusions(token, params));

      if (
        selfExclusion &&
        selfExclusion.data &&
        selfExclusion.data.length > 0
      ) {
        dispatch(setMessageAlert(t("transactions.currentSelfExclusion")));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
        setSubmitting(false);
        return setOpenConfirmation(false);
      }

      // Validate active-exclusion
      const paramsExclusions: any = {
        playerEmail,
        activeExclusions: true,
      };
      const activeExclusions =
        token && (await getExclusions(token, paramsExclusions));
      if (
        activeExclusions &&
        activeExclusions.data &&
        activeExclusions.data.length > 0
      ) {
        dispatch(setMessageAlert(t("transactions.currentExclusion")));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
        setSubmitting(false);
        return setOpenConfirmation(false);
      }

      const parseProps: ITransactionForm = {
        emailPlayer: playerEmail,
        movementType: formValues.movementType,
        documentNumberPlayer: documentNumber,
        observations: formValues.observations,
        state: StateTransaction.Approved,
        type: formValues.setType,
        wallet: formValues.wallet,
        value: parseFloat(formValues.value),
        isRefill: false,
        detail: formValues.detail,
        responsible: formValues.responsible,
      };

      await dispatch(startCreateTransactionSetting(parseProps, false));
    } catch (error) {
      console.error("An error occurred in handleCreateSet:", error);
      dispatch(setMessageAlert(t("transactions.errorOccurred")));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    } finally {
      setSubmitting(false);
      setOpenConfirmation(false);
    }
  };
  const handleShowConfirmationWindow = (message: string) => {
    setOpenConfirmation(true);
    setMessage(message);
  };
  const isEmptyValues = (formValues: any) => {
    let emptyValues = false;

    Object.entries(formValues).forEach(([key, value]) => {
      if (value === "") {
        emptyValues = true;
      }
    });

    return emptyValues;
  };

  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    token && fetchOperators(token);
    return () => {
      dispatch(setCompaniesRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange
            onSubmit={async (data) => {
              setFormValues(data);
              handleShowConfirmationWindow(t("transactions.sureCreated"));
            }}
          >
            {({ handleChange, values, isValid, resetForm, setFieldValue }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} mb={2}>
                    <Divider
                      sx={{
                        "&.MuiDivider-root": {
                          "&::before": {
                            width: "20px",
                          },
                        },
                        color: "#333333",
                      }}
                      textAlign="left"
                    >
                      {t("user.personalInformation")}
                    </Divider>
                  </Grid>
                  <Grid item xs={4}>
                    <MyTextField
                      name="id"
                      label={`${t("table.userId")}*`}
                      onChange={handleChange}
                      inputProps={{ maxLength: 15 }}
                      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                        !regNumbers(e.key) && e.preventDefault()
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="value"
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={`${t("transactions.value")}*`}
                      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                        !regDecimal(e.key) && e.preventDefault()
                      }
                      value={values.value}
                      onChange={(e) => {
                        if (e.target.value.length <= 8) {
                          setFieldValue("value", e.target.value);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MyTextField
                      name="movementType"
                      variant="outlined"
                      size="small"
                      fullWidth
                      select
                      label={`${t("transactions.movementType")}*`}
                      onChange={handleChange}
                    >
                      {movementTypes.map((item) => (
                        <MenuItem
                          key={item.name}
                          value={item.id}
                          sx={{
                            "&.Mui-focused": {
                              backgroundColor: "red",
                            },
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </MyTextField>
                  </Grid>
                  <Grid item xs={4}>
                    <MyTextField
                      name="setType"
                      variant="outlined"
                      size="small"
                      fullWidth
                      select
                      label={`${t("transactions.setType")}*`}
                      onChange={handleChange}
                    >
                      {settingTypes.map((item) => (
                        <MenuItem
                          key={item.name}
                          value={item.id}
                          sx={{
                            "&.Mui-focused": {
                              backgroundColor: "red",
                            },
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </MyTextField>
                  </Grid>
                  <Grid item xs={4}>
                    <MyTextField
                      name="wallet"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      select
                      label={`${t("transactions.wallet")}*`}
                      onChange={handleChange}
                    >
                      {wallets.map((item) => (
                        <MenuItem
                          key={item.name}
                          value={item.id}
                          sx={{
                            "&.Mui-focused": {
                              backgroundColor: "red",
                            },
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </MyTextField>
                  </Grid>
                  <Grid item xs={4}>
                    <MyTextField
                      name="detail"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      select
                      label={`${t("transactionalSettings.detail")}*`}
                      onChange={handleChange}
                    >
                      {Object.entries(DetailTransactionalSettings).map(
                        ([key, value]) => (
                          <MenuItem
                            key={value}
                            value={key}
                            sx={{
                              "&.Mui-focused": {
                                backgroundColor: "red",
                              },
                            }}
                          >
                            {t(`transactionalSettings.${value}`, value)}
                          </MenuItem>
                        )
                      )}
                    </MyTextField>
                  </Grid>
                  <Grid item xs={4}>
                    <MyTextField
                      name="responsible"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      select
                      label={`${t(
                        "transactionalSettings.responsibleSetting"
                      )}*`}
                      onChange={handleChange}
                    >
                      {Object.entries(ResponsibleSetting).map(
                        ([key, value]) => (
                          <MenuItem
                            key={value}
                            value={key}
                            sx={{
                              "&.Mui-focused": {
                                backgroundColor: "red",
                              },
                            }}
                          >
                            {t(`transactionalSettings.${value}`)}
                          </MenuItem>
                        )
                      )}
                    </MyTextField>
                  </Grid>
                  {isSuper && (
                    <Grid item xs={4}>
                      <FormControl sx={{ width: "100%" }} size="small">
                        <InputLabel id="operator-label">
                          {t("companyManagement.operator") + "*"}
                        </InputLabel>
                        <Select
                          name="operator"
                          size="small"
                          fullWidth
                          onChange={handleChange}
                          value={values.operator || ""}
                          MenuProps={MenuProps}
                          input={
                            <OutlinedInput
                              label={t("companyManagement.operator") + "*"}
                            />
                          }
                        >
                          {companies?.data?.map((company) => (
                            <MenuItem key={company.id} value={company.id}>
                              {company.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12} mb={0.5} mt={2}>
                    <Divider
                      sx={{
                        "&.MuiDivider-root": {
                          "&::before": {
                            width: "20px",
                          },
                        },
                        color: "#333333",
                      }}
                      textAlign="left"
                    >
                      {t("tabs.observations")}
                    </Divider>
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextField
                      name="observations"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      InputLabelProps={{ shrink: false }}
                      label={` `}
                      multiline
                      rows={6}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 500,
                      }}
                      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                        !regLettersNumbers(e.key) && e.preventDefault()
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    sx={{ height: "60px" }}
                    mt={5}
                  >
                    <Button
                      style={{ border: "none", outline: "none" }}
                      variant="contained"
                      className="footer-btn danger-btn ripple"
                      onClick={handleCancel}
                    >
                      <i className="fa-solid fa-x"></i>
                      {t("button.cancel")}
                    </Button>
                    <Button
                      type="submit"
                      style={{ border: "none", outline: "none" }}
                      variant="contained"
                      id="btnSave"
                      disabled={
                        isSubmitting || isEmptyValues(values) || !isValid
                      }
                      className="footer-btn primary-btn ripple"
                    >
                      {isSubmitting ? (
                        <CircularProgress size={"1.6rem"} color="inherit" />
                      ) : (
                        <>
                          <i className="fa-solid fa-check"></i>
                          {t("button.save")}
                        </>
                      )}
                    </Button>
                    {!isSubmitting && (
                      <Button
                        type="button"
                        style={{
                          border: "none",
                          outline: "none",
                          marginLeft: "4px",
                        }}
                        onClick={() => handleCleanForm(resetForm)}
                      >
                        <img src={clearLogo} alt="Icon clear" />
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          <MyDialog
            open={openConfirmation}
            title={t("dialog.confirmationWindow")}
            message={message}
            handleAccept={() =>
              message === t("dialog.sureCancel")
                ? handleGoBack()
                : handleCreateSet(formValues)
            }
            handleClose={() => setOpenConfirmation(false)}
            isLoading={isSubmitting}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
