/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import "./managementUsers.scss";
import { useTranslation } from "react-i18next";
import Filter from "../../../components/Filter/Filter";
import { TableComponent } from "../../../components/Table/TableComponent";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  Enum_FieldType,
  IColumnsTable,
  Icons,
  IFilterField,
} from "../../../interfaces/components";
import FormCreateUser from "../../components/ManagementUsers/FormCreateUser";
import {
  setCloseAlert,
  setFlagForm,
  setRowsPerPage,
  setFlagFormAssociate,
} from "../../../redux/actions/helperActions";
import {
  setActiveMenu,
  setActiveModule,
} from "../../../redux/actions/managementMenuActions";
import {
  setActiveUser,
  setGetUsers,
} from "../../../redux/actions/managementUsersActions";

import { setRoles } from "../../../redux/actions/managementRolesActions";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { getOperatorsService } from "../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../redux/actions/managementCompanyActions";
import { State } from "../../../enums/enums";
import { MyAlert } from "../../components/custom/MyAlert";
import { useLocation } from "react-router-dom";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { MyCustomDialog } from "../../../components/Custom/MyCustomDialog";
import { FormConfigLogin } from "../../components/ManagementUsers/FormConfigLogin";
import { uiSetOpenDialog } from "../../../redux/actions/uiAction";

export const ManagementUsers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data } = useSelector((state: AppState) => state.search);
  const { roles } = useSelector((state: AppState) => state.managementRoles);
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { networkState, openDialog } = useSelector(
    (state: AppState) => state.ui
  );
  const isOnline = networkState === "Online";

  const { pathname } = useLocation();

  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);

  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;

  /**
   * Soporta el estado boolean para indicar si estamos o no en edición
   */
  const { openAlert, messageAlert, typeAlert } = useSelector(
    (state: AppState) => state.helper
  );

  const [filterFields, setfilterFields] = useState<IFilterField[]>([]);
  /**
   * Soporta el estado boolean para mostrar el formulario de creación de usuarios
   */
  const { flagFormCreate, flagFormCreateAssociate, component } = useSelector(
    (state: AppState) => state.helper
  );
  const { users, activeUser } = useSelector(
    (state: AppState) => state.managementUsers
  );
  const { token, user } = useSelector((state: AppState) => state.auth);
  const [dataFilter, setDataFilter] = useState<any>({});

  useEffect(() => {
    dispatch(setCloseAlert());
    dispatch(setRowsPerPage(9));
    const fetchRoles = async (token: string) => {
      if (user && token && isOnline && !component) {
        const id = user.operator ? user.operator.id : undefined;
        const paramsQueryRoles = {
          state: State.ACTIVE,
        };
        await dispatch(setRoles(token, id, undefined, paramsQueryRoles));
        await dispatch(setGetUsers(token, 1));
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    dispatch(setActiveMenu(null));
    dispatch(setActiveModule(null));
    dispatch(setActiveUser(null));
    component === null && dispatch(setFlagForm({ flagFormCreate: false }));
    if (token) {
      fetchRoles(token);
    }
    return () => {
      dispatch(setActiveUser(null));
      dispatch(setFlagFormAssociate({ flagFormCreateAssociate: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Soporta la información para los placeholders de inputs en el componente filtro
   */
  useEffect(() => {
    if (roles) {
      let FilterFileds: IFilterField[] = [
        {
          label: t("filters.username"),
          type: Enum_FieldType.TEXT,
        },
        {
          label: t("filters.name"),
          type: Enum_FieldType.TEXT,
        },
        {
          label: t("filters.startDate"),
          type: Enum_FieldType.DATE,
        },
        {
          label: t("filters.endDate"),
          type: Enum_FieldType.DATE,
        },
        {
          label: t("form.state"),
          type: Enum_FieldType.SELECT,
          options: [
            {
              label: t("state.active"),
              value: State.ACTIVE,
            },
            {
              label: t("state.inactive"),
              value: State.INACTIVE,
            },
            {
              label: t("state.retired"),
              value: State.RETIRED,
            },
          ],
        },
      ];
      if (roles.data.length > 0) {
        FilterFileds = [
          ...FilterFileds,
          {
            label: t("user.rol"),
            type: Enum_FieldType.SELECT,
            options: [
              ...roles.data.map((rol) => ({
                id: rol.rol ? rol.rol.id : rol.id,
                label: rol.rol ? rol.rol.name : rol.name,
                value: rol.rol ? rol.rol.id.toString() : rol.id.toString(),
              })),
            ],
          },
        ];
      }

      setfilterFields(FilterFileds);
    }
  }, [roles]);

  /**
   * Soporta las Columnas para renderizar en el header de la tabla
   */

  const columns: IColumnsTable[] = [
    { id: 1, header: "table.userId" },
    { id: 2, header: "table.name" },
    { id: 3, header: "table.email" },
    { id: 4, header: "table.creationDate" },
    { id: 5, header: "table.role" },
    { id: 6, header: "table.createdBy" },
    { id: 7, header: "table.state" },
    { id: 8, header: "table.actions" },
  ];

  /**
   * Soporta operador terniario para los iconos de acciones en tabla
   */

  const iconos: Icons = {
    see: false,
    delete: false,
    seeMore: true,
  };

  const handleOpenForm = () => {
    dispatch(setFlagForm({ flagFormCreate: true }));
    dispatch(setActiveUser(null));
  };

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
  };
  const handleOpenConfigForm = () => {
    dispatch(uiSetOpenDialog());
  };

  return (
    <div className="content w-100 mb-4">
      <Row>
        <Col lg={12} md={12} sm={12}>
          {enableCreated && (
            <Row style={{ marginBottom: 5 }}>
              <Col xs={12}>
                <MyBreadCrumbs
                  titles={[t("sidebar.settings"), t("sidebar.ManagementUsers")]}
                >
                  {flagFormCreate && (
                    <Typography color="text.primary">
                      {t(!!activeUser ? "user.editUser" : "user.createUser")}
                    </Typography>
                  )}
                </MyBreadCrumbs>
              </Col>
            </Row>
          )}
          {flagFormCreate || flagFormCreateAssociate ? (
            <FormCreateUser />
          ) : (
            <>
              <Row style={{ marginTop: 10, marginBottom: -10 }}>
                <Col xs={12}>
                  <h3 style={{ fontSize: 16, color: "#333333" }}>
                    {t("filters.filters")}
                  </h3>
                </Col>
              </Row>
              <div className="white-container">
                {users && filterFields.length > 0 ? (
                  <>
                    <Row>
                      <Col xs={12}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          mb={2}
                        >
                          {user?.operator && (
                            <>
                              <Box display="flex">
                                <Box display="flex" alignItems="center">
                                  <p
                                    className="txt-primary-md"
                                    style={{
                                      fontWeight: "bold",
                                      textTransform: "uppercase",
                                      alignSelf: "center",
                                    }}
                                  >
                                    {t("user.configLogin").toUpperCase()}
                                  </p>
                                </Box>
                                <Box>
                                  <IconButton
                                    onClick={handleOpenConfigForm}
                                    style={{ border: "none", outline: "none" }}
                                  >
                                    <span className="btn-add">
                                      <i
                                        className="fa-solid fa-plus"
                                        style={{ fontSize: 30 }}
                                      ></i>
                                    </span>
                                  </IconButton>
                                </Box>
                              </Box>
                            </>
                          )}

                          {enableCreated && (
                            <>
                              <Box display="flex" alignItems="center">
                                <p
                                  className="txt-primary-md"
                                  style={{
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    alignSelf: "center",
                                  }}
                                >
                                  {t("user.createUser")}
                                </p>
                              </Box>
                              <Box>
                                <IconButton
                                  onClick={handleOpenForm}
                                  id="openFormBtn"
                                  style={{ border: "none", outline: "none" }}
                                >
                                  <span className="btn-add">
                                    <i
                                      className="fa-solid fa-plus"
                                      style={{ fontSize: 30 }}
                                    ></i>
                                  </span>
                                </IconButton>
                              </Box>
                            </>
                          )}
                        </Box>
                      </Col>
                    </Row>

                    <Filter
                      fields={filterFields}
                      component={"users"}
                      setDataFilter={setDataFilter}
                    />

                    {data ? (
                      <>
                        {data.data.length > 0 && (
                          <TableComponent
                            columns={columns}
                            data={data.data}
                            pages={data.last_page}
                            icons={iconos}
                            component={"users"}
                            totalData={data}
                            isSortingTable={true}
                            dataFilter={dataFilter}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {users && users.data.length > 0 && (
                          <TableComponent
                            columns={columns}
                            data={users.data}
                            pages={users.last_page}
                            icons={iconos}
                            component={"users"}
                            totalData={users}
                            isSortingTable={true}
                            dataFilter={dataFilter}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <Grid container className={"paperLoading"}>
                    <Box mx="auto" my="40vh">
                      <CircularProgress className={"colorLoading"} />
                    </Box>
                  </Grid>
                )}
                <MyAlert
                  open={openAlert}
                  message={messageAlert ? messageAlert : ""}
                  typeAlert={typeAlert}
                  handleClose={onCloseAlert}
                />
              </div>
            </>
          )}
        </Col>
      </Row>

      <MyCustomDialog
        open={openDialog}
        title={t("user.configLogin")}
        onClose={() => dispatch(uiSetOpenDialog())}
        content={<FormConfigLogin />}
      />
    </div>
  );
};
