import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MyCustomTable, {
  IColumns,
} from "../../../../../components/Custom/Table/MyCustomTable";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import {
  asyncLoadingFunction,
  setLoadingState,
} from "../../../../../redux/actions/helperActions";
import { RowCardsInfo } from "./RowCardsInfo";
import { useEffect, useState } from "react";
import {
  setActiveLot,
  setIrrigationCards,
  startGetIrrigationCardsInventory,
} from "../../../../../redux/actions/managementCardsActions";
import { Roles_Enum } from "../../../../../enums/enums";
import { CurrentPlaceInventoryCards } from "../../../../../helpers/currentPlaceCard";
import moment from "moment";
import { IrrigationsInventoryDataTable } from "../../../../../hooks/useDataTable/irrigation/cards/IrrigationCardsInventoryDataTable";
import { IrrigationCardsInventoryPdf } from "../../../../../components/Pdf/Irrigation/cards/IrrigationsCardsInventoryPdfDocument";

export const IrrigationAdminInfoCards = () => {
  const [filterParams, setFilterParams] = useState({});
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { irrigationCardsInventory } = useSelector(
    (state: AppState) => state.managementCards
  );
  const { loading } = useSelector((state: AppState) => state.helper);
  const { pointSale } = useSelector((state: AppState) => state.wallet);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isRoleSeller = [Roles_Enum.SELLER, Roles_Enum.POINT_SALE].includes(
    user?.roles.name as any
  );
  const handleGetIrrigationCards = async () => {
    try {
      if (!user) return;
      const userId = isRoleSeller ? pointSale?.pointSale.id : user.id;
      const currentPlace = CurrentPlaceInventoryCards(
        user.roles.name as Roles_Enum
      );
      const params = {
        currentPlace,
        userId,
        page: 1,
      };
      setFilterParams(params);

      dispatch(setLoadingState({ loading: true }));
      dispatch(setActiveLot(null));

      await dispatch(startGetIrrigationCardsInventory(params));
    } catch (error) {
      console.error("Error fetching irrigation cards:", error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };

  useEffect(() => {
    handleGetIrrigationCards();
    return () => {
      dispatch(setIrrigationCards(null));
      dispatch(setActiveLot(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: IColumns[] = [
    {
      align: "center",
      label: t("irrigation.lot"),
    },
    {
      align: "center",
      label: t("form.value"),
    },
    {
      align: "center",
      label: t("cards.consecutiveCard"),
    },
    {
      align: "center",
      label: t("irrigation.redeemed"),
    },
    {
      align: "center",
      label: t("irrigation.inactive"),
    },
    {
      align: "center",
      label: t("irrigation.notAvailable"),
    },
    {
      align: "center",
      label: t("irrigation.available"),
    },
  ];

  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(
        async () =>
          await dispatch(
            startGetIrrigationCardsInventory({
              ...filterParams,
              page,
              perPage: rows || rowsPerPage,
            })
          )
      )
    );
  };

  const data = irrigationCardsInventory && irrigationCardsInventory;

  const getAllIrrigationCardsHistory = async () => {
    if (!token) {
      return false;
    }

    return data;
  };

  return (
    <Box className="filter" p={2} sx={{ minHeight: "300px" }}>
      <Box display="flex" alignItems="center">
        <Grid item xs={8} style={{ display: "flex", alignItems: "center" }}>
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <Typography
            color="text.primary"
            style={{ fontSize: "16px", fontWeight: 500 }}
          >
            {t("irrigation.cardsInformation")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            type="button"
            variant="contained"
            className="primary-btn ripple"
            style={{
              border: "none",
              outline: "none",
              minWidth: "100px",
              color: "white",
            }}
            onClick={handleGetIrrigationCards}
          >
            {loading ? (
              <CircularProgress size={"1.6rem"} color="inherit" />
            ) : (
              t("button.update").toUpperCase()
            )}
          </Button>
        </Grid>
      </Box>

      <Box mt={2}>
        {data && data.data.length === 0 ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <i
              className="fa-solid fa-circle-info"
              style={{ color: "#02D0E7", marginRight: 9, fontSize: 25 }}
            ></i>
            <Typography
              color="text.primary"
              style={{ fontSize: "20px", fontWeight: 600 }}
            >
              {t("reports.noIrrigatedCards")}
            </Typography>
          </Box>
        ) : (
          <MyCustomTable
            columns={columns}
            data={data?.data ?? []}
            lastPage={data?.last_page ?? 1}
            totalData={data?.total ?? 0}
            onPageChange={handlePageChange}
            renderBody={(item) => <RowCardsInfo row={item} />}
            expand={true}
            exportData={isRoleSeller}
            hookBuildTable={IrrigationsInventoryDataTable}
            getAllDataToExport={getAllIrrigationCardsHistory}
            pdfComponent={IrrigationCardsInventoryPdf}
            titleCSV={`${t("reports.cardIrrigation")
              .split(" ")
              .join("-")}-${moment().toISOString()}`}
          />
        )}
      </Box>
    </Box>
  );
};
