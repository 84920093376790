import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import InfoIcon from "@mui/icons-material/Info";
import "./securityQuestions.scss";
import { Row } from "reactstrap";
import LogoExclamation from "../../../../assets/img/exclamation-logo.png";
import CheckConfirmation from "../../../../assets/svg/check-confirmation.svg";
import { MyDialog } from "../../custom/MyDialog";
import { useState } from "react";
import { DialogParamSettings } from "../../ParamSettings.tsx/DialogParamSettings";
import { uiSetOpenDialog } from "../../../../redux/actions/uiAction";
import { resetSecurityQuestionsPlayer } from "../../../../redux/actions/managementPlayersActions";

export const SecurityQuestions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { player } = useSelector((state: AppState) => state.managementPlayers);
  const [open, setOpen] = useState(false);
  const { openDialog } = useSelector((state: AppState) => state.ui);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Soporta los eventos del dialogo de confirmación
   */
  const handleAccept = async () => {
    setIsLoading(true);
    let dataPlayer = player && player.data[0];

    if (dataPlayer && dataPlayer !== undefined) {
      await dispatch(resetSecurityQuestionsPlayer(dataPlayer));
    }
    setOpen(false);
    setIsLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const openModalConfimation = () => {
    setOpen(true);
  };

  /**
   * Soporta el evento para cerrar el modal de confirmación
   */

  const handleCloseModal = () => {
    dispatch(uiSetOpenDialog());
  };

  return (
    <div className="container-fluid">
      <Row
        style={{
          marginTop: "12px",
          pointerEvents: player && player.data.length > 0 ? "all" : "none",
          opacity: player && player.data.length > 0 ? 1 : 0.4,
        }}
      ></Row>
      {player && player.data.length > 0 ? (
        <>
          {player.data[0].answersPlayers &&
          player.data[0].answersPlayers.length > 0 ? (
            <>
              <Box display="flex">
                <i
                  className="fa-solid fa-circle-info"
                  style={{ color: "#02D0E7", marginRight: 9, fontSize: 22 }}
                ></i>
                <p
                  className="txt-primary-md"
                  style={{ fontWeight: 600, fontSize: 17 }}
                >
                  {t("alerts.resetSecurityQuestions")}
                </p>
              </Box>
              <Box borderTop={1} borderColor="#02D0E7" mb={3} />
              <Grid container spacing={2}>
                <Grid item xs={12} pt={3} mt={2}>
                  <Typography
                    color="text.primary"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: 700,
                    }}
                  >
                    {t("securityQuestions.msg1")}
                  </Typography>
                </Grid>
                <Grid item xs={12} pt={3} mt={2}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={LogoExclamation}
                      alt="Icon exclamation"
                      style={{ marginRight: "10px" }}
                    />
                    <Button
                      style={{
                        border: "none",
                        outline: "none",
                        fontSize: 17,
                        fontWeight: 800,
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "40px",
                        paddingRight: "40px",
                      }}
                      variant="contained"
                      className="btn-dialog primary-btn"
                      onClick={openModalConfimation}
                    >
                      {t("alerts.resetSecurityQuestions")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            // MSG PLAYER DOESN'T SECURITY QUESTIONS CONFIG
            <Grid item xs={12} pt={3} mt={-2}>
              <Stack
                sx={{ width: "100%" }}
                style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
                spacing={2}
              >
                <Alert
                  icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
                  sx={{
                    borderLeft: `7px solid #2C97DF`,
                    backgroundColor: "#EAF5FC",
                    color: "#4b4b4b",
                  }}
                >
                  <div className="d-flex">
                    <AlertTitle
                      style={{ marginTop: "5px" }}
                      sx={{ color: "#333333" }}
                    >
                      {t("alerts.playerDoesnotHave")}
                    </AlertTitle>
                    <AlertTitle
                      style={{ marginTop: "5px", marginLeft: "5px" }}
                      sx={{ color: "#333333", fontWeight: "bold" }}
                    >
                      {t("alerts.securityQuestionsConfig")}
                    </AlertTitle>
                  </div>
                </Alert>
              </Stack>
            </Grid>
          )}
        </>
      ) : (
        // MSG FIRST FILTER PLAYER TO SEE INFORMATION
        <Grid item xs={12} pt={3} mt={-2}>
          <Stack
            sx={{ width: "100%" }}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
            spacing={2}
          >
            <Alert
              icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
              sx={{
                borderLeft: `7px solid #2C97DF`,
                backgroundColor: "#EAF5FC",
                color: "#4b4b4b",
              }}
            >
              <div className="d-flex">
                <AlertTitle
                  style={{ marginTop: "5px" }}
                  sx={{ color: "#333333" }}
                >
                  {t("alerts.informationPlayer")}
                </AlertTitle>
                <AlertTitle
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  sx={{ color: "#333333", fontWeight: "bold" }}
                >
                  {t("alerts.resetSecurityQuestions")}
                </AlertTitle>
              </div>
            </Alert>
          </Stack>
        </Grid>
      )}
      <MyDialog
        open={open}
        title={t("dialog.confirmationWindow")}
        message={t("dialog.sureResetSecurityQuestion")}
        handleAccept={handleAccept}
        handleClose={handleCancel}
      />
      {/* DIALOG CONFIRMATION DATA SUCCESS */}
      <DialogParamSettings
        openModal={openDialog}
        handleClose={handleCloseModal}
        title={`${t("dialog.informationWindow")}`}
        content={
          <Grid container spacing={2}>
            {isLoading ? (
              <CircularProgress className={"colorLoading"} />
            ) : (
              <>
                {/* IMG CHECK */}
                <Grid item xs={12} pt={3} mt={2}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={CheckConfirmation}
                      alt="Icon checkConfirmation"
                      style={{ marginRight: "10px" }}
                    />
                  </Grid>
                </Grid>
                {/* TEXT RESET QUESTIONS SUCCESS */}
                <Grid item xs={12} pt={3} mt={2}>
                  <Typography
                    color="text.primary"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: 700,
                    }}
                  >
                    {t("securityQuestions.resetQuestionSuccess")}
                  </Typography>
                  <Box borderTop={1} borderColor="#02D0E7" mt={2} />
                </Grid>
                {/* BUTTON TEXT OK */}
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="flex-end"
                  mt={10}
                >
                  <Button
                    style={{
                      border: "none",
                      outline: "none",
                      fontSize: 14,
                    }}
                    variant="contained"
                    className="btn-dialog primary-btn"
                    onClick={handleCloseModal}
                  >
                    {t("ok")}
                    <i className="fa-solid fa-check-circle ml-2"></i>
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        }
      />
    </div>
  );
};
