import { t } from "i18next";
import { IPromotion, IPromotionForm, IPromotionsFilters, IPromotionsParameters } from "../../interfaces/promotions";
import { createPromotion, deletePromotion, getPromotions, updatePromotion } from "../../services/promotions";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import { setFlagForm, setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";
import { arraySearch, setChangeData } from "./searchActions";

export const startFilterPromotions = (params: IPromotionsFilters, cleanFilter?: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (params.page || rowsPerPage) {
      params.perPage = rowsPerPage;
    }

    try {
      if (token) {
        let finalValues: any = {};
        Object.entries(params).forEach(([key, value], index) => {
          //@ts-ignore
          if (value && value.length > 0) {
            finalValues[key] = value;
          }
        });
        const response = user && (await getPromotions(token, user.operator?.id, finalValues));
        if (response && !response.error) {
          if (cleanFilter) {
            dispatch(arraySearch(null));
            dispatch(setPromotion(response));
          } else {
            dispatch(arraySearch(response));
          }
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetPromotion"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const startCreatePromotions = (data: IPromotionForm) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    try {
      if (user && token) {
        const operatorId = user.operator ? user.operator.id : null;
        data.operator = operatorId;
        const response = await createPromotion(data, token);
        if (response && !response.error) {
          await dispatch(arraySearch(null));

          dispatch(setOpenAlert());
          dispatch(setMessageAlert(response.message));
          await dispatch(setTypeAlert("success"));

          dispatch(setFlagForm({ flagFormCreate: false }));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert(response.error.errorMessage));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const startUpdatePromotion = (dataPromotion: IPromotionForm) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { promotions: promotion } = (getState() as AppState).promotions;
    const { data } = (getState() as AppState).search;
    if (!user || !token) {
      throw new Error("NOT AVAILABLE ACTION");
    }
    try {
      const response = await updatePromotion(dataPromotion, token);
      if (response && !response.error) {
        const newData = data ? { ...data } : promotion && { ...promotion };
        dispatch(data ? arraySearch(newData) : setPromotion(newData));
        dispatch(setOpenAlert());
        dispatch(setMessageAlert(response.message));
        await dispatch(setTypeAlert("success"));
        dispatch(setFlagForm({ flagFormCreate: false }));
        dispatch(setActivePromotion(null));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert(response.error.errorMessage));
        dispatch(setTypeAlert("error"));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const startDeletePromotion = (id: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { data } = (getState() as AppState).search;
    const { promotions: promotion } = (getState() as AppState).promotions;

    if (!token) {
      throw new Error("invalid token");
    }

    const response = await deletePromotion(id, token);
    if (response && !response.error) {
      data && dispatch(setChangeData(true));
      const newData = data ? { ...data } : promotion && { ...promotion };
      newData.data = newData.data.filter((el: any) => el.id !== id);
      const dataSearchFilter = {
        data: newData.data,
        total: newData.total - 1,
        page: newData.page,
        lastPage: newData.lastPage,
      };

      dispatch(
        data ? arraySearch(dataSearchFilter) : setPromotion(dataSearchFilter)
      );

      dispatch(setActivePromotion(null));
      dispatch(setOpenAlert());
      dispatch(setMessageAlert(t("promotions.deleteSuccess")));
      dispatch(setTypeAlert("success"));
    } else {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert(t("promotions.deleteError")));
      dispatch(setTypeAlert("error"));
    }
  };
};

export const setPromotion = (promotion: IPromotionsParameters) => ({
  type: Types.setPromotions,
  payload: promotion,
});

export const setActivePromotion = (promotion: IPromotion | null) => ({
  type: Types.setActivePromotion,
  payload: promotion,
});