import moment from "moment";
import { FC } from "react";
import TableCell from "@mui/material/TableCell";
import { IWithdrawalData } from "../../../../interfaces/withdrawals";
import { buildRedName, getStateSettingName } from "../../../../utils/utils";
interface Props {
  row: IWithdrawalData;
}

export const RowDataTableWithdrawalHistory: FC<Props> = ({ row }) => {

  return (
    <>
      <TableCell className="word-break">{row.id}</TableCell>
      <TableCell className="word-break">{row.meta?.transactionReference || ""}</TableCell>
      <TableCell className="word-break">{row.player.email}</TableCell>
      <TableCell className="word-break">${row.withdrawalValue}</TableCell>
      <TableCell className="word-break">${row.paidValue}</TableCell>
      <TableCell className="word-break">{getStateSettingName(row.state)}</TableCell>
      <TableCell className="word-break">{buildRedName(row)}</TableCell>
      <TableCell className="word-break">
        {moment(row.createAt).format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
      <TableCell />
    </>
  );
};