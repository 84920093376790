import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { TabPanel } from "../../../../components/Tabs";
import { ManagementHeader } from "./colors/header";
import { ManagementFooter } from "./colors/footer";
import { SlidesCasino } from "./slides/SlidesCasino";
import { SlidesDashboard } from "./slides/SlidesDashboard";
import { SlidesCasinoLive } from "./slides/SlidesCasinoLive";
import { SlidesPromotions } from "./slides/SlidesPromotions";
import { ManagementLogo } from "./colors/header/ManagementLogo";
import { SlidesBettingsShortcutBackground } from "./slides/SlidesBettingsShortcutBackground";
import { SlidesBettingsShortcuts } from "./slides/SlidesBettingsShortcuts";
import { FooterImages } from "./slides/FooterImages";
import { PaymentMethods } from "./slides/PaymentMethods";
import { SlidesPageBackground } from "./slides/SlidesPageBackground";
import { ManagementAlternativeLogo } from "./colors/header/ManagementAlternativeLogo";
import { SlidesSignUp } from "./slides/SlidesSignUp";
import { SlidesBingo } from "./slides/SlidesBingo";
import { SlidesVirtualsGoldenRace } from "./slides/SlidesVirtualsGoldenRace";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const styles = {
  "&.Mui-selected": {
    color: "#55BCD9",
    border: "none",
    outline: "none",
  },
};
export const ManagementAdsTabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        style={{ border: "none", outline: "none" }}
        variant="standard"
        TabIndicatorProps={{
          style: {
            backgroundColor: " #02D0E7",
          },
        }}
      >
        <Tab
          sx={styles}
          label={<span className="spanTab">COLORES</span>}
          {...a11yProps(0)}
        />
        <Tab
          sx={styles}
          label={<span className="spanTab">LOGO</span>}
          {...a11yProps(1)}
        />
        <Tab
          sx={styles}
          label={<span className="spanTab">LOGO ALTERNATIVO</span>}
          {...a11yProps(2)}
        />
        <Tab
          sx={styles}
          label={<span className="spanTab">SLIDE HOME PRINCIPAL</span>}
          {...a11yProps(3)}
        />
        <Tab
          sx={styles}
          label={<span className="spanTab">FONDO DEL SITIO</span>}
          {...a11yProps(4)}
        />
        <Tab
          sx={styles}
          label={<span className="spanTab">FONDO ATAJOS APUESTAS</span>}
          {...a11yProps(5)}
        />
        <Tab
          sx={styles}
          label={<span className="spanTab">ATAJOS APUESTAS</span>}
          {...a11yProps(6)}
        />
        <Tab
          sx={styles}
          label={<span className="spanTab">SLIDE CASINO</span>}
          {...a11yProps(7)}
        />
        <Tab
          sx={styles}
          label={<span className="spanTab">SLIDE CASINO LIVE</span>}
          {...a11yProps(8)}
        />
        <Tab
          sx={styles}
          label={<span className="spanTab">PROMOCIONES</span>}
          {...a11yProps(9)}
        />
        <Tab
          sx={styles}
          label={<span className="spanTab">MÉTODOS DE PAGO</span>}
          {...a11yProps(10)}
        />
        <Tab
          sx={styles}
          label={<span className="spanTab">REDES SOCIALES</span>}
          {...a11yProps(11)}
        />
        <Tab
          sx={styles}
          label={<span className="spanTab">IMAGENES FOOTER</span>}
          {...a11yProps(12)}
        />
        <Tab
          sx={styles}
          label={<span className="spanTab">SLIDER REGISTRO</span>}
          {...a11yProps(13)}
        />
        <Tab
          sx={styles}
          label={<span className="spanTab">SLIDER BINGO</span>}
          {...a11yProps(14)}
        />
        <Tab
          sx={styles}
          label={<span className="spanTab">SLIDER VIRTUALS GOLDEN RACE</span>}
          {...a11yProps(15)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ManagementHeader />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ManagementLogo />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ManagementAlternativeLogo />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <SlidesDashboard />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <SlidesPageBackground />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <SlidesBettingsShortcutBackground />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <SlidesBettingsShortcuts />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <SlidesCasino />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <SlidesCasinoLive />
      </TabPanel>
      <TabPanel value={value} index={9}>
        <SlidesPromotions />
      </TabPanel>
      <TabPanel value={value} index={10}>
        <PaymentMethods />
      </TabPanel>
      <TabPanel value={value} index={11}>
        <ManagementFooter />
      </TabPanel>
      <TabPanel value={value} index={12}>
        <FooterImages />
      </TabPanel>
      <TabPanel value={value} index={13}>
        <SlidesSignUp />
      </TabPanel>
      <TabPanel value={value} index={14}>
        <SlidesBingo />
      </TabPanel>
      <TabPanel value={value} index={15}>
        <SlidesVirtualsGoldenRace />
      </TabPanel>
    </>
  );
};
