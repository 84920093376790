import moment from "moment";
import { FC } from "react";
import { View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../views/components/custom/customStyles";
import { headersWithdrawalHistory } from "../../../helpers/headersTables";
import { IWithdrawalData } from "../../../interfaces/withdrawals";
import { buildRedName, getStateSettingName } from "../../../utils/utils";

interface Props {
  page: IWithdrawalData[];
}

export const WithdrawalsHistoryPdfDocument: FC<Props> = ({ page }) => {
  const columns = headersWithdrawalHistory;
  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column) => (
          <View key={column.key} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        {page.map((page: IWithdrawalData) => (
          <View key={page.id} style={stylesPdf.row}>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{page.id}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{page.meta?.transactionReference || ""}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {page.player.email}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {page.withdrawalValue}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {page.paidValue}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {getStateSettingName(page.state)}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {buildRedName(page)}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {moment(page.createAt).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};
