import { IPromotion, IPromotionsParameters } from "../../interfaces/promotions";
import { Types } from "../types/types";

interface IPromotionsActions {
  type: Types;
  payload?: IPromotionsParameters | IPromotion | null;
}

interface IPromotionsState {
  promotions: IPromotionsParameters | null;
  activePromotion: IPromotion | null;
}

const initialState: IPromotionsState = {
  promotions: null,
  activePromotion: null,
};

export const promotionsReducer = (
  state = initialState,
  action: IPromotionsActions
): IPromotionsState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setPromotions:
      return {
        ...state,
        promotions: payload as IPromotionsParameters,
      };
    case Types.setActivePromotion:
      return {
        ...state,
        activePromotion: payload as IPromotion,
      };

    default:
      return state;
  }
};
