import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { TableComponent } from "../../../../components/Table/TableComponent";
import { IColumnsTable } from "../../../../interfaces/components";
import { AppState } from "../../../../redux/reducers/rootReducer";

const columns: IColumnsTable[] = [
  { id: 1, header: "pointsOfSale.productID" },
  { id: 2, header: "pointsOfSale.productName" },
  { id: 3, header: "pointsOfSale.activationDate" },
  { id: 4, header: "form.state" },
];

export const ProductsPointSaleTab = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);
  const { activePointSale } = useSelector(
    (state: AppState) => state.pointsSale
  );

  const products = [
    t("pointsOfSale.sports"),
    t("sidebar.casino"),
    t("pointsOfSale.virtual"),
    t("pointsOfSale.equestrian"),
  ];

  useEffect(() => {
    const arrayProducts: any[] = [];
    if (activePointSale) {
      products.forEach((el, index) => {
        arrayProducts.push({
          id: index + 1,
          name: el,
          activationDate: activePointSale.configGames.find(
            (row) => row.game.name.toLowerCase() === el.toLowerCase()
          )?.createAt,
          state: !!activePointSale.configGames.find(
            (row) => row.game.name === el
          ),
        });
      });
    }
    setData(arrayProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePointSale]);

  return (
    <TableComponent
      columns={columns}
      data={data}
      pages={1}
      component={"commissionalPointSale"}
      totalData={
        data
          ? {
              total: data.length,
              last_page: 1,
              page: 1,
            }
          : []
      }
      credentialsTitle={
        t("associates.listAssociatedProducts").toUpperCase() +
        " " +
        activePointSale?.name.toUpperCase()
      }
      isSortingTable={false}
      dataFilter={{}}
    />
  );
};
