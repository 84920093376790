import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import messages_es from "./language/es.json";
import messages_en from "./language/en.json";

const currentLocale = navigator.language.split(/[-_]/)[0];
const resources = {
  en: {
    translation: messages_en,
  },
  es: {
    translation: messages_es,
  },
};

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: currentLocale,
  resources,
});

export default i18next;
