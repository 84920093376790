import React, { Dispatch, FC, SetStateAction } from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import filePlus from "../../../assets/svg/file-plus.svg";
import { useTranslation } from "react-i18next";
// @ts-ignore
import ReactExport from "react-data-export";
import { getBarcodeCard } from "../../../utils/utils";
import moment from "moment";
import { State } from "../../../enums/enums";

interface Props {
  totalData: any;
  setTooltipIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const ExportDetailsDeliveredCardsData: FC<Props> = ({
  totalData,
  setTooltipIsOpen,
}) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const { t } = useTranslation();

  const onExport = async () => {
    setTooltipIsOpen((prev: boolean) => !prev);
  };
  return (
    <ExcelFile
      element={
        <ListItem role="listitem" onClick={onExport}>
          <ListItemIcon sx={{ mr: -3, ml: -0.2 }}>
            <img width={"19px"} src={filePlus} alt="File plus" />
          </ListItemIcon>
          <ListItemText sx={{ my: 0 }} primary={t("cards.exportCards")} />
        </ListItem>
      }
      filename={`${t("reports.cardIrrigation")
        .split(" ")
        .join("-")}-${moment().toISOString()}`}
    >
      <ExcelSheet data={totalData} name="cardsList">
        <ExcelColumn label={t("id")} value="id" />
        <ExcelColumn
          label={t("reports.deliveredTo")}
          value={(card: any) =>
            card.deliveredTo ? card.deliveredTo : t("cards.nonIrrigated")
          }
        />
        <ExcelColumn label={t("form.value")} value="value" />
        <ExcelColumn
          label={t("form.state")}
          value={(card: any) =>
            card.used
              ? t("cards.redeemed").toUpperCase()
              : card.state === State.ACTIVE
              ? t("state.active").toUpperCase()
              : t("state.inactive").toUpperCase()
          }
        />
        <ExcelColumn
          label={t("cards.barcode")}
          value={(card: any) => getBarcodeCard(card.value)}
        />
        <ExcelColumn label={t("user.observation")} value="observation" />
      </ExcelSheet>
    </ExcelFile>
  );
};
