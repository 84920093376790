import * as React from "react";
import { useTranslation } from "react-i18next";

import { Tabs, Tab, Box, Grid } from "@mui/material";
import { FormPaymentMethodsRefills } from "./FormPaymentMethodsRefills";
import { FormPaymentMethodsPrizes } from "./FormPaymentMethodsPrizes";
import { ICountryStep } from "../../../../interfaces/operationCompany";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const styles = {
  "&.Mui-selected": {
    color: "#208E9A",
    border: "none",
    outline: "none",
  },
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

interface Props {
  formValues: any;
  countriesSelected: ICountryStep[];
  setPaymentMethod(indexCountry: number, value: any): void;
}

export const TabsConfigPaymentMethods = ({
  countriesSelected,
  setPaymentMethod,
  formValues,
}: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      {/* CONFIGURACIÓN MARCA */}
      <Grid item xs={12} className="mb-2">
        <Box
          display="flex"
          className="pb-1"
          borderBottom={1}
          borderColor="#02D0E7"
        >
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <p className="txt-primary-md" style={{ fontWeight: 500 }}>
            {t("companyManagement.configurePaymentMethods")}
          </p>
        </Box>
      </Grid>
      <Box
        sx={{
          width: "100%",
          ml: 2,
        }}
      >
        <Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            style={{ border: "none", outline: "none" }}
            variant="fullWidth"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#208E9A",
              },
            }}
            centered
          >
            <Tab
              sx={styles}
              label={
                <span className="spanTabPayment">
                  {t("companyManagement.paymentMethodsToRefill")}
                </span>
              }
              {...a11yProps(0)}
            />
            <Tab
              sx={styles}
              label={
                <span className="spanTabPayment">
                  <i
                    className="fa fa-money-bill-wave"
                    style={{ marginRight: 10 }}
                  />
                  {t("companyManagement.paymentMethodsToPrizes")}
                </span>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <div className=" mt-4">
          {/* METODOS DE PAGO PARA REALIZAR RECARGAS */}
          <TabPanel value={value} index={0}>
            <FormPaymentMethodsRefills
              formValues={formValues}
              countriesSelected={countriesSelected}
              setPaymentMethod={setPaymentMethod}
            />
          </TabPanel>
          {/* METODOS DE PAGO PARA REALIZAR RETIRO DE PREMIOS */}
          <TabPanel value={value} index={1}>
            <FormPaymentMethodsPrizes
              countriesSelected={countriesSelected}
              setPaymentMethod={setPaymentMethod}
            />
          </TabPanel>
        </div>
      </Box>
    </>
  );
};
