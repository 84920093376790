import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Grid, ThemeProvider, CircularProgress } from "@mui/material";

import { useForm } from "../../../../hooks/useForm";
import { CountryType } from "../../../../components/Custom/CountrySelect";
import { theme } from "../../custom/customStyles";
import {
  setActiveStep,
  setCloseAlert,
  setFlagFormConfig,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { FormConfigBrandCompany } from "./FormConfigBrandCompany";
import { FormConfigCurrencyCompany } from "./FormConfigCurrencyCompany";

import { FormConfigLanguageCompany } from "./FormConfigLanguageCompany";
import { TabsConfigPaymentMethods } from "./TabsConfigPaymentMethods";
import { TabsConfirmation } from "./TabsConfirmation";
import { FormConfigSolutionsCompany } from "./FormConfigSolutionsCompany";
import {
  ICountryStep,
  IFormConfigOperation,
  IPaymentMethodsOn,
  ISolutionsOn,
} from "../../../../interfaces/operationCompany";

import clearLogo from "../../../../assets/svg/icon-park_clear-format.svg";
import { MyDialog } from "../../custom/MyDialog";
import {
  startAddNewConfigCompany,
  setActiveBrand,
  setCompaniesRedux,
  startEditConfigCompany,
  setActiveCompany,
} from "../../../../redux/actions/managementCompanyActions";
import { MyAlert } from "../../custom/MyAlert";
import { getCopyArrayOrObject } from "../../../../utils/utils";
import { setUsersRedux } from "../../../../redux/actions/managementUsersActions";
import { Roles_Enum } from "../../../../enums/enums";
import { getBrandsService } from "../../../../services/managementCompanyService";
import { setLoadingState } from "../../../../redux/actions/helperActions";
import {
  setCurernciesOperator,
  startGetCurrenciesOperator,
} from "../../../../redux/actions/currenciesActions";
interface Props {
  setNextStep: any;
}

export const FormConfigOperationCompany = ({ setNextStep }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { activeStep, openAlert, messageAlert, typeAlert, loading } =
    useSelector((state: AppState) => state.helper);
  const { activeBrand } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const { user, token } = useSelector((state: AppState) => state.auth);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const brandCountries: any[] = activeBrand
    ? getCopyArrayOrObject(activeBrand.countries)
    : [];

  brandCountries.forEach((country, index) => {
    const {
      countriesCurrencies,
      countriesLanguages,
      countriesPayments,
      countriesGames,
      countriesSolutions,
    } = country;
    const parseCurrencies: any[] = [];
    const parseLanguages: any[] = [];
    const parseGames: any[] = [];
    const parseSolutions: any[] = [];
    const parsePaymentMethodRecharges: any[] = [];
    const parsePaymentMethodWithDrawal: any[] = [];
    countriesCurrencies?.forEach(
      (currency: { currency: any }, indexCurrency: any) => {
        parseCurrencies.push(currency.currency);
      }
    );

    countriesLanguages?.forEach(
      (language: { language: any }, indexCurrency: any) => {
        parseLanguages.push(language.language);
      }
    );

    countriesGames?.forEach((game: any, indexCurrency: any) => {
      parseGames.push(game.paramGame?.game);
    });

    countriesSolutions?.forEach((solution: any) => {
      parseSolutions.push(solution.paramSolution?.solution);
    });

    countriesPayments?.forEach((country: any) => {
      const {
        paymentMethod: { type },
      } = country.paramMethod;
      if (type === "withdrawal") {
        parsePaymentMethodWithDrawal.push({
          ...country.paramMethod?.paymentMethod,
          state: country.state,
        });
      } else {
        parsePaymentMethodRecharges.push({
          ...country.paramMethod?.paymentMethod,
          state: country.state,
        });
      }
    });

    brandCountries[index].countriesCurrencies = parseCurrencies;
    brandCountries[index].countriesLanguages = parseLanguages;
    brandCountries[index].countriesGames = parseGames;
    brandCountries[index].countriesSolutions = parseSolutions;
    brandCountries[index].paymentMethodsWithdrawal =
      parsePaymentMethodWithDrawal;
    brandCountries[index].paymentMethodsRecharges = parsePaymentMethodRecharges;
    //country.countriesCurrencies = parseCurrencies
  });
  const [countriesSelected, setCountriesSelected] = useState<ICountryStep[]>(
    activeBrand?.countries ? brandCountries : []
  );
  const [disabled, setDisabled] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [resetForm, setResetForm] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [errorName, setErrorName] = useState(false);
  // Variables para manejar el disabled del botón siguiente
  const noGames = countriesSelected.find(
    (country: ICountryStep) => country.countriesGames?.length === 0
  );
  const noCurrencies = countriesSelected.find(
    (country: ICountryStep) => country.countriesCurrencies?.length === 0
  );
  const noLanguages = countriesSelected.find(
    (country: ICountryStep) => country.countriesLanguages?.length === 0
  );
  const countriesCopy = getCopyArrayOrObject(countriesSelected);

  const initialValues: IFormConfigOperation = {
    id: activeBrand?.id && activeBrand.id,
    name: activeBrand ? activeBrand.name : "",
    countries: activeBrand ? brandCountries : [],
    state: activeBrand ? activeBrand.state : 1,
    createAt: activeBrand?.createAt && activeBrand.createAt,
    createdUser: activeBrand?.createdUser
      ? activeBrand.createdUser
      : { id: user?.id!, nameComplete: user?.nameComplete! },
    operator: activeBrand?.operator && activeBrand.operator,
  };

  const [formValues, handleInputChange] = useForm({
    ...initialValues,
    operator: activeBrand?.operator
      ? activeBrand.operator.id
      : user?.roles.name !== Roles_Enum.SUPER_ADMIN
      ? user?.operator.id
      : 0,
    createdUser: activeBrand?.createdUser
      ? activeBrand.createdUser.id
      : user?.id,
  });
  const getCurrenciesOperator = async (idOperator: number) => {
    const params = user?.operator
      ? {
          operator: user.operator.id,
        }
      : {
          operator: idOperator,
        };
    await dispatch(startGetCurrenciesOperator(params));
  };
  useEffect(() => {
    const idOperator = formValues.operator;
    if (idOperator !== 0) {
      getCurrenciesOperator(idOperator);
    }

    return () => {
      dispatch(setCurernciesOperator([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.operator]);

  const handleSelectCountries = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: CountryType[]
  ) => {
    setCountriesSelected(value);
    formValues.countries = value;
    formValues.countries.length > 0 && setNextStep(true);
  };

  const handleCancel = () => {
    setMessage("dialog.sureExit");
    setCancel(true);
    setOpenDialog(true);
  };

  const handleNext = async () => {
    dispatch(setActiveStep(activeStep + 1));
  };

  const handlePrevious = () => {
    dispatch(setActiveStep(activeStep - 1));
  };

  const handleSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    indexCountry: number,
    value: any
  ) => {
    const {
      target: { name },
    } = event;
    const allCountrySelected = [...countriesSelected];
    let currentCountry = allCountrySelected[indexCountry];
    const existGame = currentCountry.countriesGames?.find(
      (game) => game.name === name
    );
    if (existGame) {
      //delete from arr (user switch off game)
      currentCountry.countriesGames = currentCountry.countriesGames?.filter(
        (game) => game.name !== name
      );
    } else {
      currentCountry.countriesGames?.push(value);
    }
    allCountrySelected[indexCountry] = currentCountry;
    setCountriesSelected(allCountrySelected);
  };

  const setCurrencySelectedByCountry = (indexCountry: number, value: any) => {
    const allCountries = [...countriesSelected];
    allCountries[indexCountry].countriesCurrencies = value;
    setCountriesSelected(allCountries);
  };

  const setLanguagesSelectedByCountry = (indexCountry: number, value: any) => {
    const allCountries = [...countriesSelected];
    allCountries[indexCountry].countriesLanguages = value;
    setCountriesSelected(allCountries);
  };

  const setPaymentMethodsByCountry = (
    indexCountry: number,
    method: IPaymentMethodsOn,
    fromRecharges?: boolean
  ) => {
    const allCountries = [...countriesSelected];
    let currentCountry = allCountries[indexCountry];
    const { id } = method;
    const existPaymentMethod = fromRecharges
      ? currentCountry.paymentMethodsRecharges?.find((pay) => pay.id === id)
      : currentCountry.paymentMethodsWithdrawal?.find((pay) => pay.id === id);
    if (existPaymentMethod) {
      //delete from arr (user switch off payment method)
      fromRecharges
        ? (currentCountry.paymentMethodsRecharges =
            currentCountry.paymentMethodsRecharges?.map((pay) =>
              pay.id === id ? { ...pay, state: pay.state === 1 ? 0 : 1 } : pay
            ))
        : (currentCountry.paymentMethodsWithdrawal =
            currentCountry.paymentMethodsWithdrawal?.map((pay) =>
              pay.id === id ? { ...pay, state: pay.state === 1 ? 0 : 1 } : pay
            ));
    } else {
      fromRecharges
        ? currentCountry.paymentMethodsRecharges?.push({
            ...method,
            state: 1,
          })
        : currentCountry.paymentMethodsWithdrawal?.push({
            ...method,
            state: 1,
          });
    }
    allCountries[indexCountry] = currentCountry;
    setCountriesSelected(allCountries);
  };

  const setSolutionsByCountry = (
    indexCountry: number,
    solution: ISolutionsOn
  ) => {
    const allCountries = [...countriesSelected];
    let currentCountry = allCountries[indexCountry];
    const { name } = solution;

    const existSolution = currentCountry.countriesSolutions?.find(
      (sol) => sol.name === name
    );

    if (existSolution) {
      currentCountry.countriesSolutions =
        currentCountry.countriesSolutions?.filter((sol) => sol.name !== name);
    } else {
      currentCountry.countriesSolutions?.push(solution);
    }
    allCountries[indexCountry] = currentCountry;
    setCountriesSelected(allCountries);
  };

  const handleSaveConfiguration = async () => {
    setMessage(
      activeBrand
        ? "pointsOfSale.sureApplyChanges"
        : "companyManagement.sureCreateBrand"
    );
    setCancel(false);
    setResetForm(false);
    setOpenDialog(true);
  };

  // Efecto para manejar el next button
  useEffect(() => {
    setNextStep(true);
    if (activeStep === 0) {
      if (
        formValues.name === "" ||
        noGames ||
        countriesSelected.length === 0 ||
        (user?.roles.name === Roles_Enum.SUPER_ADMIN &&
          formValues.operator === 0)
      ) {
        setDisabled(true);
        setNextStep(false);
      } else {
        setDisabled(false);
        setNextStep(true);
      }
    }
    if (activeStep === 1) {
      setDisabled(!!noCurrencies);
      setNextStep(!noCurrencies);
      !!noGames && dispatch(setActiveStep(0));
    }
    if (activeStep === 2) {
      setDisabled(!!noLanguages);
      setNextStep(!noLanguages);
      !!noCurrencies && dispatch(setActiveStep(1));
    }
    if (activeStep === 3) {
      const noPaymentMethodsRefills = countriesCopy.find(
        (country: ICountryStep) =>
          country.paymentMethodsRecharges?.filter((el: any) => el.state)
            ?.length === 0
      );
      const noPaymentMethodsWithdrawal = countriesSelected.find(
        (country: ICountryStep) =>
          country.paymentMethodsWithdrawal?.filter((el: any) => el.state)
            ?.length === 0
      );
      const noMethods = noPaymentMethodsRefills || noPaymentMethodsWithdrawal;
      setDisabled(!!noMethods);
      setNextStep(!noMethods);
      noLanguages && dispatch(setActiveStep(2));
    }
    if (activeStep === 4 || activeStep === 5) {
      const noPaymentMethodsRefills = countriesCopy.find(
        (country: ICountryStep) =>
          country.paymentMethodsRecharges?.filter((el: any) => el.state)
            ?.length === 0
      );
      const noPaymentMethodsWithdrawal = countriesSelected.find(
        (country: ICountryStep) =>
          country.paymentMethodsWithdrawal?.filter((el: any) => el.state)
            ?.length === 0
      );
      const noMethods = noPaymentMethodsRefills || noPaymentMethodsWithdrawal;
      if (noMethods) {
        dispatch(setActiveStep(3));
      } else {
        setDisabled(false);
        setNextStep(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesSelected, activeStep, formValues]);

  useEffect(() => {
    handleInputChange({
      target: {
        name: "countries",
        value: countriesSelected,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesSelected]);

  const handleCleanForm = () => {
    setMessage("dialog.sureClean");
    setResetForm(true);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCancel(false);
    setResetForm(false);
  };

  const handleCleanStep1 = () => {
    formValues.name = "";
    if (isSuper) {
      formValues.operator = 0;
    }
    setCountriesSelected(
      countriesSelected.map((country) => {
        return { ...country, countriesGames: [] };
      })
    );
  };

  const handleCleanStep2 = () => {
    setCountriesSelected(
      countriesSelected.map((country) => {
        return { ...country, countriesCurrencies: [] };
      })
    );
  };

  const handleCleanStep3 = () => {
    setCountriesSelected(
      countriesSelected.map((country) => {
        return { ...country, countriesLanguages: [] };
      })
    );
  };

  const handleCleanStep4 = () => {
    setCountriesSelected(
      countriesSelected.map((country) => {
        return {
          ...country,
          paymentMethodsRecharges: [],
          paymentMethodsWithdrawal: [],
        };
      })
    );
  };

  const handleCleanStep5 = () => {
    setCountriesSelected(
      countriesSelected.map((country) => {
        return { ...country, countriesSolutions: [] };
      })
    );
  };

  const handleCleanFields = () => {
    if (activeStep === 0) {
      handleCleanStep1();
    }
    if (activeStep === 1) {
      handleCleanStep2();
    }
    if (activeStep === 2) {
      handleCleanStep3();
    }
    if (activeStep === 3) {
      handleCleanStep4();
    }
    if (activeStep === 4) {
      handleCleanStep5();
    }
    if (activeStep === 5) {
      handleCleanStep1();
      setCountriesSelected(
        countriesSelected.map((country) => {
          return {
            ...country,
            countriesGames: [],
            countriesCurrencies: [],
            countriesLanguages: [],
            countriesSolutions: [],
            paymentMethodsRecharges: [],
            paymentMethodsWithdrawal: [],
          };
        })
      );
      setDisabled(true);
      setNextStep(false);
      dispatch(setActiveStep(0));
    }
  };

  const handleAccept = async () => {
    handleCloseDialog();

    if (resetForm && !cancel) {
      setDisabled(true);
      setNextStep(false);
      setCancel(false);
      setResetForm(false);
      return handleCleanFields();
    }

    if (cancel) {
      dispatch(setFlagFormConfig({ flagFormConfig: false }));
      dispatch(setActiveBrand(null));
      dispatch(setActiveCompany(null));
      setCancel(false);
      setResetForm(false);
    } else {
      setCancel(false);
      setResetForm(false);
      const data = { ...formValues };
      data.countries = countriesSelected;

      data.operator = activeBrand
        ? //@ts-ignore
          activeBrand.operator.id
        : user?.roles.name === Roles_Enum.SUPER_ADMIN
        ? formValues.operator
        : user?.operator.id;

      data.createdUser = activeBrand ? activeBrand.createdUser?.id : user?.id;

      if (token && user) {
        dispatch(setLoadingState({ loading: true }));
        if (
          (activeBrand &&
            data.name.toLowerCase() !== activeBrand.name.toLowerCase()) ||
          !activeBrand
        ) {
          const getBrand = await existsBrand(data as any);

          if (getBrand && getBrand.data && getBrand.data.length > 0) {
            setErrorName(true);
            dispatch(setLoadingState({ loading: false }));
            dispatch(setMessageAlert("companyManagement.brandNameExists"));
            dispatch(setTypeAlert("error"));
            dispatch(setOpenAlert());
            return dispatch(setActiveStep(0));
          } else {
            activeBrand
              ? await dispatch(startEditConfigCompany(data))
              : await dispatch(startAddNewConfigCompany(data));
          }
        } else {
          activeBrand && (await dispatch(startEditConfigCompany(data)));
        }
      }
    }
  };

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
    if (typeAlert === "success") {
      dispatch(setFlagFormConfig({ flagFormConfig: false }));
      dispatch(setLoadingState({ loading: false }));
      dispatch(setActiveBrand(null));
      dispatch(setActiveCompany(null));
    }
  };

  useEffect(() => {
    dispatch(setActiveStep(0));
    return () => {
      dispatch(setCompaniesRedux(null));
      dispatch(setUsersRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const existsBrand = async (data: any) => {
    if (token) {
      const resp = await getBrandsService(token, null, undefined, {
        brand: data.name,
        exact: true,
        operatorId: data.operator ? data.operator : null,
      });

      return resp;
    }
  };

  return (
    <div className="ml-4 mr-4">
      <div className="filter pl-5">
        <ThemeProvider theme={theme}>
          <Grid container spacing={2}>
            {/* ********************************** */}
            {/*                STEP 1              */}
            {/* ********************************** */}

            {activeStep === 0 && (
              <FormConfigBrandCompany
                formValues={formValues}
                countriesSelected={countriesSelected}
                handleInputChange={handleInputChange}
                handleSwitch={handleSwitch}
                handleSelectCountry={handleSelectCountries}
                errorName={errorName}
                setErrorName={setErrorName}
              />
            )}

            {/* ********************************** */}
            {/*                STEP 2              */}
            {/* ********************************** */}
            {activeStep === 1 && (
              <FormConfigCurrencyCompany
                countriesSelected={countriesSelected}
                setCurrencySelected={setCurrencySelectedByCountry}
              />
            )}

            {/* ********************************** */}
            {/*                STEP 3              */}
            {/* ********************************** */}
            {activeStep === 2 && (
              <FormConfigLanguageCompany
                countriesSelected={countriesSelected}
                //languagesSelected={languagesSelected}
                setLanguagesSelected={setLanguagesSelectedByCountry}
              />
            )}

            {/* ********************************** */}
            {/*                STEP 4              */}
            {/* ********************************** */}
            {activeStep === 3 && (
              <TabsConfigPaymentMethods
                formValues={formValues}
                countriesSelected={countriesSelected}
                setPaymentMethod={setPaymentMethodsByCountry}
              />
            )}

            {/* ********************************** */}
            {/*                STEP 5              */}
            {/* ********************************** */}
            {activeStep === 4 && (
              <FormConfigSolutionsCompany
                formValues={formValues}
                countriesSelected={countriesSelected}
                setSolutions={setSolutionsByCountry}
              />
            )}

            {/* ********************************** */}
            {/*                STEP 6              */}
            {/* ********************************** */}
            {activeStep === 5 && <TabsConfirmation formValues={formValues} />}

            <Grid
              item
              xs={12}
              mt={2}
              display="flex"
              justifyContent="center"
              sx={{ height: "60px" }}
            >
              {activeStep > 0 && (
                <Button
                  style={{
                    border: "none",
                    outline: "none",
                  }}
                  variant="contained"
                  className="footer-btn primary-btn ripple"
                  onClick={handlePrevious}
                  disabled={!!loading}
                >
                  <i className="fa-solid fa-angle-left pl-2"></i>
                  {t("button.previous").toUpperCase()}
                </Button>
              )}
              <Button
                style={{ border: "none", outline: "none" }}
                variant="contained"
                className="footer-btn danger-btn ripple"
                onClick={handleCancel}
                disabled={!!loading}
              >
                <i className="fa-solid fa-x"></i>
                {t("button.cancel").toUpperCase()}
              </Button>

              {activeStep === 5 ? (
                <Button
                  style={{
                    border: "none",
                    outline: "none",
                  }}
                  variant="contained"
                  className="footer-btn primary-btn ripple"
                  onClick={handleSaveConfiguration}
                  disabled={!!loading}
                >
                  {loading ? (
                    <CircularProgress size={"1.6rem"} color="inherit" />
                  ) : (
                    <>
                      <i className="fa-solid fa-check-circle"></i>

                      {t(
                        activeBrand ? "button.edit" : "button.save"
                      ).toUpperCase()}
                    </>
                  )}
                </Button>
              ) : (
                <Button
                  style={{
                    border: "none",
                    outline: "none",
                  }}
                  variant="contained"
                  className="footer-btn primary-btn ripple"
                  disabled={disabled}
                  onClick={handleNext}
                >
                  {t("button.next").toUpperCase()}
                  <i className="fa-solid fa-angle-right pl-2"></i>
                </Button>
              )}

              <Button
                type="button"
                style={{
                  border: "none",
                  outline: "none",
                  marginLeft: "4px",
                  opacity:
                    !!activeBrand || !!loading || activeStep === 5 ? "0.3" : "",
                }}
                onClick={handleCleanForm}
                disabled={!!activeBrand || !!loading || activeStep === 5}
              >
                <img src={clearLogo} alt="Icon clear" />
              </Button>
            </Grid>
          </Grid>
          <MyDialog
            open={openDialog}
            title={t("dialog.confirmationWindow")}
            message={t(message)}
            handleAccept={handleAccept}
            handleClose={handleCloseDialog}
            isLoading={!!loading}
          />

          <MyAlert
            open={openAlert}
            message={messageAlert ? messageAlert : ""}
            typeAlert={typeAlert}
            handleClose={onCloseAlert}
          />
        </ThemeProvider>
      </div>
    </div>
  );
};
