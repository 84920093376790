import { useTranslation } from "react-i18next";

import { Box, Grid } from "@mui/material";

import { LanguageSelect } from "../../../../components/Custom/LanguageSelect";
import { ICountryStep } from "../../../../interfaces/operationCompany";

interface Props {
  countriesSelected: ICountryStep[];
  //languagesSelected: LanguageType[];
  setLanguagesSelected: any;
}

export const FormConfigLanguageCompany = ({
  countriesSelected,
  //languagesSelected,
  setLanguagesSelected,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {/* CONFIGURACIÓN IDIOMA */}
      <Grid item xs={12} className="mb-2">
        <Box
          display="flex"
          className="pb-1"
          borderBottom={1}
          borderColor="#02D0E7"
        >
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <p className="txt-primary-md" style={{ fontWeight: 500 }}>
            {t("companyManagement.configureLanguage")}
          </p>
        </Box>
      </Grid>

      {countriesSelected.map((country, index) => (
        <Box
          width={1}
          ml={2}
          mt={2}
          mb={1}
          borderBottom={1}
          borderColor="#9E9E9E"
          key={country.id}
        >
          <Grid container mb={2}>
            <Grid item xs={5} display="flex" alignItems="flex-end">
              <img
                loading="lazy"
                style={{
                  height: "50px",
                  width: "50px",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
                alt="Country"
                src={country.imgUrl}
              />
              <label className="label-text">
                {(country.name as string).toUpperCase()}
              </label>
            </Grid>

            <Grid item xs={7}>
              <label className="label-text">
                {t("companyManagement.selectLanguage")}*
              </label>
              {country.countriesLanguages && (
                <LanguageSelect
                  indexCountry={index}
                  languagesSelected={country.countriesLanguages}
                  setLanguagesSelected={setLanguagesSelected}
                  idCountry={parseInt(country.id)}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      ))}
    </>
  );
};
