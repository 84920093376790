import { IContentManagementState } from "../redux/reducers/contentManagementReducer";

export const createConfigColorsOperator = (
  token: string,
  operator: number,
  setting: Partial<IContentManagementState>
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(setting),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}colors/${operator}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
export const setLogoOperatorService = (
  token: string,
  operator: number,
  logo: File,
  alternative: boolean
) => {
  const myHeaders = new Headers();
  myHeaders.append("api", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Authorization", `bearer ${token}`);
  const formData = new FormData();
  formData.append("operator", operator.toString());

  if (alternative) {
    formData.append("alternativeLogo", logo, logo.name);
    formData.append("alternative", alternative.toString());
  } else {
    formData.append("logo", logo, logo.name);
  }

  const requestOptions: any = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}operatorLogo`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
