import { IPointSaleForm, IPointsOfSale } from "../../interfaces/pointsSale";
import { Types } from "../types/types";

interface IPointsSaleActions {
  type: Types;
  payload?: IPointsOfSale | IPointSaleForm;
}

interface IPointsSaleDataState {
  pointsSale: IPointsOfSale | null;
  activePointSale: IPointSaleForm | null;
}

const initialState: IPointsSaleDataState = {
  pointsSale: null,
  activePointSale: null,
};

export const pointsSaleReducer = (
  state = initialState,
  action: IPointsSaleActions
): IPointsSaleDataState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setPointsSale:
      return {
        ...state,
        pointsSale: payload as IPointsOfSale,
      };
    case Types.addPointSale:
      const newPointSale = state.pointsSale
        ? ({
            ...state.pointsSale,
            data: [payload, ...state.pointsSale.data],
          } as IPointsOfSale)
        : state.pointsSale;
      return {
        ...state,
        pointsSale: newPointSale,
      };

    case Types.setActivePointSale:
      return {
        ...state,
        activePointSale: payload as IPointSaleForm,
      };

    case Types.updatePointSale:
      const pointsSaleUpdated = state.pointsSale
        ? ({
            ...state.pointsSale,
            data: state.pointsSale.data.map((row) =>
              row.id === (payload as IPointSaleForm).id
                ? (payload as IPointSaleForm)
                : row
            ),
          } as IPointsOfSale)
        : state.pointsSale;
      return {
        ...state,
        pointsSale: pointsSaleUpdated,
      };

    default:
      return state;
  }
};
