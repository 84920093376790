import moment from "moment";
import { useSelector } from "react-redux";
import { State } from "../../../enums/enums";
import { headersLegal } from "../../../helpers/headersTables";
import { AppState } from "../../../redux/reducers/rootReducer";
import { getCopyArrayOrObject } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

export const LegalDataTable = () => {
  const { t } = useTranslation();
  let { legalParameters, totalLegal } = useSelector(
    (state: AppState) => state.legal
  );

  let { data } = useSelector((state: AppState) => state.search);

  const copyLegal = data
    ? getCopyArrayOrObject(data)
    : getCopyArrayOrObject(legalParameters);

  const copyTotalLegal = getCopyArrayOrObject(totalLegal);
  const headersCSVLegal = headersLegal;

  //   COLUMNS HEADERS STYLES
  const headersCvsStyle = headersCSVLegal.map((header) => {
    return {
      title: header.label,
      width: { wpx: 150 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });
  // Obtener datos por vista
  const dataLegal = copyLegal
    ? copyLegal.data.map((legal: any) => {
        legal.state =
          legal.state === State.ACTIVE
            ? t("state.active")
            : t("state.inactive");
        legal.createAt = moment(legal.createAt).format("DD/MM/YYYY HH:mm:ss");
        return legal;
      })
    : [];

  // Obtener el total de datos
  const dataTotalLegal = copyTotalLegal
    ? copyTotalLegal.data.map((legal: any) => {
        legal.state =
          legal.state === State.ACTIVE
            ? t("state.active")
            : t("state.inactive");
        legal.createAt = moment(legal.createAt).format("DD/MM/YYYY HH:mm:ss");
        return legal;
      })
    : [];

  // DATA POR VISTA
  const multiDataSetLegal = [
    {
      columns: headersCvsStyle,
      data: dataLegal.map((data: any) => {
        let array;
        array = [
          {
            value: "V." + data.version,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.title,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.state,
            style: {
              fill: {
                patternType: "solid",
                fgColor: {
                  rgb: data.state === t("state.active") ? "c6efce" : "ffc7ce",
                },
              },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: data.createAt,
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  //   DATA TOTAL
  const multiTotalDataSetLegal = [
    {
      columns: headersCvsStyle,
      data: dataTotalLegal.map((data: any) => {
        let array;
        array = [
          {
            value: "V." + data.version,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.title,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.state,
            style: {
              fill: {
                patternType: "solid",
                fgColor: {
                  rgb: data.state === t("state.active") ? "c6efce" : "ffc7ce",
                },
              },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: data.createAt,
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  return {
    dataLegal,
    dataTotalLegal,
    headersCSVLegal: headersCSVLegal,
    multiDataSetLegal,
    multiTotalDataSetLegal,
  };
};
