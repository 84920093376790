import {
  IDataMassiveTransactionSettings,
  ITransactionForm,
  ITransactionSettingParams,
} from "../interfaces/transactionSettings";

export const getWallets = (token: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}wallet`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
export const getTransactionSettings = (
  token: string,
  params: Partial<ITransactionSettingParams>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}transactionalSetting?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const addMassiveTransactionSetting = (
  token: string,
  settings: IDataMassiveTransactionSettings
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(settings),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}transactionalSetting/create/massive`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const addTransactionSetting = (
  token: string,
  setting: ITransactionForm
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(setting),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}transactionalSetting`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
