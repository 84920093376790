import { Box, Button, CircularProgress, Grid, MenuItem } from "@mui/material";
import { Form, Formik } from "formik";
import React, { KeyboardEvent, useState } from "react";
import { MyTextField } from "../custom/MyTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  setCloseAlert,
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import clearLogo from "../../../assets/svg/icon-park_clear-format.svg";
import { regLetters, regNumbers, regPassport } from "../../../utils/utils";
import { AppState } from "../../../redux/reducers/rootReducer";
import { DocumentTypes, ExclusionType, State } from "../../../enums/enums";
import { IExclusion } from "../../../interfaces/exclusions";
import moment from "moment";
import {
  filterPlayers,
  getExclusions,
} from "../../../services/managementPlayersService";
import { MyAlert } from "../custom/MyAlert";
import { createNewExclusion } from "../../../redux/actions/managementPlayersActions";
import { IDocumentTypes } from "../../../redux/interfaces/shared";
interface Props {
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setWidth: React.Dispatch<React.SetStateAction<"md" | "sm" | "lg">>;
  setCreatedExclusion: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ExclusionFormByAgileCheck = ({
  setTitle,
  setWidth,
  setCreatedExclusion,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [cancel, setCancel] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [resetFunction, setResetFunction] = useState<Function>();
  const [totalData, setTotalData] = useState<any>();
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { exclusionCategories } = useSelector(
    (state: AppState) => state.managementPlayers
  );
  const { openAlert, messageAlert, typeAlert, documentTypes } = useSelector(
    (state: AppState) => state.helper
  );

  const initialValues = {
    number: "",
    name: "",
    category: "",
    documentType: "",
  };

  const validationSchema = yup.object({
    documentType: yup.number().required(t("form.requiredFile")),
    number: yup
      .string()
      .required(t("form.requiredFile"))
      .when("documentType", {
        is: (value: number) =>
          [DocumentTypes.CITIZENSHIP_CARD, DocumentTypes.PASSPORT].includes(
            value
          ),
        then: yup
          .string()
          .min(7, t("form.invalidLengthDocumentNumber", { min: 7, max: 10 }))
          .max(10, t("form.invalidLengthDocumentNumber", { min: 7, max: 10 })),
      })
      .when("documentType", {
        is: DocumentTypes.CIP,
        then: yup
          .string()
          .min(7, t("form.invalidLengthDocumentNumber", { min: 7, max: 13 }))
          .max(13, t("form.invalidLengthDocumentNumber", { min: 7, max: 13 })),
      })
      .when("documentType", {
        is: DocumentTypes.DNI,
        then: yup
          .string()
          .min(8, t("form.invalidLengthDocumentNumber", { min: 8, max: 13 }))
          .max(13, t("form.invalidLengthDocumentNumber", { min: 8, max: 13 })),
      })
      .when("documentType", {
        is: (value: number) =>
          [DocumentTypes.DUI, DocumentTypes.CE].includes(value),
        then: yup
          .string()
          .min(5, t("form.invalidLengthDocumentNumber", { min: 5, max: 15 }))
          .max(15, t("form.invalidLengthDocumentNumber", { min: 5, max: 15 })),
      })
      .when("documentType", {
        is: DocumentTypes.DPI,
        then: yup
          .string()
          .min(13, t("form.invalidLengthDocument", { value: 13 }))
          .max(13, t("form.invalidLengthDocument", { value: 13 })),
      }),
    name: yup.string().required(t("form.requiredFile")),
    category: yup.string().required(t("form.requiredFile")),
  });

  const changeConfirmation = () => {
    setTitle(t("dialog.confirmationWindow"));
    setWidth("sm");
  };

  const changeForm = () => {
    setTitle(t("exclusions.newExclusion"));
    setWidth("md");
  };

  const openConfirmation = () => {
    changeConfirmation();
    setMessage("exclusions.sureExcludePlayer");
    setOpenDialog(true);
  };

  const handleCloseConfirmation = () => {
    changeForm();
    setOpenDialog(false);
    setCancel(false);
    setResetForm(false);
  };

  const handleCancel = () => {
    changeConfirmation();
    setMessage("dialog.sureCancelExclusion");
    setCancel(true);
    setOpenDialog(true);
    setResetForm(false);
  };

  const handleCleanForm = () => {
    changeConfirmation();
    setMessage("dialog.sureClean");
    setResetForm(true);
    setOpenDialog(true);
    setCancel(false);
  };

  const handleAccept = async () => {
    handleCloseConfirmation();

    if (resetForm && !cancel) {
      return resetFunction && resetFunction();
    }
    if (cancel) {
      dispatch(setFlagForm({ flagFormCreate: false }));
    } else {
      if (totalData && token) {
        setLoading(true);

        const player = await validatePlayer();
        if (player) {
          const existsExclusion = await validateExclusion(player.id);
          if (existsExclusion) {
            dispatch(
              setMessageAlert(
                t("exclusions.playerAlreadyExcluded") +
                  existsExclusion.category.name
              )
            );
            dispatch(setTypeAlert("error"));
            dispatch(setOpenAlert());
          } else {
            let newExclusion: IExclusion = {
              startDate: moment(new Date()).format("YYYY-MM-DD"),
              endDate: null,
              category: totalData.category,
              totalExclusion: false,
              player: player.id,
              state: 1,
            };

            token &&
              (await dispatch(
                createNewExclusion(
                  token,
                  newExclusion,
                  true,
                  null,
                  setCreatedExclusion
                )
              ));
          }
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("exclusions.playerInformationError"));
          dispatch(setTypeAlert("error"));
        }
        setLoading(false);
      }
    }
    setCancel(false);
    setResetForm(false);
    setOpenDialog(false);
  };

  const validatePlayer = async () => {
    if (token && user) {
      const player = await filterPlayers(
        token,
        {
          name: totalData.name,
          documentType: totalData.documentType,
          numberDocument: totalData.number,
          exact: "true",
        },
        user.roles.name,
        user.operator?.id
      );

      return player && player.data && player.data.length > 0
        ? player.data[0]
        : null;
    }
  };

  const validateExclusion = async (playerId: number) => {
    if (token) {
      const paramsExclusions = {
        playerId: playerId,
        type: ExclusionType.AGILE_CHECK,
        state: State.ACTIVE,
      };

      const existsExclusion = await getExclusions(token, paramsExclusions);
      return existsExclusion &&
        existsExclusion.data &&
        existsExclusion.data.length > 0
        ? existsExclusion.data[0]
        : null;
    }
  };

  const onCloseAlert = () => {
    dispatch(setCloseAlert());
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      enableReinitialize={true}
      onSubmit={async (data, { setSubmitting }) => {
        setSubmitting(true);
        openConfirmation();
        setTotalData(data);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, handleChange, values, handleReset, setFieldValue }) => (
        <Form>
          {openDialog ? (
            <>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="80px"
              >
                <span className="font-weight-bold">{t(message)}</span>
              </Box>
              <Box display="flex" justifyContent="flex-end" mb={-2}>
                <Button
                  type="button"
                  style={{
                    border: "none",
                    outline: "none",
                    backgroundColor: "#FF5757",
                  }}
                  variant="contained"
                  className="ripple"
                  onClick={handleCloseConfirmation}
                >
                  {t("table.not")}
                  <i className="fa fa-circle-xmark ml-2"></i>
                </Button>
                &nbsp;&nbsp;
                <Button
                  type="button"
                  id="btnSave"
                  data-testid="btnSubmit"
                  style={{
                    border: "none",
                    outline: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "#02D0E7",
                  }}
                  variant="contained"
                  className="ripple"
                  onClick={handleAccept}
                  disabled={loading}
                >
                  {t("user.yes")}
                  <i className="fa fa-circle-check ml-2"></i>
                </Button>
              </Box>
            </>
          ) : (
            <Grid container spacing={2} mb={-2}>
              <Grid item xs={4}>
                <label>{t("exclusions.userDocumentType")}*</label>
                <MyTextField
                  name="documentType"
                  label={
                    values.documentType !== ""
                      ? " "
                      : t("exclusions.enterDocumentType")
                  }
                  select
                  InputLabelProps={{ shrink: false }}
                  value={values.documentType}
                  onChange={(event: any, value: IDocumentTypes | null) => {
                    handleChange(event);
                    setFieldValue("number", "");
                  }}
                >
                  {documentTypes.map((item) => (
                    <MenuItem key={item.name} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>
              <Grid item xs={4}>
                <label>{t("exclusions.userDocumentNumber")}*</label>
                <MyTextField
                  name="number"
                  onChange={handleChange}
                  placeholder={t("exclusions.enterDocumentNumber")}
                  inputProps={{
                    maxLength: 15,
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (
                      [DocumentTypes.PASSPORT, DocumentTypes.CE].includes(
                        +values.documentType
                      )
                        ? !regPassport(e.key)
                        : !regNumbers(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <label>
                  {t("user.fullName")} {t("login.user").toLowerCase()}*
                </label>
                <MyTextField
                  name="name"
                  onChange={handleChange}
                  placeholder={t("exclusions.enterUsername")}
                  autoComplete="off"
                  inputProps={{ maxLength: 80 }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regLetters(e.key) && e.preventDefault()
                  }
                />
              </Grid>

              <Grid item xs={4}>
                <label>{t("exclusions.exclusionReason")}*</label>
                <MyTextField
                  name="category"
                  onChange={handleChange}
                  InputLabelProps={{ shrink: false }}
                  label={values.category === "" ? "Seleccionar" : " "}
                  select
                >
                  {exclusionCategories?.data
                    ?.filter((cat) => cat.type.id === ExclusionType.AGILE_CHECK)
                    .map((cat) => (
                      <MenuItem key={cat.id} value={cat.id}>
                        {cat.name}
                      </MenuItem>
                    ))}
                </MyTextField>
              </Grid>

              <Grid item xs={12} mt={15} className="gridButton">
                <Button
                  type="button"
                  style={{ border: "none", outline: "none", height: "42px" }}
                  variant="contained"
                  className="footer-btn danger-btn ripple"
                  onClick={handleCancel}
                >
                  <i className="fa-solid fa-x"></i>
                  {t("button.cancel")}
                </Button>
                <Button
                  type="submit"
                  id="btnSave"
                  data-testid="btnSubmit"
                  style={{
                    border: "none",
                    outline: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    height: "42px",
                  }}
                  variant="contained"
                  className="footer-btn primary-btn ripple"
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={"1.6rem"} color="inherit" />
                  ) : (
                    t("button.save")
                  )}
                  {!loading && <i className="fa-solid fa-angle-right ml-2"></i>}
                </Button>

                <Button
                  type="button"
                  style={{
                    border: "none",
                    outline: "none",
                    marginLeft: "4px",
                    height: "42px",
                  }}
                  onClick={() => {
                    setResetFunction(() => handleReset);
                    handleCleanForm();
                  }}
                  disabled={!!loading}
                >
                  <img src={clearLogo} alt="Icon clear" />
                </Button>
              </Grid>
            </Grid>
          )}
          <MyAlert
            open={openAlert}
            message={messageAlert ? messageAlert : ""}
            typeAlert={typeAlert}
            handleClose={onCloseAlert}
          />
        </Form>
      )}
    </Formik>
  );
};
