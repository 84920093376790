import moment from "moment";
import { FC } from "react";
import TableCell from "@mui/material/TableCell";
import { ExclusionType } from "../../../../enums/enums";
import { useTranslation } from "react-i18next";

interface Props {
  row: any;
}

export const RowDataTableExclusion: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  return (
    <>
      <TableCell className="word-break">{row.id}</TableCell>
      <TableCell className="word-break">{row.player?.id}</TableCell>
      <TableCell className="word-break">
        {row.player?.firstName + " " + row.player?.lastName}
      </TableCell>
      <TableCell className="word-break">
        {moment(row.startDate).format("DD/MM/YYYY HH:mm:ss")}
      </TableCell>
      <TableCell className="word-break">
        {row.category?.type?.id === ExclusionType.CATEGORY &&
        !row.totalExclusion
          ? moment(row.endDate).format("DD/MM/YYYY - hh:mm:ss A") || ""
          : "N/A"}
      </TableCell>
      <TableCell className="word-break">
        {row.totalExclusion
          ? t("exclusionsReport.totalExclusion")
          : row.category?.name}
      </TableCell>
      <TableCell className="word-break">
        {t("exclusionsReport.by") + " " + row.category?.type?.name}
      </TableCell>
      <TableCell />
    </>
  );
};
