import React, { Fragment } from "react";

import { Grid, ThemeProvider } from "@mui/material";
import { theme } from "../../custom/customStyles";
import { MyToggleButton } from "../../../../components/Custom/MyToggleButton";
import { ICountryStep } from "../../../../interfaces/operationCompany";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { IPaymentMethods } from "../../../../redux/interfaces/shared";
import { useTranslation } from "react-i18next";

interface Props {
  countriesSelected: ICountryStep[];
  setPaymentMethod(
    indexCountry: number,
    value: any,
    fromRecharges?: boolean
  ): void;
}

export const FormPaymentMethodsPrizes = ({
  countriesSelected,
  setPaymentMethod,
}: Props) => {
  const { paymentMethods: countryPaymentMethods } = useSelector(
    (state: AppState) => state.helper
  );
  const { t } = useTranslation();

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    state: IPaymentMethods,
    indexCountry: number
  ) => {
    setPaymentMethod(indexCountry, state);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={5}>
        {countriesSelected.map((country, index) => (
          <Grid item xs={4} p={4} key={country.id}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <img
                    loading="lazy"
                    style={{
                      height: "50px",
                      width: "50px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                    alt="Country"
                    src={country.imgUrl}
                  />
                  <label className="label-text">{country.name}</label>
                </Grid>
              </Grid>

              {/* <Grid item xs={12} display="flex" alignItems="center">
                <Grid item xs={6}>
                  {t("companyManagement.transfer").toUpperCase()}
                </Grid>
                <Grid item xs={6}>
                  <MyToggleButton
                    checked={
                      !!country.paymentMethodsWithdrawal?.find(
                        (pay) => pay.name === "transfer"
                      )
                    }
                    handleChange={(e) => handleToggle(e, "transfer", index)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} display="flex" alignItems="center">
                <Grid item xs={6}>
                  {t("companyManagement.cash").toUpperCase()}
                </Grid>
                <Grid item xs={6}>
                  <MyToggleButton
                    checked={
                      !!country.paymentMethodsWithdrawal?.find(
                        (pay) => pay.name === "cash"
                      )
                    }
                    handleChange={(e) => handleToggle(e, "cash", index)}
                  />
                </Grid>
              </Grid> */}
              {countryPaymentMethods?.find((method) => method.id === country.id)
                ?.methods.length === 0 && (
                <label className="label-text mt-2">
                  {t("parameterization.noParamPaymentMethods")}
                </label>
              )}
              {countryPaymentMethods
                ?.find((method) => method.id === country.id)
                ?.methods.map((method: any) => (
                  <Fragment key={method.id}>
                    {method.methods.type === "withdrawal" && (
                      <Grid item xs={12} display="flex" alignItems="center">
                        <Grid item xs={6}>
                          {method.methods.name.toUpperCase()}
                        </Grid>
                        <Grid item xs={6}>
                          <MyToggleButton
                            checked={
                              !!country.paymentMethodsWithdrawal?.find(
                                (pay) =>
                                  pay.name === method.methods.name && pay.state
                              )
                            }
                            handleChange={(e) =>
                              handleToggle(
                                e,
                                {
                                  id: method.methods.id,
                                  name: method.methods.name,
                                  idParamMethod: method.id,
                                  type: method.methods.type,
                                },
                                index
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Fragment>
                ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </ThemeProvider>
  );
};
