import React, { FC } from "react";
import { TableCell } from "@mui/material";
import moment from "moment";
interface props {
  row: any;
}
export const RowAssociateBetsReport: FC<props> = ({ row }) => {
  return (
    <>
      <TableCell className="word-break" align="center">
        {row.reference}
      </TableCell>
      <TableCell className="word-break" align="center">
        {moment(row.createAt).format("DD/MM/YYYY")}
      </TableCell>
      <TableCell className="word-break" align="center">
        {moment(row.createAt).format("HH:mm:ss A")}
      </TableCell>
      <TableCell className="word-break" align="center">
        $ {row.amount}
      </TableCell>
      <TableCell className="word-break" align="center">
        {row.player.email}
      </TableCell>
    </>
  );
};
