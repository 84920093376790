import { combineReducers } from "redux";
import { Types } from "../types/types";
import { associatesReducer } from "./associatesReducer";
import { authReducer } from "./authReducer";
import { BetsReducer } from "./betsReducer";
import { bonusReducer } from "./bonusReducer";
import { configParamReducer } from "./configParamsReducer";
import { contentManagementReducer } from "./contentManagementReducer";
import { currenciesReducer } from "./currenciesReducer";
import { helperReducer } from "./helperReducer";
import { legalReducer } from "./legalReducer";
import { managementAdsReducer } from "./managementAdsReducer";
import { managementCardsReducer } from "./managementCardsReducer";
import { managementCompanyReducer } from "./managementCompanyReducer";
import { managementDealersReducer } from "./managementDealersReducer";
import { managementMenuReducer } from "./managementMenuReducer";
import { managementParametersReducer } from "./managementParametersReducer";
import { managementPayMethodsReducer } from "./managementPayMethodsReducer";
import { managementPlayerSReducer } from "./managementPlayersReducer";
import { managementPromotionsReducer } from "./managementPromotionsReducer";
import { managementRolesReducer } from "./managementRoles";
import { managementSlidesReducer } from "./managementSlidesReducer";
import { managementUserReducer } from "./managementUserReducer";
import { messagesReducer } from "./messagesReducer";
import { notificatiosReducer } from "./notificationsReducer";
import { pointsSaleReducer } from "./pointsSaleReducer";
import { promotionsReducer } from "./promotionsReducer";
import { searchReducer } from "./searchReducer";
import { transactionsReducer } from "./transactionsReducer";
import { uiReducer } from "./uiReducer";
import { walletReducer } from "./walletReducer";
import { paysReducer } from "./paymentReducer";
import { managementPromotersReducer } from "./managementPromotersReducer";
import { commercialNetworksReducer } from "./commercialNetworks";
import { managementCasinoReducer } from "./managementCasinoReducer";
import { affiliationsReducer } from "./affiliationsReducer";
import { segmentationReducer } from "./segmentations";
import { managementPromotionsPopupsReducer } from "./managementPromotionsPopupsReducer";
import { managementIpBlacklistReducer } from "./managementIpBlacklistReducer";
import { depositReducer } from "./depositReducer";

export const appReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  helper: helperReducer,
  managementMenus: managementMenuReducer,
  managementUsers: managementUserReducer,
  managementPlayers: managementPlayerSReducer,
  search: searchReducer,
  managementRoles: managementRolesReducer,
  managementCompanies: managementCompanyReducer,
  managementPayMethods: managementPayMethodsReducer,
  managementParameters: managementParametersReducer,
  pointsSale: pointsSaleReducer,
  associates: associatesReducer,
  managementAds: managementAdsReducer,
  bets: BetsReducer,
  bonus: bonusReducer,
  legal: legalReducer,
  transactions: transactionsReducer,
  configParams: configParamReducer,
  currencies: currenciesReducer,
  contentManagement: contentManagementReducer,
  managementSlides: managementSlidesReducer,
  managementPromotions: managementPromotionsReducer,
  notifications: notificatiosReducer,
  managementCards: managementCardsReducer,
  managementDealers: managementDealersReducer,
  managementPromoters: managementPromotersReducer,
  messages: messagesReducer,
  wallet: walletReducer,
  promotions: promotionsReducer,
  pays: paysReducer,
  commercialNetworks: commercialNetworksReducer,
  casino: managementCasinoReducer,
  affiliations: affiliationsReducer,
  segmentations: segmentationReducer,
  promotionsPopups: managementPromotionsPopupsReducer,
  ipAddresses: managementIpBlacklistReducer,
  deposits: depositReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export const rootReducer = (state: any, action: any) => {
  // Clear all data in redux store to initial.
  if (action.type === Types.reset) {
    state = undefined;
  }

  return appReducer(state, action);
};
