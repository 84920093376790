import React, { useState, useImperativeHandle, forwardRef } from "react";
import "./MultiSelect.scss";

interface Item {
  id: number;
  name: string;
}

interface MultiSelectProps {
  items: Item[];
  selectedItemsFunction: (selectedItems: Item[]) => void;
  activeItems?: Item[];
}

const MultiSelect = forwardRef<any, MultiSelectProps>(
  ({ items, selectedItemsFunction, activeItems }, ref) => {
    const [selectedItems, setSelectedItems] = useState<Item[]>(
      activeItems ? activeItems : []
    );
    const [filter, setFilter] = useState<string>("");
    const [isOpen, setIsOpen] = useState<boolean>(false);

    // useImperativeHandle para exponer la función clearSelection al padre
    useImperativeHandle(ref, () => ({
      clearSelection: () => {
        setSelectedItems([]);
        selectedItemsFunction([]);
      },
    }));

    // Toggle selection of items
    const toggleItem = (item: Item) => {
      const newSelectedItems = selectedItems.some(
        (selected) => selected.id === item.id
      )
        ? selectedItems.filter((selected) => selected.id !== item.id)
        : [...selectedItems, item];

      setSelectedItems(newSelectedItems);
      selectedItemsFunction(newSelectedItems);
    };

    // Filter items based on search input
    const filteredItems = items.filter((item) =>
      item.name.toLowerCase().includes(filter.toLowerCase())
    );

    return (
      <div className="multi-select">
        <div className="selected-items" onClick={() => setIsOpen(!isOpen)}>
          <span>
            {selectedItems.length} item{selectedItems.length !== 1 && "s"}{" "}
            seleccionado{selectedItems.length !== 1 && "s"}
          </span>
        </div>
        {isOpen && (
          <div className="dropdown">
            <div className="search-box">
              <input
                type="text"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Buscar..."
              />
              <span className="search-icon">
                <i className="fa-solid fa-magnifying-glass"></i>
              </span>
            </div>
            {filteredItems.length > 0 ? (
              filteredItems.map((item) => (
                <div
                  key={item.id}
                  className="dropdown__item"
                  onClick={() => toggleItem(item)}
                >
                  <input
                    type="checkbox"
                    checked={selectedItems.some(
                      (selected) => selected.id === item.id
                    )}
                    readOnly
                  />
                  {item.name}
                </div>
              ))
            ) : (
              <div className="dropdown__item">No se encontraron resultados</div>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default MultiSelect;
