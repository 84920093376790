import moment from "moment";
import React, { FC } from "react";
import { Text, View } from "@react-pdf/renderer";

import {
  headersWithdrawalsApproveCash,

} from "../../../helpers/headersTables";
import { IWithdrawalData } from "../../../interfaces/withdrawals";
import { stylesPdf } from "../../../views/components/custom/customStyles";

interface props {
  page: IWithdrawalData[];
}
export const WithdrawalApproveCashPdf: FC<props> = ({ page }) => {
  const columns = headersWithdrawalsApproveCash;

  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column) => (
          <View key={column.key} style={[stylesPdf.pCol, stylesPdf.col]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>

      <View style={{ flex: 1 }}>
        {page.map((data) => (
          <View key={data.id} style={stylesPdf.row}>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>{data.id}</Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>{data.player.id}</Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>
                {data.player.numberDocument}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>{data.player.email}</Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>
                {data.approvalDate
                  ? moment(data.approvalDate).format("DD/MM/YYYY - HH:mm:ss")
                  : ""}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>{data.withdrawalValue}</Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>{data.paidValue}</Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};
