import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { State } from "../../../enums/enums";
import { headersUsers } from "../../../helpers/headersTables";
import { IUserData } from "../../../redux/interfaces/users";
import { AppState } from "../../../redux/reducers/rootReducer";
import { getCopyArrayOrObject } from "../../../utils/utils";

export const UsersDataTable = () => {
  const { t } = useTranslation();
  let { users, totalUsers } = useSelector(
    (state: AppState) => state.managementUsers
  );
  const copyUsers = getCopyArrayOrObject(users);
  const copyTotalUsers = getCopyArrayOrObject(totalUsers);
  const headersCSVUsers = headersUsers;

  //   COLUMNS HEADERS STYLES
  const headersCvsStyle = headersCSVUsers.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  // Obtener datos por vista
  const dataUsers =
    copyUsers && copyUsers.data
      ? copyUsers.data.map((user: IUserData) => {
          // @ts-ignore
          user.verificationTwoStep =
            user.verificationTwoStep === 2
              ? t("table.not").toUpperCase()
              : t("table.yes").toUpperCase();
          // @ts-ignore
          user.stateUser =
            user.stateUser === State.ACTIVE
              ? t("state.active")
              : user.stateUser === State.RETIRED
              ? t("state.retired")
              : user.stateUser === State.RESTRICTED
              ? t("state.restricted")
              : t("state.inactive");
          user.createAt = moment(user.createAt).format("YYYY-MM-DD");
          return user;
        })
      : [];

  // Obtener el total de datos
  const dataTotalUsers = copyTotalUsers
    ? copyTotalUsers.data.map((user: IUserData) => {
        // @ts-ignore
        user.verificationTwoStep =
          user.verificationTwoStep === 2
            ? t("table.not").toUpperCase()
            : t("table.yes").toUpperCase();
        // @ts-ignore
        user.stateUser =
            user.stateUser === State.ACTIVE
              ? t("state.active")
              : user.stateUser === State.RETIRED
              ? t("state.retired")
              : user.stateUser === State.RESTRICTED
              ? t("state.restricted")
              : t("state.inactive");
        user.createAt = moment(user.createAt).format("YYYY-MM-DD");
        return user;
      })
    : [];

  // DATA POR VISTA
  const multiDataSetUsers = [
    {
      columns: headersCvsStyle,
      data: dataUsers.map((data: any) => {
        let array;
        array = array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.nameComplete,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.email,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.createAt,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.roles && data.roles.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.createdUser ? data.createdUser.nameComplete : "",
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.stateUser,
            style: {
              fill: {
                patternType: "solid",
                fgColor: {
                  rgb:
                    data.stateUser === t("state.active")
                      ? "c6efce"
                      : data.stateUser === t("state.retired") || data.stateUser === t("state.restricted")
                      ? "ebebeb"
                      : "ffc7ce",
                },
              },
              alignment: { horizontal: "center" },
            },
          },
          { value: "", style: { alignment: { horizontal: "center" } } },
          {
            value: data.verificationTwoStep,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.userLoginHistory,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.observation,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.username,
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  //   DATA TOTAL
  const multiTotalDataSetUsers = [
    {
      columns: headersCvsStyle,
      data: dataTotalUsers.map((data: any) => {
        let array;
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.nameComplete,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.email,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.createAt,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.roles && data.roles.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.createdUser ? data.createdUser.nameComplete : "",
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.stateUser,
            style: {
              fill: {
                patternType: "solid",
                fgColor: {
                  rgb:
                    data.stateUser === t("state.active")
                      ? "c6efce"
                      : data.stateUser === t("state.retired") || data.stateUser === t("state.restricted")
                      ? "ebebeb"
                      : "ffc7ce",
                },
              },
              alignment: { horizontal: "center" },
            },
          },
          { value: "", style: { alignment: { horizontal: "center" } } },
          {
            value: data.verificationTwoStep,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.userLoginHistory,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.observation,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.username,
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  return {
    dataUsers,
    dataTotalUsers,
    headersCSVUsers,
    multiDataSetUsers,
    multiTotalDataSetUsers,
  };
};
