import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import { KeyboardEvent, useRef, useState, useEffect, ChangeEvent } from "react";
import { Form, Formik } from "formik";

import { MyTextField } from "../../components/custom/MyTextField";
import { regNumbers } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { sendLimitNotification } from "../../../services/notificationsService";
import { LimitType, Roles_Enum } from "../../../enums/enums";
import {
  editOperatorService,
  getOperatorByIdService,
} from "../../../services/managementCompanyService";
import { createLimitAudit } from "../../../services/limitsAudit";
import * as yup from "yup";
import { IOperationManagForm } from "../../../interfaces/operationCompany";

interface FormValues {
  maximumDailyBet: string;
  maximumWeeklyBet: string;
  maximumMonthlyBet: string;
}

export const BetLimit = () => {
  const { token, user } = useSelector((state: AppState) => state.auth);
  const [operator, setOperator] = useState<any>(null);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const [operatorId, setOperatorId] = useState(
    isSuper ? "" : user?.operator?.id
  );

  const [checking, setChecking] = useState(false);
  const formRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialValues = {
    maximumDailyBet: operator?.maximumDailyBet ? operator.maximumDailyBet : "",
    maximumWeeklyBet: operator?.maximumWeeklyBet
      ? operator?.maximumWeeklyBet
      : "",
    maximumMonthlyBet: operator?.maximumMonthlyBet
      ? operator?.maximumMonthlyBet
      : "",
  };

  useEffect(() => {
    handleGetOperator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetOperator = async () => {
    setChecking(true);
    if (token && operatorId) {
      const operator = await getOperatorByIdService(token, +operatorId);
      if (!operator) {
        setChecking(false);
        dispatch(setMessageAlert("alerts.noData"));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      setOperator(operator);
    }
    setChecking(false);
  };

  const validationSchema = yup.object({
    maximumDailyBet: yup.string(),
    maximumWeeklyBet: yup.string(),
    maximumMonthlyBet: yup.string(),
  });

  const validateBetValues = (values: FormValues) => {
    const { maximumDailyBet, maximumWeeklyBet, maximumMonthlyBet } = values;
    const errors: Partial<FormValues> = {};

    if (
      maximumDailyBet &&
      maximumWeeklyBet &&
      parseFloat(maximumDailyBet) >= parseFloat(maximumWeeklyBet)
    ) {
      errors.maximumDailyBet = t("deposits.limitValidationError", {
        limit1: t("deposits.daily"),
        condition: t("deposits.less"),
        limit2: t("deposits.weekly"),
      });
    }

    if (
      maximumDailyBet &&
      maximumMonthlyBet &&
      parseFloat(maximumDailyBet) >= parseFloat(maximumMonthlyBet)
    ) {
      errors.maximumDailyBet = t("deposits.limitValidationError", {
        limit1: t("deposits.daily"),
        condition: t("deposits.less"),
        limit2: t("deposits.monthly"),
      });
    }

    if (
      maximumWeeklyBet &&
      parseFloat(maximumWeeklyBet) <= parseFloat(maximumDailyBet)
    ) {
      errors.maximumWeeklyBet = t("deposits.limitValidationError", {
        limit1: t("deposits.weekly"),
        condition: t("deposits.greater"),
        limit2: t("deposits.daily"),
      });
    }

    if (
      maximumWeeklyBet &&
      maximumMonthlyBet &&
      parseFloat(maximumWeeklyBet) >= parseFloat(maximumMonthlyBet)
    ) {
      errors.maximumWeeklyBet = t("deposits.limitValidationError", {
        limit1: t("deposits.weekly"),
        condition: t("deposits.less"),
        limit2: t("deposits.monthly"),
      });
    }

    if (
      maximumMonthlyBet &&
      parseFloat(maximumMonthlyBet) <= parseFloat(maximumDailyBet)
    ) {
      errors.maximumMonthlyBet = t("deposits.limitValidationError", {
        limit1: t("deposits.monthly"),
        condition: t("deposits.greater"),
        limit2: t("deposits.daily"),
      });
    }

    if (
      maximumMonthlyBet &&
      parseFloat(maximumMonthlyBet) <= parseFloat(maximumWeeklyBet)
    ) {
      errors.maximumMonthlyBet = t("deposits.limitValidationError", {
        limit1: t("deposits.monthly"),
        condition: t("deposits.greater"),
        limit2: t("deposits.weekly"),
      });
    }

    return errors;
  };

  const handleSetBetLimit = async (data: FormValues) => {
    const { maximumDailyBet, maximumWeeklyBet, maximumMonthlyBet } = data;
    try {
      if (!token || !user) {
        throw new Error("Invalid token");
      }
      const objEditOperator: Partial<IOperationManagForm> = {
        maximumDailyBet: maximumDailyBet.trim() || null,
        maximumWeeklyBet: maximumWeeklyBet.trim() || null,
        maximumMonthlyBet: maximumMonthlyBet.trim() || null,
      };

      if (data.maximumDailyBet) {
        const event = t("playerLimit.auditMessage", {
          message: t("playerLimit.maximumDailyBet"),
          value: data.maximumDailyBet,
          currency: operator.currencyOp[0]
            ? operator.currencyOp[0].currency.code
            : "USD",
        });

        await createLimitAudit(token, {
          event,
          createdBy: user.id,
          operatorAffected: parseInt(operatorId as string),
        });
      }
      if (data.maximumWeeklyBet) {
        const event = t("playerLimit.auditMessage", {
          message: t("playerLimit.maximumWeeklyBet"),
          value: data.maximumWeeklyBet,
          currency: operator.currencyOp[0]
            ? operator.currencyOp[0].currency.code
            : "USD",
        });

        await createLimitAudit(token, {
          event,
          createdBy: user.id,
          operatorAffected: parseInt(operatorId as string),
        });
      }
      if (data.maximumMonthlyBet) {
        const event = t("playerLimit.auditMessage", {
          message: t("playerLimit.maximumMonthlyBet"),
          value: data.maximumMonthlyBet,
          currency: operator.currencyOp[0]
            ? operator.currencyOp[0].currency.code
            : "USD",
        });

        await createLimitAudit(token, {
          event,
          createdBy: user.id,
          operatorAffected: parseInt(operatorId as string),
        });
      }

      const rs = await editOperatorService(
        objEditOperator,
        token,
        parseInt(operatorId as string)
      );

      if (!rs.error) {
        await sendLimitNotification(
          {
            email: operator.email,
            limitType: LimitType.BETS,
          },
          token
        );
        dispatch(setMessageAlert(`companyManagement.updatedCompany`));
        dispatch(setTypeAlert("success"));
        return dispatch(setOpenAlert());
      } else {
        dispatch(setMessageAlert(`alerts.error`));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert(`alerts.error`));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} className="white-container" mt={2} ml={2}>
        <Formik
          innerRef={formRef}
          validate={validateBetValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            await handleSetBetLimit(data);
            setSubmitting(false);
          }}
        >
          {({ handleChange, values, isSubmitting, handleReset, errors }) => (
            <Form>
              {isSuper && (
                <Grid container spacing={1}>
                  <Grid item sm={12} md={7}>
                    <TextField
                      name="operatorId"
                      value={operatorId || ""}
                      label={t("parameterization.operatorId")}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleReset();
                        setOperator(null);
                        setOperatorId(e.target.value as string);
                      }}
                      onKeyPress={(e: any) => {
                        !regNumbers(e.key) && e.preventDefault();
                      }}
                      inputProps={{
                        maxLength: 6,
                      }}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={12} md={5}>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      className="primary-btn ripple"
                      style={{ border: "none", outline: "none" }}
                      onClick={() => {
                        handleReset();
                        setOperator(null);
                        handleGetOperator();
                      }}
                      disabled={!operatorId?.toString().trim() || !!operator}
                    >
                      {checking ? (
                        <CircularProgress size={"1.6rem"} color="inherit" />
                      ) : (
                        t("filters.filter")
                      )}
                    </Button>
                  </Grid>
                </Grid>
              )}

              <Grid
                container
                spacing={2}
                columns={{ xs: 1, sm: 1, md: 1 }}
                mb={5}
                mt={1}
              >
                <Grid item xs={1}>
                  <label className="form-label">
                    {" "}
                    {t("playerLimit.maximumDailyBet")}
                  </label>
                  <MyTextField
                    disabled={isSubmitting || !operatorId}
                    name="maximumDailyBet"
                    value={values.maximumDailyBet}
                    label={""}
                    InputLabelProps={{ shrink: false }}
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    onKeyPress={(e: any) => {
                      !regNumbers(e.key) && e.preventDefault();
                    }}
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <label className="form-label">
                    {" "}
                    {t("playerLimit.maximumWeeklyBet")}
                  </label>
                  <MyTextField
                    disabled={isSubmitting || !operatorId}
                    name="maximumWeeklyBet"
                    value={values.maximumWeeklyBet}
                    label={" "}
                    InputLabelProps={{ shrink: false }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regNumbers(e.key) && e.preventDefault()
                    }
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <label className="form-label">
                    {" "}
                    {t("playerLimit.maximumMonthlyBet")}
                  </label>
                  <MyTextField
                    disabled={isSubmitting || !operatorId}
                    name="maximumMonthlyBet"
                    label={" "}
                    value={values.maximumMonthlyBet}
                    InputLabelProps={{ shrink: false }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regNumbers(e.key) && e.preventDefault()
                    }
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={
                      isSubmitting ||
                      !!!operator ||
                      !operatorId ||
                      !!errors.maximumDailyBet ||
                      !!errors.maximumMonthlyBet ||
                      !!errors.maximumWeeklyBet
                    }
                    fullWidth
                    className="primary-btn ripple"
                    style={{ border: "none", outline: "none" }}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      t("button.save")
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
