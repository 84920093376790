import Dashboard from "./views/Dashboard";
import { ManagementBonus } from "./views/pages/Bonus/ManagementBonus";
import FilesUploads from "./views/pages/FilesUploads/FormFilesUploads";
import { Login } from "./views/pages/Login";
import { ManagementPlayers } from "./views/pages/ManagementPlayers/ManagementPlayers";
import { ManagementUsers } from "./views/pages/ManagementUsers/ManagementUsers";
import { Messages } from "./views/pages/Messages/Messages";
import { ConfigCompany } from "./views/pages/OperationCompany/Config/ConfigCompany";
import { ManagementCompany } from "./views/pages/OperationCompany/Management/ManagementCompany";
import { ParamSettings } from "./views/pages/ParamSettings/ParamSettings";
import { Promotions } from "./views/pages/Promotions/Promotions";
import { Recover } from "./views/pages/Recover";
import { Dashboard as DashboardStatistics } from "./views/pages/Statistics/Dashboard";
import { Bets } from "./views/pages/bets/Bets";
import { Exclusions } from "./views/pages/exclusions/Exclusions";
import { IrrigationPv } from "./views/pages/irrigation/IrrigationPv";
import { LegalParameters } from "./views/pages/legal/LegalParameters";
import { LimitPlayers } from "./views/pages/limitsPlayer/LimitPlayers";
import { ManagementAds } from "./views/pages/managementAds/ManagementAds";
import { ManagementCards } from "./views/pages/managementCards/managementCards";
import ConfigParams from "./views/pages/managementConfigParams/ConfigParams";
import { ManagementDealers } from "./views/pages/managementDealers/ManagementDealers";
import { ManagementDeposits } from "./views/pages/managementDeposits/ManagementDeposits";
import ManagementMenu from "./views/pages/managementMenu/ManagementMenu";
import { ManagementRoles } from "./views/pages/managementRoles/ManagementRoles";
import { Associates } from "./views/pages/masters/associates/Associates";
import { PointsOfSale } from "./views/pages/masters/pointsOfSale/PointsSale";
import { Reports } from "./views/pages/reports/Reports";
import { Transactions } from "./views/pages/transactions/Transactions";
import { UpdatePlayer } from "./views/pages/updatePlayer/UpdatePlayer";
import { Withdrawals } from "./views/pages/withdrawals/Withdrawals";
import { AwardsPayments } from "./views/pages/awardsPayments/AwardsPayments";
import { IrrigationDealer } from "./views/pages/irrigation/IrrigationDealer";
import { IrrigationAdmin } from "./views/pages/irrigation/admin/IrrigationAdmin";
import { IrrigationCardsDealer } from "./views/pages/irrigation/cardsToPointSales/IrrigationCardsToPointSales";
import { IrrigationCardsToDealer } from "./views/pages/irrigation/cardsToDealer/IrrigationCardsToDealer";
import { IrrigationCardsToPromotor } from "./views/pages/irrigation/carsdToPromotor/IrrigationCardsToPromotor";
import { ManagementPromoters } from "./views/pages/managementPromoters/ManagementPromoters";
import { CommercialNetworks } from "./views/pages/masters/networks/CommercialNetworks";
import { Casino } from "./views/pages/Casino/Casino";
import { ManagementNetworks } from "./views/pages/managementNetworks/ManagementNetworks";
import { Affiliations } from "./views/pages/Affiliations/Affiliations";
import { BulkMessages } from "./views/pages/BulkMessages/BulkMessages";
import { Conciliations } from "./views/components/managementNetworks/Conciliations";
import PromotionsPopups from "./views/pages/PromotionsPopups/PromotionsPopups";
import { ReportCards } from "./views/pages/ReportCards/ReportCards";
import { IpAddresses } from "./views/pages/IpAddresses/IpAddresses";
import { ManagementStatusUsers } from "./views/pages/ManagementStatusUsers/ManagementStatusUsers";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/recover",
    name: "Recover",
    component: Recover,
    layout: "/auth",
  },
  {
    path: "/home",
    name: "Inicio",

    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    path: "/fileUpload",
    name: "Archivos",
    layout: "/admin",
    views: [
      {
        path: "/management",
        name: "Gestión Archivos",
        component: FilesUploads,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    path: "/statistics",
    name: "Estadísticas",
    layout: "/admin",
    views: [
      {
        path: "/dashboard",
        name: "Dashboard Estadístico",
        component: DashboardStatistics,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    path: "/masters",
    name: "Maestros",

    component: Dashboard,
    layout: "/admin",
    views: [
      {
        path: "/associates",
        name: "Asociados",
        component: Associates,
        layout: "/admin",
      },
      {
        path: "/dropdowns",
        name: "Listas desplegables",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/providers",
        name: "Proveedores",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/point-of-sale",
        name: "Puntos de venta",
        component: PointsOfSale,
        layout: "/admin",
      },
      {
        path: "/networks",
        name: "Redes Comerciales",
        component: CommercialNetworks,
        layout: "/admin",
      },
      {
        path: "/point-of-sale-api",
        name: "Puntos de venta api",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/network-associates",
        name: "Red asociados",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/point-of-sale-network",
        name: "Red puntos de venta",
        component: Dashboard,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    path: "/player",
    name: "Jugador",
    layout: "/admin",
    views: [
      {
        path: "/activate-reject",
        name: "Activación o rechazo",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/detail",
        name: "Detalle completo",
        component: ManagementPlayers,
        layout: "/admin",
      },
      {
        path: "/exclusions",
        name: "Exclusiones",
        component: Exclusions,
        layout: "/admin",
      },
      {
        path: "/limits",
        name: "Limites",
        component: LimitPlayers,
        layout: "/admin",
      },
      {
        path: "/update-player",
        name: "Actualizar datos",
        component: UpdatePlayer,
        layout: "/admin",
      },
      {
        path: "/ip-address",
        name: "Direcciones IP",
        component: IpAddresses,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    path: "/settings",
    name: "Configuración",
    component: Dashboard,
    layout: "/admin",
    views: [
      {
        path: "/management-menu",
        name: "Gestión Menu",
        component: ManagementMenu,
        layout: "/admin",
      },
      {
        path: "/management-users",
        name: "Gestión Usuarios",
        component: ManagementUsers,
        layout: "/admin",
      },
      {
        path: "/management-roles",
        name: "Gestión Roles",
        component: ManagementRoles,
        layout: "/admin",
      },
      {
        path: "/management-cards",
        name: "Inventario tarjetas",
        component: ManagementCards,
        layout: "/admin",
      },
      {
        path: "/management-dealers",
        name: "Gestión distribuidor",
        component: ManagementDealers,
        layout: "/admin",
      },
      {
        path: "/management-promoters",
        name: "Gestión promotores",
        component: ManagementPromoters,
        layout: "/admin",
      },
      {
        path: "/bonds",
        name: "Bonos",
        component: ManagementBonus,
        layout: "/admin",
      },
      {
        path: "/change-password",
        name: "Cambiar contraseña",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/config-api-keys",
        name: "Configuración llaves api",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/bumper-config",
        name: "Configuración de topes",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/legal",
        name: "Parámetros legales",
        component: LegalParameters,
        layout: "/admin",
      },
      {
        path: "/promotions-management",
        name: "Gestión promociones",
        component: Promotions,
        layout: "/admin",
      },
      {
        path: "/messages",
        name: "Mensajes",
        component: Messages,
        layout: "/admin",
      },
      {
        path: "/bulk-messages",
        name: "Gestión mensajes masivos",
        component: BulkMessages,
        layout: "/admin",
      },
      {
        path: "/casino",
        name: "Gestión Casino",
        component: Casino,
        layout: "/admin",
      },
      {
        path: "/menu",
        name: "Menu",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/profile",
        name: "Mi perfil",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/params",
        name: "Parametrizaciones",
        component: ParamSettings,
        layout: "/admin",
      },
      {
        path: "/config-params",
        name: "Parámetros configuración",
        component: ConfigParams,
        layout: "/admin",
      },
      {
        path: "/gateways",
        name: "Pasarelas",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/bets",
        name: "Polla",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/admin-users",
        name: "Usuarios administrativos",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/networks",
        name: "Gestión Redes Comerciales",
        component: ManagementNetworks,
        layout: "/admin",
      },
      {
        path: "/conciliations",
        name: "Conciliaciones",
        component: Conciliations,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    path: "/transactions",
    name: "Transacciones",

    component: Dashboard,
    layout: "/admin",
    views: [
      {
        path: "/irrigation-cards-associate",
        name: "Irrigación tarjetas admin => asociado",
        component: IrrigationAdmin,
        layout: "/admin",
      },
      {
        path: "/irrigation-cards-dealer",
        name: "Irrigación tarjetas asociado => dealer",
        component: IrrigationCardsToDealer,
        layout: "/admin",
      },
      {
        path: "/irrigation-cards-promotor",
        name: "Irrigación tarjetas dealer => promotor",
        component: IrrigationCardsToPromotor,
        layout: "/admin",
      },
      {
        path: "/irrigation-cards-pointSale",
        name: "Irrigación tarjetas dealer => punto de venta",
        component: IrrigationCardsDealer,
        layout: "/admin",
      },
      {
        path: "/settings",
        name: "Ajustes",
        component: Transactions,
        layout: "/admin",
      },
      {
        path: "/withdrawals",
        name: "Retiros",
        component: Withdrawals,
        layout: "/admin",
      },
      {
        path: "/awards-payments",
        name: "Pagos de premios",
        component: AwardsPayments,
        layout: "/admin",
      },
      {
        path: "/settings-authorization",
        name: "Autorización ajustes",
        component: Dashboard,
        layout: "/admin",
      },

      {
        path: "/returns",
        name: "Devoluciones",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/settlement-management",
        name: "Gestión liquidación",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/irrigation",
        name: "Irrigación asociado => punto de venta",
        component: IrrigationPv,
        layout: "/admin",
      },
      {
        path: "/irrigation-dealers",
        name: "Irrigación asociado => dealer",
        component: IrrigationDealer,
        layout: "/admin",
      },
      {
        path: "/network-irrigation",
        name: "Irrigación red",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/payment-orders",
        name: "Órdenes de pago",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/anonymous-ticket-payment",
        name: "Pago ticket anónimo",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/top-up-credits",
        name: "Recargar créditos",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/transfers",
        name: "Traslados",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/management-users-status",
        name: "Gestión Estados de Usuarios",
        component: ManagementStatusUsers,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    path: "/operating-company",
    name: "Empresa Operadora",
    layout: "/admin",
    views: [
      {
        path: "/management",
        name: "Gestión Empresa Operadora",
        component: ManagementCompany,
        layout: "/admin",
      },
      {
        path: "/config",
        name: "Configurar Empresa Operadora",
        component: ConfigCompany,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/bets",
    name: "Apuestas",
    component: Bets,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "profile",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/ads",
    name: "Anuncios",
    component: ManagementAds,
    layout: "/admin",
  },
  {
    path: "/reset-password/:token",
    name: "Reset Password",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/deposit",
    name: "Depósito",
    component: ManagementDeposits,
    layout: "/admin",
  },
  {
    path: "/reports",
    name: "Reportes",
    component: Reports,
    layout: "/admin",
  },
  {
    path: "/affiliations",
    name: "Afiliaciones",
    component: Affiliations,
    layout: "/admin",
  },
  {
    path: "/promotions-popups",
    name: "Promociones Popups",
    component: PromotionsPopups,
    layout: "/admin",
  },
  {
    path: "/report-card-punto-pago",
    name: "Reporte tarjetas",
    component: ReportCards,
    layout: "/admin",
  },

  {
    collapse: true,
    path: "/casino",
    name: "Casino",

    component: Dashboard,
    layout: "/admin",
    views: [
      {
        path: "/games",
        name: "Juegos casino",
        component: Dashboard,
        layout: "/admin",
      },
    ],
  },
];

export default routes;
