import { FC } from "react";
import { TableCell, Link, Grid } from "@mui/material";
import moment from "moment";
import {
  CasinoBonusType,
  ProvidersBonus,
  Roles_Enum,
  State,
} from "../../../enums/enums";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setActiveBonus,
  setOpenBonusDetails,
} from "../../../redux/actions/bonusActions";
import {
  ActionsTable,
  IActionsProps,
} from "../../../components/Custom/Table/ActionsTable";
import { AppState } from "../../../redux/reducers/rootReducer";
interface Props {
  row: any;
  actions?: IActionsProps;
  onAdd?(row: any): void;
  onDelete?(row: any): void;
  onEdit?(row: any): void;
  onSee?(row: any): void;
  onSeeMore?(row: any): void;
  onApprove?(row: any): void;
  onReject?(row: any): void;
}
export const RowManagementBonus: FC<Props> = ({
  row,
  actions,
  onAdd,
  onDelete,
  onEdit,
  onSee,
  onSeeMore,
  onApprove,
  onReject,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleOpenDeliveredDetails = () => {
    dispatch(setActiveBonus(row));
    dispatch(setOpenBonusDetails(true));
  };
  const { user } = useSelector((state: AppState) => state.auth);

  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const { pathname } = useLocation();
  const { routes } = useSelector((state: AppState) => state.managementMenus);

  const menuPermissions = routes.find(
    (route) =>
      !!route.menuItem.find((module) => module.url === pathname) ||
      route.url === pathname
  );

  let finalActions: IActionsProps = { ...actions };
  if (menuPermissions) {
    const seeAction =
      finalActions.see !== undefined &&
      row.casinoBonusType === CasinoBonusType.REGISTRATION
        ? finalActions.see
        : {
            show: false,
            state: State.ACTIVE,
          };
    const editAction =
      finalActions.edit !== undefined
        ? row.provider.id !== ProvidersBonus.PARIPLAY
          ? finalActions.edit
          : false
        : false;
    const deleteAction =
      finalActions.delete !== undefined ? finalActions.delete : false;
    const seeMoreAction =
      finalActions.seeMore !== undefined ? finalActions.seeMore : false;
    if (menuPermissions.menuItem.length > 0) {
      const modulePermissions = menuPermissions.menuItem.find(
        (module) => module.url === pathname
      );

      finalActions.see = isSuper
        ? seeAction && row.casinoBonusType === CasinoBonusType.REGISTRATION
        : modulePermissions && modulePermissions.permissions?.readed
        ? row.casinoBonusType === CasinoBonusType.REGISTRATION && seeAction
        : undefined;
      finalActions.edit = isSuper
        ? editAction
        : modulePermissions && modulePermissions.permissions?.updated === 1
        ? editAction
        : false;
      finalActions.delete = isSuper
        ? deleteAction
        : finalActions.delete !== undefined
        ? finalActions.delete
        : modulePermissions && modulePermissions.permissions?.deleted === 1
        ? deleteAction
        : false;
      finalActions.seeMore = isSuper
        ? seeMoreAction
        : modulePermissions?.permissions?.readed === 1
        ? seeMoreAction
        : false;
    } else {
      const permissions = menuPermissions.permissionsMenu;

      finalActions.see = isSuper
        ? seeAction
        : permissions?.readed === 1
        ? finalActions.see !== undefined
          ? finalActions.see
          : seeAction
        : undefined;
      finalActions.edit = isSuper
        ? editAction
        : permissions?.updated === 1
        ? finalActions.edit !== undefined
          ? finalActions.edit
          : editAction
        : false;
      finalActions.delete = isSuper
        ? deleteAction
        : permissions?.deleted === 1
        ? finalActions.delete !== undefined
          ? finalActions.delete
          : deleteAction
        : false;
      finalActions.seeMore = isSuper
        ? seeMoreAction
        : permissions?.readed === 1
        ? finalActions.seeMore !== undefined
          ? finalActions.seeMore
          : seeMoreAction
        : false;
    }
  }

  return (
    <>
      <TableCell align="center">{row.id}</TableCell>
      <TableCell align="center">{row.name}</TableCell>
      <TableCell align="center">{row.provider.name}</TableCell>

      <TableCell align="center">
        {moment(row.startDate).format("YYYY-MM-DD HH:mm:ss A")}
      </TableCell>
      <TableCell align="center">
        {moment(row.endDate).format("YYYY-MM-DD HH:mm:ss A")}
      </TableCell>
      <TableCell align="center">
        {row.isMassive ? (
          t("managementBonds.isMassiveBonus")
        ) : (
          <>
            {`${t("managementBonds.delivered")} (${row.totalBonusDelivered}-`}
            {row.amountBonus > 0 ? (
              row.amountBonus
            ) : (
              <i
                className="fa-solid fa-infinity"
                style={{
                  color: "rgb(82,82,82)",
                }}
              ></i>
            )}
            {")"}

            <Link
              component={"button"}
              variant="body1"
              onClick={handleOpenDeliveredDetails}
              style={{ border: "none", outline: "none", boxShadow: "none" }}
            >
              {`${t("managementBonds.seeDetails")}`}
            </Link>
          </>
        )}
      </TableCell>

      <TableCell align="center">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                State.ACTIVE === parseInt(row.state)
                  ? "d-flex justify-content-center cellRowActive"
                  : State.INACTIVE === parseInt(row.state)
                  ? "d-flex justify-content-center cellRowInactive"
                  : "d-flex justify-content-center cellRowRetired"
              }
            >
              <div className="d-flex col-sm-4 justify-content-center">
                {State.ACTIVE === parseInt(row.state) && (
                  <>{t("state.active")}</>
                )}
                {State.INACTIVE === parseInt(row.state) && (
                  <>{t("state.inactive")}</>
                )}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell align="center">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {actions && (
              <ActionsTable
                actions={{
                  ...finalActions,
                  see: {
                    show: true,
                    state: row.state,
                  },
                }}
                onAdd={() => onAdd && onAdd(row)}
                onDelete={() => onDelete && onDelete(row)}
                onEdit={() =>
                  onEdit &&
                  row.provider.id !== ProvidersBonus.PARIPLAY &&
                  onEdit(row)
                }
                onSee={() => onSee && onSee(row)}
                onSeeMore={() => onSeeMore && onSeeMore(row)}
                onApprove={() => onApprove && onApprove(row)}
                onReject={() => onReject && onReject(row)}
              />
            )}
          </Grid>
        </Grid>
      </TableCell>
    </>
  );
};
