import React from "react";
import { AwardsPaymentsForm } from "../../components/transactions/awardsPayments/AwardsPaymentsForm";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { useTranslation } from "react-i18next";

export const AwardsPayments = () => {
  const { t } = useTranslation();
  return (
    <div className="content w-100 mb-4">
      <MyBreadCrumbs titles={[t("transactions.title"),t("awardsPayments.title")]}/>
      
      <div className="white-container pt-5">
        <AwardsPaymentsForm />
      </div>
    </div>
  );
};
