import { Types } from "../types/types";
import {
  ICountriesSolution,
  ICountriesPayment,
  ICountriesGame,
} from "../interfaces/shared";

interface IParametersState {
  activeParamSolutions: ICountriesSolution | null;
  activeParamPayments: ICountriesPayment | null;
  activeParamGames: ICountriesGame | null;
}
interface IParametersAction {
  type: Types;
  payload?: ICountriesSolution | ICountriesPayment | ICountriesGame | null;
}

const initialState: IParametersState = {
  activeParamSolutions: null,
  activeParamPayments: null,
  activeParamGames: null,
};

export const managementParametersReducer = (
  state = initialState,
  action: IParametersAction
): IParametersState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setActiveParamSolutions:
      return {
        ...state,
        activeParamSolutions: payload as ICountriesSolution,
      };

    case Types.setActiveParamPayments:
      return {
        ...state,
        activeParamPayments: payload as ICountriesPayment,
      };
    case Types.setActiveParamGames:
      return {
        ...state,
        activeParamGames: payload as ICountriesGame,
      };

    default:
      return state;
  }
};
