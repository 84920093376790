import { Form, Formik } from "formik";
import { useState, useEffect, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import {
  Box,
  Grid,
  MenuItem,
  Button,
  FormControl,
  FormHelperText,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import { MyTextField } from "../custom/MyTextField";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { IFile, IFormLegal } from "../../../interfaces/legal";
import { MyDialog } from "../custom/MyDialog";
import {
  setFlagForm,
  asyncLoadingFunction,
  setOpenAlert,
  setMessageAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { Roles_Enum, State } from "../../../enums/enums";
import { getOperatorsService } from "../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../redux/actions/managementCompanyActions";
import {
  setActiveLegal,
  startCreateLegal,
  startUpdateLegal,
} from "../../../redux/actions/legalActions";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { TextEditor } from "../custom/TextEditor";
import { regLettersNumbers } from "../../../utils/utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const FormCreateLegal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { activeLegal } = useSelector((state: AppState) => state.legal);
  const { loading } = useSelector((state: AppState) => state.helper);
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );

  const [fileLabel, setFileLabel] = useState(
    activeLegal && activeLegal.fileUrl.length > 0 && activeLegal.fileName
      ? activeLegal.fileName
      : ""
  );
  const [file, setFile] = useState<IFile>({
    file: null,
    label: activeLegal ? fileLabel : "",
  });
  const [valid, setValid] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [cancel, setCancel] = useState(false);
  const [saveValues, setSaveValues] = useState<any>(null);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const [operatorId, setOperatorId] = useState<number | null>(
    activeLegal
      ? activeLegal.operator.id
      : !isSuper && user
      ? user.operator.id
      : null
  );

  const [value, setValue] = useState(activeLegal ? activeLegal.stringText : "");
  const [textEditor, setTextEditor] = useState("");

  const states = [
    { label: "Activo", value: 1 },
    { label: "Inactivo", value: 2 },
  ];

  const initialValues = {
    title: activeLegal ? activeLegal.title : "",
    fileUrl: null,
    state: activeLegal ? activeLegal.state : "",
    version: activeLegal ? activeLegal.version : 1,
    fileName: "",
    createdUser: activeLegal ? activeLegal.createdUser.id : user?.id,
  };

  const validationSchema = yup.object({
    title: yup.string().required(t("form.requiredFile")),
    state: yup.number().required(t("form.requiredFile")),
    version: yup.number(),
    fileName: yup.string(),
  });

  const handleCancel = () => {
    setMessage("dialog.sureExit");
    setCancel(true);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setCancel(false);
    setOpenDialog(false);
  };

  const handleAccept = async () => {
    handleCloseDialog();
    if (cancel) {
      dispatch(setFlagForm({ flagFormCreate: false }));
    } else {
      const formData: IFormLegal = {
        file: saveValues.fileUrl ? saveValues.fileUrl : null,
        title: saveValues.title,
        state: saveValues.state,
        version: saveValues.version,
        operator: saveValues.operator,
        createdUser: saveValues.createdUser,
        fileName: saveValues.fileName,
        edit: textEditor.trim() !== "" ? 1 : 0,
        stringText: textEditor.trim() !== "" ? value : "",
      };
      dispatch(
        asyncLoadingFunction(async () =>
          activeLegal
            ? await dispatch(
                startUpdateLegal({
                  ...formData,
                  id: activeLegal.id,
                  version: formData.version + 1,
                })
              )
            : await dispatch(startCreateLegal(formData))
        )
      );
    }
  };

  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    token && fetchOperators(token);
    return () => {
      dispatch(setActiveLegal(null));
      dispatch(setFlagForm({ flagFormCreate: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabledButton = (title: string, state: number | string) => {
    return (
      title === "" ||
      state === "" ||
      (isSuper && !operatorId) ||
      (textEditor.trim() === "" && !file.label)
    );
  };

  const handleSelectFile = (file: File) => {
    const SUPPORTED_FORMATS = [
      "text/plain",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (file) {
      if (!SUPPORTED_FORMATS.includes(file.type)) {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.invalidFormat"));
        return dispatch(setTypeAlert("error"));
      }
      setValid(true);
      setFile({ file, label: file.name });

      dispatch(setOpenAlert());
      dispatch(setMessageAlert("legal.fileUploaded"));
      dispatch(setTypeAlert("success"));
    } else {
      setFile({ file: null, label: "" });
    }
  };

  const handleDeleteFile = () => {
    setFile({ file: null, label: "" });
    setFileLabel("");
  };

  return (
    <Box m={2}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          if (operatorId && (file.label || textEditor.trim().length > 0)) {
            // let newFile: File | null;

            const newFile = file.file ? file.file : null;

            const formValues = { ...data };
            // @ts-ignore
            setSaveValues({
              ...formValues,
              fileUrl: newFile,
              fileName: file.label !== "" ? file.label : fileLabel,
              operator: operatorId,
            });
            setOpenDialog(true);
            setMessage(
              activeLegal
                ? t("legal.sureUpdateLegal")
                : t("legal.sureCreateLegal")
            );
            setCancel(false);
            setSubmitting(false);
          }
        }}
      >
        {({ handleChange, values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item md={4} lg={4} sm={6} xs={12}>
                <MyTextField
                  name="title"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label={`${t("legal.title")}*`}
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={handleChange}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regLettersNumbers(e.key) && e.preventDefault()
                  }
                />
              </Grid>
              <Grid item md={4} lg={4} sm={6} xs={12}>
                <MyTextField
                  name="state"
                  select
                  label={t("user.state") + "*"}
                  onChange={handleChange}
                >
                  {states.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>
              <Grid item xs={12}>
                <TextEditor
                  value={value}
                  onChange={setValue}
                  placeholder="Escribe aqui"
                  onChangeFile={handleSelectFile}
                  onChangeText={(e: string) => setTextEditor(e)}
                />
              </Grid>

              {file.label !== "" && (
                <Grid item xs={4}>
                  <TextField
                    disabled={true}
                    variant="outlined"
                    style={{ color: "black" }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <>
                          {file.label.length > 0 && (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleDeleteFile}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  boxShadow: "none",
                                }}
                              >
                                <CancelIcon />
                              </IconButton>
                            </InputAdornment>
                          )}
                        </>
                      ),
                    }}
                    label={
                      file.label.length ? file.label : t("legal.selectFile")
                    }
                    name="image"
                    size="small"
                    error={!valid || (submit && file.label === "")}
                    helperText={
                      !valid
                        ? t("alerts.invalidFormat")
                        : submit && file.label === "" && t("form.requiredFile")
                    }
                  />
                </Grid>
              )}

              {
                //MOSTRAR SELECT CON OPERADORES CUANDO SEA SUPER_ADMIN
                user?.roles.name === Roles_Enum.SUPER_ADMIN && !activeLegal && (
                  <Grid item md={4} lg={4} sm={6} xs={12}>
                    <FormControl
                      sx={{ width: "100%" }}
                      size="small"
                      error={submit && isSuper && !operatorId}
                      disabled={!!activeLegal}
                    >
                      <InputLabel id="operator-label">
                        {t("companyManagement.operator") + "*"}
                      </InputLabel>
                      <Select
                        labelId="operator-label"
                        name="operator"
                        size="small"
                        fullWidth
                        onChange={(e: any) => setOperatorId(e.target.value)}
                        value={!operatorId ? "" : operatorId}
                        input={
                          <OutlinedInput
                            label={t("companyManagement.operator") + "*"}
                          />
                        }
                        MenuProps={MenuProps}
                      >
                        {companies?.data?.map((company) => (
                          <MenuItem key={company.id} value={company.id}>
                            {company.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormHelperText>
                      {submit && isSuper && !operatorId && (
                        <label className="ml-3" style={{ color: "#d32f2f" }}>
                          {t("form.requiredFile")}
                        </label>
                      )}
                    </FormHelperText>
                  </Grid>
                )
              }
            </Grid>

            <Grid container>
              <Box
                display={"flex"}
                mt={2}
                flex={1}
                justifyContent="flex-end"
                alignItems={"flex-end"}
              >
                <Button
                  variant="contained"
                  className="footer-btn danger-btn ripple"
                  style={{ border: "none", outline: "none" }}
                  onClick={handleCancel}
                  disabled={!!loading}
                >
                  {t("button.cancel").toUpperCase()}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className="footer-btn primary-btn ripple"
                  style={{ border: "none", outline: "none" }}
                  onClick={() => setSubmit(true)}
                  disabled={disabledButton(values.title, values.state)}
                >
                  {loading ? (
                    <CircularProgress size={"1.6rem"} color="inherit" />
                  ) : activeLegal ? (
                    t("button.edit").toUpperCase()
                  ) : (
                    t("button.save").toUpperCase()
                  )}
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>

      <MyDialog
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message={message}
        handleAccept={handleAccept}
        handleClose={handleCloseDialog}
        isLoading={!!loading}
      />
    </Box>
  );
};
