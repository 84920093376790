import {
  useEffect,
  useState,
  KeyboardEvent,
  Dispatch,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import {
  CountrySelect,
  CountryType,
} from "../../../../components/Custom/CountrySelect";

import logoGames from "../../../../assets/svg/games.svg";
import { ICountryStep } from "../../../../interfaces/operationCompany";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { ICountryGamesDb } from "../../../../interfaces/params";
import { getParamGameByCountry } from "../../../../services/managementParametersService";
import { Roles_Enum, State } from "../../../../enums/enums";
import { getOperatorsService } from "../../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../../redux/actions/managementCompanyActions";
import { regLettersNumbers } from "../../../../utils/utils";

interface Props {
  formValues: any;
  countriesSelected: ICountryStep[];
  handleInputChange: (e: any) => void;
  handleSwitch: (e: any, index: number, value: any) => void;
  handleSelectCountry: (e: any, value: CountryType[]) => void;
  errorName: boolean;
  setErrorName: Dispatch<SetStateAction<boolean>>;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const FormConfigBrandCompany = ({
  formValues,
  countriesSelected,
  handleInputChange,
  handleSwitch,
  handleSelectCountry,
  errorName,
  setErrorName,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [expandCurrentIcon, setExpandCurrentIcon] = useState("");
  const [, setIsLoading] = useState(false);
  const { token, user } = useSelector((state: AppState) => state.auth);
  const { companies, activeBrand } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const [countryGames, setCountryGames] = useState<any[]>([]);
  const getParam = async (country: number): Promise<ICountryGamesDb[]> => {
    setIsLoading(true);
    if (!token || !user) {
      return [];
    }

    const response = await getParamGameByCountry(
      token,
      country,
      formValues.operator
    );
    setIsLoading(false);
    return response.data;
  };

  useEffect(() => {
    if (formValues.operator !== 0) {
      const getGames = async () => {
        const finalData: any[] = [];
        for (const country of countriesSelected) {
          const response = await getParam(parseInt(country.id));

          const objCountryGame = {
            id: country.id,
            games: response,
          };
          finalData.push(objCountryGame);
        }
        finalData.forEach((data) => {
          data.games = data.games.filter((game: any) => game.active === 1);
        });
        setCountryGames(finalData);
      };
      getGames();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesSelected, formValues.operator]);

  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    token && fetchOperators(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* CONFIGURACIÓN MARCA */}
      <Grid item xs={12} className="mb-2">
        <Box
          display="flex"
          className="pb-1"
          borderBottom={1}
          borderColor="#02D0E7"
        >
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <p className="txt-primary-md" style={{ fontWeight: 500 }}>
            {t("companyManagement.configureBrand")} -{" "}
            {(user?.operator && user.operator.name) || "SUPER ADMIN"}
          </p>
        </Box>
      </Grid>

      <Grid item xs={3}>
        <label className="label-text">
          {t("companyManagement.brandName")}*
        </label>
        <TextField
          name="name"
          variant="outlined"
          size="small"
          type="text"
          InputLabelProps={{ shrink: false }}
          value={formValues.name}
          label={" "}
          onChange={(e: any) => {
            setErrorName(false);
            handleInputChange(e);
          }}
          fullWidth
          autoComplete="off"
          inputProps={{ maxLength: 150 }}
          onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
            !regLettersNumbers(e.key) && e.preventDefault()
          }
          error={errorName}
          
        />
      </Grid>
      <Grid item xs={9}>
        <label className="label-text">
          {t("companyManagement.selectCountry")}*
        </label>
        <CountrySelect
          countriesSelected={countriesSelected}
          handleSelectCountry={handleSelectCountry}
        />
      </Grid>

      {
        //MOSTRAR SELECT CON OPERADORES CUANDO SEA SUPER_ADMIN
        user?.roles.name === Roles_Enum.SUPER_ADMIN && !activeBrand && (
          <Grid item xs={3}>
            <label className="ml-3">
              {t("companyManagement.operator") + "*"}
            </label>
            <FormControl
              sx={{ width: "100%" }}
              size="small"
              disabled={!!activeBrand}
            >
              <Select
                name="operator"
                size="small"
                fullWidth
                onChange={handleInputChange}
                value={
                  formValues.operator === 0 ? "" : formValues.operator || ""
                }
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                MenuProps={MenuProps}
              >
                {companies?.data?.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )
      }

      {/* CONFIGURACIÓN JUEGOS */}
      <Grid item xs={12} className="mb-2 mt-2">
        <Box
          display="flex"
          className="pb-1"
          borderBottom={1}
          borderColor="#02D0E7"
        >
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <p className="txt-primary-md" style={{ fontWeight: 500 }}>
            {t("companyManagement.configureGames")}
          </p>
        </Box>
      </Grid>
      {countriesSelected.map((country, indexCountry) => (
        <Grid item xs={12} key={country.id}>
          <Box className="box-games" display="flex">
            <Grid container>
              <Grid item xs={1} display="flex" alignItems={"stretch"}>
                <Box className="box-games-img">
                  <img src={logoGames} alt="Logo games" />
                </Box>
              </Grid>
              <Grid item xs={11}>
                <Box width={1} m={2} mt={1} pr={3}>
                  <Grid container>
                    <Grid item xs={6}>
                      <label className="label-text">
                        {t("companyManagement.company").toUpperCase()}
                      </label>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Grid
                        container
                        display={"flex"}
                        alignItems="stretch"
                        justifyContent={"space-between"}
                      >
                        <Grid
                          item
                          xs={11}
                          p={1}
                          sx={{ wordWrap: "break-word" }}
                        >
                          {formValues.name}
                        </Grid>{" "}
                        <Grid item xs={1}>
                          <IconButton
                            size="small"
                            style={{ border: "none", outline: "none" }}
                            onClick={() =>
                              expandCurrentIcon === country.name
                                ? setExpandCurrentIcon("")
                                : setExpandCurrentIcon(country.name as string)
                            }
                          >
                            {expandCurrentIcon === country.name ? (
                              <i className="fa fa-angle-up" />
                            ) : (
                              <i className="fa fa-angle-down" />
                            )}
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container mt={1}>
                    <Grid item xs={12}>
                      <Box
                        borderBottom={1}
                        borderColor="#9E9E9E"
                        width={1}
                        display="flex"
                      >
                        <Grid item xs={6}>
                          <label className="label-text">
                            {t(
                              "companyManagement.selectedCountry"
                            ).toUpperCase()}
                          </label>
                        </Grid>
                        <Grid item xs={6}>
                          <img
                            loading="lazy"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "10px",
                              borderRadius: "50%",
                              padding: 0,
                            }}
                            src={country.imgUrl}
                            alt=""
                          />
                          <label className="label-text">{country.name}</label>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    m={1}
                    display="flex"
                    justifyContent="space-between"
                  >
                    {expandCurrentIcon === country.name && (
                      <>
                        {countryGames.find((game) => game.id === country.id)
                          ?.games.length === 0 && (
                          <label className="label-text mt-2">
                            {t("parameterization.noParamGames")}
                          </label>
                        )}
                        {formValues.operator !== 0 &&
                          countryGames
                            .find((gameDB) => gameDB.id === country.id)
                            ?.games.map((game: any) => (
                              <Grid className="flex-switch" key={game.id}>
                                <label className="label-text">
                                  {game.games.name.toUpperCase()}{" "}
                                </label>
                                <Switch
                                  name={game.games.name}
                                  onChange={(e) =>
                                    handleSwitch(e, indexCountry, {
                                      id: game.games.id,
                                      name: game.games.name,
                                      idParamGame: game.id,
                                    })
                                  }
                                  checked={
                                    !!country.countriesGames?.find(
                                      (el) => el.name === game.games.name
                                    )
                                  }
                                />
                              </Grid>
                            ))}
                      </>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      ))}
    </>
  );
};
