import { MovementType, ProvidersString } from "../enums/enums";

interface IQueryIndicators {
  startDate: string;
  endDate: string;

  provider: ProvidersString;
  type: MovementType;
  operator: string;
}

export const getIndicators = (
  token: string,
  params: Partial<IQueryIndicators>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}indicators?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
