import React, { FC, useRef } from "react";
import { Box, Grid, Button } from "@mui/material";
import { QRCodeCanvas } from "qrcode.react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import DepositTable from "./DepositTable";

const DepositQR: FC = () => {
  const qrRef = useRef<HTMLDivElement>(null);
  const { user } = useSelector((state: AppState) => state.auth);

  const handleDownloadQRCode = () => {
    if (qrRef.current) {
      const canvas = qrRef.current.querySelector("canvas");
      if (canvas) {
        const largeCanvas = document.createElement("canvas");
        const size = 500;
        largeCanvas.width = size;
        largeCanvas.height = size;
        const context = largeCanvas.getContext("2d");
        if (context) {
          context.drawImage(canvas, 0, 0, size, size);
          const link = document.createElement("a");
          link.href = largeCanvas.toDataURL("image/png");
          link.download = "QRCode.png";
          link.click();
        }
      }
    }
  };

  const handlePrintQRCode = useReactToPrint({
    content: () => qrRef.current,
    documentTitle: "QRCode",
    pageStyle: `
      @media print {
        body {
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
        }
        .print-qr-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100vw;
          height: 100vh;
          overflow: hidden;
        }
        canvas {
          width: 500px !important;
          height: 500px !important;
        }
      }
    `,
  });

  return (
    <div className="content w-100 mb-5">
      <div className="white-container">
        <Grid container spacing={2}>
          <Grid item xs={12} className="mb-4">
            <Box
              display="flex"
              className="pb-1"
              borderBottom={1}
              borderColor="#02D0E7"
            >
              <i
                className="fa-solid fa-circle-info"
                style={{ color: "#02D0E7", marginRight: 9, fontSize: 18 }}
              ></i>
              <p
                className="txt-primary-md"
                style={{ fontWeight: 600, fontSize: 15 }}
              >
                Administrador depósitos por código QR
              </p>
            </Box>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} className="mt-4">
              <Box
                display="flex"
                justifyContent="center"
                ref={qrRef}
                className="print-qr-container"
              >
                <QRCodeCanvas
                  value={`${String(
                    process.env.REACT_APP_SITE_URL
                  )}external-deposit/${user?.id}`}
                  className="print-qr"
                />
              </Box>
              <Box
                display="flex"
                className="mt-2"
                justifyContent="center"
                gap={2}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleDownloadQRCode}
                >
                  Descargar
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handlePrintQRCode}
                >
                  Imprimir
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DepositTable />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DepositQR;
