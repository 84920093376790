import { Box, Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import { useTranslation } from "react-i18next";

export const DealerIrrigatedCardsDetail = () => {
  const { irrigatedDetail } = useSelector(
    (state: AppState) => state.managementCards
  );
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <label className="font-weight-bold">
          {t("cards.totalDeliveredCards").toUpperCase()}
        </label>
        <Box className="no-overflow">{irrigatedDetail?.total}</Box>
      </Grid>
      <Grid item xs={6}>
        <label className="font-weight-bold">
          {t("cards.batch").toUpperCase()}
        </label>
        <Box className="no-overflow">{irrigatedDetail?.lot.id}</Box>
      </Grid>
    </Grid>
  );
};
