import moment from "moment";
import { useSelector } from "react-redux";
import { headersBets } from "../../../helpers/headersTables";
import { AppState } from "../../../redux/reducers/rootReducer";
import { getCopyArrayOrObject, roundNumberDown } from "../../../utils/utils";
import { betStatuses } from "../../../helpers/betStatus";
import { useTranslation } from "react-i18next";
import { State_Bets } from "../../../enums/enums";

export const BetsDataTable = () => {
  const { t } = useTranslation();
  let { bets, totalBets } = useSelector((state: AppState) => state.bets);

  const copyBets = getCopyArrayOrObject(bets);
  const copyTotalBets = getCopyArrayOrObject(totalBets);
  const headersCSVBets = headersBets;

  //   COLUMNS HEADERS STYLES
  const headersCvsStyle = headersCSVBets.map((header) => {
    return {
      title: header.label,
      width: { wpx: 150 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });
  // Obtener datos por vista
  const dataBets = copyBets ? copyBets.data : [];

  // Obtener el total de datos
  const dataTotalBets = copyTotalBets ? copyTotalBets.data : [];

  // DATA POR VISTA
  const multiDataSetBets = [
    {
      columns: headersCvsStyle,
      data:
        dataBets &&
        dataBets.map((data: any) => {
          let feeds = 1;
          data.events.forEach((item: any) => {
            feeds =
              data.eventCount > 1
                ? data.betStakes[0].odds
                : item.markets[0]
                ? item.markets[0].odds * 1
                : 1;
          });
          let array;
          array = [
            {
              value: data.id,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: moment(data.createAt).format("DD/MM/YYYY HH:mm:ss A"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value:
                data.eventCount > 1
                  ? `${t(
                      data.isSystem ? "betType.system" : "betType.multiple"
                    )} (${data.eventCount})`
                  : t(data.isSystem ? "betType.system" : "betType.simple"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value:
                data.eventCount > 1
                  ? `${t("bets.eventsNumber")} (${data.eventCount})`
                  : "-",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value:
                data.transactions &&
                data.transactions.length > 0 &&
                State_Bets.WON === data.statusName
                  ? (parseFloat(data.transactions[0].amount) / feeds).toFixed(2)
                  : parseFloat(data.amount).toFixed(2),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value:
                State_Bets.WON === data.statusName
                  ? data.transactions && data.transactions.length > 0
                    ? parseFloat(data.transactions[0].amount).toFixed(2)
                    : (parseFloat(data.amount) * feeds).toFixed(2)
                  : "0.00",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: roundNumberDown(feeds),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.bonusAccountId ? t("table.yes") : t("table.not"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: betStatuses
                .find((status) => status.value === data.status)
                ?.description.toUpperCase(),
              style: { alignment: { horizontal: "center" } },
            },
          ];
          return array;
        }),
    },
  ];

  //   DATA TOTAL
  const multiTotalDataSetBets = [
    {
      columns: headersCvsStyle,
      data:
        dataTotalBets &&
        dataTotalBets.map((data: any) => {
          let feeds = 1;
          data.events.forEach((item: any) => {
            feeds =
              data.eventCount > 1
                ? data.betStakes[0].odds
                : item.markets[0]
                ? item.markets[0].odds * 1
                : 1;
          });
          let array;
          array = [
            {
              value: data.id,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: moment(data.createAt).format("DD/MM/YYYY HH:mm:ss A"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value:
                data.eventCount > 1
                  ? `${t(
                      data.isSystem ? "betType.system" : "betType.multiple"
                    )} (${data.eventCount})`
                  : t(data.isSystem ? "betType.system" : "betType.simple"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value:
                data.eventCount > 1
                  ? `${t("bets.eventsNumber")} (${data.eventCount})`
                  : "-",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value:
                data.transactions &&
                data.transactions.length > 0 &&
                State_Bets.WON === data.statusName
                  ? (parseFloat(data.transactions[0].amount) / feeds).toFixed(2)
                  : parseFloat(data.amount).toFixed(2),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value:
                State_Bets.WON === data.statusName
                  ? data.transactions && data.transactions.length > 0
                    ? parseFloat(data.transactions[0].amount).toFixed(2)
                    : (parseFloat(data.amount) * feeds).toFixed(2)
                  : "0.00",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: roundNumberDown(feeds),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.bonusAccountId ? t("table.yes") : t("table.not"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: betStatuses
                .find((status) => status.value === data.status)
                ?.description.toUpperCase(),
              style: { alignment: { horizontal: "center" } },
            },
          ];
          return array;
        }),
    },
  ];

  return {
    dataBets,
    dataTotalBets,
    headersCSVBets,
    multiDataSetBets,
    multiTotalDataSetBets,
  };
};
