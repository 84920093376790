import React, { FC } from "react";
import { Grid, TableCell, Tooltip } from "@mui/material";
import { State } from "../../../enums/enums";
import { useTranslation } from "react-i18next";
import { IDealer } from "../../../interfaces/cards";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveDealer,
  startUpdateDealer,
} from "../../../redux/actions/managementDealersActions";
import { setFlagForm } from "../../../redux/actions/helperActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useLocation } from "react-router-dom";

interface props {
  row: IDealer;
}
export const RowDealers: FC<props> = ({ row }) => {
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { pathname } = useLocation();

  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);
  const enableUpdate =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.updated === 1;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onChangeState = async () => {
    await dispatch(
      startUpdateDealer(row.id, {
        state: row.state === State.ACTIVE ? State.INACTIVE : State.ACTIVE,
      })
    );
  };
  const onEdit = () => {
    dispatch(setActiveDealer(row));
    dispatch(setFlagForm({ flagFormCreate: true }));
  };
  return (
    <>
      <TableCell align="center">{row.id}</TableCell>
      <TableCell align="center">{row.name}</TableCell>
      <TableCell align="center">{row.phone}</TableCell>
      <TableCell align="center">{row.email}</TableCell>
      <TableCell align="center">
        {row.comissionPercentage.toString() + "%"}
      </TableCell>
      <TableCell align="center">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                State.ACTIVE === row.state
                  ? "d-flex justify-content-center cellRowActive"
                  : State.INACTIVE === row.state
                  ? "d-flex justify-content-center cellRowInactive"
                  : "d-flex justify-content-center cellRowRetired"
              }
            >
              <div className="d-flex col-sm-4 justify-content-center">
                {State.ACTIVE === row.state && <>{t("state.active")}</>}
                {State.INACTIVE === row.state && <>{t("state.inactive")}</>}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell align="center">
        {enableUpdate && (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Tooltip title={t("button.changeState") as string} arrow>
                <div>
                  <i
                    className={
                      row.state === State.ACTIVE
                        ? `fa fa-eye fa-lg mr-2 iconSee`
                        : `fa-regular fa-eye-slash fa-lg mr-2 iconSee`
                    }
                    onClick={onChangeState}
                  />
                </div>
              </Tooltip>
              <Tooltip title={t("button.edit") as string} arrow>
                <div>
                  <i
                    onClick={onEdit}
                    className="fa fa-pencil-square-o fa-lg mr-2 iconEdit"
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        )}
      </TableCell>
    </>
  );
};
