import { IAssociateForm, IAssociateUpdate } from "../interfaces/associate";

export const getAssociatesService = (
  token: string,
  page: number,
  rowsPerPage: number | null,
  operatorId: number | null
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const params = rowsPerPage
    ? operatorId
      ? `operatorId=${operatorId}&page=${page}&perPage=${rowsPerPage}`
      : `page=${page}&perPage=${rowsPerPage}`
    : operatorId
    ? `operatorId=${operatorId}&page=${page}`
    : `page=${page}`;

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}associates?${params}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const getAssociatesFilterService = (
  token: string,
  operatorId: number | null,
  params?: any
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  let queryString = params ? new URLSearchParams(params).toString() : "";
  const operator = operatorId ? `operatorId=${operatorId}` : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}associates?${operator}&${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const editAssociatesService = (
  data: IAssociateUpdate,
  token: string,
  id: number
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}associates/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const addAssociatesService = (data: IAssociateForm, token: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${process.env.REACT_APP_API_HTTP_SECURITY}associates`, requestOptions)
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const getAssociateById = (
  token: string,
  id: number,
  findUserAssociate?: boolean,
  pointSalesAssociate?: boolean
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const findUser = findUserAssociate ? `findUser=true` : "";
  const pointSales = pointSalesAssociate ? `pointSales=true` : "";
  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}associates/${id}?${findUser}&${pointSales}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
