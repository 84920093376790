import { Box } from "@mui/material";
import React, { FC, useState } from "react";
import Filter from "../../../../../components/Filter/Filter";
import { CustomAlert } from "../../../../../components/Alerts/CustomAlert";
import { useTranslation } from "react-i18next";
import {
  Enum_FieldType,
  IFilterField,
} from "../.././../../../interfaces/components";
import { useDispatch, useSelector } from "react-redux";
import { asyncLoadingFunction } from "../../../../../redux/actions/helperActions";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import MyCustomTable, {
  IColumns,
} from "../../../../../components/Custom/Table/MyCustomTable";
import { RowIrrigationAdminHistory } from "./RowIrrigationAdminHistory";
import {
  setIrrigationCardsHistory,
  startGetIrrigationCardsHistory,
} from "../../../../../redux/actions/managementCardsActions";
import { CurrentPlaceInventoryCards } from "../../../../../helpers/currentPlaceCard";
import { CurrentPlaceCards, Roles_Enum } from "../../../../../enums/enums";
import { IrrigationsCardsHistoryDataTable } from "../../../../../hooks/useDataTable/irrigation/cards/IrrigationsCardsHistoryDataTable";
import moment from "moment";
import { IrrigationCardsHistoryPdf } from "../../../../../components/Pdf/Irrigation/cards/IrrigationsCardsHistoryPdfDocument";
import { getIrrigationCardsHistory } from "../../../../../services/cards";
import { getUserById } from "../../../../../services/managementUsers";

export const IrrigationAdminHistory: FC<{ to?: CurrentPlaceCards }> = ({
  to,
}) => {
  const [dataFilter, setDataFilter] = useState({});
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { irrigationCardsHistory } = useSelector(
    (state: AppState) => state.managementCards
  );
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterFields: IFilterField[] = [
    {
      label: t("form.value"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
  ];

  const columns: IColumns[] = [
    {
      align: "center",
      label: t("reports.deliveredTo"),
    },
    {
      align: "center",
      label: t("irrigation.lot"),
    },
    {
      align: "center",
      label: t("form.value"),
    },
    {
      align: "center",
      label: t("cards.consecutiveCard"),
    },
    {
      align: "center",
      label: t("irrigation.redeemed"),
    },
    {
      align: "center",
      label: t("irrigation.available"),
    },
    {
      align: "center",
      label: t("irrigation.inactive"),
    },
    {
      align: "center",
      label: t("reports.deliveredDate"),
    },
  ];
  const data = irrigationCardsHistory;

  const currentPlace = CurrentPlaceInventoryCards(
    user?.roles.name as Roles_Enum
  );

  const handleFilter = async (formValues: any) => {
    const startDate = formValues[t("filters.startDate")]
      ? moment(formValues[t("filters.startDate")]).format("YYYY-MM-DD") +
        " 00:00:00"
      : null;
    const endDate = formValues[t("filters.endDate")]
      ? moment(formValues[t("filters.endDate")]).format("YYYY-MM-DD") +
        " 23:59:59"
      : null;
    const lotId = formValues[t("filters.lotId")];
    const params = {
      userId: user?.id,
      currentPlace: currentPlace,
      cardAmount: formValues[t("form.value")],
      startDate,
      endDate,
      lotId,
      page: 1,
      perPage: 9,
      to,
    };
    await dispatch(
      asyncLoadingFunction(
        async () =>
          await dispatch(startGetIrrigationCardsHistory({ ...params }))
      )
    );
    setDataFilter(params);
  };
  const onCleanFilter = async () => {
    dispatch(setIrrigationCardsHistory(null));
    setDataFilter({});
  };
  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(
        async () =>
          await dispatch(
            startGetIrrigationCardsHistory({
              ...dataFilter,
              page,
              perPage: rows || rowsPerPage,
            })
          )
      )
    );
  };
  const getAllIrrigationCardsHistory = async () => {
    if (!token || !user) {
      throw new Error("invalid action");
    }
    const { page, perPage, ...rest }: any = dataFilter;
    const params: Record<string, any> = {};

    if (currentPlace === CurrentPlaceCards.ASSOCIATE) {
      const associateId =
        user.associatesUsers.length > 0
          ? user.associatesUsers[0].associate.id
          : user.id;
      rest.userId = associateId;
    }
    if (currentPlace === CurrentPlaceCards.DEALER) {
      const userDetail = await getUserById(token, user.id);
      const {
        dealer: { id: dealerId },
      } = userDetail;

      rest.userId = dealerId;
    }
    if (currentPlace === CurrentPlaceCards.PROTOMOR) {
      const userDetail = await getUserById(token, user.id);
      const { promotor } = userDetail;
      const promotorId = promotor?.id;
      rest.userId = promotorId;
    }
    for (const key in rest) {
      if (Object.prototype.hasOwnProperty.call(rest, key)) {
        if (rest[key] !== undefined && rest[key] !== null && rest[key] !== "") {
          params[key] = rest[key];
        }
      }
    }

    return await getIrrigationCardsHistory(params, token);
  };

  return (
    <Box className="filter" p={2} sx={{ minHeight: "300px" }}>
      <Box sx={{ border: "1px solid #DADADA", borderRadius: "5px" }} p={1}>
        <Box my={1}>
          <Filter
            fields={filterFields}
            handleFilter={handleFilter}
            onClean={onCleanFilter}
            cleanFilter={!data}
          />
        </Box>

        {!data && (
          <CustomAlert htmlText={t("irrigation.filterText")} type="info" />
        )}
      </Box>
      {data && data.total > 0 && (
        <Box mt={2}>
          <MyCustomTable
            columns={columns}
            data={data.data}
            lastPage={data.last_page}
            totalData={data.total}
            onPageChange={handlePageChange}
            renderBody={(item) => <RowIrrigationAdminHistory row={item} />}
            exportData
            hookBuildTable={IrrigationsCardsHistoryDataTable}
            getAllDataToExport={getAllIrrigationCardsHistory}
            pdfComponent={IrrigationCardsHistoryPdf}
            titleCSV={`${t("reports.cardIrrigation")
              .split(" ")
              .join("-")}-${moment().toISOString()}`}
          />
        </Box>
      )}
    </Box>
  );
};
