import React, { FC } from "react";
import { headersIrrigationCardsHistory } from "../../../../helpers/headersTables";
import { Text, View } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../views/components/custom/customStyles";
import moment from "moment";
import { Roles_Enum } from "../../../../enums/enums";

interface props {
  page: any[];
  rol?: Roles_Enum;
}
export const IrrigationCardsHistoryPdf: FC<props> = ({ page, rol }) => {
  const columns = headersIrrigationCardsHistory;
  const isRoleAssociate = rol === Roles_Enum.ASSOCIATE;
  const isRoleDealer = rol === Roles_Enum.DEALER;
  const isRoleAdmin = rol === Roles_Enum.ADMIN_OPERATOR;
  const isRolePromotor = rol === Roles_Enum.PROMOTOR;

  const nameUserIrrigation = (data: any): string => {
    let name: string = "";
    if (isRoleAssociate) {
      name = data.dealerName || data.pointSaleName || "";
    } else if (isRoleDealer) {
      name = data.promotorName ?? data.pointSaleName ?? "";
    } else if (isRoleAdmin) {
      name = data.associateName || "";
    } else if (isRolePromotor) {
      name = data.pointSaleName || "";
    }
    return name;
  };

  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, index) => (
          <View key={index} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>

      <View style={{ flex: 1 }}>
        {page.map((data, index) => (
          <View key={index} style={stylesPdf.row}>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {nameUserIrrigation(data)}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.lotId}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.cardAmount}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {data.minCardId}-{data.maxCardId}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.redeemedCards}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.activeCards}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.inactiveCards}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {moment(data.updateAt).format("YYYY-MM-DD HH:mm:ss")}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};
