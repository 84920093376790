import { KeyboardEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ICreateExtenalUser } from "../../../../interfaces/commercialNetworks";
import {
  regEmail,
  regLetters,
  regLettersNumbers,
  regNit,
  regNumbers,
  regUsername,
} from "../../../../utils/utils";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { Roles_Enum, State } from "../../../../enums/enums";
import { getOperatorsService } from "../../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../../redux/actions/managementCompanyActions";
import {
  asyncLoadingFunction,
  setFlagForm,
} from "../../../../redux/actions/helperActions";
import { MyDialog } from "../../custom/MyDialog";
import { MyTextField } from "../../custom/MyTextField";
import { createNewCommercialNetwork } from "../../../../redux/actions/commercialNetworkActions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const FormCreateCommercialNetwork = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { loading } = useSelector((state: AppState) => state.helper);
  const [showPassword, setShowPassword] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [cancel, setCancel] = useState(false);
  const [saveValues, setSaveValues] = useState<ICreateExtenalUser | null>(null);

  const initialValues: ICreateExtenalUser = {
    name: "",
    nit: "",
    legalRepresentative: "",
    phone: "",
    username: "",
    password: "",
    operator:
      user?.roles.name === Roles_Enum.SUPER_ADMIN ? "" : user?.operator.id!,
    email: "",
    endpoint: "",
    timezone: 'America/Bogota'
  };
  const validationErrorMsg = t("form.invalidLengthDocumentNumber", {
    min: 8,
    max: 13,
  });

  const validationSchema = yup.object({
    operator: yup.number().required(t("form.requiredFile")),
    name: yup
      .string()
      .required(t("form.requiredFile"))
      .min(3, `${t("form.minNumberCharacters")} 3`)
      .max(150, `${t("form.maxNumberCharacters")} 150`),
    nit: yup
      .string()
      .required(t("form.requiredFile"))
      .min(6, `${t("form.minNumberCharacters")} 6`)
      .max(100, `${t("form.maxNumberCharacters")} 100`),
    legalRepresentative: yup
      .string()
      .required(t("form.requiredFile"))
      .min(6, `${t("form.minNumberCharacters")} 6`)
      .max(150, `${t("form.maxNumberCharacters")} 150`),
    phone: yup
      .string()
      .min(8, validationErrorMsg)
      .max(13, validationErrorMsg)
      .required(t("form.requiredFile")),
    email: yup
      .string()
      .email(t("user.invalidEmail"))
      .required(t("form.requiredFile"))
      .min(3, `${t("form.minNumberCharacters")} 3`)
      .max(100, `${t("form.maxNumberCharacters")} 100`),
    username: yup
      .string()
      .required(t("form.requiredFile"))
      .min(6, `${t("form.minNumberCharacters")} 6`)
      .max(20, `${t("form.maxNumberCharacters")} 20`),
    password: yup
      .string()
      .required(t("form.requiredFile"))
      .min(6, t("alerts.minLengthPassword"))
      .max(20, t("form.maxLength"))
      .matches(
        //eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*()_+\-=;':"\\|,.?~])/,
        t("user.rexPassword")
      ),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    token && fetchOperators(token);
    return () => {
      dispatch(setCompaniesRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    setMessage("dialog.sureExit");
    setCancel(true);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setCancel(false);
    setOpenDialog(false);
  };

  const handleAccept = async () => {
    handleCloseDialog();
    if (cancel) {
      dispatch(setFlagForm({ flagFormCreate: false }));
    } else {
      saveValues &&
        dispatch(
          asyncLoadingFunction(
            async () => await dispatch(createNewCommercialNetwork(saveValues))
          )
        );
    }
  };

  return (
    <div className="container mt-2">
      <div className="filter">
        <Formik
          validateOnChange={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          enableReinitialize={true}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            setOpenDialog(true);
            setSaveValues(data);
            setMessage(t("commercialNetworks.sureCreateNetwork"));
            setCancel(false);
            setSubmitting(false);
          }}
        >
          {({ values, handleChange, isValid }) => (
            <Form>
              <Grid container spacing={1.5}>
                <Grid item xs={12} className="mb-3">
                  <Box
                    display="flex"
                    className="pb-1"
                    borderBottom={1}
                    borderColor="#02D0E7"
                  >
                    <i
                      className="fa-solid fa-circle-info"
                      style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
                    ></i>
                    <p className="txt-primary-md">
                      {t("commercialNetworks.createNetworks")}
                    </p>
                  </Box>
                </Grid>
                {user?.roles.name === Roles_Enum.SUPER_ADMIN && (
                  <Grid item xs={4}>
                    <label className="ml-3">
                      {t("companyManagement.operator") + "*"}
                    </label>
                    <FormControl
                      sx={{ width: "100%" }}
                      size="small"
                      error={submit && values.operator === ""}
                    >
                      <Select
                        name="operator"
                        value={values.operator ?? ""}
                        onChange={handleChange}
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                        MenuProps={MenuProps}
                      >
                        <MenuItem disabled value=""></MenuItem>
                        {companies?.data?.map((company) => (
                          <MenuItem key={company.id} value={company.id}>
                            {company.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormHelperText>
                      {submit && values.operator === "" && (
                        <label className="ml-3" style={{ color: "#d32f2f" }}>
                          {t("form.requiredFile")}
                        </label>
                      )}
                    </FormHelperText>
                  </Grid>
                )}

                <Grid item xs={4}>
                  <label className="ml-3">
                    {t("commercialNetworks.name")}*
                  </label>
                  <MyTextField
                    inputProps={{
                      maxLength: 150,
                    }}
                    name="name"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regLettersNumbers(e.key) && e.preventDefault()
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <label className="ml-3">{t("companyManagement.nit")}*</label>
                  <MyTextField
                    inputProps={{
                      maxLength: 100,
                    }}
                    name="nit"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regNit(e.key) && e.preventDefault()
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <label className="ml-3">
                    {t("companyManagement.legalRepresentative")}*
                  </label>
                  <MyTextField
                    inputProps={{
                      maxLength: 150,
                    }}
                    name="legalRepresentative"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regLetters(e.key) && e.preventDefault()
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <label className="ml-3">{t("user.phone")}*</label>
                  <MyTextField
                    name="phone"
                    inputProps={{
                      maxLength: 15,
                    }}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                      !regNumbers(e.key) && e.preventDefault();
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <label className="ml-3">{t("user.email")}*</label>
                  <MyTextField
                    name="email"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                      !regEmail(e.key) && e.preventDefault();
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <label className="ml-3">{t("user.username")}*</label>
                  <MyTextField
                    inputProps={{
                      maxLength: 20,
                    }}
                    name="username"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regUsername(e.key) && e.preventDefault()
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <label className="ml-3">{t("user.password")}*</label>
                  <MyTextField
                    inputProps={{
                      maxLength: 30,
                    }}
                    name="password"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={handleClickShowPassword}
                            sx={{
                              "&:focus": {
                                outline: 0,
                                border: 0,
                              },
                            }}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    type={showPassword ? "text" : "password"}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  mt={3}
                  className="gridButton"
                  sx={{ height: "60px" }}
                >
                  <Button
                    type="button"
                    style={{ border: "none", outline: "none" }}
                    variant="contained"
                    className="footer-btn danger-btn ripple"
                    onClick={handleCancel}
                  >
                    <i className="fa-solid fa-x"></i>
                    {t("button.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className="footer-btn primary-btn ripple"
                    id="btnSave"
                    data-testid="btnSubmit"
                    onClick={() => setSubmit(true)}
                    disabled={!isValid || !!loading}
                  >
                    {!!loading ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      <>{t("button.save")}</>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>

        <MyDialog
          open={openDialog}
          title={t("dialog.confirmationWindow")}
          message={message}
          handleAccept={handleAccept}
          handleClose={handleCloseDialog}
          isLoading={!!loading}
        />
      </div>
    </div>
  );
};
