import React, { FC } from "react";
import TableCell from "@mui/material/TableCell";
import { State } from "../../../enums/enums";
import { useTranslation } from "react-i18next";
import { IMenuList } from "../../../interfaces/menu";

interface Props {
  row: IMenuList;
}

export const RowDataTable: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  const tableClass = (row: IMenuList) => {
    if (State.ACTIVE === row.state) {
      return "d-flex justify-content-center cellRowActive";
    } else {
      if (State.INACTIVE) {
        return "d-flex justify-content-center cellRowInactive";
      } else {
        return "d-flex justify-content-center cellRowRetired";
      }
    }
  };

  return (
    <>
      <TableCell className="word-break">{row.url}</TableCell>
      <TableCell className="word-break">{row.icon}</TableCell>
      <TableCell className="word-break">
        {row?.createdUser?.nameComplete}
      </TableCell>
      <TableCell align="center">
        <div className="row">
          <div className="col-sm-12">
            <div className={tableClass(row)}>
              <div className="d-flex col-sm-4 justify-content-center">
                {State.ACTIVE === row.state && <>{t("state.active")}</>}
                {State.INACTIVE === row.state && <>{t("state.inactive")}</>}
                {State.RETIRED === row.state && <>{t("state.retired")}</>}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
    </>
  );
};
