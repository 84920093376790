import React, { FC } from "react";
import { TableCell } from "@material-ui/core";
import { ITotalWallet } from "../../../interfaces/cards";
import { roundCommission } from "../../../utils/utils";
//import { roundCommission } from "../../../utils/utils";
interface props {
  row: ITotalWallet;
}
export const RowTotalWallet: FC<props> = ({ row }) => {
  const roundedCommission = roundCommission(row.totalComission)
  const roundTotalRecibe = roundCommission(row.totalSell - roundedCommission)

  return (
    <>
      <TableCell align="center">{row.amountCards}</TableCell>
      <TableCell align="center">{row.amountSell}</TableCell>
      <TableCell align="center">{`$${row.totalSell} ${row.currency.code}`}</TableCell>
      <TableCell align="center">{`$${roundedCommission} ${row.currency.code}`}</TableCell>
      <TableCell align="center">{`$${roundTotalRecibe} ${
        row.currency.code
      }`}</TableCell>
    </>
  );
};
