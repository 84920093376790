import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "reactstrap";

import { Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";

import { AppState } from "../../../redux/reducers/rootReducer";
import { FormConfigOperationCompany } from "./ConfigCompany/FormConfigOperationCompany";
import {
  setActiveStep,
  setGames,
  setLanguages,
  setSolutions,
} from "../../../redux/actions/helperActions";
import { useEffect, useState } from "react";
import {
  setActiveBrand,
  setActiveCompany,
} from "../../../redux/actions/managementCompanyActions";

const stylesStepper = {
  "& .MuiStepConnector-line": {
    height: "70px",
    width: "2px",
    border: "1px solid #C0C0C0",
  },
  "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
    border: "1px solid #0BC5DA",
    height: "70px",
    width: "0px",
  },
  "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
    border: "1px solid  #0BC5DA",
    height: "70px",
    width: "0px",
  },
  "& .MuiStepLabel-root .Mui-active": {
    color: "#0BC5DA",
    fontSize: 30,
    borderRadius: "50%",
    border: "1px solid #35D9EC",
    overflow: "hidden",
    padding: "1px",
    background: "#fff",
  },
  "& .MuiStepLabel-root .Mui-completed": {
    color: "#0BC5DA",
  },
};

export const StepsConfigOperationCompany = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const steps = ["1", "2", "3", "4", "5", "6"];
  const { activeStep } = useSelector((state: AppState) => state.helper);
  const [nextStep, setNextStep] = useState(false);
  const { token } = useSelector((state: AppState) => state.auth);
  const { activeBrand } = useSelector(
    (state: AppState) => state.managementCompanies
  );

  useEffect(() => {
    if (token) {
      dispatch(setLanguages(token));
      dispatch(setGames(token));
      dispatch(setSolutions(token));
    }

    return () => {
      dispatch(setActiveBrand(null));
      dispatch(setActiveCompany(null));
      dispatch(setActiveStep(0));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content w-100 pl-4 mt-1">
      <Row>
        <Row style={{ marginBottom: 10 }}>
          <Col xs={12}>
            <Typography color="text.primary" style={{ fontSize: "16px" }}>
              {t("companyManagement.title")} /{" "}
              {t(
                !!activeBrand
                  ? "companyManagement.editConfiguration"
                  : "companyManagement.createConfiguration"
              )}
            </Typography>
          </Col>
        </Row>
        <Grid container>
          <Grid item xs={1} sm={1} md={0.5} mb={2} pl={1}>
            <Stepper
              activeStep={activeStep}
              orientation={"vertical"}
              sx={stylesStepper}
            >
              {steps.map((label, index: number) => (
                <Step key={label}>
                  <StepLabel
                    onClick={() => nextStep && dispatch(setActiveStep(index))}
                  ></StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={11} sm={11} md={11.5}>
            <FormConfigOperationCompany setNextStep={setNextStep} />
          </Grid>
        </Grid>
      </Row>
    </div>
  );
};
