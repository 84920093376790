import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { TableComponent } from "../../../../components/Table/TableComponent";
import { IColumnsTable } from "../../../../interfaces/components";
import { AppState } from "../../../../redux/reducers/rootReducer";

const columns: IColumnsTable[] = [
  { id: 1, header: "pointsOfSale.productID" },
  { id: 2, header: "pointsOfSale.productName" },
  { id: 3, header: "pointsOfSale.activationDate" },
  { id: 4, header: "pointsOfSale.totalPercentage" },
  { id: 5, header: "form.state" },
];

export const CommissionalPointSaleTab = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);
  const { activePointSale } = useSelector(
    (state: AppState) => state.pointsSale
  );

  const commissioners = [
    t("pointsOfSale.refillsCredits"),
    t("tabs.paymentOrders"),
    t("pointsOfSale.affiliateSales"),
    t("pointsOfSale.gameSales"),
  ];

  useEffect(() => {
    const arrayCommissioners: any[] = [];
    if (activePointSale) {
      commissioners.forEach((el, index) => {
        arrayCommissioners.push({
          id: index + 1,
          name: el,
          activationDate: activePointSale.configCommissioners.find(
            (row) => row.commissional.name.toLowerCase() === el.toLowerCase()
          )?.createAt || "",
          totalPercentage:
            activePointSale.configCommissioners.find(
              (row) => row.commissional.name.toLowerCase() === el.toLowerCase()
            )?.percentage || 0,
          state: !!activePointSale.configCommissioners.find(
            (row) => row.commissional.name.toLowerCase() === el.toLowerCase()
          ),
        });
      });
    }
    setData(arrayCommissioners);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePointSale]);

  return (
    <TableComponent
      columns={columns}
      data={data}
      pages={1}
      component={"commissionalPointSale"}
      totalData={
        data
          ? {
              total: data.length,
              last_page: 1,
              page: 1,
            }
          : []
      }
      credentialsTitle={
        t("associates.listAssociatedCommissioners").toUpperCase() +
        " " +
        activePointSale?.name.toUpperCase()
      }
      isSortingTable={false}
      dataFilter={{}}
    />
  );
};
