import { AltenarBonusCampain, BonusRedeemedStatus } from "../enums/enums";
import i18next from "../i18n";

export const bonusCampaignType = [
  {
    label: i18next.t("altenar.Unknown"),
    value: AltenarBonusCampain.Unknown,
  },
  {
    label: i18next.t("altenar.CodedBonus"),
    value: AltenarBonusCampain.CodedBonus,
  },
  {
    label: i18next.t("altenar.DepositBonus"),
    value: AltenarBonusCampain.DepositBonus,
  },
  {
    label: i18next.t("altenar.FirstDepositBonus"),
    value: AltenarBonusCampain.FirstDepositBonus,
  },
  {
    label: i18next.t("altenar.FreeBetBonus"),
    value: AltenarBonusCampain.FreeBetBonus,
  },
  {
    label: i18next.t("altenar.CashbackBonus"),
    value: AltenarBonusCampain.CashbackBonus,
  },
  {
    label: i18next.t("altenar.RiskFreeBet"),
    value: AltenarBonusCampain.RiskFreeBet,
  },
];

export const bonusRedeemedStatus = [
  {
    label: i18next.t("altenar.init"),
    value: BonusRedeemedStatus.INIT,
  },
  {
    label: i18next.t("altenar.active"),
    value: BonusRedeemedStatus.ACTIVE,
  },
  {
    label: i18next.t("altenar.expired"),
    value: BonusRedeemedStatus.EXPIRED,
  },
  {
    label: i18next.t("altenar.cancelled"),
    value: BonusRedeemedStatus.CANCELLED,
  },
  {
    label: i18next.t("altenar.closed"),
    value: BonusRedeemedStatus.CLOSED,
  },
  {
    label: i18next.t("altenar.won"),
    value: BonusRedeemedStatus.WON,
  },
];
