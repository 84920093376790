import { ISlides } from "../interfaces/slides";

export const createNewSlide = (data: ISlides, token: string) => {
  const myHeaders = new Headers();
  myHeaders.append("api", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Authorization", `bearer ${token}`);
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("operator", data.operator);
  formData.append("linkTo", data.linkTo);
  formData.append("module", data.module);
  data.image && formData.append("slide", data.image, data.image.name);

  const requestOptions: any = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}slides`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const deleteSlide = (
  operatorId: number | undefined,
  id: number,
  token: string
) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}slides/${operatorId}/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const updateOrder = (
  data: Pick<ISlides, "operator" | "sliders" | "module">,
  token: string
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify({ sliders: data.sliders, module: data.module }),
  };
  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}slides/${data.operator}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const getSlidesByOperator = (
  token: string,
  idOperator: number,
  params: any
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_MARKETING}slides/${idOperator}?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
