import { IExclusionAuditQuery } from "../../interfaces/exclusionsAudit";
import { getExclusionsAudit } from "../../services/exclusionsAudit";
import { AppState } from "../reducers/rootReducer";
import { setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";
import { arraySearch } from "./searchActions";

export const startGetExclusionsAudit = (
  params: Partial<IExclusionAuditQuery>
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;

    try {
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      if (params.page) {
        params.perPage = rowsPerPage;
      }
      if (user?.operator?.id) {
        params.operatorId = user.operator.id;
      }
      const response = await getExclusionsAudit(token, params);
      if (!response.error) {
        const { rs } = response;
        if (rs.data?.length === 0) {
          dispatch(arraySearch(null));
          dispatch(setMessageAlert("alerts.noData"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        } else {
          dispatch(arraySearch(rs));
        }
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};
