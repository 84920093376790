import { Types } from "../types/types";

interface ICasinoActions {
  type: Types;
  payload?: any;
}

interface ICasinoState {
  casinoCategory: any;
  activeCategory: any;
  casinoGames: any;
}

const initialState: ICasinoState = {
  casinoCategory: null,
  activeCategory: null,
  casinoGames: null,
};

export const managementCasinoReducer = (
  state = initialState,
  action: ICasinoActions
): ICasinoState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setCasinoCategory:
      return {
        ...state,
        casinoCategory: payload as any,
      };
    case Types.setActiveCategory:
      return {
        ...state,
        activeCategory: payload as any,
      };
    case Types.setCasinoGames:
      return {
        ...state,
        casinoGames: payload as any,
      };
    default:
      return state;
  }
};
