import { FC } from "react";
import { View, Text } from "@react-pdf/renderer";
import moment from "moment";

import { stylesPdf } from "../../../views/components/custom/customStyles";
import { headersCommercialNetwork } from "../../../helpers/headersTables";
import { IExternalUsersData } from "../../../interfaces/commercialNetworks";
import { capitalizeFirstLetter } from "../../../utils/utils";

interface Props {
  page: IExternalUsersData[];
}

export const CommercialNetworksPdf: FC<Props> = ({ page }) => {
  const columns = headersCommercialNetwork;
  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, index) => (
          <View key={index} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        {page.map((page: IExternalUsersData) => {
          return (
            <View key={page.id} style={stylesPdf.row}>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{capitalizeFirstLetter(page.name)}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{page.nit}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {page.legalRepresentative}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{page.phone}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{page.email}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {moment(page.createAt).format("YYYY-MM-DD HH:mm:ss")}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </>
  );
};
