import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Tab, Tabs } from "@mui/material";

import { Row } from "reactstrap";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";

import { BetLimit } from "./BetLimit";
import { SessionLimit } from "./SessionLimit";
import { MyAlert } from "../../components/custom/MyAlert";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { setCloseAlert } from "../../../redux/actions/helperActions";
import { DepositLimit } from "./DepositLimit";
import { WithdrawalLimit } from "./WithdrawalLimit";
import { LimitsPerPlayer } from "./LimitsPerPlayer";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const styles = {
  marginRight: "3px !important",
  width: "260px",
  boxShadow: "3px 0px 1px rgba(0, 0, 0, 0.25) !important",
  borderRadius: "0px 5px 0px 0px",
  backgroundColor: "#EEEEEE",
  color: "#747474",
  "&.Mui-selected": {
    border: "none",
    outline: "none",
    boxShadow: "3px 0px 1px rgba(2, 208, 231, 0.25) !important",
    borderRadius: "0px 5px 0px 0px",
    color: "#02D0E7",
    backgroundColor: "#C6F9FF",
    fontWeight: 700,
  },
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const LimitPlayers = () => {
  const [value, setValue] = React.useState(0);
  const { openAlert, messageAlert, typeAlert } =
    useSelector((state: AppState) => state.helper);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { t } = useTranslation();
  const dispatch = useDispatch()

  return (
    <div className="content w-100">
      <Row style={{ marginBottom: 10, marginLeft: 0 }}>
        <MyBreadCrumbs
          titles={[
            t("sidebar.player"),
            t("player.limits"),
          ]}
        />
      </Row>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          style={{ border: "none", outline: "none" }}
          variant="scrollable"
          TabIndicatorProps={{
            style: {
              display: "none",
              width: "250px",
            },
          }}
        >
          <Tab
            sx={styles}
            label={<span className="spanTab">{t("playerLimit.betLimit")}</span>}
            {...a11yProps(0)}
          />
           <Tab
            sx={styles}
            label={<span>{t("playerLimit.depositLimit")}</span>}
            {...a11yProps(1)}
          />
          <Tab
            sx={styles}
            label={<span>{t("playerLimit.sesionLimit")}</span>}
            {...a11yProps(2)}
          />
          <Tab
            sx={styles}
            label={<span>{t("playerLimit.withdrawalLimit")}</span>}
            {...a11yProps(3)}
          />
          <Tab
            sx={styles}
            label={<span>{t("playerLimit.limitsPerPlayer")}</span>}
            {...a11yProps(4)}
          />
        </Tabs>
      </Box>
      <div style={{paddingLeft: '30px'}}>
        <TabPanel value={value} index={0}>
         <BetLimit/>
        </TabPanel>
        <TabPanel value={value} index={1}>
         <DepositLimit/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SessionLimit />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <WithdrawalLimit />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <LimitsPerPlayer />
        </TabPanel>
      </div>
      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={()=> dispatch(setCloseAlert())}
      />
    </div>
  );
};
