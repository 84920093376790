import { FC } from "react";

import { Box, IconButton, MenuItem, TextField } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DOTS, UsePagination } from "../../hooks/pagination/UsePagination";
import {
  getDataPagination,
  setRowsPerPage,
} from "../../redux/actions/helperActions";
import { AppState } from "../../redux/reducers/rootReducer";

interface Props {
  onPageChange: any;
  totalPages: number;
  siblingCount: number;
  currentPage: number;
  totalData?: any;
  component?:
  | "menu"
  | "users"
  | "roles"
  | "refills"
  | "managementCompany"
  | "configCompany"
  | "files"
  | "paymentOrders"
  | "parameterizations"
  | "managementAds"
  | "pointsOfSale"
  | "associates"
  | "credentials"
  | "credentialsPointSale"
  | "commissionalPointSale"
  | "bets"
  | "casino"
  | "playerActivity"
  | "observations"
  | "tickets"
  | "bettingHistory"
  | "managementBonds"
  | "exclusions"
  | "transactions"
  | "bonds"
  | "legal"
  | "messages"
  | "promotions"
  | "configParams"
  | "irrigation";

  param?:
  | "paramPaymentMethods"
  | "paramGames"
  | "paramSolutions"
  | "bonusTable"
  | "percentageWithdrawalTable";
  dataFilter?: any;
}

export const Pagination: FC<Props> = ({
  onPageChange,
  totalPages,
  siblingCount = 1,
  currentPage,
  component = "menu",
  totalData,
  param,
  dataFilter,
}) => {
  const paginationRange: any = UsePagination({
    currentPage,
    totalPages,
    siblingCount,
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let rows = [9, 18, 27];

  const { rowsPerPage } = useSelector((state: AppState) => state.helper);

  const minValue = (currentPage - 1) * rowsPerPage + 1;
  const maxValue =
    currentPage === totalData.last_page
      ? totalData.total
      : currentPage * rowsPerPage;

  // Si hay menos de 2 veces en el rango de paginación, no renderizaremos el componente
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = async () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = async () => {
    onPageChange(currentPage - 1);
  };
  const handleGetDataPage = async (page: number) => {
    onPageChange(page);
    await dispatch(getDataPagination(page, component, param, dataFilter));
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setRowsPerPage(parseInt(event.target.value)));
    onPageChange(1);
  };

  return (
    <div className="d-flex justify-content-center m-0 p-0">
      {totalData.total !== 0 && (
        <Box
          mr={2}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <span>{`${minValue} - ${maxValue} ${t("table.of")} ${totalData.total
            }`}</span>
        </Box>
      )}
      <ul className="ulStyle pagination-container pagination-bar mt-3">
        {/* Flecha de navegación primera página */}
        <IconButton
          disabled={totalData?.page === 1}
          onClick={() => handleGetDataPage(1)}
          size="small"
          sx={{ color: "#000" }}
          style={{ border: "none", outline: "none", fontWeight: 600 }}
        >
          &lt;&lt;
        </IconButton>
        {/* Flecha de navegación izquierda */}
        <IconButton
          onClick={onPrevious}
          sx={{ color: "#000" }}
          style={{ border: "none", outline: "none", fontWeight: 600 }}
          size="small"
          disabled={totalData?.page === 1}
        >
          &lt;
        </IconButton>
        {paginationRange.map((pageNumber: any, index: number) => {
          // Si el elemento de página es un DOT, represente el carácter unicode DOTS
          if (pageNumber === DOTS) {
            return (
              <li
                className="pagination-item dots"
                key={pageNumber + "-" + index}
              >
                &#8230;
              </li>
            );
          }

          // Render numbers para la página
          return (
            <li
              key={pageNumber}
              className={
                totalData?.page === pageNumber
                  ? "pagination-item selected"
                  : "pagination-item"
              }
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        {/* Flecha de navegación derecha  */}
        <IconButton
          onClick={onNext}
          sx={{ color: "#000" }}
          style={{ border: "none", outline: "none", fontWeight: 600 }}
          size="small"
          disabled={totalData?.page === totalData?.last_page}
        >
          &gt;
        </IconButton>
        {/* Flecha de navegación ultima página */}
        <IconButton
          onClick={() => onPageChange(totalData?.last_page)}
          size="small"
          sx={{ color: "#000" }}
          style={{ border: "none", outline: "none", fontWeight: 600 }}
          disabled={totalData?.page === totalData?.last_page}
        >
          &gt;&gt;
        </IconButton>
      </ul>
      <Box
        ml={2}
        width={"30%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <TextField
          name="rowsPerPage"
          size="small"
          select
          InputLabelProps={{ shrink: false }}
          label={" "}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {rows.map((row) => (
            <MenuItem key={row} value={row}>
              {row}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </div>
  );
};
