import { Dispatch, SetStateAction } from "react";
import {
  IDataMassiveTransactionSettings,
  ITransaction,
  ITransactionForm,
} from "./../../interfaces/transactionSettings";
import { AppState } from "./../reducers/rootReducer";
import { ITransactionSettingParams } from "../../interfaces/transactionSettings";
import {
  addMassiveTransactionSetting,
  addTransactionSetting,
  getTransactionSettings,
} from "../../services/transactionSettings";
import { Types } from "../types/types";
import {
  setMessageAlert,
  setTypeAlert,
  setOpenAlert,
  setFlagForm,
  setFlagFormModal,
} from "./helperActions";
import { IPaginationData } from "../../interfaces/shared";
import { IGenericData } from "../../interfaces/params";
import {
  IIrrigationsParams,
  Irrigation,
  IrrigationForm,
} from "../../interfaces/irrigations";
import {
  generateIrrigationAssociateDealer,
  generateIrrigationAssociatePointSale,
  generateIrrigationDealerPointSale,
  generateTransfer,
  getIrrigations,
  getIrrigationsDealer,
  getIrrigationsPV,
} from "../../services/irrigations";
import {
  Roles_Enum,
  StateTransaction,
  TransferConcept,
} from "../../enums/enums";
import { getAssociatesFilterService } from "../../services/associates";
import { setActiveAssociate } from "./associatesActions";
import { arraySearch } from "./searchActions";
import {
  approveAwardPay,
  approveCashWithdrawal,
  getWithdrawals,
  updateWithdrawal,
  uploadVoucher,
} from "../../services/withdrawals";
import {
  IApproveWithdrawalCash,
  IBodyAwardPayment,
  IWithdrawalData,
} from "../../interfaces/withdrawals";
import {
  setPointSaleWallet,
  startGetAssociateWallet,
  startGetDealerWallet,
  startGetPointSaleWallet,
} from "./walletActions";
import { buildFinalQueryParams } from "../../utils/utils";
import { IUpdateWalletPointSale } from "../../interfaces/pointsSale";
import { updateWalletPointSale } from "../../services/pointsSaleService";
import { getUserById } from "../../services/managementUsers";

export const startGetTransactionSetting = (
  params: Partial<ITransactionSettingParams>
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token, user } = (getState() as AppState).auth;
      const { rowsPerPage } = (getState() as AppState).helper;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      if (params.page) {
        params.perPage = rowsPerPage;
      }
      if (user?.operator) {
        params.operatorId = user.operator.id;
      }
      let finalValues: any = {};
      const exeptionsTypes = ["number", "boolean"];
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (exeptionsTypes.includes(typeof value) || value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });

      const rs = await getTransactionSettings(token, finalValues);

      return dispatch(setTransactionSettings(rs));
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};
const setTransactionSettings = (data: IPaginationData<ITransaction>) => ({
  type: Types.setTransactionalSettings,
  payload: data,
});
export const setMovementTypes = (data: IGenericData[]) => ({
  type: Types.setMovementTypes,
  payload: data,
});
export const setSettingTypes = (data: IGenericData[]) => ({
  type: Types.setSettingTypes,
  payload: data,
});
export const setWallets = (data: IGenericData[]) => ({
  type: Types.setWallets,
  payload: data,
});

export const startCreateTransactionSetting = (
  transaction: ITransactionForm,
  deposit?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { user, token } = (getState() as AppState).auth;
      const { pointSale, value } = transaction;
      if (!token || !user || (deposit && !pointSale)) {
        throw new Error("INVALID ACTION");
      }

      const rs = await addTransactionSetting(token, transaction);
      if (rs && !rs.error) {
        if (deposit) {
          dispatch(setMessageAlert("deposits.depositCreated"));
        } else {
          dispatch(setMessageAlert("transactions.settingCreated"));
        }
        if (deposit && pointSale) {
          //subtract point sale balance
          let walletUpdateData: IUpdateWalletPointSale = {
            pointSale,
            amount: value,
            user: user.id,
          };
          const rsWallet = await updateWalletPointSale(token, walletUpdateData);
          if (rsWallet.error) {
            dispatch(setMessageAlert(`deposits.error`));
            dispatch(setTypeAlert("error"));
            return dispatch(setOpenAlert());
          }
          await dispatch(startGetPointSaleWallet(pointSale));
        }
        await dispatch(
          startGetTransactionSetting({ page: 1, isSetting: true })
        );
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
        return dispatch(setFlagForm({ flagFormCreate: false }));
      } else {
        let errorMsg = "errorCreateSetting";
        errorMsg = rs.error.errorMessage;
        if (rs.error === "User not match with email and document number") {
          errorMsg = "userNotMatch";
        }
        if (
          rs.error === "The limit of the value of daily recharges is exceeded"
        ) {
          errorMsg = "limitRechargeExceeded";
        }
        if (rs.error === "player not exits") {
          errorMsg = "userNotFound";
        }
        if (rs.error === "Player has not verified his account") {
          errorMsg = "playerNotVerifiedAccount";
        }

        dispatch(setMessageAlert(`transactions.${errorMsg}`));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const setTotalSettings = (data: any) => ({
  type: Types.setTotalTransactionalSettings,
  payload: data,
});

export const startGetIrrigations = (page: number) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token, user } = (getState() as AppState).auth;
      const { rowsPerPage } = (getState() as AppState).helper;
      if (!token || !user) {
        throw new Error("INVALID ACTION");
      }
      const operatorId = user.operator ? user.operator.id : null;
      const params: Partial<IIrrigationsParams> = {
        perPage: rowsPerPage,
        page,
      };

      const rs = await getIrrigations(token, operatorId, params);

      if (rs && !rs.error) {
        dispatch(arraySearch(null));
        dispatch(setIrrigationsRedux(rs));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorSetIrrigations"));
        dispatch(setTypeAlert("error"));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const setIrrigationsRedux = (
  irrigations: IPaginationData<Irrigation> | null
) => ({
  type: Types.setIrrigations,
  payload: irrigations,
});

export const startFilterIrrigations = (params: any, cleanFilter?: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (params.page || rowsPerPage) {
      params.page = params.page.toString();
      params.perPage = rowsPerPage.toString();
    }

    try {
      if (token) {
        let finalValues: any = {};
        Object.entries(params).forEach(([key, value], index) => {
          //@ts-ignore
          if (value && value.length > 0) {
            finalValues[key] = value;
          }
        });

        const response =
          user && (await getIrrigations(token, user.operator?.id, finalValues));

        if (response && !response.error) {
          if (cleanFilter) {
            dispatch(arraySearch(null));
            dispatch(setIrrigationsRedux(response));
          } else {
            dispatch(arraySearch(response));
          }
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetIrrigations"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const startCreateIrrigation = (
  transfer: IrrigationForm,
  reset: Function,
  setResetInfo: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token, user } = (getState() as AppState).auth;
      const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
      if (!token) {
        throw new Error("INVALID ACTION");
      }

      const rs = await generateTransfer(token, transfer);
      if (rs && !rs.error) {
        await dispatch(startGetIrrigations(1));
        const operatorId = !isSuper && user ? user.operator.id : null;

        const resp =
          token &&
          (await getAssociatesFilterService(token, operatorId, {
            id: transfer.associate,
          }));

        if (resp && resp.data && resp.data.length > 0) {
          await dispatch(setActiveAssociate(resp.data[0]));
        }

        dispatch(setMessageAlert("irrigation.createdSuccess"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
        setResetInfo(true);
        reset();
        dispatch(setActiveAssociate(null));
      } else {
        dispatch(setMessageAlert("irrigation.createdError"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const setTotalIrrigations = (data: IPaginationData<Irrigation>) => ({
  type: Types.setTotalIrrigations,
  payload: data,
});

export const startGetWithdrawals = (params: any, isFilter?: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (params.page) {
      params.page = params.page.toString();
      params.perPage = rowsPerPage.toString();
    }

    try {
      if (token) {
        let finalParams: any = {};
        Object.entries(params).forEach(([key, value], index) => {
          //@ts-ignore
          if (typeof value === "number" || value.length > 0) {
            //@ts-ignore
            finalParams[key] = value;
          }
        });

        const response =
          user && (await getWithdrawals(token, user.operator?.id, finalParams));

        if (response && !response.error) {
          if (isFilter && response.data && response.data.length === 0) {
            dispatch(setMessageAlert("alerts.noData"));
            dispatch(setTypeAlert("error"));
            dispatch(setOpenAlert());
            dispatch(setWithdrawalsRedux(null));
          } else {
            dispatch(setWithdrawalsRedux(response));
          }
        } else {
          dispatch(setMessageAlert("alerts.errorSetWithdrawals"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const setWithdrawalsRedux = (
  withdrawals: IPaginationData<IWithdrawalData> | null
) => ({
  type: Types.setWithdrawals,
  payload: withdrawals,
});

export const setTotalWithdrawals = (
  data: IPaginationData<IWithdrawalData>
) => ({
  type: Types.setTotalWithdrawals,
  payload: data,
});

export const setActiveWithdrawal = (withdrawal: IWithdrawalData | null) => ({
  type: Types.setActiveWithdrawal,
  payload: withdrawal,
});

export const startUpdateWithdrawal = (data: any, isTableAction: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    const { withdrawals } = (getState() as AppState).transactions;

    if (!user || !token) {
      throw new Error("NOT AVAILABLE ACTION");
    }

    // try {
    const response = await updateWithdrawal(data.id, data, token);

    if (response && !response.error) {
      if (isTableAction) {
        const newData = withdrawals
          ? { ...withdrawals }
          : { data: [], total: 0, last_page: 1, page: 1 };

        newData.data = newData.data.filter((el: any) => el.id !== data.id);
        newData.total = newData.total - 1;
        newData.last_page = Math.ceil((newData.total - 1) / rowsPerPage);

        dispatch(setWithdrawalsRedux(newData));
      }
    }
    return response;
    // } catch (error) {
    //   console.log(error);
    // }
  };
};
export const startApproveWithdrawalCash = (params: IApproveWithdrawalCash) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { withdrawals } = (getState() as AppState).transactions;
    if (!user || !token || !withdrawals) {
      throw new Error("NOT AVAILABLE ACTION");
    }
    try {
      const rs = await approveCashWithdrawal(params, token);
      if (rs.status && rs.status !== 200) {
        //alert
        dispatch(setMessageAlert(`withdrawals.${rs.error.errorMessage}`));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }

      await dispatch(
        startGetWithdrawals({
          page: withdrawals.page,

          paymentMethod: TransferConcept.CASH,
          state: StateTransaction.Pending,
        })
      );
      dispatch(
        setFlagFormModal({
          flagFormModal: false,
        })
      );
      dispatch(setActiveWithdrawal(null));
      dispatch(setMessageAlert(`withdrawals.approvalWithdrawalSucces`));
      dispatch(setTypeAlert("success"));
      return dispatch(setOpenAlert());
    } catch (error) {}
  };
};

export const startCreateIrrigationToPointSale = (irrigationData: {
  wallet: number;
  amount: string;
  pointSale: number;
}) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token, user } = (getState() as AppState).auth;

      if (!token) {
        throw new Error("INVALID ACTION");
      }
      const isDealer = user?.roles.name === Roles_Enum.DEALER;
      let data: any = {
        ...irrigationData,
        user: user?.id,
      };
      if (isDealer) {
        const userDetail = await getUserById(token, user.id);
        const { dealer } = userDetail;
        if (dealer) {
          data = { ...data, dealer: dealer.id };
        }
      } else {
        data = { ...data, associate: user?.associatesUsers[0].associate.id };
      }

      const rs = isDealer
        ? await generateIrrigationDealerPointSale(token, data)
        : await generateIrrigationAssociatePointSale(token, data);
      if (rs && !rs.error) {
        isDealer
          ? await dispatch(startGetDealerWallet())
          : await dispatch(startGetAssociateWallet());
        dispatch(setMessageAlert("irrigation.createdSuccess"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());

        dispatch(setPointSaleWallet(null));
      } else {
        dispatch(setMessageAlert("irrigation.createdError"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};
export const startGetIrrigationsPointSale = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (params.page || rowsPerPage) {
      params.page = params.page.toString();
      params.perPage = rowsPerPage.toString();
    }

    try {
      if (token) {
        const finalValues = buildFinalQueryParams(params);

        const response = user && (await getIrrigationsPV(token, finalValues));

        if (response && !response.error) {
          dispatch(arraySearch(response));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetIrrigations"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const startVerifyAwardPay = (params: IBodyAwardPayment) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    if (!user || !token) {
      throw new Error("NOT AVAILABLE ACTION");
    }
    try {
      const rs = await approveAwardPay(params, token);
      let alertMsg = "approvalWithdrawalSucces";
      let alertType: any = "success";
      if (rs.status && rs.status !== 200) {
        alertMsg = `${rs.error.errorServer}`;
        alertType = "error";
      }

      dispatch(setMessageAlert(`withdrawals.${alertMsg}`));
      dispatch(setTypeAlert(alertType));
      return dispatch(setOpenAlert());
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startCreateIrrigationToDealer = (irrigationData: {
  wallet: number;
  amount: string;
  dealer: number;
}) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token, user } = (getState() as AppState).auth;

      if (!token) {
        throw new Error("INVALID ACTION");
      }
      const data = {
        ...irrigationData,
        user: user?.id,
        associate: user?.associatesUsers[0].associate.id,
      };

      const rs = await generateIrrigationAssociateDealer(token, data);
      if (rs && !rs.error) {
        await dispatch(startGetAssociateWallet());
        dispatch(setMessageAlert("irrigation.createdSuccess"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());

        dispatch(setPointSaleWallet(null));
      } else {
        dispatch(setMessageAlert("irrigation.createdError"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startGetIrrigationsDealer = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (params.page || rowsPerPage) {
      params.page = params.page.toString();
      params.perPage = rowsPerPage.toString();
    }

    try {
      if (token) {
        const finalValues = buildFinalQueryParams(params);

        const response =
          user && (await getIrrigationsDealer(token, finalValues));

        if (response && !response.error) {
          dispatch(arraySearch(response));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetIrrigations"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const startUploadVoucher = (
  idWithdrawal: number,
  file: File,
  setOpenAttach: Dispatch<SetStateAction<boolean>>
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { withdrawals } = (getState() as AppState).transactions;
    if (!user || !token) {
      throw new Error("Ivalid action");
    }
    try {
      const response = await uploadVoucher({ id: idWithdrawal, file }, token);

      const newData = withdrawals
        ? { ...withdrawals }
        : { data: [], total: 0, last_page: 1, page: 1 };

      newData.data = newData.data.map((el: any) => {
        if (el.id === idWithdrawal) {
          el.voucher = response?.rs?.voucher;
        }
        return el;
      });

      dispatch(setWithdrawalsRedux(newData));
      setOpenAttach(false);
      if (response.rs) {
        dispatch(setMessageAlert("withdrawals.voucherSuccessUploaded"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
        dispatch(setActiveWithdrawal(null));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startCreateMassiveTransactionSetting = (
  settings: IDataMassiveTransactionSettings
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { user, token } = (getState() as AppState).auth;
      if (!token || !user) {
        throw new Error("INVALID ACTION");
      }

      const rs = await addMassiveTransactionSetting(token, settings);
      if (rs && !rs.error) {
        dispatch(setMessageAlert("transactions.massiveSettingCreated"));
        await dispatch(
          startGetTransactionSetting({ page: 1, isSetting: true })
        );
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
      } else {
        let errorMsg = "errorCreateSetting";
        errorMsg = rs.error.errorMessage;
        dispatch(setMessageAlert(`transactions.${errorMsg}`));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};
