import { SyntheticEvent, useState } from "react";
import { Slides } from "./Slides";
import { Module } from "../../../../../enums/enums";
import { Tab, Tabs } from "@mui/material";
import { TabPanel } from "../../../../../components/Tabs";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const styles = {
  "&.Mui-selected": {
    color: "#55BCD9",
    border: "none",
    outline: "none",
  },
};

export const SlidesDashboard = () => {
  const [value, setValue] = useState<number>(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        style={{ border: "none", outline: "none" }}
        variant="standard"
        TabIndicatorProps={{
          style: {
            backgroundColor: " #02D0E7",
          },
        }}
      >
        <Tab
          sx={styles}
          label={<span className="spanTab">DESKTOP</span>}
          {...a11yProps(0)}
        />
        <Tab
          sx={styles}
          label={<span className="spanTab">MOBILE</span>}
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Slides module={Module.DASHBOARD} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Slides module={Module.MOBILE} />
      </TabPanel>
    </>
  );
};
