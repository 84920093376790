import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  startActiveOrInactiveModule,
  startGetPointSales,
} from "../../../redux/actions/affiliationsActions";
import "./TableComponent.scss";

const TableComponentPointSaleManagement: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pointSales } = useSelector((state: AppState) => state.affiliations);
  const { token, user } = useSelector((state: AppState) => state.auth);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState(10);
  const currentItems = pointSales?.data;
  const totalPages = pointSales?.last_page;

  const handleFirstPage = () => {
    setCurrentPage(1);
    const params = {
      page: 1,
      perPage: itemsPerPage,
      operatorId: user?.operator?.id,
    };
    dispatch(startGetPointSales(params));
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      const params = {
        page: currentPage - 1,
        perPage: itemsPerPage,
        operatorId: user?.operator?.id,
      };
      dispatch(startGetPointSales(params));
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    const params = {
      page: page,
      perPage: itemsPerPage,
      operatorId: user?.operator?.id,
    };
    dispatch(startGetPointSales(params));
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      const params = {
        page: currentPage + 1,
        perPage: itemsPerPage,
        operatorId: user?.operator?.id,
      };
      dispatch(startGetPointSales(params));
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    const params = {
      page: totalPages,
      perPage: itemsPerPage,
      operatorId: user?.operator?.id,
    };
    dispatch(startGetPointSales(params));
  };

  const generatePageNumbers = () => {
    let pages = [];
    if (totalPages <= 7) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else if (currentPage <= 4) {
      pages = [1, 2, 3, 4, 5, "...", totalPages];
    } else if (currentPage > totalPages - 4) {
      pages = [
        1,
        "...",
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    } else {
      pages = [
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        totalPages,
      ];
    }
    return pages;
  };

  useEffect(() => {
    if (token) {
      const params = {
        page: 1,
        perPage: 10,
        operatorId: user?.operator?.id,
      };
      dispatch(startGetPointSales(params));
    }
  }, [dispatch, token, user?.operator?.id]);

  const onSee = (item: any) => {
    dispatch(startActiveOrInactiveModule(item));
  };

  return (
    <div className="table-container">
      {pointSales?.total === 0 ? (
        <div>No se encontraron resultados</div>
      ) : (
        <Fragment>
          <table className="table">
            <thead>
              <tr>
                <th>Nombre del punto de venta</th>
                <th>Dirección</th>
                <th style={{ textAlign: "center" }}>Acciones</th>
              </tr>
            </thead>
            <tbody className="tbody-table">
              {currentItems?.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.address}</td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <Tooltip
                          title={t("button.changeState") as string}
                          arrow
                        >
                          <div>
                            <i
                              className={
                                item?.walletPointSale &&
                                item?.walletPointSale?.players[0]
                                  ?.affiliationCode
                                  ? `fa-regular fa-eye-slash fa-lg mr-2 iconSee`
                                  : `fa fa-eye fa-lg mr-2 iconSee`
                              }
                              onClick={() => onSee(item)}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination">
            <button
              className="navigation"
              onClick={handleFirstPage}
              disabled={currentPage === 1}
            >
              {"<<"}
            </button>
            <button
              className="navigation"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              {"<"}
            </button>
            {generatePageNumbers().map((page, index) =>
              page === "..." ? (
                <span key={index} className="ellipsis">
                  ...
                </span>
              ) : (
                <button
                  key={index}
                  className={`page-number ${
                    page === currentPage ? "active" : ""
                  }`}
                  onClick={() => handlePageChange(page as number)}
                >
                  {page}
                </button>
              )
            )}
            <button
              className="navigation"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              {">"}
            </button>
            <button
              className="navigation"
              onClick={handleLastPage}
              disabled={currentPage === totalPages}
            >
              {">>"}
            </button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default TableComponentPointSaleManagement;
