import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { setActiveMenu } from "../../../redux/actions/managementMenuActions";
import { IColumnsTable } from "../../../interfaces/components";
import { BasicTable } from "../../../components/BasicTable/BasicTable";

export const MenuDetail = () => {
  const dispatch = useDispatch();
  const { activeMenu } = useSelector(
    (state: AppState) => state.managementMenus
  );

  useEffect(() => {
    return () => {
      dispatch(setActiveMenu(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Soporta las Columnas para renderizar en el header de la tabla
   */

  const columns: IColumnsTable[] = [
    { id: 1, header: "table.module" },
    { id: 2, header: "table.url" },
    { id: 3, header: "table.description" },
    { id: 4, header: "table.createdBy" },
    { id: 5, header: "table.state" },
  ];

  return (
    <BasicTable
      component={"menu"}
      data={activeMenu ? activeMenu : []}
      columns={columns}
    />
  );
};
