import { io } from "socket.io-client";

let firstConnectionSuccessful = false;

export const socket = io(process.env.REACT_APP_WEB_SOCKETS as string, {
  path: "/notificationss",
  reconnectionAttempts: 3,
  reconnectionDelay: 5 * 1000,
  transports: ["polling"],
  extraHeaders: {
    api: "e270eea8-61b9-4789-917d-bd5244daa398",
  },
});

// Escucha 'connect'
socket.on("connect", () => {
  firstConnectionSuccessful = true;
});

socket.io.on("reconnect_attempt", () => {
  if (!firstConnectionSuccessful) {
    socket.io.opts.reconnectionAttempts = 0;
  }
});

socket.on("connect_error", (error) => {
  console.error("Error de conexión:", error);
  if (!firstConnectionSuccessful) {
    socket.io.opts.reconnectionAttempts = 0;
  }
});
