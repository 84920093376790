import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DialogLayout } from "../../../components/Custom/DialogLayout";
import { MyCard } from "../../../components/Custom/MyCard";
import { MyCarrousel } from "../../../components/Custom/MyCarrousel";
import { MyCharts } from "../../../components/Custom/MyCharts";
import { MyDialog } from "../../../components/Custom/MyDialog";
import { RankingCardsStatistics } from "../../../components/Statistics/RankingCardsStatistics";
import { uiSetOpenDialog } from "../../../redux/actions/uiAction";
import { AppState } from "../../../redux/reducers/rootReducer";
import { filterPlayers } from "../../../services/managementPlayersService";
import { TakeOver } from "./betsTakeOver/TakeOver";
import { Deposits } from "./deposits/Deposits";
import { Ggr } from "./ggr/Ggr";
import { Payout } from "./payout/Payout";
import { BetsWins } from "./wins/BetsWins";
import { WithDrawals } from "./withdrawals/WithDrawals";
import { IPlayerData } from "../../../interfaces/users";
import { State } from "../../../enums/enums";
import { getWins } from "../../../services/bets";

export const Dashboard = () => {
  const { t } = useTranslation();
  const { openDialog } = useSelector((state: AppState) => state.ui);
  const { user, token } = useSelector((state: AppState) => state.auth);
  const [activeDialog, setActiveDialog] = useState<"Players" | "Transactions">(
    "Players"
  );
  const [startIndexCarrousel, setStartIndexCarrousel] = useState(0);
  const [players, setPlayers] = useState<IPlayerData[] | null>(null);
  const [wins, setWins] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    if (user && token) {
      const {
        roles: { name },
        operator,
      } = user;

      const fetchData = async () => {
        //GET TOTAL PLAYERS IF USER IS SUPERADMIN OTHERWISE FILTER BY OPERATOR
        const rsPlayers = await filterPlayers(token, {}, name, operator?.id);
        if (rsPlayers) {
          setPlayers(rsPlayers.data);
        }

        // GET TOTAL PRIZES WON
        const rs = await getWins(token, user.operator?.id);
        setWins(rs && rs.total ? rs.total : "0");
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gameActivityCard: any[] = [
    {
      background: "#02D0E7",
      title: "Turnover",
      content: "$1.000.000",
    },
    {
      background: "#02D0E7B2",
      title: "Wins",
      content: wins
        ? `$${Intl.NumberFormat("de-DE").format(Number(wins))}`
        : "...",
    },
    {
      background: "#02D0E780",
      title: "GGR",
      content: "$200.000",
    },
    {
      background: "#80E7F34D",
      title: "Payout",
      content: "80%",
    },
  ];
  const transactionsCard: any[] = [
    {
      background: "#036A76",
      title: t("bets.deposits"),
      content: "$1.000.000",
    },
    {
      background: "#036A76CC",
      title: t("bets.withdrawals"),
      content: "$300.000",
    },
    {
      background: "#036A7699",
      title: "Hold",
      content: "$200.000",
    },
    {
      background: "#FFF2E2",
      title: "Wagering",
      content: "10x",
    },
  ];
  const playersCards: any[] = [
    {
      background: "#1EA97CCC",
      title: t("bets.registered"),
      content: players ? players.length.toString() : "...",
    },
    {
      background: "#1EA97C80",
      title: t("bets.actives"),
      content: players
        ? players.filter((player) => player.stateUser === State.ACTIVE).length
        : "...",
    },
    {
      background: "#E4F8F0",
      title: "FTDs",
      content: "5",
    },
  ];
  const fakeListRanking = [
    {
      id: 1,
      name: "Lorem ipsum 25.000",
    },
    {
      id: 2,
      name: "Lorem ipsum 25.000",
    },
    {
      id: 3,
      name: "Lorem ipsum 25.000",
    },
    {
      id: 4,
      name: "Lorem ipsum 25.000",
    },
  ];
  useEffect(() => {
    if (!openDialog) {
      setStartIndexCarrousel(0);
    }
  }, [openDialog]);

  const handleOpenDialogPlayerActivity = (index: number) => {
    setStartIndexCarrousel(index);
    setActiveDialog("Players");
    dispatch(uiSetOpenDialog());
  };
  const handleOpenDialogTransactions = (index: number) => {
    setStartIndexCarrousel(index);
    setActiveDialog("Transactions");
    dispatch(uiSetOpenDialog());
  };
  const handleCloseDialog = () => {
    dispatch(uiSetOpenDialog());
  };
  return (
    <div className="content w-100 mb-5">
      <p
        className="txt-primary-md"
        style={{ position: "relative", top: "-30px" }}
      >
        {t("bets.dashboard")}
      </p>
      <div className="white-container">
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Box className="mb-3">
              <Box
                display="flex"
                className="pb-1 mb-4"
                borderBottom={1}
                borderColor="#02D0E7"
              >
                <i
                  className="fa-solid fa-circle-info"
                  style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
                ></i>
                <p className="txt-primary-md" style={{ fontWeight: "bold" }}>
                  {t("tabs.game")}
                </p>
              </Box>
              <Grid container spacing={2}>
                {gameActivityCard.map((item, index) => (
                  <Grid item xs={6} md={3} lg={3} key={index}>
                    <MyCard
                      onClick={() => handleOpenDialogPlayerActivity(index)}
                      {...item}
                      contentStyleProps={
                        index > 1
                          ? { color: "#333333", marginTop: "30px" }
                          : { marginTop: "30px" }
                      }
                      titleStyleProps={
                        index > 1
                          ? { color: "#333333", marginTop: "-30px" }
                          : { marginTop: "-30px" }
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box className="mb-3">
              <Box
                display="flex"
                className="pb-1 mb-4"
                borderBottom={1}
                borderColor="#02D0E7"
              >
                <i
                  className="fa-solid fa-circle-info"
                  style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
                ></i>
                <p className="txt-primary-md" style={{ fontWeight: "bold" }}>
                  {t("sidebar.transactions")}
                </p>
              </Box>
              <Grid container spacing={2}>
                {transactionsCard.map((item, index) => (
                  <Grid item xs={6} md={3} lg={3} key={index}>
                    <MyCard
                      onClick={() =>
                        index <= 1 ? handleOpenDialogTransactions(index) : {}
                      }
                      {...item}
                      contentStyleProps={
                        index > 1
                          ? { color: "#333333", marginTop: "30px" }
                          : { marginTop: "30px" }
                      }
                      titleStyleProps={
                        index > 1
                          ? { color: "#333333", marginTop: "-30px" }
                          : { marginTop: "-30px" }
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Grid container spacing={2}>
              {[
                `${t("bets.topGames")} - GGR`,
                `${t("bets.topEvents")} - GGR`,
                `${t("bets.topWinners")} - GGR`,
                `${t("bets.topPlayers")} - GGR`,
              ].map((item) => (
                <Grid item xs={12} lg={3} key={item}>
                  <RankingCardsStatistics title={item} list={fakeListRanking} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Box mb={2}>
              <Box
                display="flex"
                className="pb-1 mb-4"
                borderBottom={1}
                borderColor="#02D0E7"
              >
                <i
                  className="fa-solid fa-circle-info"
                  style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
                ></i>
                <p className="txt-primary-md" style={{ fontWeight: "bold" }}>
                  {t("player.players")}
                </p>
              </Box>
              <Grid container spacing={2}>
                {playersCards.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <MyCard
                      //onClick={handleOpenDialog}
                      sm
                      {...item}
                      wrapperStyleProps={{ cursor: "auto" }}
                      contentStyleProps={
                        index > 1 ? { color: "#333333" } : undefined
                      }
                      titleStyleProps={
                        index > 1 ? { color: "#333333" } : undefined
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <MyCharts
              title={t("bets.depositsByPayment")}
              series={[
                {
                  colorByPoint: true,
                  innerSize: "75%",
                  data: [
                    {
                      name: "PSE",
                      y: 28500,
                      color: "#FF5757",
                    },
                    {
                      name: "Addi",
                      y: 19845,
                      color: "#036A76",
                    },
                    {
                      name: "Lorem",
                      y: 35248,
                      color: "#02D0E7",
                    },
                  ],
                },
              ]}
              getContext={(context) => {
                const series: any = context.series[0],
                  seriesCenter = series.center,
                  x = seriesCenter[0] + context.plotLeft,
                  y = seriesCenter[1] + context.plotTop,
                  text = "+ " + series.total,
                  fontMetrics = context.renderer.fontMetrics(16);

                if (!context.customTitle) {
                  context.customTitle = context.renderer
                    .text(text, null, null, true)
                    .css({
                      transform: "translate(-50%)",
                      fontSize: "1.92vh",
                      fontWeight: "bold",
                    })
                    .add();
                }

                context.customTitle.attr({
                  x,
                  y: y + fontMetrics.f / 2,
                });
              }}
              options={{
                legend: {
                  align: "center",
                  layout: "vertical",
                  verticalAlign: "bottom",
                  itemMarginBottom: 10,
                  symbolHeight: 20,
                  symbolWidth: 20,
                  symbolRadius: 5,
                  labelFormatter: function () {
                    return (
                      (this as any).name +
                      " - " +
                      (this as any).y
                        .toLocaleString("en-US")
                        .split(",")
                        .join(".")
                    );
                  },
                  margin: 40,
                },
                title: {
                  style: {
                    fontWeight: "bold",
                    fontSize: "14px",
                  },
                  text: t("bets.depositsByPayment"),
                },
              }}
              variant={{ type: "pie" }}
              customChartStyles={{
                backgroundColor: "#D5D5D533",
                //margin: [0, 110, 110, 110],
                borderRadius: 15,
                spacingBottom: 50,
              }}
            />
          </Grid>
        </Grid>
      </div>
      {activeDialog === "Players" ? (
        <MyDialog
          //title=""
          openModal={openDialog}
          onClose={handleCloseDialog}
          content={
            <MyCarrousel
              items={[
                {
                  id: 1,
                  component: (
                    <DialogLayout>
                      <TakeOver />
                    </DialogLayout>
                  ),
                  title: t("bets.takeOver"),
                },
                {
                  id: 2,
                  component: (
                    <DialogLayout>
                      <BetsWins />
                    </DialogLayout>
                  ),
                  title: t("bets.awards"),
                },
                {
                  id: 3,
                  component: (
                    <DialogLayout>
                      <Ggr />
                    </DialogLayout>
                  ),
                  title: "GGR",
                },
                {
                  id: 4,
                  component: (
                    <DialogLayout>
                      <Payout />
                    </DialogLayout>
                  ),
                  //title: t("bets.payout"),
                  title: "Payout",
                },
              ]}
              startIndex={startIndexCarrousel}
            />
          }
        />
      ) : (
        <MyDialog
          //title=""
          openModal={openDialog}
          onClose={handleCloseDialog}
          content={
            <MyCarrousel
              items={[
                {
                  id: 5,
                  component: (
                    <DialogLayout>
                      <Deposits />
                    </DialogLayout>
                  ),
                  title: t("bets.deposits"),
                },
                {
                  id: 6,
                  component: (
                    <DialogLayout>
                      <WithDrawals />
                    </DialogLayout>
                  ),
                  title: t("bets.withdrawals"),
                },
              ]}
              startIndex={startIndexCarrousel}
            />
          }
        />
      )}
    </div>
  );
};
