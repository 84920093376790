import React, { FC } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { Box, Grid } from "@mui/material";
interface props {
  id: number | null;
}
export const TabPromoterInfo: FC<props> = ({ id }) => {
  const { promoters } = useSelector(
    (state: AppState) => state.managementPromoters
  );
  const getPromoterDetail = (id: number | null) => {
    if (id) {
      const promoter =
        promoters && promoters.data.find((promoter) => promoter.id === id);
      return promoter ? promoter.name : "";
    }
    return "";
  };
  return (
    <Grid container spacing={2}>
      <Grid container>
        <Grid item xs={3}>
          <Box className="no-overflow">{getPromoterDetail(id)}</Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
