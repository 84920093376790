import moment from "moment";
import { useSelector } from "react-redux";
import { headersBonusRedeemed } from "../../../helpers/headersTables";
import { AppState } from "../../../redux/reducers/rootReducer";
import { getCopyArrayOrObject } from "../../../utils/utils";
import { IBonusRedeemed } from "../../../interfaces/bonus";
import {
  bonusCampaignType,
  bonusRedeemedStatus,
} from "../../../helpers/bonusType";

export const BondsDataTable = () => {
  const { bonusRedeemed, totalBonusRedeemed } = useSelector(
    (state: AppState) => state.managementPlayers
  );

  const copyBonusRedeemed = getCopyArrayOrObject(bonusRedeemed);
  const copyTotalBonusRedeemed = getCopyArrayOrObject(totalBonusRedeemed);
  const headersCSVBonusRedeemed = headersBonusRedeemed;

  //   COLUMNS HEADERS STYLES
  const headersCvsStyle = headersCSVBonusRedeemed.map((header) => {
    return {
      title: header.label,
      width: { wpx: 150 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });
  // Obtener datos por vista
  const dataBonusRedeemed = copyBonusRedeemed ? copyBonusRedeemed.data : [];

  // Obtener el total de datos
  const dataTotalBonusRedeemed = copyTotalBonusRedeemed
    ? copyTotalBonusRedeemed.data
    : [];

  // DATA POR VISTA
  const multiDataSetBonusRedeemed = [
    {
      columns: headersCvsStyle,
      data:
        dataBonusRedeemed &&
        dataBonusRedeemed.map((data: IBonusRedeemed) => {
          let array;
          array = [
            {
              value: data.bonus.altenarBonusType
                ? bonusCampaignType.find(
                    (type) => type.value === data.bonus.altenarBonusType
                  )?.label
                : "Pragmatic",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.bonus.name,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "$ " + data.bonus.value,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: moment(data.createAt).format("YYYY-MM-DD HH:mm:ss"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: moment(data.bonus.endDate).format("YYYY-MM-DD HH:mm:ss"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: bonusRedeemedStatus
                .find((type) => type.value === data.state)
                ?.label.toUpperCase(),
              style: { alignment: { horizontal: "center" } },
            },
          ];
          return array;
        }),
    },
  ];

  //   DATA TOTAL
  const multiTotalDataSetBonusRedeemed = [
    {
      columns: headersCvsStyle,
      data:
        dataTotalBonusRedeemed &&
        dataTotalBonusRedeemed.map((data: any) => {
          let array;
          array = [
            {
              value: data.bonus.altenarBonusType
                ? bonusCampaignType.find(
                    (type) => type.value === data.bonus.altenarBonusType
                  )?.label
                : "Pragmatic",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.bonus.name,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "$ " + data.bonus.value,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: moment(data.createAt).format("YYYY-MM-DD HH:mm:ss"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: moment(data.bonus.endDate).format("YYYY-MM-DD HH:mm:ss"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: bonusRedeemedStatus
                .find((type) => type.value === data.state)
                ?.label.toUpperCase(),
              style: { alignment: { horizontal: "center" } },
            },
          ];
          return array;
        }),
    },
  ];

  return {
    dataBonusRedeemed,
    dataTotalBonusRedeemed,
    headersCSVBonusRedeemed,
    multiDataSetBonusRedeemed,
    multiTotalDataSetBonusRedeemed,
  };
};
