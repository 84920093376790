export enum Types {
  login = "[Auth] Login",
  logout = "[Auth] Logout",
  reset = "[Auth] reset storage",
  roles = "[helper] Set roles from data base",
  countries = "[helper] Set countries from data base",
  states = "[helper] Set states from data base",
  cities = "[helper] Set cities from data base",
  setCurrencies = "[helper] Set currencies from data base",
  setLanguages = "[helper] Set languages from data base",
  setPaymentMethods = "[helper] Set payment methods from data base",
  setPaymentMethodsByCountry = "[helper] Set payment methods by country from data base",
  setGames = "[helper] Set games from data base",
  setSolutions = "[helper] Set solutions from data base",
  openAlert = "[helper] Open alert",
  closeAlert = "[helper] Close alert",
  setMessageAlert = "[helper] Set message alert",
  setTypeAlert = "[helper] Set type alert",
  setActiveStep = "[helper] Set active step",
  setRowsPerPage = "[helper] Set rows per page",
  setComponent = "[helper] Set component",
  setDocumentTypes = "[helper] Set document types",
  setBanks = "[helper] Set banks",
  setOpenTimeout = "[helper] Set open timeout",
  setChangeTab = "[helper] Set change tab",

  // UI
  setError = "[UI] Set error",
  setErrorMessage = "[UI] Set error message",
  loadAvailableRoutes = "[Routes] load available routes",
  setOpenNotifications = "[UI] Set open notifications",
  closeNotifications = "[UI] close notifications",
  setOpenDialog = "[UI] Set open dialog",
  setDialogMessage = "[UI] Set dialog message",
  setNetworkState = "[UI] Set network state",

  // HELPERS
  setFlagFormCreate = "[Form] set boolean to open creation form",
  setFlagFormCreateAssociate = "[Form] set boolean to open creation associate form",
  setFlagFormEdit = "[Form] set boolean to open creation form edit",
  setFlagFormConfig = "[Form] set boolean to open config form",
  setFlagFormModal = "[Form] set boolean to open modal form",
  setLoadingState = "[Form] set boolean to state loading",
  setFlagSelect = "[Form] set boolean to update selects",
  setFlagCleanFilter = "[Form] set boolean to clean filter",

  // USERS
  setUsers = "[ManagementUsers] Set users from data base",
  addUser = "[ManagementUsers] add user redux",
  updateUser = "[ManagementUsers] update user redux",
  setActiveUser = "[ManagementUsers] set active user",
  setTotalUsers = "[ManagementUsers] Set total users from data base",

  // MENUS
  setMenus = "[ManagementMenus] Set menus from data base",
  setTotalMenus = "[ManagementMenus] Set total menus from data base",
  addMenu = "[ManagementMenus] add menu redux",
  updateMenu = "[ManagementMenus] update menu redux",
  deleteMenu = "[ManagementMenus] delete menu",
  deleteModule = "[ManagementMenus] delete module",
  setActiveMenu = "[ManagementMenus] set active menu",
  setActiveModule = "[ManagementMenus] set active module",

  //SEARCH
  arraySearch = "[search] Array search",
  valueSearched = "[search] Value searched",
  changeData = "[search] Change data in redux manually",

  //MANAGEMENT PLAYERS
  setPlayer = "[ManagementPlayers] Set player from data base to redux",
  setRefills = "[ManagementPlayers] Set refills from data base to redux",
  setTotalRefills = "[ManagementPlayers] Set total refills from data base to redux",
  updatePlayer = "[ManagementPlayers] Update player redux",
  setExclusions = "[ManagementPlayers] Set exclusions from data base to redux",
  addExclusion = "[ManagementPlayers] add exclusion redux",
  updateExclusion = "[ManagementPlayers] update exclusion",
  setExclusionCategories = "[ManagementPlayers] Set exclusion categories from data base to redux",
  setObservations = "[ManagementPlayers] Set player observations",
  setActiveObservation = "[ManagementPlayers] Set active player observation",
  setBonusRedeemedByPlayer = "[ManagementPlayers] Set bonus redeemed by player",
  setTotalBonusRedeemedByPlayer = "[ManagementPlayers] Set total bonus redeemed by player",
  setPlayerUpdateHistory = "[ManagementPlayers] Set player update history",

  //MANAGEMENT ROLES
  setActiveRol = "[ManagementRoles] set active rol",
  deleteRol = "[ManagementRoles] delete rol by id",

  //MANAGEMENT COMPANIES
  setCompanies = "[ManagementCompanies] Set companies from data base",
  addCompany = "[ManagementCompanies] add company redux",
  updateCompany = "[ManagementCompanies] update company redux",
  setActiveCompany = "[ManagementCompanies] set active company",
  setActiveBrand = "[ManagementCompanies] set active brand",
  setBrands = "[ManagementCompanies] Set brands from data base",
  setTotalBrands = "[ManagementCompanies] Set total brands from data base",
  setTotalCompanies = "[ManagementCompanies] Set total companies from data base",

  // MANAGEMENT PARAMETERS
  setActiveParamSolutions = "[ManagementParameters] set active param solutions",
  setActiveParamPayments = "[ManagementParameters] set active param payments",
  setActiveParamGames = "[ManagementParameters] set active param games",
  setOperators = "[Operators] get all operators",

  // POINTS OF SALE
  setPointsSale = "[PointsSale] Set points of sale from data base",
  addPointSale = "[PointsSale] add point of sale redux",
  updatePointSale = "[PointsSale] update point of sale redux",
  setActivePointSale = "[PointsSale] set active point of sale",
  setSellers = "[PointSale] set sellers",

  // ASSOCIATES
  setAssociates = "[Associates] Set associates from data base",
  addAssociate = "[Associates] add associate to redux",
  updateAssociate = "[Associates] update associate redux",
  setActiveAssociate = "[Associates] set active associates",

  // ADS
  getAdsOperator = "[Ads] get ads by operator",
  setAds = "[Ads] set Ads",
  setActiveAd = "[Ads] set active ad",
  deleteAd = "[Ads] delete ad by id",

  //BETS
  setBets = "[Bets] set bets",
  setTotalBets = "[Bets] set total bets",
  setActiveBet = "[Bets] set active bet",

  //BONUS
  setBonus = "[Bonus] set all bonus",
  setBonustypes = "[Bonus] set all bonus types",
  setActiveBonus = "[Bonus] set active bonus",
  setOpenBonusDetails = "[Bonus] set open bonus details",
  setActiveBonusType = "[Bonus] set active bonus type",
  setBonusGroup = "[Bonus] set bonus group",
  setActiveBonusGroup = "[Bonus] set active bonus group",
  setBonusPariplay = "[Bonus] set all bonus pariplay",
  setFreeTicketsBonus = "[Bonus] set all free tickets bonus",

  // LEGAL
  setLegal = "[Legal] set all legal parameters",
  setActiveLegal = "[Legal] set active legal",
  setTotalLegal = "[Legal] Set total legal parameters from data base",

  //TRANSACTIONS
  setTransactionalSettings = "[Transactions] set transactional settings",
  setMovementTypes = "[Transactions] set movement types",
  setSettingTypes = "[Transactions] set setting types",
  setWallets = "[Transactions] set wallets",
  setTotalTransactionalSettings = "[Transactions] set total transactional settings",
  setIrrigations = "[Transactions] set irrigations",
  setTotalIrrigations = "[Transactions] set total irrigations",
  setWithdrawals = "[Transactions] set withdrawals",
  setTotalWithdrawals = "[Transactions] set total withdrawals",
  setActiveWithdrawal = "[Transactions] set active withdrawal",

  //CONFIG PARAMS
  setConfigParams = "[ConfigParams] set config params",
  setActiveConfigParam = "[ConfigParams] set active config param",

  //CURRENCIES
  setCurrenciesOperator = "[Currencies] set currencies operator",
  //CONTENT MANAGEMENT
  setUpContainerColors = "[content Management] set container up color",
  setLogoOperator = "[content Management] set logo operator",
  setAlternativeLogoOperator = "[content Management] set alternative logo operator",
  setFooter = "[content Management] set footer",
  setButtonsColors = "[content Management] set buttons colors",
  // SLIDES
  setSlides = "[Ads] set slides",
  // PROMOTIONS ADS
  setPromotionsAds = "[Ads] set promotions ads",

  //NOTIFICATIONS
  setNotifications = "[Notifications] set notifications last 30 days",
  addNotifications = "[Notifications] add notification to redux",
  updateNotification = "[Notifications] old update notification redux",
  deleteNotification = "[Notifications] old delete notification redux",
  markAsReadNotification = "[Notifications] new update notification redux",
  softDeleteNotification = "[Notifications] new delete notification redux",

  //CARDS
  setLots = "[Cards] set lots",
  setActiveLot = "[Cards] set active lot",
  setCards = "[Cards] set cards",

  //DEALERS
  setDealers = "[Dealers] set dealers",
  setActiveDealer = "[Dealers] set active dealer",
  setDealersPointSale = "[Dealers] set dealers point sale",

  //CARD DEALERS
  setCardDealers = "[Cards] set card dealers",
  setActiveCardDealer = "[Cards] set active dealer",

  //IRRIGATION CARDS
  setIrrigationCardsInventory = "[Cards] set irrigation cards inventory",
  setIrrigationCardsHistory = "[Cards] set irrigation cards history",
  setIrrigatedCards = "[CARDS] set irrigated cards resume",

  //TOTAL WALLET
  setTotalWallet = "[Cards] set total wallet",

  //MESSAGES
  setMessages = "[MSJ] set messages",
  setActiveMessage = "[MSJ] set active message",
  //Wallet
  setWalletPointSale = "[Wallet] set wallet point sale",
  setWalletAssociate = "[Wallet] set wallet associate",
  setWalletDealer = "[Wallet] set wallet dealer",

  // PAY METHODS
  setPayMethods = "[PAYM] set pay methods",

  //PROMOTIONS
  setPromotions = "[MSJ] set promotions",
  setActivePromotion = "[MSJ] set active promotion",

  // PAYS
  setPays = "[P] set pay recods",

  //DEALERS
  setPromoters = "[Promoters] set promoters",
  setActivePromoter = "[Promoters] set active promoter",

  // Commercial Networks
  setCommercialNetworks = "[CommercialNetworks] set commercial networks",
  setConciliations = "[CommercialNetworks] set conciliations",

  //CASINO
  setCasinoCategory = "[casino] Set casino category",
  setActiveCategory = "[casino] Set active casino category",
  setSelectedCasinoCategory = "[casino] Set selected casino category",
  setCasinoGames = "[casino] set casino games",
  //AFFILIATES
  setAffiliates = "[affiliates] set affiliates",
  //SEGMENTATION
  setSegmentations = "[segmentations] set segmentations",
  //PROMOTIONS POPUPS
  setPromotionsPopups = "[promotions popups] set promotions popups",
  setActivePromotionPopup = "[promotions popups] set active promotion popup",
  //IPBLACKLIST
  setBlacklist = "[ip blacklist] set promotions popups",
  setActiveIp = "[ip blacklist] set active promotion popup",
  //DEPOSIT QR
  setDepositQR = "set deposits qr",
  setActiveDepositQR = "set active deposit qr",
}
