import React, { FC } from "react";
import { headersIrrigationPv } from "../../../helpers/headersTables";
import { Text, View } from "@react-pdf/renderer";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import moment from "moment";
import { BalanceType } from "../../../enums/enums";
import { useTranslation } from "react-i18next";
interface props {
  page: any[];
}
export const IrrigationWalletPdf: FC<props> = ({ page }) => {
  const columns = headersIrrigationPv;
  const { t } = useTranslation();
  const getWalletName = (wallet: number) => {
    return t(
      wallet === BalanceType.REFILL
        ? "associates.refillBalance"
        : "irrigation.bettingBalance"
    ).toUpperCase();
  };
  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, index) => (
          <View key={index} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>

      <View style={{ flex: 1 }}>
        {page.map((data) => (
          <View key={data.id} style={stylesPdf.row}>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.id}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {data.transactionType.name}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {getWalletName(data.meta.wallet)}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.amount}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.newBalance}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {data.createBy.nameComplete}
              </Text>
            </View>

            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {moment(data.createAt).format("DD/MM/YYYY HH:mm:ss")}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};
