import { Form, Formik } from "formik";
import moment from "moment";
import { KeyboardEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Grid,
  TextField,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  Autocomplete,
  Divider,
  Button,
  InputAdornment,
  IconButton,
  FormHelperText,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import {
  setCities,
  setCountries,
  setCountriesRedux,
  setDocumentTypesRedux,
  setFlagForm,
  setFlagFormEdit,
  setMessageAlert,
  setOpenAlert,
  setStates,
  setTypeAlert,
  setCloseAlert,
  setComponent,
  setDocumentTypes,
} from "../../../redux/actions/helperActions";
import { IUserData, IRoles } from "../../../redux/interfaces/users";
import {
  createUser,
  editUser,
  setActiveUser,
} from "../../../redux/actions/managementUsersActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { MyTextField } from "../custom/MyTextField";
import {
  ICities,
  ICountries,
  IDocumentTypes,
  IStates,
} from "../../../redux/interfaces/shared";

import "./formCreateUser.scss";
import { IHelper } from "../../../redux/interfaces/helper";
import { MyAlert } from "../custom/MyAlert";
import { MyDialog } from "../custom/MyDialog";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../custom/customStyles";
import { filterUsers } from "../../../services/managementUsers";

import clearLogo from "../../../assets/svg/icon-park_clear-format.svg";
import { DocumentTypes, Roles_Enum, State } from "../../../enums/enums";
import { ICompanyData } from "../../../interfaces/operationCompany";
import { IGenericData } from "../../../interfaces/params";
import { useHistory } from "react-router-dom";

import {
  regAddress,
  regEmail,
  regLetters,
  regNumbers,
  regPassport,
  regUsername,
  validatePhoneByCountry,
} from "../../../utils/utils";
import { arraySearch } from "../../../redux/actions/searchActions";
import { getAssociateById } from "../../../services/associates";
import { DEFAULT_ROLES } from "../../../shared/const";
import { IDealer } from "../../../interfaces/cards";
import { getDealers } from "../../../services/dealers";
const operatorsShowLinkDealer = [2, 3];
const FormCreateUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [resetFormFlag, setResetFormFlag] = useState(false);
  const [resetFunction, setResetFunction] = useState<Function>();

  const state = [
    { label: t("state.active"), value: State.ACTIVE },
    { label: t("state.inactive"), value: State.INACTIVE },
    { label: t("state.retired"), value: State.RETIRED },
    { label: t("state.restricted"), value: State.RESTRICTED },
  ];
  /**
   * Soporta el estado boolean para cerrar el formulario de creación de usuarios
   */
  const setFlagFormHelper: IHelper = {
    flagFormCreate: false,
  };

  /**
   * Soporta el estado boolean para indicar si estamos o no en edición
   */
  const { openAlert, messageAlert, typeAlert, documentTypes } = useSelector(
    (state: AppState) => state.helper
  );
  /**
   * Soporta el usuario, y el usuario activo
   */
  const { activeUser } = useSelector(
    (state: AppState) => state.managementUsers
  );

  /**
   * Soporta el token para enviar con la funcion de crear o eliminar
   */
  const { token, user } = useSelector((state: AppState) => state.auth);

  /**
   * Soporta los roles, paises, estados y ciudades para mostrar en los respectivos selects
   */
  const { countries, states, cities, component } = useSelector(
    (state: AppState) => state.helper
  );
  const { roles } = useSelector((state: AppState) => state.managementRoles);
  const { activeAssociate } = useSelector(
    (state: AppState) => state.associates
  );
  const { companies: operators } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const companies = operators?.data.filter(
    (operator) => operator.stateOperator === State.ACTIVE
  );

  const [cityData, setCity] = useState<ICities | null>(
    activeUser?.city ? activeUser.city : null
  );

  const [flagStates, setFlagStates] = useState(true);
  const [flagCity, setFlagCity] = useState(true);

  /**
   * Soporta los estados en los selects para mostrar y filtrar
   */
  const [selectedCountry, setSelectedCountry] = useState<ICountries | null>(
    activeUser?.country ? activeUser.country : null
  );
  const [selectedStates, setSelectedStates] = useState<IStates | null>(
    activeUser?.state ? activeUser.state : null
  );
  const [errorCountry, setErrorCountry] = useState(false);
  const [errorStates, setErrorStates] = useState(false);
  const [errorCity, setErrorCity] = useState(false);
  const [rolesSelected, setRolesSelected] = useState<IRoles | null>(
    activeUser ? activeUser.roles : null
  );
  const [errorRoles, setErrorRoles] = useState(false);
  const [errorOperator, setErrorOperator] = useState(false);
  const [totalData, setTotalData] = useState<IUserData>();
  const [cancel, setCancel] = useState(false);
  const [fieldsValid, setFieldsValid] = useState(false);
  const [operatorSelected, setOperatorSelected] = useState<IGenericData | null>(
    user?.operator
      ? { id: user.operator.id, name: user.operator.name }
      : activeUser && activeUser.operator
      ? activeUser.operator
      : component === "associates" && activeAssociate
      ? activeAssociate.operator
      : null
  );
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;

  /**
   * Soporta los errores para valores duplicados
   */
  const [numberDocumentError, setNumberDocumentError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loadingValidate, setLoadingValidate] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [checkLinkDealer, setCheckLinkDealer] = useState(
    activeUser ? (activeUser.userDealer.length > 0 ? true : false) : false
  );

  const [dealers, setDealers] = useState<IDealer[]>([]);
  const [dealerSelected, setdealerSelected] = useState<any>(
    activeUser
      ? activeUser.userDealer.length > 0
        ? activeUser.userDealer[0].dealer.id
        : null
      : null
  );

  const handleSelectCountry = async (value: ICountries | null) => {
    setSelectedStates(null);
    setCity(null);
    !value ? setErrorCountry(true) : setErrorCountry(false);
    value && setSelectedCountry(value);
  };

  const handleSelectStates = async (value: IStates | null) => {
    setCity(null);
    !value ? setErrorStates(true) : setErrorStates(false);
    value && setSelectedStates(value);
  };

  const handleSelectCities = async (value: ICities | null) => {
    !value ? setErrorCity(true) : setErrorCity(false);
    value && setCity(value);
  };

  useEffect(() => {
    dispatch(setCloseAlert());
    token && dispatch(setCountries(token));
    token && dispatch(setDocumentTypes(token));
    if (activeUser && roles && rolesSelected) {
      const isRolFromSuper = roles.data.find((item) =>
        item.rol
          ? item.rol.id === rolesSelected?.id
          : item.id === rolesSelected.id
      );
      if (!isRolFromSuper) {
        roles.data = [...roles.data, rolesSelected];
      }
    }
    return () => {
      dispatch(setCountriesRedux([]));
      dispatch(setDocumentTypesRedux([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //FILTRAR LOS SELECTS
    if (selectedCountry) {
      getStates(selectedCountry.id);
      //@ts-ignore
      if (!activeUser) {
        setSelectedStates(null);
      }
      setFlagStates(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  useEffect(() => {
    //FILTRAR LOS SELECTS
    if (selectedStates) {
      getCities(selectedStates.id);
      if (!activeUser) {
        setCity(null);
      }
      setFlagCity(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStates]);
  /**
   * DATOS INICIALES FORMULARIO
   */
  let defaultValues: any = {
    id: activeUser?.id,
    nameComplete: activeUser?.nameComplete ? activeUser.nameComplete : "",
    typeDocument: activeUser ? activeUser.typeDocument.id : "",
    numberDocument: activeUser?.numberDocument
      ? activeUser?.typeDocument.id === DocumentTypes.DUI
        ? activeUser.numberDocument.slice(0, 8) +
          "-" +
          activeUser.numberDocument.slice(8)
        : activeUser.numberDocument
      : "",
    expeditionDate: activeUser?.expeditionDate
      ? moment(activeUser?.expeditionDate).utc().format("YYYY-MM-DD")
      : "",
    phone: activeUser?.phone ? activeUser.phone : "",
    address: activeUser?.address ? activeUser.address : "",
    country: { id: 0, name: "", imgUrl: "", code: "" },
    city: { id: 0, names: "" },
    username: activeUser?.username ? activeUser.username : "",
    email: activeUser?.email ? activeUser.email : "",
    password: "",
    verificationTwoStep: activeUser?.verificationTwoStep
      ? activeUser.verificationTwoStep
      : "",
    observation: activeUser?.observation ? activeUser.observation : "",
    state: activeUser?.state ? activeUser.state : { id: 0, names: "" },
    stateUser: activeUser?.stateUser ? activeUser.stateUser : 1,
    roles: { id: 54, name: "admin_manager" },
    createAt: activeUser?.createAt && activeUser.createAt,
    updateAt: activeUser?.updateAt && activeUser.updateAt,
    createdUser: activeUser?.createdUser && activeUser.createdUser,
    operator: activeUser?.operator && activeUser.operator,
  };

  const validationSchema = yup.object({
    nameComplete: yup.string().required(t("form.requiredFile")),
    typeDocument: yup.number().required(t("form.requiredFile")),
    numberDocument: yup
      .string()
      .required(t("form.requiredFile"))
      .when("typeDocument", {
        is: (value: number) =>
          [DocumentTypes.CITIZENSHIP_CARD, DocumentTypes.PASSPORT].includes(
            value
          ),
        then: yup
          .string()
          .min(7, t("form.invalidLengthDocumentNumber", { min: 7, max: 10 }))
          .max(10, t("form.invalidLengthDocumentNumber", { min: 7, max: 10 })),
      })
      .when("typeDocument", {
        is: DocumentTypes.CIP,
        then: yup
          .string()
          .min(7, t("form.invalidLengthDocumentNumber", { min: 7, max: 13 }))
          .max(13, t("form.invalidLengthDocumentNumber", { min: 7, max: 13 })),
      })
      .when("typeDocument", {
        is: DocumentTypes.DNI,
        then: yup
          .string()
          .min(8, t("form.invalidLengthDocumentNumber", { min: 8, max: 13 }))
          .max(13, t("form.invalidLengthDocumentNumber", { min: 8, max: 13 })),
      })
      .when("typeDocument", {
        is: (value: number) =>
          [DocumentTypes.DUI, DocumentTypes.CE].includes(value),
        then: yup
          .string()
          .min(5, t("form.invalidLengthDocumentNumber", { min: 5, max: 15 }))
          .max(15, t("form.invalidLengthDocumentNumber", { min: 5, max: 15 })),
      })
      .when("typeDocument", {
        is: DocumentTypes.DPI,
        then: yup
          .string()
          .min(13, t("form.invalidLengthDocument", { value: 13 }))
          .max(13, t("form.invalidLengthDocument", { value: 13 })),
      }),
    expeditionDate: yup.date().required(t("form.requiredFile")),

    phone: yup
      .number()
      .positive(t("user.errorNegativeNumber"))
      .required(t("form.requiredFile"))

      .test(
        "len",
        t("user.invalidPhone", {
          length: 10,
        }),
        function (value) {
          if (value) {
            const country: any = this.resolve(yup.ref("country"));

            const { valid, length, startOf } = validatePhoneByCountry(
              country.code,
              value?.toString()
            ); // Validar el número de teléfono

            if (!valid) {
              const params: any = {
                length,
                rest: startOf
                  ? t("user.startOfNumber", {
                      numbers: startOf.toString(),
                    })
                  : "",
              };

              return this.createError({
                message: t("user.invalidPhone", params),
              });
            }
          }
          return true;
        }
      ),

    address: yup.string().required(t("form.requiredFile")),
    city: yup.object(),
    country: yup.object(),
    state: yup.object(),
    username: yup.string().required(t("form.requiredFile")),
    roles: yup.object(),
    operator: yup.object(),
    email: yup
      .string()
      .email(t("user.invalidEmail"))
      .required(t("form.requiredFile")),
    password: activeUser
      ? yup.string()
      : yup
          .string()
          .required(t("form.requiredFile"))
          .matches(
            //eslint-disable-next-line
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*()_+\-=;':"\\|,.?~])/,
            t("user.rexPassword")
          ),
    confirmPassword: activeUser
      ? yup.string()
      : yup
          .string()
          .required(t("form.requiredFile"))
          .oneOf([yup.ref("password"), null], () => (
            <>
              <i className="tim-icons icon-alert-circle-exc" />
              {t("user.matchingPasswords")}
            </>
          )),
    stateUser: yup.number().required(t("form.requiredFile")),
    observation: yup.string(),
    verificationTwoStep: yup.number().required(" "),
  });

  /**
   * Soporta el evento click del boton para cancelar o volver a la info del usuario
   */
  const handleCancel = () => {
    setMessage("dialog.sureGoBackUser");
    setCancel(true);
    setOpenDialog(true);
  };

  const handleCleanForm = () => {
    setMessage("dialog.sureClean");
    setResetFormFlag(true);
    setOpenDialog(true);
  };

  /**
   * Soporta el evento click del boton para traer los estados del país
   */
  const getStates = (id: number) => {
    if (token) {
      dispatch(setStates(token, id));
    }
  };
  /**
   * Soporta el evento click del boton para traer las ciudades del estado
   */
  const getCities = (id: number) => {
    if (token) {
      dispatch(setCities(token, id));
    }
  };

  useEffect(() => {
    if (operatorSelected) {
      const getAllDealers = async () => {
        if (!user || !token) {
          throw new Error("invalid action");
        }

        const rs = await getDealers(
          {
            operator: operatorSelected.id,
          },
          token
        );
        if (rs && !rs.error) {
          setDealers(rs.data);
        }
      };

      getAllDealers();
    }
    !activeUser && setdealerSelected(null);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatorSelected]);

  useEffect(() => {
    if (activeUser) {
      defaultValues.stateUser = activeUser.stateUser;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUser]);

  const validateFields = async (data: IUserData) => {
    rolesSelected?.name === "" || !rolesSelected
      ? setErrorRoles(true)
      : setErrorRoles(false);

    !selectedCountry ? setErrorCountry(true) : setErrorCountry(false);

    !selectedStates ? setErrorStates(true) : setErrorStates(false);

    !cityData ? setErrorCity(true) : setErrorCity(false);

    (!operatorSelected || operatorSelected.name === "") && isSuper
      ? setErrorOperator(true)
      : setErrorOperator(false);

    //MOSTRAR ALERTA DE ERROR
    const isValid = await validationSchema.isValid(data);
    if (!isValid) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.completeRequiredFields"));
      dispatch(setTypeAlert("error"));
    }
  };
  const validatedDuplicateField = async (data: any) => {
    let isValid = true;
    let messages = [];
    setLoadingValidate(true);
    //setFieldsValid(false);
    const operatorId = activeUser?.operator
      ? activeUser.operator.id
      : user
      ? user.roles.name === Roles_Enum.SUPER_ADMIN
        ? operatorSelected?.id
        : user.operator.id
      : 0;

    const { confirmPassword, ...newData } = data;
    setTotalData(newData);

    if (data) {
      if (data?.numberDocument && !activeUser) {
        const params = {
          numberDocument: data.numberDocument,
        };
        const findNumberDocument =
          token &&
          user?.id &&
          (await filterUsers(
            token,
            user.id,
            user.roles.name,
            params,
            operatorId ? operatorId : null,
            true
          ));
        if (findNumberDocument.data.length > 0) {
          messages.push("alerts.numberDocumentDuplicate");
          setNumberDocumentError(true);

          isValid = false;
        } else {
          setNumberDocumentError(false);
        }
      }
      if (data.username && !activeUser) {
        const params = {
          username: data.username,
        };
        const findUsername =
          token &&
          user?.id &&
          (await filterUsers(
            token,
            user.id,
            user.roles.name,
            params,
            operatorId ? operatorId : null,
            true
          ));

        if (findUsername.data.length > 0) {
          messages.push("alerts.usernameDuplicate");

          setUsernameError(true);

          isValid = false;
        } else {
          setUsernameError(false);
        }
      }
      if (data?.email !== activeUser?.email || (!activeUser && data?.email)) {
        const params = {
          email: data.email,
        };
        const findEmail =
          token &&
          user?.id &&
          (await filterUsers(
            token,
            user.id,
            user.roles.name,
            params,
            operatorId ? operatorId : null,
            true
          ));
        if (findEmail.data.length > 0) {
          messages.push("alerts.emailDuplicate");

          setEmailError(true);

          isValid = false;
        } else {
          setEmailError(false);
        }
      }
    }
    setFieldsValid(isValid);

    const finalMesg =
      messages.length > 1 ? "alerts.duplicateValuesForm" : messages[0];

    return { isValid, msg: finalMesg };
  };

  const handleSelectRoles = (value: IRoles | null) => {
    value?.name !== "" ? setErrorRoles(false) : setErrorRoles(true);
    value &&
      value.name !== "" &&
      setRolesSelected({
        id: value.rol ? value.rol.id : value.id,
        name: value.rol ? value.rol.name : value.name,
      });
  };

  const handleSelectOperator = (value: ICompanyData | null) => {
    value ? setErrorOperator(false) : setErrorOperator(true);
    value && setOperatorSelected({ id: value.id as any, name: value.name });
    setRolesSelected(null);
  };

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
    if (typeAlert === "success") {
      if (fieldsValid) {
        dispatch(setFlagForm({ flagFormCreate: false }));
        dispatch(setFlagFormEdit({ flagFormEdit: false }));
        setLoadingValidate(false);
        if (component === "associates") {
          history.replace("/admin/masters/associates");
          dispatch(setComponent(null));
        } else {
          dispatch(setActiveUser(null));
        }
      }
    }
    setLoadingValidate(false);
    setLoadingSave(false);
  };

  /**
   * Soporta el evento click para abrir la ventana de confirmación
   */
  const openConfirmation = () => {
    setMessage(activeUser ? "dialog.sureEdit" : "dialog.sureSaveUser");
    setOpenDialog(true);
  };
  /**
   * Soporta el evento click para cerrar la ventana de confirmación
   */
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCancel(false);
  };
  const cleanSelectFields = () => {
    setCity(null);
    setSelectedCountry(null);
    setSelectedStates(null);
    setRolesSelected(null);
    isSuper && setOperatorSelected(null);
  };

  const handleUser = async () => {
    if (loadingSave) {
      return;
    }
    setLoadingSave(true);
    setOpenDialog(false);
    if (resetFormFlag && !cancel && resetFunction) {
      cleanSelectFields();
      resetFunction();
    } else {
      if (cancel) {
        if (component === "associates") {
          dispatch(setFlagForm(setFlagFormHelper));
          dispatch(setComponent(null));
          history.replace("/admin/masters/associates");
        } else {
          dispatch(setFlagForm(setFlagFormHelper));
          dispatch(setFlagFormEdit({ flagFormEdit: false }));
          dispatch(setActiveUser(null));
        }
      } else {
        setNumberDocumentError(false);
        setEmailError(false);
        setUsernameError(false);
        let validCreateUser = true;

        if (component === "associates" && token && activeAssociate?.id) {
          const findUserAssociate = await getAssociateById(
            token,
            activeAssociate.id,
            true
          );

          validCreateUser = !findUserAssociate;
        }

        if (validCreateUser) {
          const { isValid, msg } = await validatedDuplicateField(totalData);
          setLoadingValidate(false);

          if (checkLinkDealer && !dealerSelected) {
            setLoadingSave(false);
            dispatch(setMessageAlert(`alerts.completeRequiredFields`));
            dispatch(setTypeAlert("error"));

            return dispatch(setOpenAlert());
          }
          if (isValid) {
            if (token && totalData) {
              if (!!activeUser && totalData.id) {
                delete totalData.password;
                delete totalData.confirmPassword;

                const responseEdit: any = await dispatch(
                  editUser(
                    totalData,
                    token,
                    totalData.id,
                    dealerSelected
                      ? {
                          id:
                            activeUser.userDealer.length > 0
                              ? activeUser.userDealer[0].id
                              : null,
                          dealerId: dealerSelected,
                        }
                      : undefined
                  )
                );
                if (responseEdit && responseEdit.error) {
                  dispatch(setMessageAlert(`alerts.${responseEdit?.error}`));
                  dispatch(setTypeAlert("error"));
                  dispatch(setOpenAlert());
                }
              } else {
                const responseCreate: any = await dispatch(
                  createUser(totalData, token, dealerSelected)
                );
                if (responseCreate && responseCreate.error) {
                  dispatch(setMessageAlert(`alerts.${responseCreate?.error}`));
                  dispatch(setTypeAlert("error"));
                  dispatch(setOpenAlert());
                }
              }
              setLoadingSave(false);
            }
          } else {
            dispatch(setMessageAlert(msg));
            dispatch(setTypeAlert("error"));
            dispatch(setOpenAlert());
          }
        } else {
          dispatch(setMessageAlert("associates.existsUserAssociate"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        }
        dispatch(arraySearch(null));
      }
    }
    setCancel(false);
    setResetFormFlag(false);
    setLoadingSave(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    return () => {
      dispatch(setComponent(null));
      dispatch(setFlagForm({ flagFormCreate: false }));
      dispatch(setFlagFormEdit({ flagFormEdit: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCheckLinkDealer = () => {
    setCheckLinkDealer((prev) => !prev);
    if (!checkLinkDealer) {
      setdealerSelected(null);
    }
  };

  return (
    <div className="container-fluid mt-2">
      <div className="filter">
        <ThemeProvider theme={theme}>
          <Box>
            <Grid item xs={12} marginBottom={3}>
              <i className="fa fa-user-plus fa-lg pr-3 iconUser" />
              <span className="titleUser">{t("user.newUser")}</span>
            </Grid>
            {/* BARRA */}
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  "&::before": {
                    width: "20px",
                  },
                },
              }}
              textAlign="left"
            >
              {t("user.personalInformation")}
            </Divider>
            <Grid item xs={12} marginBottom={3} />

            <Formik
              validateOnChange={true}
              initialValues={{ ...defaultValues, confirmPassword: "" }}
              validationSchema={validationSchema}
              onSubmit={async (data, { setSubmitting }) => {
                setEmailError(false);
                setNumberDocumentError(false);
                setUsernameError(false);
                setSubmitting(true);
                if (
                  !errorCity &&
                  !errorCountry &&
                  selectedCountry &&
                  !errorStates &&
                  cityData &&
                  selectedStates &&
                  !errorOperator &&
                  !errorRoles &&
                  data.verificationTwoStep !== 0 &&
                  (!checkLinkDealer || (checkLinkDealer && dealerSelected))
                ) {
                  data.country = selectedCountry;
                  data.state = selectedStates;
                  data.city = cityData;
                  data.nameComplete = data.nameComplete.trim();
                  data.address = data.address.trim();
                  data.username = data.username.trim();
                  data.email = data.email.trim();
                  data.password = data.password ? data.password.trim() : "";
                  data.observation = data.observation.trim();
                  data.roles = rolesSelected;
                  data.operator =
                    user && !isSuper
                      ? { id: user.operator.id, name: user.operator.name }
                      : operatorSelected!;
                  if (user) {
                    data.createdUser = activeUser
                      ? activeUser.createdUser
                      : { id: user.id, nameComplete: user.nameComplete };
                  }
                  setTotalData(data);
                  openConfirmation();
                  setSubmit(false);
                  setSubmitting(false);
                }
                setSubmitting(false);
              }}
            >
              {({
                values,
                handleChange,
                errors,
                handleReset,
                setFieldValue,
              }) => (
                <Form>
                  <Grid container spacing={2} mb={3}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      mb={errors.nameComplete && -1}
                    >
                      <MyTextField
                        inputProps={{
                          "data-testid": "nameComplete",
                          maxLength: 250,
                        }}
                        name="nameComplete"
                        type="text"
                        label={t("user.fullName") + "*"}
                        onChange={handleChange}
                        value={values.nameComplete}
                        fullWidth
                        autoComplete="off"
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                          !regLetters(e.key) && e.preventDefault()
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      mb={errors.typeDocument && -1}
                    >
                      <MyTextField
                        name="typeDocument"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        label={t("user.documentType") + "*"}
                        onChange={(
                          event: any,
                          value: IDocumentTypes | null
                        ) => {
                          handleChange(event);
                          setFieldValue("numberDocument", "");
                        }}
                        disabled={!!activeUser}
                      >
                        {documentTypes?.map((item) => (
                          <MenuItem key={item.name} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </MyTextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <MyTextField
                        inputProps={{
                          "data-testid": "numberDocument",
                          maxLength: 15,
                        }}
                        name="numberDocument"
                        variant="outlined"
                        size="small"
                        label={t("user.documentNumber") + "*"}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setNumberDocumentError(false);
                          handleChange(e);
                        }}
                        value={values.numberDocument}
                        error={numberDocumentError && !loadingValidate}
                        fullWidth
                        disabled={!!activeUser}
                        autoComplete="off"
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                          if (
                            [DocumentTypes.PASSPORT, DocumentTypes.CE].includes(values.typeDocument)
                              ? !regPassport(e.key)
                              : !regNumbers(e.key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      mb={errors.expeditionDate && -1}
                    >
                      <MyTextField
                        name="expeditionDate"
                        variant="outlined"
                        size="small"
                        type="date"
                        label={t("user.expeditionDate") + "*"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          "data-testid": "expeditionDate",
                          max: new Date().toLocaleDateString("en-CA"),
                        }}
                        onChange={handleChange}
                        value={values.expeditionDate}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      mb={errors.phone && -1}
                    >
                      <MyTextField
                        inputProps={{
                          "data-testid": "phone",
                        }}
                        name="phone"
                        variant="outlined"
                        size="small"
                        label={t("user.cellphone") + "*"}
                        onChange={handleChange}
                        value={values.phone}
                        fullWidth
                        autoComplete="off"
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                          if (values.phone === "") {
                            e.key === "0"
                              ? e.preventDefault()
                              : !regNumbers(e.key) && e.preventDefault();
                          } else {
                            !regNumbers(e.key) && e.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      mb={errors.address && -1}
                    >
                      <MyTextField
                        inputProps={{
                          "data-testid": "address",
                          maxLength: 150,
                        }}
                        name="address"
                        variant="outlined"
                        size="small"
                        label={t("user.address") + "*"}
                        onChange={handleChange}
                        value={values.address}
                        fullWidth
                        autoComplete="off"
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                          !regAddress(e.key) && e.preventDefault()
                        }
                      />
                    </Grid>
                    {countries && (
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Autocomplete
                          id="countries"
                          data-testid="countriesSelect"
                          options={countries}
                          disableClearable
                          // @ts-ignore
                          value={selectedCountry}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event: any, value: ICountries | null) => {
                            setFieldValue("country", value);

                            handleSelectCountry(value);
                          }}
                          renderOption={(props, option) => {
                            const { name } = option;
                            return (
                              <span {...props} style={{ color: "#000" }}>
                                {name}
                              </span>
                            );
                          }}
                          renderInput={({ inputProps, ...rest }) => (
                            <TextField
                              {...rest}
                              inputProps={{ ...inputProps, readOnly: true }}
                              name="countries"
                              variant="outlined"
                              size="small"
                              label={t("user.country") + "*"}
                              error={errorCountry}
                            />
                          )}
                        />
                        <FormHelperText>
                          {errorCountry && (
                            <label
                              className="ml-3"
                              style={{ color: "#d32f2f" }}
                            >
                              {t("form.requiredFile")}
                            </label>
                          )}
                        </FormHelperText>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <Autocomplete
                        name="state"
                        data-testid="stateSelect"
                        id="state"
                        options={states ? states : []}
                        renderOption={(props, option) => {
                          return (
                            <span {...props} style={{ color: "#000" }}>
                              {option.names}
                            </span>
                          );
                        }}
                        disabled={flagStates}
                        disableClearable
                        // @ts-ignore
                        value={selectedStates}
                        getOptionLabel={(option) => option.names}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event: any, value: IStates | null) =>
                          handleSelectStates(value)
                        }
                        renderInput={({ inputProps, ...rest }) => (
                          <TextField
                            {...rest}
                            inputProps={{ ...inputProps, readOnly: true }}
                            name="state"
                            variant="outlined"
                            size="small"
                            label={t("user.states") + "*"}
                            error={errorStates}
                          />
                        )}
                      />
                      <FormHelperText>
                        {errorStates && (
                          <label className="ml-3" style={{ color: "#d32f2f" }}>
                            {t("form.requiredFile")}
                          </label>
                        )}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <Autocomplete
                        name="city"
                        data-testid="citySelect"
                        id="city"
                        options={cities ? cities : []}
                        disabled={flagCity}
                        disableClearable
                        // @ts-ignore
                        value={cityData}
                        getOptionLabel={(option) => option.names}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event: any, value: ICities | null) =>
                          handleSelectCities(value)
                        }
                        renderOption={(props, option) => {
                          return (
                            <span {...props} style={{ color: "#000" }}>
                              {option.names}
                            </span>
                          );
                        }}
                        renderInput={({ inputProps, ...rest }) => (
                          <TextField
                            {...rest}
                            inputProps={{ ...inputProps, readOnly: true }}
                            name="city"
                            variant="outlined"
                            size="small"
                            label={t("user.city") + "*"}
                            error={errorCity}
                          />
                        )}
                      />
                      <FormHelperText>
                        {errorCity && (
                          <label className="ml-3" style={{ color: "#d32f2f" }}>
                            {t("form.requiredFile")}
                          </label>
                        )}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                  {/* BARRA */}
                  <Divider
                    sx={{
                      "&.MuiDivider-root": {
                        "&::before": {
                          width: "20px",
                        },
                      },
                    }}
                    textAlign="left"
                  >
                    {t("user.accountInformation")}
                  </Divider>
                  <Grid item xs={12} marginBottom={3} />

                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      mb={errors.username && -1}
                    >
                      <MyTextField
                        inputProps={{
                          "data-testid": "username",
                          maxLength: 50,
                        }}
                        name="username"
                        variant="outlined"
                        size="small"
                        label={t("user.username") + "*"}
                        onChange={(e: any) => {
                          handleChange(e);
                          setUsernameError(false);
                        }}
                        value={values.username}
                        error={usernameError && !loadingValidate}
                        fullWidth
                        disabled={!!activeUser}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                          !regUsername(e.key) && e.preventDefault()
                        }
                      />
                    </Grid>
                    {
                      //MOSTRAR SELECT CON OPERADORES CUANDO SEA SUPER_ADMIN
                      user?.roles.name === Roles_Enum.SUPER_ADMIN &&
                        !activeUser && (
                          <>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              lg={4}
                              mb={errors.roles && -1}
                            >
                              <Autocomplete
                                id="operator"
                                data-testid="operatorSelect"
                                options={
                                  companies
                                    ? companies.filter(
                                        (operator) =>
                                          operator.stateOperator ===
                                          State.ACTIVE
                                      )
                                    : []
                                }
                                disabled={
                                  !!activeUser || component === "associates"
                                }
                                renderOption={(props, option) => {
                                  return (
                                    <span
                                      {...props}
                                      style={{ color: "#000" }}
                                      key={option.id}
                                    >
                                      {option.name}
                                    </span>
                                  );
                                }}
                                disableClearable
                                value={operatorSelected as any}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                onChange={(
                                  event: any,
                                  value: ICompanyData | null
                                ) => handleSelectOperator(value)}
                                renderInput={({ inputProps, ...rest }) => (
                                  <TextField
                                    {...rest}
                                    inputProps={{
                                      ...inputProps,
                                      readOnly: true,
                                    }}
                                    variant="outlined"
                                    size="small"
                                    name="operator"
                                    label={
                                      t("companyManagement.operator") + "*"
                                    }
                                    error={errorOperator}
                                  />
                                )}
                              />
                              <FormHelperText>
                                {errorOperator && (
                                  <label
                                    className="ml-3"
                                    style={{ color: "#d32f2f" }}
                                  >
                                    {t("form.requiredFile")}
                                  </label>
                                )}
                              </FormHelperText>
                            </Grid>
                          </>
                        )
                    }
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      mb={errors.roles && -1}
                    >
                      <Autocomplete
                        data-testid="rolSelect"
                        id="roles"
                        options={
                          roles
                            ? roles.data.filter((roles) =>
                                roles.rol
                                  ? roles.rol.state === State.ACTIVE
                                  : component === "associates"
                                  ? ["ASSOCIATE"].includes(roles.name)
                                  : !operatorSelected
                                  ? DEFAULT_ROLES.includes(roles.name)
                                  : (roles.state === State.ACTIVE &&
                                      !!roles.actionsPermissions?.find(
                                        (item) =>
                                          item.menu.operator?.id ===
                                          operatorSelected?.id
                                      )) ||
                                    DEFAULT_ROLES.includes(roles.name)
                              )
                            : []
                        }
                        renderOption={(props, option) => {
                          return (
                            <span
                              {...props}
                              style={{ color: "#000" }}
                              key={option.id}
                            >
                              {option.rol
                                ? DEFAULT_ROLES.includes(option.rol.name)
                                  ? t(`roles.${option.rol.name}`)
                                  : option.rol.name
                                : DEFAULT_ROLES.includes(option.name)
                                ? t(`roles.${option.name}`)
                                : option.name}
                            </span>
                          );
                        }}
                        disableClearable
                        value={rolesSelected as IRoles}
                        getOptionLabel={(option) => {
                          return option.rol
                            ? option.rol.name
                            : DEFAULT_ROLES.includes(option.name)
                            ? t(`roles.${option.name}`)
                            : option.name;
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.rol
                            ? option.rol.id === value.id
                            : option.id === value.id
                        }
                        onChange={(event: any, value: IRoles | null) =>
                          handleSelectRoles(value)
                        }
                        renderInput={({ inputProps, ...rest }) => (
                          <TextField
                            {...rest}
                            inputProps={{ ...inputProps, readOnly: true }}
                            variant="outlined"
                            size="small"
                            label={t("user.rol") + "*"}
                            error={errorRoles}
                          />
                        )}
                      />

                      <FormHelperText>
                        {errorRoles && (
                          <label className="ml-3" style={{ color: "#d32f2f" }}>
                            {t("form.requiredFile")}
                          </label>
                        )}
                      </FormHelperText>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      mb={errors.email && -1}
                    >
                      <MyTextField
                        inputProps={{
                          "data-testid": "email",
                          maxLength: 100,
                        }}
                        name="email"
                        label={t("user.email") + "*"}
                        onChange={(e: any) => {
                          handleChange(e);
                          setEmailError(false);
                        }}
                        value={values.email}
                        error={emailError && !loadingValidate}
                        fullWidth
                        autoComplete="off"
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                          !regEmail(e.key) && e.preventDefault()
                        }
                      />
                    </Grid>
                    {!activeUser && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={4}
                          mb={errors.password && -1}
                        >
                          <MyTextField
                            inputProps={{
                              "data-testid": "password",
                              maxLength: 30,
                            }}
                            name="password"
                            label={t("user.password") + "*"}
                            onChange={handleChange}
                            value={values.password}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    onClick={handleClickShowPassword}
                                    sx={{
                                      "&:focus": {
                                        outline: 0,
                                        border: 0,
                                      },
                                    }}
                                  >
                                    {showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            type={showPassword ? "text" : "password"}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={4}
                          mb={errors.confirmPassword && -1}
                        >
                          <MyTextField
                            inputProps={{
                              "data-testid": "confirmPassword",
                              maxLength: 30,
                            }}
                            name="confirmPassword"
                            label={t("user.confirmPassword") + "*"}
                            onChange={handleChange}
                            value={values.confirmPassword}
                            fullWidth
                            autoComplete="off"
                            InputProps={{
                              // disableUnderline: false,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    onClick={handleClickShowConfirmPassword}
                                    sx={{
                                      "&:focus": {
                                        outline: 0,
                                        border: 0,
                                      },
                                    }}
                                  >
                                    {showConfirmPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            type={showConfirmPassword ? "text" : "password"}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      mb={errors.state && -1}
                    >
                      <MyTextField
                        name="stateUser"
                        select
                        id="demo-multiple-name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={t("user.state")}
                        onChange={handleChange}
                        value={values.state}
                        fullWidth
                      >
                        {state.map((item) => (
                          <MenuItem
                            key={item.value}
                            value={item.value}
                            sx={{
                              "&.Mui-focused": {
                                backgroundColor: "red",
                              },
                            }}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </MyTextField>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      mb={errors.observation && -1}
                    >
                      <MyTextField
                        name="observation"
                        label={t("user.observation")}
                        onChange={handleChange}
                        value={values.observation}
                        multiline
                        minRows={3}
                        maxRows={8}
                        fullWidth
                        inputProps={{ maxLength: 200 }}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                          !regAddress(e.key) && e.preventDefault()
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={8}>
                      <FormLabel>{t("user.verificationTwoStep")}</FormLabel>
                      <RadioGroup
                        name="verificationTwoStep"
                        value={values.verificationTwoStep}
                        onChange={handleChange}
                        row
                      >
                        <FormControlLabel
                          key="yes"
                          value={1}
                          control={<Radio size="small" />}
                          label={t("user.yes")}
                        />
                        <FormControlLabel
                          key="no"
                          value={2}
                          control={<Radio size="small" />}
                          label="No"
                        />
                      </RadioGroup>
                      <FormHelperText>
                        {submit && values.verificationTwoStep === "" && (
                          <label style={{ color: "#d32f2f" }}>
                            {t("form.requiredFile")}
                          </label>
                        )}
                      </FormHelperText>
                    </Grid>
                    {((user &&
                      user.operator &&
                      operatorsShowLinkDealer.includes(user.operator.id)) ||
                      isSuper) && (
                      <>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checkLinkDealer}
                                onChange={handleCheckLinkDealer}
                              />
                            }
                            label={`${t("formUser.linkDealer")}`}
                          />
                        </Grid>
                        {checkLinkDealer && (
                          <Grid item xs={2}>
                            <TextField
                              select
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={`${t("managementDealers.dealerName")}*`}
                              onChange={(e: any) =>
                                setdealerSelected(e.target.value)
                              }
                              value={dealerSelected || ""}
                              fullWidth
                              size="small"
                              error={
                                submit && checkLinkDealer && !dealerSelected
                              }
                            >
                              {dealers.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </TextField>
                            <FormHelperText>
                              {submit && checkLinkDealer && !dealerSelected && (
                                <label style={{ color: "#d32f2f" }}>
                                  {t("form.requiredFile")}
                                </label>
                              )}
                            </FormHelperText>
                          </Grid>
                        )}
                      </>
                    )}
                    <Grid
                      item
                      xs={12}
                      mt={3}
                      className="gridButton"
                      sx={{ height: "60px" }}
                    >
                      <Button
                        type="button"
                        style={{ border: "none", outline: "none" }}
                        variant="contained"
                        className="footer-btn danger-btn ripple"
                        onClick={handleCancel}
                        disabled={loadingValidate || loadingSave}
                      >
                        <i className="fa-solid fa-x"></i>
                        {t("button.cancel")}
                      </Button>
                      <Button
                        type="submit"
                        id="btnSave"
                        data-testid="btnSubmit"
                        style={{
                          border: "none",
                          outline: "none",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        variant="contained"
                        className="footer-btn primary-btn ripple"
                        onClick={() => {
                          setSubmit(true);
                          validateFields(values);
                        }}
                        disabled={loadingValidate || loadingSave}
                      >
                        {loadingValidate || loadingSave ? (
                          <CircularProgress size={"1.6rem"} color="inherit" />
                        ) : (
                          t(!!activeUser ? "button.edit" : "button.save")
                        )}
                      </Button>

                      <Button
                        type="button"
                        style={{
                          border: "none",
                          outline: "none",
                          marginLeft: "4px",
                          opacity: !!activeUser ? "0.3" : "",
                        }}
                        onClick={() => {
                          setResetFunction(() => handleReset);
                          handleCleanForm();
                        }}
                        disabled={
                          !!activeUser || loadingValidate || loadingSave
                        }
                      >
                        <img src={clearLogo} alt="Icon clear" />
                      </Button>
                    </Grid>
                  </Grid>
                  <MyDialog
                    id="confirmationDialog"
                    open={openDialog}
                    title={t("dialog.confirmationWindow")}
                    message={t(message)}
                    handleAccept={handleUser}
                    handleClose={handleCloseDialog}
                    isLoading={loadingValidate || loadingSave}
                  />
                </Form>
              )}
            </Formik>
          </Box>
        </ThemeProvider>
      </div>
      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />
    </div>
  );
};

export default FormCreateUser;
