import moment from "moment";
import { FC } from "react";
import TableCell from "@mui/material/TableCell";

interface Props {
  row: any;
}

export const RowDataTableTransactions: FC<Props> = ({ row }) => {
  return (
    <>
      <TableCell className="word-break">{row.id}</TableCell>
      <TableCell className="word-break">{row.player?.id}</TableCell>
      <TableCell className="word-break">
        {row.player?.firstName + " " + row.player?.lastName}
      </TableCell>
      <TableCell className="word-break">
        {moment(row.createAt).format("DD/MM/YYYY HH:mm:ss")}
      </TableCell>
      <TableCell className="word-break">{"$ " + row.value}</TableCell>
      <TableCell className="word-break">{row.observations}</TableCell>
      <TableCell />
    </>
  );
};
