import { IEvolutionQuery } from "../interfaces/casino";
export const getEvolutionCasinoReportForActivity = (
  token: string,
  params: Partial<IEvolutionQuery>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  if (params.startDate) {
    params.startDate = new Date(`${params.startDate} 00:00:00`).toISOString();
  }
  if (params.endDate) {
    params.endDate = new Date(`${params.endDate} 23:59:59`).toISOString();
  }

  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_EVOLUTION}report?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
