import React, { useState } from 'react';
import { Button, Grid, Tooltip } from '@mui/material';
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MyDialog } from './MyDialog';

import './dragAndDropCardStyles.scss';
interface DragAndDropCardsProps {
  cards: any;
  deleteMethod: (id: number) => (dispatch: Function, getState: Function) => Promise<void>;
  updateOrderMethod: (data: any, module: string) => (dispatch: Function, getState: Function) => Promise<void>;
  module: string
}

const DragAndDropCards = ({ cards, deleteMethod, updateOrderMethod, module }: DragAndDropCardsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [message, setMessage] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [cardData, setCardData] = useState(cards);
  const [activeCard, setActiveCard] = useState<any>();

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result
    if (!destination) return
    setDisabled(false);
    const items = Array.from(cardData)
    const [newCardData] = items.splice(source.index, 1)
    items.splice(destination.index, 0, newCardData)
    setCardData(items)
  }

  const handleAction = async () => {
    switch (type) {
      case 'delete':
        if (activeCard) {
          setOpenDialog(false);
          await dispatch(deleteMethod(activeCard.id));
          const newArray = cardData.filter((item: any) => item.id !== activeCard.id);
          setCardData(newArray);
        }
        break;
      case 'save':
        setOpenDialog(false);
        await dispatch(updateOrderMethod(cardData, module));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="slider" direction="horizontal">
          {(provided) => (
            <Grid container spacing={2} className="slider" {...provided.droppableProps} ref={provided.innerRef}>
              {cardData.map((card: any, index: number) => {
                return (
                  <Draggable key={card.id} draggableId={card.id} index={index}>
                    {(provided) => (
                      <Grid item xs={12} sm={12} md={4}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className='card-content'>
                          <img src={card.url} alt="Card" />
                        </div>
                        <Tooltip title={t("button.delete") as string} arrow>
                          <div className='button-container'>
                            <div className='circle-button'>
                              <i
                                className="fa fa-trash fa-lg iconDelete"
                                onClick={() => {
                                  setActiveCard(card);
                                  setType('delete');
                                  setMessage(t("managementAds.deleteElementAsk"));
                                  setOpenDialog(true);
                                }}
                              />
                            </div>
                          </div>
                        </Tooltip>
                      </Grid>
                    )}
                  </Draggable>
                )
              })}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        disabled={disabled}
        variant="contained"
        className="footer-btn primary-btn ripple"
        style={{
          border: "none",
          outline: "none",
          marginTop: "20px"
        }}
        onClick={() => {
          setType('save');
          setMessage(t("dialog.saveOrder"));
          setOpenDialog(true);
        }}
      >
        {t("button.saveOrder")}
      </Button>
      <MyDialog
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message={message}
        handleAccept={handleAction}
        handleClose={() => {
          setOpenDialog(false);
        }}
      />
    </>
  );
};

export default DragAndDropCards;
