import { View, Text } from "@react-pdf/renderer";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { State } from "../../../enums/enums";

import { IFormConfigOperation } from "../../../interfaces/operationCompany";
import { stylesPdf } from "../../../views/components/custom/customStyles";

interface Props {
  page: any;
}

export const OperatorConfigPdfDocument: FC<Props> = ({ page }) => {
  const { t } = useTranslation();
  const columnsMenus = [
    t("companyManagement.company").toUpperCase(),
    t("companyManagement.brand").toUpperCase(),
    t("user.country").toUpperCase(),
    t("pointsOfSale.games").toUpperCase(),
    t("table.currency").toUpperCase(),
    t("companyManagement.methodDeposit").toUpperCase(),
    t("companyManagement.methodWithdrawal").toUpperCase(),
    t("parameterization.solutions").toUpperCase(),
  ];
  const columns = columnsMenus;
  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column) => (
          <View key={column} style={[stylesPdf.pCol, stylesPdf.col]}>
            <Text style={stylesPdf.text}>{column}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        {page.map((page: any) => (
          <View key={page.id} style={stylesPdf.row}>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>
                {(page as IFormConfigOperation).operator?.name}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>
                {(page as IFormConfigOperation).name}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>
                {page.countries
                  ? (page as IFormConfigOperation).countries[0].name
                  : page.countriesBrands[0].country.name}
              </Text>
            </View>
            {page.countries && (
              <>
                <View style={[stylesPdf.pCol, stylesPdf.col]}>
                  {(
                    page as IFormConfigOperation
                  ).countries[0].countriesGames?.map(
                    (item: any, index: number) => (
                      <Text
                        key={`${item.id}-${index}`}
                        style={stylesPdf.textContent}
                      >
                        {item.paramGame ? item.paramGame.game.name : "NA"}
                        &nbsp;
                      </Text>
                    )
                  )}
                </View>
                <View style={[stylesPdf.pCol, stylesPdf.col]}>
                  {(
                    page as IFormConfigOperation
                  ).countries[0].countriesCurrencies?.map(
                    (item: any, index: number) => (
                      <Text
                        key={`${item.id}-${index}`}
                        style={stylesPdf.textContent}
                      >
                        {item.currency ? item.currency.currency : "NA"}
                        &nbsp;
                      </Text>
                    )
                  )}
                </View>
                <View style={[stylesPdf.pCol, stylesPdf.col]}>
                  {page.countries[0].countriesPayments
                    ?.filter(
                      (item: any) =>
                        item.state === State.ACTIVE &&
                        item.paramMethod &&
                        item.paramMethod.paymentMethod.type !== "withdrawal"
                    )
                    .map((item: any, index: number) => (
                      <Text
                        key={`${item.id}-${index}`}
                        style={stylesPdf.textContent}
                      >
                        {item.paramMethod.paymentMethod.name} &nbsp;
                      </Text>
                    ))}
                </View>
                <View style={[stylesPdf.pCol, stylesPdf.col]}>
                  {page.countries[0].countriesPayments
                    ?.filter(
                      (item: any) =>
                        item.state === State.ACTIVE &&
                        item.paramMethod &&
                        item.paramMethod.paymentMethod.type === "withdrawal"
                    )
                    .map((item: any, index: number) => (
                      <Text
                        key={`${item.id}-${index}`}
                        style={stylesPdf.textContent}
                      >
                        {item.paramMethod.paymentMethod.name} &nbsp;
                      </Text>
                    ))}
                </View>
                <View style={[stylesPdf.pCol, stylesPdf.col]}>
                  {page.countries[0].countriesSolutions?.map(
                    (item: any, index: number) => (
                      <Text
                        key={`${item.id}-${index}`}
                        style={stylesPdf.textContent}
                      >
                        {item.paramSolution
                          ? item.paramSolution.solution.name
                          : "NA"}
                        &nbsp;
                      </Text>
                    )
                  )}
                </View>
              </>
            )}
            {page.countriesBrands && (
              <>
                <View style={[stylesPdf.pCol, stylesPdf.col]}>
                  {page.countriesBrands[0].countriesGames?.map(
                    (item: any, index: number) => (
                      <Text
                        key={`${item.id}-${index}`}
                        style={stylesPdf.textContent}
                      >
                        {item.paramGame ? item.paramGame.game.name : "NA"}
                        &nbsp;
                      </Text>
                    )
                  )}
                </View>
                <View style={[stylesPdf.pCol, stylesPdf.col]}>
                  {page.countriesBrands[0].countriesCurrencies?.map(
                    (item: any, index: number) => (
                      <Text
                        key={`${item.id}-${index}`}
                        style={stylesPdf.textContent}
                      >
                        {item.currency ? item.currency.currency : "NA"}
                        &nbsp;
                      </Text>
                    )
                  )}
                </View>
                <View style={[stylesPdf.pCol, stylesPdf.col]}>
                  {page.countriesBrands[0].countriesPayments
                    ?.filter(
                      (itemFilter: any) =>
                        itemFilter.state === State.ACTIVE &&
                        itemFilter.paramMethod &&
                        itemFilter.paramMethod.paymentMethod.type !==
                          "withdrawal"
                    )
                    .map((itemMap: any, index: number) => (
                      <Text
                        key={`${itemMap.id}-${index}`}
                        style={stylesPdf.textContent}
                      >
                        {itemMap.paramMethod.paymentMethod.name} &nbsp;
                      </Text>
                    ))}
                </View>
                <View style={[stylesPdf.pCol, stylesPdf.col]}>
                  {page.countriesBrands[0].countriesPayments
                    ?.filter(
                      (item: any) =>
                        item.state === State.ACTIVE &&
                        item.paramMethod &&
                        item.paramMethod.paymentMethod.type === "withdrawal"
                    )
                    .map((item: any, index: number) => (
                      <Text
                        key={`${item.id}-${index}`}
                        style={stylesPdf.textContent}
                      >
                        {item.paramMethod.paymentMethod.name} &nbsp;
                      </Text>
                    ))}
                </View>
                <View style={[stylesPdf.pCol, stylesPdf.col]}>
                  {page.countriesBrands[0].countriesSolutions?.map(
                    (item: any, index: number) => (
                      <Text
                        key={`${item.id}-${index}`}
                        style={stylesPdf.textContent}
                      >
                        {item.paramSolution
                          ? item.paramSolution.solution.name
                          : "NA"}
                        &nbsp;
                      </Text>
                    )
                  )}
                </View>
              </>
            )}
          </View>
        ))}
      </View>
    </>
  );
};
