import React, { useState, ChangeEvent, KeyboardEvent } from "react";
import { Form, Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { MyTextField } from "../../components/custom/MyTextField";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { setActiveCategory } from "../../../redux/actions/casinoActivity";
import { MyDialog } from "../../components/custom/MyDialog";
import {
  asyncLoadingFunction,
  setFlagForm,
} from "../../../redux/actions/helperActions";
import { IFile } from "../../../interfaces/legal";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { regLettersNumbers } from "../../../utils/utils";
import {
  startCreatePromotion,
  startUpdatePromotion,
} from "../../../redux/actions/promotionsPopupsActions";
import { urls } from "../../../const/const";

const PromotionForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activePromotion } = useSelector(
    (state: AppState) => state.promotionsPopups
  );
  const [openDialog, setOpenDialog] = useState(false);
  const { loading } = useSelector((state: AppState) => state.helper);
  const [noValid, setNoValid] = useState(false);
  const [errorFileMessage, setErrorFileMessage] = useState("");
  const [fileLabel, setFileLabel] = useState(
    activePromotion && activePromotion.image ? activePromotion.image.url : ""
  );
  const [file, setFile] = useState<IFile>({
    file: null,
    label: activePromotion ? fileLabel : "",
  });
  const [saveValues, setSaveValues] = useState<any>(null);
  const [cancel, setCancel] = useState(false);
  const fileSupported = {
    accept: "image/jpeg, image/png",
  };

  const handleSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
    const selectedFile = e.target.files?.[0] as File;
    if (selectedFile) {
      if (!SUPPORTED_FORMATS.includes(selectedFile.type)) {
        setErrorFileMessage(errorFileMessage);
        return setNoValid(true);
      }
      setNoValid(false);
      setErrorFileMessage("");
      setFile({ file: selectedFile, label: selectedFile.name });
      setFileLabel(selectedFile.name);
    } else {
      setFile({
        file: null,
        label: "",
      });
    }
  };

  const handleDeleteFile = () => {
    setFile({ file: null, label: "" });
    setFileLabel("");
  };

  const handleCloseDialog = () => {
    setCancel(false);
    setOpenDialog(false);
  };

  const handleAccept = async () => {
    handleCloseDialog();
    if (cancel) {
      dispatch(setFlagForm({ flagFormCreate: false }));
    } else {
      const formData = {
        title: saveValues.title,
        promotionalPopup: saveValues.fileUrl,
        buttons: saveValues.buttons,
        startDate: saveValues.startDate,
        endDate: saveValues.endDate,
      };
      if (activePromotion && saveValues.fileUrl === null) {
        delete formData.promotionalPopup;
      }
      dispatch(
        asyncLoadingFunction(async () =>
          activePromotion
            ? dispatch(
                startUpdatePromotion(formData, Number(activePromotion?.id))
              )
            : dispatch(startCreatePromotion(formData))
        )
      );
    }
  };

  const validationSchema = Yup.object({
    startDate: Yup.string().required("Campo requerido"),
    endDate: Yup.string()
      .required("Campo requerido")
      .test(
        "is-greater",
        "La fecha de fin debe ser igual o posterior a la fecha de inicio",
        function (value) {
          const { startDate } = this.parent;
          return startDate && value
            ? new Date(value).getTime() >= new Date(startDate).getTime()
            : true;
        }
      )
      .test(
        "is-not-past",
        "La fecha de fin no puede ser anterior a la fecha actual",
        function (value) {
          const today = new Date();
          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, "0");
          const day = String(today.getDate()).padStart(2, "0");
          const currentDate = `${year}-${month}-${day}`;
          const currentDateObj = new Date(currentDate);
          const selectedDateObj = new Date(String(value));
          currentDateObj.setHours(0, 0, 0, 0);
          selectedDateObj.setHours(0, 0, 0, 0);
          return selectedDateObj >= currentDateObj;
        }
      ),
  });

  const validateButtons = (data: any) => {
    const validButtons = data.buttons.filter(
      (button: any) => button.name && button.url
    );
    if (validButtons.length === 0) {
      return null;
    }
    return validButtons;
  };

  const initialButtons =
    activePromotion && activePromotion.buttons
      ? JSON.parse(activePromotion.buttons)
      : [{ name: "", url: "" }];

  return (
    <div>
      <Formik
        initialValues={{
          title: activePromotion ? activePromotion.title : "",
          buttons: initialButtons ? initialButtons : [{ name: "", url: "" }],
          startDate: activePromotion ? activePromotion.startDate : "",
          endDate: activePromotion ? activePromotion.endDate : "",
        }}
        validateOnChange={true}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          const newFile = file.file ? file.file : null;
          if (newFile || file?.label) {
            const validatedButtons = validateButtons(data);
            const transformedData = {
              ...data,
              buttons: validatedButtons ? validatedButtons : null,
            };
            const formValues = { ...transformedData };
            setSaveValues({
              ...formValues,
              fileUrl: newFile,
              fileName: file.label !== "" ? file.label : fileLabel,
            });
            setOpenDialog(true);
            setNoValid(false);
            setErrorFileMessage("");
          } else {
            setNoValid(true);
            setErrorFileMessage("El campo es requerido");
          }
          setSubmitting(false);
        }}
      >
        {({ handleChange, values, errors, touched }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <label className="ml-3">Título de la promoción</label>
                <MyTextField
                  name="title"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label=""
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={handleChange}
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="ml-3">Imagen de la promoción*</label>
                <TextField
                  size="small"
                  type="file"
                  inputProps={fileSupported}
                  onChange={handleSelectFile}
                  id="icon-button-file"
                  autoComplete="off"
                  style={{ display: "none" }}
                  fullWidth
                  error={noValid}
                />
                <Grid container>
                  <Grid item xs={8}>
                    <TextField
                      disabled={true}
                      variant="outlined"
                      style={{ color: "black" }}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <>
                            {file.label.length > 0 && (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleDeleteFile}
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    boxShadow: "none",
                                  }}
                                >
                                  <CancelIcon />
                                </IconButton>
                              </InputAdornment>
                            )}
                          </>
                        ),
                      }}
                      label={
                        file.label.length
                          ? file.label
                          : t("managementAds.uploadImg")
                      }
                      name="image"
                      size="small"
                      error={noValid}
                      helperText={errorFileMessage}
                    />
                  </Grid>
                  <Grid item xs={2} pr={1}>
                    <label htmlFor="icon-button-file">
                      <Button
                        style={{
                          border: "none",
                          outline: "none",
                          backgroundColor: "#007e8c",
                        }}
                        variant="contained"
                        component="span"
                      >
                        {t("button.inspect").toUpperCase()}
                      </Button>
                    </label>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="ml-3">{t("promotions.startDate")}</label>
                <MyTextField
                  name="startDate"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="date"
                  InputLabelProps={{ shrink: false }}
                  label={" "}
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={handleChange}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regLettersNumbers(e.key) && e.preventDefault()
                  }
                  error={touched.startDate && Boolean(errors.startDate)}
                  helperText={touched.startDate && errors.startDate}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <label className="ml-3">{t("promotions.deadline")}</label>
                <MyTextField
                  name="endDate"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="date"
                  InputLabelProps={{ shrink: false }}
                  label={" "}
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={handleChange}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regLettersNumbers(e.key) && e.preventDefault()
                  }
                  error={touched.endDate && Boolean(errors.endDate)}
                  helperText={touched.endDate && errors.endDate}
                />
              </Grid>
              <FieldArray name="buttons">
                {({ push, remove }) => (
                  <Grid item container xs={12} spacing={2}>
                    {values.buttons.map((_: any, index: number) => (
                      <React.Fragment key={index}>
                        <Grid item xs={12} md={5}>
                          <label className="ml-3">Texto del botón</label>
                          <MyTextField
                            name={`buttons[${index}].name`}
                            variant="outlined"
                            size="small"
                            fullWidth
                            type="text"
                            label=""
                            inputProps={{
                              maxLength: 100,
                            }}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <label className="ml-3">
                            {t("messages.buttonUrl")}
                          </label>
                          <MyTextField
                            name={`buttons[${index}].url`}
                            select
                            InputLabelProps={{ shrink: false }}
                            label={""}
                            onChange={handleChange}
                          >
                            {urls.map((item) => (
                              <MenuItem
                                key={item.value}
                                value={item.value}
                                sx={{
                                  "&.Mui-focused": {
                                    backgroundColor: "red",
                                  },
                                }}
                              >
                                {item.label}
                              </MenuItem>
                            ))}
                          </MyTextField>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          display="flex"
                          alignItems="center"
                        >
                          <IconButton
                            onClick={() => remove(index)}
                            disabled={values.buttons.length === 1}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                          {index === values.buttons.length - 1 && (
                            <IconButton
                              onClick={() => push({ name: "", url: "" })}
                            >
                              <AddCircleIcon />
                            </IconButton>
                          )}
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                )}
              </FieldArray>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    variant="contained"
                    className="footer-btn danger-btn ripple"
                    style={{ border: "none", outline: "none" }}
                    onClick={() => {
                      dispatch(setActiveCategory(null));
                      dispatch(setFlagForm({ flagFormCreate: false }));
                    }}
                  >
                    {t("button.cancel").toUpperCase()}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className="footer-btn primary-btn ripple"
                    style={{ border: "none", outline: "none" }}
                  >
                    {loading ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      t("button.save").toUpperCase()
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <MyDialog
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message={"¿Esta seguro de crear la promoción?"}
        handleAccept={handleAccept}
        handleClose={() => setOpenDialog(false)}
        isLoading={!!loading}
      />
    </div>
  );
};

export default PromotionForm;
