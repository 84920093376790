import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { setFlagForm } from "../../../redux/actions/helperActions";
import { MyDialog } from "../../components/custom/MyDialog";
import { useTranslation } from "react-i18next";
import PromotionForm from "./PromotionForm";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import "./Promos.scss";
import PromotionsTable from "./PromotionsTable";
import { setActivePromotionPopup } from "../../../redux/actions/promotionsPopupsActions";

const PromotionsPopups: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { flagFormCreate, loading } = useSelector(
    (state: AppState) => state.helper
  );
  const [openDialog, setOpenDialog] = useState(false);

  const handleAccept = () => {
    setOpenDialog(false);
  };

  return (
    <div className="content w-100 mb-5">
      <MyBreadCrumbs titles={["Popups promocionales"]} />
      <div className="promotions-container mt-2">
        {flagFormCreate ? (
          <PromotionForm />
        ) : (
          <Fragment>
            <div className="casino-content">
              <div className="button-section">
                <span>Crear nueva promoción</span>
                <div
                  className="btn-add"
                  onClick={() => {
                    dispatch(setActivePromotionPopup(null));
                    dispatch(setFlagForm({ flagFormCreate: true }));
                  }}
                >
                  <i className="fa-solid fa-plus"></i>
                </div>
              </div>
            </div>
            <PromotionsTable />
          </Fragment>
        )}
        <MyDialog
          open={openDialog}
          title={t("dialog.confirmationWindow")}
          message="¿Esta seguro de eliminar la promoción?"
          handleAccept={handleAccept}
          handleClose={() => setOpenDialog(false)}
          isLoading={!!loading}
        />
      </div>
    </div>
  );
};

export default PromotionsPopups;
