import { FC } from "react";
import { TableCell } from "@mui/material";
import moment from "moment";
import { State } from "../../../enums/enums";
import { useTranslation } from "react-i18next";
interface Props {
  row: any;
}
export const RowDataTableLegal: FC<Props> = ({ row }) => {
  const { t } = useTranslation();

  return (
    <>
      <TableCell className="word-break">{row.title}</TableCell>
      <TableCell align="center">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                State.ACTIVE === row.state
                  ? "d-flex cellRowActive"
                  : "d-flex cellRowInactive"
              }
            >
              <div className="d-flex col-sm-4 justify-content-center">
                {State.ACTIVE === row.state && <>{t("state.active")}</>}
                {State.INACTIVE === row.state && <>{t("state.inactive")}</>}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell className="word-break">
        {moment(row.createAt).format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
    </>
  );
};
