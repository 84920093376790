import { ICreateBonusPariplay } from "../interfaces/bonus";
import { ICasinoQuery } from "../interfaces/casino";

export const getCasinoActivity = (
  token: string,
  params: Partial<ICasinoQuery>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}casino?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const getPariplayGames = (token: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_BACK_PARIPLAY}getGamesPariplay`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const addFreeRoundsPlayersPariplay = (
  token: string,
  data: ICreateBonusPariplay
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_BACK_PARIPLAY}freeRounds/addPlayers`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const getGamesDataBase = (token: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}casinoGames/getGames`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const getCasinoCategory = (token: string, params?: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}casinoGames/categoryCasinoGames?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const updateCasinoCategory = (
  token: string,
  params: any,
  id: number
) => {
  const myHeaders = new Headers();
  myHeaders.append("api", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Authorization", `bearer ${token}`);
  const formData = new FormData();
  formData.append("name", params.name);
  formData.append("games", JSON.stringify(params.games));
  formData.append("order", params.order);
  formData.append("type", params.type);
  params.operator && formData.append("operator", params.operator.toString());
  params.icon && formData.append("icon", params.icon);
  const requestOptions: any = {
    method: "PUT",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}casinoGames/categoryCasinoGames/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const getGames = (token: string, params: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}casinoGames/getGames?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const createCasinoCategory = (token: string, params: any) => {
  const myHeaders = new Headers();
  myHeaders.append("api", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Authorization", `bearer ${token}`);
  const formData = new FormData();
  formData.append("name", params.name);
  formData.append("games", JSON.stringify(params.games));
  formData.append("order", params.order);
  formData.append("type", params.type);
  params.operator && formData.append("operator", params.operator.toString());
  params.icon && formData.append("icon", params.icon);

  const requestOptions: any = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}casinoGames/categoryCasinoGames`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const deleteCasinoCategory = (id: number, token: string) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}casinoGames/categoryCasinoGames/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const updateGame = (token: string, params: any, id: number) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(params),
  };
  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}casinoGames/updateCasinoGames/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const updateOrderGame = (token: string, params: any) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(params),
  };
  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}casinoGames/updateArrayGames`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
