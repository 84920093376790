import { Types } from "../types/types";
import { IAssociates, IAssociatesData } from "../../interfaces/associate";

interface IAssociatesActions {
  type: Types;
  payload?: IAssociates | IAssociatesData | number | any;
}

interface IAssociatesDataState {
  associates: IAssociates | null;
  activeAssociate: IAssociatesData | null;
}

const initialState: IAssociatesDataState = {
  associates: null,
  activeAssociate: null,
};
export const associatesReducer = (
  state = initialState,
  action: IAssociatesActions
): IAssociatesDataState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setAssociates:
      return {
        ...state,
        associates: payload as IAssociates,
      };

    case Types.addAssociate:
      const newAssociates = state.associates
        ? ({
            ...state.associates,
            data: [payload.associate, ...state.associates.data],
            total: state.associates.total + 1,
            last_page: Math.ceil(
              (state.associates.total + 1) / payload.rowsPerPage
            ),
          } as IAssociates)
        : state.associates;
      return {
        ...state,
        associates: newAssociates,
      };

    case Types.setActiveAssociate:
      return {
        ...state,
        activeAssociate: payload as IAssociatesData,
      };

    case Types.updateAssociate:
      const associatesUpdated = state.associates
        ? ({
            ...state.associates,
            data: state.associates.data.map((row) =>
              row.id === (payload as IAssociatesData).id
                ? (payload as IAssociatesData)
                : row
            ),
          } as IAssociates)
        : state.associates;
      return {
        ...state,
        associates: associatesUpdated,
      };

    default:
      return state;
  }
};
