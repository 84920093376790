import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { headersPlayerUpdateHistory } from "../../../helpers/headersTables";
import { getCopyArrayOrObject } from "../../../utils/utils";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IPlayerUpdateHistoryData } from "../../../interfaces/players";

export const PlayerUpdateHistoryDataTable = (totalData: any[]) => {
  const { playerUpdateHistory: data } = useSelector(
    (state: AppState) => state.managementPlayers
  );
  const { t } = useTranslation();

  const headersCvsStyle = headersPlayerUpdateHistory.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });
  const dataPlayerUpdate =
    data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalPlayerUpdate = totalData
    ? getCopyArrayOrObject(totalData)
    : [];
  const buildDetailUpdate = (row: IPlayerUpdateHistoryData) => {
    const { newPlayerData } = row;

    const { id, ...restData } = newPlayerData;
    return Object.entries(restData)
      .map(([key, value]) => `  ${t(`player.${key}`)} = ${value}`)
      .join(", \n");
  };

  const buildTotalData = (arr: any[]) => {
    const multiData = [
      {
        columns: headersCvsStyle,
        data: arr.map((data) => {
          const array = [
            {
              value: data.player.id,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: buildDetailUpdate(data),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.observation,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.responsible.username,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: moment(data.createAt).format("YYYY-MM-DD HH:mm:ss"),
              style: { alignment: { horizontal: "center" } },
            },
          ];
          return array;
        }),
      },
    ];
    return multiData;
  };

  const multiTotalData = buildTotalData(dataTotalPlayerUpdate);
  const multiData = buildTotalData(dataPlayerUpdate);
  const finalData = {
    dataPerView: dataPlayerUpdate,
    dataTotal: dataTotalPlayerUpdate,
    headersCSV: headersCvsStyle,
    multiDataSet: multiData,
    multiTotalDataSet: multiTotalData,
  };
  return finalData;
};
