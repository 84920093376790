import React, { ChangeEvent } from "react";
import { Grid, Typography, Switch } from "@mui/material";
import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../redux/reducers/rootReducer";
import { setFooter } from "../../../../../../redux/actions/contentManagementActions";
import "./footer.scss";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    transitionDuration: "300ms",
    color: "#707070",
    "&.Mui-checked": {
      transform: "translateX(26px)",
      color: "#04BF9D",
      "& + .MuiSwitch-track": {
        backgroundColor: "#04BF9D",
        opacity: 0.58,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.58,
      },
    },
  },
}));
export const FormCustomFooter = () => {
  const { footer } = useSelector((state: AppState) => state.contentManagement);
  const dispatch = useDispatch();
  const columns = [
    "facebook",
    "instagram",
    "telegram",
    "tiktok",
    "twitter",
    "youtube",
  ];

  const handleChangeSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name },
    } = event;

    let objUpdate: any = { ...footer };
    const key = name;
    objUpdate[key]["show"] = !objUpdate[key]["show"];
    dispatch(setFooter(objUpdate));
  };

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;

    let objUpdate: any = { ...footer };
    const key = name;
    objUpdate[key]["url"] = value;
    dispatch(setFooter(objUpdate));
  };

  const renderSocialMedia = (socialMediaData: any) => {
    return Object.keys(socialMediaData).map((key) => (
      <Grid
        item
        xs={2}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        className="footer-social-media"
        key={key}
        padding={1}
      >
        <CustomSwitch
          onChange={handleChangeSwitch}
          name={key}
          checked={!!socialMediaData[key].show}
        />
        {socialMediaData[key].show && (
          <input
            name={key}
            value={socialMediaData[key].url as string}
            onChange={handleChangeValue}
          />
        )}
      </Grid>
    ));
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        {columns.map((col) => (
          <Grid
            key={col}
            item
            xs={2}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant="body1"
              style={{ fontWeight: "bold" }}
              color={"#333"}
              sx={{
                fontSize: "14px",
              }}
            >
              {col.toUpperCase()}
            </Typography>
          </Grid>
        ))}
        <Grid item xs={12} bgcolor={"#F4F4F4"} marginTop={1}>
          <Grid container spacing={1}>
            {renderSocialMedia(footer)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
