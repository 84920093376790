import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { TableComponent } from "../../../../components/Table/TableComponent";
import { IColumnsTable } from "../../../../interfaces/components";
import { AppState } from "../../../../redux/reducers/rootReducer";

const columns: IColumnsTable[] = [
  { id: 1, header: "table.userId" },
  { id: 2, header: "filters.username" },
  { id: 3, header: "user.rol" },
  { id: 4, header: "user.email" },
  { id: 5, header: "user.createAt" },
  { id: 6, header: "associates.twoStepVerification" },
  { id: 7, header: "form.state" },
];

export const CredentialsTab = () => {
  const { t } = useTranslation();
  const { activeAssociate } = useSelector(
    (state: AppState) => state.associates
  );
  return (
    <TableComponent
      columns={columns}
      data={activeAssociate ? activeAssociate.associatesUsers : []}
      pages={1}
      component={"credentials"}
      totalData={
        activeAssociate
          ? {
              total: activeAssociate.associatesUsers.length,
              last_page: 1,
              page: 1,
            }
          : []
      }
      credentialsTitle={
        t("associates.listAssociatedCredentials").toUpperCase() +
        " " +
        activeAssociate?.name.toUpperCase()
      }
      isSortingTable={false}
      dataFilter={{}}
    />
  );
};
