import React, { FC } from "react";
import { TableCell, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { State } from "../../../enums/enums";

export interface IActiveAction {
  state: State;
  show: boolean;
}
export interface IActionsProps {
  see?: IActiveAction | boolean;
  edit?: boolean;
  delete?: boolean;
  seeMore?: boolean;
  add?: boolean;
  approve?: boolean;
  reject?: boolean;
  attach?: boolean;
}

interface props {
  actions: IActionsProps;
  onSee?(): void;
  onSeeMore?(): void;
  onEdit?(): void;
  onDelete?(item: any): void;
  onAdd?(): void;
  onApprove?(): void;
  onReject?(): void;
  onAttach?(): void;
}

export const ActionsTable: FC<props> = ({
  actions,
  onAdd,
  onDelete,
  onEdit,
  onSee,
  onSeeMore,
  onApprove,
  onReject,
  onAttach,
}) => {
  const { t } = useTranslation();
  return (
    <TableCell align="center">
      <div className="row">
        <div className="col-sm-12">
          <div className="d-flex justify-content-center">
            {(actions.see as IActiveAction)?.show && (
              <Tooltip title={t("button.changeState") as string} arrow>
                <div>
                  <i
                    className={
                      (actions.see as IActiveAction).state === State.ACTIVE
                        ? `fa fa-eye fa-lg mr-2 iconSee`
                        : `fa-regular fa-eye-slash fa-lg mr-2 iconSee`
                    }
                    onClick={onSee}
                  />
                </div>
              </Tooltip>
            )}

            {actions.edit && (
              <Tooltip title={t("button.edit") as string} arrow>
                <div>
                  <i
                    onClick={onEdit}
                    className="fa fa-pencil-square-o fa-lg mr-2 iconEdit"
                  />
                </div>
              </Tooltip>
            )}

            {actions.seeMore && (
              <Tooltip title={t("button.showMoreInfo") as string} arrow>
                <div>
                  <i
                    className="fa fa-search-plus fa-lg mr-2 iconSeeMore"
                    onClick={onSeeMore}
                  />
                </div>
              </Tooltip>
            )}

            {actions.delete && (
              <Tooltip title={t("button.delete") as string} arrow>
                <div>
                  <i
                    className="fa fa-trash fa-lg iconDelete"
                    onClick={onDelete}
                  />
                </div>
              </Tooltip>
            )}

            {actions && actions.add && (
              <Tooltip title={t("button.add") as string} arrow>
                <div>
                  <i
                    className="fa fa-plus-circle fa-lg iconAdd"
                    onClick={onAdd}
                  />
                </div>
              </Tooltip>
            )}

            {actions.approve && (
              <Tooltip title={t("button.approve") as string} arrow>
                <div>
                  <i
                    className="fa fa-circle-check fa-lg mr-2 iconApprove"
                    onClick={onApprove}
                  />
                </div>
              </Tooltip>
            )}

            {actions.reject && (
              <Tooltip title={t("button.reject") as string} arrow>
                <div>
                  <i
                    className="fa fa-circle-xmark fa-lg mr-2 iconDelete"
                    onClick={onReject}
                  />
                </div>
              </Tooltip>
            )}
 
            {actions.attach && (
              <Tooltip title={t("button.attachVoucher") as string} arrow>
                <div>
                  <i
                    className="fa fa-paperclip fa-lg mr-2 iconAttach"
                    onClick={onAttach}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </TableCell>
  );
};
