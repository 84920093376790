import { filterBonus } from "../services/bonus";
import { createRamdomCode } from "../utils/utils";

export const createUniquePragmaticCode = async (
  token: string
): Promise<string> => {
  const ramdomCode = createRamdomCode(8);
  //consultar en la db si ya existe el codigo del bono pragmatic
  const bonusRs = await filterBonus(
    null,
    {
      pragmaticBonusCode: ramdomCode,
    },
    token
  );
  if (bonusRs.total === 0) {
    return ramdomCode;
  }
  return createUniquePragmaticCode(token);
};
