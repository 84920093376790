import { IActivityQuery } from "../../interfaces/activityPlayer";
import { getActivityPlayer } from "../../services/activityPlayer";
import { getFinantialInformation } from "../../services/playersService";
import { AppState } from "../reducers/rootReducer";
import { setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";
import { arraySearch } from "./searchActions";

export const startGetActivityPlayer = (params: Partial<IActivityQuery>) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token, user } = (getState() as AppState).auth;
      const { rowsPerPage } = (getState() as AppState).helper;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      if (!params.perPage) {
        params.perPage = rowsPerPage;
      }
      if (user?.operator?.id) {
        params.operator = user.operator.id;
      }
      let finalValues: any = {};
      const exeptionsTypes = ["number", "boolean"];
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (exeptionsTypes.includes(typeof value) || value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });

      const response = await getActivityPlayer(token, finalValues);

      if (response && !response.error) {
        dispatch(arraySearch(response.rs));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startGetFinantialInformation = (
  params: Partial<IActivityQuery>
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      const exeptionsTypes = ["number", "boolean"];
      let finalValues: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (exeptionsTypes.includes(typeof value) || value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });

      const response = await getFinantialInformation(token, finalValues);

      if (response && !response.error) {
        dispatch(arraySearch(response.rs));
      }
      return response;
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};
