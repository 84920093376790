import React, { FC } from "react";
import TableCell from "@mui/material/TableCell";

import { State } from "../../../enums/enums";
import { useTranslation } from "react-i18next";
interface Props {
  row: any;
}

export const RowDataTableRoles: FC<Props> = ({ row }) => {
  const { rol: rolData } = row;
  const rol = rolData ? rolData : row;

  const { t } = useTranslation();
  return (
    <>
      <TableCell className="word-break">{rol?.id}</TableCell>
      <TableCell align="center">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                State.ACTIVE === rol.state
                  ? "d-flex justify-content-center cellRowActive"
                  : "d-flex justify-content-center cellRowInactive"
              }
            >
              <div className="d-flex col-sm-4 justify-content-center">
                {State.ACTIVE === rol.state && <>{t("state.active")}</>}
                {State.INACTIVE === rol.state && <>{t("state.inactive")}</>}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
    </>
  );
};
