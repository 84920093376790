import { FC } from "react";
import { TableCell } from "@mui/material";
import moment from "moment";

import { useTranslation } from "react-i18next";
import { IFreeTicketsBonus } from "../../../../interfaces/bonus";
import { FreeTicketsType, State } from "../../../../enums/enums";

interface Props {
  row: IFreeTicketsBonus;
}
export const RowFreeTicketsBonus: FC<Props> = ({ row }) => {
  const { t } = useTranslation();

  const getFreeTicketsTypeLabel = (type: FreeTicketsType) => {
    switch (type) {
      case FreeTicketsType.FREE_TICKETS:
        return t("Free Tickets");
      case FreeTicketsType.SUBVENTIONED_FREE_TICKETS:
        return t("Subventioned Free Tickets");
      default:
        return t("Unknown Type");
    }
  };

  return (
    <>
      <TableCell align="center">{row.id}</TableCell>
      <TableCell align="center">{getFreeTicketsTypeLabel(row.type)}</TableCell>

      <TableCell align="center">
        {moment(row.startDate).format("YYYY-MM-DD HH:mm:ss A")}
      </TableCell>
      <TableCell align="center">
        {moment(row.usableUntil).format("YYYY-MM-DD HH:mm:ss A")}
      </TableCell>
      <TableCell align="center">{row.givenAmount}</TableCell>
      <TableCell align="center">{row.player.id}</TableCell>
      <TableCell align="center">
        {row.player.firstName} {row.player.lastName}
      </TableCell>
      <TableCell align="center">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                State.ACTIVE === row.status
                  ? "d-flex justify-content-center cellRowActive"
                  : State.INACTIVE === row.status
                  ? "d-flex justify-content-center cellRowInactive"
                  : "d-flex justify-content-center cellRowRetired"
              }
            >
              <div className="d-flex col-sm-4 justify-content-center">
                {State.ACTIVE === row.status && <>{t("state.active")}</>}
                {State.INACTIVE === row.status && <>{t("state.inactive")}</>}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
    </>
  );
};
