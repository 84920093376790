import { useTranslation } from "react-i18next";

import { Box, CircularProgress, Grid } from "@mui/material";

import { MyToggleButton } from "../../../../components/Custom/MyToggleButton";
import { ICountryStep } from "../../../../interfaces/operationCompany";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { Fragment, useEffect, useState } from "react";
import { ISolutions } from "../../../../redux/interfaces/shared";
import { ICountrySolutionsDb } from "../../../../interfaces/params";
import { getParamSolutionByCountry } from "../../../../services/managementParametersService";
import { State } from "../../../../enums/enums";

interface Props {
  formValues: any;
  countriesSelected: ICountryStep[];
  setSolutions(indexCountry: number, value: any): void;
}

export const FormConfigSolutionsCompany = ({
  countriesSelected,
  setSolutions,
  formValues,
}: Props) => {
  const { t } = useTranslation();
  const { token } = useSelector((state: AppState) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [countrySolutions, setCountrySolutions] = useState<any[]>([]);

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    state: ISolutions,
    indexCountry: number
  ) => {
    setSolutions(indexCountry, state);
  };

  const getParam = async (country: number): Promise<ICountrySolutionsDb[]> => {
    setIsLoading(true);
    if (!token) {
      return [];
    }

    const response = await getParamSolutionByCountry(
      token,
      country,
      formValues.operator
    );
    setIsLoading(false);
    return response.data;
  };
  useEffect(() => {
    const getSolutions = async () => {
      const finalData: any[] = [];
      for (const country of countriesSelected) {
        const response = await getParam(parseInt(country.id));

        const objCountrySolution = {
          id: country.id,
          solutions: response,
        };
        finalData.push(objCountrySolution);
      }

      finalData.forEach((data) => {
        data.solutions = data.solutions.filter(
          (solution: any) => solution.active === State.ACTIVE
        );
        return data;
      });

      setCountrySolutions(finalData);
    };
    getSolutions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* CONFIGURACIÓN SOLUCIONES */}
      <Grid item xs={12} className="mb-2">
        <Box
          display="flex"
          className="pb-1"
          borderBottom={1}
          borderColor="#02D0E7"
        >
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <p className="txt-primary-md" style={{ fontWeight: 500 }}>
            {t("companyManagement.configureSolutions")}
          </p>
        </Box>
      </Grid>

      <Grid container mb={2}>
        {countriesSelected.map((country, indexCountry) => (
          <Grid item xs={4} p={4} key={country.id}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent={indexCountry === 0 ? "flex-end" : "center"}
                alignItems="center"
              >
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <img
                    loading="lazy"
                    style={{
                      height: "50px",
                      width: "50px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                    alt="Country"
                    src={country.imgUrl}
                  />
                  <label className="label-text">{country.name}</label>
                </Grid>
              </Grid>

              {isLoading ? (
                <Box
                  display={"flex"}
                  justifyContent="center"
                  alignItems={"center"}
                  width="100%"
                  mt={3}
                  ml={9}
                >
                  <CircularProgress className={"colorLoading"} />
                </Box>
              ) : (
                <>
                  {countrySolutions.find(
                    (solution) => solution.id === country.id
                  )?.solutions.length === 0 && (
                    <label className="label-text mt-2">
                      {t("parameterization.noParamSolutions")}
                    </label>
                  )}
                </>
              )}

              {countrySolutions
                .find((solution) => solution.id === country.id)
                ?.solutions.map((solution: any) => (
                  <Fragment key={solution.id}>
                    <Grid item xs={12} display="flex" alignItems="center">
                      <Grid item xs={6}>
                        {solution.solutions.name.toUpperCase()}
                      </Grid>
                      <Grid item xs={6}>
                        <MyToggleButton
                          checked={
                            !!country.countriesSolutions?.find(
                              (sol) => sol.name === solution.solutions.name
                            )
                          }
                          handleChange={(e) =>
                            handleToggle(
                              e,
                              {
                                id: solution.solutions.id,
                                name: solution.solutions.name,
                                idCountrySolution: solution.id,
                              },
                              indexCountry
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </Fragment>
                ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
