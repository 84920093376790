import React, { FC } from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Box, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ICarrouselItemsItems {
  id: number;
  component: JSX.Element;
  title: string;
}
interface Props {
  items: ICarrouselItemsItems[];
  startIndex?: number;
  handleCloseDialog?(): void;
}

export const MyCarrousel: FC<Props> = ({
  items,
  startIndex,
  handleCloseDialog,
}) => {
  return (
    <Carousel
      className=""
      animation="slide"
      timeout={250}
      autoPlay={false}
      index={startIndex ? startIndex : 0}
      //indicatorContainerProps={{ className: 'custom-indicator-container' }}
      //IndicatorIcon={<FiberManualRecordIcon sx={{ fontSize: '30px' }} />}
      indicatorIconButtonProps={{
        style: {
          color: "#afafaf",
          border: "none",
          outline: "none",
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          color: "#02D0E7",
        },
      }}
      cycleNavigation={false}
      fullHeightHover={false}
      indicators={false}
      navButtonsProps={{
        style: {
          backgroundColor: "#FFFFFF",
          borderRadius: "50%",
          border: "none",
          outline: "none",

          color: "#007E8C",
          padding: 12,
          fontSize: "30px",
        },
      }}
      navButtonsWrapperProps={{
        style: {},
      }}
      navButtonsAlwaysVisible
    >
      {items.map(({ component, title, id}) => (
        <Paper key={id} style={{ marginLeft: 90, marginRight: 90, padding: 14 }}>
          <Grid item xs={12}>
            <Box
              display="flex"
              className="pb-1 mb-4 ml-4"
              justifyContent={"space-between"}
              borderBottom={1}
              borderColor="#02D0E7"
            >
              <Box display={"flex"} flexDirection={"row"}>
                <i
                  className="fa-solid fa-circle-info"
                  style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
                ></i>
                <p className="title-carrousel" style={{ fontWeight: "bold" }}>
                  {title}
                </p>
              </Box>

              <IconButton
                disableFocusRipple
                disableRipple
                style={{ border: "none", outline: "none" }}
                onClick={handleCloseDialog}
                size="small"
                sx={{
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "transparent",
                  },
                }}
              >
                <CloseIcon
                  sx={{
                    color: "#333333",
                    "&:hover": {
                      color: "#02D0E7 !important",
                    },
                  }}
                />
              </IconButton>
            </Box>
          </Grid>
          {component}
        </Paper>
      ))}
    </Carousel>
  );
};
