import { FC } from "react";
import { View, Text } from "@react-pdf/renderer";
import moment from "moment";

import { stylesPdf } from "../../../views/components/custom/customStyles";
import { headersCommercialNetworksReport } from "../../../helpers/headersTables";
import { capitalizeFirstLetter } from "../../../utils/utils";
import { IPlayerActivityData } from "../../../interfaces/playerActivity";

interface Props {
  page: IPlayerActivityData[];
}

export const CommercialNetworksReportPdf: FC<Props> = ({ page }) => {
  const columns = headersCommercialNetworksReport;
  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, index) => (
          <View key={index} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        {page.map((page: IPlayerActivityData) => {
          return (
            <View key={page.id} style={stylesPdf.row}>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{capitalizeFirstLetter(page.externalUser?.name || "")}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{page.id}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {page.refTransactionId || ""}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{page.transactionType?.name || ""}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{page.transactionStatus?.name || ""}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{page.player.id}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{page.player.numberDocument}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>${page.amount}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{page.meta?.codePoint || ""}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{page.meta?.province || ""}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{page.meta?.canton || ""}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {moment(page.updateAt).format("DD/MM/YYYY HH:mm:ss")}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </>
  );
};
