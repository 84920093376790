import React, { FC } from "react";
import { TableCell } from "@mui/material";
import { State } from "../../../enums/enums";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { IConfigParam } from "../../../interfaces/configParams";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
interface props {
  row: IConfigParam;
}
const RowConfigParams: FC<props> = ({ row }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: AppState) => state.auth);
  const isSuper = user?.operator === undefined;
  return (
    <>
      <TableCell className="word-break">{row.configParam.code}</TableCell>
      <TableCell className="word-break">{`${row.configParam.name}${
        isSuper ? " - " + row.operator.name : ""
      }`}</TableCell>
      <TableCell className="word-break">
        {moment(row.createAt).format("DD/MM/YYYY hh:mm:ss A")}
      </TableCell>
      <TableCell align="center">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                State.ACTIVE === row.state
                  ? "d-flex justify-content-center cellRowActive"
                  : State.INACTIVE === row.state
                  ? "d-flex justify-content-center cellRowInactive"
                  : "d-flex justify-content-center cellRowRetired"
              }
            >
              <div className="d-flex col-sm-4 justify-content-center">
                {State.ACTIVE === row.state && <>{t("state.active")}</>}
                {State.INACTIVE === row.state && <>{t("state.inactive")}</>}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
    </>
  );
};

export default RowConfigParams;
