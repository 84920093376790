import { IDealer } from "../../interfaces/cards";
import { IActionRedux, IPaginationData } from "../../interfaces/shared";
import { Types } from "../types/types";

interface IState {
  dealers: IPaginationData<IDealer> | null;
  activeDealer: IDealer | null;
  dealersPointSale: IDealer[];
}

const initialState: IState = {
  dealers: null,
  activeDealer: null,
  dealersPointSale: [],
};
export const managementDealersReducer = (
  state = initialState,
  action: IActionRedux
): IState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setDealers:
      return {
        ...state,
        dealers: payload,
      };
    case Types.setActiveDealer:
      return {
        ...state,
        activeDealer: payload,
      };
    case Types.setDealersPointSale:
      return {
        ...state,
        dealersPointSale: payload,
      };

    default:
      return state;
  }
};
