import React, { FC } from "react";
// import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel, TableCell, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { arraySearch } from "../../../../../redux/actions/searchActions";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import {
  setActiveLot,
  setIrrigatedDetail,
} from "../../../../../redux/actions/managementCardsActions";
import { IrrigationEntry } from "../../../../../interfaces/cards";
import { Roles_Enum } from "../../../../../enums/enums";
interface props {
  row: IrrigationEntry;
}
export const RowCardsInfo: FC<props> = ({ row }) => {
  const dispatch = useDispatch();
  const { activeLot } = useSelector((state: AppState) => state.managementCards);
  const { user } = useSelector((state: AppState) => state.auth);
  const data = activeLot as IrrigationEntry;

  const isRoleSeller = [Roles_Enum.SELLER, Roles_Enum.POINT_SALE].includes(
    user?.roles.name as any
  );

  const handleChange = (_event: React.ChangeEvent<HTMLInputElement>) => {
    if (data?.lotId === row.lotId) {
      dispatch(setActiveLot(null));
      dispatch(setIrrigatedDetail(null));
    } else {
      dispatch(setActiveLot(row as any));
      dispatch(setIrrigatedDetail(null));
    }
  };
  const buildAvailableRanges = () => {
    const { availableRanges } = row;
    return availableRanges.map((range, index) => (
      <React.Fragment key={index}>
        {`${range.minCardId} - ${range.maxCardId}`}
        {index < availableRanges.length - 1 ? <br /> : null}
      </React.Fragment>
    ));
  };

  const cardsNotAvailable =
    parseInt(row.redeemedCards as string) +
    parseInt(row.inactiveCards as string);

  return (
    <>
      <TableRow key={row.lotId} hover role="checkbox" tabIndex={-1}>
        <TableCell align="center">
          {isRoleSeller ? (
            <>{row.lotId}</>
          ) : (
            <FormControlLabel
              value="activeLot"
              control={
                <Checkbox
                  checked={data?.lotId === row.lotId}
                  onChange={handleChange}
                  size="small"
                />
              }
              label={row.lotId}
            />
          )}
        </TableCell>
        <TableCell align="center">{row.value}</TableCell>
        <TableCell align="center">{buildAvailableRanges()}</TableCell>
        <TableCell align="center">{row.redeemedCards}</TableCell>
        <TableCell align="center">{row.inactiveCards}</TableCell>
        <TableCell align="center">{cardsNotAvailable}</TableCell>
        <TableCell align="center">{row.activeCards}</TableCell>
      </TableRow>
    </>
  );
};
