import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import { ICountryStep } from "../../../../interfaces/operationCompany";

interface Props {
  countriesSelected: ICountryStep[];
}

export const TabCurrencyLanguageConfirmation = ({
  countriesSelected,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      {/* CONFIRMACIÓN MONEDA*/}
      {countriesSelected.map((country) => (
        <Grid container mb={3} ml={4} key={country.id}>
          <Grid item xs={3}>
            {(country.name as string).toUpperCase()}
          </Grid>
          <Grid item xs={9}>
            {country.countriesCurrencies?.map((currency, index) => (
              <label key={`${currency.id}-${index}`}>
                {currency.label} - {currency.currency} &nbsp;
                {country.countriesCurrencies &&
                  country.countriesCurrencies.length - 1 !== index &&
                  "-"}
                &nbsp;
              </label>
            ))}
          </Grid>
        </Grid>
      ))}

      {/* CONFIRMACIÓN IDIOMA */}
      <>
        <Box
          display="flex"
          width={1}
          className="pb-1"
          borderBottom={1}
          borderColor="#02D0E7"
          mb={3}
        >
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <p className="txt-primary-md" style={{ fontWeight: 500 }}>
            {t("companyManagement.languages")}
          </p>
        </Box>

        {countriesSelected.map((country) => (
          <Grid container mb={3} ml={4} key={country.id}>
            <Grid item xs={3}>
              {(country.name as string).toUpperCase()}
            </Grid>
            <Grid item xs={9}>
              {country.countriesLanguages?.map((language, index) => (
                <label key={`${language.id}-${index}`}>
                  {language.name} &nbsp;
                  {country.countriesLanguages &&
                    country.countriesLanguages.length - 1 !== index &&
                    "-"}
                  &nbsp;
                </label>
              ))}
            </Grid>
          </Grid>
        ))}
      </>
    </Grid>
  );
};
