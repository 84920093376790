import { Types } from "../types/types";
import { IMessagesParameters, IMessagesDb } from "../../interfaces/messages";

interface IMessagesActions {
  type: Types;
  payload?: IMessagesParameters | IMessagesDb | null;
}

interface IMessagesState {
  messages: IMessagesParameters | null;
  activeMessage: IMessagesDb | null;
}

const initialState: IMessagesState = {
  messages: null,
  activeMessage: null,
};

export const messagesReducer = (
  state = initialState,
  action: IMessagesActions
): IMessagesState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setMessages:
      return {
        ...state,
        messages: payload as IMessagesParameters,
      };
    case Types.setActiveMessage:
      return {
        ...state,
        activeMessage: payload as IMessagesDb,
      };

    default:
      return state;
  }
};
