import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Tab, Tabs } from "@mui/material";
import credentialsLogo from "../../../../assets/svg/credential-logo.svg";
import credentialsLogoDark from "../../../../assets/svg/credentials-logo-dark.svg";
import { CredentialsTab } from "./CredentialsTab";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const styles = {
  "&.Mui-selected": {
    color: "#007E8C",
    border: "none",
    outline: "none",
  },
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const TabsAssociateInformation = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { t } = useTranslation();

  return (
    <Box mt={2}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          style={{ border: "none", outline: "none" }}
          TabIndicatorProps={{
            style: {
              backgroundColor: " #007E8C",
              fontWeight: "bold",
            },
          }}
        >
          <Tab
            sx={styles}
            label={
              <span>
                {t("associates.credentials")}
                <img
                  width={"17px"}
                  style={{ marginLeft: 5, borderRadius: 0 }}
                  src={value === 1 ? credentialsLogo : credentialsLogoDark}
                  alt="Credentials"
                />
              </span>
            }
            {...a11yProps(0)}
          />
        </Tabs>
      </Box>
      <div className="mt-4">
        <TabPanel value={value} index={0}>
          <CredentialsTab />
        </TabPanel>
      </div>
    </Box>
  );
};
