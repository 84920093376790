import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Box, Tab, Tabs } from "@mui/material";
import credentialsLogo from "../../../../assets/svg/credential-logo.svg";
import credentialsLogoDark from "../../../../assets/svg/credentials-logo-dark.svg";
import commissionalLogo from "../../../../assets/svg/logo-dolar.svg";
import commissionalLogoDark from "../../../../assets/svg/logo-dolar-dark.svg";
import productsLogo from "../../../../assets/svg/products-logo.svg";
import productsLogoDark from "../../../../assets/svg/products-logo-dark.svg";
import { CredentialsPointSaleTab } from "./CredentialsPointSaleTab";
import { CommissionalPointSaleTab } from "./CommissionalPointSaleTab";
import { ProductsPointSaleTab } from "./ProductsPointSaleTab";
import { valueSearched } from "../../../../redux/actions/searchActions";
import { useDispatch } from "react-redux";
import { SellersTab } from "./SellersTab";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const styles = {
  "&.Mui-selected": {
    color: "#007E8C",
    border: "none",
    outline: "none",
  },
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const TabsPointSaleInformation = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(valueSearched(""));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box mt={2}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          style={{ border: "none", outline: "none" }}
          TabIndicatorProps={{
            style: {
              backgroundColor: " #007E8C",
              fontWeight: "bold",
            },
          }}
        >
          <Tab
            sx={styles}
            label={
              <span>
                {t("associates.credentials")}
                <img
                  width={"17px"}
                  style={{ marginLeft: 5, borderRadius: 0 }}
                  src={value === 0 ? credentialsLogoDark : credentialsLogo}
                  alt="Credentials"
                />
              </span>
            }
            {...a11yProps(0)}
          />
          <Tab
            sx={styles}
            label={
              <span>
                {t("pointsOfSale.commissional")}
                <img
                  width={"19px"}
                  style={{ marginLeft: 5, borderRadius: 0 }}
                  src={value === 1 ? commissionalLogoDark : commissionalLogo}
                  alt="Commissional"
                />
              </span>
            }
            {...a11yProps(1)}
          />
          <Tab
            sx={styles}
            label={
              <span>
                {t("associates.products")}
                <img
                  width={"19px"}
                  style={{ marginLeft: 5, borderRadius: 0 }}
                  src={value === 2 ? productsLogoDark : productsLogo}
                  alt="Products"
                />
              </span>
            }
            {...a11yProps(2)}
          />
          <Tab
            sx={styles}
            label={
              <span>
                {t("pointsOfSale.sellers")}
                <i className="fas fa-users ml-1" style={{fontSize:24}}></i>
              </span>
            }
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <div className="mt-4">
        <TabPanel value={value} index={0}>
          <CredentialsPointSaleTab />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <CommissionalPointSaleTab />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <ProductsPointSaleTab />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <SellersTab/>
        </TabPanel>
      </div>
    </Box>
  );
};
