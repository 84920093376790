import React, { FC } from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { ISeller } from "../../../../interfaces/pointsSale";
interface props {
  data: ISeller[];
}
export const TabSellers: FC<props> = ({ data }) => {
  const { sellers } = useSelector((state: AppState) => state.helper);

  const getSellerDetail = (id: number) => {
    const seller = sellers.find((seller) => seller.id === id);

    return seller;
  };
  return (
    <Grid container spacing={2}>
      {data.map((seller, index) => (
        <React.Fragment key={index}>
          <Grid container>
            <Grid item xs={3}>
              <Box className="no-overflow">
                {getSellerDetail(parseInt(`${seller.user}`))?.nameComplete}
                {`, ID ${seller.user}`}
              </Box>
            </Grid>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};
