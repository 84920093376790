import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Filter from "../../../../components/Filter/Filter";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import {
  asyncLoadingFunction,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import { startGetPlayerSessions } from "../../../../redux/actions/managementPlayersActions";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  Alert,
  AlertTitle,
  Grid,
  Stack,
  CircularProgress,
} from "@mui/material";
import MyCustomTable, {
  IColumns,
} from "../../../../components/Custom/Table/MyCustomTable";
import InfoIcon from "@mui/icons-material/Info";
import { RowDataTablePlayerSession } from "./RowDataTablePlayerSession";
import { PlayerSessionDataTable } from "../../../../hooks/useDataTable/reports/PlayerSessionDataTable";
import { playerSessionReport } from "../../../../services/reports";
import { PlayerSessionPdf } from "../../../../components/Pdf/Reports/PlayerSessionPdf";
import { arraySearch } from "../../../../redux/actions/searchActions";
import { Box } from "@material-ui/core";

export const PlayerSessionReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dataFilter, setDataFilter] = useState<any>({});
  const [filter, setFilter] = useState(false);
  const [resetPage, setResetPage] = useState(false);
  const { data } = useSelector((state: AppState) => state.search);
  const { rowsPerPage, loading } = useSelector(
    (state: AppState) => state.helper
  );
  const { token, user } = useSelector((state: AppState) => state.auth);

  const filterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("table.playerId"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("filters.documentNumber"),
      type: Enum_FieldType.TEXT,
    },
  ];

  const columns: IColumns[] = [
    {
      align: "left",
      label: t("playerSessionReport.idSession"),
    },
    {
      align: "left",
      label: t("table.playerId"),
    },
    {
      align: "left",
      label: t("filters.name"),
    },
    {
      align: "left",
      label: t("filters.startDate"),
    },
    {
      align: "left",
      label: t("filters.endDate"),
    },
    {
      align: "left",
      label: t("playerSessionReport.ipAddress"),
    },
    {
      align: "left",
      label: t("playerSessionReport.balanceAtLogin"),
    },
    {
      align: "left",
      label: t("playerSessionReport.balanceAtLogout"),
    },
    {
      align: "left",
      label: t("playerSessionReport.totalBets"),
    },
    {
      align: "left",
      label: t("playerSessionReport.totalWon"),
    },
    {
      align: "left",
      label: t("playerSessionReport.totalDeposits"),
    },
    {
      align: "left",
      label: t("playerSessionReport.totalWithdrawals"),
    },
    {
      align: "left",
      label: t("playerSessionReport.terminationReason"),
    },
  ];

  const handleFilter = (formValues: any) => {
    setResetPage(false);
    const startDate = formValues[t("filters.startDate")];
    const endDate = formValues[t("filters.endDate")];
    const params = {
      startDate,
      endDate,
      playerId: formValues[t("table.playerId")],
      playerDocument: formValues[t("filters.documentNumber")],
      page: 1,
    };

    if (!params.playerId.trim() && !params.playerDocument.trim()) {
      dispatch(setMessageAlert("playerSessionReport.filterByPlayer"));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }

    if (endDate.trim() && !startDate.trim()) {
      dispatch(setMessageAlert("alerts.selectStartDate"));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }

    const errorDate = moment(startDate).isAfter(endDate);
    if (errorDate) {
      dispatch(setMessageAlert("alerts.errorDate"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    } else {
      dispatch(
        asyncLoadingFunction(
          async () => await dispatch(startGetPlayerSessions(params))
        )
      );
    }
    setDataFilter(params);
    setFilter(true);
    setResetPage(true);
  };

  const onCleanFilter = async () => {
    if (filter) {
      setResetPage(false);
      dispatch(arraySearch(null));
      setDataFilter({});
      setResetPage(true);
    }
    setFilter(false);
  };

  const handlePageChange = async (page: number, rows?: number) => {
    setResetPage(false);
    dispatch(
      asyncLoadingFunction(
        async () =>
          await dispatch(
            startGetPlayerSessions({
              ...dataFilter,
              page,
              perPage: rows || rowsPerPage,
            })
          )
      )
    );
  };

  const getPlayerSessionAction = async (props: any) => {
    if (token && user) {
      const params = {
        ...props,
        playerId: dataFilter.playerId ? dataFilter.playerId : "",
        startDate: dataFilter.startDate ? dataFilter.startDate : "",
        endDate: dataFilter.endDate ? dataFilter.endDate : "",
        playerDocument: dataFilter.playerDocument
          ? dataFilter.playerDocument
          : "",
      };
      if (user.operator) {
        params.operatorId = user.operator.id;
      }
      return await playerSessionReport(token, params);
    }
  };

  return (
    <>
      <div
        className="filter"
        style={{ padding: "18px 0px 0px", marginBottom: "18px" }}
      >
        <Filter
          fields={filterFields}
          setDataFilter={setDataFilter}
          handleFilter={handleFilter}
          onClean={onCleanFilter}
        />
      </div>

      {data && data.data.length > 0 ? (
        <div className="filter">
          <Grid item xs={12}>
            <MyCustomTable
              columns={columns}
              data={data.data}
              lastPage={data.last_page}
              totalData={data.total}
              renderBody={(item) => <RowDataTablePlayerSession row={item} />}
              onPageChange={handlePageChange}
              resetCurrentPage={resetPage}
              exportData
              hookBuildTable={PlayerSessionDataTable}
              getAllDataToExport={getPlayerSessionAction}
              pdfComponent={PlayerSessionPdf}
              titleCSV={t("reports.playerSessionReport")}
            />
          </Grid>
        </div>
      ) : (
        loading && (
          <div className="filter">
            <Box
              display={"flex"}
              justifyContent="center"
              alignItems={"center"}
              width="100%"
              mt={3}
            >
              <CircularProgress className={"colorLoading"} />
            </Box>
          </div>
        )
      )}

      {!filter && !data && (
        <Grid item xs={12} pt={3} mt={-2}>
          <Stack
            sx={{ width: "100%" }}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
            spacing={2}
          >
            <Alert
              icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
              sx={{
                borderLeft: `7px solid #2C97DF`,
                backgroundColor: "#EAF5FC",
                color: "#4b4b4b",
              }}
            >
              <div className="d-flex">
                <AlertTitle
                  style={{ marginTop: "5px" }}
                  sx={{ color: "#333333" }}
                >
                  {t("alerts.clickButton")}
                </AlertTitle>
                <AlertTitle
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  sx={{ color: "#333333", fontWeight: "bold" }}
                >
                  {t("playerSessionReport.sessionReport")}
                </AlertTitle>
              </div>
            </Alert>
          </Stack>
        </Grid>
      )}
    </>
  );
};
