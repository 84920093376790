import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import React, { FC, useCallback, useState } from "react";
import { FontAwesomeIcons } from "../../assets/font-awesome/icons";
import { CustomSearch } from "../Dashboard/CustomSearch";
import _ from "lodash";
import "./IconSearch.scss";
import { useTranslation } from "react-i18next";

interface Props {
  onSelectIcon(icon: string): void;
}
const itemsPerPage = 40;
export const IconSearch: FC<Props> = ({ onSelectIcon }) => {
  const { t } = useTranslation();
  const [filteredValues, setFilteredValues] = useState<string[]>([
    ...FontAwesomeIcons,
  ]);
  const [page, setPage] = useState(0);
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, FontAwesomeIcons.length);

  const searchIcons = (query: string) => {
    if (query.length <= 0) {
      return setFilteredValues([...FontAwesomeIcons]);
    }
    const filtered = [
      ...FontAwesomeIcons.filter((item) => item.includes(query)),
    ];
    setFilteredValues(filtered);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(
    _.debounce((q) => searchIcons(q), 300),
    []
  );
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    delayedQuery(e.target.value);
  };
  const handleSelectIcon = (iconName: string) => {
    onSelectIcon(iconName);
  };
  const handleNextPage = (e: any) => {
    e.preventDefault();
    //agregar 10 al currentPage
    setPage(page + 1);
  };
  const handlePrevPage = (e: any) => {
    //restar 10 al currentPage
    e.preventDefault();
    //agregar 10 al currentPage
    if (page >= 0) {
      setPage(page - 1);
    }
  };

  return (
    <section className="picker-container p-2">
      <Grid container spacing={2}>
        <Grid item xs={12} height={60}>
          <CustomSearch onChange={handleInputChange} />
        </Grid>

        {filteredValues.slice(from, to).map((item, index) => (
          <Grid item xs={3} key={item}>
            <div className="item">
              <Tooltip title={item}>
                <IconButton
                  size="small"
                  onClick={() => handleSelectIcon(item)}
                  style={{ border: "none", outline: "none" }}
                >
                  <i className={`${item} picker-icon`}></i>
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Grid container>
            <Button
              disabled={page === 0}
              onClick={handlePrevPage}
              style={{ border: "none", outline: "none" }}
            >
              {t("paginator.prev")}
            </Button>
            <Button
              disabled={to >= filteredValues.length}
              onClick={handleNextPage}
              style={{ border: "none", outline: "none" }}
            >
              {t("paginator.next")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};
