import React, { FC } from "react";
import { Box, IconButton, MenuItem, TextField } from "@mui/material";
import { DOTS, UsePagination } from "../../../hooks/pagination/UsePagination";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useTranslation } from "react-i18next";
import { setRowsPerPage } from "../../../redux/actions/helperActions";

interface props {
  total: number;
  currentPage: number;
  lastPage: number;
  siblingCount: number;
  onPageChange(page: number, rowPerPage?: number): void;
}

export const MyPagination: FC<props> = ({
  total,
  currentPage,
  lastPage,
  siblingCount,
  onPageChange,
}) => {
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const paginationRange: any = UsePagination({
    currentPage,
    siblingCount,
    totalPages: lastPage,
  });
  const minValue = (currentPage - 1) * rowsPerPage + 1;
  const maxValue = currentPage === lastPage ? total : currentPage * rowsPerPage;
  const rows = [9, 18, 27];

  const onNext = async () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = async () => {
    onPageChange(currentPage - 1);
  };
  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const rows = parseInt(event.target.value);
    dispatch(setRowsPerPage(rows));
    onPageChange(1, rows);
  };

  return (
    <div className="d-flex justify-content-center m-0 p-0">
      {total !== 0 && (
        <Box
          mr={2}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <span>{`${minValue} - ${maxValue} ${t("table.of")} ${total}`}</span>
        </Box>
      )}
      <ul className="ulStyle pagination-container pagination-bar mt-3">
        {/* Flecha de navegación primera página */}
        <IconButton
          disabled={currentPage === 1}
          onClick={() => onPageChange(1)}
          size="small"
          sx={{ color: "#000" }}
          style={{ border: "none", outline: "none", fontWeight: 600 }}
        >
          &lt;&lt;
        </IconButton>
        {/* Flecha de navegación izquierda */}
        <IconButton
          onClick={onPrevious}
          sx={{ color: "#000" }}
          style={{ border: "none", outline: "none", fontWeight: 600 }}
          size="small"
          disabled={currentPage === 1}
        >
          &lt;
        </IconButton>
        {paginationRange.map((pageNumber: any, index: number) => {
          // Si el elemento de página es un DOT, represente el carácter unicode DOTS
          if (pageNumber === DOTS) {
            return (
              <li
                className="pagination-item dots"
                key={pageNumber + "-" + index}
              >
                &#8230;
              </li>
            );
          }

          // Render numbers para la página
          return (
            <li
              key={pageNumber}
              className={
                currentPage === pageNumber
                  ? "pagination-item selected"
                  : "pagination-item"
              }
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        {/* Flecha de navegación derecha  */}
        <IconButton
          onClick={onNext}
          sx={{ color: "#000" }}
          style={{ border: "none", outline: "none", fontWeight: 600 }}
          size="small"
          disabled={currentPage === lastPage}
        >
          &gt;
        </IconButton>
        {/* Flecha de navegación ultima página */}
        <IconButton
          onClick={() => onPageChange(lastPage)}
          size="small"
          sx={{ color: "#000" }}
          style={{ border: "none", outline: "none", fontWeight: 600 }}
          disabled={currentPage === lastPage}
        >
          &gt;&gt;
        </IconButton>
      </ul>
      <Box
        ml={2}
        width={"30%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <TextField
          name="rowsPerPage"
          size="small"
          select
          InputLabelProps={{ shrink: false }}
          label={" "}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {rows.map((row) => (
            <MenuItem key={row} value={row}>
              {row}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </div>
  );
};
