import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { headersWithdrawalsBank as originalHeadersWithdrawalsBank } from "../../../helpers/headersTables";
import { AppState } from "../../../redux/reducers/rootReducer";
import { buildFullName, getCopyArrayOrObject } from "../../../utils/utils";
import { IWithdrawalData } from "../../../interfaces/withdrawals";
import {
  DocumentTypes,
  Operators,
  WhiteListOperators,
} from "../../../enums/enums";

export const WithdrawalsBankDataTable = (totalData: IWithdrawalData[]) => {
  const { t } = useTranslation();
  const { withdrawals } = useSelector((state: AppState) => state.transactions);
  const { user } = useSelector((state: AppState) => state.auth);
  const isApproval = totalData && totalData.find((item) => item.approvalDate);
  const headersWithdrawalsBank = [...originalHeadersWithdrawalsBank];

  if (isApproval) {
    headersWithdrawalsBank[headersWithdrawalsBank.length - 1] = {
      label: t("withdrawals.approvedDate").toUpperCase(),
    };
  }

  if (!isApproval && user?.operator?.id === Operators.BETPPROSV) {
    headersWithdrawalsBank.unshift(
      { label: "Amount" },
      { label: "PaymentMethodType" },
      { label: "Email" },
      { label: "First name" },
      { label: "Last name" },
      { label: "Document type" },
      { label: "Document number" },
      { label: "Phone number" },
      { label: "Person type (N/J)" },
      { label: "Expiration days" },
      { label: "Tipo de cuenta  (A/C)" },
      { label: "" },
      { label: "Account" },
      { label: "" },
      { label: "" },
      { label: "Notification Channel" },
      { label: "Notify to" },
      { label: "Concept" }
    );
  }
  //   COLUMNS HEADERS STYLES
  const headersCvsStyle = headersWithdrawalsBank.map((header) => {
    return {
      title: header.label,
      width: { wpx: 150 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  // Obtener datos por vista
  const dataWithdrawals =
    withdrawals && withdrawals.data
      ? getCopyArrayOrObject(withdrawals.data)
      : [];
  // Obtener total datos
  const dataTotalWithdrawals = totalData ? getCopyArrayOrObject(totalData) : [];

  // DATA POR VISTA
  const multiDataSetWithdrawals = [
    {
      columns: headersCvsStyle,
      data: dataWithdrawals.map((data: IWithdrawalData) => {
        const accountNumber = data.playerBankAccounts?.accountNumber ? data.playerBankAccounts.accountNumber : null;
        const accountType = data.playerBankAccounts?.accountType ? data.playerBankAccounts.accountType : null;
        const percentage =
          data.player.operator?.name?.toUpperCase() ===
          WhiteListOperators.BETPPRO
            ? 0
            : 5.5;
        let array;
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.typeDocument.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.numberDocument,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: buildFullName(
              data.player.firstName,
              data.player.secondName,
              data.player.lastName,
              data.player.secondLastName
            ),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.email,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.playerBankAccounts?.bank?.name ? data.playerBankAccounts.bank.name : '',
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: accountNumber ? `${accountNumber} - ${t(
              accountType === 1 ? "associates.savings" : "associates.current"
            )}` : '',
            style: { alignment: { horizontal: "center" } },
          },
          {
            value:
              parseFloat(data.taxValue) === 0
                ? parseFloat(data.taxValue).toFixed(1)
                : percentage.toFixed(1),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: parseFloat(data.taxValue).toFixed(2),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.withdrawalValue,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.paidValue,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: t(data.playerBankAccounts?.verified ? "table.yes" : "table.not"),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.approvalDate || data.createAt).format(
              "DD/MM/YYYY HH:mm:ss"
            ),
            style: { alignment: { horizontal: "center" } },
          },
        ];

        if (!isApproval && user?.operator?.id === Operators.BETPPROSV) {
          array.unshift(
            {
              value: Number(data.withdrawalValue),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.playerBankAccounts?.bank?.paymentMethod || "",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.player.email,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.player.firstName,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.player.lastName,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value:
                data.player.typeDocument.id === DocumentTypes.DUI
                  ? "nationalId"
                  : data.player.typeDocument.id === DocumentTypes.PASSPORT
                    ? "passport"
                    : "",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.player.numberDocument,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "N",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: 24,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.playerBankAccounts
                ? data.playerBankAccounts?.accountType === 1
                  ? "A"
                  : "C"
                : "",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.playerBankAccounts?.accountNumber || "",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "business.operations@inswitch.com",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "email",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "business.operations@inswitch.com",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "Concepto XXX",
              style: { alignment: { horizontal: "center" } },
            }
          );
        }
        return array;
      }),
    },
  ];

  //   DATA TOTAL
  const multiTotalDataSetWithdrawals = [
    {
      columns: headersCvsStyle,
      data: dataTotalWithdrawals.map((data: IWithdrawalData) => {
        const accountNumber = data.playerBankAccounts?.accountNumber ? data.playerBankAccounts.accountNumber : null;
        const accountType = data.playerBankAccounts?.accountType ? data.playerBankAccounts.accountType : null;
        const percentage =
          data.player.operator?.name?.toUpperCase() ===
          WhiteListOperators.BETPPRO
            ? 0
            : 5.5;
        let array;
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.typeDocument.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.numberDocument,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: buildFullName(
              data.player.firstName,
              data.player.secondName,
              data.player.lastName,
              data.player.secondLastName
            ),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.email,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.playerBankAccounts?.bank?.name ? data.playerBankAccounts.bank.name : '',
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: accountNumber? `${accountNumber} - ${t(
              accountType === 1 ? "associates.savings" : "associates.current"
            )}` : '',
            style: { alignment: { horizontal: "center" } },
          },
          {
            value:
              parseFloat(data.taxValue) === 0
                ? parseFloat(data.taxValue).toFixed(1)
                : percentage.toFixed(1),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: parseFloat(data.taxValue).toFixed(2),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.withdrawalValue,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.paidValue,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: t(data.playerBankAccounts?.verified ? "table.yes" : "table.not"),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.approvalDate || data.createAt).format(
              "DD/MM/YYYY HH:mm:ss"
            ),
            style: { alignment: { horizontal: "center" } },
          },
        ];

        if (!isApproval && user?.operator?.id === Operators.BETPPROSV) {
          array.unshift(
            {
              value: Number(data.withdrawalValue),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.playerBankAccounts?.bank?.paymentMethod || "",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.player.email,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.player.firstName,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.player.lastName,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value:
                data.player.typeDocument.id === DocumentTypes.DUI
                  ? "nationalId"
                  : data.player.typeDocument.id === DocumentTypes.PASSPORT
                    ? "passport"
                    : "",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.player.numberDocument,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "N",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: 24,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.playerBankAccounts
                ? data.playerBankAccounts?.accountType === 1
                  ? "A"
                  : "C"
                : "",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.playerBankAccounts?.accountNumber || "",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "business.operations@inswitch.com",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "email",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "business.operations@inswitch.com",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: "Concepto XXX",
              style: { alignment: { horizontal: "center" } },
            }
          );
        }
        return array;
      }),
    },
  ];

  const finalData = {
    dataPerView: dataWithdrawals,
    dataTotal: dataTotalWithdrawals,
    headersCSV: headersWithdrawalsBank,
    multiDataSet: multiDataSetWithdrawals,
    multiTotalDataSet: multiTotalDataSetWithdrawals,
  };
  return finalData;
};
