import { Grid, Box, ThemeProvider } from "@mui/material";
import React from "react";
import { theme } from "../../custom/customStyles";
import { useTranslation } from "react-i18next";
import { MyToggleButton } from "../../../../components/Custom/MyToggleButton";
import { IConfigGames } from "../../../../interfaces/pointsSale";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";

interface Props {
  setGames: any;
  games: IConfigGames[];
}

export const FormConfigGames = ({ setGames, games }: Props) => {
  /**
   *  Soporta la constante para las traducciones
   */
  const { t } = useTranslation();

  const { activePointSale } = useSelector(
    (state: AppState) => state.pointsSale
  );

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    game: IConfigGames
  ) => {
    setGames(game);
  };

  return (
    <div className="container mt-3">
      <ThemeProvider theme={theme}>
        <Grid container spacing={2}>
          <Grid item xs={12} className="mb-4">
            <Box
              display="flex"
              className="pb-1"
              borderBottom={1}
              borderColor="#02D0E7"
            >
              <i
                className="fa-solid fa-circle-info"
                style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
              ></i>
              <p className="txt-primary-md">
                {t(
                  activePointSale
                    ? "pointsOfSale.editGames"
                    : "companyManagement.configureGames"
                )}
              </p>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <label>
              <b>{t("pointsOfSale.sports")}</b>
            </label>
            <MyToggleButton
              checked={!!games.find((game) => game.id === 1)}
              handleChange={(e) => {
                handleToggle(e, {
                  id: 1,
                  name: t("pointsOfSale.sports"),
                });
              }}
            />
          </Grid>
          <Grid
            item
            xs={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <label>
              <b>{t("sidebar.casino")}</b>
            </label>
            <MyToggleButton
              checked={!!games.find((game) => game.id === 2)}
              handleChange={(e) => {
                handleToggle(e, {
                  id: 2,
                  name: t("sidebar.casino"),
                });
              }}
            />
          </Grid>

          <Grid
            item
            xs={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <label>
              <b>{t("pointsOfSale.virtual")}</b>
            </label>
            <MyToggleButton
              checked={!!games.find((game) => game.id === 3)}
              handleChange={(e) => {
                handleToggle(e, {
                  id: 3,
                  name: t("pointsOfSale.virtual"),
                });
              }}
            />
          </Grid>

          <Grid
            item
            xs={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <label>
              <b>{t("pointsOfSale.equestrian")}</b>
            </label>
            <MyToggleButton
              checked={!!games.find((game) => game.id === 4)}
              handleChange={(e) => {
                handleToggle(e, {
                  id: 4,
                  name: t("pointsOfSale.equestrian"),
                });
              }}
            />
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};
