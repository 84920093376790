import { Alert, AlertTitle, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../../../../components/Filter/Filter";
import { TableComponent } from "../../../../components/Table/TableComponent";
import {
  Enum_FieldType,
  IColumnsTable,
  Icons,
  IFilterField,
} from "../../../../interfaces/components";
import { AppState } from "../../../../redux/reducers/rootReducer";
import InfoIcon from "@mui/icons-material/Info";
import { Row } from "reactstrap";
import { useEffect, useState } from "react";
import { setBonusRedeemedRedux } from "../../../../redux/actions/managementPlayersActions";
import { setCloseAlert } from "../../../../redux/actions/helperActions";
import { bonusCampaignType } from "../../../../helpers/bonusType";

export const Bonds = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { player, bonusRedeemed } = useSelector(
    (state: AppState) => state.managementPlayers
  );
  const [dataFilter, setDataFilter] = useState<any>({});
  const { flagCleanFilter } = useSelector((state: AppState) => state.helper);
  const [cleanFilter, setCleanFilter] = useState(false);

  useEffect(() => {
    if (flagCleanFilter) {
      return setCleanFilter(true);
    }
    return setCleanFilter(false);
  }, [flagCleanFilter]);

  /**
   * Datos inputs para filtro
   */
  const FilterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("managementBonds.bondValue"),
      type: Enum_FieldType.NUMBER,
    },
    {
      label: t("managementBonds.bondType"),
      type: Enum_FieldType.SELECT,
      options: [
        ...bonusCampaignType.map((type) => ({
          id: type.value,
          label: type.label,
          value: type.value,
        })),
      ],
    },
  ];

  /**
   * Soporta las Columnas para renderizar en el header de la tabla
   */
  const columns: IColumnsTable[] = [
    { id: 1, header: "managementBonds.bondType" },
    { id: 2, header: "managementBonds.bondName" },
    { id: 3, header: "managementBonds.bondValue" },
    { id: 4, header: "table.creationDate" },
    { id: 5, header: "table.expirationDate" },
    { id: 6, header: "form.state" },
    // { id: 6, header: "table.actions" },
  ];

  /**
   * Soporta los iconos de acciones para renderizar en la tabla
   */
  const iconos: Icons = {
    see: false,
    edit: false,
    delete: false,
    seeMore: false,
    add: false,
  };

  useEffect(() => {
    if (!player) {
      dispatch(setBonusRedeemedRedux(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  useEffect(() => {
    dispatch(setCloseAlert());
    return () => {
      dispatch(setCloseAlert());
      dispatch(setBonusRedeemedRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid">
      <Row
        style={{
          marginTop: "12px",
          pointerEvents: player && player.data.length > 0 ? "all" : "none",
          opacity: player && player.data.length > 0 ? 1 : 0.4,
        }}
      >
        <Filter
          fields={FilterFields}
          component={"bonds"}
          cleanFilter={cleanFilter}
          setDataFilter={setDataFilter}
        />
      </Row>
      {bonusRedeemed && bonusRedeemed.data.length > 0 ? (
        <>
          <TableComponent
            columns={columns}
            data={bonusRedeemed.data}
            pages={bonusRedeemed.last_page || 1}
            component={"bonds"}
            totalData={bonusRedeemed}
            icons={iconos}
            isSortingTable={false}
            dataFilter={dataFilter}
          />
        </>
      ) : (
        <Grid item xs={12} pt={3} mt={-2}>
          <Stack
            sx={{ width: "100%" }}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
            spacing={2}
          >
            <Alert
              icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
              sx={{
                borderLeft: `7px solid #2C97DF`,
                backgroundColor: "#EAF5FC",
                color: "#4b4b4b",
              }}
            >
              <div className="d-flex">
                <AlertTitle
                  style={{ marginTop: "5px" }}
                  sx={{ color: "#333333" }}
                >
                  {t("alerts.clickButton")}
                </AlertTitle>
                <AlertTitle
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  sx={{ color: "#333333", fontWeight: "bold" }}
                >
                  {t("alerts.bonusHistory")}
                </AlertTitle>
              </div>
            </Alert>
          </Stack>
        </Grid>
      )}
    </div>
  );
};
