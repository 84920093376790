import React, { FC } from "react";
import { Card, Typography } from "@mui/material";
import { CardBody } from "reactstrap";
import "./myCard.scss";
export interface ICardProps {
  background: string;
  title: string;
  content: string;
  onClick(): void;
  //horizontal?:boolean,
  sm?: boolean;
  titleStyleProps?: React.CSSProperties;
  contentStyleProps?: React.CSSProperties;
  wrapperStyleProps?: React.CSSProperties;
}
export const MyCard: FC<ICardProps> = ({
  background,
  content,
  title,
  contentStyleProps,
  titleStyleProps,
  wrapperStyleProps,
  sm = false,
  onClick,
}) => {
  return (
    <Card
      className={!sm ? "my-card-container" : "my-card-container-sm"}

      onClick={onClick}
      style={ wrapperStyleProps?{...wrapperStyleProps,backgroundColor: background}: { backgroundColor: background }}
    >
      <CardBody>
        <Typography
          fontSize={{ lg: 20, md: 18, sm: 16, xs: 16 }}
          className={!sm ? "my-card-title" : "my-card-title-sm"}
          style={titleStyleProps ? titleStyleProps : undefined}
        >
          {title}
        </Typography>
        <Typography
          fontSize={{ lg: 30, md:20, sm: 20, xs: 20 }}
          className={!sm ? "my-card-content" : "my-card-content-sm"}
          style={contentStyleProps ? contentStyleProps : undefined}
        >
          {content}
        </Typography>
        {/* <p className={!sm?"my-card-title":"my-card-title-sm"} style={titleStyleProps?titleStyleProps:undefined}>{title}</p>
        <p className={!sm?"my-card-content":"my-card-content-sm"} style={contentStyleProps?contentStyleProps:undefined}>{content}</p> */}
      </CardBody>
    </Card>
  );
};
