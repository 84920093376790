import React, { FC, useState, useEffect } from "react";

import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";

import { MyTextField } from "../custom/MyTextField";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { regDecimal, regNumbers } from "../../../utils/utils";
import {
  PragmaticBonusGameList,
  getPragmaticGames,
} from "../../../services/pragmatic";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";

import { SelectVirtualized } from "../../../components/Custom/SelectVirtualized";
import moment from "moment";
import { CasinoBonusType } from "../../../enums/enums";
import { IGameList } from "../../../interfaces/bonus";

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  inputAdorned: {
    marginRight: "30px",
  },
}));
const styles = {
  "& input.Mui-disabled ": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
  "& .MuiSelect-select.Mui-disabled": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
};

interface props {
  formikRef: any;
  currency: string;
}
export const PragmaticBonusForm: FC<props> = ({ formikRef, currency }) => {
  const { t } = useTranslation();
  const { token } = useSelector((state: AppState) => state.auth);
  const { activeBonus } = useSelector((state: AppState) => state.bonus);
  const { error } = useSelector((state: AppState) => state.ui);
  const [games, setGames] = useState<IGameList[]>([]);
  const [totalBetValues, setTotalBetValues] = useState(0);
  const [maxDate, setMaxDate] = useState<any>();

  // SELECT BONUS TYPE
  const casinoBonusType = [
    {
      label: t("casinoBonusType.registration"),
      value: CasinoBonusType.REGISTRATION,
    },
    { label: t("casinoBonusType.deposit"), value: CasinoBonusType.DEPOSIT },
    { label: t("casinoBonusType.custom"), value: CasinoBonusType.CUSTOM },
  ];

  const classes = useStyles();
  const { values, handleChange, setFieldValue } = formikRef;
  const defaultValues: PragmaticBonusGameList = {
    gameId: "", // ide del juego pragmatic
    betValues: [
      {
        currency: currency, // moneda del operador

        totalBet: "", //valor de la ronda
      },
    ],
  };
  const [formValues, setFormValues] = useState(
    activeBonus && activeBonus.pragmaticBonus
      ? (activeBonus.pragmaticBonus as PragmaticBonusGameList[])
      : [defaultValues]
  );

  useEffect(() => {
    const fetchGames = async () => {
      try {
        if (!token) {
          throw new Error("invalid action");
        }
        const rs: any = await getPragmaticGames(token);
        if (rs && rs.gameList) {
          const finalGameList: IGameList[] = rs.gameList
            .filter((item: any) => item.frbAvailable)
            .map(({ gameID, gameName }: any) => ({
              gameID,
              gameName,
            }));

          setGames(finalGameList);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let totalValues = 0;
    const validValues: any[] = [];

    formValues.forEach((item: PragmaticBonusGameList) => {
      if (item.gameId.trim() && item.betValues[0].totalBet !== "") {
        totalValues += parseFloat(item.betValues[0].totalBet as any);
        validValues.push(item);
      }
    });

    setFieldValue("pragmaticBonus", validValues);
    setTotalBetValues(totalValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const handleSelectGame = (e: any, index: number) => {
    let currentFormValue = { ...formValues[index] };

    currentFormValue["gameId"] = e.target.value;
    currentFormValue.betValues[0]["totalBet"] = values.value;

    let finalFormValues = [...formValues];
    finalFormValues[index] = currentFormValue;

    setFormValues(finalFormValues);
  };

  const handleDeleteGame = (indexDelete: number) => {
    const finalFormValues = formValues.filter(
      (_, index) => index !== indexDelete
    );
    setFormValues(finalFormValues);
  };
  const actualDay = moment().format("YYYY-MM-DD");

  const getMaxDate = (start: any) => {
    const startDate = new Date(start);

    // Sumar 30 días a la fecha actual
    const maxDate = new Date(startDate.setDate(startDate.getDate() + 30));

    // Formatear la fecha en formato yyyy-mm-dd
    const formattedMaxDate = maxDate.toISOString().split("T")[0];

    setMaxDate(formattedMaxDate);
  };

  useEffect(() => {
    const totalBonusValue =
      parseFloat(values.value) * parseInt(values.pragmaticNumberRounds);
    setFieldValue(
      "totalBondsValue",
      totalBonusValue && !isNaN(totalBonusValue) ? totalBonusValue : ""
    );
    formValues.forEach((item: any) => {
      item.betValues[0].totalBet = values.value;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.value, values.pragmaticNumberRounds]);

  return (
    <>
      <Grid item xs={4}>
        <MyTextField
          id="stateTextField"
          name="casinoBonusType"
          variant="outlined"
          size="small"
          fullWidth
          value={values.state}
          onChange={(e: any) =>
            setFieldValue("casinoBonusType", e.target.value)
          }
          role={"combobox"}
          select
          label={`${t("managementBonds.bondType")}*`}
          inputProps={{
            id: "stateInput",
          }}
        >
          {casinoBonusType.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              sx={{
                "&.Mui-focused": {
                  backgroundColor: "red",
                },
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </MyTextField>
      </Grid>
      <Grid item xs={4}>
        <MyTextField
          name="amountBonus"
          variant="outlined"
          size="small"
          fullWidth
          onKeyPress={(e: any) => !regNumbers(e.key) && e.preventDefault()}
          value={values.amountBonus}
          onChange={handleChange}
          label={`${t("managementBonds.amountBonds")}*`}
          inputProps={{
            "data-testid": "amountBonus",
            maxLength: 10,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <MyTextField
          name="pragmaticNumberRounds"
          variant="outlined"
          size="small"
          fullWidth
          onKeyPress={(e: any) => !regNumbers(e.key) && e.preventDefault()}
          value={values.pragmaticNumberRounds || ""}
          onChange={handleChange}
          label={`${t("pragmatic.NumberRounds")}*`}
          inputProps={{
            maxLength: 10,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <MyTextField
          name="value"
          variant="outlined"
          size="small"
          fullWidth
          onKeyPress={(e: any) => {
            if (
              !regDecimal(e.key)
            ) {
              return e.preventDefault();
            }
          }}
          inputProps={{
            "data-testid": "value",
            maxLength: 10,
          }}
          value={values.value || ""}
          onChange={handleChange}
          label={`${t("pragmatic.RoundValue")}*`}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <MyTextField
          name="totalBondsValue"
          variant="outlined"
          size="small"
          fullWidth
          onKeyPress={(e: any) => {
            if (
              !regNumbers(e.key) ||
              (e.target.value.length === 0 && e.key === "0")
            ) {
              return e.preventDefault();
            }
          }}
          inputProps={{
            "data-testid": "totalBonusValue",
            maxLength: 10,
          }}
          value={values.totalBondsValue}
          onChange={handleChange}
          disabled
          label={`${t("managementBonds.bondValue")}*`}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <MyTextField
          variant="outlined"
          size="small"
          type={"date"}
          name={"startDate"}
          label={`${t("filters.startDate")}*`}
          fullWidth
          InputProps={{
            classes: {
              input: classes.root,
            },
            inputProps: {
              min: activeBonus ? undefined : actualDay,
            },
          }}
          inputProps={{
            "data-testid": "startDate",
          }}
          value={values.startDate}
          e
          onChange={(e: any) => {
            handleChange(e);
            getMaxDate(e.target.value);
          }}
          sx={styles}
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={4}>
        <MyTextField
          variant="outlined"
          size="small"
          type={"date"}
          name={"endDate"}
          label={`${t("table.expirationDate")}*`}
          InputProps={{
            classes: {
              input: classes.root,
              adornedEnd: classes.inputAdorned,
            },
            inputProps: { min: values.startDate, max: maxDate },
          }}
          fullWidth
          value={values.endDate}
          inputProps={{
            "data-testid": "endDate",
          }}
          onChange={handleChange}
          sx={styles}
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      {values.pragmaticNumberRounds !== "" ? (
        games.length > 0 ? (
          <>
            <Grid item xs={8} />
            {formValues.map((item, index) => (
              <React.Fragment key={item.gameId + "-" + index}>
                <Grid item xs={7.5}>
                  <SelectVirtualized
                    options={games.map((game) => ({
                      label: game.gameName,
                      value: game.gameID,
                    }))}
                    label={`${t("pragmatic.Game")}*`}
                    name="gameId"
                    onChange={(e: any) => handleSelectGame(e, index)}
                    value={item.gameId || ""}
                  />
                </Grid>
                {!activeBonus && (
                  <Grid item xs={0.5}>
                    <i
                      className={"fa fa-trash iconDelete"}
                      style={{
                        fontSize: 24,
                      }}
                      onClick={() =>
                        //handleDeleteModule(index, module.id)
                        handleDeleteGame(index)
                      }
                    />
                  </Grid>
                )}
              </React.Fragment>
            ))}

            {error && totalBetValues > 0 && (
              <Grid item xs={12}>
                <label className="form-text" style={{ color: "red" }}>
                  {t("pragmatic.roundsHighterBonus")}
                </label>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              mt={2}
              ml={4}
              className="box-add-module"
              onClick={() => setFormValues([...formValues, defaultValues])}
              style={{
                pointerEvents: !error ? "all" : "none",
                opacity: !error ? 1 : 0.4,
              }}
            >
              <Typography fontSize={12} color="black">
                {t("pragmatic.addGame")}
              </Typography>
            </Grid>
          </>
        ) : (
          <Box
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            width="100%"
            mt={3}
          >
            <CircularProgress className={"colorLoading"} />
          </Box>
        )
      ) : (
        <></>
      )}
    </>
  );
};
