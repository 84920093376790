import React from "react";
import { Col, Row } from "reactstrap";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { useTranslation } from "react-i18next";
import { FormManagementStatusUsers } from "../../components/managementStatusUsers/FormManagementStatusUsers";

export const ManagementStatusUsers = () => {
  const { t } = useTranslation();
  return (
    <div className="content w-100 mb-4">
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Row style={{ marginBottom: 5 }}>
            <Col xs={12}>
              <MyBreadCrumbs
                titles={[
                  t("sidebar.transactions"),
                  t("sidebar.ManagementUsers"),
                ]}
              ></MyBreadCrumbs>
            </Col>
          </Row>
          <FormManagementStatusUsers />
        </Col>
      </Row>
    </div>
  );
};
