import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousRoundedIcon from "@mui/icons-material/DangerousRounded";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface Props {
  typeAlert: "success" | "error" | "warning" | "info";
  message: string;
  open: boolean;
  handleClose?: () => void;
}

export const MyAlert = ({ typeAlert, message, open, handleClose }: Props) => {
  const { t } = useTranslation();

  const titleColor =
    typeAlert === "success"
      ? "#2CBF6E"
      : typeAlert === "error"
      ? " #E0606F"
      : typeAlert === "warning"
      ? "#FF9740"
      : "#2C97DF";

  const background =
    typeAlert === "success"
      ? "#EAF5FC"
      : typeAlert === "error"
      ? "#FCEFF0"
      : typeAlert === "warning"
      ? "#FFF5EC"
      : "#EAF5FC";

  const MyIcon =
    typeAlert === "success" ? (
      <CheckCircleIcon sx={{ fontSize: 30, color: titleColor }} />
    ) : typeAlert === "error" ? (
      <DangerousRoundedIcon sx={{ fontSize: 30, color: titleColor }} />
    ) : typeAlert === "warning" ? (
      <ErrorIcon sx={{ fontSize: 30, color: titleColor }} />
    ) : (
      <InfoIcon sx={{ fontSize: 30, color: titleColor }} />
    );

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={open}
        autoHideDuration={typeAlert === "warning" ? 10000 : 5000}
        onClose={handleClose}
        sx={{
          "&:focus": {
            outline: "none !important",
          },
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
      >
        <Alert
          icon={MyIcon}
          onClose={handleClose}
          sx={{
            borderLeft: `7px solid ${titleColor}`,
            backgroundColor: background,
            color: "#4b4b4b",
          }}
          action={
            <IconButton
              disableFocusRipple
              disableRipple
              aria-label="close"
              className="hover-close"
              color="inherit"
              size="small"
              onClick={handleClose}
              sx={{
                "&:focus": {
                  outline: "none !important",
                },
              }}
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          }
        >
          <AlertTitle
            sx={{
              color: titleColor,
              fontWeight: "bold",
              "&:focus": {
                outline: "none !important",
              },
              fontSize: "20px",
            }}
          >
            {typeAlert === "success" && t("alerts.successful")}
            {typeAlert === "error" && t("alerts.error")}
            {typeAlert === "warning" && t("alerts.warning")}
            {typeAlert === "info" && t("alerts.informative")}
          </AlertTitle>
          <span
            style={{
              fontSize: "18px",
            }}
          >
            {t(`${message}`)}
          </span>
        </Alert>
      </Snackbar>
    </Stack>
  );
};
