import React from "react";
import { Grid, Box } from "@mui/material";
import moment from "moment";
import { MyCharts } from "../../../../components/Custom/MyCharts";
import { RankingCardsStatistics } from "../../../../components/Statistics/RankingCardsStatistics";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  card_text_md: {
    color: "#fff",
    fontSize: 20,
  },
  card_text_sm: {
    color: "#fff",
    fontSize: 14,
  },
}));

export const Deposits = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const limit = 150000;

  const fakeListRanking = [
    {
      id: 1,
      name: "Lorem ipsum",
      value: "1000",
    },
    {
      id: 2,
      name: "Lorem ipsum",
      value: "800",
    },
    {
      id: 3,
      name: "Lorem ipsum",
      value: "650",
    },
    {
      id: 4,
      name: "Lorem ipsum",
      value: "500",
    },
  ];
  return (
    <div className="white-container">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyCharts
            variant={{ type: "area" }}
            title=""
            series={[
              {
                name: t("bets.sports"),
                color: "#0099FF",
                data: [
                  { x: moment.utc().valueOf(), y: 18000 },
                  { x: moment.utc().add(2, "days"), y: 8000 },
                  { x: moment.utc().add(4, "days"), y: 22000 },
                  { x: moment.utc().add(8, "days"), y: 14000 },
                ],
              },
              {
                name: t("sidebar.casino"),
                color: "#02C5EC",

                data: [
                  { x: moment.utc().valueOf(), y: 27000 },
                  { x: moment.utc().add(3, "days"), y: 9000 },
                  { x: moment.utc().add(5, "days"), y: 5000 },
                  { x: moment.utc().add(8, "days"), y: 20000 },
                ],
              },
              {
                name: t("bets.casinoLive"),
                color: "#036A76",

                data: [
                  { x: moment.utc().valueOf(), y: 10000 },
                  { x: moment.utc().add(1, "days"), y: 20000 },
                  { x: moment.utc().add(3, "days"), y: 8000 },
                  { x: moment.utc().add(8, "days"), y: 15000 },
                ],
              },
            ]}
            options={{
              xAxis: {
                type: "datetime",
              },
              yAxis: {
                title: {
                  text: "",
                },
                labels: {
                  format: "{value}",
                },
              },
              plotOptions: {
                /*  series: {
                    pointStart: 2014
                }, */
                area: {
                  fillOpacity: 0.5,
                },
              },
              tooltip: {
                formatter: function () {
                  //return  '<b>' + this.series.name +'</b><br/>'+ this.y +'</b><br/>'+moment(this.x).format("YYYY/MM/DD")
                  return (
                    "<b>" +
                    moment(this.x).format("MMMM") +
                    "</b><br/>" +
                    this.y?.toLocaleString("en-US").split(",").join(".")
                  );
                },
                backgroundColor: "#265C8099",
                style: {
                  color: "#FFFFFF",
                },
              },
              legend: {
                align: "right",
                layout: "vertical",
                verticalAlign: "top",
                itemMarginTop: 10,
                itemMarginBottom: 20,
                symbolHeight: 30,
                symbolWidth: 30,
                symbolRadius: 10,
                useHTML: true,

                className: "legend-container",
              },
              responsive: {
                rules: [
                  {
                    condition: {
                      maxWidth: 300,
                    },
                    chartOptions: {
                      legend: {
                        align: "center",
                        verticalAlign: "bottom",
                        layout: "horizontal",
                        symbolHeight: 15,
                        symbolWidth: 15,
                        symbolRadius: 5,
                        itemMarginTop: 5,
                        itemMarginBottom: 5,
                      },
                    },
                  },
                ], //.reverse()
              },
            }}
          />
        </Grid>
        <Grid xs={0.7} />
        <Grid xs={10}>
          <Grid container spacing={2}>
            {[`${t("bets.playersWithMoreDeposits")}`].map((item) => (
              <Grid item xs={12} lg={4}>
                <RankingCardsStatistics
                  key={item}
                  title={item}
                  list={fakeListRanking}
                  cols={[
                    `${t("sidebar.player").toUpperCase()}`,
                    `${t("bets.totalDeposits").toUpperCase()}`,
                  ]}
                />
              </Grid>
            ))}

            <Grid item xs={12} lg={5}>
              <MyCharts
                title={`${t("bets.percentByPayment")} - ${t("bets.deposits")}`}
                series={[
                  {
                    //type: variant.type as any,
                    //name: "aaaa",
                    colorByPoint: true,
                    innerSize: "80%",
                    data: [
                      {
                        name: "PayU",
                        y: 28500,
                        color: "#02D0E7",
                      },
                      {
                        name: "PSE",
                        y: 19845,
                        color: "#FF5757",
                      },
                      {
                        name: "Addi",
                        y: 35248,
                        color: "#036A76",
                      },
                      {
                        name: "other",

                        y: limit - 83593,
                        color: "#D5D5D5",
                      },
                    ],
                  },
                ]}
                getContext={(context) => {
                  const series: any = context.series[0],
                    seriesCenter = series.center,
                    x = seriesCenter[0] + context.plotLeft,
                    y = seriesCenter[1] + context.plotTop,
                    //parseTotal = series.total-limit,
                    //text = "+ " + series.total,
                    fontMetrics = context.renderer.fontMetrics(16);

                  let yData: any[] = series.yData;
                  yData.pop();
                  const total = yData.reduce(
                    (partialSum, a) => partialSum + a,
                    0
                  );
                  const text = `+ ${total}`;

                  if (!(context as any).customTitle) {
                    (context as any).customTitle = (context as any).renderer
                      .text(text, null, null, true)
                      .css({
                        transform: "translate(-50%)",
                        fontSize: "20px",
                      })
                      .add();
                  }

                  (context as any).customTitle.attr({
                    x,
                    y: y + fontMetrics.f / 2,
                  });

                  const chart = context;
                  const legends = chart.legend;

                  const allLegendItems = legends.allItems;
                  allLegendItems[
                    allLegendItems.length - 1
                  ].legendItem.group.hide();
                }}
                options={{
                  legend: {
                    align: "left",
                    layout: "vertical",
                    verticalAlign: "middle",
                    itemMarginTop: 40,
                    //itemMarginBottom: 20,
                    symbolHeight: 40,
                    symbolWidth: 40,
                    symbolRadius: 6,
                    useHTML: true,
                    className: "legend-container-lg",

                    /*  labelFormatter: function () {
                  return (
                    (this as any).name +
                    " - " +
                    (this as any).y.toLocaleString("en-US").split(",").join(".")
                  );
                }, */
                  },
                  title: {
                    style: {
                      fontSize: "14px",
                      fontWeight: "bold",
                    },
                    text: `${t("bets.percentByPayment")} - ${t(
                      "bets.deposits"
                    )}`,
                    useHTML: true,
                    margin: 0,
                  },
                  responsive: {
                    rules: [
                      {
                        condition: {
                          maxWidth: 300,
                        },
                        chartOptions: {
                          legend: {
                            //align: "center",
                            verticalAlign: "bottom",
                            layout: "horizontal",
                            symbolHeight: 15,
                            symbolWidth: 15,
                            symbolRadius: 5,
                            itemMarginTop: 5,
                            itemMarginBottom: 5,
                          },
                          chart: {
                            height: "300px",
                          },
                        },
                      },
                    ], //.reverse()
                  },
                }}
                variant={{ type: "pie" }}
                height="63%"
                customChartStyles={{
                  backgroundColor: "#D5D5D533",
                  borderRadius: 15,
                  margin: [30, 30, 70, 45],

                  //marginTop:70
                  //spacingBottom: 50,
                }}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <Box
                width={"100%"}
                style={{ backgroundColor: "#1EA97CCC" }}
                className="responsive-box-sm"
                display="flex"
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems="center"
                borderRadius={5}
              >
                <span className={classes.card_text_md}>{`ADPU`}</span>
                <span
                  className={classes.card_text_sm}
                >{`(Average Deposit per User) `}</span>
                <span className={`${classes.card_text_md} mt-2`}>{`30`}</span>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
