import { Box, Grid, MenuItem, TextField, ThemeProvider } from "@mui/material";
import { FC, useEffect } from "react";
import { theme } from "../../custom/customStyles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { getPromoters } from "../../../../services/promoters";
import { setPromoters } from "../../../../redux/actions/managementPromotersActions";
interface props {
  dealers: any;
  operator: number;
  selectedPromoter: number | null;
  setSelectedPromoter: any;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const FormConfigPromoter: FC<props> = ({
  operator,
  selectedPromoter,
  setSelectedPromoter,
  dealers,
}) => {
  const { token } = useSelector((state: AppState) => state.auth);
  const { activePointSale } = useSelector(
    (state: AppState) => state.pointsSale
  );
  const { promoters } = useSelector(
    (state: AppState) => state.managementPromoters
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fetchPromoters = async () => {
    if (!token) {
      throw new Error("Invalid action");
    }
    const params = {
      operator: operator,
      dealersId: dealers,
    };

    const rs = await getPromoters(params, token);

    dispatch(setPromoters(rs));
  };

  useEffect(() => {
    fetchPromoters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mt-3">
      <ThemeProvider theme={theme}>
        <Grid item xs={12}>
          <Box
            display="flex"
            className="pb-1"
            borderBottom={1}
            borderColor="#02D0E7"
          >
            <i
              className="fa-solid fa-circle-info"
              style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
            ></i>
            <p className="txt-primary-md">
              {t(
                activePointSale
                  ? "managementPromoters.editPromoter"
                  : "managementPromoters.addPromoter"
              )}
            </p>
          </Box>
        </Grid>
        <Grid container spacing={1} mt={1} ml={3}>
          <Grid item xs={6}>
            <label className="label-text">
              {t("managementPromoters.promoter")}
            </label>
            <TextField
              name="promotor"
              variant="outlined"
              size="small"
              type="text"
              InputLabelProps={{ shrink: false }}
              label={" "}
              fullWidth
              autoComplete="off"
              onChange={(e) => setSelectedPromoter(parseInt(e.target.value))}
              value={selectedPromoter || ""}
              select
              defaultValue={""}
              SelectProps={{
                MenuProps,
              }}
            >
              {promoters?.data?.map((promoter) => (
                <MenuItem key={promoter.id} value={promoter.id}>
                  {promoter.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};
