import { FC } from "react";
import { TableCell } from "@mui/material";
import moment from "moment";
import { BalanceType, TransferConcept } from "../../../../enums/enums";
import { useTranslation } from "react-i18next";
import { Irrigation } from "../../../../interfaces/irrigations";

interface Props {
  row: Irrigation;
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

export const RowDataTableIrrigation: FC<Props> = ({ row }) => {
  const { t } = useTranslation();

  const getWalletName = (wallet: number) => {
    return t(
      wallet === BalanceType.REFILL
        ? "associates.refillBalance"
        : "irrigation.bettingBalance"
    ).toUpperCase();
  };
  return (
    <>
      <TableCell align="center">
        {moment(row.createAt).format("DD/MM/YYYY HH:mm:ss")}
      </TableCell>
      <TableCell align="center">{row.associate.name}</TableCell>
      <TableCell align="center">{getWalletName(row.balanceType)}</TableCell>

      <TableCell align="center">
        {row.transferConcept === TransferConcept.CASH &&
          t("companyManagement.cash")}
        {row.transferConcept === TransferConcept.BANK_TRANSFER &&
          t("irrigation.bankTransfer")}
      </TableCell>
      <TableCell align="center">
        {formatter.format(Number(row.value))}
      </TableCell>
    </>
  );
};
