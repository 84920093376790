import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import { ICountryStep } from "../../../../interfaces/operationCompany";
interface Props {
  formValues: any;
}

export const TabBrandGamesConfirmation = ({ formValues }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      {/* CONFIRMACIÓN MONEDA*/}
      <Grid container mb={3} ml={4} style={{ overflow: "hidden" }}>
        <Grid item xs={3}>
          <label>{t("companyManagement.companyName").toUpperCase()}</label>
          <Box mt={2}>
            <label className="no-overflow">
              {formValues.name.toUpperCase()}
            </label>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <label>
            {t("companyManagement.selectedCountries").toUpperCase()}
          </label>
          <Box mt={2}>
            {formValues.countries?.map(
              (country: ICountryStep, index: number) => (
                <label key={`${country.id}-${index}`} className="no-overflow">
                  {(country.name as string).toUpperCase()} &nbsp;
                  {formValues.countries &&
                    formValues.countries.length - 1 !== index &&
                    "-"}
                  &nbsp;
                </label>
              )
            )}
          </Box>
        </Grid>
      </Grid>

      {/* CONFIRMACIÓN JUEGOS */}
      <>
        <Box
          display="flex"
          width={1}
          className="pb-1"
          borderBottom={1}
          borderColor="#02D0E7"
          mb={3}
        >
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <p className="txt-primary-md" style={{ fontWeight: 500 }}>
            {t("companyManagement.selectedGames")}
          </p>
        </Box>

        {formValues.countries.map((country: ICountryStep) => (
          <Grid container mb={2} ml={4} key={country.id}>
            <Grid item xs={3} className="no-overflow">
              {(country.name as string).toUpperCase()}
            </Grid>
            <Grid item xs={9}>
              {country.countriesGames?.map((game, index) => (
                <label
                  key={`${game.id}-${index}`}
                  style={{ marginRight: "20px" }}
                >
                  {game.name} &nbsp;
                </label>
              ))}
            </Grid>
          </Grid>
        ))}
      </>
    </Grid>
  );
};
