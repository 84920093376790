import { IBodyDealer, IDealersQuery } from "../interfaces/cards";

export const getDealers = (params: Partial<IDealersQuery>, token: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const queryString = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}dealers?&${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const createDealer = (data: IBodyDealer, token: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}dealers`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const updateDealer = (
  id: number,
  data: Partial<IBodyDealer>,
  token: string
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}dealers/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const getDealerById = (id: number, token: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}dealers/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
