import { View, Text, Font } from "@react-pdf/renderer";
import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import {
  bonusCampaignType,
  bonusRedeemedStatus,
} from "../../../helpers/bonusType";
import { IBonusRedeemed } from "../../../interfaces/bonus";

Font.registerHyphenationCallback((word) => {
  const middle = Math.floor(word.length / 2);
  const parts =
    word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];

  return parts;
});

interface Props {
  page: any;
}

export const BondsPdfDocument: FC<Props> = ({ page }) => {
  const { t } = useTranslation();
  const columnsBonusRedeemed = [
    t("managementBonds.bondType").toUpperCase(),
    t("managementBonds.bondName").toUpperCase(),
    t("managementBonds.bondValue").toUpperCase(),
    t("table.creationDate").toUpperCase(),
    t("table.expirationDate").toUpperCase(),
    t("form.state").toUpperCase(),
  ];
  const columns = columnsBonusRedeemed;

  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column) => (
          <View key={column} style={[stylesPdf.pCol, stylesPdf.col]}>
            <Text style={stylesPdf.text}>{column}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        {page.map((page: IBonusRedeemed) => (
          <View key={page.id} style={stylesPdf.row}>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>
                {page.bonus.altenarBonusType
                  ? bonusCampaignType.find(
                      (type) => type.value === page.bonus.altenarBonusType
                    )?.label
                  : "Pragmatic" || ""}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={[stylesPdf.textContent]}>{page.bonus.name}</Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>
                {"$ " + page.bonus.value}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>
                {moment(page.createAt).format("YYYY-MM-DD HH:mm:ss")}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={[stylesPdf.textContent]}>
                {moment(page.bonus.endDate).format("YYYY-MM-DD HH:mm:ss")}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={[stylesPdf.textContent]}>
                {bonusRedeemedStatus
                  .find((type) => type.value === page.state)
                  ?.label.toUpperCase() || ""}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};
