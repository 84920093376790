import { Switch, SwitchProps } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

export const CustomSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 53,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    //margin: 2,
    transitionDuration: "300ms",
    color: "#707070",
    "&.Mui-checked": {
      transform: "translateX(26px)",
      color: "#04BF9D",
      "& + .MuiSwitch-track": {
        backgroundColor: "#04BF9D",
        opacity: 0.58,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.58,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 25,
    height: 25,
  },

  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#707070",
    opacity: 0.58,
  },
}));
