import { FC, useState } from "react";
import { TableCell } from "@mui/material";
import moment from "moment";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveDepositQR,
  startApproveOrDeny,
} from "../../../../redux/actions/depositsPlayersActions";
import { MyDialog } from "../../custom/MyDialog";
import { t } from "i18next";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { asyncLoadingFunction } from "../../../../redux/actions/helperActions";

export const RowDepositQR: FC<{ row: any }> = ({ row }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: AppState) => state.helper);
  const { activeDeposit } = useSelector((state: AppState) => state.deposits);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  });
  const [text, setText] = useState("");
  const [state, setState] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const showModalDialog = (deposit: any, approveOrDeny: boolean) => {
    setText(
      `¿Está seguro de ${
        approveOrDeny ? "aprobar" : "cancelar"
      } la transacción?`
    );
    setState(approveOrDeny);
    setOpenDialog(true);
    dispatch(setActiveDepositQR(deposit));
  };

  const handleAccept = async () => {
    const params = {
      isApproved: state,
    };
    await dispatch(
      asyncLoadingFunction(() =>
        dispatch(startApproveOrDeny(params, Number(activeDeposit.id)))
      )
    );
    handleClose();
  };

  const handleClose = () => {
    dispatch(setActiveDepositQR(null));
    setOpenDialog(false);
    setState(false);
  };
  return (
    <>
      <TableCell align="center">{row.id}</TableCell>
      <TableCell align="center">{`${row.player.firstName} ${row.player.lastName}`}</TableCell>
      <TableCell align="center">{row.player.numberDocument}</TableCell>
      <TableCell align="center">
        {moment(row.createAt).format("YYYY-MM-DD HH:mm:ss A")}
      </TableCell>
      <TableCell align="center">
        {formatter.format(Number(row.amount))}
      </TableCell>
      <TableCell align="center">
        <div
          style={{
            cursor: "pointer",
          }}
        >
          {row.transactionStatus.id === 2 ? (
            <>
              <CheckCircle
                color="success"
                onClick={() => showModalDialog(row, true)}
                style={{
                  margin: "0px 5px",
                }}
              />
              <Cancel
                color="error"
                onClick={() => showModalDialog(row, false)}
                style={{
                  margin: "0px 5px",
                }}
              />
            </>
          ) : (
            row.transactionStatus.name
          )}
        </div>
      </TableCell>
      <MyDialog
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message={text}
        handleAccept={handleAccept}
        handleClose={handleClose}
        isLoading={!!loading}
      />
    </>
  );
};
