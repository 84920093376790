import { IBet, IBetData } from "./../../interfaces/bets";
import { Types } from "../types/types";

interface IBetsState {
  bets: IBet | null;
  totalBets: IBet | null;
  activeBet: IBetData | null;
}

interface IBetsActions {
  type: Types;
  payload?: IBet | IBet[] | IBetData | null;
}
const initialState: IBetsState = {
  bets: null,
  totalBets: null,
  activeBet: null,
};

export const BetsReducer = (
  state = initialState,
  action: IBetsActions
): IBetsState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setBets:
      return {
        ...state,
        bets: payload as IBet | null,
      };

    case Types.setActiveBet:
      return {
        ...state,
        activeBet: payload as IBetData,
      };

    case Types.setTotalBets:
      return {
        ...state,
        totalBets: payload as IBet | null,
      };

    default:
      return state;
  }
};
