import React, { useState } from "react";
import { AssignAssociate } from "./AssignAssociate";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { ILotsList } from "../../../interfaces/cards";
import {
  setActiveLot,
  startFilterLots,
  startUpdateCardDealers,
} from "../../../redux/actions/managementCardsActions";
import {
  setFlagFormEdit,
  setLoadingState,
} from "../../../redux/actions/helperActions";

export const EditCardDealers = () => {
  const { user } = useSelector((state: AppState) => state.auth);
  const { activeLot, lots } = useSelector(
    (state: AppState) => state.managementCards
  );
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);
  const [dealers, setDealers] = useState<any[]>(
    (activeLot as ILotsList).dealer.map((item) => ({
      amountCards: `${item.amountCards}`,
      dealer: `${item.dealer.id}`,
      name: `${item.dealer.name}`,
    }))
  );

  const dispatch = useDispatch();

  const handleUpdateCardDealers = async (data: any) => {
    dispatch(setLoadingState({ loading: true }));
    await dispatch(startUpdateCardDealers((activeLot as any).id, data));
    await dispatch(
      startFilterLots({
        page: lots?.page,
        perPage: rowsPerPage,
      })
    );
    dispatch(setLoadingState({ loading: false }));
    dispatch(
      setFlagFormEdit({
        flagFormEdit: false,
      })
    );
    dispatch(setActiveLot(null));
  };
  return (
    <>
      <AssignAssociate
        dealers={dealers}
        setDealers={setDealers}
        amountCards={`${(activeLot as ILotsList)?.amountCards}`}
        operator={user?.operator?.id ?? null}
        edit
        handleEdit={handleUpdateCardDealers}
      />
    </>
  );
};
