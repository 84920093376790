import { Grid } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { MyCharts } from "../../../../components/Custom/MyCharts";
import { RankingCardsStatistics } from "../../../../components/Statistics/RankingCardsStatistics";

export const Payout = () => {
  const { t } = useTranslation();
  const fakeListRanking = [
    {
      id: 1,
      name: "Lorem ipsum 25.000",
    },
    {
      id: 2,
      name: "Lorem ipsum 25.000",
    },
    {
      id: 3,
      name: "Lorem ipsum 25.000",
    },
    {
      id: 4,
      name: "Lorem ipsum 25.000",
    },
    {
      id: 5,
      name: "Lorem ipsum 25.000",
    },
  ];
  return (
    <div className="white-container">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyCharts
            variant={{ type: "area" }}
            title=""
            series={[
              {
                name: t("bets.sports"),
                color: "#0099FF",
                data: [
                  { x: moment.utc().valueOf(), y: 18000 },
                  { x: moment.utc().add(2, "days"), y: 8000 },
                  { x: moment.utc().add(4, "days"), y: 22000 },
                  { x: moment.utc().add(8, "days"), y: 14000 },
                ],
              },
              {
                name: t("sidebar.casino"),
                color: "#02C5EC",

                data: [
                  { x: moment.utc().valueOf(), y: 27000 },
                  { x: moment.utc().add(3, "days"), y: 9000 },
                  { x: moment.utc().add(5, "days"), y: 5000 },
                  { x: moment.utc().add(8, "days"), y: 20000 },
                ],
              },
              {
                name: t("bets.casinoLive"),
                color: "#036A76",

                data: [
                  { x: moment.utc().valueOf(), y: 10000 },
                  { x: moment.utc().add(1, "days"), y: 20000 },
                  { x: moment.utc().add(3, "days"), y: 8000 },
                  { x: moment.utc().add(8, "days"), y: 15000 },
                ],
              },
            ]}
            options={{
              xAxis: {
                type: "datetime",
              },
              yAxis: {
                title: {
                  text: "",
                },
                labels: {
                  format: "{value}",
                },
              },
              plotOptions: {
                area: {
                  fillOpacity: 0.5,
                },
              },
              tooltip: {
                formatter: function () {
                  return (
                    "<b>" +
                    moment(this.x).format("MMMM") +
                    "</b><br/>" +
                    this.y?.toLocaleString("en-US").split(",").join(".")
                  );
                },
                backgroundColor: "#265C8099",
                style: {
                  color: "#FFFFFF",
                },
              },
              legend: {
                align: "right",
                layout: "vertical",
                verticalAlign: "top",
                itemMarginTop: 10,
                itemMarginBottom: 20,
                symbolHeight: 30,
                symbolWidth: 30,
                symbolRadius: 10,
                useHTML: true,
                className: "legend-container",
              },
              responsive: {
                rules: [
                  {
                    condition: {
                      maxWidth: 300,
                    },
                    chartOptions: {
                      legend: {
                        align: "center",
                        verticalAlign: "bottom",
                        layout: "horizontal",
                        symbolHeight: 15,
                        symbolWidth: 15,
                        symbolRadius: 5,
                        itemMarginTop: 5,
                        itemMarginBottom: 5,
                      },
                      chart: {
                        height: 300,
                      },
                    },
                  },
                ],
              },
            }}
          />
        </Grid>
        <Grid xs={0.3} />
        <Grid item xs={12} lg={5}>
          <Grid container spacing={2}>
            {[
              `${t("bets.topGames")} - ${t("bets.payout")}`,
              `${t("bets.topProviders")} - ${t("bets.payout")}`,
            ].map((item) => (
              <Grid item xs={12} lg={6} key={item}>
                <RankingCardsStatistics title={item} list={fakeListRanking} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6.7}>
          <Grid container spacing={2}>
            {[0, 1, 2].map((item) => (
              <Grid item xs={12} lg={4} key={item}>
                <MyCharts
                  title=""
                  series={[
                    {
                      data: [
                        {
                          name: "PSE",
                          y: 28500,
                          color: "#FF5757",
                          sliced: true,
                        },
                        {
                          name: "Addi",
                          y: 19845,
                          color: "#036A76",
                          sliced: true,
                        },
                        {
                          name: "Lorem",
                          y: 35248,
                          color: "#02D0E7",
                          sliced: true,
                        },
                      ],
                    },
                  ]}
                  options={{
                    chart: {
                      type: "pie",
                      margin: [50, 50, 50, 50],
                      height: "100%",
                    },
                    plotOptions: {
                      pie: {
                        innerSize: "100%",
                        borderWidth: 15,
                        borderColor: undefined,
                        slicedOffset: 0,
                        showInLegend: true,
                        dataLabels: {
                          enabled: false,
                          connectorWidth: 0,
                        },
                      },
                    },

                    legend: {
                      align: "left",
                      layout: "vertical",
                      verticalAlign: "top",
                    },
                  }}
                  variant={{ type: "pie" }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
