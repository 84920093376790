import FileIcon from "../../../assets/svg/reports-icon.svg";
import { useTranslation } from "react-i18next";
import { Card, Row } from "reactstrap";
import { ReportsAccordion } from "./ReportsAccordion";

export const Reports = () => {
  const { t } = useTranslation();
  return (
    <Card className="reports-container" style={{ margin: "0 1px 24px 2px" }}>
      <Row className="title">
        <img src={FileIcon} alt="arrow-down" />

        <p>{t("reports.title")}</p>
      </Row>

      <ReportsAccordion />
    </Card>
  );
};
