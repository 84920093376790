import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PointSaleSelect } from "../../custom/PointSaleSelect";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  setPointSaleWallet,
  startGetPointSaleWallet,
} from "../../../../redux/actions/walletActions";
import { PointSaleDetail } from "./PointSaleDetail";
import { arraySearch } from "../../../../redux/actions/searchActions";
import { DealerTypeEnum } from "../../../../enums/enums";

export const PointSaleInfo = () => {
  const { pointSale: walletPointSale } = useSelector(
    (state: AppState) => state.wallet
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialValues = {
    pointSale: "",
  };
  useEffect(() => {
    dispatch(setPointSaleWallet(null));

    return () => {
      dispatch(setPointSaleWallet(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetDetailsPointSale = async (pointSale: string) => {
    await dispatch(startGetPointSaleWallet(parseInt(pointSale)));
  };
  const resetIrrigationHistory = () => {
    dispatch(setPointSaleWallet(null));
    dispatch(arraySearch(null));
  };

  return (
    <Box className="filter" py={1}>
      <Box display="flex" justifyContent={"space-between"} mb={2}>
        <Box display="flex" alignItems="center">
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <Typography
            color="text.primary"
            style={{ fontSize: "16px", fontWeight: 500 }}
          >
            {t("pointsOfSale.pointSaleInformation")}
          </Typography>
        </Box>
      </Box>
      <Formik
        initialValues={initialValues}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          await handleGetDetailsPointSale(data.pointSale);
          setSubmitting(false);
        }}
      >
        {({ handleChange, isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <PointSaleSelect
                  onChange={(e) => {
                    handleChange(e);
                    resetIrrigationHistory();
                  }}
                  type={DealerTypeEnum.ANONYMOUS}
                />
              </Grid>

              <Grid item xs={4} sx={{ height: "60px" }} mb={2}>
                <Button
                  type="submit"
                  style={{
                    border: "none",
                    outline: "none",
                  }}
                  variant="contained"
                  id="btnSave"
                  disabled={isSubmitting}
                  className="primary-btn ripple"
                >
                  {isSubmitting ? (
                    <CircularProgress size={"1.6rem"} color="inherit" />
                  ) : (
                    <>{t("search.title")}</>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {walletPointSale && <PointSaleDetail />}
    </Box>
  );
};
