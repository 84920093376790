import { IBodyPromoter, IPromotersQuery } from "../interfaces/promoters";

export const getPromoters = (
  params: Partial<IPromotersQuery>,
  token: string,
) => {


  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    }
  };
  let queryString = '';
  if (params) {
    const { dealersId, ...restParams } = params;
    const queryParams: any = { ...restParams };

    if (dealersId && dealersId.length > 0) {
      const dealersIdString = JSON.stringify(dealersId);
      queryParams.dealersId = dealersIdString;
    }

    queryString = new URLSearchParams(queryParams).toString();
  }

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}promoters?&${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const createPromoter = (data: IBodyPromoter, token: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}promoters`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const updatePromoter = (
  id: number,
  data: Partial<IBodyPromoter>,
  token: string
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}promoters/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const getPromoterById = (id: number, token: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}promoters/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
