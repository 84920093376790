import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { State } from "../../../enums/enums";
import { headersMenus } from "../../../helpers/headersTables";
import { IMenuList } from "../../../interfaces/menu";
import { AppState } from "../../../redux/reducers/rootReducer";
import { getCopyArrayOrObject } from "../../../utils/utils";

export const MenusDataTable = () => {
  const { t } = useTranslation();
  let { menus, totalMenus } = useSelector(
    (state: AppState) => state.managementMenus
  );
  const copyMenus = getCopyArrayOrObject(menus);
  const copyTotalMenus = getCopyArrayOrObject(totalMenus);
  const headersCSVMenus = headersMenus;

  //   COLUMNS HEADERS STYLES
  const headersCvsStyle = headersCSVMenus.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  // Obtener datos por vista
  const dataMenus =
    copyMenus && copyMenus.data
      ? copyMenus.data.map((menu: IMenuList) => {
          // @ts-ignore
          menu.state =
            menu.state === State.ACTIVE
              ? t("state.active")
              : t("state.inactive");
          menu.createAt = moment(menu.createAt).format("YYYY-MM-DD");
          return menu;
        })
      : [];

  // Obtener el total de datos
  const dataTotalMenus = copyTotalMenus
    ? copyTotalMenus.data.map((menu: IMenuList) => {
        // @ts-ignore
        menu.state =
          menu.state === State.ACTIVE ? t("state.active") : t("state.inactive");
        menu.createAt = moment(menu.createAt).format("YYYY-MM-DD");
        return menu;
      })
    : [];

  // DATA POR VISTA
  const multiDataSetMenus = [
    {
      columns: headersCvsStyle,
      data: dataMenus.map((data: any) => {
        let array;
        array = array = [
          {
            value: data.url,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.icon,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.createdUser ? data.createdUser.nameComplete : "",
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.state,
            style: {
              fill: {
                patternType: "solid",
                fgColor: {
                  rgb: data.state === t("state.active") ? "c6efce" : "ffc7ce",
                },
              },
              alignment: { horizontal: "center" },
            },
          },
        ];
        return array;
      }),
    },
  ];

  //   DATA TOTAL
  const multiTotalDataSetMenus = [
    {
      columns: headersCvsStyle,
      data: dataTotalMenus.map((data: any) => {
        let array;
        array = [
          {
            value: data.url,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.icon,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.createdUser ? data.createdUser.nameComplete : "",
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.state,
            style: {
              fill: {
                patternType: "solid",
                fgColor: {
                  rgb: data.state === t("state.active") ? "c6efce" : "ffc7ce",
                },
              },
              alignment: { horizontal: "center" },
            },
          },
        ];
        return array;
      }),
    },
  ];

  return {
    dataMenus,
    dataTotalMenus,
    headersCSVMenus,
    multiDataSetMenus,
    multiTotalDataSetMenus,
  };
};
