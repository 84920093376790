import { FC } from "react";
import { TableCell } from "@mui/material";
import moment from "moment";

interface Props {
  row: any;
}
export const RowDataTableMessage: FC<Props> = ({ row }) => {
  return (
    <>
      <TableCell className="word-break">{row.messageName}</TableCell>
      <TableCell className="word-break">{moment(row.createAt).format('MM/DD/YYYY')}</TableCell>
      <TableCell className="word-break">{row.image}</TableCell>
    </>
  );
};
