import {
  addPlayersSettings,
  createUserService,
  editUserService,
  filterUsers,
  getUsers,
} from "../../services/managementUsers";
import { Types } from "../types/types";
import { IUserData, IUserDB } from "../interfaces/users";
import {
  setLoadingState,
  setOpenAlert,
  setMessageAlert,
  setTypeAlert,
} from "./helperActions";
import { AppState } from "../reducers/rootReducer";
import { arraySearch } from "./searchActions";
import { Roles_Enum } from "../../enums/enums";
import { editAssociatesService } from "../../services/associates";
import { getCopyArrayOrObject } from "../../utils/utils";
import { editAssociateRedux } from "./associatesActions";
import {
  createUserDealer,
  deleteUserDealerByUser,
  updateUserDealer,
} from "../../services/userDealer";
import { IDataUserStatus } from "../../interfaces/players";

export const setGetUsers = (token: string, page: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { user } = (getState() as AppState).auth;

    try {
      dispatch(setLoadingState({ loading: true }));
      const response =
        user &&
        (await getUsers(
          token,
          user.id,
          user.roles.name,
          page,
          rowsPerPage,
          user.operator?.id
        ));

      if (response && !response.error) {
        const data: IUserDB = response;
        dispatch(setUsersRedux(data));
      } else {
        if (user) {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetUsers"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};
const linkUserDealer = async (user: number, dealer: number, token: string) => {
  return await createUserDealer(
    {
      user,
      dealer,
    },
    token
  );
};
export const createUser = (data: IUserData, token: string, dealer?: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    const { component, rowsPerPage } = (getState() as AppState).helper;
    const { activeAssociate } = (getState() as AppState).associates;
    const associateCopy = getCopyArrayOrObject(activeAssociate);
    const dataCopy = getCopyArrayOrObject(data);

    const isSuperAdmin = user?.roles.name === Roles_Enum.SUPER_ADMIN;

    if (!user) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.createUserError"));
      return dispatch(setTypeAlert("error"));
    }

    user.operator && (dataCopy.operator = user.operator);

    try {
      const resp1 = await createUserService(dataCopy, token, isSuperAdmin);

      if (resp1.error) {
        return resp1;
      }
      if (resp1 && resp1.raw) {
        const idUser = resp1.raw[0].id;
        dataCopy.id = idUser;
        dataCopy.createAt = resp1.raw[0].createAt;
        if (dealer) {
          await linkUserDealer(idUser, dealer, token);
        }

        if (component === "associates" && activeAssociate) {
          const associateUpdated = {
            associatesForm: {},
            configUser: { user: resp1.raw[0].id },
          };

          const resp2 =
            token &&
            activeAssociate.id &&
            (await editAssociatesService(
              associateUpdated,
              token,
              activeAssociate?.id
            ));

          if (resp2) {
            associateCopy.associatesUsers = [
              ...activeAssociate.associatesUsers,
              { id: resp2[1].raw[0].id, user: dataCopy },
            ];
            dispatch(editAssociateRedux(associateCopy));
            dispatch(addUserRedux(dataCopy, rowsPerPage));
            dispatch(setOpenAlert());
            dispatch(setMessageAlert("alerts.createUserSuccess"));
            dispatch(setTypeAlert("success"));
          } else {
            return resp2;
          }
        } else {
          dispatch(arraySearch(null));
          await dispatch(setGetUsers(token, 1));
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.createUserSuccess"));
          dispatch(setTypeAlert("success"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const editUser = (
  data: IUserData,
  token: string,
  id: number,
  dealer?: {
    id: number | null;
    dealerId: number;
  }
) => {
  return async (dispatch: Function, getState: Function) => {
    const dataCopy = getCopyArrayOrObject(data);

    const response = await editUserService(dataCopy, token, id);
    if (dealer) {
      const { id: idUserDealer, dealerId } = dealer;
      idUserDealer
        ? await updateUserDealer(idUserDealer, { dealer: dealerId }, token)
        : await linkUserDealer(id, dealerId, token);
    } else {
      await deleteUserDealerByUser(id, token);
    }
    if (response.error) {
      return response;
    } else {
      if (response) {
        dispatch(arraySearch(null));
        await dispatch(setGetUsers(token, 1));
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.informationSentSuccess"));
        dispatch(setTypeAlert("success"));
      } else {
        return response;
      }
    }
  };
};

export const setUsersRedux = (user: IUserDB | null) => ({
  type: Types.setUsers,
  payload: user,
});

export const setTotalUsersRedux = (user: IUserDB | null) => ({
  type: Types.setTotalUsers,
  payload: user,
});

export const addUserRedux = (user: IUserData, rowsPerPage: number) => ({
  type: Types.addUser,
  payload: { user, rowsPerPage },
});

export const editUserRedux = (user: IUserData) => ({
  type: Types.updateUser,
  payload: user,
});

export const setActiveUser = (user: IUserData | null) => ({
  type: Types.setActiveUser,
  payload: user,
});
export const startFilteringUsers = (
  formValues: any,
  page?: number,
  cleanFilter?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    if (token) {
      let finalValues: any = {};
      Object.entries(formValues).forEach(([key, value], index) => {
        //@ts-ignore
        if (value && value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });
      dispatch(setLoadingState({ loading: true }));
      const response =
        user &&
        (await filterUsers(
          token,
          user.id,
          user.roles.name,
          finalValues,
          user.operator?.id,
          false,
          page,
          rowsPerPage
        ));
      if (response && !response.error) {
        if (cleanFilter) {
          dispatch(arraySearch(null));
          dispatch(setUsersRedux(response));
        } else {
          dispatch(arraySearch(response));
        }
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorSetUsers"));
        dispatch(setTypeAlert("error"));
      }

      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const editUsernameOrPassword = (
  data: any,
  token: string,
  id: number,
  changeUser: boolean,
  setUserChanged: any
) => {
  return async (dispatch: Function) => {
    const response = await editUserService(data, token, id);
    if (response) {
      setUserChanged(true);
      dispatch(setOpenAlert());
      dispatch(
        setMessageAlert(
          changeUser
            ? "account.userSuccessfullyChanged"
            : "account.passwordSuccessfullyChanged"
        )
      );
      dispatch(setTypeAlert("success"));
    } else {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.editUserError"));
      dispatch(setTypeAlert("error"));
    }
  };
};

export const startCreatePlayersSettings = (settings: IDataUserStatus) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { user, token } = (getState() as AppState).auth;
      if (!token || !user) {
        throw new Error("INVALID ACTION");
      }
      const responsible = user.id;
      settings.settings.responsible = responsible;
      const rs = await addPlayersSettings(token, settings);
      if (rs && !rs.error) {
        dispatch(setMessageAlert("managementUsers.createdSuccessfully"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
      } else {
        let errorMsg = "createdError";
        errorMsg = rs.error.errorMessage;
        dispatch(setMessageAlert(`managementUsers.${errorMsg}`));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};
