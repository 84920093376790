import React, { Fragment, useState } from "react";
import { Row } from "reactstrap";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { IpAddressesForm } from "./IpAddressesForm";
import { setFlagForm } from "../../../redux/actions/helperActions";
import { MyDialog } from "../../components/custom/MyDialog";
import { IpAddressesTable } from "./IpAddressesTable";
import { setActiveIp } from "../../../redux/actions/IpAddressesActions";

export const IpAddresses = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { flagFormCreate, loading } = useSelector(
    (state: AppState) => state.helper
  );
  const [openDialog, setOpenDialog] = useState(false);

  const handleAccept = () => {
    setOpenDialog(false);
  };

  return (
    <div className="content w-100">
      <Row style={{ marginBottom: 10, marginLeft: 0 }}>
        <MyBreadCrumbs
          titles={[t("sidebar.player"), t("player.ipAddresses")]}
        />
      </Row>
      <div className="promotions-container mt-2">
        {flagFormCreate ? (
          <IpAddressesForm />
        ) : (
          <Fragment>
            <div className="casino-content">
              <div className="button-section">
                <span> {t("player.createIpRestriction")}</span>
                <div
                  className="btn-add"
                  onClick={() => {
                    dispatch(setActiveIp(null));
                    dispatch(setFlagForm({ flagFormCreate: true }));
                  }}
                >
                  <i className="fa-solid fa-plus"></i>
                </div>
              </div>
            </div>
            <IpAddressesTable />
          </Fragment>
        )}
        <MyDialog
          open={openDialog}
          title={t("dialog.confirmationWindow")}
          message={t("player.confirmDeleteIp")}
          handleAccept={handleAccept}
          handleClose={() => setOpenDialog(false)}
          isLoading={!!loading}
        />
      </div>
    </div>
  );
};
