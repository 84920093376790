import { useDispatch, useSelector } from "react-redux";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { Roles_Enum } from "../../../enums/enums";
import Tabs from "../Casino/Tabs";
import { AppState } from "../../../redux/reducers/rootReducer";
import { getOperatorByIdService } from "../../../services/managementCompanyService";
import { setActiveCompany } from "../../../redux/actions/managementCompanyActions";
import { useEffect } from "react";
import TableComponentPointSale from "./TableComponentPointSale";
import TableComponentPlayer from "./TableComponentPlayer";
import TableComponentPointSaleManagement from "./TableComponentPointSaleManagement";
import ValuesManagement from "./ValuesManagement";
import "./Affiliations.scss";

export const Affiliations = () => {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state: AppState) => state.auth);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const operatorId = !isSuper && user ? user.operator.id : null;
  const tabs = [
    {
      label: "Afiliaciones puntos de venta",
      content: <TableComponentPointSale />,
    },
    {
      label: "Afiliaciones Front jugador",
      content: <TableComponentPlayer />,
    },
    {
      label: "Gestión puntos de venta",
      content: <TableComponentPointSaleManagement />,
    },
    { label: "Gestión de valores", content: <ValuesManagement /> },
  ];

  const getOperatorById = async () => {
    if (operatorId && token) {
      const operator = await getOperatorByIdService(token, operatorId);
      return dispatch(setActiveCompany(operator));
    }
  };

  useEffect(() => {
    getOperatorById();
    return () => {
      dispatch(setActiveCompany(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content w-100 mb-5">
      <MyBreadCrumbs titles={["Reportes / Afiliaciones"]} />
      <Tabs tabs={tabs} />
    </div>
  );
};
