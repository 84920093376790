import { IGenericData } from "../../interfaces/params";
import { IPaginationData } from "../../interfaces/shared";
import { ITransaction } from "../../interfaces/transactionSettings";
import { Types } from "../types/types";
import { Irrigation } from "../../interfaces/irrigations";
import { IWithdrawalData } from "../../interfaces/withdrawals";

interface ITransactionState {
  settings: IPaginationData<ITransaction> | null;
  movementTypes: IGenericData[];
  settingTypes: IGenericData[];
  wallets: IGenericData[];
  totalSettings: IPaginationData<ITransaction> | null;
  irrigations: IPaginationData<Irrigation> | null;
  totalIrrigations: IPaginationData<Irrigation> | null;
  withdrawals: IPaginationData<IWithdrawalData> | null;
  totalWithdrawals: IPaginationData<IWithdrawalData> | null;
  activeWithdrawal: IWithdrawalData | null;
}

interface ITransactionActions {
  type: Types;
  payload?:
    | IPaginationData<ITransaction>
    | null
    | IGenericData[]
    | IPaginationData<Irrigation>
    | IPaginationData<IWithdrawalData>
    | IWithdrawalData;
}
const initialState: ITransactionState = {
  settings: null,
  movementTypes: [],
  settingTypes: [],
  wallets: [],
  totalSettings: null,
  irrigations: null,
  totalIrrigations: null,
  withdrawals: null,
  totalWithdrawals: null,
  activeWithdrawal: null,
};

export const transactionsReducer = (
  state = initialState,
  action: ITransactionActions
): ITransactionState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setTransactionalSettings:
      return {
        ...state,
        settings: payload as IPaginationData<ITransaction>,
      };
    case Types.setTotalTransactionalSettings:
      return {
        ...state,
        totalSettings: payload as any,
      };
    case Types.setMovementTypes:
      return {
        ...state,
        movementTypes: payload as IGenericData[],
      };
    case Types.setSettingTypes:
      return {
        ...state,
        settingTypes: payload as IGenericData[],
      };
    case Types.setWallets:
      return {
        ...state,
        wallets: payload as IGenericData[],
      };

    case Types.setIrrigations:
      return {
        ...state,
        irrigations: payload as IPaginationData<Irrigation>,
      };

    case Types.setTotalIrrigations: {
      return {
        ...state,
        totalIrrigations: payload as IPaginationData<Irrigation>,
      };
    }

    case Types.setWithdrawals:
      return {
        ...state,
        withdrawals: payload as IPaginationData<IWithdrawalData>,
      };

    case Types.setTotalWithdrawals: {
      return {
        ...state,
        totalWithdrawals: payload as IPaginationData<IWithdrawalData>,
      };
    }

    case Types.setActiveWithdrawal: {
      return {
        ...state,
        activeWithdrawal: payload as IWithdrawalData,
      };
    }

    default:
      return state;
  }
};
