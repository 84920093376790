import React, { FC, useState, useMemo } from "react";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { ISelectOptions } from "../../interfaces/components";
import { removeAccents } from "../../utils/utils";

interface props {
  options: ISelectOptions[];
  value: string;
  label: string;
  onChange: any;
  name?: string;
}
const styles = {
  "& input.Mui-disabled ": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
  "& .MuiSelect-select.Mui-disabled": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const SelectWithSearch: FC<props> = ({
  options,
  value,
  label,
  onChange,
  name,
}) => {
  const [searchText, setSearchText] = useState("");

  const displayedOptions = useMemo(
    () =>
      options.filter((option) => {
        return removeAccents(option.label)
          .toLowerCase()
          .includes(removeAccents(searchText.toLowerCase()));
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchText, options]
  );

  return (
    <FormControl sx={{ width: "100%" }} size="small">
      <InputLabel id={`${label}`}>{`${label}`}</InputLabel>

      <Select
        labelId="search-select-label"
        name={name || label}
        value={typeof value === "number" || value.length > 0 ? value : ""}
        size="small"
        onChange={onChange}
        sx={styles}
        input={<OutlinedInput label={`${label}`} />}
        onClose={() => setSearchText("")}
        MenuProps={{
          autoFocus: false,

          ...MenuProps,
        }}
      >
        <ListSubheader>
          <TextField
            //ref={inputRef}
            size="small"
            name="searchText"
            value={searchText || ""}
            fullWidth
            InputLabelProps={{ shrink: false }}
            label={" "}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            autoFocus
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>

        {displayedOptions.map((option, i) => (
          <MenuItem key={`${option.value}-${i}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
