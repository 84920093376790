import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { State } from "../../../enums/enums";
import { headersManagementCompanies } from "../../../helpers/headersTables";
import { ICompanyData } from "../../../interfaces/operationCompany";
import { AppState } from "../../../redux/reducers/rootReducer";
import { getCopyArrayOrObject } from "../../../utils/utils";

export const ManagementCompaniesDataTable = () => {
  const { t } = useTranslation();
  let { companies, totalCompanies } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  let { data } = useSelector((state: AppState) => state.search);

  const copyCompanies = data
    ? getCopyArrayOrObject(data)
    : getCopyArrayOrObject(companies);
  const copyTotalCompanies = getCopyArrayOrObject(totalCompanies);
  const headersCSVCompanies = headersManagementCompanies;

  //   COLUMNS HEADERS STYLES
  const headersCvsStyle = headersCSVCompanies.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  // Obtener datos por vista
  const dataCompanies =
    copyCompanies && copyCompanies.data
      ? copyCompanies.data.map((company: ICompanyData) => {
          // @ts-ignore
          company.state =
            company.stateOperator === State.ACTIVE
              ? t("state.active")
              : t("state.inactive");
          company.createAt = moment(company.createAt).format("YYYY-MM-DD");
          return company;
        })
      : [];

  // Obtener el total de datos
  const dataTotalCompanies = copyTotalCompanies
    ? copyTotalCompanies.data.map((company: ICompanyData) => {
        // @ts-ignore
        company.state =
          company.stateOperator === State.ACTIVE
            ? t("state.active")
            : t("state.inactive");
        company.createAt = moment(company.createAt).format("YYYY-MM-DD");
        return company;
      })
    : [];

  // DATA POR VISTA
  const multiDataSetCompanies = [
    {
      columns: headersCvsStyle,
      data: dataCompanies.map((data: any) => {
        let array;
        array = array = [
          {
            value: data.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.nit,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.country.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.createAt,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.state,
            style: {
              fill: {
                patternType: "solid",
                fgColor: {
                  rgb: data.state === t("state.active") ? "c6efce" : "ffc7ce",
                },
              },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: data.createdUser ? data.createdUser.nameComplete : "",
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  //   DATA TOTAL
  const multiTotalDataSetCompanies = [
    {
      columns: headersCvsStyle,
      data: dataTotalCompanies.map((data: any) => {
        let array;
        array = [
          {
            value: data.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.nit,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.country.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.createAt,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.state,
            style: {
              fill: {
                patternType: "solid",
                fgColor: {
                  rgb: data.state === t("state.active") ? "c6efce" : "ffc7ce",
                },
              },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: data.createdUser ? data.createdUser.nameComplete : "",
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  return {
    dataCompanies,
    dataTotalCompanies,
    headersCSVCompanies,
    multiDataSetCompanies,
    multiTotalDataSetCompanies,
  };
};
