import moment from "moment";
import { Alert, AlertTitle, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import { Row } from "reactstrap";
import { AppState } from "../../../redux/reducers/rootReducer";

import { Enum_FieldType, IFilterField } from "../../../interfaces/components";
import Filter from "../../../components/Filter/Filter";

import {
  asyncLoadingFunction,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import {
  setBetsRedux,
  setTotalBets,
  startFilterBets,
  startGetBets,
} from "../../../redux/actions/betsActions";
import { MyDialogInformation } from "../../../components/DialogInformation/MyDialogInformation";
import MyCustomTable, {
  IColumns,
} from "../../../components/Custom/Table/MyCustomTable";

import { RowDataTableBettingHistory } from "../../pages/bets/RowDataTableBettingHistory";
import { AnonymousBetsDataTable } from "../../../hooks/useDataTable/bets/betHistory/AnonymousBetsDataTable";
import { BetsAnonymousPdf } from "../../../components/Pdf/bets/BetsAnonymousPdf";
import { getBetsByAssociateSeller } from "../../../services/bets";
import { buildFinalQueryParams } from "../../../utils/utils";

export const TabSellerBettingHistory = () => {
  const { token, user } = useSelector((state: AppState) => state.auth);
  const { data } = useSelector((state: AppState) => state.search);
  const { bets } = useSelector((state: AppState) => state.bets);
  const [dataFilter, setDataFilter] = useState<any>({});
  const [filter, setFilter] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /**
   * Datos inputs para filtro
   */
  const FilterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("bets.ticketNumber"),
      type: Enum_FieldType.TEXT,
    },
  ];

  const columns: IColumns[] = [
    { align: "center", label: t("table.betId") },
    { align: "center", label: t("bets.ticketNumber") },
    { align: "center", label: t("bets.dateBet") },
    { align: "center", label: t("bets.hourBet") },
    { align: "center", label: t("bets.winningAmount") },
    { align: "center", label: t("bets.betAmount") },
    { align: "center", label: t("bets.bettingStatus") },
    { align: "center", label: t("table.actions") },
  ];

  const handleFilter = (formValues: any) => {
    setFilter(true);
    const startDate =
      formValues[t("filters.startDate")].length > 0
        ? formValues[t("filters.startDate")] + "T00:00:00"
        : "";
    const endDate =
      formValues[t("filters.endDate")].length > 0
        ? formValues[t("filters.endDate")] + "T23:59:00"
        : "";
    const params = {
      startDate,
      endDate,
      ticketId: formValues[t("bets.ticketNumber")],
      page: 1,
    };

    const errorDate = moment(startDate).isAfter(endDate);
    if (errorDate) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.errorDate"));
      dispatch(setTypeAlert("error"));
    } else {
      dispatch(
        asyncLoadingFunction(
          async () => await dispatch(startFilterBets(params))
        )
      );
    }
    setDataFilter(params);
  };
  const onCleanFilter = async () => {
    await dispatch(
      asyncLoadingFunction(
        async () => await dispatch(startFilterBets({ page: 1 }, true))
      )
    );
    setFilter(false);
  };

  useEffect(() => {
    return () => {
      dispatch(setBetsRedux(null));
      dispatch(setTotalBets(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePageChange = async (page: number, _rows?: number) => {
    data
      ? await dispatch(
          asyncLoadingFunction(
            async () =>
              await dispatch(
                startFilterBets({ ...dataFilter, page: page.toString() })
              )
          )
        )
      : await dispatch(
          asyncLoadingFunction(async () => await dispatch(startGetBets(page)))
        );
  };
  const totalBets = data ?? bets;

  const getAllData = async () => {
    if (user && token) {
      const params: any = {
        ...dataFilter,
      };

      delete params.perPage;
      const finalParams = buildFinalQueryParams(params);
      return await getBetsByAssociateSeller(token, finalParams);
    }
  };

  return (
    <div className="container-fluid">
      <Row
        style={{
          marginTop: "12px",
        }}
      >
        <Filter
          fields={FilterFields}
          component={"bettingHistory"}
          setDataFilter={setDataFilter}
          handleFilter={handleFilter}
          onClean={onCleanFilter}
        />
      </Row>
      {totalBets && (
        <MyCustomTable
          columns={columns}
          data={totalBets.data}
          lastPage={totalBets.last_page}
          totalData={totalBets.total}
          onPageChange={handlePageChange}
          renderBody={(row) => <RowDataTableBettingHistory row={row} />}
          exportData
          hookBuildTable={AnonymousBetsDataTable}
          getAllDataToExport={getAllData}
          pdfComponent={BetsAnonymousPdf}
          titleCSV={`${t("reports.associateBets")
            .split(" ")
            .join("-")}-${moment().toISOString()}`}
        />
      )}

      {!bets && !filter && !data && (
        <Grid item xs={12} pt={3} mt={-2}>
          <Stack
            sx={{ width: "100%" }}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
            spacing={2}
          >
            <Alert
              icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
              sx={{
                borderLeft: `7px solid #2C97DF`,
                backgroundColor: "#EAF5FC",
                color: "#4b4b4b",
              }}
            >
              <div className="d-flex">
                <AlertTitle
                  style={{ marginTop: "5px" }}
                  sx={{ color: "#333333" }}
                >
                  {t("alerts.clickButton")}
                </AlertTitle>
                <AlertTitle
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  sx={{ color: "#333333", fontWeight: "bold" }}
                >
                  {t("alerts.refillBets")}
                </AlertTitle>
              </div>
            </Alert>
          </Stack>
        </Grid>
      )}

      <MyDialogInformation component={"bets"} title={"bets.betDetail"} />
    </div>
  );
};
