import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Stack, Alert, AlertTitle, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { FilterPlayer } from "../../components/exclusions/FilterPlayer";
import { ExclusionForm } from "../../components/exclusions/ExclusionForm";
import { MyAlert } from "../../components/custom/MyAlert";
import { AppState } from "../../../redux/reducers/rootReducer";
import { setCloseAlert } from "../../../redux/actions/helperActions";
import { IColumnsTable, Icons } from "../../../interfaces/components";
import { TableComponent } from "../../../components/Table/TableComponent";
import { useLocation } from "react-router-dom";
import { setExclusionsRedux } from "../../../redux/actions/managementPlayersActions";

const columns: IColumnsTable[] = [
  { id: 1, header: "table.userId" },
  { id: 2, header: "exclusions.startDateExclusion" },
  { id: 3, header: "exclusions.endDateExclusion" },
  { id: 4, header: "exclusions.exclusionCategory" },
  { id: 5, header: "exclusions.totalExclusion" },
  { id: 6, header: "exclusions.action" },
];

export const ExclusionsByCategory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { openAlert, messageAlert, typeAlert } = useSelector(
    (state: AppState) => state.helper
  );
  const { exclusions } = useSelector(
    (state: AppState) => state.managementPlayers
  );
  const [dataFilter, setDataFilter] = useState<any>({});
  const [errorDate, setErrorDate] = useState<boolean>(false);
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { loading } = useSelector((state: AppState) => state.helper);
  const { pathname } = useLocation();
  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;

  const onCloseAlert = () => {
    dispatch(setCloseAlert());
  };

  const iconos: Icons = {
    see: false,
    edit: false,
    seeMore: false,
    add: false,
    delete: false,
    activate: true,
  };

  useEffect(() => {
    dispatch(setExclusionsRedux(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="filter">
            <FilterPlayer
              setErrorDate={setErrorDate}
              setDataFilter={setDataFilter}
            />
            <ExclusionForm
              errorDate={errorDate}
              setErrorDate={setErrorDate}
              enableCreated={enableCreated}
            />

            {exclusions && exclusions.data.length > 0 && (
              <>
                <Box borderTop={1} borderColor="#02D0E7" mt={2} mb={2}></Box>
                <TableComponent
                  columns={columns}
                  data={exclusions.data}
                  pages={exclusions.last_page}
                  icons={iconos}
                  component={"exclusions"}
                  totalData={exclusions ? exclusions : []}
                  isSortingTable={false}
                  dataFilter={dataFilter}
                />
              </>
            )}
          </div>
        </Col>

        <Col lg={12} md={12} sm={12}>
          {!exclusions && !loading && (
            <Stack
              mt={2}
              sx={{ width: "100%" }}
              style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
              spacing={2}
            >
              <Alert
                icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
                sx={{
                  borderLeft: `7px solid #2C97DF`,
                  backgroundColor: "#EAF5FC",
                  color: "#4b4b4b",
                }}
              >
                <div className="d-flex">
                  <AlertTitle
                    style={{ marginTop: "5px" }}
                    sx={{ color: "#333333" }}
                  >
                    {t("alerts.clickButton")}
                  </AlertTitle>
                  <AlertTitle
                    style={{ marginTop: "5px", marginLeft: "5px" }}
                    sx={{ color: "#333333", fontWeight: "bold" }}
                  >
                    {t("exclusions.seeExclusions")}
                  </AlertTitle>
                </div>
              </Alert>
            </Stack>
          )}
        </Col>
      </Row>

      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />
    </div>
  );
};
