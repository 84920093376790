import React, { FC } from "react";
import "./CustomAlert.scss";

interface Props {
  htmlText: string;
  type: "info";
}

export const CustomAlert: FC<Props> = ({ htmlText, type }) => {
  return (
    <>
      {type === "info" && (
        <div className="custom-alert-container mt-3">
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#2C97DF", marginRight: 9 }}
          ></i>

          <div dangerouslySetInnerHTML={{ __html: htmlText }} />
        </div>
      )}
    </>
  );
};
