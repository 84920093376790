import { INotifications } from "../../interfaces/notifications";
import { Types } from "../types/types";

interface INotificationsActions {
  type: Types;
  payload?: INotifications[] | INotifications;
}

interface INotificationsDataState {
  notifications: INotifications[] | null;
}

const initialState: INotificationsDataState = {
  notifications: null,
};

// Utilidad para verificar si la notificación ya existe
const notificationExists = (
  notifications: INotifications[],
  newNotification: INotifications
): boolean =>
  notifications.some((notification) => notification.id === newNotification.id);

export const notificatiosReducer = (
  state = initialState,
  action: INotificationsActions
): INotificationsDataState => {
  const { type, payload } = action;

  if (!payload) return state;

  const currentNotifications = state.notifications || [];

  switch (type) {
    case Types.addNotifications:
      const newNotification = payload as INotifications;
      if (!notificationExists(currentNotifications, newNotification)) {
        return {
          ...state,
          notifications: [newNotification, ...currentNotifications],
        };
      }
      break;

    case Types.updateNotification:
      const updatedNotification = payload as INotifications;
      return {
        ...state,
        notifications: currentNotifications.map(
          (notification: INotifications) =>
            notification.id === updatedNotification.id
              ? updatedNotification
              : notification
        ),
      };

    case Types.deleteNotification:
      return {
        ...state,
        notifications: currentNotifications.filter(
          (notification) => notification.id !== (payload as INotifications).id
        ),
      };

    case Types.markAsReadNotification:
      const { id } = payload as any;
      return {
        ...state,
        notifications: currentNotifications.map(
          (notification: INotifications) =>
            notification.id === id
              ? { ...notification, read: true }
              : notification
        ),
      };

    case Types.softDeleteNotification:
      const softDeletedNotificationId = payload as INotifications;
      return {
        ...state,
        notifications: currentNotifications.filter(
          (notification: INotifications) =>
            notification.id !== softDeletedNotificationId.id
        ),
      };

    default:
      break;
  }
  return state;
};
