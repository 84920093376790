import { getPayMethodsDB } from "../../services/managementPayMethodsService";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import { setLoadingState } from "./helperActions";

export const getPayMethods = () => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;

    if (!token) {
      console.error("Token no disponible");
      return;
    }

    try {
      dispatch(setLoadingState({ loading: true }));
      const response = await getPayMethodsDB(token);
      if (response && response.length) {
        dispatch({
          type: Types.setPayMethods,
          payload: response,
        });
      } else {
        console.error("No se pudieron obtener los métodos de pago");
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};
