import React, { FC } from "react";
import { TableCell, Link } from "@mui/material";
import moment from "moment";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  setActiveBonus,
  setOpenBonusDetails,
} from "../../../../redux/actions/bonusActions";
import { State } from "../../../../enums/enums";
interface props {
  row: any;
}

export const RowMasiveBonus: FC<props> = ({ row }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleOpenDeliveredDetails = () => {
    dispatch(setActiveBonus(row));
    dispatch(setOpenBonusDetails(true));
  };
  return (
    <>
      <TableCell align="center">{row.id}</TableCell>
      <TableCell align="center">{row.name}</TableCell>

      <TableCell align="center">
        {moment(row.createAt).format("YYYY-MM-DD hh:mm:ss")}
      </TableCell>
      
      <TableCell align="center">
        {`${t("managementBonds.delivered")}( ${row.totalBonusDelivered} ) `}
        <Link
          component={"button"}
          variant="body1"
          onClick={handleOpenDeliveredDetails}
          style={{ border: "none", outline: "none", boxShadow: "none" }}
        >
          {`${t("managementBonds.seeDetails")}`}
        </Link>
      </TableCell>

      <TableCell align="center">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                State.ACTIVE === parseInt(row.state)
                  ? "d-flex justify-content-center cellRowActive"
                  : State.INACTIVE === parseInt(row.state)
                  ? "d-flex justify-content-center cellRowInactive"
                  : "d-flex justify-content-center cellRowRetired"
              }
            >
              <div className="d-flex col-sm-4 justify-content-center">
                {State.ACTIVE === parseInt(row.state) && (
                  <>{t("state.active")}</>
                )}
                {State.INACTIVE === parseInt(row.state) && (
                  <>{t("state.inactive")}</>
                )}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
    </>
  );
};
