import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import MyCustomTable from "../../../../components/Custom/Table/MyCustomTable";
import { useTranslation } from "react-i18next";
import { RowSellersTab } from "./RowSellersTab";

export const SellersTab = () => {
  const { activePointSale } = useSelector(
    (state: AppState) => state.pointsSale
  );
  const { t } = useTranslation();
  return (
    <MyCustomTable
      columns={[
        {
          align: "center",
          label: "ID",
        },
        {
          align: "center",
          label: t("roles.SELLER"),
        },
      ]}
      data={activePointSale?.sellerPointSales ?? []}
      lastPage={1}
      totalData={activePointSale?.sellerPointSales?.length ?? 0}
      onPageChange={() => {}}
      renderBody={(row) => <RowSellersTab row={row} />}
    />
  );
};
