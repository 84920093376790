import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { headersGamingActivityAviatrix } from "../../../helpers/headersTables";
import { getCopyArrayOrObject } from "../../../utils/utils";
import moment from "moment";
import { IAviatrixCasino } from "../../../interfaces/casino";
import { useTranslation } from "react-i18next";

export const GamingActivityAviatrixDataTable = (totalData: IAviatrixCasino[]) => {
  const { t } = useTranslation();
  const { data } = useSelector((state: AppState) => state.search);

  const headersCvsStyle = headersGamingActivityAviatrix.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });
  // Obtener datos por vista
  const dataGamingActivityAviatrix =
    data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalaGamingActivityAviatrix = totalData
    ? getCopyArrayOrObject(totalData)
    : [];

  const buildTotalData = (arr: IAviatrixCasino[]) => {
    const multiData = [
      {
        columns: headersCvsStyle,
        data: arr.map((data: IAviatrixCasino) => {
          const status: { [key: string]: string } = {
            won: t("betResults.won"),
            lost: t("betResults.loss"),
            canceled: t("betResults.canceled"),
            done: t("betResults.done"),
          };
          const array = [
            {
              value: moment(data.createAt).format("YYYY-MM-DD HH:mm:ss"),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.id,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.amountBet
                ? "$ " + parseFloat(data.amountBet.toString()).toFixed(2)
                : "0.00",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.awardsBet
                ? "$ " + parseFloat(data.awardsBet.toString()).toFixed(2)
                : "0.00",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.roundId,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: status[`${data.status}`].toUpperCase(),
              style: { alignment: { horizontal: "center" } },
            },
          ];
          return array;
        }),
      },
    ];
    return multiData;
  };

  const multiTotalData = buildTotalData(dataTotalaGamingActivityAviatrix);
  const multiData = buildTotalData(dataGamingActivityAviatrix);
  const finalData = {
    dataPerView: dataGamingActivityAviatrix,
    dataTotal: dataTotalaGamingActivityAviatrix,
    headersCSV: headersGamingActivityAviatrix,
    multiDataSet: multiData,
    multiTotalDataSet: multiTotalData,
  };
  return finalData;
};
