import { View, Text, Font } from "@react-pdf/renderer";
import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import { BalanceType, TransferConcept } from "../../../enums/enums";
import { Irrigation } from "../../../interfaces/irrigations";

Font.registerHyphenationCallback((word) => {
  const middle = Math.floor(word.length / 2);
  const parts =
    word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];

  return parts;
});

interface Props {
  page: any;
  perView?: boolean;
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

export const IrrigationPdfDocument: FC<Props> = ({ page, perView }) => {
  const { t } = useTranslation();
  const columnsIrrigation = [
    t("irrigation.associatedID").toUpperCase(),
    t("user.createAt").toUpperCase(),
    t("irrigation.associatesName").toUpperCase(),
    t("transactions.wallet").toUpperCase(),
    t("irrigation.transactionConcept").toUpperCase(),
    t("irrigation.transferValue").toUpperCase(),
  ];
  const columns = columnsIrrigation;
  const getWalletName = (wallet: number) => {
    return t(
      wallet === BalanceType.REFILL
        ? "associates.refillBalance"
        : "irrigation.bettingBalance"
    ).toUpperCase();
  };

  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, index) => (
          <View key={column} style={[stylesPdf.pCol, stylesPdf.col]}>
            <Text style={stylesPdf.text}>{column}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        {page.map((page: Irrigation) => (
          <View key={page.id} style={stylesPdf.row}>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>{page.associate.id}</Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={stylesPdf.textContent}>
                {perView
                  ? moment(page.createAt).format("DD/MM/YYYY HH:mm:ss")
                  : page.createAt}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={[stylesPdf.textContent]}>{page.associate.name}</Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={[stylesPdf.textContent]}>
                {getWalletName(page.balanceType)}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={[stylesPdf.textContent]}>
                {page.transferConcept === TransferConcept.CASH &&
                  t("companyManagement.cash")}
                {page.transferConcept === TransferConcept.BANK_TRANSFER &&
                  t("irrigation.bankTransfer")}
              </Text>
            </View>
            <View style={[stylesPdf.pCol, stylesPdf.col]}>
              <Text style={[stylesPdf.textContent, { textAlign: "right" }]}>
                {formatter.format(Number(page.value))}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};
