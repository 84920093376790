import { View, Text } from "@react-pdf/renderer";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { stylesPdf } from "../../../views/components/custom/customStyles";

interface Props {
  page: any;
}

export const GrossIncomeReportsDocument: FC<Props> = ({ page }) => {
  const { t } = useTranslation();
  const columnsMenus = [
    t("awardsReports.totalAmountBets").toUpperCase(),
    t("awardsReports.totalAmountDeposits").toUpperCase(),
    t("awardsReports.totalFundsAvailable").toUpperCase()
  ];
  const columns = columnsMenus;
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return (
    <>
      <View
        style={[
          stylesPdf.row,
          {
            backgroundColor: "#02D0E7",
          },
        ]}
      >
        {columns.map((column, index) => (
          <View key={column} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        <View style={[stylesPdf.row]}>
          <View style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.textContent}>
              {currencyFormatter.format(page[0].totalAmountBets)}
            </Text>
          </View>
          <View style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.textContent}>
              {currencyFormatter.format(page[0].totalAmountDeposits)}
            </Text>
          </View>
          <View style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.textContent}>
              {currencyFormatter.format(page[0].totalFundsAvailable)}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};
