import { CurrentPlaceCards, Roles_Enum } from "../enums/enums";

export const CurrentPlace = (rol: Roles_Enum) => {
  switch (rol) {
    case Roles_Enum.ADMIN_OPERATOR:
      return CurrentPlaceCards.ASSOCIATE;
    case Roles_Enum.ASSOCIATE:
      return CurrentPlaceCards.DEALER;
    case Roles_Enum.DEALER:
      return CurrentPlaceCards.POINT_SALE;
    case Roles_Enum.PROMOTOR:
      return CurrentPlaceCards.POINT_SALE;
    default:
      return CurrentPlaceCards.ADMIN;
  }
};

export const CurrentPlaceInventoryCards = (rol: Roles_Enum) => {
  switch (rol) {
    case Roles_Enum.ADMIN_OPERATOR:
      return CurrentPlaceCards.ADMIN;
    case Roles_Enum.ASSOCIATE:
      return CurrentPlaceCards.ASSOCIATE;
    case Roles_Enum.DEALER:
      return CurrentPlaceCards.DEALER;
    case Roles_Enum.SELLER || Roles_Enum.POINT_SALE:
      return CurrentPlaceCards.POINT_SALE;
    case Roles_Enum.PROMOTOR:
      return CurrentPlaceCards.PROTOMOR;
    default:
      return CurrentPlaceCards.ADMIN;
  }
};
