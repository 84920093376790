import { IFormMessages } from "../interfaces/messages";

export const getMessages = (
  token: string,
  operatorId: number | null,
  params?: any
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  params.operatorId = operatorId;
  const queryString = params ? new URLSearchParams(params).toString() : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}messages?${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const createMessage = (data: IFormMessages, token: string) => {
  const myHeaders = new Headers();
  myHeaders.append("api", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Authorization", `bearer ${token}`);
  const formData = new FormData();
  formData.append("messageName", data.messageName);
  formData.append("messageBody", data.messageBody);
  formData.append("buttonText", data.buttonText);
  formData.append("buttonUrl", data.buttonUrl);
  data.operator && formData.append("operator", data.operator.toString());
  data.file && formData.append("file", data.file);

  const requestOptions: any = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}messages`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const deleteMessage = (id: number, token: string) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}messages/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const updateMessage = (data: IFormMessages, token: string) => {
  const myHeaders = new Headers();
  myHeaders.append("api", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Authorization", `bearer ${token}`);
  const formData = new FormData();
  formData.append("messageName", data.messageName);
  formData.append("messageBody", data.messageBody);
  formData.append("buttonText", data.buttonText);
  formData.append("buttonUrl", data.buttonUrl);
  data.operator && formData.append("operator", data.operator.toString());
  data.file && formData.append("file", data.file);

  const requestOptions: any = {
    method: "PUT",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}messages/${data.id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const resendMessage = (data: Partial<IFormMessages>, token: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}messages/resend`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
}