import { Grid, Box } from "@mui/material";
import { RankingCardsStatistics } from "../../../../components/Statistics/RankingCardsStatistics";
import { MyCharts } from "../../../../components/Custom/MyCharts";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  card_text_md: {
    color: "#fff",
    fontSize: 20,
  },
  card_text_sm: {
    color: "#fff",
    fontSize: 14,
  },
}));

export const WithDrawals = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const limit = 150000;
  const fakeListRanking = [
    {
      id: 1,
      name: "Lorem ipsum",
      value: "1000",
    },
    {
      id: 2,
      name: "Lorem ipsum",
      value: "800",
    },
    {
      id: 3,
      name: "Lorem ipsum",
      value: "650",
    },
    {
      id: 4,
      name: "Lorem ipsum",
      value: "500",
    },
  ];
  return (
    <div className="white-container">
      <Grid container spacing={2}>
        {[`${t("bets.playersWithMoreWithdrawals")}`].map((item) => (
          <Grid item xs={12} lg={4}>
            <RankingCardsStatistics
              key={item}
              title={item}
              list={fakeListRanking}
              cols={[
                `${t("sidebar.player").toUpperCase()}`,
                `${t("bets.totalDeposits").toUpperCase()}`,
              ]}
            />
          </Grid>
        ))}

        <Grid xs={12} lg={5} ml={2}>
          <MyCharts
            title={`${t("bets.percentByPayment")} - ${t("bets.withdrawals")}`}
            series={[
              {
                colorByPoint: true,
                innerSize: "75%",
                data: [
                  {
                    name: "PayU",
                    y: 28500,
                    color: "#02D0E7",
                  },
                  {
                    name: "PSE",
                    y: 19845,
                    color: "#FF5757",
                  },
                  {
                    name: "Addi",
                    y: 35248,
                    color: "#036A76",
                  },
                  {
                    name: "other",

                    y: limit - 83593,
                    color: "#D5D5D5",
                  },
                ],
              },
            ]}
            getContext={(context) => {
              const series: any = context.series[0],
                seriesCenter = series.center,
                x = seriesCenter[0] + context.plotLeft,
                y = seriesCenter[1] + context.plotTop,
                fontMetrics = context.renderer.fontMetrics(16);

              let yData: any[] = series.yData;
              yData.pop();
              const total = yData.reduce((partialSum, a) => partialSum + a, 0);
              const text = `+ ${total}`;

              if (!context.customTitle) {
                context.customTitle = (context as any).renderer
                  .text(text, null, null, true)
                  .css({
                    transform: "translate(-50%)",
                    fontSize: "20px",
                  })
                  .add();
              }

              context.customTitle.attr({
                x,
                y: y + fontMetrics.f / 2,
              });

              const chart = context;
                  const legends = chart.legend;

                  const allLegendItems = legends.allItems;
                  allLegendItems[
                    allLegendItems.length - 1
                  ].legendItem.group.hide();
            }}
            options={{
              legend: {
                align: "left",
                layout: "vertical",
                verticalAlign: "middle",
                itemMarginTop: 40,
                symbolHeight: 40,
                symbolWidth: 40,
                symbolRadius: 6,
                useHTML: true,
                className: "legend-container-lg",
              },
              responsive: {
                rules: [
                  {
                    condition: {
                      maxWidth: 300,
                    },
                    chartOptions: {
                      legend: {
                        align: "left",
                        layout: "vertical",
                        verticalAlign: "middle",
                        symbolHeight: 15,
                        symbolWidth: 15,
                        symbolRadius: 5,
                        itemMarginTop: 5,
                        itemMarginBottom: 5,
                      },
                      chart: {
                        height: "300px",
                      },
                    },
                  },
                ],
              },
              title: {
                style: {
                  fontWeight: "bold",
                },
              },
            }}
            variant={{ type: "pie" }}
            height="56%"
            customChartStyles={{
              backgroundColor: "#D5D5D533",
              borderRadius: 15,
              margin: [30, 30, 70, 45],
            }}
          />
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <Box
            width={"100%"}
            style={{ backgroundColor: "#036A7699" }}
            className="responsive-box-sm"
            display="flex"
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems="center"
            borderRadius={10}
          >
            <span className={classes.card_text_md}>{`HOLD`}</span>

            <span className={`${classes.card_text_md} mt-2`}>{`$200.000`}</span>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
