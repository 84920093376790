import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { StateTransaction, TransferConcept } from "../../../../enums/enums";
import MyCustomTable, {
  IColumns,
} from "../../../../components/Custom/Table/MyCustomTable";
import {
  asyncLoadingFunction,
  setChangeTab,
  setCloseAlert,
  setFlagFormConfig,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import {
  setActiveWithdrawal,
  startGetWithdrawals,
  startUpdateWithdrawal,
} from "../../../../redux/actions/transactionsActions";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { RowDataTableBankWithdrawals } from "./RowDataTableBankWithdrawals";
import { IWithdrawalData } from "../../../../interfaces/withdrawals";
import { MyCustomDialog } from "../../../../components/Custom/MyCustomDialog";
import { WithdrawalDetail } from "./WithdrawalDetail";
import { MyDialog } from "../../custom/MyDialog";
import { MyAlert } from "../../custom/MyAlert";
import {
  bankApprovedColumns,
  bankColumns,
  bankPendingColumns,
  bankRejectColumns,
  cashColumns,
  cashRejectColumns,
} from "../../../../helpers/headersWithdrawalTable";

import { FormRejectWithdrawal } from "./FormRejectWithdrawal";
import { getWithdrawals } from "../../../../services/withdrawals";
import { WithdrawalsRejectBankDataTable } from "../../../../hooks/useDataTable/withdrawals/WithdrawalsRejectBankDataTable ";
import { WithdrawalRejectBankPdfDocument } from "../../../../components/Pdf/withdrawals/WithdrawalRejectBankPdfDocument";
import moment from "moment";
import { WithdrawalBankPdfDocument } from "../../../../components/Pdf/withdrawals/WithdrawalBankPdfDocument";
import { WithdrawalsBankDataTable } from "../../../../hooks/useDataTable/withdrawals/WithdrawalsBankDataTable";
import { useLocation } from "react-router-dom";
import { AttachVoucher } from "./AttachVoucher";

interface Props {
  component: StateTransaction;
  dataFilter: any;
  isCash: boolean;
}

export const TableWithdrawals: FC<Props> = ({
  component,
  dataFilter,
  isCash,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { rowsPerPage, flagFormConfig, openAlert, messageAlert, typeAlert } =
    useSelector((state: AppState) => state.helper);
  const { token, user } = useSelector((state: AppState) => state.auth);
  const { withdrawals, activeWithdrawal } = useSelector(
    (state: AppState) => state.transactions
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [approve, setApprove] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [openAttach, setOpenAttach] = useState(false);
  const [loading, setLoading] = useState(false);
  const [columnsBank, setColumnsBank] = useState<IColumns[]>(bankColumns);
  const [columnsCash, setColumnsCash] = useState<IColumns[]>(cashColumns);
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { pathname } = useLocation();

  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);
  const enableEdit =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.updated === 1;

  let actions = {
    seeMore: true,
    approve: true,
    reject: true,
    see: false,
    attach: true,
  };
  if (!enableEdit) {
    actions.approve = false;
    actions.reject = false;
  }

  useEffect(() => {
    if (isCash) {
      setColumnsCash(
        component === StateTransaction.Refused ? cashRejectColumns : cashColumns
      );
    } else {
      if (component === StateTransaction.Pending) {
        return setColumnsBank(bankPendingColumns);
      }
      if (component === StateTransaction.Approved) {
        return setColumnsBank(bankApprovedColumns);
      }
      setColumnsBank(
        component === StateTransaction.Refused ? bankRejectColumns : bankColumns
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component]);

  useEffect(() => {
    dispatch(setChangeTab(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = async (page: number, rows?: number) => {
    const params = {
      ...dataFilter,
      page: page,
      perPage: rows || rowsPerPage,
      state: component,
      paymentMethod: isCash
        ? TransferConcept.CASH
        : TransferConcept.BANK_TRANSFER,
    };
    dispatch(
      asyncLoadingFunction(
        async () => await dispatch(startGetWithdrawals(params))
      )
    );
  };

  const onSeeMore = (row: IWithdrawalData) => {
    dispatch(setActiveWithdrawal(row));
    dispatch(setFlagFormConfig({ flagFormConfig: true }));
  };

  const onCloseModalInfo = async () => {
    setOpenReject(false);
    dispatch(setFlagFormConfig({ flagFormConfig: false }));
    dispatch(setActiveWithdrawal(null));
    setOpenAttach(false);
  };

  const handleAccept = async () => {
    if (approve) {
      await handleAprroveWithdrawal();
    } else {
      setOpenDialog(false);
      setOpenReject(true);
    }
  };

  const handleAprroveWithdrawal = async () => {
    setLoading(true);

    if (activeWithdrawal) {
      if(!activeWithdrawal.voucher){
        dispatch(setMessageAlert("withdrawals.approvingErrorVoucher"));
        dispatch(setTypeAlert("error"));
        handleCloseDialog();
        setLoading(false);
        dispatch(setActiveWithdrawal(null));
        return dispatch(setOpenAlert());
      }
      const withdrawalObj = {
        id: activeWithdrawal.id,
        withdrawalValue: activeWithdrawal.withdrawalValue,
        balanceType: activeWithdrawal.balanceType,
        paymentMethod: activeWithdrawal.paymentMethod,
        state: StateTransaction.Approved,
        player: activeWithdrawal.player.id,
        approvalDate: moment().toISOString(),
      };
      const response: any = await dispatch(
        startUpdateWithdrawal(withdrawalObj, true)
      );
      await setLoading(false);
      handleCloseDialog();
      if (response) {
        dispatch(
          setMessageAlert(
            `alerts.${
              response.error ? response.error.errorMessage : response.message
            }`
          )
        );
        dispatch(setTypeAlert(response.error ? "error" : "success"));
      } else {
        dispatch(setMessageAlert("alerts.updatedWithdrawalError"));
        dispatch(setTypeAlert("error"));
      }
      dispatch(setOpenAlert());
    }
  };

  const handleRejectWithdrawal = async (reason: string) => {
    setLoading(true);
    if (activeWithdrawal) {
      const withdrawalObj = {
        id: activeWithdrawal.id,
        withdrawalValue: activeWithdrawal.withdrawalValue,
        balanceType: activeWithdrawal.balanceType,
        paymentMethod: activeWithdrawal.paymentMethod,
        state: StateTransaction.Refused,
        reason,
        player: activeWithdrawal.player.id,
        taxValue: activeWithdrawal.taxValue,
        rejectDate: moment().toISOString(),
      };
      const response: any = await dispatch(
        startUpdateWithdrawal(withdrawalObj, true)
      );
      await setLoading(false);
      onCloseModalInfo();
      if (response) {
        dispatch(
          setMessageAlert(
            `${
              response.error
                ? "alerts.rejectWithDrawalError"
                : "alerts.rejectWithDrawalSuccess"
            }`
          )
        );
        dispatch(setTypeAlert(response.error ? "error" : "success"));
      } else {
        dispatch(setMessageAlert("alerts.rejectWithDrawalError"));
        dispatch(setTypeAlert("error"));
      }
      dispatch(setOpenAlert());
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    dispatch(setActiveWithdrawal(null));
    setApprove(false);
  };

  const onApprove = (row: IWithdrawalData) => {
    setMessage(t("withdrawals.sureApproveWithdrawal"));
    dispatch(setActiveWithdrawal(row));
    setApprove(true);
    setOpenDialog(true);
  };

  const onReject = (row: IWithdrawalData) => {
    setMessage(t("withdrawals.sureRejectWithdrawal"));
    dispatch(setActiveWithdrawal(row));
    setApprove(false);
    setOpenDialog(true);
  };

  const getWithdrawalsAction = async () => {
    if (token && user) {
      const { page, perPage, ...rest } = dataFilter;
      const params = {
        ...rest,
        state: component,
        paymentMethod: isCash
          ? TransferConcept.CASH
          : TransferConcept.BANK_TRANSFER,
      };
      return await getWithdrawals(token, user.operator?.id, params);
    }
  };

  const onAttach = (row: IWithdrawalData) => {
    setOpenAttach(true);
    dispatch(setActiveWithdrawal(row));
  };

  return (
    <>
      {withdrawals && withdrawals.data && withdrawals.data.length > 0 && (
        <MyCustomTable
          columns={isCash ? columnsCash : columnsBank}
          data={withdrawals ? withdrawals.data : []}
          lastPage={withdrawals ? withdrawals.last_page : 1}
          totalData={withdrawals ? withdrawals.total : 0}
          renderBody={(item) => (
            <RowDataTableBankWithdrawals row={item} component={component} />
          )}
          onPageChange={handlePageChange}
          actions={
            columnsBank.find((col) => col.label === t("table.actions")) &&
            !isCash
              ? actions
              : undefined
          }
          onSeeMore={onSeeMore}
          onApprove={onApprove}
          onReject={onReject}
          onAttach={onAttach}
          isSortingTable={true}
          exportData
          hookBuildTable={
            component === StateTransaction.Refused
              ? WithdrawalsRejectBankDataTable
              : WithdrawalsBankDataTable
          }
          getAllDataToExport={getWithdrawalsAction}
          pdfComponent={
            component === StateTransaction.Refused
              ? WithdrawalRejectBankPdfDocument
              : WithdrawalBankPdfDocument
          }
          titleCSV={t(
            isCash ? "table.neatWithdrawalCash" : "table.neatWithdrawalBank"
          )}
        />
      )}

      <MyCustomDialog
        open={!!flagFormConfig}
        content={<WithdrawalDetail />}
        onClose={onCloseModalInfo}
        title={t("withdrawals.playerWithdrawalInformation")}
        width={"lg"}
        icon={
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 20 }}
          ></i>
        }
        okButton={true}
      />

      <MyDialog
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message={message}
        handleAccept={handleAccept}
        handleClose={handleCloseDialog}
        isLoading={loading}
      />

      <MyCustomDialog
        open={openReject}
        content={
          <FormRejectWithdrawal
            loading={loading}
            handleClose={onCloseModalInfo}
            handleAccept={handleRejectWithdrawal}
          />
        }
        onClose={onCloseModalInfo}
        title={t("withdrawals.reason")}
        width={"sm"}
        icon={
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 20 }}
          ></i>
        }
      />

      <MyCustomDialog
        open={openAttach}
        content={<AttachVoucher setOpenAttach={setOpenAttach}/>}
        onClose={onCloseModalInfo}
        title={t("withdrawals.attachPaymentVoucher")}
        width={"sm"}
        icon={
          <i
            className="fa-solid fa-circle-info"
            style={{color: "#02D0E7", marginRight: 9, fontSize: 20}}
          ></i>
        }
      />

      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={() => dispatch(setCloseAlert())}
      />
    </>
  );
};
