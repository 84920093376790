import { Alert, AlertTitle, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../../../../components/Filter/Filter";

import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import { AppState } from "../../../../redux/reducers/rootReducer";
import InfoIcon from "@mui/icons-material/Info";
import "./activity.scss";
import { Row } from "reactstrap";
import { useEffect, useState } from "react";
import MyCustomTable, {
  IColumns,
} from "../../../../components/Custom/Table/MyCustomTable";
import { RowDataTableActivity } from "./RowDataTableActivity";
import { IActivityQuery } from "../../../../interfaces/activityPlayer";
import { ActivityType } from "../../../../enums/enums";
import { asyncLoadingFunction } from "../../../../redux/actions/helperActions";
import { startGetActivityPlayer } from "../../../../redux/actions/activityPlayerActions";

import { getActivityPlayer } from "../../../../services/activityPlayer";
import { ActivityPlayerPdf } from "../../../../components/Pdf/activityPlayer/ActivityPlayerPdf";
import { ActivityPlayerDataTable } from "../../../../hooks/useDataTable/activityPlayer/ActivityPlayerDataTable";

export const Activity = () => {
  const { token } = useSelector((state: AppState) => state.auth);
  const { player } = useSelector((state: AppState) => state.managementPlayers);
  const { data } = useSelector((state: AppState) => state.search);
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);
  const [dataFilter, setDataFilter] = useState<any>({});
  const { flagCleanFilter } = useSelector((state: AppState) => state.helper);
  const [cleanFilter, setCleanFilter] = useState(false);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (flagCleanFilter) {
      return setCleanFilter(true);
    }
    return setCleanFilter(false);
  }, [flagCleanFilter]);

  /**
   * Datos inputs para filtro
   */
  const FilterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.number"),
      type: Enum_FieldType.NUMBER,
    },
    {
      label: t("playerActivity.activityType"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("activityType.sportsBet"),
          value: ActivityType.BETS,
        },
        {
          label: t("sidebar.casino"),
          value: ActivityType.CASINO,
        },
        {
          label: t("activityType.withdrawalBalance"),
          value: ActivityType.WITHDRAWALS,
        },
        {
          label: t("activityType.currentBalance"),
          value: ActivityType.BALANCE,
        },
        {
          label: t("pointsOfSale.equestrian"),
          value: ActivityType.HORSERIDING,
        },
      ],
    },
  ];
  const columns: IColumns[] = [
    {
      align: "center",
      label: t("table.date"),
    },
    {
      align: "center",
      label: t("playerActivity.activityNumber"),
    },
    {
      align: "center",
      label: t("playerActivity.activityType"),
    },
    {
      align: "center",
      label: t("commercialNetworks.transactionStatus"),
    },
    {
      align: "center",
      label: t("form.value"),
    },
    {
      align: "center",
      label: t("playerActivity.balance"),
    },
    {
      align: "center",
      label: t("managementBonds.provider"),
    },
    {
      align: "center",
      label: t("bets.ticketNumber"),
    },
  ];

  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startGetActivityPlayer({
            ...dataFilter,
            page,
            perPage: rows || rowsPerPage,
          })
        )
      )
    );
  };
  const handleFilter = async (values: any) => {
    if (player) {
      const params: Partial<IActivityQuery> = {
        playerId: (player as any).data[0].id,
        startDate: values[t("filters.startDate")],
        endDate: values[t("filters.endDate")],
        number: values[t("filters.number")],
        type: values[t("playerActivity.activityType")],
      };

      setDataFilter(params);

      dispatch(
        asyncLoadingFunction(() => dispatch(startGetActivityPlayer(params)))
      );
    }
  };
  const getActivityPlayerAction = async () => {
    if (player && token) {
      delete dataFilter.page;
      delete dataFilter.perPage;
      const params: Partial<IActivityQuery> = {
        ...dataFilter,
        playerId: (player as any).data[0].id,
      };
      return await getActivityPlayer(token, params);
    }
  };

  return (
    <div className="container-fluid">
      <Row
        style={{
          marginTop: "12px",
          pointerEvents: player && player.data.length > 0 ? "all" : "none",
          opacity: player && player.data.length > 0 ? 1 : 0.4,
        }}
      >
        <Filter
          fields={FilterFields}
          cleanFilter={cleanFilter}
          handleFilter={handleFilter}
        />
      </Row>
      {data &&
      data.data.length > 0 &&
      data.data[0].transactionType &&
      player ? (
        <Grid item xs={12}>
          <MyCustomTable
            columns={columns}
            data={data.data}
            lastPage={data.last_page}
            totalData={data.total}
            renderBody={(item) => <RowDataTableActivity row={item} />}
            onPageChange={handlePageChange}
            exportData
            hookBuildTable={ActivityPlayerDataTable}
            getAllDataToExport={getActivityPlayerAction}
            pdfComponent={ActivityPlayerPdf}
            titleCSV={`activityplayer${(player as any).data[0].id}`}
          />
        </Grid>
      ) : (
        <Grid item xs={12} pt={3} mt={-2}>
          <Stack
            sx={{ width: "100%" }}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
            spacing={2}
          >
            <Alert
              icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
              sx={{
                borderLeft: `7px solid #2C97DF`,
                backgroundColor: "#EAF5FC",
                color: "#4b4b4b",
              }}
            >
              <div className="d-flex">
                <AlertTitle
                  style={{ marginTop: "5px" }}
                  sx={{ color: "#333333" }}
                >
                  {t("alerts.clickButton")}
                </AlertTitle>
                <AlertTitle
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  sx={{ color: "#333333", fontWeight: "bold" }}
                >
                  {t("alerts.playerActivity")}
                </AlertTitle>
              </div>
            </Alert>
          </Stack>
        </Grid>
      )}
    </div>
  );
};
