import { FC } from "react";
import { useTranslation } from "react-i18next";

import TableCell from "@mui/material/TableCell";

import { State } from "../../../../enums/enums";
import { IUserData } from "../../../../redux/interfaces/users";
import moment from "moment";

interface Props {
  row: Partial<IUserData>;
}

export const RowDataTableCredentialsPointSale: FC<Props> = ({ row }) => {
  const { t } = useTranslation();

  return (
    <>
      <TableCell className="word-break">{row.nameComplete}</TableCell>
      <TableCell className="word-break">{row.email}</TableCell>
      <TableCell className="word-break">
        {moment(row.createAt).utc().format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
      <TableCell align="center">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                State.ACTIVE === row.stateUser
                  ? "d-flex justify-content-center cellRowActive"
                  : State.RESTRICTED === row.stateUser
                  ? "d-flex justify-content-center cellRowRetired"
                  : "d-flex justify-content-center cellRowInactive"
              }
            >
              <div className="d-flex col-sm-4 justify-content-center">
                {State.ACTIVE === row.stateUser && <>{t("state.active")}</>}
                {State.INACTIVE === row.stateUser && <>{t("state.inactive")}</>}
                {State.RESTRICTED === row.stateUser && (
                  <>{t("state.restricted")}</>
                )}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
    </>
  );
};
