export const urls = [
  {
    label: "Deportivas",
    value: "/bet",
  },
  {
    label: "Bingo",
    value: "/bingo",
  },
  {
    label: "En vivo",
    value: "/live",
  },
  {
    label: "Casino",
    value: "/casino",
  },
  {
    label: "Casino Live",
    value: "/live-casino",
  },
  {
    label: "Virtuales",
    value: "/virtuals",
  },
  {
    label: "Promociones",
    value: "/promotional",
  },
  {
    label: "Recargas",
    value: "/deposit",
  },
  {
    label: "Retiros",
    value: "/withdrawals",
  },
  {
    label: "Registro",
    value: "/sign-up",
  },
  {
    label: "Afiliados",
    value: "/my-account?affiliates",
  },
];
