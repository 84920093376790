import { View, Text, Font } from "@react-pdf/renderer";
import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import { IBetData } from "../../../interfaces/bets";
import { betStatuses } from "../../../helpers/betStatus";
import { State_Bets } from "../../../enums/enums";

Font.registerHyphenationCallback((word) => {
  const middle = Math.floor(word.length / 2);
  const parts =
    word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];

  return parts;
});

interface Props {
  page: any;
}

export const BetsPlayerPdfDocument: FC<Props> = ({ page }) => {
  const { t } = useTranslation();
  const columnsBetHistory = [
    t("table.betId").toUpperCase(),
    t("user.createAt").toUpperCase(),
    t("bets.betType").toUpperCase(),
    t("bets.event").toUpperCase(),
    t("bets.betAmount").toUpperCase(),
    t("bets.winningAmount").toUpperCase(),
    t("bets.feeds").toUpperCase(),
    t("bets.bonus").toUpperCase(),
    t("table.state").toUpperCase(),
  ];
  const columns = columnsBetHistory;

  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column) => (
          <View key={column} style={[stylesPdf.pCol, stylesPdf.col]}>
            <Text style={stylesPdf.text}>{column}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        {page.map((page: IBetData) => {
          let feeds = 1;
          page.events.forEach((item: any) => {
            feeds =
              page.eventCount > 1
                ? page.betStakes[0].odds
                : item.markets[0]
                ? item.markets[0].odds * 1
                : 1;
          });
          return (
            <View key={page.id} style={stylesPdf.row}>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>{page.id}</Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={[stylesPdf.textContent]}>
                  {moment(page.createAt).format("DD/MM/YYYY HH:mm:ss A")}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {page.eventCount > 1
                    ? `${t(
                        page.isSystem ? "betType.system" : "betType.multiple"
                      )} (${page.eventCount})`
                    : t(page.isSystem ? "betType.system" : "betType.simple")}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {page.eventCount > 0
                    ? `${t("bets.eventsNumber")} (${page.eventCount})`
                    : "-"}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={[stylesPdf.textContent]}>
                  {page.transactions &&
                  page.transactions.length > 0 &&
                  State_Bets.WON === page.statusName
                    ? (parseFloat(page.transactions[0].amount) / feeds).toFixed(
                        2
                      )
                    : parseFloat(page.amount).toFixed(2)}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={[stylesPdf.textContent]}>
                  {State_Bets.WON === page.statusName
                    ? page.transactions && page.transactions.length > 0
                      ? parseFloat(page.transactions[0].amount).toFixed(2)
                      : (parseFloat(page.amount) * feeds).toFixed(2)
                    : "0.00"}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={[stylesPdf.textContent]}>{feeds}</Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={[stylesPdf.textContent]}>
                  {page.bonusAccountId ? t("table.yes") : t("table.not")}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={[stylesPdf.textContent]}>
                  {betStatuses
                    .find((status) => status.value === page.status)
                    ?.description.toUpperCase()}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </>
  );
};
