import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  ThemeProvider,
} from "@mui/material";

import { theme } from "../../custom/customStyles";
import { useForm } from "../../../../hooks/useForm";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import {
  createNewObservation,
  setActiveObservation,
  startUpdateObservation,
} from "../../../../redux/actions/managementPlayersActions";
import { AppState } from "../../../../redux/reducers/rootReducer";

export const FormObservations = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { player, activeObservation } = useSelector(
    (state: AppState) => state.managementPlayers
  );

  /**
   * FORM VALUES
   */
  const initialFormValues = {
    observation: activeObservation ? activeObservation.observation : "",
    state: activeObservation ? activeObservation.state : 1,
    player: activeObservation
      ? activeObservation.player.id
      : player?.data[0].id,
  };

  let [formValues, handleInputChange] = useForm({
    ...initialFormValues,
  });

  /**
   * SOPORTA EL EVENTO PARA ABRIR MODAL DE CONFIRMACION CERACIÓN OBSERVACION
   */
  const handleCreateObservation = async () => {
    setIsSubmit(true);
    if (formValues.observation === "") {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.fillInRequiredFields"));
      dispatch(setTypeAlert("error"));
    } else {
      handleAcceptCreate();
    }
  };

  /**
   * SOPORTA EL EVENTO PARA ACEPTAR EL ENVIO DE FORMULARIO PARA CREACION
   */
  const handleAcceptCreate = async () => {
    setIsLoading(true);
    !!activeObservation
      ? await dispatch(startUpdateObservation(formValues))
      : await dispatch(createNewObservation(formValues));
    setIsLoading(false);
    setIsSubmit(false);
  };

  useEffect(() => {
    return () => {
      dispatch(setActiveObservation(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <label className="ml-2">{t("observations.title")}</label>
          <FormControl sx={{ width: 1 }} size="small">
            <TextField
              name="observation"
              size="small"
              type="text"
              InputLabelProps={{
                shrink: false,
              }}
              inputProps={{
                "data-testid": "observation",
                maxLength: 50,
              }}
              onChange={handleInputChange}
              value={formValues.observation}
              label={" "}
              variant="outlined"
              multiline
              minRows={3}
              maxRows={8}
              fullWidth
              error={isSubmit && formValues.observation === ""}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="flex-end" mt={10}>
          {isLoading ? (
            <CircularProgress className={"colorLoading"} />
          ) : (
            <Button
              data-testid="btnSubmit"
              style={{
                border: "none",
                outline: "none",
                fontSize: 14,
              }}
              variant="contained"
              className="btn-dialog primary-btn"
              onClick={handleCreateObservation}
            >
              {t(
                activeObservation
                  ? "observations.editObservation"
                  : "observations.createObservation"
              )}
              <i className="fa-solid fa-check-circle ml-2"></i>
            </Button>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
