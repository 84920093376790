import { FC } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
interface Props {
  row: any;
}

export const RowDataTableProgressiveJackpotWon: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  return (
    <>
      <TableCell align="center" className="word-break">{t("reports.withoutActivity").toUpperCase()}</TableCell>
    </>
  );
};
