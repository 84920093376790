import { Box, Tab, Tabs } from "@mui/material";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setWithdrawalsRedux } from "../../../../redux/actions/transactionsActions";
import { TabPanel, a11yProps, styles } from "../../../../components/Tabs";
import { TableWithDrawalsTab } from "./TableWithDrawalsTab";
import { StateTransaction } from "../../../../enums/enums";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { CustomAlert } from "../../../../components/Alerts/CustomAlert";
import { setChangeTab } from "../../../../redux/actions/helperActions";
interface props {
  filterParams: any;
  setCurrentTab(tab: StateTransaction): void;
}
export const TabsWithDrawalRequest: FC<props> = ({
  filterParams,
  setCurrentTab,
}) => {
  const { withdrawals } = useSelector((state: AppState) => state.transactions);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const currentTab =
      newValue === 0
        ? StateTransaction.Pending
        : newValue === 2
        ? StateTransaction.Expired
        : StateTransaction.Approved;
    setCurrentTab(currentTab);
    dispatch(setChangeTab(true));
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={(e: React.SyntheticEvent, newValue: number) => {
            dispatch(setWithdrawalsRedux(null));
            handleChange(e, newValue);
          }}
          style={{ border: "none", outline: "none" }}
          TabIndicatorProps={{
            style: {
              backgroundColor: " #02D0E7",
              fontWeight: "bold",
            },
          }}
        >
          <Tab
            sx={styles}
            label={
              <span className="spanTab">
                <i className="fa-regular fa-clock mr-1"></i>
                {t("tabs.slopes")}
              </span>
            }
            {...a11yProps(0)}
          />
          <Tab
            sx={styles}
            label={
              <span className="spanTab">
                <i className="fa-solid fa-dollar-sign mr-1"></i>
                {t("tabs.approved")}
              </span>
            }
            {...a11yProps(1)}
          />
          <Tab
            sx={styles}
            label={
              <span className="spanTab">
                <i className="fa-solid fa-calendar-xmark mr-1"></i>
                {t("withdrawals.expired")}
              </span>
            }
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <div className="filter mt-3">
        <TabPanel value={value} index={0}>
          {withdrawals ? (
            <TableWithDrawalsTab
              stateWithDrawal={StateTransaction.Pending}
              filterParams={filterParams}
            />
          ) : (
            <CustomAlert htmlText={t("withdrawals.filterText")} type="info" />
          )}
        </TabPanel>

        <TabPanel value={value} index={1}>
          {withdrawals ? (
            <TableWithDrawalsTab
              stateWithDrawal={StateTransaction.Approved}
              filterParams={filterParams}
            />
          ) : (
            <CustomAlert htmlText={t("withdrawals.filterText")} type="info" />
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {withdrawals ? (
            <TableWithDrawalsTab
              stateWithDrawal={StateTransaction.Expired}
              filterParams={filterParams}
            />
          ) : (
            <CustomAlert htmlText={t("withdrawals.filterText")} type="info" />
          )}
        </TabPanel>
      </div>
    </Box>
  );
};
