import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../../redux/reducers/rootReducer';
import { Box, Grid } from '@mui/material';
interface props {
    data: number[];
  }
export const TabDealers:FC<props> = ({data}) => {
    const {dealersPointSale} = useSelector((state:AppState) => state.managementDealers);
    const getDealerDetail = (id: number) => {
        const seller = dealersPointSale.find((dealer) => dealer.id === id);
    
        return seller;
      };
      return (
        <Grid container spacing={2}>
          {data.map((dealer, index) => (
            <React.Fragment key={index}>
              <Grid container>
                <Grid item xs={3}>
                  <Box className="no-overflow">
                    {getDealerDetail(parseInt(`${dealer}`))?.name}
                  </Box>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      );
}
