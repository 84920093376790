import {
  IBodyPromoter,
  IPromoter,
  IPromotersQuery,
} from "../../interfaces/promoters";
import { IPaginationData } from "../../interfaces/shared";
import {
  createPromoter,
  getPromoters,
  updatePromoter,
} from "../../services/promoters";
import { buildFinalQueryParams } from "../../utils/utils";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import { setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";

export const startGetPromoters = (params: Partial<IPromotersQuery>) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;

    if (!token || !user) {
      throw new Error("invalid action");
    }

    try {
      if (params.page) {
        params.perPage = rowsPerPage;
      }
      if (user.operator) {
        params.operator = user.operator.id;
      }
      let finalValues = buildFinalQueryParams(params);

      const response = await getPromoters(finalValues, token);
      if (response.error) {
        dispatch(
          setMessageAlert(`managementPromoters.${response.error.errorServer}`)
        );
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      dispatch(setPromoters(response));
    } catch (error) {}
  };
};

export const setPromoters = (promoter: IPaginationData<IPromoter> | null) => ({
  type: Types.setPromoters,
  payload: promoter,
});

export const startAddPromoter = (promoter: IBodyPromoter) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    if (!token || !user) {
      throw new Error("Invalid action");
    }

    //createBy user
    const createBy = user.id;

    promoter.createBy = createBy;
    const response = await createPromoter(promoter, token);
    const messageAlert = response.error
      ? response.error.errorServer
      : "createPromoterSuccess";
    const alertType = response.error ? "error" : "success";

    dispatch(setMessageAlert(`managementPromoters.${messageAlert}`));
    dispatch(setTypeAlert(alertType));
    if (response.error) {
      dispatch(setOpenAlert());
      throw new Error(response.error);
    }
    await dispatch(startGetPromoters({ page: 1 }));

    dispatch(setOpenAlert());
  };
};
export const startUpdatePromoter = (
  id: number,
  promoter: Partial<IBodyPromoter>
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { promoters } = (getState() as AppState).managementPromoters;
    if (!token) {
      throw new Error("Invalid action");
    }
    const objUpdate:Partial<IBodyPromoter> = {
      name: promoter.name,
      address: promoter.address,
      phone: promoter.phone,
      state: promoter.state,
      email: promoter.email,
    } 
    const response = await updatePromoter(id, objUpdate, token);
    const messageAlert = response.error
      ? response.error.errorServer
      : "updatePromoterSuccess";
    const alertType = response.error ? "error" : "success";

    dispatch(setMessageAlert(`managementPromoters.${messageAlert}`));
    dispatch(setTypeAlert(alertType));
    if (response.error) {
      dispatch(setOpenAlert());
      throw new Error(messageAlert);
    }
    await dispatch(startGetPromoters({ page: promoters?.page }));

    dispatch(setOpenAlert());
  };
};
export const setActivePromoter = (promoter: IPromoter | null) => ({
  type: Types.setActivePromoter,
  payload: promoter,
});
