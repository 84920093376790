import {
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IColumnsTable } from "../../interfaces/components";
import { RowDataTableRolesItem } from "../../views/pages/managementRoles/RowDataTableRolesItem";
import { IMenuItem } from "../../interfaces/menu";
import { RowDataTableModules } from "../../views/pages/managementMenu/RowDataTableModules";
interface Props {
  component: string;
  data: any;
  columns: IColumnsTable[];
}
export const BasicTable: FC<Props> = ({ component, data, columns }) => {
  const { t } = useTranslation();
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F4F4F4",
      color: "#000",
      fontSize: 14,
      fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      fontWeight: 700,
    },
  }));

  /**
   * @param component module to render
   * @returns body of table
   */
  const BuildTableBody = (component: string) => {
    switch (component) {
      case "roles":
        return data && data.rol
          ? data.rol.actionsPermissions.map((item: any, index: number) => (
              <RowDataTableRolesItem key={item.id} row={item} />
            ))
          : data?.actionsPermissions &&
              data.actionsPermissions.map((item: any, index: number) => (
                <RowDataTableRolesItem key={item.id} row={item} />
              ));

      case "menu":
        return (
          data &&
          data.menuItem &&
          data.menuItem.map((item: IMenuItem) => (
            <RowDataTableModules row={item} key={item.id} />
          ))
        );

      // TODO: DEMAS COMPONENTES
      default:
        return null;
    }
  };
  return (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell colSpan={12} style={{ padding: 0 }}>
            <Table>
              <TableHead>
                {/* RENDER COLUMNS */}
                <TableRow
                  style={{ backgroundColor: "#F4F4F4" }}
                  key={data && data.id}
                >
                  {columns.map((item: IColumnsTable, index) => {
                    return (
                      <StyledTableCell key={item.id}>
                        {t(`${item.header}`).toUpperCase()}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              {/* RENDER BODY DATA */}
              <TableBody>{BuildTableBody(component)}</TableBody>
            </Table>
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
};
