import { FC, useEffect, useState } from "react";
import { Grid, IconButton, Box } from "@mui/material";
import "./CustomStyles.scss";

export interface IOptions {
  id: number;
  label: string;
}
interface Props {
  options: IOptions[];
  onChangeComponent(option: number): void;
  setOption?(callback: Function): void;
}

export const DynamicTabs: FC<Props> = ({
  options,
  onChangeComponent,
  setOption,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [optionSelected, setOptionSelected] = useState(
    options.length > 0 ? options[0].id : 0
  );
  const rowSize = Math.round((12 / options.length) * 10) / 10;
  useEffect(() => {
    if (setOption) {
      setOption(setOptionSelected);
    }
  }, [setOption, setOptionSelected]);

  useEffect(() => {
    onChangeComponent(optionSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionSelected]);

  return (
    <Box mt={2}>
      <Grid container spacing={1} className="dynamic-toogle-tabs">
        <Grid item xs={1} className="toogle-button-container">
          <IconButton
            disableRipple
            onClick={() => setIsOpen(!isOpen)}
            style={{ border: "none", outline: "none", boxShadow: "none" }}
          >
            <span className="circle-btn-green">
              {isOpen ? (
                <i
                  style={{ fontSize: 20, color: "#fff" }}
                  className="fa-solid fa-xmark"
                ></i>
              ) : (
                <i
                  style={{ fontSize: 20, color: "#fff" }}
                  className="fa-solid fa-plus"
                ></i>
              )}
            </span>
          </IconButton>
        </Grid>

        {isOpen && (
          <Grid item xs={11} mt={1} className="dynamic-tabs-container">
            <Grid container spacing={2}>
              {options.map((option) => (
                <Grid
                  item
                  xs={rowSize}
                  key={option.id}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="tab"
                >
                  {option.id === optionSelected ? (
                    <div
                      className="option-btn-active"
                      onClick={() => setOptionSelected(option.id)}
                    >
                      <p
                        style={{ color: "#fff", textAlign: "center" }}
                        className="txt-primary-sm"
                      >
                        {option.label}
                      </p>
                    </div>
                  ) : (
                    <div
                      className="option-btn"
                      onClick={() => setOptionSelected(option.id)}
                    >
                      <p
                        className="txt-primary-sm"
                        style={{ textAlign: "center" }}
                      >
                        {option.label}
                      </p>
                    </div>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
