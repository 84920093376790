import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { Grid, IconButton } from "@mui/material";

import { MyCustomDialog } from "../../../components/Custom/MyCustomDialog";
import { DetailsDeliveredCards } from "../../components/ManagementCards/DetailsDeliveredCards";
import { RowLotCards } from "../../components/ManagementCards/RowLotCards";
import Filter from "../../../components/Filter/Filter";
import { FormCreateCards } from "../../components/ManagementCards/FormCreateCards";
import { Enum_FieldType, IFilterField } from "../../../interfaces/components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useLocation } from "react-router-dom";
import {
  asyncLoadingFunction,
  setFlagForm,
  setFlagFormEdit,
  setFlagFormModal,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import {
  setActiveLot,
  startFilterLots,
  startUpdateLot,
} from "../../../redux/actions/managementCardsActions";
import MyCustomTable from "../../../components/Custom/Table/MyCustomTable";
import { uiSetOpenDialog } from "../../../redux/actions/uiAction";
import { MyDialog } from "../../components/custom/MyDialog";
import { ILotsList } from "../../../interfaces/cards";
import { StateLotCards } from "../../../enums/enums";
import { EditCardDealers } from "../../components/ManagementCards/EditCardDealers";

export const CardsTab = () => {
  const {
    rowsPerPage,
    flagFormModal,
    flagFormCreate,
    messageAlert,
    flagFormEdit,
  } = useSelector((state: AppState) => state.helper);
  const { openDialog } = useSelector((state: AppState) => state.ui);
  const { lots, activeLot } = useSelector(
    (state: AppState) => state.managementCards
  );
  const [dataFilter, setDataFilter] = useState<any>({});
  const [isFiltering, setIsFiltering] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useSelector((state: AppState) => state.auth);
  const [isDelivered, setIsDelivered] = useState(false);

  const { routes: RoutesRedux } = useSelector(
    (state: AppState) => state.managementMenus
  );
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const modulePermissions = RoutesRedux.find(
    (route) => !!route.menuItem.find((module) => module.url === pathname)
  )?.menuItem.find((module) => module.url === pathname);
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;

  useEffect(() => {
    if (lots && lots.data.length === 0) {
      dispatch(
        setMessageAlert(isFiltering ? "alerts.noData" : "alerts.noRecords")
      );
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lots]);

  const fetchLots = async () => {
    //await dispatch(starGetBonus());
    if (user) {
      let params: any = {
        page: currentPage,
        perPage: rowsPerPage,
      };
      await dispatch(
        asyncLoadingFunction(() =>
          dispatch(
            startFilterLots({
              ...params,
            })
          )
        )
      );
    }
  };
  useEffect(() => {
    fetchLots();
    return () => {
      dispatch(setFlagForm({ flagFormCreate: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const FilterFileds: IFilterField[] = [
    {
      label: t("user.createAt"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("table.serial"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: "ID",
      type: Enum_FieldType.TEXT,
    },
  ];

  const handleFilter = async (values: any) => {
    const params = {
      createDate: values[t("user.createAt")],
      serial: values[t("table.serial")],
      id: values["ID"],
    };
    setIsFiltering(true);
    await dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startFilterLots({
            ...params,
            page: 1,
          })
        )
      )
    );
    setDataFilter({ ...params });
  };
  const onCleanFilter = async () => {
    dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startFilterLots({
            page: 1,
          })
        )
      )
    );
    setDataFilter({});
    setIsFiltering(false);
  };

  const handleOpenForm = () => {
    dispatch(setFlagForm({ flagFormCreate: true }));
  };

  const handlePageChange = async (page: number, rows?: number) => {
    setCurrentPage(page);
    await dispatch(
      startFilterLots({
        page: page,
        perPage: rows || rowsPerPage,
        ...dataFilter,
      })
    );
  };
  const handleCloseDetails = () => {
    dispatch(uiSetOpenDialog());
  };
  const handleCloseEdit = () => {
    dispatch(
      setFlagFormEdit({
        flagFormEdit: false,
      })
    );
    dispatch(setActiveLot(null));
  };
  const handleDeliverDealer = async () => {
    if (activeLot) {
      setIsDelivered(true);
      const lot: any = activeLot as ILotsList;
      await dispatch(
        startUpdateLot(lot.id, {
          state: StateLotCards.DELIVERED_DEALER,
          amount: lot.amountCards,
          updateOnlyLot: true,
          value: parseInt(lot.totalValue) / lot.amountCards,
          extLot: lot.extLot,
          activateDate: new Date().toISOString(),
        })
      );
      await dispatch(
        startFilterLots({
          page: lots?.page || 1,
        })
      );
      dispatch(setMessageAlert("cards.successDeliveredCards"));
      dispatch(setTypeAlert("success"));
      dispatch(setOpenAlert());
      closeConfirmationWindow();
      setIsDelivered(false);
    }
  };
  const closeConfirmationWindow = () => {
    dispatch(
      setFlagFormModal({
        flagFormModal: false,
      })
    );
    dispatch(setActiveLot(null));
  };
  return (
    <>
      {flagFormCreate ? (
        <FormCreateCards />
      ) : (
        <>
          {lots ? (
            <>
              {enableCreated && (
                <Grid item display="flex" justifyContent="flex-end">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-around"
                    mb={2}
                  >
                    <Box display="flex" alignItems="center">
                      <p
                        className="txt-primary-md"
                        style={{
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          alignSelf: "center",
                        }}
                      >
                        {t("cards.createCard")}
                      </p>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={handleOpenForm}
                        style={{ border: "none", outline: "none" }}
                      >
                        <span className="btn-add">
                          <i
                            className="fa-solid fa-plus"
                            style={{ fontSize: 30 }}
                          ></i>
                        </span>
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Filter
                    fields={FilterFileds}
                    handleFilter={handleFilter}
                    onClean={onCleanFilter}
                  />
                </Grid>

                <Grid item xs={12}>
                  <MyCustomTable
                    columns={[
                      {
                        align: "center",
                        label: t("table.serial"),
                      },
                      {
                        align: "center",
                        label: "ID",
                      },
                      {
                        align: "center",
                        label: t("table.amountLotCards"),
                      },
                      {
                        align: "center",
                        label: t("user.createAt"),
                      },
                      {
                        align: "center",
                        label: t("form.state"),
                      },
                      {
                        align: "center",
                        label: t("table.actions"),
                      },
                    ]}
                    data={lots?.data || []}
                    lastPage={lots?.last_page || 1}
                    totalData={lots?.total || 0}
                    renderBody={(item) => (
                      <RowLotCards row={item} dataFilter={dataFilter} />
                    )}
                    onPageChange={handlePageChange}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container className={"paperLoading"}>
              <Box mx="auto" my="40vh">
                <CircularProgress className={"colorLoading"} />
              </Box>
            </Grid>
          )}
        </>
      )}

      <MyCustomDialog
        open={openDialog}
        content={<DetailsDeliveredCards />}
        onClose={handleCloseDetails}
        title={t("cards.infoDeliveredCrds")}
        icon={
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 20 }}
          ></i>
        }
      />
      {activeLot && (
        <MyCustomDialog
          open={!!flagFormEdit}
          content={<EditCardDealers />}
          onClose={handleCloseEdit}
          title={t("cards.reassingCards")}
          icon={
            <i
              className="fa-solid fa-circle-info"
              style={{ color: "#02D0E7", marginRight: 9, fontSize: 20 }}
            ></i>
          }
        />
      )}
      <MyDialog
        open={!!flagFormModal}
        title={t("dialog.confirmationWindow")}
        message={messageAlert}
        handleAccept={handleDeliverDealer}
        handleClose={closeConfirmationWindow}
        isLoading={isDelivered}
      />
    </>
  );
};
