import { FC } from "react";

import { Grid } from "@mui/material";

import { ICountryStep } from "../../../../interfaces/operationCompany";

interface Props {
  countriesSelected: ICountryStep[];
}

export const TabSolutionsConfirmation: FC<Props> = ({ countriesSelected }) => {
  return (
    <Grid container>
      {/* CONFIRMACIÓN SOLUCIONES*/}

      {countriesSelected.map((country) => (
        <Grid container mb={2} ml={4} key={country.id}>
          <Grid item xs={3}>
            {(country.name as string).toUpperCase()}
          </Grid>
          <Grid item xs={9}>
            {country.countriesSolutions?.map((solution, index) => (
              <label key={index}>
                {solution.name
                  ? solution.name.toUpperCase()
                  : solution.solution
                  ? solution.solution.name.toUpperCase()
                  : ""}
                &nbsp;
                {country.countriesSolutions &&
                  country.countriesSolutions.length - 1 !== index &&
                  "-"}
                &nbsp;
              </label>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
