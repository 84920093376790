import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Stack, Alert, AlertTitle, Box, Grid, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { MyAlert } from "../../components/custom/MyAlert";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  setCloseAlert,
  setDocumentTypes,
  setDocumentTypesRedux,
  setFlagForm,
} from "../../../redux/actions/helperActions";
import { useLocation } from "react-router-dom";
import { FilterPlayer } from "./FilterPlayer";
import { MyCustomDialog } from "../../../components/Custom/MyCustomDialog";
import { ExclusionFormByAgileCheck } from "./ExclusionFormByAgileCheck";
import {
  setExclusionCategories,
  setExclusionsRedux,
  startGetExclusionCategories,
} from "../../../redux/actions/managementPlayersActions";
import { TableComponent } from "../../../components/Table/TableComponent";
import { IColumnsTable, Icons } from "../../../interfaces/components";

const columns: IColumnsTable[] = [
  { id: 1, header: "exclusions.userDocument" },
  { id: 2, header: "account.userName" },
  { id: 3, header: "exclusions.exclusionDate" },
  { id: 4, header: "exclusions.exclusionReason" },
  { id: 5, header: "exclusions.exclusionState" },
  { id: 6, header: "exclusions.action" },
];

const iconos: Icons = {
  see: false,
  edit: false,
  seeMore: false,
  add: false,
  delete: false,
  activate: true,
};

export const ExclusionsByAgileCheck = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { openAlert, messageAlert, typeAlert, flagFormCreate, loading } =
    useSelector((state: AppState) => state.helper);
  const { exclusions } = useSelector(
    (state: AppState) => state.managementPlayers
  );
  const { token } = useSelector((state: AppState) => state.auth);

  const [, setErrorDate] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(t("exclusions.newExclusion"));
  const [width, setWidth] = useState<"md" | "sm" | "lg">("md");
  const [dataFilter, setDataFilter] = useState<any>({});
  const [createdExclusion, setCreatedExclusion] = useState(false);
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { pathname } = useLocation();
  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;

  const onCloseAlert = () => {
    dispatch(setCloseAlert());
  };

  const handleCloseForm = () => {
    dispatch(setFlagForm({ flagFormCreate: false }));
  };

  useEffect(() => {
    dispatch(startGetExclusionCategories());
    dispatch(setExclusionsRedux(null));
    token && dispatch(setDocumentTypes(token));
    return () => {
      dispatch(setExclusionCategories(null));
      dispatch(setDocumentTypesRedux([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="filter">
            <FilterPlayer
              setErrorDate={setErrorDate}
              setDataFilter={setDataFilter}
              createdExclusion={createdExclusion}
              agileCheck
            />

            {enableCreated && (
              <Grid
                container
                display="flex"
                justifyContent="flex-end"
                sx={{ marginTop: "-50px", marginRight: 0 }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <Box display="flex" alignItems="center">
                    <p
                      className="txt-primary-md"
                      style={{
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        alignSelf: "center",
                      }}
                    >
                      {t("exclusions.createNewExclusion")}
                    </p>
                  </Box>
                  <Box mr={-1}>
                    <IconButton
                      onClick={() => {
                        dispatch(setFlagForm({ flagFormCreate: true }));
                      }}
                      id="openFormBtn"
                      style={{ border: "none", outline: "none" }}
                    >
                      <span className="btn-add">
                        <i
                          className="fa-solid fa-plus"
                          style={{ fontSize: 30 }}
                        ></i>
                      </span>
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            )}

            {exclusions && exclusions.data.length > 0 && (
              <Box mt={2}>
                <Box borderTop={1} borderColor="#02D0E7" mt={2} mb={2}></Box>
                <TableComponent
                  columns={columns}
                  data={exclusions.data}
                  pages={exclusions.last_page}
                  icons={iconos}
                  component={"exclusions"}
                  totalData={exclusions ? exclusions : []}
                  isSortingTable={false}
                  dataFilter={dataFilter}
                  agileCheck={true}
                />
              </Box>
            )}
          </div>
        </Col>

        <Col lg={12} md={12} sm={12}>
          {!exclusions && !loading && (
            <Stack
              mt={2}
              sx={{ width: "100%" }}
              style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
              spacing={2}
            >
              <Alert
                icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
                sx={{
                  borderLeft: `7px solid #2C97DF`,
                  backgroundColor: "#EAF5FC",
                  color: "#4b4b4b",
                }}
              >
                <div className="d-flex">
                  <AlertTitle
                    style={{ marginTop: "5px" }}
                    sx={{ color: "#333333" }}
                  >
                    {t("alerts.clickButton")}
                  </AlertTitle>
                  <AlertTitle
                    style={{ marginTop: "5px", marginLeft: "5px" }}
                    sx={{ color: "#333333", fontWeight: "bold" }}
                  >
                    {t("exclusions.seeExclusionsByAgileCheck")}
                  </AlertTitle>
                </div>
              </Alert>
            </Stack>
          )}
        </Col>
      </Row>

      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />

      <MyCustomDialog
        open={!!flagFormCreate}
        content={
          <ExclusionFormByAgileCheck
            setTitle={setTitle}
            setWidth={setWidth}
            setCreatedExclusion={setCreatedExclusion}
          />
        }
        onClose={handleCloseForm}
        title={title}
        width={width}
        icon={
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#2C97DF", marginRight: 9, fontSize: 20 }}
          ></i>
        }
      />
    </div>
  );
};
