import * as XLSX from "xlsx";

export const excelToJson = (
  file: File,
  callback: Function
): Promise<string> => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target?.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws);
      /* Update state */
      res(convertToJson(data, callback));
    };
    reader.readAsBinaryString(file);
  });
};

const convertToJson = async (csv: any, onProgress: Function) => {
  const lines = csv.split("\n");
  const result = [];
  /* Headers */
  const headers: any[] = lines[0].split(",");
  let totalParse = 0;
  for (let i = 1; i <= lines.length - 1; i++) {
    let obj: any = {};
    let currentline = lines[i].split(",");
    /* Build current line */
    for (let j = 0; j <= headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }
    /* Save */
    result.push(obj);
    totalParse += 1;
    onProgress(totalParse, lines.length - 1);
  }
  return JSON.stringify(result);
};
