import { CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import Filter from "../../../../components/Filter/Filter";
import { TableComponent } from "../../../../components/Table/TableComponent";
import {
  Enum_FieldType,
  IColumnsTable,
  IFilterField,
  Icons,
} from "../../../../interfaces/components";
import {
  setCloseAlert,
  setCountries,
  setFlagForm,
  setFlagFormEdit,
  setFlagFormConfig,
} from "../../../../redux/actions/helperActions";
import { IHelper } from "../../../../redux/interfaces/helper";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { FormCreateOperationCompany } from "../../../components/OperationCompany/FormCreateOperationCompany";
import "./ManagementCompany.scss";
import { StepsConfigOperationCompany } from "../../../components/OperationCompany/StepsConfigOperationCompany";
import { MyAlert } from "../../../components/custom/MyAlert";
import {
  setActiveCompany,
  startGetCompanies,
} from "../../../../redux/actions/managementCompanyActions";
import { useLocation } from "react-router-dom";

export const ManagementCompany = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { token } = useSelector((state: AppState) => state.auth);
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const { countries } = useSelector((state: AppState) => state.helper);
  const { data } = useSelector((state: AppState) => state.search);
  const [loading, setLoading] = useState(false);
  const [filterFields, setFilterFields] = useState<IFilterField[]>([]);
  const { flagFormCreate, flagFormConfig, openAlert, messageAlert, typeAlert } =
    useSelector((state: AppState) => state.helper);
  const setFlagFormHelper: IHelper = {
    flagFormCreate: true,
  };
  const [dataFilter, setDataFilter] = useState<any>({});
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { pathname } = useLocation();
  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;

  useEffect(() => {
    const fetchCountries = async (token: string) => {
      setLoading(true);

      await dispatch(setCountries(token));

      setLoading(false);
    };
    if (token) {
      fetchCountries(token);
    }
  }, [dispatch, token]);

  useEffect(() => {
    dispatch(setFlagForm({ flagFormCreate: false }));
    dispatch(setFlagFormEdit({ flagFormEdit: false }));
    dispatch(setFlagFormConfig({ flagFormConfig: false }));
    if (countries) {
      const FilterFileds: IFilterField[] = [
        {
          label: t("companyManagement.companyName"),
          type: Enum_FieldType.TEXT,
        },
        {
          label: t("companyManagement.nit"),
          type: Enum_FieldType.TEXT,
        },
        {
          label: t("user.country"),
          type: Enum_FieldType.SELECT,
          options: [
            ...countries.map((country) => ({
              id: country.id,
              label: country.name,
              value: country.id,
            })),
          ],
        },
        {
          label: t("filters.startDate"),
          type: Enum_FieldType.DATE,
        },
        {
          label: t("filters.endDate"),
          type: Enum_FieldType.DATE,
        },
      ];
      setFilterFields(FilterFileds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);
  const handleShowForm = () => {
    dispatch(setActiveCompany(null));
    dispatch(setFlagForm(setFlagFormHelper));
  };

  const columns: IColumnsTable[] = [
    { id: 1, header: "companyManagement.companyName" },
    { id: 2, header: "companyManagement.nit" },
    { id: 3, header: "user.country" },
    { id: 4, header: "table.creationDate" },
    { id: 5, header: "table.state" },
    { id: 6, header: "table.createdBy" },
    { id: 7, header: "table.actions" },
  ];

  const iconos: Icons = {
    see: true,
    edit: true,
    delete: false,
    seeMore: false,
    add: false,
  };
  const onCloseAlertMenu = () => {
    dispatch(setCloseAlert());
  };

  useEffect(() => {
    const fetchOperators = async () => {
      await dispatch(startGetCompanies());
    };
    fetchOperators();
    return () => {
      dispatch(setActiveCompany(null));
      dispatch(setFlagForm({ flagFormCreate: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content w-100">
      <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {flagFormCreate ? (
          <Col xs={10}>
            <FormCreateOperationCompany />
          </Col>
        ) : (
          <Col lg={12} md={12} sm={12}>
            <div className="W-100">
              <Row style={{ marginBottom: 10 }}>
                <Col xs={12}>
                  <Typography color="text.primary" style={{ fontSize: "16px" }}>
                    {t("companyManagement.title")}
                  </Typography>
                </Col>
              </Row>
              {flagFormConfig ? (
                <StepsConfigOperationCompany />
              ) : (
                <div className="white-container">
                  {loading ? (
                    <Grid container className={"paperLoading"}>
                      <Box mx="auto" my="40vh">
                        <CircularProgress
                          className={"colorLoading"}
                          data-testid={"circularProgress"}
                        />
                      </Box>
                    </Grid>
                  ) : (
                    <>
                      {enableCreated && (
                        <Row>
                          <Col
                            xs={12}
                            style={{
                              display: " flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-around"
                              mb={2}
                            >
                              <Box display="flex" alignItems="center">
                                <p
                                  className="txt-primary-md"
                                  style={{
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    alignSelf: "center",
                                  }}
                                >
                                  {t("companyManagement.createCompany")}
                                </p>
                              </Box>
                              <Box>
                                <IconButton
                                  onClick={handleShowForm}
                                  id="openFormBtn"
                                  style={{ border: "none", outline: "none" }}
                                >
                                  <span className="btn-add">
                                    <i
                                      className="fa-solid fa-plus"
                                      style={{ fontSize: 30 }}
                                    ></i>
                                  </span>
                                </IconButton>
                              </Box>
                            </Box>
                          </Col>
                        </Row>
                      )}

                      <Filter
                        fields={filterFields}
                        component={"managementCompany"}
                        setDataFilter={setDataFilter}
                      />

                      {data ? (
                        <>
                          {data.data.length > 0 && (
                            <TableComponent
                              columns={columns}
                              data={data.data}
                              pages={data.last_page}
                              icons={iconos}
                              component={"managementCompany"}
                              totalData={data}
                              isSortingTable={true}
                              dataFilter={dataFilter}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {companies && companies.data.length > 0 && (
                            <TableComponent
                              columns={columns}
                              data={companies.data}
                              pages={companies.last_page}
                              icons={iconos}
                              component={"managementCompany"}
                              totalData={companies}
                              isSortingTable={true}
                              dataFilter={dataFilter}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </Col>
        )}

        <MyAlert
          open={openAlert}
          message={messageAlert ? messageAlert : ""}
          typeAlert={typeAlert}
          handleClose={onCloseAlertMenu}
        />
      </Row>
    </div>
  );
};
