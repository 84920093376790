import { FC } from "react";
import TableCell from "@mui/material/TableCell";
import "../tabsGamingActivity.scss";
import { useTranslation } from "react-i18next";

import {
  IBingoGameActivity,
} from "../../../../../interfaces/casino";
import moment from "moment";
import { GameResultBingo } from "../../../../../enums/enums";

interface Props {
  row: IBingoGameActivity;
}

export const RowDataTableBingo: FC<Props> = ({ row }) => {
  const { t } = useTranslation();

  const statusClass = row.status === GameResultBingo.LOST ? "loss" : "open";
  const status: { [key: number]: string } = {
    1: t("betResults.won"),
    2: t("betResults.loss"),
    3: t("betResults.wonBonus"),
  };

  return (
    <>
      <TableCell align="center">
        {moment(row.createAt).format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
      <TableCell align="center">{row.id}</TableCell>
      <TableCell align="center">{row.amountBet}</TableCell>
      <TableCell align="center">{row.awardsBet}</TableCell>
      <TableCell align="center">{row.roomId}</TableCell>
      <TableCell align="center">
        <div className={statusClass}>
          {status[`${row.status}`].toUpperCase()}
        </div>
      </TableCell>
    </>
  );
};
