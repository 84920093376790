import React, { FC } from "react";
import { headersAnonymousBetsReport } from "../../../helpers/headersTables";
import { Text, View } from "@react-pdf/renderer";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import moment from "moment";
import { betStatuses } from "../../../helpers/betStatus";
import { useTranslation } from "react-i18next";
interface props {
  page: any[];
}
export const BetsAnonymousPdf: FC<props> = ({ page }) => {
  const { t } = useTranslation();
  const columns = headersAnonymousBetsReport;

  const calculateFee = (data: any) => {
    let fees: number = 1;
    data.events.forEach((item: any) => {
      const calculateFee =
        data.eventCount > 1
          ? data.betStakes[0].odds
          : item.markets[0]
          ? item.markets[0].odds * fees
          : 1;
      fees = calculateFee;
    });
    return fees;
  };
  const calculateNewFee = (data: any) => {
    let newFees: any;
    data.events.forEach((item: any) => {
      newFees =
        data.eventCount > 1
          ? data.betStakes[0].odds
          : item.markets[0]
          ? item.markets[0].odds * 1
          : 1;
    });
    return newFees;
  };
  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, index) => (
          <View key={index} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>

      <View style={{ flex: 1 }}>
        {page.map((data) => {
          const fees = calculateFee(data);
          const newFees = calculateNewFee(data);

          return (
            <>
              <View key={data.id} style={stylesPdf.row}>
                <View style={[stylesPdf.col, stylesPdf.pCol]}>
                  <Text style={stylesPdf.textContent}>{data.id}</Text>
                </View>
                <View style={[stylesPdf.col, stylesPdf.pCol]}>
                  <Text style={stylesPdf.textContent}>{data.reference}</Text>
                </View>
                <View style={[stylesPdf.col, stylesPdf.pCol]}>
                  <Text style={stylesPdf.textContent}>
                    {moment(data.createAt).format("DD/MM/YYYY")}
                  </Text>
                </View>
                <View style={[stylesPdf.col, stylesPdf.pCol]}>
                  <Text style={stylesPdf.textContent}>
                    {moment(data.createAt).format("HH:mm:ss A")}
                  </Text>
                </View>
                <View style={[stylesPdf.col, stylesPdf.pCol]}>
                  <Text style={stylesPdf.textContent}>
                    {"$ "}
                    {data.status === "S"
                      ? data.transactions && data.transactions.length > 0
                        ? parseFloat(data.transactions[0].amount).toFixed(2)
                        : (parseFloat(data.amount) * fees).toFixed(2)
                      : "0"}
                  </Text>
                </View>
                <View style={[stylesPdf.col, stylesPdf.pCol]}>
                  <Text style={stylesPdf.textContent}>
                    {"$ " +
                      ` ${
                        data.status === "S" &&
                        data.transactions &&
                        data.transactions.length > 0
                          ? (
                              parseFloat(data.transactions[0].amount) / newFees
                            ).toFixed(2)
                          : data.bonusAccountId
                          ? (data.betStakes[0].betstake / 100).toFixed(2)
                          : data.amount
                      }`}
                  </Text>
                </View>
                <View style={[stylesPdf.col, stylesPdf.pCol]}>
                  <Text style={stylesPdf.textContent}>
                    {betStatuses
                      .find((status) => status.value === data.status)
                      ?.description.toUpperCase()}
                    {data.paid && ` - ${t("betState.paid").toUpperCase()}`}
                  </Text>
                </View>
              </View>
            </>
          );
        })}
      </View>
    </>
  );
};
