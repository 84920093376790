import {
  IActionsPermissions,
  IFormRoles,
  IQueryFilterRoles,
  IRolesParams,
} from "../../interfaces/roles";
import {
  getAllRoles,
  getRoles,
  getRolesByPage,
} from "../../services/helperService";
import {
  deleteRolesService,
  filterRoles,
  saveRoles,
  updateRoles,
} from "../../services/managementRoles";
import { IPaginationRoles, IRoles } from "../interfaces/users";
import { IRolesActions } from "../reducers/managementRoles";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import {
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";
import { arraySearch, setChangeData } from "./searchActions";
import { uiSetError, uiSetErrorMessage } from "./uiAction";

export const setRoles = (
  token: string,
  idOperator?: number | undefined,
  noLoading?: boolean,
  params?: any
) => {
  return async (dispatch: Function, getState: Function) => {
    const { component } = (getState() as AppState).helper;
    const { user } = (getState() as AppState).auth;
    try {
      if (!noLoading) {
        dispatch(setLoadingState({ loading: true }));
      }
      if(user?.operator){
        params.operatorId = user.operator.id;
      }
      const isAssociate = component === "associates";

      const isSuper = idOperator === undefined;
      //SI ES SUPER TRAE TODOS LOS ROLES, DE LO CONTRARIO SOLO TRAE LOS QUE CORRESPONDAN AL OPERADOR
      let response =
        isSuper || user?.roles?.name === "ADMIN_OPERATOR"
          ? await getAllRoles(token, params)
          : await getRoles(
              token,
              idOperator?.toString(),
              isAssociate,
              isSuper,
              params
            );
      if (isSuper) {
        const { data } = response;
        const filterData = data.filter(
          (rol: any) => rol.name !== "SUPER_ADMIN"
        );
        response.data = filterData;
      }

      if (response) {
        const data: IRoles[] = response;
        dispatch(setRolesRedux(data));
      } else {
        dispatch(uiSetError(true));
        dispatch(uiSetErrorMessage("alerts.errorSetRoles"));
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (!noLoading) {
        dispatch(setLoadingState({ loading: false }));
      }
    }
  };
};
export const startGetRolesByPage = (
  token: string,
  page: number,
  idOperator?: number | undefined,
  filter?: boolean | null
) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    try {
      dispatch(setLoadingState({ loading: true }));
      const response = await getRolesByPage(
        token,
        page.toString(),
        rowsPerPage,
        idOperator?.toString()
      );

      if (response) {
        const { data: dataRs } = response;
        const filterData = dataRs.filter((rol: any) =>
          rol.name !== "SUPER_ADMIN" && rol.rol
            ? rol.rol.name !== "SELLER"
            : rol.name !== "SELLER"
        );

        response.data = filterData;

        const data: IRoles[] = response;
        if (filter) {
          await dispatch(arraySearch(response));
        } else {
          await dispatch(setRolesRedux(data));
        }
      } else {
        dispatch(uiSetError(true));
        dispatch(uiSetErrorMessage("alerts.errorSetRoles"));
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setLoadingState({ loading: false }));
  };
};

export const setRolesRedux = (roles: IRoles[] | null | IPaginationRoles) => ({
  type: Types.roles,
  payload: roles,
});
export const startSaveRoles = (
  roles: IFormRoles[],
  operatorId: number | null
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    if (user && token) {
      const id = operatorId ? operatorId : user.operator.id;

      try {
        for (const rol of roles) {
          const permissions: IActionsPermissions[] = [];
          rol.modules.forEach((module) => {
            const { create, edit } = module.permissions;
            const objPermission: IActionsPermissions = {
              created: create ? 1 : 0,
              updated: edit ? 1 : 0,
              deleted: module.permissions.delete ? 1 : 0,
              readed: module.permissions.read ? 1 : 0,
              menu: module.idMenu,
              menuItem:
                module.idModule === "" ? null : (module.idModule as number),
            };
            permissions.push(objPermission);
          });
          const rolParams: IRolesParams = {
            name: rol.name,
            operator: id,
            actionsPermissions: permissions,
            description: rol.description,
            state: rol.state,
            notification: rol.notification as any,
          };
          await saveRoles(rolParams, token);
        }
        dispatch(arraySearch(null));
        await dispatch(
          setRoles(token, operatorId ? undefined : user.operator.id)
        );
        dispatch(setMessageAlert("alerts.createRolSuccess"));
        dispatch(setTypeAlert("success"));
        await dispatch(setOpenAlert());
      } catch (error) {
        dispatch(setMessageAlert("alerts.errorSaveRol"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    }
  };
};
export const setActiveRol = (rol: IRoles | null): IRolesActions => ({
  type: Types.setActiveRol,
  payload: rol,
});
export const startEditRol = (
  rolesData: IFormRoles[],
  idRol: number,
  isTableAction?: boolean,
  isFilterByState?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { data } = (getState() as AppState).search;
    const { rowsPerPage } = (getState() as AppState).helper;
    const { roles } = (getState() as AppState).managementRoles;
    if (user && token) {
      let response: any;
      try {
        for (const rol of rolesData) {
          const permissions: IActionsPermissions[] = [];
          rol.modules.forEach((module) => {
            const { create, edit } = module.permissions;
            const objPermission: IActionsPermissions = {
              created: create ? 1 : 0,
              updated: edit ? 1 : 0,
              deleted: module.permissions.delete ? 1 : 0,
              readed: module.permissions.read ? 1 : 0,
              menu: module.idMenu,
              menuItem:
                module.idModule === "" ? null : (module.idModule as number),
              id: module.permissions.id,
            };
            permissions.push(objPermission);
          });
          const rolParams: IRolesParams = {
            id: idRol,
            name: rol.name,
            actionsPermissions: permissions,
            description: rol.description,
            state: rol.state,
            notification: rol.notification as any,
          };
          response = await updateRoles(rolParams, token, idRol);

          if (response && !response.error) {
            if (isTableAction) {
              const newData = data ? { ...data } : roles && { ...roles };
              newData.data =
                data && isFilterByState
                  ? newData.data.filter((el: any) =>
                      el.rol ? el.rol.id !== idRol : el.id !== idRol
                    )
                  : newData.data.map((el: any) =>
                      el.rol
                        ? el.rol.id === idRol
                          ? { ...el, state: rol.state }
                          : el
                        : el.id === idRol
                        ? { ...el, state: rol.state }
                        : el
                    );
              newData.total =
                data && isFilterByState ? data.total - 1 : newData.total;
              newData.last_page =
                data && isFilterByState
                  ? Math.ceil((data.total - 1) / rowsPerPage)
                  : newData.last_page;

              dispatch(data ? arraySearch(newData) : setRolesRedux(newData));
            } else {
              dispatch(arraySearch(null));
            }

            dispatch(setMessageAlert("alerts.updateRolSuccess"));
            dispatch(setTypeAlert("success"));
            dispatch(setOpenAlert());
          } else {
            dispatch(setMessageAlert("alerts.updateRolError"));
            dispatch(setTypeAlert("error"));
            dispatch(setOpenAlert());
          }
        }
      } catch (error) {
        dispatch(setMessageAlert("alerts.updateRolError"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    }
  };
};
export const startDeleteRol = (idRol: number, filter?: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { data } = (getState() as AppState).search;
    const { roles } = (getState() as AppState).managementRoles;

    if (user && token) {
      const response = await deleteRolesService(token, idRol);

      if (response.affected === 1) {
        data && dispatch(setChangeData(true));

        const newData = data ? { ...data } : roles && { ...roles };
        newData.data = newData.data.filter((el: any) =>
          el.rol ? el.rol.id !== idRol : el.id !== idRol
        );
        const dataSearchFilter = {
          data: newData.data,
          total: newData.total - 1,
          page: newData.page,
          last_page: newData.last_page,
        };

        dispatch(
          data ? arraySearch(dataSearchFilter) : setRolesRedux(dataSearchFilter)
        );

        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.deleteRolSuccess"));
        dispatch(setTypeAlert("success"));
        dispatch(setActiveRol(null));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.deleteRolError"));
        dispatch(setTypeAlert("error"));
      }
    }
  };
};
export const startFilteringRoles = (
  params: IQueryFilterRoles,
  superAdmin: boolean | null,
  page?: number | null,
  isFilter?: boolean | null,
  cleanFilter?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    params.state = params.state.toString();
    params.permission = params.permission.toString();
    if (token) {
      let finalValues: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });
      dispatch(setLoadingState({ loading: true }));
      const response =
        user &&
        (await filterRoles(
          token,
          user.operator ? user.operator.id : null,
          finalValues,
          superAdmin,
          page,
          rowsPerPage,
          isFilter
        ));

      if (response && !response.error) {
        if (cleanFilter) {
          dispatch(arraySearch(null));
          dispatch(setRolesRedux(response));
        } else {
          dispatch(arraySearch(response));
        }
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorSetRoles"));
        dispatch(setTypeAlert("error"));
      }
      dispatch(setLoadingState({ loading: false }));
    }
  };
};
