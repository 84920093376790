import React from "react";
import { Box, Grid } from "@mui/material";
import { DealerInfo } from "../../components/transactions/irrigation/DealerInfo";
import { GenerateTransferDealer } from "../../components/transactions/irrigation/GenerateTransferDealer";
import { IrrigationDealerHistory } from "../../components/transactions/irrigation/IrrigationDealerHistory";

export const IrrigationDealer = () => {
  return (
    <div className="content w-100 mb-4">
      <Box sx={{ ml: 3, mt: 2 }} width={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
           <DealerInfo/>
          </Grid>
          <Grid item xs={12} md={6}>
            <GenerateTransferDealer/>
          </Grid>
          <Grid item xs={12}>
            <IrrigationDealerHistory/>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
