import React from "react";
import { Grid } from "@mui/material";
import "./takeOver.scss";
import { useTranslation } from "react-i18next";
import { MyCharts } from "../../../../components/Custom/MyCharts";
import { RankingCardsStatistics } from "../../../../components/Statistics/RankingCardsStatistics";
import { CircularProgressWithLabel } from "../../../../components/Custom/CircularProgress";
import moment from "moment";

export const TakeOver = () => {
  const { t } = useTranslation();
  const fakeListRanking = [
    {
      id: 1,
      name: "Lorem ipsum 25.000",
    },
    {
      id: 2,
      name: "Lorem ipsum 25.000",
    },
    {
      id: 3,
      name: "Lorem ipsum 25.000",
    },
    {
      id: 4,
      name: "Lorem ipsum 25.000",
    },
    {
      id: 5,
      name: "Lorem ipsum 25.000",
    },
  ];
  return (
    <div className="white-container">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyCharts
            variant={{ type: "area" }}
            title=""
            series={[
              {
                name: t("bets.sports"),
                color: "#0099FF",
                data: [
                  { x: moment.utc().valueOf(), y: 18000 },
                  { x: moment.utc().add(2, "days"), y: 8000 },
                  { x: moment.utc().add(4, "days"), y: 22000 },
                  { x: moment.utc().add(8, "days"), y: 14000 },
                ],
              },
              {
                name: t("sidebar.casino"),
                color: "#02C5EC",

                data: [
                  { x: moment.utc().valueOf(), y: 27000 },
                  { x: moment.utc().add(3, "days"), y: 9000 },
                  { x: moment.utc().add(5, "days"), y: 5000 },
                  { x: moment.utc().add(8, "days"), y: 20000 },
                ],
              },
              {
                name: t("bets.casinoLive"),
                color: "#036A76",

                data: [
                  { x: moment.utc().valueOf(), y: 10000 },
                  { x: moment.utc().add(1, "days"), y: 20000 },
                  { x: moment.utc().add(3, "days"), y: 8000 },
                  { x: moment.utc().add(8, "days"), y: 15000 },
                ],
              },
            ]}
            options={{
              xAxis: {
                type: "datetime",
              },
              yAxis: {
                title: {
                  text: "",
                },
                labels: {
                  format: "{value}",
                },
              },
              plotOptions: {
                /*  series: {
                    pointStart: 2014
                }, */
                area: {
                  fillOpacity: 0.5,
                },
              },
              tooltip: {
                formatter: function () {
                  //return  '<b>' + this.series.name +'</b><br/>'+ this.y +'</b><br/>'+moment(this.x).format("YYYY/MM/DD")
                  return (
                    "<b>" +
                    moment(this.x).format("MMMM") +
                    "</b><br/>" +
                    this.y?.toLocaleString("en-US").split(",").join(".")
                  );
                },
                backgroundColor: "#265C8099",
                style: {
                  color: "#FFFFFF",
                },
              },
              legend: {
                align: "right",
                layout: "vertical",
                verticalAlign: "top",
                itemMarginTop: 10,
                itemMarginBottom: 20,
                symbolHeight: 30,
                symbolWidth: 30,
                symbolRadius: 10,
                useHTML: true,

                className: "legend-container",
              },
              responsive: {
                rules: [
                  {
                    condition: {
                      maxWidth: 300,
                    },
                    chartOptions: {
                      legend: {
                        align: "center",
                        verticalAlign: "bottom",
                        layout: "horizontal",
                        symbolHeight: 15,
                        symbolWidth: 15,
                        symbolRadius: 5,
                        itemMarginTop: 5,
                        itemMarginBottom: 5,
                      },
                      chart: {
                        height: 300,
                      },
                    },
                  },
                ], //.reverse()
              },
            }}
          />
        </Grid>

        <Grid container spacing={2} mt={2}>
          <Grid xs={0.7} />
          <Grid xs={10}>
            <Grid container spacing={2}>
              {[
                `${t("bets.topEvents")} - ${t("bets.title")}`,
                `${t("bets.topGames")} - ${t("bets.title")}`,
                `${t("bets.topProviders")} - ${t("bets.title")}`,
              ].map((item) => (
                <Grid item xs={12} lg={3}>
                  <RankingCardsStatistics
                    key={item}
                    title={item}
                    list={fakeListRanking}
                  />
                </Grid>
              ))}
              <Grid item xs={12} lg={3}>
                <RankingCardsStatistics
                  title={`${t("bets.betsWithBonus")}`}
                  list={[]}
                  content={
                    <>
                      <CircularProgressWithLabel value={17}  />
                    </>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};