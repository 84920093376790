import { Types } from "../types/types";
import {
  ICompanyDB,
  ICompanyData,
  IFormConfigOperation,
} from "../../interfaces/operationCompany";

interface IManagementCompanyActions {
  type: Types;
  payload?: ICompanyDB | ICompanyData | IFormConfigOperation | any;
}

interface ICompanyDataState {
  companies: ICompanyDB | null;
  activeCompany: ICompanyData | null;
  activeBrand: IFormConfigOperation | null;
  brands: any | null;
  totalBrands: any | null;
  totalCompanies: ICompanyDB | null;
}

const initialState: ICompanyDataState = {
  companies: null,
  activeCompany: null,
  activeBrand: null,
  brands: null,
  totalBrands: null,
  totalCompanies: null,
};
export const managementCompanyReducer = (
  state = initialState,
  action: IManagementCompanyActions
): ICompanyDataState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setCompanies:
      return {
        ...state,
        companies: payload as ICompanyDB,
      };
    case Types.addCompany:
      const newCompanies = state.companies
        ? ({
            ...state.companies,
            data: [payload, ...state.companies.data],
          } as ICompanyDB)
        : state.companies;
      return {
        ...state,
        companies: newCompanies,
      };

    case Types.setActiveCompany:
      return {
        ...state,
        activeCompany: payload as ICompanyData,
      };

    case Types.updateCompany:
      const companyUpdated = state.companies
        ? ({
            ...state.companies,
            data: state.companies.data.map((row) =>
              row.id === (payload as ICompanyData).id
                ? (payload as ICompanyData)
                : row
            ),
          } as ICompanyDB)
        : state.companies;
      return {
        ...state,
        companies: companyUpdated,
      };

    case Types.setActiveBrand:
      return {
        ...state,
        activeBrand: payload as IFormConfigOperation,
      };

    case Types.setBrands:
      return {
        ...state,
        brands: payload as IFormConfigOperation[],
      };
    case Types.setTotalBrands:
      return {
        ...state,
        totalBrands: payload as IFormConfigOperation,
      };
    case Types.setTotalCompanies:
      return {
        ...state,
        totalCompanies: payload as ICompanyDB,
      };

    default:
      return state;
  }
};
