import { IMenuDB, IMenuItem, IMenuList } from "./../../interfaces/menu";
import { IRoutes } from "../../interfaces/menu";
import { Types } from "../types/types";

interface IManagementMenusActions {
  type: Types;
  payload?: IRoutes[] | IMenuList | IMenuList[] | IMenuDB | any;
}

interface IMenuDataState {
  routes: IRoutes[];
  menus: IMenuDB | null;
  totalMenus: IMenuDB | null;
  activeMenu: IMenuList | null;
  activeModule: IMenuItem | null;
}

const initialState: IMenuDataState = {
  routes: [],
  menus: null,
  totalMenus: null,
  activeMenu: null,
  activeModule: null,
};
export const managementMenuReducer = (
  state = initialState,
  action: IManagementMenusActions
): IMenuDataState => {
  const { type, payload } = action;
  switch (type) {
    case Types.loadAvailableRoutes:
      return {
        ...state,
        routes: payload as IRoutes[],
      };
    case Types.setMenus:
      return {
        ...state,
        menus: payload as IMenuDB,
      };

    case Types.setTotalMenus:
      return {
        ...state,
        totalMenus: payload as IMenuDB,
      };
    case Types.addMenu:
      const newMenus = state.menus
        ? ({
            ...state.menus,
            data: [payload.menu, ...state.menus.data],
            total: state.menus.total + 1,
            last_page: Math.ceil((state.menus.total + 1) / payload.rowsPerPage),
          } as IMenuDB)
        : state.menus;
      return {
        ...state,
        menus: newMenus,
      };

    case Types.setActiveMenu:
      return {
        ...state,
        activeMenu: payload as IMenuList,
      };

    case Types.updateMenu:
      const menusUpdated = state.menus
        ? ({
            ...state.menus,
            data: state.menus.data.map((row) =>
              row.id === (payload as IMenuList).id
                ? (payload as IMenuList)
                : row
            ),
          } as IMenuDB)
        : state.menus;
      return {
        ...state,
        menus: menusUpdated,
      };

    case Types.deleteMenu:
      const newRoutes = state.routes.filter(
        (route) => route.id?.toString() !== payload.id
      );
      const menusDelete = state.menus
        ? ({
            ...state.menus,
            data: state.menus?.data.filter(
              (menu) => menu.id?.toString() !== payload.id
            ),
            total: state.menus.total - 1,
            last_page: Math.ceil((state.menus.total - 1) / payload.rowsPerPage),
          } as IMenuDB)
        : state.menus;

      return {
        ...state,
        menus: menusDelete,
        routes: newRoutes,
      };
    case Types.deleteModule:
      if (!state.menus) {
        return state;
      }
      const indexRouteMenu = state.routes.findIndex(
        (route) =>
          !!route.menuItem.find((item) => item.id?.toString() === payload)
      );
      const indexMenu = state.menus.data.findIndex(
        (menu) =>
          !!menu.menuItem.find((item) => item.id?.toString() === payload)
      );

      const stateRoutes = [...state.routes];
      const stateMenu = [...state.menus.data];

      if (indexRouteMenu >= 0) {
        stateRoutes[indexRouteMenu].menuItem = stateRoutes[
          indexRouteMenu
        ].menuItem.filter((item) => item.id?.toString() !== payload);
      }
      if (indexMenu >= 0) {
        stateMenu[indexMenu].menuItem = stateMenu[indexMenu].menuItem.filter(
          (item) => item.id?.toString() !== payload
        );
      }
      return {
        ...state,
        menus: { ...state.menus, data: stateMenu },
        routes: stateRoutes,
      };

    case Types.setActiveModule:
      return {
        ...state,
        activeModule: payload as IMenuItem,
      };

    default:
      return state;
  }
};
