import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  Enum_FieldType,
  IColumnsTable,
  Icons,
  IFilterField,
} from "../../../../interfaces/components";
import { Box, CircularProgress, Grid, IconButton } from "@mui/material";
import {
  setCloseAlert,
  setFlagForm,
  setFlagFormEdit,
  setLoadingState,
  setRowsPerPage,
} from "../../../../redux/actions/helperActions";
import Filter from "../../../../components/Filter/Filter";
import { TableComponent } from "../../../../components/Table/TableComponent";
import { StepsNewPointSale } from "../../../components/masters/pointsOfSale/StepsNewPointSale";
import { setGetPointsSale } from "../../../../redux/actions/pointsSaleActions";
import { PointSaleDetails } from "../../../components/masters/pointsOfSale/PointSaleDetails";
import { State, Roles_Enum } from "../../../../enums/enums";
import { useLocation } from "react-router-dom";
import { getAssociatesService } from "../../../../services/associates";
import { IAssociates } from "../../../../interfaces/associate";
import { MyAlert } from "../../../components/custom/MyAlert";
import MyBreadCrumbs from "../../../../components/Custom/MyBreadCrumbs";
import { setConfigParams } from "../../../../redux/actions/configParamsActions";

export const PointsOfSale = () => {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state: AppState) => state.auth);
  const { data } = useSelector((state: AppState) => state.search);
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const [filterFields, setfilterFields] = useState<IFilterField[]>([]);
  const { pointsSale } = useSelector((state: AppState) => state.pointsSale);
  const { openAlert, messageAlert, typeAlert } = useSelector(
    (state: AppState) => state.helper
  );
  const [dataFilter, setDataFilter] = useState<any>({});
  const { pathname } = useLocation();
  const [arrayAssociates, setArrayAssociates] = useState<IAssociates | null>(
    null
  );

  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;

  const pointSaleData = pointsSale ? pointsSale.data : [];

  /**
   *  Soporta la constante para las traducciones
   */
  const { t } = useTranslation();

  /**
   * Soporta el estado boolean para mostrar el formulario de creación de puntos de venta
   */
  const { flagFormCreate } = useSelector((state: AppState) => state.helper);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;

  /**
   * Use effect inicializar datos
   * PerPage, PointsOfSale, Alerts, Loading
   */
  useEffect(() => {
    dispatch(setCloseAlert());
    dispatch(setRowsPerPage(9));
    dispatch(setFlagForm({ flagFormCreate: false }));
    //CONSULTAR EL ID DEL ASOCIADO AL QUE PERTENECE AL USUARIO SI EL ROL DEL USUARIO LOGEADO ES ASSOCIATE

    //CONSULTAR LOS ASOCIADOS DEPENDIENDO DEL OPERADOR
    const fetchAssociatesByOperator = async () => {
      if (token) {
        dispatch(setLoadingState({ loading: true }));
        const operator = !isSuper && user ? user.operator.id : null;
        const response = await getAssociatesService(token, 1, null, operator);

        if (response && !response.error) {
          setArrayAssociates(response);
        } else {
          setArrayAssociates(null);
        }
        dispatch(setGetPointsSale(token, 1));
        dispatch(setLoadingState({ loading: false }));
      }
    };
    fetchAssociatesByOperator();
    return () => {
      dispatch(setFlagForm({ flagFormCreate: false }));
      dispatch(setFlagFormEdit({ flagFormEdit: false }));

      dispatch(setConfigParams(null));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Soporta la información para los placeholders de inputs en el componente filtro
   */

  useEffect(() => {
    let filterFields: IFilterField[] = [
      {
        label: t("filters.id"),
        type: Enum_FieldType.NUMBER,
      },
      {
        label: t("pointsOfSale.associated"),
        type: Enum_FieldType.SELECT,
        options: [],
      },
      {
        label: t("pointsOfSale.name"),
        type: Enum_FieldType.TEXT,
      },
      {
        label: t("table.createdBy"),
        type: Enum_FieldType.TEXT,
      },
      {
        label: t("pointsOfSale.email"),
        type: Enum_FieldType.TEXT,
      },
      {
        label: t("pointsOfSale.address"),
        type: Enum_FieldType.TEXT,
      },
      {
        label: t("pointsOfSale.phone"),
        type: Enum_FieldType.NUMBER,
      },
      {
        label: t("form.state"),
        type: Enum_FieldType.SELECT,
        options: [
          {
            label: t("state.active"),
            value: State.ACTIVE,
          },
          {
            label: t("state.inactive"),
            value: State.INACTIVE,
          },
        ],
      },
    ];
    if (
      arrayAssociates &&
      arrayAssociates.data &&
      arrayAssociates.data.length > 0
    ) {
      filterFields[1] = {
        ...filterFields[1],
        //@ts-ignore
        options: [
          ...arrayAssociates.data
            .filter((associate: any) => associate.stateUser === 1)
            .map((associate: any) => ({
              id: associate.id,
              label: associate.name,
              value: associate.id?.toString(),
            })),
        ],
      };
    }
    setfilterFields(filterFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayAssociates]);

  /**
   * Soporta las Columnas para renderizar en el header de la tabla
   */

  const columns: IColumnsTable[] = [
    { id: 1, header: "ID" },
    { id: 2, header: "Nombre" },
    { id: 3, header: "reports.associate" },
    { id: 4, header: "dealerWallet.dealer" },
    { id: 5, header: "reports.promoter" },
    { id: 6, header: "user.address" },
    { id: 7, header: "user.phone" },
    { id: 8, header: "table.createdBy" },
    { id: 9, header: "table.state" },
    { id: 10, header: "table.actions" },
  ];

  /**
   * Soporta los íconos a usar en la tabla
   */
  const iconos: Icons = {
    seeMore: true,
    delete: false,
  };

  /**
   * Soporta el evento para abir el modal de crear
   * un punto de venta
   */
  const handleOpenForm = () => {
    dispatch(setFlagForm({ flagFormCreate: true }));
  };

  const onCloseAlert = async () => {
    dispatch(setCloseAlert());
  };

  return (
    /** CONTAINER */
    <div className="content w-100 mb-4">
      <Row>
        <Col lg={12} md={12} sm={12}>
          {/* TITTLE */}
          {/* BREADCRUMBS */}
          <Row>
            <Col xs={12}>
              <MyBreadCrumbs
                titles={[t("sidebar.masters"), t("shorcuts.pointsOfSale")]}
              />
            </Col>
          </Row>
          {/* BANDERA PARA MOSTRAR FILTROS Y TABLA Ó FORMULARIO CREACIÓN */}
          {flagFormCreate ? (
            <StepsNewPointSale />
          ) : (
            <>
              {/* DIV FILTRO Y TABLAS */}
              <div className="white-container">
                {/* CONDICIONAL MOSTRAR LOADING MIENTRAS CARGAN LOS DATOS */}
                {filterFields.length > 0 ? (
                  <>
                    {enableCreated && (
                      <Row>
                        <Col xs={12}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            mb={2}
                          >
                            <Box display="flex" alignItems="center">
                              <p
                                className="txt-primary-md"
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "uppercase",
                                  alignSelf: "center",
                                }}
                              >
                                {t("pointsOfSale.createPointOfSale")}
                              </p>
                            </Box>
                            <Box>
                              <IconButton
                                id="openFormBtn"
                                onClick={handleOpenForm}
                                style={{ border: "none", outline: "none" }}
                              >
                                <span className="btn-add">
                                  <i
                                    className="fa-solid fa-plus"
                                    style={{ fontSize: 30 }}
                                  ></i>
                                </span>
                              </IconButton>
                            </Box>
                          </Box>
                        </Col>
                      </Row>
                    )}
                    {/* FILTRO */}
                    <Filter
                      fields={filterFields}
                      component={"pointsOfSale"}
                      setDataFilter={setDataFilter}
                    />

                    {data ? (
                      <>
                        {data.data.length > 0 && (
                          <TableComponent
                            columns={columns}
                            data={data.data}
                            pages={data.last_page}
                            icons={iconos}
                            component={"pointsOfSale"}
                            totalData={data}
                            isSortingTable={true}
                            dataFilter={dataFilter}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {pointsSale && pointsSale.data.length > 0 && (
                          <TableComponent
                            columns={columns}
                            data={pointSaleData}
                            pages={pointsSale.last_page}
                            icons={iconos}
                            component={"pointsOfSale"}
                            totalData={pointsSale}
                            isSortingTable={true}
                            dataFilter={dataFilter}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  /** LOADING */
                  <Grid container className={"paperLoading"}>
                    <Box mx="auto" my="40vh">
                      <CircularProgress className={"colorLoading"} />
                    </Box>
                  </Grid>
                )}
              </div>
            </>
          )}
        </Col>
      </Row>

      <PointSaleDetails />

      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />
    </div>
  );
};
