import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, CircularProgress, Grid, ThemeProvider } from "@mui/material";

import { theme } from "../../custom/customStyles";
import {
  setActiveStep,
  setCloseAlert,
  setFlagForm,
  setFlagFormEdit,
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import { AppState } from "../../../../redux/reducers/rootReducer";

import clearLogo from "../../../../assets/svg/icon-park_clear-format.svg";
import { MyDialog } from "../../custom/MyDialog";
import { MyAlert } from "../../custom/MyAlert";
import { FormConfigPointSale } from "./FormConfigPointSale";
import { FormConfigCommissioners } from "./FormConfigCommissioners";
import {
  IAssociateStep,
  IConfigCommissional,
  IConfigGames,
  IConfigPointSale,
  IPointSaleForm,
  ISeller,
} from "../../../../interfaces/pointsSale";
import { useForm } from "../../../../hooks/useForm";
import {
  ICities,
  ICountries,
  IStates,
} from "../../../../redux/interfaces/shared";
import { FormConfigGames } from "./FormConfigGames";
import { TabsConfirmation } from "./TabsConfirmation";
import {
  setActivePointSale,
  startAddPointSale,
  startEditPointSale,
} from "../../../../redux/actions/pointsSaleActions";
import { getPointsSaleFilterService } from "../../../../services/pointsSaleService";
import { setGetPointsSale } from "../../../../redux/actions/pointsSaleActions";
import * as yup from "yup";
import { Roles_Enum } from "../../../../enums/enums";
import { validatePhoneByCountry } from "../../../../utils/utils";
import { FormConfigSellers } from "./FormConfigSellers";
import { FormConfigDealers } from "./FormConfigDealers";
import { FormConfigPromoter } from "./FormConfigPromoter";
interface Props {
  setNextStep: any;
}

export const FormCreatePointSale = ({ setNextStep }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { countries, activeStep, openAlert, messageAlert, typeAlert, loading } =
    useSelector((state: AppState) => state.helper);
  const { token, user } = useSelector((state: AppState) => state.auth);

  const [disabled, setDisabled] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [resetForm, setResetForm] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const { activePointSale } = useSelector(
    (state: AppState) => state.pointsSale
  );
  const [associatesSelected, setAssociatesSelected] = useState<
    IAssociateStep[]
  >(
    activePointSale
      ? activePointSale.associates
          .filter((el) => el.associate)
          .map((el) => {
            return { id: el.associate.id, name: el.associate.name };
          })
      : []
  );

  const [promoterSelected, setPromoterSelected] = useState<number | null>(
    activePointSale?.promotor?.id ?? null
  );

  /**
   * Soporta los estados en los selects para mostrar y filtrar
   */
  const [selectedCountry, setSelectedCountry] = useState<ICountries | null>(
    activePointSale ? activePointSale.country : null
  );
  const [selectedStates, setSelectedStates] = useState<IStates | null>(
    activePointSale ? activePointSale.state : null
  );
  const [cityData, setCity] = useState<ICities | null>(
    activePointSale ? activePointSale.city : null
  );
  const [errorCountry, setErrorCountry] = useState(false);
  const [errorAssociates, setErrorAssociates] = useState(false);
  const [errorStates, setErrorStates] = useState(false);
  const [errorCity, setErrorCity] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [noValidEmail, setNoValidEmail] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorTransfer, setErrorTransfer] = useState(false);
  const [validPhone, setValidPhone] = useState(true);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;

  /**
   * Soporat hook para los datos de commissioners seleccionados
   */
  let [commissionersData, setCommissionersData] = useState<
    IConfigCommissional[]
  >(
    activePointSale
      ? activePointSale.configCommissioners.map((el) => {
          return {
            id: el.commissional.id,
            name: el.commissional.name,
            percentage: el.percentage,
          };
        })
      : []
  );
  /**
   * 
  /**
   * Soporat hook para los datos de commissioners seleccionados
   */
  let [gamesData, setGamesData] = useState<IConfigGames[]>(
    activePointSale
      ? activePointSale.configGames.map((el) => {
          return { id: el.game.id, name: el.game.name };
        })
      : []
  );

  const defaultSellerValues: ISeller = {
    user: "",
  };
  const sellersLinked: ISeller[] = activePointSale
    ? (activePointSale as any).sellerPointSales.map((item: any) => ({
        user: item.user.id,
      }))
    : [];
  const [sellers, setSellers] = useState(
    activePointSale ? sellersLinked : [defaultSellerValues]
  );
  const dealersLinked: any[] = activePointSale
    ? (activePointSale as any).dealersPointSale.map(
        ({ dealer }: any) => dealer.id
      )
    : [];
  const [dealers, setDealers] = useState(activePointSale ? dealersLinked : []);

  let [configPointsSale, setConfigPointsSale] = useState<IConfigPointSale>();
  const [paramsPhoneError, setParamsPhoneError] = useState("");

  /**
   * Soporta los valores inciales del formulario
   */
  const initialValues: IPointSaleForm = {
    id: activePointSale?.id,
    associates: activePointSale ? activePointSale.associates : [],
    promotor: activePointSale?.promotor ? activePointSale.promotor : null,
    country: { id: 0, name: "", imgUrl: "", code: "" },
    state: { id: 0, names: "" },
    city: { id: 0, names: "" },
    name: activePointSale ? activePointSale.name : "",
    address: activePointSale ? activePointSale.address : "",
    phone: activePointSale ? activePointSale.phone : "",
    email: activePointSale ? activePointSale.email : "",
    ntc: activePointSale ? activePointSale.ntc : 0,
    allowsTransfer: activePointSale ? activePointSale.allowsTransfer : 0,
    minimumTransfers: activePointSale ? activePointSale.minimumTransfers : "",
    maximumTransfers: activePointSale ? activePointSale.maximumTransfers : "",
    stateSalesPoint: activePointSale ? activePointSale.stateSalesPoint : 1,
    configCommissioners: [],
    configGames: [],
    amountWallet: activePointSale?.walletPointSale?.amount.toString() ?? "0",
    sellers: sellersLinked,
    operator: activePointSale
      ? // @ts-ignore
        activePointSale.operator.id
      : user?.roles.name !== Roles_Enum.SUPER_ADMIN
      ? user?.operator.id
      : "",
    createdUser: activePointSale?.createdUser && activePointSale.createdUser,
    allowAnonymousSale: activePointSale
      ? activePointSale.allowAnonymousSale
      : "",
    allowAnonymousPayouts: activePointSale
      ? activePointSale.allowAnonymousPayouts
      : "",
    accountDebitNumber: activePointSale?.accountDebitNumber ?? "",
    accountCreditNumber: activePointSale?.accountCreditNumber ?? "",
    accountCreditNumberWithdrawals:
      activePointSale?.accountCreditNumberWithdrawals ?? "",
    accountDebitNumberWithdrawals:
      activePointSale?.accountDebitNumberWithdrawals ?? "",
    accountCreditNumberWithdrawalTaxes:
      activePointSale?.accountCreditNumberWithdrawalTaxes ?? "",
    accountDebitNumberWithdrawalTaxes:
      activePointSale?.accountDebitNumberWithdrawalTaxes ?? "",
    latitude: activePointSale?.latitude ?? "",
    longitude: activePointSale?.longitude ?? "",
    dealers: [],
    type: activePointSale?.type ?? "",
    commissionType: activePointSale ? activePointSale.commissionType : "",
  };

  const [formValues, handleInputChange] = useForm({
    ...initialValues,
  });

  const schema = yup.object().shape({
    email: yup.string().email(),
  });

  useEffect(() => {
    return () => {
      dispatch(setActivePointSale(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @param e Event onchange autocomplete select associates
   * @param v Value type associate
   *
   * Soporta el evento onChange para los asociados seleccionados
   */
  const handleSelectAssociates = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: IAssociateStep[]
  ) => {
    !value ? setErrorAssociates(true) : setErrorAssociates(false);
    value && setAssociatesSelected(value);
  };

  const onChangeCountry = async (value: ICountries | null) => {
    !value ? setErrorCountry(true) : setErrorCountry(false);
    if (value && value.id !== selectedCountry?.id) {
      setSelectedCountry(value);
      setSelectedStates(null);
      setCity(null);
    }
  };

  /**
   * Soporta el evento para seleccionar los estados
   */

  const onChangeStates = async (value: IStates | null) => {
    !value ? setErrorStates(true) : setErrorStates(false);
    if (value && value.id !== selectedStates?.id) {
      setSelectedStates(value);
      setCity(null);
    }
  };

  /**
   * Soporta el evento para seleccionar las ciudades
   */
  const onChangeCities = async (value: ICities | null) => {
    !value ? setErrorCity(true) : setErrorCity(false);
    value && value.id !== cityData?.id && setCity(value);
  };

  /**
   * Soporta el evento para guardar los commissioners
   */
  const setCommissioners = (commissionalSelected: IConfigCommissional) => {
    const { id } = commissionalSelected;
    let currentCommissional = commissionersData;

    const existCommissional = currentCommissional.find(
      (item) => item.id === id
    );

    if (existCommissional) {
      currentCommissional = currentCommissional.filter(
        (item) => item.id !== id
      );
      setCommissionersData(currentCommissional);
    } else {
      commissionalSelected.percentage = "";
      setCommissionersData([...commissionersData, commissionalSelected]);
    }
  };

  const setPercentageByCommissioner = (
    id: number,
    percentage: number | string
  ) => {
    setCommissionersData(
      commissionersData.map((el: IConfigCommissional) =>
        el.id === id
          ? {
              ...el,
              percentage,
            }
          : el
      )
    );
  };

  /**
   * Soporta el evento para guardar los games
   */
  const setGames = (gameSelected: IConfigGames) => {
    const { id } = gameSelected;
    let currentGame = gamesData;

    const existGame = currentGame.find((item) => item.id === id);

    if (existGame) {
      currentGame = currentGame.filter((item) => item.id !== id);
      setGamesData(currentGame);
    } else {
      setGamesData([...gamesData, gameSelected]);
    }
  };

  /**
   * Soportan los eventos para crear o eliminar cajas
   */

  const handleAddSeller = () => {
    setSellers((prev) => [...prev, defaultSellerValues]);
  };
  const handleDeleteSeller = (index: number) => {
    setSellers(sellers.filter((_, i: number) => i !== index));
  };

  const handleChangeSeller = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setSellers(
      sellers.map((seller: ISeller, i: number) =>
        i === index
          ? {
              ...seller,
              [event.target.name]: event.target.value,
            }
          : seller
      )
    );
  };
  const handleAddDealer = () => {
    setDealers((prev) => [...prev, ""]);
  };
  const handleDeleteDealer = (index: number) => {
    setDealers(dealers.filter((_, i: number) => i !== index));
  };
  const handleChangeDealer = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setDealers(
      dealers.map((dealer: number, i: number) =>
        i === index ? event.target.value : dealer
      )
    );
  };

  const handleNext = async () => {
    setIsSubmit(true);
    /**
     * ACTIVE STEP FORM SALES POINTS
     */
    if (activeStep === 0) {
      const isValid = await validateFormFields(formValues); // VALIDATE FORM

      if (isValid) {
        dispatch(setLoadingState({ loading: true }));
        const exists = await verifyExistsPointSale();
        if (exists) {
          setActiveStep(0);
          setDisabled(true);

          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.dataDuplicate"));
          return dispatch(setTypeAlert("error"));
        }
      }
    }
    /**
     * ACTIVE STEP CONFIG COMMISSIONERS
     */
    if (activeStep === 1) {
      if (commissionersData.length <= 0) {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("pointsOfSale.commisionalSelected"));
        return dispatch(setTypeAlert("info"));
      }
    }
    /**
     * ACTIVE STEP CONFIG GAMES
     */
    if (activeStep === 2) {
      if (gamesData.length <= 0) {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("pointsOfSale.gameSelected"));
        return dispatch(setTypeAlert("info"));
      }
    }

    if (activeStep === 3) {
      setIsSubmit(false);
    }

    dispatch(setActiveStep(activeStep + 1));
  };
  const validateSellers = () => {
    const isValid =
      sellers.length > 0 &&
      sellers.filter((seller) => seller.user !== "").length === sellers.length;
    return isValid;
  };

  const handlePrevious = () => {
    dispatch(setActiveStep(activeStep - 1));
  };

  const verifyExistsPointSale = async () => {
    const isValidName = await validateAvaliableName(formValues.name);
    setErrorName(!isValidName);

    const isValidEmail = await validateAvailableEmail(formValues.email);
    setErrorEmail(!isValidEmail);
    dispatch(setLoadingState({ loading: false }));
    return !isValidEmail || !isValidName;
  };

  // Efecto para manejar el next button
  useEffect(() => {
    const validateNextStep = async () => {
      setNextStep(true);
      if (activeStep === 0) {
        const isValid = await validateFormFields(formValues);
        setDisabled(!isValid);
        setNextStep(false);
      }
      if (activeStep === 1) {
        const withoutPercentage = !!commissionersData.find((com) => {
          return (
            !com.hasOwnProperty("percentage") ||
            com.percentage === "" ||
            (com.percentage && parseInt(com.percentage as any) > 100)
          );
        });

        const isValid = commissionersData.length > 0 && !withoutPercentage;
        setNextStep(isValid);
        setDisabled(!isValid);
      }
      if (activeStep === 2) {
        const isValid = gamesData.length > 0;
        setNextStep(isValid);
        setDisabled(!isValid);
      }
      if (activeStep === 3) {
        const isValid = validateSellers();

        setNextStep(isValid);
        setDisabled(!isValid);
      }
      if (activeStep === 6) {
        formValues.associates = associatesSelected;
        formValues.country = selectedCountry;
        formValues.state = selectedStates;
        formValues.city = cityData;
        formValues.configCommissioners = commissionersData;
        formValues.configGames = gamesData;
        formValues.sellers = sellers;
        formValues.dealers = dealers;
        formValues.promotor = promoterSelected;

        const finalData: IConfigPointSale = {
          associates: formValues.associates.map((el: IAssociateStep) => {
            return { associate: { id: el.id, name: el.name } };
          }),
          promotor: promoterSelected,
          country: selectedCountry,
          state: selectedStates,
          city: cityData,
          name: formValues.name,
          address: formValues.address,
          phone: formValues.phone,
          email: formValues.email,
          ntc: formValues.ntc,
          allowsTransfer: formValues.allowsTransfer,
          minimumTransfers: formValues.minimumTransfers,
          maximumTransfers: formValues.maximumTransfers,
          stateSalesPoint: formValues.stateSalesPoint,
          operator: formValues.operator,
          sellers: formValues.sellers,
          amountWallet: formValues.amountWallet,
          configCommissioners: formValues.configCommissioners.map(
            (el: IConfigCommissional) => {
              return {
                commissional: { id: el.id, name: el.name },
                percentage: el.percentage,
              };
            }
          ),
          configGames: formValues.configGames.map((el: IConfigGames) => {
            return { game: { id: el.id, name: el.name } };
          }),
          allowAnonymousPayouts: formValues.allowAnonymousPayouts,
          allowAnonymousSale: formValues.allowAnonymousSale,
          accountDebitNumber: formValues.accountDebitNumber,
          accountCreditNumber: formValues.accountCreditNumber,
          accountCreditNumberWithdrawals:
            formValues.accountCreditNumberWithdrawals,
          accountDebitNumberWithdrawals:
            formValues.accountDebitNumberWithdrawals,
          accountCreditNumberWithdrawalTaxes:
            formValues.accountCreditNumberWithdrawalTaxes,
          accountDebitNumberWithdrawalTaxes:
            formValues.accountDebitNumberWithdrawalTaxes,
          latitude: formValues.latitude,
          longitude: formValues.longitude,
          dealers: formValues.dealers,
          type: formValues.type,
          commissionType: formValues.commissionType,
        };

        setConfigPointsSale(finalData);
      }
    };

    validateNextStep();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formValues.allowsTransfer,
    formValues.ntc,
    formValues.phone,
    formValues.email,
    formValues.name,
    formValues.address,
    formValues.maximumTransfers,
    formValues.minimumTransfers,
    formValues.allowAnonymousPayouts,
    formValues.allowAnonymousSale,
    formValues.operator,
    formValues.associates,
    selectedCountry,
    associatesSelected,
    promoterSelected,
    selectedStates,
    cityData,
    gamesData,
    commissionersData,
    activeStep,
    sellers,
    formValues.accountDebitNumber,
    formValues.accountCreditNumber,
    formValues.accountCreditNumberWithdrawals,
    formValues.accountDebitNumberWithdrawals,
    formValues.accountDebitNumberWithdrawalTaxes,
    formValues.accountCreditNumberWithdrawalTaxes,
    formValues.latitude,
    formValues.longitude,
    dealers,
    formValues.type,
    formValues.commissionType,
  ]);
  const validatePhoneNumber = () => {
    if (!countries) {
      return;
    }
    const currentCountry = countries?.find(
      (country) => country.id === selectedCountry?.id
    );

    if (!currentCountry) {
      return;
    }

    const { valid, length, startOf } = validatePhoneByCountry(
      currentCountry.code,
      formValues.phone.toString()
    ); // Validar el número de teléfono

    if (!valid) {
      const params: any = {
        length,
        rest: startOf
          ? t("user.startOfNumber", {
              numbers: startOf.toString(),
            })
          : "",
      };

      setParamsPhoneError(params);
    }
    return valid;
  };
  /**
   * soporta el evento para las validaciones
   */
  const validateFormFields = async (data: any) => {
    let isValid = true;
    associatesSelected.length === 0
      ? setErrorAssociates(true)
      : setErrorAssociates(false);
    !selectedCountry ? setErrorCountry(true) : setErrorCountry(false);
    !selectedStates ? setErrorStates(true) : setErrorStates(false);
    !cityData ? setErrorCity(true) : setErrorCity(false);

    const {
      minimumTransfers,
      maximumTransfers,
      allowsTransfer,
      allowAnonymousPayouts,
      allowAnonymousSale,
      amountWallet,
      accountDebitNumber,
      accountCreditNumber,
      accountCreditNumberWithdrawals,
      accountDebitNumberWithdrawals,
      accountDebitNumberWithdrawalTaxes,
      accountCreditNumberWithdrawalTaxes,
      latitude,
      longitude,
      ...rest
    } = data;

    if (allowsTransfer === 1) {
      rest.minimumTransfers = minimumTransfers;
      rest.maximumTransfers = maximumTransfers;
    }
    rest.allowsTransfer = allowsTransfer;

    Object.entries(rest).forEach(([key, value], index) => {
      //@ts-ignore
      if (value === "" || value === 0) {
        isValid = false;
      }
    });

    const emailCorrect = await validateEmail(data.email);
    const validPhone = countries ? await validatePhoneNumber() : true;

    return (
      isValid &&
      validPhone &&
      emailCorrect &&
      selectedCountry &&
      selectedStates &&
      cityData &&
      !errorCountry &&
      !errorStates &&
      !errorCity &&
      associatesSelected.length > 0 &&
      (allowsTransfer === 2 ||
        (allowsTransfer === 1 &&
          Number(maximumTransfers) > Number(minimumTransfers)))
    );
  };
  const validateAvailableEmail = async (email: string) => {
    if (!token) {
      return false;
    }

    if (activePointSale && email === activePointSale?.email) {
      return true;
    }

    const response = await getPointsSaleFilterService(token, { email }, true);
    return response.data.length === 0;
  };

  /**
   *
   * @param email
   * @returns Validar email correcto
   */
  const validateEmail = async (email: string) => {
    return await schema.isValid({ email: formValues.email });
  };

  useEffect(() => {
    const checkEmailValidation = async () => {
      const isValid = await validateEmail(formValues.email);
      setNoValidEmail(!isValid);
    };
    checkEmailValidation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.email]);

  /**
   * Soporta el evento para validar el campo de nit no este registrado en la base de datos
   */

  const validateAvaliableName = async (name: string) => {
    const opertorId =
      user?.roles.name === Roles_Enum.SUPER_ADMIN ? null : user?.operator.id;
    if (!token) {
      return false;
    }
    if (activePointSale && name === activePointSale?.name) {
      return true;
    }
    const response = await getPointsSaleFilterService(
      token,
      { name },
      true,
      null,
      null,
      opertorId
    );
    return response.data.length === 0;
  };

  /**
   * Soporta el evento para validar el número de telefono correcto
   */
  useEffect(() => {
    if (countries) {
      const valid = validatePhoneNumber();
      setValidPhone(!!valid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.phone]);

  useEffect(() => {
    setErrorTransfer(false);
    if (formValues.allowsTransfer === 2) {
      formValues.minimumTransfers = "";
      formValues.maximumTransfers = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.allowsTransfer]);

  useEffect(() => {
    if (formValues.allowsTransfer === 1) {
      setErrorTransfer(
        Number(formValues.minimumTransfers) !== 0 &&
          Number(formValues.minimumTransfers) >=
            Number(formValues.maximumTransfers)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.minimumTransfers, formValues.maximumTransfers]);

  /**
   * Soporta el evento para armar el objeto a enviar a base de datos
   */
  const handleSave = () => {
    if (activePointSale) {
      setMessage("pointsOfSale.sureApplyChanges");
    } else {
      setMessage("pointsOfSale.sureCreatePointOfSale");
    }
    setOpenDialog(true);
  };

  const handleCancel = () => {
    setMessage("dialog.sureExit");
    setCancel(true);
    setOpenDialog(true);
  };

  const handleCleanForm = () => {
    setMessage("dialog.sureClean");
    setResetForm(true);
    setOpenDialog(true);
    setCancel(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCancel(false);
    setResetForm(false);
  };

  const handleCleanFields = () => {
    if (activeStep === 0) {
      handleCleanStep1();
    }
    if (activeStep === 1) {
      setCommissionersData([]);
    }
    if (activeStep === 2) {
      setGamesData([]);
    }
    if (activeStep === 3) {
      //setBoxesData([defaultBoxValues]);
    }
    if (activeStep === 4) {
      handleCleanStep1();
      setCommissionersData([]);
      setGamesData([]);
      //setBoxesData([defaultBoxValues]);
      setDisabled(true);
      dispatch(setActiveStep(0));
      setNextStep(false);
    }
    if (activeStep === 5) {
      //setBoxesData([defaultBoxValues]);
    }
  };

  const handleCleanStep1 = () => {
    setIsSubmit(false);
    formValues.associates = [];
    formValues.country = null;
    formValues.state = null;
    formValues.city = null;
    formValues.associates = [];
    formValues.promoter = null;
    formValues.country = null;
    formValues.state = null;
    formValues.city = null;
    formValues.name = "";
    formValues.address = "";
    formValues.phone = "";
    formValues.email = "";
    formValues.ntc = 0;
    formValues.allowsTransfer = 0;
    formValues.minimumTransfers = 0;
    formValues.maximumTransfers = 0;
    formValues.operator = isSuper ? "" : formValues.operator;
    formValues.commissionType = "";
    setAssociatesSelected([]);
    setSelectedCountry(null);
    setSelectedStates(null);
    setCity(null);
  };

  const handleAccept = async () => {
    handleCloseDialog();

    if (resetForm && !cancel) {
      setDisabled(true);
      setNextStep(false);
      return handleCleanFields();
    }

    if (cancel) {
      dispatch(setFlagFormEdit({ flagFormEdit: false }));
      dispatch(setFlagForm({ flagFormCreate: false }));
      dispatch(setActivePointSale(null));
    } else {
      if (configPointsSale) {
        dispatch(setLoadingState({ loading: true }));
        if (!!activePointSale && formValues.id) {
          await dispatch(startEditPointSale(formValues, formValues.id));
        } else {
          await dispatch(startAddPointSale(configPointsSale));
        }
        token && (await dispatch(setGetPointsSale(token, 1, false)));
      }
    }
    setCancel(false);
    setResetForm(false);
  };

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
    if (typeAlert === "success") {
      dispatch(setFlagForm({ flagFormCreate: false }));
      dispatch(setFlagFormEdit({ flagFormEdit: false }));
      dispatch(setLoadingState({ loading: false }));
      dispatch(setActivePointSale(null));
      dispatch(setActiveStep(0));
    }
  };

  useEffect(() => {
    if (!activePointSale) {
      formValues.allowAnonymousPayouts = "";
      formValues.allowAnonymousSale = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.operator]);

  return (
    <div className="ml-4 mr-4">
      <div className="filter pl-5">
        <ThemeProvider theme={theme}>
          <Grid container spacing={2}>
            {/* ********************************** */}
            {/*                STEP 1              */}
            {/* ********************************** */}

            {activeStep === 0 && (
              <FormConfigPointSale
                formValues={formValues}
                submit={isSubmit}
                handleInputChange={handleInputChange}
                handleSelectAssociate={handleSelectAssociates}
                handleSelectCountry={onChangeCountry}
                handleSelectState={onChangeStates}
                handleSelectCity={onChangeCities}
                selectedAssociates={associatesSelected}
                setSelectedAssociates={setAssociatesSelected}
                selectedCountries={selectedCountry}
                selectedStates={selectedStates}
                selectedCities={cityData}
                errorCountry={errorCountry}
                errorStates={errorStates}
                errorCity={errorCity}
                errorAssociates={errorAssociates}
                errorEmail={errorEmail}
                emailNoValid={noValidEmail}
                errorName={errorName}
                errorTransfer={errorTransfer}
                validPhone={validPhone}
                setErrorEmail={setErrorEmail}
                setErrorName={setErrorName}
                paramsPhoneError={paramsPhoneError}
              />
            )}

            {/* ********************************** */}
            {/*                STEP 2              */}
            {/* ********************************** */}
            {activeStep === 1 && (
              <FormConfigCommissioners
                setCommissional={setCommissioners}
                commissioners={commissionersData}
                setPercentage={setPercentageByCommissioner}
              />
            )}

            {/* ********************************** */}
            {/*                STEP 3              */}
            {/* ********************************** */}
            {activeStep === 2 && (
              <FormConfigGames setGames={setGames} games={gamesData} />
            )}

            {/* ********************************** */}
            {/*                STEP 4              */}
            {/* ********************************** */}
            {activeStep === 3 && (
              <FormConfigSellers
                sellers={sellers}
                setSellers={handleChangeSeller}
                operator={formValues.operator}
                handleAddSeller={handleAddSeller}
                handleDeleteSeller={handleDeleteSeller}
              />
            )}
            {/* ********************************** */}
            {/*                STEP 5              */}
            {/* ********************************** */}
            {activeStep === 4 && (
              <FormConfigDealers
                dealers={dealers}
                setDealers={handleChangeDealer}
                handleAddDealer={handleAddDealer}
                handleDeleteDealer={handleDeleteDealer}
                operator={formValues.operator}
                pointType={formValues.type}
              />
            )}
            {/* ********************************** */}
            {/*                STEP 6             */}
            {/* ********************************** */}
            {activeStep === 5 && (
              <FormConfigPromoter
                dealers={dealers}
                selectedPromoter={promoterSelected}
                setSelectedPromoter={setPromoterSelected}
                operator={formValues.operator}
              />
            )}

            {/* ********************************** */}
            {/*                STEP 7              */}
            {/* ********************************** */}
            {activeStep === 6 && configPointsSale && (
              <TabsConfirmation data={configPointsSale} />
            )}

            <Grid
              item
              xs={12}
              mt={2}
              display="flex"
              justifyContent="center"
              sx={{ height: "60px" }}
            >
              {activeStep > 0 && (
                <Button
                  style={{
                    border: "none",
                    outline: "none",
                  }}
                  variant="contained"
                  className="footer-btn primary-btn ripple"
                  onClick={handlePrevious}
                  disabled={!!loading}
                >
                  <i className="fa-solid fa-angle-left pl-2"></i>
                  {t("button.previous").toUpperCase()}
                </Button>
              )}
              <Button
                style={{ border: "none", outline: "none" }}
                variant="contained"
                className="footer-btn danger-btn ripple"
                onClick={handleCancel}
                disabled={!!loading}
              >
                <i className="fa-solid fa-x"></i>
                {t("button.cancel").toUpperCase()}
              </Button>

              {activeStep === 6 ? (
                <Button
                  style={{
                    border: "none",
                    outline: "none",
                  }}
                  variant="contained"
                  className="footer-btn primary-btn ripple"
                  onClick={handleSave}
                  disabled={disabled || !!loading}
                >
                  {!!loading ? (
                    <CircularProgress size={"1.6rem"} color="inherit" />
                  ) : (
                    <>
                      <i className="fa-solid fa-check-circle"></i>
                      {t("button.save").toUpperCase()}
                    </>
                  )}
                </Button>
              ) : (
                <Button
                  style={{
                    border: "none",
                    outline: "none",
                  }}
                  data-testid="btnNext"
                  variant="contained"
                  className="footer-btn primary-btn ripple"
                  disabled={disabled || !!loading}
                  onClick={handleNext}
                >
                  {!!loading ? (
                    <CircularProgress size={"1.6rem"} color="inherit" />
                  ) : (
                    t("button.next").toUpperCase()
                  )}
                  {!loading && <i className="fa-solid fa-angle-right pl-2"></i>}
                </Button>
              )}

              {activeStep !== 5 && (
                <Button
                  type="button"
                  style={{
                    border: "none",
                    outline: "none",
                    marginLeft: "4px",
                    opacity: !!loading || !!activePointSale ? "0.3" : "",
                  }}
                  onClick={handleCleanForm}
                  disabled={!!loading || !!activePointSale}
                >
                  <img src={clearLogo} alt="Icon clear" />
                </Button>
              )}
            </Grid>
          </Grid>
          <MyDialog
            open={openDialog}
            title={t("dialog.confirmationWindow")}
            message={t(message)}
            handleAccept={handleAccept}
            handleClose={handleCloseDialog}
            isLoading={!!loading}
          />

          <MyAlert
            open={openAlert}
            message={messageAlert ? messageAlert : ""}
            typeAlert={typeAlert}
            handleClose={onCloseAlert}
          />
        </ThemeProvider>
      </div>
    </div>
  );
};
