import { Form, Formik } from "formik";
import moment from "moment";
import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import { MyDialog } from "../../components/custom/MyDialog";
import { MyAlert } from "../../components/custom/MyAlert";
import { FilterPlayer } from "../../components/exclusions/FilterPlayer";
import { MyTextField } from "../../components/custom/MyTextField";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { regEmail, regLetters, regLettersNumbers } from "../../../utils/utils";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  setCities,
  setCloseAlert,
  setCountries,
  setCountriesRedux,
  setStates,
} from "../../../redux/actions/helperActions";
import {
  setPlayerHistory,
  setPlayerRedux,
  startCreatePlayerUpdate,
  startGetPlayerHistory,
} from "../../../redux/actions/managementPlayersActions";
import { styled } from "@mui/system";
import { PlayerUpdateHistory } from "./PlayerUpdateHistory";
// import { phoneNumberValidation } from "../../../helpers/validations";
import { useLocation } from "react-router-dom";

const Container = styled("div")({
  display: "flex",
});
const CustomButton = styled(Button)({
  backgroundColor: "#007E8C",
  color: "white",
  height: "100%",
  width: "150px",
  "&:hover": {
    backgroundColor: "#007E8C",
    opacity: 1,
  },
});
export const UpdatePlayer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, setErrorDate] = useState<boolean>(false);
  const [, setDataFilter] = useState<any>({});
  const [submit, setSubmit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [country, setCountry] = useState<number | string>("");
  const [state, setState] = useState<number | string>("");
  const [city, setCity] = useState<number | string>("");
  const [typeDocument, setTypeDocument] = useState<string>(""); // Default document type
  const [totalData, setTotalData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state: AppState) => state.auth);
  const { countries, states, cities, openAlert, messageAlert, typeAlert } =
    useSelector((state: AppState) => state.helper);
  const { player } = useSelector((state: AppState) => state.managementPlayers);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef(null);
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { pathname } = useLocation();
  const formikRef = useRef(null);
  const isPlayerAnonima = player?.data[0]?.playerType === "Anonima";

  useEffect(() => {
    if (isPlayerAnonima && formikRef.current) {
      // Limpiar estados locales
      setCountry("");
      setState("");
      setCity("");
      setTypeDocument("");

      // Limpiar el formulario a través del ref de Formik
      formikRef.current.resetForm();
    }
  }, [isPlayerAnonima]);

  const genders = [
    { value: "M", text: t("form.labels.male") },
    { value: "F", text: t("form.labels.female") },
  ];

  const documentTypes = [
    {
      id: 1,
      name: "Cédula de ciudadanía",
    },
    {
      id: 2,
      name: "Pasaporte",
    },
    {
      id: 3,
      name: "Documento Nacional de Identificación",
    },
    {
      id: 4,
      name: "Documento Único de Identidad",
    },
    {
      id: 5,
      name: "Cédula de Identidad Personal",
    },
    {
      id: 6,
      name: "Cédula de Extranjería",
    },
  ];

  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);
  const enableRead =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.readed === 1;
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;

  useEffect(() => {
    if (player?.data[0] && !isPlayerAnonima) {
      setCountry(player.data[0].country.id);
      setState(+player.data[0].state.id);
      setCity(+player.data[0].city.id);
      setTypeDocument(+player.data[0].typeDocument.id);
      dispatch(startGetPlayerHistory({ page: 1 }));
    }
  }, [dispatch, isPlayerAnonima, player]);

  const initialValues = {
    id: player?.data[0]?.id ?? "",
    firstName: player?.data[0]?.firstName ?? "",
    secondName: player?.data[0]?.secondName ?? "",
    lastName: player?.data[0]?.lastName ?? "",
    secondLastName: player?.data[0]?.secondLastName ?? "",
    birthDate: player?.data[0]?.birthDate
      ? moment(player?.data[0]?.birthDate).utc().format("YYYY-MM-DD")
      : "",
    nationality: player?.data[0] ? player?.data[0].nationality : "",
    state: "",
    city: "",
    // phone: player?.data[0]?.phone ?? "",
    email: player?.data[0]?.email ?? "",
    attachFile: {},
    observation: "",
    typeDocument: { id: player?.data[0]?.typeDocument?.id ?? "" },
    numberDocument: player?.data[0].numberDocument ?? typeDocument,
    gender: player?.data[0]?.gender ?? "",
  };

  const validationSchema = yup.object({
    firstName: yup.string().required(t("form.requiredFile")),
    secondName: yup.string(),
    lastName: yup.string().required(t("form.requiredFile")),
    typeDocument: yup.object({
      id: yup.number().required(t("form.requiredFile")),
    }),
    numberDocument: yup.string().when("typeDocument.id", {
      is: (value: number) => value === 1, // Cédula de ciudadanía
      then: yup
        .string()
        .matches(/^\d{7,10}$/, t("alerts.errorCC"))
        .required(t("form.requiredFile")),
      otherwise: yup.string().when("typeDocument.id", {
        is: (value: number) => value === 6, // Cédula de Extranjería
        then: yup
          .string()
          .matches(/^\d{6,15}$/, t("alerts.errorCE"))
          .required(t("form.requiredFile")),
        otherwise: yup.string().when("typeDocument.id", {
          is: (value: number) => value === 2, // Pasaporte
          then: yup
            .string()
            .matches(/^[a-zA-Z0-9]{6,15}$/, t("alerts.errorPAS"))
            .required(t("form.requiredFile")),
          otherwise: yup.string().when("typeDocument.id", {
            is: (value: number) => value === 3, // DNI - Honduras
            then: yup
              .string()
              .matches(/^\d{13}$/, t("alerts.errorDNI"))
              .required(t("form.requiredFile")),
            otherwise: yup.string().when("typeDocument.id", {
              is: (value: number) => value === 4, // DUI - El Salvador
              then: yup
                .string()
                .matches(/^\d{9}$/, t("alerts.errorDUI"))
                .required(t("form.requiredFile")),
              otherwise: yup.string().when("typeDocument.id", {
                is: (value: number) => value === 5, // CIP - Panamá
                then: yup
                  .string()
                  .matches(/^\d{6,15}$/, t("alerts.errorCIP"))
                  .required(t("form.requiredFile")),
                otherwise: yup.string().when("typeDocument.id", {
                  is: (value: number) => value === 7, // DPI - Guatemala
                  then: yup
                    .string()
                    .matches(/^\d{13}$/, t("alerts.errorDPI"))
                    .required(t("form.requiredFile")),
                  otherwise: yup.string().required(t("form.requiredFile")),
                }),
              }),
            }),
          }),
        }),
      }),
    }),
    secondLastName: yup.string(),
    birthDate: yup.date().required(t("form.requiredFile")),
    nationality: yup.number().required(t("form.requiredFile")),
    state: yup.number(),
    city: yup.number(),
    email: yup
      .string()
      .email(t("user.invalidEmail"))
      .required(t("form.requiredFile")),
    // phone: yup.string(),
    observation: yup.string(),
    gender: yup.string().required(t("form.requiredFile")),
  });

  useEffect(() => {
    dispatch(setCloseAlert());
    token && dispatch(setCountries(token));
    return () => {
      dispatch(setCountriesRedux([]));
      dispatch(setPlayerRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (country && country !== "") {
      token && dispatch(setStates(token, +country));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    if (state && state !== "") {
      token && dispatch(setCities(token, +state));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleUpdatePlayer = async () => {
    setLoading(true);
    setOpenDialog(false);
    setSubmit(false);
    totalData.typeDocument = +totalData.typeDocument.id;

    const response = await dispatch(startCreatePlayerUpdate(totalData));
    // @ts-ignore
    if (response?.status === "ok") {
      dispatch(setPlayerHistory(null));
      dispatch(setPlayerRedux(null));
      setState("");
      setCity("");
      setSelectedFile(null);
    }

    setLoading(false);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files && event.target.files[0];
    if (file) {
      const fileExtension = file.name
        ? file.name.split(".").pop()?.toLowerCase()
        : "";

      if (["jpg", "png", "jpeg", "pdf"].includes(fileExtension as string)) {
        setSelectedFile(file);
      } else {
        setSelectedFile(null);
      }
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      (fileInputRef.current as any).value = ""; // Resetear el valor
      (fileInputRef.current as any).click();
    }
  };

  const handleUnSelectFile = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      (fileInputRef.current as any).value = ""; // Asegurarse de resetear el valor
    }
  };

  return (
    <div className="content w-100">
      <Row style={{ marginBottom: 10, marginLeft: 0 }}>
        <MyBreadCrumbs titles={[t("sidebar.player"), t("player.updateData")]} />
      </Row>
      {enableCreated && (
        <div className="filter">
          <FilterPlayer
            setErrorDate={setErrorDate}
            setDataFilter={setDataFilter}
            getExclusions={false}
          />
        </div>
      )}

      <div className="filter mt-4">
        {enableCreated && (
          <>
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  "&::before": {
                    width: "20px",
                  },
                },
              }}
              textAlign="left"
            >
              {t("player.playerInformation")}
            </Divider>
            {isPlayerAnonima ? (
              <p style={{ color: "#525f7f" }}>
                Este es un jugador de apuestas anonimas
              </p>
            ) : null}

            <Formik
              innerRef={formikRef}
              validateOnChange={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);
                if (state !== "" && city !== "") {
                  data.state = state.toString();
                  data.city = city.toString();

                  data.attachFile = selectedFile as object;
                  setTotalData(data);
                  setOpenDialog(true);
                }
                setSubmitting(false);
              }}
              enableReinitialize={true}
            >
              {({ values, handleChange, isSubmitting }) => (
                <Form>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <MyTextField
                        inputProps={{
                          maxLength: 50,
                        }}
                        name="firstName"
                        label={t("player.firstName") + "*"}
                        onChange={handleChange}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                          !regLetters(e.key) && e.preventDefault()
                        }
                        disabled={isPlayerAnonima}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <MyTextField
                        inputProps={{
                          maxLength: 50,
                        }}
                        name="secondName"
                        label={t("player.secondName")}
                        onChange={handleChange}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                          !regLetters(e.key) && e.preventDefault()
                        }
                        disabled={isPlayerAnonima}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <MyTextField
                        inputProps={{
                          maxLength: 30,
                        }}
                        name="lastName"
                        label={t("player.lastName") + "*"}
                        onChange={handleChange}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                          !regLetters(e.key) && e.preventDefault()
                        }
                        disabled={isPlayerAnonima}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <MyTextField
                        inputProps={{
                          maxLength: 30,
                        }}
                        name="secondLastName"
                        label={t("player.secondLastName")}
                        onChange={handleChange}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                          !regLetters(e.key) && e.preventDefault()
                        }
                        disabled={isPlayerAnonima}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <FormControl
                        sx={{ width: 1 }}
                        size="small"
                        error={submit && values.typeDocument.id === ""}
                      >
                        <InputLabel id="document-type-label">
                          {t("user.documentType")}
                        </InputLabel>
                        <Select
                          labelId="document-type-label"
                          label={t("user.documentType")}
                          name="typeDocument.id"
                          fullWidth
                          onChange={(e) => {
                            handleChange(e);
                            setTypeDocument(e.target.value);
                          }}
                          value={values.typeDocument.id ?? typeDocument}
                          input={
                            <OutlinedInput
                              label={t("user.documentType") + "*"}
                            />
                          }
                          disabled={isPlayerAnonima}
                        >
                          <MenuItem disabled value={""}></MenuItem>
                          {documentTypes.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                              {type.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <MyTextField
                        inputProps={{
                          maxLength: 30,
                        }}
                        name="numberDocument"
                        label={t("filters.documentNumber")}
                        onChange={handleChange}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                          !regLettersNumbers(e.key) && e.preventDefault()
                        }
                        disabled={isPlayerAnonima}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <MyTextField
                        name="birthDate"
                        type="date"
                        label={t("player.dateOfBirth") + "*"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          "data-testid": "expeditionDate",
                          max: new Date(
                            new Date().setFullYear(
                              new Date().getFullYear() - 18
                            )
                          ).toLocaleDateString("en-CA"),
                        }}
                        onChange={handleChange}
                        value={values.birthDate}
                        fullWidth
                        disabled={isPlayerAnonima}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <FormControl
                        sx={{ width: 1 }}
                        size="small"
                        error={submit && values.nationality === ""}
                      >
                        <InputLabel id="country-label">
                          {t("player.nationality") + "*"}
                        </InputLabel>
                        <Select
                          labelId="country-label"
                          label={t("player.nationality")}
                          name="nationality"
                          fullWidth
                          onChange={handleChange}
                          value={values.nationality ?? ""}
                          input={
                            <OutlinedInput
                              label={t("player.nationality") + "*"}
                            />
                          }
                          disabled={isPlayerAnonima}
                        >
                          <MenuItem disabled value={""}></MenuItem>
                          {countries?.map((country) => (
                            <MenuItem key={country.id} value={country.id}>
                              {country.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <FormControl
                        sx={{ width: 1 }}
                        size="small"
                        error={submit && state === ""}
                      >
                        <InputLabel id="province-label">
                          {t("player.province") + "*"}
                        </InputLabel>
                        <Select
                          labelId="province-label"
                          label={t("player.province")}
                          name="state"
                          fullWidth
                          onChange={(e: SelectChangeEvent<string | number>) => {
                            setState(e.target.value);
                            setCity("");
                          }}
                          value={state ?? ""}
                          input={
                            <OutlinedInput label={t("player.province") + "*"} />
                          }
                          disabled={isPlayerAnonima}
                        >
                          <MenuItem disabled value={""}></MenuItem>
                          {states?.map((state) => (
                            <MenuItem key={state.id} value={state.id}>
                              {state.names}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <FormControl
                        sx={{ width: 1 }}
                        size="small"
                        error={submit && city === ""}
                      >
                        <InputLabel id="city-label">
                          {t("user.city") + "*"}
                        </InputLabel>
                        <Select
                          labelId="city-label"
                          label={t("user.city")}
                          name="city"
                          fullWidth
                          onChange={(e: SelectChangeEvent<string | number>) =>
                            setCity(e.target.value)
                          }
                          value={city ?? ""}
                          input={<OutlinedInput label={t("user.city") + "*"} />}
                          disabled={isPlayerAnonima}
                        >
                          <MenuItem disabled value={""}></MenuItem>
                          {cities?.map((city) => (
                            <MenuItem key={city.id} value={city.id}>
                              {city.names}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {submit && city === "" && (
                            <label style={{ color: "#d32f2f" }}>
                              {t("form.requiredFile")}
                            </label>
                          )}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6} lg={4}>
                      <MyTextField
                        name="phone"
                        label={t("user.cellphone") + "*"}
                        onChange={handleChange}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                          if (values.phone === "") {
                            e.key === "0"
                              ? e.preventDefault()
                              : !regNumbers(e.key) && e.preventDefault();
                          } else {
                            !regNumbers(e.key) && e.preventDefault();
                          }
                        }}
                        inputProps={{
                          maxLength: 10,
                        }}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <MyTextField
                        inputProps={{
                          maxLength: 100,
                        }}
                        name="email"
                        label={t("user.email") + "*"}
                        onChange={(e: any) => {
                          handleChange(e);
                        }}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                          !regEmail(e.key) && e.preventDefault()
                        }
                        disabled={isPlayerAnonima}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <MyTextField
                        multiline
                        maxRows={8}
                        fullWidth
                        inputProps={{ maxLength: 200 }}
                        name="observation"
                        label={t("user.observation")}
                        onChange={handleChange}
                        disabled={isPlayerAnonima}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <FormControl
                        sx={{ width: 1 }}
                        size="small"
                        error={submit && values.gender === ""}
                      >
                        <InputLabel id="gender-label">
                          {t("user.gender") + "*"}
                        </InputLabel>
                        <Select
                          labelId="gender-label"
                          label={t("user.gender")}
                          name="gender"
                          fullWidth
                          onChange={handleChange}
                          value={values.gender ?? ""}
                          input={
                            <OutlinedInput label={t("user.gender") + "*"} />
                          }
                          disabled={isPlayerAnonima}
                        >
                          <MenuItem disabled value={""}></MenuItem>
                          {genders.map((gender) => (
                            <MenuItem key={gender.value} value={gender.value}>
                              {gender.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                        }}
                      >
                        <Container>
                          <TextField
                            label={`${t("button.attachFile")}`}
                            disabled
                            name="attachFile"
                            size="small"
                            fullWidth
                            value={selectedFile ? selectedFile.name : ""}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                          <input
                            type="file"
                            accept=".jpg,.png,.jpeg,.pdf"
                            style={{ display: "none" }}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            disabled={isPlayerAnonima}
                          />
                          <label style={{ margin: 0 }}>
                            <CustomButton
                              style={{
                                border: "none",
                                outline: "none",
                                boxShadow: "none",
                              }}
                              onClick={
                                selectedFile
                                  ? handleUnSelectFile
                                  : handleButtonClick
                              }
                            >
                              {t(
                                selectedFile
                                  ? "filters.clean"
                                  : "managementBonds.examine"
                              )}
                            </CustomButton>
                          </label>
                        </Container>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      mt={3}
                      className="gridButton"
                      sx={{ height: "60px" }}
                    >
                      <Button
                        type="submit"
                        style={{
                          border: "none",
                          outline: "none",
                        }}
                        variant="contained"
                        className="footer-btn primary-btn ripple"
                        disabled={!player?.data[0]}
                        onClick={() => setSubmit(true)}
                      >
                        {isSubmitting || loading ? (
                          <CircularProgress size={"1.6rem"} color="inherit" />
                        ) : (
                          t("player.updateData")
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </>
        )}
        {enableRead && (
          <Box mt={2}>
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  "&::before": {
                    width: "20px",
                  },
                },
              }}
              textAlign="left"
            >
              {t("player.updateHistory")}
            </Divider>
            <PlayerUpdateHistory />
          </Box>
        )}

        <MyDialog
          id="confirmationDialog"
          open={openDialog}
          title={t("dialog.confirmationWindow")}
          message={t("player.sureUpdatePlayer")}
          handleAccept={handleUpdatePlayer}
          handleClose={() => setOpenDialog(false)}
          isLoading={loading}
        />
        <MyAlert
          open={openAlert}
          message={messageAlert ? messageAlert : ""}
          typeAlert={typeAlert}
          handleClose={() => dispatch(setCloseAlert())}
        />
      </div>
    </div>
  );
};
