import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { asyncLoadingFunction } from "../../../../redux/actions/helperActions";
import { startUploadVoucher } from "../../../../redux/actions/transactionsActions";

interface Props {
  setOpenAttach: Dispatch<SetStateAction<boolean>>;
}
export const AttachVoucher: FC<Props> = ({ setOpenAttach }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading } = useSelector((state: AppState) => state.helper);
  const { activeWithdrawal } = useSelector(
    (state: AppState) => state.transactions
  );
  const [file, setFile] = useState<File | null>(null);

  const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files?.[0];
    files && setFile(files);
  };

  const handleUpload = async () => {
    activeWithdrawal &&
      file &&
      dispatch(
        asyncLoadingFunction(
          async () =>
            await dispatch(
              startUploadVoucher(activeWithdrawal?.id, file, setOpenAttach)
            )
        )
      );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          size="small"
          type="file"
          inputProps={{ accept: "application/pdf" }}
          onChange={handleSelectFile}
          id="icon-button-file"
          autoComplete="off"
          style={{ display: "none" }}
          fullWidth
        />
      </Grid>

      <Grid
        item
        xs={12}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <label htmlFor="icon-button-file">
          <Button
            style={{
              border: "none",
              outline: "none",
              backgroundColor: "#007e8c",
              height: "30px",
            }}
            variant="contained"
            component="span"
          >
            {"Adjuntar"}
            <i className="fa fa-paperclip fa-lg ml-2" />
          </Button>
        </label>
        <label>
          {!file?.name &&
            !activeWithdrawal?.voucher &&
            t("withdrawals.noFileSelected")}
        </label>
        <label>{file?.name}</label>
      </Grid>

      <Grid item xs={12} display={"flex"} flexDirection={"column"}>
        {activeWithdrawal?.voucher && (
          <>
            <label>
              {t("withdrawals.voucherMessage", { number: activeWithdrawal?.id })}
            </label>
            <a
              href={activeWithdrawal?.voucher?.url}
              target="_blank"
              style={{ textDecoration: "underline" }}
              rel="noreferrer"
            >
              {activeWithdrawal?.voucher?.name}
            </a>
          </>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        display="flex"
        justifyContent={"flex-end"}
        mt={1}
        mb={-2}
      >
        <Button
          type="button"
          style={{
            border: "none",
            outline: "none",
            backgroundColor: "#02D0E7",
          }}
          variant="contained"
          disabled={!!loading || !file}
          onClick={handleUpload}
        >
          {loading ? (
            <CircularProgress size={"1.6rem"} color="inherit" />
          ) : (
            <>
              {t(activeWithdrawal?.voucher ? "button.update" : "button.save")}{" "}
            </>
          )}
        </Button>
      </Grid>
    </Grid>
  );
};
