import { useState } from "react";
import { Form, Formik } from "formik";
import { Container, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  InputAdornment,
  CircularProgress,
} from "@mui/material";

import { MyTextField } from "../custom/MyTextField";
import EmailIcon from "@mui/icons-material/Email";
import mailIcon from "../../../assets/svg/mail.svg";
import { forgotPassword } from "../../../services/auth";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";

import { maskEmail } from "../../../helpers/maskEmail";

interface Props {
  openModal: boolean;
  setShowModalPassword: any;
}

export const RequestPassword = ({ openModal, setShowModalPassword }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [mask, setMaskEmail] = useState<string>("");
  const initialValues = {
    email: "",
  };
  const validationSchema = yup.object({
    email: yup
      .string()
      .max(50, t('form.maxLength'))
      .email(t("user.invalidEmail"))
      .required(t("form.requiredFile")),
  });

  const handleSubmit = async (data: any) => {
    setLoading(true);
    const result = (await forgotPassword(data.email)) as any;

    if (result?.error) {
      dispatch(setOpenAlert());
      dispatch(setTypeAlert("error"));
      dispatch(setMessageAlert(result?.error));
    } else {
      setSuccess(true);
      setMaskEmail(maskEmail(data?.email));
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={openModal}
      maxWidth="md"
      disableEscapeKeyDown
      scroll="body"
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 0,
        }}
      >
        <Box display="flex">
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <p className="txt-primary-md" style={{ fontWeight: 500 }}>
            {t("forgotPassword.modalTitle")}
          </p>
        </Box>
        <IconButton
          disableFocusRipple
          disableRipple
          className="hover-close"
          style={{ border: "none", outline: "none" }}
          onClick={() =>{
            setShowModalPassword(false);
            setSuccess(false);
          }}
        >
          <i className="fa-solid fa-xmark"></i>
        </IconButton>
      </DialogTitle>
      <Box borderTop={1} borderColor="#02D0E7">
        <DialogContent>
          <Container>
            <Col>
              {!success ? (
                <Formik
                  validateOnChange={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    handleSubmit(values);
                  }}
                >
                  {({ values, handleChange, errors }) => (
                    <Form>
                      <Grid>
                        <Grid item mb={errors.email && -1}>
                          <MyTextField
                            inputProps={{
                              "data-testid": "email",
                              maxLength: 50,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <EmailIcon />
                                </InputAdornment>
                              ),
                            }}
                            name="email"
                            label={t("user.email") + "*"}
                            onChange={(e: any) => {
                              handleChange(e);
                            }}
                            value={values.email}
                            fullWidth
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          mt={3}
                          className="gridButton"
                          sx={{ height: "60px" }}
                        >
                          <Button
                            type="button"
                            style={{ border: "none", outline: "none" }}
                            variant="contained"
                            className="footer-btn danger-btn ripple"
                            onClick={() =>{
                              setShowModalPassword(false);
                              setSuccess(false);
                            }}
                          >
                            {t("button.cancel")}
                          </Button>
                          <Button
                            type="submit"
                            id="btnSave"
                            data-testid="btnSubmit"
                            style={{
                              border: "none",
                              outline: "none",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            disabled={errors.email || loading ? true : false}
                            variant="contained"
                            className="footer-btn primary-btn ripple"
                          >
                            {loading && !errors.email ? (
                              <CircularProgress
                                size={"1.6rem"}
                                color="inherit"
                              />
                            ) : (
                              t("button.send")
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={mailIcon} alt="mail icon" />
                  <span>
                    <b>
                      {t("forgotPassword.sendEmailTitle", { userEmail: mask })}
                    </b>
                  </span>
                  <span>{t("forgotPassword.sendEmailFooter")}</span>
                </Box>
              )}
            </Col>
          </Container>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
