import { IFile } from "./../../interfaces/file";
import {
  createFileService,
  filterFiles,
  getdocumentsFiles,
} from "../../services/managementFilesService";
import { AppState } from "../reducers/rootReducer";
import {
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";
import { arraySearch } from "./searchActions";

export const startGetDocumentsFiles = () => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    if (token) {
      dispatch(setLoadingState({ loading: true }));
      const response =
        user && (await getdocumentsFiles(token));
      dispatch(arraySearch(response));
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const createFile = (data: IFile) => {
  return async (dispatch: Function, getState: Function) => {
  
    dispatch(setLoadingState({ loading: true }));
    const response = await createFileService(data);
    if (response) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.createFileSuccess"));
      dispatch(setTypeAlert("success"));
      dispatch(setLoadingState({ loading: false }));
    } else {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.createFileError"));
      dispatch(setTypeAlert("error"));
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const startFilteringFiles = (formValues: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    if (!!user && token) {
      let finalValues: any = {};
      Object.entries(formValues).forEach(([key, value], index) => {
        //@ts-ignore
        if (value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });
      dispatch(setLoadingState({ loading: true }));
      const response = await filterFiles(token, finalValues);
      dispatch(arraySearch(response));
      dispatch(setLoadingState({ loading: false }));
    }
  };
};
