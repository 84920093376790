import { makeStyles } from "@material-ui/core";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const stylesBonusForm = makeStyles((theme: any) => ({
  root: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  inputAdorned: {
    marginRight: "30px",
  },
  infoIcon: {
    color: "#02D0E7",
    marginRight: theme.spacing(1.125), // roughly equivalent to 9px
    fontSize: 17,
  },
  title: {
    fontWeight: "bold",
    color: "black",
  },
  titleBox: {
    display: "flex",
    borderBottom: `1px solid #02D0E7`,
    paddingBottom: theme.spacing(0.25), // roughly equivalent to 4px
  },
  formControl: {
    width: "100%",
  },
  errorLabel: {
    marginLeft: theme.spacing(0.75),
    color: "#d32f2f",
  },
}));

export const stylesDate = {
  "& input.Mui-disabled ": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
  "& .MuiSelect-select.Mui-disabled": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
};

// Constants for menu props
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const Container = styled("div")({
  display: "flex",
});
export const CustomButton = styled(Button)({
  backgroundColor: "#007E8C",
  color: "white",
  height: "100%",
  width: "150px",
  "&:hover": {
    backgroundColor: "#007E8C",
    opacity: 1,
  },
});
