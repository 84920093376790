import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { headersIrrigationCardsHistory } from "../../../../helpers/headersTables";
import { getCopyArrayOrObject } from "../../../../utils/utils";
import { Roles_Enum } from "../../../../enums/enums";
import moment from "moment";

export const IrrigationsCardsHistoryDataTable = (totalData: any[]) => {
  const { data } = useSelector((state: AppState) => state.search);
  const { user } = useSelector((state: AppState) => state.auth);
  const headersCvsStyle = headersIrrigationCardsHistory.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  const isRoleAssociate = user?.roles.name === Roles_Enum.ASSOCIATE;
  const isRoleDealer = user?.roles.name === Roles_Enum.DEALER;
  const isRoleAdmin = user?.roles.name === Roles_Enum.ADMIN_OPERATOR;
  const isRolePromotor = user?.roles.name === Roles_Enum.PROMOTOR;

  // Obtener datos por vista
  const dataIrrigationHistory =
    data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalIrrigationHistory = totalData
    ? getCopyArrayOrObject(totalData)
    : [];
  const buildTotalData = (arr: any) => {
    const multiData = [
      {
        columns: headersCvsStyle,
        data: arr.map((data: any) => {
          const nameUserIrrigation = (): string => {
            let name: string = "";

            if (isRoleAssociate) {
              name = data.dealerName || data.pointSaleName || "";
            } else if (isRoleDealer) {
              name = data.promotorName ?? data.pointSaleName ?? "";
            } else if (isRoleAdmin) {
              name = data.associateName || "";
            } else if (isRolePromotor) {
              name = data.pointSaleName || "";
            }
            return name;
          };
          const array = [
            {
              value: nameUserIrrigation(),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.lotId,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.cardAmount,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: `${data.minCardId}-${data.maxCardId}`,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.redeemedCards,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.activeCards,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.inactiveCards,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: moment(data.updateAt).format("YYYY-MM-DD HH:mm:ss"),
              style: { alignment: { horizontal: "center" } },
            },
          ];
          return array;
        }),
      },
    ];
    return multiData;
  };

  const multiTotalData = buildTotalData(dataTotalIrrigationHistory);
  const multiData = buildTotalData(dataIrrigationHistory);
  const finalData = {
    dataPerView: dataIrrigationHistory,
    dataTotal: dataTotalIrrigationHistory,
    headersCSV: headersIrrigationCardsHistory,
    multiDataSet: multiData,
    multiTotalDataSet: multiTotalData,
  };
  return finalData;
};
