import { Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IFile } from "../../../interfaces/file";
import { MyAlert } from "../../components/custom/MyAlert";
import { AppState } from "../../../redux/reducers/rootReducer";
import { setCloseAlert } from "../../../redux/actions/helperActions";
import { createFile } from "../../../redux/actions/managementFilesActions";
import { Loading } from "../../../components/Loading/Loading";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "./FilesUploads.scss";

/**
 * VALORES PARA INICIALIZAR LOS CAMPO EN MENU
 */
const defaultValues: IFile = {
  frontCard: "",
  backCard: "",
  selfie: "",
};

const FormFilesUploads = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading } = useSelector((state: AppState) => state.helper);
  const { openAlert, messageAlert, typeAlert } = useSelector(
    (state: AppState) => state.helper
  );
  const [fromMobile, setFromMobile] = useState(false);
  /**
   * ESTADO INICIAL VALORES FORMULARIO
   */
  const [formValues, setFormValues] = useState(defaultValues);

  const [optionRadio, setOptionRadio] = useState("0");

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptionRadio((event.target as HTMLInputElement).value);
  };

  const saveFilesDocument = async (e: any) => {
    e.preventDefault();
    if (
      optionRadio === "0" &&
      (formValues.backCard === "" || formValues.frontCard === "")
    ) {
      return alert("Completa los campos requeridos");
    }
    if (optionRadio === "1" && formValues.selfie === "") {
      return alert("Completa los campos requeridos");
    }
    await dispatch(createFile(formValues));
    setFormValues(defaultValues);
    const frontCardInput = document.getElementById("frontCardInput");
    const backCardInput = document.getElementById("backCardInput");
    const selfieInput = document.getElementById("selfieInput");

    if (frontCardInput && backCardInput) {
      //@ts-ignore
      frontCardInput.value = "";
      //@ts-ignore
      backCardInput.value = "";
    }
    if (selfieInput) {
      //@ts-ignore
      selfieInput.value = "";
    }
  };
  useEffect(() => {
    setFormValues(defaultValues);
  }, [optionRadio]);
  const detectMob = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  };
  useEffect(() => {
    const isMobile = detectMob();
    setFromMobile(isMobile);
  }, []);

  const handleFormChange = (event: any) => {
    const file: File = event.target.value;
    if (file) {
      setFormValues({
        ...formValues,
        [event.target.name]: file,
      });
    }
  };
  const handleSelectFileFront = (e: any) => {
    const file: File = e.target.files[0];

    if (file) {
      handleFormChange({
        //@ts-ignore
        target: {
          value: file,
          name: e.target.name,
        },
      });
    }
  };
  const handleSelectFileBack = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      handleFormChange({
        //@ts-ignore
        target: {
          value: file,
          name: e.target.name,
        },
      });
    }
  };
  const hanldeSelectSelfie = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      handleFormChange({
        //@ts-ignore
        target: {
          value: file,
          name: e.target.name,
        },
      });
    }
  };

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
  };
  return (
    <div className="content container w-100 mt-5">
      <div className="filter">
        <Grid item xs={12} marginBottom={3}>
          <i className="fa fa-upload fa-lg pr-3 iconUser" />
          <span className="titleUser">{t("fileUpload.fileUpload")}</span>
        </Grid>
        {/* BARRA */}
        <Divider
          sx={{
            "&.MuiDivider-root": {
              "&::before": {
                width: "20px",
              },
            },
          }}
          textAlign="left"
        ></Divider>
        {/* FORMULARIO */}
        <form className=" pt-3">
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Seleccione una de las siguientes opciones:
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleChangeRadio}
              value={optionRadio}
            >
              <FormControlLabel value={0} control={<Radio />} label="Cedula" />
              <FormControlLabel value={1} control={<Radio />} label="Selfie" />
            </RadioGroup>
          </FormControl>
          <Grid container>
            {optionRadio === "0" ? (
              <>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <label>Cédula frontal*:</label>
                  <input
                    name="frontCard"
                    accept="image/*"
                    id="frontCardInput"
                    //@ts-ignore
                    capture={fromMobile ? "camera" : undefined}
                    type="file"
                    onChange={handleSelectFileFront}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <label>Cédula parte trasera*:</label>
                  <input
                    name="backCard"
                    accept="image/*"
                    id="backCardInput"
                    type="file"
                    //@ts-ignore
                    capture={fromMobile ? "camera" : undefined}
                    onChange={handleSelectFileBack}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <label>Selfie*:</label>
                <input
                  name="selfie"
                  accept="image/*"
                  id="selfieInput"
                  type="file"
                  //@ts-ignore
                  capture={fromMobile ? "camera" : undefined}
                  onChange={hanldeSelectSelfie}
                />
              </Grid>
            )}
            <Grid item xs={12} className="gridButton" mt={3}>
              <button
                className="d-flex btn-light-dark mr-3"
                onClick={(e) => {
                  saveFilesDocument(e);
                }}
              >
                <div className="p-0 text-right mr-1">
                  <i className="fa fa-check btn-icon pt-1 fa-md"></i>
                </div>
                <div className="p-0 text-left">
                  <div className="p-0 text-left btn-label pl-1">
                    {t("button.save")}
                  </div>
                </div>
              </button>
            </Grid>
          </Grid>
        </form>
      </div>
      {loading && <Loading />}
      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />
    </div>
  );
};

export default FormFilesUploads;
