import { IRolesParams } from "../interfaces/roles";

export const saveRoles = (rol: IRolesParams, token: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(rol),
  };

  return fetch(`${process.env.REACT_APP_API_HTTP_SECURITY}roles`, requestOptions)
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const updateRoles = (
  rol: IRolesParams,
  token: string,
  idRol: number
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(rol),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}roles/${idRol}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const deleteRolesService = (token: string, idRol: number) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}roles/${idRol}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const filterRoles = (
  token: string,
  operatorId: number | null,
  params: any,
  superAdmin: boolean | null,
  page?: number | null,
  perPage?: number | null,
  isFilter?: boolean | null
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryString = new URLSearchParams(params).toString();

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}rolesOperators?${
      operatorId ? `operator=${operatorId}` : superAdmin ? "super=true" : ""
    }&${page ? `page=${page}` : `page=1`}&${
      perPage ? `perPage=${perPage}` : `perPage=9`
    }&${isFilter ? `filter=${isFilter}` : `filter=false`}&${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const getRolByOperator = (token: string, rolId: number) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}rolesOperators?rol=${rolId}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
