import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Tab, Tabs } from "@mui/material";
import { TabBetting } from "./tabBets/TabBetting";
import { TabCasino } from "./tabCasino/TabCasino";
import { TabAviatrix } from "./tabAviatrix/TabAviatrix";
import { TabGoldenRace } from "./tabGoldenRace/TabGoldenRace";
import { TabBingo } from "./tabBingo/TabBingo";
import { TabVirtual } from "./TabVirtual";
import { TabEvolution } from "./tabEvolution/TabEvolution";
import casinoIcon from "../../../../assets/svg/icon_casino.svg";
//import aviatrixIcon from "../../../../assets/svg/icon_airplaine.svg";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import GridGoldenratioIcon from "@mui/icons-material/Airplay";
import GridOnIcon from "@mui/icons-material/GridOn";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const styles = {
  "&.Mui-selected": {
    color: "#55BCD9",
    border: "none",
    outline: "none",
  },
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const TabsGamingActivity = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          style={{ border: "none", outline: "none" }}
          TabIndicatorProps={{
            style: {
              backgroundColor: " #02D0E7",
              fontWeight: "bold",
            },
          }}
        >
          <Tab
            sx={styles}
            label={
              <span className="spanTab">
                <i className="fa-solid fa-ticket mr-1"></i>
                {t("bets.title")}
              </span>
            }
            {...a11yProps(0)}
          />
          <Tab
            sx={styles}
            label={
              <span className="spanTab">
                {/* <i className="fa-solid fa-spade mr-1"></i> */}
                <img
                  loading="lazy"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  src={casinoIcon}
                  alt=""
                />
                {t("sidebar.casino")}
              </span>
            }
            {...a11yProps(1)}
          />
          <Tab
            sx={styles}
            label={
              <span className="spanTab">
                {/* <i className="fa-solid fa-spade mr-1"></i> */}
                <FlightTakeoffIcon />
                {t("tabs.aviatrix")}
              </span>
            }
            {...a11yProps(2)}
          />
          <Tab
            sx={styles}
            label={
              <span className="spanTab">
                {/* <i className="fa-solid fa-spade mr-1"></i> */}
                <GridGoldenratioIcon />
                {t("tabs.goldenRace")}
              </span>
            }
            {...a11yProps(3)}
          />
          <Tab
            sx={styles}
            label={
              <span className="spanTab">
                {/* <i className="fa-solid fa-spade mr-1"></i> */}
                <GridOnIcon />
                {t("tabs.bingo")}
              </span>
            }
            {...a11yProps(4)}
          />
          <Tab
            sx={styles}
            label={
              <span className="spanTab">
                {/* <i className="fa-solid fa-spade mr-1"></i> */}
                <TrackChangesIcon />
                {t("tabs.evolution")}
              </span>
            }
            {...a11yProps(5)}
          />
        </Tabs>
      </Box>
      <div className="filter mt-4">
        <TabPanel value={value} index={0}>
          <TabBetting />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <TabCasino />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <TabAviatrix />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <TabGoldenRace />
        </TabPanel>

        <TabPanel value={value} index={4}>
          <TabBingo />
        </TabPanel>

        <TabPanel value={value} index={5}>
          <TabEvolution />
        </TabPanel>

        <TabPanel value={value} index={6}>
          <TabVirtual />
        </TabPanel>
      </div>
    </Box>
  );
};
