import moment from "moment";
import { FC } from "react";
import { View, Text } from "@react-pdf/renderer";

import { IRefills } from "../../../interfaces/refills";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import { headersRefills } from "../../../helpers/headersTables";
import { useTranslation } from "react-i18next";
import { buildRedName } from "../../../utils/utils";

interface Props {
  page: IRefills[];
}

export const RefillsPdfDocument: FC<Props> = ({ page }) => {
  const { t } = useTranslation();
  const columns = headersRefills;
  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
         {columns.map((column) => (
          <View key={column.key} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        {page.map((page: IRefills) => {
          const method = page.meta?.debitParty?.typeClass
            ? `- ${t(`paymentMethods.${page.meta?.debitParty?.typeClass}`)}`
            : "";
          return (
            <View key={page.id} style={stylesPdf.row}>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{page.id}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{page.meta?.transactionReference || ""}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {page.player.email}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {page.amount}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {page.transactionType.name + " " + method}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {buildRedName(page)}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {moment(page.createAt).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </Text>
              </View>
            </View>
          )}
        )}
      </View>
    </>
  );
};
