import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import {
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";
import { arraySearch } from "./searchActions";
import { Roles_Enum } from "../../enums/enums";
import {
  getAssociatesService,
  getAssociatesFilterService,
  addAssociatesService,
  editAssociatesService,
} from "../../services/associates";
import {
  IAssociates,
  IAssociateForm,
  IAssociatesData,
} from "../../interfaces/associate";

/* GET ASOCIADOS */
export const setGetAssociates = (
  token: string,
  page: number,
  totalData?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { user } = (getState() as AppState).auth;
    try {
      dispatch(setLoadingState({ loading: true }));
      const response =
        user &&
        (await getAssociatesService(
          token,
          page,
          totalData ? null : rowsPerPage,
          user.operator ? user.operator.id : null
        ));

      if (response && !response.error) {
        const data: IAssociates = response;
        dispatch(setAssociatesRedux(data));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorSetAssociates"));
        dispatch(setTypeAlert("error"));
      }
    } catch (error) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.errorSetAssociates"));
      dispatch(setTypeAlert("error"));
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const setAssociatesRedux = (associates: IAssociates | null) => ({
  type: Types.setAssociates,
  payload: associates,
});

/* FILTRAR ASOCIADOS */
export const getAssociatesFilter = (
  formValues: any,
  cleanFilter?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (formValues.page) {
      formValues.page = formValues.page.toString();
      formValues.perPage = rowsPerPage.toString();
    }

    try {
      if (token) {
        let finalValues: any = {};
        Object.entries(formValues).forEach(([key, value], index) => {
          //@ts-ignore
          if (value !== undefined && value.length > 0) {
            //@ts-ignore
            finalValues[key] = value;
          }
        });

        const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
        const operatorId = !isSuper && user ? user.operator.id : null;

        dispatch(setLoadingState({ loading: true }));
        const response =
          user &&
          (await getAssociatesFilterService(token, operatorId, finalValues));

        if (response && !response.error) {
          if (cleanFilter) {
            dispatch(arraySearch(null));
            dispatch(setAssociatesRedux(response));
          } else {
            dispatch(arraySearch(response));
          }
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.errorSetAssociates"));
          dispatch(setTypeAlert("error"));
        }

        dispatch(setLoadingState({ loading: false }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

/* CREAR ASOCIADO */
export const startAddAssociate = (data: IAssociateForm) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;

    if (!token || !user) {
      throw new Error("INVALID ACTION");
    }

    try {
      const response = token && (await addAssociatesService(data, token));

      if (response && response.raw) {
        dispatch(arraySearch(null));
        token && (await dispatch(setGetAssociates(token, 1)));

        dispatch(setMessageAlert("associates.successCreateAssociate"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
      } else {
        dispatch(setMessageAlert("alerts.createAssociateError"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      throw new Error(error as any);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};
export const addAssociateRedux = (
  associate: IAssociateForm,
  rowsPerPage: number
) => ({
  type: Types.addAssociate,
  payload: { associate, rowsPerPage },
});

/* EDITAR ASOCIADO */
export const startEditAssociate = (
  associateData: IAssociateForm,
  id: number,
  isTableAction?: boolean,
  isFilterByState?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    const { data } = (getState() as AppState).search;
    const { associates } = (getState() as AppState).associates;
    if (!token || !user) {
      throw new Error("INVALID ACTION");
    }
    const { associatesUsers, ...rest } = associateData;
    try {
      const response =
        token &&
        (await editAssociatesService({ associatesForm: rest }, token, id));

      if (response && !response.error) {
        // @ts-ignore
        associateData.associatesUsers = associateData.associatesUsers
          ? associateData.associatesUsers.map((user) => {
              return {
                ...user,
                user: { ...user.user, stateUser: associateData.stateUser },
              };
            })
          : [];
        if (isTableAction) {
          const newData = data ? { ...data } : associates && { ...associates };
          newData.data =
            data && isFilterByState
              ? newData.data.filter((el: any) => el.id !== id)
              : newData.data.map((el: any) =>
                  el.id === id ? associateData : el
                );
          newData.total =
            data && isFilterByState ? data.total - 1 : newData.total;
          newData.last_page =
            data && isFilterByState
              ? Math.ceil((data.total - 1) / rowsPerPage)
              : newData.last_page;

          dispatch(data ? arraySearch(newData) : setAssociatesRedux(newData));
        } else {
          dispatch(arraySearch(null));
          token && (await dispatch(setGetAssociates(token, 1)));
        }

        dispatch(setMessageAlert("associates.updatedAssociate"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.editAssociateError"));
        dispatch(setTypeAlert("error"));
      }
    } catch (error) {
      throw new Error(error as any);
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const editAssociateRedux = (associate: IAssociateForm) => ({
  type: Types.updateAssociate,
  payload: associate,
});

export const setActiveAssociate = (associate: IAssociatesData | null) => ({
  type: Types.setActiveAssociate,
  payload: associate,
});
