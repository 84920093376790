export const getAffiliates = (token: string, params?: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}affiliations/report?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const updateCommission = (
  token: string,
  operator: number,
  params: any
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(params),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}operators/updateBasicData/${operator}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const updatePointSale = (token: string, params: any) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(params),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}affiliations/players/update`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const getPointSale = (token: string, params?: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}pointsSale?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
