export interface InputFilter {
  inputUno: string;
  inputDos: string;
  inputTres?: string;
  inputCuatro?: string;
  inputCinco?: string;
  inputSeis?: string;
}
export interface ISelectFilter {
  datos: ISelectDatos[];
}

interface ISelectDatos {
  id: number;
  label: string;
  value: string | number;
}
export interface IColumnsTable {
  id: number;
  header: string;
}

export interface Icons {
  see?: boolean;
  edit?: boolean;
  delete?: boolean;
  seeMore?: boolean;
  add?: boolean;
  activate?: boolean;
  pay?: boolean;
  send?: boolean;
}
export enum Enum_FieldType {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  DATE = "DATE",
  SELECT = "SELECT",
  RADIO = "RADIO",
  SELECT_CHECKBOX = "SELECT_CHECKBOX"
}
export interface ISelectOptions {
  label: string;
  value: string | number;
}
export interface OptionSelectCheckBox {
  [key: string]: {
    check: boolean;
    value: number;
    // Otras propiedades si las hay
  };
}
export interface IFilterField {
  //id: number;
  label: string;
  type: Enum_FieldType;
  value?: string | number;
  options?: ISelectOptions[] | OptionSelectCheckBox;
}
export enum TypeReportChanges{
  LIMITS = 1,
  EXCLUSIONS = 2
}
