import moment from "moment";
import { FC } from "react";
import { TableCell } from "@mui/material";
import { IBonusRedeemed } from "../../../../interfaces/bonus";
import {
  bonusCampaignType,
  bonusRedeemedStatus,
} from "../../../../helpers/bonusType";
import { ProvidersBonus, ProvidersString } from "../../../../enums/enums";
interface Props {
  row: IBonusRedeemed;
}
export const RowDataTableBonds: FC<Props> = ({ row }) => {
  const { bonus } = row;
  const { percentageDeposit, totalValue } = bonus;
  const Bonusvalue =
    ProvidersBonus.ALTENAR === row.bonus.provider.id
      ? percentageDeposit
        ? row.value
        : row.bonus.value
      : totalValue;

  const selectedProvider = row.bonus.provider.id;
  const altenarBonusType = row.bonus.altenarBonusType;

  const bonusTypeMap: any = {
    [ProvidersBonus.PRAGMATIC]: ProvidersString.PRAGMATIC,
    [ProvidersBonus.PARIPLAY]: ProvidersString.PARIPLAY,
  };

  const selectedBonusType = bonusTypeMap[selectedProvider];

  const selectedLabel = altenarBonusType
    ? (bonusCampaignType.find((type) => type.value === altenarBonusType) || {})
        .label
    : selectedBonusType;

  return (
    <>
      <TableCell align="left">{selectedLabel}</TableCell>
      <TableCell align="left"> {row.bonus.name}</TableCell>
      <TableCell align="left">$ {Bonusvalue}</TableCell>
      <TableCell align="left">
        {moment(row.createAt).format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
      <TableCell align="left">
        {moment(row.bonus.endDate).format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
      <TableCell align="left">
        {bonusRedeemedStatus
          .find((type) => type.value === row.state)
          ?.label.toUpperCase() || ""}
      </TableCell>
    </>
  );
};
