import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import { IBet, IBetData } from "./../../interfaces/bets";
import { setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";
import { arraySearch } from "./searchActions";
import {
  getBets,
  getBetsByAssociateSeller,
  getPlayerBets,
  payBetService,
} from "../../services/bets";
import { buildFinalQueryParams } from "../../utils/utils";

export const setActiveTicket = (bet: IBetData | null) => ({
  type: Types.setActiveBet,
  payload: bet,
});

export const setBetsRedux = (bets: IBet | null) => ({
  type: Types.setBets,
  payload: bets,
});

export const startFilterBets = (
  params: any,
  cleanFilter?: boolean,
  ticket?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;

    if (params.page || rowsPerPage) {
      params.page = params.page.toString();
      params.perPage = rowsPerPage.toString();
    }

    try {
      if (token) {
        let finalValues: any = buildFinalQueryParams(params);

        const response =
          user && (await getBetsByAssociateSeller(token, finalValues));

        if (response && !response.error) {
          if (cleanFilter) {
            dispatch(arraySearch(null));
            dispatch(setBetsRedux(response));
          } else {
            if (response.data.length === 0 && ticket) {
              dispatch(setOpenAlert());
              dispatch(setMessageAlert("bets.ticketNumberNotExist"));
              dispatch(setTypeAlert("error"));
              dispatch(arraySearch(null));
            } else {
              dispatch(arraySearch(response));
            }
          }
        } else {
          dispatch(setOpenAlert());
          dispatch(
            setMessageAlert(
              ticket ? "alerts.errorSetTickets" : "alerts.errorSetBets"
            )
          );
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const startGetBets = (page: number) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token, user } = (getState() as AppState).auth;
      const { rowsPerPage } = (getState() as AppState).helper;
      if (!token || !user) {
        throw new Error("INVALID ACTION");
      }
      const operatorId = user.operator ? user.operator.id : null;
      const params = {
        perPage: rowsPerPage,
        page,
      };

      const rs = await getBets(token, operatorId, params);

      if (rs && !rs.error) {
        dispatch(arraySearch(null));
        dispatch(setBetsRedux(rs));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorSetBets"));
        dispatch(setTypeAlert("error"));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const setTotalBets = (data: IBet | null) => ({
  type: Types.setTotalBets,
  payload: data,
});

export const startGetPlayerBets = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    if (!token || !user) {
      throw new Error("INVALID ACTION");
    }

    if (params.page || rowsPerPage) {
      params.page = params.page.toString();
      params.perPage = rowsPerPage.toString();
    }
    try {
      let finalValues: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (value && value.length > 0) {
          finalValues[key] = value;
        }
      });

      const response = await getPlayerBets(token, finalValues);

      if (response && !response.error) {
        if (response.data.length === 0) {
          dispatch(setMessageAlert("alerts.playerNotBets"));
          dispatch(setTypeAlert("warning"));
          dispatch(setOpenAlert());
          dispatch(setBetsRedux(null));
        } else {
          dispatch(setBetsRedux(response));
        }
      } else {
        dispatch(setMessageAlert("alerts.errorSetBets"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startPayBet = (dataBet: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;

    if (!token || !user) {
      throw new Error("INVALID ACTION");
    }
    const dataUpdated = {
      id: dataBet.id,
      amountPay: dataBet.amountPay,
      taxValue: dataBet.taxValue,
      playerId: dataBet.playerId,
      reference: dataBet.reference,
    };

    try {
      const rs = await payBetService(dataUpdated, token, dataUpdated.id);

      if (rs && !rs.error) {
        dispatch(setMessageAlert("bets.successPayment"));
        dispatch(setTypeAlert("success"));
      } else {
        dispatch(setMessageAlert(`bets.${rs.error}`));
        dispatch(setTypeAlert("error"));
      }
      dispatch(setOpenAlert());
    } catch (error) {
      dispatch(setMessageAlert(`bets.failedPaid`));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};
