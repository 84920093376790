import { State } from "../enums/enums";
import { IPaginationProps } from "../interfaces/shared";

export interface IQueryParams extends IPaginationProps {
  id: string;
  player: string;
  startDate: string;
  endDate: string;
  createBy: string;
}
export interface IObservationData {
  observation: string;
  player: number;
  createBy: number;
  action: State;
}

export const getObservationsPlayerState = (
  token: string,
  params: Partial<IQueryParams>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}observationPlayerState?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
export const createObservationsPlayerState = (
  token: string,
  data: IObservationData
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}observationPlayerState`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
