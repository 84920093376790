import React from "react";

import { MyAlert } from "../../components/custom/MyAlert";
import {
  setCloseAlert,
  setMessageAlert,
} from "../../../redux/actions/helperActions";

import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useTranslation } from "react-i18next";
import { BonusTabs } from "./tabs/BonusTabs";
import { Box } from "@mui/material";

export const ManagementBonus = () => {
  const { openAlert, messageAlert, typeAlert } = useSelector(
    (state: AppState) => state.helper
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onCloseAlert = () => {
    dispatch(setCloseAlert());
    dispatch(setMessageAlert(""));
  };

  return (
    <div className="content w-100 mb-5">
      <MyBreadCrumbs
        titles={[t("sidebar.settings"), t("managementBonds.title")]}
      />
      <Box mt={2}>

      <BonusTabs />
      </Box>

      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />
    </div>
  );
};
