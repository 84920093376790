import React, { useEffect, useState, useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
//@ts-ignore
import classNames from "classnames";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav, Collapse } from "reactstrap";
import { IRoutes } from "../../interfaces/menu";
import logoNeat from "../../assets/svg/logo-neat.svg";
import logoNeatHover from "../../assets/svg/logo-neat-hover.svg";
import logoSm from "../../assets/svg/logo-neat-mini.svg";
import logoSmHover from "../../assets/svg/logo-neat-mini-hover.svg";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import { arraySearch } from "../../redux/actions/searchActions";
import { useDispatch, useSelector } from "react-redux";
import { uiCloseNotifications } from "../../redux/actions/uiAction";
import { AppState } from "../../redux/reducers/rootReducer";
import { setFlagForm } from "../../redux/actions/helperActions";

let ps: any;

const Sidebar = (props: any) => {
  const [state, setState] = React.useState({});
  const sidebarRef = React.useRef(null);
  const location = useLocation();
  const [sidebarWidth, setSidebarWidth] = useState(80);
  const [imageLogo, setImageLogo] = useState(logoNeat);
  const [imageLogoSm, setImageLogoSm] = useState(logoSm);
  const { openNotifications } = useSelector((state: AppState) => state.ui);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const dispatch = useDispatch();

  /* React.useEffect(() => {
    setState(getCollapseStates(props.routes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); */
  useEffect(() => {
    setState(getCollapseStates(props.routes));

    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      //@ts-ignore
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    // this verifies if any of the collapses should be default opened on a rerender of this component
    const resizeObserver = new ResizeObserver((event) => {
      const mainPanel = document.getElementById("main-panel");
      if (mainPanel) {
        if (event[0].contentBoxSize[0].inlineSize > 80) {
          mainPanel.classList.add("opacity-main-panel");
        } else {
          mainPanel.classList.remove("opacity-main-panel");
        }
      }
      setSidebarOpen(event[0].contentBoxSize[0].inlineSize === 273);
      setSidebarWidth(event[0].contentBoxSize[0].inlineSize);
    });
    const element = document.getElementById("main-sidebar");
    if (element) {
      resizeObserver.observe(element);
    }

    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      setSidebarOpen(false);
      setSidebarWidth(80);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes: IRoutes[]) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          //@ts-ignore
          [prop.state]: getCollapseInitialState(prop.menuItem),
          //@ts-ignore
          ...getCollapseStates(prop.menuItem),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  /* useEffect(() => {
    const resizeObserver = new ResizeObserver((event) => {
      const mainPanel = document.getElementById("main-panel");
      if (mainPanel) {
        if (event[0].contentBoxSize[0].inlineSize > 80) {
          mainPanel.classList.add("opacity-main-panel");
        } else {
          mainPanel.classList.remove("opacity-main-panel");
        }
      }
      setSidebarOpen(event[0].contentBoxSize[0].inlineSize === 273);
      setSidebarWidth(event[0].contentBoxSize[0].inlineSize);
    });
    const element = document.getElementById("main-sidebar");
    if (element) {
      resizeObserver.observe(element);
    }
  }, []); */

  //this closes the notification with the esc key
  const checkKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const { code } = event;

      if (code === "Escape") {
        if (sidebarOpen) {
          props.openSidebar();
          props.closeSidebar();
        }

        if (openNotifications) {
          dispatch(uiCloseNotifications());
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openNotifications, sidebarOpen]
  );
  useEffect(() => {
    document.addEventListener("keydown", checkKeyPress);

    return () => {
      document.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes: IRoutes[]) => {
    for (let i = 0; i < routes.length; i++) {
      //@ts-ignore

      if (routes[i].collapse && getCollapseInitialState(routes[i].menuItem)) {
        return true;
        //@ts-ignore
      } else if (window.location.href.indexOf(routes[i].url) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes: IRoutes[], isCollapse: boolean = false) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        const st = {};
        //@ts-ignore
        st[prop["state"]] = !state[prop.state];
        return (
          <li key={key}>
            <a
              href="/#"
              data-toggle="collapse"
              //@ts-ignore
              aria-expanded={state[prop.state]}
              className="hover-ancla"
              onClick={(e) => {
                e.preventDefault();
                if (sidebarWidth === 80) {
                  props.openSidebar();
                }
                setState({ ...state, ...st });
              }}
            >
              {prop.icon !== undefined && (
                <Grid container>
                  <Grid item xs={10}>
                    <Box display={"flex"} flexDirection="row">
                      <Box>
                        <Tooltip
                          title={sidebarOpen ? "" : prop.name}
                          placement="right-end"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: "#02D0E7",
                                "& .MuiTooltip-arrow": {
                                  color: "white",
                                },
                                fontSize: "13px",
                                borderRadius: "25px",
                              },
                            },
                          }}
                        >
                          <i
                            className={`${prop.icon} ${
                              //@ts-ignore
                              getCollapseInitialState(prop.menuItem)
                                ? "sidebar-active-link"
                                : ""
                            } `}
                            style={{ fontSize: "18px !important" }}
                          />
                        </Tooltip>
                      </Box>
                      <Box style={{ width: "100%", wordWrap: "break-word" }}>
                        <p
                          className="sidebar-text"
                          style={
                            sidebarWidth < 150
                              ? {
                                  whiteSpace: "nowrap",
                                }
                              : undefined
                          }
                        >
                          {prop.name}
                        </p>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <p
                      style={
                        sidebarWidth < 150
                          ? {
                              whiteSpace: "nowrap",
                            }
                          : undefined
                      }
                    >
                      <i className="fa-solid fa-angle-down caret my-caret pt-2"></i>
                    </p>
                  </Grid>
                </Grid>
              )}
            </a>
            {/* 
        //@ts-ignore*/}
            <Collapse isOpen={state[prop.state] && sidebarWidth > 80}>
              {/* 
        //@ts-ignore*/}
              <ul className="nav">{createLinks(prop.menuItem, true)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li key={key}>
          <NavLink
            to={prop.url ? prop.url : "#"}
            activeClassName=""
            onClick={() => {
              if (sidebarWidth > 80) {
                props.openSidebar();
              }
              props.closeSidebar();
              dispatch(setFlagForm({ flagFormCreate: false }));
              dispatch(arraySearch(null));
            }}
          >
            {prop.icon && !isCollapse ? (
              <Box display={"flex"} flexDirection="row">
                <Box>
                  <Tooltip
                    title={prop.name}
                    placement="right-end"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#02D0E7",
                          "& .MuiTooltip-arrow": {
                            color: "white",
                          },
                          fontSize: "13px",
                          borderRadius: "25px",
                        },
                      },
                    }}
                  >
                    <i
                      className={`${prop.icon} ${
                        activeRoute(prop) ? "sidebar-active-link" : ""
                      } `}
                    />
                  </Tooltip>
                </Box>
                <Box>
                  <p
                    className={`sidebar-text ${
                      activeRoute(prop) ? "sidebar-active-text" : ""
                    }`}
                    style={
                      sidebarWidth < 150
                        ? {
                            whiteSpace: "nowrap",
                          }
                        : undefined
                    }
                  >
                    {prop.name}
                  </p>
                </Box>
              </Box>
            ) : (
              <Grid container>
                <Grid item xs={3} />
                <Grid item xs={9}>
                  <Box
                    className={classNames(
                      `sidebar-normal sidebar-text-sm
                    } ${activeRoute(prop) ? "sidebar-active-text" : ""}`
                    )}
                    style={{ width: "100%", wordWrap: "break-word" }}
                  >
                    <span>{prop.name}</span>
                  </Box>
                </Grid>
              </Grid>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (route: any) => {
    return location.pathname === route.url || "";
  };

  const { activeColor, logo } = props;
  let logoImg = null;
  let logoText = null;
  if (logo !== undefined) {
    if (logo.outterLink !== undefined) {
      logoImg = (
        <div style={{ display: "flex", width: 1 }}>
          {sidebarWidth <= 200 && (
            <IconButton
              onClick={props.openSidebar}
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="logo-lg-sidebar-mini">
                {
                  <img
                    src={imageLogoSm}
                    alt="react-logo"
                    onMouseOver={() => setImageLogoSm(logoSmHover)}
                    onMouseLeave={() => setImageLogoSm(logoSm)}
                  />
                }
              </div>
            </IconButton>
          )}

          {sidebarWidth > 150 && (
            <IconButton
              disableRipple
              onClick={props.openSidebar}
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={imageLogo}
                  className="logo-lg-sidebar"
                  alt="NEAT LOGO"
                  onMouseOver={() => setImageLogo(logoNeatHover)}
                  onMouseLeave={() => setImageLogo(logoNeat)}
                />
              </div>
            </IconButton>
          )}
        </div>
      );
      logoText = (
        <a
          href={logo.outterLink}
          className="simple-text logo-normal"
          target="_blank"
          onClick={props.closeSidebar}
          rel="noreferrer"
        >
          {logo.text}
        </a>
      );
    } else {
      logoImg = (
        <NavLink
          to={logo.innerLink}
          className="simple-text logo-mini"
          onClick={props.closeSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </NavLink>
      );
      logoText = (
        <NavLink
          to={logo.innerLink}
          className="simple-text logo-normal"
          onClick={props.closeSidebar}
        >
          {logo.text}
        </NavLink>
      );
    }
  }
  useEffect(() => {
    dispatch(uiCloseNotifications());
  }, [location.pathname, dispatch]);
  return (
    <div
      id={"main-sidebar"}
      className={classNames("sidebar my-sidebar")}
      //@ts-ignore
      data={activeColor}
    >
      {/* <span className="sidebar-vertical-line" /> */}
      <div className="sidebar-wrapper" ref={sidebarRef}>
        {logoImg !== null || logoText !== null ? (
          <>
            <div className="logo">{logoImg}</div>
          </>
        ) : null}

        <Nav>{createLinks(props.routes)}</Nav>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  activeColor: PropTypes.oneOf(["primary", "blue", "green", "orange", "red"]),
  rtlActive: PropTypes.bool,
  routes: PropTypes.array.isRequired,
  logo: PropTypes.oneOfType([
    PropTypes.shape({
      innerLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      outterLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
    }),
  ]),
  // this is used on responsive to close the sidebar on route navigation
  closeSidebar: PropTypes.func,
};

export default Sidebar;
