import React, { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { Enum_FieldType, IFilterField } from "../../../interfaces/components";
import { useTranslation } from "react-i18next";
import Filter from "../../../components/Filter/Filter";
import { CustomAlert } from "../../../components/Alerts/CustomAlert";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncLoadingFunction,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import {
  setActiveCardDealer,
  setCardDealers,
  startGetCardDelaers,
} from "../../../redux/actions/managementCardsActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import MyCustomTable, {
  IColumns,
} from "../../../components/Custom/Table/MyCustomTable";
import { RowDealerWallet } from "../../components/ManagementCards/RowDealerWallet";
import { MyCustomDialog } from "../../../components/Custom/MyCustomDialog";
import { uiSetOpenDialog } from "../../../redux/actions/uiAction";
import { DetailsDealerWallet } from "../../components/dealerWallet/DetailsDealerWallet";
import {
  getCardByLotId,
  getCardDealersReport
} from "../../../services/cards";
import { ICardsList } from "../../../interfaces/cards";
import { Roles_Enum, StateCards } from "../../../enums/enums";
import { ExportJerCardsReport } from "../../components/ManagementCards/ExportJerCardsReport";
import { getDealers } from "../../../services/dealers";

export const DealerWallet = () => {
  const [dataFilter, setDataFilter] = useState<any>({});
  const { openDialog } = useSelector((state: AppState) => state.ui);
  const [isFiltering, setIsFiltering] = useState(false);
  const { cardDealers } = useSelector(
    (state: AppState) => state.managementCards
  );
  const { rowsPerPage, loading } = useSelector(
    (state: AppState) => state.helper
  );
  const { user, token } = useSelector((state: AppState) => state.auth);
  const [allDeallers, setAllDeallers] = useState<any[]>([]);
  const [FilterFileds, setFilterFileds] = useState<any>(null);
  const [cardsReport, setCardsReport] = useState<ICardsList[]>([]);
  const [isBuildingReport, setIsBuildingReport] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isJerRol = user && user.roles.name.toUpperCase() === Roles_Enum.JER
 

  useEffect(() => {
    const getAllDealers = async () => {
      if (!user || !token) {
        throw new Error("invalid action");
      }
      let params: any;
      if (user.operator && !isJerRol) {
        params = {
          operator: user.operator.id,
        };
      }
      const rs = await getDealers(
        {
          ...params,
        },
        token
      );
      if (!rs.error) {
        setAllDeallers(rs.data);
      }
    };

    ((user && user?.userDealer?.length === 0) || !user?.operator) &&
      getAllDealers();
    return () => {
      dispatch(setCardDealers(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (allDeallers) {
      const filterFields: IFilterField[] = [
        {
          label: t("filters.startDate"),
          type: Enum_FieldType.DATE,
        },
        {
          label: t("filters.endDate"),
          type: Enum_FieldType.DATE,
        },
      ];
      ((user && user?.userDealer?.length === 0) || !user?.operator) &&
        filterFields.push({
          label: t("cards.selectDealer"),
          type: Enum_FieldType.SELECT,
          options: [
            ...allDeallers.map((dealer) => ({
              value: dealer.id,
              label: dealer.name,
            })),
          ],
        });

      setFilterFileds(filterFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDeallers]);

  useEffect(() => {
    if (cardDealers && cardDealers.data.length === 0) {
      dispatch(
        setMessageAlert(isFiltering ? "alerts.noData" : "alerts.noRecords")
      );
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDealers]);

  const handleFilter = async (values: any) => {
    if (!user) {
      throw new Error("invalid action");
    }
    const dealerId =
      user.operator && user.userDealer.length > 0
        ? user.userDealer[0].dealer.id
        : values[t("cards.selectDealer")];
    const params = {
      startDate: values[t("filters.startDate")],
      endDate: values[t("filters.endDate")],
      dealer: dealerId,
      deliveredDealer: true,
    };

    await dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startGetCardDelaers({
            ...params,
            page: 1,
          })
        )
      )
    );
    setIsFiltering(true);
    setDataFilter({ ...params });
    setCardsReport([]);

    if (isJerRol) {
      await handleBuildReport(params);
    }
  };

  const onCleanFilter = async () => {
    dispatch(setCardDealers(null));
    setDataFilter({});
    setIsFiltering(false);
  };
  let columns: IColumns[] = [
    {
      align: "center",
      label: t("managementDealers.idDealer"),
    },
    {
      align: "center",
      label: t("managementDealers.dealerName"),
    },
    {
      align: "center",
      label: t("dealerWallet.cardValue"),
    },
    {
      align: "center",
      label: t("dealerWallet.idLot"),
    },
    {
      align: "center",
      label: t("dealerWallet.assignedCards"),
    },
    {
      align: "center",
      label: t("dealerWallet.commission"),
    },
    {
      align: "center",
      label: t("dealerWallet.activationDate"),
    },
    {
      align: "center",
      label: t("table.state"),
    },
  ];
  if (!isJerRol) {
    columns = [
      ...columns,
      {
        align: "center",
        label: t("table.actions"),
      },
    ];
  }
  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(
        async () =>
          await dispatch(
            startGetCardDelaers({
              ...dataFilter,
              page,
              perPage: rows || rowsPerPage,
            })
          )
      )
    );
  };
  const handleCloseDetails = () => {
    dispatch(uiSetOpenDialog());
    dispatch(setActiveCardDealer(null));
  };
  const handleBuildReport = async (params?: any) => {
    if (!token || !user) {
      throw new Error("INVALID ACTION");
    }

    let finalParams: any = {};
    const exeptionsTypes = ["number", "boolean"];
    Object.entries(params).forEach(([key, value]) => {
      //@ts-ignore
      if (exeptionsTypes.includes(typeof value) || value.length > 0) {
        //@ts-ignore
        finalParams[key] = value;
      }
    });

    setIsBuildingReport(true);
    const allCardDealers: any = await getCardDealersReport(finalParams, token);
    let lotIds = allCardDealers.data.map((cards: any) => cards.lot.id);
    //@ts-ignore
    lotIds = [...new Set(lotIds)];
    const paramsCards = finalParams.dealer
      ? {
          dealer: finalParams.dealer,
        }
      : {};

    const allCards = await Promise.all(
      lotIds.map(async (lot: any) => {
        let finalCards = [];
        const cardsRedeemed = await getCardByLotId(lot, token, {
          ...paramsCards,
          state: StateCards.REDEEMED,
        });
        const cardsInactive = await getCardByLotId(lot, token, {
          ...paramsCards,
          state: StateCards.INACTIVE,
        });
        finalCards = [...cardsRedeemed.data, ...cardsInactive.data];
        return finalCards;
      })
    );
    const castedCards = allCards.flat();
    setCardsReport(castedCards);
    setIsBuildingReport(false);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mt={2}>
        {FilterFileds && (
          <Filter
            fields={FilterFileds}
            handleFilter={handleFilter}
            onClean={onCleanFilter}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {(!cardDealers || cardDealers.total === 0) && (
          <CustomAlert htmlText={t("cards.filterDealerWaller")} type="info" />
        )}
        {isJerRol && cardDealers && cardDealers.total > 0 && !loading && (
          <>
            {isBuildingReport && cardsReport.length <= 0 ? (
              <CircularProgress size={"1.6rem"} className={"colorLoading"} />
            ) : (
              <ExportJerCardsReport totalData={cardsReport} />
            )}
          </>
        )}

        {cardDealers && cardDealers.total > 0 && (
          <MyCustomTable
            columns={columns}
            data={cardDealers.data}
            lastPage={cardDealers.last_page}
            totalData={cardDealers.total}
            onPageChange={handlePageChange}
            renderBody={(item) => <RowDealerWallet row={item} />}
          />
        )}
      </Grid>
      <MyCustomDialog
        open={openDialog}
        content={<DetailsDealerWallet />}
        onClose={handleCloseDetails}
        title={t("cards.infoDeliveredCrds")}
        icon={
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 20 }}
          ></i>
        }
      />
    </Grid>
  );
};
