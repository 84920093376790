import { Box, Divider, Grid } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IFullDetailPlayer } from "../../../../interfaces/users";
import "./general.scss";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { startGetFinantialInformation } from "../../../../redux/actions/activityPlayerActions";

const valuesPlayer: IFullDetailPlayer = {
  typeDocument: "",
  numberDocument: "",
  firstName: "",
  secondName: "",
  lastName: "",
  birthDate: "",
  birthDepartment: "",
  birthCity: "",
  residenceDepartment: "",
  residenceCity: "",
  address: "",
  postalCode: "",
  email: "",
  gender: "",
  phone: "",
  affiliator: "",
  user: "",
  login: "",
  username: "",
  specialStatus: "",
  state: "",
  failedLoginAttempts: "",
  createAt: "",
  lastLoginDate: "",
  IpLastLogin: "",
  dateDrawal: "",
  stateRegulation: "",
  credits: "",
  awards: "",
  bonds: "",
  dailyDepositLimit: "",
  weeklyDepositLimit: "",
  monthlyDepositLimit: "",
  dailyBettingLimit: "",
  weeklyBettingLimit: "",
  monthlyBettingLimit: "",
};
interface Props {
  data: any;
}
export const General: React.FC<Props> = ({ data }) => {
  const valuesNotBackend: IFullDetailPlayer = {
    typeDocument: data.length > 0 ? "Carnet de identidad" : "",
    numberDocument: data.length > 0 ? "1094970590" : "",
    firstName: data.length > 0 ? "Daniela Diaz" : "",
    secondName: data.length > 0 ? "Daniela Diaz" : "",
    lastName: data.length > 0 ? "Daniela Diaz" : "",
    birthDate: data.length > 0 ? "1998-09-29" : "",
    birthDepartment: data.length > 0 ? "Guayas" : "",
    birthCity: data.length > 0 ? "Guayaquil" : "",
    residenceDepartment: data.length > 0 ? "Guayas" : "",
    residenceCity: data.length > 0 ? "Guayaquil" : "",
    address: data.length > 0 ? "Pichincha" : "",
    postalCode: data.length > 0 ? "--" : "",
    email: data.length > 0 ? "DDIAZPENA47@GMAIL.COM" : "",
    gender: data.length > 0 ? "Femenina" : "",
    phone: data.length > 0 ? "3142347935" : "",
    affiliator: data.length > 0 ? "--" : "",
    user: data.length > 0 ? "6308" : "",
    login: data.length > 0 ? "DDIAZPENA47@GMAIL.COM" : "",
    username: data.length > 0 ? "DanielaDiazp" : "",
    specialStatus: data.length > 0 ? "Activo" : "",
    state: data.length > 0 ? "Activo" : "",
    failedLoginAttempts: data.length > 0 ? "0" : "",
    createAt: data.length > 0 ? "2022-04-05 18:04:02" : "",
    lastLoginDate: data.length > 0 ? "2022-04-26 15:53:24" : "",
    IpLastLogin: data.length > 0 ? "186.83.184.72" : "",
    dateDrawal: data.length > 0 ? "--" : "",
    stateRegulation: data.length > 0 ? "--" : "",
    credits: data.length > 0 ? "$ 0,00" : "",
    awards: data.length > 0 ? "$ 0,00" : "",
    bonds: data.length > 0 ? "$ 0,00" : "",
    dailyDepositLimit: data.length > 0 ? "$ 2.000,00" : "",
    weeklyDepositLimit: data.length > 0 ? "$ 15.000,00" : "",
    monthlyDepositLimit: data.length > 0 ? "$ 60.000,00" : "",
    dailyBettingLimit: data.length > 0 ? "$ 0,00" : "",
    weeklyBettingLimit: data.length > 0 ? "$ 0,00" : "",
    monthlyBettingLimit: data.length > 0 ? "$ 0,00" : "",
  };
  const dispatch = useDispatch();
  const { player } = useSelector((state: AppState) => state.managementPlayers);

  const [dataFinaltialInformation, setDataFinaltialInformation] = useState<any>(
    {
      totalDeposits: 0,
      totalBets: 0,
      totalPrizePayments: 0,
      totalWithdrawals: 0,
      ggr: 0,
    }
  );

  useEffect(() => {
    setData(valuesNotBackend);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [dataPlayers, setData] = useState(valuesPlayer);
  const { t } = useTranslation();

  const getFinantialInformation = async (playerId: any) => {
    const result: any = await dispatch(
      startGetFinantialInformation({ playerId: playerId })
    );
    if (result) {
      setDataFinaltialInformation({
        totalDeposits: +result.totalDeposits || 0,
        totalBets: +result.totalBets || 0,
        totalPrizePayments: +result.totalPrizePayments || 0,
        totalWithdrawals: +result.totalWithdrawals || 0,
        ggr: +result.ggr || 0,
      });
    }
  };

  useEffect(() => {
    if (player) {
      getFinantialInformation(player.data[0].id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  const getColorStyle = (value: number) => ({
    color: value >= 0 ? "green" : "red",
  });

  const stateOptions = [
    { label: t("exclusions.stateOptions.active"), value: 1 },
    { label: t("exclusions.stateOptions.inactive"), value: 2 },
    { label: t("exclusions.stateOptions.retained"), value: 3 },
    { label: t("exclusions.stateOptions.pendingValidation"), value: 4 },
    { label: t("exclusions.stateOptions.closed"), value: 5 },
    { label: t("exclusions.stateOptions.selfExcluded"), value: 6 },
  ];

  return (
    <div className="container-fluid">
      <>
        <Box>
          {/* INFORMACIÓN FISCAL */}
          <Grid item xs={12}>
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  "&::before": {
                    width: "20px",
                  },
                },
              }}
              textAlign="left"
              style={{ color: "#000" }}
            >
              {t("tabs.taxInformation")}
            </Divider>
            <Grid container spacing={2} mt={1} mb={3}>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">{t("form.name")}</label>
                <span className="labelP">
                  {data.length > 0
                    ? data[0].secondName
                      ? data[0].firstName +
                        " " +
                        data[0].secondName +
                        " " +
                        data[0].lastName
                      : data[0].firstName + " " + data[0].lastName
                    : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.documentNumber")}
                </label>
                <span className="labelP">
                  {data.length > 0 ? data[0].numberDocument : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.documentType")}
                </label>
                <span className="labelP">
                  {data.length > 0 ? data[0].typeDocument?.name : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.birthDate")}
                </label>
                <span className="labelP">
                  {data.length > 0
                    ? moment(data[0].birthDate).utc().format("YYYY-MM-DD")
                    : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.address")}
                </label>
                <span className="labelP">
                  {data.length > 0 ? data[0].address : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.email")}
                </label>
                <span className="labelP">
                  {data.length > 0 ? data[0].email : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.gender")}
                </label>
                <span className="labelP">
                  {data.length > 0 ? data[0].gender : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.cellphone")}
                </label>
                <span className="labelP">
                  {data.length > 0 ? data[0].phone : ""}
                </span>
              </Grid>
            </Grid>

            {/* INFORMACION DE ACCESO */}
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  "&::before": {
                    width: "20px",
                  },
                },
              }}
              textAlign="left"
              style={{ color: "#000" }}
            >
              {t("tabs.accessInformation")}
            </Divider>
            <Grid container spacing={2} mt={1} mb={3}>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">{t("user.user")}</label>
                <span className="labelP">
                  {data.length > 0 ? data[0].id : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.login")}
                </label>
                <span className="labelP">
                  {data.length > 0 ? data[0].email : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.specialStatus")}
                </label>
                <span className="labelP">
                  {data.length > 0 ? data[0].status : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.state")}
                </label>
                <span className="labelP">
                  {data.length > 0
                    ? stateOptions.find(
                        (option) => option.value === data[0].stateUser
                      )?.label || ""
                    : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.failedLoginAttempts")}
                </label>
                <span className="labelP">
                  {dataPlayers.failedLoginAttempts}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.createAt")}
                </label>
                <span className="labelP">
                  {data.length > 0
                    ? moment(data[0].createAt).format("YYYY-MM-DD HH:mm:ss")
                    : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.lastLoginDate")}
                </label>
                <span className="labelP">
                  {data.length > 0 && data[0].last_login
                    ? moment(data[0].last_login).format("YYYY-MM-DD hh:mm:ss A")
                    : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.dateDrawal")}
                </label>
                <span className="labelP">{dataPlayers.dateDrawal}</span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.stateRegulation")}
                </label>
                <span className="labelP">{dataPlayers.stateRegulation}</span>
              </Grid>
            </Grid>

            {/* SALDOS Y TOPES */}
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  "&::before": {
                    width: "20px",
                  },
                },
              }}
              textAlign="left"
              style={{ color: "#000" }}
            >
              {t("tabs.balancesCaps")}
            </Divider>
            <Grid container spacing={2} mt={1} mb={3}>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.credits")}
                </label>
                <span className="labelP">
                  {data.length > 0
                    ? "$ " + data[0].playerBalance?.amountWallet
                    : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.awards")}
                </label>
                <span className="labelP">
                  {data.length > 0
                    ? "$ " + data[0].playerBalance?.awardsWallet
                    : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.bonds")}
                </label>
                <span className="labelP">
                  {data.length > 0
                    ? "$ " + data[0].playerBalance?.bonusWallet
                    : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("exclusionsCategory.ALL")}
                </label>
                <span className="labelP">
                  {data.length > 0
                    ? "$ " + data[0].playerBalance?.balanceWallet
                    : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.dailyDepositLimit")}
                </label>
                <span className="labelP">
                  {data.length > 0
                    ? parseFloat(data[0].maximumDailyRefill).toFixed(2) ??
                      t("playerActivity.noParam")
                    : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.weeklyDepositLimit")}
                </label>
                <span className="labelP">
                  {data.length > 0
                    ? parseFloat(data[0].maximumWeeklyRefill).toFixed(2) ??
                      t("playerActivity.noParam")
                    : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.monthlyDepositLimit")}
                </label>
                <span className="labelP">
                  {data.length > 0
                    ? parseFloat(data[0].maximumMonthlyRefill).toFixed(2) ??
                      t("playerActivity.noParam")
                    : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.dailyBettingLimit")}
                </label>
                <span className="labelP">
                  {data.length > 0
                    ? data[0].maximumDailyBet
                      ? parseFloat(data[0].maximumDailyBet).toFixed(2)
                      : t("playerActivity.noParam")
                    : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.weeklyBettingLimit")}
                </label>
                <span className="labelP">
                  {data.length > 0
                    ? data[0].maximumWeeklyBet
                      ? parseFloat(data[0].maximumWeeklyBet).toFixed(2)
                      : t("playerActivity.noParam")
                    : ""}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("user.monthlyBettingLimit")}
                </label>
                <span className="labelP">
                  {data.length > 0
                    ? data[0].maximumMonthlyBet
                      ? parseFloat(data[0].maximumMonthlyBet).toFixed(2)
                      : t("playerActivity.noParam")
                    : ""}
                </span>
              </Grid>
            </Grid>
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  "&::before": {
                    width: "20px",
                  },
                },
              }}
              textAlign="left"
              style={{ color: "#000" }}
            >
              {t("tabs.finantianInformation")}
            </Divider>
            <Grid container spacing={2} mt={1} mb={3}>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("form.totalDeposits")}
                </label>
                <span className="labelP">
                  $ {dataFinaltialInformation.totalDeposits}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("form.totalBets")}
                </label>
                <span className="labelP">
                  $ {dataFinaltialInformation.totalBets}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("form.totalAwards")}
                </label>
                <span className="labelP">
                  $ {dataFinaltialInformation.totalPrizePayments}
                </span>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <label className="form-text labelField">
                  {t("form.totalWithdrawals")}
                </label>
                <span className="labelP">
                  $ {dataFinaltialInformation.totalWithdrawals}
                </span>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} mb={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div>
                  <label className="form-text labelField">
                    {t("form.totalggr")}
                  </label>
                  <span
                    className="labelP"
                    style={getColorStyle(dataFinaltialInformation.ggr)}
                  >
                    $ {dataFinaltialInformation.ggr}
                  </span>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
    </div>
  );
};
