import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import Filter from "../../../components/Filter/Filter";
import { TableComponent } from "../../../components/Table/TableComponent";
import {
  Enum_FieldType,
  IColumnsTable,
  Icons,
  IFilterField,
} from "../../../interfaces/components";
import {
  asyncLoadingFunction,
  setCloseAlert,
  setFlagForm,
  setMessageAlert,
} from "../../../redux/actions/helperActions";
import { startGetMessages } from "../../../redux/actions/messagesActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { MyAlert } from "../../components/custom/MyAlert";
import { FormCreatePromotion } from "../../components/promotions/FormCreatePromotion";

export const Promotions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { flagFormCreate, openAlert, messageAlert, typeAlert } = useSelector(
    (state: AppState) => state.helper
  );
  const { data } = useSelector((state: AppState) => state.search);
  const { promotions } = useSelector((state: AppState) => state.promotions);
  const { routes } = useSelector((state: AppState) => state.managementMenus);

  const [dataFilter, setDataFilter] = useState<any>({});

  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);

  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;

  useEffect(() => {
    dispatch(setFlagForm({ flagFormCreate: false }));
    const fetchData = async () => {
      await dispatch(startGetMessages(1));
    };
    dispatch(asyncLoadingFunction(fetchData));
    fetchData();
    return () => {
      dispatch(setFlagForm({ flagFormCreate: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
  ];
  const columns: IColumnsTable[] = [
    { id: 1, header: "promotions.promotionID" },
    { id: 2, header: "promotions.promotionName" },
    { id: 3, header: "table.startDate" },
    { id: 4, header: "table.deadline" },
    { id: 5, header: "table.uploadedImage" },
    { id: 6, header: "table.actions" },
  ];
  const iconos: Icons = {
    see: false,
    seeMore: false,
    add: false,
  };

  const handleShowForm = () => {
    dispatch(setFlagForm({ flagFormCreate: true }));
  };
  const onCloseAlert = () => {
    dispatch(setCloseAlert());
    dispatch(setMessageAlert(""));
  };

  return (
    <div className="content w-100 mb-5">
      <MyBreadCrumbs
        titles={[t("sidebar.settings"), t("promotions.promotionManagement")]}
      />

      <div className="white-container mt-2">
        {flagFormCreate ? (
          <FormCreatePromotion />
        ) : (
          <Grid container spacing={2}>
            {enableCreated && (
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <Box display="flex" alignItems="center">
                    <p
                      className="txt-primary-md"
                      style={{
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        alignSelf: "center",
                      }}
                    >
                      {t("promotions.createPromotion").toLocaleUpperCase()}
                    </p>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={handleShowForm}
                      id="openFormBtn"
                      data-testid="openFormBtn"
                      style={{ border: "none", outline: "none" }}
                    >
                      <span className="btn-add">
                        <i
                          className="fa-solid fa-plus"
                          style={{ fontSize: 30 }}
                        ></i>
                      </span>
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <Filter
                fields={filterFields}
                setDataFilter={setDataFilter}
                component="promotions"
              />
            </Grid>
            <Grid item xs={12}>
              {data ? (
                <>
                  {data.data.length > 0 && (
                    <TableComponent
                      columns={columns}
                      data={data.data}
                      pages={data.lastPage}
                      icons={iconos}
                      component={"promotions"}
                      totalData={data}
                      isSortingTable={true}
                      dataFilter={dataFilter}
                    />
                  )}
                </>
              ) : (
                <>
                  {promotions && promotions.data.length > 0 && (
                    <TableComponent
                      columns={columns}
                      data={promotions.data}
                      pages={promotions.lastPage}
                      icons={iconos}
                      component={"promotions"}
                      totalData={promotions}
                      isSortingTable={true}
                      dataFilter={dataFilter}
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
        )}
      </div>
      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />
    </div>
  );
};
