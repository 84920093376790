import { useTranslation } from "react-i18next";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import Tabs from "./Tabs";
import CasinoTab from "./CasinoTab";
import CasinoLiveTab from "./CasinoLiveTab";
import GamesTable from "./GamesTable";

export const Casino = () => {
  const { t } = useTranslation();
  const tabs = [
    { label: "Casino", content: <CasinoTab /> },
    { label: "Casino en Vivo", content: <CasinoLiveTab /> },
    { label: "Juegos", content: <GamesTable /> },
  ];
  return (
    <div className="content w-100 mb-5">
      <MyBreadCrumbs titles={[t("sidebar.settings"), "Administrar Casino"]} />
      <Tabs tabs={tabs} />
    </div>
  );
};
