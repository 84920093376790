import { IPlayerByTypeAndNumberDocumentParams } from "../../interfaces/players";
import {
  approveOrDeny,
  getExternalDeposit,
  playerByTypeAndNumberDocumentService,
} from "../../services/depositsPlayers";
import { buildFinalQueryParams } from "../../utils/utils";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import { setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";
import { arraySearch } from "./searchActions";
import { startGetPointSaleWallet } from "./walletActions";

export const getPlayerByTypeAndNumberDocument = (
  params: Partial<IPlayerByTypeAndNumberDocumentParams>
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      if (!token) {
        throw new Error("INVALID ACTION");
      }

      let finalValues: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (typeof value === "number" || value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });
      return await playerByTypeAndNumberDocumentService(token, finalValues);
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startGetExternalDeposit = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    try {
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      const finalValues = buildFinalQueryParams(params);
      finalValues.perPage = rowsPerPage;
      const response = await getExternalDeposit(token, finalValues);
      if (!response.error) {
        if (response.data?.length === 0) {
          dispatch(arraySearch(null));
          dispatch(setMessageAlert("alerts.noData"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
        } else {
          dispatch(arraySearch(response));
        }
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startApproveOrDeny = (params: any, id: number) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token, user } = (getState() as AppState).auth;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      const response = await approveOrDeny(token, params, id);
      if (response.error) {
        dispatch(setMessageAlert(`alerts.${response.error.errorMessage}`));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("Depósito actualizado exitosamente"));
        dispatch(setTypeAlert("success"));
      }
      await dispatch(startGetExternalDeposit({ userId: user?.id }));
      await dispatch(startGetPointSaleWallet());
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const setDepositQR = (deposits: any) => ({
  type: Types.setDepositQR,
  payload: deposits,
});

export const setActiveDepositQR = (deposits: any) => ({
  type: Types.setActiveDepositQR,
  payload: deposits,
});
