import { IPaginationRoles, IRoles, IRolesDb } from "../interfaces/users";
import { Types } from "../types/types";
interface IRolesState {
  roles: IPaginationRoles | null;
  activeRol: IRolesDb | null;
}

export interface IRolesActions {
  type: Types;
  payload?: null | IRoles | IPaginationRoles | IRolesDb | number;
}
const initialState: IRolesState = {
  roles: null,
  activeRol: null,
};
export const managementRolesReducer = (
  state = initialState,
  action: IRolesActions
): IRolesState => {
  const { type, payload } = action;
  switch (type) {
    case Types.roles:
      return {
        ...state,
        roles: { ...(payload as IPaginationRoles) },
      };
    case Types.setActiveRol:
      return {
        ...state,
        activeRol: payload as any,
      };
     /*  case Types.deleteRol:
        return {
          ..state,
          roles: state.roles?.data.
        } */
    default:
      return state;
  }
};
