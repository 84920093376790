import { Grid, Box, ThemeProvider, TextField } from "@mui/material";
import React, { useEffect, useState, KeyboardEvent } from "react";
import { theme } from "../../custom/customStyles";
import { useTranslation } from "react-i18next";
import { MyToggleButton } from "../../../../components/Custom/MyToggleButton";
import { IConfigCommissional } from "../../../../interfaces/pointsSale";
import InputAdornment from "@mui/material/InputAdornment";
import PercentIcon from "@mui/icons-material/Percent";
import { regIp } from "../../../../utils/utils";

interface Props {
  setCommissional: any;
  commissioners: IConfigCommissional[];
  setPercentage: any;
}

interface Percentages {
  percentageRefill: string | number;
  percentagePayment: string | number;
  percentageAffiliationSales: string | number;
  percentageSalesGame: string | number;
  percentageCards: string | number;
}

export const FormConfigCommissioners = ({
  setCommissional,
  commissioners,
  setPercentage,
}: Props) => {
  /**
   *  Soporta la constante para las traducciones
   */
  const { t } = useTranslation();
  const [percentages, setPercentages] = useState<Percentages>({
    percentageRefill: "",
    percentagePayment: "",
    percentageAffiliationSales: "",
    percentageSalesGame: "",
    percentageCards: "",
  });

  const [errorPercentages, setErrorPercentages] = useState({
    percentageRefill: false,
    percentagePayment: false,
    percentageAffiliationSales: false,
    percentageSalesGame: false,
    percentageCards: false,
  });

  useEffect(() => {
    setPercentages({
      percentageRefill: !!commissioners.find(
        (commissional) => commissional.id === 1
      )
        ? commissioners.find((commissional) => commissional.id === 1)
            ?.percentage!
        : "",
      percentagePayment: !!commissioners.find(
        (commissional) => commissional.id === 2
      )
        ? commissioners.find((commissional) => commissional.id === 2)
            ?.percentage!
        : "",
      percentageAffiliationSales: !!commissioners.find(
        (commissional) => commissional.id === 3
      )
        ? commissioners.find((commissional) => commissional.id === 3)
            ?.percentage!
        : "",
      percentageSalesGame: !!commissioners.find(
        (commissional) => commissional.id === 4
      )
        ? commissioners.find((commissional) => commissional.id === 4)
            ?.percentage!
        : "",
      percentageCards: !!commissioners.find(
        (commissional) => commissional.id === 5
      )
        ? commissioners.find((commissional) => commissional.id === 5)
            ?.percentage!
        : "",
    });
  }, [commissioners]);

  const handleInputChange = ({ target }: any, id: number) => {
    setPercentages({ ...percentages, [target.name]: target.value });
    setPercentage(id, target.value);
  };

  const activePointOfSale = false;

  const handleToggle = (
    _: React.MouseEvent<HTMLElement>,
    commissional: IConfigCommissional
  ) => {
    setCommissional(commissional);
  };

  const findDotString = (value: string): number => {
    let count = 0;
    for (var i = 0; i < value.length; i++) {
      if (value[i] === ".") count++;
    }
    return count;
  };

  return (
    <div className="container mt-3">
      <ThemeProvider theme={theme}>
        <Grid container spacing={2}>
          <Grid item xs={12} className="mb-4">
            <Box
              display="flex"
              className="pb-1"
              borderBottom={1}
              borderColor="#02D0E7"
            >
              <i
                className="fa-solid fa-circle-info"
                style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
              ></i>
              <p className="txt-primary-md">
                {t(
                  activePointOfSale
                    ? "pointsOfSale.editCommissioners"
                    : "pointsOfSale.configureCommissioners"
                )}
              </p>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box mb={2}>
              <label>
                <b>{t("pointsOfSale.refillsCredits")}</b>
              </label>
              <MyToggleButton
                checked={
                  !!commissioners.find((commissional) => commissional.id === 1)
                }
                handleChange={(e) => {
                  handleToggle(e, {
                    id: 1,
                    name: t("pointsOfSale.refillsCredits"),

                    state: !commissioners.find(
                      (commissional) => commissional.id === 1
                    ),
                  });
                }}
              />
            </Box>
            {!!commissioners.find((commissional) => commissional.id === 1) && (
              <Box>
                <label style={{ textAlign: "center", fontSize: 15 }}>
                  {t("pointsOfSale.creditRefillCommissionPercentage")}
                </label>
                <TextField
                  variant="outlined"
                  InputLabelProps={{ shrink: false }}
                  label={" "}
                  size="small"
                  name="percentageRefill"
                  sx={{ width: 1, mt: 1 }}
                  value={percentages.percentageRefill}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PercentIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    findDotString(value) <= 1 && handleInputChange(e, 1);

                    setErrorPercentages({
                      ...errorPercentages,
                      percentageRefill: Number(value) < 1 || Number(value) > 20,
                    });
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regIp(e.key) && e.preventDefault()
                  }
                  inputProps={{
                    maxLength:
                      (percentages.percentageRefill as number) >= 100 ? 3 : 4,
                  }}
                  error={errorPercentages.percentageRefill}
                  helperText={
                    errorPercentages.percentageRefill &&
                    t("pointsOfSale.errorPercentages")
                  }
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={3}>
            <Box mb={2}>
              <label className="ml-5">
                <b>{t("tabs.paymentOrders")}</b>
              </label>
              <MyToggleButton
                checked={
                  !!commissioners.find((commissional) => commissional.id === 2)
                }
                handleChange={(e) => {
                  handleToggle(e, {
                    id: 2,
                    name: t("tabs.paymentOrders"),
                    state: !commissioners.find(
                      (commissional) => commissional.id === 2
                    ),
                  });
                }}
              />
            </Box>
            {!!commissioners.find((commissional) => commissional.id === 2) && (
              <Box>
                <label style={{ textAlign: "center", fontSize: 15 }}>
                  {t("pointsOfSale.commissionPercentagePaymentOrders")}
                </label>
                <TextField
                  variant="outlined"
                  InputLabelProps={{ shrink: false }}
                  label={" "}
                  size="small"
                  name="percentagePayment"
                  sx={{ width: 1, mt: 1 }}
                  value={percentages.percentagePayment}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PercentIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    findDotString(value) <= 1 && handleInputChange(e, 2);

                    setErrorPercentages({
                      ...errorPercentages,
                      percentagePayment:
                        Number(value) < 1 || Number(value) > 20,
                    });
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regIp(e.key) && e.preventDefault()
                  }
                  inputProps={{
                    maxLength:
                      (percentages.percentagePayment as number) >= 100 ? 3 : 4,
                  }}
                  error={errorPercentages.percentagePayment}
                  helperText={
                    errorPercentages.percentagePayment &&
                    t("pointsOfSale.errorPercentages")
                  }
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={3}>
            <Box mb={2}>
              <label className="ml-5">
                <b>{t("pointsOfSale.affiliateSales")}</b>
              </label>
              <MyToggleButton
                checked={
                  !!commissioners.find((commissional) => commissional.id === 3)
                }
                handleChange={(e) => {
                  handleToggle(e, {
                    id: 3,
                    name: t("pointsOfSale.affiliateSales"),
                    state: !commissioners.find(
                      (commissional) => commissional.id === 3
                    ),
                  });
                }}
              />
            </Box>
            {!!commissioners.find((commissional) => commissional.id === 3) && (
              <Box>
                <label style={{ textAlign: "center", fontSize: 15 }}>
                  {t("pointsOfSale.affiliationSalesCommissionPercentage")}
                </label>
                <TextField
                  variant="outlined"
                  InputLabelProps={{ shrink: false }}
                  label={" "}
                  size="small"
                  name="percentageAffiliationSales"
                  sx={{ width: 1, mt: 1 }}
                  value={percentages.percentageAffiliationSales}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PercentIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    findDotString(value) <= 1 && handleInputChange(e, 3);

                    setErrorPercentages({
                      ...errorPercentages,
                      percentageAffiliationSales:
                        Number(value) < 1 || Number(value) > 20,
                    });
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regIp(e.key) && e.preventDefault()
                  }
                  inputProps={{
                    maxLength:
                      (percentages.percentageAffiliationSales as number) >= 100
                        ? 3
                        : 4,
                  }}
                  error={errorPercentages.percentageAffiliationSales}
                  helperText={
                    errorPercentages.percentageAffiliationSales &&
                    t("pointsOfSale.errorPercentages")
                  }
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={3}>
            <Box mb={2}>
              <label className="ml-5">
                <b>{t("pointsOfSale.cardSale")}</b>
              </label>
              <MyToggleButton
                checked={
                  !!commissioners.find((commissional) => commissional.id === 5)
                }
                handleChange={(e) => {
                  handleToggle(e, {
                    id: 5,
                    name: t("pointsOfSale.cardSale"),
                    state: !!commissioners.find(
                      (commissional) => commissional.id === 5
                    ),
                  });
                }}
              />
            </Box>
            {!!commissioners.find((commissional) => commissional.id === 5) && (
              <Box>
                <label style={{ textAlign: "center", fontSize: 15 }}>
                  {t("pointsOfSale.percentageCards")}
                </label>
                <TextField
                  variant="outlined"
                  InputLabelProps={{ shrink: false }}
                  label={" "}
                  size="small"
                  name="percentageCards"
                  sx={{ width: 1, mt: 1 }}
                  value={percentages.percentageCards}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PercentIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    findDotString(value) <= 1 && handleInputChange(e, 5);

                    setErrorPercentages({
                      ...errorPercentages,
                      percentageCards: Number(value) > 100,
                    });
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regIp(e.key) && e.preventDefault()
                  }
                  inputProps={{
                    maxLength:
                      (percentages.percentageCards as number) >= 100 ? 3 : 4,
                  }}
                  error={errorPercentages.percentageCards}
                  helperText={
                    errorPercentages.percentageCards &&
                    t("pointsOfSale.errorPercentages")
                  }
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};
