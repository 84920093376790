import * as React from "react";
import { ISvgProps } from "../../interfaces/shared";

export const UploadIcon: React.FC<Partial<ISvgProps>> = ({
  color,
  heigth,
  width,
}) => (
  <svg
    width={width || 18}
    height={heigth || 18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 17H17"
      stroke={color || "black"}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 11.4348H11.6667V7.26087H15L9 1M9 11.4348H6.33333V7.26087H3L9 1"
      fill={color || "black"}
    />
    <path
      d="M9 11.4348H11.6667V7.26087H15L9 1L3 7.26087H6.33333V11.4348H9Z"
      stroke={color || "black"}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
