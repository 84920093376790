import { getPaymentOrders } from "../../services/paymentOrders";
import { AppState } from "../reducers/rootReducer";
import { setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";
import { arraySearch } from "./searchActions";

export const startFilterPaymentOrders = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { token, user } = (getState() as AppState).auth;
    const { player } = (getState() as AppState).managementPlayers;

    if (params.page || rowsPerPage) {
      params.page = params.page.toString();
      params.perPage = rowsPerPage.toString();
    }

    try {
      if (token) {
        let finalValues: any = {};
        Object.entries(params).forEach(([key, value], index) => {
          //@ts-ignore
          if (value && value.length > 0) {
            finalValues[key] = value;
          }
        });

        const response =
          user &&
          player &&
          (await getPaymentOrders(
            token,
            user.operator?.id,
            player.data[0].id,
            finalValues
          ));

        if (response && !response.error) {
          dispatch(arraySearch(response));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.noData"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};
