import React, { useEffect, useState } from "react";
import ToggleGroup from "./ToggleGroup";
import "./Casino.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  setActiveCategory,
  startDeleteCasinoCategory,
  startGetCasinoCategories,
  startUpdateOrderGame,
} from "../../../redux/actions/casinoActivity";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import CategoryForm from "./CategoryForm";
import { setFlagForm } from "../../../redux/actions/helperActions";
import { MyDialog } from "../../components/custom/MyDialog";
import { useTranslation } from "react-i18next";

const CasinoLiveTab: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token } = useSelector((state: AppState) => state.auth);
  const { activeCategory, casinoCategory } = useSelector(
    (state: AppState) => state.casino
  );
  const [categories, setCategories] = useState<any>([]);
  const [originalCategories, setOriginalCategories] = useState<any[]>([]);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const { flagFormCreate, loading } = useSelector(
    (state: AppState) => state.helper
  );
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (token) {
      const params = {
        type: 2,
      };
      dispatch(startGetCasinoCategories(params));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (casinoCategory) {
      setCategories(casinoCategory);
      setOriginalCategories(casinoCategory);
    }
  }, [casinoCategory]);

  const handleOnDragEnd = (result: DropResult, categoryIndex: number) => {
    if (!result.destination) return;

    const newCategories = [...categories];
    const [reorderedGame] = newCategories[categoryIndex].casinoGames.splice(
      result.source.index,
      1
    );
    newCategories[categoryIndex].casinoGames.splice(
      result.destination.index,
      0,
      reorderedGame
    );

    newCategories[categoryIndex].casinoGames.forEach(
      (game: any, index: number) => {
        game.order = result.source.index === index ? null : index;
      }
    );

    setCategories(newCategories);
    setShowSaveButton(true);
  };

  const handleSaveOrder = (category: any) => {
    setOriginalCategories(categories);
    setShowSaveButton(false);
    dispatch(startUpdateOrderGame(category?.casinoGames.slice(0, 50)));
  };

  const handleCancelOrder = () => {
    setCategories(originalCategories);
    setShowSaveButton(false);
  };

  const showModalDialog = (category: any) => {
    setOpenDialog(true);
    dispatch(setActiveCategory(category));
  };

  const handleAccept = () => {
    dispatch(startDeleteCasinoCategory(activeCategory.id, 2));
    setOpenDialog(false);
    dispatch(setActiveCategory(null));
  };

  const handleClose = () => {
    dispatch(setActiveCategory(null));
    setOpenDialog(false);
  };

  const handleEdit = (category: any) => {
    dispatch(setActiveCategory(category));
    dispatch(setFlagForm({ flagFormCreate: true }));
  };

  return (
    <>
      {flagFormCreate ? (
        <CategoryForm type={2} />
      ) : (
        <div className="casino-content">
          <div className="button-section">
            <span>Crear nueva categoría</span>
            <div
              className="btn-add"
              onClick={() => dispatch(setFlagForm({ flagFormCreate: true }))}
            >
              <i className="fa-solid fa-plus"></i>
            </div>
          </div>
          {categories.length > 0 ? (
            categories.map((category: any, index: number) => {
              const games = category.casinoGames.slice(0, 50);
              return (
                <ToggleGroup
                  key={index}
                  title={category.name}
                  deleteAction={() => showModalDialog(category)}
                  editAction={() => handleEdit(category)}
                  isFirst={index === 0}
                >
                  <>
                    <DragDropContext
                      onDragEnd={(result) => handleOnDragEnd(result, index)}
                    >
                      <Droppable
                        droppableId={`droppable-${index}`}
                        direction="horizontal"
                      >
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="games-container"
                            style={{ display: "flex", flexWrap: "wrap" }}
                          >
                            {games.map((game: any, gameIndex: number) => (
                              <Draggable
                                key={game.name}
                                draggableId={game.name}
                                index={gameIndex}
                              >
                                {(provided) => (
                                  <span
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="game-item"
                                    style={{
                                      margin: "0 4px",
                                      cursor: "pointer",
                                      userSelect: "none",
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    {game.name}
                                    {gameIndex < games.length - 1 && ", "}
                                  </span>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {showSaveButton && (
                      <div className="button-actions">
                        <button
                          onClick={handleCancelOrder}
                          className="btn-cancel"
                        >
                          Cancelar
                        </button>
                        <button
                          onClick={() => handleSaveOrder(category)}
                          className="btn-save"
                        >
                          Guardar Orden
                        </button>
                      </div>
                    )}
                  </>
                </ToggleGroup>
              );
            })
          ) : (
            <div className="disclaimer">
              <p>No existen categorías creadas en este momento</p>
            </div>
          )}
        </div>
      )}
      <MyDialog
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message="¿Esta seguro de eliminar la categoría?. Si la elimina los juegos quedarán desactivados automáticamente"
        handleAccept={handleAccept}
        handleClose={handleClose}
        isLoading={!!loading}
      />
    </>
  );
};

export default CasinoLiveTab;
