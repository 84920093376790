import { IBingoGameActivityQuery } from "../../interfaces/casino";
import { getBingoReportForGameActivity } from "../../services/bingo";
import { buildFinalQueryParams } from "../../utils/utils";
import { AppState } from "../reducers/rootReducer";
import { setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";
import { arraySearch } from "./searchActions";

export const startGetBingoGameActivity = (
  params: Partial<IBingoGameActivityQuery>
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      const { rowsPerPage } = (getState() as AppState).helper;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      if (params.page) {
        params.perPage = rowsPerPage;
      }

      const finalParams = buildFinalQueryParams(params);
      
      const response = await getBingoReportForGameActivity(
        token,
        finalParams
      );

      if (response && !response.error) {
        return dispatch(arraySearch(response));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};
