import moment from "moment";
import { useEffect, useState } from "react";
import { Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Alert, AlertTitle, Grid, Stack } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import Filter from "../../../../components/Filter/Filter";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import { AppState } from "../../../../redux/reducers/rootReducer";
import MyCustomTable, {
  IColumns,
} from "../../../../components/Custom/Table/MyCustomTable";
import { IActivityQuery } from "../../../../interfaces/activityPlayer";
import { ActivityType } from "../../../../enums/enums";
import {
  asyncLoadingFunction,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import { startGetActivityPlayer } from "../../../../redux/actions/activityPlayerActions";
import { arraySearch } from "../../../../redux/actions/searchActions";
import { RowDataTableRefills } from "./RowDataTableRefills";
import "./refills.scss";
import { getActivityPlayer } from "../../../../services/activityPlayer";
import { RefillsDataTable } from "../../../../hooks/useDataTable/refills/RefillsDataTable";
import { RefillsPdfDocument } from "../../../../components/Pdf/Refills/RefillsPdfDocument";

export const Refills = () => {
  const dispatch = useDispatch();
  const { player } = useSelector((state: AppState) => state.managementPlayers);
  const { data } = useSelector((state: AppState) => state.search);
  const { token } = useSelector((state: AppState) => state.auth);
  const { flagCleanFilter, rowsPerPage } = useSelector(
    (state: AppState) => state.helper
  );
  const [cleanFilter, setCleanFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState<any>({});
  const [filter, setFilter] = useState(false);
  const [resetPage, setResetPage] = useState(false);

  useEffect(() => {
    if (flagCleanFilter) {
      return setCleanFilter(true);
    }
    return setCleanFilter(false);
  }, [flagCleanFilter]);

  const { t } = useTranslation();

  const filterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("form.refillNumber"),
      type: Enum_FieldType.NUMBER,
    },
    {
      label: t("form.value"),
      type: Enum_FieldType.NUMBER,
    },
  ];

  const columns: IColumns[] = [
    { align: "left", label: t("form.refillNumber") },
    { align: "left", label: t("withdrawals.externalId") },
    { align: "left", label: t("user.user") },
    { align: "left", label: t("form.value") },
    { align: "left", label: t("form.description") },
    { align: "left", label: t("form.network") },
    { align: "left", label: t("tabs.status") },
    { align: "left", label: t("user.createAt") },
  ];

  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(
        async () =>
          await dispatch(
            startGetActivityPlayer({
              ...dataFilter,
              page,
              perPage: rows || rowsPerPage,
            })
          )
      )
    );
    setResetPage(false);
  };

  const handleFilter = async (values: any) => {
    if (player) {
      setResetPage(false);
      const startDate = values[t("filters.startDate")];
      const endDate = values[t("filters.endDate")];
      const refillParams: Partial<IActivityQuery> = {
        startDate,
        endDate,
        playerId: (player as any).data[0].id,
        number: values[t("form.refillNumber")],
        value: values[t("form.value")],
        type: ActivityType.REFILL,
        page: 1,
      };

      const errorDate = moment(startDate).isAfter(endDate);
      if (errorDate) {
        dispatch(setMessageAlert("alerts.errorDate"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      } else {
        dispatch(
          asyncLoadingFunction(() =>
            dispatch(startGetActivityPlayer(refillParams))
          )
        );
      }
      setDataFilter(refillParams);
      setFilter(true);
      setResetPage(true);
    }
  };

  const onCleanFilter = async () => {
    setResetPage(false);
    dispatch(arraySearch(null));
    setDataFilter({});
    setFilter(false);
    setResetPage(true);
  };

  const getActivityPlayerAction = async (props: any) => {
    if (player && token) {
      const params: Partial<IActivityQuery> = {
        ...props,
        playerId: (player as any).data[0].id,
        type: ActivityType.REFILL,
      };
      return await getActivityPlayer(token, params);
    }
  };

  return (
    <div className="container-fluid">
      <Row
        style={{
          marginTop: "12px",
          pointerEvents: player && player.data.length > 0 ? "all" : "none",
          opacity: player && player.data.length > 0 ? 1 : 0.4,
        }}
      >
        <Filter
          fields={filterFields}
          cleanFilter={cleanFilter}
          handleFilter={handleFilter}
          setDataFilter={setDataFilter}
          onClean={onCleanFilter}
        />
      </Row>

      {data && data.data.length > 0 && (
        <Grid item xs={12}>
          <MyCustomTable
            columns={columns}
            data={data.data}
            lastPage={data.last_page}
            totalData={data.total}
            renderBody={(item) => <RowDataTableRefills row={item} />}
            onPageChange={handlePageChange}
            resetCurrentPage={resetPage}
            exportData
            hookBuildTable={RefillsDataTable}
            getAllDataToExport={getActivityPlayerAction}
            pdfComponent={RefillsPdfDocument}
            titleCSV={t("table.neatRefills")}
          />
        </Grid>
      )}

      {!filter && !data && (
        <Grid item xs={12} pt={3} mt={-2}>
          <Stack
            sx={{ width: "100%" }}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
            spacing={2}
          >
            <Alert
              icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
              sx={{
                borderLeft: `7px solid #2C97DF`,
                backgroundColor: "#EAF5FC",
                color: "#4b4b4b",
              }}
            >
              <div className="d-flex">
                <AlertTitle
                  style={{ marginTop: "5px" }}
                  sx={{ color: "#333333" }}
                >
                  {t("alerts.clickButton")}
                </AlertTitle>
                <AlertTitle
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  sx={{ color: "#333333", fontWeight: "bold" }}
                >
                  {t("alerts.refillHistory")}
                </AlertTitle>
              </div>
            </Alert>
          </Stack>
        </Grid>
      )}
    </div>
  );
};
