/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  useRef,
  useState,
  KeyboardEvent,
  useEffect,
  ChangeEvent,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  CustomButton,
  stylesBonusForm,
  stylesDate,
} from "../../../custom/bonusStyles";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import moment from "moment";
import * as yup from "yup";
import {
  CasinoBonusType,
  EnumUserTypesBonus,
  ProvidersBonus,
  Roles_Enum,
} from "../../../../../enums/enums";
import {
  regDecimal,
  regLettersNumbers,
  regNumbers,
} from "../../../../../utils/utils";
import { MyTextField } from "../../../custom/MyTextField";
import { status } from "../../../../../helpers/status";
import { useOperatorForm } from "../../../../../helpers/RenderOperatorForm";
import MultiSelect from "../../../../../helpers/MultiSelect";
import {
  ICasinoGameList,
  IGameList,
  PragmaticBonusGameList,
} from "../../../../../interfaces/bonus";
import {
  asingBonusToPlayer,
  getPragmaticGames,
} from "../../../../../services/pragmatic";
import { fetchOperatorDetails } from "../../../../../helpers/fetchOperatorDetails";
import clearLogo from "../../../../../assets/svg/icon-park_clear-format.svg";
import { MyDialog } from "../../../custom/MyDialog";
import {
  setFlagForm,
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../../redux/actions/helperActions";
import { normalizeData } from "../../../../../helpers/normalizeData";
import {
  setActiveBonus,
  starGetBonus,
  startCreateNewBonus,
  startGetNewBonusType,
} from "../../../../../redux/actions/bonusActions";
import { excelToJson } from "../../../../../helpers/convertFileToJson";
import { createUniquePragmaticCode } from "../../../../../helpers/uniqueBonusPragmaticCode";
import PayMethodsSelect from "../../../../../helpers/payMethodsSelect";

export const FormPragmaticBonus = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = stylesBonusForm();
  const formikRef = useRef<any>(null);
  const multiSelectRef = useRef<any>(null);

  const { activeBonus, bonusType } = useSelector(
    (state: AppState) => state.bonus
  );
  const { token, user } = useSelector((state: AppState) => state.auth);
  const { error } = useSelector((state: AppState) => state.ui);
  const [games, setGames] = useState<IGameList[]>([]);
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const { loading } = useSelector((state: AppState) => state.helper);

  const [submit, setSubmit] = useState(false);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const [operatorId, setOperatorId] = useState<number | null>(
    activeBonus
      ? activeBonus.operator.id
      : !isSuper && user
      ? user.operator.id
      : null
  );
  const [maxDate, setMaxDate] = useState<string>();

  const [selectedItems, setSelectedItems] = useState<ICasinoGameList[]>([]);
  const [totalData, setTotalData] = useState<any>();
  const [totalBetValues, setTotalBetValues] = useState(0);
  const [totalBonusValue, setTotalBonusValue] = useState(0);
  const [currencyOp, setCurrencyOp] = useState<string>("USD");
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [cancel, setCancel] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedBonusType, setSelectedBonusType] =
    useState<CasinoBonusType | null>(
      activeBonus ? activeBonus.casinoBonusType : null
    );
  const [checkIsMassive, setCheckIsMassive] = useState<boolean | null>(false);
  const [resetSelection, setResetSelection] = useState(false);

  const fileInputRef = useRef(null);

  const initialValues = {
    id: activeBonus ? activeBonus.id : null,
    name: activeBonus ? activeBonus.name : "",
    state: activeBonus ? activeBonus.state : "",
    type: activeBonus?.bonusType ? activeBonus.bonusType.id : "",
    startTime: activeBonus ? activeBonus.startTime : "",
    endTime: activeBonus ? activeBonus.endTime : "",
    startDate: activeBonus
      ? moment(activeBonus.startDate).format("yyyy-MM-DD")
      : "",
    endDate: activeBonus
      ? moment(activeBonus.endDate).format("yyyy-MM-DD")
      : "",
    expirationDays: activeBonus?.expirationDays
      ? activeBonus.expirationDays
      : "",
    value: activeBonus?.value ? (activeBonus.value as any) : "",
    amountBonus: activeBonus?.amountBonus ? activeBonus.amountBonus : "",
    totalBondsValue: activeBonus?.totalValue ? activeBonus.totalValue : "",
    /* ROLLOVER */
    minimumDeposit: activeBonus?.minimumDeposit
      ? activeBonus.minimumDeposit
      : "",
    minimumSportsLines: activeBonus?.minimumSportsLines
      ? activeBonus.minimumSportsLines
      : "",
    maximumSportsLines: activeBonus?.maximumSportsLines
      ? activeBonus.maximumSportsLines
      : "",
    minimumSportsFee: activeBonus?.minimumSportsFee
      ? parseFloat(activeBonus.minimumSportsFee as any)
      : "",
    paymentMethod: activeBonus?.paymentMethod ? activeBonus.paymentMethod : "",

    registrationDate: activeBonus?.registrationDate
      ? moment(activeBonus.registrationDate).format("yyyy-MM-DD")
      : "",

    operator: activeBonus && activeBonus.operator.id,
    createdUser: activeBonus && activeBonus.createdUser.id,
    provider: activeBonus?.provider ? activeBonus.provider.id : "",
    code: activeBonus?.code ? activeBonus.code : "",
    isMassive: activeBonus?.isMassive || false,
    providerBonusId: activeBonus?.providerBonusId || "",
    pragmaticNumberRounds: activeBonus?.pragmaticNumberRounds || "",
    pragmaticBonus: activeBonus?.pragmaticBonus || [], // json con los juegos vinculados al bono
    casinoBonusType: activeBonus?.casinoBonusType || "",
    percentageDeposit: (activeBonus?.percentageDeposit as any) || "", //porcentaje del deposito aplicado al bono
    minDeposit: activeBonus?.minDeposit || "",
    maxDeposit: activeBonus?.maxDeposit || "",
    maxBonus: activeBonus?.maxBonus || "",
    // payMethods: [],
    payMethods: activeBonus?.payMethods || [],
    userTypes: activeBonus?.userTypes || "",
  };

  const validationSchema = yup.object({
    name: yup.string().required(t("form.requiredFile")),
    state: yup.number().required(t("form.requiredFile")),
    // userTypes: yup.number().required(t("form.requiredFile")),
    startDate: yup
      .date()
      .required(t("form.requiredFile"))
      .test(
        "isDateRangeValid",
        t("managementBonds.isStartDateRangeValid"),
        function (value) {
          const endDate: any = this.resolve(yup.ref("endDate"));
          return moment(value).isSameOrBefore(endDate, "day");
        }
      ),

    endDate: yup
      .date()
      .required(t("form.requiredFile"))
      .test(
        "isDateRangeValid",
        t("managementBonds.isEndDateRangeValid"),
        function (value) {
          const startDate: any = this.resolve(yup.ref("startDate"));
          return moment(value).isSameOrAfter(startDate, "day");
        }
      ),
    provider: yup.number(),
    code: yup.string(),
    isMassive: yup.boolean().required(t("form.requiredFile")),
    pragmaticNumberRounds: yup
      .number()
      .min(0)
      .when("percentageDeposit", {
        is: (percentageDeposit: number) => {
          return !percentageDeposit;
        },
        then: yup
          .number()
          .required(t("form.requiredFile"))
          .moreThan(0, t("managementBonds.greaterThanZero")),
        otherwise: yup.number(),
      }),

    casinoBonusType: yup.number().required(t("form.requiredFile")),
    amountBonus: yup
      .number()
      .min(0)
      .when("percentageDeposit", {
        is: (percentageDeposit: number) => {
          return !percentageDeposit;
        },
        then: yup.number().required(t("form.requiredFile")),
        otherwise: yup.number(),
      }),

    totalBondsValue: yup.number().when("percentageDeposit", {
      is: (percentageDeposit: number) => {
        return percentageDeposit;
      },
      then: yup.number().required(t("form.requiredFile")),
      otherwise: yup.number(),
    }),

    percentageDeposit: yup.lazy((_v) => {
      return yup.number().when("value", {
        is: (value: number) => {
          return !value || String(value).trim() === "";
        },
        then: yup
          .number()
          .moreThan(0, t("managementBondsgreaterThanZero"))
          .max(100, t("managementBonds.errorMaxValuePercentage"))
          .required(t("form.requiredFile")),
        otherwise: yup.number(),
      });
    }),

    value: yup.number().when("percentageDeposit", {
      is: (percentageDeposit: number) => {
        return !percentageDeposit;
      },
      then: yup.number().required(t("form.requiredFile")),
      otherwise: yup.number(),
    }),
    minDeposit: yup.lazy((_v, { parent }) =>
      yup.number().when("casinoBonusType", {
        is: (casinoBonusType: CasinoBonusType | null) =>
          isDepositBonusType(casinoBonusType),
        then: yup
          .number()
          .moreThan(0, t("managementBondsgreaterThanZero"))
          .required(t("form.requiredFile"))
          .test(
            "is-less-than-max-deposit",
            t("managementBonds.minDepositMustBeLessThanMaxDeposit"),
            function (value) {
              const { maxDeposit } = parent;
              return !maxDeposit || !value || value < maxDeposit;
            }
          ),
        otherwise: yup.number().nullable(),
      })
    ),
    maxDeposit: yup.lazy(() =>
      yup.number().when("casinoBonusType", {
        is: (casinoBonusType: CasinoBonusType | null) =>
          isDepositBonusType(casinoBonusType),
        then: yup
          .number()
          .moreThan(0, t("managementBondsgreaterThanZero"))
          .required(t("form.requiredFile")),
        otherwise: yup.number().nullable(),
      })
    ),
    expirationDays: yup.lazy(() =>
      yup.number().when("casinoBonusType", {
        is: (casinoBonusType: CasinoBonusType | null) =>
          isDepositBonusType(casinoBonusType),
        then: yup
          .number()
          .moreThan(0, t("managementBondsgreaterThanZero"))
          .required(t("form.requiredFile")),
        otherwise: yup.number().nullable(),
      })
    ),
  });

  //   CURRENCY AND COUNTRY (CODE)
  useEffect(() => {
    const fetchDetails = async () => {
      if (operatorId && token) {
        const { currency } = await fetchOperatorDetails(token, operatorId);
        setCurrencyOp(currency);
      }
    };

    fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatorId]);

  const { renderOperatorForm } = useOperatorForm({ token, user, dispatch });

  const actualDay = moment().format("YYYY-MM-DD");

  const getMaxDate = (start: any) => {
    const startDate = new Date(start);

    // Sumar 30 días a la fecha actual
    const maxDate = new Date(startDate.setDate(startDate.getDate() + 30));

    // Formatear la fecha en formato yyyy-mm-dd
    const formattedMaxDate = maxDate.toISOString().split("T")[0];

    setMaxDate(formattedMaxDate);
  };

  const fetchBonusType = async () => {
    await dispatch(startGetNewBonusType());
  };

  //   FETCH GAMES
  useEffect(() => {
    const fetchGames = async () => {
      try {
        if (!token) {
          throw new Error("invalid action");
        }
        const rs: any = await getPragmaticGames(token);
        if (rs && rs.gameList) {
          const finalGameList: IGameList[] = rs.gameList
            .filter((item: any) => item.frbAvailable)
            .map(({ gameID, gameName }: any) => ({
              gameID,
              gameName,
            }));

          setGames(finalGameList);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchGames();
    fetchBonusType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (formikRef.current && !totalBonusValue) {
      const pragmaticNumberRounds: string =
        formikRef.current.values.pragmaticNumberRounds || "0";
      const amountBonds: string = formikRef.current.values.amountBonus || "0";
      const totalBonusValue =
        parseFloat(event.target.value) *
        parseInt(pragmaticNumberRounds) *
        parseInt(amountBonds);
      formikRef.current.setFieldValue(
        "totalBondsValue",
        totalBonusValue && !isNaN(totalBonusValue) ? totalBonusValue : ""
      );
    }
  };
  const handleChangePragmaticNumberRounds = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (formikRef.current) {
      const amount: string = formikRef.current.values.value || "0";
      const amountBonds: string = formikRef.current.values.amountBonus || "0";
      const totalBonusValue =
        parseFloat(amount) *
        parseInt(amountBonds) *
        parseInt(event.target.value);

      formikRef.current.setFieldValue(
        "totalBondsValue",
        totalBonusValue && !isNaN(totalBonusValue) ? totalBonusValue : ""
      );
    }
  };

  const handleChangeAmountBonds = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (formikRef.current) {
      const amount: string = formikRef.current.values.value || "0";
      const pragmaticNumberRounds: string =
        formikRef.current.values.pragmaticNumberRounds || "0";
      const totalBonusValue =
        parseFloat(amount) *
        parseInt(pragmaticNumberRounds) *
        parseInt(event.target.value);
      formikRef.current.setFieldValue(
        "totalBondsValue",
        totalBonusValue && !isNaN(totalBonusValue) ? totalBonusValue : ""
      );
    }
  };

  const handleChangeTotalBonusValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTotalBonusValue(parseFloat(event.target.value));
  };

  const _handleCancel = () => {
    dispatch(setActiveBonus(null));
    dispatch(setFlagForm({ flagFormCreate: false }));
    setCancel(false);
  };

  const handleCancel = () => {
    setMessage("dialog.sureExit");
    setCancel(true);
    setOpenDialog(true);
  };

  const handleClean = () => {
    if (formikRef.current) formikRef.current.resetForm();
    setSelectedFile(null);
    setSelectedItems([]);
    setResetSelection(true);
    setOpenDialog(false);
    if (multiSelectRef.current) multiSelectRef.current.clearSelection(); // Llamar a la función para limpiar
  };

  const handleAccept = async () => {
    handleCloseDialog();
    if (cancel) {
      _handleCancel();
    } else {
      await buildFormValues();
      await handleValidationAndSubmission();
    }
  };

  const handleValidationAndSubmission = async () => {
    setIsSubmit(true);
    await validateExistsBonus(totalData);
    setIsSubmit(false);
    // setCancel(false);
  };

  const callback = (totalParse: number, totalLines: number) => {
    // Callback function to track progress
    console.log(`Progreso: ${totalParse}/${totalLines}`);
  };

  const validateExistsBonus = async (formData: any) => {
    if (!token) return;
    dispatch(setLoadingState({ loading: true }));
    const data = normalizeData(formData);
    let playerList: string[] = [];

    if (selectedFile) {
      const json = await excelToJson(selectedFile, callback);
      playerList = JSON.parse(json).map((item: any) => item.playerId);
    }

    const bonusPragmaticCode = await createUniquePragmaticCode(token);

    if (validateDates(data.startDate, data.endDate)) {
      return showErrorDateAlert();
    }
    data.provider = ProvidersBonus.PRAGMATIC;
    data.pragmaticBonusCode = bonusPragmaticCode;

    const responseCreateBonus: any = await dispatch(startCreateNewBonus(data));
    if (responseCreateBonus?.message === "success" && selectedFile) {
      await asingBonusToPlayer(token, bonusPragmaticCode, { playerList });
    }
    dispatch(setLoadingState({ loading: false }));
    await dispatch(starGetBonus());
    handleClean();
  };

  const buildFormValues = async () => {
    let totalValues = 0;
    const validValues: PragmaticBonusGameList[] = [];

    selectedItems?.map((item: ICasinoGameList) => {
      const formValue = {
        gameId: item.id,
        betValues: [
          {
            currency: currencyOp,
            totalBet: totalData.value,
          },
        ],
      };

      // Verificar si el gameId y totalBet son válidos
      if (formValue.gameId.trim() && formValue.betValues[0].totalBet !== "") {
        totalValues += parseFloat(formValue.betValues[0].totalBet as any);
        validValues.push(formValue);
      }

      return formValue;
    });

    totalData.pragmaticBonus = validValues;

    if (totalBonusValue) {
      setTotalBetValues(totalBonusValue);
    } else {
      setTotalBetValues(totalValues);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getMessage = () => {
    return !!activeBonus ? "dialog.sureEdit" : "managementBonds.sureCreateBond";
  };

  const openConfirmation = () => {
    setMessage(getMessage());
    setOpenDialog(true);
    // setCancel(false);
  };

  // VALIDATE DATE
  const validateDates = (startDate: string, endDate: string) => {
    return moment(startDate).isAfter(endDate);
  };

  const showErrorDateAlert = () => {
    dispatch(setOpenAlert());
    dispatch(setMessageAlert("alerts.errorDate"));
    dispatch(setTypeAlert("error"));
    dispatch(setLoadingState({ loading: false }));
  };

  // ONCHANGE SELECT BONUS TYPE
  const handleChangeBonusType = (event: ChangeEvent<HTMLInputElement>) => {
    const bonusType = parseInt(event.target.value, 10) as CasinoBonusType;
    setSelectedBonusType(bonusType);
    setCheckIsMassive(false);
    formikRef.current.setFieldValue("isMassive", false);
  };

  // ONCHANGE SELECT FILE
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files && event.target.files[0];
    setSelectedFile(file ?? null);
  };

  // HANDLE CHANGE CHECK MASSIVE
  const handleChangeCheckIsMassive = (event: ChangeEvent<HTMLInputElement>) => {
    const isCheck = event.target.checked;
    setCheckIsMassive(isCheck);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      (fileInputRef.current as any).click();
    }
  };

  const handleDownload = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();

    const fileName = "PlantillaBonusMasivosPragmatic.csv";

    const publicURL = process.env.PUBLIC_URL;
    const url = `${publicURL}/${fileName}`;

    window.location.href = url;
  };
  //OPTIONS BONUSTYPE
  const bonusTypeOptions =
    bonusType && bonusType.length > 0 ? (
      bonusType.map((item) => (
        <MenuItem
          key={item.id}
          value={item.id}
          sx={{
            "&.Mui-focused": {
              backgroundColor: "red",
            },
          }}
        >
          {item.name}
        </MenuItem>
      ))
    ) : (
      <MenuItem value="" disabled>
        {t("managementBonds.noOptions")}
      </MenuItem>
    );

  const isDepositBonusType = (bonusType: CasinoBonusType | null) => {
    if (!bonusType) return false;
    return [
      CasinoBonusType.DEPOSIT,
      CasinoBonusType.FIRTS_DEPOSIT,
      CasinoBonusType.SECOND_DEPOSIT,
      CasinoBonusType.THRID_DEPOSIT,
    ].includes(bonusType);
  };
  return (
    <Grid container spacing={2} mt={1} pl={1} pr={1}>
      <Grid item xs={12} className="mb-4">
        <Box className={classes.titleBox}>
          <i className={`fa-solid fa-circle-info ${classes.infoIcon}`}></i>
          <Typography variant="body1" className={classes.title}>
            {t("managementBonds.createBonds")}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          onSubmit={async (data) => {
            if (operatorId) {
              data.operator = operatorId;
              setTotalData(data);
              openConfirmation();
            }
          }}
        >
          {({ values, isValid, setFieldValue, handleChange }) => (
            <Form data-testid={"pragmaticBonusForm"}>
              <Grid container spacing={4}>
                {/* OPERATOR */}
                {renderOperatorForm({
                  companies: companies ? companies.data : [],
                  isSuper,
                  operatorId,
                  submit,
                  activeBonus,
                  classes,
                  setOperatorId,
                  t,
                })}
                {/* NOMBRE DEL BONO */}
                <Grid item xs={4}>
                  <MyTextField
                    name="name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="text"
                    label={`${t("managementBonds.bondName")}*`}
                    inputProps={{
                      "data-testid": "bonusName",
                      maxLength: 50,
                    }}
                    onChange={handleChange}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regLettersNumbers(e.key) && e.preventDefault()
                    }
                  />
                </Grid>
                {/* STATE */}
                <Grid item xs={4}>
                  <MyTextField
                    id="stateTextField"
                    name="state"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={values.state}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue("state", e.target.value)
                    }
                    role={"combobox"}
                    select
                    label={`${t("form.state")}*`}
                    inputProps={{
                      id: "stateInput",
                    }}
                  >
                    {status.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        sx={{
                          "&.Mui-focused": {
                            backgroundColor: "red",
                          },
                        }}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </MyTextField>
                </Grid>
                {/* CASINO BONUS TYPE */}
                <Grid item xs={4}>
                  <MyTextField
                    id="stateTextField"
                    name="casinoBonusType"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={values.casinoBonusType}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeBonusType(e);
                      setFieldValue("casinoBonusType", e.target.value);
                    }}
                    role={"combobox"}
                    select
                    label={`${t("managementBonds.bondType")}`}
                    inputProps={{
                      id: "stateInput",
                    }}
                  >
                    {bonusTypeOptions}
                  </MyTextField>
                </Grid>
                {/* USER TYPES */}
                {isDepositBonusType(selectedBonusType) && (
                  <Grid item xs={4}>
                    <MyTextField
                      id="stateTextField"
                      name="userTypes"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.userTypes}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue("userTypes", e.target.value);
                      }}
                      role={"combobox"}
                      select
                      label={`${t("managementBonds.userTypes")}`}
                      inputProps={{
                        id: "stateInput",
                      }}
                    >
                      <MenuItem value={EnumUserTypesBonus.NEW}>
                        {t("managementBonds.userTypesBonus.new")}
                      </MenuItem>
                      <MenuItem value={EnumUserTypesBonus.OLD}>
                        {t("managementBonds.userTypesBonus.old")}
                      </MenuItem>
                      <MenuItem value={EnumUserTypesBonus.ALL}>
                        {t("managementBonds.userTypesBonus.all")}
                      </MenuItem>
                    </MyTextField>
                  </Grid>
                )}
                {/* START DATE */}
                <Grid item xs={4}>
                  <MyTextField
                    variant="outlined"
                    size="small"
                    type={"date"}
                    name={"startDate"}
                    label={`${t("filters.startDate")}*`}
                    fullWidth
                    InputProps={{
                      classes: {
                        input: classes.root,
                      },
                      inputProps: {
                        min: activeBonus ? undefined : actualDay,
                      },
                    }}
                    inputProps={{
                      "data-testid": "startDate",
                    }}
                    value={values.startDate}
                    e
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      getMaxDate(e.target.value);
                    }}
                    sx={stylesDate}
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {/* END DATE */}
                <Grid item xs={4}>
                  <MyTextField
                    variant="outlined"
                    size="small"
                    type={"date"}
                    name={"endDate"}
                    label={`${t("table.expirationDate")}*`}
                    InputProps={{
                      classes: {
                        input: classes.root,
                        adornedEnd: classes.inputAdorned,
                      },
                      inputProps: { min: values.startDate, max: maxDate },
                    }}
                    fullWidth
                    value={values.endDate}
                    inputProps={{
                      "data-testid": "endDate",
                    }}
                    onChange={handleChange}
                    sx={stylesDate}
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {/* VALUE */}
                <Grid item xs={4}>
                  <MyTextField
                    name="value"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (!regDecimal(e.key)) {
                        return e.preventDefault();
                      }
                    }}
                    inputProps={{
                      "data-testid": "value",
                      maxLength: 10,
                    }}
                    value={values.value || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeValue(e);
                      handleChange(e);
                    }}
                    label={`${t("pragmatic.RoundValue")}*`}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {/* NUMBER ROUNDS */}
                <Grid item xs={4}>
                  <MyTextField
                    name="pragmaticNumberRounds"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                      !regNumbers(e.key) && e.preventDefault()
                    }
                    disabled={values.percentageDeposit.trim()}
                    value={values.pragmaticNumberRounds || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangePragmaticNumberRounds(e);
                      handleChange(e);
                    }}
                    label={`${t("pragmatic.NumberRounds")}*`}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </Grid>
                {/* PERCENTAGE DEPOSIT */}
                {isDepositBonusType(selectedBonusType) && (
                  <Grid item xs={4}>
                    <MyTextField
                      name="percentageDeposit"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                        !regDecimal(e.key) && e.preventDefault()
                      }
                      disabled={!!values.pragmaticNumberRounds}
                      label={`${t("managementBonds.percentageDeposit")}`}
                      value={values.percentageDeposit}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 3,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}
                {isDepositBonusType(selectedBonusType) && (
                  <>
                    {/* EXPIRATION DAYS */}
                    <Grid item xs={4}>
                      <MyTextField
                        name="expirationDays"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onKeyPress={(
                          e: React.KeyboardEvent<HTMLInputElement>
                        ) => !regNumbers(e.key) && e.preventDefault()}
                        value={values.expirationDays}
                        onChange={handleChange}
                        label={`${t("managementBonds.expirationDays")}*`}
                        inputProps={{
                          "data-testid": "expirationDays",
                          maxLength: 10,
                        }}
                      />
                    </Grid>
                  </>
                )}
                {/* AMOUNT BONUS */}
                <Grid item xs={4}>
                  <MyTextField
                    name="amountBonus"
                    variant="outlined"
                    size="small"
                    disabled={values.percentageDeposit.trim()}
                    fullWidth
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                      !regNumbers(e.key) && e.preventDefault()
                    }
                    value={values.amountBonus}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeAmountBonds(e);
                      handleChange(e);
                    }}
                    label={`${t("managementBonds.amountBonds")}*`}
                    inputProps={{
                      "data-testid": "amountBonus",
                      maxLength: 10,
                    }}
                  />
                </Grid>

                {/* TOTAL BONUS VALUE */}
                <Grid item xs={4}>
                  <MyTextField
                    name="totalBondsValue"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onKeyPress={(e: any) => {
                      if (
                        !regNumbers(e.key) ||
                        (e.target.value.length === 0 && e.key === "0")
                      ) {
                        return e.preventDefault();
                      }
                    }}
                    inputProps={{
                      "data-testid": "totalBonusValue",
                      maxLength: 10,
                    }}
                    value={values.totalBondsValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeTotalBonusValue(e);
                      handleChange(e);
                    }}
                    disabled={!values.percentageDeposit.trim()}
                    label={`${t("managementBonds.totalBondsValue")}*`}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}></Grid>
                {/* GAMES */}
                {games.length > 0 ? (
                  <>
                    <Grid item xs={12} md={4}>
                      <label className="ml-3">Seleccionar Juegos*</label>
                      <MultiSelect
                        ref={multiSelectRef} // Asignar la referencia aquí
                        items={
                          games
                            ? games
                                .sort((a, b) =>
                                  a.gameName.localeCompare(b.gameName)
                                )
                                ?.map(
                                  ({ gameID: id, gameName: name }: any) => ({
                                    id,
                                    name,
                                  })
                                )
                            : []
                        }
                        selectedItemsFunction={setSelectedItems as any}
                      />
                    </Grid>
                  </>
                ) : (
                  <Box
                    display={"flex"}
                    justifyContent="center"
                    alignItems={"center"}
                    width="100%"
                    mt={3}
                  >
                    <CircularProgress className={"colorLoading"} />
                  </Box>
                )}
                {error && totalBetValues > 0 && (
                  <Grid item xs={12}>
                    <label className="form-text" style={{ color: "red" }}>
                      {t("pragmatic.roundsHighterBonus")}
                    </label>
                  </Grid>
                )}

                {/* Condiciones para entregar el bono por depósito */}
                {isDepositBonusType(selectedBonusType) && (
                  <>
                    <Grid item xs={12}>
                      {`${t("managementBonds.label1")}`}
                    </Grid>
                    {/* MIN DEPOSIT */}
                    <Grid item xs={4}>
                      <MyTextField
                        name="minDeposit"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onKeyPress={(e: any) => {
                          if (
                            !regNumbers(e.key) ||
                            (e.target.value.length === 0 && e.key === "0")
                          ) {
                            return e.preventDefault();
                          }
                        }}
                        inputProps={{
                          "data-testid": "minDeposit",
                          maxLength: 10,
                        }}
                        value={values.minDeposit}
                        onChange={handleChange}
                        label={`${t("managementBonds.minDeposit")}*`}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    {/* MAX DEPOSIT */}
                    <Grid item xs={4}>
                      <MyTextField
                        name="maxDeposit"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onKeyPress={(e: any) => {
                          if (
                            !regNumbers(e.key) ||
                            (e.target.value.length === 0 && e.key === "0")
                          ) {
                            return e.preventDefault();
                          }
                        }}
                        inputProps={{
                          "data-testid": "maxDeposit",
                          maxLength: 10,
                        }}
                        value={values.maxDeposit}
                        onChange={handleChange}
                        label={`${t("managementBonds.maxDeposit")}*`}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    {/* Para métodos de pago específicos */}
                    <Grid item xs={12}>
                      {`${t("managementBonds.label3")}`}
                    </Grid>
                    {/* METODOS DE PAGO */}
                    <PayMethodsSelect
                      formikRef={formikRef}
                      classes={classes}
                      resetSelection={resetSelection}
                    />
                  </>
                )}

                {/* IS MASSIVE */}
                {selectedBonusType === CasinoBonusType.CUSTOM && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isMassive"
                          checked={values.isMassive}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleChangeCheckIsMassive(e);
                            handleChange(e);
                          }}
                        />
                      }
                      label={`${t("managementBonds.isMassiveBonus")}*`}
                    />
                  </Grid>
                )}
                {checkIsMassive && (
                  <>
                    {/* Label Assign Players to the Bonus*/}
                    <Grid item xs={12}>
                      <Box className={classes.titleBox}>
                        <i
                          className={`fa-solid fa-user-plus ${classes.infoIcon}`}
                        ></i>
                        <Typography variant="body1" className={classes.title}>
                          {`${t("managementBonds.label4")}`}
                        </Typography>
                      </Box>
                    </Grid>

                    {/* FILE PLAYERS */}
                    <Grid item xs={6}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                        }}
                      >
                        <Container>
                          <TextField
                            label={`${t("managementBonds.uploadFilePlayers")}*`}
                            disabled
                            size="small"
                            fullWidth
                            value={selectedFile ? selectedFile.name : ""}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                          <input
                            type="file"
                            accept=".csv"
                            style={{ display: "none" }}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                          />
                          <label style={{ margin: 0 }}>
                            <CustomButton
                              style={{
                                border: "none",
                                outline: "none",
                                boxShadow: "none",
                              }}
                              onClick={handleButtonClick}
                            >
                              {t("managementBonds.examine")}
                            </CustomButton>
                          </label>
                        </Container>

                        <Typography>
                          <a href="#" onClick={handleDownload}>
                            {t("managementBonds.dowloadTemplate")}
                          </a>
                        </Typography>
                      </div>
                    </Grid>
                  </>
                )}
                <Box
                  display={"flex"}
                  mt={9}
                  flex={1}
                  justifyContent="flex-end"
                  alignItems={"flex-end"}
                >
                  <Button
                    variant="contained"
                    className="footer-btn danger-btn ripple"
                    style={{ border: "none", outline: "none" }}
                    onClick={handleCancel}
                    disabled={!!isSubmit || !!loading}
                    data-testid={"btnCancel"}
                  >
                    {t("button.cancel").toUpperCase()}
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    className="footer-btn primary-btn ripple"
                    style={{ border: "none", outline: "none" }}
                    disabled={
                      isSubmit ||
                      !isValid ||
                      loading ||
                      error ||
                      selectedItems?.length === 0 ||
                      selectedItems === undefined ||
                      (activeBonus?.totalBonusDelivered as any) > 0 ||
                      ((checkIsMassive ?? false) && !selectedFile)
                    }
                    data-testid={"btnSave"}
                    onClick={() => setSubmit(true)}
                  >
                    {isSubmit || !!loading ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      <>{t("button.save").toUpperCase()}</>
                    )}
                  </Button>

                  <Button
                    style={{
                      border: "none",
                      outline: "none",
                      marginLeft: "4px",
                    }}
                    disabled={activeBonus !== null}
                    onClick={() => {
                      setMessage("dialog.sureClean");
                      setOpenDialog(true);
                    }}
                  >
                    <img src={clearLogo} alt="Icon clear" />
                  </Button>
                </Box>
              </Grid>
              <MyDialog
                id="confirmationDialog"
                data-testid="confirmationDialog"
                open={openDialog}
                title={t("dialog.confirmationWindow")}
                message={t(message)}
                handleAccept={
                  message === "dialog.sureClean" ? handleClean : handleAccept
                }
                handleClose={handleCloseDialog}
                isLoading={isSubmit || !!loading}
              />
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
