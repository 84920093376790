import { FC } from "react";
import TableCell from "@mui/material/TableCell";
import { State } from "../../../enums/enums";
import { useTranslation } from "react-i18next";
import { IMenuItem } from "../../../interfaces/menu";
import { TableRow, Tooltip } from "@mui/material";

interface Props {
  row: IMenuItem;
}

export const RowTableMenuItems: FC<Props> = ({ row }) => {
  const { t } = useTranslation();

  return (
    <TableRow style={{ width: "100%" }}>
      <TableCell align="left" width={"20%"}>
        {row.name}
      </TableCell>
      <TableCell align="center" width={"20%"}>
        {row.url}
      </TableCell>
      <TableCell align="center" width={"20%"}>
        <Tooltip
          title={row.description}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#02D0E7",
                "& .MuiTooltip-arrow": {
                  color: "#02D0E7",
                },
                fontWeight: "bold",
              },
            },
          }}
          arrow
        >
          <p style={{ color: "#000" }}>{row.description}</p>
        </Tooltip>
      </TableCell>
      <TableCell align="center" width={"20%"}>
        {row?.createdUser?.nameComplete}
      </TableCell>
      <TableCell align="center" width={"20%"}>
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                State.ACTIVE === row.state
                  ? "d-flex justify-content-center cellRowActive"
                  : "d-flex justify-content-center cellRowInactive"
              }
            >
              <div className="d-flex col-sm-4 justify-content-center">
                {State.ACTIVE === row.state && <>{t("state.active")}</>}
                {State.INACTIVE === row.state && <>{t("state.inactive")}</>}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};
