import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import {
  Divider,
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
} from "@mui/material";
import { useStyles } from "../custom/customStyles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <Typography className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton
          disableFocusRipple
          disableRipple
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface Props {
  open: boolean;
  handleClose: () => void;
  handleAccept: () => void;
  title: string;
}

export const MyDialogExportFile: React.FC<Props> = ({
  open,
  handleClose,
  handleAccept,
  title,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("");
  const styleLabel = {
    margin: 0,
    padding: 0,
    color: "#00458D !important",
  };

  /**
   * OnChange para value checkbox
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  return (
    <div>
      <BootstrapDialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <span
            style={{
              color: "#00458D",
              fontWeight: 400,
              fontSize: "16px",
              textTransform: "uppercase",
            }}
          >
            {t(`${title}`)}
          </span>
        </BootstrapDialogTitle>
        <Divider variant="middle" style={{ backgroundColor: "#87AECC" }} />
        <DialogContent>
          <Box p={1}>
            <FormControl>
              <RadioGroup
                name="verificationTwoStep"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  key="exportCsv"
                  value={"1"}
                  control={
                    <Radio size="small" sx={{ color: "#02D0E7 !important" }} />
                  }
                  label={t("dialog.exportCsv")}
                  sx={styleLabel}
                />
                <FormControlLabel
                  key="exportPageCsv"
                  value={"2"}
                  control={
                    <Radio size="small" sx={{ color: "#02D0E7 !important" }} />
                  }
                  label={t("dialog.exportPageCsv")}
                  sx={styleLabel}
                />
                <FormControlLabel
                  key="exportPdf"
                  value={"3"}
                  control={
                    <Radio size="small" sx={{ color: "#02D0E7 !important" }} />
                  }
                  label={t("dialog.exportPdf")}
                  sx={styleLabel}
                />
                <FormControlLabel
                  key="exportPagePdf"
                  value={"4"}
                  control={
                    <Radio size="small" sx={{ color: "#02D0E7 !important" }} />
                  }
                  label={t("dialog.exportPagePdf")}
                  sx={styleLabel}
                />
                <FormControlLabel
                  key="exportExcel"
                  value={"5"}
                  control={
                    <Radio size="small" sx={{ color: "#02D0E7 !important" }} />
                  }
                  label={t("dialog.exportExcel")}
                  sx={styleLabel}
                />
                <FormControlLabel
                  key="exportPageExcel"
                  value={"6"}
                  control={
                    <Radio size="small" sx={{ color: "#02D0E7 !important" }} />
                  }
                  label={t("dialog.exportPageExcel")}
                  sx={styleLabel}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <Divider variant="middle" style={{ backgroundColor: "#87AECC" }} />
        <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            className={value === "" ? "disabledButon" : "d-flex btn-light-dark"}
            style={{
              width: "250px",
              textTransform: "uppercase",
              fontSize: "14px",
              fontWeight: 700,
            }}
            type="button"
            onClick={handleAccept}
            disabled={value === "" ? true : false}
          >
            <i className="fa fa-check btn-icon pt-1 fa-md mr-2"></i>
            {t("dialog.exportFormat")}
          </button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
