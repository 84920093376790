import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import React from "react";
import { IConfigPointSale } from "../../../../interfaces/pointsSale";
import { DealerTypeEnum, State } from "../../../../enums/enums";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";

interface Props {
  data: IConfigPointSale;
}

export const TabFormPointSale = ({ data }: Props) => {
  const { t } = useTranslation();
  const { configParams } = useSelector((state: AppState) => state.configParams);
  const enableAnonymousSaleSelect =
    configParams && configParams.data.length > 0
      ? !!configParams.data[0].configParam.options.find(
          (op) =>
            op.name === "Juego anónimo deportivas" &&
            op.state === State.ACTIVE &&
            !op.isNative
        )
      : false;
  const enableAnonymousPayoutSelect =
    configParams && configParams.data.length > 0
      ? !!configParams.data[0].configParam.options.find(
          (op) =>
            op.name === "Pagar premios de juego anónimo" &&
            op.state === State.ACTIVE &&
            !op.isNative
        )
      : false;
  const getPointTypeName = (type: DealerTypeEnum) => {
    switch (type) {
      case DealerTypeEnum.CARDS:
        return t("managementCards.cards");
      case DealerTypeEnum.ANONYMOUS:
        return t("managementDealers.anonymous");
      case DealerTypeEnum.BOTH:
        return `${t("managementCards.cards")}-${t(
          "managementDealers.anonymous"
        )}`;

      default:
        return "";
    }
  };

  return (
    <Grid container>
      {/* CONFIRMACIÓN FORMULARIO DATOS PUNTO DE VENTA*/}
      <Grid container mb={2} ml={4}>
        <Grid item xs={3} mb={3}>
          <label>{t("Asociados").toUpperCase()}</label>
          <Box className="no-overflow">
            {data.associates?.map((associate, index) => (
              <b key={associate.id + "-" + index}>
                {associate.associate
                  ? associate.associate.name.toUpperCase()
                  : ""}
                &nbsp;
                {data.associates && data.associates.length - 1 !== index && "-"}
                &nbsp;
              </b>
            ))}
          </Box>
        </Grid>
        <Grid item xs={3} mb={3}>
          <label>{t("user.country").toUpperCase()}</label>
          <Box mt={1}>
            <label>
              <b>{data.country?.name.toUpperCase()}</b>
            </label>
          </Box>
        </Grid>
        <Grid item xs={3} mb={3}>
          <label>{t("user.states").toUpperCase()}</label>
          <Box>
            <label>
              <b>{data.state?.names.toUpperCase()}</b>
            </label>
          </Box>
        </Grid>
        <Grid item xs={3} mb={3}>
          <label>{t("user.city").toUpperCase()}</label>
          <Box>
            <label>
              <b>{data.city?.names.toUpperCase()}</b>
            </label>
          </Box>
        </Grid>
        <Grid item xs={3} mb={3}>
          <label>{t("form.name").toUpperCase()}</label>
          <Box className="no-overflow">
            <b>{data.name.toUpperCase()}</b>
          </Box>
        </Grid>
        <Grid item xs={3} mb={3}>
          <label>{t("user.address").toUpperCase()}</label>
          <Box className="no-overflow">
            <b>{data.address.toUpperCase()}</b>
          </Box>
        </Grid>
        <Grid item xs={3} mb={3}>
          <label>{t("user.email").toUpperCase()}</label>
          <Box className="no-overflow">
            <b>{data.email.toUpperCase()}</b>
          </Box>
        </Grid>
        <Grid item xs={3} mb={3}>
          <label>{t("user.phone").toUpperCase()}</label>
          <Box>
            <label>
              <b>{data.phone.toUpperCase()}</b>
            </label>
          </Box>
        </Grid>
        <Grid item xs={3} mb={3}>
          <label>{t("pointsOfSale.appliesNTC").toUpperCase()}</label>
          <Box>
            <label>
              <b>{t(data.ntc === 1 ? "table.yes" : "table.not")}</b>
            </label>
          </Box>
        </Grid>
        <Grid item xs={3} mb={3}>
          <label>{t("pointsOfSale.allowsTransfers").toUpperCase()}</label>
          <Box>
            <label>
              <b>{t(data.allowsTransfer === 1 ? "table.yes" : "table.not")}</b>
            </label>
          </Box>
        </Grid>
        <Grid item xs={3} mb={3}>
          <label>{t("pointsOfSale.minimumTransferValue").toUpperCase()}</label>
          <Box>
            <label>
              {/* @ts-ignore */}
              <b>{Intl.NumberFormat("es-MX").format(data.minimumTransfers)}</b>
            </label>
          </Box>
        </Grid>
        <Grid item xs={3} mb={3}>
          <label>{t("pointsOfSale.maximumTransferValue").toUpperCase()}</label>
          <Box>
            <label>
              <b>
                {/* @ts-ignore */}
                {Intl.NumberFormat("es-MX").format(data.maximumTransfers)}
              </b>
            </label>
          </Box>
        </Grid>
        {enableAnonymousSaleSelect && (
          <Grid item xs={3} mb={3}>
            <label>{t("pointsOfSale.allowAnonymousSale").toUpperCase()}</label>
            <Box>
              <label>
                <b>
                  <b>
                    {t(
                      data.allowAnonymousSale === State.ACTIVE
                        ? "table.yes"
                        : "table.not"
                    )}
                  </b>
                </b>
              </label>
            </Box>
          </Grid>
        )}

        {enableAnonymousPayoutSelect && (
          <Grid item xs={3} mb={3}>
            <label>
              {t("pointsOfSale.allowAnonymousPayouts").toUpperCase()}
            </label>
            <Box>
              <label>
                <b>
                  <b>
                    {t(
                      data.allowAnonymousPayouts === State.ACTIVE
                        ? "table.yes"
                        : "table.not"
                    )}
                  </b>
                </b>
              </label>
            </Box>
          </Grid>
        )}
        <Grid item xs={3} mb={3}>
          <label>{t("pointsOfSale.type").toUpperCase()}</label>
          <Box>
            <label>
              <b>{getPointTypeName(data.type as DealerTypeEnum)}</b>
            </label>
          </Box>
        </Grid>
        <Grid item xs={3} mb={3}>
          <label>{"Modelo de Afiliación".toUpperCase()}</label>
          <Box>
            <label>
              <b>{data.commissionType}</b>
            </label>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
