import React, { KeyboardEvent, FC, useEffect, useState } from "react";
import {
  Grid,
  InputAdornment,
  MenuItem,
  InputLabel,
  Select,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import { MyTextField } from "../custom/MyTextField";
import {
  regDecimal,
  regLettersNumbers,
  regNumbers,
} from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { bonusCampaignType } from "../../../helpers/bonusType";
import { getPayMethods } from "../../../redux/actions/managementPayMethodsActions";
import { AltenarBonusCampain } from "../../../enums/enums";

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  inputAdorned: {
    marginRight: "30px",
  },
  formControl: {
    width: "100%",
  },
}));
const styles = {
  "& input.Mui-disabled ": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
  "& .MuiSelect-select.Mui-disabled": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
};
interface props {
  formikRef: any;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const AltenarBonusForm: FC<props> = ({ formikRef }) => {
  const { values, handleChange } = formikRef;
  const { activeBonus } = useSelector((state: AppState) => state.bonus);
  const { t } = useTranslation();
  const classes = useStyles();
  const actualDay = moment().format("YYYY-MM-DD");
  const dispatch = useDispatch();
  const { payMethods } = useSelector(
    (state: AppState) => state.managementPayMethods
  );
  const [showBonusCodeField, setShowBonusCodeField] = useState(false);
  const handlePayMethodChange = (event: any) => {
    const value = event.target.value;
    formikRef.setFieldValue("payMethods", value);
  };
  useEffect(() => {
    dispatch(getPayMethods());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let maxBonusValue = 0;
    if (values.value && !values.percentageDeposit) {
      maxBonusValue = +values.value;
    }
    if (!values.value && values.percentageDeposit) {
      const percent = values.percentageDeposit / 100;
      maxBonusValue = +values.maxDeposit * percent;
    }

    formikRef.setFieldValue("maxBonus", maxBonusValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.value, values.percentageDeposit, values.maxDeposit]);

  useEffect(() => {
    const totalBondsValue =
      parseInt(values.value) * parseInt(values.amountBonus);
    if (totalBondsValue && !isNaN(totalBondsValue)) {
      formikRef.setFieldValue("totalBondsValue", totalBondsValue);
    } else {
      if (!activeBonus?.percentageDeposit) {
        formikRef.setFieldValue("totalBondsValue", "");
      }
    }
    const currentValue = activeBonus?.value || "";
    const currentAmountValue = activeBonus?.amountBonus || "";

    if (
      currentValue !== values.value ||
      currentAmountValue !== values.amountBonus
    ) {
      formikRef.setFieldValue("percentageDeposit", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.value, values.amountBonus]);
  useEffect(() => {
    const currentPercentage = activeBonus?.percentageDeposit || "";
    if (currentPercentage !== values.percentageDeposit) {
      formikRef.setFieldValue("value", "");
      formikRef.setFieldValue("amountBonus", "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.percentageDeposit]);

  useEffect(() => {
    const bonusType = values.altenarBonusType;
    if (
      bonusType === AltenarBonusCampain.FreeBetBonus ||
      bonusType === AltenarBonusCampain.CashbackBonus ||
      bonusType === AltenarBonusCampain.RiskFreeBet
    ) {
      setShowBonusCodeField(true);
    } else {
      setShowBonusCodeField(false);
    }
  }, [values.altenarBonusType]);

  const handleBonusTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const bonusType = event.target.value as AltenarBonusCampain;

    handleChange(event);

    setShowBonusCodeField(
      bonusType === AltenarBonusCampain.FreeBetBonus ||
        bonusType === AltenarBonusCampain.CashbackBonus ||
        bonusType === AltenarBonusCampain.RiskFreeBet
    );
  };

  return (
    <>
      <Grid item xs={4}>
        <MyTextField
          name="providerBonusId"
          variant="outlined"
          size="small"
          fullWidth
          onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
            !regLettersNumbers(e.key) && e.preventDefault()
          }
          label={`${t("managementBonds.bondIdAltenar")}*`}
          value={values.providerBonusId}
          onChange={handleChange}
          inputProps={{
            maxLength: 10,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <MyTextField
          name="altenarBonusType"
          variant="outlined"
          size="small"
          fullWidth
          value={values.provider}
          onChange={handleBonusTypeChange}
          role={"combobox"}
          select
          label={`${t("managementBonds.bondType")}*`}
        >
          {bonusCampaignType.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              sx={{
                "&.Mui-focused": {
                  backgroundColor: "red",
                },
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </MyTextField>
      </Grid>
      {showBonusCodeField && (
        <Grid item xs={4}>
          <MyTextField
            name="redemtionCode"
            variant="outlined"
            size="small"
            fullWidth
            onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
              !regLettersNumbers(e.key) && e.preventDefault()
            }
            label={`${t("managementBonds.redemtionCodeAltenar")}`}
            value={values.redemtionCode}
            onChange={handleChange}
            inputProps={{
              maxLength: 100,
            }}
          />
        </Grid>
      )}
      {!showBonusCodeField && <Grid item xs={4}></Grid>}
      <Grid item xs={3}>
        <MyTextField
          variant="outlined"
          size="small"
          type={"date"}
          name={"startDate"}
          label={`${t("filters.startDate")}*`}
          fullWidth
          InputProps={{
            classes: {
              input: classes.root,
            },
            inputProps: {
              min: activeBonus ? undefined : actualDay,
            },
          }}
          inputProps={{
            "data-testid": "startDate",
          }}
          value={values.startDate}
          onChange={handleChange}
          sx={styles}
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={3}>
        <MyTextField
          variant="outlined"
          size="small"
          type={"time"}
          name={"startTime"}
          label={`${t("table.startTime")}*`}
          fullWidth
          InputProps={{
            classes: {
              input: classes.root,
            },
            inputProps: {},
          }}
          inputProps={{
            "data-testid": "startTime",
          }}
          value={values.startTime}
          onChange={handleChange}
          sx={styles}
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={3}>
        <MyTextField
          variant="outlined"
          size="small"
          type={"date"}
          name={"endDate"}
          label={`${t("table.expirationDate")}*`}
          InputProps={{
            classes: {
              input: classes.root,
              adornedEnd: classes.inputAdorned,
            },
            inputProps: { min: values.startDate },
          }}
          fullWidth
          value={values.endDate}
          inputProps={{
            "data-testid": "endDate",
          }}
          onChange={handleChange}
          sx={styles}
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={3}>
        <MyTextField
          variant="outlined"
          size="small"
          type={"time"}
          name={"endTime"}
          label={`${t("table.endTime")}*`}
          InputProps={{
            classes: {
              input: classes.root,
              adornedEnd: classes.inputAdorned,
            },
            inputProps: {},
          }}
          fullWidth
          value={values.endTime}
          inputProps={{
            "data-testid": "endTime",
          }}
          onChange={handleChange}
          sx={styles}
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>

      <Grid item xs={4}>
        <MyTextField
          name="value"
          variant="outlined"
          size="small"
          fullWidth
          disabled={values.percentageDeposit.trim()}
          onKeyPress={(e: any) => {
            if (
              !regNumbers(e.key) ||
              (e.target.value.length === 0 && e.key === "0")
            ) {
              return e.preventDefault();
            }
          }}
          inputProps={{
            "data-testid": "value",
            maxLength: 10,
          }}
          value={values.value}
          onChange={handleChange}
          label={`${t("managementBonds.bondValue")}*`}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <MyTextField
          name="amountBonus"
          variant="outlined"
          size="small"
          disabled={values.percentageDeposit.trim()}
          fullWidth
          onKeyPress={(e: any) => !regNumbers(e.key) && e.preventDefault()}
          value={values.amountBonus}
          onChange={handleChange}
          label={`${t("managementBonds.amountBonds")}*`}
          inputProps={{
            "data-testid": "amountBonus",
            maxLength: 10,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <MyTextField
          name="percentageDeposit"
          variant="outlined"
          size="small"
          fullWidth
          onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
            !regDecimal(e.key) && e.preventDefault()
          }
          disabled={!!values.value.trim()}
          label={`${t("managementBonds.percentageDeposit")}`}
          value={values.percentageDeposit}
          onChange={handleChange}
          inputProps={{
            maxLength: 3,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <MyTextField
          name="totalBondsValue"
          variant="outlined"
          size="small"
          fullWidth
          onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
            !regNumbers(e.key) && e.preventDefault()
          }
          label={`${t("managementBonds.totalBondsValue")}*`}
          disabled={!values.percentageDeposit.trim()}
          onChange={handleChange}
          inputProps={{
            "data-testid": "totalBondsValue",
            maxLength: 20,
          }}
          value={values.totalBondsValue}
          sx={{
            "& .MuiOutlinedInput-root.Mui-error": {
              "& fieldset": {
                borderColor: "#d32f2f",
              },
            },
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={6}></Grid>

      <Grid item xs={12}>
        {`${t("managementBonds.label1")}`}
      </Grid>
      <Grid item xs={4}>
        <MyTextField
          name="minDeposit"
          variant="outlined"
          size="small"
          fullWidth
          onKeyPress={(e: any) => {
            if (
              !regNumbers(e.key) ||
              (e.target.value.length === 0 && e.key === "0")
            ) {
              return e.preventDefault();
            }
          }}
          inputProps={{
            "data-testid": "minDeposit",
            maxLength: 10,
          }}
          value={values.minDeposit}
          onChange={handleChange}
          label={`${t("managementBonds.minDeposit")}*`}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <MyTextField
          name="maxDeposit"
          variant="outlined"
          size="small"
          fullWidth
          onKeyPress={(e: any) => {
            if (
              !regNumbers(e.key) ||
              (e.target.value.length === 0 && e.key === "0")
            ) {
              return e.preventDefault();
            }
          }}
          inputProps={{
            "data-testid": "maxDeposit",
            maxLength: 10,
          }}
          value={values.maxDeposit}
          onChange={handleChange}
          label={`${t("managementBonds.maxDeposit")}*`}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        {`${t("managementBonds.label2")}`}
      </Grid>

      <Grid item xs={4}>
        <MyTextField
          name="maxBonus"
          variant="outlined"
          size="small"
          fullWidth
          onKeyPress={(e: any) => {
            if (
              !regNumbers(e.key) ||
              (e.target.value.length === 0 && e.key === "0")
            ) {
              return e.preventDefault();
            }
          }}
          inputProps={{
            "data-testid": "maxBonus",
            maxLength: 10,
          }}
          value={values.maxBonus}
          label={`${t("managementBonds.maxBonus")}*`}
          disabled={true}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {`${t("managementBonds.label3")}`}
      </Grid>
      <Grid item xs={6}>
        <FormControl className={classes.formControl} size="small">
          <InputLabel id="operator-label">
            {t("managementBonds.payLabel")}
          </InputLabel>
          <Select
            labelId="operator-label"
            name="operator"
            size="small"
            fullWidth
            multiple
            value={
              Array.isArray(formikRef.values.payMethods)
                ? formikRef.values.payMethods
                : []
            }
            onChange={handlePayMethodChange}
            input={<OutlinedInput label={t("managementBonds.payLabel")} />}
            MenuProps={MenuProps}
          >
            {payMethods.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};
