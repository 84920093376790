import { FC, Fragment } from "react";
import ToggleGroup from "../Casino/ToggleGroup";

const CommissionManagement: FC<{ setShowForm: any; setValue: any }> = ({
  setShowForm,
  setValue,
}) => {
  return (
    <Fragment>
      <div className="commission-management-container">
        <ToggleGroup
          title="Comisión jugador"
          editAction={() => {
            setShowForm(true);
            setValue("affiliationPercentage");
          }}
          isFirst={true}
          toggle={false}
        />
      </div>
    </Fragment>
  );
};

export default CommissionManagement;
