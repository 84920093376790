import React, { Dispatch, FC, SetStateAction } from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import filePlus from "../../../assets/svg/file-plus.svg";
import { useTranslation } from "react-i18next";
// @ts-ignore
import ReactExport from "react-data-export";
import { useDispatch, useSelector } from "react-redux";
import {
  startFilterLots,
  startUpdateLot,
} from "../../../redux/actions/managementCardsActions";
import { StateLotCards } from "../../../enums/enums";
import { AppState } from "../../../redux/reducers/rootReducer";
import { getBarcodeCard } from "../../../utils/utils";

interface Props {
  totalData: any;
  serial: string;
  setTooltipIsOpen: Dispatch<SetStateAction<boolean>>;
  row: any;
}

export const ExportCardsData: FC<Props> = ({
  totalData,
  serial,
  setTooltipIsOpen,
  row,
}) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const { lots } = useSelector((state: AppState) => state.managementCards);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onExport = async () => {
    if (!row.isDeliveredMaker) {
      await dispatch(
        startUpdateLot(row.id, {
          state: StateLotCards.DELIVERED_MANUFACTURER,
          updateOnlyLot: true,
          amount: row.amountCards,
          value: parseInt(row.totalValue) / row.amountCards,
          extLot: row.extLot,
          isDeliveredMaker:true
        },false)
      );
      await dispatch(
        startFilterLots({
          page: lots?.page || 1,
        })
      );
    }
    setTooltipIsOpen((prev: boolean) => !prev);
  };
  return (
    <ExcelFile
      element={
        <ListItem role="listitem" onClick={onExport}>
          <ListItemIcon sx={{ mr: -3, ml: -0.2 }}>
            <img width={"19px"} src={filePlus} alt="File plus" />
          </ListItemIcon>
          <ListItemText sx={{ my: 0 }} primary={t("cards.exportCards")} />
        </ListItem>
      }
      filename={`lot-${serial}`}
    >
      <ExcelSheet data={totalData} name="cardsList">
        <ExcelColumn label={t("cards.consecutiveCard")} value="id" />
        <ExcelColumn
          label={t("cards.barcode")}
          value={(card: any) => getBarcodeCard(card.value)}
        />
        <ExcelColumn label={t("cards.secretCode")} value="code" />
        <ExcelColumn label={t("cards.rechargeValue")} value="value" />
        <ExcelColumn
          label={t("cards.secretCode2")}
          value={(card: any) => (card.bonusCode ? card.bonusCode.code : "NA")}
        />
      </ExcelSheet>
    </ExcelFile>
  );
};
