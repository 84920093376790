import React from "react";
import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import { WithDrawalsRequest } from "../../components/transactions/withdrawals/WithDrawalsRequest";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";

export const Withdrawals = () => {
  const { t } = useTranslation();

  return (
    <div className="content w-100 mb-4">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyBreadCrumbs
            titles={[
              t("transactions.title"),
              t("transactions.withdrawalRequestNormal"),
            ]}
          />
        </Grid>

        <WithDrawalsRequest />
      </Grid>
    </div>
  );
};
