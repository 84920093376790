import { useState } from "react";
import PropTypes from "prop-types";
export const useForm = (initialValues: any = {}) => {
  const [formValues, setFormValues] = useState(initialValues);
  const reset = () => {
    setFormValues(initialValues);
  };
  const handleInputChange = (value: any | any[]) => {
    const multiSetValues = value.length !== undefined;
    if (multiSetValues) {
      const copyFormValues = { ...formValues };
      //@ts-ignore
      value.forEach(({ target }) => {
        copyFormValues[target.name] = target.value;
      });
      return setFormValues(copyFormValues);
    }
    const { target } = value;
    setFormValues({ ...formValues, [target.name]: target.value });
  };
  return [formValues, handleInputChange, reset];
};
useForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
};
