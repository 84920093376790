import Box from "@mui/material/Box";

import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";

import { ICurrencies } from "../../redux/interfaces/shared";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  indexCountry: number;
  currencySelected: ICurrencies[];
  setCurrencySelected: any;
}
export const CurrencySelect = ({
  indexCountry,
  currencySelected,
  setCurrencySelected,
}: Props) => {
  const { currenciesOperator } = useSelector(
    (state: AppState) => state.currencies
  );

  // @ts-ignore
  const arrayCurrencies: readonly ICurrencies[] = currenciesOperator.map(
    (currencyOp) => ({ ...currencyOp.currency })
  );

  return (
    <Autocomplete
      multiple
      id="country-select-demo"
      options={arrayCurrencies}
      autoComplete={false}
      // @ts-ignore
      value={currencySelected}
      onChange={(e, v) => {
        setCurrencySelected(indexCountry, v);
      }}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => (
        <Box
          component="li"
          sx={{
            "& > img": { mr: 2, flexShrink: 0, borderRadius: "50%" },
            color: "black",
          }}
          {...props}
        >
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            sx={{
              marginRight: 2,
              "&.Mui-checked": {
                color: "#04BF9D",
              },
            }}
            checked={selected}
          />
          <img
            loading="lazy"
            style={{
              width: "20px",
              height: "20px",
            }}
            src={option.imgUrl}
            alt=""
          />
          {option.label} - {option.currency}
        </Box>
      )}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip
            style={{
              backgroundColor: "rgba(0, 126, 140, 0.1)",
              height: "28px",
            }}
            deleteIcon={<ClearIcon style={{ fontSize: 14 }} />}
            {...getTagProps({ index })}
            label={
              <>
                <img
                  loading="lazy"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                    borderRadius: "50%",
                    padding: 0,
                  }}
                  src={option.imgUrl}
                  alt=""
                />
                {option.label} - {option.currency}
              </>
            }
          />
        ));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          // @ts-ignore
          InputLabelProps={{ shrink: false }}
          label={" "}
          inputProps={{
            ...params.inputProps,
            autoComplete: "disabled",
            readOnly: true,
          }}
        />
      )}
    />
  );
};
