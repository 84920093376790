import React, { Fragment, useState } from "react";
import "./TableComponent.scss";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, Tooltip } from "@mui/material";
import { MyTextField } from "../../components/custom/MyTextField";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  startGetGames,
  startUpdateGame,
} from "../../../redux/actions/casinoActivity";

const TableComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { casinoGames } = useSelector((state: AppState) => state.casino);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState(10);
  const [isFiltering, setIsFiltering] = useState(false);
  const [filterData, setFilterData] = useState({});
  const currentItems = casinoGames?.data;
  const totalPages = casinoGames?.last_page;

  const providers = [
    { name: "Pragmatic", value: 1 },
    { name: "Pariplay", value: 5 },
    { name: "Golden race", value: 78 },
    { name: "Aviatrix", value: 85 },
    { name: "Evolution", value: 87 },
  ];

  const initialValues = {
    name: "",
    provider: "",
  };

  const handleSubmit = (data: any) => {
    if (data.name || data.provider) {
      setIsFiltering(true);
      setFilterData(data);
    }
    const params = {
      ...data,
      page: currentPage,
      perPage: itemsPerPage,
    };
    dispatch(startGetGames(params));
  };

  const handleClean = (resetForm: any) => {
    setIsFiltering(false);
    resetForm();
    dispatch(startGetGames());
  };

  const getProviderName = (providerId: number): string => {
    const provider = providers.find((p) => p.value === providerId);
    return provider ? provider.name : "Unknown Provider";
  };

  const handleState = (item: any) => {
    const params = {
      state: item.state === 1 ? 2 : 1,
    };
    dispatch(startUpdateGame(params, Number(item.id)));
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    const params = {
      ...filterData,
      page: 1,
      perPage: itemsPerPage,
    };
    dispatch(startGetGames(params));
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      const params = {
        ...filterData,
        page: currentPage - 1,
        perPage: itemsPerPage,
      };
      dispatch(startGetGames(params));
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    const params = {
      ...filterData,
      page: page,
      perPage: itemsPerPage,
    };
    dispatch(startGetGames(params));
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      const params = {
        ...filterData,
        page: currentPage + 1,
        perPage: itemsPerPage,
      };
      dispatch(startGetGames(params));
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    const params = {
      ...filterData,
      page: totalPages,
      perPage: itemsPerPage,
    };
    dispatch(startGetGames(params));
  };

  const generatePageNumbers = () => {
    let pages = [];
    if (totalPages <= 7) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else if (currentPage <= 4) {
      pages = [1, 2, 3, 4, 5, "...", totalPages];
    } else if (currentPage > totalPages - 4) {
      pages = [
        1,
        "...",
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    } else {
      pages = [
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        totalPages,
      ];
    }
    return pages;
  };

  return (
    <div className="table-container">
      <div className="table-controls">
        <div className="filters">
          <Formik
            initialValues={initialValues}
            onSubmit={async (data, { resetForm }) => {
              if (!isFiltering) {
                handleSubmit(data);
              } else {
                handleClean(resetForm);
              }
            }}
          >
            {({ handleChange }) => (
              <Form>
                <Grid container spacing={2} alignItems="end">
                  <Grid item xs={12} md={4}>
                    <label className="ml-3">Nombre del juego</label>
                    <MyTextField
                      name="name"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="text"
                      label=""
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(e: any) => {
                        handleChange(e);
                        setIsFiltering(false);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <label className="ml-3">Seleccionar Proveedor</label>
                    <MyTextField
                      name="provider"
                      select
                      InputLabelProps={{ shrink: false }}
                      label={" "}
                      onChange={(event: any) => {
                        handleChange(event);
                        setIsFiltering(false);
                      }}
                    >
                      {providers.map((item: any, index: number) => (
                        <MenuItem
                          key={index}
                          value={item.value}
                          sx={{
                            "&.Mui-focused": {
                              backgroundColor: "red",
                            },
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </MyTextField>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <button
                      className="d-flex btn-light-dark"
                      type="submit"
                      style={{ width: "100%" }}
                    >
                      <div className="p-0 text-right">
                        <i className="fa fa-filter btn-icon pt-1 fa-md"></i>
                      </div>
                      <div className="p-0 text-left">
                        <label className="btn-label pl-1">
                          {isFiltering
                            ? t("filters.clean")
                            : t("filters.filter")}
                        </label>
                      </div>
                    </button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {casinoGames?.total === 0 ? (
        <div>No se encontraron resultados</div>
      ) : (
        <Fragment>
          <table className="table">
            <thead>
              <tr>
                <th>Código del juego</th>
                <th>Nombre del juego</th>
                <th>Proveedor</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody className="tbody-table">
              {currentItems?.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>{item.gameCode}</td>
                    <td>{item.name}</td>
                    <td>{getProviderName(Number(item.provider))}</td>
                    <td>
                      <Tooltip title={t("button.changeState") as string} arrow>
                        <div
                          style={{ textAlign: "center" }}
                          onClick={() => handleState(item)}
                        >
                          <i
                            className={
                              item.state === 1
                                ? "fa fa-eye fa-lg mr-2 iconSee"
                                : "fa-regular fa-eye-slash fa-lg mr-2 iconSee"
                            }
                          />
                        </div>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination">
            <button
              className="navigation"
              onClick={handleFirstPage}
              disabled={currentPage === 1}
            >
              {"<<"}
            </button>
            <button
              className="navigation"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              {"<"}
            </button>
            {generatePageNumbers().map((page, index) =>
              page === "..." ? (
                <span key={index} className="ellipsis">
                  ...
                </span>
              ) : (
                <button
                  key={index}
                  className={`page-number ${
                    page === currentPage ? "active" : ""
                  }`}
                  onClick={() => handlePageChange(page as number)}
                >
                  {page}
                </button>
              )
            )}
            <button
              className="navigation"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              {">"}
            </button>
            <button
              className="navigation"
              onClick={handleLastPage}
              disabled={currentPage === totalPages}
            >
              {">>"}
            </button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default TableComponent;
