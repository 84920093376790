import { FC } from "react";
import { Page, View, Document, Image } from "@react-pdf/renderer";
import { IUserData } from "../../redux/interfaces/users";
import LogoNeat from "../../assets/img/NEAT-LOGO.png";
import { sliceArray } from "../../utils/utils";
import { IRefills } from "../../interfaces/refills";
import { UsersPdfDocument } from "./Users/UsersPdfDocument";
import { stylesPdf } from "../../views/components/custom/customStyles";
import { RefillsPdfDocument } from "./Refills/RefillsPdfDocument";
import { MenusPdfDocument } from "./Menus/MenusPdfDocument";
import { OperatorConfigPdfDocument } from "./OperationCompany/OperatorConfigPdfDocument";
import { ManagementCompaniesPdfDocument } from "./ManagementCompany/ManagementCompaniesPdfDocument";
import TransactionSettingsPdf from "./transactions/TransactionSettingsPdf";
import { LegalPdfDocument } from "./Legal/LegalPdfDocument";
import { IrrigationPdfDocument } from "./Irrigation/IrrigationPdfDocument";
import { BetHistoryPdfDocument } from "./bets/BetHistoryPdfDocument";
import { BetsPlayerPdfDocument } from "./activityPlayer/BetsPlayerPdfDcoument";
import { BondsPdfDocument } from "./activityPlayer/BondsPdfDocument";
import { GrossIncomeReportsDocument } from "./Reports/grossIncomeReportsDocument";
import { JackpotAwardsReportDocument } from "./Reports/jackpotAwardsReportDocument";
import { RelevantEventsReportDocument } from "./Reports/relevantEventsReportDocument";
import { SignificantEventsReport } from "./Reports/significantEventsReportDocument";

interface Props {
  id: number;
  data: IUserData[] | IRefills[];
  component: string;
  perView?: boolean;
}

export const PdfDocument: FC<Props> = ({ data, component, id, perView }) => {
  const BuildPdf = (component: string, page: any) => {
    switch (component) {
      case "users":
        return <UsersPdfDocument page={page} />;
      case "refills":
        return <RefillsPdfDocument page={page} />;
      case "menu":
        return <MenusPdfDocument page={page} />;
      case "configCompany":
        return <OperatorConfigPdfDocument page={page} />;
      case "managementCompany":
        return <ManagementCompaniesPdfDocument page={page} />;
      case "transactions":
        return <TransactionSettingsPdf page={page} />;
      case "legal":
        return <LegalPdfDocument page={page} perView={perView} />;
      case "irrigation":
        return <IrrigationPdfDocument page={page} perView={perView} />;
      case "bettingHistory":
        return <BetHistoryPdfDocument page={page} />;
      case "bets":
        return <BetsPlayerPdfDocument page={page} />;
      case "bonds":
        return <BondsPdfDocument page={page} />;
      case "grossIncomeReport":
        return <GrossIncomeReportsDocument page={page} />;
      case "jackpotAwardsReport":
        return <JackpotAwardsReportDocument page={page} />;
      case "relevantEventsReport":
        return <RelevantEventsReportDocument page={page} />;
      case "significantEventsReport":
        return <SignificantEventsReport page={page} />;
      // TODO: DEMAS COMPONENTES
      default:
        return null;
    }
  };

  const pagesData: any[][] = sliceArray(20, data);
  return (
    <Document>
      {pagesData.map((page, index) => (
        <Page
          size="A4"
          key={index}
          style={stylesPdf.page}
          orientation="landscape"
        >
          <View style={stylesPdf.container}>
            <View
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <Image src={LogoNeat} style={stylesPdf.logo} />
            </View>
            {BuildPdf(component, page)}
          </View>
        </Page>
      ))}
    </Document>
  );
};
