import { Form, Formik, useFormikContext } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import { MyTextField } from "../../../custom/MyTextField";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import { CurrentPlaceCards, State } from "../../../../../enums/enums";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../../redux/actions/helperActions";
import { MyDialog } from "../../../custom/MyDialog";
import {
  setIrrigationCardsHistory,
  startCreateIrrigationCards,
} from "../../../../../redux/actions/managementCardsActions";
import {
  IDataIrrigationCard,
  IrrigationEntry,
} from "../../../../../interfaces/cards";

interface Props {
  expand: boolean;
  setExpand: React.Dispatch<React.SetStateAction<boolean>>;
  setResetInfo: React.Dispatch<React.SetStateAction<boolean>>;
  resetInfo: boolean;
  setResetGenerate: React.Dispatch<React.SetStateAction<boolean>>;
  resetGenerate: boolean;
  to?: CurrentPlaceCards;
}
interface Event {
  target: {
    value: string;
    name: string;
  };
}

export const GenerateTransferAdmin: FC<Props> = ({
  expand,
  setExpand,
  setResetInfo,
  resetInfo,
  setResetGenerate,
  resetGenerate,
  to,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [newTransfer, setNewTransfer] = useState<IDataIrrigationCard | null>(
    null
  );
  const [firstCardId, setFirstCardId] = useState("");
  const [lastCardId, setLastCardId] = useState("");

  const { activeLot, irrigatedDetail } = useSelector(
    (state: AppState) => state.managementCards
  );

  const { activeAssociate } = useSelector(
    (state: AppState) => state.associates
  );

  const initialValues = {
    value: "",
  };

  const dataActiveLot = activeLot as IrrigationEntry;

  let validationSchema = yup.object({
    value: yup
      .string()
      .matches(/^\d+,\d+$/, t("form.invalidFormatConsecutive"))
      .required(t("form.requiredFile")),
  });
  if (dataActiveLot) {
    const minCardId = dataActiveLot.availableRanges[0]?.minCardId as number;
    const maxCardId = dataActiveLot.availableRanges[0]?.maxCardId as number;

    validationSchema = validationSchema.shape({
      value: validationSchema.fields.value.test(
        "range",
        t("irrigation.rangeLotValidation"),
        (value) => {
          if (!value) {
            return true;
          }
          const [firstNumber, secondNumber] = value.split(",").map(Number);
          return (
            firstNumber === minCardId &&
            firstNumber <= maxCardId &&
            secondNumber >= minCardId &&
            secondNumber <= maxCardId
          );
        }
      ),
    });
  }
  const handleGenerateTransfer = async () => {
    if (!activeAssociate && !irrigatedDetail) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("irrigation.searchAssociate"));
      return dispatch(setTypeAlert("warning"));
    }

    if (!dataActiveLot) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("irrigation.selectedLot"));
      return dispatch(setTypeAlert("warning"));
    }
    if (activeAssociate?.stateUser !== State.ACTIVE && !irrigatedDetail) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("irrigation.errorAssociateInactive"));
      return dispatch(setTypeAlert("error"));
    }
    const transfer: IDataIrrigationCard = {
      range: {
        firstCardId: parseInt(firstCardId),
        lastCardId: parseInt(lastCardId),
      },
      payload: {
        //@ts-ignore
        id: activeAssociate?.id ?? irrigatedDetail?.idIrrigated,
      },
      lot: dataActiveLot.lotId as number,
    };
    setNewTransfer(transfer);

    setOpenDialog(true);

    setLoading(false);
  };

  const handleAccept = async (resetForm: any) => {
    try {
      setLoading(true);
      setOpenDialog(false);

      if (newTransfer) {
        await dispatch(startCreateIrrigationCards(newTransfer, to));
        dispatch(setIrrigationCardsHistory(null));

        resetForm();
      }

      setNewTransfer(null);
    } catch (error) {
      console.error("Error during handleAccept operation:", error);
    } finally {
      setLoading(false);
    }
  };

  const AutoReset = () => {
    const { resetForm } = useFormikContext();
    useEffect(() => {
      if (resetInfo || resetGenerate) {
        resetForm();
      }
      setResetInfo(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetInfo, resetGenerate]);
    return null;
  };

  const handleChangeValue = (e: Event) => {
    const { value } = e.target;

    // Separar los números por coma y eliminar espacios en blanco
    const numbers = value.split(",").map((num) => num.trim());
    if (numbers.length < 2) {
      return;
    }
    let firstNumber = numbers[0].trim();
    let secondNumber = numbers[1].trim() || "";

    // Si el primer número no es un número válido, establecer como vacío
    if (isNaN(parseFloat(firstNumber))) {
      firstNumber = "";
    }

    // Si el segundo número no es un número válido, establecer como vacío
    if (isNaN(parseFloat(secondNumber))) {
      secondNumber = "";
    }

    // Actualizar los estados de firstCardId y lastCardId
    setFirstCardId(firstNumber);
    setLastCardId(secondNumber);
  };

  return (
    <Box className="filter" py={1} sx={{ minHeight: expand ? "200px" : "" }}>
      <Box display="flex" justifyContent={"space-between"}>
        <Box display="flex" alignItems="center">
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <Typography
            color="text.primary"
            style={{ fontSize: "16px", fontWeight: 500 }}
          >
            {t("irrigation.generateTransfer")}
          </Typography>
        </Box>
        <IconButton
          size="small"
          style={{ border: "none", outline: "none" }}
          onClick={() => setExpand((prev) => !prev)}
        >
          {expand ? (
            <i className="fa fa-angle-up" />
          ) : (
            <i className="fa fa-angle-down" />
          )}
        </IconButton>
      </Box>
      {expand && (
        <Box width={1} borderTop={1} borderColor={"#02D0E7"} mt={1}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={true}
            enableReinitialize={true}
            onSubmit={async (_data, { setSubmitting }) => {
              setSubmitting(true);
              await handleGenerateTransfer();
            }}
          >
            {({ handleChange, values, errors, resetForm }) => (
              <Form>
                <Grid container spacing={1} my={2}>
                  <Grid item md={6} xs={12}>
                    <MyTextField
                      name="value"
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={`${t("irrigation.consecutive")}*`}
                      inputProps={{
                        maxLength: 50,
                      }}
                      value={values.value}
                      onChange={(e: Event) => {
                        handleChangeValue(e);
                        handleChange(e);
                      }}
                      onKeyPress={(
                        e: React.KeyboardEvent<HTMLInputElement>
                      ) => {
                        // Permitir solo números y coma
                        const allowedChars = /^[0-9,]+$/;
                        if (!allowedChars.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      className="primary-btn ripple"
                      style={{
                        border: "none",
                        outline: "none",
                        width: "20%",
                        marginTop: !errors.value ? "20px" : "",
                        color: "white",
                        opacity:
                          values.value.split(",").length < 2 &&
                          (!irrigatedDetail || !activeAssociate)
                            ? "0.5"
                            : "",
                      }}
                      disabled={
                        values.value.split(",").length < 2 &&
                        (!irrigatedDetail || !activeAssociate)
                      }
                    >
                      {loading ? (
                        <CircularProgress size={"1.6rem"} color="inherit" />
                      ) : (
                        t("button.save").toUpperCase()
                      )}
                    </Button>
                  </Grid>
                </Grid>
                <AutoReset />
                <MyDialog
                  open={openDialog}
                  title={t("dialog.confirmationWindow")}
                  message={t("irrigation.sureMakeTransfer")}
                  handleAccept={() => handleAccept(resetForm)}
                  handleClose={() => setOpenDialog(false)}
                  isLoading={loading}
                />
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </Box>
  );
};
