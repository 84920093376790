import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import React from "react";
import { IConfigGames } from "../../../../interfaces/pointsSale";

interface Props {
  data: IConfigGames[];
}

export const TabConfigGames = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      {/* CONFIRMACIÓN CONFIGURACIÓN JUEGOS*/}
      <Grid container mb={2} ml={4}>
        <Grid item xs={3} mb={3}>
          <label>{t("pointsOfSale.sports").toUpperCase()}</label>
          <Box>
            <label>
              <b>
                {" "}
                {data.find(
                  (game: IConfigGames) =>
                    game.game?.name.toLowerCase() ===
                    t("pointsOfSale.sports").toLowerCase()
                )
                  ? t("state.active").toUpperCase()
                  : t("state.inactive").toUpperCase()}
              </b>
            </label>
          </Box>
        </Grid>

        <Grid item xs={3} mb={3}>
          <label>{t("sidebar.casino").toUpperCase()}</label>
          <Box>
            <label>
              <b>
                {data.find(
                  (game: IConfigGames) =>
                    game.game?.name.toLowerCase() ===
                    t("sidebar.casino").toLowerCase()
                )
                  ? t("state.active").toUpperCase()
                  : t("state.inactive").toUpperCase()}
              </b>
            </label>
          </Box>
        </Grid>

        <Grid item xs={3} mb={3}>
          <label>{t("pointsOfSale.virtual").toUpperCase()}</label>
          <Box>
            <label>
              <b>
                {data.find(
                  (game: IConfigGames) =>
                    game.game?.name.toLowerCase() ===
                    t("pointsOfSale.virtual").toLowerCase()
                )
                  ? t("state.active").toUpperCase()
                  : t("state.inactive").toUpperCase()}
              </b>
            </label>
          </Box>
        </Grid>

        <Grid item xs={3} mb={3}>
          <label>{t("pointsOfSale.equestrian").toUpperCase()}</label>
          <Box>
            <label>
              <b>
                {data.find(
                  (game: IConfigGames) =>
                    game.game?.name.toLowerCase() ===
                    t("pointsOfSale.equestrian").toLowerCase()
                )
                  ? t("state.active").toUpperCase()
                  : t("state.inactive").toUpperCase()}
              </b>
            </label>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
