import { View, Text } from "@react-pdf/renderer";
import { FC } from "react";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import { t } from "i18next";

interface Props {
  page: any;
}

export const grossRevenueReportDocument: FC<Props> = ({ page }) => {
  const columnsMenus = [
    t("reports.totalAmountDeposits").toUpperCase(),
    t("reports.totalWithdrawals").toUpperCase(),
    t("reports.totalFundsAvailable").toUpperCase(),
  ];
  const columns = columnsMenus;

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return (
    <>
      <View
        style={[
          stylesPdf.row,
          {
            backgroundColor: "#02D0E7",
          },
        ]}
      >
        {columns.map((column, index) => (
          <View key={column} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        {
          page.map((data: any, index: number) => {
            return (
              <View key={index} style={[stylesPdf.row]}>
                <View style={[stylesPdf.col, stylesPdf.pCol]}>
                  <Text style={stylesPdf.textContent}>
                    {currencyFormatter.format(data?.totalAmountDeposits)}
                  </Text>
                </View>
                <View style={[stylesPdf.col, stylesPdf.pCol]}>
                  <Text style={stylesPdf.textContent}>
                    {currencyFormatter.format(data?.totalWithdrawals)}
                  </Text>
                </View>
                <View style={[stylesPdf.col, stylesPdf.pCol]}>
                  <Text style={stylesPdf.textContent}>
                    {currencyFormatter.format(data?.totalFundsAvailable)}
                  </Text>
                </View>
              </View>
            )
          })
        }
      </View>
    </>
  );
};
