import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BasicTable } from "../../../components/BasicTable/BasicTable";
import { IColumnsTable } from "../../../interfaces/components";
import { AppState } from "../../../redux/reducers/rootReducer";
import { setActiveRol } from "../../../redux/actions/managementRolesActions";

export const RoleDetail = () => {
  const { activeRol } = useSelector((state: AppState) => state.managementRoles);
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(setActiveRol(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Soporta las Columnas para renderizar en el header de la tabla
   */

  const columns: IColumnsTable[] = [
    { id: 1, header: "table.menuAssigned" },
    { id: 2, header: "table.moduleAssigned" },
    { id: 3, header: "rolManagement.create" },
    { id: 4, header: "rolManagement.read" },
    { id: 5, header: "rolManagement.edit" },
    { id: 6, header: "rolManagement.delete" },
  ];

  return (
    <BasicTable
      component={"roles"}
      data={activeRol ? activeRol : []}
      columns={columns}
    />
  );
};
