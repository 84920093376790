export const loginAccess = (username: string, password: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
    },
    body: JSON.stringify({ username, password }),
  };
  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}security/login`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const sessionValidation = (userId: string | number, token?: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const params = `userId=${userId}`;

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}users/session/validation?${params}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const forgotPassword = (email: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
    },
    body: JSON.stringify({ email, application: true }),
  };
  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}reset-password`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const changePassword = (password: string, token: string) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
    },
    body: JSON.stringify({ password, token }),
  };
  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}reset-password`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
