import moment from "moment";

export const normalizeData = (formData: any) => {
  const data = { ...formData };

  if (data.registrationDate) {
    data.registrationDate = moment(data.registrationDate)
      .startOf("day")
      .toISOString();
  }

  for (const key in data) {
    if (data[key] === "") {
      data[key] = null;
    }
  }

  return data;
};
