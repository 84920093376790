import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";

import { Grid, Step, StepLabel, Stepper } from "@mui/material";

import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  setActiveStep,
  setFlagForm,
} from "../../../../redux/actions/helperActions";
import { useEffect, useState } from "react";
import { FormCreatePointSale } from "./FormCreatePointSale";
import { setGetAssociates } from "../../../../redux/actions/associatesActions";

const stylesStepper = {
  "& .MuiStepConnector-line": {
    height: "70px",
    width: "2px",
    border: "1px solid #C0C0C0",
  },
  "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
    border: "1px solid #0BC5DA",
    height: "70px",
    width: "0px",
  },
  "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
    border: "1px solid  #0BC5DA",
    height: "70px",
    width: "0px",
  },
  "& .MuiStepLabel-root .Mui-active": {
    color: "#0BC5DA",
    fontSize: 30,
    borderRadius: "50%",
    border: "1px solid #35D9EC",
    overflow: "hidden",
    padding: "1px",
    background: "#fff",
  },
  "& .MuiStepLabel-root .Mui-completed": {
    color: "#0BC5DA",
  },
};

export const StepsNewPointSale = () => {
  const dispatch = useDispatch();
  const steps = ["1", "2", "3", "4", "5", "6", "7"];
  const { activeStep } = useSelector((state: AppState) => state.helper);
  const { token } = useSelector((state: AppState) => state.auth);
  const [nextStep, setNextStep] = useState(false);

  // Efecto para cargar asociados
  useEffect(() => {
    token && dispatch(setGetAssociates(token, 1, true));
    return () => {
      dispatch(setFlagForm({ flagFormCreate: false }));
      dispatch(setActiveStep(0));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content w-100 pl-4 mt-0">
      <Row>
        <Grid container>
          <Grid item xs={1} sm={1} md={0.5} mb={2} pl={1}>
            <Stepper
              activeStep={activeStep}
              orientation={"vertical"}
              sx={stylesStepper}
            >
              {steps.map((label, index: number) => (
                <Step key={label}>
                  <StepLabel
                    onClick={() => nextStep && dispatch(setActiveStep(index))}
                  ></StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={11} sm={11} md={11.5}>
            <FormCreatePointSale setNextStep={setNextStep} />
          </Grid>
        </Grid>
      </Row>
    </div>
  );
};
