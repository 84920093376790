import { Roles_Enum } from "../enums/enums";
import { IPlayerData } from "../interfaces/users";
import { IExclusion } from "../interfaces/exclusions";
import { IObservationsData } from "../interfaces/players";

export const getFullDetailPlayer = (
  token: string,
  operatorId: number,
  nameRole: string
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const rol = nameRole === Roles_Enum.SUPER_ADMIN ? 1 : 2;

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}players?operatorId=${operatorId}&rol=${rol}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const getPlayerById = (
  token: string,
  id: string,
  operator: number | null
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const operatorId = operator ? `?operatorId=${operator}` : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}players/${id}${operatorId}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const getRefillsService = (
  token: string,
  userId: number,
  page: number,
  rowsPerPage: number | null,
  operatorId?: number
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const params = rowsPerPage
    ? operatorId
      ? `operatorId=${operatorId}&userId=${userId}&page=${page}&perPage=${rowsPerPage}`
      : `page=${page}&perPage=${rowsPerPage}`
    : operatorId
    ? `operatorId=${operatorId}&userId=${userId}&page=${page}`
    : `page=${page}`;

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}refills?${params}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const filterPlayers = (
  token: string,
  params: any,
  nameRole: string,
  operatorId?: number
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const rol = nameRole === Roles_Enum.SUPER_ADMIN ? 1 : 2;
  const queryString = new URLSearchParams(params).toString();
  const operator = operatorId ? `&operatorId=${operatorId}` : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}players?${queryString}&rol=${rol}${operator}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const filterRefills = (
  token: string,
  params: any,
  userId: number,
  operatorId?: number
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryString = new URLSearchParams(params).toString();
  const operator = operatorId
    ? `operatorId=${operatorId}&userId=${userId}`
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}refills?${queryString}&${operator}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const editPlayerService = (
  data: IPlayerData | any,
  token: string,
  id: number
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}players/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const resetSecurityQuestionService = (token: string, id: number) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}answersPlayers/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const getExclusions = (token: string, params?: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}exclusions?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const createEXclusionService = (token: string, data: IExclusion) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}exclusions`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const updateExclusionService = (
  token: string,
  idExclusion: number,
  data: Partial<IExclusion>
): Promise<any> => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify({ ...data }),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}exclusions/${idExclusion}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const getExclusionCategories = (token: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}exclusionCategories`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const getPlayerObservations = (token: string, params?: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}observations?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const createObservationService = (
  token: string,
  data: IObservationsData
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}observations`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const updateObservationService = (
  token: string,
  id: number,
  data: IObservationsData
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}observations/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
