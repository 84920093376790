import { ICurrencyOp } from "./../../interfaces/operationCompany";
import { getCurrenciesOperator } from "./../../services/currenciesOperator";
import { ICurrenciesOperatorQuery } from "../../interfaces/curreniesOperator";
import { AppState } from "../reducers/rootReducer";
import { setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";
import { Types } from "../types/types";

export const startGetCurrenciesOperator = (
  params: Partial<ICurrenciesOperatorQuery>
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;

    if (!token || !user) {
      throw new Error("invalid action");
    }
    try {
      if (user.operator) {
        params.operator = user.operator.id;
      }

      let finalValues: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (typeof value === "number" || value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });

      const response = await getCurrenciesOperator(token, finalValues);
      if (response.error) {
        dispatch(setMessageAlert(`alerts.${response.error.errorMessage}`));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      const { rs } = response;
      return dispatch(setCurernciesOperator(rs));
    } catch (error) {
      dispatch(setMessageAlert(`alerts.getError`));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }
  };
};

export const setCurernciesOperator = (data: ICurrencyOp[]) => ({
  type: Types.setCurrenciesOperator,
  payload: data,
});
