import Box from "@mui/material/Box";

import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";
import { ILanguageType } from "../../redux/interfaces/shared";
import { useEffect, useState } from "react";
import { getLanguages } from "../../services/helperService";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  indexCountry: number;
  languagesSelected: ILanguageType[];
  setLanguagesSelected: any;
  idCountry: number;
}
export const LanguageSelect = ({
  indexCountry,
  languagesSelected,
  setLanguagesSelected,
  idCountry,
}: Props) => {
  const [languages, setLanguages] = useState<ILanguageType[]>([]);
  const { countries } = useSelector((state: AppState) => state.helper);
  const { token } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    const getLanguagesByCountry = async (idCountry: number, token: string) => {
      const response = await getLanguages(token, idCountry);
      setLanguages(response);
    };
    if (countries && token) {
      getLanguagesByCountry(idCountry, token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // @ts-ignore
  const arrayLanguages: readonly ILanguageType[] = languages;
  return (
    <Autocomplete
      multiple
      id="country-select-demo"
      options={arrayLanguages}
      autoComplete={false}
      // @ts-ignore
      value={languagesSelected}
      onChange={(e, v) => {
        setLanguagesSelected(indexCountry, v);
      }}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderOption={(props, option, { selected }) => (
        <Box component="li" {...props} style={{ color: "black" }}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            sx={{
              marginRight: 2,
              "&.Mui-checked": {
                color: "#04BF9D",
              },
            }}
            checked={selected}
          />
          {option.name}
        </Box>
      )}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip
            style={{
              backgroundColor: "rgba(0, 126, 140, 0.1)",
              height: "28px",
            }}
            deleteIcon={<ClearIcon style={{ fontSize: 14 }} />}
            {...getTagProps({ index })}
            label={option.name}
          />
        ));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          // @ts-ignore
          InputLabelProps={{ shrink: false }}
          label={" "}
          inputProps={{
            ...params.inputProps,
            autoComplete: "disabled",
            readOnly: true,
          }}
        />
      )}
    />
  );
};
