import { State } from "../enums/enums";
import { ICreatedUser } from "../redux/interfaces/users";
import { IOperator } from "../redux/reducers/authReducer";
import { IPaginationProps } from "./shared";

export enum Enum_rol {
  ADMIN = "ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
  PLAYER = "PLAYER",
}
interface IMenuItems {
  url: string | null;
  name: string;
  layout: string;
  icon: string;
  state: boolean | string;
  modules: IModules[] | null;
}
export interface IModules {
  name: string;
  url: string;
  icon: string;
  state: boolean | string;
  component?: any;
}
export interface IMenu {
  rol: Enum_rol;
  menu: IMenuItems[];
}

export interface IRoutes extends IMenuList {
  collapse?: boolean;
  layout: string;
  component?: any;
}

export interface IFiltro {
  inputUno: string;
  inputDos: string;
  inputTres: string;
}

export interface IFiltroSelect {
  opcionUno: string;
  opcionDos: string;
  opcionTres: string;
}
export interface IMenuParams extends IPaginationProps{
  userId:number,
  operatorId:number,
  state:State,
  url:string,
  name:string,
  description:string

}
export interface IMenuDB {
  data: IMenuList[];
  last_page: number;
  page: number;
  total: number;
}
export interface IMenuList {
  createAt?: string;
  description: string;
  icon: string;
  id?: number;
  menuItem: IMenuItem[];
  name: string;
  order: string | number;
  state: any;
  updateAt?: string;
  url: string;
  createdUser?: ICreatedUser;
  operator?: IOperator;
  permissionsMenu:IModulePermissions
}

export interface IMenuListCreate {
  createAt?: string;
  description: string;
  icon: string;
  id?: number;
  menuItem: IMenuItem[];
  name: string;
  state: any;
  updateAt?: string;
  url: string;
  createdUser?: number | ICreatedUser;
  operator?: number | null;
}

export interface IMenuCreate {
  createAt?: string;
  description: string;
  icon: string;
  id?: number;
  menuItem: IMenuItem[];
  name: string;
  state: number;
  updateAt?: string;
  url: string;
  createdUser?: ICreatedUser;
  operator?: IOperator;
}
export interface IMenuItem {
  description: string;
  id?: number;
  name: string;
  order?: string | number;
  state?: number;
  url: string;
  createdUser?: ICreatedUser;
  permissions?:IModulePermissions
}
export interface IModulePermissions{
  created:1|0,
  deleted:1|0,
  readed:1|0,
  updated:1|0,
}
export interface IQueryFilterMenu {
  name: string;
  description: string;
  url: string;
  state: string;
}
