import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { TableComponent } from "../../../../components/Table/TableComponent";
import { IColumnsTable } from "../../../../interfaces/components";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { IUserData } from "../../../../redux/interfaces/users";

const columns: IColumnsTable[] = [
  { id: 1, header: "table.userId" },
  { id: 2, header: "filters.username" },
  { id: 3, header: "user.email" },
  { id: 4, header: "user.createAt" },
  { id: 5, header: "form.state" },
  { id: 6, header: "user.specialStatus" },
];

export const CredentialsPointSaleTab = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<Partial<IUserData>[]>([]);
  const { activePointSale } = useSelector(
    (state: AppState) => state.pointsSale
  );

  useEffect(() => {
    let arrayUsers: Partial<IUserData>[] = [];
    if (activePointSale) {
      activePointSale.associates
        .filter((el) => el.associate)
        .forEach((associate) => {
          associate.associate.associatesUsers?.forEach(
            (associateUsers: any) => {
              arrayUsers.push(associateUsers.user);
            }
          );
        });
      setData(arrayUsers);
    } else {
      setData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePointSale]);

  return (
    <TableComponent
      columns={columns}
      data={data}
      pages={1}
      component={"credentialsPointSale"}
      totalData={
        data
          ? {
              total: data.length,
              last_page: 1,
              page: 1,
            }
          : []
      }
      credentialsTitle={
        t("associates.listAssociatedCredentials").toUpperCase() +
        " " +
        activePointSale?.name.toUpperCase()
      }
      isSortingTable={false}
      dataFilter={{}}
    />
  );
};
