import { Alert, AlertTitle, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../../../../../components/Filter/Filter";

import InfoIcon from "@mui/icons-material/Info";
import { Row } from "reactstrap";
import MyCustomTable from "../../../../../components/Custom/Table/MyCustomTable";
import { GameResultBingo } from "../../../../../enums/enums";
import { IBingoGameActivityQuery } from "../../../../../interfaces/casino";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../../interfaces/components";
import {
  asyncLoadingFunction,
  setCloseAlert,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../../redux/actions/helperActions";
import { arraySearch } from "../../../../../redux/actions/searchActions";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import "../tabsGamingActivity.scss";
import { RowDataTableBingo } from "./RowDataTableBingo";
import { startGetBingoGameActivity } from "../../../../../redux/actions/bingoGameActivityActions";
import moment from "moment";
import { BingoGameActivityDataTable } from "../../../../../hooks/useDataTable/activityPlayer/bingo/BingoDataTable";
import { BingoGameActivityPdf } from "../../../../../components/Pdf/activityPlayer/bingo/BingoGameActivityPdf";
import { getBingoReportForGameActivity } from "../../../../../services/bingo";

export const TabBingo = () => {
  const { t } = useTranslation();
  const { token } = useSelector((state: AppState) => state.auth);
  const { player } = useSelector((state: AppState) => state.managementPlayers);
  const { flagCleanFilter, rowsPerPage } = useSelector(
    (state: AppState) => state.helper
  );
  const { data } = useSelector((state: AppState) => state.search);
  const [cleanFilter, setCleanFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState<any>({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (flagCleanFilter) {
      return setCleanFilter(true);
    }
    return setCleanFilter(false);
  }, [flagCleanFilter]);

  /**
   * Datos inputs para filtro
   */
  const FilterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("bets.bettingStatus"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("betResults.won"),
          value: GameResultBingo.WIN,
        },
        {
          label: t("betResults.loss"),
          value: GameResultBingo.LOST,
        },
      ],
    },
    {
      label: t("bets.betAmount"),
      type: Enum_FieldType.NUMBER,
    },
    {
      label: t("bets.winningAmount"),
      type: Enum_FieldType.NUMBER,
    },
    {
      label: t("bets.internalId"),
      type: Enum_FieldType.TEXT,
    },
  ];

  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startGetBingoGameActivity({
            ...dataFilter,
            page,
            perPage: rows || rowsPerPage,
          })
        )
      )
    );
  };

  const handleFilter = async (values: any) => {
    if (player) {
      const params: Partial<IBingoGameActivityQuery> = {
        internalId: values[t("bets.internalId")],
        playerId: (player as any).data[0].id,
        startDate: values[t("filters.startDate")],
        endDate: values[t("filters.endDate")],
        result: values[t("bets.bettingStatus")],
        amountBet: values[t("bets.betAmount")],
        awardsBet: values[t("bets.winningAmount")],
        page: 1,
      };
      setDataFilter(params);

      if (params.endDate?.trim() && !params.startDate?.trim()) {
        dispatch(setMessageAlert("alerts.selectStartDate"));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }

      dispatch(
        asyncLoadingFunction(() => dispatch(startGetBingoGameActivity(params)))
      );
    }
  };

  useEffect(() => {
    dispatch(setCloseAlert());
    return () => {
      dispatch(setCloseAlert());
      dispatch(arraySearch(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBingoGameActivityAction = async () => {
    if (player && token) {
      const { page, perPage, ...rest } = dataFilter;
      const playerId = (player as any).data[0].id;
      const params: Record<string, any> = {};
      for (const key in rest) {
        if (Object.prototype.hasOwnProperty.call(rest, key)) {
          if (
            rest[key] !== undefined &&
            rest[key] !== null &&
            rest[key] !== ""
          ) {
            params[key] = rest[key];
          }
        }
      }
      if (playerId) {
        params.playerId = playerId;
      }
      return await getBingoReportForGameActivity(token, params);
    }
  };

  return (
    <div className="container-fluid">
      <Row
        style={{
          marginTop: "12px",
          pointerEvents: player && player.data.length > 0 ? "all" : "none",
          opacity: player && player.data.length > 0 ? 1 : 0.4,
        }}
      >
        <Filter
          fields={FilterFields}
          cleanFilter={cleanFilter}
          handleFilter={handleFilter}
        />
      </Row>
      {data && data.data.length > 0 && data.data[0].status ? (
        <>
          <MyCustomTable
            columns={[
              {
                align: "center",
                label: t("table.date"),
              },
              {
                align: "center",
                label: t("bets.internalId"),
              },
              {
                align: "center",
                label: t("bets.betAmount"),
              },
              {
                align: "center",
                label: t("bets.winningAmount"),
              },
              {
                align: "center",
                label: t("bets.round"),
              },
              {
                align: "center",
                label: t("tabs.status"),
              },
            ]}
            data={data.data}
            lastPage={data.last_page}
            totalData={data.total}
            renderBody={(item) => <RowDataTableBingo row={item} />}
            onPageChange={handlePageChange}
            exportData
            pdfComponent={BingoGameActivityPdf}
            hookBuildTable={BingoGameActivityDataTable}
            getAllDataToExport={getBingoGameActivityAction}
            titleCSV={`${t("tabs.bingo")
              .split(" ")
              .join("-")}-${moment().toISOString()}`}
          />
        </>
      ) : (
        <Grid item xs={12} pt={3} mt={-2}>
          <Stack
            sx={{ width: "100%" }}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
            spacing={2}
          >
            <Alert
              icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
              sx={{
                borderLeft: `7px solid #2C97DF`,
                backgroundColor: "#EAF5FC",
                color: "#4b4b4b",
              }}
            >
              <div className="d-flex">
                <AlertTitle
                  style={{ marginTop: "5px" }}
                  sx={{ color: "#333333" }}
                >
                  {t("alerts.clickButton")}
                </AlertTitle>
                <AlertTitle
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  sx={{ color: "#333333", fontWeight: "bold" }}
                >
                  {t("alerts.refillBets")}
                </AlertTitle>
              </div>
            </Alert>
          </Stack>
        </Grid>
      )}
    </div>
  );
};
