import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PointSaleSelect } from "../../../custom/PointSaleSelect";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import { arraySearch } from "../../../../../redux/actions/searchActions";
import { setActivePointSale } from "../../../../../redux/actions/pointsSaleActions";
import {
  IQueryIrrigatedCards,
  IrrigationEntry,
} from "../../../../../interfaces/cards";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../../redux/actions/helperActions";
import {
  setIrrigatedDetail,
  startGetCardsIrrigated,
} from "../../../../../redux/actions/managementCardsActions";
import { DealerIrrigatedCardsDetail } from "../dealer/DealerIrrigatedCardsDetail";
import { useLocation } from "react-router-dom";
import {
  CurrentPlaceCards,
  DealerTypeEnum,
  Roles_Enum,
} from "../../../../../enums/enums";

interface Props {
  expand: boolean;
  setExpand: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PointSaleInfoCardsDealer: FC<Props> = ({ expand, setExpand }) => {
  const { user } = useSelector((state: AppState) => state.auth);
  const { irrigatedDetail, activeLot } = useSelector(
    (state: AppState) => state.managementCards
  );
  const [isSearch, setIsSearch] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const isDirectIrrigation =
    user?.roles.name === Roles_Enum.ASSOCIATE &&
    pathname.includes("irrigation-cards-pointSale");
  const initialValues = {
    pointSale: "",
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(setActivePointSale(null));

    return () => {
      dispatch(setActivePointSale(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsSearch(false);

    return () => {
      setIsSearch(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLot]);

  const handleGetDetailsPointSale = async (pointSale: string) => {
    if (!activeLot) {
      dispatch(setTypeAlert("warning"));
      dispatch(setMessageAlert("irrigation.selectedLot"));
      setLoading(false);
      return dispatch(setOpenAlert());
    }
    const { lotId } = activeLot as IrrigationEntry;
    const searchParams: Partial<IQueryIrrigatedCards> = {
      lot: lotId as number,
      irrigator: parseInt(pointSale),
    };
    setLoading(false);

    if (isDirectIrrigation) {
      searchParams.pref = CurrentPlaceCards.POINT_SALE;
    }
    await dispatch(startGetCardsIrrigated(searchParams));
  };
  const resetIrrigationHistory = () => {
    dispatch(setActivePointSale(null));
    dispatch(arraySearch(null));
  };

  return (
    <Box className="filter" py={1} sx={{ minHeight: expand ? "200px" : "" }}>
      <Box display="flex" justifyContent={"space-between"}>
        <Box display="flex" alignItems="center">
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <Typography
            color="text.primary"
            style={{ fontSize: "16px", fontWeight: 500 }}
          >
            {t("pointsOfSale.pointSaleInformation")}
          </Typography>
        </Box>
        <IconButton
          size="small"
          style={{ border: "none", outline: "none" }}
          onClick={() => setExpand((prev) => !prev)}
        >
          {expand ? (
            <i className="fa fa-angle-up" />
          ) : (
            <i className="fa fa-angle-down" />
          )}
        </IconButton>
      </Box>
      {expand && (
        <Box width={1} borderTop={1} borderColor={"#02D0E7"} mt={1}>
          <Formik
            initialValues={initialValues}
            onSubmit={async (data, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              if (isSearch) {
                setLoading(true);
                await handleGetDetailsPointSale(data.pointSale);
              } else {
                dispatch(setIrrigatedDetail(null));
                resetForm();
              }
              setSubmitting(false);
            }}
          >
            {({ handleChange, values }) => (
              <Form>
                <Grid container spacing={1} my={2}>
                  <Grid item xs={8}>
                    <PointSaleSelect
                      onChange={(e) => {
                        handleChange(e);
                        resetIrrigationHistory();
                      }}
                      onlyDirect={isDirectIrrigation}
                      type={DealerTypeEnum.CARDS}
                      showNestedPoints
                    />
                  </Grid>

                  <Grid item xs={4} sx={{ height: "60px" }} mb={2}>
                    <Button
                      type="submit"
                      style={{
                        border: "none",
                        outline: "none",
                        color: "white",
                        opacity:
                          values.pointSale === "" || loading ? "0.5" : "",
                      }}
                      variant="contained"
                      id="btnSave"
                      disabled={loading || values.pointSale === ""}
                      className="primary-btn ripple"
                      onClick={() => setIsSearch((prev) => !prev)}
                    >
                      {loading ? (
                        <CircularProgress size={"1.6rem"} color="inherit" />
                      ) : (
                        <>
                          {isSearch
                            ? t("filters.clean").toUpperCase()
                            : t("search.title").toUpperCase()}
                        </>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          {irrigatedDetail && <DealerIrrigatedCardsDetail />}
        </Box>
      )}
    </Box>
  );
};
