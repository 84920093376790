import { FC, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";
import { regIcons } from "../../utils/utils";
interface Props {
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}
export const CustomSearch: FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();
  return (
    <div className="input-container">
      <Input
        placeholder={t("search.placeholder")}
        type="text"
        onChange={onChange}
        className="input-search"
        maxLength={100}
        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
          !regIcons(e.key) && e.preventDefault()
        }
      />
      <button
        aria-label="Close"
        className="close input-icon"
        data-dismiss="modal"
        type="button"
      >
        <i className="fa-solid fa-magnifying-glass"></i>
      </button>
    </div>
  );
};
