import {
  Card,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  Box,
} from "@mui/material";
import React, { FC } from "react";
import { CardBody } from "reactstrap";
import "./rankingCards.scss";
interface RankingItem {
  id: number;
  name: string;
  value?: string;
}

interface Props {
  title: string;
  list: RankingItem[];

  content?: JSX.Element;
  cols?: string[];
}
export const RankingCardsStatistics: FC<Props> = ({
  list,
  title,
  content,
  cols,
}) => {
  return (
    <>
      <Card className="card-ranking-container">
        <CardBody>
          <Grid item xs={12} display="flex" justifyContent={"center"}>
            <Typography
              fontSize={{ lg: 14, md: 14, sm: 14, xs: 12 }}
              className="card-ranking-title mb-2"
            >
              {title}
            </Typography>
          </Grid>
          {cols ? (
            <Grid container>
              {cols.map((name) => (
                <Grid
                  key={name}
                  item
                  xs={Math.round(12 / cols.length)}
                  textAlign="center"
                  mt={2}
                  mb={2}
                >
                  <p className="subtitle-list">{name}</p>
                </Grid>
              ))}
              <Grid item xs={12}>
                <List>
                  {list.map((item, index) => (
                    <ListItem
                      key={item.name + "_" + item.id}
                      className="mb-3"
                      sx={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderStyle: "solid",
                        borderBottomWidth: "1px",
                        borderBottomColor: "#747474",
                        //paddingBottom: "3px",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid xs={6} display="flex">
                          <ListItemIcon>
                            <span className="identifier-ranking">
                              <Typography
                                fontSize={{ lg: 10, md: 16, sm: 10, xs: 12 }}
                              >
                                {index + 1}
                              </Typography>
                              {/*  <p>{index + 1}</p> */}
                            </span>
                          </ListItemIcon>
                          <p className="text-list">{item.name}</p>
                          {/* <ListItemText className="text-list" primary={item.name} /> */}
                        </Grid>
                        <Grid xs={6} textAlign="center">
                          <p className="text-list">{item.value}</p>
                          {/* <ListItemText className="text-list" primary={item.name} /> */}
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          ) : (
            <>
              {content && list.length <= 0 ? (
                <Box
                  display={"flex"}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  {content}
                </Box>
              ) : (
                <List>
                  {list.map((item, index) => (
                    <ListItem
                      key={item.name + "_" + item.id}
                      sx={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderStyle: "solid",
                        borderBottomWidth: "1px",
                        borderBottomColor: "#747474",
                        paddingBottom: "3px",
                      }}
                    >
                      <span className="identifier-ranking mr-1">
                        <Typography
                          fontSize={{ lg: 10, md: 10, sm: 10, xs: 10 }}
                        >
                          {index + 1}
                        </Typography>
                      </span>

                      <Typography
                        fontSize={{ lg: 12, md: 12, sm: 11, xs: 11 }}
                        className="text-list"
                      >
                        {item.name}
                      </Typography>
                      {/* <p className="text-list">{item.name}</p> */}
                      {/* <ListItemText className="text-list" primary={item.name} /> */}
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          )}

          {/* <Typography
          fontSize={{ lg: 12, md: 11.5, sm: 11, xs: 11 }}
          className="card-category"
        ></Typography> */}
        </CardBody>
      </Card>
    </>
  );
};
