import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IExpand } from "./MyAccountSettings";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { getUserById } from "../../../services/managementUsers";
import { State } from "../../../enums/enums";

interface Props {
  expand: IExpand;
  setExpand: React.Dispatch<React.SetStateAction<IExpand>>;
}
export const UserInformation = ({ expand, setExpand }: Props) => {
  const { t } = useTranslation();
  const { user, token } = useSelector((state: AppState) => state.auth);
  const [stateUser, setStateUser] = useState();

  useEffect(() => {
    const fetchUser = async () => {
      if (token && user) {
        const getUser = await getUserById(token, user.id);

        getUser && setStateUser(getUser.stateUser);
      }
    };
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid
        item
        xs={12}
        ml={2}
        className={
          expand.userInfo ? "box-account-active" : "box-account cursor"
        }
        onClick={() =>
          setExpand({
            userInfo: !expand.userInfo,
            changeUser: false,
            changePassword: false,
          })
        }
      >
        <Box display={"flex"} alignItems="center" ml={2}>
          <Typography color="text.primary" style={{ fontSize: "16px" }}>
            {t("account.userInformation")}
          </Typography>
        </Box>
        <IconButton
          size="small"
          style={{ border: "none", outline: "none" }}
          onClick={() =>
            setExpand({
              userInfo: !expand.userInfo,
              changeUser: false,
              changePassword: false,
            })
          }
        >
          {expand.userInfo ? (
            <i className="fa fa-angle-up" />
          ) : (
            <i className="fa fa-angle-down" />
          )}
        </IconButton>
      </Grid>

      {expand.userInfo && (
        <Grid
          container
          ml={2}
          mt={1}
          className="box-account"
          style={{ cursor: "default !important" }}
        >
          <Grid item xs={3}>
            <Typography
              align="center"
              color="text.primary"
              style={{ fontWeight: 600 }}
            >
              {t("account.userLogin")}
            </Typography>
            <Typography mt={1} align="center" color="text.primary">
              {user?.username || ""}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              align="center"
              color="text.primary"
              style={{ fontWeight: 600 }}
            >
              {t("account.userName")}
            </Typography>
            <Typography
              mt={1}
              align="center"
              color="text.primary"
              style={{ textTransform: "capitalize" }}
            >
              {user?.nameComplete || ""}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              align="center"
              color="text.primary"
              style={{ fontWeight: 600 }}
            >
              {t("account.userStatus")}
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box
                style={{
                  borderRadius: "50%",
                  width: "17px",
                  height: "17px",
                  // @ts-ignore
                  backgroundColor:
                    stateUser === State.ACTIVE
                      ? "#007E8C"
                      : stateUser === State.INACTIVE
                      ? "#FF5757"
                      : "#ebebeb",
                  marginRight: "10px",
                }}
              ></Box>{" "}
              <Typography mt={1} align="center" color="text.primary">
                {State.ACTIVE === stateUser && <>{t("state.active")}</>}
                {State.INACTIVE === stateUser && <>{t("state.inactive")}</>}
                {State.RETIRED === stateUser && <>{t("state.retired")}</>}
                {State.RESTRICTED === stateUser && <>{t("state.restricted")}</>}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Typography
              align="center"
              color="text.primary"
              style={{ fontWeight: 600 }}
            >
              {t("account.userRole")}
            </Typography>
            <Typography mt={1} align="center" color="text.primary">
              {t(`roles.${user?.roles.name}`) || ""}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
