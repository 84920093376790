import React, { FC } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogActions,
  Button,
} from "@mui/material";

interface props {
  content: JSX.Element;
  title: string;
  open: boolean;
  onClose(): void;
  width?: "lg" | "md" | "sm" | "xl" | "xs";
  icon?: JSX.Element;
  okButton?: boolean;
}
export const MyCustomDialog: FC<props> = ({
  content,
  onClose,
  open,
  title,
  width = "lg",
  icon,
  okButton,
}) => {
  return (
    <Dialog
      open={open}
      maxWidth={width}
      fullWidth
      disableEscapeKeyDown
      scroll="body"
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 0,
          marginBottom: -20,
        }}
      >
        <Box display="flex" alignItems={"center"}>
          {icon ? (
            icon
          ) : (
            <i
              className="fa-solid fa-user-plus"
              style={{ color: "#02D0E7", marginRight: 9, fontSize: 20 }}
            ></i>
          )}
          <p
            className="txt-primary"
            style={{ fontWeight: "bold", fontSize: 17 }}
          >
            {title}
          </p>
        </Box>
        <IconButton
          disableFocusRipple
          disableRipple
          style={{ border: "none", outline: "none", marginBottom: 5 }}
          onClick={onClose}
          className="hover-close"
          sx={{
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent",
            },
          }}
        >
          <i className="fa-solid fa-xmark"></i>
        </IconButton>
      </DialogTitle>
      <Box borderTop={1} borderColor="#02D0E7" m={2}>
        <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
          {content}
        </DialogContent>
      </Box>

      {okButton && (
        <Box borderTop={1} borderColor="#02D0E7" mx={2}>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: 0,
              marginTop: 1,
            }}
          >
            <Button
              style={{
                border: "none",
                outline: "none",
                marginRight: "0px !important",
                marginBottom: 15,
                width: "15px !important",
              }}
              variant="contained"
              className="footer-btn primary-btn ripple"
              onClick={onClose}
            >
              <i className="fa-solid fa-check-circle"></i>
              OK
            </Button>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  );
};
