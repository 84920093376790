import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Box, IconButton } from "@mui/material";
import MyBreadCrumbs from "../../../../components/Custom/MyBreadCrumbs";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { FormCreateCommercialNetwork } from "../../../components/masters/networks/FormCreateCommercialNetwork";
import {
  asyncLoadingFunction,
  setFlagForm,
} from "../../../../redux/actions/helperActions";
import { startGetExternalUsers } from "../../../../redux/actions/commercialNetworkActions";
import MyCustomTable, {
  IColumns,
} from "../../../../components/Custom/Table/MyCustomTable";
import { RowDataTableCommercialNetworks } from "../../../components/masters/networks/RowDataTableCommercialNetworks";
import {
  IQueryParams,
  getExternalUsers,
} from "../../../../services/commercialNetworks";
import { Enum_FieldType, IFilterField } from "../../../../interfaces/components";
import { IExternalUsersData } from "../../../../interfaces/commercialNetworks";
import { CommercialNetworkDataTable } from "../../../../hooks/useDataTable/commercialNetworks/CommercialNetworksDataTable";
import { CommercialNetworksPdf } from "../../../../components/Pdf/commercialNetworks/CommercialNetworksPdf";
import Filter from "../../../../components/Filter/Filter";
import { arraySearch } from "../../../../redux/actions/searchActions";

export const CommercialNetworks = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { data } = useSelector((state: AppState) => state.search);
  const { pathname } = useLocation();
  const { flagFormCreate } = useSelector((state: AppState) => state.helper);
  const { networks } = useSelector((state: AppState) => state.commercialNetworks);
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);
  const [dataFilter, setDataFilter] = useState<any>({});
  const [, setFilter] = useState(false);
  const [resetPage, setResetPage] = useState(false);
  const [externalUsers, setExternalUsers] = useState<Array<IExternalUsersData>>(
    []
  );
  const totalCommercial = data ?? networks;

  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;
  const enableEdit =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.updated === 1;

  const handleOpenForm = () => {
    dispatch(setFlagForm({ flagFormCreate: true }));
  };

  useEffect(() => {
    dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startGetExternalUsers({ operatorId: user?.operator?.id, page: 1 })
        )
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterFields: IFilterField[] = [
    {
      label: t("commercialNetworks.commercialNetwork"),
      type: Enum_FieldType.SELECT,
      options: [
        ...externalUsers.map((network) => ({
          label: network.name,
          value: network.id,
        })),
      ],
    },
  ];

  let columns: IColumns[] = [
    { align: "left", label: t("commercialNetworks.commercialNetwork") },
    { align: "left", label: t("companyManagement.nit") },
    { align: "left", label: t("companyManagement.legalRepresentative") },
    { align: "left", label: t("user.phone") },
    { align: "left", label: t("user.email") },
    { align: "left", label: t("user.createAt") },
    // {align: "left", label: t("table.actions")},
  ];
  if (enableEdit) {
    columns = [
      ...columns,
      {
        align: "center",
        label: t("table.actions"),
      },
    ];
  }

  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(
        async () =>
          await dispatch(
            startGetExternalUsers({
              ...dataFilter,
              page,
              perPage: rows || rowsPerPage,
            })
          )
      )
    );
    setResetPage(false);
  };

  const handleFilter = async (values: any) => {
    setResetPage(false);
    let params: Partial<IQueryParams> = {
      page: 1,
      operatorId: user?.operator?.id.toString(),
      network: values[t("commercialNetworks.commercialNetwork")] ?? "",
    };

    dispatch(
      asyncLoadingFunction(
        async () => await dispatch(startGetExternalUsers(params, true))
      )
    );

    setDataFilter(params);
    setFilter(true);
    setResetPage(true);
  };

  useEffect(() => {
    if (networks?.data) {
      setExternalUsers(networks?.data);
    }
  }, [networks]);

  const getCommercialNetworks = async (props: any) => {
    if (token) {
      const params: Partial<IQueryParams> = {
        ...props,
      };
      return await getExternalUsers(params);
    }
  };

  const onCleanFilter = async () => {
    setResetPage(false);
    dispatch(arraySearch(null));
    dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startGetExternalUsers({ page: 1, operatorId: user?.operator?.id })
        )
      )
    );
    setDataFilter({});
    setFilter(false);
    setResetPage(true);
  };

  return (
    <div className="content w-100 mb-4">
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Row>
            <Col xs={12}>
              <MyBreadCrumbs
                titles={[
                  t("sidebar.masters"),
                  t("shorcuts.commercialNetworks"),
                ]}
              ></MyBreadCrumbs>
            </Col>{" "}
          </Row>
        </Col>
      </Row>

      {flagFormCreate ? (
        <FormCreateCommercialNetwork />
      ) : (
        <div className="white-container mt-2">
          <>
            {enableCreated && (
              <Row>
                <Col xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    mb={2}
                  >
                    <Box display="flex" alignItems="center">
                      <p
                        className="txt-primary-md"
                        style={{
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          alignSelf: "center",
                        }}
                      >
                        {t("button.createCommercialNetwork")}
                      </p>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={handleOpenForm}
                        style={{ border: "none", outline: "none" }}
                        id="openFormBtn"
                      >
                        <span className="btn-add">
                          <i
                            className="fa-solid fa-plus"
                            style={{ fontSize: 30 }}
                          ></i>
                        </span>
                      </IconButton>
                    </Box>
                  </Box>
                </Col>
              </Row>
            )}

            <Row>
              <Box display="flex" mb={2} width={1}>
                <Filter
                  fields={filterFields}
                  handleFilter={handleFilter}
                  onClean={onCleanFilter}
                />
              </Box>
            </Row>

            {totalCommercial && totalCommercial.data.length > 0 && (
              <MyCustomTable
                columns={columns}
                data={totalCommercial.data}
                lastPage={totalCommercial.last_page}
                totalData={totalCommercial.total}
                renderBody={(item) => (
                  <RowDataTableCommercialNetworks row={item} />
                )}
                onPageChange={handlePageChange}
                resetCurrentPage={resetPage}
                exportData
                hookBuildTable={CommercialNetworkDataTable}
                getAllDataToExport={getCommercialNetworks}
                pdfComponent={CommercialNetworksPdf}
                titleCSV={t("table.neatCommercialNetworks")}
              />
            )}
          </>
        </div>
      )}
    </div>
  );
};
