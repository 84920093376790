import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { useForm } from "../../../hooks/useForm";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  setCountries,
  setGames,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { theme } from "../custom/customStyles";
import { getParamGameByCountry } from "../../../services/managementParametersService";
import { ICountryGamesDb } from "../../../interfaces/params";
import {
  addParamGames,
  setActiveParamGames,
  updateParamGames,
} from "../../../redux/actions/managementParametersActions";
import { MyDialog } from "../custom/MyDialog";
import { Roles_Enum, State } from "../../../enums/enums";
import { getOperatorsService } from "../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../redux/actions/managementCompanyActions";

const ITEM_HEIGHT = 42;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const FormGamesTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token, user } = useSelector((state: AppState) => state.auth);
  const [initialParam, setInitialParam] = useState<ICountryGamesDb[]>([]);

  const { countries, games } = useSelector((state: AppState) => state.helper);
  const { activeParamGames: active } = useSelector(
    (state: AppState) => state.managementParameters
  );
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const [searchText, setSearchText] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const gamesOptions = useMemo(() => (games ? games : []), [games]);

  const initialFormValues = {
    country: active ? active.country.id : 0,
    games: active ? active.games.map((game) => game.name) : [],
    operator: active
      ? active.operator.id
      : user?.roles.name !== Roles_Enum.SUPER_ADMIN
      ? user?.operator.id
      : 0,
  };

  let [formValues, handleInputChange] = useForm({
    ...initialFormValues,
  });
  const getParam = async (token: string, country: number) => {
    try {
      const operatorId =
        user && user.operator ? user.operator.id : formValues.operator;
      const response = await getParamGameByCountry(token, country, operatorId);
      const { data } = response;
      setInitialParam(data);
    } catch (error) {}
  };
  useEffect(() => {
    if (token) {
      dispatch(setCountries(token));
      dispatch(setGames(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (token) {
      getParam(token, formValues.country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.country, formValues.operator]);
  useEffect(() => {
    handleInputChange({
      target: {
        name: "games",
        value:
          formValues.operator === 0
            ? []
            : initialParam.length > 0
            ? initialParam
                .filter((method) => method.state === 1)
                .map((item) => item.games.id)
            : [],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialParam, formValues.operator]);

  const displayedOptions = useMemo(
    () =>
      gamesOptions.filter((option) =>
        option.name.toLowerCase().includes(searchText.toLowerCase())
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchText, gamesOptions]
  );
  const validateEmptyGames = (games: any[]) => {
    return games.length > 0;
  };
  const handleCreateGames = async () => {
    setIsSubmit(true);

    if (
      formValues.country === 0 ||
      (user?.roles.name === Roles_Enum.SUPER_ADMIN &&
        formValues.operator === 0) ||
      !validateEmptyGames(formValues.games)
    ) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.fillInRequiredFields"));
      dispatch(setTypeAlert("error"));
    } else {
      if (!!active) {
        setOpenDialog(true);
      } else {
        handleAcceptCreate();
      }
    }
  };

  const handleAcceptCreate = async () => {
    setIsLoading(true);
    setOpenDialog(false);
    if (initialParam.length > 0) {
      await dispatch(updateParamGames(formValues, initialParam));
    } else {
      if (
        formValues.country !== 0 &&
        formValues.games.length > 0 &&
        formValues.operator !== 0
      ) {
        await dispatch(addParamGames(formValues));
      }
    }
    setIsLoading(false);
    setIsSubmit(false);
  };

  const handleCancelCreate = () => {
    setOpenDialog(false);
    setIsSubmit(false);
  };

  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    token && fetchOperators(token);
    return () => {
      dispatch(setActiveParamGames(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2}>
        {
          //MOSTRAR SELECT CON OPERADORES CUANDO SEA SUPER_ADMIN
          user?.roles.name === Roles_Enum.SUPER_ADMIN && !active && (
            <>
              {companies && (
                <Grid item xs={4}>
                  <label className="ml-3">
                    {t("companyManagement.operator") + "*"}
                  </label>
                  <FormControl
                    sx={{ width: "100%" }}
                    size="small"
                    disabled={!!active}
                    error={
                      isSubmit &&
                      user.roles.name === Roles_Enum.SUPER_ADMIN &&
                      formValues.operator === 0
                    }
                  >
                    <Select
                      name="operator"
                      size="small"
                      fullWidth
                      onChange={handleInputChange}
                      value={
                        formValues.operator === 0
                          ? ""
                          : formValues.operator || ""
                      }
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                    >
                      {companies?.data?.map((company) => (
                        <MenuItem key={company.id} value={company.id}>
                          {company.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </>
          )
        }

        <Grid item xs={4}>
          <label className="ml-2">
            {t("companyManagement.selectCountry")}*
          </label>
          <FormControl
            sx={{ width: 1 }}
            size="small"
            error={isSubmit && formValues.country === 0}
          >
            <InputLabel id="country-label">{t("user.country")}</InputLabel>
            <Select
              labelId="country-label"
              label={t("user.country")}
              name="country"
              fullWidth
              onChange={handleInputChange}
              value={formValues.country === 0 ? "" : formValues.country || ""}
              input={<OutlinedInput label={t("user.country")} />}
              disabled={!!active || formValues.operator === 0}
            >
              {countries?.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  <img
                    loading="lazy"
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                    src={country.imgUrl}
                    alt={country.name}
                  />{" "}
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <label className="ml-2">{t("parameterization.selectGames")}*</label>
          <FormControl
            sx={{ width: 1 }}
            size="small"
            error={isSubmit && !validateEmptyGames(formValues.games)}
          >
            <InputLabel id="game-label">
              {t("parameterization.game")}
            </InputLabel>
            <Select
              labelId="game-label"
              label={t("parameterization.game")}
              name="games"
              multiple
              value={formValues.games || ""}
              onChange={handleInputChange}
              input={<OutlinedInput label={t("parameterization.game")} />}
              renderValue={(selected) =>
                gamesOptions
                  .filter((game) => selected.includes(game.id))
                  .map((game) => game.name)
                  .join(" - ")
              }
              MenuProps={MenuProps}
              onClose={() => setSearchText("")}
            >
              <ListSubheader>
                <TextField
                  size="small"
                  autoFocus
                  fullWidth
                  InputLabelProps={{ shrink: false }}
                  label={" "}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      e.stopPropagation();
                    }
                  }}
                  
                />
              </ListSubheader>
              {displayedOptions.map((game) => (
                <MenuItem key={game.id} value={game.id}>
                  <Checkbox
                    checked={
                      !!formValues.games.find((item: any) => item === game.id)
                    }
                    sx={{
                      "&.Mui-checked": {
                        color: "#04BF9D",
                      },
                    }}
                  />
                  <ListItemText primary={game.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="flex-end" mt={20}>
          {isLoading ? (
            <CircularProgress className={"colorLoading"} />
          ) : (
            <Button
              style={{
                border: "none",
                outline: "none",
                fontSize: 14,
              }}
              variant="contained"
              className="btn-dialog primary-btn"
              onClick={handleCreateGames}
            >
              {t(
                !!active || initialParam.length > 0
                  ? "button.editParameters"
                  : "button.createParameters"
              )}
              <i className="fa-solid fa-check-circle ml-2"></i>
            </Button>
          )}
        </Grid>

        <MyDialog
          open={openDialog}
          title={t("dialog.confirmationWindow")}
          message={t("dialog.sureEdit")}
          handleAccept={handleAcceptCreate}
          handleClose={handleCancelCreate}
        />
      </Grid>
    </ThemeProvider>
  );
};
