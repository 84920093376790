import { IMenuList } from "../../interfaces/menu";
import { IGenericData } from "../../interfaces/params";
import { IActionsPermissions } from "../../interfaces/roles";
import { IUserDealer } from "../interfaces/users";
import { Types } from "../types/types";

interface IRoles {
  id: number;
  name: string;
  notification?: number;
  createAt: string;
  updateAt: string;
}
interface IAssociatesUsers {
  id: number;
  associate: IAssociateData;
}
interface IAssociateData {
  id: number;
  name: string;
  commissionPercentageRefills: string;
  commissionPercentageWithdrawals: string;
  commissionPercentageGGR: string;
  commissionPercentagePrizes: string;
  commissionPercentageAffiliations: string;
}
export interface UserBox extends IGenericData {
  pointSale: IGenericData;
}
interface IUserData {
  id: number;
  nameComplete: string;
  numberDocument: string;
  expeditionDate: string;
  phone: string;
  address: string;
  username: string;
  email: string;
  verificationTwoStep: number;
  observation: string;
  state: number;
  createAt: string;
  updateAt: string;
  roles: IRoles;
  operator: IOperator;
  actionsPermissions: IActionsPermissions[];
  menuAccess: IMenuList[];
  associatesUsers: IAssociatesUsers[];
  userDealer: IUserDealer[];
  box: UserBox[];
  dealer: { id: number; name: string; type: number };
  promotor?: IGenericData;
}

export interface IUserLoggedIn {
  user: IUserData;
  token: string;
}
export interface IOperator {
  id: number;
  name: string;
  description?: string;
  address?: string;
  createAt?: string;
  updateAt?: string;
  loginAttempts: number;
  withdrawalPercentage: string;
}

interface IAuthState {
  user: IUserData | null;
  token: string | null;
}

interface IAuthActions {
  type: Types;
  payload?: IUserLoggedIn | null;
}

const initialState: IAuthState = {
  user: null,
  token: null,
};

export const authReducer = (
  state = initialState,
  action: IAuthActions
): IAuthState => {
  const { type, payload } = action;
  switch (type) {
    case Types.login:
      return {
        user: (payload as IUserLoggedIn).user,
        token: (payload as IUserLoggedIn).token,
      };

    case Types.logout:
      return initialState;

    default:
      return state;
  }
};
