import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { useLocation } from "react-router";
import {
  asyncLoadingFunction,
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import {
  setActiveBonus,
  setOpenBonusDetails,
  //starGetBonus,
  startFilterBonus,
  startUpdateBonus,
} from "../../../../redux/actions/bonusActions";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import { State } from "../../../../enums/enums";
import { IBonus, IQueryFilterBonus } from "../../../../interfaces/bonus";
import { Box, Grid, IconButton } from "@mui/material";
import Filter from "../../../../components/Filter/Filter";
import MyCustomTable from "../../../../components/Custom/Table/MyCustomTable";

import { BonusDeliverDetails } from "../../../pages/Bonus/tabs/BonusDeliverDetails";
import { RowMasiveBonus } from "./RowMasiveBonus";
import { FormCreateMasiveBonus } from "./FormCreateMasiveBonus";
import { MyCustomDialog } from "../../../../components/Custom/MyCustomDialog";

export const MassiveBonus = () => {
  const { t } = useTranslation();
  const { flagFormCreate } = useSelector((state: AppState) => state.helper);

  const { rowsPerPage } = useSelector((state: AppState) => state.helper);

  const { bonus, openDetails } = useSelector((state: AppState) => state.bonus);

  const [dataFilter, setDataFilter] = useState<any>({});
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { user } = useSelector((state: AppState) => state.auth);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFiltering, setIsFiltering] = useState(false);

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);

  const fetchBonus = async () => {
    //await dispatch(starGetBonus());
    if (user) {
      let params: any = {
        page: currentPage,
        perPage: rowsPerPage,
        massive: true,
      };
      await dispatch(
        asyncLoadingFunction(() =>
          dispatch(
            startFilterBonus({
              ...params,
            })
          )
        )
      );
    }
  };
  useEffect(() => {
    fetchBonus();
    return () => {
      dispatch(setFlagForm({ flagFormCreate: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!flagFormCreate) {
      fetchBonus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagFormCreate]);

  useEffect(() => {
    if (bonus && bonus.data.length === 0) {
      dispatch(
        setMessageAlert(isFiltering ? "alerts.noData" : "alerts.noRecords")
      );
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bonus]);

  const FilterFileds: IFilterField[] = [
    {
      label: t("managementBonds.bonusCode"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("managementBonds.bondName"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("menu.users.creator"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("form.state"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("state.active"),
          value: State.ACTIVE,
        },
        {
          label: t("state.inactive"),
          value: State.INACTIVE,
        },
      ],
    },
  ];

  const handleShowForm = () => {
    dispatch(setFlagForm({ flagFormCreate: true }));
  };
  const handlePageChange = async (page: number, rows?: number) => {
    setCurrentPage(page);
    await dispatch(
      startFilterBonus({
        page: page,
        perPage: rows || rowsPerPage,
        ...dataFilter,
        massive: true
      })
    );
  };
  const handleFilter = async (values: any) => {
    setIsFiltering(true);
    const params: IQueryFilterBonus = {
      state: values[t("form.state")].toString(),
      code: values[t("managementBonds.bonusCode")].toString(),
      name: values[t("managementBonds.bondName")],
      createdUser: values[t("menu.users.creator")],
    };
    await dispatch(
      asyncLoadingFunction(() =>
        dispatch(startFilterBonus({ ...params, page: 1, massive: true }))
      )
    );
    setDataFilter({ ...params });
  };
  const onCleanFilter = async () => {
    dispatch(
      asyncLoadingFunction(() =>
        dispatch(startFilterBonus({ page: 1, massive: true }))
      )
    );
    setDataFilter({});
    setIsFiltering(false);
  };

  const handleCloseDetails = () => {
    dispatch(setActiveBonus(null));
    dispatch(setOpenBonusDetails(false));
  };
  const handleEnableBonus = async (row: IBonus) => {
    dispatch(setActiveBonus(row));
    const updateObj = { ...row };
    //@ts-ignore
    delete updateObj.totalBonusDelivered;
    const finalState =
      row.state === State.ACTIVE ? State.INACTIVE : State.ACTIVE;

    await dispatch(
      startUpdateBonus({
        ...updateObj,
        state: finalState,
      })
    );
    await dispatch(
      startFilterBonus({
        page: currentPage,
        perPage: rowsPerPage,
        ...dataFilter,
        massive: true,
      })
    );
    dispatch(setActiveBonus(null));
  };

  return (
    <>
      {flagFormCreate ? (
        <FormCreateMasiveBonus />
      ) : (
        <Grid container spacing={2}>
          {modulePermissions &&
            modulePermissions.permissions &&
            modulePermissions.permissions.created === 1 && (
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  mb={2}
                >
                  <Box display="flex" alignItems="center">
                    <p
                      className="txt-primary-md"
                      style={{
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        alignSelf: "center",
                      }}
                    >
                      {t(
                        "managementBonds.createMasiveBonus"
                      ).toLocaleUpperCase()}
                    </p>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={handleShowForm}
                      id="openFormBtn"
                      data-testid="openFormBtn"
                      style={{ border: "none", outline: "none" }}
                    >
                      <span className="btn-add">
                        <i
                          className="fa-solid fa-plus"
                          style={{ fontSize: 30 }}
                        ></i>
                      </span>
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            )}

          <Grid item xs={12}>
            <Filter
              fields={FilterFileds}
              handleFilter={handleFilter}
              onClean={onCleanFilter}
            />
          </Grid>
          <Grid item xs={12}>
            <MyCustomTable
              columns={[
                {
                  align: "center",
                  label: t("managementBonds.bonusCode"),
                },
                {
                  align: "center",
                  label: t("managementBonds.bondName"),
                },
                {
                  align: "center",
                  label: t("table.creationDate"),
                },

                {
                  align: "center",
                  label: t("managementBonds.amountDeliveredBonus"),
                },
                {
                  align: "center",
                  label: t("table.state"),
                },
                {
                  align: "center",
                  label: '',
                },
              ]}
              actions={{
                see: false,
              }}
              data={bonus?.data || []}
              lastPage={bonus?.last_page || 1}
              totalData={bonus?.total || 0}
              renderBody={(item) => <RowMasiveBonus row={item} />}
              onPageChange={handlePageChange}
              onSee={handleEnableBonus}
            />
          </Grid>

          <MyCustomDialog
            open={openDetails}
            content={<BonusDeliverDetails />}
            onClose={handleCloseDetails}
            title={t("managementBonds.infoDeliveredBonus")}
          />
        </Grid>
      )}
    </>
  );
};
