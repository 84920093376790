import ReactDOM from "react-dom";

import { App } from "./App";
import "./i18n";

import "./assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "./assets/scss/black-dashboard-pro-react.scss?v=1.2.0";
import "./assets/demo/demo.css";

//@ts-ignore
window.process = {};
ReactDOM.render(<App />, document.getElementById("root"));
