import { Form, Formik } from "formik";
import { useEffect, useState, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Button,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  FormControl,
  CircularProgress,
  FormHelperText,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";

import { MyTextField } from "../custom/MyTextField";
import {
  setCloseAlert,
  setFlagForm,
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { ISlides } from "../../../interfaces/slides";
import { setActiveAd } from "../../../redux/actions/managementAdsActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { MyDialog } from "../custom/MyDialog";
import { Module, Roles_Enum } from "../../../enums/enums";
import { regLettersNumbers } from "../../../utils/utils";
import "./managementSlides.scss";
import { MyAlert } from "../custom/MyAlert";
import { startCreateSlide } from "../../../redux/actions/managementSlidesActions";
import { getSlidesByOperator } from "../../../services/slides";
import { urls } from "../../../const/const";

interface IFile {
  file: File | null;
  label: string;
}
interface Props {
  module: string;
}
export const FormCreateNewSlide = ({ module }: Props) => {
  const { t } = useTranslation();
  const { activeAd } = useSelector((state: AppState) => state.managementAds);
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { loading, openAlert, messageAlert, typeAlert } = useSelector(
    (state: AppState) => state.helper
  );
  const [fileLabel, setFileLabel] = useState(
    activeAd && activeAd.image.length > 0 && activeAd.imageName
      ? activeAd.imageName
      : ""
  );

  const [file, setFile] = useState<IFile>({
    file: null,
    label: activeAd ? fileLabel : "",
  });
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const [noValid, setNoValid] = useState(false);
  const [errorFileMessage, setErrorFileMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [cancel, setCancel] = useState(false);
  const [saveValues, setSaveValues] = useState<any>(null);
  const [errorName, setErrorName] = useState(false);
  const [operatorId, setOperatorId] = useState<number | null>(
    activeAd ? activeAd.operator.id : !isSuper && user ? user.operator.id : null
  );
  const [showOperatorError, setShowOperatorError] = useState(false);
  const [submit, setSubmit] = useState(false);
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const dispatch = useDispatch();
  const initialValues: Omit<ISlides, "id" | "order" | "url" | "sliders"> = {
    name: "",
    image: null,
    operator: user?.operator?.id,
    linkTo: "",
    module: "",
  };

  useEffect(() => {
    return () => {
      dispatch(setActiveAd(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = yup.object({
    name:
      module === Module.FOOTERITEMS
        ? yup.string()
        : yup.string().required(t("form.requiredFile")),
    linkTo:
      module === Module.BETTINGSHORTCUTSBACKGROUND ||
      module === Module.PAGEBACKGROUND ||
      module === Module.PAYMENTMETHODS ||
      module === Module.FOOTERITEMS ||
      module === Module.BINGO ||
      module === Module.VIRTUALSGOLDENRACE
        ? yup.string()
        : yup.string().required(t("form.requiredFile")),
  });

  const handleSelectFile = (e: any) => {
    const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
    const file = e.target.files[0] as File;
    if (file) {
      if (!SUPPORTED_FORMATS.includes(file.type)) {
        setErrorFileMessage(t("alerts.invalidFormat"));
        return setNoValid(true);
      }
      if (module === Module.MOBILE) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const image = new Image();
          image.onload = () => {
            if (image.width !== 320 || image.height !== 200) {
              setErrorFileMessage(
                "El tamaño de la imagen debe ser 320x200 píxeles."
              );
              return setNoValid(true);
            } else {
              setErrorFileMessage("");
              setNoValid(false);
              setFile({ file, label: e.target.files[0].name });
            }
          };
          image.src = event.target?.result as string;
        };
        reader.readAsDataURL(file);
      }
      setErrorFileMessage("");
      setNoValid(false);
      setFile({ file, label: e.target.files[0].name });
    } else {
      setFile({
        file: null,
        label: "",
      });
    }
  };
  // ARCHIVOS SOPORTADOS PARA INPUT TYPE=FILE
  const fileSupported = {
    accept: "image/jpeg, image/png",
  };
  const handleCloseForm = () => {
    dispatch(setFlagForm({ flagFormCreate: false }));
  };
  const handleSave = async (data: ISlides) => {
    await dispatch(startCreateSlide(data, module));
  };

  const handleDeleteFile = () => {
    setFile({ file: null, label: "" });
    setFileLabel("");
  };
  const handleCloseDialog = () => {
    setCancel(false);
    setOpenDialog(false);
  };

  const handleSelectOperator = (e: any) => {
    setOperatorId(e.target.value);
  };

  const handleCancel = () => {
    setMessage("dialog.sureExit");
    setCancel(true);
    setOpenDialog(true);
  };

  const handleAccept = async () => {
    handleCloseDialog();
    if (cancel) {
      handleCloseForm();
    } else {
      dispatch(setLoadingState({ loading: true }));
      //validation no duplicate name slider
      if (module === Module.FOOTERITEMS) {
        await handleSave(saveValues);
      } else {
        const response = await getSlideByName(saveValues.name);
        if (response && response.length > 0) {
          dispatch(setMessageAlert("managementAds.duplicateNameSlider"));
          dispatch(setTypeAlert("error"));
          dispatch(setOpenAlert());
          setErrorName(true);
        } else {
          await handleSave(saveValues);
        }
      }
      dispatch(setLoadingState({ loading: false }));
    }
  };

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
    if (typeAlert === "success") {
      dispatch(setFlagForm({ flagFormCreate: false }));
      dispatch(setActiveAd(null));
    }
  };

  const getSlideByName = async (name: string) => {
    if (!token || !user) {
      return false;
    }

    if (activeAd?.name.toLowerCase() === name.toLowerCase()) {
      return [];
    } else {
      const filterParams = {
        name,
        exact: true,
      };
      const resp = await getSlidesByOperator(
        token,
        user && !isSuper ? user.operator.id : operatorId!,
        filterParams
      );

      return resp;
    }
  };

  return (
    <Grid container spacing={2} pl={1} pr={1}>
      <Grid item xs={12} className="mb-2">
        <Box
          display="flex"
          className="pb-1"
          borderBottom={1}
          borderColor="#02D0E7"
        >
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#2C97DF", marginRight: 9, fontSize: 17 }}
          ></i>
          <p className="txt-primary-md">
            {t(
              activeAd ? "managementAds.editAds" : "managementAds.createSliders"
            )}
          </p>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          onSubmit={async (data, { setSubmitting }) => {
            setErrorName(false);
            if (operatorId && !noValid) {
              const formValues = { ...data };
              const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
              if (isSuper && !operatorId && !activeAd) {
                return setShowOperatorError(true);
              }

              setSaveValues({
                ...formValues,
                image: file.file ? file.file : null,
                imageName: file.label !== "" ? file.label : fileLabel,
                module: module,
              });
              setOpenDialog(true);
              setMessage(
                activeAd
                  ? t("managementAds.sureUpdateSlider")
                  : t("managementAds.sureCreateSlider")
              );
              setCancel(false);
              setSubmitting(false);
              setShowOperatorError(false);
            }
          }}
        >
          {({ handleChange }) => (
            <Form>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={6} sm={6} md={4}>
                  <MyTextField
                    name="name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e: any) => {
                      setErrorName(false);
                      handleChange(e);
                    }}
                    type="text"
                    label={
                      module === Module.FOOTERITEMS
                        ? "Url"
                        : `${t("managementAds.sliderName")}*`
                    }
                    inputProps={{
                      maxLength: 100,
                    }}
                    error={errorName}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regLettersNumbers(e.key) && e.preventDefault()
                    }
                    autoComplete="off"
                  />
                </Grid>
                {module === Module.BETTINGSHORTCUTSBACKGROUND ||
                module === Module.PAGEBACKGROUND ||
                module === Module.PAYMENTMETHODS ||
                module === Module.FOOTERITEMS ||
                module === Module.BINGO ||
                module === Module.VIRTUALSGOLDENRACE ? (
                  <></>
                ) : (
                  <Grid item xs={6} sm={6} md={4}>
                    <MyTextField
                      name="linkTo"
                      select
                      label={`${t("messages.buttonUrl")}*`}
                      onChange={handleChange}
                    >
                      {urls.map((item) => (
                        <MenuItem
                          key={item.value}
                          value={item.value}
                          sx={{
                            "&.Mui-focused": {
                              backgroundColor: "red",
                            },
                          }}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </MyTextField>
                  </Grid>
                )}
                <Grid item xs={6} sm={6} md={4}>
                  <TextField
                    size="small"
                    type="file"
                    inputProps={fileSupported}
                    onChange={handleSelectFile}
                    id="icon-button-file"
                    autoComplete="off"
                    style={{ display: "none" }}
                    fullWidth
                    error={noValid}
                  />
                  <Grid container>
                    <Grid item xs={8}>
                      <TextField
                        disabled={true}
                        variant="outlined"
                        style={{ color: "black" }}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <>
                              {file.label.length > 0 && (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleDeleteFile}
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      boxShadow: "none",
                                    }}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </InputAdornment>
                              )}
                            </>
                          ),
                        }}
                        label={
                          file.label.length
                            ? file.label
                            : t("managementAds.uploadImg")
                        }
                        name="image"
                        size="small"
                        error={noValid}
                        helperText={errorFileMessage}
                      />
                    </Grid>
                    <Grid item xs={2} pr={1}>
                      <label htmlFor="icon-button-file">
                        <Button
                          style={{
                            border: "none",
                            outline: "none",
                            backgroundColor: "#007e8c",
                          }}
                          variant="contained"
                          component="span"
                        >
                          {t("button.inspect").toUpperCase()}
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
                {
                  //MOSTRAR SELECT CON OPERADORES CUANDO SEA SUPER_ADMIN
                  user?.roles.name === Roles_Enum.SUPER_ADMIN && (
                    <>
                      {companies && (
                        <Grid item xs={6} sm={6} md={4}>
                          <FormControl
                            sx={{ width: "100%" }}
                            size="small"
                            disabled={!!activeAd}
                          >
                            <TextField
                              name="operator"
                              size="small"
                              fullWidth
                              error={
                                showOperatorError || (submit && !operatorId)
                              }
                              onChange={handleSelectOperator}
                              value={!operatorId ? "" : operatorId}
                              label={`${t("companyManagement.operator")}*`}
                              select
                              disabled={!!activeAd}
                            >
                              {companies?.data?.map((company) => (
                                <MenuItem key={company.id} value={company.id}>
                                  {company.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                          <FormHelperText>
                            {(showOperatorError || (submit && !operatorId)) && (
                              <label
                                className="ml-3"
                                style={{ color: "#d32f2f" }}
                              >
                                {t("form.requiredFile")}
                              </label>
                            )}
                          </FormHelperText>
                        </Grid>
                      )}
                    </>
                  )
                }
              </Grid>
              <Grid container>
                <Box
                  display={"flex"}
                  mt={2}
                  flex={1}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <Button
                    variant="contained"
                    className="footer-btn danger-btn ripple"
                    style={{ border: "none", outline: "none" }}
                    onClick={handleCancel}
                    disabled={!!loading}
                  >
                    {t("button.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className="footer-btn primary-btn ripple"
                    style={{ border: "none", outline: "none" }}
                    disabled={!!loading}
                    onClick={() => setSubmit(true)}
                  >
                    {loading ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : activeAd ? (
                      t("button.edit")
                    ) : (
                      t("button.save")
                    )}
                  </Button>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
        <MyDialog
          open={openDialog}
          title={t("dialog.confirmationWindow")}
          message={message}
          handleAccept={handleAccept}
          handleClose={handleCloseDialog}
          isLoading={!!loading}
        />

        <MyAlert
          open={openAlert}
          message={messageAlert ? messageAlert : ""}
          typeAlert={typeAlert}
          handleClose={onCloseAlert}
        />
      </Grid>
    </Grid>
  );
};
