import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { IConciliation } from "../../../interfaces/commercialNetworks";
import { getConciliationById } from "../../../services/commercialNetworks";
import { TransferType } from "../../../enums/enums";

interface Props {
  conciliation: IConciliation;
}

interface ISummary {
  rechargeCount: number;
  rechargeAmount: string;
  withdrawalCount: number;
  withdrawalAmount: string;
  rechargeCountNeat: number;
  rechargeAmountNeat: string;
  withdrawalCountNeat: number;
  withdrawalAmountNeat: string;
  type: string;
}

interface IBodySummary {
  countNetwork: number;
  countNeat: number;
  amountNetwork: string;
  amountNeat: string;
}

export const ConciliationSummary: FC<Props> = ({ conciliation }) => {
  const [summary, setSummary] = useState<ISummary | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getSummary = async () => {
      setLoading(true);
      const summary = await getConciliationById(conciliation.id);
      setSummary(summary ? summary : null);
      setLoading(false);
    };
    getSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={1} px={2}>
      {loading ? (
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
          width="100%"
          mt={3}
        >
          <CircularProgress className={"colorLoading"} />
        </Grid>
      ) : (
        <>
          <Grid item xs={6}>
            <label style={{ fontWeight: "bold" }}>{`RED:  `}</label>&nbsp;
            <label>{conciliation.externalUser.name.toUpperCase()}</label>
          </Grid>
          <Grid item xs={6}>
            <label style={{ fontWeight: "bold" }}>{`FECHA:  `}</label>&nbsp;
            <label>{conciliation.conciliationDate}</label>
          </Grid>
          {summary && (
            <>
              <Grid
                item
                xs={12}
                mt={summary.type === TransferType.WITHDRAWAL ? 2 : 0}
              >
                <label style={{ fontWeight: "bold" }}>
                  {summary.type === TransferType.RECHARGE
                    ? "RESUMEN DE RECARGAS"
                    : "RESUMEN DE RETIROS"}
                </label>
              </Grid>
              <Grid item xs={12}>
                <SummaryTable
                  countNetwork={
                    summary.type === TransferType.RECHARGE
                      ? summary.rechargeCount
                      : summary.withdrawalCount
                  }
                  countNeat={
                    summary.type === TransferType.RECHARGE
                      ? summary.rechargeCountNeat
                      : summary.withdrawalCountNeat
                  }
                  amountNetwork={
                    summary.type === TransferType.RECHARGE
                      ? summary.rechargeAmount
                      : summary.withdrawalAmount
                  }
                  amountNeat={
                    summary.type === TransferType.RECHARGE
                      ? summary.rechargeAmountNeat
                      : summary.withdrawalAmountNeat
                  }
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

const SummaryTable: FC<IBodySummary> = ({
  countNeat,
  countNetwork,
  amountNetwork,
  amountNeat,
}) => {
  return (
    <Table size="small" aria-label="purchases">
      <TableHead>
        <TableRow>
          <TableCell align="center">TRANSACCIONES RED</TableCell>
          <TableCell align="center">TRANSACCIONES NEAT</TableCell>
          <TableCell align="center">DIFERENCIA TRANSACCIONES</TableCell>
          <TableCell align="center">VALOR TOTAL RED</TableCell>
          <TableCell align="center">VALOR TOTAL NEAT</TableCell>
          <TableCell align="center">DIFERENCIA VALOR TOTAL</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="center">{countNetwork}</TableCell>
          <TableCell align="center">{countNeat}</TableCell>
          <TableCell align="center">
            {countNetwork - countNeat === 0 ? "-" : countNetwork - countNeat}
          </TableCell>
          <TableCell align="center">{amountNetwork}</TableCell>
          <TableCell align="center">{amountNeat}</TableCell>
          <TableCell align="center">
            {parseFloat(amountNetwork) - parseFloat(amountNeat) === 0
              ? "-"
              : (parseFloat(amountNetwork) - parseFloat(amountNeat)).toFixed(2)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
