import React from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { useTranslation } from "react-i18next";

export const PointSaleDetail = () => {
  const { pointSale: walletPointSale } = useSelector(
    (state: AppState) => state.wallet
  );
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <label className="font-weight-bold">ID</label>
        <Box className="no-overflow">{walletPointSale?.id}</Box>
      </Grid>
      <Grid item xs={4}>
        <label className="font-weight-bold">
          {t("associates.refillBalance")}
        </label>
        <Box className="no-overflow">{walletPointSale?.amount}</Box>
      </Grid>
      <Grid item xs={4}>
        <label className="font-weight-bold">
          {t("irrigation.bettingBalance")}
        </label>
        <Box className="no-overflow">{walletPointSale?.amountBets}</Box>
      </Grid>
    </Grid>
  );
};
