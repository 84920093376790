import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import {
  CircularProgress,
  Grid,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import Filter from "../../../../components/Filter/Filter";
import { TableComponent } from "../../../../components/Table/TableComponent";
import {
  Enum_FieldType,
  IColumnsTable,
  IFilterField,
} from "../../../../interfaces/components";
import {
  setCountries,
  setFlagForm,
  setFlagFormConfig,
  setFlagFormEdit,
} from "../../../../redux/actions/helperActions";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { Icons } from "../../../../interfaces/components";
import { StepsConfigOperationCompany } from "../../../components/OperationCompany/StepsConfigOperationCompany";

import "../Management/ManagementCompany.scss";
import {
  setActiveBrand,
  setActiveCompany,
  setConfigBrands,
} from "../../../../redux/actions/managementCompanyActions";
import { useLocation } from "react-router-dom";
import { MyAlert } from "../../../components/custom/MyAlert";
import { setCloseAlert } from "../../../../redux/actions/helperActions";

export const ConfigCompany = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { token } = useSelector((state: AppState) => state.auth);
  const { countries, openAlert, messageAlert, typeAlert } = useSelector(
    (state: AppState) => state.helper
  );
  const { data } = useSelector((state: AppState) => state.search);
  const [loading, setLoading] = useState(false);
  const [filterFields, setFilterFields] = useState<IFilterField[]>([]);
  const { flagFormConfig } = useSelector((state: AppState) => state.helper);
  const [dataFilter, setDataFilter] = useState<any>({});
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { brands } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const { pathname } = useLocation();
  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;

  useEffect(() => {
    const fetchCountries = async (token: string) => {
      setLoading(true);
      await dispatch(setCountries(token));
      await dispatch(setConfigBrands());
      setLoading(false);
    };
    if (token) {
      fetchCountries(token);
    }

    return () => {
      dispatch(setFlagFormConfig({ flagFormConfig: false }));
      dispatch(setActiveBrand(null));
      dispatch(setActiveCompany(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setFlagForm({ flagFormCreate: false }));
    dispatch(setFlagFormEdit({ flagFormEdit: false }));
    if (countries) {
      const FilterFileds: IFilterField[] = [
        {
          label: t("companyManagement.companyName"),
          type: Enum_FieldType.TEXT,
        },
        {
          label: t("companyManagement.brand"),
          type: Enum_FieldType.TEXT,
        },
        {
          label: t("user.country"),
          type: Enum_FieldType.SELECT,
          options: [
            ...countries.map((country) => ({
              id: country.id,
              label: country.name,
              value: country.id,
            })),
          ],
        },
        {
          label: t("filters.startDate"),
          type: Enum_FieldType.DATE,
        },
        {
          label: t("filters.endDate"),
          type: Enum_FieldType.DATE,
        },
      ];
      setFilterFields(FilterFileds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  const columns: IColumnsTable[] = [
    { id: 1, header: "companyManagement.company" },
    { id: 2, header: "companyManagement.brand" },
    { id: 3, header: "user.country" },
    { id: 4, header: "table.creationDate" },
    { id: 5, header: "table.state" },
    { id: 6, header: "table.createdBy" },
    { id: 7, header: "table.actions" },
  ];

  const iconos: Icons = {
    delete: false,
    seeMore: true,
    add: true,
  };
  const handleOpenForm = () => {
    dispatch(setFlagFormConfig({ flagFormConfig: true }));
  };

  const onCloseAlert = () => {
    dispatch(setCloseAlert());
  };

  return (
    <div className="content w-100">
      <Row style={{ width: "100%" }}>
        {flagFormConfig ? (
          <StepsConfigOperationCompany />
        ) : (
          <Col lg={12} md={12} sm={12}>
            <div className="W-100">
              <Row style={{ marginBottom: 10 }}>
                <Col xs={12}>
                  <Typography color="text.primary" style={{ fontSize: "16px" }}>
                    {`${t("companyManagement.title")} `}
                  </Typography>
                </Col>
              </Row>
              <div className="white-container">
                {loading ? (
                  <Grid container className={"paperLoading"}>
                    <Box mx="auto" my="40vh">
                      <CircularProgress className={"colorLoading"} />
                    </Box>
                  </Grid>
                ) : (
                  <>
                    {enableCreated && (
                      <Row>
                        <Col xs={12}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            mb={2}
                          >
                            <Box display="flex" alignItems="center">
                              <p
                                className="txt-primary-md"
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "uppercase",
                                  alignSelf: "center",
                                }}
                              >
                                {t("companyManagement.createBrand")}
                              </p>
                            </Box>
                            <Box>
                              <IconButton
                                data-testid="btnOpenForm"
                                onClick={handleOpenForm}
                                style={{ border: "none", outline: "none" }}
                              >
                                <span className="btn-add">
                                  <i
                                    className="fa-solid fa-plus"
                                    style={{ fontSize: 30 }}
                                  ></i>
                                </span>
                              </IconButton>
                            </Box>
                          </Box>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Filter
                        fields={filterFields}
                        component={"configCompany"}
                        setDataFilter={setDataFilter}
                      />
                    </Row>

                    {data ? (
                      <>
                        {data.data.length > 0 && (
                          <TableComponent
                            columns={columns}
                            data={data.data}
                            pages={data.last_page}
                            icons={iconos}
                            component={"configCompany"}
                            totalData={data}
                            isSortingTable={true}
                            dataFilter={dataFilter}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {brands && brands.data.length > 0 && (
                          <TableComponent
                            columns={columns}
                            data={brands.data}
                            pages={brands.last_page}
                            icons={iconos}
                            component={"configCompany"}
                            totalData={brands}
                            isSortingTable={true}
                            dataFilter={dataFilter}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </Col>
        )}
      </Row>
      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />
    </div>
  );
};
