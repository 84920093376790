import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { setFlagFormConfig } from "../../../../redux/actions/helperActions";
import { useTranslation } from "react-i18next";
import { AssociateInformation } from "./AssociateInformation";
import { TabsAssociateInformation } from "./TabsAssociateInformation";
import { setActiveAssociate } from "../../../../redux/actions/associatesActions";
import { valueSearched } from "../../../../redux/actions/searchActions";

export const AssociateDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { flagFormConfig } = useSelector((state: AppState) => state.helper);
  useEffect(() => {
    if (!flagFormConfig) {
      dispatch(valueSearched(""));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagFormConfig]);

  const handleClose = () => {
    dispatch(setFlagFormConfig({ flagFormConfig: false }));
    dispatch(setActiveAssociate(null));
  };

  return (
    <Dialog
      open={!!flagFormConfig}
      maxWidth="lg"
      fullWidth
      disableEscapeKeyDown
      scroll="body"
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 0,
          marginBottom: -20,
        }}
      >
        <Box display="flex">
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <p className="txt-primary-md" style={{ fontWeight: 500 }}>
            {t("associates.associateInformation")}
          </p>
        </Box>
        <IconButton
          disableFocusRipple
          disableRipple
          style={{ border: "none", outline: "none", marginBottom: 5 }}
          onClick={handleClose}
          className="hover-close"
          sx={{
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent",
            },
          }}
        >
          <i className="fa-solid fa-xmark"></i>
        </IconButton>
      </DialogTitle>
      <Box borderTop={1} borderColor="#02D0E7" m={2}>
        <DialogContent>
          <AssociateInformation />
          <TabsAssociateInformation />
        </DialogContent>
      </Box>
      <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          style={{
            border: "none",
            outline: "none",
            marginRight: 15,
            marginBottom: 15,
          }}
          variant="contained"
          className="footer-btn primary-btn ripple"
          onClick={handleClose}
        >
          <i className="fa-solid fa-check-circle"></i>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
