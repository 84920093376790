/* eslint-disable array-callback-return */
import React, { FC, useState } from "react";
import { Grid, TableCell, Tooltip } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { StateLotCards } from "../../../enums/enums";
import { useDispatch, useSelector } from "react-redux";
import { uiSetOpenDialog } from "../../../redux/actions/uiAction";
import {
  setActiveLot,
  startFilterLots,
  startUpdateLot,
} from "../../../redux/actions/managementCardsActions";
import { getCardByLotId } from "../../../services/cards";
import { AppState } from "../../../redux/reducers/rootReducer";
import { ExportCardsData } from "./ExportCardsData";
import DeliveryIcon from "../../../assets/svg/carbon_delivery.svg";
import {
  setFlagFormEdit,
  setFlagFormModal,
  setMessageAlert,
} from "../../../redux/actions/helperActions";
import { useLocation } from "react-router-dom";

interface Props {
  row: any;
  dataFilter: any;
}
export const RowLotCards: FC<Props> = ({ row, dataFilter }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token } = useSelector((state: AppState) => state.auth);
  const { lots } = useSelector((state: AppState) => state.managementCards);
  const [totalData, setTotalData] = useState([]);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const { pathname } = useLocation();
  const { routes: RoutesRedux } = useSelector(
    (state: AppState) => state.managementMenus
  );

  const modulePermissions = RoutesRedux.find(
    (route) => !!route.menuItem.find((module) => module.url === pathname)
  )?.menuItem.find((module) => module.url === pathname);

  const enableUpdate =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.updated === 1;

  const onExport = async () => {
    const response = await getCardByLotId(row.id, token);
    if (response.data) {
      const { data } = response;
      data.map((col: any, index: number) => {
        col.position = index + 1;
      });
      setTotalData(response.data);
      setTooltipIsOpen(true);
    }
  };

  const onSeeMore = (row: number) => {
    dispatch(uiSetOpenDialog());
    dispatch(setActiveLot(row));
  };
  const onChangeState = async () => {
    await dispatch(
      startUpdateLot(row.id, {
        state: [StateLotCards.INACTIVE, StateLotCards.PENDING].includes(
          row.state
        )
          ? StateLotCards.ACTIVE
          : StateLotCards.INACTIVE,
        amount: row.amountCards,
        updateOnlyLot: true,
        value: parseInt(row.totalValue) / row.amountCards,
        extLot: row.extLot,
      })
    );
    await dispatch(
      startFilterLots({
        ...dataFilter,
        page: lots?.page || 1,
      })
    );
  };
  const onDeliverDealer = async () => {
    dispatch(
      setFlagFormModal({
        flagFormModal: true,
      })
    );
    dispatch(
      setMessageAlert(
        t("cards.sureDeliverDealer", {
          dealers: row.dealer.map((item: any) => item.dealer.name).toString(),
        })
      )
    );

    dispatch(setActiveLot(row));
  };
  const handleOpenEdit = () => {
    dispatch(
      setFlagFormEdit({
        flagFormEdit: true,
      })
    );
    dispatch(setActiveLot(row));
  };
  return (
    <>
      <TableCell align="center">{row.serial}</TableCell>
      <TableCell align="center">{row.id}</TableCell>
      <TableCell align="center">{row.amountCards}</TableCell>
      <TableCell align="center">
        {moment(row.createAt).format("YYYY-MM-DD hh:mm:ss A")}
      </TableCell>
      <TableCell align="center">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                [
                  StateLotCards.ACTIVE,
                  StateLotCards.DELIVERED_MANUFACTURER,
                  StateLotCards.DELIVERED_DEALER,
                ].includes(parseInt(row.state))
                  ? "cellRowActive"
                  : StateLotCards.INACTIVE === parseInt(row.state)
                  ? "d-flex justify-content-center cellRowInactive"
                  : "d-flex justify-content-center cellRowRetired"
              }
            >
              <div className="p-1">
                {StateLotCards.PENDING === parseInt(row.state) && (
                  <>{t("state.pending")}</>
                )}
                {StateLotCards.ACTIVE === parseInt(row.state) && (
                  <>{t("state.active")}</>
                )}
                {StateLotCards.INACTIVE === parseInt(row.state) && (
                  <>{t("state.inactive")}</>
                )}
                {StateLotCards.DELIVERED_MANUFACTURER ===
                  parseInt(row.state) && (
                  <>{t("cards.deliveredToManufactured")}</>
                )}
                {StateLotCards.DELIVERED_DEALER === parseInt(row.state) && (
                  <>{t("cards.deliveredToDealer")}</>
                )}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell align="center">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {enableUpdate && (
              <Tooltip
                open={tooltipIsOpen}
                onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#fff",
                      "& .MuiTooltip-arrow": {
                        color: "#02D0E7",
                      },
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#000",
                      maxWidth: "none",
                      cursor: "pointer",
                    },
                  },
                }}
                title={
                  totalData.length > 0 && (
                    <ExportCardsData
                      totalData={totalData}
                      serial={row.serial}
                      setTooltipIsOpen={setTooltipIsOpen}
                      row={row}
                    />
                  )
                }
                disableHoverListener
                placement="bottom"
              >
                <div>
                  <i
                    className="fa-solid fa-file-export mr-1"
                    style={{
                      fontSize: 16,
                      color: "#2C97DF",
                      cursor: "pointer",
                    }}
                    onClick={onExport}
                  />
                </div>
              </Tooltip>
            )}

            <Tooltip title={t("button.showMoreInfo") as string} arrow>
              <div>
                <i
                  className="fa fa-search-plus fa-lg mr-1 iconSeeMore"
                  onClick={() => onSeeMore(row.id)}
                />
              </div>
            </Tooltip>
            <Tooltip title={t("button.changeState") as string} arrow>
              <div>
                <i
                  className={
                    [
                      StateLotCards.PENDING,
                      StateLotCards.ACTIVE,
                      StateLotCards.DELIVERED_MANUFACTURER,
                      StateLotCards.DELIVERED_DEALER,
                    ].includes(row.state)
                      ? `fa fa-eye fa-lg mr-2 iconSee`
                      : `fa-regular fa-eye-slash fa-lg mr-2 iconSee`
                  }
                  onClick={onChangeState}
                  style={{
                    pointerEvents: [
                      StateLotCards.DELIVERED_MANUFACTURER,
                      StateLotCards.DELIVERED_DEALER,
                      StateLotCards.ACTIVE,
                      StateLotCards.INACTIVE,
                    ].includes(row.state)
                      ? "all"
                      : "none",
                    opacity: [
                      StateLotCards.DELIVERED_MANUFACTURER,
                      StateLotCards.DELIVERED_DEALER,
                      StateLotCards.ACTIVE,
                      StateLotCards.INACTIVE,
                    ].includes(row.state)
                      ? 1
                      : 0.5,
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip
              title={t("button.edit") as string}
              arrow
              style={{
                cursor: "pointer",
              }}
            >
              <div>
                <i
                  onClick={handleOpenEdit}
                  className="fa fa-pencil-square-o fa-lg mr-2 iconEdit"
                />
              </div>
            </Tooltip>
            {(row.state === StateLotCards.DELIVERED_MANUFACTURER ||
              row.isDeliveredMaker) &&
              !row.activateDate && (
                <Tooltip
                  title={t("cards.deliverToDealer") as string}
                  arrow
                  onClick={onDeliverDealer}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <div>
                    <img src={DeliveryIcon} alt="File plus" />
                  </div>
                </Tooltip>
              )}
          </Grid>
        </Grid>
      </TableCell>
    </>
  );
};
