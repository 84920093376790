import { OperatorConfigDataTable } from "./operationCompany/OperatorConfigDataTable";
import { MenusDataTable } from "./menus/MenusDataTable";
import { UsersDataTable } from "./users/usersDataTable";
import { ManagementCompaniesDataTable } from "./managementCompanies/ManagementCompaniesDataTable";

import { LegalDataTable } from "./legal/LegalDataTable";
import { IrrigationDataTable } from "./irrigation/IrrigationDataTable";
import { BetHistoryDataTable } from "./bets/betHistory/BetHistoryDataTable";
import { BetsDataTable } from "./activityPlayer/BetsDataTable";
import { BondsDataTable } from "./activityPlayer/BondsDataTable";

export const useDataTable = (component: string) => {
  let obj: any;
  const dataExport = () => {
    switch (component) {
      case "users":
        const {
          dataUsers,
          dataTotalUsers,
          headersCSVUsers,
          multiDataSetUsers,
          multiTotalDataSetUsers,
        } = UsersDataTable();
        return (obj = {
          dataPerView: dataUsers,
          dataTotal: dataTotalUsers,
          headersCSV: headersCSVUsers,
          multiDataSet: multiDataSetUsers,
          multiTotalDataSet: multiTotalDataSetUsers,
        });
      case "menu":
        const {
          dataMenus,
          dataTotalMenus,
          headersCSVMenus,
          multiDataSetMenus,
          multiTotalDataSetMenus,
        } = MenusDataTable();
        return (obj = {
          dataPerView: dataMenus,
          dataTotal: dataTotalMenus,
          headersCSV: headersCSVMenus,
          multiDataSet: multiDataSetMenus,
          multiTotalDataSet: multiTotalDataSetMenus,
        });
      case "configCompany":
        const {
          dataBrands,
          dataTotalBrands,
          headersCSVBrands,
          multiDataSetBrands,
          multiTotalDataSetBrands,
        } = OperatorConfigDataTable();
        return (obj = {
          dataPerView: dataBrands,
          dataTotal: dataTotalBrands,
          headersCSV: headersCSVBrands,
          multiDataSet: multiDataSetBrands,
          multiTotalDataSet: multiTotalDataSetBrands,
        });
      case "managementCompany":
        const {
          dataCompanies,
          dataTotalCompanies,
          headersCSVCompanies,
          multiDataSetCompanies,
          multiTotalDataSetCompanies,
        } = ManagementCompaniesDataTable();
        return (obj = {
          dataPerView: dataCompanies,
          dataTotal: dataTotalCompanies,
          headersCSV: headersCSVCompanies,
          multiDataSet: multiDataSetCompanies,
          multiTotalDataSet: multiTotalDataSetCompanies,
        });
      case "legal":
        const {
          dataLegal,
          dataTotalLegal,
          headersCSVLegal,
          multiDataSetLegal,
          multiTotalDataSetLegal,
        } = LegalDataTable();
        return (obj = {
          dataPerView: dataLegal,
          dataTotal: dataTotalLegal,
          headersCSV: headersCSVLegal,
          multiDataSet: multiDataSetLegal,
          multiTotalDataSet: multiTotalDataSetLegal,
        });
      case "irrigation":
        const {
          dataIrrigation,
          dataTotalIrrigation,
          headersCSVIrrigation,
          multiDataSetIrrigation,
          multiTotalDataSetIrrigation,
        } = IrrigationDataTable();
        return (obj = {
          dataPerView: dataIrrigation,
          dataTotal: dataTotalIrrigation,
          headersCSV: headersCSVIrrigation,
          multiDataSet: multiDataSetIrrigation,
          multiTotalDataSet: multiTotalDataSetIrrigation,
        });
      case "bettingHistory":
        const {
          dataBetHistory,
          dataTotalBetHistory,
          headersCSVBetHistory,
          multiDataSetBetHistory,
          multiTotalDataSetBetHistory,
        } = BetHistoryDataTable();
        return (obj = {
          dataPerView: dataBetHistory,
          dataTotal: dataTotalBetHistory,
          headersCSV: headersCSVBetHistory,
          multiDataSet: multiDataSetBetHistory,
          multiTotalDataSet: multiTotalDataSetBetHistory,
        });
      case "bets":
        const {
          dataBets,
          dataTotalBets,
          headersCSVBets,
          multiDataSetBets,
          multiTotalDataSetBets,
        } = BetsDataTable();
        return (obj = {
          dataPerView: dataBets,
          dataTotal: dataTotalBets,
          headersCSV: headersCSVBets,
          multiDataSet: multiDataSetBets,
          multiTotalDataSet: multiTotalDataSetBets,
        });
      case "bonds":
        const {
          dataBonusRedeemed,
          dataTotalBonusRedeemed,
          headersCSVBonusRedeemed,
          multiDataSetBonusRedeemed,
          multiTotalDataSetBonusRedeemed,
        } = BondsDataTable();
        return (obj = {
          dataPerView: dataBonusRedeemed,
          dataTotal: dataTotalBonusRedeemed,
          headersCSV: headersCSVBonusRedeemed,
          multiDataSet: multiDataSetBonusRedeemed,
          multiTotalDataSet: multiTotalDataSetBonusRedeemed,
        });
      default:
        break;
    }
  };

  dataExport();
  return obj;
};
