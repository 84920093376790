import React, { FC } from 'react'
import { TableCell } from '@mui/material'

interface props{
    row:any
}
export const RowSellersTab:FC<props> = ({row}) => {
  return (
    <>
     <TableCell className="word-break" align='center'>{row.user.id}</TableCell>
     <TableCell className="word-break" align='center'>{row.user.nameComplete}</TableCell>
    </>
  )
}
