import { ICreatedUser } from "./../interfaces/users";
import {
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";
import {
  createMenuService,
  deleteMenuService,
  deleteModuleService,
  editMenuService,
  getMenusDb,
  getMenusLogged,
  getMenusPage,
  getMenusService,
} from "./../../services/managementMenuService";
import { buildRoutes } from "../../helpers/buildRoutes";
import {
  IMenuList,
  IRoutes,
  IMenuItem,
  IMenuDB,
  IMenuListCreate,
  IMenuParams,
} from "../../interfaces/menu";
import { IRoutesAction } from "../interfaces/routes";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import { uiSetError, uiSetErrorMessage } from "./uiAction";
import { arraySearch, setChangeData } from "./searchActions";
import { startLogout } from "./authActions";
import { DealerTypeEnum, Roles_Enum } from "../../enums/enums";

export const startLoadAvailableRoutes = () => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { pointSale } = (getState() as AppState).wallet;
    //call backend to get available routes/menu
    const exclusiveModulesStarPlay = ["/admin/settings/management-cards"];
    try {
      if (!!user && token) {
        const response = await getMenusLogged(token, user.roles.id);
        const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
        const isDealer = user?.roles.name === Roles_Enum.DEALER;
        const isSeller = user?.roles.name === Roles_Enum.SELLER;

        if (response) {
          let routesAdmin = buildRoutes(response, "/admin", user.roles);

          const isStarplay = isSuper
            ? true
            : user.operator
            ? ["starplay", "operador neat", "betppro"].includes(
                user.operator.name.toLowerCase()
              )
            : false;

          if (!isStarplay && user.operator) {
            //@ts-ignore
            routesAdmin = routesAdmin
              .map((route) => {
                if (!exclusiveModulesStarPlay.includes(route.url)) {
                  if (route.menuItem.length > 0) {
                    route.menuItem = route.menuItem.filter(
                      (sub: any) => !exclusiveModulesStarPlay.includes(sub.url)
                    );
                  }
                  return route;
                }
                return null;
              })
              .filter((route) => route !== null);
          }
          if (isDealer) {
            const dealerType = user.dealer.type;
            if (dealerType === DealerTypeEnum.ANONYMOUS) {
              //@ts-ignore
              routesAdmin = routesAdmin.filter((route) => {
                if (route.url === "/admin/settings") {
                  return false;
                }
                if (route.menuItem.length > 0) {
                  route.menuItem = route.menuItem.filter((sub: any) => {
                    return ![
                      "/admin/transactions/irrigation-cards-pointSale",
                      "/admin/transactions/irrigation-cards-promotor",
                    ].includes(sub.url);
                  });
                }
                return true;
              });
            }
            if (dealerType === DealerTypeEnum.CARDS) {
              //@ts-ignore
              routesAdmin = routesAdmin.filter((route) => {
                if (route.menuItem.length > 0) {
                  route.menuItem = route.menuItem.filter((sub: any) => {
                    return sub.url !== "/admin/transactions/irrigation";
                  });
                }
                return true;
              });
            }
          }
          if (isSeller && pointSale) {
            const sellerType = pointSale.pointSale.type;
            if (sellerType === DealerTypeEnum.ANONYMOUS) {
              //@ts-ignore
              routesAdmin = routesAdmin.filter((route) => {
                if (route.url === "/admin/settings") {
                  return false;
                }
                return true;
              });
            } else if (sellerType === DealerTypeEnum.CARDS) {
              routesAdmin = routesAdmin.filter((route) => {
                if (
                  route.url === "/admin/bets" ||
                  route.url === "/admin/transactions" ||
                  route.url === "/admin/deposit"
                ) {
                  return false;
                }
                return true;
              });
            }
          }
          dispatch(loadAvailableRoutes(routesAdmin));
        } else {
          dispatch(startLogout());
        }
      } else {
        dispatch(startLogout());
      }
    } catch (error) {
      dispatch(startLogout());
      console.log(error);
    }
  };
};
export const startGetMenus = (params: Partial<IMenuParams>) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token, user } = (getState() as AppState).auth;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      if (user?.operator) {
        params.operatorId = user.operator.id;
      }

      let finalValues: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (typeof value === "number" || value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });

      const rs = await getMenusDb(token, finalValues);

      return dispatch(getMenusRedux(rs));
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const getMenus = (
  token: string,
  page?: number,
  showLoading?: boolean,
  params?: any,
  operatorId?: number
) => {
  return async (dispatch: Function, getState: Function) => {
    const { rowsPerPage } = (getState() as AppState).helper;
    const { user } = (getState() as AppState).auth;

    try {
      if (user) {
        showLoading !== undefined &&
          showLoading &&
          dispatch(setLoadingState({ loading: true }));
        const operator = user.operator ? user.operator.id : operatorId;

        let response = null;
        if (page) {
          response = await getMenusPage(
            token,
            operator,
            user.id,
            page,
            rowsPerPage
          );
        } else {
          response = await getMenusService(token, operator, params);
        }
        if (response && !response.error) {
          const data: IMenuDB = response;
          dispatch(getMenusRedux(data));
        } else {
          dispatch(getMenusRedux(null));
          dispatch(uiSetError(true));
          dispatch(uiSetErrorMessage("alerts.errorSetMenus"));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      showLoading !== undefined &&
        showLoading &&
        dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const createMenu = (data: IMenuListCreate, token: string) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    // const { rowsPerPage } = (getState() as AppState).helper;
    data.createdUser =
      (data.createdUser as ICreatedUser).id !== 0
        ? (data.createdUser as ICreatedUser).id
        : user
        ? { id: user.id, nameComplete: user.nameComplete }
        : { id: 0, nameComplete: "" };
    data.operator = data.operator ? data.operator : user?.operator.id;

    try {
      dispatch(setLoadingState({ loading: true }));
      const response = await createMenuService(data, token);

      if (response && !response.error) {
        dispatch(startLoadAvailableRoutes());

        dispatch(arraySearch(null));
        dispatch(setMessageAlert("alerts.createMenuSuccess"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
      } else {
        dispatch(setMessageAlert("alerts.createMenuError"));
        dispatch(setTypeAlert("error"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      console.log(error);
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.createMenuError"));
      dispatch(setTypeAlert("error"));
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const editMenu = (
  data: IMenuListCreate,
  token: string,
  id: number,
  deleteItemsIds: number[]
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    data.createdUser = data.createdUser
      ? data.createdUser
      : user
      ? { id: user.id, nameComplete: user.nameComplete }
      : { id: 0, nameComplete: "" };

    data.operator = data.operator
      ? data.operator
      : user && user.operator
      ? user.operator.id
      : null;

    try {
      dispatch(setLoadingState({ loading: true }));

      const response = await editMenuService(data, token, id);

      const responseDelete =
        deleteItemsIds.length > 0
          ? await Promise.all([
              deleteItemsIds.forEach((id) =>
                deleteModuleService(token, id.toString())
              ),
            ])
          : true;
      if (response && responseDelete) {
        // refresh routes for sidebar
        await dispatch(startLoadAvailableRoutes());
        dispatch(arraySearch(null));

        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.editMenuSuccess"));
        dispatch(setTypeAlert("success"));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.editMenuError"));
        dispatch(setTypeAlert("error"));
      }
    } catch (error) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.editMenuError"));
      dispatch(setTypeAlert("error"));
    } finally {
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const editStateMenu = (dataMenu: IMenuListCreate) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { data } = (getState() as AppState).search;
    try {
      const response =
        token &&
        dataMenu.id &&
        (await editMenuService(dataMenu, token, dataMenu.id));

      if (response && !response.error) {
        data && dispatch(setChangeData(true));
        await dispatch(editMenuRedux(dataMenu));
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.editStateMenuSuccess"));
        dispatch(setTypeAlert("success"));
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.editMenuError"));
        dispatch(setTypeAlert("error"));
      }
    } catch (error) {
      alert(error);
    }
  };
};

export const startDeleteMenu = (idMenu: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    const { data } = (getState() as AppState).search;
    const { menus } = (getState() as AppState).managementMenus;
    try {
      if (!!user && token) {
        const response = await deleteMenuService(token, idMenu);
        if (!response || response.error) {
          dispatch(setOpenAlert());
          dispatch(
            setMessageAlert(
              response.error
                ? "alerts.errorMenuAssociatedToRole"
                : "alerts.deleteMenuError"
            )
          );
          dispatch(setTypeAlert("error"));
          throw new Error(response.error);
        } else {
          if (response.affected === 1) {
            dispatch(setActiveMenu(null));

            data && dispatch(setChangeData(true));
            const newData = data ? { ...data } : menus && { ...menus };
            newData.data = newData.data.filter((el: any) => el.id !== idMenu);
            const dataSearchFilter = {
              data: newData.data,
              total: newData.total - 1,
              page: newData.page,
              last_page: newData.last_page,
            };

            dispatch(
              data
                ? arraySearch(dataSearchFilter)
                : getMenusRedux(dataSearchFilter)
            );

            dispatch(setOpenAlert());
            dispatch(setMessageAlert("alerts.deleteMenuSuccess"));
            dispatch(setTypeAlert("success"));
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const startDeleteModule = (idModule: string) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    try {
      if (!!user && token) {
        const response = await deleteModuleService(token, idModule);
        if (response.affected === 1) {
          dispatch(deleteModule(idModule));
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.deleteModuleSuccess"));
          dispatch(setTypeAlert("success"));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("alerts.deleteModuleError"));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

const loadAvailableRoutes = (routes: IRoutes[]): IRoutesAction => ({
  type: Types.loadAvailableRoutes,
  payload: routes,
});

export const getMenusRedux = (menus: IMenuDB | null) => ({
  type: Types.setMenus,
  payload: menus,
});

export const setTotalMenusRedux = (menus: IMenuDB | null) => ({
  type: Types.setTotalMenus,
  payload: menus,
});

export const addMenuRedux = (menu: IMenuListCreate, rowsPerPage: number) => ({
  type: Types.addMenu,
  payload: { menu, rowsPerPage },
});

export const editMenuRedux = (menu: IMenuListCreate) => ({
  type: Types.updateMenu,
  payload: menu,
});

export const deleteMenu = (idMenu: string, rowsPerPage: number) => ({
  type: Types.deleteMenu,
  payload: { id: idMenu, rowsPerPage },
});

export const setActiveMenu = (menu: IMenuList | null) => ({
  type: Types.setActiveMenu,
  payload: menu,
});

export const setActiveModule = (module: IMenuItem | null) => ({
  type: Types.setActiveModule,
  payload: module,
});
export const deleteModule = (idModule: string) => ({
  type: Types.deleteModule,
  payload: idModule,
});

export const startFilteringMenus = (
  formValues: any,
  page?: number,
  showLoading: boolean = true,
  cleanFilter?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;
    if (token && user) {
      const operatorId =
        user.roles.name === "SUPER_ADMIN" ? undefined : user.operator.id;
      let finalValues: any = {};
      Object.entries(formValues).forEach(([key, value], index) => {
        //@ts-ignore
        if (value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });
      showLoading && dispatch(setLoadingState({ loading: true }));
      const response =
        user &&
        (await getMenusService(
          token,
          operatorId,
          finalValues,
          page,
          rowsPerPage
        ));

      if (response && !response.error) {
        if (cleanFilter) {
          dispatch(arraySearch(null));
          dispatch(getMenusRedux(response));
        } else {
          dispatch(arraySearch(response));
        }
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("alerts.errorSetMenus"));
        dispatch(setTypeAlert("error"));
      }
      showLoading && dispatch(setLoadingState({ loading: false }));
    }
  };
};
