import { Form, Formik } from "formik";
import { ChangeEvent, useState, useEffect, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import { MyTextField } from "../../components/custom/MyTextField";

import { AppState } from "../../../redux/reducers/rootReducer";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { regNumbers } from "../../../utils/utils";
import {
  editPlayerService,
  getPlayerById,
} from "../../../services/managementPlayersService";

interface IFormValues {
  dailyRefillsLimit: number | null;
  dailyBetsLimit: number | null;
  dailyWithdrawalsLimit: number | null;
}

export const LimitsPerPlayer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token, user } = useSelector((state: AppState) => state.auth);
  const [player, setPlayer] = useState<any>(null);
  const [playerId, setPlayerId] = useState("");

  const [checking, setChecking] = useState(false);
  const initialValues: IFormValues = {
    dailyBetsLimit: player?.dailyBetsLimit ?? "",
    dailyRefillsLimit: player?.dailyRefillsLimit ?? "",
    dailyWithdrawalsLimit: player?.dailyWithdrawalsLimit ?? "",
  };

  useEffect(() => {
    handleGetPlayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetPlayer = async () => {
    setChecking(true);
    if (token && playerId) {
      const player = await getPlayerById(
        token,
        playerId,
        user?.operator?.id ?? null
      );
      if (!player?.id) {
        setChecking(false);
        dispatch(setMessageAlert("alerts.noData"));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      setPlayer(player);
    }
    setChecking(false);
  };

  const validationSchema = yup.object({
    dailyBetsLimit: yup.number().moreThan(0, t("managementBonds.greaterThanZero")),
    dailyRefillsLimit: yup.number().moreThan(0, t("managementBonds.greaterThanZero")),
    dailyWithdrawalsLimit: yup.number().moreThan(0, t("managementBonds.greaterThanZero")),
  });

  const handleSetDailyLimits = async (data: any) => {
    const { dailyBetsLimit, dailyRefillsLimit, dailyWithdrawalsLimit } = data;
    if (player?.id) {
      const objEditPlayer = {
        dailyBetsLimit: dailyBetsLimit.trim() ? dailyBetsLimit : null,
        dailyRefillsLimit: dailyRefillsLimit.trim() ? dailyRefillsLimit : null,
        dailyWithdrawalsLimit: dailyWithdrawalsLimit.trim()
          ? dailyWithdrawalsLimit
          : null,
      };
      const resp =
        token && (await editPlayerService(objEditPlayer, token, player.id));

      if (resp?.rs?.affected) {
        dispatch(setMessageAlert("companyManagement.successUpdated"));
        dispatch(setTypeAlert("success"));
      } else {
        dispatch(
          setMessageAlert(
            resp?.error.errorMessage
              ? `alerts.${resp.error.errorMessage}`
              : "alerts.updateError"
          )
        );
        dispatch(setTypeAlert("error"));
      }
      dispatch(setOpenAlert());
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} className="white-container" mt={2} ml={2}>
        <Formik
          validationSchema={validationSchema}
          validateOnChange={true}
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            const newData = { ...data };
            await handleSetDailyLimits(newData);
            setSubmitting(false);
          }}
        >
          {({ handleChange, values, isSubmitting, handleReset, errors }) => (
            <Form>
              <Grid container spacing={1}>
                <Grid item sm={12} md={7}>
                  <TextField
                    name="playerId"
                    value={playerId ?? ""}
                    label={t("table.playerId")}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleReset();
                      setPlayer(null);
                      setPlayerId(e.target.value as string);
                    }}
                    onKeyPress={(e: any) => {
                      !regNumbers(e.key) && e.preventDefault();
                    }}
                    inputProps={{
                      maxLength: 20,
                    }}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={5}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    className="primary-btn ripple"
                    style={{ border: "none", outline: "none" }}
                    onClick={() => {
                      handleReset();
                      setPlayer(null);
                      handleGetPlayer();
                    }}
                    disabled={!playerId?.toString().trim() || !!player}
                  >
                    {checking ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      t("filters.filter")
                    )}
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                columns={{ xs: 1, sm: 1, md: 1 }}
                mb={5}
                mt={1}
              >
                <Grid item xs={1}>
                  <label className="form-label">
                    {t("playerLimit.maximumDailyRefill")}
                  </label>
                  <MyTextField
                    disabled={isSubmitting || !playerId}
                    name="dailyRefillsLimit"
                    value={values.dailyRefillsLimit}
                    label={""}
                    InputLabelProps={{ shrink: false }}
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regNumbers(e.key) && e.preventDefault()
                    }
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <label className="form-label">
                    {t("playerLimit.maximumDailyBet")}
                  </label>
                  <MyTextField
                    disabled={isSubmitting || !playerId}
                    name="dailyBetsLimit"
                    value={values.dailyBetsLimit}
                    label={""}
                    InputLabelProps={{ shrink: false }}
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regNumbers(e.key) && e.preventDefault()
                    }
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <label className="form-label">
                    {t("playerLimit.maximumDailyWithdrawal")}
                  </label>
                  <MyTextField
                    disabled={isSubmitting || !playerId}
                    name="dailyWithdrawalsLimit"
                    value={values.dailyWithdrawalsLimit}
                    label={""}
                    InputLabelProps={{ shrink: false }}
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regNumbers(e.key) && e.preventDefault()
                    }
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting || !!!player || !playerId}
                    fullWidth
                    className="primary-btn ripple"
                    style={{ border: "none", outline: "none" }}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      t("button.save")
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
