import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import Filter from "../../../../components/Filter/Filter";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IWithdrawalParams } from "../../../../interfaces/withdrawals";
import { ProvidersBonus, StateTransaction, TransferConcept } from "../../../../enums/enums";
import { asyncLoadingFunction, setMessageAlert, setOpenAlert, setTypeAlert } from "../../../../redux/actions/helperActions";
import {
  setWithdrawalsRedux,
  startGetWithdrawals,
} from "../../../../redux/actions/transactionsActions";
import { TabsWithDrawalRequest } from "./TabsWithDrawalRequest";
import moment from "moment";

export const WithDrawalsRequest = () => {
  const [dataFilter, setDataFilter] = useState<any>({});

  const [currentTab, setCurrentTab] = useState(StateTransaction.Pending);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filterFields: IFilterField[] = [
    {
      type: Enum_FieldType.NUMBER,
      label: t("withdrawals.withdrawalNumber"),
    },
    {
      type: Enum_FieldType.TEXT,
      label: `${t("user.documentNumber")}`,
    },
    {
      type: Enum_FieldType.TEXT,
      label: t("user.email"),
    },
    {
      type: Enum_FieldType.NUMBER,
      label: t("transactions.value"),
    },
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
  ];

  const handleFilter = async (values: any) => {
    const params: Partial<IWithdrawalParams> = {
      id: values[t("withdrawals.withdrawalNumber")],
      numberDocument: values[t("user.documentNumber")],
      email: values[t("user.email")],
      value: values[t("transactions.value")],
      startDate: values[t("filters.startDate")],
      endDate: values[t("filters.endDate")],
      state: currentTab,
      paymentMethod: TransferConcept.CASH,
      providerId: ProvidersBonus.NEAT,
      page: 1,
    };
    const { endDate, startDate } = params;

    if (endDate?.trim() && !startDate?.trim()) {
      dispatch(setMessageAlert("alerts.selectStartDate"));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }

    const errorDate = moment(startDate).isAfter(endDate);
    if (errorDate) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.errorDate"));
      dispatch(setTypeAlert("error"));
    } else {
      dispatch(
        asyncLoadingFunction(
          async () => await dispatch(startGetWithdrawals(params, true))
        )
      );
      setDataFilter(params);
    }
  };

  const onCleanFilter = async () => {
    dispatch(setWithdrawalsRedux(null));

    setDataFilter({});
  };
  return (
    <div className={"w-100 mr-4"}>
      <Box sx={{ ml: 3, mt: 2 }} width={1}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box className="filter" p={2} pb={0} sx={{ minHeight: "110px" }}>
              <Filter
                fields={filterFields}
                handleFilter={handleFilter}
                onClean={onCleanFilter}
              />
            </Box>

            <Box mt={1}>
              <TabsWithDrawalRequest
                filterParams={dataFilter}
                setCurrentTab={setCurrentTab}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
