import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  ThemeProvider,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { theme } from "../../custom/customStyles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { getDealers } from "../../../../services/dealers";
import { IDealer } from "../../../../interfaces/cards";
import { setDealersRedux } from "../../../../redux/actions/managementDealersActions";
import { DealerTypeEnum, Roles_Enum } from "../../../../enums/enums";
interface props {
  setDealers: any;
  dealers: number[];
  handleAddDealer: () => void;
  handleDeleteDealer: (i: number) => void;
  operator: number;
  pointType: DealerTypeEnum;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const FormConfigDealers: FC<props> = ({
  operator,
  dealers,
  setDealers,
  handleAddDealer,
  handleDeleteDealer,
  pointType,
}) => {
  const { token, user } = useSelector((state: AppState) => state.auth);
  const { activePointSale } = useSelector(
    (state: AppState) => state.pointsSale
  );
  const [availableDealers, setAvailableDealers] = useState<IDealer[]>([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getAvailableDealers = async () => {
    if (!token || !user) {
      return null;
    }
    const { associatesUsers } = user;
    const associateId =
      associatesUsers?.length > 0 ? associatesUsers[0].associate.id : null;
    let params: any = {
      operator,
      type: pointType,
    };
    if (user.roles.name === Roles_Enum.ASSOCIATE) {
      params = {
        ...params,
        associate: associateId,
      };
    }
    const response = await getDealers(params, token);
    return response;
  };
  useEffect(() => {
    //consultar los vendedores
    getAvailableDealers().then((res) => {
      const currentDealers = [...res.data];

      setAvailableDealers(currentDealers);
      dispatch(setDealersRedux(currentDealers));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mt-3">
      <ThemeProvider theme={theme}>
        <Grid item xs={12}>
          <Box
            display="flex"
            className="pb-1"
            borderBottom={1}
            borderColor="#02D0E7"
          >
            <i
              className="fa-solid fa-circle-info"
              style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
            ></i>
            <p className="txt-primary-md">
              {t(
                activePointSale
                  ? "pointsOfSale.editDealers"
                  : "pointsOfSale.addDealers"
              )}
            </p>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Box display="flex" alignItems="center">
              <p
                className="txt-primary-md"
                style={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  alignSelf: "center",
                }}
              >
                {t("pointsOfSale.addDealer")}
              </p>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  handleAddDealer();
                }}
                style={{ border: "none", outline: "none" }}
              >
                <span className="btn-add">
                  <i className="fa-solid fa-plus" style={{ fontSize: 30 }}></i>
                </span>
              </IconButton>
            </Box>
          </Box>
        </Grid>
        {dealers.map((dealer, index) => (
          <Grid key={index} container spacing={1} mt={1} ml={3}>
            <Grid item xs={6}>
              <label className="label-text">{t("cards.selectDealer")}</label>
              <TextField
                name="user"
                variant="outlined"
                size="small"
                type="text"
                InputLabelProps={{ shrink: false }}
                label={" "}
                fullWidth
                autoComplete="off"
                onChange={(e) => setDealers(e, index)}
                value={dealer || ""}
                select
                defaultValue={""}
                SelectProps={{
                  MenuProps,
                }}
              >
                <MenuItem disabled value=""></MenuItem>
                {availableDealers.length > 0 &&
                  availableDealers
                    .filter((item) => {
                      if (dealer === item.id) {
                        return true;
                      } else {
                        return !dealers.some(
                          (s, i) => i !== index && s === item.id
                        );
                      }
                    })
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
              </TextField>
            </Grid>
            <Grid item xs={0.5} display="flex" mt={2} justifyContent="center">
              <i
                className={"fa fa-trash fa-1x iconDelete"}
                style={{ fontSize: 20 }}
                onClick={() => {
                  handleDeleteDealer(index);
                }}
              />
            </Grid>
          </Grid>
        ))}
      </ThemeProvider>
    </div>
  );
};
