import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { headersIrrigationPv } from "../../../helpers/headersTables";
import { getCopyArrayOrObject } from "../../../utils/utils";
import moment from "moment";
import i18next from "i18next";
import { BalanceType } from "../../../enums/enums";

export const IrrigationWalletDataTable = (totalData: any[]) => {
  const { data } = useSelector((state: AppState) => state.search);

  const headersCvsStyle = headersIrrigationPv.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });
  // Obtener datos por vista
  const dataIrrigation =
    data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalIrrigation = totalData ? getCopyArrayOrObject(totalData) : [];
  const getWalletName = (wallet: number) => {
    return i18next
      .t(
        wallet === BalanceType.REFILL
          ? "associates.refillBalance"
          : "irrigation.bettingBalance"
      )
      .toUpperCase();
  };
  const buildTotalData = (arr: any) => {
    const multiData = [
      {
        columns: headersCvsStyle,
        data: arr.map((data: any) => {
          const array = [
            {
              value: data.id,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.transactionType.name,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: getWalletName(data.meta.wallet),
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.amount,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.newBalance,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.createBy.nameComplete,
              style: { alignment: { horizontal: "center" } },
            },

            {
              value: moment(data.createAt).format("DD/MM/YYYY HH:mm:ss"),
              style: { alignment: { horizontal: "center" } },
            },
          ];
          return array;
        }),
      },
    ];
    return multiData;
  };

  const multiTotalData = buildTotalData(dataTotalIrrigation);
  const multiData = buildTotalData(dataIrrigation);
  const finalData = {
    dataPerView: dataIrrigation,
    dataTotal: dataTotalIrrigation,
    headersCSV: headersIrrigationPv,
    multiDataSet: multiData,
    multiTotalDataSet: multiTotalData,
  };
  return finalData;
};
