import i18next from "i18next";
import {
  CountryCode,
  StateTransaction,
  TransactionTypes,
} from "../enums/enums";
import { IRefills } from "../interfaces/refills";
import { IPlayerActivityData } from "../interfaces/playerActivity";
import { IWithdrawalData } from "../interfaces/withdrawals";

export const getCopyArrayOrObject = (arrayOrObject: any) => {
  return JSON.parse(JSON.stringify(arrayOrObject));
};
export const sliceArray = (chunks: number, array: any[]) => {
  const result = array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunks);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
  return result;
};

export const regLetters = (key: string) => {
  return new RegExp(/^[A-Za-z\u00C0-\u017F\s]+$/g).test(key);
};

export const regNumbers = (key: string) => {
  return new RegExp(/^[0-9\b.]+$/).test(key);
};
export const regNumbersReals = (key: string) => {
  return new RegExp(/^[0-9\b.-]+$/).test(key);
};

export const regLettersNumbers = (key: string) => {
  return new RegExp(/^[A-Za-z\u00C0-\u017F0-9\s]+$/g).test(key);
};

export const regNit = (key: string) => {
  return new RegExp(/^[A-Za-z\u00C0-\u017F0-9\s-]*$/i).test(key);
};

export const regEmail = (key: string) => {
  return new RegExp(/^[A-Za-z\u00C0-\u017F0-9._@-]*$/i).test(key);
};

export const regPassport = (key: string) => {
  return new RegExp(/^[a-zA-Z0-9]$/g).test(key);
};
export const regCIP = (key: string) => {
  return new RegExp(/^[a-zA-Z0-9_-]+$/g).test(key);
};

export const regAddress = (key: string) => {
  return new RegExp(/^[A-Za-z\u00C0-\u017F0-9\s,#.'_!-]*$/i).test(key);
};

export const regUsername = (key: string) => {
  return new RegExp(/^[^ <>#{}[\]]*$/).test(key);
};

export const regIcons = (key: string) => {
  return new RegExp(/^[A-Za-z0-9\s-]+$/g).test(key);
};

export const regUrl = (key: string) => {
  return new RegExp(/^[A-Za-z0-9+-/]*$/g).test(key);
};

export const regSpecialCharacters = (key: string) => {
  return new RegExp(/[`!@#$%^&*()_+\-={};':"\\|,.<>?~]/).test(key);
};

export const regIp = (key: string) => {
  return new RegExp(/^[0-9+.\b]+$/).test(key);
};

export const regPercentage = (key: string) => {
  return new RegExp(/^(\d*[.]\d*|)\d*$/gm).test(key);
};

export const regPassword = (key: string) => {
  return new RegExp(/^[^ <>{}[\]]*$/).test(key);
};

export const regDecimal = (key: string) => {
  return new RegExp(/^[0-9\b.]+$/).test(key);
};

export const removeAccents = (string: string) => {
  const accents: any = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
  };

  return string
    .split("")
    .map((letter) => accents[letter] || letter)
    .join("")
    .toString();
};
export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const createRamdomCode = (n: number): string => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let rs = "";

  for (let i = 0; i < n; i++) {
    const index = Math.floor(Math.random() * characters.length);
    rs += characters.charAt(index);
  }

  return rs;
};

export const roundNumberDown = (number: number) => {
  const factor = Math.pow(10, 2);
  return Math.trunc(number * factor) / factor;
};

export const roundCommission = (commission: number): number => {
  // Round the commission to two decimal places
  const roundedCommission = commission.toFixed(2);

  // Convert the rounded commission back to a number
  const finalCommission = parseFloat(roundedCommission);

  return finalCommission;
};
export const validatePhoneByCountry = (code: string, phone: string) => {
  const validLength = phone.length >= 8 && phone.length <= 10;
  switch (code) {
    case CountryCode.EL_SALVADOR:
      const lengthPhoneSV = 10;
      return {
        valid: phone.length <= lengthPhoneSV,
        length: lengthPhoneSV,
        startOf: null,
      };

    case CountryCode.HONDURAS:
      const startOfHD = ["2", "3", "7", "8", "9"];
      return {
        valid: validLength && startOfHD.includes(phone[0]),
        length: 8,
        startOf: startOfHD,
      };

    case CountryCode.GUATEMALA:
      const startOfGT = ["2", "6", "7"];

      return {
        valid: validLength && ["2", "6", "7"].includes(phone[0]),
        length: 8,
        startOf: startOfGT,
      };

    case CountryCode.PANAMA:
      const validPhonePA = phone.length >= 8 && phone.length <= 10;
      return {
        valid: validPhonePA,
        length: 8,
        startOf: null,
      };

    default:
      return {
        valid: validLength,
        length: 8,
        startOf: null,
      };
  }
};

export const buildFullName = (...nameParts: (string | null)[]): string => {
  const nonEmptyParts = nameParts.filter((part) => part && part.trim() !== "");
  return nonEmptyParts.join(" ");
};
export const getMovementType = (typeId: number) => {
  const outputTypes = [
    TransactionTypes.REFOUND_WITHDRAWAL_REJECT,
    TransactionTypes.WITHDRAWAL_BANK,
    TransactionTypes.WITHDRAWAL_CASH,
    TransactionTypes.BET_PAYMENT,
    TransactionTypes.BET_CANCELLATION,
  ];

  let movementType = i18next.t("notifications.Input");
  if (outputTypes.includes(typeId)) {
    movementType = i18next.t("notifications.Output");
  }
  return movementType;
};
export const getBarcodeCard = (value: string) => {
  const parseValue = parseInt(value);
  switch (parseValue) {
    case 5:
      return "7452105500016";
    case 10:
      return "7452105500023";
    case 20:
      return "7452105500030";
    default:
      return "7452105500016";
  }
};
export const buildFinalQueryParams = (params: any) => {
  let finalValues: any = {};
  const exeptionsTypes = ["number", "boolean"];
  Object.entries(params).forEach(([key, value], _) => {
    //@ts-ignore
    if (
      exeptionsTypes.includes(typeof value) ||
      (!!value && (value as string).length > 0)
    ) {
      //@ts-ignore
      finalValues[key] = value;
    }
  });
  return finalValues;
};
export const calculateTax = (value: string, tax: string) => {
  const taxValue = (parseFloat(value) * parseFloat(tax)) / 100;
  const result = parseFloat(value) - taxValue;

  // Limitar a máximo 2 decimales sin redondeo
  const roundedResult = parseFloat(result.toFixed(2));

  return roundedResult;
};
export const calculateTaxValue = (value: string, tax: string) => {
  const taxValue = (parseFloat(value) * parseFloat(tax)) / 100;
  return taxValue.toFixed(4).slice(0, -2);
};

export const getStateSettingName = (state: StateTransaction): string => {
  switch (state) {
    case StateTransaction.Approved:
      return i18next.t("state.realized");

    case StateTransaction.Pending:
      return i18next.t("state.pending");

    case StateTransaction.Refused:
      return i18next.t("state.refused");

    case StateTransaction.Expired:
      return i18next.t("state.expired");
    case StateTransaction.REVERSE:
      return i18next.t("state.reverse");

    default:
      return i18next.t("state.realized");
  }
};

export const buildRedName = (
  row: IRefills | IPlayerActivityData | IWithdrawalData
) => {
  if (row.pointSale) {
    return row.pointSale.name;
  }
  if (row.externalUser) {
    return `${row.provider?.name} - ${row.externalUser.name}`;
  }
  return row.provider?.name;
};
