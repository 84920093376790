import { useSelector } from "react-redux";
import { State } from "../../../enums/enums";
import { headersOperatorConfig } from "../../../helpers/headersTables";
import { AppState } from "../../../redux/reducers/rootReducer";
import { getCopyArrayOrObject } from "../../../utils/utils";

export const OperatorConfigDataTable = () => {
  let { brands, totalBrands } = useSelector(
    (state: AppState) => state.managementCompanies
  );

  let { data } = useSelector((state: AppState) => state.search);

  const copyBrands = data
    ? getCopyArrayOrObject(data)
    : getCopyArrayOrObject(brands);
  const copyTotalBrands = getCopyArrayOrObject(totalBrands);
  const headersCSVBrands = headersOperatorConfig;

  //   COLUMNS HEADERS STYLES
  const headersCvsStyle = headersCSVBrands.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });
  // Obtener datos por vista
  const dataBrands = copyBrands
    ? copyBrands.data.map((brand: any) => {
        brand.countriesCSV = brand.countries.reduce(
          (acc: any, country: any) => {
            // GAMES
            country.games = country.countriesGames
              ?.map((item: any) =>
                item.paramGame ? item.paramGame.game.name : ""
              )
              .toString()
              .split(",")
              .join("-");

            // CURRENCIES
            country.currencies = country.countriesCurrencies
              ?.map((item: any) =>
                item.currency ? item.currency.currency : ""
              )
              .toString()
              .split(",")
              .join("-");

            // PAYMENTS DEPOSITS
            country.deposits = country.paymentMethodsRecharges
              ?.filter((item: any) => item.state === State.ACTIVE)
              .map((item: any) =>
                item.paramMethod ? item.paramMethod.paymentMethod.name : ""
              )
              .toString()
              .split(",")
              .join("-");

            // PAYMENTS WITHDRAWAL
            country.payments = country.paymentMethodsWithdrawal
              ?.filter((item: any) => item.state === State.ACTIVE)
              .map((item: any) =>
                item.paramMethod ? item.paramMethod.paymentMethod.name : ""
              )
              .toString()
              .split(",")
              .join("-");
            // SOLUTIONS
            country.solutions = country.countriesSolutions
              ?.map((item: any) =>
                item.paramSolution ? item.paramSolution.solution.name : ""
              )
              .toString()
              .split(",")
              .join("-");

            return country;
          },
          {}
        );

        return brand;
      })
    : [];

  // Obtener el total de datos
  const dataTotalBrands = copyTotalBrands
    ? copyTotalBrands.data.map((brand: any) => {
        brand.countriesCSV = brand.countriesBrands.reduce(
          (acc: any, country: any) => {
            country.name = country.country.name;
            // GAMES
            country.games = country.countriesGames
              ?.map((item: any) =>
                item.paramGame ? item.paramGame.game.name : ""
              )
              .toString()
              .split(",")
              .join("-");

            // CURRENCIES
            country.currencies = country.countriesCurrencies
              ?.map((item: any) =>
                item.currency ? item.currency.currency : ""
              )
              .toString()
              .split(",")
              .join("-");

            // PAYMENTS DEPOSITS
            country.deposits = country.countriesPayments
              ?.filter(
                (item: any) =>
                  item.state === State.ACTIVE &&
                  item.paramMethod &&
                  item.paramMethod.paymentMethod.type !== "withdrawal"
              )
              .map((item: any) => item.paramMethod.paymentMethod.name)
              .toString()
              .split(",")
              .join("-");

            // PAYMENTS WITHDRAWAL
            country.payments = country.countriesPayments
              ?.filter(
                (item: any) =>
                  item.state === State.ACTIVE &&
                  item.paramMethod &&
                  item.paramMethod.paymentMethod.type === "withdrawal"
              )
              .map((item: any) => item.paramMethod.paymentMethod.name)
              .toString()
              .split(",")
              .join("-");
            // SOLUTIONS
            country.solutions = country.countriesSolutions
              ?.map((item: any) =>
                item.paramSolution ? item.paramSolution.solution.name : ""
              )
              .toString()
              .split(",")
              .join("-");

            return country;
          },
          {}
        );
        return brand;
      })
    : [];

  // DATA POR VISTA
  const multiDataSetBrands = [
    {
      columns: headersCvsStyle,
      data: dataBrands.map((data: any) => {
        let array;
        array = array = [
          {
            value: data.operator.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.countriesCSV.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.countriesCSV.games,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.countriesCSV.currencies,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.countriesCSV.deposits,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.countriesCSV.payments,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.countriesCSV.solutions,
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  //   DATA TOTAL
  const multiTotalDataSetBrands = [
    {
      columns: headersCvsStyle,
      data: dataTotalBrands.map((data: any) => {
        let array;
        array = [
          {
            value: data.operator.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.countriesCSV.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.countriesCSV.games,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.countriesCSV.currencies,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.countriesCSV.deposits,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.countriesCSV.payments,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.countriesCSV.solutions,
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];

  return {
    dataBrands,
    dataTotalBrands,
    headersCSVBrands,
    multiDataSetBrands,
    multiTotalDataSetBrands,
  };
};
