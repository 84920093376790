import {
  IExclusionAuditQuery,
  IExclusionsAuditData,
} from "../interfaces/exclusionsAudit";

export const createExclusionAudit = (
  token: string,
  data: IExclusionsAuditData
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}exclusionsAudit`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const getExclusionsAudit = (
  token: string,
  params: Partial<IExclusionAuditQuery>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}exclusionsAudit?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
