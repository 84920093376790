import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Roles_Enum, State } from "../../../enums/enums";
import { getOperatorsService } from "../../../services/managementCompanyService";
import {
  setActiveCompany,
  setCompaniesRedux,
  startEditOperator,
} from "../../../redux/actions/managementCompanyActions";
import { regNumbers } from "../../../utils/utils";
import { IOperationManagForm } from "../../../interfaces/operationCompany";

const ITEM_HEIGHT = 42;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const FormDepostiPercentage = () => {
  const { activeCompany } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const [isLoading, setIsLoading] = useState(false);
  const [percentage, setPercentage] = useState(
    activeCompany ? activeCompany.percentage : ""
  );
  const { user, token } = useSelector((state: AppState) => state.auth);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const [operator, setOperator] = useState(isSuper ? null : user?.operator.id);
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    token && fetchOperators(token);
    if (activeCompany) {
      setOperator(activeCompany.id);
    }
    return () => {
      dispatch(setCompaniesRedux(null));
      dispatch(setActiveCompany(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateDepositPercentage = async () => {
    if (operator) {
      setIsLoading(true);
      const objEditOperator: Partial<IOperationManagForm> = {
        withdrawalPercentage: percentage && parseFloat(percentage),
      };
      await dispatch(startEditOperator(objEditOperator, operator, true));
    }
    setIsLoading(false);
  };

  const handleChangeSelectOperator = (e: SelectChangeEvent<number>) => {
    setOperator(Number(e.target.value));
  };

  return (
    <Grid container spacing={2}>
      {/* {isSuper && !activeBonusType && ( */}
      {isSuper && !activeCompany && (
        <>
          {companies && (
            <Grid item xs={4}>
              <label className="ml-3">
                {t("companyManagement.operator") + "*"}
              </label>
              <FormControl
                sx={{ width: "100%" }}
                size="small"
              >
                <Select
                  name="operator"
                  size="small"
                  fullWidth
                  onChange={handleChangeSelectOperator}
                  value={operator || ""}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  MenuProps={MenuProps}
                >
                  {companies?.data?.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </>
      )}
      <Grid item xs={4}>
        <label className="ml-3">
          {t("parameterization.valuePercentage") + "*"}
        </label>
        <TextField
          variant="outlined"
          size="small"
          label=" "
          InputLabelProps={{
            shrink: false,
          }}
          onKeyPress={(e: any) => {
            if (
              !regNumbers(e.key) ||
              (e.target.value.length === 0 && e.key === "0")
            ) {
              return e.preventDefault();
            }
          }}
          fullWidth
          value={percentage}
          onChange={(e) => setPercentage(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="flex-end" mt={20}>
        {isLoading ? (
          <CircularProgress className={"colorLoading"} />
        ) : (
          <Button
            style={{
              border: "none",
              outline: "none",
              fontSize: 14,
            }}
            variant="contained"
            className="btn-dialog primary-btn"
            onClick={handleCreateDepositPercentage}
            disabled={percentage === ""}
          >
            {t(
              !!activeCompany
                ? "button.editParameters"
                : "button.createParameters"
            )}
            <i className="fa-solid fa-check-circle ml-2"></i>
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
