import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { CircularProgress, TableCell, Tooltip } from "@mui/material";
import { IConciliation } from "../../../interfaces/commercialNetworks";
import { capitalizeFirstLetter } from "../../../utils/utils";
import { downloadExcel } from "../../../services/commercialNetworks";
interface Props {
  row: IConciliation;
  handleOpenModal: (row: IConciliation) => void;
}

export const RowDataTableConciliations: FC<Props> = ({
  row,
  handleOpenModal,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleDownloadExcel = async (row: IConciliation) => {
    setLoading(true);
    try {
      const params = {
        conciliation: row.id.toString(),
        conciliationDate: row.conciliationDate,
        network: row.externalUser.id.toString(),
        timezone: row.externalUser.timezone,
        type: row.type,
      };
      const response = (await downloadExcel(params)) as any;

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();

      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute(
        "download",
        `Conciliacion${capitalizeFirstLetter(row.externalUser.name)}_${
          row.conciliationDate
        }.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el archivo", error);
    }
    setLoading(false);
  };

  return (
    <>
      <TableCell className="word-break">{row.id}</TableCell>
      <TableCell className="word-break">
        {capitalizeFirstLetter(row.externalUser.name)}
      </TableCell>
      <TableCell className="word-break">{row.conciliationDate}</TableCell>
      <TableCell className="word-break">
        {moment(row.createAt).format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
      <TableCell className="word-break">
        {t(`conciliation.${row.type}`)}
      </TableCell>
      <TableCell>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={"Ver resumen"} arrow>
            <div>
              <i
                className="fa fa-search-plus fa-lg mr-2 iconSeeMore"
                onClick={() => handleOpenModal(row)}
              />
            </div>
          </Tooltip>
          <Tooltip title={t("button.download")} arrow>
            <div>
              <button
                onClick={() => handleDownloadExcel(row)}
                className="buttonActive"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100px",
                  justifyContent: "center",
                }}
              >
                {loading ? (
                  <CircularProgress size={"1.6rem"} color="inherit" />
                ) : (
                  <>
                    <i className="fa-solid fa-file-export mr-1" />
                    {t("conciliation.report")}
                  </>
                )}
              </button>
            </div>
          </Tooltip>
        </div>
      </TableCell>
      <TableCell />
    </>
  );
};
