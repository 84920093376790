import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Enum_FieldType, IFilterField } from "../../../interfaces/components";
import {
  IConciliation,
  IExternalUsersData,
} from "../../../interfaces/commercialNetworks";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { asyncLoadingFunction } from "../../../redux/actions/helperActions";
import {
  startGetConciliations,
  startGetExternalUsers,
} from "../../../redux/actions/commercialNetworkActions";
import Filter from "../../../components/Filter/Filter";
import { IQueryParams } from "../../../services/commercialNetworks";
import MyCustomTable, {
  IColumns,
} from "../../../components/Custom/Table/MyCustomTable";
import { RowDataTableConciliations } from "./RowDataTableConciliations";
import { MyCustomDialog } from "../../../components/Custom/MyCustomDialog";
import { ConciliationSummary } from "./ConciliationSummary";

export const Conciliations = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: AppState) => state.auth);
  const { networks, conciliations } = useSelector(
    (state: AppState) => state.commercialNetworks
  );
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);
  const [dataFilter, setDataFilter] = useState<any>({});
  const [, setFilter] = useState(false);
  const [resetPage, setResetPage] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [externalUsers, setExternalUsers] = useState<Array<IExternalUsersData>>(
    []
  );
  const [conciliation, setConciliation] = useState<IConciliation | null>(null);

  const filterFields: IFilterField[] = [
    {
      label: t("commercialNetworks.commercialNetwork"),
      type: Enum_FieldType.SELECT,
      options: [
        ...externalUsers.map((network) => ({
          label: network.name,
          value: network.id,
        })),
      ],
    },
    {
      label: t("table.date"),
      type: Enum_FieldType.DATE,
    },
  ];

  let columns: IColumns[] = [
    { align: "left", label: t("conciliation.id") },
    { align: "left", label: t("form.network") },
    { align: "left", label: t("conciliation.date") },
    { align: "left", label: t("user.createAt") },
    { align: "left", label: t("transactions.type") },
    { align: "left", label: t("table.actions") },
  ];

  useEffect(() => {
    dispatch(
      asyncLoadingFunction(async () => {
        await dispatch(
          startGetExternalUsers({ operatorId: user?.operator?.id })
        );
        await dispatch(
          startGetConciliations({ operatorId: user?.operator?.id, page: 1 })
        );
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (networks?.data) {
      setExternalUsers(networks?.data);
    }
  }, [networks]);

  const handleFilter = async (values: any) => {
    setResetPage(false);
    let params: Partial<IQueryParams> = {
      page: 1,
      operatorId: user?.operator?.id.toString(),
      network: values[t("commercialNetworks.commercialNetwork")] ?? "",
      date: values[t("table.date")] ?? "",
    };

    dispatch(
      asyncLoadingFunction(
        async () => await dispatch(startGetConciliations(params))
      )
    );

    setDataFilter(params);
    setFilter(true);
    setResetPage(true);
  };

  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(
        async () =>
          await dispatch(
            startGetConciliations({
              ...dataFilter,
              page,
              perPage: rows || rowsPerPage,
            })
          )
      )
    );
    setResetPage(false);
  };

  const onCleanFilter = async () => {
    setResetPage(false);
    dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startGetConciliations({ page: 1, operatorId: user?.operator?.id })
        )
      )
    );
    setDataFilter({});
    setFilter(false);
    setResetPage(true);
  };

  const handleOpenModal = (row: IConciliation) => {
    setOpenModal(true);
    setConciliation(row);
  };

  return (
    <div className="content w-100 mb-4">
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Row>
            <Col xs={12}>
              <MyBreadCrumbs
                titles={[t("sidebar.settings"), t("shorcuts.conciliations")]}
              ></MyBreadCrumbs>
            </Col>{" "}
          </Row>
        </Col>
      </Row>

      <div className="filter mt-2" style={{ minHeight: "70vh" }}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} className="mb-3">
            <Box
              display="flex"
              className="pb-1"
              borderBottom={1}
              borderColor="#02D0E7"
            >
              <i
                className="fa-solid fa-circle-info"
                style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
              ></i>
              <p className="txt-primary-md">{t("shorcuts.conciliations")}</p>
            </Box>
          </Grid>

          <Filter
            fields={filterFields}
            handleFilter={handleFilter}
            onClean={onCleanFilter}
          />

          <Grid item xs={12} mt={-2}>
            {conciliations && conciliations.data.length > 0 && (
              <MyCustomTable
                columns={columns}
                data={conciliations.data}
                lastPage={conciliations.last_page}
                totalData={conciliations.total}
                renderBody={(item) => (
                  <RowDataTableConciliations
                    row={item}
                    handleOpenModal={handleOpenModal}
                  />
                )}
                onPageChange={handlePageChange}
                resetCurrentPage={resetPage}
              />
            )}
          </Grid>
        </Grid>

        {conciliation && (
          <MyCustomDialog
            open={openModal}
            content={<ConciliationSummary conciliation={conciliation} />}
            onClose={() => setOpenModal(false)}
            title={"RESUMEN CONCILIACION"}
            width={"md"}
            icon={
              <i
                className="fa-solid fa-circle-info"
                style={{ color: "#02D0E7", marginRight: 9, fontSize: 20 }}
              ></i>
            }
          />
        )}
      </div>
    </div>
  );
};
