import { useState, useEffect } from "react";
import { Alert, AlertTitle, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../../../../../components/Filter/Filter";
import { TableComponent } from "../../../../../components/Table/TableComponent";
import {
  Enum_FieldType,
  IColumnsTable,
  IFilterField,
  Icons,
} from "../../../../../interfaces/components";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import InfoIcon from "@mui/icons-material/Info";
import "../tabsGamingActivity.scss";
import { Row } from "reactstrap";
import { MyDialogInformation } from "../../../../../components/DialogInformation/MyDialogInformation";
import { setBetsRedux } from "../../../../../redux/actions/betsActions";
import { BetMode, BetType } from "../../../../../enums/enums";
import { betStatuses } from "../../../../../helpers/betStatus";
import { setCloseAlert } from "../../../../../redux/actions/helperActions";

export const TabBetting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { player } = useSelector((state: AppState) => state.managementPlayers);
  const { bets } = useSelector((state: AppState) => state.bets);
  const [dataFilter, setDataFilter] = useState<any>({});

  /**
   * Datos inputs para filtro
   */
  const FilterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("table.betId"),
      type: Enum_FieldType.NUMBER,
    },
    {
      label: t("bets.sport"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("footer.sportsBets"),
          value: BetMode.SPORTS_BETTING,
        },
        {
          label: t("footer.virtualSports"),
          value: BetMode.VIRTUAL_SPORTS,
        },
      ],
    },
    {
      label: t("bets.betType"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("betType.simple"),
          value: BetType.SIMPLE,
        },
        {
          label: t("betType.multiple"),
          value: BetType.MULTIPLE,
        },
        {
          label: t("betType.system"),
          value: BetType.SYSTEM,
        },
      ],
    },
    {
      label: t("bets.event"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("table.state"),
      type: Enum_FieldType.SELECT,
      options: [
        ...betStatuses.map((status) => ({
          label: status.description,
          value: status.value,
        })),
      ],
    },
    // {
    //   label: t("bets.bonus"),
    //   type: Enum_FieldType.RADIO,
    // },
    {
      label: t("bets.betAmount"),
      type: Enum_FieldType.NUMBER,
    },
    {
      label: t("bets.winningAmount"),
      type: Enum_FieldType.NUMBER,
    },
    // {
    //   label: t("bets.platform"),
    //   type: Enum_FieldType.TEXT,
    // },
    {
      label: t("bets.market"),
      type: Enum_FieldType.TEXT,
    },
  ];
  /**
   * Soporta las Columnas para renderizar en el header de la tabla
   */
  const columns: IColumnsTable[] = [
    { id: 1, header: "table.betId" },
    { id: 2, header: "user.createAt" },
    { id: 3, header: "bets.betType" },
    { id: 4, header: "bets.event" },
    { id: 5, header: "bets.betAmount" },
    { id: 6, header: "bets.winningAmount" },
    { id: 7, header: "bets.feeds" },
    { id: 8, header: "bets.bonus" },
    // { id: 9, header: "bets.cashout" },
    { id: 9, header: "table.state" },
    { id: 10, header: "table.actions" },
  ];

  const iconos: Icons = {
    see: false,
    delete: false,
    seeMore: true,
    edit: false,
  };

  useEffect(() => {
    if (!player) {
      dispatch(setBetsRedux(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  useEffect(() => {
    dispatch(setCloseAlert());
    return () => {
      dispatch(setCloseAlert());
      dispatch(setBetsRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid">
      <Row
        style={{
          marginTop: "12px",
          pointerEvents: player && player.data.length > 0 ? "all" : "none",
          opacity: player && player.data.length > 0 ? 1 : 0.4,
        }}
      >
        <Filter
          fields={FilterFields}
          component={"bets"}
          setDataFilter={setDataFilter}
        />
      </Row>
      {bets && bets.data.length > 0 ? (
        <>
          <TableComponent
            columns={columns}
            data={bets.data}
            pages={(bets && bets.last_page) || 1}
            component={"bets"}
            totalData={bets ? bets : []}
            subMenu={false}
            icons={iconos}
            isSortingTable={true}
            dataFilter={dataFilter}
          />
        </>
      ) : (
        <Grid item xs={12} pt={3} mt={-2}>
          <Stack
            sx={{ width: "100%" }}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
            spacing={2}
          >
            <Alert
              icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
              sx={{
                borderLeft: `7px solid #2C97DF`,
                backgroundColor: "#EAF5FC",
                color: "#4b4b4b",
              }}
            >
              <div className="d-flex">
                <AlertTitle
                  style={{ marginTop: "5px" }}
                  sx={{ color: "#333333" }}
                >
                  {t("alerts.clickButton")}
                </AlertTitle>
                <AlertTitle
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  sx={{ color: "#333333", fontWeight: "bold" }}
                >
                  {t("alerts.refillBets")}
                </AlertTitle>
              </div>
            </Alert>
          </Stack>
        </Grid>
      )}
      <MyDialogInformation component={"bets"} title={"bets.betDetail"} />
    </div>
  );
};
