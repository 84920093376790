import { TableCell } from "@mui/material";
import React, { FC } from "react";
import { IPlayerUpdateHistoryData } from "../../../interfaces/players";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface props {
  row: IPlayerUpdateHistoryData;
}
export const RowPlayerUpdateHistory: FC<props> = ({ row }) => {
  const { t } = useTranslation();
  const { newPlayerData } = row;

  const buildDetailUpdate = () => {
    const { id, ...restData } = newPlayerData;
    return Object.entries(restData)
      .map(([key, value]) => `  ${t(`player.${key}`)} = ${value}`)
      .join(", ");
  };
  return (
    <>
      <TableCell align="center">{row.player.id}</TableCell>
      <TableCell
        align="center"
        sx={{
          width: "30vw",
          wordWrap: "break-word",
          whiteSpace: "normal",
        }}
      >
        {buildDetailUpdate()}
      </TableCell>

      <TableCell align="center">{row.observation}</TableCell>
      <TableCell align="center">{row.responsible.username}</TableCell>
      <TableCell align="center">
        {moment(row.createAt).format("YYYY-MM-DD hh:mm:ss A")}
      </TableCell>
      <TableCell align="center">
        {row.attachFiles ? (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={row.attachFiles?.url}
          >
            {t("button.download")}
          </a>
        ) : (
          ""
        )}
      </TableCell>
    </>
  );
};
