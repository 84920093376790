import {
  getSegmentations,
  sendMessageWithSegmentation,
} from "../../services/segmentations";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import { setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";

export const setSegmentations = (segmentations: any) => ({
  type: Types.setSegmentations,
  payload: segmentations,
});

export const startGetSegmentations = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    try {
      if (token) {
        const response = await getSegmentations(token, params);
        dispatch(setSegmentations(response));
      }
    } catch (error) {
      dispatch(setTypeAlert("error"));
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startSendMessages = (body: object) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    try {
      if (token) {
        await sendMessageWithSegmentation(token, body);
      }
      dispatch(setTypeAlert("success"));
      dispatch(setMessageAlert("alerts.segmentationMessagesSentSuccessfully"));
      dispatch(setOpenAlert())
    } catch (error) {
      dispatch(setTypeAlert("error"));
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setOpenAlert());
    }
  };
};
