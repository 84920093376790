import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import Filter from "../../../components/Filter/Filter";
import { FormCreateMenu } from "../../components/ManagementMenu/FormCreateMenu";
import { TableComponent } from "../../../components/Table/TableComponent";
import {
  Enum_FieldType,
  IColumnsTable,
  Icons,
  IFilterField,
} from "../../../interfaces/components";
import { AppState } from "../../../redux/reducers/rootReducer";
import "./managementMenu.scss";
import {
  getMenus,
  setActiveMenu,
} from "../../../redux/actions/managementMenuActions";
import { setActiveUser } from "../../../redux/actions/managementUsersActions";
import {
  setActiveStep,
  setCloseAlert,
  setFlagForm,
  setFlagFormEdit,
  setLoadingState,
  setRowsPerPage,
} from "../../../redux/actions/helperActions";
import { MyAlert } from "../../components/custom/MyAlert";
import { State } from "../../../enums/enums";
import routes from "../../../routes";
import { arraySearch } from "../../../redux/actions/searchActions";
import { useLocation } from "react-router-dom";
import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { MyDialogInformation } from "../../../components/DialogInformation/MyDialogInformation";

const getSteps = () => {
  return ["1", "2", "3"];
};

export const ManagementMenu = () => {
  const { t } = useTranslation();
  const steps = getSteps();
  const { flagFormCreate, openAlert, messageAlert, typeAlert, activeStep } =
    useSelector((state: AppState) => state.helper);
  const { data } = useSelector((state: AppState) => state.search);
  const dispatch = useDispatch();
  const [dataFilter, setDataFilter] = useState<any>({});
  const { routes: RoutesRedux } = useSelector(
    (state: AppState) => state.managementMenus
  );
  const { pathname } = useLocation();

  const modulePermissions = RoutesRedux.find(
    (route) => !!route.menuItem.find((module) => module.url === pathname)
  )?.menuItem.find((module) => module.url === pathname);
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;

  /**
   * Lista de Menus consultados en redux para asignar a la tabla
   */
  const { menus, activeMenu } = useSelector(
    (state: AppState) => state.managementMenus
  );
  const { token } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    dispatch(setCloseAlert());
    dispatch(setRowsPerPage(9));
    dispatch(setActiveStep(0));
    dispatch(setActiveMenu(null));
    dispatch(setActiveUser(null));
    dispatch(arraySearch(null));
    dispatch(setFlagForm({ flagFormCreate: false }));
    if (token) {
      dispatch(setLoadingState({ loading: true }));
      dispatch(getMenus(token, 1));
      dispatch(setLoadingState({ loading: false }));
    }
    return () => {
      dispatch(setFlagForm({ flagFormCreate: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stylesStepper = {
    "& .MuiStepConnector-line": {
      height: "180px",
      width: "2px",
      border: "1px solid #C0C0C0",
    },
    "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
      border: "1px solid  #0BC5DA",
      height: "180px",
      width: "0px",
    },
    "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
      border: "1px solid  #0BC5DA",
      height: "180px",
      width: "0px",
    },
    "& .MuiStepLabel-root .Mui-active": {
      color: "#0BC5DA",
      fontSize: 30,
      borderRadius: "50%",
      border: "1px solid #35D9EC",
      overflow: "hidden",
      padding: "1px",
      background: "#fff",
    },
    "& .MuiStepLabel-root .Mui-completed": {
      color: "#0BC5DA",
    },
  };
  /**
   * Soporta la información para los placeholders de inputs en el componente filtro
   */
  const filterFields: IFilterField[] = [
    {
      label: t("menuManagment.menu"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("form.description"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("table.url"),
      type: Enum_FieldType.SELECT,
      options: routes
        .filter((route) => route.layout !== "/auth")
        .map((route) => ({
          label: route.layout + route.path,
          value: route.layout + route.path,
        })),
    },
    {
      label: t("form.state"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("state.active"),
          value: State.ACTIVE,
        },
        {
          label: t("state.inactive"),
          value: State.INACTIVE,
        },
      ],
    },
  ];

  /* routes.filter((route) => route.layout !== "/auth") */

  /**
   * Soporta las Columnas para renderizar en el header de la tabla
   */

  const columns: IColumnsTable[] = [
    { id: 1, header: "table.menu" },
    { id: 2, header: "table.url" },
    { id: 3, header: "table.icon" },
    { id: 5, header: "table.createdBy" },
    { id: 6, header: "table.state" },
    { id: 7, header: "table.actions" },
  ];

  /**
   * Soporta operador terniario para los iconos de acciones en tabla
   */

  const iconos: Icons = {
    add: false,
    delete: false,
  };

  const handleResetSteps = () => {
    dispatch(setActiveStep(0));
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <FormCreateMenu menu handleReset={handleResetSteps} />;
      case 1:
        return <FormCreateMenu module handleReset={handleResetSteps} />;
      case 2:
        return <FormCreateMenu confirm handleReset={handleResetSteps} />;
      default:
        return;
    }
  };

  /**
   * Soporta el evento click para cerrar la ventana de alerta
   */
  const onCloseAlertMenu = async () => {
    await dispatch(setCloseAlert());
    if (typeAlert === "success") {
      dispatch(setFlagForm({ flagFormCreate: false }));
      dispatch(setFlagFormEdit({ flagFormEdit: false }));
      handleResetSteps();
    }
  };

  const handleOpenForm = () => {
    dispatch(setFlagForm({ flagFormCreate: true }));
    dispatch(setActiveMenu(null));
  };

  return (
    <div className="content w-100 mb-5">
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Row style={{ marginBottom: 2 }}>
            <Col xs={12}>
              <MyBreadCrumbs
                titles={[t("sidebar.settings"), t("sidebar.ManagementMenu")]}
              >
                {flagFormCreate && (
                  <Typography color="text.primary" style={{ fontSize: "16px" }}>
                    {t(
                      !!activeMenu
                        ? "menuManagment.editMenu"
                        : "button.createMenu"
                    )}
                  </Typography>
                )}
              </MyBreadCrumbs>
            </Col>
          </Row>

          {flagFormCreate ? (
            <Grid container>
              <Grid item xs={1} sm={1} md={0.5} mb={2} pl={1}>
                <Stepper
                  activeStep={activeStep}
                  orientation={"vertical"}
                  sx={stylesStepper}
                >
                  {steps.map((label, index: number) => (
                    <Step key={label}>
                      <StepLabel
                        onClick={() => dispatch(setActiveStep(index))}
                      ></StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item xs={11} sm={11} md={11.5}>
                {getStepContent(activeStep)}
              </Grid>
            </Grid>
          ) : (
            <>
              <Row style={{ marginTop: 10, marginBottom: -10 }}>
                <Col xs={12}>
                  <h3 style={{ fontSize: 16, color: "#333333" }}>
                    {t("filters.filters")}
                  </h3>
                </Col>
              </Row>
              <div className="white-container">
                {menus && filterFields.length > 0 ? (
                  <>
                    {enableCreated && (
                      <Row>
                        <Col xs={12}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            mb={2}
                          >
                            <Box display="flex" alignItems="center">
                              <p
                                className="txt-primary-md"
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "uppercase",
                                  alignSelf: "center",
                                }}
                              >
                                {t("button.createMenu")}
                              </p>
                            </Box>
                            <Box>
                              <IconButton
                                onClick={handleOpenForm}
                                id="openFormBtn"
                                style={{ border: "none", outline: "none" }}
                              >
                                <span className="btn-add">
                                  <i
                                    className="fa-solid fa-plus"
                                    style={{ fontSize: 30 }}
                                  ></i>
                                </span>
                              </IconButton>
                            </Box>
                          </Box>
                        </Col>
                      </Row>
                    )}
                    <Filter
                      fields={filterFields}
                      component={"menu"}
                      setDataFilter={setDataFilter}
                    />

                    {data ? (
                      <>
                        {data.data.length > 0 && (
                          <TableComponent
                            columns={columns}
                            data={data.data}
                            pages={data.last_page}
                            icons={iconos}
                            component={"menu"}
                            totalData={data}
                            isSortingTable={true}
                            dataFilter={dataFilter}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {menus && menus.data.length > 0 && (
                          <TableComponent
                            columns={columns}
                            data={menus.data}
                            pages={menus.last_page}
                            icons={iconos}
                            component={"menu"}
                            totalData={menus}
                            isSortingTable={true}
                            dataFilter={dataFilter}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <Grid container className={"paperLoading"}>
                    <Box mx="auto" my="40vh">
                      <CircularProgress className={"colorLoading"} />
                    </Box>
                  </Grid>
                )}
              </div>
            </>
          )}
        </Col>
      </Row>
      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlertMenu}
      />
      <MyDialogInformation
        component={"menu"}
        title={"menuManagment.menuDetail"}
        width="md"
      />
    </div>
  );
};

export default ManagementMenu;
