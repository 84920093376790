import {
  ClassNameMap,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { MenuProps } from "../views/components/custom/bonusStyles";
import { ICompanyData } from "../interfaces/operationCompany";
import { IBonus, UseOperatorFormProps } from "../interfaces/bonus";
import { useEffect } from "react";
import { State } from "../enums/enums";
import { getOperatorsService } from "../services/managementCompanyService";
import { setCompaniesRedux } from "../redux/actions/managementCompanyActions";
import { setFlagForm } from "../redux/actions/helperActions";

export const useOperatorForm = ({
  token,
  user,
  dispatch,
}: UseOperatorFormProps) => {
  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };

    if (token) {
      fetchOperators(token);
    }

    return () => {
      dispatch(setCompaniesRedux(null));
      dispatch(setFlagForm({ flagFormCreate: false }));
    };
  }, [token, user, dispatch]);

  interface OperatorFormProps {
    companies: ICompanyData[] | null;
    isSuper: boolean;
    operatorId: number | null;
    submit: boolean;
    activeBonus: IBonus | null;
    classes: ClassNameMap<
      "infoIcon" | "title" | "titleBox" | "formControl" | "errorLabel"
    >;
    setOperatorId: React.Dispatch<React.SetStateAction<number | null>>;
    t: (key: string) => string;
  }

  const renderOperatorForm = ({
    companies,
    isSuper,
    operatorId,
    submit,
    activeBonus,
    classes,
    setOperatorId,
    t,
  }: OperatorFormProps) => {
    if (!isSuper || !companies) return null;

    return (
      <Grid item xs={4}>
        <FormControl
          className={classes.formControl}
          size="small"
          error={submit && !operatorId}
          disabled={!!activeBonus}
        >
          <InputLabel id="operator-label">
            {t("companyManagement.operator") + "*"}
          </InputLabel>
          <Select
            labelId="operator-label"
            name="operator"
            size="small"
            fullWidth
            onChange={(e: any) => setOperatorId(e.target.value)}
            value={operatorId ?? ""}
            input={
              <OutlinedInput label={t("companyManagement.operator") + "*"} />
            }
            MenuProps={MenuProps}
            disabled={!!activeBonus}
          >
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormHelperText>
          {submit && !operatorId && (
            <label className={classes.errorLabel}>
              {t("form.requiredFile")}
            </label>
          )}
        </FormHelperText>
      </Grid>
    );
  };
  return {
    renderOperatorForm,
  };
};
