import { Types } from "../types/types";
import { ICurrencyOp } from "./../../interfaces/operationCompany";

interface IState {
  currenciesOperator: ICurrencyOp[];
}
interface IAction {
  type: Types;
  payload?: any;
}
const initialState: IState = {
  currenciesOperator: [],
};
export const currenciesReducer = (
  state = initialState,
  action: IAction
): IState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setCurrenciesOperator:
      return {
        ...state,
        currenciesOperator: [...payload],
      };

    default:
      return state;
  }
};
