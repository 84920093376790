import React, { FC, useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { OptionSelectCheckBox } from "../../interfaces/components";

interface props {
  options: OptionSelectCheckBox;
  label: string;
  onChange(e: any): void;
}
export const SelectCheckbox: FC<props> = ({ label, options, onChange }) => {
  const [checklist, setChecklist] = useState(options);
  const { t } = useTranslation();
  useEffect(() => {
    const checked = Object.entries(checklist)
      .filter(([key, value]) => value.check === true)
      .map((item) => {
        return item[1].value;
      });

    onChange({
      target: {
        name: label,
        value: checked,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklist]);

  const handleCheck = (name: string) => {
    const currentOption = checklist[name];
    setChecklist({
      ...checklist,
      [name]: {
        ...currentOption,
        check: !currentOption.check,
      },
    });
  };
  return (
    <FormControl sx={{ width: "100%" }} size="small">
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        id="custom-checklist"
        multiple
        value={Object.entries(checklist)
          .filter(([key, value]) => value.check === true)
          .map(([key, value]) => {
            return `${key}`;
          })}
        //onChange={handleChangePermissions}
        input={<OutlinedInput label={t("rolManagement.permissions")} />}
        renderValue={(selected) => selected.join(", ")}
      >
        {Object.entries(checklist).map(
          // eslint-disable-next-line array-callback-return
          ([name, value], index) => {
            return (
              <MenuItem
                key={index}
                className="check-list-item"
                value={name}
                onClick={() => handleCheck(name)}
              >
                <Checkbox
                  checked={!!value.check}
                  sx={{
                    color: "#DADADA",
                    "&.Mui-checked": {
                      color: "#02D0E7",
                    },
                  }}
                />
                <ListItemText primary={`${name}`} />
              </MenuItem>
            );
          }
        )}
      </Select>
    </FormControl>
  );
};
