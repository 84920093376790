import React, { FC } from "react";
import { TableCell } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import { Roles_Enum } from "../../../../../enums/enums";
interface props {
  row: any;
}
export const RowIrrigationAdminHistory: FC<props> = ({ row }) => {
  const { user } = useSelector((state: AppState) => state.auth);

  const isRoleAssociate = user?.roles.name === Roles_Enum.ASSOCIATE;
  const isRoleDealer = user?.roles.name === Roles_Enum.DEALER;
  const isRoleAdmin = user?.roles.name === Roles_Enum.ADMIN_OPERATOR;
  const isRolePromotor = user?.roles.name === Roles_Enum.PROMOTOR;

  const nameUserIrrigation = (): string => {
    let name: string = "";

    if (isRoleAssociate) {
      name = row.dealerName || row.pointSaleName || "";
    } else if (isRoleDealer) {
      name = row.promotorName ?? row.pointSaleName ?? "";
    } else if (isRoleAdmin) {
      name = row.associateName || "";
    } else if (isRolePromotor) {
      name = row.pointSaleName || "";
    }
    return name;
  };
  const getDateIrrigation = () => {
    if (isRoleAdmin) {
      return row.dateIrrigationAssociate || "";
    } else if (isRolePromotor) {
      return row.dateIrrigationPointSale || "";
    } else if (isRoleDealer) {
      return row.dateIrrigationPromotor ?? row.dateIrrigationPointSale ?? "";
    } else {
      return row.dateIrrigationDealer || row.dateIrrigationPointSale || "";
    }
  };

  return (
    <>
      <TableCell align="center">{nameUserIrrigation()}</TableCell>
      <TableCell align="center">{row.lotId}</TableCell>
      <TableCell align="center">{row.cardAmount}</TableCell>
      <TableCell align="center">
        {row.minCardId}-{row.maxCardId}
      </TableCell>
      <TableCell align="center">{row.redeemedCards}</TableCell>
      <TableCell align="center">{row.activeCards}</TableCell>
      <TableCell align="center">{row.inactiveCards}</TableCell>
      <TableCell align="center">
        {moment(getDateIrrigation()).format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
    </>
  );
};
