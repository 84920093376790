import moment from "moment";
import { Provider } from "react-redux";

import { AppRouter } from "./routers/AppRouter";
import ReduxStore from "./store/store";
import "moment/locale/es";
import { PersistGate } from "redux-persist/integration/react";


moment.updateLocale("es", {
  relativeTime: {
    future: (diff) => (diff === "ahora" ? diff : `en ${diff}`),
    past: (diff) => (diff === "ahora" ? diff : `hace ${diff}`),
    s: "ahora",
    ss: "ahora",
  },
});

export const App = () => {
  const { store, persistor } = ReduxStore();
  

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AppRouter />
      </PersistGate>
    </Provider>
  );
};
