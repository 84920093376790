import { Button, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Filter from "../../../components/Filter/Filter";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useTranslation } from "react-i18next";
import { Enum_FieldType, IFilterField } from "../../../interfaces/components";
import MyCustomTable, {
  IColumns,
} from "../../../components/Custom/Table/MyCustomTable";
import { RowTotalWallet } from "../../components/ManagementCards/RowTotalWallet";
import { asyncLoadingFunction } from "../../../redux/actions/helperActions";
import {
  setTotalWallet,
  startGetTotalWallet,
} from "../../../redux/actions/managementCardsActions";
import { getDealerById } from "../../../services/dealers";
import {
  DealerTypeEnum,
  RolMultilevel,
  Roles_Enum,
} from "../../../enums/enums";
import { getUserById } from "../../../services/managementUsers";
import { IGenericData } from "../../../interfaces/params";
import { setGetAssociates } from "../../../redux/actions/associatesActions";

import { usePointSales } from "../../../hooks/usePointSales";
import { getAssociateById } from "../../../services/associates";
import * as XLSX from "xlsx";
import { getReportTotalWallet } from "../../../services/cards";
import { IReportTotalWallet } from "../../../interfaces/cards";
import { buildFinalQueryParams } from "../../../utils/utils";

interface Field {
  label: string;
}
export const TotalWallet = () => {
  const [filterFields, setFilterFields] = useState<any>([]);
  const [listDealers, setListDealers] = useState<IGenericData[]>([]);
  const [idDealer, setIdDealer] = useState<number | string>("none");
  const [idAssociate, setIdAssociate] = useState<number | string>("");
  const [pointSalesFilter, setPointSalesFilter] = useState<IGenericData[]>([]);
  const [pointSales, setPointSales] = useState<IGenericData[]>([]);
  const { associates } = useSelector((state: AppState) => state.associates);
  const [isExporting, setIsExporting] = useState(false);

  const [dataFilter, setDataFilter] = useState<any>({});
  const { totalWallet } = useSelector(
    (state: AppState) => state.managementCards
  );
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const whiteListPVDealerType = [DealerTypeEnum.CARDS, DealerTypeEnum.BOTH];
  const allPointSales = usePointSales(
    {
      id: user && user.id,
      operator: user?.operator
        ? {
            id: user.operator.id,
            name: user.operator.name,
          }
        : null,
      roles: {
        id: user?.roles.id,
        name: user?.roles.name,
      },
    },
    token,
    DealerTypeEnum.CARDS
  );
  const isRoleDealer = user?.roles.name === Roles_Enum.DEALER;
  const isRoleAssociate = user?.roles.name === Roles_Enum.ASSOCIATE;
  const isRoleAdmin = user?.roles.name === Roles_Enum.ADMIN_OPERATOR;
  const isRolFromPointSale = [
    Roles_Enum.POINT_SALE,
    Roles_Enum.SELLER,
  ].includes(user?.roles.name as Roles_Enum);
  const role: string | null = isRoleDealer
    ? RolMultilevel.DEALER
    : isRoleAssociate
    ? RolMultilevel.ASSOCIATE
    : isRolFromPointSale
    ? RolMultilevel.POINTSALE
    : null;
  const columns: IColumns[] = [
    {
      align: "center",
      label: t("cards.totalDeliveredCards"),
    },
    {
      align: "center",
      label: t("cards.selledCards"),
    },
    {
      align: "center",
      label: t("cards.totalValueCollected"),
    },
    {
      align: "center",
      label: t("cards.commisionToPay"),
    },
    {
      align: "center",
      label: t("cards.totalToReceive"),
    },
  ];
  const getDealers = async () => {
    if (!user || !token) {
      throw new Error("Invalid action");
    }
    const userDetail = await getUserById(token, user.id);

    const filterDealers = userDetail.associatesUsers
      .flatMap((asc: any) => asc.associate.dealers)
      .filter((del: any) =>
        [DealerTypeEnum.CARDS, DealerTypeEnum.BOTH].includes(del.type)
      );
    setListDealers(filterDealers);
  };

  useEffect(() => {
    if (isRoleAssociate) {
      getDealers();
    } else if (isRoleAdmin) {
      const fetchAsociates = async () => {
        if (!user || !token) {
          throw new Error("Invalid action");
        }
        await dispatch(setGetAssociates(token, 1, true));
      };
      fetchAsociates();
    }

    return () => {
      dispatch(setTotalWallet(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (idDealer !== "none") {
      const fetchDealerDetail = async () => {
        const dealerDetail = await getDealer();
        const dealersPointSale = dealerDetail.dealersPointSale
          .map((item: any) => ({
            id: item.pointSale.id,
            name: item.pointSale.name,
            type: item.pointSale.type,
          }))
          .filter((point: any) => whiteListPVDealerType.includes(point.type));
        setPointSalesFilter(dealersPointSale);
      };
      fetchDealerDetail();
    } else {
      if (!isRoleAdmin) {
        setPointSalesFilter(allPointSales);
      } else {
        if (
          listDealers.length !== 0 &&
          idAssociate &&
          idAssociate !== "none" &&
          token
        ) {
          fetchAssociateData(token);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idDealer, allPointSales]);
  const getDealer = async () => {
    if (token) {
      const dealerDetail = await getDealerById(idDealer as number, token);
      return dealerDetail && dealerDetail;
    }
    return [];
  };
  const fetchAssociateData = async (token: string) => {
    const findUserAssociate: any = await getAssociateById(
      token,
      Number(idAssociate),
      false,
      true
    );
    const associatePointSales = findUserAssociate.PointSalesAssociate.filter(
      (point: any) => whiteListPVDealerType.includes(point.type)
    );
    setPointSalesFilter(associatePointSales);

    const { dealers } = findUserAssociate;
    const filterDealers = dealers.filter((del: any) =>
      whiteListPVDealerType.includes(del.type)
    );

    setListDealers(filterDealers || []);
  };
  useEffect(() => {
    if (idAssociate && idAssociate !== "none" && token) {
      fetchAssociateData(token);
    } else {
      const dealers = associates
        ? associates.data.flatMap((associate) => associate.dealers)
        : [];
      setListDealers(dealers);
      setPointSalesFilter([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idAssociate]);

  const filterOutRole = (fields: Field[], role: string): Field[] => {
    if (role === RolMultilevel.DEALER) {
      return fields.filter(
        (item) =>
          item.label !== t("managementDealers.dealers") &&
          item.label !== t("shorcuts.Associates")
      );
    }
    if (role === RolMultilevel.ASSOCIATE) {
      return fields.filter((item) => item.label !== t("shorcuts.Associates"));
    }

    if (role === RolMultilevel.POINTSALE) {
      return fields.filter((item) =>
        [t("filters.startDate"), t("filters.endDate")].includes(item.label)
      );
    }
    return fields;
  };

  const getFilteredFields = (filterFields: Field[], role: string): Field[] => {
    const fields = filterOutRole(filterFields, role);

    return fields;
  };

  const shouldFilterPointSales =
    (isRoleAssociate && idDealer !== 0) || isRoleAdmin;

  const pointSalesArray = shouldFilterPointSales
    ? pointSalesFilter
    : allPointSales;

  if (pointSalesArray !== pointSales) {
    setPointSales(pointSalesArray);
  }

  const generateAssociateOptions = (
    associates: any
  ): { label: string; value: string }[] => {
    if (!associates) {
      return [];
    }
    return associates.data.map((pointSale: any) => ({
      label: pointSale.name,
      value: pointSale.id,
    }));
  };

  const associatesOptions = generateAssociateOptions(associates);

  useEffect(() => {
    const filterFieldsFunction = () => {
      const filterFields: IFilterField[] = [
        {
          label: t("filters.startDate"),
          type: Enum_FieldType.DATE,
        },
        {
          label: t("filters.endDate"),
          type: Enum_FieldType.DATE,
        },
        {
          label: t("shorcuts.Associates"),
          type: Enum_FieldType.SELECT,
          options: [
            { label: t("managementDealers.none"), value: "none" },
            ...associatesOptions,
          ],
        },
        {
          label: t("managementDealers.dealers"),
          type: Enum_FieldType.SELECT,
          options: [
            { label: t("managementDealers.none"), value: "none" },
            ...listDealers.map((dealer) => ({
              label: dealer.name,
              value: dealer.id,
            })),
          ],
        },
        {
          label: t("shorcuts.PointSale"),
          type: Enum_FieldType.SELECT,
          options: [
            { label: t("managementBonds.all"), value: "all" },
            ...pointSales.map((pointSale: any) => ({
              label: pointSale.name,
              value: pointSale.id,
            })),
          ],
        },
      ];
      const newFilterFields: Field[] = role
        ? getFilteredFields(filterFields, role)
        : filterFields;

      setFilterFields(newFilterFields);
    };

    filterFieldsFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointSales, listDealers, associates]);

  const handleFilter = async (formValues: any) => {
    if (!user || !token) {
      throw new Error("invalid action");
    }
    const keys = {
      startDate: t("filters.startDate"),
      endDate: t("filters.endDate"),
      pointSale: t("shorcuts.PointSale"),
      dealer: t("managementDealers.dealers"),
      associate: t("shorcuts.Associates"),
    };

    const excludedValues: any = {
      pointSale: "all",
      dealer: "none",
      associate: "none",
    };

    const params = Object.entries(keys).reduce(
      (acc: any, [key, translatedKey]) => {
        const value = formValues[translatedKey];

        if (value !== excludedValues[key]) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    const { associatesUsers } = user;
    const associateId =
      associatesUsers.length > 0 ? associatesUsers[0].associate.id : null;
    const isDealer = user.roles.name === Roles_Enum.DEALER;

    if (associateId) {
      params.associate = associateId;
    }

    if (isDealer) {
      const userDetail = await getUserById(token, user.id);

      const dealerId = userDetail.dealer.id;
      params.dealer = dealerId;
    }
    if (isRolFromPointSale) {
      const userDetail = await getUserById(token, user.id);
      const pointSale = userDetail.sellerPointSales.map(
        ({ pointSale }: any) => ({
          id: pointSale.id,
          name: pointSale.name,
        })
      );
      params.pointSale = pointSale[0].id;
    }
    await dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startGetTotalWallet({
            ...params,
          })
        )
      )
    );
    setDataFilter({ ...params });
  };
  const onCleanFilter = async () => {
    dispatch(setTotalWallet(null));
    setDataFilter({});
  };

  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(
        async () =>
          await dispatch(
            startGetTotalWallet({
              ...dataFilter,
              page,
              perPage: rows || rowsPerPage,
            })
          )
      )
    );
  };
  const handleExportData = async () => {
    if (!token) {
      throw new Error("Invalid action");
    }
    try {
      setIsExporting(true);
      const finalParams = buildFinalQueryParams(dataFilter);

      const rs: IReportTotalWallet[] = await getReportTotalWallet(
        {
          ...finalParams,
        },
        token
      );

      const columnMapping: any = {
        lot_id: t("cards.batch"),
        totalCards: t("cards.totalCards"),
        redeemedCards: t("cards.selledCards"),
        value: t("reports.amount"),
        totalValue: t("cards.totalValueCollected"),
        commissions: t("reports.commissions"),
      };

      // Convertir y personalizar los nombres de las columnas
      const dataToExport = rs.map((item) => {
        const newItem: any = {};
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            if (key === "commissions" && Array.isArray(item[key])) {
              // Si es la propiedad 'commissions' y es un arreglo, convierte a cadena
              newItem[columnMapping[key] || key] = item[key].join(", ");
            } else {
              newItem[columnMapping[key] || key] = (item as any)[key];
            }
          }
        }
        return newItem;
      });
      // Convertir el arreglo commissions a una cadena de texto con toString

      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      const fileName = `${t("cards.totalWaller")
        .toLowerCase()
        .split(" ")
        .join("-")}-${new Date().toISOString()}`;
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        `${t("cards.totalWaller").toLowerCase()}`
      );

      // Generar el archivo y descargarlo
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    } catch (error) {
      console.error("Error exporting data:", error);
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mt={2}>
        {filterFields.length > 0 && (
          <Filter
            fields={filterFields}
            handleFilter={handleFilter}
            onClean={onCleanFilter}
            component="reportAssociate"
            setIdDealer={setIdDealer}
            setIdAssociate={setIdAssociate}
          />
        )}
      </Grid>

      {totalWallet && (
        <>
          {totalWallet.amountSell > 0 && (
            <Grid item xs={4} mb={2}>
              <Button
                style={{
                  border: "none",
                  outline: "none",
                  fontSize: 14,
                }}
                variant="contained"
                className="btn-dialog primary-btn"
                onClick={handleExportData}
                disabled={isExporting}
              >
                {isExporting ? (
                  <CircularProgress size={"1.6rem"} color="inherit" />
                ) : (
                  <>
                    {t("managementCards.exportData")}
                    <i className="fa-solid fa-file-export ml-2"></i>
                  </>
                )}
              </Button>
            </Grid>
          )}

          <Grid item xs={12}>
            <MyCustomTable
              columns={columns}
              data={[totalWallet]}
              lastPage={1}
              totalData={1}
              onPageChange={handlePageChange}
              renderBody={(row) => <RowTotalWallet row={row} />}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
