import moment from "moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "@react-pdf/renderer";

import { headersWithdrawalsRejectBank } from "../../../helpers/headersTables";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import { IWithdrawalData } from "../../../interfaces/withdrawals";

interface props {
  page: IWithdrawalData[];
}
export const WithdrawalRejectBankPdfDocument: FC<props> = ({ page }) => {
  const { t } = useTranslation();
  const columns = headersWithdrawalsRejectBank;

  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, index) => (
          <View key={index} style={[stylesPdf.pCol, stylesPdf.col]}>
            <Text style={stylesPdf.text}>{column.label.toUpperCase()}</Text>
          </View>
        ))}
      </View>

      <View style={{ flex: 1 }}>
        {page.map((data) => {
          return (
            <View key={data.id} style={stylesPdf.row}>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>{data.id}</Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>{data.player.id}</Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {data.player.typeDocument.name}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {data.player.email}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {data.player.numberDocument}
                </Text>
              </View>

              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {data.playerBankAccounts?.bank?.name
                    ? data.playerBankAccounts.bank.name
                    : ""}
                </Text>
              </View>

              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                    {data.playerBankAccounts?.accountNumber
                      ? `${data.playerBankAccounts.accountNumber} - ${t(
                          data.playerBankAccounts?.accountType === 1
                            ? "associates.savings"
                            : "associates.current"
                        )}`
                      : ""}
                  </Text>
              </View>

              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {data.withdrawalValue}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>{data.paidValue}</Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {moment(data.rejectDate).format("DD/MM/YYYY HH:mm:ss")}
                </Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>{data.reason}</Text>
              </View>
              <View style={[stylesPdf.pCol, stylesPdf.col]}>
                <Text style={stylesPdf.textContent}>
                  {t(
                    data.playerBankAccounts?.verified
                      ? "table.yes"
                      : "table.not"
                  )}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </>
  );
};
