import { View, Text } from "@react-pdf/renderer";
import React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { State } from "../../../enums/enums";
import { IMenuList } from "../../../interfaces/menu";
import { stylesPdf } from "../../../views/components/custom/customStyles";

interface Props {
  page: any;
}

export const MenusPdfDocument: FC<Props> = ({ page }) => {
  const { t } = useTranslation();
  const columnsMenus = [
    t("table.menu").toUpperCase(),
    t("table.nameRol").toUpperCase(),
    t("table.icon").toUpperCase(),
    t("table.createdBy").toUpperCase(),
    t("table.state").toUpperCase(),
  ];
  const columns = columnsMenus;
  return (
    <>
      <View
        style={[
          stylesPdf.row,
          {
            backgroundColor: "#02D0E7",
          },
        ]}
      >
        {columns.map((column, index) => (
          <View key={column} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        {page.map((page: any) => (
          <View key={page.id} style={[stylesPdf.row]}>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {(page as IMenuList).url}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {(page as IMenuList).name}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {(page as IMenuList).icon}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {(page as IMenuList).createdUser?.nameComplete}
              </Text>
            </View>
            {((page as IMenuList).state === State.ACTIVE ||
              page.state === "Activo") && (
              <View
                style={[
                  stylesPdf.col,
                  stylesPdf.pCol,
                  {
                    backgroundColor: "#c6efce",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                ]}
              >
                <Text style={[stylesPdf.textContent, { color: "#0d6a44" }]}>
                  {t("state.active")}
                </Text>
              </View>
            )}
            {((page as IMenuList).state === State.INACTIVE ||
              page.state === "Inactivo") && (
              <View
                style={[
                  stylesPdf.col,
                  stylesPdf.pCol,
                  {
                    backgroundColor: "#ffc7ce",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                ]}
              >
                <Text style={[stylesPdf.textContent, { color: "#ad120b" }]}>
                  {t("state.inactive")}
                </Text>
              </View>
            )}
          </View>
        ))}
      </View>
    </>
  );
};
