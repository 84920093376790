import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserInformation } from "./UserInformation";
import { ChangeUser } from "./ChangeUser";
import { ChangePassword } from "./ChangePassword";
import { MyAlert } from "../custom/MyAlert";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  setCloseAlert,
  setLoadingState,
} from "../../../redux/actions/helperActions";
import { startLogout } from "../../../redux/actions/authActions";

export interface IExpand {
  userInfo: boolean;
  changeUser: boolean;
  changePassword: boolean;
}

export const MyAccountSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [expand, setExpand] = useState<IExpand>({
    userInfo: false,
    changeUser: false,
    changePassword: false,
  });
  const { openAlert, typeAlert, messageAlert } = useSelector(
    (state: AppState) => state.helper
  );
  const [userChanged, setUserChanged] = useState(false);

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
    dispatch(setLoadingState({ loading: false }));
    userChanged && dispatch(startLogout());
  };

  return (
    <div className="filter mt-2 w-100">
      <Grid container spacing={2}>
        <Grid item xs={12} className="mb-4">
          <Box
            display="flex"
            className="pb-1"
            borderBottom={1}
            borderColor="#02D0E7"
          >
            <i
              className="fa-solid fa-circle-info"
              style={{ color: "#2C97DF", marginRight: 9, fontSize: 17 }}
            ></i>
            <Typography
              color="text.primary"
              style={{ fontSize: "16px", fontWeight: 500 }}
            >
              {t("account.myAccountSettings")}
            </Typography>
          </Box>
        </Grid>

        {/* USER INFORMATION */}
        <UserInformation expand={expand} setExpand={setExpand} />

        {/* CHANGE USER */}
        <ChangeUser
          expand={expand}
          setExpand={setExpand}
          setUserChanged={setUserChanged}
        />

        {/* CHANGE PASSWORD */}
        <ChangePassword
          expand={expand}
          setExpand={setExpand}
          setUserChanged={setUserChanged}
        />
      </Grid>

      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />
    </div>
  );
};
