import {
  IConfigParamForm,
  IConfigParamOperatorUpdate,
  IConfigParamsQuery,
  IParamOptionOperatorBody,
} from "../interfaces/configParams";

export const getConfigParams = (
  token: string,
  params: Partial<IConfigParamsQuery>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}configParamOperator?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
export const createConfigParam = (token: string, param: IConfigParamForm) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(param),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}configParams`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
export const updateConfigParam = (
  token: string,
  id: number,
  param: Partial<IConfigParamForm>
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(param),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}configParamOperator/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
export const updateConfigParamOperator = (
  token: string,
  id: number,
  param: Partial<IConfigParamOperatorUpdate>
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(param),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}configParamOperator/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
export const updateParamOptionOperator = (
  token: string,
  id: number,
  param: Partial<IParamOptionOperatorBody>
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(param),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}paramOptionsOperator/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
export const createParamOptionOperator = (
  token: string,
  param: Partial<IParamOptionOperatorBody>
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(param),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}paramOptionsOperator`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
