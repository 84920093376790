import { AppState } from "../reducers/rootReducer";
import {
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";
import { arraySearch } from "./searchActions";
import {
  createPromotion,
  deletePromotion,
  getPromotions,
  updatePromotion,
} from "../../services/promotionsPopups";
import { Types } from "../types/types";

export const startCreatePromotion = (data: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    try {
      if (user && token) {
        const operatorId = user.operator ? user.operator.id : null;
        data.operator = operatorId;
        const response = await createPromotion(data, token);
        if (response && !response.error) {
          await dispatch(startGetPromotions());
          await dispatch(arraySearch(null));
          dispatch(setOpenAlert());
          dispatch(setMessageAlert("La promoción fue creada correctamente"));
          await dispatch(setTypeAlert("success"));
          dispatch(setFlagForm({ flagFormCreate: false }));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert(response.error.errorMessage));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const startGetPromotions = (params?: any) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      const response = await getPromotions(token, params);
      if (response) {
        dispatch(setPromotionsPopups(response));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startDeletePromotionPopup = (id: number) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      const response = await deletePromotion(id, token);
      if (response) {
        await dispatch(startGetPromotions());
        await dispatch(setActivePromotionPopup(null));
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("Promoción eliminada exitosamente"));
        dispatch(setTypeAlert("success"));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startUpdatePromotion = (data: any, id: number) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    try {
      if (user && token) {
        const operatorId = user.operator ? user.operator.id : null;
        data.operator = operatorId;
        const response = await updatePromotion(data, token, id);
        if (response && !response.error) {
          await dispatch(startGetPromotions());
          await dispatch(arraySearch(null));
          dispatch(setOpenAlert());
          dispatch(
            setMessageAlert("La promoción fue actualizada correctamente")
          );
          await dispatch(setTypeAlert("success"));
          dispatch(setFlagForm({ flagFormCreate: false }));
        } else {
          dispatch(setOpenAlert());
          dispatch(setMessageAlert(response.error.errorMessage));
          dispatch(setTypeAlert("error"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const setPromotionsPopups = (promotions: any) => ({
  type: Types.setPromotionsPopups,
  payload: promotions,
});

export const setActivePromotionPopup = (promotion: any) => ({
  type: Types.setActivePromotionPopup,
  payload: promotion,
});
