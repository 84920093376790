import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setFlagFormConfig } from "../../redux/actions/helperActions";
import { setActiveAssociate } from "../../redux/actions/associatesActions";
import { setActivePointSale } from "../../redux/actions/pointsSaleActions";
import { AppState } from "../../redux/reducers/rootReducer";
import { FC } from "react";
import { RoleDetail } from "../../views/pages/managementRoles/RoleDetail";
import { BetDetail } from "../../views/components/ManagementPlayers/GamingActivity/tabBets/BetDetail";
import { setActiveTicket } from "../../redux/actions/betsActions";
import { MenuDetail } from "../../views/pages/managementMenu/MenuDetail";
import { setActiveMenu } from "../../redux/actions/managementMenuActions";

interface Props {
  component: string;
  title: string;
  width?: "lg" | "md" | "sm";
}

export const MyDialogInformation: FC<Props> = ({
  component,
  title,
  width = "lg",
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { flagFormConfig } = useSelector((state: AppState) => state.helper);

  const handleClose = () => {
    dispatch(setFlagFormConfig({ flagFormConfig: false }));
    dispatch(setActiveAssociate(null));
    dispatch(setActivePointSale(null));
    dispatch(setActiveTicket(null));
    dispatch(setActiveMenu(null));
  };

  const BuildDialogContent = (component: string) => {
    switch (component) {
      case "roles":
        return <RoleDetail />;
      case "bets":
        return <BetDetail />;
      case "menu":
        return <MenuDetail />;
      // TODO: DEMAS COMPONENTES
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={!!flagFormConfig}
      maxWidth={width}
      fullWidth
      disableEscapeKeyDown
      scroll="body"
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 0,
          marginBottom: -20,
        }}
      >
        <Box display="flex">
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          {/* TITLE DIALOG */}
          <p className="txt-primary-md" style={{ fontWeight: 500 }}>
            {t(title)}
          </p>
        </Box>
        <IconButton
          disableFocusRipple
          disableRipple
          style={{ border: "none", outline: "none", marginBottom: 5 }}
          onClick={handleClose}
          className="hover-close"
        >
          <i className="fa-solid fa-xmark"></i>
        </IconButton>
      </DialogTitle>
      <Box borderTop={1} borderColor="#02D0E7" m={2}>
        {/* RENDER CONTENT BY COMPONENT */}
        <DialogContent>{BuildDialogContent(component)}</DialogContent>
      </Box>
      <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          style={{
            border: "none",
            outline: "none",
            marginRight: 15,
            marginBottom: 15,
          }}
          variant="contained"
          className="footer-btn primary-btn ripple"
          onClick={handleClose}
        >
          <i className="fa-solid fa-check-circle"></i>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
