import { IConfigParam } from "../../interfaces/configParams";
import { Types } from "../types/types";
import { IPaginationData } from "./../../interfaces/shared";
interface IConfigParamState {
  configParams: IPaginationData<IConfigParam> | null;
  activeParam: IConfigParam | null;
}
interface IAction {
  type: Types;
  payload?: any;
}
const initialState: IConfigParamState = {
  configParams: null,
  activeParam: null,
};
export const configParamReducer = (
  state = initialState,
  action: IAction
): IConfigParamState => {
  const { type, payload } = action;

  switch (type) {
    case Types.setConfigParams:
      return {
        ...state,
        configParams: payload,
      };
    case Types.setActiveConfigParam:
      return {
        ...state,
        activeParam: payload,
      };

    default:
      return state;
  }
};
