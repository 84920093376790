import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { useForm } from "../../../hooks/useForm";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  setCountries,
  setMessageAlert,
  setOpenAlert,
  setSolutions,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { theme } from "../custom/customStyles";
import { ICountrySolutionsDb } from "../../../interfaces/params";
import { getParamSolutionByCountry } from "../../../services/managementParametersService";
import {
  addParamSolutions,
  setActiveParamSolutions,
  updateParamSolution,
} from "../../../redux/actions/managementParametersActions";
import { MyDialog } from "../custom/MyDialog";
import { Roles_Enum, State } from "../../../enums/enums";
import { getOperatorsService } from "../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../redux/actions/managementCompanyActions";

const ITEM_HEIGHT = 42;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const FormSolutionsTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token, user } = useSelector((state: AppState) => state.auth);
  const { countries, solutions } = useSelector(
    (state: AppState) => state.helper
  );
  const { activeParamSolutions: active } = useSelector(
    (state: AppState) => state.managementParameters
  );
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const [searchText, setSearchText] = useState("");
  const [initialParam, setInitialParam] = useState<ICountrySolutionsDb[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);

  const solutionsOptions = useMemo(
    () => (solutions ? solutions : []),
    [solutions]
  );

  const initialFormValues = {
    country: active ? active.country.id : 0,
    solutions: active ? active.solutions.map((solution) => solution.name) : [],
    operator: active
      ? active.operator.id
      : user?.roles.name !== Roles_Enum.SUPER_ADMIN
      ? user?.operator.id
      : 0,
  };

  let [formValues, handleInputChange] = useForm({
    ...initialFormValues,
  });
  const getParam = async (token: string, country: number) => {
    try {
      const operatorId =
        user && user.operator ? user.operator.id : formValues.operator;
      const response = await getParamSolutionByCountry(
        token,
        country,
        operatorId
      );
      const { data } = response;
      setInitialParam(data);
    } catch (error) {}
  };
  useEffect(() => {
    if (token) {
      dispatch(setCountries(token));
      dispatch(setSolutions(token));
    }
    return () => {
      dispatch(setActiveParamSolutions(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (token) {
      getParam(token, formValues.country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.country, formValues.operator]);
  useEffect(() => {
    handleInputChange({
      target: {
        name: "solutions",
        value:
          formValues.operator === 0
            ? []
            : initialParam.length > 0
            ? initialParam
                .filter((solution) => solution.state === 1)
                .map((item) => item.solutions.id)
            : [],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialParam, formValues.operator]);

  const displayedOptions = useMemo(
    () =>
      solutionsOptions.filter((option) =>
        option.name.toLowerCase().includes(searchText.toLowerCase())
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchText, solutionsOptions]
  );
  const validateEmptySolutions = (solutions: any[]) => {
    return solutions.length > 0;
  };

  const handleCreateSolutions = async () => {
    setIsSubmit(true);

    if (
      formValues.country === 0 ||
      (user?.roles.name === Roles_Enum.SUPER_ADMIN &&
        formValues.operator === 0) ||
      !validateEmptySolutions(formValues.solutions)
    ) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.fillInRequiredFields"));
      dispatch(setTypeAlert("error"));
    } else {
      if (!!active) {
        setOpenDialog(true);
      } else {
        handleAcceptCreate();
      }
    }
  };

  const handleAcceptCreate = async () => {
    setIsLoading(true);
    setOpenDialog(false);
    if (initialParam.length > 0) {
      //UPDATE
      await dispatch(updateParamSolution(formValues, initialParam));
    } else {
      //CREATE
      if (
        formValues.country !== 0 &&
        formValues.solutions.length > 0 &&
        formValues.operator !== 0
      ) {
        await dispatch(addParamSolutions(formValues));
      }
    }
    setIsLoading(false);
    setIsSubmit(false);
  };

  const handleCancelCreate = () => {
    setOpenDialog(false);
    setIsSubmit(false);
  };

  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    token && fetchOperators(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2}>
        {user?.roles.name === Roles_Enum.SUPER_ADMIN && !active && (
          <>
            {companies && (
              <Grid item xs={4}>
                <label className="ml-3">
                  {t("companyManagement.operator") + "*"}
                </label>
                <FormControl
                  sx={{ width: "100%" }}
                  size="small"
                  disabled={!!active}
                  error={
                    isSubmit &&
                    user.roles.name === Roles_Enum.SUPER_ADMIN &&
                    formValues.operator === 0
                  }
                >
                  <Select
                    name="operator"
                    size="small"
                    fullWidth
                    onChange={handleInputChange}
                    value={
                      formValues.operator === 0 ? "" : formValues.operator || ""
                    }
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                  >
                    {companies?.data?.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </>
        )}

        <Grid item xs={4}>
          <label className="ml-2">
            {t("companyManagement.selectCountry")}*
          </label>
          <FormControl
            sx={{ width: 1 }}
            size="small"
            error={isSubmit && formValues.country === 0}
          >
            <InputLabel id="country-label">{t("user.country")}</InputLabel>
            <Select
              labelId="country-label"
              label={t("user.country")}
              name="country"
              fullWidth
              onChange={handleInputChange}
              value={formValues.country === 0 ? "" : formValues.country || ""}
              input={<OutlinedInput label={t("user.country")} />}
              disabled={!!active}
            >
              {countries?.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  <img
                    loading="lazy"
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                    src={country.imgUrl}
                    alt={country.name}
                  />{" "}
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <label className="ml-2">
            {t("parameterization.enterSolutions")}*
          </label>
          <FormControl
            sx={{ width: 1 }}
            size="small"
            error={isSubmit && !validateEmptySolutions(formValues.solutions)}
          >
            <InputLabel id="game-label">
              {t("parameterization.solution")}
            </InputLabel>
            <Select
              labelId="game-label"
              label={t("parameterization.solution")}
              name="solutions"
              multiple
              value={formValues.solutions || ""}
              onChange={handleInputChange}
              input={<OutlinedInput label={t("parameterization.solution")} />}
              renderValue={(selected) =>
                solutionsOptions
                  .filter((solution) => selected.includes(solution.id))
                  .map((solution) => solution.name)
                  .join(" - ")
              }
              MenuProps={MenuProps}
              onClose={() => setSearchText("")}
            >
              <ListSubheader>
                <TextField
                  size="small"
                  autoFocus
                  fullWidth
                  InputLabelProps={{ shrink: false }}
                  label={" "}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      e.stopPropagation();
                    }
                  }}
                  
                />
              </ListSubheader>
              {displayedOptions.map((solution) => (
                <MenuItem key={solution.id} value={solution.id}>
                  <Checkbox
                    checked={
                      !!formValues.solutions.find(
                        (item: any) => item === solution.id
                      )
                    }
                    sx={{
                      "&.Mui-checked": {
                        color: "#04BF9D",
                      },
                    }}
                  />
                  <ListItemText primary={solution.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="flex-end" mt={20}>
          {isLoading ? (
            <CircularProgress className={"colorLoading"} />
          ) : (
            <Button
              style={{
                border: "none",
                outline: "none",
                fontSize: 14,
              }}
              variant="contained"
              className="btn-dialog primary-btn"
              onClick={handleCreateSolutions}
            >
              {t(
                !!active || initialParam.length > 0
                  ? "button.editParameters"
                  : "button.createParameters"
              )}
              <i className="fa-solid fa-check-circle ml-2"></i>
            </Button>
          )}
        </Grid>

        <MyDialog
          open={openDialog}
          title={t("dialog.confirmationWindow")}
          message={t("dialog.sureEdit")}
          handleAccept={handleAcceptCreate}
          handleClose={handleCancelCreate}
        />
      </Grid>
    </ThemeProvider>
  );
};
