import { Types } from "../types/types";

export const arraySearch = (data: null | any) => ({
  type: Types.arraySearch,
  payload: data,
});

export const valueSearched = (value: string) => ({
  type: Types.valueSearched,
  payload: value,
});

export const setChangeData = (change: boolean) => ({
  type: Types.changeData,
  payload: change,
});
