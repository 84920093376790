import { FC } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";

interface Props {
  indicators: any[];
}

export const IndicatorsList: FC<Props> = ({ indicators }) => {
  const { t } = useTranslation();
  return (
    <Row style={{ marginBottom: -25 }}>
      <Col xs={12}>
        <h3 className="subtitle">{t("indicators.title")}</h3>
      </Col>

      <Row lg={12} xs={12} className="container-list">
        {indicators.map((indicator, index) => (
          <Col
            md={3}
            sm={6}
            xs={6}
            key={index}
            style={{ marginTop: "-10px" }}
            className="indicators-container"
          >
            <Card
              className="my-card"
              style={{ backgroundColor: indicator.background }}
            >
              <CardBody className="my-card-body">
                <Grid
                  container
                  spacing={1}
                  display="flex"
                  justifyContent="center"
                >
                  <Grid
                    item
                    xs={3}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <div className="container-img">
                      <img src={indicator.logo} alt="icon" />
                    </div>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      fontSize={{ lg: 12, md: 11.5, sm: 11, xs: 11 }}
                      className="card-category"
                    >
                      {t("indicators." + indicator.title)}
                    </Typography>
                    <p className="card-value">{indicator.value}</p>
                    <p className="card-days">{t("indicators.lastDays")}</p>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Row>
  );
};
