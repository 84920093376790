import moment from "moment";
import { useSelector } from "react-redux";
import { getCopyArrayOrObject, getMovementType } from "../../../utils/utils";
import { AppState } from "../../../redux/reducers/rootReducer";
import { headersCashFlow } from "../../../helpers/headersTables";

export const CashFlowDataTable = (totalData: any[]) => {
  const { data } = useSelector((state: AppState) => state.search);

  const headersCvsStyle = headersCashFlow.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });

  // Obtener datos por vista
  const dataCashFlow = data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalCashFlow = totalData ? getCopyArrayOrObject(totalData) : [];
  //   DATA TOTAL
  const multiTotalDataCashFlow = [
    {
      columns: headersCvsStyle,
      data: dataTotalCashFlow.map((data: any) => {
        let array;
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.pointSale.name,
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: getMovementType(data.transactionType.id),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.id,
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: "$ " + data.amount,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: `$ ${data?.meta?.valueWithTax ?? 0}`,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.transactionType.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.createAt).format("DD/MM/YYYY HH:mm:ss"),
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];
  const multiDataCashFlow = [
    {
      columns: headersCvsStyle,
      data: dataCashFlow.map((data: any) => {
        let array;
        array = [
          {
            value: data.id,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.pointSale.name,
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: getMovementType(data.transactionType.id),
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.player.id,
            style: { alignment: { horizontal: "center" } },
          },

          {
            value: "$ " + data.amount,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: `$ ${data?.meta?.valueWithTax ?? 0}`,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: data.transactionType.name,
            style: { alignment: { horizontal: "center" } },
          },
          {
            value: moment(data.createAt).format("DD/MM/YYYY HH:mm:ss"),
            style: { alignment: { horizontal: "center" } },
          },
        ];
        return array;
      }),
    },
  ];
  const finalData = {
    dataPerView: dataCashFlow,
    dataTotal: dataTotalCashFlow,
    headersCSV: headersCashFlow,
    multiDataSet: multiDataCashFlow,
    multiTotalDataSet: multiTotalDataCashFlow,
  };
  return finalData;
};
