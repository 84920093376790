import moment from "moment";
import { Alert, AlertTitle, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import { Row } from "reactstrap";
import { AppState } from "../../../redux/reducers/rootReducer";

import {
  Enum_FieldType,
  IColumnsTable,
  IFilterField,
  Icons,
} from "../../../interfaces/components";
import Filter from "../../../components/Filter/Filter";
import { TableComponent } from "../../../components/Table/TableComponent";
import {
  asyncLoadingFunction,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import {
  setBetsRedux,
  setTotalBets,
  startFilterBets,
} from "../../../redux/actions/betsActions";
import { MyDialogInformation } from "../../../components/DialogInformation/MyDialogInformation";

const icons: Icons = {
  see: false,
  delete: false,
  seeMore: true,
  edit: false,
};

export const TabBettingHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data } = useSelector((state: AppState) => state.search);
  const { bets } = useSelector((state: AppState) => state.bets);
  const [dataFilter, setDataFilter] = useState<any>({});
  const [filter, setFilter] = useState(false);

  /**
   * Datos inputs para filtro
   */
  const FilterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("bets.ticketNumber"),
      type: Enum_FieldType.TEXT,
    },
  ];
  /**
   * Soporta las Columnas para renderizar en el header de la tabla
   */
  const columns: IColumnsTable[] = [
    { id: 1, header: "table.betId" },
    { id: 3, header: "bets.ticketNumber" },
    { id: 4, header: "bets.dateBet" },
    { id: 5, header: "bets.hourBet" },
    { id: 6, header: "bets.winningAmount" },
    { id: 7, header: "bets.bettingStatus" },
    { id: 8, header: "table.actions" },
  ];

  const handleFilter = (formValues: any) => {
    setFilter(true);
    const startDate =
      formValues[t("filters.startDate")].length > 0
        ? formValues[t("filters.startDate")] + "T00:00:00"
        : "";
    const endDate =
      formValues[t("filters.endDate")].length > 0
        ? formValues[t("filters.endDate")] + "T23:59:00"
        : "";
    const params = {
      startDate,
      endDate,
      ticketId: formValues[t("bets.ticketNumber")],
      page: 1,
    };

    const errorDate = moment(startDate).isAfter(endDate);
    if (errorDate) {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.errorDate"));
      dispatch(setTypeAlert("error"));
    } else {
      dispatch(
        asyncLoadingFunction(
          async () => await dispatch(startFilterBets(params))
        )
      );
    }
    setDataFilter(params);
  };
  const onCleanFilter = async () => {
    await dispatch(
      asyncLoadingFunction(
        async () => await dispatch(startFilterBets({ page: 1 }, true))
      )
    );
    setFilter(false);
  };

  useEffect(() => {
    return () => {
      dispatch(setBetsRedux(null));
      dispatch(setTotalBets(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid">
      <Row
        style={{
          marginTop: "12px",
        }}
      >
        <Filter
          fields={FilterFields}
          component={"bettingHistory"}
          setDataFilter={setDataFilter}
          handleFilter={handleFilter}
          onClean={onCleanFilter}
        />
      </Row>

      {data ? (
        <>
          {data.data.length > 0 && (
            <TableComponent
              columns={columns}
              data={data.data}
              pages={data.last_page}
              component={"bettingHistory"}
              icons={icons}
              totalData={data}
              subMenu={false}
              isSortingTable={false}
              dataFilter={dataFilter}
            />
          )}
        </>
      ) : (
        <>
          {bets && bets.data.length > 0 && (
            <TableComponent
              columns={columns}
              data={bets.data}
              pages={bets.last_page}
              component={"bettingHistory"}
              icons={icons}
              totalData={bets}
              subMenu={false}
              isSortingTable={false}
              dataFilter={dataFilter}
            />
          )}
        </>
      )}

      {!bets && !filter && !data && (
        <Grid item xs={12} pt={3} mt={-2}>
          <Stack
            sx={{ width: "100%" }}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
            spacing={2}
          >
            <Alert
              icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
              sx={{
                borderLeft: `7px solid #2C97DF`,
                backgroundColor: "#EAF5FC",
                color: "#4b4b4b",
              }}
            >
              <div className="d-flex">
                <AlertTitle
                  style={{ marginTop: "5px" }}
                  sx={{ color: "#333333" }}
                >
                  {t("alerts.clickButton")}
                </AlertTitle>
                <AlertTitle
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  sx={{ color: "#333333", fontWeight: "bold" }}
                >
                  {t("alerts.refillBets")}
                </AlertTitle>
              </div>
            </Alert>
          </Stack>
        </Grid>
      )}

      <MyDialogInformation component={"bets"} title={"bets.betDetail"} />
    </div>
  );
};
