import React, { useState } from "react";
import "./Tabs.scss";
import { setFlagForm } from "../../../redux/actions/helperActions";
import { useDispatch } from "react-redux";

interface Tab {
  label: string;
  content: React.ReactNode;
}

interface TabsProps {
  tabs: Tab[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="tabs">
      <div className="tab-links">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab ${activeTab === index ? "active" : ""}`}
            onClick={() => {
              setActiveTab(index);
              dispatch(setFlagForm({ flagFormCreate: false }));
            }}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="tab-content">{tabs[activeTab].content}</div>
    </div>
  );
};

export default Tabs;
