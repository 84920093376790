import {useSelector} from "react-redux";
import moment from "moment";
import {headersCommercialNetworksReport} from "../../../helpers/headersTables";
import {
  capitalizeFirstLetter,
  getCopyArrayOrObject,
} from "../../../utils/utils";
import {AppState} from "../../../redux/reducers/rootReducer";
import {IPlayerActivityData} from "../../../interfaces/playerActivity";

export const CommercialNetworksReportDataTable = (
  totalData: IPlayerActivityData[]
) => {
  const {data} = useSelector((state: AppState) => state.search);
  const headersCvsStyle = headersCommercialNetworksReport.map((header) => {
    return {
      title: header.label,
      width: {wpx: 120},
      style: {
        font: {bold: true, color: {rgb: "FFFFFF"}},
        fill: {patternType: "solid", fgColor: {rgb: "02D0E7"}},
        alignment: {vertical: "center", horizontal: "center"},
      },
    };
  });

  const dataNetworks = totalData
    ? getCopyArrayOrObject(totalData)
    : data.data
    ? getCopyArrayOrObject(data.data)
    : [];

  const buildTotalData = (arr: IPlayerActivityData[]) => {
    const multiData = [
      {
        columns: headersCvsStyle,
        data: arr.map((data: IPlayerActivityData) => {
          const array = [
            {
              value: capitalizeFirstLetter(data.externalUser?.name || ""),
              style: {alignment: {horizontal: "center"}},
            },
            {
              value: data.id,
              style: {alignment: {horizontal: "center"}},
            },
            {
              value: data.refTransactionId || "",
              style: {alignment: {horizontal: "center"}},
            },
            {
              value: data.transactionType?.name || "",
              style: {alignment: {horizontal: "center"}},
            },
            {
              value: data.transactionStatus?.name || "",
              style: {alignment: {horizontal: "center"}},
            },
            {
              value: data.player.id,
              style: {alignment: {horizontal: "center"}},
            },
            {
              value: data.player.numberDocument,
              style: {alignment: {horizontal: "center"}},
            },
            {
              value: parseFloat(data.amount),
              style: {alignment: {horizontal: "center"}},
            },
            {
              value: data.meta?.codePoint || "",
              style: {alignment: {horizontal: "center"}},
            },
            {
              value: data.meta?.province || "",
              style: {alignment: {horizontal: "center"}},
            },
            {
              value: data.meta?.canton || "",
              style: {alignment: {horizontal: "center"}},
            },
            {
              value: moment(data.updateAt).format("DD/MM/YYYY HH:mm:ss"),
              style: {alignment: {horizontal: "center"}},
            },
          ];
          return array;
        }),
      },
    ];
    return multiData;
  };

  const multiData = buildTotalData(dataNetworks);
  const finalData = {
    dataPerView: dataNetworks,
    dataTotal: dataNetworks,
    headersCSV: headersCommercialNetworksReport,
    multiDataSet: multiData,
    multiTotalDataSet: multiData,
  };
  return finalData;
};
