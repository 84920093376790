import { FC, useMemo } from "react";
import "./pagination.scss";

interface Props {
  //   totalCount: number;
  totalPages: number;
  //   pageSize: number;
  siblingCount: number;
  currentPage: number;
}

//  range función personalizada que toma un valor start y end y devuelve una matriz con elementos de principio a fin
export const DOTS = "...";
const range = (start: number, end: number) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const UsePagination: FC<Props> = ({
  //   totalCount, // representa el total de datos .length
  totalPages, // representa el total de paginas
  //   pageSize, //  representa los datos máximos que son visibles en una sola página.
  siblingCount = 1, // representa el número mínimo de botones de página que se mostrarán a cada lado del botón de la página actual. El valor predeterminado es 1.
  currentPage, // representa la página activa actual
}) => {
  const paginationRange = useMemo(() => {
    // TOTAL PAGINAS
    const totalPageCount = totalPages;

    //El recuento de páginas se determina como siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
    Caso 1:
    Si el número de páginas es inferior a los números de página que queremos mostrar en nuestro
    paginationComponent, devolvemos el rango [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    /*
     Calcule el índice de hermanos izquierdo y 
     derecho y asegúrese de que estén dentro del rango 1 y el recuento total de páginas
    */
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    /*
    No mostramos puntos, solo cuando hay un número de página para
    insertar entre los extremos (izquierda y derecha) y los límites de página, es decir, 1 y
    totalPageCount. Por lo tanto, estamos usando leftSiblingIndex > 2 y rightSiblingIndex < totalPageCount - 2 
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /*
    Caso 2: No hay puntos izquierdos para mostrar, pero sí puntos derechos para mostrar
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    /*
    Caso 3: No hay puntos a la derecha para mostrar, pero sí puntos a la izquierda para mostrar
     */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
    Caso 4: Se mostrarán los puntos tanto en lado izquierdo como en el derecho
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
    //   }, [totalCount, pageSize,siblingCount, currentPage]);
  }, [siblingCount, currentPage, totalPages]);

  return paginationRange as any;
};
