import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { Tabs, Tab, Box, Grid } from "@mui/material";
import { TabCurrencyLanguageConfirmation } from "./TabCurrencyLanguageConfirmation";
import { TabBrandGamesConfirmation } from "./TabBrandGamesConfirmation";
import { TabPaymentMethodsConfirmation } from "./TabPaymentMethodsConfirmation";
import { TabSolutionsConfirmation } from "./TabSolutionsConfirmation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const styles = {
  "&.Mui-selected": {
    color: "#208E9A",
    border: "none",
    outline: "none",
  },
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

interface Props {
  formValues: any;
  readOnly?: boolean;
}
export const TabsConfirmation: FC<Props> = ({ formValues, readOnly }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      {/* CONFIGURACIÓN MARCA */}
      {!readOnly && (
        <Grid item xs={12} className="mb-2">
          <Box
            display="flex"
            className="pb-1"
            borderBottom={1}
            borderColor="#02D0E7"
          >
            <i
              className="fa-solid fa-circle-info"
              style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
            ></i>
            <p className="txt-primary-md" style={{ fontWeight: 500 }}>
              {t("companyManagement.confirmation")}
            </p>
          </Box>
        </Grid>
      )}
      <Box
        sx={{
          width: "100%",
          ml: readOnly ? 0 : 2,
        }}
      >
        <Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            style={{ border: "none", outline: "none" }}
            variant="fullWidth"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#208E9A",
              },
            }}
            centered
          >
            <Tab
              sx={styles}
              label={
                <span className="spanTabConfirmation">
                  {t("companyManagement.configureBrandTab")}
                </span>
              }
              {...a11yProps(0)}
            />
            <Tab
              sx={styles}
              label={
                <span className="spanTabConfirmation">
                  {t("companyManagement.configureCurrencyAndLanguageTab")}
                </span>
              }
              {...a11yProps(1)}
            />
            <Tab
              sx={styles}
              label={
                <span className="spanTabConfirmation">
                  {t("companyManagement.configurePaymentMethodsTab")}
                </span>
              }
              {...a11yProps(2)}
            />
            <Tab
              sx={styles}
              label={
                <span className="spanTabConfirmation">
                  {t("companyManagement.configureSolutionsTab")}
                </span>
              }
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
        <div className="mt-4">
          <TabPanel value={value} index={0}>
            <TabBrandGamesConfirmation formValues={formValues} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TabCurrencyLanguageConfirmation
              countriesSelected={formValues.countries}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TabPaymentMethodsConfirmation
              countriesSelected={formValues.countries}
              operator={formValues.operator}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <TabSolutionsConfirmation
              countriesSelected={formValues.countries}
            />
          </TabPanel>
        </div>
      </Box>
    </>
  );
};
