import React, { FC, useState } from "react";
import { CircularProgress, Grid, TableCell, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ICardsList } from "../../../../interfaces/cards";
import { MyDialog } from "../../custom/MyDialog";
import { updatedBalalanceRefillPointSale } from "../../../../redux/actions/walletActions";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { StateCards } from "../../../../enums/enums";
import moment from "moment";

interface props {
  row: ICardsList;
}
export const RowCardsSales: FC<props> = ({ row }) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const { pointSale } = useSelector((state: AppState) => state.wallet);
  const dispatch = useDispatch();

  const onSell = () => {
    setOpenDialog(true);
    setLoading(true);
    setMessage("managementCards.dialogCard");
  };
  const onSave = async () => {
    pointSale &&
      (await dispatch(
        updatedBalalanceRefillPointSale(
          pointSale?.pointSale.id,
          parseFloat(row.value),
          row.id as number
        )
      ));
    setOpenDialog(false);
    setLoading(false);
  };

  /**
   * Soporta el evento click para cerrar la ventana de confirmación
   */
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      {openDialog && (
        <MyDialog
          open={openDialog}
          title={t("dialog.confirmationWindow")}
          message={t(message)}
          handleAccept={onSave}
          handleClose={handleCloseDialog}
          isLoading={loading}
        />
      )}
      {loading && <CircularProgress size={"1.6rem"} color="inherit" />}
      <TableCell align="center">{row.lot.id}</TableCell>
      <TableCell align="center">{row.id}</TableCell>
      <TableCell align="center">{row.value}</TableCell>
      {row.state === StateCards.ACTIVE && (
        <TableCell align="center">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Tooltip title={t("button.sell") as string} arrow>
                <div>
                  <i
                    onClick={onSell}
                    className="fa fa-money fa-lg mr-2 iconEdit"
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </TableCell>
      )}
      {row.state === StateCards.SOLD && (
        <TableCell align="center">
          {row.activationDate
            ? moment(row.activationDate).format("DD/MM/YYYY - HH:mm:ss")
            : ""}
        </TableCell>
      )}
    </>
  );
};
