import React, { ChangeEvent, useEffect, useState } from "react";
import MultiSelect from "./MultiSelect";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { MyTextField } from "../../components/custom/MyTextField";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  setActiveCategory,
  startCreateCasinoCategory,
  startGetGames,
  startUpdateCasinoCategories,
} from "../../../redux/actions/casinoActivity";
import { MyDialog } from "../../components/custom/MyDialog";
import { setFlagForm } from "../../../redux/actions/helperActions";
import { IFile } from "../../../interfaces/legal";
import CancelIcon from "@mui/icons-material/Cancel";

const CategoryForm: React.FC<{ type: number }> = ({ type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { casinoCategory, casinoGames, activeCategory } = useSelector(
    (state: AppState) => state.casino
  );
  const [selectedItems, setSelectedItems] = useState<any>(
    activeCategory ? activeCategory.casinoGames : []
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [saveValues, setSaveValues] = useState<any>(null);
  const { loading } = useSelector((state: AppState) => state.helper);
  const { token } = useSelector((state: AppState) => state.auth);
  const length = casinoCategory.length + 1;
  const numericArray =
    length > 0 ? Array.from({ length }, (v, i) => i + 1) : [1];
  const initialValues = {
    name: activeCategory ? activeCategory.name : "",
    order: activeCategory ? activeCategory.order : "",
  };
  const validationSchema = yup.object({
    name: yup.string().required(t("form.requiredFile")),
    order: yup.string().required(t("form.requiredFile")),
  });
  const fileSupported = {
    accept: "image/png",
  };
  const [noValid, setNoValid] = useState(false);
  const [errorFileMessage, setErrorFileMessage] = useState("");
  const [fileLabel, setFileLabel] = useState(
    activeCategory && activeCategory.icon ? activeCategory.icon.url : ""
  );
  const [file, setFile] = useState<IFile>({
    file: null,
    label: activeCategory ? fileLabel : "",
  });

  useEffect(() => {
    if (token) {
      const params = {
        type: type,
      };
      dispatch(startGetGames(params));
    }
  }, [dispatch, token, type]);

  const handleAccept = () => {
    const formData = {
      name: saveValues.name,
      games: saveValues.games,
      order: saveValues.order,
      icon: saveValues.fileUrl,
    };
    dispatch(
      activeCategory
        ? startUpdateCasinoCategories(formData, activeCategory.id, type)
        : startCreateCasinoCategory(formData, type)
    );
    setOpenDialog(false);
  };

  const handleSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    const SUPPORTED_FORMATS = ["image/png"];
    const selectedFile = e.target.files?.[0] as File;
    if (selectedFile) {
      if (!SUPPORTED_FORMATS.includes(selectedFile.type)) {
        setErrorFileMessage(
          "Formato no soportado. Solo se permiten imágenes PNG."
        );
        return setNoValid(true);
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.onload = () => {
          if (image.width !== 32 || image.height !== 32) {
            setErrorFileMessage(
              "El tamaño de la imagen debe ser 32x32 píxeles."
            );
            return setNoValid(true);
          }
          setNoValid(false);
          setErrorFileMessage("");
          setFile({ file: selectedFile, label: selectedFile.name });
          setFileLabel(selectedFile.name);
        };
        image.src = event.target?.result as string;
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setFile({
        file: null,
        label: "",
      });
    }
  };

  const handleDeleteFile = () => {
    setFile({ file: null, label: "" });
    setFileLabel("");
  };

  return (
    <div className="category-form">
      <Formik
        initialValues={initialValues}
        validateOnChange={true}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          const newFile = file.file ? file.file : null;
          if (newFile || file?.label) {
            setSaveValues({
              ...data,
              fileUrl: newFile,
              fileName: file.label !== "" ? file.label : fileLabel,
              games: selectedItems,
            });
            setOpenDialog(true);
            setNoValid(false);
            setErrorFileMessage("");
          } else {
            setNoValid(true);
            setErrorFileMessage("El campo es requerido");
          }
          setSubmitting(false);
        }}
      >
        {({ handleChange, values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <label className="ml-3">Nombre de la Categoria*</label>
                <MyTextField
                  name="name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label=""
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <label className="ml-3">Seleccionar Posición Categoria*</label>
                <MyTextField
                  name="order"
                  select
                  InputLabelProps={{ shrink: false }}
                  label={" "}
                  onChange={handleChange}
                >
                  {numericArray.map((item: any, index: number) => (
                    <MenuItem
                      key={index}
                      value={item}
                      sx={{
                        "&.Mui-focused": {
                          backgroundColor: "red",
                        },
                      }}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <label className="ml-3">Seleccionar Juegos*</label>
                <MultiSelect
                  items={
                    casinoGames
                      ? casinoGames?.data
                          .sort((a: any, b: any) =>
                            a.name.localeCompare(b.name)
                          )
                          ?.map(({ id, name }: any) => ({
                            id,
                            name,
                          }))
                      : []
                  }
                  selectedItemsFunction={setSelectedItems}
                  activeItems={
                    activeCategory
                      ? activeCategory.casinoGames
                          ?.sort((a: any, b: any) =>
                            a.name.localeCompare(b.name)
                          )
                          .map(({ id, name }: any) => ({
                            id,
                            name,
                          }))
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <label className="ml-3">Imagen de la promoción*</label>
                <TextField
                  size="small"
                  type="file"
                  inputProps={fileSupported}
                  onChange={handleSelectFile}
                  id="icon-button-file"
                  autoComplete="off"
                  style={{ display: "none" }}
                  fullWidth
                  error={noValid}
                />
                <Grid container>
                  <Grid item xs={8}>
                    <TextField
                      disabled={true}
                      variant="outlined"
                      style={{ color: "black" }}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <>
                            {file.label.length > 0 && (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleDeleteFile}
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    boxShadow: "none",
                                  }}
                                >
                                  <CancelIcon />
                                </IconButton>
                              </InputAdornment>
                            )}
                          </>
                        ),
                      }}
                      label={
                        file.label.length
                          ? file.label
                          : t("managementAds.uploadImg")
                      }
                      name="image"
                      size="small"
                      error={noValid}
                      helperText={errorFileMessage}
                    />
                  </Grid>
                  <Grid item xs={2} pr={1}>
                    <label htmlFor="icon-button-file">
                      <Button
                        style={{
                          border: "none",
                          outline: "none",
                          backgroundColor: "#007e8c",
                        }}
                        variant="contained"
                        component="span"
                      >
                        {t("button.inspect").toUpperCase()}
                      </Button>
                    </label>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <CircularProgress size={"1.6rem"} color="inherit" />
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      className="footer-btn danger-btn ripple"
                      style={{ border: "none", outline: "none" }}
                      onClick={() => {
                        dispatch(setActiveCategory(null));
                        dispatch(setFlagForm({ flagFormCreate: false }));
                      }}
                    >
                      {t("button.cancel").toUpperCase()}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      className="footer-btn primary-btn ripple"
                      style={{ border: "none", outline: "none" }}
                      disabled={
                        !values.name ||
                        !values.order ||
                        selectedItems.length === 0
                          ? true
                          : false
                      }
                    >
                      {activeCategory
                        ? t("button.edit").toUpperCase()
                        : t("button.save").toUpperCase()}
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <MyDialog
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message={
          activeCategory
            ? "¿Esta seguro de editar la categoría?"
            : "¿Esta seguro de crear la categoría?"
        }
        handleAccept={handleAccept}
        handleClose={() => setOpenDialog(false)}
        isLoading={!!loading}
      />
    </div>
  );
};

export default CategoryForm;
