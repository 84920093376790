import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";

import MyBreadCrumbs from "../../../components/Custom/MyBreadCrumbs";
import { useTranslation } from "react-i18next";
import Filter from "../../../components/Filter/Filter";
import {
  Enum_FieldType,
  IColumnsTable,
  Icons,
  IFilterField,
} from "../../../interfaces/components";
import { State } from "../../../enums/enums";
import { TableComponent } from "../../../components/Table/TableComponent";
import { MyCustomDialog } from "../../../components/Custom/MyCustomDialog";
import FormConfigParams from "../../components/managementConfigParams/FormConfigParams";
import { useDispatch, useSelector } from "react-redux";
import { uiSetOpenDialog } from "../../../redux/actions/uiAction";
import {
  setActiveConfigParam,
  setConfigParams,
  startGetConfigParams,
} from "../../../redux/actions/configParamsActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  asyncLoadingFunction,
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setRowsPerPage,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import FormCreateParam from "../../components/managementConfigParams/FormCreateParam";
import { useLocation } from "react-router-dom";
import { IConfigParamsQuery } from "../../../interfaces/configParams";

const ConfigParams = () => {
  const { t } = useTranslation();
  const [dataFilter, setDataFilter] = useState<any>({});
  const [isFilter, setIsFilter] = useState(false);
  const { openDialog } = useSelector((state: AppState) => state.ui);
  const { activeParam, configParams } = useSelector(
    (state: AppState) => state.configParams
  );
  const { flagFormCreate } = useSelector((state: AppState) => state.helper);
  const dispatch = useDispatch();
  const { routes: RoutesRedux } = useSelector(
    (state: AppState) => state.managementMenus
  );
  const { pathname } = useLocation();

  const modulePermissions = RoutesRedux.find(
    (route) => !!route.menuItem.find((module) => module.url === pathname)
  )?.menuItem.find((module) => module.url === pathname);
  const enableCreated =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.created === 1;

  const getConfigParams = async () => {
    dispatch(
      asyncLoadingFunction(
        async () => await dispatch(startGetConfigParams({ page: 1 }))
      )
    );
  };

  useEffect(() => {
    getConfigParams();
    dispatch(setRowsPerPage(9));
    return () => {
      dispatch(setConfigParams(null));
      dispatch(setRowsPerPage(9));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (configParams && configParams.data.length === 0) {
      dispatch(
        setMessageAlert(isFilter ? "alerts.noData" : "alerts.noRecords")
      );
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configParams]);

  const FilterFileds: IFilterField[] = [
    {
      label: t("configParams.paramCode"),
      type: Enum_FieldType.NUMBER,
    },
    {
      label: t("configParams.paramName"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("user.createAt"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("form.state"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("state.active"),
          value: State.ACTIVE,
        },
        {
          label: t("state.inactive"),
          value: State.INACTIVE,
        },
      ],
    },
  ];
  const handleFilter = (formValues: any) => {
    setIsFilter(true);
    const params: Partial<IConfigParamsQuery> = {
      code: formValues[t("configParams.paramCode")],
      name: formValues[t("configParams.paramName")],
      creationDate: formValues[t("user.createAt")],
      state: formValues[t("form.state")],
      page: 1,
    };
    dispatch(
      asyncLoadingFunction(
        async () => await dispatch(startGetConfigParams(params))
      )
    );
    //mostrar mensaje si no hay resultados de la bsuqyeda

    setDataFilter(params);
  };
  const columns: IColumnsTable[] = [
    { id: 1, header: "configParams.paramCode" },
    { id: 2, header: "configParams.paramName" },
    { id: 3, header: "user.createAt" },
    { id: 4, header: "form.state" },
    { id: 5, header: "table.actions" },
  ];
  const icons: Icons = {
    delete: false,
    seeMore: false,
    add: false,
  };

  const handleCloseForm = () => {
    dispatch(uiSetOpenDialog());
    dispatch(setActiveConfigParam(null));
  };
  const handleCloseFormCreate = () => {
    dispatch(setFlagForm({ flagFormCreate: false }));
  };
  const handleShowForm = () => {
    dispatch(setFlagForm({ flagFormCreate: true }));
  };
  const onCleanFilter = async () => {
    dispatch(
      asyncLoadingFunction(() => dispatch(startGetConfigParams({ page: 1 })))
    );
    setDataFilter({});

    setIsFilter(false);
  };

  return (
    <div className="content w-100 mb-5">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyBreadCrumbs
            titles={[t("sidebar.settings"), t("configParams.title")]}
          />
        </Grid>
        <div className="white-container w-100">
          {enableCreated && (
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
              >
                <Box display="flex" alignItems="center">
                  <p
                    className="txt-primary-md"
                    style={{
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      alignSelf: "center",
                    }}
                  >
                    {t("configParams.createParam")}
                  </p>
                </Box>
                <Box mr={1}>
                  <IconButton
                    onClick={handleShowForm}
                    id="openFormBtn"
                    style={{ border: "none", outline: "none" }}
                  >
                    <span className="btn-add">
                      <i
                        className="fa-solid fa-plus"
                        style={{ fontSize: 30 }}
                      ></i>
                    </span>
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Filter
              fields={FilterFileds}
              handleFilter={handleFilter}
              onClean={onCleanFilter}
            />
          </Grid>

          <TableComponent
            columns={columns}
            data={configParams ? configParams.data : []}
            pages={configParams ? configParams.last_page : 1}
            titleButton={t("button.createMenu")}
            subMenu={false}
            icons={icons}
            component="configParams"
            totalData={
              configParams
                ? configParams
                : { data: [], total: 0, page: 0, last_page: 0 }
            }
            isSortingTable={false}
            dataFilter={dataFilter}
          />
        </div>
        {activeParam && (
          <MyCustomDialog
            open={openDialog}
            content={<FormConfigParams />}
            onClose={handleCloseForm}
            title={`${t("button.edit")} ${activeParam.configParam.name}`}
          />
        )}

        <MyCustomDialog
          open={!!flagFormCreate}
          content={<FormCreateParam />}
          onClose={handleCloseFormCreate}
          title={t("configParams.createParam")}
        />
      </Grid>
    </div>
  );
};

export default ConfigParams;
