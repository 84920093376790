import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { headersComission } from "../../../helpers/headersTables";
import { getCopyArrayOrObject } from "../../../utils/utils";

export const ComissionsDataTable = (totalData: any[]) => {
  const { data } = useSelector((state: AppState) => state.search);

  const headersCvsStyle = headersComission.map((header) => {
    return {
      title: header.label,
      width: { wpx: 120 },
      style: {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { patternType: "solid", fgColor: { rgb: "02D0E7" } },
        alignment: { vertical: "center", horizontal: "center" },
      },
    };
  });
  // Obtener datos por vista
  const dataComission =
    data && data.data ? getCopyArrayOrObject(data.data) : [];
  // Obtener total datos
  const dataTotalComission = totalData ? getCopyArrayOrObject(totalData) : [];
  const buildTotalData = (arr: any) => {
    const multiData = [
      {
        columns: headersCvsStyle,
        data: arr.map((data: any) => {
          const array = [
            {
              value: data.movementType,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.quantity,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.total,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: data.commission,
              style: { alignment: { horizontal: "center" } },
            },
          ];
          return array;
        }),
      },
    ];
    return multiData;
  };

  const multiTotalData = buildTotalData(dataTotalComission);
  const multiData = buildTotalData(dataComission);
  const finalData = {
    dataPerView: dataComission,
    dataTotal: dataTotalComission,
    headersCSV: headersComission,
    multiDataSet: multiData,
    multiTotalDataSet: multiTotalData,
  };
  return finalData;
};
