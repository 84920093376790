import React, { useState, KeyboardEvent } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IExpand } from "./MyAccountSettings";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { editUsernameOrPassword } from "../../../redux/actions/managementUsersActions";
import { Form, Formik } from "formik";
import { MyTextField } from "../custom/MyTextField";
import * as yup from "yup";
import { regPassword } from "../../../utils/utils";
import { setLoadingState } from "../../../redux/actions/helperActions";

interface Props {
  expand: IExpand;
  setExpand: React.Dispatch<React.SetStateAction<IExpand>>;
  setUserChanged: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ChangePassword = ({
  expand,
  setExpand,
  setUserChanged,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { loading } = useSelector((state: AppState) => state.helper);
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validationSchema = yup.object({
    password: yup
      .string()
      .min(6, t("alerts.lengthPassword"))
      .required(t("form.requiredFile"))
      .matches(
        //eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*()_+\-=;':"\\|,.?~])/,
        t("user.rexPassword")
      ),
    confirmPassword: yup
      .string()
      .required(t("form.requiredFile"))
      .oneOf([yup.ref("password"), null], () => (
        <>
          <i className="fa-solid fa-circle-exclamation" />{" "}
          {t("user.matchingPasswords")}
        </>
      )),
  });

  return (
    <>
      <Grid
        item
        xs={12}
        ml={2}
        mt={1}
        className={
          expand.changePassword ? "box-account-active" : "box-account cursor"
        }
        onClick={() =>
          setExpand({
            userInfo: false,
            changeUser: false,
            changePassword: !expand.changePassword,
          })
        }
      >
        <Box display={"flex"} alignItems="center" ml={2}>
          <Typography color="text.primary" style={{ fontSize: "16px" }}>
            {t("account.changePassword")}
          </Typography>
        </Box>
        <IconButton
          size="small"
          style={{ border: "none", outline: "none" }}
          onClick={() =>
            setExpand({
              userInfo: false,
              changeUser: false,
              changePassword: !expand.changePassword,
            })
          }
        >
          {expand.changePassword ? (
            <i className="fa fa-angle-up" />
          ) : (
            <i className="fa fa-angle-down" />
          )}
        </IconButton>
      </Grid>

      {expand.changePassword && (
        <Grid container>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={true}
              onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);
                setUserChanged(false);
                dispatch(setLoadingState({ loading: true }));
                token &&
                  user &&
                  (await dispatch(
                    editUsernameOrPassword(
                      { password: data.password },
                      token,
                      user?.id,
                      false,
                      setUserChanged
                    )
                  ));
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, handleChange }) => (
                <Form>
                  <Grid
                    container
                    m={1}
                    spacing={1}
                    style={{ cursor: "default !important" }}
                  >
                    <Grid item xs={5}>
                      <label className="label-text ml-2">
                        {t("user.password")}*
                      </label>
                      <MyTextField
                        name="password"
                        InputLabelProps={{ shrink: false }}
                        label={" "}
                        fullWidth
                        onChange={handleChange}
                        autoComplete="off"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onClick={() => setShowPassword(!showPassword)}
                                sx={{
                                  "&:focus": {
                                    outline: 0,
                                    border: 0,
                                  },
                                }}
                              >
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        type={showPassword ? "text" : "password"}
                        inputProps={{ maxLength: 50 }}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                          !regPassword(e.key) && e.preventDefault();
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <label className="label-text ml-2">
                        {t("user.confirmPassword")}*
                      </label>
                      <MyTextField
                        name="confirmPassword"
                        InputLabelProps={{ shrink: false }}
                        label={" "}
                        fullWidth
                        onChange={handleChange}
                        autoComplete="off"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                sx={{
                                  "&:focus": {
                                    outline: 0,
                                    border: 0,
                                  },
                                }}
                              >
                                {showConfirmPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        type={showConfirmPassword ? "text" : "password"}
                        inputProps={{ maxLength: 50 }}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                          !regPassword(e.key) && e.preventDefault();
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} display="flex" mt={3} pr={2}>
                      <Button
                        fullWidth
                        type="submit"
                        style={{
                          border: "none",
                          outline: "none",
                          backgroundColor: "#02D0E7",
                          height: "40px",
                          marginBottom: "2px",
                        }}
                        variant="contained"
                        disabled={isSubmitting || !!loading}
                      >
                        {isSubmitting ? (
                          <CircularProgress size={"1.6rem"} color="inherit" />
                        ) : (
                          <>{t("button.save")}</>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      )}
    </>
  );
};
