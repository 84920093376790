import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  asyncLoadingFunction,
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import {
  setActiveBonusGroup,
  setOpenBonusDetails,
  startGetBonusGroup,
} from "../../../../redux/actions/bonusActions";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import { State } from "../../../../enums/enums";
import { Grid } from "@mui/material";
import Filter from "../../../../components/Filter/Filter";
import MyCustomTable from "../../../../components/Custom/Table/MyCustomTable";

import { MyCustomDialog } from "../../../../components/Custom/MyCustomDialog";
import { RowAutomaticBonus } from "../../../components/managementBonus/automaticBonus/RowMasiveBonus";
import { BonusGroupDetails } from "./BonusGroupDetails";

export const AutomaticBonus = () => {
  const { t } = useTranslation();
  const { flagFormCreate } = useSelector((state: AppState) => state.helper);
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);
  const { bonusGroup, openDetails } = useSelector(
    (state: AppState) => state.bonus
  );
  const [dataFilter, setDataFilter] = useState<any>({});
  const { user } = useSelector((state: AppState) => state.auth);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFiltering, setIsFiltering] = useState(false);
  const dispatch = useDispatch();

  const fetchBonus = async () => {
    if (user) {
      let params: any = {
        page: currentPage,
        perPage: rowsPerPage,
      };
      await dispatch(
        asyncLoadingFunction(() =>
          dispatch(
            startGetBonusGroup({
              ...params,
            })
          )
        )
      );
    }
  };

  useEffect(() => {
    fetchBonus();
    return () => {
      dispatch(setFlagForm({ flagFormCreate: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!flagFormCreate) {
      fetchBonus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagFormCreate]);

  useEffect(() => {
    if (bonusGroup && bonusGroup.length === 0) {
      dispatch(
        setMessageAlert(isFiltering ? "alerts.noData" : "alerts.noRecords")
      );
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bonusGroup]);

  const FilterFileds: IFilterField[] = [
    {
      label: t("managementBonds.bondName"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("table.description"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("form.state"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("state.active"),
          value: State.ACTIVE,
        },
        {
          label: t("state.inactive"),
          value: State.INACTIVE,
        },
      ],
    },
  ];

  const handlePageChange = async (page: number, rows?: number) => {
    setCurrentPage(page);
    await dispatch(
      startGetBonusGroup({
        page: page,
        perPage: rows || rowsPerPage,
        ...dataFilter,
      })
    );
  };
  const handleFilter = async (values: any) => {
    setIsFiltering(true);
    const params = {
      name: values[t("managementBonds.bondName")].toString(),
      state: values[t("form.state")].toString(),
      description: values[t("table.description")].toString(),
    };
    await dispatch(
      asyncLoadingFunction(() =>
        dispatch(startGetBonusGroup({ ...params, page: 1 }))
      )
    );
    setDataFilter({ ...params });
  };
  const onCleanFilter = async () => {
    dispatch(
      asyncLoadingFunction(() => dispatch(startGetBonusGroup({ page: 1 })))
    );
    setDataFilter({});
    setIsFiltering(false);
  };

  const handleCloseDetails = () => {
    dispatch(setActiveBonusGroup(null));
    dispatch(setOpenBonusDetails(false));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Filter
          fields={FilterFileds}
          handleFilter={handleFilter}
          onClean={onCleanFilter}
        />
      </Grid>
      <Grid item xs={12}>
        <MyCustomTable
          columns={[
            {
              align: "center",
              label: t("managementBonds.groupName"),
            },
            {
              align: "center",
              label: t("table.description"),
            },
            {
              align: "center",
              label: t("managementBonds.qtyBonus"),
            },
          ]}
          data={bonusGroup || []}
          lastPage={bonusGroup?.last_page || 1}
          totalData={bonusGroup?.total || 0}
          renderBody={(item) => <RowAutomaticBonus row={item} />}
          onPageChange={handlePageChange}
        />
      </Grid>

      <MyCustomDialog
        open={openDetails}
        content={<BonusGroupDetails />}
        onClose={handleCloseDetails}
        title={t("managementBonds.infoDeliveredBonus")}
      />
    </Grid>
  );
};
