import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Box, Tab, Tabs } from "@mui/material";
import { TabConsultTicket } from "./TabConsultTicket";
import { TabBettingHistory } from "./TabBettingHistory";
import consultIcon from "../../../assets/svg/consult.svg";
import historyIcon from "../../../assets/svg/history.svg";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { TabSellerQueryTicket } from "./TabSellerQueryTicket";
import { TabSellerBettingHistory } from "./TabSellerBettingHistory";
import { TabSellersPaymentsHistory } from "./TabSellersPaymentsHistory";
import { arraySearch } from "../../../redux/actions/searchActions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const styles = {
  "&.Mui-selected": {
    color: "#55BCD9",
    border: "none",
    outline: "none",
    paddingLeft: 0,
  },
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Props {
  onChangeComponent(option: string): void;
}
export const TabsBets: FC<Props> = ({ onChangeComponent }) => {
  const [value, setValue] = React.useState(0);
  const { user }: any = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();
  const isSeller = user.roles.name === "SELLER";
  const isSPointSale = user.roles.name === "POINT_SALE";

  const handleChange = async(event: React.SyntheticEvent, newValue: number) => {
    await dispatch(dispatch(arraySearch(null)));
    switch (newValue) {
      case 0:
        onChangeComponent(t("bets.consultTicket"));
        break;
      case 1:
        onChangeComponent(t("reports.associateBets"));
        break;
      case 2:
        onChangeComponent(t("reports.associateAwards"));
        break;
      default:
        break;
    }
    setValue(newValue);
  };
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          style={{ border: "none", outline: "none" }}
          TabIndicatorProps={{
            style: { 
              backgroundColor: " #02D0E7", 
              fontWeight: "bold",
            },
          }}
        >
          <Tab
            sx={styles}
            label={
              <span className="spanTab">
                <img
                  loading="lazy"
                  style={{
                    width: "25px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                  src={consultIcon}
                  alt=""
                />
                {t("bets.consultTicket")}
              </span>
            }
            {...a11yProps(0)}
          />
          <Tab
            sx={styles}
            label={
              <span className="spanTab">
                <img
                  loading="lazy"
                  style={{
                    width: "25px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                  src={historyIcon}
                  alt=""
                />
                {t("reports.associateBets")}
              </span>
            }
            {...a11yProps(1)}
          />
          {isSeller && (
            <Tab
              sx={styles}
              label={
                <span className="spanTab">
                  <img
                    src={historyIcon}
                    alt=""
                    style={{
                      width: "25px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                  />
                  {t("reports.associateAwards")}
                </span>
              }
              {...a11yProps(2)}
            />
          )}
        </Tabs>
      </Box>
      <div className="filter mt-4">
        <TabPanel value={value} index={0}>
          {isSeller || isSPointSale ? (
            <TabSellerQueryTicket />
          ) : (
            <TabConsultTicket />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {isSeller || isSPointSale ? (
            <TabSellerBettingHistory />
          ) : (
            <TabBettingHistory />
          )}
        </TabPanel>
        {isSeller && (
          <TabPanel value={value} index={2}>
            <TabSellersPaymentsHistory />
          </TabPanel>
        )}
      </div>
    </Box>
  );
};
