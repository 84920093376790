import { createTheme, Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { StyleSheet } from "@react-pdf/renderer";

export const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: "#707070",
        },
        colorPrimary: {
          "&.Mui-checked": {
            color: "#04BF9D",
          },
        },
        track: {
          ".Mui-checked.Mui-checked + &": {
            opacity: 0.58,
            backgroundColor: "#04BF9D",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.MuiMenuItem-root:hover": {
            color: "#036A76 !important",
            backgroundColor: "#E5F2F3",
          },
          "&.MuiMenuItem-root:focus": {
            backgroundColor: "#white",
            color: "#333333",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          "&.Mui-focused": {
            color: "white !important",
            backgroundColor: "#02D0E7 !important",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          height: "35px",
          borderRadius: "10px",
          textTransform: "none",
          "&.Mui-selected": {
            color: "white",
            backgroundColor: "#007E8C !important",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            color: "black",
          },
          "&.Mui-error": {
            color: "black",
          },
          "&:hover .MuiInputLabel-root": {
            color: "black",
          },
          "&.Mui-focused": {
            color: "black",
          },
        },
        outlined: {
          color: "black",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: 1,
            borderColor: "#DADADA",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderWidth: 2,
            borderColor: "#02D0E7",
            color: "green",
          },
          "&.Mui-focused fieldset": {
            borderWidth: 0,
            borderTop: "0px solid #DADADA",
            borderBottom: "1px solid #02D0E7",
          },
          "&.Mui-error": {
            backgroundColor: "#FCF0F1",
            color: "black",
          },
        },
      },
    },
  },
});

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: "18px",
    fontWeight: 300,
    color: "#333333",
  },
  btnConfirm: {
    backgroundColor: "#02D0E7",
    color: "white",
  },
  message: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#333333",
  },
  tooltip: {
    backgroundColor: "transparent",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  tableCellPadding: {
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  cellTextPrimary: {
    fontSize: 14,
    fontWeight: "bold",
    height: "40px",
    marginBottom: "4px",
  },
  cellTextSecondary: {
    fontSize: 13.5,
    height: "50px",
  },
  cellMinWidth: {
    minWidth: 100,
    padding: "8px 0",
  },
  cellMaxWidth: {
    maxWidth: 100,
    padding: "8px 0",
  },
  subtitleCellTextPrimay: {
    color: "#333333",
    fontWeight: 600,
    height: "35px",
    display: "flex",
    alignItems: "center",
  },
  textCellSecondary: {
    height: "50px",
  },
}));

export const stylesPdf = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 4,
    marginTop: 30,
  },
  container: {
    height: "100%",
    width: "90%",
    marginHorizontal: "5%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  logo: {
    width: "70px",
    height: "20px",
  },
  text: {
    fontSize: "6px",
    color: "#fff",
    fontWeight: "bold",
  },
  col: {
    flex: 1,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    // borderWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    flexWrap: "wrap",
  },
  textContent: {
    fontSize: "6px",
    color: "#333333",
  },
  textContentMap: {
    fontSize: "6px",
    color: "#333333",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    flexWrap: "wrap",
  },
  pCol: {
    paddingHorizontal: 5,
    paddingVertical: 3,
  },
  pColMap: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
});
