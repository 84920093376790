import {
  Grid,
  InputAdornment,
  TextField,
  Box,
  CircularProgress,
} from "@mui/material";
import React, { useState, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "reactstrap";
import SearchIcon from "@mui/icons-material/Search";
import LogoTicket from "../../../assets/svg/ticket.svg";
import { IColumnsTable, Icons } from "../../../interfaces/components";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { TableComponent } from "../../../components/Table/TableComponent";
import parse from "html-react-parser";
import { MyDialog } from "../custom/MyDialog";
import { uiSetOpenDialog } from "../../../redux/actions/uiAction";
import { regSpecialCharacters } from "../../../utils/utils";
import {
  setCloseAlert,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { MyAlert } from "../custom/MyAlert";
import {
  checkTicketAssociateSeller,
  payBetsByAssociateSeller,
} from "../../../services/bets";
import { IBet } from "../../../interfaces/bets";
import { MyDialogInformation } from "../../../components/DialogInformation/MyDialogInformation";
import { setActiveTicket } from "../../../redux/actions/betsActions";
import { startGetPointSaleWallet } from "../../../redux/actions/walletActions";

const icons: Icons = {
  see: false,
  delete: false,
  seeMore: true,
  edit: false,
  pay: true,
};

export const TabSellerQueryTicket = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { value } = useSelector((state: AppState) => state.search);
  const { token } = useSelector((state: AppState) => state.auth);
  const { openDialog } = useSelector((state: AppState) => state.ui);
  const { activeBet } = useSelector((state: AppState) => state.bets);
  const [ticket, setTicket] = useState(value !== "" ? value : "");
  const [dataTicket, setDataTicket] = useState<IBet | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingTicket, setLoadingTicket] = useState(false);
  const { openAlert, typeAlert, messageAlert } = useSelector(
    (state: AppState) => state.helper
  );

  /**
   * Soporta las Columnas para renderizar en el header de la tabla
   */
  const columns: IColumnsTable[] = [
    { id: 1, header: "table.betId" },
    { id: 3, header: "bets.ticketNumber" },
    { id: 4, header: "bets.dateBet" },
    { id: 5, header: "bets.hourBet" },
    { id: 6, header: "bets.winningAmount" },
    { id: 7, header: "bets.bettingStatus" },
    { id: 8, header: "table.actions" },
  ];

  const handleCancel = () => {
    dispatch(uiSetOpenDialog());
    dispatch(setActiveTicket(null));
  };

  /**
   * MENSAJE DE CONFIRMACIÓN A ENVIAR -> PARSEADO
   */
  const textConfirmation =
    `<span >${t("bets.dialogTicktetMsg1")} </span>` +
    ` <span style="font-weight:bold">#${activeBet?.reference},</span>` +
    `${t("bets.dialogTicktetMsg2")} ` +
    ` <span style="font-weight:bold">${
      activeBet && activeBet.transactions.length > 0
        ? parseFloat(activeBet.transactions[0].amount).toFixed(2)
        : ""
    }</span> ` +
    `<br>` +
    `<span style="display:flex; justify-content: center; font-weight:bold; margin-top: 4px;">${t(
      "bets.surePaid"
    )}</span>`;

  const textSend = parse(textConfirmation);

  const handleSearchTicket = async () => {
    setLoadingTicket(true);
    if (ticket.trim()) {
      const cleanTicket = ticket.trim();
      const response: any =
        token && (await checkTicketAssociateSeller(token, cleanTicket));
      if (response && !response.error) {
        // @ts-ignore
        setDataTicket({ data: [response] });
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("bets.ticketNumberNotExist"));
        dispatch(setTypeAlert("error"));
        setDataTicket(null);
      }
    } else {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.errorSetBets"));
      dispatch(setTypeAlert("error"));
    }
    setLoadingTicket(false);
  };

  const handleAccept = async () => {
    setLoading(true);
    if (ticket.trim()) {
      const response: any =
        // @ts-ignore
        token && (await payBetsByAssociateSeller(token, activeBet?.reference));
      if (response && !response.error) {
        setLoading(false);
        dispatch(uiSetOpenDialog());
        dispatch(setActiveTicket(null));
        handleSearchTicket();
        await dispatch(startGetPointSaleWallet());
      } else {
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("bets.ticketNumberNotExist"));
        dispatch(setTypeAlert("error"));
      }
    } else {
      dispatch(setOpenAlert());
      dispatch(setMessageAlert("alerts.errorSetBets"));
      dispatch(setTypeAlert("error"));
    }
    setLoading(false);
  };

  const onCloseAlert = async () => {
    await dispatch(setCloseAlert());
  };

  return (
    <div className="container-fluid">
      <Row
        style={{
          marginTop: "12px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} pt={3} mt={2}>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={1} mt={2}>
                <img
                  width={95}
                  height={95}
                  src={LogoTicket}
                  alt="Icon exclamation"
                  style={{ marginRight: "10px" }}
                />
              </Grid>
              <Grid
                item
                xs={3}
                sm={4}
                md={4}
                display={"flex"}
                alignItems={"center"}
              >
                <label className="ml-3 mr-3" style={{ marginBottom: 0 }}>
                  {t("table.betId")}
                </label>
                <TextField
                  name="ticket"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: false }}
                  inputProps={{ maxLength: 100 }}
                  label={" "}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setTicket(e.target.value);
                  }}
                  value={ticket}
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={handleSearchTicket}
                        >
                          <SearchIcon fontSize="large" />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    regSpecialCharacters(e.key) && e.preventDefault()
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Row>
      {loadingTicket ? (
        <Box
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
          width="100%"
        >
          <CircularProgress className={"colorLoading"} />
        </Box>
      ) : (
        dataTicket &&
        dataTicket.data.length > 0 && (
          <>
            <TableComponent
              columns={columns}
              icons={icons}
              data={dataTicket.data}
              pages={0}
              component={"tickets"}
              totalData={dataTicket}
              isSortingTable={false}
              dataFilter={{}}
            />
          </>
        )
      )}
      <MyDialog
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message={activeBet ? textSend : ""}
        handleAccept={handleAccept}
        handleClose={handleCancel}
        component={"tickets"}
        isLoading={loading}
      />

      <MyAlert
        open={openAlert}
        message={messageAlert ? messageAlert : ""}
        typeAlert={typeAlert}
        handleClose={onCloseAlert}
      />

      <MyDialogInformation component={"bets"} title={"bets.betDetail"} />
    </div>
  );
};
