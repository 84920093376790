import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { FC, KeyboardEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IFullDetailPlayerData } from "../../../interfaces/users";
import {
  regNit,
  regEmail,
  regLetters,
  regUsername,
  regLettersNumbers,
  regUrl,
  regNumbers,
  regAddress,
  regDecimal,
} from "../../../utils/utils";

interface Props {
  player: IFullDetailPlayerData | null;
  label: string;
  component?: string;
  formValues: any;
  isValidEmail: boolean;
  handleInputChangeMain: (e: any) => void;
  type?: "text" | "number";
}

export const TextFieldFilter: FC<Props> = ({
  player,
  label,
  component,
  formValues,
  isValidEmail,
  handleInputChangeMain,
  type,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<any>(formValues[`${label}`]);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setValue(e.target.value);

    handleInputChangeMain && handleInputChangeMain(e);
  };

  useEffect(() => {
    const dataValueTextfield = () => {
      if (player && player.data?.length > 0 && component === "player") {
        switch (label) {
          case t("filters.documentNumber"):
            return setValue(player.data[0].numberDocument);
          case t("filters.id"):
            return setValue(player.data[0].id?.toString());
          case t("filters.email"):
            return setValue(player.data[0].email);
          case t("user.username"):
            return setValue(
              player.data[0].firstName.trim() +
                " " +
                player.data[0].lastName.trim()
            );
          default:
            return setValue(formValues[`${label}`]);
        }
      } else {
        setValue(formValues[`${label}`]);
      }
    };
    dataValueTextfield();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label, player, formValues]);

  const validateFields = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      label === t("filters.email") ||
      label === t("pointsOfSale.email") ||
      label === t("managementDealers.dealerEmail") ||
      label === t("managementPromoters.promoterEmail")
    ) {
      return !regEmail(e.key) && e.preventDefault();
    }
    if (label === t("filters.username")) {
      return !regUsername(e.key) && e.preventDefault();
    }
    if (label === t("companyManagement.nit")) {
      return !regNit(e.key) && e.preventDefault();
    }
    if (label === t("table.url")) {
      return !regUrl(e.key) && e.preventDefault();
    }
    if (
      label === t("managementBonds.bonusCode") ||
      label === t("associates.idCode") ||
      label === t("user.phone") ||
      label === t("filters.id")
    ) {
      return !regNumbers(e.key) && e.preventDefault();
    }
    if (label === t("form.value")) {
      return !regDecimal(e.key) && e.preventDefault();
    }
    if (label === t("user.address") || label === t("pointsOfSale.address")) {
      return !regAddress(e.key) && e.preventDefault();
    }
    if (
      label === t("filters.name") ||
      label === t("companyManagement.companyName") ||
      label === t("table.createdBy") ||
      label === t("menu.users.creator")
    ) {
      return !regLetters(e.key) && e.preventDefault();
    } else {
      return !regLettersNumbers(e.key) && e.preventDefault();
    }
  };

  const handleFieldLength = () => {
    let maxLength = 50;

    if (
      label === t("managementBonds.bonusCode") ||
      label === t("filters.id") ||
      label === t("transactions.setId") ||
      label === `${t("sidebar.player")} id` ||
      label === t("withdrawals.withdrawalNumber") ||
      label === `${t("login.user")} id`
    ) {
      maxLength = 9;
    }
    if (
      label === t("companyManagement.nit") ||
      label === t("user.phone") ||
      label === t("pointsOfSale.phone") ||
      label === t("table.serial")
    ) {
      maxLength = 10;
    }
    if (
      label === t("table.createdBy") ||
      label === t("filters.email") ||
      label === t("legal.title")
    ) {
      maxLength = 100;
    }
    if (
      label === t("companyManagement.companyName") ||
      label === t("companyManagement.brand") ||
      label === t("filters.name")
    ) {
      maxLength = 150;
    }
    if (label === t("filters.documentNumber")) {
      maxLength = 13;
    }
    if (label === t("transactions.value")) {
      maxLength = 8;
    }
    return maxLength;
  };

  return (
    <TextField
      variant="outlined"
      sx={{
        "& legend": { display: "none" },
        "& fieldset": { top: 0 },
      }}
      size="small"
      fullWidth
      type={type || "text"}
      name={`${label}`}
      id={`${label}`}
      value={value}
      onChange={(e) => {
        if (type === "number") {
          if (e.target.value.length <= handleFieldLength()) {
            handleChange(e);
          }
        } else {
          handleChange(e);
        }
      }}
      placeholder={`${label}`}
      error={label === t("filters.email") && !isValidEmail}
      helperText={
        label === t("filters.email") && !isValidEmail
          ? t("user.invalidEmail")
          : ""
      }
      onKeyPress={(e: any) => {
        type === "number"
          ? !regNumbers(e.key) && e.preventDefault()
          : validateFields(e);
      }}
      inputProps={{ maxLength: handleFieldLength() }}
      InputProps={{
        endAdornment: label === t("legal.title") && (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
