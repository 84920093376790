import {
  IConfigOperator,
  IOperationManagForm,
} from "../interfaces/operationCompany";

export const getOperatorsService = (
  token: string,
  params?: any,
  exact?: boolean
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  let queryString = params ? new URLSearchParams(params).toString() : "";
  queryString = exact ? queryString + "&exact=true" : queryString;

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}operators?${queryString}`,
    requestOptions
  )
    .then((response) => response.json())

    .then((responseData) => responseData)
    .catch((error) => {
      console.warn(error);
    });
};
export const getOperatorByIdService = (token: string, id: number) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}operators/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const editOperatorService = (
  data: Partial<IOperationManagForm>,
  token: string,
  id: number
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}operators/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const addOperatorService = (
  data: IOperationManagForm,
  token: string
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}operators`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const getBrandsService = (
  token: string,
  userId?: number | null,
  operatorId?: number | undefined,
  params?: any
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const queryString = params ? new URLSearchParams(params).toString() : "";
  const operator = operatorId
    ? userId
      ? `operatorId=${operatorId}&userId=${userId}`
      : `operatorId=${operatorId}`
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}brand?${operator}&${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const addBrandService = (data: IConfigOperator | any, token: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}brand`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const editBrandService = (
  data: IConfigOperator,
  token: string,
  id: number
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}brand/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
