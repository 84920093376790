import { View, Text, Font } from "@react-pdf/renderer";
import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import { ILegalDb } from "../../../interfaces/legal";
import { State } from "../../../enums/enums";

Font.registerHyphenationCallback((word) => {
  const middle = Math.floor(word.length / 2);
  const parts =
    word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];

  return parts;
});

interface Props {
  page: any;
  perView?: boolean;
}

export const LegalPdfDocument: FC<Props> = ({ page, perView }) => {
  const { t } = useTranslation();
  const columnsLegal = [
    t("legal.version").toUpperCase(),
    t("legal.title").toUpperCase(),
    t("form.state").toUpperCase(),
    t("user.createAt").toUpperCase(),
  ];
  const columns = columnsLegal;

  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, index) => (
          <View
            key={column}
            style={[stylesPdf.pCol, { width: index === 1 ? "55%" : "15%" }]}
          >
            <Text style={stylesPdf.text}>{column}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        {page.map((page: any) => (
          <View key={page.id} style={stylesPdf.row}>
            <View
              style={[
                stylesPdf.cell,
                {
                  width: "15%",
                },
              ]}
            >
              <Text style={stylesPdf.textContent}>
                {"V." + (page as ILegalDb).version}
              </Text>
            </View>
            <View
              style={[
                stylesPdf.pCol,
                {
                  width: "55%",
                  flex: 1,
                },
              ]}
            >
              <Text style={[stylesPdf.textContent]}>
                {(page as ILegalDb).title}
              </Text>
            </View>

            <View
              style={[
                stylesPdf.pCol,
                {
                  width: "15%",
                  backgroundColor:
                    page.state === "Activo" || page.state === State.ACTIVE
                      ? "#c6efce"
                      : "#ffc7ce",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={[
                  stylesPdf.textContent,
                  {
                    color:
                      page.state === "Activo" || page.state === State.ACTIVE
                        ? "#0d6a44"
                        : "#ad120b",
                  },
                ]}
              >
                {page.state === "Activo" || page.state === State.ACTIVE
                  ? t("state.active")
                  : t("state.inactive")}
              </Text>
            </View>

            <View style={[stylesPdf.pCol, { width: "15%" }]}>
              <Text style={stylesPdf.textContent}>
                {perView
                  ? moment((page as ILegalDb).createAt).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )
                  : page.createAt}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};
