import {
  IPointsSalesFilter,
  IQueryAssociateReport,
  IRequestedCardsQuery,
} from "../interfaces/reports";

export const generalReports = (
  operatorId: number | null,
  token: string | null,
  type: string
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}reports/${type}/${operatorId}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const playerSessionReport = (token: string, params: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}reports/playerSessionReport?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const playerExlusionsReport = async (token: string, params: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  const response = await fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}reports/getExclusionsReport?${queryParams}`,
    requestOptions
  );
  const responseData = await response.json();
  return responseData;
};

export const gameRevenueReport = (token: string | null, params: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}reports/gameRevenueReport?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const playerTransactionsReport = async (token: string, params: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  const response = await fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}reports/getTransactionsReport?${queryParams}`,
    requestOptions
  );
  const responseData = await response.json();
  return responseData;
};

export const getAssociateReport = (
  token: string,
  params: Partial<IQueryAssociateReport>,
  pointsSales: IPointsSalesFilter[]
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(pointsSales),
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}reports/associate?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const getBetsByAssociate = async (
  token: string,
  params: Partial<IQueryAssociateReport>,
  pointsSales: IPointsSalesFilter[]
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(pointsSales),
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  const response = await fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bets/bets-history-associate?${queryParams}`,
    requestOptions
  );
  const responseData = await response.json();
  return responseData;
};

export const getPaymentsAwardsByAssociate = async (
  token: string,
  params: Partial<IQueryAssociateReport>,
  pointsSales: IPointsSalesFilter[]
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(pointsSales),
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  const response = await fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}bets/pay-history-associate?${queryParams}`,
    requestOptions
  );
  const responseData = await response.json();
  return responseData;
};

export const getResumeIrrigationCards = async (
  token: string,
  params: Partial<IQueryAssociateReport>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  const response = await fetch(
    `${process.env.REACT_APP_API_HTTP_BETS}api/cards/irrigationResume?${queryParams}`,
    requestOptions
  );
  const responseData = await response.json();
  return responseData;
};

export const getAffiliationsReport = (
  token: string,
  params: Partial<IQueryAssociateReport>,
  pointsSales: IPointsSalesFilter[]
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(pointsSales),
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_PLAYERS}affiliations/GGR/Affiliate?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const getRequestedCards = async (
  token: string,
  params: Partial<IRequestedCardsQuery>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  const response = await fetch(
    `${process.env.REACT_APP_API_HTTP_UTILITIES}reports/cardsPuntoPago?${queryParams}`,
    requestOptions
  );
  const responseData = await response.json();
  return responseData;
};
