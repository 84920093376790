import { IMenuItem, IMenuListCreate, IMenuParams } from "./../interfaces/menu";

export const getMenusDb = (token: string, params: Partial<IMenuParams>) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryString = params
    ? new URLSearchParams(params as any).toString()
    : "";
  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}menu?${queryString}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const getMenusService = (
  token: string,
  operatorId: number | undefined,
  params?: any,
  page?: number,
  perPage?: number | null
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryString = params ? new URLSearchParams(params).toString() : "";
  const paginationQuery =
    page && perPage ? `&page=${page}&perPage=${perPage}` : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}menu?${queryString}${
      operatorId ? "&operatorId=" + operatorId : ""
    }${paginationQuery}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const getMenusPage = (
  token: string,
  operatorId: number | undefined,
  userId?: number,
  page?: number,
  rowsPerPage?: number
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const params = rowsPerPage
    ? operatorId
      ? `operatorId=${operatorId}&page=${page}&perPage=${rowsPerPage}`
      : `page=${page}&perPage=${rowsPerPage}`
    : operatorId
    ? `operatorId=${operatorId}&page=${page}`
    : `page=${page}`;

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}menu?${params}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const createMenuService = (data: IMenuListCreate, token: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${process.env.REACT_APP_API_HTTP_SECURITY}menu/`, requestOptions)
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const editMenuService = (
  data: IMenuListCreate,
  token: string,
  id: number
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${process.env.REACT_APP_API_HTTP_SECURITY}menu/${id}`, requestOptions)
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const deleteMenuService = (
  token: string,
  idMenu: number
): Promise<any> => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}menu/${idMenu}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const editModuleService = (
  data: IMenuItem,
  token: string,
  id: number
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(`${process.env.REACT_APP_API_HTTP_SECURITY}module/${id}`, requestOptions)
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const deleteModuleService = (
  token: string,
  idmodule: string
): Promise<any> => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}menuItems/${idmodule}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
export const getMenusLogged = (token: string, idRol: number,filter?:boolean) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const filterParam = filter? `?filter=${filter}`:""

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}menu/logged/${idRol}${filterParam}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};
