import React, { KeyboardEvent, useEffect } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { MyTextField } from "../custom/MyTextField";
import { regLettersNumbers, regNumbers } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Roles_Enum, State } from "../../../enums/enums";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { setCompaniesRedux } from "../../../redux/actions/managementCompanyActions";
import { getOperatorsService } from "../../../services/managementCompanyService";
import { startCreateParam } from "../../../redux/actions/configParamsActions";
import { IConfigParamForm } from "../../../interfaces/configParams";
import {
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import { getConfigParams } from "../../../services/configParams";

const styles = {
  "& input.Mui-disabled ": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
  "& .MuiSelect-select.Mui-disabled": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FormCreateParam = () => {
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    if (isSuper && token) {
      fetchOperators(token);
    }
    return () => {
      dispatch(setCompaniesRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    code: "",
    name: "",
    operator: isSuper ? "" : user?.operator.id,
  };
  const validationSchema = yup.object({
    name: yup.string().required(t("form.requiredFile")),
    code: yup.string().required(t("form.requiredFile")),
    operator: yup.string().required(t("form.requiredFile")),
  });
  const isEmptyValues = (formValues: any) => {
    let emptyValues = false;

    Object.entries(formValues).forEach(([key, value]) => {
      if (value === "") {
        emptyValues = true;
      }
    });

    return emptyValues;
  };
  const areDuplicatedValues = async (formValues: any) => {
    const { operator, code, name } = formValues;
    let areDuplicated = false;
    if (!token) {
      return true;
    }
    const codeResponse = await getConfigParams(token, {
      code,
      operator,
    });
    const nameResponse = await getConfigParams(token, {
      name,
      operator,
    });

    if (codeResponse.rs.data.length > 0 || nameResponse.rs.data.length > 0) {
      areDuplicated = true;
    }

    return areDuplicated;
  };
  const showDuplicateAlert = () => {
    dispatch(setMessageAlert(`configParams.duplicatedValues`));
    dispatch(setTypeAlert("error"));
    dispatch(setOpenAlert());
  };
  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          data.code = parseInt(data.code).toString();
          const areDuplicated = await areDuplicatedValues(data);
          if (!areDuplicated) {
            await dispatch(startCreateParam(data as IConfigParamForm));
            dispatch(setFlagForm({ flagFormCreate: false }));
          } else {
            showDuplicateAlert();
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleChange, isValid, values }) => (
          <Form>
            <Grid container spacing={2}>
              {isSuper && companies && (
                <Grid item xs={4}>
                  <TextField
                    name="operator"
                    size="small"
                    select
                    fullWidth
                    onChange={handleChange}
                    value={values.operator}
                    label={`${t("companyManagement.operator")}*`}
                    sx={styles}
                    SelectProps={{
                      MenuProps,
                    }}
                  >
                    {companies?.data?.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              <Grid item xs={isSuper ? 4 : 6}>
                <MyTextField
                  name="name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label={`${t("configParams.paramName")}*`}
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regLettersNumbers(e.key) && e.preventDefault()
                  }
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={isSuper ? 4 : 6}>
                <MyTextField
                  name="code"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label={`${t("configParams.paramCode")}*`}
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regNumbers(e.key) && e.preventDefault()
                  }
                  autoComplete="off"
                />
              </Grid>

              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                mt={4}
              >
                {isSubmitting ? (
                  <CircularProgress className={"colorLoading"} />
                ) : (
                  <Button
                    style={{
                      border: "none",
                      outline: "none",
                      fontSize: 14,
                    }}
                    variant="contained"
                    type="submit"
                    className="btn-dialog primary-btn"
                    disabled={isSubmitting || isEmptyValues(values) || !isValid}
                    //onClick={handleCreatePaymentMethods}
                  >
                    {t("button.createParameters")}
                    <i className="fa-solid fa-check-circle ml-2"></i>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default FormCreateParam;
