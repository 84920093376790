import { LimitType } from "../enums/enums";

const BASE_API_URL = process.env.REACT_APP_API_HTTP_NOTIFICATIONS;

interface ILimitNotification {
  email: string;
  limitType: LimitType;
  time?: number;
}

const createRequestOptions = (
  method: string,
  token: string,
  bodyData?: any
) => {
  return {
    method,
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: bodyData ? JSON.stringify(bodyData) : undefined,
  };
};

export const checkHealtNotificationsBack = (token: string) => {
  const requestOptions = createRequestOptions("GET", token);
  return fetch(`${BASE_API_URL}health`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.warn(error));
};

export const getNotificationsService = (token: string, operatorId?: number) => {
  const operator = operatorId ? `&operatorId=${operatorId}` : "";
  const requestOptions = createRequestOptions("GET", token);

  return fetch(`${BASE_API_URL}notifications/month?${operator}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.warn(error));
};

export const updateNotificationsService = (
  id: number | string,
  data: any,
  token: string
) => {
  const requestOptions = createRequestOptions("PUT", token, data);

  return fetch(`${BASE_API_URL}notifications/${id}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.warn(error));
};

export const sendLimitNotification = (
  data: ILimitNotification,
  token: string
) => {
  const requestOptions = createRequestOptions("POST", token, data);

  return fetch(`${BASE_API_URL}notifications/limits`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.warn(error));
};

export const markNotificationAsReadService = (
  notificationId: number | string,
  token: string
) => {
  const data = { read: true };
  const requestOptions = createRequestOptions("POST", token, data);

  return fetch(
    `${BASE_API_URL}notifications/mark-as-read/${notificationId}`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => console.warn(error));
};

export const softDeleteNotificationService = (
  notificationId: number | string,
  token: string
) => {
  const data = { state: 0 }; // Soft delete by setting state to 0
  const requestOptions = createRequestOptions("POST", token, data);

  return fetch(
    `${BASE_API_URL}notifications/soft-delete/${notificationId}`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => console.warn(error));
};
