import React, { FC } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { IPointSaleForm } from "../../../../interfaces/pointsSale";
import { State } from "../../../../enums/enums";

interface Props {
  row: IPointSaleForm;
}

export const RowDataTablePointsOfSale: FC<Props> = ({ row }) => {
  const { t } = useTranslation();

  const newRow = { ...row };
  newRow.associates = newRow.associates.filter(
    (associate) => associate.associate
  );

  return (
    <>
      <TableCell className="word-break">{row.name}</TableCell>
      {!newRow.associates[0] ? (
        <TableCell className="word-break">{"No asociado"}</TableCell>
      ) : (
        <TableCell className="word-break">
          {newRow.associates[0].associate.name}
        </TableCell>
      )}
      {!row.dealersPointSale[0] ? (
        <TableCell className="word-break">{"N/A"}</TableCell>
      ) : (
        <TableCell className="word-break">
          {row.dealersPointSale[0].dealer.name}
        </TableCell>
      )}
      {!row.promotor ? (
        <TableCell className="word-break">{"N/A"}</TableCell>
      ) : (
        <TableCell className="word-break">{row.promotor?.name}</TableCell>
      )}
      <TableCell className="word-break">{newRow.address}</TableCell>
      <TableCell className="word-break">{newRow.phone}</TableCell>
      <TableCell className="word-break">
        {newRow.createdUser?.nameComplete}
      </TableCell>
      <TableCell align="center">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                State.ACTIVE === newRow.stateSalesPoint
                  ? "d-flex justify-content-center cellRowActive"
                  : "d-flex justify-content-center cellRowInactive"
              }
            >
              <div className="d-flex col-sm-4 justify-content-center">
                {State.ACTIVE === newRow.stateSalesPoint && (
                  <>{t("state.active")}</>
                )}
                {State.INACTIVE === newRow.stateSalesPoint && (
                  <>{t("state.inactive")}</>
                )}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
    </>
  );
};
