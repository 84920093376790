import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { TableCell } from "@mui/material";

import {
  IExclusion,
  IExclusionCategory,
  IPlayer,
} from "../../../interfaces/exclusions";
import { State } from "../../../enums/enums";
import { buildFullName } from "../../../utils/utils";

interface Props {
  row: IExclusion;
}

export const RowDataTableExclusionsAgileCheck: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  const stateLabel =
    row.state === State.ACTIVE
      ? t("state.active").toUpperCase()
      : t("state.inactive").toUpperCase();

  return (
    <>
      <TableCell className="word-break">
        {buildFullName(
          (row.player as IPlayer).firstName,
          (row.player as IPlayer).secondName,
          (row.player as IPlayer).lastName,
          (row.player as IPlayer).secondLastName
        )}
      </TableCell>
      <TableCell className="word-break">
        {moment(row.createAt).format("DD-MM-YYYY hh:mm:ss")}
      </TableCell>
      <TableCell className="word-break">
        {(row.category as IExclusionCategory).name}
      </TableCell>
      <TableCell className="word-break">{stateLabel}</TableCell>
    </>
  );
};
