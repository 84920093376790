import {
  IHelperAssociate,
  IHelperCleanFilter,
  IHelperConfig,
  IHelperFormModal,
  IHelperSelect,
  ILoadingState,
} from "./../interfaces/helper";
import { Types } from "../types/types";
import {
  ICities,
  ICountries,
  IStates,
  ICurrencies,
  ILanguageType,
  IPaymentMethods,
  IGames,
  IDocumentTypes,
  ISolutions,
} from "../interfaces/shared";
import { IHelper, IHelperEdit } from "../interfaces/helper";
import { IGenericData } from "../../interfaces/params";
import { IUserData } from "../interfaces/users";

export interface IHelperState {
  flagFormCreate: boolean | null;
  flagFormCreateAssociate: boolean | null;
  flagFormEdit: boolean | null;
  flagFormConfig: boolean | null;
  flagFormModal: boolean | null;
  loading: boolean | null;
  countries: ICountries[] | null;
  states: IStates[] | null;
  cities: ICities[] | null;
  currencies: ICurrencies[] | null;
  languages: ILanguageType[] | null;
  paymentMethods: any[] | null;
  paymentMethodsByCountry: IPaymentMethods[] | null;
  games: IGames[] | null;
  solutions: ISolutions[] | null;
  openAlert: boolean;
  messageAlert: string | null;
  typeAlert: "success" | "error" | "warning" | "info";
  activeStep: number;
  rowsPerPage: number;
  operators: any[];
  component: "associates" | null;
  documentTypes: IDocumentTypes[];
  banks: IGenericData[];
  sellers: IUserData[];
  flagSelect: boolean | null;
  flagCleanFilter: boolean | null;
  openTimeout: boolean;
  changeTab: boolean;
}

interface IHelperActions {
  type: Types;
  payload?:
    | IHelper
    | IHelperSelect
    | IHelperCleanFilter
    | IHelperAssociate
    | IHelperEdit
    | IHelperConfig
    | IHelperFormModal
    | ILoadingState
    | null
    | ICountries
    | ICountries[]
    | IStates
    | IStates[]
    | ICities
    | ICities[]
    | ICurrencies
    | ICurrencies[]
    | ILanguageType
    | ILanguageType[]
    | IPaymentMethods
    | IPaymentMethods[]
    | IGames
    | IGames[]
    | ISolutions
    | ISolutions[]
    | IDocumentTypes[]
    | IGenericData[]
    | IUserData[]
    | string
    | number
    | boolean;
}

const initialState: IHelperState = {
  flagFormCreate: false,
  flagFormCreateAssociate: false,
  flagFormEdit: false,
  flagFormConfig: false,
  flagFormModal: false,
  loading: false,
  countries: null,
  states: null,
  cities: null,
  currencies: null,
  languages: null,
  paymentMethods: null,
  paymentMethodsByCountry: null,
  games: null,
  solutions: null,
  openAlert: false,
  messageAlert: null,
  typeAlert: "success",
  activeStep: 0,
  rowsPerPage: 9,
  operators: [],
  component: null,
  documentTypes: [],
  banks: [],
  sellers: [],
  flagSelect: false,
  flagCleanFilter: false,
  openTimeout: false,
  changeTab: false,
};
export const helperReducer = (
  state = initialState,
  action: IHelperActions
): IHelperState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setFlagFormCreate:
      return {
        ...state,
        flagFormCreate: (payload as IHelper).flagFormCreate,
      };
    case Types.setFlagFormCreateAssociate:
      return {
        ...state,
        flagFormCreateAssociate: (payload as IHelperAssociate)
          .flagFormCreateAssociate,
      };
    case Types.setFlagFormEdit:
      return {
        ...state,
        flagFormEdit: (payload as IHelperEdit).flagFormEdit,
      };
    case Types.setFlagFormConfig:
      return {
        ...state,
        flagFormConfig: (payload as IHelperConfig).flagFormConfig,
      };
    case Types.setFlagFormModal:
      return {
        ...state,
        flagFormModal: (payload as IHelperFormModal).flagFormModal,
      };
    case Types.setLoadingState:
      return {
        ...state,
        loading: (payload as ILoadingState).loading,
      };
    case Types.countries:
      return {
        ...state,
        countries: [...(payload as ICountries[])],
      };
    case Types.states:
      return {
        ...state,
        states: [...(payload as IStates[])],
      };
    case Types.cities:
      return {
        ...state,
        cities: [...(payload as ICities[])],
      };

    case Types.openAlert:
      return {
        ...state,
        openAlert: true,
      };

    case Types.closeAlert:
      return {
        ...state,
        openAlert: false,
      };

    case Types.setMessageAlert:
      return {
        ...state,
        messageAlert: payload as string,
      };

    case Types.setTypeAlert:
      return {
        ...state,
        typeAlert: payload as any,
      };

    case Types.setActiveStep:
      return {
        ...state,
        activeStep: payload as number,
      };

    case Types.setRowsPerPage:
      return {
        ...state,
        rowsPerPage: payload as number,
      };

    case Types.setCurrencies:
      return {
        ...state,
        currencies: [...(payload as ICurrencies[])],
      };

    case Types.setLanguages:
      return {
        ...state,
        languages: [...(payload as ILanguageType[])],
      };

    case Types.setPaymentMethods:
      return {
        ...state,
        paymentMethods: [...(payload as IPaymentMethods[])],
      };

    case Types.setPaymentMethodsByCountry:
      return {
        ...state,
        paymentMethodsByCountry: [...(payload as IPaymentMethods[])],
      };

    case Types.setGames:
      return {
        ...state,
        games: [...(payload as IGames[])],
      };

    case Types.setSolutions:
      return {
        ...state,
        solutions: [...(payload as ISolutions[])],
      };

    case Types.setOperators:
      return {
        ...state,
        operators: [...(payload as any[])],
      };

    case Types.setComponent:
      return {
        ...state,
        component: payload as any,
      };

    case Types.setDocumentTypes:
      return {
        ...state,
        documentTypes: payload as IDocumentTypes[],
      };

    case Types.setBanks:
      return {
        ...state,
        banks: payload as IGenericData[],
      };
    case Types.setSellers:
      return {
        ...state,
        sellers: payload as IUserData[],
      };

    case Types.setFlagSelect:
      return {
        ...state,
        flagSelect: (payload as IHelperSelect).flagSelect,
      };

    case Types.setFlagCleanFilter:
      return {
        ...state,
        flagCleanFilter: (payload as IHelperCleanFilter).flagCleanFilter,
      };

    case Types.setOpenTimeout:
      return {
        ...state,
        openTimeout: payload as boolean,
      };

    case Types.setChangeTab:
      return {
        ...state,
        changeTab: payload as boolean,
      };
    default:
      return state;
  }
};
