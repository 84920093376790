import { FC, Fragment } from "react";
import PeriodicityForm from "./PeriodicityForm";
import CommisionForm from "./CommisionForm";
import AffiliationCommissionFTDForm from "./affiliationCommissionFTDForm";

interface ValuesManagementFormProps {
  value?:
    | "affiliationPercentage"
    | "affiliationPeriodicity"
    | "affiliationCommissionFTD"
    | "";
  setShowForm: any;
  setValue: any;
}

const ValuesManagementForm: FC<ValuesManagementFormProps> = ({
  value,
  setShowForm,
  setValue,
}) => {
  let content;

  switch (value) {
    case "affiliationPeriodicity":
      content = (
        <PeriodicityForm setShowForm={setShowForm} setValue={setValue} />
      );
      break;
    case "affiliationPercentage":
      content = <CommisionForm setShowForm={setShowForm} setValue={setValue} />;
      break;
    case "affiliationCommissionFTD":
      content = (
        <AffiliationCommissionFTDForm
          setShowForm={setShowForm}
          setValue={setValue}
        />
      );
      break;
    default:
      content = (
        <PeriodicityForm setShowForm={setShowForm} setValue={setValue} />
      );
      break;
  }

  return <Fragment>{content}</Fragment>;
};

export default ValuesManagementForm;
