/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikHelpers } from "formik";
import { useRef, useState, KeyboardEvent, ChangeEvent } from "react";
import * as yup from "yup";
import { MyTextField } from "../../custom/MyTextField";
import { regLettersNumbers } from "../../../../utils/utils";
import moment from "moment";
import clearLogo from "../../../../assets/svg/icon-park_clear-format.svg";
import { FreeTicketsType } from "../../../../enums/enums";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { excelToJson } from "../../../../helpers/convertFileToJson";
import {
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import { MyDialog } from "../../custom/MyDialog";
import {
  IDataFreeTicketsBonus,
  ISettingsFreeTickets,
} from "../../../../interfaces/bonus";
import { startCreateFreeTicketsBonus } from "../../../../redux/actions/bonusActions";

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  inputAdorned: {
    marginRight: "30px",
  },
  infoIcon: {
    color: "#02D0E7",
    marginRight: theme.spacing(1.125),
    fontSize: 17,
  },
  title: {
    fontWeight: "bold",
    color: "black",
  },
  titleBox: {
    display: "flex",
    borderBottom: `1px solid #02D0E7`,
    paddingBottom: theme.spacing(0.25),
  },
  formControl: {
    width: "100%",
  },
  errorLabel: {
    marginLeft: theme.spacing(0.75),
    color: "#d32f2f",
  },
}));
const styles = {
  "& input.Mui-disabled ": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
  "& .MuiSelect-select.Mui-disabled": {
    background: "#F5F5F5",
    border: "1px solid #CED4DA",
    borderRadius: "6px",
  },
};

const Container = styled("div")({
  display: "flex",
});

const CustomButton = styled(Button)({
  backgroundColor: "#007E8C",
  color: "white",
  height: "100%",
  width: "150px",
  "&:hover": {
    backgroundColor: "#007E8C",
    opacity: 1,
  },
});
export const FormCreateFreeTicketsBonus = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState<any>(null);
  const { token } = useSelector((state: AppState) => state.auth);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const formikRef = useRef<any>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const initialValues = {
    given_amount: "",
    usable_until: "",
    type: "",
    description: "",
  };

  const validationSchema = yup.object({
    given_amount: yup.number().required(t("form.requiredFile")),
    usable_until: yup.date().required(t("form.requiredFile")),
    type: yup.number().required(t("form.requiredFile")),
  });

  const handleShowConfirmationWindow = (message: string) => {
    setOpenConfirmation(true);
    setMessage(message);
  };

  const freeTicketsType = [
    {
      id: FreeTicketsType.FREE_TICKETS,
      name: "100 Free Tickets",
    },
    {
      id: FreeTicketsType.SUBVENTIONED_FREE_TICKETS,
      name: "Subventioned Free Tickets",
    },
  ];

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files && event.target.files[0];
    setSelectedFile(file ?? null);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      (fileInputRef.current as any).click();
    }
  };

  const handleDownload = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();

    const fileName = "PlantillaBonusFreeTickets.csv";

    const publicURL = process.env.PUBLIC_URL;
    const url = `${publicURL}/${fileName}`;

    window.location.href = url;
  };

  const isEmptyValues = (formValues: any) => {
    let emptyValues = false;

    Object.entries(formValues).forEach(([key, value]) => {
      if (value === "") {
        emptyValues = true;
      }
    });

    return emptyValues;
  };

  const handleCleanForm = (resetForm: any) => {
    resetForm();
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const callback = (totalParse: number, totalLines: number) => {
    // Callback function to track progress
    console.log(`Progreso: ${totalParse}/${totalLines}`);
  };

  const handleSave = async (
    formValues: ISettingsFreeTickets,
    resetForm: FormikHelpers<any>["resetForm"]
  ) => {
    if (!selectedFile || !token) return;
    setSubmitting(true);
    try {
      const json = await excelToJson(selectedFile, callback);
      const dataPlayers = JSON.parse(json);
      const combinedData: IDataFreeTicketsBonus = {
        dataPlayers,
        settings: formValues,
      };
      await dispatch(startCreateFreeTicketsBonus(combinedData));
    } catch (error) {
      dispatch(setMessageAlert("transactions.errorCreateMassiveSetting"));
      dispatch(setTypeAlert("warning"));
      dispatch(setOpenAlert());
    } finally {
      setSubmitting(false);
      setOpenConfirmation(false);
      setSelectedFile(null);
      resetForm();
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleCancel = () => {
    dispatch(setFlagForm({ flagFormCreate: false }));
    setSelectedFile(null);
    setOpenConfirmation(false);
  };

  const classes = useStyles();

  return (
    <Grid container spacing={2} mt={1} pl={1} pr={1}>
      <Grid item xs={12} className="mb-4">
        <Box className={classes.titleBox}>
          <i className={`fa-solid fa-circle-info ${classes.infoIcon}`}></i>
          <Typography variant="body1" className={classes.title}>
            {t("managementBonds.createFreeTicketsBonus")}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          onSubmit={async (data) => {
            setFormValues(data);
            handleShowConfirmationWindow(
              t("freeTicketsBonus.sureCreatedBonus")
            );
          }}
        >
          {({ handleChange, values, isValid, resetForm }) => (
            <Form>
              <Grid container spacing={2}>
                {/* given_amount */}
                <Grid item xs={4}>
                  <MyTextField
                    name="given_amount"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                    label={`${t("freeTicketsBonus.givenAmount")}*`}
                    inputProps={{
                      "data-testid": "given_amount",
                      maxLength: 50,
                    }}
                    onChange={handleChange}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regLettersNumbers(e.key) && e.preventDefault()
                    }
                  />
                </Grid>
                {/* usable_until */}
                <Grid item xs={4}>
                  <MyTextField
                    variant="outlined"
                    size="small"
                    type={"date"}
                    name={"usable_until"}
                    label={`${t("freeTicketsBonus.usableUntil")}*`}
                    InputProps={{
                      classes: {
                        input: classes.root,
                        adornedEnd: classes.inputAdorned,
                      },
                      inputProps: {
                        min: moment().add(1, "day").format("YYYY-MM-DD"),
                      },
                    }}
                    fullWidth
                    inputProps={{
                      "data-testid": "usable_until",
                    }}
                    onChange={handleChange}
                    sx={styles}
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {/* Type */}
                <Grid item xs={4}>
                  <MyTextField
                    name="type"
                    variant="outlined"
                    size="small"
                    fullWidth
                    select
                    label={`${t("managementBonds.bondType")}*`}
                    onChange={handleChange}
                    value={values.type || ""}
                  >
                    {freeTicketsType.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        sx={{
                          "&.Mui-focused": {
                            backgroundColor: "red",
                          },
                        }}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </MyTextField>
                </Grid>
                {/* UPLOAD FILE */}
                <Grid item xs={6}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Container>
                      <TextField
                        label={`${t("managementBonds.uploadFilePlayers")}*`}
                        disabled
                        size="small"
                        fullWidth
                        value={selectedFile ? selectedFile.name : ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <input
                        type="file"
                        accept=".csv"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                      <label style={{ margin: 0 }}>
                        <CustomButton
                          style={{
                            border: "none",
                            outline: "none",
                            boxShadow: "none",
                          }}
                          onClick={handleButtonClick}
                        >
                          {t("managementBonds.examine")}
                        </CustomButton>
                      </label>
                    </Container>
                    <Typography>
                      <a href="#" onClick={handleDownload}>
                        {t("managementBonds.dowloadTemplate")}
                      </a>
                    </Typography>
                  </div>
                </Grid>
                {/* description */}
                <Grid item xs={12}>
                  <MyTextField
                    name="description"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="text"
                    label={`${t("form.description")}`}
                    multiline
                    rows={6}
                    onChange={handleChange}
                    inputProps={{
                      maxLength: 500,
                    }}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      !regLettersNumbers(e.key) && e.preventDefault()
                    }
                  />
                </Grid>
                {/* BUTTONS */}
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  sx={{ height: "60px" }}
                  mt={3}
                  mb={3}
                >
                  <Button
                    variant="contained"
                    className="footer-btn danger-btn ripple"
                    style={{ border: "none", outline: "none" }}
                    onClick={() =>
                      handleShowConfirmationWindow(t("dialog.sureCancel"))
                    }
                    disabled={!!isSubmitting}
                    data-testid={"btnCancel"}
                  >
                    {t("button.cancel").toUpperCase()}
                  </Button>
                  <Button
                    type="submit"
                    style={{ border: "none", outline: "none" }}
                    variant="contained"
                    id="btnSave"
                    disabled={
                      isSubmitting ||
                      isEmptyValues(values) ||
                      !isValid ||
                      !selectedFile
                    }
                    className="footer-btn primary-btn ripple"
                  >
                    {isSubmitting ? (
                      <CircularProgress size={"1.6rem"} color="inherit" />
                    ) : (
                      <>
                        <i className="fa-solid fa-check"></i>
                        {t("button.save")}
                      </>
                    )}
                  </Button>
                  {!isSubmitting && (
                    <Button
                      type="button"
                      style={{
                        border: "none",
                        outline: "none",
                        marginLeft: "4px",
                      }}
                      onClick={() => handleCleanForm(resetForm)}
                    >
                      <img src={clearLogo} alt="Icon clear" />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <MyDialog
          open={openConfirmation}
          title={t("dialog.confirmationWindow")}
          message={message}
          handleAccept={() =>
            message === t("dialog.sureCancel")
              ? handleCancel()
              : handleSave(formValues, formikRef.current?.resetForm)
          }
          handleClose={() => setOpenConfirmation(false)}
          isLoading={isSubmitting}
        />
      </Grid>
    </Grid>
  );
};
