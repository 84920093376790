export enum State {
  ACTIVE = 1,
  INACTIVE = 2,
  RETAINED = 3,
  RETIRED = 3,
  PENDING_VALIDATION = 4,
  RESTRICTED = 4,
  CLOSED = 5,
  SELF_EXCLUDED = 6,
}

export enum Status {
  BLOCKED = 1,
  CLOSED = 2,
}
export enum Enum_PaymentMethods {
  Refills = "refills",
  WithDrawal = "withdrawal",
}
export enum PaymentMethodsBonus {
  ALL = 1,
  PHYSICAL = 2,
  ONLINE = 3,
}
export enum ProvidersBonus {
  ALTENAR = 1,
  PRAGMATIC = 3,
  NEAT = 4,
  PARIPLAY = 5,
  INSWITCH = 77,
}

export enum Roles_Enum {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN_OPERATOR = "ADMIN_OPERATOR",
  USER = "USER",
  ADMIN_ASSOCIATE = "ADMINISTRATIVE",
  POINT_SALE = "POINT_SALE",
  ASSOCIATE = "ASSOCIATE",
  DEALER = "DEALER",
  JER = "JER",
  SELLER = "SELLER",
  BETPPRO_TREASURY = "ROL TESORERIA",
  CRM = "CRM",
  CONTACT_CENTER = "LIDER CONTACT CENTER",
  PROMOTOR = "PROMOTOR",
  CONCILIATION = "CONCILIACION",
  UPDATE_DATA = "UPDATE DATA",
}
export enum Roles_Ids_Enum {
  SUPER_ADMIN = 1,
  ADMIN_OPERATOR = 2,
}

export enum State_Bets {
  OPEN = "Open",
  LOST = "Lost",
  WON = "Won",
}

export enum State_Bet {
  PAID = "Pagada",
  PENDING = "Pendiente por pagar",
}

export enum StateTransaction {
  Approved = 1,
  Pending = 2,
  Refused = 3,
  Expired = 4,
  REVERSE = 5,
}

export enum BalanceType {
  REFILL = 1,
  BETTING = 2,
}

export enum TransferConcept {
  CASH = 1,
  BANK_TRANSFER = 2,
}

export enum WithdrawalType {
  CASH = "efectivo",
  BANK_TRANSFER = "bancario",
}

export enum ExclusionType {
  CATEGORY = 1,
  AGILE_CHECK = 2,
  DEPOSIT = 3,
}

export enum ActivityType {
  BETS = 1,
  CASINO = 2,
  WITHDRAWALS = 3,
  BALANCE = 4,
  HORSERIDING = 5,
  BET_PAYMENT = 6,
  REFILL = 7,
}
export enum GameType {
  ROULETTE = "ruleta",
}
export enum GameResult {
  WIN = 1,
  LOST = 2,
  NULL = 3,
}

export enum GameResultAviatrixCasino {
  WIN = "won",
  LOST = "lost",
  CANCELED = "canceled",
  DONE = "done",
}

export enum BetType {
  SIMPLE = 1,
  MULTIPLE = 2,
  SYSTEM = 3,
}

export enum Enum_Suitable {
  TRUE = "true",
  FALSE = "false",
  PENDING = "pending",
}

export enum MovementType {
  Input = 1,
  Output = 2,
}

export enum TransactionType {
  Bets = 1,
  PaymentOrders = 2,
  Awards = 3,
  Recharge = 4,
}
export enum TransactionTypesDB {
  BUY_BET = 1,
  BET_PRIZE = 2,
  RECHARGE_PUNTO_PAGO = 6,
  RECHARGE = 12,
  BET_PAYMENT = 13,
  WITHDRAWAL_CASH = 15,
  WITHDRAWAL_BANK = 16,
  REFOUND_WITHDRAWAL_REJECT = 17,
  RECHARGE_PAYCASH = 19,
  TRANSACTIONAL_SETTING_INPUT = 18,
  BET_CANCELLATION = 4,
  PAYMENT_AWARD = 83,
}

export enum Wallet {
  Credits = 1,
  Awards = 2,
}

export enum DocumentTypes {
  CITIZENSHIP_CARD = 1,
  PASSPORT = 2,
  DNI = 3,
  DUI = 4,
  CIP = 5,
  CE = 6,
  DPI = 7,
}

export enum NotificationsTypes {
  SUPER_ADMIN = 1,
  PORTFOLIO = 2,
  NONE = 3,
}
export enum AltenarBonusCampain {
  Unknown = 0,
  CodedBonus = 1,
  DepositBonus = 2,
  FirstDepositBonus = 3,
  FreeBetBonus = 4,
  CashbackBonus = 5,
  RiskFreeBet = 6,
  Pragmatic = 7,
}

export enum BonusRedeemedStatus {
  INIT = 1,
  ACTIVE = 2,
  EXPIRED = 3,
  CANCELLED = 4,
  CLOSED = 5,
  WON = 6,
}

export enum ExclusionCategory {
  ALL = 1,
  SPORTS_BETTING = 2,
  CASINO = 3,
  VIRTUAL = 4,
  EQUESTIAN = 5,
  EQU_LEGAL_AGE = 6,
  INTERPOL_CIRCULAR = 7,
  POLICE_RECORD = 8,
  OTHER = 9,
  SELF_EXCLUSION = 10,
  LOGOUT = 11,
}
export enum LimitType {
  BETS = 1,
  SESSION = 2,
}

export enum SessionLogoutReason {
  USER_LOGOUT = "userLogout",
  EXCLUSION_LOGOUT = "exclusionLogout",
  CHANGE_LOGIN_CREDENTIALS = "changeCredentials",
  PLATFORM_LOGOUT = "platformLogout",
  TIMEOUT_EXCEEDED = "timeoutExceeded",
}
export enum StateLotCards {
  ACTIVE = 1,
  INACTIVE = 2,
  DELIVERED_MANUFACTURER = 3,
  DELIVERED_DEALER = 4,
  PENDING = 5,
}

export enum BetMode {
  VIRTUAL_SPORTS = "VSPORT",
  SPORTS_BETTING = "SPORTS_BETTING",
}

export enum StateCards {
  ACTIVE = 1,
  INACTIVE = 2,
  REDEEMED = 3,
  SOLD = 4,
}

export enum WhiteListOperators {
  STARPLAY = "STARPLAY",
  BETPPRO = "BETPPRO",
}
export enum CountryCode {
  COLOMBIA = "CO",
  PANAMA = "PA",
  EL_SALVADOR = "SV",
  HONDURAS = "HN",
  GUATEMALA = "GT",
  ECUADOR = "EC",
}

export enum CasinoBonusType {
  REGISTRATION = 1,
  DEPOSIT = 2,
  FIRTS_DEPOSIT = 3,
  SECOND_DEPOSIT = 4,
  THRID_DEPOSIT = 5,
  CUSTOM = 6,
}
export enum DefaultOperators {
  NEAT = 3,
}
export enum ProvidersString {
  PRAGMATIC = "Pragmatic",
  PARIPLAY = "Pariplay",
  ALTENAR = "Altenar",
}
export enum TransactionTypes {
  BUY_BET = 1,
  BET_PRIZE = 2,
  RECHARGE_PUNTO_PAGO = 6,
  RECHARGE = 12,
  BET_PAYMENT = 13,
  WITHDRAWAL_CASH = 15,
  WITHDRAWAL_BANK = 16,
  REFOUND_WITHDRAWAL_REJECT = 17,
  RECHARGE_PAYCASH = 19,
  TRANSACTIONAL_SETTING_INPUT = 18,
  BET_CANCELLATION = 4,
}

export enum ProvidersCasino {
  PRAGMATICPLAY = "Pragmatic Play",
  PARIPLAY = "Pariplay",
}

export enum CategoriesCasinoGames {
  SLOTS = "Slots",
  TABLE_GAMES = "Table games",
  NEWS = "News",
  LIVE_GAMES = "Live games",
}
export const whiteListExport = [
  Roles_Enum.SUPER_ADMIN,
  Roles_Enum.ADMIN_OPERATOR,
  Roles_Enum.POINT_SALE,
  Roles_Enum.SELLER,
  Roles_Enum.CRM,
  Roles_Enum.DEALER,
  Roles_Enum.ASSOCIATE,
  Roles_Enum.PROMOTOR,
];

export enum Module {
  DASHBOARD = "dashboard",
  CASINO = "casino",
  PROMOTIONS = "promotions",
  CASINO_LIVE = "casino_live",
  BETTINGSHORTCUTSBACKGROUND = "bettingShortcutsBackground",
  BETTINGSHORTCUTS = "bettingShortcuts",
  PAGEBACKGROUND = "pageBackground",
  PAYMENTMETHODS = "paymentMethods",
  FOOTERITEMS = "footerItems",
  SIGNUP = "signUp",
  BINGO = "bingo",
  VIRTUALSGOLDENRACE = "virtualsGoldenRace",
  MOBILE = "mobile",
}

export enum TabsReportsEnum {
  PLAYER_SESSION = 1,
  EXCLUSION = 2,
  ACCOUNT_BALANCE = 3,
  PROMOTIONAL_ACCOUNT_SUMMARY = 4,
  NOTIFICATION_CHANGE = 5,
  GAME_REVENUE = 6,
  PROGRESIVE_JACKPOT_WON = 7,
  CASH_FLOW = 8,
  CASH_FLOW_DEPOSITS = 9,
  COMMISSIONS = 10,
  ASSOCIATE_BETS = 11,
  ASSOCIATE_AWARDS = 12,
  CARD_IRRIGATION_POINT_SALE = 13,
  IRRIGATION_RESUME = 14,
  AFFILIATIONS = 16,
}

export enum CurrentPlaceCards {
  ADMIN = "AD",
  ASSOCIATE = "AS",
  DEALER = "DEL",
  PROTOMOR = "PR",
  POINT_SALE = "PS",
}
export enum RolMultilevel {
  DEALER = "Distribuidores",
  ASSOCIATE = "Asociados",
  POINTSALE = "Point sale",
}
export enum GameResultGoldenRace {
  PENDING = "pending",
  WON = "won",
  LOST = "lost",
  CANCELED = "canceled",
}

export enum GameResultBingo {
  WIN = 1,
  LOST = 2,
}

export enum EnumWhiteListUserCard {
  CORPMULTIRED = "corpmultired",
  ADMIN_STARPLAY = "Admin_starplay",
  ADMIN_DEV = "AdminBetPro",
}
export const WhiteListUserCard = [
  EnumWhiteListUserCard.ADMIN_STARPLAY,
  EnumWhiteListUserCard.CORPMULTIRED,
  EnumWhiteListUserCard.ADMIN_DEV,
];
export enum DealerTypeEnum {
  CARDS = 1,
  ANONYMOUS = 2,
  BOTH = 3,
}

export const PaymentMethods = {
  91: "Loopay Online",
  90: "Loopay Fisico",
  81: "Aliados",
  80: "Payment Box",
  79: "Inswitch",
  78: "Punto Pago",
  77: "Inswitch",
  50: "TuCompra",
  45: "N1co",
  44: "PayPhone",
  12: "AstroPay",
  11: "Paycash",
  10: "TarjetaPlay",
  9: "Paguelo Facil",
  8: "Pagadito",
  6: "Juguemos",
  5: "PSE",
  4: "Transferencia",
  3: "Efectivo",
  2: "Paypal",
  1: "Credit card",
};

export const DetailTransactionalSettings = {
  1: "adjustmentToInternalTestAccounts",
  2: "adjustThirdPartyTests",
  3: "casino",
  4: "sports",
  5: "N1co",
  6: "Paguelo Facil",
  7: "Paycash",
  8: "PayPhone",
  9: "Punto Pago",
  10: "casinoRoom",
  11: "slots",
  12: "rechargeCard",
  13: "N/A",
};

export const ResponsibleSetting = {
  1: "onlineManagement",
  2: "onlineGuideline",
  3: "risks",
  4: "productOperations",
  5: "customerService",
};

export const MovementTypeComissionReport = {
  GGR: 3,
  Affiliations: 5,
};

export enum GameResultEvolution {
  PENDING = "pending",
  WON = "won",
  LOST = "lost",
  CANCELED = "canceled",
}

export enum Operators {
  BETPPROSV = 58,
  STARPLAYPA = 2,
  ASTROBETEC = 4,
  BETPPROHN = 3,
}

export enum TransferType {
  RECHARGE = "recharge",
  WITHDRAWAL = "withdrawal",
}

export enum FreeTicketsType {
  FREE_TICKETS = 1,
  SUBVENTIONED_FREE_TICKETS = 2,
}

export enum EnumFreeTicketsState {
  ACTIVE = 1,
  INACTIVE = 2,
  CLOSED = 3,
}

export enum EnumUserTypesBonus {
  NEW = 1,
  OLD = 2,
  ALL = 3,
}
