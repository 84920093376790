import { IIrrigationsParams, IrrigationForm } from "../interfaces/irrigations";

export const getIrrigations = (
  token: string,
  operatorId: number | null,
  params?: Partial<IIrrigationsParams>
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };

  const operatorParam = operatorId ? `operatorId=${operatorId}` : "";
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}irrigations?${operatorParam}&${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const generateTransfer = (token: string, transfer: IrrigationForm) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(transfer),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}irrigations`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
export const generateIrrigationAssociatePointSale = (
  token: string,
  transfer: any
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(transfer),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}irrigations/associate-point-sale`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
export const generateIrrigationDealerPointSale = (
  token: string,
  transfer: any
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(transfer),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}irrigations/dealer-point-sale`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
export const getIrrigationsPV = (token: string, params?: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}irrigations/associate-point-sale?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
export const generateIrrigationAssociateDealer = (
  token: string,
  transfer: any
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(transfer),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}irrigations/associate-dealer`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const getIrrigationsDealer = (token: string, params?: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      api: `${process.env.REACT_APP_API_KEY}`,
      Authorization: `bearer ${token}`,
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_SECURITY}irrigations/dealer?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};
