import React, { useEffect, useState } from "react";
import { IrrigationAdminInfoCards } from "../../../components/transactions/irrigation/admin/IrrigationAdminInfoCards";
import { useDispatch, useSelector } from "react-redux";
import { setActiveAssociate } from "../../../../redux/actions/associatesActions";
import { arraySearch } from "../../../../redux/actions/searchActions";
import { Box, Grid } from "@mui/material";
import { GenerateTransferAdmin } from "../../../components/transactions/irrigation/admin/GenerateTransferAdmin";
import { IrrigationAdminHistory } from "../../../components/transactions/irrigation/admin/IrirgationAdminHistory";
import { PointSaleInfoCardsDealer } from "../../../components/transactions/irrigation/cardsDealer/pointSalesInfoCardsDealer";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { CurrentPlaceCards, Roles_Enum } from "../../../../enums/enums";

export const IrrigationCardsDealer = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: AppState) => state.auth);

  const [expand, setExpand] = useState<boolean>(false);
  const [resetInfo, setResetInfo] = useState(false);
  const [resetGenerate, setResetGenerate] = useState(false);
  const isDirectIrrigation = user?.roles.name === Roles_Enum.ASSOCIATE;

  useEffect(() => {
    return () => {
      dispatch(setActiveAssociate(null));
      dispatch(arraySearch(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="content w-100 mb-4">
      <Box sx={{ ml: 3, mt: 2 }} width={1}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <IrrigationAdminInfoCards />
          </Grid>
          <Grid item xs={12} md={6}>
            <PointSaleInfoCardsDealer expand={expand} setExpand={setExpand} />
          </Grid>
          <Grid item xs={12} md={6}>
            <GenerateTransferAdmin
              expand={expand}
              setExpand={setExpand}
              setResetInfo={setResetInfo}
              resetInfo={resetInfo}
              setResetGenerate={setResetGenerate}
              resetGenerate={resetGenerate}
              to={isDirectIrrigation ? CurrentPlaceCards.POINT_SALE : undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <IrrigationAdminHistory to={CurrentPlaceCards.POINT_SALE} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
