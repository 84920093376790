import React, { FC } from "react";
import { TableCell } from "@mui/material";
import moment from "moment";
import { getMovementType } from "../../../../utils/utils";
interface props {
  row: any;
}
export const RowCashFlow: FC<props> = ({ row }) => {
  const dateTransaction = row.transactionId?.approvalDate ?? row.createAt;
  return (
    <>
      <TableCell className="word-break" align="center">
        {row.id}
      </TableCell>
      <TableCell className="word-break" align="center">
        {row.pointSale.name}
      </TableCell>
      <TableCell className="word-break" align="center">
        {getMovementType(row.transactionType.id)}
      </TableCell>
      <TableCell className="word-break" align="center">
        {row.player.id}
      </TableCell>

      <TableCell className="word-break" align="center">
        {"$ " + row.amount}
      </TableCell>
      <TableCell className="word-break" align="center">
        {`$ ${row?.meta?.valueWithTax ?? 0}`}
      </TableCell>
      <TableCell className="word-break" align="center">
        {row.transactionType.name}
      </TableCell>
      <TableCell className="word-break" align="center">
        {moment(dateTransaction).format("DD/MM/YYYY HH:mm:ss")}
      </TableCell>
    </>
  );
};
