import React, { FC } from "react";
import TableCell from "@mui/material/TableCell";
import { TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IMenuItem } from "../../../interfaces/menu";
import { State } from "../../../enums/enums";
interface Props {
  row: IMenuItem;
}

export const RowDataTableModules: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  return (
    <TableRow key={row.id}>
      <TableCell className="word-break">{row.name}</TableCell>
      <TableCell className="word-break">{row.url}</TableCell>
      <TableCell className="word-break">{row.description}</TableCell>
      <TableCell className="word-break">
        {row.createdUser?.nameComplete}
      </TableCell>
      <TableCell>
        {State.ACTIVE === row.state && <>{t("state.active").toUpperCase()}</>}
        {State.INACTIVE === row.state && (
          <>{t("state.inactive").toUpperCase()}</>
        )}
      </TableCell>
    </TableRow>
  );
};
