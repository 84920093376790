import { useTranslation } from "react-i18next";

import { Box, Grid } from "@mui/material";

import { CurrencySelect } from "../../../../components/Custom/CurrencySelect";
import { ICountryStep } from "../../../../interfaces/operationCompany";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";

interface Props {
  countriesSelected: ICountryStep[];
  setCurrencySelected: any;
}

export const FormConfigCurrencyCompany = ({
  countriesSelected,
  setCurrencySelected,
}: Props) => {
  const { t } = useTranslation();
  const { currenciesOperator } = useSelector(
    (state: AppState) => state.currencies
  );

  return (
    <>
      {/* CONFIGURACIÓN MONEDA */}
      <Grid item xs={12} className="mb-2">
        <Box
          display="flex"
          className="pb-1"
          borderBottom={1}
          borderColor="#02D0E7"
        >
          <i
            className="fa-solid fa-circle-info"
            style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
          ></i>
          <p className="txt-primary-md" style={{ fontWeight: 500 }}>
            {t("companyManagement.configureCurrency")}
          </p>
        </Box>
      </Grid>

      {currenciesOperator.length > 0 ? (
        <>
          {countriesSelected.map((country, index) => (
            <Box
              width={1}
              ml={2}
              mt={2}
              mb={1}
              borderBottom={1}
              borderColor="#9E9E9E"
              key={country.id}
            >
              <Grid container mb={2}>
                <Grid item xs={5} display="flex" alignItems="flex-end">
                  <img
                    loading="lazy"
                    style={{
                      height: "50px",
                      width: "50px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                    alt="Country"
                    src={country.imgUrl}
                  />
                  <label className="label-text">
                    {(country.name as string).toUpperCase() as string}
                  </label>
                </Grid>

                <Grid item xs={7}>
                  <label className="label-text">
                    {t("companyManagement.selectCountryAndCurrency")}*
                  </label>
                  {country.countriesCurrencies && (
                    <CurrencySelect
                      indexCountry={index}
                      currencySelected={country.countriesCurrencies}
                      setCurrencySelected={setCurrencySelected}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
        </>
      ) : (
        <>
          <>
            <label className="label-text mt-2">
              {t("parameterization.noOperatorCurrencies")}
            </label>
          </>
        </>
      )}
    </>
  );
};
