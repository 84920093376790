import React, { FC } from "react";
import { headersManagementBonus } from "../../../helpers/headersTables";
import { Text, View } from "@react-pdf/renderer";
import { stylesPdf } from "../../../views/components/custom/customStyles";
import moment from "moment";
import { ProvidersBonus, State } from "../../../enums/enums";
import { useTranslation } from "react-i18next";
interface props {
  page: any[];
}
export const ManagementBonusPdf: FC<props> = ({ page }) => {
  const columns = headersManagementBonus;
  const { t } = useTranslation();
  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, i) => (
          <View key={i} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>
      <View style={{ flex: 1 }}>
        {page.map((data: any, i) => (
          <View key={i} style={stylesPdf.row}>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data.name}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data?.provider.name}</Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {data?.provider.id === ProvidersBonus.PRAGMATIC
                  ? data?.pragmaticBonusCode
                  : data?.code}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {data?.state === State.ACTIVE
                  ? t("state.active").toUpperCase()
                  : t("state.inactive").toUpperCase()}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {moment(data?.startDate).format("YYYY-MM-DD hh:mm:ss A")}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {moment(data?.endDate).format("YYYY-MM-DD hh:mm:ss A")}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>
                {data?.provider.id === ProvidersBonus.ALTENAR
                  ? data.value
                    ? `$ ${data?.value}`
                    : ""
                  : data?.totalValue
                  ? `$ ${data.totalValue}`
                  : ""}
              </Text>
            </View>
            <View style={[stylesPdf.col, stylesPdf.pCol]}>
              <Text style={stylesPdf.textContent}>{data?.amountBonus}</Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};
