import React, { useState } from "react";
import Filter from "../../../../components/Filter/Filter";
import {
  Enum_FieldType,
  IFilterField,
  TypeReportChanges,
} from "../../../../interfaces/components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Grid,
  Stack,
} from "@mui/material";
import MyCustomTable, {
  IColumns,
} from "../../../../components/Custom/Table/MyCustomTable";
import {
  asyncLoadingFunction,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
  startGetLimitsAudit,
} from "../../../../redux/actions/helperActions";
import { RowNotificationChanges } from "./RowNotificationChanges";
import { startGetExclusionsAudit } from "../../../../redux/actions/exclusionsAuditActions";
import { NotificationChangesDataTable } from "../../../../hooks/notificationChanges/notificationChangesDataTable";
import { IAuditQuery, getLimitAudit } from "../../../../services/audit";
import { getExclusionsAudit } from "../../../../services/exclusionsAudit";
import moment from "moment";
import { NotificationChangesPdf } from "../../../../components/Pdf/notificationChanges.tsx/NotificationChangesPdf";
import InfoIcon from "@mui/icons-material/Info";
import { arraySearch } from "../../../../redux/actions/searchActions";
import { IExclusionAuditQuery } from "../../../../interfaces/exclusionsAudit";

export const NotificationChanges = () => {
  const [dataFilter, setDataFilter] = useState<any>({});
  const [filter, setFilter] = useState(false);
  const { data } = useSelector((state: AppState) => state.search);
  const { token, user } = useSelector((state: AppState) => state.auth);
  const { rowsPerPage, loading } = useSelector(
    (state: AppState) => state.helper
  );
  const [typeReport, setTypeReport] = useState<any>(TypeReportChanges.LIMITS);

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const filterFields: IFilterField[] = [
    {
      label: t("playerLimit.typeReport"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("playerLimit.limits"),
          value: TypeReportChanges.LIMITS,
        },
        {
          label: t("playerLimit.exclusions"),
          value: TypeReportChanges.EXCLUSIONS,
        },
      ],
    },
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("table.playerId"),
      type: Enum_FieldType.TEXT,
    },
  ];
  const columns: IColumns[] = [
    {
      align: "left",
      label: t("bets.event"),
    },
    {
      align: "left",
      label: t("table.createdBy"),
    },
    {
      align: "left",
      label: t("playerLimit.playerAffected"),
    },
    {
      align: "left",
      label: t("user.createAt"),
    },
  ];
  const handleFilter = async (formValues: any) => {
    const startDate = formValues[t("filters.startDate")];
    const endDate = formValues[t("filters.endDate")];
    const typeReport = formValues[t("playerLimit.typeReport")];
    const playerAffected = formValues[t("table.playerId")];
    setTypeReport(typeReport);
    const params: IAuditQuery | IExclusionAuditQuery = {
      startDate,
      endDate,
      page: 1,
    };
    if (playerAffected.toString().trim()) {
      params.playerAffected = playerAffected;
    }
    if (!typeReport.toString().trim()) {
      dispatch(setMessageAlert("changeNotificationReport.selectReportType"));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }
    if (endDate.trim() && !startDate.trim()) {
      dispatch(setMessageAlert("alerts.selectStartDate"));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }
    //SERVICIO GET AUDIT
    //dependiendo del type se manda a llamar a limitAudit o exclusionsAudit
    if (typeReport === TypeReportChanges.LIMITS) {
      //get audit limits
      dispatch(
        asyncLoadingFunction(
          async () => await dispatch(startGetLimitsAudit(params))
        )
      );
    } else {
      //get exclusions limits
      dispatch(
        asyncLoadingFunction(
          async () => await dispatch(startGetExclusionsAudit(params))
        )
      );
    }

    setDataFilter(params);
    setFilter(true);
  };
  const onCleanFilter = async () => {
    if (filter) {
      dispatch(arraySearch(null));
      setTypeReport(null);
      setDataFilter({});
    }
    setFilter(false);
  };
  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(async () =>
        typeReport === TypeReportChanges.LIMITS
          ? await dispatch(
              startGetLimitsAudit({
                ...dataFilter,
                page,
                perPage: rows || rowsPerPage,
              })
            )
          : await dispatch(
              startGetExclusionsAudit({
                ...dataFilter,
                page,
                perPage: rows || rowsPerPage,
              })
            )
      )
    );
  };
  const getNotificationChanges = async (props: any) => {
    if (token && user) {
      if (typeReport === TypeReportChanges.LIMITS) {
        return await getLimitAudit(token, {});
      } else {
        return await getExclusionsAudit(token, {});
      }
    }
  };
  return (
    <>
      <div
        className="filter"
        style={{ padding: "18px 0px 0px", marginBottom: "18px" }}
      >
        <Filter
          fields={filterFields}
          handleFilter={handleFilter}
          onClean={onCleanFilter}
        />
      </div>

      {data && data.data.length > 0 ? (
        <div className="white-container">
          <Grid item xs={12}>
            <MyCustomTable
              columns={columns}
              data={data.data}
              lastPage={data.last_page}
              totalData={data.total}
              onPageChange={handlePageChange}
              renderBody={(item) => <RowNotificationChanges row={item} />}
              exportData
              hookBuildTable={NotificationChangesDataTable}
              getAllDataToExport={getNotificationChanges}
              titleCSV={`report${moment().toISOString()}`}
              pdfComponent={NotificationChangesPdf}
            />
          </Grid>
        </div>
      ) : (
        loading && (
          <div className="filter">
            <Box
              display={"flex"}
              justifyContent="center"
              alignItems={"center"}
              width="100%"
              mt={3}
            >
              <CircularProgress className={"colorLoading"} />
            </Box>
          </div>
        )
      )}

      {!filter && !data && (
        <Grid item xs={12} pt={3} mt={-2}>
          <Stack
            sx={{ width: "100%" }}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)" }}
            spacing={2}
          >
            <Alert
              icon={<InfoIcon sx={{ fontSize: 30, color: "#2C97DF" }} />}
              sx={{
                borderLeft: `7px solid #2C97DF`,
                backgroundColor: "#EAF5FC",
                color: "#4b4b4b",
              }}
            >
              <div className="d-flex">
                <AlertTitle
                  style={{ marginTop: "5px" }}
                  sx={{ color: "#333333" }}
                >
                  {t("alerts.clickButton")}
                </AlertTitle>
                <AlertTitle
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  sx={{ color: "#333333", fontWeight: "bold" }}
                >
                  {t("changeNotificationReport.viewReport")}
                </AlertTitle>
              </div>
            </Alert>
          </Stack>
        </Grid>
      )}
    </>
  );
};
