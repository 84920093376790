import {
  IConfigParam,
  IConfigParamForm,
  IConfigParamOperatorUpdate,
  IConfigParamOption,
  IConfigParamsQuery,
  IParamOptionOperatorBody,
} from "./../../interfaces/configParams";
import {
  createConfigParam,
  createParamOptionOperator,
  getConfigParams,
  updateConfigParam,
  updateParamOptionOperator,
} from "../../services/configParams";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import { IPaginationData } from "../../interfaces/shared";
import { setMessageAlert, setOpenAlert, setTypeAlert } from "./helperActions";

export const setActiveConfigParam = (active: any) => ({
  type: Types.setActiveConfigParam,
  payload: active,
});

export const startGetConfigParams = (params: Partial<IConfigParamsQuery>) => {
  return async (dispatch: Function, getState: Function) => {
    const { token, user } = (getState() as AppState).auth;
    const { rowsPerPage } = (getState() as AppState).helper;

    if (!token || !user) {
      throw new Error("invalid action");
    }
    try {
      if (params.page) {
        params.perPage = rowsPerPage;
      }
      if (user.operator) {
        params.operator = user.operator.id;
      }
      let finalValues: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (typeof value === "number" || value.length > 0) {
          //@ts-ignore
          finalValues[key] = value;
        }
      });
      const response = await getConfigParams(token, finalValues);

      if (response.error) {
        dispatch(setMessageAlert(`alerts.${response.error.errorMessage}`));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      const { rs } = response;
      return dispatch(setConfigParams(rs));
    } catch (error) {
      dispatch(setMessageAlert(`alerts.getError`));
      dispatch(setTypeAlert("error"));
      return dispatch(setOpenAlert());
    }
  };
};
export const setConfigParams = (
  data: IPaginationData<IConfigParam> | null
) => ({
  type: Types.setConfigParams,
  payload: data,
});
export const startCreateParam = (values: IConfigParamForm) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    if (!token) {
      throw new Error("Invalid action");
    }
    const response = await createConfigParam(token, values);
    const messageAlert = response.error
      ? response.error.errorMessage
      : response.message;
    const alertType = response.error ? "error" : "success";

    dispatch(setMessageAlert(`configParams.${messageAlert}`));
    dispatch(setTypeAlert(alertType));
    if (response.error) {
      return dispatch(setOpenAlert());
    }
    await dispatch(startGetConfigParams({ page: 1 }));
    return dispatch(setOpenAlert());
  };
};
export const startUpdateParam = (
  id: number,
  values: Partial<IConfigParamOperatorUpdate>,
  succesMessage?: string,
  currentPage?: number
) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;

    if (!token) {
      throw new Error("Invalid action");
    }
    const response = await updateConfigParam(token, id, values);
    const messageAlert = response.error
      ? response.error.errorMessage
      : succesMessage || response.message;
    const alertType = response.error ? "error" : "success";

    dispatch(setMessageAlert(`configParams.${messageAlert}`));
    dispatch(setTypeAlert(alertType));
    if (response.error) {
      return dispatch(setOpenAlert());
    }
    await dispatch(startGetConfigParams({ page: currentPage }));
    return dispatch(setOpenAlert());
  };
};

export const startUpdateConfigOption = (formValues: IConfigParamOption[]) => {
  return async (dispatch: Function, getState: Function) => {
    const { token } = (getState() as AppState).auth;
    const { activeParam, configParams } = (getState() as AppState).configParams;

    if (!token || !activeParam) {
      throw new Error("Invalid action");
    }
    try {
      const rs = await Promise.all(
        formValues.map(async (item) => {
          const requestBody: IParamOptionOperatorBody = {
            state: item.state,
            option: item.id,
            operator: activeParam.operator.id,
          };
          if (item.isNative) {
            return await createParamOptionOperator(token, requestBody);
          }

          const requestUpdateBody: Partial<IParamOptionOperatorBody> = {
            state: item.state,
          };
          return await updateParamOptionOperator(
            token,
            item.id,
            requestUpdateBody
          );
        })
      );

      const isOk = !!!rs.find((res) => res.status === 406);
      if (!isOk) {
        dispatch(setMessageAlert(`configParams.errorUpdate`));
        dispatch(setTypeAlert("error"));
        return dispatch(setOpenAlert());
      }
      await dispatch(startGetConfigParams({ page: configParams?.page }));
      dispatch(setActiveConfigParam(null));
      dispatch(setMessageAlert(`configParams.succesUpdate`));
      dispatch(setTypeAlert("success"));
      return dispatch(setOpenAlert());
    } catch (error) {}
  };
};
