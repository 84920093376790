import {
  IBonusDataPariplay,
  IBonusPariplayDb,
  IBonusType,
  IBonusTypeDb,
  IFreeTicketsBonusDb,
} from "./../../interfaces/bonus";
import { Types } from "./../types/types";
import { IBonus, IBonusDb } from "../../interfaces/bonus";

export interface IBonusState {
  bonus: IBonusDb | null;
  bonusPariplay: IBonusPariplayDb | null;
  bonusType: IBonusType[] | null;
  activeBonus: IBonus | null;
  openDetails: boolean;
  activeBonusType: IBonusType | null;
  bonusGroup: any;
  activeBonusGroup: any;
  freeTicketsBonus: IFreeTicketsBonusDb | null;
}
export interface IBonusActions {
  type: Types;
  payload?:
    | IBonus
    | IBonusDataPariplay
    | IBonusDb
    | IBonusPariplayDb
    | IBonusType
    | IBonusTypeDb
    | IFreeTicketsBonusDb
    | null
    | any;
}

const initialState: IBonusState = {
  bonus: null,
  bonusPariplay: null,
  bonusType: null,
  activeBonus: null,
  openDetails: false,
  activeBonusType: null,
  bonusGroup: null,
  activeBonusGroup: null,
  freeTicketsBonus: null,
};

export const bonusReducer = (
  state = initialState,
  action: IBonusActions
): IBonusState => {
  const { type, payload } = action;

  switch (type) {
    case Types.setBonus:
      return {
        ...state,
        bonus: payload as IBonusDb,
      };
    case Types.setBonusPariplay:
      return {
        ...state,
        bonusPariplay: payload as IBonusPariplayDb,
      };
    case Types.setBonustypes:
      return {
        ...state,
        bonusType: payload as IBonusType[],
      };
    case Types.setActiveBonus:
      return {
        ...state,
        activeBonus: payload as any,
      };
    case Types.setOpenBonusDetails:
      return {
        ...state,
        openDetails: payload as any,
      };
    case Types.setActiveBonusType:
      return {
        ...state,
        activeBonusType: payload as any,
      };

    case Types.setBonusGroup:
      return {
        ...state,
        bonusGroup: payload as any,
      };

    case Types.setActiveBonusGroup:
      return {
        ...state,
        activeBonusGroup: payload as any,
      };
    case Types.setFreeTicketsBonus:
      return {
        ...state,
        bonus: payload as IFreeTicketsBonusDb,
      };

    default:
      return state;
  }
};
