import React, { useState } from "react";
import MyCustomTable, {
  IColumns,
} from "../../../components/Custom/Table/MyCustomTable";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { asyncLoadingFunction } from "../../../redux/actions/helperActions";
import {
  setPlayerHistory,
  startGetPlayerHistory,
} from "../../../redux/actions/managementPlayersActions";
import { RowPlayerUpdateHistory } from "./RowPlayerUpdateHistory";
import Filter from "../../../components/Filter/Filter";
import { Grid } from "@mui/material";
import { CustomAlert } from "../../../components/Alerts/CustomAlert";
import { Enum_FieldType, IFilterField } from "../../../interfaces/components";
import { PlayerUpdateHistoryDataTable } from "../../../hooks/useDataTable/playerUpdateHistory";
import { PlayerUpdateHistoryPdf } from "../../../components/Pdf/playerUpdateHistory/PlayerUpdateHistoryPdf";
import { getPlayerHistory } from "../../../services/playersService";

export const PlayerUpdateHistory = () => {
  const { playerUpdateHistory } = useSelector(
    (state: AppState) => state.managementPlayers
  );
  const { token } = useSelector((state: AppState) => state.auth);
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);
  const [dataFilter, setDataFilter] = useState<any>({});

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const columns: IColumns[] = [
    {
      align: "center",
      label: t("sidebar.player"),
    },
    {
      align: "center",
      label: t("transactionalSettings.detail"),
    },
    {
      align: "center",
      label: t("user.observation"),
    },
    {
      align: "center",
      label: t("form.responsible"),
    },
    {
      align: "center",
      label: t("user.createAt"),
    },
    {
      align: "center",
      label: t("sidebar.files"),
    },
  ];
  const filterFileds: IFilterField[] = [
    {
      label: t("reports.playerId"),
      type: Enum_FieldType.NUMBER,
    },
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
  ];

  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(
        async () =>
          await dispatch(
            startGetPlayerHistory({
              ...dataFilter,
              page,
              perPage: rows || rowsPerPage,
            })
          )
      )
    );
  };

  const handleFilter = async (values: any) => {
    const params = {
      player: values[t("reports.playerId")],
      startDate: values[t("filters.startDate")],
      endDate: values[t("filters.endDate")],
    };
    await dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startGetPlayerHistory({
            ...params,
            page: 1,
          })
        )
      )
    );
    setDataFilter({ ...params });
  };
  const onCleanFilter = async () => {
    setDataFilter({});
    dispatch(setPlayerHistory(null));
  };
  const getTotalData = async () => {
    if (token) {
      const params = {
        ...dataFilter,
      };

      return await getPlayerHistory(token, params);
    }
  };
  return (
    <Grid container spacing={2} mt={2}>
      <Grid item xs={12}>
        <Filter
          fields={filterFileds}
          handleFilter={handleFilter}
          onClean={onCleanFilter}
        />
      </Grid>
      <Grid item xs={12}>
        {playerUpdateHistory ? (
          <MyCustomTable
            columns={columns}
            data={playerUpdateHistory.data}
            lastPage={playerUpdateHistory.last_page}
            totalData={playerUpdateHistory.total}
            onPageChange={handlePageChange}
            renderBody={(row) => <RowPlayerUpdateHistory row={row} />}
            exportData={true}
            hookBuildTable={PlayerUpdateHistoryDataTable}
            pdfComponent={PlayerUpdateHistoryPdf}
            titleCSV={"details-player" + new Date().toISOString()}
            getAllDataToExport={getTotalData}
          />
        ) : (
          <>
            <CustomAlert htmlText={t("player.filterText")} type="info" />
          </>
        )}
      </Grid>
    </Grid>
  );
};
