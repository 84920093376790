import React, { useEffect } from "react";
import NotificationAlert from "react-notification-alert";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";

import { AdminNavbar } from "../../components/Navbars/AdminNavbar";
import Sidebar from "../../components/Sidebar/Sidebar";

import logo from "../../assets/svg/logo-mini.svg";
import { IRoutes } from "../../interfaces/menu";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";
import { NotificationsList } from "../../components/Dashboard/NotificationsList";
import { Grid } from "@mui/material";
import { MyAccount } from "../../views/pages/account/MyAccount";
import { startLoadAvailableRoutes } from "../../redux/actions/managementMenuActions";
import { TimeoutDialog } from "../../views/components/timeout/TimeoutDialog";
import { MyAlert } from "../../views/components/custom/MyAlert";
import { setCloseAlert } from "../../redux/actions/helperActions";

let ps: any;

export const AdminLayout = (props: any) => {
  const [sidebarMini, setSidebarMini] = React.useState(true);
  const { openNotifications } = useSelector((state: AppState) => state.ui);
  const { openAlert, messageAlert, typeAlert } = useSelector(
    (state: AppState) => state.helper
  );

  const [opacity, setOpacity] = React.useState(0);
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const mainPanelRef = React.useRef(null);
  const notificationAlertRef = React.useRef(null);
  const location = useLocation();
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const { user } = useSelector((state: AppState) => state.auth);
  const { openTimeout } = useSelector((state: AppState) => state.helper);
  const dispatch = useDispatch();
  const onCloseAlert = () => {
    dispatch(setCloseAlert());
  };

  useEffect(() => {
    if (document.scrollingElement) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      if (mainPanelRef.current) {
        (mainPanelRef.current as any).scrollTop = 0;
      }
    }
    const getModulePermissions = async () => {
      await dispatch(startLoadAvailableRoutes());
    };
    if (user) {
      getModulePermissions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    let innerMainPanelRef = mainPanelRef;
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.classList.add("perfect-scrollbar-on");
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current as any);
      mainPanelRef.current &&
        (mainPanelRef.current as any).addEventListener(
          "ps-scroll-y",
          showNavbarButton
        );
      let tables = document.querySelectorAll(".table-responsive");
      tables.forEach((value, i) => {
        ps = new PerfectScrollbar(tables[i] as any);
      });
    }
    window.addEventListener("scroll", showNavbarButton);
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
        innerMainPanelRef.current &&
          (innerMainPanelRef.current as any).removeEventListener(
            "ps-scroll-y",
            showNavbarButton
          );
      }
      window.removeEventListener("scroll", showNavbarButton);
    };
  }, []);
  const showNavbarButton = () => {
    if (document.scrollingElement) {
      if (
        document.documentElement.scrollTop > 50 ||
        document.scrollingElement.scrollTop > 50 ||
        (mainPanelRef.current && (mainPanelRef.current as any).scrollTop > 50)
      ) {
        setOpacity(1);
      } else if (
        document.documentElement.scrollTop <= 50 ||
        document.scrollingElement.scrollTop <= 50 ||
        (mainPanelRef.current && (mainPanelRef.current as any).scrollTop <= 50)
      ) {
        setOpacity(0);
      }
    }
  };
  const getRoutes = (routes: IRoutes[]): any => {
    //Comparar si la ruta que llega del back esta en las rutas de la aplicacion
    return routes.map((prop, key) => {
      if (prop.collapse && prop.menuItem.length > 0) {
        //@ts-ignore
        return getRoutes(prop.menuItem);
      }
      if (prop.url?.split("/")[1] === "admin") {
        return <Route path={prop.url} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  const getActiveRoute = (routes: IRoutes[]): any => {
    let activeRoute = "Default Brand Text";
    routes.forEach((route) => {
      if (route.collapse) {
        //@ts-ignore
        let collapseActiveRoute = getActiveRoute(route.menuItem);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        } else {
          if (
            window.location.pathname.indexOf(
              //@ts-ignore
              route.url
            ) !== -1
          ) {
            return route.name;
          }
        }
      }
    });
    return activeRoute;
  };

  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false);
    } else {
      setSidebarMini(true);
    }

    document.body.classList.toggle("sidebar-mini");
  };
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
    document.documentElement.classList.toggle("nav-open");
  };
  const closeSidebar = () => {
    setSidebarOpened(false);
    document.documentElement.classList.remove("nav-open");
  };
  const handleCloseSidebar = () => {
    if (sidebarOpened) {
      closeSidebar();
    }
    if (!sidebarMini) {
      setSidebarMini(true);
      document.body.classList.toggle("sidebar-mini");
    }
  };
  return (
    <div className="wrapper">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="navbar-minimize-fixed" style={{ opacity: opacity }}>
        <button
          className="minimize-sidebar btn btn-link btn-just-icon"
          onClick={handleMiniClick}
        >
          <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
          <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
        </button>
      </div>
      {routes.length > 0 && (
        <Sidebar
          {...props}
          //routes={routesAdmin}
          routes={routes}
          activeColor={"blue"}
          logo={{
            outterLink: "https://www.creative-tim.com/",
            imgSrc: logo,
          }}
          openSidebar={handleMiniClick}
          closeSidebar={closeSidebar}
        />
      )}
      {openNotifications && (
        <Grid container>
          <Grid item sm={5} xs={7} lg={3.4} className="notification-wrapper-sm">
            <NotificationsList closeIcon />
          </Grid>
        </Grid>
      )}
      <div
        onClick={() => handleCloseSidebar()}
        className="main-panel opacity-main-panel"
        id="main-panel"
        ref={mainPanelRef}
        // @ts-ignore
        data={"blue"}
      >
        {
          <AdminNavbar
            {...props}
            handleMiniClick={handleMiniClick}
            brandText={getActiveRoute(routes)}
            sidebarOpened={sidebarOpened}
            toggleSidebar={toggleSidebar}
          />
        }
        <Switch>
          {getRoutes(routes)}
          <Route path="/admin/account" component={MyAccount} />
          {/* <Route
            path="/admin/transactions/withdrawals"
            exact
            component={WithdrawalRequest}
          />
          <Route
            path="/admin/transactions"
            exact
            component={TransactionSettings}
          /> */}
          {routes && user && (
            <Redirect
              from="*"
              to={
                routes.find((route) => route.url === "/admin/home")
                  ? "/admin/home"
                  : routes[0].collapse && routes[0].menuItem.length > 0
                  ? routes[0].menuItem[0].url
                  : routes[0].url
              }
            />
          )}
        </Switch>
        <TimeoutDialog open={openTimeout} handleStay={props.handleStay} />
        <MyAlert
          open={openAlert}
          message={messageAlert ? messageAlert : ""}
          typeAlert={typeAlert}
          handleClose={onCloseAlert}
        />
      </div>
    </div>
  );
};
