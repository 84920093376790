import { Form, Formik } from "formik";
import { useState, useEffect, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import {
  Box,
  Grid,
  MenuItem,
  Button,
  FormControl,
  FormHelperText,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import { MyTextField } from "../custom/MyTextField";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { IFile } from "../../../interfaces/legal";
import { MyDialog } from "../custom/MyDialog";
import {
  setFlagForm,
  asyncLoadingFunction,
} from "../../../redux/actions/helperActions";
import { Roles_Enum, State } from "../../../enums/enums";
import { getOperatorsService } from "../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../redux/actions/managementCompanyActions";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { TextEditor } from "../custom/TextEditor";
import { regLettersNumbers } from "../../../utils/utils";
import { IFormMessages } from "../../../interfaces/messages";
import {
  setActiveMessage,
  startCreateMessage,
  startUpdateMessage,
} from "../../../redux/actions/messagesActions";
import { urls } from "../../../const/const";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const FormCreateMessage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { activeMessage } = useSelector((state: AppState) => state.messages);
  const { loading } = useSelector((state: AppState) => state.helper);
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const [fileLabel, setFileLabel] = useState(
    activeMessage && activeMessage.image.length > 0 ? activeMessage.image : ""
  );
  const [file, setFile] = useState<IFile>({
    file: null,
    label: activeMessage ? fileLabel : "",
  });
  const [noValid, setNoValid] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [cancel, setCancel] = useState(false);
  const [saveValues, setSaveValues] = useState<any>(null);
  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;
  const [operatorId, setOperatorId] = useState<number | null>(
    activeMessage
      ? activeMessage.operator
      : !isSuper && user
      ? user.operator.id
      : null
  );

  const [value, setValue] = useState(
    activeMessage ? activeMessage.messageBody : ""
  );
  const [textEditor, setTextEditor] = useState("");
  const initialValues = {
    messageName: activeMessage ? activeMessage.messageName : "",
    image: null,
    messageBody: activeMessage ? activeMessage.messageBody : "",
    buttonText: activeMessage ? activeMessage.buttonText : "",
    buttonUrl: activeMessage ? activeMessage.buttonUrl : "",
  };

  const validationSchema = yup.object({
    messageName: yup.string().required(t("form.requiredFile")),
    buttonText: yup.string().required(t("form.requiredFile")),
    buttonUrl: yup.string().required(t("form.requiredFile")),
  });

  const handleCancel = () => {
    setMessage("dialog.sureExit");
    setCancel(true);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setCancel(false);
    setOpenDialog(false);
  };

  const handleAccept = async () => {
    handleCloseDialog();
    if (cancel) {
      dispatch(setFlagForm({ flagFormCreate: false }));
    } else {
      const formData: IFormMessages = {
        messageName: saveValues.messageName,
        buttonText: saveValues.buttonText,
        buttonUrl: saveValues.buttonUrl,
        image: activeMessage ? saveValues.fileName : saveValues.image,
        messageBody: textEditor.trim() !== "" ? value : "",
        operator: user?.operator ? user.operator.id : null,
        file: saveValues.fileUrl ? saveValues.fileUrl : null,
      };
      dispatch(
        asyncLoadingFunction(async () =>
          activeMessage
            ? await dispatch(
                startUpdateMessage({
                  ...formData,
                  id: activeMessage.id,
                })
              )
            : await dispatch(startCreateMessage(formData))
        )
      );
    }
  };

  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    token && fetchOperators(token);
    return () => {
      dispatch(setActiveMessage(null));
      dispatch(setFlagForm({ flagFormCreate: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectFile = (e: any) => {
    const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
    const file = e.target.files[0] as File;

    if (file) {
      if (!SUPPORTED_FORMATS.includes(file.type)) {
        return setNoValid(true);
      }
      setNoValid(false);
      setFile({ file, label: e.target.files[0].name });
      setFileLabel(e.target.files[0].name);
    } else {
      setFile({
        file: null,
        label: "",
      });
    }
  };
  // ARCHIVOS SOPORTADOS PARA INPUT TYPE=FILE
  const fileSupported = {
    accept: "image/jpeg, image/png",
  };

  const handleDeleteFile = () => {
    setFile({ file: null, label: "" });
    setFileLabel("");
  };

  return (
    <Box m={2}>
      <Formik
        initialValues={initialValues}
        validateOnChange={true}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          if (textEditor.trim().length > 0) {
            const newFile = file.file ? file.file : null;
            const formValues = { ...data };
            setSaveValues({
              ...formValues,
              fileUrl: newFile,
              fileName: file.label !== "" ? file.label : fileLabel,
              operator: operatorId,
            });
            setMessage(
              activeMessage
                ? t("messages.sureUpdateMessage")
                : t("messages.sureCreateMessage")
            );
            setOpenDialog(true);
          }
          setSubmitting(false);
        }}
      >
        {({ handleChange, values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <MyTextField
                  name="messageName"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label={`${t("messages.messageName")}*`}
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="file"
                  inputProps={fileSupported}
                  onChange={handleSelectFile}
                  id="icon-button-file"
                  autoComplete="off"
                  style={{ display: "none" }}
                  fullWidth
                  error={noValid}
                />
                <Grid container>
                  <Grid item xs={8}>
                    <TextField
                      disabled={true}
                      variant="outlined"
                      style={{ color: "black" }}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <>
                            {file.label.length > 0 && (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleDeleteFile}
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    boxShadow: "none",
                                  }}
                                >
                                  <CancelIcon />
                                </IconButton>
                              </InputAdornment>
                            )}
                          </>
                        ),
                      }}
                      label={
                        file.label.length
                          ? file.label
                          : t("managementAds.uploadImg")
                      }
                      name="image"
                      size="small"
                      error={noValid}
                      helperText={noValid && t("alerts.invalidFormat")}
                    />
                  </Grid>
                  <Grid item xs={2} pr={1}>
                    <label htmlFor="icon-button-file">
                      <Button
                        style={{
                          border: "none",
                          outline: "none",
                          backgroundColor: "#007e8c",
                        }}
                        variant="contained"
                        component="span"
                      >
                        {t("button.inspect").toUpperCase()}
                      </Button>
                    </label>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <MyTextField
                  name="buttonText"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  label={`${t("messages.buttonText")}*`}
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={handleChange}
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    !regLettersNumbers(e.key) && e.preventDefault()
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <label className="ml-3">{t("messages.buttonUrl")}*</label>
                <MyTextField
                  name="buttonUrl"
                  select
                  InputLabelProps={{ shrink: false }}
                  label={" "}
                  onChange={handleChange}
                >
                  {urls.map((item) => (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                      sx={{
                        "&.Mui-focused": {
                          backgroundColor: "red",
                        },
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={12}>
                <TextEditor
                  value={value}
                  onChange={setValue}
                  placeholder="Escribe aqui"
                  onChangeFile={handleSelectFile}
                  onChangeText={(e: string) => setTextEditor(e)}
                />
              </Grid>
              {
                //MOSTRAR SELECT CON OPERADORES CUANDO SEA SUPER_ADMIN
                user?.roles.name === Roles_Enum.SUPER_ADMIN &&
                  !activeMessage && (
                    <Grid item md={4} lg={4} sm={6} xs={12}>
                      <FormControl
                        sx={{ width: "100%" }}
                        size="small"
                        error={submit && isSuper && !operatorId}
                        disabled={!!activeMessage}
                      >
                        <InputLabel id="operator-label">
                          {t("companyManagement.operator") + "*"}
                        </InputLabel>
                        <Select
                          labelId="operator-label"
                          name="operator"
                          size="small"
                          fullWidth
                          onChange={(e: any) => setOperatorId(e.target.value)}
                          value={!operatorId ? "" : operatorId}
                          input={
                            <OutlinedInput
                              label={t("companyManagement.operator") + "*"}
                            />
                          }
                          MenuProps={MenuProps}
                        >
                          {companies?.data?.map((company) => (
                            <MenuItem key={company.id} value={company.id}>
                              {company.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormHelperText>
                        {submit && isSuper && !operatorId && (
                          <label className="ml-3" style={{ color: "#d32f2f" }}>
                            {t("form.requiredFile")}
                          </label>
                        )}
                      </FormHelperText>
                    </Grid>
                  )
              }
            </Grid>

            <Grid container>
              <Box
                display={"flex"}
                mt={2}
                flex={1}
                justifyContent="flex-end"
                alignItems={"flex-end"}
              >
                <Button
                  variant="contained"
                  className="footer-btn danger-btn ripple"
                  style={{ border: "none", outline: "none" }}
                  onClick={handleCancel}
                  disabled={!!loading}
                >
                  {t("button.cancel").toUpperCase()}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className="footer-btn primary-btn ripple"
                  style={{ border: "none", outline: "none" }}
                  disabled={
                    !values.messageName ||
                    !values.buttonText ||
                    !values.buttonUrl ||
                    file.file === null ||
                    !value
                  }
                  onClick={() => setSubmit(true)}
                >
                  {loading ? (
                    <CircularProgress size={"1.6rem"} color="inherit" />
                  ) : activeMessage ? (
                    t("button.edit").toUpperCase()
                  ) : (
                    t("button.save").toUpperCase()
                  )}
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>

      <MyDialog
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message={message}
        handleAccept={handleAccept}
        handleClose={handleCloseDialog}
        isLoading={!!loading}
      />
    </Box>
  );
};
