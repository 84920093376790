import { FC } from "react";
import {  IBingoGameActivity } from "../../../../interfaces/casino";
import { headersBingoGameActivity } from "../../../../helpers/headersTables";
import { Text, View } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../views/components/custom/customStyles";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface props {
  page: IBingoGameActivity[];
}
export const BingoGameActivityPdf: FC<props> = ({ page }) => {
  const { t } = useTranslation();
  const columns = headersBingoGameActivity;
  return (
    <>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#02D0E7",
        }}
      >
        {columns.map((column, index) => (
          <View key={index} style={[stylesPdf.col, stylesPdf.pCol]}>
            <Text style={stylesPdf.text}>{column.label}</Text>
          </View>
        ))}
      </View>

      <View style={{ flex: 1 }}>
        {page.map((data) => {
          const status: { [key: number]: string } = {
            1: t("betResults.won"),
            2: t("betResults.loss"),
            3: t("betResults.wonBonus"),
          };

          return (
            <View key={data.id} style={stylesPdf.row}>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {moment(data.createAt).format("YYYY-MM-DD HH:mm:ss")}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.id}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  ${" "}
                  {data.amountBet
                    ? parseFloat(data.amountBet.toString()).toFixed(2)
                    : "0.00"}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  ${" "}
                  {data.awardsBet
                    ? parseFloat(data.awardsBet.toString()).toFixed(2)
                    : "0.00"}
                </Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>{data.roomId}</Text>
              </View>
              <View style={[stylesPdf.col, stylesPdf.pCol]}>
                <Text style={stylesPdf.textContent}>
                  {status[`${data.status}`].toUpperCase()}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </>
  );
};