import React, { useState } from "react";
import Filter from "../../../../components/Filter/Filter";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { asyncLoadingFunction } from "../../../../redux/actions/helperActions";
import MyCustomTable, {
  IColumns,
} from "../../../../components/Custom/Table/MyCustomTable";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { Grid } from "@material-ui/core";
import { RowPromotialAcount } from "./RowPromotialAcount";
import { startGetReportBonus } from "../../../../redux/actions/bonusActions";
import { PromotionalAcountDataTable } from "../../../../hooks/promotionalAcount/promotionalAcount";
import { filterBonus } from "../../../../services/bonus";
import moment from "moment";
import { PromotionalAcountPdf } from "../../../../components/Pdf/promotionalAcount/PromotionalAcountPdf";

export const PromotionalAcountStatement = () => {
  const [dataFilter, setDataFilter] = useState<any>({});
  const { user, token } = useSelector((state: AppState) => state.auth);
  const { data } = useSelector((state: AppState) => state.search);
  const { rowsPerPage } = useSelector((state: AppState) => state.helper);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterFields: IFilterField[] = [
    {
      label: t("filters.startDate"),
      type: Enum_FieldType.DATE,
    },
    {
      label: t("filters.endDate"),
      type: Enum_FieldType.DATE,
    },
  ];
  const columns: IColumns[] = [
    {
      align: "left",
      label: t("managementBonds.bondName"),
    },
    {
      align: "left",
      label: t("form.value"),
    },
    {
      align: "left",
      label: t("managementBonds.amountDeliveredBonus"),
    },
    {
      align: "left",
      label: t("managementBonds.totalBondsValue"),
    },
    {
      align: "left",
      label: t("reports.totalValue"),
    },
    {
      align: "left",
      label: t("managementBonds.bonusValueDelivered"),
    },
    {
      align: "left",
      label: t("managementBonds.provider"),
    },
    {
      align: "left",
      label: t("table.createdBy"),
    },
    {
      align: "left",
      label: t("user.createAt"),
    },
    {
      align: "left",
      label: t("companyManagement.operator"),
    },
    {
      align: "left",
      label: t("managementBonds.expiredBonus"),
    },
  ];
  const handleFilter = async (formValues: any) => {
    const startDate = formValues[t("filters.startDate")];
    const endDate = formValues[t("filters.endDate")];
    const params: any = {
      startDate,
      endDate,
      page: 1,
      perPage: 9,
    };

    dispatch(
      asyncLoadingFunction(
        async () => await dispatch(startGetReportBonus(params))
      )
    );
    setDataFilter(params);
  };
  const onCleanFilter = async () => {
    dispatch(
      asyncLoadingFunction(
        async () => await dispatch(startGetReportBonus({ page: 1 }))
      )
    );

    setDataFilter({});
  };
  const handlePageChange = async (page: number, rows?: number) => {
    dispatch(
      asyncLoadingFunction(
        async () =>
          await dispatch(
            startGetReportBonus({
              ...dataFilter,
              page,
              perPage: rows || rowsPerPage,
            })
          )
      )
    );
  };
  const getBonusReport = async (props: any) => {
    if (!user || !token) {
      throw new Error("Invalid action");
    }
    return await filterBonus(user.operator?.id, {}, token);
  };
  return (
    <>
      <div
        className="filter"
        style={{ padding: "18px 0px 0px", marginBottom: "18px" }}
      >
        <Filter
          fields={filterFields}
          handleFilter={handleFilter}
          onClean={onCleanFilter}
          component="reports"
        />
      </div>

      {data && (
        <div className="white-container">
          <Grid item xs={12}>
            <MyCustomTable
              columns={columns}
              data={data.data}
              lastPage={data.last_page}
              totalData={data.total}
              onPageChange={handlePageChange}
              renderBody={(item) => <RowPromotialAcount row={item} />}
              exportData
              hookBuildTable={PromotionalAcountDataTable}
              getAllDataToExport={getBonusReport}
              titleCSV={`bonus-report${moment().toISOString()}`}
              pdfComponent={PromotionalAcountPdf}
            />
          </Grid>
        </div>
      )}
    </>
  );
};
