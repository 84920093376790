import React, { useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import logoGames from "../../../assets/svg/param.svg";
import { useTranslation } from "react-i18next";
import { CustomSwitch } from "../custom/CustomSwitch";
import "./configParams.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { State } from "../../../enums/enums";
import { uiSetOpenDialog } from "../../../redux/actions/uiAction";
import {
  setActiveConfigParam,
  startUpdateConfigOption,
} from "../../../redux/actions/configParamsActions";
import { IConfigParamOption } from "../../../interfaces/configParams";

const FormConfigParams = () => {
  const { t } = useTranslation();
  const [expandInfo, setExpandInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const { activeParam } = useSelector((state: AppState) => state.configParams);
  const [paramValues, setParamValues] = useState<IConfigParamOption[]>(
    activeParam ? [...activeParam?.configParam.options] : []
  );
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(setActiveConfigParam(null));
    dispatch(uiSetOpenDialog());
  };
  const handleSwitch = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const copyValues = [...paramValues];
    const currentElement = { ...copyValues[index] };
    copyValues[index] = {
      ...copyValues[index],
      state:
        currentElement.state === State.ACTIVE ? State.INACTIVE : State.ACTIVE,
    };
    setParamValues(copyValues);
  };
  const handleUpdate = async () => {
    setLoading(true);

    await dispatch(startUpdateConfigOption(paramValues));
    setLoading(false);
    handleCancel();
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className="box-games" display="flex">
            <Grid container>
              <Grid item xs={0.8} display="flex" alignItems={"stretch"}>
                <Box className="box-games-img">
                  <img src={logoGames} alt="Logo games" />
                </Box>
              </Grid>
              <Grid item xs={11.2}>
                <Box width={1} m={2} mt={1} pr={3}>
                  <Grid container>
                    <Grid item xs={6}>
                      <label className="label-text title-blue">
                        {t("configParams.paramCode").toUpperCase()}
                      </label>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Grid
                        container
                        display={"flex"}
                        alignItems="stretch"
                        justifyContent={"space-between"}
                      >
                        <Grid
                          item
                          xs={11}
                          p={1}
                          sx={{ wordWrap: "break-word" }}
                        >
                          {activeParam?.configParam.code}
                        </Grid>{" "}
                        <Grid item xs={1}>
                          <IconButton
                            size="small"
                            style={{ border: "none", outline: "none" }}
                            onClick={() => setExpandInfo(!expandInfo)}
                          >
                            {expandInfo ? (
                              <i
                                className="fa fa-angle-up"
                                style={{ color: "#1D4ED8" }}
                              />
                            ) : (
                              <i
                                className="fa fa-angle-down"
                                style={{ color: "#1D4ED8" }}
                              />
                            )}
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container mt={1} borderBottom={1} borderColor="#9E9E9E">
                    <Grid item xs={6}>
                      <Box width={1} display="flex">
                        <Grid item xs={6}>
                          <label className="label-text title">
                            {t("configParams.selectedParam").toUpperCase()}
                          </label>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <label className="label-text">
                        {activeParam?.configParam.name}
                      </label>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    mt={3}
                    mb={5}
                    display="flex"
                    justifyContent="space-around"
                  >
                    {expandInfo &&
                      activeParam &&
                      activeParam.configParam.options.length > 0 && (
                        <>
                          {
                            //@ts-ignore
                            paramValues.map(({ id, name, state }, index) => (
                              <Grid
                                className="flex-switch"
                                key={id + "-" + name}
                              >
                                <label className="label-text">
                                  {name.toUpperCase()}{" "}
                                </label>

                                <CustomSwitch
                                  name={name}
                                  checked={state === State.ACTIVE}
                                  onChange={(e) => handleSwitch(e, index)}
                                />
                              </Grid>
                            ))
                          }
                        </>
                      )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            display={"flex"}
            mt={2}
            flex={1}
            justifyContent="flex-end"
            alignItems={"flex-end"}
          >
            <Button
              variant="contained"
              className="footer-btn danger-btn ripple"
              style={{ border: "none", outline: "none" }}
              onClick={handleCancel}
              disabled={!!loading}
            >
              {t("button.cancel")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              className="footer-btn primary-btn ripple"
              style={{ border: "none", outline: "none" }}
              disabled={!!loading}
              onClick={handleUpdate}
              //onClick={() => setSubmit(true)}
            >
              {loading ? (
                <CircularProgress size={"1.6rem"} color="inherit" />
              ) : (
                t("button.edit")
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormConfigParams;
