import { FC, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "../tabsGamingActivity.scss";
import { State_Bets } from "../../../../../enums/enums";
import { IBetData } from "../../../../../interfaces/bets";
import { betStatuses } from "../../../../../helpers/betStatus";
import { useStyles } from "../../../custom/customStyles";
interface Props {
  row: IBetData;
}

export const RowDataTableBets: FC<Props> = ({ row }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [fees, setFees] = useState(1);

  useEffect(() => {
    row.events.map((item: any) =>
      setFees(
        row.eventCount > 1
          ? row.betStakes[0].odds
          : item.markets[0]
          ? item.markets[0].odds * fees
          : 1
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TableCell className={` word-break ${classes.capitalize}`}>
        {moment(row.createAt).format("D MMM HH:mm")}
      </TableCell>
      {row.eventCount > 1 ? (
        <TableCell className="word-break">
          {`${t(row.isSystem ? "betType.system" : "betType.multiple")} (${
            row.eventCount
          })`}
        </TableCell>
      ) : (
        <TableCell className="word-break">
          {t(row.isSystem ? "betType.system" : "betType.simple")}
        </TableCell>
      )}
      <TableCell className="word-break">
        {row.eventCount > 0
          ? `${t("bets.eventsNumber")} (${row.eventCount})`
          : "-"}
      </TableCell>

      <TableCell className="word-break">
        {row.transactions &&
        row.transactions.length > 0 &&
        State_Bets.WON === row.statusName
          ? (parseFloat(row.transactions[0].amount) / fees).toFixed(2)
          : (row.betStakes[0].betstake / 100).toFixed(2)}
      </TableCell>
      <TableCell className="word-break">
        {State_Bets.WON === row.statusName
          ? row.transactions && row.transactions.length > 0
            ? parseFloat(row.transactions[0].amount).toFixed(2)
            : "0.00"
          : "0.00"}
      </TableCell>
      <TableCell className="word-break">{fees.toFixed(2)}</TableCell>
      <TableCell className="word-break">
        {row.bonusAccountId ? t("table.yes") : t("table.not")}
      </TableCell>
      <TableCell align="left">
        <div
          className={
            State_Bets.OPEN === row.statusName ||
            State_Bets.WON === row.statusName
              ? "open"
              : "loss"
          }
        >
          {betStatuses
            .find((status) => status.value === row.status)
            ?.description.toUpperCase()}
        </div>
      </TableCell>
    </>
  );
};
