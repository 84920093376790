import React, { FC } from "react";
import { ICardDealer } from "../../../interfaces/cards";
import { TableCell, Tooltip } from "@material-ui/core";
import moment from "moment";
import { EnumWhiteListUserCard, State, StateLotCards, WhiteListUserCard } from "../../../enums/enums";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { setActiveCardDealer } from "../../../redux/actions/managementCardsActions";
import { useDispatch, useSelector } from "react-redux";
import { uiSetOpenDialog } from "../../../redux/actions/uiAction";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useLocation } from "react-router-dom";

interface props {
  row: ICardDealer;
}
export const RowDealerWallet: FC<props> = ({ row }) => {
  const { user } = useSelector((state: AppState) => state.auth);
  const { routes: RoutesRedux } = useSelector(
    (state: AppState) => state.managementMenus
  );
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const modulePermissions = RoutesRedux.find(
    (route) => !!route.menuItem.find((module) => module.url === pathname)
  )?.menuItem.find((module) => module.url === pathname);
  const enableEdit =
    modulePermissions &&
    modulePermissions.permissions &&
    modulePermissions.permissions.updated === 1;

  const onSeeMore = () => {
    dispatch(setActiveCardDealer(row));
    dispatch(uiSetOpenDialog());
  };

  return (
    <>
      <TableCell align="center">{row.dealer.id}</TableCell>
      <TableCell align="center">{row.dealer.name}</TableCell>
      <TableCell align="center">{"$" + row.lot.cards[0].value}</TableCell>
      <TableCell align="center">{row.lot.id}</TableCell>
      <TableCell align="center">{row.amountCards}</TableCell>
      <TableCell align="center">{row.comissionPercent + "%"}</TableCell>
      <TableCell align="center">
        {moment(row.lot.activateDate).format("YYYY-MM-DD hh:mm:ss A")}
      </TableCell>
      <TableCell align="center">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={
                [
                  State.ACTIVE,
                  StateLotCards.DELIVERED_DEALER,
                  StateLotCards.DELIVERED_MANUFACTURER,
                ].includes(row.lot.state)
                  ? "d-flex justify-content-center cellRowActive"
                  : State.INACTIVE === row.lot.state
                  ? "d-flex justify-content-center cellRowInactive"
                  : "d-flex justify-content-center cellRowRetired"
              }
            >
              <div className="d-flex col-sm-4 justify-content-center">
                {[
                  State.ACTIVE,
                  StateLotCards.DELIVERED_DEALER,
                  StateLotCards.DELIVERED_MANUFACTURER,
                ].includes(row.lot.state) && <>{t("state.active")}</>}
                {State.INACTIVE === row.lot.state && <>{t("state.inactive")}</>}
              </div>
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell align="center">
        {WhiteListUserCard.includes(`${user?.username}` as EnumWhiteListUserCard) && enableEdit  && (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Tooltip title={t("button.showMoreInfo") as string} arrow>
                <div>
                  <i
                    className="fa fa-search-plus fa-lg mr-1 iconSeeMore"
                    onClick={onSeeMore}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        )}
      </TableCell>
    </>
  );
};
