import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { TabPanel } from "../../../../components/Tabs";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setBonus } from "../../../../redux/actions/bonusActions";
import { PragmaticBonus } from "../../../components/managementBonus/casino/pragmatic/PragmaticBonus";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const styles = {
  "&.Mui-selected": {
    color: "#02D0E7",
    border: "none",
    outline: "none",
    fontWeight: "bold",
  },
};

export const CasinoBonusTab = () => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setBonus(null));
    setValue(newValue);
  };
  return (
    <>
      <Tabs
        sx={{
          marginLeft: "20px",
        }}
        value={value}
        onChange={handleChange}
        style={{ border: "none", outline: "none" }}
        variant="standard"
        TabIndicatorProps={{
          style: {
            backgroundColor: " #02D0E7",
            height: 3,
          },
        }}
      >
        <Tab
          sx={styles}
          label={
            <span className="spanTab">
              <i className="fa-sharp fa-solid fa-diamond mr-1"></i>
              {t("Pragmatic")}
            </span>
          }
          {...a11yProps(0)}
        />
      </Tabs>
      <div className="white-container">
        <TabPanel value={value} index={0}>
          {/* PRAGMATIC  */}
          <PragmaticBonus />
        </TabPanel>
      </div>
    </>
  );
};
