import {
  createConfigColorsOperator,
  setLogoOperatorService,
} from "../../services/managContentService";
import { getOperatorByIdService } from "../../services/managementCompanyService";
import {
  IColors,
  IContentManagementState,
  IFooter,
  ILogo,
} from "../reducers/contentManagementReducer";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import {
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";

export const startSetContent = () => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    dispatch(setLoadingState({ loading: true }));
    if (!user || !token) {
      throw new Error("Ivalid action");
    }
    try {
      const operatorDetails = await getOperatorByIdService(
        token,
        user.operator.id
      );
      if (operatorDetails) {
        dispatch(setUpContainerColors(operatorDetails.colors.colors));
        dispatch(setOperatorLogo(operatorDetails.logo));
        dispatch(setAlternativeOperatorLogo(operatorDetails.alternativeLogo));
        dispatch(setFooter(operatorDetails.colors.footer));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
    dispatch(setLoadingState({ loading: false }));
  };
};

export const startSetColors = (
  operator: number,
  colors: Partial<IContentManagementState>
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    if (!user || !token) {
      throw new Error("Ivalid action");
    }
    try {
      const response = await createConfigColorsOperator(
        token,
        operator,
        colors
      );

      if (response.rs) {
        dispatch(setMessageAlert("managContent.createSuccess"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const setUpContainerColors = (colors: IColors) => ({
  type: Types.setUpContainerColors,
  payload: colors,
});

export const setOperatorLogo = (logo: File | ILogo | null) => ({
  type: Types.setLogoOperator,
  payload: logo,
});

export const setAlternativeOperatorLogo = (
  alternativeLogo: File | ILogo | null
) => ({
  type: Types.setAlternativeLogoOperator,
  payload: alternativeLogo,
});

export const startSetOperatorLogo = (
  operator: number,
  logo: File,
  alternative: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user, token } = (getState() as AppState).auth;
    if (!user || !token) {
      throw new Error("Ivalid action");
    }
    try {
      const response = await setLogoOperatorService(
        token,
        operator,
        logo,
        alternative
      );
      if (response.rs) {
        dispatch(setMessageAlert("managContent.createSuccess"));
        dispatch(setTypeAlert("success"));
        dispatch(setOpenAlert());
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const setFooter = (data: IFooter) => ({
  type: Types.setFooter,
  payload: data,
});
