/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  KeyboardEvent,
  ChangeEvent,
  useEffect,
  useState,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { excelToJson } from "../../../helpers/convertFileToJson";
import { useTranslation } from "react-i18next";
import {
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../redux/actions/helperActions";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import {
  DetailTransactionalSettings,
  ResponsibleSetting,
  Roles_Enum,
  State,
} from "../../../enums/enums";
import * as yup from "yup";
import { MyTextField } from "../../components/custom/MyTextField";
import { getOperatorsService } from "../../../services/managementCompanyService";
import { setCompaniesRedux } from "../../../redux/actions/managementCompanyActions";
import { regLettersNumbers } from "../../../utils/utils";
import clearLogo from "../../../assets/svg/icon-park_clear-format.svg";
import { MyDialog } from "../../components/custom/MyDialog";
import {
  IDataMassiveTransactionSettings,
  ISettings,
} from "../../../interfaces/transactionSettings";
import { startCreateMassiveTransactionSetting } from "../../../redux/actions/transactionsActions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Container = styled("div")({
  display: "flex",
});

const CustomButton = styled(Button)({
  backgroundColor: "#007E8C",
  color: "white",
  height: "100%",
  width: "150px",
  "&:hover": {
    backgroundColor: "#007E8C",
    opacity: 1,
  },
});
export const MassiveTransactionSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { token, user } = useSelector((state: AppState) => state.auth);
  const { movementTypes, settingTypes, wallets } = useSelector(
    (state: AppState) => state.transactions
  );
  const { companies } = useSelector(
    (state: AppState) => state.managementCompanies
  );
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [formValues, setFormValues] = useState<any>(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [message, setMessage] = useState("");
  const formikRef = useRef<any>(null);

  useEffect(() => {
    const fetchOperators = async (token: string) => {
      if (user) {
        const params = {
          stateOperator: State.ACTIVE,
        };
        const operators = await getOperatorsService(token, params);
        dispatch(setCompaniesRedux(operators));
      }
    };
    token && fetchOperators(token);
    return () => {
      dispatch(setCompaniesRedux(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSuper = user?.roles.name === Roles_Enum.SUPER_ADMIN;

  const initialValues = {
    movementType: "",
    type: "",
    wallet: "",
    observations: "",
    operator: isSuper ? "" : user?.operator.id,
    detail: "",
    responsible: "",
  };

  const validationSchema = yup.object({
    movementType: yup.number().required(t("form.requiredFile")),
    type: yup.number().required(t("form.requiredFile")),
    wallet: yup.number().required(t("form.requiredFile")),
    observations: yup.string().required(t("form.requiredFile")),
    detail: yup.string().required(t("form.requiredFile")),
    responsible: yup.string().required(t("form.requiredFile")),
    operator: isSuper
      ? yup.string().required(t("form.requiredFile"))
      : yup.string(),
  });
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files && event.target.files[0];
    setSelectedFile(file ?? null);
  };
  const callback = (totalParse: number, totalLines: number) => {
    // Callback function to track progress
    console.log(`Progreso: ${totalParse}/${totalLines}`);
  };
  const handleSave = async (
    formValues: ISettings,
    resetForm: FormikHelpers<any>["resetForm"]
  ) => {
    if (!selectedFile || !token) return;
    setSubmitting(true);
    try {
      const json = await excelToJson(selectedFile, callback);
      const dataPlayers = JSON.parse(json);
      const combinedData: IDataMassiveTransactionSettings = {
        dataPlayers,
        settings: formValues,
      };
      await dispatch(startCreateMassiveTransactionSetting(combinedData));
    } catch (error) {
      dispatch(setMessageAlert("transactions.errorCreateMassiveSetting"));
      dispatch(setTypeAlert("warning"));
      dispatch(setOpenAlert());
    } finally {
      setSubmitting(false);
      setOpenConfirmation(false);
      setSelectedFile(null);
      resetForm();
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
  };

  const handleDownload = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();

    const fileName = "PlantillaAjustesMasivos.csv";

    const publicURL = process.env.PUBLIC_URL;
    const url = `${publicURL}/${fileName}`;

    window.location.href = url;
  };

  const handleShowConfirmationWindow = (message: string) => {
    setOpenConfirmation(true);
    setMessage(message);
  };

  const handleCleanForm = (resetForm: any) => {
    resetForm();
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const isEmptyValues = (formValues: any) => {
    let emptyValues = false;

    Object.entries(formValues).forEach(([key, value]) => {
      if (value === "") {
        emptyValues = true;
      }
    });

    return emptyValues;
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      (fileInputRef.current as any).click();
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className="white-container">
          <Grid item xs={12} className="mb-4">
            <Box
              display="flex"
              className="pb-1"
              borderBottom={1}
              borderColor="#02D0E7"
            >
              <i
                className="fa-solid fa-circle-info"
                style={{ color: "#02D0E7", marginRight: 9, fontSize: 17 }}
              ></i>
              <p
                className="txt-primary-md"
                style={{
                  fontWeight: "bold",
                }}
              >
                {t("transactions.createMassiveSetting")}
              </p>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange
              onSubmit={async (data) => {
                setFormValues(data);
                handleShowConfirmationWindow(t("transactions.sureCreated"));
              }}
            >
              {({ handleChange, values, isValid, resetForm }) => (
                <Form>
                  <Grid container spacing={2}>
                    {/* Divider Personal information */}
                    <Grid item xs={12} mb={2}>
                      <Divider
                        sx={{
                          "&.MuiDivider-root": {
                            "&::before": {
                              width: "20px",
                            },
                          },
                          color: "#333333",
                        }}
                        textAlign="left"
                      >
                        {t("user.personalInformation")}
                      </Divider>
                    </Grid>
                    {/* Movement Types */}
                    <Grid item xs={4}>
                      <MyTextField
                        name="movementType"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        label={`${t("transactions.movementType")}*`}
                        onChange={handleChange}
                      >
                        {movementTypes.map((item) => (
                          <MenuItem
                            key={item.name}
                            value={item.id}
                            sx={{
                              "&.Mui-focused": {
                                backgroundColor: "red",
                              },
                            }}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </MyTextField>
                    </Grid>
                    {/* Setting type */}
                    <Grid item xs={4}>
                      <MyTextField
                        name="type"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        label={`${t("transactions.setType")}*`}
                        onChange={handleChange}
                      >
                        {settingTypes.map((item) => (
                          <MenuItem
                            key={item.name}
                            value={item.id}
                            sx={{
                              "&.Mui-focused": {
                                backgroundColor: "red",
                              },
                            }}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </MyTextField>
                    </Grid>
                    {/* Wallet type */}
                    <Grid item xs={4}>
                      <MyTextField
                        name="wallet"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        select
                        label={`${t("transactions.wallet")}*`}
                        onChange={handleChange}
                      >
                        {wallets.map((item) => (
                          <MenuItem
                            key={item.name}
                            value={item.id}
                            sx={{
                              "&.Mui-focused": {
                                backgroundColor: "red",
                              },
                            }}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </MyTextField>
                    </Grid>
                    {/* Detail Transaction */}
                    <Grid item xs={3}>
                      <MyTextField
                        name="detail"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        select
                        label={`${t("transactionalSettings.detail")}*`}
                        onChange={handleChange}
                      >
                        {Object.entries(DetailTransactionalSettings).map(
                          ([key, value]) => (
                            <MenuItem
                              key={value}
                              value={key}
                              sx={{
                                "&.Mui-focused": {
                                  backgroundColor: "red",
                                },
                              }}
                            >
                              {t(`transactionalSettings.${value}`, value)}
                            </MenuItem>
                          )
                        )}
                      </MyTextField>
                    </Grid>
                    {/* Responsible */}
                    <Grid item xs={3}>
                      <MyTextField
                        name="responsible"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        select
                        label={`${t(
                          "transactionalSettings.responsibleSetting"
                        )}*`}
                        onChange={handleChange}
                      >
                        {Object.entries(ResponsibleSetting).map(
                          ([key, value]) => (
                            <MenuItem
                              key={value}
                              value={key}
                              sx={{
                                "&.Mui-focused": {
                                  backgroundColor: "red",
                                },
                              }}
                            >
                              {t(`transactionalSettings.${value}`)}
                            </MenuItem>
                          )
                        )}
                      </MyTextField>
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                        }}
                      >
                        <Container>
                          <TextField
                            label={`${t("managementBonds.uploadFilePlayers")}*`}
                            disabled
                            size="small"
                            fullWidth
                            value={selectedFile ? selectedFile.name : ""}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                          <input
                            type="file"
                            accept=".csv"
                            style={{ display: "none" }}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                          />
                          <label style={{ margin: 0 }}>
                            <CustomButton
                              style={{
                                border: "none",
                                outline: "none",
                                boxShadow: "none",
                              }}
                              onClick={handleButtonClick}
                            >
                              {t("managementBonds.examine")}
                            </CustomButton>
                          </label>
                        </Container>
                        <Typography>
                          <a href="#" onClick={handleDownload}>
                            {t("managementBonds.dowloadTemplate")}
                          </a>
                        </Typography>
                      </div>
                    </Grid>
                    {/* Operator */}
                    {isSuper && (
                      <Grid item xs={4}>
                        <FormControl sx={{ width: "100%" }} size="small">
                          <InputLabel id="operator-label">
                            {t("companyManagement.operator") + "*"}
                          </InputLabel>
                          <Select
                            name="operator"
                            size="small"
                            fullWidth
                            onChange={handleChange}
                            value={values.operator || ""}
                            MenuProps={MenuProps}
                            input={
                              <OutlinedInput
                                label={t("companyManagement.operator") + "*"}
                              />
                            }
                          >
                            {companies?.data?.map((company) => (
                              <MenuItem key={company.id} value={company.id}>
                                {company.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    {/* Divider Observation */}
                    <Grid item xs={12} mb={0.5} mt={2}>
                      <Divider
                        sx={{
                          "&.MuiDivider-root": {
                            "&::before": {
                              width: "20px",
                            },
                          },
                          color: "#333333",
                        }}
                        textAlign="left"
                      >
                        {t("tabs.observations")}
                      </Divider>
                    </Grid>
                    {/* Observation */}
                    <Grid item xs={12}>
                      <MyTextField
                        name="observations"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        InputLabelProps={{ shrink: false }}
                        label={` `}
                        multiline
                        rows={6}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: 500,
                        }}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                          !regLettersNumbers(e.key) && e.preventDefault()
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="center"
                      sx={{ height: "60px" }}
                      mt={3}
                      mb={3}
                    >
                      <Button
                        type="submit"
                        style={{ border: "none", outline: "none" }}
                        variant="contained"
                        id="btnSave"
                        disabled={
                          isSubmitting ||
                          isEmptyValues(values) ||
                          !isValid ||
                          !selectedFile
                        }
                        className="footer-btn primary-btn ripple"
                      >
                        {isSubmitting ? (
                          <CircularProgress size={"1.6rem"} color="inherit" />
                        ) : (
                          <>
                            <i className="fa-solid fa-check"></i>
                            {t("button.save")}
                          </>
                        )}
                      </Button>
                      {!isSubmitting && (
                        <Button
                          type="button"
                          style={{
                            border: "none",
                            outline: "none",
                            marginLeft: "4px",
                          }}
                          onClick={() => handleCleanForm(resetForm)}
                        >
                          <img src={clearLogo} alt="Icon clear" />
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
            <MyDialog
              open={openConfirmation}
              title={t("dialog.confirmationWindow")}
              message={message}
              handleAccept={() =>
                message === t("dialog.sureCancel")
                  ? handleCancel()
                  : handleSave(formValues, formikRef.current?.resetForm)
              }
              handleClose={() => setOpenConfirmation(false)}
              isLoading={isSubmitting}
            />
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
