import React, { useState, useEffect } from "react";
import { Grid, Button, CircularProgress, Box } from "@mui/material";
import { FormCustomColors } from "./FormCustomColors";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../redux/reducers/rootReducer";
import { MyDialog } from "../../../../../components/custom/MyDialog";
import { setLoadingState } from "../../../../../../redux/actions/helperActions";
import {
  startSetColors,
  startSetContent,
  startSetOperatorLogo,
} from "../../../../../../redux/actions/contentManagementActions";
import { IContentManagementState } from "../../../../../../redux/reducers/contentManagementReducer";

export const ManagementHeader = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const { logo, colors, footer } = useSelector(
    (state: AppState) => state.contentManagement
  );
  const { user } = useSelector((state: AppState) => state.auth);
  const { loading } = useSelector((state: AppState) => state.helper);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startSetContent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSaveConfigHeader = async () => {
    const operator = user?.operator ? user.operator.id : null;
    if (operator) {
      const data: Partial<IContentManagementState> = {
        colors: colors,
        footer: footer,
      };
      dispatch(setLoadingState({ loading: true }));
      if (logo && "type" in logo) {
        await dispatch(startSetOperatorLogo(operator, logo as File));
      }
      await dispatch(startSetColors(operator, data));
      dispatch(setLoadingState({ loading: false }));
      setOpenDialog(false);
    }
  };

  return (
    <Grid container>
      {loading ? (
        <Grid container className={"paperLoading"}>
          <Box mx="auto" my={"30vh"}>
            <CircularProgress className={"colorLoading"} />
          </Box>
        </Grid>
      ) : (
        <>
          <Grid
            container
            bgcolor={"#FFF"}
            p={2}
            sx={{
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
          >
            <FormCustomColors />
            <Grid item xs={10} />

            <Grid item xs={2} mt={1}>
              <Button
                type="submit"
                variant="contained"
                className="footer-btn primary-btn ripple"
                style={{ border: "none", outline: "none" }}
                onClick={() => setOpenDialog(true)}
                disabled={!!loading}
              >
                {t("button.save")}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      <MyDialog
        id="confirmationDialog"
        data-testid="confirmationDialog"
        open={openDialog}
        title={t("dialog.confirmationWindow")}
        message={t("dialog.sureSaveChanges")}
        handleAccept={handleSaveConfigHeader}
        handleClose={() => setOpenDialog(false)}
        isLoading={!!loading}
      />
    </Grid>
  );
};
