import { IPromoter } from "../../interfaces/promoters";
import { IActionRedux, IPaginationData } from "../../interfaces/shared";
import { Types } from "../types/types";

interface IState {
  promoters: IPaginationData<IPromoter> | null;
  activePromoter: IPromoter | null;
}

const initialState: IState = {
  promoters: null,
  activePromoter: null,
};
export const managementPromotersReducer = (
  state = initialState,
  action: IActionRedux
): IState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setPromoters:
      return {
        ...state,
        promoters: payload,
      };
    case Types.setActivePromoter:
      return {
        ...state,
        activePromoter: payload,
      };

    default:
      return state;
  }
};
