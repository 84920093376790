import React, { useState } from "react";
import {
  Grid,
  Paper,
  ThemeProvider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
  Pagination,
} from "@mui/material";
import { theme } from "../../custom/customStyles";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { useSelector } from "react-redux";
import { CurrentPlaceCards, Roles_Enum } from "../../../../enums/enums";

interface CardsResumeModalProps {
  row: any;
  onClose: () => void;
}

export const CardsResumeModal: React.FC<CardsResumeModalProps> = ({
  row,
  onClose,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [searchAssociate, setSearchAssociate] = useState("");
  const [searchDealer, setSearchDealer] = useState("");
  const [searchPointSale, setSearchPointSale] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const { user } = useSelector((state: AppState) => state.auth);

  const textStyle = { color: "black" };

  const paginationStyle = {
    marginTop: "10px",
    "& .MuiPaginationItem-root": {
      backgroundColor: "#6c757d",
      color: "white",
    },
    "& .Mui-selected": {
      backgroundColor: "#5a6268",
      color: "white",
    },
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Restablece la página actual a 1
  };

  const handleSearchAssociateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchAssociate(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchDealerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchDealer(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchPointSaleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchPointSale(event.target.value);
    setCurrentPage(1);
  };

  const cardStates = [
    { id: 1, name: "filters.cardActive" },
    { id: 2, name: "filters.cardInactive" },
    { id: 3, name: "filters.cardRedeemed" },
  ];

  const getCardStateText = (id: number) => {
    const state = cardStates.find((state) => state.id === id);
    return state ? t(state.name) : t("Unknown");
  };

  const getUserRoleAbbreviation = () => {
    switch (user?.roles.name) {
      case Roles_Enum.SUPER_ADMIN:
      case Roles_Enum.ADMIN_OPERATOR:
        return "AD";
      case Roles_Enum.ADMIN_ASSOCIATE:
      case Roles_Enum.ASSOCIATE:
        return "AS";
      case Roles_Enum.DEALER:
        return "DEL";
      case Roles_Enum.SELLER:
        return "PS";
      case Roles_Enum.PROMOTOR:
        return "PR";
    }
  };

  const userRoleAbbreviation = getUserRoleAbbreviation();

  const exportToCSV = () => {
    let csvContent = ["Lote"];

    if (userRoleAbbreviation === "AD") {
      csvContent.push("Asociado");
      csvContent.push("Distribuidor");
    }
    if (userRoleAbbreviation === "AS") {
      csvContent.push("Distribuidor");
    }
    if (userRoleAbbreviation === CurrentPlaceCards.DEALER) {
      csvContent.push(t("reports.promoter"));
    }

    csvContent.push(
      "Punto de Venta",
      "Numero de Tarjeta",
      "Valor de la Tarjeta",
      "Estado",
      "Jugador",
      "Fecha de Redención",
      "Hora"
    );
    // @ts-ignore
    csvContent = csvContent.join(",") + "\n";

    const filteredCards = row.cards
      .filter((card: any) => card.id.toString().includes(searchTerm))
      .filter(
        (card: any) =>
          selectedState === "" || card.state === Number(selectedState)
      )
      .filter((card: any) =>
        searchAssociate
          ? (card.associate || "")
              .toLowerCase()
              .includes(searchAssociate.toLowerCase())
          : true
      )
      .filter((card: any) =>
        searchDealer
          ? (card.dealer || "")
              .toLowerCase()
              .includes(searchDealer.toLowerCase())
          : true
      )
      .filter((card: any) =>
        searchPointSale
          ? (card.pointSale || "")
              .toLowerCase()
              .includes(searchPointSale.toLowerCase())
          : true
      );

    filteredCards.forEach((card: any) => {
      const cardData = [row.lotId];

      if (userRoleAbbreviation === "AD") {
        cardData.push(card.associate);
        cardData.push(card.dealer);
      }
      if (userRoleAbbreviation === "AS") {
        cardData.push(card.dealer);
      }
      if (userRoleAbbreviation === CurrentPlaceCards.DEALER) {
        cardData.push(card.promotor);
      }

      cardData.push(
        card.pointSale,
        card.id,
        row.cardAmount,
        getCardStateText(card.state),
        card.redeemingPlayerId || "-",
        card.redemptionDate
          ? moment(card.redemptionDate).format("YYYY-MM-DD")
          : "-",
        card.redemptionDate
          ? moment(card.redemptionDate).format("HH:mm:ss")
          : "-"
      );
      // @ts-ignore
      csvContent += cardData.join(",") + "\n";
    });
    // @ts-ignore
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(
      blob,
      `${userRoleAbbreviation}-${row.lotId}-${moment().format(
        "YYYY-MM-DD"
      )}.csv`
    );
  };

  const exportToXLSX = () => {
    let sheetData = [["Lote"]];

    if (userRoleAbbreviation === "AD") {
      sheetData[0].push("Asociado", "Distribuidor");
    }
    if (userRoleAbbreviation === "AS") {
      sheetData[0].push("Distribuidor");
    }
    if (userRoleAbbreviation === CurrentPlaceCards.DEALER) {
      sheetData[0].push(t("reports.promoter"));
    }

    sheetData[0].push(
      "Punto de Venta",
      "Numero de Tarjeta",
      "Valor de la Tarjeta",
      "Estado",
      "Jugador",
      "Fecha de Redención",
      "Hora"
    );

    const filteredCards = row.cards
      .filter((card: any) => card.id.toString().includes(searchTerm))
      .filter(
        (card: any) =>
          selectedState === "" || card.state === Number(selectedState)
      )
      .filter((card: any) =>
        searchAssociate
          ? (card.associate || "")
              .toLowerCase()
              .includes(searchAssociate.toLowerCase())
          : true
      )
      .filter((card: any) =>
        searchDealer
          ? (card.dealer || "")
              .toLowerCase()
              .includes(searchDealer.toLowerCase())
          : true
      )
      .filter((card: any) =>
        searchPointSale
          ? (card.pointSale || "")
              .toLowerCase()
              .includes(searchPointSale.toLowerCase())
          : true
      );

    filteredCards.forEach((card: any) => {
      const cardData = [row.lotId];

      if (userRoleAbbreviation === "AD") {
        cardData.push(card.associate, card.dealer);
      }
      if (userRoleAbbreviation === "AS") {
        cardData.push(card.dealer);
      }
      if (userRoleAbbreviation === CurrentPlaceCards.DEALER) {
        cardData.push(card.promotor);
      }

      cardData.push(
        card.pointSale,
        Number(card.id),
        Number(row.cardAmount),
        getCardStateText(card.state),
        Number(card.redeemingPlayerId) || "-",
        card.redemptionDate
          ? moment(card.redemptionDate).format("YYYY-MM-DD")
          : "-",
        card.redemptionDate
          ? moment(card.redemptionDate).format("HH:mm:ss")
          : "-"
      );

      sheetData.push(cardData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Cards");

    const xlsxData = XLSX.write(workbook, { type: "array", bookType: "xlsx" });
    const blob = new Blob([xlsxData], { type: "application/octet-stream" });
    saveAs(
      blob,
      `${userRoleAbbreviation}-${row.lotId}-${moment().format(
        "YYYY-MM-DD"
      )}.xlsx`
    );
  };

  const filteredCards = row.cards
    .filter((card: any) => card.id.toString().includes(searchTerm))
    .filter(
      (card: any) =>
        selectedState === "" || card.state === Number(selectedState)
    )
    .filter((card: any) =>
      searchAssociate
        ? (card.associate || "")
            .toLowerCase()
            .includes(searchAssociate.toLowerCase())
        : true
    )
    .filter((card: any) =>
      searchDealer
        ? (card.dealer || "").toLowerCase().includes(searchDealer.toLowerCase())
        : true
    )
    .filter((card: any) =>
      searchPointSale
        ? (card.pointSale || "")
            .toLowerCase()
            .includes(searchPointSale.toLowerCase())
        : true
    );

  const paginatedCards = filteredCards.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={2}
        style={{ padding: "20px", backgroundColor: "white" }}
      >
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Typography variant="h6" style={textStyle}>
            {t("reports.totalCards")}: {row.totalCards}
          </Typography>
          <Typography variant="body1" style={textStyle}>
            {t("filters.cardActive")}: {row.activeCards}
          </Typography>
          <Typography variant="body1" style={textStyle}>
            {t("filters.cardInactive")}: {row.inactiveCards}
          </Typography>
          <Typography variant="body1" style={textStyle}>
            {t("filters.cardRedeemed")}: {row.redeemedCards}
          </Typography>
          <Typography variant="body1" style={textStyle}>
            {t("reports.filteredCards")}: {filteredCards.length}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            label={t("filters.cardState")}
            variant="outlined"
            fullWidth
            value={selectedState}
            onChange={(event) => setSelectedState(event.target.value)}
            style={{ marginBottom: "20px" }}
          >
            <MenuItem value="">{t("filters.cardAllStates")}</MenuItem>
            {cardStates.map((state) => (
              <MenuItem key={state.id} value={state.id}>
                {t(state.name)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("filters.cardNumber")}
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ marginBottom: "20px" }}
          />
        </Grid>
        {userRoleAbbreviation === "AD" && (
          <Grid item xs={4}>
            <TextField
              label={t("filters.associate")}
              variant="outlined"
              fullWidth
              value={searchAssociate}
              onChange={handleSearchAssociateChange}
              style={{ marginBottom: "20px" }}
            />
          </Grid>
        )}
        {(userRoleAbbreviation === "AD" || userRoleAbbreviation === "AS") && (
          <Grid item xs={4}>
            <TextField
              label={t("filters.dealer")}
              variant="outlined"
              fullWidth
              value={searchDealer}
              onChange={handleSearchDealerChange}
              style={{ marginBottom: "20px" }}
            />
          </Grid>
        )}

        <Grid item xs={4}>
          <TextField
            label={t("reports.pointSale")}
            variant="outlined"
            fullWidth
            value={searchPointSale}
            onChange={handleSearchPointSaleChange}
            style={{ marginBottom: "20px" }}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <button
            className="d-flex btn-light-dark"
            type="button"
            style={{ width: "48%", marginBottom: "20px", marginRight: "4%" }}
            onClick={exportToCSV}
          >
            <div className="p-0 text-center">
              <label className="btn-label pl-1">{t("dialog.exportCsv")}</label>
            </div>
          </button>
          <button
            className="d-flex btn-light-dark"
            type="button"
            style={{ width: "48%", marginBottom: "20px" }}
            onClick={exportToXLSX}
          >
            <div className="p-0 text-center">
              <label className="btn-label pl-1">
                {t("dialog.exportExcel")}
              </label>
            </div>
          </button>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Paper elevation={2} style={{ padding: "10px" }}>
            <TableContainer style={{ maxHeight: 300, overflow: "auto" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("reports.cardId")}</TableCell>
                    {userRoleAbbreviation === "AD" && (
                      <TableCell>{t("reports.associate")}</TableCell>
                    )}
                    {(userRoleAbbreviation === "AD" ||
                      userRoleAbbreviation === "AS") && (
                      <TableCell>{t("reports.dealer")}</TableCell>
                    )}
                    {userRoleAbbreviation === CurrentPlaceCards.DEALER && (
                      <TableCell>{t("reports.promoter")}</TableCell>
                    )}
                    <TableCell>{t("reports.pointSale")}</TableCell>
                    <TableCell>{t("filters.cardState")}</TableCell>
                    <TableCell>{t("reports.redeemingPlayerId")}</TableCell>
                    <TableCell>{t("reports.redeemingPlayerName")}</TableCell>
                    <TableCell>{t("reports.redemptionDate")}</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {paginatedCards.map((card: any) => (
                    <TableRow key={card.id}>
                      <TableCell>{card.id}</TableCell>
                      {userRoleAbbreviation === "AD" && (
                        <TableCell>{card.associate}</TableCell>
                      )}
                      {(userRoleAbbreviation === "AD" ||
                        userRoleAbbreviation === "AS") && (
                        <TableCell>{card.dealer}</TableCell>
                      )}
                      {userRoleAbbreviation === CurrentPlaceCards.DEALER && (
                        <TableCell>{card.promotor}</TableCell>
                      )}

                      <TableCell>{card.pointSale}</TableCell>
                      <TableCell>{getCardStateText(card.state)}</TableCell>
                      <TableCell>{card.redeemingPlayerId || "-"}</TableCell>
                      <TableCell>{card.redeemingPlayerName || "-"}</TableCell>
                      <TableCell>
                        {card.redemptionDate
                          ? moment(card.redemptionDate).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          : "-"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              count={Math.ceil(filteredCards.length / itemsPerPage)}
              page={currentPage}
              onChange={(event, newPage) => setCurrentPage(newPage)}
              color="primary"
              sx={paginationStyle}
            />
            <button
              className="d-flex btn-light-dark"
              type="button"
              style={{ width: "20%", marginTop: "10px" }}
              onClick={onClose}
            >
              <div className="p-0 text-center">
                <label className="btn-label pl-1">Cerrar</label>
              </div>
            </button>
          </Paper>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
